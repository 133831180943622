<template>
  <a-drawer
    placement="right"
    width="728"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>确认导拍模版</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <div class="drawer-tips">
          <a-icon type="exclamation-circle" theme="filled" class="icon" />
          <span>导拍模板确认后经销商可见</span>
        </div>
        <div class="drawer-table">
          <div class="drawer-table-header">
            <div class="header-col col1">名称</div>
            <div class="header-col col2">生产日期</div>
            <div class="header-col col3">操作</div>
          </div>
          <div class="drawer-table-body">
            <div class="loading-box" v-if="tableLoading">
              <a-spin :spinning="tableLoading" />
            </div>
            <div
              class="loading-box"
              v-if="!tableLoading && templateData.length == 0"
            >
              <Empty :title="'暂无模版'" />
            </div>
            <div
              class="drawer-table-item"
              v-for="(item, index) in templateData"
              :key="index"
            >
              <div
                class="col1 fc1 ellipsis"
                :title="item.template_name"
                @click="getAuthUrl(item.template_url)"
              >
                {{ item.template_name || "-" }}
              </div>
              <div class="col2 fc2">{{ returnDate(item.create_time) }}</div>
              <div class="col3 action-col">
                <span
                  class="action-text fc1"
                  v-if="item.status == 1"
                  @click="editTemplate(item.template_id, 2)"
                  >确认</span
                >
                <span class="action-text fc3" v-if="item.status == 2"
                  >已确认</span
                >
                <span
                  class="action-text fc1"
                  @click="getAuthUrl(item.template_url)"
                  >预览</span
                >
                <span
                  class="action-text fc1"
                  @click="editTemplate(item.template_id, 3)"
                  >删除</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="drawer-tools">
        <span class="btn btn1" @click="onClose">取消</span>
        <a-button
          class="btn btn2"
          @click="editTemplate('all', 2)"
          :loading="isAddGroupLoading"
          >确定全部</a-button
        >
      </div>
    </div>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="videoModalClose"
      ref="modal"
    >
      <video
        id="videoElement"
        autoplay
        controls
        muted
        style="width: 100%; height: 100%; border-radius: 10px"
      ></video>
    </a-modal>
  </a-drawer>
</template>

<script>
import api from "@/api/activityManagement";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import Empty from "@/components/Empty/Empty";
export default {
  components: { Empty },
  props: {},
  data() {
    return {
      visible: false,
      previewVisible: false,
      previewImage: "",
      isAddGroupLoading: false,
      currentItem: {},
      flvPlayer: null,
      tableLoading: false,
      templateData: [],
    };
  },
  created() {},
  methods: {
    showDrawer(currentItem) {
      this.currentItem = currentItem;
      console.log("this.currentItem", this.currentItem);
      this.visible = true;
      this.getTemplateManage();
    },

    // 活动弹窗-取消
    onClose() {
      this.visible = false;
    },
    getTemplateManage() {
      let params = {
        activity_id: this.currentItem.id,
      };
      this.tableLoading = true;
      this.templateData = [];
      api
        .getTemplateManage(params)
        .then((res) => {
          console.log("getTemplateManage", res);
          if (res.data.code === 0) {
            this.templateData = res.data.data;
          } else {
            this.$message.error(res.message || "获取模版失败");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    // 视频地址通过内容中台获取权限
    getAuthUrl(source) {
      let body = {
        resource_url: source,
      };
      api
        .getAuthUrl(body)
        .then((res) => {
          console.log("getAuthUrl", res);
          if (res.data.code === 200) {
            this.getStream(res.data.data);
          } else {
            this.$message.error(res.data.message || "获取视频权限失败");
          }
        })
        .catch((err) => {
          this.$message.error("获取视频权限失败");
          console.error(err);
        });
    },
    // 初始化flv
    getStream(source) {
      this.previewVisible = true;
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          console.log(1, source);
          this.flvPlayer = flvjs.createPlayer({
            type: source.indexOf(".mp4") > 0 ? "mp4" : "flv",
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            this.videoModalClose();
            this.$message.warning("视频播放失败，请联系管理员...");
          });
        }
      });
    },
    // 编辑点击事件
    editTemplate(id, status) {
      let template_ids = [];
      if (id == "all") {
        template_ids = this.templateData.map((item) => {
          return item.template_id;
        });
      } else {
        template_ids.push(id);
      }
      if (status == 2) {
        this.editTemplateManage(template_ids, 2);
      } else {
        this.editTemplateManage(template_ids, 3);
        // this.$confirm({
        //   title: "确认删除该模板吗？",
        //   okText: "确定",
        //   cancelText: "取消",
        //   onOk() {
        //     this.editTemplateManage(template_ids, 3);
        //   },
        // });
      }
    },
    // 编辑模版状态接口
    editTemplateManage(template_ids, status) {
      let body = {
        activity_id: this.currentItem.id,
        status: status,
        template_ids: template_ids,
      };
      api
        .editTemplateManage(body)
        .then((res) => {
          console.log("editTemplateManage", res);
          if (res.data.code === 0) {
            this.$message.success("修改成功");
            this.getTemplateManage();
          } else {
            this.$message.error(res.message || "修改模版状态失败");
          }
        })
        .catch((err) => {
          this.$message.error("修改模版状态失败");
        });
    },
    // 关闭 flv 视频弹窗事件
    videoModalClose() {
      this.previewVisible = false;
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
      }
      this.$refs.modal.$destroyAll();
    },
    returnDate(str) {
      if (str) {
        return str.slice(0, 10);
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 116px);
    padding: 0 24px;
    overflow-y: auto;
    .drawer-tips {
      width: 100%;
      height: 40px;
      background: #1e2b48;
      border-radius: 2px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      margin-top: 24px;
      .icon {
        color: #3c7eff;
        margin: 0 8px 0 17px;
      }
    }
    .drawer-table {
      width: 100%;
      margin-top: 15px;
      &-header {
        width: 100%;
        height: 50px;
        background: #242832;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.85);
      }
      &-body {
        width: 100%;
        height: calc(100vh - 260px);
        overflow-y: auto;
        .loading-box {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .drawer-table-item {
          height: 50px;
          border-bottom: 1px solid #3c4252;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          font-size: 14px;
          .ellipsis {
            padding-right: 88px;
            display: inline-block;
            overflow: hidden;
            flex: 1;
            vertical-align: middle;
            width: 280px;
            height: 50px;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
            line-height: 50px;
            cursor: pointer;
          }
          .action-col {
            display: flex;
            > .action-text {
              margin-right: 16px;
              cursor: pointer;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      .col1 {
        width: 387px;
        padding-left: 24px;
      }
      .col2 {
        width: 132px;
      }
      .col3 {
        width: 161px;
        padding-right: 24px;
      }
      .fc1 {
        color: #ffca5b;
      }
      .fc2 {
        color: rgba(255, 255, 255, 0.85);
      }
      .fc3 {
        color: #8890a6;
      }
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 88px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.btn1 {
        background: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}
</style>