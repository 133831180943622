<template>
  <a-modal
    :title="`查看示例 · ${titleMap[name]}`"
    :visible="visible"
    width="720px"
    @cancel="visible = false"
  >
    <a-alert
      type="warning"
      banner
      :message="
        `标题、正文、话题只需其中一个类别命中全部关键词，该笔记即判定为命中`
      "
      style="margin-bottom: 12px;"
      v-if="name === 'demo_3_and'"
    />
    <img
      class="demo-img"
      :src="require(`@/assets/demo_img/${name}.png`)"
      v-if="name"
    />
    <div slot="footer" class="modal-footer">
      <div class="btn btn2" @click="visible = false">确认</div>
    </div>
  </a-modal>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      visible: false,
      titleMap: {
        demo_1_or: "视频标题包含任意关键词",
        demo_1_and: "视频标题包含全部关键词",
        demo_2_or: "直播标题包含任意关键词",
        demo_2_and: "直播标题包含全部关键词",
        demo_3_or: "笔记标题/正文/话题包含任意关键词",
        demo_3_and: "笔记标题/正文/话题包含全部关键词",
      },
      name: "",
    };
  },
  methods: {
    openModal(checkData, KeywordRelation) {
      this.name = "";
      this.name = `demo_${checkData}_${KeywordRelation}`;
      this.visible = true;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep .ant-modal-header {
  padding: 24px;
  border-bottom: none;
}
::v-deep .ant-modal-close-x {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
::v-deep .ant-modal-body {
  padding: 0 24px;
}
::v-deep .ant-modal-footer {
  padding: 20px 24px;
  border-top: none;
}
::v-deep .ant-alert-warning {
  background: #4c3d1a;
  border-radius: 6px;
}
::v-deep .ant-alert-warning .ant-alert-icon {
  color: #ffcc55;
}
::v-deep .ant-alert-message {
  color: rgba(255, 255, 255, 0.88);
}

.demo-img {
  width: 672px;
  min-height: 351px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn {
    height: 40px;
    width: 68px;
    line-height: 40px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    border: none;
    display: inline-block;
    cursor: pointer;
    background: #ffcc55;
    color: rgba(0, 0, 0, 0.88);
  }
}
</style>
