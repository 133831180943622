<template>
  <div>
    <platform-header
      title="品牌矩阵全貌"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="main-container">
      <div class="tools">
        <a-select
          mode="multiple"
          placeholder="请选择品牌"
          v-model="brandSelected"
          :filter-option="filterOption"
          @change="brandChange"
          style="width: 600px"
        >
          <a-select-option v-for="item in brandList" :key="item.principal_id">
            {{ item.principal_name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="tabs">
        <a-tabs default-active-key="#tab1" @change="scrollTo">
          <a-tab-pane key="#tab1" tab="矩阵概况"></a-tab-pane>
          <a-tab-pane key="#tab2" tab="平台覆盖"></a-tab-pane>
          <a-tab-pane key="#tab3" tab="视频宣传"></a-tab-pane>
          <a-tab-pane key="#tab4" tab="直播宣传"></a-tab-pane>
          <!-- <a-tab-pane key="#tab5" tab="互动总量"></a-tab-pane> -->
          <a-tab-pane key="#tab6" tab="品牌经销商"></a-tab-pane>
          <a-tab-pane key="#tab7" tab="发布对比"></a-tab-pane>
          <a-tab-pane key="#tab8" tab="粉丝对比"></a-tab-pane>
        </a-tabs>
      </div>
      <div class="main" v-if="matrixData && matrixData.platform_list">
        <div class="main-item" id="tab1" style="height: 280px;padding-top: 0;">
          <!-- <div class="main-item-title"><svg-icon :icon-class="'title'" class="main-item-title-icon" />矩阵概况</div> -->
          <div class="main-item-content" style="height: 260px;">
            <div class="matrix-table">
              <img src="@/assets/img/matrix.png" width="210" alt="" />
              <div class="matrix-table-row row0">
                <div class="matrix-table-row-left"></div>
                <div
                  class="matrix-table-row-right matrix-table-row-name"
                  v-for="(item, index) in matrixData.overview_all_list"
                  :key="index"
                >
                  {{ item.principal_name }}
                </div>
              </div>
              <div class="matrix-table-row row1">
                <div class="matrix-table-row-left"></div>
                <div
                  class="matrix-table-row-right matrix-table-row-number"
                  v-for="(item, index) in matrixData.overview_all_list"
                  :key="index"
                >
                  {{ item.offical_count }}
                </div>
              </div>
              <div class="matrix-table-row row2">
                <div class="matrix-table-row-left"></div>
                <div
                  class="matrix-table-row-right matrix-table-row-number"
                  v-for="(item, index) in matrixData.overview_all_list"
                  :key="index"
                >
                  {{ item.dealer_count }}/{{ item.dealer_author_count }}
                </div>
              </div>
              <div class="matrix-table-row row3">
                <div class="matrix-table-row-left"></div>
                <div
                  class="matrix-table-row-right matrix-table-row-number"
                  v-for="(item, index) in matrixData.overview_all_list"
                  :key="index"
                >
                  {{ item.kol_count }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-item" id="tab2">
          <div class="main-item-title">
            <svg-icon
              :icon-class="'title'"
              class="main-item-title-icon"
            />平台覆盖
          </div>
          <div class="main-item-content">
            <my-chart
              :options="
                getBarOptions(
                  matrixData.platform_list.xData,
                  matrixData.platform_list.yData
                )
              "
              id="chartTab2"
            />
          </div>
        </div>
        <div class="main-item" id="tab3">
          <div class="main-item-title">
            <svg-icon
              :icon-class="'title'"
              class="main-item-title-icon"
            />视频宣传
          </div>
          <div class="main-item-content">
            <my-chart
              :options="
                getBarOptions(
                  matrixData.aweme_interaction_list.xData,
                  matrixData.aweme_interaction_list.yData
                )
              "
              id="chartTab3"
            />
          </div>
        </div>
        <div class="main-item" id="tab4">
          <div class="main-item-title">
            <svg-icon
              :icon-class="'title'"
              class="main-item-title-icon"
            />直播宣传
          </div>
          <div class="main-item-content">
            <my-chart
              :options="
                getBarOptions(
                  matrixData.live_interaction_list.xData,
                  matrixData.live_interaction_list.yData
                )
              "
              id="chartTab4"
            />
          </div>
        </div>
        <!-- <div class="main-item" id="tab5">
          <div class="main-item-title"><svg-icon :icon-class="'title'" class="main-item-title-icon" />互动总量</div>
          <div class="main-item-content">
            <my-chart :options="getMultipleBarOptions(matrixData.interaction_total_list.xData, matrixData.interaction_total_list.yData)" id="chartTab5" />
          </div>
        </div> -->
        <div class="main-item" id="tab6">
          <div class="main-item-title">
            <svg-icon
              :icon-class="'title'"
              class="main-item-title-icon"
            />品牌经销商
          </div>
          <div class="main-item-content">
            <my-chart
              :options="getPieOptions(matrixData.dealer_percent_list, '')"
              id="chartTab6"
            />
          </div>
        </div>
        <div class="main-item" id="tab7">
          <div class="main-item-title">
            <svg-icon
              :icon-class="'title'"
              class="main-item-title-icon"
            />发布对比
          </div>
          <div class="main-item-content" style="display: flex">
            <!-- <div style="width: 50%">
              <my-chart :options="getPieOptions(matrixData.live_percent_list, '直播发布占比')" id="chartTab72" />
            </div> -->
            <div style="width: 50%">
              <my-chart
                :options="
                  getPieOptions(matrixData.aweme_percent_list, '短视频发布占比')
                "
                id="chartTab71"
              />
            </div>
          </div>
        </div>
        <div class="main-item" id="tab8">
          <div class="main-item-title">
            <svg-icon
              :icon-class="'title'"
              class="main-item-title-icon"
            />粉丝对比
          </div>
          <div class="main-item-content">
            <my-chart
              :options="getPieOptions(matrixData.fans_percent_list, '')"
              id="chartTab8"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";

import optionsTab2 from "./options/optionsTab2";
import optionsTab5 from "./options/optionsTab5";
import optionsTab678 from "./options/optionsTab678";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "brandMatrix"
);
export default {
  name: "brandMatrix",
  components: { PlatformHeader, MyChart },
  data() {
    return {
      msg: "品牌矩阵",
      brandSelected: [],
      currentTab: "",
    };
  },
  computed: {
    ...mapState({
      brandList: (state) => state.brandList, // 品牌列表
      matrixData: (state) => state.matrixData, // 矩阵数据
    }),
  },
  async created() {
    // await this.getBrandList({
    //   platform: this.currentTab,
    // });
    // if (this.brandList && this.brandList.length > 0) {
    //   for (let i = 0; i < this.brandList.length; i++) {
    //     if (i < 5) {
    //       this.brandSelected.push(this.brandList[i].principal_id);
    //     }
    //   }
    // }
    // this.getMatrixData({
    //   principal_ids: this.brandSelected,
    //   platform: this.currentTab,
    // });
  },
  watch: {},
  methods: {
    ...mapActions(["getBrandList", "getMatrixData"]),
    ...mapMutations(["changeState"]),
    async tabChange(val) {
      console.log(val);
      await this.getBrandList({
        platform: this.currentTab,
      });
      if (this.brandList && this.brandList.length > 0) {
        for (let i = 0; i < this.brandList.length; i++) {
          if (i < 5) {
            this.brandSelected.push(this.brandList[i].principal_id);
          }
        }
      }
      this.getMatrixData({
        principal_ids: this.brandSelected,
        platform: this.currentTab,
      });
    },
    search() {
      this.getMatrixData({
        principal_ids: this.brandSelected,
        platform: this.currentTab,
      });
    },
    // 下拉框选中调用
    brandChange(data) {
      console.log(data);
      if (data && data.length > 5) {
        this.brandSelected = this.brandSelected.slice(0, 5);
        this.$message.warning("最多选择5个竞品品牌");
      } else {
        this.search();
      }
    },
    getBarOptions(xData, yData) {
      let option = deepClone(optionsTab2);
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      return option;
    },
    getBarLineOptions() {
      let option = deepClone(optionsTab34);
      return option;
    },
    getMultipleBarOptions(xData, yData) {
      let option = deepClone(optionsTab5);
      var data = {
        area: xData,
        legend: ["总分享数", "总评论数", "总点赞数"],
        data: yData,
      };
      option.xAxis.data = xData;

      for (var i = 0; i < data.legend.length; i++) {
        option.series.push({
          name: data.legend[i],
          type: "bar",
          stack: "总量",
          barWidth: "20px",
          label: {
            show: false,
            position: "insideRight",
          },
          data: data.data[i],
        });
      }
      return option;
    },
    getPieOptions(data, name) {
      let option = deepClone(optionsTab678);
      option.title.text = name;
      option.series[0].name = name;
      option.series[0].data = data;
      return option;
    },
    scrollTo(selector) {
      this.$el.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.trim().toLowerCase()) >= 0
      );
    },
  },
  beforeDestroy() {
    this.changeState({ data: [], name: "brandList" });
    this.changeState({ data: {}, name: "matrixData" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
  position: relative;

  .beta-icon {
    width: 36px;
    height: 14px;
    border-radius: 7px;
    background: #ff4160;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: 4px;
    right: 0px;
  }
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.main-container {
  width: 100%;
  padding: 0 24px;
  .tools {
    margin-bottom: 16px;
  }
  .main {
    height: calc(100vh - 280px);
    overflow-y: auto;
    &-item {
      height: 380px;
      border-radius: 4px;
      @include background_color("background_color2");
      padding: 16px 15px 24px 15px;
      margin-bottom: 16px;
      &-title {
        @include font_color("font_color2");
        &-icon {
          @include font_color("font_color5");
          font-size: 18px;
          margin: 0 8px;
        }
      }
      &-content {
        height: 300px;
        margin: 10px 0 0px;
        .matrix-table {
          width: 100%;
          height: 100%;
          color: #fff;
          padding: 20px 30px 0px 90px;
          position: relative;
          > img {
            position: absolute;
            top: 17px;
            left: -10px;
          }
          &-row.row0 {
            height: 30px;
            line-height: 30px;
            margin-bottom: 11px;
          }
          .row1 {
            @include background_color("background_color22");
          }
          .row2 {
            @include background_color("background_color23");
          }
          .row3 {
            @include background_color("background_color24");
          }
          &-row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            height: 44px;
            line-height: 44px;
            margin-bottom: 16px;
            &-name {
              @include font_color("font_color2");
            }
            &-number {
              @include font_color("font_color3");
            }
            &-left {
              width: 100px;
            }
            &-right {
              width: 114px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
