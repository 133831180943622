<template>
  <div class="series-card-container">
    <WebpImage
      :scale="'-series2'"
      :src="infoData.series_img"
      :width="'80px'"
      :height="'60px'"
    />
    <div class="series-right">
      <div>
        <span class="ranking">{{ infoData.ranking }}</span>
        <span class="name">{{ infoData.series_name || "-" }}</span>
        <span class="value">{{ infoData.number | changeNum }}</span>
      </div>
      <div>
        <div :style="{width: infoData.ratio}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "SeriesCard",
  components: {
    WebpImage,
  },
  props: {
    infoData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scope>
.series-card-container {
  width: 100%;
  height: 64px;
  display: flex;
  flex-wrap: nowrap;
  .series-right {
    width: 180px;
    height: 100%;
    padding: 13px 0 18px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div:first-child {
      height: 24px;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      > .ranking {
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
      }
      > .name {
        width: 118px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        line-height: 14px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
      > .value {
        font-size: 12px;
        color: #6ff5c4;
      }
    }
    > div:last-child {
      width: 100%;
      height: 4px;
      background: #323743;
      border-radius: 2px;
      margin-top: 5px;
      > div {
        height: 100%;
        border-radius: 2px;
        background: linear-gradient(90deg, #70f6c4 0%, #438aeb 100%);
      }
    }
  }
}
</style>
