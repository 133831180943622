<template>
  <div class="brand_rectangle">
    <div class="title-div">
      <span class="title">冠军总览</span>
      <NewTabs2 ref="NewTabs2" :tabData="tabsData" @tabChange="tabChange" />
    </div>
    <div class="tab-main">
      <div class="tabs-item" v-show="tabsKey == '1'">
        <div class="tabs-item-title">
          <svg-icon icon-class="champion" style="font-size: 20px" />
          <span>单场直播冠军</span>
        </div>
        <div class="tabs-item-content">
          <div
            class="img-box"
            @click.stop="toDetail(championsData.live, 'live')"
          >
            <BaseImage
              :src="championsData.live.cover || ''"
              :default-image="defaultCoverImg"
              fit="contain"
              width="63px"
              height="84px"
              radius="4px"
              style="background-color: #21232c;"
            />
            <div class="img-shadow">
              <svg-icon icon-class="play3" style="font-size: 18px" />
            </div>
          </div>
          <div class="title-box" :title="championsData.live.title || '-'">
            <div>
              {{ championsData.live.title || "-" }}
            </div>
            <span
              class="tag"
              v-if="
                championsData &&
                championsData.live &&
                championsData.live.defending_champion_count
              "
              >已卫冕{{
                championsData.live.defending_champion_count || "-"
              }}周</span
            >
            <span
              class="tag"
              v-if="
                championsData &&
                championsData.live &&
                championsData.live.defending_champion_count == 0
              "
              >本周加冕</span
            >
          </div>
          <div class="data-box">
            <span>{{ $parent.currentTab | returnWatchText }}
              <AftTooltip :code="'qjzb11'" style="top: 0px; left: 4px"
            /></span>
            <span>{{ championsData.live.watch_count | changeNum }}</span>
          </div>
        </div>
        <div class="tabs-item-bottom">
          <div>
            <span class="fc1">主播账号</span>
            <span class="fc2 ml12">{{
              championsData.live.nickname || "-"
            }}</span>
          </div>
          <div>
            <span class="fc1">开播日期</span>
            <span class="fc3 ml12">{{
              championsData.live.publish_time || "-"
            }}</span>
          </div>
        </div>
      </div>
      <div class="tabs-item" v-show="tabsKey == '2'">
        <div class="tabs-item-title">
          <svg-icon icon-class="champion" style="font-size: 20px" />
          <span>视频播放冠军</span>
        </div>
        <div class="tabs-item-content">
          <div
            class="img-box"
            @click.stop="toDetail(championsData.aweme, 'aweme')"
          >
            <BaseImage
              :src="championsData.aweme.aweme_cover || ''"
              :default-image="defaultCoverImg"
              fit="contain"
              width="63px"
              height="84px"
              radius="4px"
              style="background-color: #21232c;"
            />
            <div class="img-shadow">
              <svg-icon icon-class="play3" style="font-size: 18px" />
            </div>
          </div>
          <div class="title-box" :title="championsData.aweme.title || '-'">
            <div>
              {{ championsData.aweme.title || "-" }}
            </div>
            <span
              class="tag"
              v-if="
                championsData &&
                championsData.aweme &&
                championsData.aweme.defending_champion_count
              "
              >已卫冕{{
                championsData.aweme.defending_champion_count || "-"
              }}周</span
            >
            <span
              class="tag"
              v-if="
                championsData &&
                championsData.aweme &&
                championsData.aweme.defending_champion_count == 0
              "
              >本周加冕</span
            >
          </div>
          <div class="data-box">
            <span
              >播放量<AftTooltip :code="'pgsp01'" style="top: 0px; left: 4px"
            /></span>
            <span>{{ championsData.aweme.play_volume_count | changeNum }}</span>
          </div>
        </div>
        <div class="tabs-item-bottom">
          <div>
            <span class="fc1">主播账号</span>
            <span class="fc2 ml12">{{
              championsData.aweme.nickname || "-"
            }}</span>
          </div>
          <div>
            <span class="fc1">发布日期</span>
            <span class="fc3 ml12">{{
              championsData.aweme.publish_time || "-"
            }}</span>
          </div>
        </div>
      </div>
      <div class="tabs-item" v-show="tabsKey == '3'">
        <div class="tabs-item-title">
          <svg-icon icon-class="champion" style="font-size: 20px" />
          <span>营销价值冠军</span>
        </div>
        <div class="tabs-item-content">
          <div class="img-box" style="height: 63px">
            <BaseImage
              :src="championsData.market_value.avatar || ''"
              :default-image="defaultAvatarImg"
              fit="cover"
              width="63px"
              height="63px"
              radius="4px"
            />
            <!-- <div class="img-shadow" style="height: 63px;">
              <svg-icon icon-class="play3" style="font-size: 18px" />
            </div> -->
          </div>
          <div class="title-box" title="">
            <div>
              {{ championsData.market_value.nickname || "-" }}
            </div>
            <span
              class="tag"
              v-if="
                championsData &&
                championsData.market_value &&
                championsData.market_value.defending_champion_count
              "
              >已卫冕{{
                championsData.market_value.defending_champion_count || "-"
              }}周</span
            >
            <span
              class="tag"
              v-if="
                championsData &&
                championsData.market_value &&
                championsData.market_value.defending_champion_count == 0
              "
              >今日加冕</span
            >
          </div>
          <div class="data-box">
            <span
              >营销价值<AftTooltip :code="'pgqt03'" style="top: 0px; left: 4px"
            /></span>
            <span>{{
              championsData.market_value.market_value | changeNum
            }}</span>
          </div>
        </div>
        <div class="tabs-item-bottom">
          <div>
            <span class="fc1">账号等级</span>
            <span class="fc3 ml12">{{
              championsData.market_value.level || "-"
            }}</span>
          </div>
          <div>
            <span class="fc1">直播&视频</span>
            <span class="fc3 ml12">{{
              championsData.market_value.aweme_live_count || "-"
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="champion-card" v-if="$parent.principal_id == ''">
      <div
        :class="
          currentHover == 1 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 1"
        @click="openProduction(championsData.live)"
        v-show="championsData.live && championsData.live.author_id"
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div
              class="img-box"
              @click.stop="toDetail(championsData.live, 'live')"
            >
              <WebpImage
                :src="championsData.live.cover"
                :scale="'3-4'"
                :width="'47px'"
                :height="'62px'"
                :radius="'4px'"
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" style="font-size: 18px" />
              </div>
            </div>
            <div>
              <span class="f16 fc26">单场直播冠军</span>
              <span
                class="f12 fc27"
                v-if="
                  championsData &&
                  championsData.live &&
                  championsData.live.defending_champion_count
                "
                >已卫冕{{
                  championsData.live.defending_champion_count || "-"
                }}周</span
              >
              <span class="f12 fc27" v-else>本周加冕</span>
              <div>
                <svg-icon icon-class="eye2" style="font-size: 22px" />
                <span class="f16 fc20">{{
                  championsData.live.watch_count | changeNum
                }}</span>
              </div>
            </div>
            <svg-icon icon-class="champion" style="font-size: 30px" />
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>开播日期</span>
              <span>{{ championsData.live.publish_time || "-" }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.live.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          currentHover == 2 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 2"
        @click="openProduction(championsData.aweme)"
        v-show="championsData.aweme && championsData.aweme.author_id"
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div
              class="img-box"
              @click.stop="toDetail(championsData.aweme, 'aweme')"
            >
              <WebpImage
                :src="championsData.aweme.aweme_cover"
                :scale="'3-4'"
                :width="'47px'"
                :height="'62px'"
                :radius="'4px'"
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" style="font-size: 18px" />
              </div>
            </div>
            <div>
              <span class="f16 fc26">视频播放冠军</span>
              <span
                class="f12 fc27"
                v-if="
                  championsData &&
                  championsData.aweme &&
                  championsData.aweme.defending_champion_count
                "
                >已卫冕{{
                  championsData.aweme.defending_champion_count || "-"
                }}周</span
              >
              <span class="f12 fc27" v-else>本周加冕</span>
              <div>
                <svg-icon icon-class="eye2" style="font-size: 22px" />
                <span class="f16 fc20">{{
                  championsData.aweme.play_volume_count | changeNum
                }}</span>
                <AftTooltip :code="'pgsp01'" style="top: 0px; left: 4px" />
              </div>
            </div>
            <svg-icon icon-class="champion" style="font-size: 30px" />
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>发布日期</span>
              <span>{{ championsData.aweme.publish_time || "-" }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.aweme.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          currentHover == 3 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 3"
        @click="openProduction(championsData.market_value)"
        v-show="
          championsData.market_value && championsData.market_value.author_id
        "
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div class="img-box hidden">
              <WebpImage
                :src="championsData.market_value.avatar"
                :scale="'1-1'"
                :width="'47px'"
                :height="'47px'"
                :radius="'4px'"
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" style="font-size: 18px" />
              </div>
            </div>
            <div>
              <span class="f16 fc26">营销价值冠军</span>
              <span
                class="f12 fc27"
                v-if="
                  championsData &&
                  championsData.market_value &&
                  championsData.market_value.defending_champion_count
                "
                >已卫冕{{
                  championsData.market_value.defending_champion_count || "-"
                }}天</span
              >
              <span class="f12 fc27" v-else>今日加冕</span>
              <div>
                <span class="f16 fc20"
                  >¥{{
                    championsData.market_value.market_value | changeNum
                  }}</span
                >
                <AftTooltip :code="'pgqt03'" style="top: 0px; left: 4px" />
              </div>
            </div>
            <svg-icon icon-class="champion" style="font-size: 30px" />
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>直播&视频</span>
              <span>{{ championsData.market_value.aweme_live_count }}</span>
            </div>
            <div class="info-item">
              <span>账号等级</span>
              <span>{{ championsData.market_value.level }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.market_value.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>


<script>
import * as vuex from "vuex";
import { getPrincipalId } from "@/utils/auth";
import Tooltip from "@/components/Tooltip/Tooltip";
import defaultAvatarImg from "@/assets/default/default1-1.png";
import defaultCoverImg from "@/assets/default/default3-4.png";
import BaseImage from "@/components/BaseImage";
import Empty from "@/components/Empty/Empty";
import NewTabs2 from "@/components/PlatformTab/NewTabs2";

const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brandSummary");
export default {
  name: "CardArea",
  components: {
    Tooltip,
    BaseImage,
    Empty,
    NewTabs2,
  },
  data() {
    return {
      defaultAvatarImg,
      defaultCoverImg,
      currentHover: 1,
      tabsData: [
        {
          label: "单场直播",
          value: "1",
        },
        {
          label: "视频播放",
          value: "2",
        },
        {
          label: "营销价值",
          value: "3",
        },
      ],
      tabsKey: "1",
    };
  },
  computed: {
    ...mapState({
      championsData: (state) => state.championsData, // 冠军数据
    }),
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    ...mapActions(["getChampions"]),
    ...mapMutations(["changeState"]),
    initData(type) {
      let params = {
        principal_id: this.$parent.principal_id || getPrincipalId(),
        platform: this.$parent.currentTab,
      };
      if (type == "map") {
      } else {
        if (this.$parent.principal_id == "") {
          this.getChampions(params);
        }
      }
    },
    tabChange(val) {
      this.tabsKey = val;
    },
    openProduction(item) {
      if (item && item.author_id) {
        this.$router.push({
          path: "/dealer/production",
          query: {
            type: this.$parent.currentTab,
            author_id: item.author_id,
          },
        });
      } else {
        this.$message.warning("未获取到authorId!");
      }
    },
    toDetail(data, type) {
      if (data && data.room_id && type == "live") {
        let url = `/liveDetail?id=${data.room_id}&platform=${this.$parent.currentTab}`;
        window.open(url);
      } else if (data && data.aweme_id && type == "aweme") {
        let url = `/videoDetail?id=${data.aweme_id}&platform=${this.$parent.currentTab}`;
        window.open(url);
      } else {
        this.$message.error("暂无ID，无法查看详情");
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.brand_rectangle {
  width: 100%;
  height: 100%;
  @include background_color("background_color2");
  position: relative;
  .title-div {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .title {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.85);
    }
  }
  .tab-main {
    width: 100%;
    padding: 0 20px;
    height: 238px;
    .tabs-item {
      width: 100%;
      height: 100%;
      background: #242732;
      border-radius: 0 0 8px 8px;
      &-title {
        display: flex;
        align-items: center;
        height: 54px;
        padding: 0 16px;
        > span {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
          margin-left: 4px;
        }
      }
      &-content {
        width: 100%;
        height: 84px;
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 16px;

        > .img-box:hover {
          > .img-shadow {
            display: flex;
          }
        }
        > .img-box.hidden:hover {
          > .img-shadow {
            display: none;
          }
        }
        > .img-box {
          width: 63px;
          height: 84px;
          border-radius: 4px;
          margin-right: 16px;
          position: relative;
          > .img-shadow {
            width: 63px;
            height: 84px;
            border-radius: 4px;
            @include background_color("background_color56");
            position: absolute;
            top: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            display: none;
          }
        }
        > .title-box {
          width: 208px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          > div {
            width: 100%;
            height: 22px;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.85);
          }
          > span {
            height: 24px;
            padding: 0 8px;
            line-height: 24px;
            text-align: center;
            background: rgba(39, 195, 70, 0.3);
            font-size: 12px;
            color: #27c346;
            margin-top: 8px;
            border-radius: 4px;
          }
        }
        > .data-box {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-left: 23px;
          width: 76px;
          > span:first-child {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.45);
            line-height: 22px;
          }
          > span:last-child {
            font-size: 20px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 28px;
            margin-top: 4px;
          }
        }
      }
      &-bottom {
        width: 100%;
        height: 84px;
        border-top: 1px solid #363a46;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px;
        .ml12 {
          margin-left: 12px;
        }
        .fc1 {
          color: rgba(255, 255, 255, 0.45);
        }
        .fc2 {
          color: #ffcc55;
        }
        .fc3 {
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
  }
}
</style>
