<template>
  <div>
    <a-affix :target="() => dom" :offsetTop="0">
      <header style="background-color: #1c1e27">
        <platform-header
          title="大区透视"
          :currentTab="currentTab"
          @tabChange="changeTab"
        />
        <div class="action" id="regional-perspective-action">
          <div>
            <a-month-picker
              :default-value="current_month"
              :format="monthFormat"
              placeholder="选择月份"
              locale="zh-cn"
              class="aft-month-picker default"
              slot="tabBarExtraContent"
              @change="pickerChange"
              v-model="videoMonth"
              :disabled-date="pickerOptions"
            >
              <div slot="suffixIcon">
                <svg-icon :icon-class="'range-picker'" />
              </div>
            </a-month-picker>
            <a-select
              :value="regionKey"
              style="width: auto; min-width: 200px; margin-left: 8px"
              :suffixIcon="suffixIcon"
              mode="multiple"
              size="large"
              placeholder="不限大区"
              dropdownClassName="region-key-select"
              @change="onChangeRegionKey"
              @deselect="onDeselect"
              @dropdownVisibleChange="onDropdownVisibleChange"
            >
              <a-select-option
                v-for="(item, index) in areaList.region_list
                  ? areaList.region_list.filter((item) => item.value)
                  : []"
                :value="item.value"
                :key="index"
                :disabled="
                  regionKey.length > 4 && !regionKey.includes(item.value)
                "
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>

          <div class="RouterButton">
            <RouterButton
              :title="'竞品区域对比'"
              :icon="'rb-region-contrast'"
              :path="'/dealer/regionContrast'"
              style="margin-right: 10px"
            />
            <!-- 10-9 金琳让屏蔽 -->
            <!-- <RouterButton
              :title="'标杆门店'"
              :icon="'rb-regional-stores'"
              :path="'/dealer/regionalStores'"
            /> -->
          </div>
        </div>
      </header>
    </a-affix>
    <!-- 短视频 -->
    <div class="card-warp">
      <div class="card">
        <div class="card-title">
          <svg-icon :icon-class="'title'" class="card-title-icon" />短视频
        </div>
        <a-tabs
          @change="changeVideoTab"
          v-model="tabVideoKey"
          class="no-background"
        >
          <a-tab-pane v-for="i in videoTab" :key="i.key" :tab="i.title">
            <!-- tab图表 -->
          </a-tab-pane>
        </a-tabs>
        <a-spin tip="" :spinning="videoTabLoading">
          <div class="chart">
            <template v-if="areaVideoDataHasDay">
              <my-chart
                ref="video_Message"
                :id="'video_Message'"
                :options="getChartOption('video')"
              />
            </template>
            <Empty v-else></Empty>
          </div>
        </a-spin>
      </div>
    </div>
    <!-- 直播 -->
    <div class="card-warp">
      <div class="card">
        <div class="card-title">
          <svg-icon :icon-class="'title'" class="card-title-icon" />直播
        </div>
        <a-tabs
          @change="changeLiveTab"
          v-model="tabLiveKey"
          class="no-background"
        >
          <a-tab-pane v-for="i in liveTab" :key="i.key">
            <template #tab>
              <div v-if="i.title === '观看人次' || i.title === '观看人数'">
                {{ i.title }}
                <AftTooltip :code="'qjzb11'" style="top: 1px; left: 4px" />
              </div>
              <div v-else>{{ i.title }}</div>
            </template>
          </a-tab-pane>
        </a-tabs>
        <a-spin tip="" :spinning="liveTabLoading">
          <div class="chart">
            <template v-if="areaLiveDataHasDay">
              <my-chart
                ref="live_Message"
                :id="'live_Message'"
                :options="getChartOption('live')"
              />
            </template>
            <Empty v-else></Empty>
          </div>
        </a-spin>
      </div>
    </div>
  </div>
</template>
<script>
import PlatformTab from "@/components/PlatformTab/PlatformTab";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import BaseFilter from "@/components/BaseFilter";
import MyChart from "@/components/MyChart/MyChart";
import RouterButton from "@/components/RouterButton/RouterButton";
import Empty from "@/components/Empty/Empty.vue";
import deepClone from "@/utils/clone-utils";
import chartLine from "../topics/chartLine.js";
import * as vuex from "vuex";
import moment from "moment";
import { isEqual } from "lodash";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "areaPerspective"
);

export default {
  name: "RegionalPerspective",
  components: {
    PlatformHeader,
    BaseFilter,
    PlatformTab,
    MyChart,
    RouterButton,
    Empty,
  },
  data() {
    return {
      currentTab: "",
      regionKey: [],
      regionKeyCache: [],
      dropdownVisible: false,
      contentKey: 1,
      tabVideoKey: "aweme_total",
      tabLiveKey: "live_dealer_total",
      tooltipTitle: "抖音主播账号授权后，可查看对应视频播放数",
      videoTab: [
        {
          title: "视频发布",
          key: "aweme_total",
        },
        {
          title: "店均发布",
          key: "aweme_dealer_avg",
        },
        {
          title: "平均播放",
          key: "aweme_play_avg",
        },
        {
          title: "活跃度",
          key: "aweme_activation",
        },
        {
          title: "视频赞评比",
          key: "aweme_like_comment_rate",
        },
        {
          title: "视频赞播比",
          key: "aweme_like_play_rate",
        },
        {
          title: "视频赞转比",
          key: "aweme_like_share_rate",
        },
      ],
      liveTab: [
        {
          title: "开播门店",
          key: "live_dealer_total",
        },
        {
          title: "直播场次",
          key: "live_total",
        },
        {
          title: "直播时长",
          key: "live_play_time_total",
        },
        {
          title: "观看人次",
          key: "live_watch_user_total",
        },
        {
          title: "活跃度",
          key: "live_activation",
        },
        {
          title: "直播场均评论",
          key: "live_comment_total_rate",
        },
        {
          title: "直播评播比",
          key: "live_comment_watch_rate",
        },
        {
          title: "直播场均点赞",
          key: "live_like_total_rate",
        },
      ],
      monthFormat: "YYYY-MM",
      current_month: moment(
        `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
        "YYYY-MM"
      ),
      loading: false,
      // columns: [],
      videoMonth: null,
      liveMonth: null,
      topMonth: null,
      videoMessageTab: {
        yData: [],
        xData: [],
      },
      liveMessageTab: {
        xData: [],
        yData: [],
      },
      tableOder: "",
      showListCount: true,
      tableList: [],

      brands_logo: this.$store.state.auth.avatar,
      brands_name: this.$store.state.auth.name,
      filteredInfo: null,
      sortedInfo: null,
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      dom: "",

      isFirstInit: true,
    };
  },
  computed: {
    ...mapState({
      areaList: (state) => state.areaList,
      areaVideoData: (state) => state.areaVideoData,
      videoTabLoading: (state) => state.videoTabLoading,
      liveTabLoading: (state) => state.liveTabLoading,
      areaLiveData: (state) => state.areaLiveData,
      dealerTopList: (state) => state.dealerTopList,
    }),
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};

      const columns = [
        {
          dataIndex: "dealer_name",
          key: "dealer_name",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "name" },
          width: "22.48%",
        },
        {
          title: "视频",
          dataIndex: "aweme_count",
          sorter: (a, b) => a.aweme_count - b.aweme_count,
          sortOrder: sortedInfo.columnKey === "aweme_count" && sortedInfo.order,
          customRender: (text) => {
            return (
              <div class="font-color7">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
          width: "8%",
        },
        {
          title: "获赞",
          dataIndex: "aweme_like_count",
          key: "aweme_like_count",
          customRender: (text) => {
            return (
              <div class="font-color3">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
        },
        {
          title: "评论",
          dataIndex: "aweme_comment_count",
          key: "aweme_comment_count",
          customRender: (text) => {
            return (
              <div class="font-color3">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
        },
        {
          title: "分享",
          dataIndex: "aweme_share_count",
          key: "aweme_share_count",
          customRender: (text) => {
            return (
              <div class="font-color3">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
        },
        {
          title: "播放",
          dataIndex: "aweme_play_count",
          key: "aweme_play_count",
          width: "8%",
          customRender: (text) => {
            return (
              <div class="font-color3">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
        },
        {
          title: "直播",
          dataIndex: "live_count",
          key: "live_count",
          sorter: (a, b) => a.live_count - b.live_count,
          sortOrder: sortedInfo.columnKey === "live_count" && sortedInfo.order,
          customRender: (text) => {
            return (
              <div class="font-color7">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
          width: "8%",
        },
        {
          title: "获赞",
          dataIndex: "live_like_count",
          key: "live_like_count",
          customRender: (text) => {
            return (
              <div class="font-color3">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
        },
        {
          title: "评论",
          dataIndex: "live_comment_count",
          key: "live_comment_count",
          customRender: (text) => {
            return (
              <div class="font-color3">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
        },
        {
          title: this.filtrate.returnWatchText(this.currentTab),
          dataIndex: "live_watch_count",
          key: "live_watch_count",
          customRender: (text) => {
            return (
              <div class="font-color3">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
        },
        {
          title: "涨粉",
          dataIndex: "fans_count_increase",
          key: "fans_count_increase",
          customRender: (text) => {
            return (
              <div class="font-color3">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
        },
        {
          title: "时长",
          dataIndex: "live_total_time",
          key: "live_total_time",
          scopedSlots: { customRender: "live_total_time" },
        },
        {
          title: "总互动量",
          dataIndex: "polling_count",
          key: "polling_count",
          customRender: (text) => {
            return (
              <div class="font-color6">
                {this.$options.filters.changeNum(text)}
              </div>
            );
          },
          width: "8%",
        },
      ];
      return columns;
    },
    isMain() {
      return this.areaList.type === 10;
    },
    areaVideoDataHasDay() {
      return this.areaVideoData[0]?.chart?.day?.length;
    },
    areaLiveDataHasDay() {
      return this.areaLiveData[0]?.chart?.day?.length;
    },
  },
  mounted() {
    // this.initData();
    this.dom = document.querySelector("#main-content");
  },
  methods: {
    ...mapActions([
      "getVideoTabTable",
      "getAreaList",
      "getLiveTabTable",
      "getDealerList",
    ]),
    ...mapMutations(["changeState"]),
    onChangeRegionKey(val) {
      if (!this.isMain && !val.length) {
        this.$message.warning("至少要保留一个大区");
        return;
      }

      this.regionKey = val;
    },
    onDeselect(val) {
      if (!this.dropdownVisible) {
        if (!this.isMain && !val.length) {
          this.$message.warning("至少要保留一个大区");
          return;
        }

        this.regionKey = this.regionKey.filter((item) => item !== val);
        this.getVideoData();
        this.getLiveData();
      }
    },
    onDropdownVisibleChange(bool) {
      this.dropdownVisible = bool;
      if (bool) {
        this.regionKeyCache = [...this.regionKey];
      } else {
        const equal = isEqual(this.regionKey, this.regionKeyCache);
        if (!equal) {
          this.getVideoData();
          this.getLiveData();
        }
      }
    },
    pickerChange(value) {
      this.getVideoData(value);
      this.getLiveData(value);
      if (!value) {
        this.$message.warning("默认选择上个月数据");
      }
    },
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().endOf("month");
    },
    // 初始化数据
    async initData() {
      await this.getAreaList();

      if (this.areaList.region_list.length) {
        this.regionKey = this.isMain
          ? []
          : [this.areaList.region_list[0].value];
      } else {
        this.$message.error("本品牌尚未配置区域信息，请您知悉");
      }
      // this.getVideoData();
      // this.getLiveData();
    },
    // 获取上个月是时间
    getLastMonth() {
      let start_time = moment()
        .month(moment().month() - 1)
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      let end_time = moment()
        .month(moment().month() - 1)
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      return { start_time, end_time };
    },
    // 切换平台-todoD
    async changeTab(key) {
      this.currentTab = key;
      this.liveTab[3].title = this.filtrate.returnWatchText(this.currentTab);
      this.contentKey = key == "douyin" ? 1 : key == "kuaishou" ? 2 : 1;
      this.$nextTick(async () => {
        if (this.isFirstInit) {
          this.isFirstInit = false;
          await this.initData();
        }
        this.getVideoData(this.videoMonth);
        this.getLiveData(this.liveMonth);
      });
    },
    // 获取短视频图表
    async getVideoData(time) {
      let start_time = "";
      let end_time = "";
      if (time) {
        start_time = `${this.$moment(time)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(time)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        let date = this.getLastMonth();
        start_time = date.start_time;
        end_time = date.end_time;
        this.videoMonth = this.$moment()
          .month(moment().month() - 1)
          .startOf("month");
      }
      let params = {
        begin_time: start_time,
        end_time: end_time,
        region_ids: this.regionKey.join(),
      };
      await this.getVideoTabTable({ type: this.contentKey, params });
      this.changeVideoTab(this.tabVideoKey);
    },
    // 切换区域
    async changeArea(id) {
      this.regionKey = id;
      this.getVideoData(this.videoMonth);
      this.getLiveData(this.liveMonth);
    },
    changeVideoTab(key) {
      this.tabVideoKey = key;
      let xData = this.areaVideoData[0]?.chart?.day || [];
      const yData = this.areaVideoData.map((item) => {
        const regionName =
          this.areaList.region_list.find((el) => el.value === item.region_id)
            ?.label || "全部大区";
        return {
          regionName,
          data: item.chart[key] || [],
        };
      });

      this.videoMessageTab = {
        xData,
        yData,
      };
    },
    // 获取直播图表数据
    async getLiveData(time) {
      let start_time = "";
      let end_time = "";
      if (time) {
        start_time = `${this.$moment(time)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(time)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        let date = this.getLastMonth();
        start_time = date.start_time;
        end_time = date.end_time;
        this.liveMonth = this.$moment()
          .month(moment().month() - 1)
          .startOf("month");
      }
      let params = {
        begin_time: start_time,
        end_time: end_time,
        region_ids: this.regionKey.join(),
      };
      await this.getLiveTabTable({ type: this.contentKey, params });
      this.changeLiveTab(this.tabLiveKey);
    },
    // 直播切换tab
    changeLiveTab(key) {
      this.tabLiveKey = key;
      let xData = this.areaLiveData[0]?.chart?.day || [];
      const yData = this.areaLiveData.map((item) => {
        const regionName =
          this.areaList.region_list.find((el) => el.value === item.region_id)
            ?.label || "全部大区";
        return {
          regionName,
          data: item.chart[key] || [],
        };
      });

      this.liveMessageTab = {
        xData,
        yData,
      };
    },
    getChartOption(type) {
      let option = deepClone(chartLine);
      let xAxisData = null;
      let yAxisData = null;
      let yAxisColor = ["#FFCC55", "#6E9FF4", "#F75C5C", "#856BF8", "#FF9026"];
      let legend = [];
      if (type === "video") {
        xAxisData = this.videoMessageTab.xData;
        yAxisData = this.videoMessageTab.yData;
      } else {
        xAxisData = this.liveMessageTab.xData;
        yAxisData = this.liveMessageTab.yData;
      }

      for (let index = 0; index < yAxisData.length; index++) {
        const item = yAxisData[index];
        legend.push(item.regionName);
        option.series[index] = {
          name: item.regionName,
          data: item.data,
          smooth: true,
          type: "line",
          symbolSize: 1,
          itemStyle: {
            color: yAxisColor[index],
          },
        };
      }
      option.xAxis.data = xAxisData;
      option.legend.data = legend;
      option.legend.icon = "line";
      return option;
    },
    //日历不能选大于当前时间的月份
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color6 {
  @include font_color("font_color6");
}
.font-color7 {
  @include font_color("font_color7");
}
.picker-box {
  position: relative;
  top: -5px;
}
.content_btn {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  text-align: center;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
}
.active_content {
  background-color: rgb(112, 136, 128);
}

.top_ads {
  display: flex;
  min-height: 128px;
  padding: 24px;
  @include background_color("background_color2");

  .img_box {
    margin: 0 16px 0 0;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    border: 4px solid rgba(0, 0, 0, 0);
    @include border_color("border_color2");
  }

  p {
    width: 130px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    @include font_color("font_color1");
  }

  .address_rg {
    margin-bottom: -16px;
    width: 100%;
    // max-width: 800px;
  }
}
.img_style {
  height: 100%;
  width: 100%;
}
.chart {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-warp {
  padding: 0 24px;
  margin-top: 18px;
  .card {
    @include background_color("background_color2");
    padding: 14px 16px;
    &-title {
      font-size: 16px;
      @include font_color("font_color2");
      margin-bottom: 12px;
      &-icon {
        @include font_color("font_color5");
        margin-right: 8px;
      }
    }
  }
}
.top_count {
  position: absolute;
  right: 27px;
  top: 20px;
  @include font_color("font_color3");
}
.action {
  display: flex;
  padding: 0px 24px 14px;
  > div {
    flex: 1;
  }
  .RouterButton {
    text-align: right;
  }
}
#regional-perspective-action {
  /deep/ .ant-select-selection--multiple .ant-select-selection__placeholder {
    color: #6d6d6d !important;
    transform: translateY(3px);
  }
  /deep/ .ant-select-selection--multiple .ant-select-selection__choice {
    margin-top: 5px;
  }
}
</style>
<style lang="less">
.table_box {
  margin-bottom: 28px;
  // .ant-table-body{
  //       background: rgba(0, 0, 0, 0.35) !important;
  // }
  // .ant-table-tbody > tr > td {
  //   color: #fff !important;
  // }
  .top_count {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    text-align: right;
    margin: 8px 6px;
  }
}
#popContainer .region-key-select {
  .ant-select-dropdown-menu-item-disabled {
    color: rgba(255, 255, 255, 0.25) !important;
  }
  .ant-select-selected-icon {
    color: transparent;
  }
}
</style>
