import Nr from '@/utils/NetworldRequest'

const activityRequest = new Nr({
    baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API
}).getAxios()

export default {
  // 获取区域报表
  fetchRegionReport(params) {
    return activityRequest.get('/jjt/activity/web/report/region', { params })
  }
}
