<template>
  <div class="wrap">
    <div class="wrap-title">
      <div>
        <span class="title">任务进度</span>
      </div>
      <div class="more-text" @click="routerTo">
        <span>任务中心</span>
        <svg-icon icon-class="arrow-down" class="arrow-icon" />
      </div>
    </div>
    <div class="wrap-main">
      <!-- 任务完成率 -->
      <div class="rate-item" style="margin-right: 8px;">
        <svg-icon class="rate-item-icon" icon-class="task" />
        <div class="rate-item-num">
          {{
            taskData.taskCompletionRate !== null
              ? taskData.taskCompletionRate
              : "-"
          }}
        </div>
        <div class="rate-item-name">
          <span>任务总完成率</span>
          <AftTooltip code="hdzb06" style="margin-left: 8px;" />
        </div>
      </div>

      <!-- 门店参与率 -->
      <div class="rate-item" style="margin-right: 6px;">
        <svg-icon class="rate-item-icon" icon-class="store3" />
        <div class="rate-item-num">
          {{
            taskData.dealerParticipationRate !== null
              ? taskData.dealerParticipationRate
              : "-"
          }}
        </div>
        <div class="rate-item-name">
          <span>门店参与率</span>
          <AftTooltip code="hdzb04" style="margin-left: 8px;" />
        </div>
      </div>

      <!-- <div class="task-pie">
        <a-progress
          type="circle"
          class="aft-progress default"
          strokeColor="#FFCA5C"
          :width="132"
          :percent="parseFloat(taskData.taskCompletionRate)"
        >
          <template #format="percent">
            <div class="number">
              {{ taskData.taskCompletionRate || percent + "%" }}
            </div>
            <div class="text">任务完成率</div>
          </template>
        </a-progress>
      </div> -->
      <!-- 任务展示 -->
      <div class="task-container">
        <div
          class="grid-container"
          ref="taskList"
          v-if="taskData.taskInfoVOList && taskData.taskInfoVOList.length > 0"
        >
          <TaskItem
            v-for="(item, index) in taskData.taskInfoVOList"
            :key="index"
            :taskData="item"
          />
        </div>

        <div class="no-task" v-else>
          <span>暂无任务</span>
          <a-button class="search-btn" @click="createActivity"
            >创建任务</a-button
          >
        </div>

        <div
          class="scroll-button left-button"
          @click="taskScroll('left')"
          v-if="taskData.taskInfoVOList && taskData.taskInfoVOList.length > 0"
        >
          <a-icon type="left" />
        </div>
        <div
          class="scroll-button right-button"
          @click="taskScroll('right')"
          v-if="taskData.taskInfoVOList && taskData.taskInfoVOList.length > 0"
        >
          <a-icon type="right" />
        </div>
      </div>
    </div>
    <CreateTaskDrawer
      :activity="$parent.currentActivityItem"
      ref="CreateTaskDrawer"
      @getList="getTaskList"
    />
  </div>
</template>

<script>
import api from "@/api/activityOverview";
import CreateTaskDrawer from "../../components/CreateTaskDrawer";
import TaskItem from "@/components/Item/TaskItem";

export default {
  name: "LeftWrap2",
  components: { CreateTaskDrawer, TaskItem },
  props: {
    currentItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    taskData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isAllRegion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // isAllRegion: false,
      // taskData: {
      //   taskCompletionRate: "",
      //   taskInfoVOList: []
      // }
    };
  },
  methods: {
    // 加载当前页面所有接口
    initData() {
      this.getAreaInfoByActivity().then((res) => {
        console.log(res);
        this.setAreaData(res);
      });
      this.getTaskList();
    },
    // 获取大区
    getAreaInfoByActivity() {
      let params = {
        activityId: this.$parent.currentActivityItem.id,
      };
      return api.getAreaInfoByActivity(params);
    },
    setAreaData(res) {
      if (res.data.code === 200) {
        if (res.data.data.type === 10) {
          this.isAllRegion = true;
        }
      } else {
        console.error("获取大区失败");
      }
    },
    // 接口请求 - 获取任务列表
    getTaskList() {
      let params = {
        activityId: this.$parent.currentActivityItem.id,
        regionId: this.regionId,
      };
      this.taskScroll("first");
      api.getTaskList(params).then((res) => {
        if (res.data.code === 200) {
          console.log("getTaskList", res.data.data);
          this.taskData = res.data.data;
        } else {
          this.$message.error(res.data.message || "获取任务列表失败");
          this.taskData = {
            taskCompletionRate: "",
            taskInfoVOList: [],
          };
        }
      });
    },
    // 创建活动 - 打开创建抽屉
    createActivity() {
      if (!this.isAllRegion) {
        this.$message.warning("当前账号不可创建任务,请联系管理员");
        return false;
      }
      this.$refs.CreateTaskDrawer.showDrawer(
        this.$parent.currentActivityItem.id
      );
    },
    // 任务滚动
    taskScroll(type) {
      let dom = this.$refs[`taskList`];
      let position = 0;

      if (type == "left") {
        position = dom.scrollLeft - 496;
      } else if (type == "right") {
        position = dom.scrollLeft + 496;
      }

      dom &&
        dom.scrollTo({
          left: position,
          behavior: "smooth",
        });
    },
    // 跳转-任务中心
    routerTo() {
      this.$parent.routerToByName("任务中心");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px 0 20px 20px;
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-bottom: 20px;
    padding-right: 20px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
    }
    .date {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.55);
      margin-left: 8px;
    }
    .more-text {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.88);
      cursor: pointer;
    }
    .arrow-icon {
      margin-left: 4px;
      color: rgba(255, 255, 255, 0.88);
      transform: rotate(-90deg);
    }
  }
  &-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 156px;

    .rate-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 26px 0;
      width: 120px;
      height: 156px;
      background: #242832;
      border-radius: 8px 8px 8px 8px;

      &-icon {
        margin: 0 0 12px;
        width: 40px;
        height: 40px;
      }

      &-num {
        margin: 0 0 4px;
        color: rgba(255, 255, 255, 0.88);
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }

      &-name {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.55);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    // .task-pie {
    //   display: flex;
    //   width: 132px;
    //   height: 100%;
    //   margin-right: 24px;
    //   padding: 12px 0;
    //   .number {
    //     font-size: 24px;
    //     font-weight: bold;
    //     color: rgba(255, 255, 255, 0.88);
    //     line-height: 32px;
    //   }
    //   .text {
    //     font-size: 12px;
    //     color: rgba(255, 255, 255, 0.55);
    //     line-height: 20px;
    //   }
    // }
    .task-container {
      flex: 1;
      position: relative;
      z-index: 1;
      padding: 0 20px 0 20px;
      // width: calc(100% - 157px);
      height: 156px;
      overflow-y: hidden;

      // 有数据时
      .grid-container {
        width: 100%;
        height: 162px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
      }
      // 无数据时
      .no-task {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: #242832;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > span {
          font-size: 14px;
          color: #8890a6;
          line-height: 17px;
          margin-bottom: 12px;
        }
        .search-btn {
          @include border_color("border_color4");
          @include font_color("font_color17");
          @include background_color("background_color13");
          width: 96px;
          height: 40px;
          border-radius: 8px;
        }
      }

      &:hover {
        .scroll-button {
          opacity: 1;
        }
      }

      .scroll-button {
        display: inline-block;
        position: absolute;
        top: 58px;
        width: 40px;
        height: 40px;
        background: #2b2f3b;
        box-shadow: 0px 3px 12px 4px rgba(0, 0, 0, 0.12),
          0px 2px 7px 0px rgba(0, 0, 0, 0.2),
          0px 1.5px 4px -2px rgba(0, 0, 0, 0.32);
        border-radius: 8px;
        color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        opacity: 0;
        &:hover {
          color: rgba(255, 255, 255, 0.88);
        }
      }
      .left-button {
        left: 0px;
      }
      .right-button {
        right: 12px;
      }
    }
  }
}
</style>
