var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-content4"},[_c('div',{staticClass:"tabs-content4-main"},[_vm._m(0),(_vm.regionData && _vm.regionData.length > 0)?_c('div',{staticClass:"chart-div"},[_c('div',{staticClass:"chart-div-left"},[_c('div',{staticClass:"table-container"},[_vm._m(1),_c('div',{staticClass:"table-container-body"},_vm._l((_vm.regionData),function(item,index){return _c('div',{key:index,class:[
                'table-item',
                _vm.currentLabel == item.area_name ? 'active' : '',
              ]},[_c('div',[_vm._v(_vm._s(item.area_name || "暂无大区"))]),_c('div',[_vm._v(_vm._s(_vm._f("changeNum")(item.aweme_clue)))]),_c('div',[_vm._v(_vm._s(_vm._f("changeNum")(item.live_clue)))]),_c('div',[_vm._v(_vm._s(_vm._f("changeNum")(item.private_letter)))]),_c('div',[_vm._v(_vm._s(_vm._f("changeNum")(item.sales_count)))]),_c('div',[_vm._v(_vm._s(_vm._f("changeNum")(item.avg_dealer_sales)))]),_c('div',[_c('span',{staticStyle:{"margin":"0 6px 0 0"}},[_vm._v(_vm._s(_vm._f("numRate")(item.day_chain)))]),(item.day_chain >= 0)?_c('svg-icon',{staticStyle:{"font-size":"10px"},attrs:{"icon-class":"caret-up"}}):_vm._e(),(item.day_chain < 0)?_c('svg-icon',{staticStyle:{"font-size":"10px"},attrs:{"icon-class":"caret-down"}}):_vm._e()],1)])}),0)])]),_c('div',{staticClass:"chart-div-right"},[(_vm.chartShow)?_c('MyChart',{attrs:{"options":_vm.getPieOption(_vm.pieData),"id":'pie-chart-2'}}):_vm._e()],1)]):_c('div',{staticClass:"empty-box"},[_c('Empty',{attrs:{"title":"暂无数据"}})],1)]),_c('div',{staticClass:"chart-div-bottom"},[_vm._m(2),_c('div',{staticClass:"chart-div-main"},[(_vm.chartShow)?_c('MyChart',{attrs:{"options":_vm.getLineOption(_vm.lineData),"id":'line-chart-2'}}):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-div"},[_c('span',{staticClass:"title"},[_vm._v("大区对比")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container-header"},[_c('div',[_vm._v("大区")]),_c('div',[_vm._v("视频线索")]),_c('div',[_vm._v("直播线索")]),_c('div',[_vm._v("私信线索")]),_c('div',[_vm._v("线索总数")]),_c('div',[_vm._v("店均")]),_c('div',[_vm._v("店均变化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-div"},[_c('span',{staticClass:"title"},[_vm._v("门店分级")])])
}]

export { render, staticRenderFns }