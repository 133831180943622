<template>
  <div class="fans-portrait-container">
    <div class="data-box">
      <div class="card-title">数据概览</div>
      <div class="card-container">
        <div
          class="card-container-item"
          v-for="item in targetData"
          :key="item.targetCountKey"
          @click="currentTarget = item"
        >
          <span class="label">{{ item.targetName }}</span>
          <span class="number">{{ item.targetCount | changeNum }}</span>
        </div>
      </div>
    </div>
    <div class="row" v-if="portraitData.interest_distributions">
      <div class="row-item">
        <div class="row-item-title">兴趣分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(portraitData.interest_distributions, '1')"
            :id="'bar-chart-1'"
          />
        </div>
      </div>
      <div class="row-item">
        <div class="row-item-title">年龄层分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(portraitData.age_distributions)"
            :id="'pie-chart-1'"
          />
        </div>
      </div>
    </div>
    <div
      class="row"
      :style="{
        'grid-template-columns': `repeat(${
          $route.query.type == 'douyin' ? 3 : 2
        }, 1fr)`,
      }"
      v-if="portraitData.interest_distributions"
    >
      <div class="row-item" v-if="$route.query.type == 'douyin'">
        <div class="row-item-title">活跃度分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(portraitData.active_days_distributions)"
            :id="'pie-chart-2'"
          />
        </div>
      </div>
      <div class="row-item">
        <div class="row-item-title">性别分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(portraitData.gender_distributions)"
            :id="'pie-chart-3'"
          />
        </div>
      </div>
      <div class="row-item">
        <div class="row-item-title">Top10 省份分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(portraitData.province_distributions, '2')"
            :id="'bar-chart-2'"
          />
        </div>
      </div>
    </div>
    <div class="empty-container" v-else>
      <Empty :title="emptyTitle" />
    </div>
  </div>
</template>

<script>
const targetData = {
  2: [
    {
      targetName: "粉丝增量",
      targetCountKey: "total_add_fans_count",
      targetCount: "",
      targetChartKey: "total_add_fans_chart",
      targetChart: [],
    },
  ],
};
import api from "@/api/production";
import Empty from "@/components/Empty/Empty";
import MyChart from "@/components/MyChart/MyChart";
import chartPie from "../options/chartPie.js";
import chartBar1 from "../options/chartBar1.js";
import chartBar2 from "../options/chartBar2.js";
import deepClone from "@/utils/clone-utils";

export default {
  components: { MyChart, Empty },
  data() {
    return {
      chartShow: true,
      portraitData: {},
      targetData: [],
      currentTarget: null,
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    emptyTitle() {
      let parentParams = this.getParams();
      if (parentParams.type == "douyin") {
        return "账号需授权且粉丝数量大于 100 才能查看数据";
      } else {
        return "账号需授权且粉丝数量大于 50 才能查看数据";
      }
    },
  },
  methods: {
    // 加载当前页面接口
    initData() {
      this.getFansDataChart();
      this.getFansPortraitByTime();
    },
    // 粉丝数据概览
    getFansDataChart() {
      let parentParams = this.getParams();
      console.log("parentParams", parentParams);
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
          date_type: parentParams.dateType,
        },
      };
      // 清空targetData
      this.targetData = [];
      this.currentTarget = null;
      api.getFansDataChart(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          // 按月和周拿到当前需要展示的指标
          let defaultData = deepClone(targetData)[parentParams.dateType];
          console.log(targetData);
          console.log(parentParams.dateType);
          console.log(defaultData);
          // 补全接口数据到targetData
          this.targetData = defaultData.map((item) => {
            return {
              ...item,
              targetCount: res.data.data[item.targetChartKey][0].value,
              targetChart: res.data.data[item.targetChartKey].reverse(),
            };
          });
          this.currentTarget = this.targetData[0];
        } else {
          console.error("获取粉丝数据概览失败");
        }
      });
    },

    // 获取粉丝画像
    getFansPortraitByTime() {
      let parentParams = this.getParams();
      console.log("parentParams", parentParams);
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
          date_type: parentParams.dateType,
        },
      };
      api.getFansPortraitByTime(body).then((res) => {
        console.log("getFansPortraitByTime", res);
        const { code, data } = res.data;
        if (code == 0) {
          this.portraitData = data || {};
        } else {
          this.portraitData = {};
        }
      });
    },

    getParams() {
      return this.$parent.getFansParams();
    },

    getBarOption(data, type) {
      let option = deepClone(type == "1" ? chartBar1 : chartBar2);
      if (data && data.length > 0) {
        let xData = data.map((item) => {
          return item.item;
        });
        let sData = data.map((item) => {
          return item.value;
        });
        if (type == "1") {
          option.xAxis[0].data = xData;
          option.series[0].data = sData;
        } else {
          option.yAxis[0].data = xData.reverse();
          option.series[0].data = sData.reverse();
        }
      }
      return option;
    },
    getPieOption(data) {
      let option = deepClone(chartPie);
      if (data && data.length > 0) {
        let newData = data.map((item) => {
          return {
            name: item.item,
            value: item.value,
          };
        });
        option.series[0].data = newData;
      }
      return option;
    },

    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.fans-portrait-container {
  width: 100%;
  height: 976px;
  margin-bottom: 20px;
  .data-box {
    width: 100%;
    height: 162px;
    margin-bottom: 16px;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 20px;

    .card-title {
      height: 24px;
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
      line-height: 24px;
    }

    .card-container {
      width: 100%;
      height: 78px;
      display: flex;
      margin-top: 20px;
      &-item {
        width: 240px;
        height: 78px;
        background: #242832;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 12px;
        .label {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.55);
          line-height: 22px;
          margin-bottom: 4px;
        }
        .number {
          font-size: 20px;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.88);
          line-height: 28px;
        }
      }
    }
  }
  .row {
    width: 100%;
    height: 372px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    &-item {
      background: #2b2f3b;
      border-radius: 16px;
      padding-top: 20px;
      height: 100%;
      &-title {
        padding: 0 20px;
        width: 100%;
        height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
        line-height: 24px;
      }
      &-chart {
        width: 100%;
        height: calc(100% - 24px);
      }
    }
  }
  .empty-container {
    width: 100%;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>