<template>
  <div>
    <!-- 头部标题内容 -->
    <header>
      <div class="h-title">话题观察</div>
      <div class="title_box">
        <div>
          <a-month-picker
            :placeholder="calendarTips"
            class="aft-month-picker default"
            @change="onChangeDate"
            :disabled-date="pickerOptions"
            v-model="month"
            :open="openCalendar"
            @focus="
              () => {
                openCalendar = true;
              }
            "
          >
            <template slot="renderExtraFooter">
              <a-button type="link" @click="selectTimeRank(1)">
                近30天
              </a-button>
              <a-button type="link" @click="selectTimeRank(2)">
                近60天
              </a-button>
              <a-button type="link" @click="selectTimeRank(3)">
                近90天
              </a-button>
            </template>
            <div slot="suffixIcon">
              <svg-icon :icon-class="'range-picker'"/>  
            </div>
          </a-month-picker>
        </div>
        <div class="select_box">
          <a-select
            mode="multiple"
            show-search
            v-model="topics"
            placeholder="选择话题搜索"
            style="width: 360px"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            @change="handleChange"
            :max-tag-count="1"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option
              v-for="d in topicData"
              :key="d.topic_id"
              :disabled="topics.length >= 5 && topics.indexOf(d.topic_id) == -1"
            >
              {{ d.desc }}
            </a-select-option>
          </a-select>
          <!-- </div> -->
        </div>
      </div>
    </header>
    <!-- tab图表 -->
    <a-spin tip="" :spinning="loadingChart">
      <div class="chart">
        <my-chart
          ref="topic_release"
          :id="'topic_release'"
          :options="getChartOption()"
        />
        <div v-show="topicObserveData.xData.length == 0" class="empty-text font-color3">
          暂无数据
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartLine from "../chartLine.js";
import echarts from "echarts";
import Cookies from "js-cookie";
import * as vuex from "vuex";
import moment from "moment";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "topicMarketing"
);
import apiRq from "@/api/topicMarketing";
export default {
  name: "Observe",
  components: {
    MyChart,
  },
  data() {
    // this.searchTopic = debounce(this.searchTopic, 800);
    return {
      topics: [],
      // brand: [Cookies.get("principal_id")],
      dateSize: 30,
      month: null,
      calendarTips: "选择月份",
      openCalendar: false, // 控制弹层是否出现
      rankOrMonth: 1, // 1为月份，2为近天数选择
      lastTime: "",
      topicObserveData: {
        xData: [],
        yData: [],
        legend: [],
      },
      isChartReady: false,
      saveTooltip: "",
      lineTimeDot: "",
    };
  },
  watch: {
    topicChartData(val) {
      this.isChartReady = true;
    },
  },
  computed: {
    ...mapState({
      fetching: (state) => state.fetching,
      topicData: (state) => state.topicData,
      topicChartData: (state) => state.topicChartData,
      loadingChart: (state) => state.loadingChart,
    }),
  },
  created() {
    window.gotoDetailPage = this.gotoDetailPage;
    window.gotoVideoDetailPage = this.gotoVideoDetailPage;
  },
  methods: {
    ...mapActions(["getTopics", "getTopicObserve", "getObserveTopic"]),
    // 跳转详情
    gotoDetailPage(id, name) {
      this.$router.push(`/topic/detail?id=${id}&name=${name}`);
    },
    // todoD
    gotoVideoDetailPage(id) {
      let url = `/videoDetail?id=${id}&platform=douyin`;
      // this.$router.push(`/videoDetail?id=${item.aweme_id}`);
      window.open(url);
    },
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().endOf("month");
    },
    //  选择品牌
    selectBrand() {
      this.topics = [];
    },
    // 话题输入搜索
    // searchTopic(value) {
    //   // if(!!this.brand.length) return;
    //   this.getTopics();
    // },
    handleChange(value) {
      let time = "";
      if (this.topics.length == 5) {
        this.$message.warning("最多可选择五个话题");
      }
      if (this.topics.length > 5) {
        return;
      }
      if (value.length < 1) {
        this.$message.warning("默认选择当前品牌");
        // this.brand = [Cookies.get("principal_id")];
      }
      if (this.rankOrMonth == 2) {
        time = this.lastTime;
      } else {
        time = this.disposeTimeRange(this.month);
      }
      this.getTopicData(time);
    },
    // 时间范围数据处理
    disposeTimeRange(value) {
      let start_time = "";
      let end_time = "";
      if (!!value) {
        start_time = `${this.$moment(value)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(value)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        start_time = moment()
          .month(moment().month())
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        end_time = moment()
          .month(moment().month())
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        this.month = this.$moment()
          .month(moment().month())
          .startOf("month");
      }
      return { start_time, end_time };
    },
    // 选择时间范围
    selectTimeRank(key) {
      this.month = null;
      this.openCalendar = false;
      this.rankOrMonth = 2;
      let last = "";
      switch (key) {
        case 1:
          this.calendarTips = "近30天";
          last = this.$moment()
            .subtract("day", 29)
            .format("YYYY-MM-DD HH:mm:ss");
          break;
        case 2:
          this.calendarTips = "近60天";
          last = this.$moment()
            .subtract("day", 59)
            .format("YYYY-MM-DD HH:mm:ss");
          break;
        case 3:
          this.calendarTips = "近90天";
          last = this.$moment()
            .subtract("day", 89)
            .format("YYYY-MM-DD HH:mm:ss");
          break;
        default:
          break;
      }
      this.lastTime = last;
      let date = {
        end_time: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        start_time: last,
      };
      this.initData(date);
    },
    // 获取话题数据
    async getTopicData(time) {
      let data = {
        start_time: time.start_time,
        end_time: time.end_time,
        topic_ids: this.topics,
      };
      await this.getTopicObserve({ data });
      this.disposeChartData();
    },
    /*选择月份
     * 重新获取话题列表
     * 拿第一个话题
     */
    onChangeDate(value) {
      this.topicObserveData = {
        xData: [],
        yData: [],
        legend: [],
      };
      this.openCalendar = false;
      this.rankOrMonth = 1;
      if (!value) {
        this.$message.warning("默认选择本月数据");
      }
      this.initData(value);
    },
    // diff chart data
    disposeChartData() {
      let xData = [];
      let yData = [];
      let legend = [];
      let topicId = [];
      if (this.topicChartData.length > 0) {
        xData = this.topicChartData[0].day_data.map(({ time }) => time);
        this.topicChartData.forEach((item) => {
          let count = item.day_data.map(({ aweme_count }) => aweme_count);
          yData.push(count);
        });
        legend = this.topicChartData.map(({ desc }) => desc);
        topicId = this.topicChartData.map(({ topic_id }) => topic_id);
      }

      this.topicObserveData = {
        xData,
        yData,
        legend,
        topicId,
      };
    },
    getChartOption() {
      let option = deepClone(chartLine);
      let xAxisData = null;
      let yAxisData = null;
      let yAxisColor = ["#0185e7", "#c85502", "#01b101", "#9d2333", "#a906f8"]; //蓝色，橙色，绿色，红色，紫色
      let yAxisColorA = [
        "1,133,231",
        "200,85,2",
        "1,177,1",
        "157,35,51",
        "169,6,248",
      ]; //蓝色，橙色，绿色，红色，紫色
      let legend = [];
      let areaStyle = null;
      let tooltip = {
        trigger: "item", // 'item'时，显示单项数据内容;'axis'时，显示多项数据内容数组
        // backgroundColor :'rgba(78,93,225,0.75)',
        borderWidth: 2,
        // borderColor :'rgba(78,93,225,0.75)',
        axisPointer: {
          type: "cross",
          // label: {
          //   backgroundColor: "rgba(78,93,225,0.75)",
          //   borderWidth: 1,
          //   borderColor: "rgba(78,93,225,0.75)",
          // },
        },
        // position:function(point,params,dom,rect,size){
        //   return [point[0]+5,point[1]+5]
        // },
        position: function (point, params, dom, rect, size) {
          // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
          // 提示框位置
          var x = 0; // x坐标位置
          var y = 0; // y坐标位置

          // 当前鼠标位置
          var pointX = point[0];
          var pointY = point[1];

          // 外层div大小
          // var viewWidth = size.viewSize[0];
          // var viewHeight = size.viewSize[1];

          // 提示框大小
          var boxWidth = size.contentSize[0];
          var boxHeight = size.contentSize[1];

          // boxWidth > pointX 说明鼠标左边放不下提示框
          if (boxWidth > pointX) {
            x = 5; // 自己定个x坐标值，以防出屏
            y -= 20; // 防止点被覆盖住，可根据情况自行调节
          } else {
            // 左边放的下
            x = pointX - boxWidth - 15;
          }

          // boxHeight > pointY 说明鼠标上边放不下提示框
          if (boxHeight + 10 > pointY) {
            y = pointY + 15;
          } else if (boxHeight > pointY) {
            y = 0;
          } else {
            // 上边放得下
            y += pointY - boxHeight;
          }
          return [x, y];
        },
        triggern: "mousemove|click", //触发方式 移入或点击
        enterable: true, // 鼠标可移入tooltip中 必须
        formatter: (params, ticket, callback) => {
          if (params.value == 0) {
            this.lineTimeDot = 0;
            // return;
            return '<div class="font-color3">暂无数据</div>';
          } else {
            // let data = params.name;
            // data =
            //   data +
            //   '<div style="cursor: pointer;color:#fff" onclick="gotoDetailPage(\'' +
            //   params.seriesName +
            //   "')\">" +
            //   params.seriesName +
            //   ":" +
            //   params.value +
            //   "</div>";
            // <p onclick=gotoDetailPage(\''+date.seriesId+'\',\''+principal_name+'\')>'
            this.getTopicsDetail(params).then((res) => {
              // console.log('使用res',res)
              callback(ticket, res);
            });

            return "loading";
            // if (this.lineTimeDot != dot) {
            //   this.lineTimeDot = dot;
            //   // _.debounce(() => {
            //     console.log("点的数据", params);
            //     this.getTopicsDetail(params).then((res) => {
            //       console.log("回调callback", data);
            //       setTimeout(function () {
            //         callback(ticket, data);
            //       }, 100);
            //       // console.log("response返回", res);
            //     });
            //   // }, 500)();
            //   return "Loading...";
            // }
          }
        },
      };
      //直播互动
      xAxisData = this.topicObserveData.xData;
      yAxisData = this.topicObserveData.yData;
      legend = this.topicObserveData.legend;
      areaStyle = {};
      let topicsID = this.topicObserveData.topicId;
      option.xAxis.data = xAxisData;
      option.tooltip = tooltip;
      if (yAxisData) {
        Object.keys(yAxisData).forEach((key, index) => {
          option.series[index] = {
            name: legend[index],
            data: yAxisData[key],
            smooth: true,
            type: "line",
            symbolSize: 5,
            id: topicsID[index],
            itemStyle: {
              color: yAxisColor[index],
            },
            areaStyle: areaStyle
              ? this.setAreaStyle(yAxisColorA, index)
              : areaStyle,
          };
        });
      }
      option.legend.data = legend;
      return option;
    },
    //设置图表基础面积
    setAreaStyle(arr, index) {
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: `rgba(${arr[index]}, .5)`,
          },
          {
            offset: 0.2,
            color: `rgba(${arr[index]}, .2)`,
          },
          {
            offset: 1,
            color: `rgba(${arr[index]}, .1)`,
          },
        ]),
      };
    },
    getTopicsDetail(date) {
      return new Promise((resolve, reject) => {
        let data = {
          topic_id: date.seriesId,
          time: date.name,
        };
        let dot = this.$moment(date.name).valueOf() + Number(date.seriesId); // 唯一标示，自锁
        if (dot != this.lineTimeDot) {
          this.lineTimeDot = dot;
          this.saveTooltip = '<div></div>'
          _.debounce(() => {
            apiRq.getObserveTopic({ params: data }).then((res) => {
              let res_data = res.data.data;
              if (res_data) {
                let cTooltip = this.toHtmlTopic(date, res_data);
                this.saveTooltip = cTooltip;
                resolve(cTooltip);
              } else {
                reject("<div></div>");
              }
              // return cTooltip
            });
          }, 500)();
        }else{
          console.log("查看判断", dot != this.lineTimeDot);
          resolve(this.saveTooltip);
        }
      });
    },
    // getTopicsDetail: _.debounce(function (date) {
    //   let dot = this.$moment(date.name).valueOf() + Number(date.seriesId); // 唯一标示，自锁
    //   if (dot != this.lineTimeDot) {
    //     return new Promise((resolve, reject) => {
    //       this.lineTimeDot = dot;
    //       let data = {
    //         topic_id: date.seriesId,
    //         time: date.name,
    //       };
    //       apiRq.getObserveTopic({ params: data }).then((res) => {
    //         let res_data = res.data.data;
    //         let cTooltip = this.toHtmlTopic(date, res_data);
    //         this.saveTooltip = cTooltip
    //         // console.log('最终渲染：',cTooltip)
    //         resolve(cTooltip);
    //         // return cTooltip
    //       });
    //     });
    //   } else {
    //     return new Promise((resolve, refect) => {
    //       resolve(this.saveTooltip);
    //     });
    //   }
    // }, 500),
    toHtmlTopic(date, value) {
      let the_day = date.name;
      let the_week = this.$moment(date).format("dddd");
      let topic_name = "#" + date.seriesName;
      let tooltip_list = "";
      let list = value.aweme_infos;
      let principal_name = date.seriesName.split(":")[0];
      for (let i = 0; i < list.length; i++) {
        tooltip_list += `<p>
            <span style="display: inline-block;width: 16px;height: 16px;border-radius: 50%;border: 1px solid #fff;line-height: 16px;text-align: center;margin-right: 4px;">${
              i + 1
            }</span>
              <a style="color:#fff;" onclick="gotoVideoDetailPage('${
                list[i].aweme_id
              }')">${list[i].aweme_title}</a>
              <span style="display: inline-block;padding-left:8px"><a-icon type="heart" style="color: red" />${
                list[i].aweme_digg_count
              }
              </span>
        </p>`;
      }
      let cTooltip =
        `<div>${the_day}<span style="display:inline-block;margin-left:10px">${the_week}</span></div>
          <a style="cursor: pointer;color:#fff;display:inline-block;padding:4px 0;" onclick="gotoDetailPage('${date.seriesId}','${principal_name}')">${topic_name}
          <a-icon type="video-camera"/> ${value.topic_aweme_count}
        </a>` + tooltip_list;
      return cTooltip;
    },
    // 初始化数据
    async initData(value) {
      let data = value;
      let params = {};
      if (this.rankOrMonth == 2) {
        params = { start_time: value.start_time, end_time: value.end_time };
      } else {
        data = this.disposeTimeRange(value);
        params = { start_time: data.start_time, end_time: data.end_time };
      }
      await this.getTopics({ params });
      if (this.topicData.length) {
        this.topics = [this.topicData[0].topic_id]; // 选择框默认选中第一个
      } else {
        this.$message.error("未查到相关话题信息！");
        this.topicObserveData = {
          xData: [],
          yData: [],
          legend: [],
        };
      }
      this.getTopicData(data); // 默认上个月
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style lang="less" scoped>
.title_box {
  display: flex;
  margin-left: 32px;
  .select_box {
    display: flex;
    margin-left: 20px;
  }
}
.empty-text {
  padding-top: 20px;
  text-align: center;
}
.chart {
  height: 300px;
  padding-left: 32px;
  margin-top: 16px;
}
</style>