import filters from "@/filters";
import deepClone from "@/utils/clone-utils";
import { h } from "vue";

const noteType = {
  1: "图文",
  2: "视频",
};
export const tabsData1 = [
  {
    label: "大区数据",
    value: "0",
  },
  {
    label: "门店数据",
    value: "1",
  },
  {
    label: "短视频数据",
    value: "2",
  },
  {
    label: "直播数据",
    value: "3",
  },
  {
    label: "笔记数据",
    value: "5",
  },
];

export const tabsData2 = [
  {
    label: "大区数据",
    value: "0",
  },
  {
    label: "战区数据",
    value: "4",
  },
  {
    label: "门店数据",
    value: "1",
  },
  {
    label: "短视频数据",
    value: "2",
  },
  {
    label: "直播数据",
    value: "3",
  },
  {
    label: "笔记数据",
    value: "5",
  },
];

// 0: 大区排名 1: 门店排名 2: 短视频 3: 直播 4: 战区
const settingData_0 = [
  {
    type: "text",
    text: "剔除刷量视频",
    value: "isDropBrushAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除挂播直播",
    value: "isDropHangLive",
    bool: false,
  },
  {
    type: "text",
    text: "剔除已删除/隐藏视频",
    value: "isDropShutAweme",
    bool: false,
  },
  // {
  //   type: 'text',
  //   text: "剔除播放低于500的视频",
  //   value: "isDropFiveHundredAweme",
  //   bool: false,
  // },
  // {
  //   type: 'text',
  //   text: "剔除时长低于30min的直播",
  //   value: "isDropThirtyMinLive",
  //   bool: false,
  // },
  {
    type: "text",
    text: "剔除非模板视频",
    value: "isDropNonGuideshootAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除模板视频",
    value: "isDropGuideshootAweme",
    bool: false,
  },
];
const settingData_1 = [
  {
    type: "text",
    text: "剔除刷量视频",
    value: "isDropBrushAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除挂播直播",
    value: "isDropHangLive",
    bool: false,
  },
  {
    type: "text",
    text: "剔除已删除/隐藏视频",
    value: "isDropShutAweme",
    bool: false,
  },
  // {
  //   type: 'text',
  //   text: "剔除播放低于500的视频",
  //   value: "isDropFiveHundredAweme",
  //   bool: false,
  // },
  // {
  //   type: 'text',
  //   text: "剔除时长低于30min的直播",
  //   value: "isDropThirtyMinLive",
  //   bool: false,
  // },
  {
    type: "text",
    text: "剔除非模板视频",
    value: "isDropNonGuideshootAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除模板视频",
    value: "isDropGuideshootAweme",
    bool: false,
  },
];
const settingData_2 = [
  {
    type: "text",
    text: "剔除刷量视频",
    value: "isDropBrushAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除已删除/隐藏视频",
    value: "isDropShutAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除非模板视频",
    value: "isDropNonGuideshootAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除模板视频",
    value: "isDropGuideshootAweme",
    bool: false,
  },
  {
    type: "input",
    text: "剔除播放低于",
    text2: "的视频",
    inputValue: 500,
    inputType: "number",
    inputRules: {
      required: true,
      message: "请输入数值",
      trigger: "blur",
    },
    value: "isDropFiveHundredAweme",
    definedValue: "isDropUserDefinedAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除非活动模板视频",
    value: "isDropNonActivityGuideshootAweme",
    bool: false,
  },
];
const settingData_3 = [
  {
    type: "text",
    text: "剔除挂播直播",
    value: "isDropHangLive",
    bool: false,
  },
  {
    type: "input",
    text: "剔除时长低于",
    inputType: "number",
    inputValue: 30,
    inputRules: {
      required: true,
      message: "请输入数值",
      trigger: "blur",
    },
    text2: "min的直播",
    value: "isDropThirtyMinLive",
    definedValue: "isDropUserDefinedLive",
    bool: false,
  },
  {
    type: "input",
    text: "剔除观看低于",
    inputType: "number",
    inputValue: "",
    inputRules: {
      required: true,
      message: "请输入数值",
      trigger: "blur",
    },
    text2: "的直播",
    value: "isDropUserDefinedLiveWatch",
    definedValue: "isDropUserDefinedLiveWatch",
    bool: false,
  },
];
const settingData_4 = [
  {
    type: "text",
    text: "剔除刷量视频",
    value: "isDropBrushAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除挂播直播",
    value: "isDropHangLive",
    bool: false,
  },
  {
    type: "text",
    text: "剔除已删除/隐藏视频",
    value: "isDropShutAweme",
    bool: false,
  },
  // {
  //   type: 'text',
  //   text: "剔除播放低于500的视频",
  //   value: "isDropFiveHundredAweme",
  //   bool: false,
  // },
  // {
  //   type: 'text',
  //   text: "剔除时长低于30min的直播",
  //   value: "isDropThirtyMinLive",
  //   bool: false,
  // },
  {
    type: "text",
    text: "剔除非模板视频",
    value: "isDropNonGuideshootAweme",
    bool: false,
  },
  {
    type: "text",
    text: "剔除模板视频",
    value: "isDropGuideshootAweme",
    bool: false,
  },
];
const settingData_5 = [];

export function handleHeaderCell(column, data) {
  if (
    data.sortKey.sort_field == column.dataIndex &&
    data.sortKey.sort == "asc"
  ) {
    return {
      class: "sort-select-asc",
    };
  } else if (
    data.sortKey.sort_field == column.dataIndex &&
    data.sortKey.sort == "desc"
  ) {
    return {
      class: "sort-select-desc",
    };
  }
}
export const columns_0 = (data) => [
  {
    title: "排序",
    dataIndex: "sort",
    key: "sort0",
    align: "center",
    width: 86,
    fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "区域",
    dataIndex: "regionName",
    key: "regionName0",
    align: "left",
    width: 120,
    fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "门店数",
    dataIndex: "dealerCount",
    key: "dealerCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    // customRender: function(text, record, index) {
    //   return filters.changeNumNoRound(text) || "-";
    // },
    scopedSlots: { customRender: "dealerCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "门店参与率",
    dataIndex: "dealerStoreRate",
    key: "dealerStoreRate0",
    align: "left",
    width: 120,
    filterable: true,
    // customHeaderCell:function(column){
    //   console.log(column,'column')
    // },
    // customRender: function(text, record, index) {
    //   console.log(record)
    //   return <span style="color: #ffca5c">{filters.numRate2(text, 1) || "-"}</span>
    // },
    scopedSlots: { customRender: "dealerStoreRate" },
    sorter: true,
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "任务总完成率",
    dataIndex: "taskFinishRate",
    key: "taskFinishRate0",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "taskFinishRate" },
    customHeaderCell: (column) => {
      console.log(data);
      console.log(column);
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "粉丝总量",
    dataIndex: "fansCount",
    key: "fansCount0",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "fansCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "活动期间增粉",
    dataIndex: "addFansCount",
    key: "addFansCount0",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "addFansCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播场次",
    dataIndex: "liveCount",
    key: "liveCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播时长（分钟）",
    dataIndex: "liveDuration",
    key: "liveDuration0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveDuration" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "直播观看",
    slots: { title: "customTitle" },
    dataIndex: "liveWatchCount",
    key: "liveWatchCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveWatchCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播互动",
    dataIndex: "liveInteractCount",
    key: "liveInteractCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveInteractCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播增粉",
    dataIndex: "liveFansAddCount",
    key: "liveFansAddCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveFansAddCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频发布",
    dataIndex: "awemePublishCount",
    key: "awemePublishCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemePublishName" },
    scopedSlots: { customRender: "awemePublishCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频播放",
    dataIndex: "awemeWatchCount",
    key: "awemeWatchCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeWatchName" },
    scopedSlots: { customRender: "awemeWatchCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频互动",
    dataIndex: "awemeInteractCount",
    key: "awemeInteractCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeInteractName" },
    scopedSlots: { customRender: "awemeInteractCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频点赞",
    dataIndex: "awemeLikeCount",
    key: "awemeLikeCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeLikeName" },
    scopedSlots: { customRender: "awemeLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频总点赞",
    dataIndex: "awemeTotalLikeCount",
    key: "awemeTotalLikeCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeTotalLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频拇指赞",
    dataIndex: "awemeThumbLikeCount",
    key: "awemeThumbLikeCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeThumbLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频爱心赞",
    dataIndex: "awemeLoveLikeCount",
    key: "awemeLoveLikeCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeLoveLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频评论",
    dataIndex: "awemeCommentCount",
    key: "awemeCommentCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeCommentName" },
    scopedSlots: { customRender: "awemeCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频分享",
    dataIndex: "awemeShareCount",
    key: "awemeShareCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeShareName" },
    scopedSlots: { customRender: "awemeShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记发布",
    dataIndex: "noteCount",
    key: "noteCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记发布",
    dataIndex: "awemeNoteCount",
    key: "awemeNoteCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteName" },
    scopedSlots: { customRender: "awemeNoteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记点赞",
    dataIndex: "awemeNoteLikeCount",
    key: "awemeNoteLikeCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteLikeName" },
    scopedSlots: { customRender: "awemeNoteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记评论",
    dataIndex: "awemeNoteCommentCount",
    key: "awemeNoteCommentCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteCommentName" },
    scopedSlots: { customRender: "awemeNoteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记分享",
    dataIndex: "awemeNoteShareCount",
    key: "awemeNoteShareCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteShareName" },
    scopedSlots: { customRender: "awemeNoteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记收藏",
    dataIndex: "awemeNoteCollectCount",
    key: "awemeNoteCollectCount0",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteCollectName" },
    scopedSlots: { customRender: "awemeNoteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记发布",
    dataIndex: "imgNoteCount",
    key: "imgNoteCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记点赞",
    dataIndex: "imgNoteLikeCount",
    key: "imgNoteLikeCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记评论",
    dataIndex: "imgNoteCommentCount",
    key: "imgNoteCommentCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记收藏",
    dataIndex: "imgNoteCollectCount",
    key: "imgNoteCollectCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记分享",
    dataIndex: "imgNoteShareCount",
    key: "imgNoteShareCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记点赞",
    dataIndex: "noteLikeCount",
    key: "noteLikeCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记评论",
    dataIndex: "noteCommentCount",
    key: "noteCommentCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记收藏",
    dataIndex: "noteCollectCount",
    key: "noteCollectCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记分享",
    dataIndex: "noteShareCount",
    key: "noteShareCount0",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    key: "action0",
    align: "center",
    fixed: "right",
    width: 70,
    filterable: false,
    slots: { title: "customAction" },
    scopedSlots: { customRender: "action" },
  },
];
export const columns_1 = (data) => [
  {
    title: "排序",
    dataIndex: "sort",
    key: "sort1",
    align: "center",
    width: 86,
    fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "门店名称/公司名称",
    dataIndex: "dealerName",
    key: "dealerName1",
    align: "left",
    width: 250,
    fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "dealerName" },
  },
  {
    title: "门店编号",
    dataIndex: "dealerStoreCode",
    key: "dealerStoreCode1",
    align: "left",
    width: 120,
    // fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "区域",
    dataIndex: "regionName",
    key: "regionName1",
    align: "left",
    width: 120,
    // fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "任务完成率",
    dataIndex: "taskFinishRate",
    key: "taskFinishRate1",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "taskFinishRate" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "粉丝总量",
    dataIndex: "fansCount",
    key: "fansCount1",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "fansCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "活动期间增粉",
    dataIndex: "addFansCount",
    key: "addFansCount1",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "addFansCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播场次",
    dataIndex: "liveCount",
    key: "liveCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播时长（分钟）",
    dataIndex: "liveDuration",
    key: "liveDuration1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveDuration" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "直播观看",
    slots: { title: "customTitle" },
    dataIndex: "liveWatchCount",
    key: "liveWatchCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveWatchCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "1w爆款直播",
    dataIndex: "tenThousandHotLive",
    key: "tenThousandHotLive1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "tenThousandHotLive" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "5w爆款直播",
    dataIndex: "fiftyThousandHotLive",
    key: "fiftyThousandHotLive1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "fiftyThousandHotLive" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "10w爆款直播",
    dataIndex: "oneHundredThousandHotLive",
    key: "oneHundredThousandHotLive1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "oneHundredThousandHotLive" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播互动",
    dataIndex: "liveInteractCount",
    key: "liveInteractCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveInteractCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播点赞",
    dataIndex: "liveLikeCount",
    key: "liveLikeCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播评论",
    dataIndex: "liveCommentCount",
    key: "liveCommentCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播送礼",
    dataIndex: "liveGiftCount",
    key: "liveGiftCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveGiftCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播增粉",
    dataIndex: "liveFansAddCount",
    key: "liveFansAddCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveFansAddCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频发布",
    dataIndex: "awemePublishCount",
    key: "awemePublishCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemePublishName" },
    scopedSlots: { customRender: "awemePublishCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频播放",
    dataIndex: "awemeWatchCount",
    key: "awemeWatchCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeWatchName" },
    scopedSlots: { customRender: "awemeWatchCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "1w爆款视频",
    dataIndex: "tenThousandHotAweme",
    key: "tenThousandHotAweme1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "tenThousandHotAweme" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "5w爆款视频",
    dataIndex: "fiftyThousandHotAweme",
    key: "fiftyThousandHotAweme1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "fiftyThousandHotAweme" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "10w爆款视频",
    dataIndex: "oneHundredThousandHotAweme",
    key: "oneHundredThousandHotAweme1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,

    scopedSlots: { customRender: "oneHundredThousandHotAweme" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频互动",
    dataIndex: "awemeInteractCount",
    key: "awemeInteractCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeInteractName" },
    scopedSlots: { customRender: "awemeInteractCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频点赞",
    dataIndex: "awemeLikeCount",
    key: "awemeLikeCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeLikeName" },
    scopedSlots: { customRender: "awemeLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频总点赞",
    dataIndex: "awemeTotalLikeCount",
    key: "awemeTotalLikeCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeTotalLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频拇指赞",
    dataIndex: "awemeThumbLikeCount",
    key: "awemeThumbLikeCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeThumbLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频爱心赞",
    dataIndex: "awemeLoveLikeCount",
    key: "awemeLoveLikeCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeLoveLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频评论",
    dataIndex: "awemeCommentCount",
    key: "awemeCommentCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeCommentName" },
    scopedSlots: { customRender: "awemeCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频分享",
    dataIndex: "awemeShareCount",
    key: "awemeShareCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeShareName" },
    scopedSlots: { customRender: "awemeShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记发布",
    dataIndex: "noteCount",
    key: "noteCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记发布",
    dataIndex: "awemeNoteCount",
    key: "awemeNoteCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteName" },
    scopedSlots: { customRender: "awemeNoteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记点赞",
    dataIndex: "awemeNoteLikeCount",
    key: "awemeNoteLikeCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteLikeName" },
    scopedSlots: { customRender: "awemeNoteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记评论",
    dataIndex: "awemeNoteCommentCount",
    key: "awemeNoteCommentCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteCommentName" },
    scopedSlots: { customRender: "awemeNoteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记分享",
    dataIndex: "awemeNoteShareCount",
    key: "awemeNoteShareCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteShareName" },
    scopedSlots: { customRender: "awemeNoteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记收藏",
    dataIndex: "awemeNoteCollectCount",
    key: "awemeNoteCollectCount1",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteCollectName" },
    scopedSlots: { customRender: "awemeNoteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记发布",
    dataIndex: "imgNoteCount",
    key: "imgNoteCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记点赞",
    dataIndex: "imgNoteLikeCount",
    key: "imgNoteLikeCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记评论",
    dataIndex: "imgNoteCommentCount",
    key: "imgNoteCommentCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记收藏",
    dataIndex: "imgNoteCollectCount",
    key: "imgNoteCollectCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记分享",
    dataIndex: "imgNoteShareCount",
    key: "imgNoteShareCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记点赞",
    dataIndex: "noteLikeCount",
    key: "noteLikeCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记评论",
    dataIndex: "noteCommentCount",
    key: "noteCommentCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记收藏",
    dataIndex: "noteCollectCount",
    key: "noteCollectCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记分享",
    dataIndex: "noteShareCount",
    key: "noteShareCount1",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    key: "action1",
    align: "center",
    fixed: "right",
    width: 70,
    filterable: false,
    slots: { title: "customAction" },
    scopedSlots: { customRender: "action" },
  },
];
export const columns_2 = (data) => [
  {
    title: "排序",
    dataIndex: "sort",
    key: "sort2",
    align: "center",
    width: 86,
    fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "视频标题",
    dataIndex: "awemeTitle",
    key: "awemeTitle2",
    align: "left",
    width: 250,
    fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "awemeTitle" },
  },
  {
    title: "平台",
    dataIndex: "platform",
    key: "platform2",
    align: "left",
    width: 120,
    // fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "区域",
    dataIndex: "regionName",
    key: "regionName2",
    align: "left",
    width: 120,
    // fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "门店编号",
    dataIndex: "dealerStoreCode",
    key: "dealerStoreCode2",
    align: "left",
    width: 120,
    // fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "门店名称/账号名称",
    dataIndex: "dealerName",
    key: "dealerName2",
    align: "left",
    width: 250,
    // fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "dealerName" },
  },
  {
    title: "发布时间",
    dataIndex: "publishTime",
    key: "publishTime2",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "publishTime" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频播放",
    dataIndex: "awemeWatchCount",
    key: "awemeWatchCount2",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeWatchCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频互动",
    dataIndex: "awemeInteractCount",
    key: "awemeInteractCount2",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeInteractCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频点赞",
    dataIndex: "awemeLikeCount",
    key: "awemeLikeCount2",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频总点赞",
    dataIndex: "awemeTotalLikeCount",
    key: "awemeTotalLikeCount2",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeTotalLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频拇指赞",
    dataIndex: "awemeThumbLikeCount",
    key: "awemeThumbLikeCount2",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeThumbLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频爱心赞",
    dataIndex: "awemeLoveLikeCount",
    key: "awemeLoveLikeCount2",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeLoveLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频评论",
    dataIndex: "awemeCommentCount",
    key: "awemeCommentCount2",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频转发",
    dataIndex: "awemeShareCount",
    key: "awemeShareCount2",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "模板标题",
    dataIndex: "guideshootName",
    key: "guideshootName",
    align: "left",
    width: 250,
    filterable: false,
    scopedSlots: { customRender: "guideshootName" },
  },
  {
    key: "action2",
    align: "center",
    fixed: "right",
    width: 70,
    filterable: false,
    slots: { title: "customAction" },
    scopedSlots: { customRender: "action" },
  },
];
export const columns_3 = (data) => [
  {
    title: "排名",
    dataIndex: "sort",
    key: "sort3",
    align: "center",
    width: 86,
    fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "直播标题",
    dataIndex: "liveTitle",
    key: "liveTitle3",
    align: "left",
    width: 250,
    ellipsis: true,
    fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "平台",
    dataIndex: "platform",
    key: "platform3",
    align: "left",
    width: 120,
    // fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "区域",
    dataIndex: "regionName",
    key: "regionName3",
    align: "left",
    width: 120,
    // fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "门店编号",
    dataIndex: "dealerStoreCode",
    key: "dealerStoreCode3",
    align: "left",
    width: 120,
    // fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "门店名称/账号名称",
    dataIndex: "dealerName",
    key: "dealerName3",
    align: "left",
    width: 250,
    // fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "dealerName" },
  },
  {
    title: "开播时间",
    dataIndex: "publishTime",
    key: "publishTime3",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "publishTime" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "直播观看",
    slots: { title: "customTitle" },
    dataIndex: "liveWatchCount",
    key: "liveWatchCount3",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveWatchCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播时长（分钟）",
    dataIndex: "liveDuration",
    key: "liveDuration3",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveDuration" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播互动",
    dataIndex: "liveInteractCount",
    key: "liveInteractCount3",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveInteractCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播点赞",
    dataIndex: "liveLikeCount",
    key: "liveLikeCount3",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播评论",
    dataIndex: "liveCommentCount",
    key: "liveCommentCount3",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播送礼",
    dataIndex: "liveGiftCount",
    key: "liveGiftCount3",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveGiftCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "单场增粉",
    dataIndex: "liveAddFansCount",
    key: "liveAddFansCount3",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveAddFansCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    key: "action3",
    align: "center",
    fixed: "right",
    width: 70,
    filterable: false,
    slots: { title: "customAction" },
    scopedSlots: { customRender: "action" },
  },
];
// 战区数据Columns
export const columns_4 = (data) => [
  {
    title: "排名",
    dataIndex: "sort",
    key: "sort4",
    align: "center",
    width: 86,
    fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "区域",
    dataIndex: "warZoneName",
    key: "warZoneName4",
    width: 120,
    fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "门店数",
    dataIndex: "dealerCount",
    key: "dealerCount4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "dealerCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "门店参与率",
    dataIndex: "dealerStoreRate",
    key: "dealerStoreRate4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "dealerStoreRate" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "任务总完成率",
    dataIndex: "taskFinishRate",
    key: "taskFinishRate4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "taskFinishRate" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "粉丝总量",
    dataIndex: "fansCount",
    key: "fansCount4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "fansCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "活动期间增粉",
    dataIndex: "addFansCount",
    key: "addFansCount4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "addFansCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播场次",
    dataIndex: "liveCount",
    key: "liveCount4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播时长（分钟）",
    dataIndex: "liveDuration",
    key: "liveDuration4",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveDuration" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播观看",
    dataIndex: "liveWatchCount",
    key: "liveWatchCount4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveWatchCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播互动",
    dataIndex: "liveInteractCount",
    key: "liveInteractCount4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveInteractCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "直播增粉",
    dataIndex: "liveFansAddCount",
    key: "liveFansAddCount4",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "liveFansAddCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频发布",
    dataIndex: "awemePublishCount",
    key: "awemePublishCount4",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemePublishName" },
    scopedSlots: { customRender: "awemePublishCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频播放",
    dataIndex: "awemeWatchCount",
    key: "awemeWatchCount4",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeWatchName" },
    scopedSlots: { customRender: "awemeWatchCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频互动",
    dataIndex: "awemeInteractCount",
    key: "awemeInteractCount4",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeInteractName" },
    scopedSlots: { customRender: "awemeInteractCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频点赞",
    dataIndex: "awemeLikeCount",
    key: "awemeLikeCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeLikeName" },
    scopedSlots: { customRender: "awemeLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频总点赞",
    dataIndex: "awemeTotalLikeCount",
    key: "awemeTotalLikeCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeTotalLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频拇指赞",
    dataIndex: "awemeThumbLikeCount",
    key: "awemeThumbLikeCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeThumbLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "视频爱心赞",
    dataIndex: "awemeLoveLikeCount",
    key: "awemeLoveLikeCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "awemeLoveLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频评论",
    dataIndex: "awemeCommentCount",
    key: "awemeCommentCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeCommentName" },
    scopedSlots: { customRender: "awemeCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频分享",
    dataIndex: "awemeShareCount",
    key: "awemeShareCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeShareName" },
    scopedSlots: { customRender: "awemeShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记发布",
    dataIndex: "noteCount",
    key: "noteCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记发布",
    dataIndex: "awemeNoteCount",
    key: "awemeNoteCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteName" },
    scopedSlots: { customRender: "awemeNoteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记点赞",
    dataIndex: "awemeNoteLikeCount",
    key: "awemeNoteLikeCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteLikeName" },
    scopedSlots: { customRender: "awemeNoteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记评论",
    dataIndex: "awemeNoteCommentCount",
    key: "awemeNoteCommentCount",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteCommentName" },
    scopedSlots: { customRender: "awemeNoteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记分享",
    dataIndex: "awemeNoteShareCount",
    key: "awemeNoteShareCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteShareName" },
    scopedSlots: { customRender: "awemeNoteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    keyTitle: "视频笔记收藏",
    dataIndex: "awemeNoteCollectCount",
    key: "awemeNoteCollectCount4",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    slots: { title: "awemeNoteCollectName" },
    scopedSlots: { customRender: "awemeNoteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记发布",
    dataIndex: "imgNoteCount",
    key: "imgNoteCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记点赞",
    dataIndex: "imgNoteLikeCount",
    key: "imgNoteLikeCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记评论",
    dataIndex: "imgNoteCommentCount",
    key: "imgNoteCommentCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记收藏",
    dataIndex: "imgNoteCollectCount",
    key: "imgNoteCollectCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "图文笔记分享",
    dataIndex: "imgNoteShareCount",
    key: "imgNoteShareCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "imgNoteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记点赞",
    dataIndex: "noteLikeCount",
    key: "noteLikeCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记评论",
    dataIndex: "noteCommentCount",
    key: "noteCommentCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记收藏",
    dataIndex: "noteCollectCount",
    key: "noteCollectCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记分享",
    dataIndex: "noteShareCount",
    key: "noteShareCount4",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    key: "action4",
    align: "center",
    fixed: "right",
    width: 70,
    filterable: false,
    slots: { title: "customAction" },
    scopedSlots: { customRender: "action" },
  },
];
export const columns_5 = (data) => [
  {
    title: "排序",
    dataIndex: "sort",
    key: "sort5",
    align: "center",
    width: 86,
    fixed: "left",
    filterable: false,
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "笔记标题",
    dataIndex: "noteName",
    key: "noteName",
    align: "left",
    width: 250,
    ellipsis: true,
    fixed: "left",
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "笔记正文",
    dataIndex: "noteText",
    key: "noteText",
    align: "left",
    width: 250,
    ellipsis: true,
    filterable: false,
    scopedSlots: { customRender: "noteText" },
  },
  {
    title: "笔记话题",
    dataIndex: "noteTopic",
    key: "noteTopic",
    align: "left",
    width: 250,
    ellipsis: true,
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "笔记图片数",
    dataIndex: "noteImgNum",
    key: "noteImgNum",
    align: "left",
    width: 100,
    ellipsis: true,
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "笔记标签",
    dataIndex: "noteTags",
    key: "noteTags",
    align: "left",
    width: 250,
    ellipsis: true,
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "平台",
    dataIndex: "platform",
    key: "platform5",
    align: "left",
    width: 120,
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "区域",
    dataIndex: "regionName",
    key: "regionName5",
    align: "left",
    width: 120,
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "门店编号",
    dataIndex: "dealerStoreCode",
    key: "dealerStoreCode5",
    align: "left",
    width: 120,
    filterable: false,
    customRender: function(text, record, index) {
      return text || "-";
    },
  },
  {
    title: "门店名称/账号昵称",
    dataIndex: "dealerName",
    key: "dealerName5",
    align: "left",
    width: 250,
    filterable: false,
    scopedSlots: { customRender: "dealerName" },
  },
  {
    title: "发布时间",
    dataIndex: "notePublishTime",
    key: "publishTime5",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "notePublishTime" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记类型",
    dataIndex: "noteType",
    key: "noteType",
    align: "left",
    width: 120,
    customRender: function(text) {
      return noteType[text] || "-";
    },
  },
  // {
  //   title: "笔记观看",
  //   dataIndex: "noteWatchCount",
  //   key: "noteWatchCount",
  //   align: "left",
  //   width: 130,
  //   filterable: true,
  //   sorter: true,
  //   scopedSlots: { customRender: "noteWatchCount" },
  //   customHeaderCell: (column) => {
  //     return handleHeaderCell(column, data);
  //   },
  // },
  {
    title: "笔记点赞",
    dataIndex: "noteLikeCount",
    key: "noteLikeCount",
    align: "left",
    width: 130,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteLikeCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记评论",
    dataIndex: "noteCommentCount",
    key: "noteCommentCount",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCommentCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记收藏",
    dataIndex: "noteCollectCount",
    key: "noteCollectCount",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteCollectCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    title: "笔记分享",
    dataIndex: "noteShareCount",
    key: "noteShareCount",
    align: "left",
    width: 120,
    filterable: true,
    sorter: true,
    scopedSlots: { customRender: "noteShareCount" },
    customHeaderCell: (column) => {
      return handleHeaderCell(column, data);
    },
  },
  {
    key: "action5",
    align: "center",
    fixed: "right",
    width: 70,
    filterable: false,
    slots: { title: "customAction" },
    scopedSlots: { customRender: "action" },
  },
];

export const defaultSort = {
  0: {
    sort_field: "dealerStoreRate",
    sort: "desc",
  },
  1: {
    sort_field: "liveCount",
    sort: "desc",
  },
  2: {
    sort_field: "awemeWatchCount",
    sort: "desc",
  },
  3: {
    sort_field: "liveWatchCount",
    sort: "desc",
  },
  4: {
    sort_field: "dealerStoreRate",
    sort: "desc",
  },
  5: {
    sort_field: "noteLikeCount",
    sort: "desc",
  },
};

export const settingData = [
  settingData_0,
  settingData_1,
  settingData_2,
  settingData_3,
  settingData_4,
  settingData_5,
];

export const columnsList = [
  deepClone(columns_0),
  deepClone(columns_1),
  deepClone(columns_2),
  deepClone(columns_3),
];

export const apiList = [
  "getHonorListByRegion",
  "getHonorListByDealer",
  "getHonorListByAweme",
  "getHonorListByLive",
  "getWarZoneTopList",
  "getHonorListByNote",
];

export const exportApiList = [
  "/jjt/activity/web/statistic/regionTopExport",
  "/jjt/activity/web/statistic/dealerTopExport",
  "/jjt/activity/web/statistic/awemeExport",
  "/jjt/activity/web/statistic/liveExport",
  "/jjt/activity/web/statistic/warZoneExport",
  "/jjt/activity/web/statistic/noteExport",
];
