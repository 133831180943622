<template>
  <div class="video-list-container">
    <div class="data-box">
      <div class="data-box-item">
        <span class="label">发布视频</span>
        <span class="number">{{ awemeDetail.aweme_size | changeNum }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">视频播放</span>
        <span class="number">{{
          awemeDetail.total_play_volume | changeNum
        }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">视频点赞</span>
        <span class="number">{{
          awemeDetail.total_digg_count | changeNum
        }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">视频评论</span>
        <span class="number">{{
          awemeDetail.total_comment_count | changeNum
        }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">视频转发</span>
        <span class="number">{{
          awemeDetail.total_share_count | changeNum
        }}</span>
      </div>
    </div>
    <div style="margin-bottom: 20px">
      <a-table
        :columns="columns"
        :data-source="videoList"
        :scroll="{ y: 'calc(100vh - 406px)' }"
        :rowKey="(data) => data.aweme_id"
        :pagination="false"
        :locale="locale"
        :loading="tableLoading"
        :customRow="openDetail"
        class="aft-table default"
      >
        <div slot="firstTitle" style="padding-left: 16px">视频标题</div>

        <div slot="videoTitle" slot-scope="scope" class="table-account">
          <div class="table-account-left">
            <div class="img-box">
              <div class="img-div">
                <WebpImage
                  :scale="'3-4'"
                  :src="scope.aweme_cover"
                  :width="'60px'"
                  :height="'80px'"
                  :radius="'8px'"
                />
              </div>
              <div class="shadow">
                <svg-icon icon-class="play8" class="icon" />
              </div>
            </div>
            <div class="box-right">
              <a-tooltip>
                <template slot="title">{{ scope.aweme_title || "-" }}</template>
                <span class="box-right-title">{{
                  scope.aweme_title || "-"
                }}</span>
              </a-tooltip>
              <div class="box-right-container">
                <a-tag
                  color="#FFA73E"
                  v-for="(item, index) in getTagList(scope.tag_name)"
                  :key="index"
                  style="margin-right: 8px;"
                  >{{ item }}</a-tag
                >
              </div>
            </div>
          </div>
        </div></a-table
      >
    </div>
  </div>
</template>

<script>
import api from "@/api/production";
import Empty from "@/components/Empty/Empty";
import WebpImage from "@/components/WebpImage/WebpImage";

export default {
  components: {
    WebpImage,
  },
  data() {
    return {
      awemeDetail: {},
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      videoList: [],
      tableLoading: false,
      columns: [
        {
          key: "videoTitle",
          slots: { title: "firstTitle" },
          align: "left",
          width: "30%",
          scopedSlots: { customRender: "videoTitle" },
        },
        {
          key: "publish_time",
          title: "发布时间",
          width: "15%",
          sorter: (a, b) =>
            new Date(a.publish_time).getTime() -
            new Date(b.publish_time).getTime(),
          align: "center",
          customRender: (scope) => scope.publish_time,
        },
        {
          key: "digg_count",
          title: "点赞数",
          sorter: (a, b) => a.digg_count - b.digg_count,
          align: "center",
          customRender: (scope) =>
            this.$options.filters.changeNum(scope.digg_count),
        },
        {
          key: "comment_count",
          title: "评论数",
          sorter: (a, b) => a.comment_count - b.comment_count,
          align: "center",
          customRender: (scope) =>
            this.$options.filters.changeNum(scope.comment_count),
        },
        {
          key: "share_count",
          title: "转发数",
          sorter: (a, b) => a.share_count - b.share_count,
          align: "center",
          customRender: (scope) =>
            this.$options.filters.changeNum(scope.share_count),
        },
        {
          key: "play_volume",
          title: "播放量",
          sorter: (a, b) => a.play_volume - b.play_volume,
          align: "center",
          customRender: (scope) =>
            this.$options.filters.changeNum(scope.play_volume),
        },
        this.getParams().type != "dongchedi"
          ? {
              key: "play_total_count_completion_rate",
              title: "完播率",
              sorter: (a, b) =>
                a.play_total_count_completion_rate -
                b.play_total_count_completion_rate,
              align: "center",
              customRender: (scope) =>
                this.$options.filters.numRate(
                  scope.play_total_count_completion_rate
                ),
            }
          : {width: 0},
      ],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getVideoList();
      this.getAwemeDetail();
    },
    getVideoList() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
        },
      };
      this.tableLoading = true;
      api.getVideoList(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.videoList = res.data.data || [];
        } else {
          console.error("获取直播列表失败");
        }
        this.tableLoading = false;
      });
    },
    // 获取顶部数据
    getAwemeDetail() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
        },
      };
      api.getAwemeDetail(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.awemeDetail = res.data.data || {};
        } else {
          console.error("获取视频详情失败");
        }
      });
    },
    getParams() {
      return this.$parent.getParams();
    },
    openDetail(record, index) {
      return {
        on: {
          click: () => {
            if (record.aweme_id) {
              let url = `/videoDetail?id=${record.aweme_id}&platform=${
                this.getParams().type
              }`;
              window.open(url);
            } else {
              this.$message.error("暂无视频ID，无法查看详情");
            }
          },
        },
      };
    },
    getTagList(str) {
      if (str) {
        return str.split(",");
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.video-list-container {
  width: 100%;
  // height: 100px;
  background: #2b2f3b;
  border-radius: 16px;
  .data-box {
    width: 100%;
    height: 98px;
    padding: 0 20px;
    display: flex;
    &-item {
      width: 156px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .label {
        font-size: 14px;
        color: #8890a6;
        line-height: 20px;
      }
      .number {
        font-size: 22px;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        margin-top: 8px;
      }
    }
  }
  .aft-table {
    .ant-table-row:hover {
      .table-account {
        &-left {
          @include font_color("font_color26");
          .shadow {
            display: inline-block;
          }
        }
      }
    }
  }
  .table {
    height: calc(100vh - 350px);
    overflow-y: auto;
    &-account {
      display: flex;
      flex-direction: row;
      margin: 16px 0;
      padding-left: 16px;
      &-left {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        @include font_color("font_color20");
        .img-box {
          min-width: 60px;
          margin-right: 8px;
          border-radius: 4px;
          // background: #000;
          position: relative;
          overflow: hidden;
          .img-div {
            width: 60px;
            height: 80px;
            // @include background_color("background_color43");
            display: flex;
            align-items: center;
          }
          .shadow {
            display: none;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            @include background_color("background_color20");
            .icon {
              position: absolute;
              top: calc(50% - 10px);
              left: calc(50% - 10px);
              font-size: 20px;
              @include font_color("font_color6");
            }
          }
        }
        .box-right {
          width: calc(100% - 70px);
          display: flex;
          flex-direction: column;
          &-title {
            height: 42px;
            width: 100%;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
          &-container {
            margin-top: 4px;
            display: flex;
            > span {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
</style>