import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
    
    // 区域列表
    getAreaList() {
        return request({
            url: `/jjt/dealer/dealers/region-list`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        }).then(res => {
            let list = res.data.data.region_list || []
            if (res.data.data.type == 10) {
                list.unshift({
                    id: '',
                    name: '不限大区'
                })
            }
            list.map(item => {
                item.value = item.id
                item.label = item.name
            })
            return list
        })
    },
    getHonorListByDealer({
        platform,
        params
    }) {
        return request({
            url: `/jjt/activity/honor/roll/dealer`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            res = res.data
            return res
        })
    },
    getHonorListByAweme({
        platform,
        params
    }) {
        return request({
            url: `/jjt/activity/honor/roll/aweme`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            res = res.data
            return res
        })
    },
    getHonorListByLive({
        platform,
        params
    }) {
        return request({
            url: `/jjt/activity/honor/roll/live`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            res = res.data
            return res
        })
    },
    getHonorListByRegion({
        platform,
        params
    }) {
        return request({
            url: `/jjt/activity/honor/roll/region`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            res = res.data
            return res
        })
    },

    // 通过2级id获取其他平台id
    getActivityId(params) {
        return request({
            url: `/dealer/topic/activity/ids`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
            params
        }).then(res => {
            res = res.data
            return res
        })
    },
}
