<template>
  <div class="live-list-container">
    <div class="data-box">
      <div class="data-box-item">
        <span class="label">直播场次</span>
        <span class="number">{{
          liveMessage.live_total_count | changeNum
        }}</span>
      </div>
      <div class="data-box-item">
        <span class="label"
          >{{ getParams().type | returnWatchText }}
          <AftTooltip :code="'qjzb11'" style="top: 1px; left: 4px" />
        </span>
        <span class="number">{{
          liveMessage.live_total_watch_user_time | changeNum
        }}</span>
      </div>
      <div class="data-box-item">
        <span class="label"
          >平均{{ getParams().type | returnWatchText }}
          <AftTooltip :code="'qjzb11'" style="top: 1px; left: 4px" />
        </span>
        <span class="number">{{ liveMessage.avg_live | changeNum }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">直播点赞</span>
        <span class="number">{{
          liveMessage.live_total_like_count | changeNum
        }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">直播评论</span>
        <span class="number">{{
          liveMessage.live_total_comment_count | changeNum
        }}</span>
      </div>
    </div>
    <div>
      <LiveCalendar :calendar-data="calendarData" :month="getParams().month" />
    </div>
  </div>
</template>

<script>
import api from "@/api/production";
import LiveCalendar from "@/components/LiveCalendar/new_index.vue";

export default {
  components: {
    LiveCalendar,
  },
  data() {
    return {
      calendarData: null,
      liveMessage: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getCalendarLive();
      this.getNewLiveMessage();
    },
    getCalendarLive() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
        },
      };
      api.getCalendarLive(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.calendarData = res.data.data || {};
        } else {
          console.error("获取直播列表失败");
        }
      });
    },
    // 获取顶部数据
    getNewLiveMessage() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
        },
      };
      api.getNewLiveMessage(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.liveMessage = res.data.data || {};
        } else {
          console.error("获取直播详情失败");
        }
      });
    },
    getParams() {
      return this.$parent.getParams();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.live-list-container {
  width: 100%;
  // height: 100px;
  background: #2b2f3b;
  border-radius: 16px;
  .data-box {
    width: 100%;
    height: 98px;
    padding: 0 20px;
    display: flex;
    &-item {
      width: 156px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .label {
        font-size: 14px;
        color: #8890a6;
        line-height: 20px;
      }
      .number {
        font-size: 22px;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        margin-top: 8px;
      }
    }
  }
}
</style>
