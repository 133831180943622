<template>
  <div class="component-wrap">
    <a-spin :spinning="regionLoading" class="spin-class" />
    <div class="title-div">
      <div>
        <span class="title">标杆门店排名</span>
        <AftTooltip :code="'pgqt02'" style="top: 0px; left: 4px" />
      </div>
      <div>
        <a-select
          v-if="$parent.principal_id == ''"
          v-model="regionValue"
          :suffixIcon="suffixIcon"
          style="width: 150px; height: 36px; margin-left: 20px;"
          class="aft-select card"
          placeholder="选择大区"
          @change="initData"
        >
          <a-select-option
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-else
          v-model="regionValue"
          :suffixIcon="suffixIcon"
          style="width: 150px; height: 36px; margin-left: 20px"
          class="aft-select card"
          placeholder="选择大区"
          @change="initData"
        >
          <a-select-option value="">不限大区</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="info-div" v-if="perspectiveData">
      <div class="info-item">
        <div class="font1">门店账号</div>
        <div class="font2">{{ perspectiveData.account_count | changeNum }}</div>
      </div>
      <div class="info-item">
        <div class="font1">店均发布</div>
        <div class="font2">
          {{ perspectiveData.publish_avg_rate | changeNum }}
        </div>
      </div>
      <div class="info-item">
        <div class="font1">门店活跃占比</div>
        <div class="font2">
          {{ perspectiveData.active_account_rate | numRateAbs }}
        </div>
      </div>
      <div class="info-item">
        <div class="font1">违规直播占比</div>
        <div class="font2">
          {{ perspectiveData.illegal_live_rate | numRateAbs }}
        </div>
      </div>
      <!-- <div class="info-item">
        <div class="font1">头部门店·月度</div>
        <div class="font2">{{ perspectiveData.top_account | changeNum }}</div>
      </div> -->
      <div class="info-item">
        <div class="font1">开播比例</div>
        <div class="font2">{{ perspectiveData.live_rate | numRateAbs }}</div>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="rankList"
      :rowKey="(data) => data.author_id"
      :customRow="rowClick"
      :pagination="false"
      class="table-style border"
      :locale="locale"
    >
      <div slot="customTitle" style="display: flex">
        <div style="padding-left: 13px">本品排名</div>
        <AftTooltip :code="'pgpm03'" style="top: 0px; left: 4px" />
      </div>
      <div slot="level" slot-scope="scope" class="td-style td-center ranking">
        <span
          :class="
            scope.level == 'S'
              ? 'bc1'
              : scope.level == 'A'
              ? 'bc2'
              : scope.level == 'B'
              ? 'bc3'
              : scope.level == 'C'
              ? 'bc4'
              : 'bc'
          "
          >{{ scope.level || "-" }}</span
        >
      </div>
      <div
        slot="name"
        slot-scope="text, scope"
        class="td-style table-name"
      >
        <WebpImage
          :src="scope.avatar"
          :width="'30px'"
          :height="'30px'"
          :radius="'50%'"
        />
        <a-tooltip>
          <template slot="title">
            {{ scope.nickname }}
          </template>
          <span class="ranking-color">{{ scope.nickname }}</span>
        </a-tooltip>
      </div>
      <div
        slot="ranking1"
        slot-scope="scope"
        class="td-style td-ranking-left table-increase ranking1"
      >
        <span :class="scope.principal_rank <= 3 ? 'bc sp' : 'bc'">{{
          scope.principal_rank
        }}</span>
        <div
          v-if="scope.principal_rank_up && scope.principal_rank < 1000"
          style="position: relative; top: 2px"
        >
          <span class="font-color23">{{
            Math.abs(scope.principal_rank_up) || ""
          }}</span>
          <svg-icon
            icon-class="caret-up"
            style="font-size: 10px; margin-left: 6px"
            v-if="scope.principal_rank_up > 0"
          />
          <svg-icon
            icon-class="caret-down"
            style="font-size: 10px; margin-left: 6px"
            v-if="scope.principal_rank_up < 0"
          />
        </div>
        <svg-icon
          icon-class="NEW"
          v-if="scope.principal_rank_up === null"
          style="font-size: 30px; margin: 4px 0 0 4px"
        />
      </div>
      <div
        slot="ranking2"
        slot-scope="scope"
        class="td-style td-ranking-left table-increase"
      >
        <span :class="scope.global_rank <= 3 ? 'bc sp' : 'bc'">{{
          scope.global_rank
        }}</span>
        <div
          v-if="scope.global_rank_up && scope.global_rank_up < 1000"
          style="position: relative; top: 2px"
        >
          <span class="font-color23">{{
            Math.abs(scope.global_rank_up) || ""
          }}</span>
          <svg-icon
            icon-class="caret-up"
            style="font-size: 10px; margin-left: 6px"
            v-if="scope.global_rank_up > 0"
          />
          <svg-icon
            icon-class="caret-down"
            style="font-size: 10px; margin-left: 6px"
            v-if="scope.global_rank_up < 0"
          />
        </div>
        <svg-icon
          icon-class="NEW"
          v-if="scope.global_rank_up === null"
          style="font-size: 30px; margin: 4px 0 0 4px"
        />
      </div>
    </a-table>
  </div>
</template>

<script>
import * as vuex from "vuex";
import Tooltip from "@/components/Tooltip/Tooltip";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brandSummary");
export default {
  name: "CardRegion",
  data() {
    return {
      columns: [
        {
          width: "134px",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "ranking1" },
        },
        {
          title: "行业排名",
          width: "134px",
          align: "left",
          scopedSlots: { customRender: "ranking2" },
        },
        {
          title: "标杆门店",
          align: "left",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "账号等级",
          width: "96px",
          align: "center",
          scopedSlots: { customRender: "level" },
        },
      ],
      regionValue: "",
      locale: {
        emptyText: <Empty style="margin-top: 158px;" title="暂无数据" />,
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
    };
  },
  components: {
    Tooltip,
    WebpImage,
    Empty,
  },
  computed: {
    ...mapState({
      areaList: (state) => state.areaList, // 区域列表
      perspectiveData: (state) => state.perspectiveData, // 六个卡片
      rankList: (state) => state.rankList, // 区域门店排名
      regionLoading: (state) => state.regionLoading,
    }),
  },
  watch: {
    areaList(val) {
      if (val && val.length > 0) {
        this.regionValue = val[0].id;
        this.initData();
      }
    },
  },
  created() {
    if (this.$parent.principal_id == "") {
      this.getAreaList();
    } else {
      this.initData();
    }
  },
  methods: {
    ...mapActions(["getAreaList", "getRegionPerspective", "getRegionRank"]),
    ...mapMutations(["changeState"]),
    // 切换查询
    initData() {
      let params = {
        platform: this.$parent.currentTab,
        region_id: this.regionValue,
      };
      if (this.$parent.principal_id) {
        params.principal_id = this.$parent.principal_id;
      }
      this.getRegionPerspective(params);
      this.getRegionRank(params);
    },
    //   查看更多跳转
    routerTo() {},
    // 跳转作品发布
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            console.log(record);
            if (this.$parent.principal_id) {
              return false;
            }
            this.$router.push({
              path: "/dealer/production",
              query: {
                type: this.$parent.currentTab,
                author_id: record.author_id,
              },
            });
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.component-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 380px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 76px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .title {
      @include font_color("font_color20");
      font-size: 16px;
      margin-left: 10px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      .route {
        @include font_color("font_color28");
        font-size: 12px;
      }
      .arrow {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-left: 10px;
        @include background_color("background_color5");
        transition: all 0.5s;
      }
    }
    .route-div:hover {
      .arrow {
        @include background_color("background_color1");
      }
    }
  }
  .info-div {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    margin-bottom: 20px;
    .info-item {
      height: 100%;
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .font1 {
        font-size: 14px;
        line-height: 22px;
        @include font_color("font_color56");
      }
      .font2 {
        font-size: 20px;
        line-height: 32px;
        @include font_color("font_color52");
      }
    }
  }
  .table-style {
    .ranking > span {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      line-height: 30px;
    }
    .table-increase {
      position: relative;
      > span {
        font-size: 14px;
        @include font_color("font_color21");
      }
      > div {
        // position: absolute;
        // // left: calc(50% + 10px);
        // left: 62%;
        // top: 14px;
        margin-left: 8px;
        font-size: 12px;
        width: 46px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
    }
    .table-name {
      font-size: 14px;
      @include font_color("font_color21");
      > span {
        margin-left: 10px;
        width: calc(100% - 46px);
        display: inline-block;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
    .bc1 {
      @include background_color("background_color52");
      @include font_color("font_color27");
    }
    .bc2 {
      @include background_color("background_color53");
      @include font_color("font_color26");
    }
    .bc3 {
      @include background_color("background_color54");
      @include font_color("font_color29");
    }
    .bc4 {
      @include background_color("background_color60");
      @include font_color("font_color31");
    }
    .bc {
      @include background_color("background_color55");
      @include font_color("font_color21");
    }
    .ranking-color {
      @include font_color("font_color52");
    }
  }
  .td-style {
    height: 42px;
    display: flex;
    align-items: center;
  }
  .td-left {
    text-align: left;
  }
  .td-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .td-ranking-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.ranking1 {
      padding-left: 13px;
    }
    .bc {
      @include background_color("background_color51");
      @include font_color("font_color22");
      min-width: 24px;
      padding: 0 8px;
      height: 24px;
      border-radius: 12px;
      display: inline-block;
      text-align: center;
      line-height: 24px;
    }
    .bc.sp {
      @include background_color("background_color88");
      @include font_color("font_color55");
    }
  }
  .td-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .font-color21 {
    @include font_color("font_color21");
  }
  .font-color23 {
    @include font_color("font_color23");
  }
  .font-color26 {
    @include font_color("font_color26");
  }
  .font-color29 {
    @include font_color("font_color29");
  }
  .font-color31 {
    @include font_color("font_color31");
  }
  .font-color52 {
    @include font_color("font_color52");
  }
}
</style>
