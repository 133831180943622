var option = {
    tooltip: {
        show: true,
        trigger: 'item'
    },
    // 如果数据量超过定义的color数量，那将从第一个开始循环
    color: ['#6AF095', '#815CFF', '#E5E776', '#81D2FF', '#FF6AB0', '#8BAFFE', '#70DEF2', '#D8DDEF', '#D288FF', '#E7DBA6', '#00DA61', '#FAAC6A', '#FA816A', '#B795FF', '#FFCA5C', '#6A89FA', '#BBE05E', '#EDB9B9', '#81DCC2', '#F39494'],
    series: [
        {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            selectedOffset: 0, // 扇区偏移量
            selectedMode: 'single', // 选中模式为单选
            clockwise: true,
            label: {
                show: false,
                position: 'center',
                formatter: ['{num|{c}}', '{title|{b}}'].join('\n'),
                textStyle: {
                    color: 'auto'
                },
                rich: { // 自定义富文本样式，num，title可以随便定义，但是要formatter和rich相对应上
                    num: {
                        color: '#fff',
                        fontSize: '28',
                        fontWeight: 'bold',
                        lineHeight: '40'
                    },
                    title: {
                        fontSize: '16',
                        fontWeight: 'bold',
                        lineHeight: '30'
                    },
                }
            },
            emphasis: { // 高亮状态扇区和标签样式
                label: {
                    show: true,
                    fontSize: '40',
                    fontWeight: 'bold'
                }
            },
            data: []
        },
        {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            selectedOffset: 0, // 扇区偏移量
            selectedMode: 'single', // 选中模式为单选
            clockwise: true,
            // label: {
            //     show: false,
            //     position: 'center',
            //     formatter: ['{num|{c}}', '{title|{b}}'].join('\n'),
            //     textStyle: {
            //         color: 'auto'
            //     },
            //     rich: { // 自定义富文本样式，num，title可以随便定义，但是要formatter和rich相对应上
            //         num: {
            //             color: '#fff',
            //             fontSize: '28',
            //             fontWeight: 'bold'
            //         },
            //         title: {
            //             fontSize: '16',
            //             fontWeight: 'bold'
            //         },
            //     }
            // },
            // emphasis: { // 高亮状态扇区和标签样式
            //     label: {
            //         show: true,
            //         fontSize: '40',
            //         fontWeight: 'bold'
            //     }
            // },
            data: []
        }
    ]
}

export default option