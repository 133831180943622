<template>
  <div class="competitors-contrast-container">
    <div>
      <MyPrincipal :infoData="infoData" ref="MyPrincipal" />
    </div>
    <div>
      <EnemyPrincipal
        :infoData="otherInfoData"
        :enemyData="enemyData"
        @changePrincipal="changePrincipal"
        ref="EnemyPrincipal"
      />
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import MyPrincipal from "./MyPrincipal";
import EnemyPrincipal from "./EnemyPrincipal";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("regionContrast");
export default {
  name: "CompetitorsContrast",
  components: {
    MyPrincipal,
    EnemyPrincipal,
  },
  props: {
    platform: {
      type: String,
      default: "douyin",
    },
  },
  data() {
    return {
      searchTime: moment().subtract(1, "months"),
      enemyPrincipalId: "",
      currentRegionId: "",
      isFirst: true,
    };
  },
  computed: {
    ...mapState({
      enemyData: (state) => state.enemyData, //竞品
      infoData: (state) => state.infoData, //区域
      otherInfoData: (state) => state.otherInfoData, //区域
    }),
  },
  created() {},
  watch: {},
  methods: {
    ...mapActions(["getEnemyPrincipal", "getInfo", "getInfoById"]),
    getComponentsData(id) {
      this.currentRegionId = id;
      if (this.isFirst) {
        this.getEnemyPrincipalData();
      } else {
        this.loadInfo();
      }
    },
    async getEnemyPrincipalData() {
      await this.getEnemyPrincipal();
      this.enemyPrincipalId = this.enemyData[0].principal_id;
      // 获取本品竞品信息
      this.loadInfo();
    },

    async loadInfo() {
      this.getInfo({
        area_id: this.currentRegionId,
        year: moment(this.searchTime).format("YYYY"),
        month: moment(this.searchTime).format("MM"),
        platform: this.platform,
      });
      if (!this.enemyPrincipalId) {
        return false;
      }
      this.getInfoById({
        area_id: this.currentRegionId,
        principal_id: this.enemyPrincipalId,
        year: moment(this.searchTime).format("YYYY"),
        month: moment(this.searchTime).format("MM"),
        platform: this.platform,
      });
      // 门店Top10
      this.getMyPrincipalStoreList();
      this.getEnemyPrincipalStoreList();
    },
    getMyPrincipalStoreList() {
      let data = {
        platform: this.platform,
        params: {
          area_id: this.currentRegionId,
          year: moment(this.searchTime).format("YYYY"),
          month: moment(this.searchTime).format("MM"),
        },
      };
      this.$refs.MyPrincipal.getTopStoreList(data);
    },
    getEnemyPrincipalStoreList() {
      let data = {
        platform: this.platform,
        params: {
          area_id: this.currentRegionId,
          principal_id: this.enemyPrincipalId,
          year: moment(this.searchTime).format("YYYY"),
          month: moment(this.searchTime).format("MM"),
        },
      };
      this.$refs.EnemyPrincipal.getTopStoreList(data);
    },
    changePrincipal(id) {
      this.enemyPrincipalId = id;
      this.loadInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.competitors-contrast-container {
  width: 100%;
  height: 388px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  > div {
    width: calc(50% - 10px);
    border: 1px solid #2b2f3b;
    border-radius: 0px 0px 12px 12px;
    overflow: hidden;
  }
}
</style>
