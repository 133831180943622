<template>
  <div class="wrap">
    <div class="title-box">
      <svg-icon :icon-class="'ti-bulletin'" class="icon" />
      <span>本月曝光量</span>
    </div>
    <div class="number-box">
      <div>
        <svg-icon :icon-class="'sti-live'" class="icon f16" />
        <span class="f14 fw fc1 ml8">直播观看人次：</span>
        <span class="f14 fw fc3">{{
          matrixData.live_day.live_watch_count | changeNum
        }}</span>
        <span class="f12 fc2 ml20">昨日：</span>
        <span class="f12 fc1 mr8">{{
          matrixData.live_day.new_scope_live_watch_count | changeNum
        }}</span>
        <svg-icon
          v-if="matrixData.live_day.live_watch_count_chain"
          class="f12"
          :icon-class="
            matrixData.live_day.live_watch_count_chain >= 0
              ? 'ratio-up'
              : 'ratio-down'
          "
        />
        <span
          :class="
            matrixData.live_day.live_watch_count_chain >= 0
              ? 'fc-up'
              : 'fc-down'
          "
          >{{ matrixData.live_day.live_watch_count_chain | numRateAbs }}</span
        >
        <span class="f12 fc2 ml20">本周：</span>
        <span class="f12 fw fc1 mr8">{{
          matrixData.live_week.new_scope_live_watch_count | changeNum
        }}</span>
        <svg-icon
          v-if="matrixData.live_week.live_watch_count_chain"
          class="f12"
          :icon-class="
            matrixData.live_week.live_watch_count_chain >= 0
              ? 'ratio-up'
              : 'ratio-down'
          "
        />
        <span
          :class="
            matrixData.live_week.live_watch_count_chain >= 0
              ? 'fc-up'
              : 'fc-down'
          "
          >{{ matrixData.live_week.live_watch_count_chain | numRateAbs }}</span
        >
      </div>
      <div>
        <svg-icon :icon-class="'sti-video'" class="icon f16" />
        <span class="f14 fw fc1 ml8">短视频播放量：</span>
        <span class="f14 fw fc3">{{
          matrixData.aweme_day.aweme_play_total | changeNum
        }}</span>
        <span class="f12 fc2 ml20">昨日：</span>
        <span class="f12 fc1 mr8">{{
          matrixData.aweme_day.aweme_play_di | changeNum
        }}</span>
        <svg-icon
          v-if="matrixData.aweme_day.aweme_play_chain"
          class="f12"
          :icon-class="
            matrixData.aweme_day.aweme_play_chain >= 0
              ? 'ratio-up'
              : 'ratio-down'
          "
        />
        <span
          :class="
            matrixData.aweme_day.aweme_play_chain >= 0 ? 'fc-up' : 'fc-down'
          "
          >{{ matrixData.aweme_day.aweme_play_chain | numRateAbs }}</span
        >
        <span class="f12 fc2 ml20">本周：</span>
        <span class="f12 fw fc1 mr8">{{
          matrixData.aweme_week.aweme_play_di | changeNum
        }}</span>
        <svg-icon
          v-if="matrixData.aweme_week.aweme_play_chain"
          class="f12"
          :icon-class="
            matrixData.aweme_week.aweme_play_chain >= 0
              ? 'ratio-up'
              : 'ratio-down'
          "
        />
        <span
          :class="
            matrixData.aweme_week.aweme_play_chain >= 0 ? 'fc-up' : 'fc-down'
          "
          >{{ matrixData.aweme_week.aweme_play_chain | numRateAbs }}</span
        >
      </div>
    </div>
    <div class="chart-box">
      <my-chart
        :options="getLineOption(xData, yData1, yData2)"
        :id="'line-chart'"
      ></my-chart>
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import chartLine from "../options/chartLine";
import deepClone from "@/utils/clone-utils";
import api from "@/api/bulletinBoard";
export default {
  name: "LeftWrap2",
  components: {
    MyChart,
  },
  data() {
    return {
      xData: [],
      yData1: [],
      yData2: [],
      matrixData: {
        aweme_day: {},
        aweme_week: {},
        live_day: {},
        live_week: {},
      },
    };
  },
  computed: {},
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getTrendChart();
      this.getMatrixData();
    },
    // 接口请求 - 数据趋势
    getTrendChart() {
      let params = {
        platform: this.$parent.currentTab,
      };
      // 恢复初始数据
      this.xData = [];
      this.yData1 = [];
      this.yData2 = [];
      api.getTrendChart(params).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getTrendChart", data);
          this.xData = data.aweme.xaxis;
          this.yData1 = data.live.yaxis;
          this.yData2 = data.aweme.yaxis;
        } else {
          console.error("获取数据趋势失败");
          // 恢复初始数据
          this.xData = [];
          this.yData1 = [];
          this.yData2 = [];
        }
      });
    },
    // 接口请求 - 矩阵流量
    getMatrixData() {
      let params = {
        platform: this.$parent.currentTab,
      };
      api.getMatrixData(params).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getMatrixData", data);
          let { aweme_day, aweme_week, live_day, live_week } = data;
          this.matrixData = {
            aweme_day: aweme_day || {},
            aweme_week: aweme_week || {},
            live_day: live_day || {},
            live_week: live_week || {},
          };
        } else {
          console.error("获取矩阵流量失败");
          this.matrixData = {
            aweme_day: {},
            aweme_week: {},
            live_day: {},
            live_week: {},
          };
        }
      });
    },
    getLineOption(xData, yData1, yData2) {
      let option = deepClone(chartLine);
      option.xAxis[0].data = xData;
      option.series[0].data = yData1;
      option.series[1].data = yData2;
      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  .title-box {
    display: flex;
    align-items: center;
    padding: 20px;
    .icon {
      font-size: 22px;
    }
    > span {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .number-box {
    display: flex;
    flex-direction: column;
    padding: 40px 0 10px 50px;
    > div {
      margin-bottom: 14px;
    }
    .fc1 {
      color: #fff;
    }
    .fc2 {
      color: #9ca8ba;
    }
    .fc3 {
      color: #ffca5c;
      // width: 80px;
      // display: inline-block;
    }
    .fc-up {
      color: #e75934;
    }
    .fc-down {
      color: #45b36b;
    }
    .f12 {
      font-size: 12px;
    }
    .f14 {
      font-size: 14px;
    }
    .f16 {
      font-size: 16px;
    }
    .fw {
      font-weight: bold;
    }
    .ml8 {
      margin-left: 8px;
    }
    .mr8 {
      margin-right: 8px;
    }
    .ml20 {
      margin-left: 20px;
    }
  }
  .chart-box {
    width: 100%;
    height: 200px;
  }
}
</style>
