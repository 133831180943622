<template>
  <div class="principal-container">
    <div class="principal-container-info">
      <div class="info-header">
        <div class="info-header-left">
          <WebpImage
            :src="ossHost + infoData.avatar"
            :width="'60px'"
            :height="'60px'"
            :radius="'11px'"
          />
          <span class="p-name">{{ infoData.principal_name }}</span>
        </div>
        <div class="info-header-right">
          <div class="brand-box">
            <div
              class="brand-box-item"
              v-for="(item, index) in returnSliceEnemy(infoData, enemyData)"
              :key="index"
              @click="changePrincipal({ key: item.principal_id })"
              :style="{
                left: `${
                  (returnSliceEnemy(infoData, enemyData).length - index - 1) *
                  18
                }px`,
                'z-index': 6 - index,
              }"
            >
              <a-tooltip placement="top">
                <template slot="title">{{
                  item.principal_name || "-"
                }}</template>
                <WebpImage
                  :src="ossHost + item.avatar"
                  :width="'40px'"
                  :height="'40px'"
                  :radius="'8px'"
                />
              </a-tooltip>
            </div>
          </div>
          <a-dropdown>
            <a class="dropdown-text" @click="(e) => e.preventDefault()">
              切换竞品 <a-icon type="down" />
            </a>
            <a-menu slot="overlay" @click="changePrincipal">
              <a-menu-item :key="item.principal_id" v-for="item in enemyData">
                {{ item.principal_name }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
      <div class="info-data">
        <div class="info-data-item">
          <span>门店账号数量</span>
          <span>{{ infoData.account_count | changeNum }}</span>
        </div>
        <div class="info-data-item">
          <span>营销价值</span>
          <span>{{ infoData.marketing_value | changeNum }}</span>
        </div>
        <div class="info-data-item">
          <span>粉丝总量</span>
          <span>{{ infoData.fans_count | changeNum }}</span>
        </div>
        <div class="info-data-item">
          <span
            >{{ currentPlatform | returnWatchText }}

            <AftTooltip :code="'qjzb11'" style="top: 1px; left: 4px"
          /></span>
          <span>{{ infoData.watch_count | changeNum }}</span>
        </div>
        <div class="info-data-item">
          <span>视频播放</span>
          <span>{{ infoData.play_volume | changeNum }}</span>
        </div>
      </div>
      <div class="store-list" v-if="storeList && storeList.length > 0">
        <span
          :class="[
            'store-list-item',
            currentDealerId == item.dealer_id ? 'active' : '',
          ]"
          v-for="(item, index) in storeList"
          :key="item.dealer_id"
        >
          <a-tooltip placement="top">
            <template slot="title">{{ item.dealer_name || "-" }}</template>
            <div class="img-item" @click="changeDealerId(item.dealer_id)">
              <WebpImage
                :src="item.avatar"
                :width="'40px'"
                :height="'40px'"
                :radius="'50%'"
              />
            </div>
          </a-tooltip>

          <span class="ranking-tag" @click="changeDealerId(item.dealer_id)">{{
            index + 1
          }}</span>
        </span>
      </div>
      <div class="store-info" v-if="storeList && storeList.length > 0">
        <div class="store-info-tools">
          <span class="text-left">门店最佳账号</span>
          <a-dropdown>
            <a class="text-right" @click="(e) => e.preventDefault()">
              门店其他账号 <a-icon type="down" />
            </a>
            <a-menu slot="overlay" @click="accountClick">
              <a-menu-item :key="item.id" v-for="item in accountList">
                {{ item.name }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="store-info-main">
          <div>
            <WebpImage
              :src="authorInfoData.avatar"
              :width="'40px'"
              :height="'40px'"
              :radius="'50%'"
            />
          </div>
          <div>
            <span class="f14 fc1 fw">{{ authorInfoData.nickname || "-" }}</span>
            <span class="f12 fc2"
              >{{
                currentPlatform == "douyin"
                  ? "抖音"
                  : currentPlatform == "kuaishou"
                  ? "快手"
                  : "懂车帝"
              }}号：{{ authorInfoData.code || "-" }}</span
            >
          </div>
          <div>
            <span class="f14 fc1">账号等级</span>
            <span :class="['f48', 'fw', `fc-${authorInfoData.level}`]">{{
              authorInfoData.level
            }}</span>
          </div>
          <div>
            <span class="f14 fc3">粉丝总数</span>
            <span class="f20 fc1">{{
              authorInfoData.fans_count | changeNum
            }}</span>
            <span
              :class="[
                'f14',
                returnRateClass(authorInfoData.fans_count_increase, 1),
              ]"
              >{{ Math.abs(authorInfoData.fans_count_increase) || "-" }}
              <svg-icon
                v-if="authorInfoData.fans_count_increase"
                :icon-class="
                  returnRateClass(authorInfoData.fans_count_increase, 2)
                "
            /></span>
          </div>
          <div>
            <span class="f14 fc3">获赞总数</span>
            <span class="f20 fc1">{{
              authorInfoData.digg_count | changeNum
            }}</span>
            <span
              :class="[
                'f14',
                returnRateClass(authorInfoData.digg_count_increase, 1),
              ]"
              >{{ Math.abs(authorInfoData.digg_count_increase) || "-" }}
              <svg-icon
                v-if="authorInfoData.digg_count_increase"
                :icon-class="
                  returnRateClass(authorInfoData.digg_count_increase, 2)
                "
            /></span>
          </div>
        </div>
      </div>
      <Empty
        title="暂无门店"
        style="margin-top: 60px"
        v-if="!storeList || storeList.length == 0"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api/regionContrast.js";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";
export default {
  components: { WebpImage, Empty },
  props: {
    infoData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    enemyData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      currentPlatform: "douyin",
      currentDealerId: "",
      currentAuthorId: "",
      liveSelected: false,
      videoSelected: false,
      liveTabs: "recent",
      videoTabs: "recent",
      storeList: [],
      accountList: [],
      authorInfoData: {},
      liveAndAwemeData: {
        recent: {
          aweme: {},
          live: {},
        },
        best: {
          aweme: {},
          live: {},
        },
      },
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 获取本品门店Top10
    getTopStoreList(data) {
      this.currentPlatform = data.platform;
      api.getTopStoreListById(data).then((res) => {
        if (res.data.code === 0) {
          this.storeList = res.data.data || [];
          if (res.data.data.length > 0) {
            // 默认选中第一个门店， 并获取账号列表
            this.changeDealerId(res.data.data[0].dealer_id);
          } else {
            this.currentDealerId = "";
            this.$message.warning("未获取到门店列表！");
          }
        } else {
          this.storeList = [];
          console.error("获取本品门店Top10失败");
        }
      });
    },
    // 获取门店下账号列表
    getAccountList() {
      api
        .getAccountList({
          dealer_id: this.currentDealerId,
          platform: this.currentPlatform,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.accountList = res.data.data || [];
            if (res.data.data.length > 0) {
              // 默认选中第一个账号， 并获取详情
              this.changeAuthorId(res.data.data[0].id);
            } else {
              this.currentAuthorId = "";
              this.$message.warning("未获取到账号列表！");
            }
          } else {
            this.accountList = [];
            console.error("获取门店下账号列表失败");
          }
        });
    },
    // 获取账号详情
    getAccountInfo() {
      api
        .getAccountInfo({
          author_id: this.currentAuthorId,
          platform: this.currentPlatform,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.authorInfoData = res.data.data;
          } else {
            console.error("获取账号详情失败");
          }
        });
    },
    // 获取视频、直播详情
    getLiveAndAweme() {
      api
        .getLiveAndAweme({
          author_id: this.currentAuthorId,
          platform: this.currentPlatform,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.liveAndAwemeData = res.data.data;
          } else {
            console.error("获取视频、直播详情失败");
          }
        });
    },
    changeDealerId(id) {
      this.currentDealerId = id;
      this.getAccountList();
    },
    changeAuthorId(id) {
      this.currentAuthorId = id;
      this.getAccountInfo();
      // this.getLiveAndAweme();
    },
    changePrincipal(e) {
      this.$emit("changePrincipal", e.key);
    },
    accountClick(e) {
      this.changeAuthorId(e.key);
    },
    returnRateClass(rate, type) {
      if (type == 1) {
        return rate >= 0 ? "fc-up" : "fc-down";
      } else {
        return rate >= 0 ? "new-up2" : "new-down2";
      }
    },
    // 竞品切换数组处理
    returnSliceEnemy(infoData, enemyData) {
      console.log(infoData, enemyData);
      let newArr = [];
      enemyData.slice(0, 6).forEach((item) => {
        if (item.principal_name != infoData.principal_name) {
          newArr.push(item);
        }
      });
      console.log(111, newArr);
      return newArr;
    },
    routerToLive(item) {
      if (item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${this.currentPlatform}`;
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
    routerToAweme(item) {
      if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${this.currentPlatform}`;
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.principal-container {
  width: 100%;
  background: #2b2f3b;
  box-shadow: inset 0px 4px 0px 0px #7a91ff;
  border-top: 4px solid #7a91ff;
  &-info {
    width: 100%;
    height: 384px;
    padding: 26px 20px 16px;
    .info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      &-left {
        display: flex;
        align-items: center;
        .p-name {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          margin-left: 8px;
        }
      }
      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .dropdown-text {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
        }
        .brand-box {
          width: 128px;
          margin-right: 12px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          &-item {
            cursor: pointer;
            transition: all 0.5s;
            position: relative;
            &:hover {
              transform: translateY(-20px);
            }
          }
        }
      }
    }
    .info-data {
      width: 100%;
      height: 46px;
      margin: 20px 0 22px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      &-item {
        width: 100px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        > span:first-child {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.45);
          line-height: 20px;
        }
        > span:last-child {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
    .store-list {
      width: 100%;
      height: 52px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      &-item {
        width: 10%;
        height: 100%;
        position: relative;
        .img-item,
        .img-item > div {
          width: 40px;
          height: 40px;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 99;
          transition: all 0.3s;
          cursor: pointer;
          display: inline-block;
        }
        .ranking-tag {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          font-size: 12px;
          color: #693800;
          line-height: 20px;
          text-align: center;
          position: absolute;
          left: 24px;
          bottom: -4px;
          display: none;
          z-index: 100;
          transition: all 0.3s;
          cursor: pointer;
        }
        &.active {
          .img-item {
            width: 48px !important;
            height: 48px !important;
            & > div,
            & > div > img {
              width: 48px !important;
              height: 48px !important;
            }
          }
        }
        &:nth-of-type(1) {
          .ranking-tag {
            background: #ffca5c;
            display: inline-block;
          }
        }
        &:nth-of-type(1).active {
          .ranking-tag {
            left: 32px;
          }
          .img-item > div {
            border: 2px solid #ffca5c;
          }
        }
        &:nth-of-type(2) {
          .ranking-tag {
            background: #b7ccf9;
            display: inline-block;
          }
        }
        &:nth-of-type(2).active {
          .ranking-tag {
            left: 32px;
          }
          .img-item > div {
            border: 2px solid #b7ccf9;
          }
        }
        &:nth-of-type(3) {
          .ranking-tag {
            background: #de9c6e;
            display: inline-block;
          }
        }
        &:nth-of-type(3).active {
          .ranking-tag {
            left: 32px;
          }
          .img-item > div {
            border: 2px solid #de9c6e;
          }
        }
      }
    }
    .store-info {
      margin-top: 28px;
      height: 129px;
      &-tools {
        margin-bottom: 20px;
        .text-left {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.85);
          margin-right: 24px;
        }
        .text-right {
          font-size: 14px;
          color: #7f8188;
        }
      }
      &-main {
        width: 100%;
        height: 78px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          &:nth-of-type(1) {
            margin-right: 8px;
          }
          &:nth-of-type(2) {
            margin-right: 54px;
          }
          &:nth-of-type(n + 3) {
            width: 100px;
          }
        }
      }
    }
  }
  &-select-box {
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border-top: 1px solid #3c4252;
    background: #2b2f3b;
    overflow: hidden;
    transition: all 0.3s;
    &.selected {
      height: 389px;
      background: #1a1d26;
    }
    .box-header {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
    .box-data-main {
      display: flex;
      flex-wrap: nowrap;
      > div {
        width: 19%;
        height: 68px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    .box-tabs {
      height: 32px;
      display: flex;
      margin: 24px 0 16px;
      &-btn {
        height: 32px;
        padding: 0 16px;
        background: #1a1d26;
        border-radius: 16px;
        margin-right: 12px;
        line-height: 32px;
        font-size: 14px;
        color: #7f8188;
        cursor: pointer;
        &.active {
          background: #2b2f3b;
          color: #ffca5c;
        }
      }
    }
    .box-works {
      &-info {
        display: flex;
        cursor: pointer;
        .right-info {
          width: 50%;
          margin-left: 10px;
          > div:first-child {
            width: 100%;
            height: 44px;
            font-size: 14px;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.85);
            line-height: 22px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
          > div:last-child {
            font-size: 12px;
            color: #7f8188;
            line-height: 20px;
            margin-top: 4px;
          }
        }
      }
      &-data {
        display: flex;
        margin-top: 12px;
        &-item {
          width: 20%;
          height: 48px;
          line-height: 22px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
  // 页面公用样式
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f20 {
    font-size: 20px;
  }
  .f48 {
    font-size: 48px;
    line-height: 56px;
    @include font_color("font_color21");
  }
  .fc1 {
    color: rgba(255, 255, 255, 0.85);
  }
  .fc2 {
    color: #8a8c93;
  }
  .fc3 {
    color: #7f8188;
  }
  .fc-up {
    color: #27c346;
  }
  .fc-down {
    color: #f54e4e;
  }
  .fw {
    font-weight: bold;
  }

  .fc-S {
    @include font_color("font_color27");
  }
  .fc-A {
    @include font_color("font_color26");
  }
  .fc-B {
    @include font_color("font_color29");
  }
  .fc-C {
    @include font_color("font_color31");
  }
  .fc-D {
    @include font_color("font_color21");
  }
}
</style>
