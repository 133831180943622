<template>
  <div>
    <platform-header
      title="上周最佳十强"
      :currentTab="platform"
      @tabChange="handlePlatformClick"
    />
    <div class="main-title">
      <span>{{ orderList[active_index].text }}</span>
    </div>
    <div class="tools">
      <div class="btn-box">
        <Order :order-list="orderList" @order-click="hadnleOrderClick" />
      </div>

      <div class="btn-box">
        <date-week-tab
          style="margin: 0 10px"
          :type="dateType"
          @changeType="changeType"
          @changeDate="onChange"
          ref="dateTab"
        ></date-week-tab>
        <!-- <div class="month">第{{ thisWeek || 1 }}周</div>
            <div class="calendar-wrap">
                <div class="calendar-box">
                    <span class="date">{{ weekRange || '请选择时间' }}</span>
                    <svg-icon icon-class="range-picker" class="calendar"  />
                </div>
                <a-week-picker
                    class="aft-range-picker default"
                    placeholder="Select week"
                    :default-value="searchTime"
                    :disabled-date="disabledDate"
                    @change="onChangeWeek"
                />
            </div> -->
      </div>
    </div>

    <a-spin :spinning="loading">
      <div class="list-content" v-if="top3DataList && top3DataList.length > 0">
        <div class="top-3-list">
          <div
            :class="index === 0 ? 'item item-1' : 'item'"
            v-for="(item, index) in top3DataList"
            :key="item.author_id"
            @click="handleVideoItemClick(item)"
          >
            <div class="bg">
              <img
                :src="require(`@/assets/top_list_images/top-${index}.png`)"
              />
            </div>
            <div class="ranking">{{ index + 1 }}</div>

            <div class="brand">
              <div
                class="avatar"
                :style="`background-image: url('${item.avatar}')`"
              ></div>
            </div>
            <div class="info">
              <div class="name">{{ item.nickname || "暂无名称" }}</div>
              <div class="num-box">
                <div class="num">
                  <span>{{ item.max_count | formatValue }}</span
                  >{{ item.max_count | formatUnit }}
                </div>
                <svg-icon
                  icon-class="NEW"
                  class="new"
                  v-if="item.is_new === 0"
                />
                <div
                  class="rank-diff"
                  v-if="item.is_new !== 0 && item.rank_diff"
                >
                  <svg-icon
                    icon-class="new-up"
                    class="icon up"
                    v-if="item.rank_diff > 0"
                  />
                  <svg-icon
                    icon-class="new-down"
                    class="icon down"
                    v-if="item.rank_diff < 0"
                  />
                  <span>{{ Math.abs(item.rank_diff) }}</span>
                </div>
              </div>
            </div>
            <div class="play-box">
              <svg-icon icon-class="play3" class="play" />
            </div>
          </div>
        </div>
        <div class="data-list">
          <div class="row title-row">
            <div class="col col-1">排名</div>
            <div class="col col-2">主播</div>
            <div class="col col-3">
              {{
                active_index !== 0
                  ? `${orderList[active_index].text}人次`
                  : `${orderList[0].text}`
              }}
            </div>
            <div class="col col-4">视频</div>
          </div>
          <div
            class="row"
            v-for="(item, index) in dataList"
            :key="item.author_id"
            @click="handleVideoItemClick(item)"
          >
            <div class="col col-1">
              <span class="ranking">{{ index + 4 }}</span>
              <div class="col" v-if="item.is_new !== 0 && item.rank_diff">
                <svg-icon
                  icon-class="new-up"
                  class="icon up"
                  v-if="item.rank_diff > 0"
                />
                <svg-icon
                  icon-class="new-down"
                  class="icon down"
                  v-if="item.rank_diff < 0"
                />
                <span class="step">{{ Math.abs(item.rank_diff) }}</span>
              </div>
              <svg-icon icon-class="NEW" class="new" v-if="item.is_new === 0" />
            </div>
            <div class="col col-2">
              <div
                class="avatar"
                :style="`background-image: url('${item.avatar}')`"
              ></div>
              <div class="name">{{ item.nickname || "暂无名称" }}</div>
            </div>
            <div class="col col-3">
              <div class="num">
                <span>{{ item.max_count | formatValue }}</span
                >{{ item.max_count | formatUnit }}
              </div>
            </div>
            <div class="col col-4">
              <svg-icon icon-class="play3" class="play" />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="font-color3"
          style="text-align: center; width: 100%; padding-top: 20px"
        >
          <Empty style="margin: 20vh 0" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Order from "./components/Order";
import _ from "lodash";
import moment from "moment";
import api from "@/api/hot_list.js";
import Empty from "@/components/Empty/Empty";
import DateWeekTab from "@/components/DateTab/DateWeekTab";

export default {
  name: "hotLiveList",
  components: {
    PlatformHeader,
    Order,
    Empty,
    DateWeekTab,
  },
  filters: {
    formatValue(num) {
      if (num) {
        if (Math.abs(num) >= 100000000) {
          num = (num / 100000000).toFixed(1);
        } else if (Math.abs(num) >= 10000) {
          num = (num / 10000).toFixed(1);
        }
        return num;
      } else {
        return 0;
      }
    },
    formatUnit(num) {
      let unit = "";
      if (num) {
        if (Math.abs(num) >= 100000000) {
          unit = "亿";
        } else if (Math.abs(num) >= 10000) {
          unit = "w";
        }
      }
      return unit;
    },
  },
  data() {
    return {
      searchTime: moment().subtract(1, "week"),
      thisWeek: moment()
        .subtract(1, "week")
        .week(),
      weekRange: `${moment()
        .subtract(1, "week")
        .day(1)
        .format("YYYY-MM-DD")} ~ ${moment()
        .subtract(1, "week")
        .day(7)
        .format("YYYY-MM-DD")}`,
      disabledDate(current) {
        return current && current >= moment().startOf("week");
      },
      startTime: "",
      endTime: "",
      platform: "",
      dateType: "week",
      sort: "interactive",
      active_index: 0,
      orderList: [
        {
          text: "综合排名(单视频互动)",
          value: "interactive",
        },
        {
          text: "最多播放视频",
          value: "play",
        },
        {
          text: "最多点赞视频",
          value: "like",
        },
        {
          text: "最多评论视频",
          value: "comment",
        },
        {
          text: "最多转发视频",
          value: "share",
        },
      ],
      top3DataList: [],
      dataList: [],
      loading: false,
    };
  },
  created() {
    // let obj = this.calcStartEndTime(this.searchTime);
    // this.startTime = obj.startTime;
    // this.endTime = obj.endTime;
    // this.$nextTick(() => {
    //   this.getList();
    // });
  },
  methods: {
    getList() {
      this.loading = true;
      this.getTimeRange();

      let body = {
        platform: this.platform,
        params: {
          statistics_begin_date: this.startTime,
          statistics_end_date: this.endTime,
          rank_field: this.sort,
        },
      };

      api.getVideoList(body).then((res) => {
        this.loading = false;

        if (res.data.code === 0) {
          let top3DataList = [];
          let dataList = [];
          let list = res.data.data;

          for (let i in list) {
            if (i < 3) {
              top3DataList.push(list[i]);
            } else {
              dataList.push(list[i]);
            }
          }
          this.top3DataList = top3DataList;
          this.dataList = dataList;
        } else {
          console.error("获取排名失败");
        }
      });
    },
    getTimeRange() {
      let start_time = "";
      let end_time = "";
      if (this.dateType == "week") {
        if (
          !this.$refs.dateTab.weekStartTime ||
          !this.$refs.dateTab.weekEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.weekStartTime;
        end_time = this.$refs.dateTab.weekEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      this.startTime = start_time;
      this.endTime = end_time;
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      this.getList();
    },
    onChange() {
      this.getList();
    },
    calcStartEndTime(date) {
      let start = date.startOf("week").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("week").format("YYYY-MM-DD HH:mm:ss");

      let startRange = date.startOf("week").format("YYYY-MM-DD");
      let endRange = date.endOf("week").format("YYYY-MM-DD");

      return {
        startTime: start,
        endTime: end,
        startRange: startRange,
        endRange: endRange,
      };
    },
    handlePlatformClick(val) {
      this.platform = val;
      this.$nextTick(() => {
        this.getList();
      });
    },
    // 日期切换
    onChangeWeek(date, dateString) {
      if (!date) {
        return;
      }
      // let obj = this.calcStartEndTime(date);
      // this.startTime = obj.startTime;
      // this.endTime = obj.endTime;
      this.getTimeRange();
      this.weekRange = `${obj.startRange} ~ ${obj.endRange}`;
      this.searchTime = date;
      this.thisWeek = date.week();

      this.getList();
    },
    hadnleOrderClick(res) {
      this.sort = res.data.value;
      this.active_index = res.index;
      this.getList();
    },
    handleVideoItemClick(item) {
      if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${this.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.list_header {
  margin-bottom: 20px;
  > h3 {
    font-weight: bold;
    font-size: 18px;
    padding: 20px 20px 0 20px;
    @include font_color("font_color1");
    margin: 0;
  }
}

.main-title {
  margin: 30px 0 25px;
  text-align: center;
  span {
    font-size: 40px;
    font-weight: bold;
    line-height: 45px;
    color: #fff;
    background: linear-gradient(270deg, #82abff 0%, #ffd8d8 100%);
    -webkit-background-clip: text;
    color: transparent;
  }
}

.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 20px;
}

.btn-box {
  display: flex;
  align-items: center;
  .month {
    font-size: 14px;
    @include font_color("font_color4");
  }
}
.calendar-wrap {
  position: relative;
  margin-left: 10px;
  width: 230px;
  .calendar-box {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 14px;
    cursor: pointer;
    @include background_color("background_color34");
    .date {
      font-size: 12px;
      font-weight: bold;
      @include font_color("font_color41");
    }
    .calendar {
      font-size: 18px;
      @include font_color("font_color41");
    }
  }

  /deep/ .ant-calendar-picker {
    width: 100%;
    opacity: 0;
    input {
      height: 40px;
    }
  }
}

.list-content {
  min-height: 200px;
}

.avatar {
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  @include background_color("background_color58");
}
.new {
  font-size: 27px;
}
.play {
  font-size: 30px;
}

.top-3-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 40px 20px 10px;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    position: relative;
    min-height: 130px;
    border-radius: 16px;
    cursor: pointer;
    &:nth-child(1) {
      background: linear-gradient(270deg, #ffc05c 0%, #ffe195 100%);
    }
    &:nth-child(2) {
      background: linear-gradient(270deg, #8baffe 0%, #bfd7ff 100%);
    }
    &:nth-child(3) {
      background: linear-gradient(270deg, #f5a679 0%, #fbd2b1 100%);
    }
  }
  .bg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 121px;
    img {
      height: 100%;
    }
  }
  .ranking {
    position: absolute;
    top: 0;
    right: 0;
    width: 46px;
    height: 32px;
    line-height: 32px;
    border-radius: 0px 16px 0px 16px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    @include font_color("font_color17");
    @include background_color("background_color58");
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 60px 20px 0;
    width: 100%;
    height: 100%;
  }
  .brand {
    width: 110px;
    text-align: center;
    .avatar {
      margin: 0 auto;
      width: 70px;
      height: 70px;
    }
  }
  .info {
    flex: 1;
    .name {
      padding-right: 20px;
      padding-bottom: 15px;
      font-size: 14px;
      font-weight: bold;
      @include font_color("font_color25");
    }
    .num-box {
      display: flex;
      align-items: flex-end;
      .num {
        padding-right: 6px;
        font-size: 30px;
        line-height: 1.2;
        @include font_color("font_color25");
        span {
          font-weight: bold;
        }
      }
      .rank-diff {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 0 5px;
        margin-bottom: 5px;
        min-width: 30px;
        height: 19px;
        @include background_color("background_color58");
        border-radius: 10px;
        .icon {
          margin-left: 2px;
        }
      }
    }
  }
  .play-box {
    width: 25%;
    max-width: 70px;
  }
}

.data-list {
  margin: 0 20px;
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 20px 5%;
    &.title-row {
      font-size: 12px;
      font-weight: bold;
      @include font_color("font_color22");
    }
    &:not(.title-row) {
      margin-bottom: 10px;
      border-radius: 16px;
      cursor: pointer;
      @include background_color("background_color2");
    }
  }
  .col {
    display: flex;
    align-items: center;
    &.col-1 {
      width: 13%;
    }
    &.col-2 {
      flex: 1;
    }
    &.col-3 {
      width: 16%;
      justify-content: center;
    }
    &.col-4 {
      width: 12%;
    }
  }
  .ranking {
    padding: 0 10px;
    min-width: 42px;
    height: 30px;
    font-size: 16px;
    margin-right: 9px;
    font-weight: bold;
    line-height: 30px;
    border-radius: 15px;
    text-align: center;
    @include font_color("font_color22");
    @include background_color("background_color51");
  }
  .avatar {
    width: 54px;
    height: 54px;
  }
  .step {
    font-size: 12px;
    font-weight: bold;
    @include font_color("font_color23");
  }
  .icon {
    font-size: 12px;
  }
  .name {
    margin-left: 20px;
    font-size: 14px;
    font-weight: bold;
    @include font_color("font_color21");
  }
  .num {
    font-size: 14px;
    @include font_color("font_color21");
    span {
      font-weight: bold;
    }
  }
  .new {
  }
}
</style>
