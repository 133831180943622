import Nr from "@/utils/NetworldRequest";

const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API,
}).getAxios();

export const getBaseUpdateTime = (params) => {
  return request.get("/base/updateTime", {
    params,
  });
};
