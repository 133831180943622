<template>
  <div class="order_wrap">
    <b v-if="showTitle">{{ titleText }}</b>

    <span
      v-for="(item, idx) in orderList"
      :key="item.value"
      @click="clickItem(item, idx)"
      :class="[checkIdx === idx ? 'checked' : '']"
      >{{ item.text }}
      <AftTooltip v-if="item.text === '观看人次' || item.text === '观看人数'" :code="'qjzb11'" style="top: 1px; left: 2px"> </AftTooltip>
    </span>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  props: {
    orderList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    orderIndex: {
      type: Number,
      default: undefined,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    titleText: {
      type: String,
      default: "排序:",
    },
  },
  watch: {
    orderIndex: {
      handler: function (val) {
        if (!isNaN(val)) {
          this.checkIdx = val;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      checkIdx: 0,
    };
  },
  methods: {
    clickItem(item, idx) {
      let data = _.clone(item);
      this.checkIdx = idx;
      // 因此，我们推荐你始终使用 kebab-case 的事件名。
      this.$emit("order-click", { data, index: idx });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.order_wrap {
  position: relative;
  height: 40px;
  padding: 0 16px;
  @include font_color("font_color22");
  line-height: 40px;
  > b {
    margin-right: 12px;
  }
  > span {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    // padding: 0 22px;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    @include background_color("background_color2");
    position: relative;
    transition: all 0.3s ease-in;
    &:hover {
      @include font_color("font_color25");
      @include background_color("background_color13");
      font-weight: bold;
    }
  }

  > span.checked {
    @include font_color("font_color25");
    @include background_color("background_color13");
    font-weight: bold;
  }
}
</style>
