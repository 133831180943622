import echarts from 'echarts'

function getVirtulData(year) {
    year = year || '2017';
    var date = +echarts.number.parseDate(year + '-01-01');
    var end = +echarts.number.parseDate((+year + 1) + '-01-01');
    var dayTime = 3600 * 24 * 1000;
    var data = [];
    for (var time = date; time < end; time += dayTime) {
        data.push([
            echarts.format.formatTime('yyyy-MM-dd', time),
            Math.floor(Math.random() * 1000)
        ]);
    }
    console.log(data)
    return data;
}

var data = getVirtulData(2015);



let option = {
    tooltip: {
        position: 'top',
        formatter: function(params) {
            return '¥' + params.data[1]
        }
    },
    visualMap: {
        min: 0,
        max: 1000000,
        // inRange: {
        //     color: ['#D6D3B4', '#D99C1F', '#D96A55']
        // },
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        show: true,
        bottom: 'bottom',
        textStyle: {
            color: "#fff"
        }
    },
    calendar: [{
        left: "6%",
        right: '20',
        top: '20',
        bottom: '40',
        range: [],
        cellSize: ['auto', 'auto'],
        splitLine: {
            show: true
        },
        yearLabel: {
            show: false
        },
        monthLabel: {
            nameMap: "cn",
            fontSize: 11,
            color: '#fff'
        },
        dayLabel: {
            show: true,
            nameMap: "cn",
            fontSize: 11,
            color: '#fff'
        }
    }],

    series: [{
        type: 'heatmap',
        coordinateSystem: 'calendar',
        calendarIndex: 0,
        data: [],
    }]
};
export default option
