<template>
  <div class="tabs-content1">
    <div class="tabs-content1-left">
      <div class="title-div">
        <span class="title">组件挂载率</span>
      </div>
      <div class="chart-div">
        <MyChart
          v-if="chartShow"
          :options="getPieOption(overviewData.aweme_live_mount, overviewData.state)"
          :id="'pie-chart-1'"
        />
      </div>
    </div>
    <div class="tabs-content1-right">
      <div class="title-div">
        <span class="title">
          各渠道挂载
          <AftTooltip
            code="cmdd01"
            style="margin-left: 4px;"
          />
        </span>
      </div>
      <div class="chart-div">
        <div>
          <MyChart
            v-if="chartShow"
            :options="getPieOption2(overviewData.live_mount)"
            :id="'pie-chart-2'"
          />
        </div>
        <div>
          <MyChart
            v-if="chartShow"
            :options="getPieOption3(overviewData.aweme_mount)"
            :id="'pie-chart-3'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartPie from "../../options/chartPie.js";
import chartPie2 from "../../options/chartPie2.js";
import api from "@/api/componentsMarket.js";

export default {
  name: "TabsContent1",
  components: {
    NewTabs2,
    MyChart
  },
  data() {
    return {
      chartShow: true,
      pieData: [
        {
          value: 300,
          name: "已挂载",
        },
        {
          value: 22,
          name: "未挂载",
        },
      ],
      overviewData: {
        aweme_live_mount: [],
        aweme_mount: [],
        live_mount: [],
        state: ''
      },
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getOverviewV1();
    },
    getPieOption(data) {
      let option = deepClone(chartPie);
      let text = _.get(this.overviewData, "aweme_live_mount[0].value", 0)
      option.title[0].text = this.$options.filters.numRate2(text)
      option.series[0].data = data;
      if (this.overviewData.state == 1) {
        option.title[1].text = '健康'
        option.title[1].backgroundColor = 'rgba(39,195,70,0.3)'
        option.title[1].textStyle.color = '#27C346'
      } else {
        option.title[1].text = '偏低'
        option.title[1].backgroundColor = 'rgba(255,158,61,0.3)'
        option.title[1].textStyle.color = '#FF9E3D'
      }
      return option;
    },
    getPieOption2(data) {
      let option = deepClone(chartPie2);
      option.series[0].data = data;
      return option;
    },
    getPieOption3(data) {
      let option = deepClone(chartPie2);
      option.title[0].text = '视频组件';
      option.series[0].name = '视频组件';
      option.series[0].data = data;
      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getOverviewV1() {
      let data = this.getParentsParams();
      api.getOverviewV1(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          let newData = {
            aweme_live_mount: [
              {
                value: _.get(data, "aweme_live_mount.mount_rate", 0),
                name: "已挂载",
              },
              {
                value: _.get(data, "aweme_live_mount.not_mount_rate", 0),
                name: "未挂载",
              },
            ],
            aweme_mount: [
              {
                value: _.get(data, "aweme_mount.mount_rate", 0),
                name: "已挂载",
              },
              {
                value: _.get(data, "aweme_mount.not_mount_rate", 0),
                name: "未挂载",
              },
            ],
            live_mount: [
              {
                value: _.get(data, "live_mount.mount_rate", 0),
                name: "已挂载",
              },
              {
                value: _.get(data, "live_mount.not_mount_rate", 0),
                name: "未挂载",
              },
            ],
            state: data.state
          };
          this.overviewData = newData;
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content1 {
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .title-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    > .title {
      color: #ffffff;
      font-size: 14px;
    }
  }
  &-left {
    width: 478px;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 20px;
    .chart-div {
      width: 100%;
      height: 278px;
    }
  }
  &-right {
    width: calc(100% - 494px);
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    margin-left: 16px;
    padding: 20px;
    .chart-div {
      width: 100%;
      height: 278px;
      display: flex;
      flex-wrap: nowrap;
      > div {
        width: 50%;
        height: 100%;
      }
    }
  }
}
</style>
