/** 
* tabData: 按钮组数据
* tabChange: 点击按钮触发的方法
* example: <select-button :buttonData="buttonData" @buttonClick="buttonClick"></select-button>
*/

<template>
  <div class="select-button-wrap">
    <div
      class="item-wrap"
      v-for="(item, index) in buttonData"
      :key="index"
      @click="buttonClick(item.value)"
    >
      <img
        v-if="currentClick == item.value"
        :src="
          require('@/assets/componentsImg/SelectButton/' +
            item.iconName +
            '-active.png')
        "
        :alt="item.label"
      />
      <img
        v-else
        :src="
          require('@/assets/componentsImg/SelectButton/' +
            item.iconName +
            '.png')
        "
        :alt="item.label"
      />
      <span :class="currentClick == item.value ? 'span active' : 'span'">{{
        item.label
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectButton",
  props: {
    buttonData: {
      type: Array,
    },
  },
  data() {
    return {
      currentClick: this.buttonData[0].value,
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    buttonClick(value) {
      if (this.currentClick == value) {
        return false;
      }
      this.currentClick = value;
      this.$emit("buttonClick", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.select-button-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  .item-wrap {
    cursor: pointer;
    margin-left: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;

    img {
      width: 14px;
      height: 14px;
    }
    .span {
      font-size: 16px;
      @include font_color("font_color21");
      margin-left: 10px;
    }
    .span.active {
      @include font_color("font_color20");
    }
  }
  .item-wrap:first-child {
    margin: 0;
  }
}
</style>
