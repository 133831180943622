<template>
  <div :class="shadowShow ? 'select-container open' : 'select-container'">
    <div class="select-button" @click="openShadow">
      <span>{{ findText() }}</span>
      <svg-icon :icon-class="'select-arrow'" class="icon" />
    </div>
    <div class="select-modal-container">
      <div class="select-modal-shadow" @click="closeShadow"></div>
      <div class="select-modal-main">
        <div class="select-modal-main-container">
          <div v-for="item in modalData" :key="item.title">
            <IconTitle
              :icon="item.icon"
              :title="item.title"
              :type="3"
              class="icon-title"
            />
            <div class="button-group">
              <div
                :class="
                  currentKey == ci.key ? 'hover-button active' : 'hover-button'
                "
                v-for="ci in item.children"
                :key="ci.title"
                @click="buttonClick(ci)"
              >
                <span>{{ ci.title }}</span>

                <!-- <CardTooltip class="tooltip-icon" v-if="ci.tooltipTitle">
                  <slot>
                    <div v-html="ci.tooltipTitle"></div>
                  </slot>
                </CardTooltip> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CardTooltip from "@/components/Tooltip/CardTooltip";
import IconTitle from "@/components/IconTitle/IconTitle";
export default {
  name: "MySelect",
  components: {
    // CardTooltip,
    IconTitle,
  },
  props: {
    modalData: {
      type: Array,
      defult: [],
    },
    currentKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      shadowShow: false,
      activeKey: this.currentKey,
    };
  },
  created() {},
  mounted() {
    document.querySelector(".select-modal-container").style.display = "none";
    document.querySelector(".select-modal-main").style.padding = "0px";
  },
  computed: {},
  watch: {},
  methods: {
    openShadow() {
      document.querySelector(".select-modal-container").style.display = "inline-block";
      document.querySelector(".select-modal-main").style.padding =
        "24px 6px 24px 26px";
      setTimeout(() => {
        this.shadowShow = true;
      }, 10);
    },
    closeShadow() {
      if (this.shadowShow) {
        this.shadowShow = false;
        setTimeout(() => {
          document.querySelector(".select-modal-container").style.display = "none";
          console.log(document.querySelector(".select-modal-main"))
          document.querySelector(".select-modal-main").style.padding = "0px";
        }, 200);
      }
    },
    buttonClick(item) {
      console.log(item);
      this.activeKey = item.key;
      this.$emit("update:currentKey", this.activeKey);
      this.$emit("changeItem", item);
      this.closeShadow();
    },
    findText() {
      if (!this.currentKey) {
        return "";
      }
      let text = "";
      this.modalData.forEach((item) => {
        item.children.forEach((i) => {
          if (i.key == this.currentKey) {
            text = `${item.title} · ${i.title}`;
          }
        });
      });
      return text;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.select-container {
  width: 180px;
  height: 40px;
  position: relative;
  .select-button {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 10px 12px 10px 16px;
    @include background_color("background_color46");
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    > span {
      @include font_color("font_color20");
    }
    .icon {
      @include font_color("font_color23");
      transition: all 0.2s;
    }
  }
  .select-modal-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    .select-modal-shadow {
      width: 100vw;
      height: 100vh;
      position: fixed;
      background: rgba(0, 0, 0, 0);
      z-index: 998;
      left: 0;
      top: 0;
      display: none;
    }
    .select-modal-main {
      width: 670px;
      opacity: 0;
      height: 0px;
      background: #3c4252;
      box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.3);
      border-radius: 16px;
      position: absolute;
      top: 0px;
      left: 0;
      z-index: 999;
      transition: all 0.2s;
      padding: 24px 6px 24px 26px;
      &-container {
        width: 100%;
        height: 100%;
        padding-right: 20px;
        overflow-y: auto;
        > div {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          > .icon-title {
            width: 68px;
            padding-top: 6px;
          }
          > .button-group {
            width: 542px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            .hover-button {
              height: 32px;
              margin-left: 8px;
              padding: 8px 15px;
              font-size: 12px;
              color: #d0d6e6;
              border-radius: 8px;
              cursor: pointer;
              &:hover {
                font-weight: 600;
                color: #ffffff;
                background: #535a6e;
              }
              &.active {
                font-weight: 600;
                color: #000000;
                background: #ffca5c;
                .tooltip-icon {
                  color: #000000;
                }
              }
            }
          }
        }
      }
    }
  }

  &.open {
    .select-button {
      .icon {
        transform: rotate(180deg);
      }
    }
    .select-modal-container {
      .select-modal-shadow {
        display: block;
      }
      .select-modal-main {
        opacity: 1;
        height: 256px;
      }
    }
  }
}
</style>
