<template>
  <div class="calendar-container">
    <!-- 日历 -->
    <!-- <a-spin tip="Loading..." :spinning="calenderLoading"> -->

    <div class="card-title">
      <div>
        <svg-icon icon-class="title" class="svg-title" />
        <span>直播日历</span>
      </div>
    </div>
    <div class="calendar">
      <ul class="calendar-box calendar-header">
        <li v-for="(item, index) in week" :key="index">
          {{ item }}
        </li>
      </ul>
      <ul class="calendar-box calendar_content">
        <li v-for="index in dateWeek" :key="index" class="big_border"></li>
        <li
          v-for="item in dayList"
          :key="item.id"
          :class="item.live_infos ? 'big_border day_cell' : 'big_border'"
        >
          <span>{{ item.day }}</span>
          <span class="topic_length" v-if="item.live_infos">
            <a
              :class="
                item.id == activeLive
                  ? 'live_count active_live'
                  : 'live_count common_style'
              "
              @click="selectDay(item.id)"
              >{{ item.live_infos.length }}</a
            >
            <p class="live_tips">今日直播场次</p>
          </span>
        </li>
        <li v-for="index in lassWeek" :key="index * 31" class="big_border"></li>
      </ul>
      <!-- 表格 -->
      <div class="table_class">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :row-key="(record) => record.room_id"
          :customRow="rowClick"
          :scroll="{ y: 450 }"
          :pagination="false"
          :locale="locale"
        >
          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            {{ Number(index) + 1 }}
          </div>
          <div slot="title_img" slot-scope="text, record" class="table_title">
            <div class="img_box">
              <img :src="record.cover" />
            </div>
            <span class="title">{{ text }}</span>
          </div>
          <a
            slot="actions"
            slot-scope="text, record"
            class="detail_code"
            @click="gotoVideoDetail(record.room_id)"
          >
            详情
          </a>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
import * as vuex from "vuex";
import Empty from "@/components/Empty/Empty";
const { mapState } = vuex.createNamespacedHelpers("production");
export default {
  name: "CalendarLive",
  props: {
    calendarData: {
      type: Object,
      require: true,
    },
    month: {
      type: Object,
      require: true,
    },
    platform: {
      type: String,
      require: false,
    },
  },
  computed: {
    ...mapState({
      calenderLoading: (state) => state.calenderLoading,
    }),
  },
  data() {
    return {
      week: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      dateWeek: [],
      lassWeek: [],
      dayList: [],
      tableList: [],
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      columns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          title: "直播",
          dataIndex: "title",
          width: "30%",
          scopedSlots: { customRender: "title_img" },
        },
        {
          title: "直播时长",
          dataIndex: "statistics_live_time",
          width: "10%",
          customRender: (text) => {
            return (
              <div class="tb_font">
                {this.$options.filters.disposeLongTime_m(text)}
              </div>
            );
          },
        },
        {
          title: "开播时间",
          dataIndex: "publish_time",
          width: "10%",
          customRender: (text) => {
            return <div class="tb_font">{text}</div>;
          },
        },

        {
          title: "最高观看人数",
          dataIndex: "statistics_max_online_user",
          width: "12%",
          customRender: (text) => {
            return (
              <div class="tb_font">{this.$options.filters.changeNum(text)}</div>
            );
          },
        },
        {
          title: "总观看人次",
          dataIndex: "statistics_total_watch_user_time",
          width: "10%",
          customRender: (text) => {
            return (
              <div class="tb_font">{this.$options.filters.changeNum(text)}</div>
            );
          },
        },
        {
          title: "粉丝变化",
          dataIndex: "statistics_fans_add",
          width: "10%",
          customRender: (text) => {
            return (
              <div class="tb_font">{this.$options.filters.changeNum(text)}</div>
            );
          },
        },
        {
          title: "互动量",
          dataIndex: "statistics_total_polling_count",
          width: "10%",
          customRender: (text) => {
            return (
              <div class="tb_font">{this.$options.filters.changeNum(text)}</div>
            );
          },
        },
        {
          title: "操作",
          dataIndex: "actions",
          width: "8%",
          scopedSlots: { customRender: "actions" },
        },
      ],
      activeLive: 0,
    };
  },
  created() {
    this.getMonth(this.month);
  },
  watch: {
    calendarData: {
      handler(val) {
        this.tableList = [];
        this.getMonth(this.month);
        this.diffCalender(val);
      },
      deep: true,
    },
  },
  methods: {
    // 获取某月1号星期几
    getNowMonthWeek(date) {
      let week = this.$moment(date).startOf("month").day();
      this.dateWeek = week;
    },
    // 获取某月最后一天星期几
    getLassMontWeek(date) {
      let num = this.$moment(date).endOf("month").day();
      this.lassWeek = 6 - num;
    },
    // 计算月份信息
    getMonth(date) {
      this.getNowMonthWeek(date);
      this.getLassMontWeek(date);
      let monthLength = this.$moment(date).daysInMonth();
      let dayStr = this.$moment(date).format("YYYY-MM");
      let time_date = this.$moment().format("MMM Do");
      this.monthTips = dayStr;
      let dateList = [];
      for (let i = 1; i <= monthLength; i++) {
        let obj = {
          id: i >= 10 ? `${dayStr}-${i}` : `${dayStr}-0${i}`,
          day: i >= 10 ? i : `0${i}`,
          date: time_date,
        };
        dateList.push(obj);
      }
      this.dayList = dateList;
    },
    diffCalender(value) {
      console.log("传入对value值看看", value);
      let calenderArr = this.dayList;
      calenderArr.forEach((ele) => {
        for (let i in value) {
          if (i == ele.id) {
            ele.live_infos = value[i];
            this.tableList = this.tableList.concat(value[i]);
          }
        }
      });
      this.tableList = this.tableList.reverse();
      this.dayList = calenderArr;
    },
    // 时长计算
    diffTimeLong(value) {
      let time = "";
      var days = parseInt(value / 1440);
      var hours = parseInt((value % 1440) / 60);
      var minutes = parseInt((value % 1440) % 60);
      if (days > 0) {
        time += days + "天";
      }
      if (hours > 0) {
        time += hours + "小时";
      }
      if (minutes > 0) {
        time += minutes + "分钟";
      }
      return time;
    },
    // 选择筛选某天直播
    selectDay(id) {
      this.activeLive = id;
      for (let i in this.calendarData) {
        if (i == id) {
          this.tableList = this.calendarData[i];
        }
      }
    },
    // 跳转详情
    gotoVideoDetail(id) {
      let routeUrl = this.$router.resolve({
        path: `/liveDetail?id=${id}&platform=${
          this.platform || this.$route.query.type
        }`,
      });
      window.open(routeUrl.href, "_blank");
    },

    rowClick(record, index) {
      return {
        on: {
          click: () => {
            console.log(record);
            this.gotoVideoDetail(record.room_id);
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
@import "@/assets/styles/_handle.scss";
.calendar-container {
  @include background_color("background_color2");
  padding: 0 24px;
}

.card-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0px;
  .svg-title {
    font-size: 16px;
    margin-right: 8px;
  }
  > div > span {
    font-size: 16px;
    @include font_color("font_color2");
  }
}
.calendar {
  width: 100%;
  @include background_color("background_color1");
  border-radius: 4px;
  margin-bottom: 24px;
  &-header {
    padding: 24px 0 16px;
    @include background_color("background_color2");
  }
  &-box {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    li {
      flex: 0 0 14.28%;
      padding-left: 16px;
      @include font_color("font_color3");
    }
  }
}
.calendar_content {
  border: 1px solid;
  @include border_color("border_color1");
  border-top: none;
  .big_border {
    @include border_color("border_color1");
    height: 90px;
    padding-top: 12px;
    font-size: 16px;
    border: 1px solid;
    border-bottom: none;
    border-left: none;
  }
  .day_cell {
    // @include font_color("font_color2");
    @include background_color("background_color37");
    display: flex;
    .topic_length {
      display: inline-block;
      margin: 12px 0 0 18px;

      .live_count {
        display: inline-block;
        width: 76px;
        height: 32px;
        border-radius: 33px;
        text-align: center;
        line-height: 32px;
        font-size: 20px;
      }
      .common_style {
        @include font_color("font_color6");
        @include background_color("background_color30");
      }
      .active_live {
        @include font_color("font_color1");
        @include background_color("background_color17");
      }
      .live_tips {
        margin-top: 4px;
        font-size: 12px;
        @include font_color("font_color3");
      }
    }
  }
  li:nth-child(7n) {
    border-right: none;
  }
}
.table_title {
  display: flex;
  align-items: center;
  .img_box {
    width: 56px;
    height: 56px;
    margin: 12px 8px 12px 0;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  .title {
    flex: 2.5;
    @include font_color("font_color2");
  }
}
.table_class {
  @include background_color("background_color2");
  padding: 16px 0px 24px 0px;
}
.tb_font {
  @include font_color("font_color3");
}
.detail_code {
  @include font_color("font_color3");
  &:hover {
    @include font_color("font_color13");
  }
}
.font-color2 {
  @include font_color("font_color2");
}
</style>
<style lang="scss">
.ant-table-pagination.ant-pagination {
  float: none !important;
  margin: 16px 0;
  text-align: center;
}
</style>