import filters from '@/filters'
let option = {
  legend: {
    icon: 'circle',
    top: '24',
    itemWidth: 6,
    itemGap: 20,
    textStyle: {
      color: '#8890A6'
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: '#3C4252',
    textStyle: {
      color: '#fff'
    },
    padding: [10, 10],
  },
  grid: {
    top: '70',
    bottom: '47'
  },

  xAxis: [{
    type: "category",
    boundaryGap: false,
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6',  //更改坐标轴文字颜色
        fontSize: 12     //更改坐标轴文字大小
      }
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)',
      }
    },
    data: [],
  }],
  yAxis: [{
    type: 'value',
    axisTick: {
      show: false
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: '#8890A6'
      },
      formatter: function(value) {
          return filters.changeNum(Number(value))
      }
    },
    splitLine: {
      show: false
    }
  }, {
    type: 'value',
    position: 'right',
    axisTick: {
      show: false
    },
    axisLabel: {
      textStyle: {
        color: '#8890A6'
      },
      formatter: function(value) {
          return filters.changeNum(Number(value))
      }
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: false
    }
  }],
  series: [
  ]
};


export default option