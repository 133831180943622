<template>
  <div class="table-item2">
    <div :class="['card-title', active ? 'active' : '']">
      {{ list.title || "-" }}
    </div>
    <div class="table-body" v-if="list.data && list.data.length">
      <div
        class="table-body-item"
        v-for="(item, index) in returnList(list.data)"
        :key="list.key + index"
      >
        <div class="table-body-item-left">
          <BaseImage
            :src="item.awemeCover || item.liveCover || ''"
            :default-image="defaultCoverImg"
            fit="contain"
            width="90px"
            height="120px"
            radius="4px"
            style="background-color: #21232c;"
          />
          <span :class="['ranking', `ranking${index + 1}`]">{{
            index + 1
          }}</span>
          <svg-icon
            class="platform-icon"
            :icon-class="
              item.platform == '抖音'
                ? 'platform-color-douyin'
                : 'platform-color-kuaishou'
            "
          />
        </div>
        <div class="table-body-item-right">
          <div class="title">
            {{ item.awemeTitle || item.liveTitle || "-" }}
          </div>
          <div class="author">
            <BaseImage
              :src="item.dealerAuthorAvatar || ''"
              :default-image="defaultAvatarImg"
              width="24px"
              height="24px"
              fit="cover"
              round
            />
            <span>{{ item.dealerName || "-" }}</span>
          </div>
          <div class="statistics">
            <span>{{ list.targetName || "-" }}</span>
            <span>{{ item[list.targetKey] | changeNumNoRound }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-box" v-else>
      <Empty title="暂无数据" />
    </div>
  </div>
</template>

<script>
import defaultAvatarImg from "@/assets/default/default1-1.png";
import defaultCoverImg from "@/assets/default/default3-4.png";
import BaseImage from "@/components/BaseImage";
import Empty from "@/components/Empty/Empty";

export default {
  name: "TableItem1",
  components: {
    BaseImage,
    Empty,
  },
  props: {
    list: {
      type: Object,
      default: () => {
        return {};
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultAvatarImg,
      defaultCoverImg
    };
  },
  computed: {},
  watch: {},
  methods: {
    returnList(data) {
      if (data && data.length) {
        return data.slice(0, 3);
      } else {
        return [];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.table-item2 {
  width: 100%;
  height: 486px;
  padding-right: 7px;
  cursor: pointer;
  .card-title {
    width: 100%;
    height: 54px;
    background: #242832;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.88);
    line-height: 54px;
    padding-left: 20px;
    border-radius: 16px 16px 0 0;
    &.active {
      color: #ffcc55;
      background: #413e3d;
      position: relative;
      &::before {
        content: "";
        height: 0px;
        width: 0px;
        content: "";
        position: absolute;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #413e3d;
        border-right: 7px solid transparent;
        right: -13px;
        top: 20px;
      }
    }
  }
  .table-body {
    width: 100%;
    height: 432px;
    background: #2b2f3b;
    border-radius: 0 0 16px 16px;
    &-item {
      height: 144px;
      width: 100%;
      border-bottom: 1px solid #3c4252;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      &:last-child {
        border: none;
      }
      &-left {
        width: 90px;
        height: 120px;
        position: relative;
        .platform-icon {
          font-size: 20px;
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
      &-right {
        width: calc(100% - 98px);
        height: 120px;
        position: relative;
        > .title {
          width: 100%;
          height: 44px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.88);
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .author {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.55);
          line-height: 22px;
          margin: 8px 0 0 0;
          > span {
            margin-left: 4px;
          }
        }
        .statistics {
          position: absolute;
          bottom: 0;
          right: 0;
          > span:first-child {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.55);
            line-height: 20px;
          }
          > span:last-child {
            font-size: 12px;
            font-weight: bold;
            color: #ffcc55;
            line-height: 20px;
            margin-left: 4px;
          }
        }
      }
    }
  }
  .empty-box {
    width: 100%;
    height: 432px;
    background: #2b2f3b;
    border-radius: 0 0 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ranking {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.12);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 8px;
  bottom: 8px;
  &.ranking1,
  &.ranking2,
  &.ranking3 {
    background: rgba(255, 204, 85, 0.12);
    color: #ffcc55;
    font-weight: bold;
  }
}
</style>
