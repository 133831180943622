<template>
  <div
    :class="'card-container'"
    :style="{
      '--background': background,
      '--color': color
    }"
  >
    <span class="name">
      <span>{{ name }}</span>
      <AftTooltip
      v-if="tooltipCode"
      :code="tooltipCode"
      style="margin-left: 8px;"
    />
    </span>
    <span>{{ value }}</span>
    <svg-icon :icon-class="icon" class="icon" />
  </div>
</template>

<script>
// import CardTooltip from "@/components/Tooltip/CardTooltip";
export default {
  name: "HoverCard",
  components: {
    // CardTooltip,
  },
  props: {
    background: {
      type: String,
      default: "#fff"
    },
    color: {
      type: String,
      default: "#000"
    },
    icon: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: "-"
    },
    value: {
      default: "-"
    },
    tooltipCode: {
      type: String,
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

.card-container {
  width: 240px;
  height: 90px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  background: #242832;
  // transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px 16px;
  &:hover {
    background: var(--background) !important;
    > span:nth-child(1) {
      color: var(--color) !important;
    }
    > span:nth-child(2) {
      color: var(--color) !important;
    }
    .icon {
      color: rgba(255, 255, 255, 0.2);
    }
  }
  > span:nth-child(1) {
    display: block;
    font-size: 14px;
    color: #9ca8ba;
    line-height: 22px;
  }
  > span:nth-child(2) {
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.88);
    line-height: 28px;
  }

  .name {
    display: flex;
    align-items: center;
  }

  .icon {
    transition: all 0.3s;
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 58px;
    color: rgba(255, 255, 255, 0.1);
  }
}
</style>
