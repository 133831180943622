<template>
  <div class="button-container" @click="routerTo">
    <div class="button-container-flex">
      <svg-icon
        :icon-class="icon"
        class="button-icon"
        v-if="icon"
        style="margin-right: 10px"
      />
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RouterButton",
  props: {
    title: {
      type: String,
      default: "-",
    },
    icon: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
  },
  methods: {
    routerTo() {
      if (!this.path) {
        return false;
      }
      this.$router.push({
        path: this.path,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";
.button-container {
  padding: 8px 26px;
  height: 40px;
  display: inline-block;
  border-radius: 8px;
  @include background_color("background_color75");
  cursor: pointer;
  transition: all 0.3s;
  &-flex {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .button-icon {
      font-size: 24px;
    }
    > span {
      font-size: 12px;
      @include font_color("font_color26");
    }
  }
  &:hover {
    @include background_color("background_color76");
  }
}
</style>
