
var days = ['周日', '周六', '周五', '周四', '周三', '周二', '周一'];

var option = {
    tooltip: {
        position: 'top'
    },
    animation: false,
    grid: {
        height: '70%',
        top: '0%'
    },
    xAxis: {
        type: 'category',
        data: [],
        splitArea: {
            show: true
        },
        axisLabel: { //  改变x轴字体颜色和大小
            textStyle: {
                color: "rgba(250,250,250,0.6)",
                fontSize: 12
            }
        }
    },
    yAxis: {
        type: 'category',
        data: days,
        splitArea: {
            show: true
        },
        axisLabel: { //  改变x轴字体颜色和大小
            textStyle: {
                color: "rgba(250,250,250,0.6)",
                fontSize: 12
            }
        }
    },
    visualMap: {
        min: 0,
        max: 239,
        calculable: true,
        orient: 'horizontal',
        // inRange: {
        //     color: ['#ffffff', '#f5eba4', '#e5af88', '#db8d78', '#d4756b', '#c75658', '#c0444d']
        // },
        left: 'center',
        bottom: '0%',
        textStyle: {
            color: "#fff"
        }
    },
    series: [{
        name: '直播场次',
        type: 'heatmap',
        data: [],
        label: {
            show: true
        },
        emphasis: {
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
        }
    }]
};
export default option