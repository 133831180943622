import echarts from 'echarts'
var option = {
  backgroundColor: 'rgba(0, 0, 0, 0)',
  grid: {
    containLabel: true,
    left: '20',
    bottom: '10%',
    top: '2%'
  },

  tooltip: {
    trigger: 'axis',
    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
      type: 'none'        // 默认为直线，可选为：'line' | 'shadow'
    },
    backgroundColor: '#3c4252',
    textStyle: {
      color: '#fff',
    },
  },
  xAxis: {
    splitNumber: 5,
    axisLabel: {
      show: false
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    }
  },
  yAxis: [{
    data: [],
    axisLabel: {
      fontSize: 14,
      lineHeight: 10,
      color: '#9ca8ba', 
      interval: 0, // x轴文字标签换行
      formatter: function (value) {
        return value.split(' ').join('\n\n')
      }
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#323744'
      }
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    }
  }],
  series: [{
    type: 'bar',
    name: '',
    stack: '2',
    legendHoverLink: false,
    barWidth: 8,
    itemStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
          offset: 0,
          color: '#8BAFFE'
        }, {
          offset: 1,
          color: '#4781FF'
        }], false),
        barBorderRadius: [0, 20, 20, 0]
      },
    },
    data: []
  }]
}

export default option