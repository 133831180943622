import { render, staticRenderFns } from "./SwitchActivity.vue?vue&type=template&id=8b951618&scoped=true"
import script from "./SwitchActivity.vue?vue&type=script&lang=js"
export * from "./SwitchActivity.vue?vue&type=script&lang=js"
import style0 from "./SwitchActivity.vue?vue&type=style&index=0&id=8b951618&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b951618",
  null
  
)

export default component.exports