var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assistant-chart"},[_c('div',{staticClass:"assistant-chart-main"},[_c('div',{staticClass:"assistant-chart-main-title"},[(_vm.currentButtonClick == '2')?_c('div',[_c('span',{staticClass:"card-title"},[_vm._v("本场数据")]),_c('span',{staticClass:"tooltip-name"},[_vm._v("进场人数")]),_c('AftTooltip',{staticStyle:{"top":"-4px"},attrs:{"code":'qjzb07'}}),_c('span',{staticClass:"tooltip-name"},[_vm._v("推流速度")]),_c('AftTooltip',{staticStyle:{"top":"-4px"},attrs:{"code":'qjzb08'}}),_c('span',{staticClass:"tooltip-name"},[_vm._v("预估留存率")]),_c('AftTooltip',{staticStyle:{"top":"-4px"},attrs:{"code":'qjzb09'}})],1):_c('div',[_c('span',{staticClass:"card-title"},[_vm._v("本场数据")])]),_c('CardButtonGroup',{directives:[{name:"show",rawName:"v-show",value:(_vm.platform == 'douyin'),expression:"platform == 'douyin'"}],attrs:{"tabData":_vm.buttonData},on:{"tabChange":_vm.buttonClick}})],1),_vm._m(0),_c('div',{staticClass:"fragment-box"},[_c('div',{staticClass:"fragment-box-list"},[_c('span',[_vm._v("片段")]),_vm._l((_vm.demoData),function(item,index){return _c('div',{key:index,class:[
            'fragment-item',
            item.status == 2
              ? 'success'
              : item.status == 6
              ? 'error'
              : 'default',
            _vm.currentItem == item ? 'active' : '',
          ],on:{"click":function($event){_vm.currentItem = item}}},[_c('a-popover',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"tooltip-content"},[_c('div',{staticClass:"tooltip-content-title"},[_c('svg-icon',{staticStyle:{"font-size":"26px","margin-right":"8px"},attrs:{"icon-class":'card-tooltip'}}),_c('span',[_vm._v("智能复盘小助手")])],1),_c('div',{staticClass:"tooltip-content-main"},[_vm._v(" "+_vm._s(item.tooltip)+" ")])])]),(item.tooltip)?_c('div',{staticClass:"fragment-item-container"},[_c('svg-icon',{attrs:{"icon-class":'fragment-warning'}})],1):_vm._e()],2)],1)})],2),_vm._m(1)]),_c('div',{staticClass:"tabs-div"},[_c('NewTabs',{attrs:{"tabData":_vm.tabsData},on:{"tabChange":_vm.changeTabs}})],1),_c('div',{staticClass:"tabs-content"},[(_vm.tabsKey == '1')?_c('TabContent1',{attrs:{"tabData":_vm.currentItem && _vm.currentItem['tab_a']}}):_vm._e(),(_vm.tabsKey == '2')?_c('TabContent2',{attrs:{"tabData":_vm.currentItem && _vm.currentItem['tab_b']}}):_vm._e(),(_vm.tabsKey == '3')?_c('TabContent3',{attrs:{"tabData":_vm.currentItem && _vm.currentItem['tab_c']}}):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart-box"},[_c('div',{attrs:{"id":"chart"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment-box-lenged"},[_c('span',{staticClass:"lenged-icon error"}),_c('span',{staticClass:"label"},[_vm._v("数据异常")]),_c('span',{staticClass:"lenged-icon success"}),_c('span',{staticClass:"label"},[_vm._v("数据优异")])])
}]

export { render, staticRenderFns }