<template>
  <div class="live-list-container">
    <div class="data-box">
      <div class="data-box-left">
        <div class="card-title">数据概览</div>
        <div class="card-tips" v-if="currentTarget">
          <span
            >{{
              getArrayLastItem(currentTarget.targetChart).start_time.slice(
                5,
                10
              )
            }}
            -
            {{
              getArrayLastItem(currentTarget.targetChart).end_time.slice(5, 10)
            }}，{{ currentTarget.targetName }}：</span
          >
          <span class="number">{{
            getArrayLastItem(currentTarget.targetChart).value | changeNum
          }}</span>
          <span>，环比增长：</span>
          <span class="number">{{
            getArrayLastItem(currentTarget.targetChart).ratio | numRate2
          }}</span>
        </div>
        <div class="card-container">
          <div
            :class="[
              'card-container-item',
              currentTarget &&
              currentTarget.targetCountKey == item.targetCountKey
                ? 'active'
                : '',
            ]"
            v-for="item in targetData"
            :key="item.targetCountKey"
            @click="currentTarget = item"
          >
            <span class="label">{{ item.targetName }}</span>
            <span class="number">{{ item.targetCount | changeNum }}</span>
          </div>
        </div>
      </div>
      <div class="data-box-right">
        <div class="chart-box">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(currentTarget && currentTarget.targetChart)"
            :id="'bar-chart-live'"
          />
        </div>
      </div>
    </div>
    <div style="margin: 16px 0">
      <ButtonGroup
        :tab-data="getPrincipalState() != '3' ? tabList2 : tabList"
        @tabChange="handleTypeClick"
      />
    </div>
    <div style="padding-top: 20px; background: #2b2f3b; border-radius: 16px">
      <LiveCalendar
        ref="LiveCalendar"
        :calendar-data="returnCalendarData(`calendarData${tabType}`)"
        :month="$moment(getParams().start_time)"
        :showTag="tabType == '3'"
      />
    </div>
  </div>
</template>

<script>
const targetData = {
  3: [
    {
      targetName: "直播场次",
      targetCountKey: "total_live_count",
      targetCount: "",
      targetChartKey: "live_count_chart",
      targetChart: [],
    },
    {
      targetName: "观看人次",
      targetCountKey: "total_live_watch_cnt_count",
      targetCount: "",
      targetChartKey: "total_live_watch_cnt_chart",
      targetChart: [],
    },
  ],
  2: [
    {
      targetName: "直播场次",
      targetCountKey: "total_live_count",
      targetCount: "",
      targetChartKey: "live_count_chart",
      targetChart: [],
    },
    {
      targetName: "观看人次",
      targetCountKey: "total_live_watch_cnt_count",
      targetCount: "",
      targetChartKey: "total_live_watch_cnt_chart",
      targetChart: [],
    },
    {
      targetName: "场均观看人次",
      targetCountKey: "total_live_an_aug_cnt_count",
      targetCount: "",
      targetChartKey: "total_live_an_aug_cnt_chart",
      targetChart: [],
    },
  ],
};
const targetData_kuaishou = {
  3: [
    {
      targetName: "直播场次",
      targetCountKey: "total_live_count",
      targetCount: "",
      targetChartKey: "live_count_chart",
      targetChart: [],
    },
    {
      targetName: "观看人数",
      targetCountKey: "total_live_watch_ucnt_count",
      targetCount: "",
      targetChartKey: "total_live_watch_ucnt_chart",
      targetChart: [],
    },
  ],
  2: [
    {
      targetName: "直播场次",
      targetCountKey: "total_live_count",
      targetCount: "",
      targetChartKey: "live_count_chart",
      targetChart: [],
    },
    {
      targetName: "观看人数",
      targetCountKey: "total_live_watch_ucnt_count",
      targetCount: "",
      targetChartKey: "total_live_watch_ucnt_chart",
      targetChart: [],
    },
    {
      targetName: "场均观看人数",
      targetCountKey: "total_live_an_aug_ucnt_count",
      targetCount: "",
      targetChartKey: "total_live_an_aug_ucnt_chart",
      targetChart: [],
    },
  ],
};

const tabList = [
  {
    label: "全部直播",
    value: "1",
  },
  {
    label: "爆款直播",
    value: "2",
  },
  {
    label: "违规直播",
    value: "3",
  },
];
const tabList2 = [
  {
    label: "全部直播",
    value: "1",
  },
  {
    label: "爆款直播",
    value: "2",
  },
];
import api from "@/api/production";
import LiveCalendar from "@/components/LiveCalendar/new_index.vue";
import MyChart from "@/components/MyChart/MyChart";
import chartBar3 from "../options/chartBar3.js";
import deepClone from "@/utils/clone-utils";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import { getPrincipalState } from "@/utils/auth";

export default {
  components: {
    LiveCalendar,
    MyChart,
    ButtonGroup,
  },
  data() {
    return {
      calendarData1: null,
      liveMessage: {},
      tabList: deepClone(tabList),
      tabList2: deepClone(tabList2),
      tabType: "1",
      targetData: [],
      currentTarget: null,
      chartShow: true,
    };
  },
  computed: {
    calendarData2() {
      let data = this.calendarData1.filter((item) => {
        return item.live_type && item.live_type.includes('1');
      });
      return data;
    },
    calendarData3() {
      let data = this.calendarData1.filter((item) => {
        return item.live_type && item.live_type.includes('2');
      });
      return data;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getLiveDataChart();
      this.getLiveDataList();
      // this.getCalendarLive();
    },

    // 视频数据概览
    getLiveDataChart() {
      let parentParams = this.getParams();
      console.log("parentParams", parentParams);
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
          date_type: parentParams.dateType,
        },
      };
      // 清空targetData
      this.targetData = [];
      this.currentTarget = null;
      api.getLiveDataChart(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          // 按月和周拿到当前需要展示的指标
          let defaultData = [];
          if (parentParams.type == "kuaishou") {
            defaultData = deepClone(targetData_kuaishou)[parentParams.dateType];
          } else {
            defaultData = deepClone(targetData)[parentParams.dateType];
          }
          // 补全接口数据到targetData
          this.targetData = defaultData.map((item) => {
            let targetChart = res.data.data[item.targetChartKey] || [];
            return {
              ...item,
              targetCount: targetChart[0].value,
              targetChart: targetChart.reverse(),
            };
          });
          this.currentTarget = this.targetData[0];
        } else {
          console.error("获取直播数据概览失败");
        }
      });
    },

    // 直播列表
    getLiveDataList() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        data: {
          author_ids: [parentParams.author_id],
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
          date_type: parentParams.dateType,
        },
      };

      api.getLiveDataList(body).then((res) => {
        if (res.data.code === 0) {
          console.log("getLiveDataList", res.data);
          this.calendarData1 = res.data.data
          let newTab = [];
          if (getPrincipalState() != "3") {
            newTab = deepClone(tabList2);
          } else {
            newTab = deepClone(tabList);
          }

          newTab = newTab.map((item) => {
            return {
              label:
                item.label + `(${this["calendarData" + item.value].length})`,
              value: item.value,
            };
          });
          this.tabList = newTab;
          this.tabList2 = newTab;
        } else {
          console.error("获取直播列表失败");
        }
      });
    },

    returnCalendarData(type) {
      let data = this[type] || [];
      console.log(data);
      let newData = data.reduce((acc, cur) => {
        let currentTime = cur.publish_time.slice(0, 10);
        if (acc[currentTime]) {
          acc[currentTime].push(cur);
        } else {
          acc[currentTime] = [cur];
        }
        return acc;
      }, {});
      return newData;
    },

    getBarOption(data) {
      let parentParams = this.getParams();
      let option = deepClone(chartBar3);
      if (data && data.length) {
        let xData = data.map((item) => {
          return item.item;
        });
        option.xAxis.data = xData;
        if (parentParams.dateType == 3) {
          option.xAxis.axisLabel.formatter = "近{value}月";
        } else if (parentParams.dateType == 2) {
          option.xAxis.axisLabel.formatter = "近{value}周";
        }
        option.tooltip.formatter = (params) => {
          let newParams = params[0];
          return `
          <div style="background: #3C4252;line-height: 24px;font-size: 12px;padding: 4px;">
            <div style="color: rgba(255,255,255,0.88);font-weight: bold;">${newParams.data.start_time.slice(
              0,
              10
            )} - ${newParams.data.end_time.slice(0, 10)}</div>
            <div><span style="color: rgba(255,255,255,0.55);">${
              this.currentTarget.targetName
            }: </span><span style="color: #fff;">${this.$options.filters.changeNum(
            newParams.data.value
          )}</span></div>
            <div><span style="color: rgba(255,255,255,0.55);">环比增长: </span><span style="color: #fff;">${this.$options.filters.numRate2(
              newParams.data.ratio
            )}</span></div>
          </div>`;
        };
        option.series[0].data = data;
      }
      return option;
    },

    getArrayLastItem(arr) {
      if (arr && arr.length) {
        return arr[arr.length - 1];
      } else {
        return {};
      }
    },

    // 切换视频类型tab
    handleTypeClick(val) {
      if (this.tabType == val) {
        return false;
      }
      this.$refs.LiveCalendar.activeLive = 0;
      this.tabType = val;
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
    // getCalendarLive() {
    //   let parentParams = this.getParams();
    //   let body = {
    //     type: parentParams.type,
    //     params: {
    //       author_id: parentParams.author_id,
    //       start_time: parentParams.start_time,
    //       end_time: parentParams.end_time,
    //     },
    //   };
    //   api.getCalendarLive(body).then((res) => {
    //     if (res.data.code === 0) {
    //       console.log(res.data);
    //       this.calendarData = res.data.data || {};
    //     } else {
    //       console.error("获取直播列表失败");
    //     }
    //   });
    // },
    getPrincipalState() {
      return getPrincipalState();
    },
    getParams() {
      return this.$parent.getParams();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.live-list-container {
  width: 100%;
  .data-box {
    width: 100%;
    height: 304px;
    display: flex;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-wrap: nowrap;
    &-left {
      width: 436px;
      height: 100%;
      .card-title {
        height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
        line-height: 24px;
      }
      .card-tips {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #242832;
        border-radius: 8px;
        margin: 20px 0 16px;
        padding-left: 12px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        .number {
          color: #ffcc55;
        }
      }
      .card-container {
        width: 100%;
        height: 164px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
        &-item {
          width: 140px;
          height: 78px;
          background: none;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 12px;
          .label {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.55);
            line-height: 22px;
            margin-bottom: 4px;
          }
          .number {
            font-size: 20px;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.88);
            line-height: 28px;
          }
          &:hover {
            background: #242832;
          }
          &.active {
            background: #ffcc55;
            .label {
              color: rgba(0, 0, 0, 0.55);
            }
            .number {
              color: rgba(0, 0, 0, 0.88);
            }
          }
        }
      }
    }
    &-right {
      width: calc(100% - 436px);
      height: 100%;
      .chart-box {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>