import Nr from '@/utils/NetworldRequest'

const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()

const activity_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API
}).getAxios()


export default {
  // 数据趋势(累计)
  fetchDataTrend1(params) {
    return activity_request.get('/jjt/activity/web/profile/dataTrend/v2', { params })
  },
  // 数据趋势(每日)
  fetchDataTrend2(params) {
    return activity_request.get('/jjt/activity/web/profile/dataTrend/v3', { params })
  },
  // 数据趋势(今日)
  fetchDataTrend3(params) {
    return activity_request.get('/jjt/activity/web/profile/dataTrend', { params: params })
  },
  // 任务中心
  getTaskList(params) {
    return activity_request.get('/jjt/activity/web/profile/task', { params: params })
  },
  // 区域进度
  getRegionProgress(params) {
    return activity_request.get('/jjt/activity/web/profile/regionalSchedule', { params: params })
  },
  // 活动榜单
  getActivityList(params) {
    return activity_request.get('/jjt/activity/web/profile/activityList', { params: params })
  },
  // 活动数据更新时间
  getUpdateTime(params) {
    return activity_request.get('/jjt/activity/web/statistic/updateTime', { params: params })
  },
  // 获取大区
  getAreaInfoByActivity(params) {
    return activity_request.get('/jjt/activity/web/statistic/regionList', {params: params})
  },
  // 获取活动数据
  getActivityData(params) {
    return activity_request.get('/jjt/activity/web/profile/data', {params: params})
  },
  
}