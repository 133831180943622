import echarts from 'echarts'
let color = ['#E6704E', '#F8AC68', '#F8E080', '#389C90', '#38869C', '#535A6E'];
let echartData = [{
    name: "1",
    value: "0"
}, {
    name: "2",
    value: "0"
}
];

var option = {
    backgroundColor: 'rgba(0,0,0,0)',
    color: color,
    tooltip: {
        trigger: 'item'
    },
    series: [{
        type: 'pie',
        radius: ['35%', '50%'],
        center: ['50%', '50%'],
        data: echartData,
        // hoverAnimation: false,
        itemStyle: {
            normal: {
                borderColor: 'rgba(0,0,0,0)',
                borderWidth: 2
            }
        },
        labelLine: {
            length: 20,
            length2: 15,
        },
        label: {
            alignTo: 'edge',
            minMargin: 5,
            normal: {
                formatter: params => {
                    return (
                        '{name|' + params.name + '}\n{value|' + params.value + '}'
                    );
                },
                padding: [0, -10, 0, -10],
                rich: {
                    name: {
                        lineHeight: 13,
                    },
                    value: {
                        lineHeight: 17,
                        fontWeight: 'bold'
                    },
                }
            }
        },
    }]
}

export default option