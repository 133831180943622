<template>
  <a-spin class="wrap" :spinning="loading">
    <div class="wrap-title">
      <div>
        <span class="title">数据趋势</span>
        <AftTooltip code="hdzb07" style="margin-left: 8px;" />
        <span class="date"
          >活动日期：{{ currentItem.startTime }} 至
          {{ currentItem.endTime }}</span
        >
      </div>
      <div>
        <NewTabs2
          ref="NewTabs2"
          :default-value="active"
          :tabData="tabsData"
          @tabChange="handleTabChange"
        />  
      </div>
      <!-- <div class="more-text" @click="routerTo">
        <span>更多数据</span>
        <svg-icon icon-class="arrow-down" class="arrow-icon" />
      </div> -->
    </div>
    <div class="chart-box">
      <MyChart
        v-if="chartOption"
        id="line-chart"
        :options="chartOption"
      />
      <template v-if="currentItem.activityStatus === 1">
        <div class="no-data">
          <span>活动未开始</span>
          <a-button class="search-btn" @click="switchActivity">切换活动</a-button>
        </div>
      </template>
      <template v-if="currentItem.activityStatus === 2 || currentItem.activityStatus === 3">
        <div class="no-data" v-if="!xData || xData.length === 0">
          <Empty title="暂无数据" />
        </div>
      </template>
      <!-- <div class="no-data" v-if="!xData || xData.length == 0">
        <span>活动未开始</span>
        <a-button class="search-btn" @click="switchActivity">切换活动</a-button>
      </div> -->
    </div>
  </a-spin>
</template>

<script>
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import MyChart from "@/components/MyChart/MyChart";
import Empty from "@/components/Empty/Empty";
import api from "@/api/activityOverview";
import chartLine from "../../options/chartLine";
import deepClone from "@/utils/clone-utils";

const defaultTabsData = [
  {
    label: "今日增量",
    value: 3
  },
  {
    label: '累计总量',
    value: 1
  },
  {
    label: "每日增量",
    value: 2
  }
]

const series1 = [
  { name: '参与门店', selected: true, key: 'activeStoresTotalCount' },
  { name: '直播场次', selected: true, key: 'liveTotalCount' },
  { name: '直播观看', selected: false, key: 'liveWatchTotalCount' },
  { name: '直播互动', selected: false, key: 'liveInteractTotalCount' },
  { name: '视频发布', selected: true, key: 'awemePublishTotalCount' },
  { name: '视频播放', selected: false, key: 'awemeWatchTotalCount' },
  { name: '视频互动', selected: false, key: 'awemeInteractTotalCount' },
  { name: '活动期间增粉', selected: false, key: 'addFansCount' }
]

const series2 = [
  { name: '参与门店', selected: true, key: 'activeStoresHourCount' },
  { name: '直播场次', selected: true, key: 'liveHourCount' },
  { name: '视频发布', selected: true, key: 'awemePublishHourCount' }
]

export default {
  name: "LeftWrap1",
  components: {
    NewTabs2,
    MyChart,
    Empty
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    trendData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      tabsData: [],
      active: 3,
      xData: [],
      chartOption: null
    };
  },
  watch: {
    currentItem: {
      immediate: true,
      handler() {
        if (this.currentItem.activityStatus === 3) {
          this.active = 1
          this.tabsData = defaultTabsData.filter(item => [1, 2].includes(item.value))
        } else {
          this.active = 3
          this.tabsData = defaultTabsData.filter(item => [1, 2, 3].includes(item.value))
        }
      }
    },
    trendData: {
      immediate: true,
      handler() {
        if (this.trendData.length > 0) {
          this.setChartOption()
        } else {
          this.xData = []
          this.chartOption = null
        }
      }  
    }
  },
  methods: {
    handleTabChange(val) {
      this.active = val;
      this.$emit('tab-change', val);
    },
    setChartOption() {
      let series = this.active !== 3 ? series1 : series2;

      // 过滤掉直播相关的
      if (this.currentItem.isLive === 0) {
        const keys1 = ['liveTotalCount', 'liveWatchTotalCount', 'liveInteractTotalCount'];
        const keys2 = ['liveHourCount'];

        series = series.filter(item => {
          const keys = this.active !== 3 ? keys1 : keys2;

          return !keys.includes(item.key);
        })
      }

      // 过滤掉视频相关的
      if (this.currentItem.isVideo === 0) {
        const keys1 = ['awemePublishTotalCount', 'awemeWatchTotalCount', 'awemeInteractTotalCount'];
        const keys2 = ['awemePublishHourCount'];

        series = series.filter(item => {
          const keys = this.active !== 3 ? keys1 : keys2;

          return !keys.includes(item.key);
        })
      }

      const option = deepClone(chartLine);

      const selected = {};
      series.forEach(item => {
        selected[item.name] = item.selected;
      });
      option.legend = {
        left: 20,
        top: 0,
        itemGap: 16,
        itemWidth: 6,
        itemHeight: 6,
        selected: selected,
        textStyle: {
          color: 'rgba(255,255,255,.88)'
        },
        icon: 'circle',
        data: series.map(item => item.name)  
      }

      option.grid = {
        left: 30,
        top: 58,
        right: 30,
        bottom: 0,
        containLabel: true, 
      }

      this.xData = this.trendData.map(item => item.date || item.hourDate);
      option.xAxis[0].data = this.xData;

      const optionSeries = [];
      series.forEach(item => {
        const obj = {
          type: "line",
          name: item.name,
          smooth: true,
          symbol: "circle",
          symbolSize: 0,
          data: []
        };

        this.trendData.forEach(item2 => {
          obj.data.push(item2[item.key]);
        })

        optionSeries.push(obj);
      })
      option.series = optionSeries;

      option.color = [
        'rgb(247, 92, 92)',
        'rgb(143, 106, 234)',
        'rgb(250, 204, 85)',
        'rgb(110, 159, 244)',
        'rgb(255, 144, 38)',
        'rgb(102, 120, 155)',
        'rgb(240, 139, 180)',
        'rgb(0, 134, 133)'
      ]

      this.chartOption = option;
    },
    // 加载当前页面所有接口
    initData() {
      this.getDataTrend();
    },
    // 接口请求 - 获取数据趋势
    getDataTrend() {
      let params = {
        activityId: this.$parent.currentActivityItem.id
      };
      api.fetchDataTrend(params).then(res => {
        if (res.data.code === 200) {
          console.log("getDataTrend", res.data.data);
          this.trendData = res.data.data;

          let xData = [];
          let yData1 = [];
          let yData2 = [];
          let yData3 = [];
          let yData4 = [];
          let yData5 = [];
          let yData6 = [];
          let yData7 = [];
          let yData8 = [];

          if (
            res.data.data &&
            res.data.data.length > 0
          ) {
            res.data.data.forEach(item => {
              xData.push(item.date);
              yData1.push(item.activeStoresTotalCount);
              yData2.push(item.liveTotalCount);
              yData3.push(item.liveWatchTotalCount);
              yData4.push(item.liveInteractTotalCount);
              yData5.push(item.awemePublishTotalCount);
              yData6.push(item.awemeWatchTotalCount);
              yData7.push(item.awemeInteractTotalCount);
              yData8.push();
            });
          }

          this.xData = xData;
          this.yData1 = yData1;
          this.yData2 = yData2;
          this.yData3 = yData3;
          this.yData4 = yData4;
          this.yData5 = yData5;
          this.yData6 = yData6;
          this.yData7 = yData7;
          this.yData8 = yData8;
        } else {
          this.$message.error(res.data.message || "获取数据趋势失败");
          this.trendData = {};
        }
      });
    },
    getLegendText() {
      return "累计";

      // if (this.currentItem.activityStatus == 3) {
      //   return "累计";
      // } else {
      //   return "今日";
      // }
    },
    getLineOption(xData, yData1, yData2, yData3, yData4, yData5, yData6, yData7, yData8) {
      console.log(xData, this.currentItem.activityStatus);
      let option = deepClone(chartLine);
      const series = this.active !== 3 ? series1 : series2;

      option.legend = {
        left: 20,
        top: 0,
        itemGap: 16,
        itemWidth: 6,
        itemHeight: 6,
        selected: {
          '参与门店': true,
          '直播场次': true,
          '直播观看': false,
          '直播互动': false,
          '视频发布': true,
          '视频播放': false,
          '视频互动': false,
          '活动期间增粉': false
        },
        textStyle: {
          color: 'rgba(255,255,255,.88)'
        },
        icon: 'circle',
        data: series.map(item => item.name)  
      }
      option.grid = {
        left: 30,
        top: 58,
        right: 30,
        bottom: 0,
        containLabel: true, 
      }

      option.xAxis[0].data = xData;
      option.series.push(
        this.returnSeriesItem(
          `参与门店`,
          "247, 92, 92",
          yData1
        )
      );
      option.series.push(
        this.returnSeriesItem(
          `直播场次`,
          "143, 106, 234",
          yData2
        )
      );
      option.series.push(
        this.returnSeriesItem(
          `直播观看`,
          "250, 204, 85",
          yData3
        )
      );
      option.series.push(
        this.returnSeriesItem(
          `直播互动`,
          "110, 159, 244",
          yData4
        )
      );
      option.series.push(
        this.returnSeriesItem(
          `视频发布`,
          "255, 144, 38",
          yData5
        )
      );
      option.series.push(
        this.returnSeriesItem(
          `视频播放`,
          "102, 120, 155",
          yData6
        )
      );
      option.series.push(
        this.returnSeriesItem(
          `视频互动`,
          "240, 139, 180",
          yData7
        )
      );
      option.series.push(
        this.returnSeriesItem(
          `活动期间增粉`,
          "240, 139, 180",
          yData8
        )
      );

      return option;
    },
    returnSeriesItem(name, color, data) {
      return {
        name: name,
        type: "line",
        smooth: true,
        symbol: "circle",
        symbolSize: 0,
        data: data,
        // areaStyle: {
        //   normal: {
        //     color: new echarts.graphic.LinearGradient(
        //       0,
        //       0,
        //       0,
        //       1,
        //       [
        //         {
        //           offset: 0,
        //           color: `rgba(${color},0.3)`
        //         },
        //         {
        //           offset: 0.8,
        //           color: `rgba(${color},0)`
        //         }
        //       ],
        //       false
        //     ),
        //     shadowColor: "rgba(0, 0, 0, 0.1)",
        //     shadowBlur: 10
        //   }
        // },
        itemStyle: {
          normal: {
            color: `rgba(${color},1)`
          }
        }
      };
    },
    // 切换活动
    switchActivity() {
      this.$parent.switchActivity();
    },
    // 跳转-活动统计
    routerTo() {
      this.$parent.routerToByName("活动统计");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;

  /deep/ .ant-spin {
    &-container {
      display: flex;
      flex-direction: column;
      padding: 20px;
      width: 100%;
      height: 100%;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
    }
    .date {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.55);
      margin-left: 8px;
    }
    .more-text {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.88);
      cursor: pointer;
    }
    .arrow-icon {
      margin-left: 4px;
      color: rgba(255, 255, 255, 0.88);
      transform: rotate(-90deg);
    }
  }
  .count-container {
    height: 50px;
    width: 100%;
    display: flex;
    margin-top: 20px;
    .count-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 114px;
      height: 50px;
      > div:first-child {
        display: flex;
        align-items: center;
        > span:first-child {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 4px;
        }
        > span:last-child {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.55);
          line-height: 20px;
        }
        .back1 {
          background: #f75c5c;
        }
        .back2 {
          background: #8f6aea;
        }
        .back3 {
          background: #fac35c;
        }
      }
      > div:last-child {
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
        line-height: 28px;
      }
    }
  }
  .chart-box {
    flex: 1;
    position: relative;
    margin: 20px -20px 0;
    // height: 190px;
    
    .no-data {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #2b2f3b;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > span {
        font-size: 14px;
        color: #8890a6;
        line-height: 17px;
        margin-bottom: 12px;
      }
      .search-btn {
        @include border_color("border_color4");
        @include font_color("font_color17");
        @include background_color("background_color13");
        width: 96px;
        height: 40px;
        border-radius: 8px;
      }
    }
  }
}
</style>
