import echarts from 'echarts'
var option = {
    title: {
        text: '',
        x: 'center',
        y: 0,
        textStyle: {
            color: '#B4B4B4',
            fontSize: 16,
            fontWeight: 'normal'
        }
    },
    tooltip: {
        trigger: 'axis',
        backgroundColor: '3C4151',
        padding: 10,
    },
    legend: {
        // 修改legend的高度宽度
        itemHeight: 5,
        itemWidth: 24,
        data: [
            {
                name: '总互动量',
                icon: 'rect'
            }, {
                name: '观看人数',
                icon: 'rect' // legend的icon
            }
        ],
        textStyle: {
            color: '#B4B4B4'
        },
        top: '0%',
    },
    grid: {
        "left": "30",
        "top": "30",
        "right": "30",
        "bottom": "40"
    },
    xAxis: [{
        // type:'category',
        data: [],
        boundaryGap: true,
        axisLine: {
            lineStyle: {
                color: '#B4B4B4'
            }
        },
        axisTick: {
            show: false
        }
    }],
    yAxis: [{
        name: '',
        nameTextStyle: {
            color: '#959595'
        },
        splitLine: {
            show: true,
            lineStyle: {
                type: 'solid',
                color: ["#313643"],
            }
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false,
            textStyle: {
                color: '#959595'
            },
        }
    },
    {
        name: '',
        nameTextStyle: {
            color: '#959595'
        },
        splitLine: {
            show: false
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false,
            textStyle: {
                color: '#959595'
            },
            formatter: '{value} '
        }
    },
    ],
    series: [
        {
            name: '总互动量',
            type: 'bar',
            barWidth: 10,
            yAxisIndex: 1,
            itemStyle: {
                normal: {
                    color: '#FFCC55',
                    borderWidth: 12,
                    //柱状图圆角
                    barBorderRadius: [7, 7, 0, 0],
                }
            },
            // data中可以使用对象，value代表相应的值，另外可加入自定义的属性
            data: []
        },
        {
            name: '观看人数',
            type: 'line',
            smooth: true,
            showSymbol: true,
            symbol: "circle",
            symbolSize: 10,
            yAxisIndex: 0,
            areaStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'rgba(60, 126, 255,0.3)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(60, 126, 255,0)'
                    }
                    ]),
                    shadowColor: 'rgba(60, 126, 255,0.2)',
                    shadowBlur: 20
                }
            },
            itemStyle: {
                normal: {
                    color: '#3C7EFF'
                }
            },

            data: []
        }
    ]
};

export default option