import { render, staticRenderFns } from "./BindButtonTab.vue?vue&type=template&id=4f2dee13&scoped=true"
import script from "./BindButtonTab.vue?vue&type=script&lang=js"
export * from "./BindButtonTab.vue?vue&type=script&lang=js"
import style0 from "./BindButtonTab.vue?vue&type=style&index=0&id=4f2dee13&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f2dee13",
  null
  
)

/* custom blocks */
import block0 from "./BindButtonTab.vue?vue&type=custom&index=0&blockType=platform-tab&%3AtabData=tabData&%40tabChange=tabChange"
if (typeof block0 === 'function') block0(component)

export default component.exports