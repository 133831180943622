<template>
  <div class="container">
    <div class="second-header">
      <div>
        <!-- <span class="back-btn" @click="goBack">
          <a-icon type="left" />
        </span> -->
        <span class="title"
          >活动数据 · {{ currentActivityItem.activityName }}</span
        >
        <span class="switch-button" @click="switchActivity">
          <span>切换活动</span>
          <svg-icon icon-class="arrow-down" class="arrow-icon" />
        </span>
      </div>
      <div>
        <platform-header
          title=""
          ref="PlatformHeader"
          :tabData="tabData"
          :currentTab="currentTab"
          :isAll="true"
          @tabChange="tabChange"
        />
      </div>
    </div>
    <div class="tabs-div">
      <NewTabs
        ref="tabs"
        :tabsKey="tabsKey"
        :tabData="tabsData"
        :loading="tableLoading"
        @tabChange="changeTabs"
        @tabNameChange="(val) => (tabsName = val)"
      />
      <div>
        <span class="date-label">活动日期：</span>
        <span class="date-value"
          >{{ currentActivityItem.startTime }} -
          {{ currentActivityItem.endTime }}</span
        >
        <a-range-picker
          v-model="dateValue"
          @calendarChange="calendarChange"
          class="aft-range-picker default"
          :disabled-date="disabledDate"
          @change="dateChange"
          locale="zh-cn"
          format="YYYY-MM-DD"
          style="width: 220px; margin-left: 8px"
          v-if="(tabsKey === '2' || tabsKey === '3') && datePickerShow"
          :allow-clear="false"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-range-picker>
      </div>
    </div>
    <div class="tools">
      <div class="left">
        <div style="display: flex">
          <!-- 联动选择开始 -->
          <a-cascader
            :options="regionList"
            :default-value="selectArr"
            @change="onMultipleSelectChange"
            size="large"
            style="width: 440px"
            ref="multipleSelect"
            :allow-clear="false"
            v-if="tabsKey !== '0' && tabsKey !== '4'"
          >
          </a-cascader>
          <a-input-search
            v-if="tabsKey !== '0' && tabsKey !== '4'"
            v-model.trim="dealerName"
            style="width: 260px; margin-left: 8px"
            class="aft-search"
            placeholder="请输入门店名、公司名或门店编号"
            :allowClear="true"
            @search="getTableList(true)"
            @pressEnter="getTableList(true)"
          />

          <a-select
            class="aft-select default"
            v-if="tabsKey === '4'"
            v-model="regionId"
            placeholder="选择大区"
            style="width: 122px; margin-right: 8px"
            @change="regionChange"
          >
            <!-- <a-select-option value="" v-if="isAllRegion">
              不限大区
            </a-select-option> -->
            <a-select-option
              v-for="item in regionList || []"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}
            </a-select-option>
          </a-select>
          <a-select
            class="aft-select default"
            v-if="tabsKey === '4'"
            v-model="warZoneId"
            placeholder="选择战区"
            style="width: 122px; margin-right: 8px"
            @change="warZoneChange"
            :key="new Date().getTime()"
          >
            <!-- <a-select-option value=""> 全部战区 </a-select-option> -->
            <a-select-option
              v-for="item in warZoneList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div>
        <SwitchPopover
          v-if="settingData.length > 0 && currentTab !== 'xiaohongshu'"
          :settingData="settingData"
          :tipText="tipText"
          style="margin-right: 8px"
          @changeSwitch="changeSwitchPopover"
        />
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button
          v-hide-by-username
          class="button2"
          @click="exportClick"
          v-track:click="{
            action: 'Excel导出',
            activityId: currentActivityItem.id,
            activityName: currentActivityItem.activityName,
            tabsName,
          }"
        >
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>
    <!-- 筛选字段显示开始 -->
    <div class="filter-container">
      <div class="filter-list-container">
        <div class="label">查询条件筛选：</div>
        <div class="tag-list">
          <template v-if="tabsKey === '4'">
            <a-tag color="#1A1D26" v-if="selectedRegionName">
              {{ selectedRegionName }}
            </a-tag>
          </template>
          <template v-if="tabsKey === '4'">
            <a-tag
              color="#1A1D26"
              :visible="true"
              closable
              v-if="selectedWarZoneName"
              @close="
                () => {
                  warZoneId = undefined;
                  warZoneChange();
                }
              "
            >
              {{ selectedWarZoneName }}
            </a-tag>
          </template>
          <template v-if="tabsKey !== '0' && tabsKey !== '4'">
            <a-tag
              color="#1A1D26"
              :visible="true"
              v-for="(item, index) in selectedSearchList"
              :key="index"
              >{{ item }}
            </a-tag>
          </template>
          <a-tag color="#1A1D26" :visible="true">
            {{ selectedTime }}
          </a-tag>
          <a-tag
            color="#1A1D26"
            :visible="true"
            v-if="tabsKey !== '0' && tabsKey !== '4' && selectedDealerName"
          >
            {{ selectedDealerName }}
          </a-tag>
        </div>
      </div>
      <div class="filter-list-container">
        <div class="label">数据条件筛选：</div>
        <div class="tag-list">
          <template v-for="(item, index) in settingData">
            <a-tag
              color="#1A1D26"
              :visible="true"
              closable
              :key="index"
              v-if="item.bool"
              @close="
                () => {
                  settingData[index].bool = false;
                  changeSwitchPopover(settingData);
                }
              "
            >
              {{ item.text }}{{ item.inputValue }}{{ item.text2 }}
            </a-tag>
          </template>
        </div>
      </div>
    </div>
    <!-- 筛选字段显示结束 -->
    <div class="content-table" ref="tableWrap">
      <a-table
        v-if="tableShow"
        :columns="filteredColumns"
        :scroll="tableScroll"
        :data-source="tableData"
        :rowKey="(data, index) => index"
        :pagination="false"
        class="aft-table default x-scroll fixed-table"
        :locale="locale"
        :loading="tableLoading"
        @change="tableChange"
        ref="aTable"
      >
        <div slot="customTitle">
          <span>{{ currentTab | returnWatchText }}</span>
        </div>
        <div slot="sort" slot-scope="text, records, index" class="table-sort">
          <span>{{ index + 1 + (current - 1) * pageSize }}</span>
        </div>
        <div slot="noteText" slot-scope="text, records">
          <div class="table-ellipsis">{{ records.noteText || "-" }}</div>
        </div>
        <div
          slot="awemeTitle"
          slot-scope="text, records"
          class="table-ellipsis"
        >
          <div :title="records.awemeTitle">{{ records.awemeTitle }}</div>
        </div>
        <div
          slot="guideshootName"
          slot-scope="text, records"
          class="table-ellipsis"
        >
          <div :title="records.guideshootName" v-if="records.guideshootName">
            {{ records.guideshootName }}
          </div>
          <div v-else>-</div>
        </div>
        <div slot="dealerStoreRate" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'dealerStoreRate',
            }"
          >
            {{ filters.numRate2(text, 1) || "-" }}
          </span>
        </div>
        <div slot="dealerCount" slot-scope="text">
          <span
            :class="{
              'color-yellow': currentSort[tabsKey].sort_field == 'dealerCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="taskFinishRate" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'taskFinishRate',
            }"
          >
            {{ typeof text !== "number" ? "-" : `${text}%` }}
          </span>
        </div>
        <div slot="fansCount" slot-scope="text">
          <span
            :class="{
              'color-yellow': currentSort[tabsKey].sort_field == 'fansCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="addFansCount" slot-scope="text">
          <span
            :class="{
              'color-yellow': currentSort[tabsKey].sort_field == 'addFansCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="liveCount" slot-scope="text">
          <span
            :class="{
              'color-yellow': currentSort[tabsKey].sort_field == 'liveCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="liveDuration" slot-scope="text">
          <span
            :class="{
              'color-yellow': currentSort[tabsKey].sort_field == 'liveDuration',
            }"
          >
            {{ filters.toMinRound(text, "") }}
          </span>
        </div>
        <div slot="liveWatchCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'liveWatchCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="liveInteractCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'liveInteractCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="liveFansAddCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'liveFansAddCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemePublishName">
          <ActiveTooltip
            :title="`视频发布`"
            :tips="
              `此字段会统计抖音快手视频号视频数据，具体统计项与活动参与平台保持一致`
            "
          />
        </div>
        <div slot="awemePublishCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemePublishCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeWatchName">
          <ActiveTooltip
            :title="'视频播放'"
            :tips="
              '此字段会统计抖音快手视频号视频数据，具体统计项与活动参与平台保持一致'
            "
          />
        </div>
        <div slot="awemeWatchCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeWatchCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeInteractName">
          <ActiveTooltip
            :title="'视频互动'"
            :tips="
              '此字段会统计抖音快手视频号视频数据，具体统计项与活动参与平台保持一致'
            "
          />
        </div>
        <div slot="awemeInteractCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeInteractCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeLikeName">
          <ActiveTooltip
            :title="`视频点赞`"
            :tips="
              `此字段会统计抖音快手视频号视频数据，具体统计项与活动参与平台保持一致`
            "
          />
        </div>
        <div slot="awemeLikeCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeLikeCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeTotalLikeCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeTotalLikeCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeThumbLikeCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeThumbLikeCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeLoveLikeCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeLoveLikeCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="tenThousandHotLive" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'tenThousandHotLive',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="fiftyThousandHotLive" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'fiftyThousandHotLive',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="oneHundredThousandHotLive" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'oneHundredThousandHotLive',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="liveLikeCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'liveLikeCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="liveCommentCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'liveCommentCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="liveGiftCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'liveGiftCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="tenThousandHotAweme" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'tenThousandHotAweme',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="fiftyThousandHotAweme" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'fiftyThousandHotAweme',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="oneHundredThousandHotAweme" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'oneHundredThousandHotAweme',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeLikeCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeLikeCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeCommentName">
          <ActiveTooltip
            :title="`视频评论`"
            :tips="
              `此字段会统计抖音快手视频号视频数据，具体统计项与活动参与平台保持一致`
            "
          />
        </div>
        <div slot="awemeCommentCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeCommentCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeShareName">
          <ActiveTooltip
            :title="`视频分享`"
            :tips="
              `此字段会统计抖音快手视频号视频数据，具体统计项与活动参与平台保持一致`
            "
          />
        </div>
        <div slot="awemeShareCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeShareCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeNoteCollectName">
          <ActiveTooltip
            :title="`视频笔记收藏`"
            :tips="'仅统计小红书视频笔记的收藏量'"
          />
        </div>
        <div slot="awemeNoteCollectCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeNoteCollectCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeNoteName">
          <ActiveTooltip
            :title="`视频笔记发布`"
            :tips="
              '此字段会统计小红书视频数据，具体统计项与活动参与平台保持一致'
            "
          />
        </div>
        <div slot="awemeNoteCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeNoteCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeNoteLikeName">
          <ActiveTooltip
            :title="`视频笔记点赞`"
            :tips="
              '此字段会统计小红书视频数据，具体统计项与活动参与平台保持一致'
            "
          />
        </div>
        <div slot="awemeNoteLikeCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeNoteLikeCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeNoteCommentName">
          <ActiveTooltip
            :title="`视频笔记评论`"
            :tips="
              '此字段会统计小红书视频数据，具体统计项与活动参与平台保持一致'
            "
          />
        </div>
        <div slot="awemeNoteCommentCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeNoteCommentCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="awemeNoteShareName">
          <ActiveTooltip
            :title="`视频笔记分享`"
            :tips="
              '此字段会统计小红书视频数据，具体统计项与活动参与平台保持一致'
            "
          />
        </div>
        <div slot="awemeNoteShareCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'awemeNoteShareCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="publishTime" slot-scope="text">
          <span
            :class="{
              'color-yellow': currentSort[tabsKey].sort_field == 'publishTime',
            }"
          >
            {{ text || "-" }}
          </span>
        </div>
        <div slot="liveAddFansCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'liveAddFansCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="dealerName" slot-scope="text, records" class="table-dealer">
          <div>{{ records.dealerName || "-" }}</div>
          <div v-if="tabsKey === '0' || tabsKey === '1'">
            {{ records.companyName || "-" }}
          </div>
          <div v-if="tabsKey === '2' || tabsKey === '3' || tabsKey === '5'">
            {{ records.authorNickname || "-" }}
          </div>
        </div>
        <!-- 笔记列开始 -->
        <div slot="notePublishTime" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'notePublishTime',
            }"
          >
            {{ text || "-" }}
          </span>
        </div>
        <div slot="noteWatchCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'noteWatchCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="noteLikeCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'noteLikeCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="noteCommentCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'noteCommentCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="noteCollectCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'noteCollectCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <div slot="noteShareCount" slot-scope="text">
          <span
            :class="{
              'color-yellow':
                currentSort[tabsKey].sort_field == 'noteShareCount',
            }"
          >
            {{ filters.changeNumNoRound(text) }}
          </span>
        </div>
        <!-- 笔记列结束 -->

        <span slot="customAction" @click="actionClick">
          <a-tooltip>
            <template slot="title"> 列设置 </template>
            <a-icon class="action-ico" type="setting" theme="filled" />
          </a-tooltip>
        </span>
        <div slot="action"></div>
      </a-table>
    </div>

    <div class="page-div aft-pagination">
      <span class="total-text">
        共 {{ total || 0 }} 项数据 ，数据统计截止时间 ：

        <span v-for="(item, index) in formatUpdateTime" :key="index">
          {{ item.label }}：{{ item.value }}
          <span v-if="index !== formatUpdateTime.length - 1">、</span>
        </span>
      </span>
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>

    <!-- <SwitchActivity
      :currentItem="currentActivityItem"
      @changeActivity="changeActivity"
      ref="SwitchActivity"
    /> -->

    <!-- 切换活动 -->
    <ActSelectDrawer
      ref="ActSelectDrawer"
      :visible.sync="actSelectDrawerVisible"
      mode="switch"
      :default-selected-act-list="[currentActivityItem]"
      @switch="changeActivity"
    />

    <!-- 收藏门店弹窗 -->
    <div class="setting-modal" v-show="settingModalShow" @click="settingClose">
      <div class="setting-modal-container" @click.stop>
        <div class="setting-modal-container-title">
          选择展示指标
          <!-- <span>(最多6个)</span> -->
        </div>
        <div class="list">
          <a-checkbox-group
            v-model="currentCheck"
            @change="checkboxChange"
            style="width: 100%"
          >
            <a-row>
              <a-col
                :span="8"
                v-for="item in tableSetting"
                :key="item.value + 'tabsKey' + tabsKey"
                :style="{
                  'margin-bottom': '16px',
                }"
              >
                <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
        <div class="action-div">
          <span class="btn btn1" @click="settingClose">取消</span>
          <span class="btn btn2" @click="settingSure">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filters from "@/filters";
import _ from "lodash";
import exportTable from "@/utils/exportTable";
import Empty from "@/components/Empty/Empty";
import NewTabs from "@/components/PlatformTab/NewTabs";
import SwitchPopover from "@/components/SwitchPopover/SwitchPopover";
import deepClone from "@/utils/clone-utils";
import api from "@/api/activityStatistics.js";
import ac_api from "@/api/marketingActivityList.js";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ActSelectDrawer from "@/components/ActSelectDrawer";
import { getBaseUpdateTime } from "@/api/bff/newJJT";
import { getPrincipalId } from "@/utils/auth";
import {
  handleHeaderCell,
  tabsData2,
  settingData,
  columns_0,
  columns_1,
  columns_2,
  columns_3,
  columns_4,
  columns_5,
  apiList,
  exportApiList,
  defaultSort,
} from "./constant";
import moment from "moment";
import tagApi from "@/api/illegalTag";
import { getRegionVillageLisNoPermissionApi } from "@/api/bff/region";
import ActiveTooltip from "./components/tooltip.vue";

const defaultSettingDataList = settingData;

export default {
  name: "activityStatistics",
  components: {
    Empty,
    NewTabs,
    SwitchPopover,
    ExcelTypeButton,
    PlatformHeader,
    // SwitchActivity,
    ActSelectDrawer,
    ActiveTooltip,
  },
  data() {
    return {
      filters,
      tabsKey: "0",
      // tabData: [
      //   {
      //     name: "全部平台",
      //     value: "0",
      //   },
      //   {
      //     name: "抖音",
      //     value: "1",
      //   },
      //   {
      //     name: "快手",
      //     value: "2",
      //   },
      //   {
      //     name: "小红书",
      //     value: "5",
      //   },
      // ],
      tabsName: "",
      tabsData: [],
      // currentSort: deepClone(defaultSort),
      currentTab: "agg",
      originalRegionList: [],
      regionList: [],
      existsCombatZone: false,
      warZoneId: undefined,
      warZoneList: [],
      isProvinceAndCityShow: true,
      provinceId: undefined,
      provinceList: [],
      cityId: undefined,
      cityList: [],
      isAllRegion: false,
      dealerList: [
        {
          name: "全部门店",
          id: "1",
        },
        {
          name: "全渠道未参与门店",
          id: "2",
        },
        {
          name: "视频未参与门店",
          id: "3",
        },
        {
          name: "直播未参与门店",
          id: "4",
        },
        {
          name: "笔记未参与门店",
          id: "5",
        },
      ],
      dealerValue: "1",
      excelType: false,
      settingData: settingData[0],
      activity_id: undefined,
      columns: [],
      filteredColumns: [],
      sort_field: "",
      sort: "",
      locale: {
        emptyText: <Empty style="margin-top: 140px;" title="暂无数据" />,
      },
      tableData: [],
      tableLoading: false,
      current: 1,
      pageSize: 20,
      total: 0,
      groupValue: "",
      areaList: [],
      regionId: undefined,
      storeValue: undefined,
      dateValue: [],
      settingType: "",
      currentActivityItem: {
        startTime: "",
        endTime: "",
        activityName: "-",
      },
      tableShow: true,
      updateTime: "",
      settingModalShow: false,
      tableSetting: [],
      currentCheck: [],
      oldCheck: [],
      tableScroll: {},
      actSelectDrawerVisible: false,
      datePickerShow: false,
      dealerName: "",
      selectedDealerName: "",

      selectArr: ["", "", "", "", "1"],
      selectedSearchList: [],

      // 埋点名称
      trackAction: "",
      // 违规标签显示
      illegalTagDisplay: {
        fake_aweme_data_display: 0,
        nobody_live_display: 0,
      },

      updateTimeData: {},
    };
  },
  computed: {
    currentSort: {
      get() {
        if (this.currentActivityItem.isLive == 0) {
          defaultSort[1].sort_field = "awemePublishCount";
        } else {
          defaultSort[1].sort_field = "liveCount";
        }
        // 如果是上汽大众
        if (!this.notShangQiDaZhong) {
          defaultSort[0].sort_field = "";
          defaultSort[0].sort = "";
        }
        return deepClone(defaultSort);
      },
      set() {},
    },
    notShangQiDaZhong() {
      return getPrincipalId() !== "869753009302654976";
    },
    onlyXiaohongshu() {
      return (
        this.tabData.length == 2 &&
        this.tabData[0].value == "0" &&
        this.tabData[1].value == "5"
      );
    },
    platformList() {
      if (!this.currentActivityItem.platforms) return [];
      return this.currentActivityItem.platforms.split(",");
    },
    hasDouyinKuaishou() {
      return this.platformList.includes("1") || this.platformList.includes("2");
    },
    isHideTempalte() {
      // 视频号隐藏模板
      return !this.hasDouyinKuaishou || this.currentTab === "shipinhao";
    },
    tabData() {
      if (!this.currentActivityItem.platforms) return [];
      const platformMap = {
        1: "1",
        2: "2",
        5: "3",
        6: "4",
      };
      const platforms = this.currentActivityItem.platforms.split(",");
      return [
        {
          name: "全部平台",
          value: "0",
        },
        {
          name: "抖音",
          value: "1",
        },
        {
          name: "快手",
          value: "2",
        },
        {
          name: "小红书",
          value: "5",
        },
        {
          name: "视频号",
          value: "6",
        },
      ].filter((item) =>
        item.value === "0" ? true : platforms.includes(platformMap[item.value])
      );
    },
    tipText() {
      if (this.tabsKey === "0" || this.tabsKey === "4") {
        return "数据条件筛选不影响任务总完成率";
      } else if (this.tabsKey === "1") {
        return "数据条件筛选不影响任务完成率";
      } else {
        return "";
      }
    },
    // columns_0(){
    //   return mySort(this.currentSort[this.tabsKey])
    // },
    // numRate2(text){
    //   return filters.numRate2(text, 1) || "-"
    // },
    selectedRegionName() {
      const region = this.regionList
        .concat(this.isAllRegion ? [{ id: "", name: "不限大区" }] : [])
        .find((item) => item.id === this.regionId);
      return region ? region.name : "";
    },
    selectedWarZoneName() {
      const warZone = this.warZoneList
        .concat([{ id: "", name: "全部战区" }])
        .find((item) => item.id === this.warZoneId);
      return warZone ? warZone.name : "";
    },
    selectedProvince() {
      const province = this.provinceList
        .concat([{ id: "", name: "全部省份" }])
        .find((item) => item.id === this.provinceId);
      return province ? province.name : "";
    },
    selectedCity() {
      const city = this.cityList
        .concat([{ id: "", name: "全部城市" }])
        .find((item) => item.id === this.cityId);
      return city ? city.name : "";
    },
    selectedStore() {
      const store = this.dealerList.find(
        (item) => item.id === this.dealerValue
      );
      return store ? store.name : "";
    },
    selectedTime() {
      return `${moment(this.dateValue[0]).format("YYYY-MM-DD")} ～ ${moment(
        this.dateValue[1]
      ).format("YYYY-MM-DD")}`;
    },
    formatTime() {
      let timeStr = "-";
      if (this.tabsKey === "5") {
        // 第一天或者活动未开始，不显示任何东西
        if (
          `${this.currentActivityItem.activityStatus}` === "1" ||
          moment().format("YYYY-MM-DD") ===
            moment(this.currentActivityItem.startTime).format("YYYY-MM-DD")
        ) {
          timeStr = "";
        } else if (`${this.currentActivityItem.activityStatus}` === "2") {
          // 活动进行中
          timeStr = this.updateTime
            ? moment(this.updateTime)
                .add(-1, "day")
                .format("YYYY-MM-DD")
            : "-";
        } else {
          // 活动结束
          timeStr = this.currentActivityItem.endTime
            ? moment(this.currentActivityItem.endTime).format("YYYY-MM-DD")
            : "-";
        }
      } else {
        timeStr = this.updateTime
          ? moment(this.updateTime).format("YYYY-MM-DD HH:mm")
          : "-";
      }
      return timeStr;
    },
    formatUpdateTime() {
      const updateTimeMap = {};
      const updateTimeArr = [];
      this.platformList.forEach((item) => {
        updateTimeMap[item] = true;
      });
      // 全部平台
      if (this.currentTab === "agg") {
        if (updateTimeMap["1"] && updateTimeMap["2"]) {
          updateTimeArr.push({
            label: "抖音快手",
            value: this.updateTimeData.updateTime || "-",
          });
        } else if (updateTimeMap["1"]) {
          updateTimeArr.push({
            label: "抖音",
            value: this.updateTimeData.updateTime || "-",
          });
        } else if (updateTimeMap["2"]) {
          updateTimeArr.push({
            label: "快手",
            value: this.updateTimeData.updateTime || "-",
          });
        }
        if (updateTimeMap["3"]) {
          updateTimeArr.push({
            label: "小红书",
            value: this.updateTimeData.xhsUpdateTime || "-",
          });
        }
        if (updateTimeMap["4"]) {
          updateTimeArr.push({
            label: "视频号",
            value: this.updateTimeData.sphUpdateTime || "-",
          });
        }
      } else if (this.currentTab === "douyin") {
        updateTimeArr.push({
          label: "抖音",
          value: this.updateTimeData.updateTime || "-",
        });
      } else if (this.currentTab === "kuaishou") {
        updateTimeArr.push({
          label: "快手",
          value: this.updateTimeData.updateTime || "-",
        });
      } else if (this.currentTab === "xiaohongshu") {
        updateTimeArr.push({
          label: "小红书",
          value: this.updateTimeData.xhsUpdateTime || "-",
        });
      } else if (this.currentTab === "shipinhao") {
        updateTimeArr.push({
          label: "视频号",
          value: this.updateTimeData.sphUpdateTime || "-",
        });
      }
      return updateTimeArr;
    },
  },
  async mounted() {
    // 判断页面来源
    if (this.$route.query.aId || this.$route.query.activityId) {
      this.currentActivityItem = {
        id: this.$route.query.aId || this.$route.query.activityId,
        startTime: this.$route.query.startTime,
        endTime: this.$route.query.endTime,
        activityName: this.$route.query.aName,
        videoCreateSource: this.$route.query.videoCreateSource,
        activityStatus: this.$route.query.activityStatus,
        isLive: Number(this.$route.query.isLive),
        isVideo: Number(this.$route.query.isVideo),
        isNote: Number(this.$route.query.isNote),
        platforms: this.$route.query.platforms,
        isShowNoteVideo: Number(this.$route.query.isShowNoteVideo),
        isShowNoteContent: Number(this.$route.query.isShowNoteContent),
      };
    }

    await this.getIllegalTagDisplay();
    const region_result = await this.getAreaInfoByActivity();
    this.setRegionList(region_result);
    this.getUpdateTime();
    this.setTabsData();
    await this.$nextTick();
    // 跳过来
    const { areaId, areaName, activityId, type } = this.$route.query;
    if (activityId) {
      this.$refs.tabs.tabClick(
        {
          label: "门店数据",
          value: "1",
        },
        true
      );

      // "2": "全渠道","3": "视频","4": "直播",
      if (this.existsCombatZone) {
        this.selectArr = [areaId, "", "", "", type];
      } else {
        this.selectArr = [areaId, "", "", type];
      }

      await this.$nextTick();
      this.onMultipleSelectChange(this.selectArr);
    } else {
      const activity_result = await this.getActivityList();
      this.setActivityList(activity_result);
    }
    // 首次进入,埋点
    this.trackAction = "切换活动";
    this.track();
  },
  methods: {
    // 默认选择活动埋点
    track() {
      const trackData = {
        action: this.trackAction,
        default: 1,
        activityId: this.currentActivityItem.id,
        activityName: this.currentActivityItem.activityName,
      };
      this.trackAction = "";
      this.$trackRequest(this.$route, this.$trackEvent.CLICK, trackData);
    },
    /**
     * 刷量视频/挂播直播状态
     */
    async getIllegalTagDisplay() {
      try {
        const res = await tagApi.getIllegalTagDisplay();
        const { code, data, message } = res.data;
        if (code === 0) {
          this.illegalTagDisplay = data;
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log("error:", error);
      }
    },
    // 活动数据更新时间
    getUpdateTime() {
      getBaseUpdateTime({
        activityId: this.currentActivityItem.id,
      }).then((res) => {
        this.updateTimeData = res.data.data || {};
      });
    },
    // 获取活动列表
    getActivityList() {
      let params = {
        page: 1,
        size: 1,
      };
      return ac_api.getActivityList(params);
    },
    /**
     * 设置选择的活动
     * @param {object} res 活动列表数据
     */
    setActivityList(res) {
      if (res.data.code == 200 && res.data.data.list.length > 0) {
        if (this.$route.query.aId || this.$route.query.activityId) {
          console.log(this.currentActivityItem, "当前活动");
          this.changeActivity(
            this.currentActivityItem.id,
            this.currentActivityItem
          );
        } else {
          this.changeActivity(res.data.data.list[0].id, res.data.data.list[0]);
        }
        // if (!this.$route.query.aId) {
        //   this.currentActivityItem = res.data.data.list[0];
        // }

        // this.dateValue = [
        //   moment(this.currentActivityItem.startTime),
        //   moment(this.currentActivityItem.endTime),
        // ];

        // this.setTabsData();
        // this.setDataBySource();
        // this.setColumns();
        // this.setCheckData(true);
      } else {
        this.currentActivityItem = {};
        this.$message.error(res.data.message || "获取活动列表失败");
      }
    },
    setTabsData() {
      let tabsData = deepClone(tabsData2);

      // 不是上汽大众，过滤掉战区数据
      if (getPrincipalId() !== "869753009302654976") {
        const values = ["4"];

        tabsData = tabsData.filter((item) => {
          return !values.includes(item.value);
        });
      }

      // 过滤掉直播相关的
      if (
        this.currentActivityItem.isLive === 0 ||
        this.currentTab === "xiaohongshu"
      ) {
        const values = ["3"];

        tabsData = tabsData.filter((item) => {
          return !values.includes(item.value);
        });
      }

      // 过滤掉视频相关的
      if (
        this.currentActivityItem.isVideo === 0 ||
        this.currentTab === "xiaohongshu"
      ) {
        const values = ["2"];

        tabsData = tabsData.filter((item) => {
          return !values.includes(item.value);
        });
      }

      // 过滤掉笔记相关的
      if (
        this.currentActivityItem.isNote === 0 ||
        (this.currentTab !== "xiaohongshu" && this.currentTab !== "agg")
      ) {
        const values = ["5"];
        tabsData = tabsData.filter((item) => {
          return !values.includes(item.value);
        });
      }

      this.tabsData = tabsData;
    },
    // 获取大区下拉列表
    getAreaInfoByActivity() {
      let params = {
        activityId: this.currentActivityItem.id,
      };
      return api.getAreaInfoByActivity(params);
    },
    initRegionList(list = [], index = 0) {
      let allList = [];
      if (this.existsCombatZone) {
        allList = [
          {
            name: "全部战区",
            id: "",
            children: [],
          },
          {
            name: "全部省份",
            id: "",
            children: [],
          },
          {
            name: "全部城市",
            id: "",
            children: [],
          },
          {
            name: "全部门店",
            id: "1",
            children: null,
          },
        ];
      } else {
        allList = [
          {
            name: "全部省份",
            id: "",
            children: [],
          },
          {
            name: "全部城市",
            id: "",
            children: [],
          },
          {
            name: "全部门店",
            id: "1",
            children: null,
          },
        ];
      }
      if (this.isAllRegion) {
        allList.unshift({
          name: "不限大区",
          id: "",
          children: [],
        });
      }
      return [allList[index], ...list].map((item) => {
        if (index === (this.existsCombatZone ? 3 : 2) && this.tabsKey === "1") {
          item.children = [
            {
              name: "全渠道未参与门店",
              id: "2",
              children: null,
            },
          ];
          if (this.currentActivityItem.isVideo === 1) {
            item.children.push({
              name: "视频未参与门店",
              id: "3",
              children: null,
            });
          }
          if (this.currentActivityItem.isLive === 1) {
            item.children.push({
              name: "直播未参与门店",
              id: "4",
              children: null,
            });
          }
          if (this.currentActivityItem.isNote === 1) {
            item.children.push({
              name: "笔记未参与门店",
              id: "5",
              children: null,
            });
          }
        }
        return {
          ...item,
          label: item.name,
          value: item.id,
          children: item.children
            ? this.initRegionList(item.children, index + 1)
            : null,
        };
      });
    },
    // 设置区域列表
    setRegionList(res) {
      if (res.data.code == 200 && res.data.data.region_list.length > 0) {
        if (res.data.data.type === 10 || res.data.data.type === 20) {
          this.isAllRegion = true;
        } else {
          this.isAllRegion = false;
        }
        this.existsCombatZone = res.data.data.existsCombatZone || false;
        this.originalRegionList = res.data.data.region_list;
        this.regionList = this.initRegionList(res.data.data.region_list);
        if (res.data.data.type === 10) {
          this.regionId = "";
        } else {
          this.regionId = this.regionList[0].id;
          !this.existsCombatZone && this.setProvinceList();
        }
        this.existsCombatZone && this.setWarZoneList();

        if (this.existsCombatZone) {
          this.selectArr = ["", "", "", "", "1"];
        } else {
          this.selectArr = ["", "", "", "1"];
        }

        // if (res.data.data.type === 30) {
        //   this.isProvinceAndCityShow = false;
        // } else {
        //   this.isProvinceAndCityShow = true;
        // }
      } else {
        this.regionList = [];
        this.$message.warning(res.data.message || "获取大区列表失败");
      }
    },
    // 获取战区列表
    // async getWarZoneList() {
    //   try {
    //     const params = {
    //       principal_id: getPrincipalId(),
    //       region_id: this.regionId
    //     }
    //     const res = await api.getWarZoneList(params);
    //     const { code, data, message } = res.data;

    //     if (code === 200) {
    //       this.warZoneList = data || [];
    //     } else {
    //       this.$message.error(message);
    //     }
    //   } catch(e) {
    //     console.error(e);
    //   }
    // },
    // 设置数据条件筛选 videoCreateSource == 1 时，隐藏‘剔除非模板视频’和‘剔除模板视频’
    setDataBySource() {
      // if (this.currentActivityItem.videoCreateSource == 1) {
      //   let newData = deepClone(settingData[Number(this.tabsKey)]);
      //   const newArr = newData.reduce((acc, cur) => {
      //     if (cur.text != "剔除非模板视频" && cur.text != "剔除模板视频") {
      //       acc.push(cur);
      //     }
      //     return acc;
      //   }, []);
      //   this.settingData = newArr;
      // } else {
      //   this.settingData = deepClone(settingData[Number(this.tabsKey)]);
      // }

      let settingData = deepClone(
        defaultSettingDataList[Number(this.tabsKey)]
      ).filter((item) => {
        if (item.text == "剔除刷量视频") {
          return this.illegalTagDisplay.fake_aweme_data_display;
        } else if (item.text == "剔除挂播直播") {
          return this.illegalTagDisplay.nobody_live_display;
        } else {
          return true;
        }
      });

      // 过滤掉直播相关的
      if (this.currentActivityItem.isLive === 0) {
        const keys = ["isDropHangLive"];

        settingData = settingData.filter((item) => {
          return !keys.includes(item.value);
        });
      }

      // 过滤掉视频相关的
      if (this.currentActivityItem.isVideo === 0) {
        const keys = [
          "isDropBrushAweme",
          "isDropShutAweme",
          "isDropNonGuideshootAweme",
          "isDropGuideshootAweme",
        ];

        settingData = settingData.filter((item) => {
          return !keys.includes(item.value);
        });
      }

      if (this.currentActivityItem.videoCreateSource == 1) {
        const keys = ["isDropNonGuideshootAweme", "isDropGuideshootAweme"];

        settingData = settingData.filter((item) => {
          return !keys.includes(item.value);
        });
      }

      if (this.isHideTempalte) {
        settingData = settingData.filter((item) => {
          return ![
            "isDropNonGuideshootAweme",
            "isDropGuideshootAweme",
            "isDropNonActivityGuideshootAweme",
          ].includes(item.value);
        });
      }

      this.settingData = settingData;
    },
    /**
     * 平台切换
     */

    tabChange(val) {
      this.currentTab = val;
      this.tableShow = false;
      this.$nextTick(() => {
        this.setTabsData();
        if (
          this.tabsData.map((item) => item.value).indexOf(this.tabsKey) == -1
        ) {
          this.tabsKey = this.tabsData[0].value;
        }
        this.changeTabs(this.tabsKey);
        // this.setColumns();
        // this.setCheckData();
        // this.setTableColums();
        // this.setTableScroll();
        // this.tableShow = true;
        // this.getTableList(true);
      });
    },
    /**
     * 设置头部
     */
    setColumns() {
      let columns;
      let obj = {
        sortKey: this.currentSort[this.tabsKey],
      };
      this.tabsKey === "0" && (columns = deepClone(columns_0(obj)));
      this.tabsKey === "1" && (columns = deepClone(columns_1(obj)));
      this.tabsKey === "2" && (columns = deepClone(columns_2(obj)));
      this.tabsKey === "3" && (columns = deepClone(columns_3(obj)));
      this.tabsKey === "4" && (columns = deepClone(columns_4(obj)));
      this.tabsKey === "5" && (columns = deepClone(columns_5(obj)));

      //需要过滤的字段
      let filterKeys = [];

      // 视频号特有数据
      const shipinhaoKeys = [
        "awemeTotalLikeCount",
        "awemeThumbLikeCount",
        "awemeLoveLikeCount",
      ];

      if (this.tabData.length > 2 && this.currentTab !== "agg") {
        filterKeys = [...filterKeys, "taskFinishRate"];
      }
      if (["douyin", "kuaishou"].includes(this.currentTab)) {
        filterKeys = [
          ...filterKeys,
          "noteCount",
          "noteLikeCount",
          "noteCommentCount",
          "noteCollectCount",
          "noteShareCount",
          "imgNoteCount",
          "imgNoteLikeCount",
          "imgNoteCommentCount",
          "imgNoteCollectCount",
          "imgNoteShareCount",
          "awemeNoteCount",
        ];
      }

      if (["shipinhao"].includes(this.currentTab)) {
        filterKeys = [
          ...filterKeys,
          "noteCount",
          "noteLikeCount",
          "noteCommentCount",
          "noteCollectCount",
          "noteShareCount",
          "imgNoteCount",
          "imgNoteLikeCount",
          "imgNoteCommentCount",
          "imgNoteCollectCount",
          "imgNoteShareCount",
          "awemeNoteCount",
          "awemeLikeCount",
          "fansCount",
          "addFansCount",
          "guideshootName",
        ];
      } else {
        filterKeys.push(...shipinhaoKeys);
      }

      // 过滤掉直播相关的
      if (
        this.currentActivityItem.isLive === 0 ||
        this.currentTab === "xiaohongshu"
      ) {
        filterKeys = [
          ...filterKeys,
          "liveCount",
          "liveDuration",
          "liveWatchCount",
          "liveInteractCount",
          "liveFansAddCount",
          "liveLikeCount",
          "liveCommentCount",
          "liveGiftCount",
          "tenThousandHotLive",
          "fiftyThousandHotLive",
          "oneHundredThousandHotLive",
        ];
      }

      // 过滤掉视频相关的
      if (this.currentTab !== "xiaohongshu") {
        filterKeys = [
          ...filterKeys,
          "awemeNoteCollectCount",
          // "awemeNoteCount",
          "awemeNoteLikeCount",
          "awemeNoteCommentCount",
          "awemeNoteShareCount",
        ];
      } else {
        filterKeys = [
          ...filterKeys,
          "awemePublishCount",
          "awemeWatchCount",
          "awemeInteractCount",
          "awemeLikeCount",
          "awemeCommentCount",
          "awemeShareCount",
          "tenThousandHotAweme",
          "fiftyThousandHotAweme",
          "oneHundredThousandHotAweme",
        ];
      }
      if (this.currentActivityItem.isVideo === 0) {
        // 小红书过滤掉视频相关的
        if (
          ["agg", "xiaohongshu"].includes(this.currentTab) &&
          this.currentActivityItem.isShowNoteVideo === 1
        ) {
          filterKeys = [
            ...filterKeys,
            "awemeWatchCount",
            "awemeInteractCount",
            "tenThousandHotAweme",
            "fiftyThousandHotAweme",
            "oneHundredThousandHotAweme",
          ];
        } else {
          filterKeys = [
            ...filterKeys,
            "awemePublishCount",
            "awemeWatchCount",
            "awemeInteractCount",
            "awemeLikeCount",
            "awemeCommentCount",
            "awemeShareCount",
            "tenThousandHotAweme",
            "fiftyThousandHotAweme",
            "oneHundredThousandHotAweme",
            "awemeNoteCount",
            "awemeNoteLikeCount",
            "awemeNoteCommentCount",
            "awemeNoteShareCount",
            "awemeNoteCollectCount",
          ];
        }
      } else {
        if (this.currentActivityItem.isShowNoteVideo === 0) {
          filterKeys = [
            ...filterKeys,
            "awemeNoteCount",
            "awemeNoteLikeCount",
            "awemeNoteCommentCount",
            "awemeNoteShareCount",
            "awemeNoteCollectCount",
          ];
        }
      }

      if (this.currentActivityItem.isShowNoteContent !== 1) {
        filterKeys = [
          ...filterKeys,
          "imgNoteCount",
          "imgNoteLikeCount",
          "imgNoteCommentCount",
          "imgNoteCollectCount",
          "imgNoteShareCount",
        ];
      }
      if (["agg"].includes(this.currentTab)) {
        filterKeys = [
          ...filterKeys,
          "imgNoteLikeCount",
          "imgNoteCommentCount",
          "imgNoteCollectCount",
          "imgNoteShareCount",
        ];
        if (this.currentActivityItem.isNote === 0) {
          filterKeys = [
            ...filterKeys,
            "noteCount",
            "noteLikeCount",
            "noteCommentCount",
            "noteCollectCount",
            "noteShareCount",
          ];
        }
      }

      if (this.onlyXiaohongshu) {
        filterKeys = [
          ...filterKeys,
          "awemePublishCount",
          "awemeLikeCount",
          "awemeCommentCount",
          "awemeShareCount",
        ];
      }

      if (!this.hasDouyinKuaishou) {
        filterKeys = [
          ...filterKeys,
          "guideshootName",
          "fansCount",
          "addFansCount",
        ];
      }

      columns = columns.filter((item) => {
        return !filterKeys.includes(item.dataIndex);
      });

      this.columns = columns;
    },
    /**
     * 获取表格数据
     * @param {boolean} isFirst 是否第一页
     */
    async getTableList(isFirst) {
      if (isFirst) {
        this.current = 1;
      }
      const params = this.getRequestData();

      if (!params) {
        return false;
      }

      this.tableLoading = true;

      try {
        const res = await api[apiList[Number(this.tabsKey)]](params);
        const { code, data, msg } = res.data;
        if (code == 200) {
          if (this.tabsKey === "0") {
            this.tableData = data || [];
            this.total = data.length || 0;
          } else {
            this.tableData = data.list || [];
            this.total = data.total || 0;
          }
        } else {
          this.$message.error(msg || "获取列表失败");
          this.tableData = [];
          this.total = 0;
        }
        this.tableLoading = false;
      } catch (error) {
        console.log(error);
        this.tableLoading = false;
      }
    },
    // 获取表格请求参数
    getRequestData() {
      this.setSettingType();

      let params = {
        activityId: this.currentActivityItem.id,
        startTime: moment(this.dateValue[0]).format("YYYY-MM-DD"),
        endTime: moment(this.dateValue[1]).format("YYYY-MM-DD"),
        type:
          this.currentTab == "agg"
            ? "1"
            : this.currentTab == "douyin"
            ? "2"
            : this.currentTab == "kuaishou"
            ? "3"
            : this.currentTab == "xiaohongshu"
            ? "4"
            : this.currentTab == "shipinhao"
            ? "5"
            : "1",
      };
      // 大区 战区 小区不用传时间
      if (this.tabsKey == "0" || this.tabsKey == "1" || this.tabsKey == "4") {
        delete params.startTime;
        delete params.endTime;
      }

      this.returnSettingParams(params);

      // if (this.tabsKey != "0") {
      params.page = this.current;
      params.size = this.pageSize;
      params.sortBy = this.currentSort[this.tabsKey].sort_field;
      params.sort = this.currentSort[this.tabsKey].sort;
      params.regionId = this.regionId || "";
      params.warZoneId = this.warZoneId || "";
      params.provinceId = this.provinceId || "";
      params.cityId = this.cityId || "";
      // }
      if (this.tabsKey == "1") {
        params.storeType = this.dealerValue || "";
      }
      if (this.tabsKey !== "0" && this.tabsKey !== "4") {
        params.dealerName = this.dealerName || "";
        this.selectedDealerName = this.dealerName;
      }

      // if (this.tabsKey === '4') {
      //   params.regionId = this.regionId || "";
      //   params.warZoneId = this.warZoneId || '';
      // }

      return params;
    },
    setSettingType() {
      const newArr = this.settingData.reduce((acc, cur) => {
        if (cur.bool) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
      this.settingType = newArr;
    },
    returnSettingParams(params) {
      // let np = deepClone(params)
      this.settingData.map((item) => {
        params[item.value] = item.bool ? 1 : 0;
        if (item.definedValue) {
          params[item.definedValue] = item.bool ? item.inputValue : "";
        }
      });
    },
    /**
     * tabs切换
     */
    changeTabs(val, noRequest = false) {
      try {
        this.tableLoading = true;
        this.tabsKey = val;
        this.tableShow = false;
        this.sort_field = this.currentSort[this.tabsKey].sort_field;
        this.sort = this.currentSort[this.tabsKey].sort;

        console.log(val, this.sort_field, this.sort);
        this.setColumns();
        this.setCheckData(true);
        this.regionList = this.initRegionList(this.originalRegionList);
        // this.setTableColums();
        // this.settingData = deepClone(settingData[val]);
        this.settingType = "";
        this.setDataBySource();
        this.tableShow = true;
        if (!noRequest) {
          if (val !== "0" && val !== "4") {
            // 不是门店,最后一个设置为全部门店
            if (val !== "1") {
              this.selectArr[this.selectArr.length - 1] = "1";
            }
            this.onMultipleSelectChange(this.selectArr);
          } else {
            this.getTableList(true);
          }
        }
      } catch (error) {
        console.log("error:", error);
        this.tableLoading = false;
      }
    },
    onMultipleSelectChange(val) {
      this.selectArr = val;
      if (this.existsCombatZone) {
        this.regionId = val[0];
        this.warZoneId = val[1];
        this.provinceId = val[2];
        this.cityId = val[3];
        this.dealerValue = val[4];
      } else {
        this.regionId = val[0];
        this.provinceId = val[1];
        this.cityId = val[2];
        this.dealerValue = val[3];
      }
      this.getTableList(true);
      this.$nextTick(() => {
        this.selectedSearchList = this.$refs.multipleSelect.$el.innerText.split(
          " / "
        );
      });
      this.setWarZoneList();
    },
    // 大区选择
    regionChange(value) {
      // 上汽大众
      // if (getPrincipalId() === '869753009302654976' && this.regionId) {
      //   this.getWarZoneList();
      // } else {
      //   this.warZoneList = [];
      // }

      this.warZoneId = undefined;
      this.provinceId = undefined;
      this.cityId = undefined;
      this.existsCombatZone && this.setWarZoneList();
      !this.existsCombatZone && this.setProvinceList();
      this.setCityList();
      this.getTableList(true);
    },
    setWarZoneList() {
      let list = [];
      if (this.existsCombatZone) {
        this.regionList.forEach((item) => {
          if (item.id == this.regionId) {
            list = item.children;
          }
        });
      }
      this.warZoneList = list;
    },
    // 处理战区改变
    warZoneChange() {
      this.provinceId = undefined;
      this.cityId = undefined;
      this.setProvinceList();
      this.setCityList();
      this.getTableList(true);
    },
    setProvinceList() {
      let list = [];
      if (this.existsCombatZone && this.regionId && this.warZoneId) {
        this.regionList.forEach((item) => {
          if (item.id === this.regionId) {
            item.children.forEach((item2) => {
              if (item2.id === this.warZoneId) {
                list = item2.children;
              }
            });
          }
        });
      }

      if (!this.existsCombatZone && this.regionId) {
        this.regionList.forEach((item) => {
          if (item.id == this.regionId) {
            list = item.children;
          }
        });
      }

      this.provinceList = list;
    },
    provinceChange() {
      this.cityId = undefined;
      this.setCityList();
      this.getTableList(true);
    },
    setCityList() {
      let list = [];

      if (
        this.existsCombatZone &&
        this.regionId &&
        this.warZoneId &&
        this.provinceId
      ) {
        this.regionList.forEach((item) => {
          if (item.id == this.regionId) {
            item.children.forEach((item2) => {
              if (item2.id == this.warZoneId) {
                item2.children.forEach((item3) => {
                  if (item3.id == this.provinceId) {
                    list = item3.children;
                  }
                });
              }
            });
          }
        });
      }

      if (!this.existsCombatZone && this.regionId && this.provinceId) {
        this.regionList.forEach((item) => {
          if (item.id == this.regionId) {
            item.children.forEach((item2) => {
              if (item2.id == this.provinceId) {
                list = item2.children;
              }
            });
          }
        });
      }

      this.cityList = list;
    },
    cityChange() {
      this.getTableList(true);
    },
    // 切换门店
    storeChange(val) {
      this.getTableList(true);
    },
    // 内容显示设置切换
    changeSwitchPopover(value) {
      // 给settingType赋值
      this.settingData = value;
      this.tableShow = false;
      this.setColumns();
      this.setCheckData();
      this.setTableColums();
      this.setTableScroll();
      this.tableShow = true;
      this.getTableList(true);
    },
    // 表格排序更改触发
    tableChange(pagination, filters, sorter, index) {
      this.currentSort = deepClone(defaultSort);
      if (sorter.order) {
        this.currentSort[this.tabsKey].sort_field = sorter.field;
        this.currentSort[this.tabsKey].sort =
          sorter.order == "ascend" ? "asc" : "desc";
      } else {
        this.currentSort[this.tabsKey].sort_field = "";
        this.currentSort[this.tabsKey].sort = "";
      }
      // this.setColumns();
      this.getTableList();
    },
    // 考核-分页
    pageChange() {
      this.getTableList();
    },
    // 切换活动点击
    switchActivity() {
      // this.$refs.SwitchActivity.showDrawer();
      this.actSelectDrawerVisible = true;
    },
    /**
     * 切换活动触发
     * @param {number} id 活动id
     * @param {object} item 活动数据
     */
    async changeActivity(id, item) {
      this.currentActivityItem = item;
      if (`${this.currentActivityItem.activityStatus}` === "2") {
        this.dateValue = [moment(this.currentActivityItem.startTime), moment()];
      } else if (
        // 活动已结束
        `${this.currentActivityItem.activityStatus}` === "3"
      ) {
        this.dateValue = [
          moment(this.currentActivityItem.startTime),
          moment(this.currentActivityItem.endTime),
        ];
      } else {
        this.dateValue = [
          moment(this.currentActivityItem.startTime),
          moment(this.currentActivityItem.endTime),
        ];
      }

      this.setTabsData();
      this.currentTab = "agg";
      this.tabsKey = this.tabsData[0].value;

      this.setDataBySource();
      this.setColumns();
      this.setCheckData(true);

      this.regionId = undefined;
      this.warZoneId = undefined;
      this.provinceId = undefined;
      this.cityId = undefined;
      this.dealerValue = "1";
      // 是否显示时间选择
      this.datePickerShow =
        `${this.currentActivityItem.activityStatus}` !== "1";

      const region_result = await this.getAreaInfoByActivity();
      this.setRegionList(region_result);
      this.getUpdateTime();
      this.getTableList(true);
    },
    // 返回
    goBack() {
      let path = "/activityManagement";
      // if (this.$route.query.reject) {
      //   path = this.$route.query.reject
      // }
      this.$router.push({
        path: path,
      });
    },
    // 日期切换
    calendarChange(date) {
      this.dateValue[0] = date[0];
    },
    dateChange(date) {
      if (date.length == 0) {
        this.dateValue = [
          moment(this.currentActivityItem.startTime),
          moment(this.currentActivityItem.endTime),
        ];
      }
      this.getTableList(true);
    },
    /**
     * 禁用日期
     * @param {Moment} current 当前日期
     * @return {bool} 是否禁用
     */
    disabledDate(current) {
      let result = false;
      // 活动进行中
      if (`${this.currentActivityItem.activityStatus}` === "2") {
        result =
          current.isBefore(this.currentActivityItem.startTime, "day") ||
          current.isAfter(moment(), "day");
      } else if (
        // 活动已结束
        `${this.currentActivityItem.activityStatus}` === "3"
      ) {
        result =
          current.isBefore(this.currentActivityItem.startTime, "day") ||
          current.isAfter(this.currentActivityItem.endTime, "day");
      }
      return result;
    },
    getWarZoneList(rId) {
      let list = [];

      this.existsCombatZone &&
        rId &&
        this.regionList.forEach((item) => {
          if (item.id == rId) {
            list = item.children;
          }
        });

      return list;
    },
    getProvinceList(rId, wId) {
      let list = [];

      if (this.existsCombatZone && rId && wId) {
        this.regionList.forEach((item) => {
          if (item.id === rId) {
            item.children.forEach((item2) => {
              if (item2.id === wId) {
                list = item2.children;
              }
            });
          }
        });
      }

      if (!this.existsCombatZone && rId) {
        this.regionList.forEach((item) => {
          if (item.id == rId) {
            list = item.children;
          }
        });
      }

      return list;
    },
    getCityList(rId, wId, pId) {
      let list = [];

      this.existsCombatZone &&
        rId &&
        wId &&
        pId &&
        this.regionList.forEach((item) => {
          if (item.id == rId) {
            item.children.forEach((item2) => {
              if (item2.id == wId) {
                item2.children.forEach((item3) => {
                  if (item3.id == pId) {
                    list = item3.children;
                  }
                });
              }
            });
          }
        });

      !this.existsCombatZone &&
        rId &&
        pId &&
        this.regionList.forEach((item) => {
          if (item.id == rId) {
            item.children.forEach((item2) => {
              if (item2.id == pId) {
                list = item2.children;
              }
            });
          }
        });

      return list;
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 导出
    exportClick() {
      const params = this.getRequestData();
      const guideshootName =
        this.hasDouyinKuaishou && this.currentTab !== "shipinhao"
          ? ["guideshootName"]
          : [];
      exportTable(
        this.$http,
        {
          ...params,
          excelKeyWord:
            this.tabsKey === "2"
              ? [...this.currentCheck, ...guideshootName].join(",")
              : this.currentCheck.join(","),
          flag: this.excelType ? 1 : 0,
        },
        "afantiactivity",
        exportApiList[Number(this.tabsKey)],
        "filename"
      );
    },
    // getUpdateTime() {
    //   return moment()
    //     .subtract(1, "day")
    //     .format("YYYY-MM-DD");
    // }

    // 指标点击
    actionClick() {
      this.settingModalShow = true;
      // console.log("this.currentCheck111", this.currentCheck);
      // console.log("this.oldCheck111", this.oldCheck);
      // console.log("this.tableSetting111", this.tableSetting);
    },
    // 初始化当前table的checkbox
    setCheckData(bool) {
      this.tableSetting = [];
      let columns = this.columns;
      let newArr = columns.reduce((acc, cur) => {
        if (cur.filterable) {
          acc.push({
            label:
              cur.title ||
              cur.keyTitle ||
              this.$options.filters.returnWatchText(this.currentTab),
            value: cur.dataIndex,
          });
        }
        return acc;
      }, []);

      this.tableSetting = newArr;
      // bool为true重置checkbox
      if (bool) {
        this.currentCheck = newArr.map((item) => {
          return item.value;
        });
        this.oldCheck = deepClone(this.currentCheck);
        this.setTableColums();
        this.setTableScroll();
      }
    },
    // 将checkData同步colums
    setTableColums() {
      let allColums = this.columns;

      allColums = allColums.filter((item) => {
        return (
          !item.filterable || this.currentCheck.indexOf(item.dataIndex) >= 0
        );
      });

      this.filteredColumns = allColums;
    },
    setTableScroll() {
      if (!this.$refs.tableWrap) return;

      const tableWrap = this.$refs.tableWrap;
      const tableScroll = {
        y: "calc(100vh - 370px - 110px)",
      };
      const calcX = (cols) => {
        return cols.reduce((total, currCol) => {
          return total + currCol.width;
        }, 0);
      };
      const x = calcX(this.filteredColumns);

      if (x > tableWrap.offsetWidth) {
        this.filteredColumns.forEach((col, index) => {
          if (index < this.filteredColumns.length - 1) {
            col.fixed && (col.fixed = "left");
          } else {
            col.field && (col.field = "right");
          }
        });
        tableScroll.x = x;
      } else {
        this.filteredColumns.forEach((col) => {
          col.fixed && (col.fixed = false);
        });
      }

      this.tableScroll = tableScroll;
    },
    // checkbox点击触发
    checkboxChange() {
      console.log("this.currentCheck", this.currentCheck);
    },

    // 指标弹窗 取消点击
    settingClose() {
      this.settingModalShow = false;
      this.currentCheck = deepClone(this.oldCheck);
    },
    // 指标弹窗 确定点击
    settingSure() {
      this.settingModalShow = false;
      this.oldCheck = deepClone(this.currentCheck);
      this.tableShow = false;
      this.setTableColums();
      this.setTableScroll();
      this.tableShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

::v-deep .ant-input-clear-icon {
  color: rgba(255, 255, 255, 0.4);
}

::v-deep .ant-table-body-inner {
  overflow-x: hidden;
}

.color-yellow {
  color: #ffca5c;
}

.container {
  position: relative;

  /deep/ .sort-select-asc {
    .ant-table-column-title {
      color: #ffcc55;
    }

    .anticon-caret-up {
      color: #ffcc55;
    }
  }

  /deep/ .sort-select-desc {
    .ant-table-column-title {
      color: #ffcc55;
    }

    .anticon-caret-down {
      color: #ffcc55;
    }
  }

  // /deep/ .sort-select .anticon-caret-up{
  //   color: #ffcc55;
  // }
  .second-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 0 0 20px;

    > div {
      display: flex;
      align-items: center;

      .back-btn {
        width: 40px;
        height: 40px;
        background: #2b2f3b;
        border-radius: 9px;
        margin-right: 12px;
        color: #9ca8ba;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }

      .switch-button {
        font-size: 14px;
        color: #ffcc55;
        margin-left: 8px;
        cursor: pointer;

        .arrow-icon {
          margin-left: 4px;
          font-size: 12px;
        }
      }
    }
  }

  .tabs-div {
    padding: 0 24px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date-label {
      font-size: 14px;
      color: #8890a6;
    }

    .date-value {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
    }
  }

  .tools {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    > div {
      display: flex;
      align-items: center;

      .button {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 24px;
        font-size: 14px;
        margin-right: 15px;
        @include font_color("font_color22");
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 2px;
          height: 24px;
          @include background_color("background_color62");
        }

        .icon {
          margin-right: 6px;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          display: block;
          overflow: hidden;
        }

        &:hover .icon {
          @include background_color("background_color2");
        }

        .arrow-right {
          font-size: 22px;
          transform: rotate(180deg);
        }
      }

      .search-btn {
        @include border_color("border_color4");
        @include font_color("font_color17");
        @include background_color("background_color13");
        width: 80px;
        height: 36px;
        border-radius: 8px;
      }

      .button2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 113px;
        height: 40px;
        border-radius: 8px;
        line-height: 40px;
        font-size: 14px;
        border: none;
        margin-left: 8px;
        @include font_color("font_color1");
        @include background_color("background_color63");

        .btn-icon {
          font-size: 18px;
          margin-right: 7px;
          @include font_color("font_color34");
        }

        &:hover .btn-icon {
          @include font_color("font_color35");
        }
      }
    }
  }

  .content {
    height: calc(100vh - 320px);
    padding: 0 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;

    .first-list {
      width: 92px;
      min-width: 92px;
      margin-right: 13px;
    }

    .first-list-item {
      font-size: 14px;
      @include font_color("font_color2");
      height: 68px;
      line-height: 68px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
    }

    .other-list {
      width: 200px;
      margin-right: 13px;
    }

    .other-title {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .activity-first-list {
      width: 92px;
      min-width: 92px;
    }

    .activity-first-list.dealer-list {
      width: 150px;
    }

    .activity-first-list-item {
      font-size: 14px;
      @include font_color("font_color2");
      height: 68px;
      line-height: 68px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
    }

    .list-bg {
      // cursor: pointer;
      // -webkit-transition: all 0.3s;
      // transition: all 0.3s;
      @include background_color("background_color2");
    }

    // .list-bg:hover,
    // .list-bg.active {
    //   @include background_color("background_color42");
    //   .list-item > span {
    //     @include font_color("font_color6");
    //     font-weight: bold;
    //   }
    // }
    .list-title {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      font-size: 14px;
      @include font_color("font_color1");
      height: 52px;
      line-height: 52px;
      @include border_color("border_color5");
    }

    .sort-div {
      cursor: pointer;
      width: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 10px;

      .default-color {
        @include font_color("font_color21");
      }

      .active-color {
        @include font_color("font_color26");
      }
    }

    .list-item {
      width: 100%;
      min-width: 170px;
      padding: 0 36px;
      height: 68px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");

      > span {
        line-height: 87px;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .filter-container {
    height: 90px;
    background: #2b2f3b;
    margin: 0 24px 20px 24px;
    border-radius: 16px;
    padding: 19px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .filter-list-container {
      display: flex;
      align-items: center;

      .label {
        color: rgba(255, 255, 255, 0.88);
      }

      .tag-list:empty {
        &::after {
          content: "-";
          color: rgba(255, 255, 255, 0.88);
        }
      }
    }
  }

  .content-table {
    width: calc(100% - 40px);
    height: calc(100vh - 320px - 110px);
    margin-left: 20px;
    background: #2b2f3b;
    border-radius: 16px;

    .table-sort {
      height: 58px;
      line-height: 58px;
    }

    .table-ellipsis {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .table-dealer {
      display: flex;
      flex-direction: column;

      > div {
        height: 22px;
        font-size: 14px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        padding-right: 40px;

        &:first-child {
          font-weight: bold;
          color: rgba(255, 255, 255, 0.85);
        }

        &:last-child {
          color: rgba(255, 255, 255, 0.45);
        }
      }
    }
  }

  .page-div {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .total-text {
      font-size: 14px;
      color: #9ca8ba;
    }
  }

  .font-color2 {
    @include font_color("font_color2");
  }

  .font-color3 {
    @include font_color("font_color3");
  }

  .font-color4 {
    @include font_color("font_color4");
  }

  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }

  .font-color22 {
    @include font_color("font_color22");
  }

  .f12 {
    font-size: 12px;
  }

  .f14 {
    font-size: 14px;
  }

  .f16 {
    font-size: 16px;
  }
}

.action-ico {
  margin: 0 20px;
  // @include font_color("font_color5");
  color: #9ca8ba;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #ffca5c;
  }
}

.setting-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 100;

  &-container {
    position: absolute;
    right: 24px;
    top: 228px;
    width: 400px;
    min-height: 190px;
    border-radius: 8px;
    background: #3c4252;
    box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    padding: 20px;

    &-title {
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      line-height: 22px;
    }

    .list {
      padding: 20px 0;
      min-height: 98px;
    }

    .action-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .btn {
        width: 74px;
        height: 32px;
        border-radius: 20px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        cursor: pointer;
      }

      .btn1 {
        background: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
        margin-right: 8px;
      }

      .btn2 {
        background: #ffcc55;
        color: rgba(0, 0, 0, 0.88);
      }
    }
  }
}

.tooltip-icon {
  @include font_color("font_color50");
  @include background_color("background_color51");
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  margin-left: 4px;
}
</style>
