<template>
  <div class="statistics-card">
    <div class="statistics-card-top">
      <div class="statistics-card-top-left">
        <span>{{ title }}</span>
        <AftTooltip v-if="tooltip && code" :code="code" style="top: -2px; left: -15px" />
        <ButtonTab
          :tabData="buttonData"
          @tabChange="buttonChange"
          :defaultBtnStyle="buttonStyle"
        />
      </div>
      <RouterButton
        v-if="routerData"
        :title="routerData.name"
        :path="routerData.path"
      />
    </div>
    <div class="statistics-card-main">
      <div
        class="statistics-card-main-item"
        v-for="(item, index) in cardData[currentButtonData]"
        :key="index"
      >
        <div>
          <span class="fs1">{{ item.data[0] | changeNum }}</span>
          <span class="fs2">{{ item.data[1] | changeNum }}</span>
          <span :class="item.data[2] < 0 ? 'fs_down' : 'fs_up'">{{
            item.data[2] | numRate
          }}</span>
        </div>
        <div>
          <span class="fs3">{{ item.name[0] }}</span>
          <span class="fs3">{{ item.name[1] }}</span>
          <span class="fs3">{{ item.name[2] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTab from "@/components/PlatformTab/NewButtonTab";
import RouterButton from "@/components/RouterButton/RouterButton";
const buttonStyle = {
  minWidth: "64px",
  width: "64px",
  height: "26px",
  "line-height": "26px",
  fontSize: "12px",
  "border-radius": "8px",
};
export default {
  name: "StatisticsCard",
  props: {
    title: {
      type: String,
      default: "-",
    },
    routerData: {
      type: Object,
      default: null,
    },
    cardData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tooltip: {
      type: Boolean
    },
    code: {
      type: String
    }
  },
  components: {
    ButtonTab,
    RouterButton,
  },
  data() {
    return {
      buttonStyle,
      buttonData: [
        {
          label: "昨日",
          value: "day",
        },
        {
          label: "本周",
          value: "week",
        },
        {
          label: "本月",
          value: "month",
        },
      ],
      currentButtonData: "day",
    };
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {
    buttonChange(val) {
      console.log("buttonChange", val);
      this.currentButtonData = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.statistics-card {
  width: 100%;
  height: 172px;
  background: #13141a;
  border-radius: 16px;
  padding: 24px 20px 20px;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      > span {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        margin-right: 20px;
      }
    }
  }
  &-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 100%;
    height: 82px;
    margin-top: 20px;
    &-item {
      height: 82px;
      background: #1a1d26;
      border-radius: 16px;
      padding: 16px 27px 14px;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        &:first-child {
          margin-bottom: 10px;
          span {
            width: 33%;
            height: 25px;
            line-height: 25px;
            text-align: center;
            display: inline-block;
          }
        }
        &:last-child {
          span {
            width: 33%;
            height: 17px;
            line-height: 17px;
            text-align: center;
            display: inline-block;
          }
        }
        .fs1 {
          font-size: 22px;
          font-weight: bold;
          color: #ffca5c;
        }
        .fs2 {
          font-size: 18px;
          color: #ffca5c;
        }
        .fs3 {
          font-size: 12px;
          color: #8890a6;
        }
        .fs_up {
          font-size: 18px;
          color: #e85a34;
        }
        .fs_down {
          font-size: 18px;
          color: #45b36b;
        }
      }
    }
  }
}
</style>