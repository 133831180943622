import Nr from '@/utils/NetworldRequest'
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const platformRequest = (platform) => {
  let baseURL = getBaseUrl(platform)

  return new Nr({
    baseURL: baseURL
  }).getAxios()
}

export default {
  getInfluenceList(params) {
    return request.get('/jjt/brand/business-influence', {
      params,
    })
  },
  getPrincipalInfo(params) {
    return request.get('/jjt/brand/business-influence/one', {
      params,
    })
  },
  getPrincipalDetailList(body) {
    return request.post('/jjt/brand/business-influence/detail', body)
  },
  getBrandLiveList(body) {
    return request.post('/jjt/brand/live-hot', body)
  },
  getBrandVideoList(body) {
    return request.post('/jjt/brand/aweme-hot', body)
  },


  //jjt-2.4版本新增
  getNewBrandLiveList(body) {
    return platformRequest(body.platform).post('/jjt/new/brand/live-hot', body.params)
  },
  getNewBrandVideoList(body) {
    return platformRequest(body.platform).post('/jjt/new/brand/aweme-hot', body.params)
  },
  getNewDealerLiveList(body) {
    return platformRequest(body.platform).get('/jjt/new/brand/dealer/live-hot', { params: body.params })
  },
  getNewDealerVideoList(body) {
    return platformRequest(body.platform).get('/jjt/new/brand/dealer/aweme-hot', { params: body.params })
  },

  // 品牌总榜

  getPrincipalTags() {
    return request.get('/jjt/principal/tags')
  },
  getGeneralList(body) {
    return platformRequest(body.platform).post('/jjt/new/brand/business-influence', body.params)
  },

  // 本品排名
  getMyRankingList(params) {
    return request.get('/jjt/new/brand/business-influence/rank', {
      params,
    })
  },
  getIndexList() {
    return request.get('/jjt/new/brand/tag')
  }

}