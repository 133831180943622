let colorList = [
  "#6E9FF4",
  "#F75C5C",
  "#66789B",
  "#FFCC55",
  "#856BF8",
  "#FF9026",
  "#008685",
  "#C4DEFF",
  "#FCC2C1",
  "#CED4DE",
  "#FCEBB9",
  "#FFE0C7",
  "#BBDEDE",
  "#6E9FF4",
  "#F75C5C",
  "#66789B",
  "#FFCC55",
  "#856BF8",
  "#FF9026",
  "#008685",
  "#C4DEFF",
  "#FCC2C1",
  "#CED4DE",
  "#FCEBB9",
  "#FFE0C7",
  "#BBDEDE",
];

const option = {
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b}: {c} ({d}%)",
  },
  legend: {
    bottom: "5%",
    left: "center",
    textStyle: {
      color: "#fff",
    },
    pageTextStyle: {
      color: "#fff",
    },
    type: "scroll",
  },
  series: [
    {
      name: "22",
      type: "pie",
      minAngle: 10,
      bottom: "15%",
      avoidLabelOverlap: true,
      radius: ["50%", "75%"],
      itemStyle: {
        normal: {
          color: function(params) {
            return params.name === "无意向"
              ? "#D0D6E6"
              : colorList[params.dataIndex];
          },
        },
      },
      label: {
        formatter: "{d}%",
        rich: {
          b: {
            fontSize: 12,
            lineHeight: 30,
          },
          per: {
            color: "#fff",
            backgroundColor: "#8890A6",
            padding: [2, 6],
            borderRadius: 4,
            fontSize: 12,
            lineHeight: 30,
            verticalAlign: "center",
          },
        },
      },
      tooltip: {
        formatter: "{b}：{c} <br/>{d}%",
      },
      data: [],
    },
  ],
};

export default option;
