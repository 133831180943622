<template>
  <div class="wrap">
    <div class="wrap-title">
      <svg-icon icon-class="cup" class="icon" />
      <span>活动榜单</span>
      <div class="more-text" @click="routerTo">
        <span>更多榜单</span>
        <svg-icon icon-class="arrow-down" class="arrow-icon" />
      </div>
    </div>
    <div class="tools">
      <NewTabs
        :tabData="tabsData"
        :labelStyle="{ lineHeight: '30px' }"
        @tabChange="changeTabs"
      />
    </div>
    <div class="table-header">
      <span>排名</span>
      <template v-if="tabsKey === '2'">
        <span>直播标题</span>
        <span>观看量</span>
      </template>
      <template v-if="tabsKey === '1'">
        <span>视频标题</span>
        <span>播放量</span>
      </template>
    </div>
    <div class="table-body" v-if="activityList && activityList.length > 0">
      <div
        class="table-body-item"
        v-for="(item, index) in activityList"
        :key="index"
      >
        <span>
          <span :class="['ranking', index < 3 ? 'ranking-y' : '']">{{
            index + 1
          }}</span>
        </span>
        <template v-if="tabsKey === '2'">
          <span :title="item.liveTitle">{{ item.liveTitle || "-" }}</span>
          <span>{{ item.liveWatchCount | changeNumNoRound }}</span>
        </template>
        <template v-if="tabsKey === '1'">
          <span :title="item.awemeTitle">{{ item.awemeTitle || "-" }}</span>
          <span>{{ item.awemeWatchCount | changeNumNoRound }}</span>
        </template>
      </div>
    </div>
    <div v-else>
      <Empty style="margin-top: 140px" title="暂无数据" />
    </div>
  </div>
</template>

<script>
import api from "@/api/activityOverview";
import NewTabs from "@/components/PlatformTab/NewTabs";
import Empty from "@/components/Empty/Empty";
import deepClone from "@/utils/clone-utils";
import { getPrincipalId } from "@/utils/auth";

const defaultTabsData = [
  { label: "直播观看日榜", value: "2" },
  { label: "视频播放日榜", value: "1" }
];

const defaultTabsData2 = [
  { label: "直播观看总榜", value: "2" },
  { label: "视频播放总榜", value: "1" }
];

export default {
  name: "RightWrap2",
  components: { NewTabs, Empty },
  props: {
    currentItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    regionId: {
      type: String
    }
  },
  data() {
    return {
      tabsKey: "2",
      tabsData: [],
      activityList: [],
    };
  },
  watch: {
    currentItem: {
      immediate: true,
      handler() {
        const { activityStatus, isLive, isVideo } = this.currentItem;
        let tabsData = [];

        activityStatus !== 3 && (tabsData = deepClone(defaultTabsData));
        activityStatus === 3 && (tabsData = deepClone(defaultTabsData2));

        // 过滤掉直播相关的
        if (isLive === 0) {
          tabsData = tabsData.filter(item => !['2'].includes(item.value));
        }

        // 过滤掉视频相关的
        if (isVideo === 0) {
          tabsData = tabsData.filter(item => !['1'].includes(item.value));
        }

        this.tabsData = tabsData;
        this.tabsKey = this.tabsData.length ? this.tabsData[0].value : '';
      }
    },
    regionId() {
      this.getActivityList();
    }, 
  },
  methods: {
    // 加载当前页面所有接口
    initData() {
      this.getActivityList();
    },

    // 切换 显示tabs
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
      this.initData();
    },

    // 接口请求 - 获取活动榜单
    getActivityList() {
      const { id, activityStatus } = this.$parent.currentActivityItem;
      let params = {
        activityId: id,
        activityType: this.tabsKey,
        page: 1,
        size: 10,
        principalId: getPrincipalId(),
        regionId: this.regionId,
        rankType: activityStatus === 3 ? 2 : 1
      };
      api.getActivityList(params).then((res) => {
        if (res.data.code === 200) {
          console.log("getActivityList", res.data.data);
          this.activityList = res.data.data || [];
        } else {
          this.$message.error(res.data.message || "获取活动榜单失败");
          this.activityList = [];
        }
      });
    },
    // 跳转-活动榜单
    routerTo() {
      this.$parent.routerToByName("活动榜单");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &-title {
    width: 100%;
    height: 54px;
    background: linear-gradient(90deg, #fa5151 0%, #fa9251 100%);
    border-radius: 16px 16px 0px 0px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.88);
    line-height: 24px;
    display: flex;
    align-items: center;
    > .icon {
      margin: 0 5px 0 21px;
    }
    .more-text {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.88);
      line-height: 22px;
      margin-left: 80px;
      cursor: pointer;
      > svg {
        font-size: 10px;
        transform: rotate(-90deg);
        color: rgba(255, 255, 255, 0.55);
        position: relative;
        top: -2px;
        left: 4px;
      }
    }
  }
  .tools {
    width: calc(100% - 40px);
    height: 47px;
    padding-top: 12px;
    border-bottom: 1px solid #363a46;
    margin-left: 20px;
  }
  .table-header {
    width: 100%;
    height: 20px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.55);
    line-height: 20px;
    display: flex;
    padding: 0 20px;
    margin: 16px 0 8px;
    > span:nth-child(1) {
      width: 64px;
      text-align: left;
    }
    > span:nth-child(2) {
      width: calc(50% - 32px);
      text-align: left;
    }
    > span:nth-child(3) {
      width: calc(50% - 32px);
      text-align: right;
    }
  }
  .table-body {
    width: 100%;
    height: calc(100% - 146px);
    overflow-y: auto;
    &-item {
      width: 100%;
      height: 50px;
      background: #2b2f3b;
      box-shadow: inset 0px -1px 0px 0px #363a46;
      padding: 0 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.88);
      > span:nth-child(1) {
        width: 64px;
        text-align: left;
        > .ranking {
          width: 24px;
          height: 24px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.65);
          line-height: 24px;
          background: rgba(255, 255, 255, 0.12);
          text-align: center;
          border-radius: 12px;
          display: inline-block;
        }
        > .ranking.ranking-y {
          font-weight: bold;
          color: #ffcc55;
          background: rgba(255, 204, 85, 0.1);
        }
      }
      > span:nth-child(2) {
        width: calc(50% - 32px);
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > span:nth-child(3) {
        width: calc(50% - 32px);
        text-align: right;
      }
    }
  }
}
</style>
