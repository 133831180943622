<template>
  <div class="container">
    <platform-header
      title="直播排行"
      :currentTab="currentTab"
      @tabChange="tabChange"
      :tooltipName="'数据说明'"
      :showTooltip="true"
      :code="'zpqt01'"
    />
    <div class="title">
      <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
      <a-button
        v-hide-by-username
        class="button2"
        @click="exportClick"
        :loading="exportLoading"
      >
        <svg-icon icon-class="export" class="btn-icon" />
        <span>Excel导出</span>
      </a-button>
    </div>
    <div :class="['tools', 'arrow-tools', isToolsOpen ? 'opened' : '']">
      <div>
        <div>
          <!-- 大区 -->
          <a-select
            v-model="regionValue"
            :suffixIcon="suffixIcon"
            style="width: 130px; height: 40px"
            placeholder="选择大区"
            @change="
              groupValue = undefined;
              search(true);
            "
          >
            <a-select-option value="">不限大区</a-select-option>
            <a-select-option
              :value="item.id"
              v-for="item in regionData.region_list"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <!-- 分组 -->
          <a-select
            v-model="groupValue"
            :suffixIcon="suffixIcon"
            style="margin: 0 0 0 12px; width: 130px; height: 40px"
            placeholder="选择分组"
            @change="
              regionValue = undefined;
              search(true);
            "
          >
            <a-select-option value="">不限分组</a-select-option>
            <a-select-option
              :value="item.group_code"
              v-for="item in groupData"
              :key="item.group_code"
            >
              {{ item.group_name }}
            </a-select-option>
          </a-select>
          <!-- 违规类型 -->
          <!-- <a-select
            v-model="violation_type"
            class="aft-select"
            placeholder="请选择违规类型"
            :suffixIcon="suffixIcon"
            @change="search(true)"
            style="margin: 0 0 0 12px; width: 164px; height: 40px"
          >
            <a-select-option value="">全部类型</a-select-option>
            <a-select-option value="1">挂播</a-select-option>
            <a-select-option value="2">非挂播</a-select-option>
          </a-select> -->
          <a-tree-select
            v-model="violation_tree_value"
            style="margin: 0 0 0 12px; width: 214px; height: 40px"
            :suffixIcon="suffixIcon"
            :allowClear="true"
            :maxTagCount="1"
            :tree-data="violationTreeData"
            :filterTreeNode="filterTreeOption"
            tree-checkable
            placeholder="请选择违规类型"
            :treeDefaultExpandAll="true"
            @change="search(true)"
          />
          <!-- 活动名称 -->
          <!-- <a-select
            v-model="topic_id"
            class="aft-select"
            placeholder="请选择活动名称"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :suffixIcon="suffixIcon"
            showArrow
            @change="search(true)"
            style="margin: 0 0 0 12px; width: 180px; height: 40px"
          >
            <a-select-option value="">全部活动</a-select-option>
            <a-select-option
              v-for="item in activityData || []"
              :key="item.id"
              :value="item.id"
            >
              {{ item.activity_name }}
            </a-select-option>
          </a-select> -->
          <span class="split"></span>
          <span class="arrow-btn" @click="isToolsOpen = !isToolsOpen">
            <svg-icon icon-class="select-arrow" class="arrow-btn-icon" />
          </span>
        </div>
        <div>
          <!-- 直播标题 -->
          <span class="tools-label">直播标题： </span>
          <a-input-search
            v-model.trim="title_ipt"
            v-emoji
            @search="search(true)"
            @pressEnter="search(true)"
            style="width: 180px; margin-right: 12px"
            class="aft-search"
            placeholder="搜索关键字"
          />
          <!-- 门店/账号名称 -->
          <span class="tools-label">门店/账号名称： </span>
          <a-input-search
            v-model.trim="name_ipt"
            v-emoji
            @search="search(true)"
            @pressEnter="search(true)"
            style="width: 180px; margin-right: 12px"
            class="aft-search"
            placeholder="搜索关键字"
          />
          <a-select
            class="aft-select"
            v-model="verification_type"
            :suffixIcon="suffixIcon"
            placeholder="请选择账号类型"
            style="width: 150px; height: 40px"
            @change="search(true)"
          >
            <a-select-option
              v-for="item in accountTypeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <a-select
            class="aft-select"
            v-model="author_tag_id"
            :suffixIcon="suffixIcon"
            placeholder="请选择业务用途"
            style="width: 150px; height: 40px"
            @change="search(true)"
          >
            <a-select-option
              v-for="item in businessTypeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <date-tab
        :type="dateType"
        @changeType="changeType"
        @changeDate="search(true)"
        ref="dateTab"
      ></date-tab>
    </div>
    <div :class="['content', isToolsOpen ? 'opened' : '']">
      <a-spin :spinning="cardLoading">
        <div :class="['card', isToolsOpen ? 'opened' : '']">
          <div class="list-box" v-if="tableData.length > 0">
            <div
              class="hover-item"
              v-for="item in tableData"
              :key="item.room_id"
              @click="openDetail(item)"
            >
              <div class="item">
                <div class="img-item">
                  <WebpImage
                    :src="item.cover || ''"
                    :width="'84px'"
                    :height="'84px'"
                    :radius="'0px'"
                  />
                </div>
                <div class="card-content">
                  <div class="card-title">
                    <span>{{ item.title || "-" }}</span>
                    <a-tag
                      color="#FFC05C"
                      v-if="item.is_dongchedi_live == 1"
                      style="margin: 0 0 0 10px"
                      >一机双播</a-tag
                    >
                  </div>
                  <div class="detail">
                    <div class="detail-item">
                      <img src="@/assets/img/type-store.png" alt="" />
                      <span>{{ item.dealer_name || "-" }}</span>
                    </div>

                    <div class="detail-item">
                      <img
                        src="@/assets/img/type-lanv.png"
                        v-if="item.verification_type == 2"
                        alt=""
                      />
                      <img src="@/assets/img/type-ordinary.png" v-else alt="" />
                      <span>{{ item.nickname || "-" }}</span>
                    </div>
                  </div>
                  <div class="data">
                    <div class="col">
                      <div class="name">门店编码</div>
                      <div class="num">
                        <span>{{ item.dealer_store_code || "-" }}</span>
                      </div>
                    </div>
                    <div class="col" style="width: 190px">
                      <div class="name">开播时间</div>
                      <div class="num">
                        <span>{{ item.publish_time }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name" @click.stop>
                        观看人数<AftTooltip
                          :code="'qjzb11'"
                          style="top: 0px; left: 4px"
                        />
                      </div>
                      <div class="num">
                        <span>{{
                          item.statistics_total_watch_user_count | changeNum
                        }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name" @click.stop>
                        观看人次<AftTooltip
                          :code="'qjzb11'"
                          style="top: 0px; left: 4px"
                        />
                      </div>
                      <div class="num">
                        <span>{{
                          item.statistics_total_watch_user_time | changeNum
                        }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">
                        {{ currentTab == "dongchedi" ? "评论人数" : "评论数" }}
                      </div>
                      <div class="num">
                        <span>{{
                          item.statistics_total_comment_count | changeNum
                        }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">点赞量</div>
                      <div class="num">
                        <span>{{
                          item.statistics_total_like_count | changeNum
                        }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">开播时长</div>
                      <div class="num">
                        <span>{{
                          item.statistics_live_time | disposeLongTime_m
                        }}</span>
                      </div>
                    </div>
                    <div class="col" v-if="currentTab == 'douyin'">
                      <div class="name">单场增粉</div>
                      <div class="num">
                        <span>{{ item.statistics_fans_add | changeNum }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fixed-button" @click.stop="openAnalysis(item)">
                智能分析
              </div>
            </div>
          </div>
          <Empty
            v-else
            style="padding: calc(50vh - 220px) 0 0 0"
            title="暂无数据"
          />
        </div>
      </a-spin>
      <div class="page-div aft-pagination">
        <span class="total-text">共 {{ total || 0 }} 项数据</span>
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "liveRanking"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import DateTab from "@/components/DateTab/DateTab2";
import MultipleGroup from "@/components/MultipleGroup/MultipleGroup";
import { getToken, getPrincipalId } from "@/utils/auth";
import WebpImage from "@/components/WebpImage/WebpImage";
import exportTable from "@/utils/exportTable";
import Empty from "@/components/Empty/Empty";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import { accountTypeList, businessTypeList } from "./constant";

export default {
  components: {
    PlatformHeader,
    DateTab,
    MultipleGroup,
    WebpImage,
    ExcelTypeButton,
    Empty,
  },
  data() {
    return {
      defultAvatar: require("@/assets/img/defult-avatar.png"),
      excelType: false,
      exportLoading: false,
      currentTab: "",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      dateType: "month",
      regionValue: undefined,
      groupValue: undefined,
      accountTypeList,
      verification_type: undefined,
      businessTypeList,
      author_tag_id: "",
      violation_type: undefined,
      title_ipt: "",
      isToolsOpen: false,
      name_ipt: "",
      cardLoading: false,
      current: 1,
      pageSize: 20,
      total: 0,
      tableData: [],
      topic_id: undefined,
      violationTreeData: [
        {
          title: "违规直播",
          value: "all",
          key: "all",
          children: [],
        },
        {
          title: "非违规直播",
          value: "0",
          key: "0",
        },
      ],
      violation_tree_value: [],

      isFirstInit: true,
    };
  },
  watch: {
    liveData(val) {
      this.tableData = val.list || [];
      this.total = val.total;
    },
  },
  computed: {
    ...mapState({
      regionData: (state) => state.regionData, //区域
      groupData: (state) => state.groupData,
      liveData: (state) => state.liveData,
      activityData: (state) => state.activityData,
      monitorTagList: (state) => state.monitorTagList,
    }),
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions([
      "getAreaList",
      "getAllGroup",
      "getMonitorTag",
      "getLiveList",
      "getActivityList",
    ]),
    ...mapMutations(["changeState"]),
    async initData() {
      return Promise.all([
        this.getAreaList(),
        this.getAllGroup(),
        this.getMonitorTag({ types: 2, principalId: getPrincipalId() }),
      ]).then((res) => {
        if (this.monitorTagList.length === 0) {
          this.violationTreeData = [
            {
              title: "非违规直播",
              value: "0",
              key: "0",
            },
          ];
        } else {
          this.violationTreeData = [
            {
              title: "违规直播",
              value: "all",
              key: "all",
              children: this.monitorTagList.map((item) => {
                return {
                  title: item.tagName,
                  value: String(item.tagCode),
                  key: String(item.tagCode),
                };
              }),
            },
            {
              title: "非违规直播",
              value: "0",
              key: "0",
            },
          ];
        }
      });
    },
    // 直播/视频 tab切换
    async tabChange(val) {
      this.currentTab = val;
      this.topic_id = undefined;
      this.changeState({ data: [], name: "activityData" });
      if (this.isFirstInit) {
        this.isFirstInit = false;
        await this.initData();
      }
      await this.getActivityList({ platform: this.currentTab });
      this.search(true);
    },
    async search(isFirst) {
      this.cardLoading = true;
      let begin_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      if (isFirst) {
        this.current = 1;
      }
      let body = {
        params: {
          region_id: this.regionValue || "",
          group_code: this.groupValue || "",
          activity_id: this.topic_id || "",
          verification_type: this.verification_type || "",
          author_tag_id: this.author_tag_id || "",
          account_type: this.author_tag_id == 30 ? this.author_tag_id : "",
          illegal_type: this.violation_tree_value.join(",") || "",
          field: this.name_ipt || "",
          title_field: this.title_ipt || "",
          start_time: begin_time,
          end_time: end_time,
          current: this.current,
          size: this.pageSize,
        },
        platform: this.currentTab,
      };
      console.log(body);
      await this.getLiveList(body);
      this.cardLoading = false;
    },

    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 导出
    exportClick() {
      let begin_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      let params = {
        region_id: this.regionValue || "",
        group_code: this.groupValue || "",
        activity_id: this.topic_id || "",
        verification_type: this.verification_type || "",
        author_tag_id: this.author_tag_id || "",
        account_type: this.author_tag_id == 30 ? this.author_tag_id : "",
        illegal_type: this.violation_tree_value.join(",") || "",
        field: this.name_ipt || "",
        title_field: this.title_ipt || "",
        start_time: begin_time,
        end_time: end_time,
        flag: this.excelType ? 1 : 0,
      };
      this.exportLoading = true;
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/live/top/exportExcel",
        "filename",
        this.exportCallback
      );
    },
    exportCallback() {
      this.exportLoading = false;
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      // this.search(true);
    },
    // 页码切换
    pageChange(val) {
      console.log(val);
      this.search();
    },
    // 违规类型tree切换
    changeViolationTree(value) {
      console.log(value);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.trim().toLowerCase()) >= 0
      );
    },
    filterTreeOption(inputValue, treeNode) {
      console.log(inputValue, treeNode);
      return (
        treeNode.data.props.title
          .toLowerCase()
          .indexOf(inputValue.trim().toLowerCase()) >= 0
      );
      return true;
    },
    openDetail(item) {
      if (item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${this.currentTab}`;
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
    openAnalysis(item) {
      if (item.room_id) {
        let url = `/intentionAnalysis?id=${item.room_id}&platform=${this.currentTab}`;
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .platform-div {
    width: 100%;
    height: 40px;
    @include background_color("background_color2");
    text-align: right;
    position: relative;

    .beta-icon {
      width: 36px;
      height: 14px;
      border-radius: 7px;
      background: #ff4160;
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      position: absolute;
      top: 4px;
      right: 0px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    padding: 0px 24px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include font_color("font_color2");
    .button {
      width: 112px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      background: none;
    }
    .btn-color1 {
      @include font_color("font_color4");
      @include background_color("background_color1");
      @include border_color("font_color4");
    }
    .btn-color2 {
      @include font_color("font_color7");
      @include background_color("background_color1");
      @include border_color("font_color7");
      margin-left: 24px;
    }
    .button2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      margin-left: 10px;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }

  .arrow-tools {
    height: 40px;
    overflow: hidden;
    transition: all 0.3s;
    > div:first-child > div {
      display: flex;
      align-items: center;
      height: 40px;
      margin-top: 8px;
      &:first-child {
        margin: 0;
      }
    }
    &.opened {
      height: 136px;
      .arrow-btn {
        background: #2b2f3b;
        &-icon {
          color: #9ca8ba;
          transform: rotate(180deg);
        }
      }
    }
    .split {
      width: 1px;
      height: 28px;
      background: #2b2f3b;
      margin: 6px 13px;
      display: inline-block;
    }
    .arrow-btn {
      width: 40px;
      height: 40px;
      background: #473f30;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      &-icon {
        font-size: 12px;
        color: #ffca5c;
      }
    }
  }
  .tools {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    &-label {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
    }
  }
  .content {
    height: calc(100vh - 274px);
    overflow: hidden;
    padding: 0 24px;
    transition: all 0.3s;
    &.opened {
      height: calc(100vh - 322px);
    }
    .card {
      height: calc(100vh - 350px);
      overflow-y: auto;
      padding: 0px 20px 0px 0;
      border-radius: 16px;
      @include background_color("background_color2");
      transition: all 0.3s;
      &.opened {
        height: calc(100vh - 400px);
      }
    }
    .list-box {
      margin-right: -20px;
      .hover-item {
        position: relative;
        &:hover {
          @include background_color("background_color82");
          .fixed-button {
            opacity: 1;
          }
        }
        .item {
          display: flex;
          padding: 24px 0 24px 0;
          margin-left: 30px;
          cursor: pointer;
          border-top: 1px solid;
          @include border_color("border_color18");
          .img-item {
            width: 84px;
            height: 108px;
            display: flex;
            align-items: center;
            background: #21232c;
            border-radius: 8px;
          }
        }

        &:first-child {
          .item {
            border: none;
          }
        }
        .fixed-button {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 72px;
          height: 28px;
          @include background_color("background_color13");
          border-radius: 8px;
          line-height: 28px;
          text-align: center;
          font-size: 12px;
          @include font_color("font_color41");
          font-weight: bold;
          opacity: 0;
          cursor: pointer;
        }
      }
      .cover {
        width: 84px;
        height: 112px;
        border-radius: 8px;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        @include background_color("background_color73");
      }
      .card-content {
        flex: 1;
        margin-left: 20px;
      }
      .card-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        @include font_color("font_color21");
      }
      .detail {
        display: flex;
        align-items: center;
        margin: 8px 0 18px;

        .detail-item {
          display: flex;
          align-items: center;
          > img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          > span {
            font-size: 14px;
            @include font_color("font_color22");
          }
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      .data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 12px;
          @include font_color("font_color23");
        }
        .num {
          margin-top: 5px;
          font-size: 16px;
          @include font_color("font_color43");
          span {
            font-weight: bold;
          }
        }
        .linght {
          @include font_color("font_color27");
        }
        > div {
          width: 11.5%;
          margin-right: 1%;
        }
      }
    }
  }
  .page-div {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .total-text {
      font-size: 14px;
      color: #9ca8ba;
    }
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .ellipsis {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}
</style>
