import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
    return (new Nr).getBaseUrl(serviceName)
}

const activity_request = new Nr({
    baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API
}).getAxios()

export default {
    // 大区下拉列表
    getAreaInfoByActivity(params) {
        return activity_request.get('/jjt/activity/web/statistic/regionList', { params: params })
    },
    // 活动榜单列表
    getActivityListByTypes(params) {
        return activity_request.get('/jjt/activity/web/profile/activityListByTypes', { params: params })
    },
}
