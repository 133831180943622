import { getToken, getPrincipalId, getUserId } from "@/utils/auth";
import { message } from 'ant-design-vue'

function getBaseUrl(serviceName) {
    let url = ''
    switch (serviceName) {
        case 'cas':
            url = process.env.VUE_APP_CAS_API
            break;
        case 'tracking':
            url = process.env.VUE_APP_TRACKING_API
            break;
        case 'agg':
            url = process.env.VUE_APP_OPENDATA_AGG_API
            break;
        case 'mqtt':
            url = process.env.VUE_APP_OPENDATA_MQTT_API
            break;
        case 'douyin':
            url = process.env.VUE_APP_OPENDATA_API
            break;
        case 'kuaishou':
            url = process.env.VUE_APP_OPENDATA_KS_API
            break;
        case 'dongchedi':
            url = process.env.VUE_APP_OPENDATA_DCD_API
            break;
        case 'hongqi':
            url = process.env.VUE_APP_OPENDATA_HQ_API
            break;
        case 'contentcenter':
            url = process.env.VUE_APP_OPENDATA_CONTENTCENTER_API
            break;
        case 'afanticontent':
            url = process.env.VUE_APP_OPENDATA_AFANTICONTENT_API
            break;
        case 'afantiactivity':
            url = process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API
            break;
        case 'businessmix':
            url = process.env.VUE_APP_OPENDATA_BUSINESSMIX_API
            break;
        default:
            url = process.env.VUE_APP_OPENDATA_API
            break;
    }
    return url
}
function exportTable(http, params, serviceName, url, exportName, callback) {

    const httpObj = {
        method: 'get', // 请求方式
        url: getBaseUrl(serviceName) + url, // 请求地址
        headers: {
            Authorization: getToken(),
            client_id: "new-jjt",
            p_id: getPrincipalId(),
            user_id: getUserId(),
        },
        params: params,
        responseType: "blob", // 重点，请求的数据类型
    };
    http(httpObj)
        .then((response) => {
            if (response.body.code == 5004 || response.body.code == 5001) {
                message.warning({
                    content: response.body.msg,
                    duration: 3
                })
            }
            console.log(response)
            let filename = exportName || '数据导出.xlsx'
            if (exportName == 'filename') {
                filename = decodeURIComponent(decodeURIComponent(response.headers.map['content-disposition'][0].split('filename=')[1]))
            }
            /* 兼容ie内核，360浏览器的兼容模式 */
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                const blob = new Blob([response.data]);
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                /* 火狐谷歌的文件下载方式 */
                var blob = new Blob([response.data]);
                var downloadElement = document.createElement("a");
                var href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = filename;
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
            }
            callback && callback()
        })
        .catch((response) => {
            console.log(response);
            callback && callback()
        });
}
export default exportTable