var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-container",style:({
    'background-image': `url(${require('../../assets/default/default'+_vm.scale+'.png')})`,
    'min-width': _vm.width,
    'width': _vm.width,
    'min-height': _vm.height,
    'height': _vm.height,
    'border-radius': _vm.radius,
  })},[_c('img',{style:({
      'background-image': `url(${require('../../assets/default/default'+_vm.scale+'.png')})`,
      'border-radius': _vm.radius,
    }),attrs:{"src":_vm.currentSrc},on:{"error":function($event){_vm.isBind && _vm.noFind()}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }