import echarts from 'echarts'
var option = {
  grid: {
    left: "50",
    right: "60",
    bottom: "5%",
    top: "70",
    containLabel: true,
  },
  legend: {
    top: 10,
    left: 30,
    itemWidth: 12,
    itemHeight: 12,
    inactiveColor: '#3C4252',
    icon: 'path://M512.005117 958.708971C265.683035 958.708971 65.290005 758.316965 65.290005 511.99386c0-246.310825 200.39303-446.703855 446.715111-446.703855 246.310825 0 446.703855 200.39303 446.703855 446.703855C958.708971 758.316965 758.316965 958.708971 512.005117 958.708971zM512.005117 169.716356c-188.738595 0-342.289784 153.545048-342.289784 342.277504 0 188.738595 153.551188 342.289784 342.289784 342.289784 188.733479 0 342.278527-153.551188 342.278527-342.289784C854.283644 323.261405 700.738595 169.716356 512.005117 169.716356z',
    textStyle: {
      fontSize: 12,
      color: '#fff',
      padding: [0, 20, 0, 4]
    },
  },
  tooltip: {
    trigger: 'axis',
    enterable: true,
    confine: true,
    padding: 0,
    axisPointer: {
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.2)'
      }
    },
    position: function (point, params, dom, rect, size) {
      return [point[0]-1, point[1]-1];
    },
  },

  xAxis: [{
    show: true,
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)',
      }
    },
    splitArea: {
      // show: true,
      color: '#8890A6',
      lineStyle: {
        color: '#8890A6'
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6',
        fontSize: 10
      },
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: [],

  }],
  yAxis: [{
    type: 'value',
    // min: 0,
    // max: 140,
    splitNumber: 4,
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)'
      }
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6',
        fontSize: 10
      },
    },
    axisTick: {
      show: false,
    },
  }],
  series: []
}

export default option