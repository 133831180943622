<template>
  <div class="player-item">
    <video
      :id="id"
      class="video-js vjs-big-play-centered"
      controls
      loop
      :ish265="h264url ? false : true"
      islive
      hasvideo
      hasaudio
      v-bind="{ autoplay: isAutoPlay }"
      :poster="poster"
    >
      <source :src="h264url || h265url" type="video/x-flv" />
    </video>
    <div class="tooltip" v-show="show">
      当前直播流清晰度过高，可能会播放卡顿
    </div>
  </div>
</template>
<script>
// 优先选用h264链接,没有时选用h265链接
import videojs from "video.js";
import "./combine/plugin";
// import WXInlinePlayer from "wx-inline-player-new";
export default {
  name: "PlayerItem",
  components: {},
  props: {
    id: {
      type: String,
      default: "",
    },
    poster: {
      type: String,
      default: "",
    },
    h264url: {
      type: String,
      default: "",
    },
    h265url: {
      type: String,
      default: "",
    },
    isShowTooltip: {
      type: Boolean,
      default: false,
    },
    isAutoPlay: {
      ype: Boolean,
      default: true,
    },
  },
  data() {
    return {
      player: null,
      show: false,
    };
  },
  computed: {},

  mounted() {
    this.$nextTick(() => {
      this.initPlayer();
    });
  },
  methods: {
    initPlayer() {
      if (this.isShowTooltip) {
        // this.$message.warning("当前直播流清晰度过高，可能会播放卡顿");
        this.showTooltip();
      }
      if (this.h264url || this.h265url) {
        this.player = videojs(this.id, {
          preload: "auto",
          techOrder: ["html5", "flvh265"],
          controlBar: {
            volumePanel: { inline: false },
            pictureInPictureToggle: false,
          },
        });
        if (this.isAutoPlay) {
          this.player.play();
        }
        this.player.on("error", function () {
          console.log("this.player.onerror", this.error());
        });
        this.player.on("loadstart", function () {
          console.log("loadstart");
          console.time();
        });
        this.player.on("loadedmetadata", function () {
          console.log("loadedmetadata");
          console.timeEnd();
        });
      }
    },
    showTooltip() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 2000);
    },
  },
  destroyed() {
    this.player.dispose();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.player-item {
  width: 100%;
  height: 100%;
  position: relative;
  video {
    width: 100%;
    height: 100%;
  }
  > div {
    width: 100%;
    height: 100%;
  }
  .tooltip {
    position: absolute;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #000;
    color: #fff;
    font-size: 13px;
    z-index: 999;
    bottom: 30px;
    left: 0;
  }
}
</style>
<style>
.video-js .vjs-time-control {
  display: none !important;
}
</style>