<template>
  <div class="jjt-label-content">
    <div class="label">{{ label }}</div>
    <div class="content" :style="{ marginTop: gap + 'px' }">
      <slot>
        {{ content }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    content: {
      type: String,
      default: "",
    },
    gap: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
.jjt-label-content {
  line-height: 22px;
  font-size: 14px;
  .label {
    color: rgba(255, 255, 255, 0.55);
  }
  .content {
    color: rgba(255, 255, 255, 0.88);
  }
}
</style>
