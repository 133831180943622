<template>
  <div class="video-list-container">
    <div class="data-box">
      <div class="data-box-left">
        <div class="card-title">数据概览</div>
        <div class="card-tips" v-if="currentTarget">
          <span
            >{{
              getArrayLastItem(currentTarget.targetChart).start_time.slice(
                5,
                10
              )
            }}
            -
            {{
              getArrayLastItem(currentTarget.targetChart).end_time.slice(5, 10)
            }}，{{ currentTarget.targetName }}：</span
          >
          <span class="number">{{
            getArrayLastItem(currentTarget.targetChart).value | changeNum
          }}</span>
          <span>，环比增长：</span>
          <span class="number">{{
            getArrayLastItem(currentTarget.targetChart).ratio | numRate2
          }}</span>
        </div>
        <div class="card-container">
          <div
            :class="[
              'card-container-item',
              currentTarget &&
              currentTarget.targetCountKey == item.targetCountKey
                ? 'active'
                : '',
            ]"
            v-for="item in targetData"
            :key="item.targetCountKey"
            @click="currentTarget = item"
          >
            <span class="label">{{ item.targetName }}</span>
            <span class="number">{{ item.targetCount | changeNum }}</span>
          </div>
        </div>
      </div>
      <div class="data-box-right">
        <div class="chart-box">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(currentTarget && currentTarget.targetChart)"
            :id="'bar-chart-clue'"
          />
        </div>
      </div>
    </div>
    <div class="table-box">
      <div class="title">留资总量</div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :rowKey="(data) => data.dealer_id"
        :loading="tableLoading"
        :pagination="false"
        :locale="locale"
        class="aft-table default"
      >
        <div slot="account" slot-scope="text, record" class="table-account">
          <div class="table-account-left">
            <WebpImage
              :src="record.avatar"
              :width="'48px'"
              :height="'48px'"
              :radius="'50%'"
              class="img"
            />
            <div>
              <div>
                <span style="font-size: 14px; color: #fff">{{
                  record.dealer_name
                }}</span>
              </div>
              <div>
                <span style="font-size: 12px; color: #d0d6e6">{{
                  record.dealer_company_name
                }}</span>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const targetData = {
  3: [
    {
      targetName: "销售线索",
      targetCountKey: "sales_clue_count",
      targetCount: "",
      targetChartKey: "sales_clue_chart",
      targetChart: [],
    },
  ],
  2: [
    {
      targetName: "销售线索",
      targetCountKey: "sales_clue_count",
      targetCount: "",
      targetChartKey: "sales_clue_chart",
      targetChart: [],
    },
  ],
};
import api from "@/api/production";
import Empty from "@/components/Empty/Empty";
import WebpImage from "@/components/WebpImage/WebpImage";
import deepClone from "@/utils/clone-utils";
import MyChart from "@/components/MyChart/MyChart";
import chartBar3 from "../options/chartBar3.js";
import filters from "@/filters";

export default {
  components: {
    MyChart,
    WebpImage,
  },
  data() {
    return {
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      tableLoading: false,
      targetData: [],
      currentTarget: null,
      columns: [
        {
          dataIndex: "dealer_company_name",
          title: "门店名称",
          width: 320,
          scopedSlots: { customRender: "account" },
        },
        {
          dataIndex: "homepage",
          title: "个人主页",
          align: "left",
          customRender: function (text) {
            return filters.changeNum(text);
          },
        },
        {
          dataIndex: "industry_anchor",
          title: "行业锚点",
          align: "left",
          customRender: function (text) {
            return filters.changeNum(text);
          },
        },
        {
          dataIndex: "reservation_component",
          title: "预约组件",
          align: "left",
          customRender: function (text) {
            return filters.changeNum(text);
          },
        },
        {
          dataIndex: "video_component",
          title: "视频组件",
          align: "left",
          customRender: function (text) {
            return filters.changeNum(text);
          },
        },
        {
          dataIndex: "live_component",
          title: "直播组件",
          align: "left",
          customRender: function (text) {
            return filters.changeNum(text);
          },
        },
        {
          dataIndex: "private_letter",
          title: "私信",
          align: "left",
          customRender: function (text) {
            return filters.changeNum(text);
          },
        },
        {
          dataIndex: "other",
          title: "其他",
          align: "left",
          customRender: function (text) {
            return filters.changeNum(text);
          },
        },
        {
          dataIndex: "sales_cue_count",
          title: "销售线索",
          width: 135,
          align: "center",
          customRender: function (text) {
            return (
              <span style="color: #FFCC55;">{filters.changeNum(text)}</span>
            );
          },
        },
      ],
      tableData: [],
      chartShow: true,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getClueDataChart();
    },
    // 线索数据概览
    getClueDataChart() {
      let parentParams = this.getParams();
      console.log("parentParams", parentParams);
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
          date_type: parentParams.dateType,
        },
      };
      // 清空targetData
      this.targetData = [];
      this.currentTarget = null;

      this.tableData = [];
      api.getClueDataChart(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          // 按月和周拿到当前需要展示的指标
          let defaultData = deepClone(targetData)[parentParams.dateType];
          // 补全接口数据到targetData
          this.targetData = defaultData.map((item) => {
            return {
              ...item,
              targetCount: res.data.data[item.targetChartKey][0].value,
              targetChart: res.data.data[item.targetChartKey].reverse(),
            };
          });
          this.currentTarget = this.targetData[0];
          this.tableData = res.data.data.detail ? [res.data.data.detail] : [];
        } else {
          console.error("获取线索数据概览失败");
        }
      });
    },

    getBarOption(data) {
      let parentParams = this.getParams();
      let option = deepClone(chartBar3);
      if (data && data.length) {
        let xData = data.map((item) => {
          return item.item;
        });
        option.xAxis.data = xData;
        if (parentParams.dateType == 3) {
          option.xAxis.axisLabel.formatter = "近{value}月";
        } else if (parentParams.dateType == 2) {
          option.xAxis.axisLabel.formatter = "近{value}周";
        }
        option.tooltip.formatter = (params) => {
          let newParams = params[0]
          return `
          <div style="background: #3C4252;line-height: 24px;font-size: 12px;padding: 4px;">
            <div style="color: rgba(255,255,255,0.88);font-weight: bold;">${newParams.data.start_time.slice(
              0,
              10
            )} - ${newParams.data.end_time.slice(0, 10)}</div>
            <div><span style="color: rgba(255,255,255,0.55);">${
              this.currentTarget.targetName
            }: </span><span style="color: #fff;">${filters.changeNum(
            newParams.data.value
          )}</span></div>
            <div><span style="color: rgba(255,255,255,0.55);">环比增长: </span><span style="color: #fff;">${filters.numRate2(
              newParams.data.ratio
            )}</span></div>
          </div>`;
        };
        option.series[0].data = data;
      }
      return option;
    },
    getParams() {
      return this.$parent.getParams();
    },
    getArrayLastItem(arr) {
      if (arr && arr.length) {
        return arr[arr.length - 1];
      } else {
        return {};
      }
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.video-list-container {
  width: 100%;
  .data-box {
    width: 100%;
    height: 304px;
    display: flex;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-wrap: nowrap;
    &-left {
      width: 436px;
      height: 100%;
      .card-title {
        height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
        line-height: 24px;
      }
      .card-tips {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #242832;
        border-radius: 8px;
        margin: 20px 0 16px;
        padding-left: 12px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        .number {
          color: #ffcc55;
        }
      }
      .card-container {
        width: 100%;
        height: 164px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
        &-item {
          width: 140px;
          height: 78px;
          background: none;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 12px;
          .label {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.55);
            line-height: 22px;
            margin-bottom: 4px;
          }
          .number {
            font-size: 20px;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.88);
            line-height: 28px;
          }
          &:hover {
            background: #242832;
          }
          &.active {
            background: #ffcc55;
            .label {
              color: rgba(0, 0, 0, 0.55);
            }
            .number {
              color: rgba(0, 0, 0, 0.88);
            }
          }
        }
      }
    }
    &-right {
      width: calc(100% - 436px);
      height: 100%;
      .chart-box {
        width: 100%;
        height: 100%;
      }
    }
  }
  .table-box {
    width: 100%;
    height: 194px;
    background: #2b2f3b;
    margin: 16px 0;
    border-radius: 16px;
    .title {
      width: 100%;
      height: 64px;
      padding-left: 20px;
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
      line-height: 64px;
    }

    .table-account {
      display: flex;
      flex-direction: row;
      margin: 16px 0;
      &-left {
        display: flex;
        flex-direction: row;
        @include font_color("font_color3");
        > .img {
          border-radius: 50%;
          @include border_color("border_color6");
        }
        > div {
          margin-left: 8px;
          > div {
            width: 190px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>