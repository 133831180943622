<template>
  <div class="tabs-content4">
    <div class="tabs-content4-main">
      <div class="title-div">
        <span class="title">大区对比</span>
      </div>
      <div class="chart-div" v-if="regionData && regionData.length > 0">
        <div class="chart-div-left">
          <div class="table-container">
            <div class="table-container-header">
              <div>大区</div>
              <div>门店数</div>
              <div>组件覆盖流量</div>
              <div>挂载率</div>
            </div>
            <div class="table-container-body">
              <div
                :class="[
                  'table-item',
                  currentLabel == item.area_name ? 'active' : '',
                ]"
                v-for="(item, index) in regionData"
                :key="index"
              >
                <div>{{ item.area_name || "暂无大区" }}</div>
                <div>{{ item.dealer_count | changeNum }}</div>
                <div>{{ item.flow_value | changeNum }}</div>
                <div>{{ item.mount_rate | numRate }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-div-right">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(regionData)"
            :id="'bar-chart-4'"
          />
        </div>
      </div>

      <div class="empty-box" v-else>
        <Empty title="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import chartBar3 from "../../options/chartBar3.js";
import deepClone from "@/utils/clone-utils";
import api from "@/api/componentsMarket.js";
import Empty from "@/components/Empty/Empty";
export default {
  name: "TabsContent4",
  components: { MyChart, Empty },
  data() {
    return {
      chartShow: true,
      regionData: [],
      currentLabel: "",
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getComponentRegion();
      this.currentLabel = this.$parent.area_id.label.trim();
    },
    getBarOption(data) {
      if (data && data.length > 0) {
        let xData = data.map((item) => {
          return item.area_name || "暂无大区";
        });
        let yData = data.map((item) => {
          return item.mount_rate * 100;
        });
        let option = deepClone(chartBar3);
        option.yAxis[0].data = xData.reverse();
        option.series[0].data = yData.reverse();
        return option;
      } else {
        let option = deepClone(chartBar3);
        return option;
      }
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getComponentRegion() {
      let data = this.getParentsParams();
      api.getComponentRegion(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          this.regionData = data || [];
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content4 {
  width: 100%;
  height: 448px;
  &-main {
    width: 100%;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      > .title {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .chart-div {
      width: 100%;
      height: 386px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      &-left {
        width: 55%;
        height: 100%;
        .table-container {
          width: 100%;
          padding: 0 30px 0 20px;
          &-header {
            width: 100%;
            height: 50px;
            background: #242832;
            box-shadow: inset 0px -1px 0px 0px #3c4252;
            display: flex;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 50px;
            > div {
              flex: 1;
              text-align: right;
              padding: 0 20px;
              &:first-child {
                text-align: left;
              }
            }
          }
          &-body {
            width: 100%;
            height: 310px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            .table-item {
              width: 100%;
              height: 50px;
              background: rgba(255, 255, 255, 0.01);
              box-shadow: inset 0px -1px 0px 0px #3c4252;
              display: flex;
              font-size: 14px;
              color: rgba(255, 255, 255, 0.85);
              line-height: 50px;
              > div {
                flex: 1;
                text-align: right;
                padding: 0 20px;
                &:first-child {
                  text-align: left;
                }
              }
              &.active {
                background: #3b3835;
                box-shadow: inset 0px -1px 0px 0px #363a46,
                  inset 2px 0px 0px 0px #ffcc55;
              }
            }
          }
        }
      }
      &-right {
        width: 45%;
        height: 100%;
      }
    }

    .empty-box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
