<template>
  <PanleWrap>
    <div class="list_header">
      <platform-header
        title="品牌总榜"
        :currentTab="currentTab"
        @tabChange="tabChange"
        :tooltipName="'数据说明'"
        :showTooltip="true"
        :code="'pzqt01'"
        :isAll="true"
      >
      </platform-header>
      <div class="tools">
        <ButtonGroup :tabData="buttonData" @tabChange="buttonClick" />
        <!-- <SelectButton :buttonData="buttonData" @buttonClick="buttonClick" /> -->
        <RouterButton :title="'本品排名变化'" :path="'/brand/myRanking'" />
      </div>
      <div class="tools">
        <div class="btn-box">
          <a-select
            v-model="tagValue"
            style="width: 125px"
            :suffixIcon="suffixIcon"
            @change="getList"
          >
            <a-select-option value="">全部品牌</a-select-option>
            <a-select-option
              :value="item.tag_name"
              v-for="(item, index) in tagList"
              :key="index"
              >{{ item.tag_name }}</a-select-option
            >
          </a-select>
          <span style="margin: 0 10px 0 16px">排名：</span>
          <a-select
            v-model="sortValue"
            :suffixIcon="suffixIcon"
            style="width: 125px"
            @change="hadnleOrderClick"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in orderList"
              :key="index"
              >{{ item.text }}</a-select-option
            >
          </a-select>
          <!-- <Order
            :order-list="orderList"
            :order-index="orderIndex"
            :titleText="'排名:'"
            @order-click="hadnleOrderClick"
          /> -->
        </div>
        <div class="btn-box">
          <div class="my-ranking" @click="scrollMyRankig">
            我的排名：<span>{{
              my_ranking.ranking ? my_ranking.ranking : "-"
            }}</span>
          </div>
          <a-month-picker
            v-model="searchTime"
            class="aft-month-picker default"
            placeholder="Select month"
            :disabled-date="disabledDate"
            @change="onChange"
            locale="zh-cn"
          >
            <div slot="suffixIcon">
              <svg-icon :icon-class="'range-picker'" />
            </div>
          </a-month-picker>

          <ExcelTypeButton
            :type="excelType"
            @changeExcelType="changeExcelType"
            style="margin-left: 10px"
          />
          <a-button v-hide-by-username class="button2" @click="exportExcel">
            <svg-icon icon-class="export" class="btn-icon" />
            <span>Excel导出</span>
          </a-button>
        </div>
      </div>
    </div>
    <a-spin :spinning="mixin_isSpinning">
      <div class="list-content">
        <template v-if="dataList.length > 0">
          <div
            class="item live-item"
            v-for="item in dataList"
            :key="item.principal_id"
            :ref="`item_${item.principal_id}`"
            @click="cardClick(item)"
          >
            <!-- <div class="hover-box">多个平台数据总和</div> -->
            <div class="ranking-wrap">
              <div
                :class="
                  item.ranking === 1
                    ? 'num first'
                    : item.ranking === 2
                    ? 'num second'
                    : item.ranking === 3
                    ? 'num third'
                    : item.principal_id === my_ranking.principal_id
                    ? 'num other'
                    : 'num'
                "
              >
                <span v-if="my_ranking.principal_id === item.principal_id"
                  >我的排名：</span
                >
                {{ item.ranking || "-" }}
              </div>
            </div>

            <div class="info-wrap">
              <div class="left">
                <span class="avatar">
                  <!-- <img :src="`${OSS_HOST}${item.avatar}`" alt="" /> -->
                  <WebpImage
                    :src="`${OSS_HOST}${item.avatar}`"
                    :width="'60px'"
                    :height="'60px'"
                    :radius="'6px'"
                    style="margin-right: 6px"
                  />
                </span>
                <h3 class="principal-name">
                  {{ item.principal_name || "暂无名称" }}
                </h3>
              </div>
              <div class="right">
                <div>
                  <span style="margin-right: 12px">{{
                    getItemByValue(sortValue).text
                  }}</span>
                  <span>{{
                    item[getItemByValue(sortValue).field] | changeNum
                  }}</span>
                </div>
                <div class="count-box">
                  <div class="count-item">
                    <span>互动量</span>
                    <span
                      :class="sortValue == '1' ? 'number highlight' : 'number'"
                      >{{ item.polling_count | changeNum }}</span
                    >
                  </div>
                  <div class="count-item">
                    <span>视频发布</span>
                    <span
                      :class="sortValue == '2' ? 'number highlight' : 'number'"
                      >{{ item.polling_aweme_count | changeNum }}</span
                    >
                  </div>
                  <div class="count-item">
                    <span>视频播放</span>
                    <span class="number">{{
                      item.aweme_play_volume_total | changeNum
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>相关账号</span>
                    <span class="number">{{
                      item.polling_author_count | changeNum
                    }}</span>
                  </div>
                </div>
                <div class="count-box">
                  <div class="count-item">
                    <span>直播场次</span>
                    <span
                      :class="sortValue == '3' ? 'number highlight' : 'number'"
                      >{{ item.polling_live_count | changeNum }}</span
                    >
                  </div>
                  <div class="count-item">
                    <span>粉丝总量</span>
                    <span
                      :class="sortValue == '4' ? 'number highlight' : 'number'"
                      >{{ item.polling_fans_count | changeNum }}</span
                    >
                  </div>
                  <div class="count-item">
                    <span>营销价值</span>
                    <span
                      :class="sortValue == '5' ? 'number highlight' : 'number'"
                      >¥{{ item.marketing_value | changeNum }}</span
                    >
                  </div>
                  <!-- 金琳让屏蔽，1-11 -->
                  <!-- <div class="count-item">
                    <span>有效直播客流</span>
                    <span
                      :class="sortValue == '6' ? 'number highlight' : 'number'"
                      >{{ item.salves_value | changeNum }}</span
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <template
        v-if="
          mixin_isBizError || mixin_isNetworldError || dataList.length === 0
        "
      >
        <div
          class="font-color3"
          style="text-align: center; width: 100%; padding-top: 20px"
        >
          暂无数据
        </div>
      </template>
    </a-spin>
  </PanleWrap>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/brand.js";
import _ from "lodash";
import moment from "moment";
import mixin from "./mixins/mixin";
import Order from "./components/Order";
import Platform from "./components/Platform";
import SelectButton from "@/components/SelectButton/SelectButton";
import WebpImage from "@/components/WebpImage/WebpImage";
import exportTable from "@/utils/exportTable";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import RouterButton from "@/components/RouterButton/RouterButton";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";

const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;

//直播条件筛选
const liveOrderList = [
  {
    text: "互动量",
    value: "1",
    field: "polling_count",
  },
  {
    text: "直播互动",
    value: "7",
    field: "live_interactive_count",
  },
  {
    text: "视频互动",
    value: "8",
    field: "aweme_interactive_count",
  },
  {
    text: "总发布量",
    value: "10",
    field: "sum_aweme_live_count",
  },
  {
    text: "直播场次",
    value: "3",
    field: "polling_live_count",
  },
  {
    text: "视频发布",
    value: "2",
    field: "polling_aweme_count",
  },
  {
    text: "视频播放",
    value: "9",
    field: "aweme_play_volume_total",
  },
  {
    text: "直播观看",
    value: "13",
    field: "live_play_count",
  },
  {
    text: "粉丝总量",
    value: "4",
    field: "polling_fans_count",
  },
  {
    text: "营销价值",
    value: "5",
    field: "marketing_value",
  },
  // {
  //   text: "有效直播客流",
  //   value: "6",
  //   field: "salves_value",
  // },
];

export default {
  name: "GeneralList",
  components: {
    PlatformHeader,
    PanleWrap,
    Order,
    Platform,
    SelectButton,
    WebpImage,
    ExcelTypeButton,
    RouterButton,
    ButtonGroup,
  },
  mixins: [mixin],
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatPublishTime(str) {
      if (!str) {
        return "暂无时间";
      } else {
        return moment(str).format("YYYY/MM/DD");
      }
    },
  },
  data() {
    return {
      excelType: false,
      currentTab: "",
      buttonData: [
        {
          label: "全部",
          value: "1",
          iconName: "all",
        },
        {
          label: "官号",
          value: "2",
          iconName: "official",
        },
        {
          label: "经销商",
          value: "3",
          iconName: "dealer",
        },
      ],
      currentButtonClick: "1",
      searchTime: moment().subtract(1, "months"),
      disabledDate(current) {
        // Can not select days before today and today
        // return current && current >= moment().add(1, 'months').startOf("day");
        return (
          current >=
          moment()
            .startOf("month")
            .subtract("month", -1)
        );
      },
      startTime: "",
      endTime: "",
      sortValue: "1",
      orderIndex: 0,
      orderList: liveOrderList,
      dataList: [],
      tagList: [],
      tagValue: "",
      OSS_HOST,
      pagination: {
        size: 8,
        current: 1,
        total: 0,
      },
      my_ranking: {},
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
    };
  },
  async created() {
    api.getPrincipalTags().then((res) => {
      this.tagList = res.data;
      return res;
    });
    let timeObj = this.calcStartEndTime(moment().subtract(1, "months"));
    this.startTime = timeObj.startTime;
    this.endTime = timeObj.endTime;

    this.getList = this.requestDecorator(() => {
      const body = {
        platform: this.currentTab,
        params: {
          data_type: this.currentButtonClick,
          start_time: this.startTime,
          end_time: this.endTime,
          sort: this.sortValue,
          tag_ps: this.tagValue,
        },
      };
      return api.getGeneralList(body).then((res) => {
        this.dataList = _.get(res, "data.data.list", []);
        this.my_ranking = _.get(res, "data.data.self", {});
        setTimeout(() => {
          this.scrollMyRankig();
        }, 200);
        return res;
      });
    });

    // await this.getList();
  },
  methods: {
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      // this.sortValue = "1";
      this.getList();
    },
    getList() {},
    getItemByValue(sortValue) {
      let newItem = this.orderList.filter((item) => {
        return item.value == sortValue;
      });
      return newItem[0];
    },
    // 全部，官号，经销商 点击
    buttonClick(value) {
      this.currentButtonClick = value;
      this.getList();
    },
    calcStartEndTime(date) {
      let start = date.startOf("month").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("month").format("YYYY-MM-DD HH:mm:ss");
      return {
        startTime: start,
        endTime: end,
      };
    },
    hadnleOrderClick(res) {
      // console.log(res);
      // this.sortValue = res.data.value;
      this.getList();
    },
    cardClick(item) {
      console.log(item);
      if (this.my_ranking.principal_id === item.principal_id) {
        this.$router.push({
          path: "/matrix/brandSummary",
        });
      } else {
        if (item.dy_author_id || item.ks_author_id || item.dcd_author_id) {
          this.$router.push({
            path: "/matrix/brandSummary",
            query: {
              principal_id: item.principal_id,
              dy_author_id: item.dy_author_id,
              ks_author_id: item.ks_author_id,
              dcd_author_id: item.dcd_author_id,
            },
          });
        } else {
          this.$message.warning("当前品牌未绑定抖音号和快手号");
        }
      }
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      let obj = this.calcStartEndTime(date);
      this.startTime = obj.startTime;
      this.endTime = obj.endTime;
      this.getList();
    },
    scrollMyRankig() {
      if (this.my_ranking.principal_id) {
        let dom = this.$refs[`item_${this.my_ranking.principal_id}`][0];
        dom.scrollIntoView({
          //滚动到指定节点
          block: "start",
          behavior: "smooth",
        });
      }
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 导出Excel
    exportExcel() {
      let params = {
        data_type: this.currentButtonClick,
        start_time: this.startTime,
        end_time: this.endTime,
        sort: this.sortValue,
        tag_ps: this.tagValue,
        flag: this.excelType ? 1 : 0,
      };
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/new/brand/business-influence/export",
        "filename"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

img {
  max-width: 100%;
}

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.list_header {
  margin-bottom: 20px;
  > h3 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    @include font_color("font_color2");
    margin: 0;
  }
}
.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 20px;
}

.btn-box {
  display: flex;
  align-items: center;
  .button2 {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 113px;
    height: 40px;
    border-radius: 8px;
    line-height: 40px;
    font-size: 14px;
    border: none;
    @include font_color("font_color1");
    @include background_color("background_color63");
    .btn-icon {
      font-size: 18px;
      margin-right: 7px;
      @include font_color("font_color34");
    }
    &:hover .btn-icon {
      @include font_color("font_color35");
    }
  }
}

.my-ranking {
  padding: 0 30px 0 16px;
  margin-right: 10px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  cursor: pointer;
  @include background_color("background_color51");
  span {
    padding-left: 6px;
    @include font_color("font_color4");
  }
}

.list-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-height: calc(100vh - 274px);
  overflow-y: auto;
  padding: 0 14px 50px 20px;
  .item {
    position: relative;
    padding: 25px 0;
    line-height: 1.5;
    border-radius: 16px;
    cursor: pointer;
    overflow: hidden;
    @include background_color("background_color2");
    height: 164px;
    .hover-box {
      position: absolute;
      right: 6px;
      bottom: 10px;
      @include font_color("font_color1");
      display: none;
    }
  }
  .item:hover {
    @include background_color("background_color5");
    .hover-box {
      display: inline-block;
    }
  }
  .live-item {
  }
  .video-item {
  }
  .ranking-wrap {
    position: absolute;
    top: 0;
    right: 0;
    .num {
      padding: 0 12px;
      min-width: 32px;
      height: 26px;
      box-sizing: border-box;
      line-height: 26px;
      font-size: 12px;
      border-radius: 0px 16px 0px 16px;
      text-align: center;
      @include font_color("font_color22");
      @include background_color("background_color51");

      &.first {
        @include font_color("font_color17");
        @include background_color("background_color15");
      }
      &.second {
        @include font_color("font_color17");
        @include background_color("background_color18");
      }
      &.third {
        @include font_color("font_color17");
        @include background_color("background_color49");
      }
      &.other {
        @include font_color("font_color1");
      }
    }
  }
  .info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 17px;
    margin-bottom: 17px;
    .title {
      margin-bottom: 15px;
      font-size: 14px;
      .number {
        font-weight: bold;
        @include font_color("font_color4");
      }
    }
    .count-item {
      .number {
        font-size: 14px;
      }
    }
  }
  .detail-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    .title {
      font-size: 14px;
      @include font_color("font_color1");
    }
    .count-box {
      margin-top: 17px;
    }
  }
  .left {
    padding: 0 2%;
    font-size: 0;
    width: 28%;
    text-align: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 72%;
    height: 91px;
    > div:first-child {
      font-size: 14px;
      span {
        @include font_color("font_color20");
      }
      span:last-child {
        @include font_color("font_color26");
        font-weight: bold;
      }
    }
  }
  .principal-name {
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    @include font_color("font_color1");
  }
  .avatar {
    width: 60px;
    height: 60px;
    display: inline-block;
    border-radius: 6px;
    overflow: hidden;
  }
  .cover {
    display: inline-block;
    border-radius: 6px;
    overflow: hidden;
    width: 80px;
    height: 105px;
    background-size: cover;
    background-position: center;
    @include background_color("background_color1");
  }
  .count-box {
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
    margin-top: 10px;
    .count-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 25%;
      font-size: 12px;
      @include font_color("font_color3");
      .number {
        font-weight: bold;
        @include font_color("font_color1");
        &.highlight {
          // @include font_color("font_color4");
        }
      }
    }
  }
  .name {
    margin-top: 4px;
    span {
      @include font_color("font_color22");
      font-size: 12px;
    }
  }
}

@media (max-width: 1600px) {
  .list-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
