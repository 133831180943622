
import filters from '@/filters'

var option = {
  grid: {
    left: "25",
    right: "25",
    bottom: "0",
    top: "5%",
    containLabel: true,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        width: 2,
        color: 'rgba(255, 202, 92, .2)'
      }
    },
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
      color: '#fff',
    },
    padding: [5, 10],
    extraCssText: 'box-shadow: 0 0 5px rgba(0,0,0,0.3)',
    axisPointer: {
      type: 'none'
    },
    backgroundColor: 'none',
    borderWidth: '0',
    padding: 0,
    formatter: function (params, ticket, callback) {
      let _params = params[0]
      return `
            <div style="background: #3C4252;border-radius: 8px;box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);color: #fff; line-height: 1.2em;min-width: 96px; box-sizing: border-box;">
                <div style="padding: 8px 25px;font-size: 10px;position: relative;">
                    <div style="position: absolute;top: 8px;left: 5px;font-size:36px;color:${_params.color};">·</div>${_params.axisValue}
                </div>
                <div style="padding: 10px 25px;font-size: 13px;font-weight: bold;border-top: 1px solid rgba(255, 255, 255, .1);">
                    ${Number(_params.data)}
                </div>
            </div>
        `
    }
  },
  xAxis: {
    show: true,
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: '#3C4252',
      },
    },
    splitArea: {
      // show: true,
      color: '#828282',
      lineStyle: {
        color: '#828282'
      },
    },
    axisLabel: {
      color: '#828282'
    },
    splitLine: {
      show: false
    },
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value',
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)'
      }
    },
    axisLine: {
      show: false
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#828282',
      },
    },
  },
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130],
      type: 'bar',
      barWidth : '18px',
      itemStyle: {
        normal: {
          color: 'rgba(232, 90, 52, 1)',
          opacity: 0.1
        }
      },


      emphasis: {

        itemStyle: {
          color: 'rgba(232, 90, 52, 1)',
          opacity: 1
        },
        label: {
          // 柱图头部显示值
          show: true,
          position: "top",
          color: "#fff",
          fontSize: "12px",
          formatter: (params) => {
            return params.value[params.encode.x[0]];
          },
          ellipsis: {

          }
        },
      }
    }
  ]
}

export default option