import _ from 'lodash'
export default {
  data() {
    return {
      mixin_isSpinning: false,
      mixin_isBizError: false,
      mixin_isNetworldError: false
    }
  },
  methods: {
    setMixinState(key, state) {
      this[key] = state
    },
    requestDecorator(func) {
      if (typeof func === 'function') {
        return (...ag) => {
          this.mixin_isSpinning = true
          this.mixin_isBizError = false
          this.mixin_isNetworldError = false
          return func(...ag)
            .then((res) => {
              if(res) {
                let code = _.get(res, "data.code", -1);
                if(code !== 0) {
                  this.mixin_isBizError = true
                  this.$message.error('网络出错，请刷新重试')
                  return Promise.reject(res)
                } else {
                  // pass
                }
              } else {
                console.error("[aft-jjt] 使用requestDecorator mixin时，请在成功回调 return 异步结果")
              }
              return res
            })
            .catch(err => {
              this.mixin_isNetworldError = true
              return Promise.reject(err)
            })
            .finally(() => {
              this.mixin_isSpinning = false
            })
        }
      } else {
        throw new Error("requestDecorator 传入的参数不是函数")
      }

    }
  }

}