var option = {
  grid: {
    left: "0",
    top: "50",
    right: "0",
    bottom: "20",
    containLabel: true,
  },
  legend: {
    textStyle: {
      color: "#fff",
    },
    padding: [10, 0],
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
    },
    backgroundColor: "rgba(37, 50, 85, 0.9)",
    textStyle: {
      color: "#fff",
    },
  },
  xAxis: {
    type: "category",
    boundaryGap: true,
    axisLabel: {
      show: true,
      textStyle: {
        color: "#FFFFFF8C",
        fontSize: 12,
      },
      interval: 0,
    },
  },
  yAxis: {
    axisLabel: {
      show: true,
      textStyle: {
        color: "#FFFFFF8C",
        fontSize: 12,
      },
      interval: 0,
    },
    splitLine: {
      lineStyle: {
        color: ["#323744"],
      },
    },
  },
  dataset: {
    dimensions: [],
    source: [],
  },
  series: [],
};

export default option;
