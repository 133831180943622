import echarts from 'echarts'
import filters from '@/filters'
var option = {
  backgroundColor: "rgba(0,0,0,0)",
  tooltip: {
    trigger: 'item',
    show: true,
    backgroundColor: '#3C4252',
    textStyle: {
      color: '#fff'
    },
    padding: [10, 10],
    axisPointer: { // 坐标轴指示器，坐标轴触发有效
      type: 'none' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function(params) {
      console.log(params)
      return `
        <div>
          <div>${params.seriesName}</div>
          <div>${params.name}: ${filters.numRate2(params.value)}</div>
        </div>
      `
    }
  },
  legend: {
    show: false
  },
  grid: {
    left: '30',
    right: '30',
    bottom: '20',
    top: 50,
    containLabel: true
  },
  xAxis: [{
    type: 'category',
    axisLine: {
      show: true, //隐藏x轴
      lineStyle: {
        color: ["#6E7079"],
      },
    },
    axisTick: {
      show: false,  //刻度线
    },
    axisLabel: {
      show: true,  //刻度线
      interval: 0,
      color: 'rgba(255,255,255,0.65)',
      rotate: -45,
      padding: [20, 0, 0, 0]
    },
    splitLine: {
      show: false,
    },
    data: [],
  }],
  yAxis: [{
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#333335"],
      },
    },
    axisLine: { //y轴
      show: false,
      lineStyle: {
        color: ["#333335"],
      },
    },
    type: 'value',
    axisTick: {
      show: true
    },
    axisLabel: {
      show: true,
      formatter: function(params) {
        console.log(params)
        return filters.numRate2(params)
      },
      textStyle: {
        color: 'rgba(255,255,255,0.3)', // 坐标轴文字颜色
        fontSize: 10, // 坐标轴文字大小
        padding: [0, 0, 0, 0]
      },
    },
  }],
  series: [{
    name: '兴趣分布',
    type: 'bar',
    barMaxWidth: 26, // 柱子宽度
    itemStyle: {
      barBorderRadius: [13, 13, 0, 0], // 圆角（左上、右上、右下、左下）
      color: '#FFCA5C', // 渐变
    },
    data: []
  },]
}
export default option