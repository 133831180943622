<template>
  <div>
    <platform-header
      title="车系详情"
      ref="platformTab"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <!-- <div class="platform-div">
      <top-platform-tab
        ref="platformTab"
        :tabData="tabData"
        @tabChange="tabChange"
      ></top-platform-tab>
    </div>
    <div class="title">车系详情</div> -->
    <div class="tools">
      <a-month-picker
        v-model="searchTime"
        placeholder="Select month"
        class="aft-month-picker default"
        :disabled-date="disabledDate"
        @change="onChange"
        locale="zh-cn"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-month-picker>
    </div>
    <div class="main-container">
      <div class="my-card">
        <div style="width: 260px;" v-if="detailData">
          <img :src="detailData.series.image" width="220" alt="" />
          <span class="card-series-name">{{ detailData.series.name }}</span>
        </div>
        <div style="width: calc(100% - 260px)">
          <my-chart :options="lineOptions" id="seriesChart" />
        </div>
      </div>
      <a-tabs :default-active-key="tab">
        <a-tab-pane key="tab1" tab="车系详情" style="height: 1250px">
          <div class="tab1-item" v-if="detailData">
            <div class="tab1-item-left">
              <model-card :modelData="detailData.series"></model-card>
            </div>
            <div class="tab1-item-right">
              <div class="tab1-item-right-title">热门话题</div>
              <div
                class="topic-item"
                v-for="(item, index) in detailData.hotTopic"
                :key="index"
              >
                #{{ item }}
              </div>
            </div>
          </div>
          <div class="tab2-item">
            <div class="tab2-item-title">
              <svg-icon :icon-class="'title'" class="card-title-icon"></svg-icon
              >最热视频
            </div>
            <div
              class="tab2-item-main"
              v-if="videoData && videoData.list.length > 0"
            >
              <video-card
                v-for="(item, index) in videoData.list"
                :videoData="item"
                :platform="currentTab"
                :key="index"
              ></video-card>
            </div>
          </div>
          <div class="tab3-item">
            <div class="tab3-item-title">
              <svg-icon :icon-class="'title'" class="card-title-icon"></svg-icon
              >热门评论
            </div>
            <div class="tab3-item-main">
              <!-- <div class="progress" v-if="emotionData">
                <div
                  class="progress-title"
                  :style="{
                    color:
                      parseInt(emotionData.emotion) < 50
                        ? '#4a58f3'
                        : parseInt(emotionData.emotion) > 50
                        ? '#F67C52'
                        : '',
                  }"
                >
                  {{
                    getProgress(emotionData.emotion)
                      ? "情感偏负向"
                      : parseInt(emotionData.emotion) > 50
                      ? "情感偏正向"
                      : "情感相等"
                  }}
                </div>
                <div class="progress-main">
                  <span>正向情感</span>
                  <a-icon type="smile" style="color: #f67c52; margin: 0 15px; font-size: 22px" />
                  <div class="progress-main-div">
                    <div :style="{ width: emotionData.emotion }"></div>
                    <span
                      :style="{
                        left: 'calc(' + emotionData.emotion + ' - 16px)',
                        color: '#959595',
                      }"
                      >{{ emotionData.emotion }}</span
                    >
                  </div>
                  <a-icon type="frown" style="color: #4a58f3; margin: 0 15px; font-size: 22px" />
                  <span>负向情感</span>
                </div>
              </div> -->
              <div class="comment-th">
                <span style="width: 32%">用户名称</span>
                <span style="width: 48%">评论内容</span>
                <span style="width: 20%">互动</span>
              </div>
              <div class="comment-list">
                <div
                  v-infinite-scroll="loadMore"
                  infinite-scroll-disabled="listDisable"
                  infinite-scroll-distance="100"
                >
                  <div
                    v-for="(item, index) in commentList ? commentList.list : []"
                    :key="index"
                    class="caption"
                  >
                    <div class="comment-item">
                      <div style="width: 32%">
                        <img
                          :src="item.fans_avatar || defaultAvatar"
                          width="32"
                          height="32"
                          alt=""
                        />
                        <div>
                          <span class="comment-item-name">{{
                            item.fans_nickname
                          }}</span>
                          <br />
                          <span class="comment-item-time">{{
                            item.publish_time
                          }}</span>
                        </div>
                      </div>
                      <div style="width: 48%" class="list-content">
                        {{ item.content }}
                      </div>
                      <div style="width: 20%">
                        {{ item.digg_count | changeNum }}
                      </div>
                    </div>
                  </div>
                  <div class="loading" v-if="listLoading && !listNoMore">
                    加载中...
                  </div>
                  <div class="loading" v-if="listNoMore">没有更多了</div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>

        <!-- <a-tab-pane key="tab2" tab="声量趋势"> 暂无数据 </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import moment from "moment";
import MyChart from "@/components/MyChart/MyChart";
import ModelCard from "./components/ModelCard";
import VideoCard from "./components/VideoCard";
import chartLine from "./chartLine";
import deepClone from "@/utils/clone-utils";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "modelDetail"
);
export default {
  name: "modelDetail",
  components: { PlatformHeader, MyChart, ModelCard, VideoCard },
  data() {
    return {
      msg: "车系详情",
      defaultAvatar: require("@/assets/img/defult-avatar.png"),
      currentTab: "",
      searchTime: moment(),
      disabledDate(current) {
        // Can not select days before today and today
        return current && current >= moment().endOf("day");
      },
      tab: "tab1",
      lineOptions: null,
    };
  },
  computed: {
    ...mapState({
      detailData: (state) => state.detailData, // 获取车系详情、热门话题
      competitorData: (state) => state.competitorData, // 获取竞品趋势
      videoData: (state) => state.videoData, // 短视频
      emotionData: (state) => state.emotionData, // 情感比例
      commentList: (state) => state.commentList,
      current: (state) => state.current,
      page_size: (state) => state.page_size,
      listLoading: (state) => state.listLoading,
      listNoMore: (state) => state.listNoMore,
    }),
    listDisable() {
      return this.listLoading || this.listNoMore;
    },
  },
  created() {
    this.$nextTick(() => {
      if (this.$route.query.platform) {
        this.tabChange(this.$route.query.platform);
      }
      if (this.$route.query.date) {
        this.searchTime = moment(this.$route.query.date);
      }
    });
  },
  mounted() {
    if (this.$route.query.id) {
      console.log(this.$route.query.id);
      this.loadAll(this.$route.query.date);
    } else {
      this.$router.push("modelPerformance");
    }
  },
  watch: {
    competitorData(val) {
      if (val) {
        this.lineOptions = this.getLineOption();
      }
    },
  },
  methods: {
    ...mapActions([
      "getSeriesDetail",
      "getCompetitorChart",
      "getVideoList",
      "getEmotion",
      "getCommentList",
    ]),
    ...mapMutations(["changeState"]),
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.loadAll(this.$route.query.date);
    },
    // 加载全部
    loadAll(time) {
      let params = {
        series_id: this.$route.query.id,
        statistics_begin_date: moment(time || this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        statistics_end_date: moment(time || this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        platform: this.currentTab,
      };
      this.getSeriesDetail(params);
      this.getCompetitorChart(params);
      this.getVideoList(params);
      // this.getEmotion(params)
      this.loadMore(true);
    },
    getLineOption() {
      if (this.competitorData == null) {
        return false;
      }
      let colors = [
        "#63A5F3",
        "#27C77C",
        "#FBBB6C",
        "#E34E3A",
        "#4A58F3",
        "#20a0ff",
        "#fa3838",
      ];
      let option = deepClone(chartLine);
      option.legend.data = this.competitorData.nameArr;
      option.xAxis.data = this.competitorData.timeArr;
      this.competitorData.dataArr.forEach((item, index) => {
        option.series.push({
          type: "line",
          name: this.competitorData.nameArr[index],
          itemStyle: {
            normal: {
              color: colors[index],
            },
          },
          barWidth: 45,
          data: item,
        });
      });
      console.log(option);
      return option;
    },
    getProgress(num) {
      return parseInt(num) < 50 ? true : false;
    },
    // 日期切换
    onChange(date, dateString) {
      this.loadAll();
    },
    loadMore(isFirst) {
      console.log(9090, this.page_size);
      this.getCommentList({
        current: isFirst ? 1 : this.current,
        page_size: this.page_size || 10,
        series_id: this.$route.query.id,
        statistics_begin_date: moment(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        statistics_end_date: moment(this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        platform: this.currentTab,
      });
    },
  },
  beforeDestroy() {
    this.changeState({ data: null, name: "detailData" });
    this.changeState({ data: null, name: "competitorData" });
    this.changeState({ data: null, name: "videoData" });
    this.changeState({ data: null, name: "emotionData" });
    this.changeState({ data: null, name: "commentList" });
    this.changeState({ data: 1, name: "current" });
    this.changeState({ data: 10, name: "page_size" });
    this.changeState({ data: false, name: "listLoading" });
    this.changeState({ data: false, name: "listNoMore" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.card-title-icon {
  @include font_color("font_color5");
  font-size: 18px;
  margin: 0 8px;
}
.tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 24px;
}
.main-container {
  width: 100%;
  padding: 0 24px;
  .my-card {
    @include background_color("background_color5");
    height: 258px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #fff;
    margin-bottom: 24px;

    > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .card-series-name {
      @include font_color("font_color1");
    }
  }
  .tab1-item {
    color: #fff;
    display: flex;
    flex-direction: row;
    &-left {
      width: 385px;
      height: 100%;
      margin-right: 1%;
    }
    &-right {
      width: calc(100% - 385px);
      height: 200px;
      overflow-y: auto;
      margin-left: 40px;
      &-title {
        font-size: 14px;
        @include font_color("font_color2");
        margin-bottom: 4px;
      }
      .topic-item {
        font-size: 14px;
        line-height: 20px;
        @include font_color("font_color3");
      }
    }
  }
  .tab2-item {
    color: #fff;
    &-title {
      font-size: 16px;
      font-weight: bold;
      margin: 24px 0 16px;
      padding-top: 24px;
      border-top: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color1");
    }
    &-main {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      overflow-x: auto;
    }
  }
  .tab3-item {
    color: #fff;
    &-title {
      font-size: 16px;
      font-weight: bold;
      margin: 24px 0 16px;
      padding-top: 24px;
      border-top: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color1");
    }
    &-main {
      margin-bottom: 30px;
      .progress {
        height: 90px;
        &-title {
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          margin: 15px 0 12px;
        }

        &-main {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          span {
            font-size: 14px;
            color: #8f94a2;
          }

          &-div {
            width: 450px;
            height: 10px;
            background: #4a58f3;
            position: relative;

            div {
              height: 10px;
              position: absolute;
              top: 0;
              left: 0;
              background: #f67c52;
            }

            span {
              position: absolute;
              top: 20px;
              font-size: 12px;
              color: #17233d;
              font-weight: bold;
            }
          }
        }
      }
      .comment-th {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 36px;
        line-height: 36px;
        @include font_color("font_color2");
        @include background_color("background_color5");
        span {
          text-align: left;
        }
        span:last-child {
          text-align: left;
          padding-right: 16px;
        }
        span:first-child {
          padding-left: 16px;
        }
      }
      .comment-list {
        height: 350px;
        width: 100%;
        overflow-y: auto;

        .comment-item {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          height: 80px;
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          @include border_color("border_color1");
          @include font_color("font_color2");
          font-size: 14px;
          padding: 16px 0;
          &-name {
            @include font_color("font_color1");
          }
          &-time {
            @include font_color("font_color3");
            font-size: 12px;
          }
          .list-content {
            height: 40px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 16px;
            img {
              width: 48px;
              height: 48px;
              border-radius: 24px;
              margin-right: 12px;
            }
          }
          > div:last-child {
            text-align: left;
            padding-right: 16px;
          }
        }
        .loading {
          text-align: center;
          margin-top: 16px;
          @include font_color("font_color2");
        }
      }
    }
  }
}
</style>
