<template>
  <div>
    <platform-header
      title="品牌官号运营"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="month_box">
      <Order
        :order-list="tagList"
        :order-index="orderIndex"
        @order-click="hadnleOrderClick"
        :show-title="false"
      />
      <a-month-picker
        v-model="month"
        placeholder="选择月份"
        class="aft-month-picker default"
        @change="selectMonth"
        :disabled-date="disabledDate"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-month-picker>
    </div>
    <div class="main-container" v-if="officialList.self">
      <!-- 滚动卡片 -->
      <a-spin :spinning="cardLoading" style="background(255,255,255,0.5)">
        <div class="card-container">
          <!-- <div class="card-container-left">
            <div class="title">
              <img :src="ossHost + officialList.self.avatar" alt="" />
              <span>{{ officialList.self.principal_name || "-" }}</span>
            </div>
            <div class="info f14">
              <div class="flex2">
                <span class="mr9 label" v-if="currentTab == 'douyin'"
                  ><i>抖</i><i>音</i><i>号:</i></span
                >
                <span class="mr9 label" v-if="currentTab == 'kuaishou'"
                  ><i>快</i><i>手</i><i>号:</i></span
                >
                <span class="font-color2">{{
                  officialList.self.code || "-"
                }}</span>
              </div>
              <div class="flex2 mt12">
                <span class="mr9 label"><i>简</i><i>介:</i></span>
                <span class="font-color2 ellipsis2">{{
                  officialList.self.signature || "-"
                }}</span>
              </div>
            </div>
            <div class="line"></div>
            <div class="number f14">
              <div class="flex1" v-if="currentTab == 'douyin'">
                <span class="mr16 font-color3">视频点赞</span>
                <span class="font-color1 f20">{{
                  officialList.self.digg_count_di | changeNum
                }}</span>
              </div>
              <div class="flex1" v-if="currentTab == 'kuaishou'">
                <span class="mr16 font-color3">视频播放</span>
                <span class="font-color1 f20">{{
                  officialList.self.aweme_play_volume_total_di | changeNum
                }}</span>
              </div>
              <div class="flex1 mt12">
                <span class="mr16 font-color3">视频发布</span>
                <span class="font-color1 f20">{{
                  officialList.self.aweme_count_di | changeNum
                }}</span>
              </div>
              <div class="flex1 mt12">
                <span class="mr16 font-color3">直播观看</span>
                <span class="font-color1 f20">{{
                  officialList.self.live_total_watch_user_time | changeNum
                }}</span>
              </div>
              <div class="flex1 mt12">
                <span class="mr16 font-color3">直播场次</span>
                <span class="font-color1 f20">{{
                  officialList.self.live_total_count | changeNum
                }}</span>
              </div>
              <div class="flex1 mt12">
                <span class="mr16 label" style="flex-wrap: wrap"
                  ><i>已</i><i>运</i><i>营</i><i>时</i><i>长</i></span
                >
                <span class="font-color1 f20">{{
                  officialList.self.publish_time | formatPublishTime
                }}</span>
              </div>
              <div style="text-align: center">
                <a-button
                  class="detail-button"
                  @click="toDetail(officialList.self.author_id)"
                  >查看详情</a-button
                >
              </div>
            </div>
          </div> -->
          <div
            :class="
              officialList.self.author_id == currentList.author_id
                ? 'card-container-left active'
                : 'card-container-left'
            "
            @click="cardClick(officialList.self)"
          >
            <div class="list-title">
              <img
                :src="
                  officialList.self.avatar
                    ? ossHost + officialList.self.avatar
                    : require('../../assets/default/default1-1.png')
                "
                alt=""
              />
              <span>{{ officialList.self.principal_name }}</span>
            </div>
            <div class="list-item f14">
              <div>
                <span class="font-color3">粉丝总量</span>
                <span class="font-color2">{{
                  officialList.self.fans_count | changeNum
                }}</span>
              </div>
              <div>
                <span class="font-color3">月环比</span>
                <span class="font-color2"
                  ><svg-icon
                    v-if="officialList.self.fans_count_compare"
                    :icon-class="
                      officialList.self.fans_count_compare >= 0 ? 'up' : 'down'
                    "
                    class="mr6"
                  />{{
                    officialList.self.fans_count_compare | numRateAbs
                  }}</span
                >
              </div>
            </div>
            <div class="list-item f14">
              <div>
                <span class="font-color3">粉丝月增长</span>
                <span class="font-color2">{{
                  officialList.self.fans_count_total_di | changeNum
                }}</span>
              </div>
              <!-- <div>
                <span class="font-color3">月环比</span>
                <span class="font-color2"
                  ><svg-icon
                    v-if="officialList.self.fans_count_total_di_compare"
                    :icon-class="
                      officialList.self.fans_count_total_di_compare >= 0
                        ? 'up'
                        : 'down'
                    "
                    class="mr6"
                  />{{
                    officialList.self.fans_count_total_di_compare | numRateAbs
                  }}</span
                >
              </div> -->
            </div>
            <div class="list-item f14">
              <div>
                <span class="font-color3"
                  >月{{ currentTab | returnWatchText }}</span
                >
                <span class="font-color2">{{
                  officialList.self.live_total_watch_user_time | changeNum
                }}</span>
              </div>
              <div>
                <span class="font-color3">月环比</span>
                <span class="font-color2"
                  ><svg-icon
                    v-if="officialList.self.live_total_watch_user_time_compare"
                    :icon-class="
                      officialList.self.live_total_watch_user_time_compare >= 0
                        ? 'up'
                        : 'down'
                    "
                    class="mr6"
                  />{{
                    officialList.self.live_total_watch_user_time_compare
                      | numRateAbs
                  }}</span
                >
              </div>
            </div>
            <div class="list-item f14">
              <div>
                <span class="font-color3">直播月发布</span>
                <span class="font-color2">{{
                  officialList.self.live_total_count | changeNum
                }}</span>
              </div>
              <div>
                <span class="font-color3">月环比</span>
                <span class="font-color2"
                  ><svg-icon
                    v-if="officialList.self.live_total_count_compare"
                    :icon-class="
                      officialList.self.live_total_count_compare >= 0
                        ? 'up'
                        : 'down'
                    "
                    class="mr6"
                  />{{
                    officialList.self.live_total_count_compare | numRateAbs
                  }}</span
                >
              </div>
            </div>
            <div class="list-item f14">
              <div>
                <span class="font-color3">视频月发布</span>
                <span class="font-color2">{{
                  officialList.self.aweme_count_di | changeNum
                }}</span>
              </div>
              <div>
                <span class="font-color3">月环比</span>
                <span class="font-color2"
                  ><svg-icon
                    v-if="officialList.self.aweme_count_di_compare"
                    :icon-class="
                      officialList.self.aweme_count_di_compare >= 0
                        ? 'up'
                        : 'down'
                    "
                    class="mr6"
                  />{{
                    officialList.self.aweme_count_di_compare | numRateAbs
                  }}</span
                >
              </div>
            </div>
            <div class="list-item f14" style="border: none">
              <div style="width: 100%; max-width: 100%">
                <span class="font-color3">已运营时长</span>
                <span class="font-color2">{{
                  officialList.self.publish_time | formatPublishTime
                }}</span>
              </div>

              <a-button
                class="detail-button"
                @click="toDetail(officialList.self.author_id)"
                >查看详情</a-button
              >
            </div>
            <div class="tip">{{ monthFormat(month) }}累计数据</div>
          </div>
          <div class="card-container-right">
            <div class="right-all" :style="{ left: positionLeft + 'px' }">
              <div
                :class="
                  currentList.author_id == item.author_id
                    ? 'card-item active'
                    : 'card-item'
                "
                v-for="(item, index) in officialList.list || []"
                @click="cardClick(item)"
                :key="index"
              >
                <div class="list-title">
                  <img
                    :src="
                      item.avatar
                        ? ossHost + item.avatar
                        : require('../../assets/default/default1-1.png')
                    "
                    alt=""
                  />
                  <span>{{ item.principal_name }}</span>
                </div>
                <div class="list-item f14">
                  <div>
                    <span class="font-color3">粉丝总量</span>
                    <span class="font-color2">{{
                      item.fans_count | changeNum
                    }}</span>
                  </div>
                  <div>
                    <span class="font-color3">月环比</span>
                    <span class="font-color2"
                      ><svg-icon
                        v-if="item.fans_count_compare"
                        :icon-class="
                          item.fans_count_compare >= 0 ? 'up' : 'down'
                        "
                        class="mr6"
                      />{{ item.fans_count_compare | numRateAbs }}</span
                    >
                  </div>
                </div>
                <div class="list-item f14">
                  <div>
                    <span class="font-color3">粉丝月增长</span>
                    <span class="font-color2">{{
                      item.fans_count_total_di | changeNum
                    }}</span>
                  </div>
                  <!-- <div>
                    <span class="font-color3">月环比</span>
                    <span class="font-color2"
                      ><svg-icon
                        v-if="item.fans_count_total_di_compare"
                        :icon-class="
                          item.fans_count_total_di_compare >= 0 ? 'up' : 'down'
                        "
                        class="mr6"
                      />{{
                        item.fans_count_total_di_compare | numRateAbs
                      }}</span
                    >
                  </div> -->
                </div>
                <div class="list-item f14">
                  <div>
                    <span class="font-color3"
                      >月{{ currentTab | returnWatchText }}</span
                    >
                    <span class="font-color2">{{
                      item.live_total_watch_user_time | changeNum
                    }}</span>
                  </div>
                  <div>
                    <span class="font-color3">月环比</span>
                    <span class="font-color2"
                      ><svg-icon
                        v-if="item.live_total_watch_user_time_compare"
                        :icon-class="
                          item.live_total_watch_user_time_compare >= 0
                            ? 'up'
                            : 'down'
                        "
                        class="mr6"
                      />{{
                        item.live_total_watch_user_time_compare | numRateAbs
                      }}</span
                    >
                  </div>
                </div>
                <div class="list-item f14">
                  <div>
                    <span class="font-color3">直播月发布</span>
                    <span class="font-color2">{{
                      item.live_total_count | changeNum
                    }}</span>
                  </div>
                  <div>
                    <span class="font-color3">月环比</span>
                    <span class="font-color2"
                      ><svg-icon
                        v-if="item.live_total_count_compare"
                        :icon-class="
                          item.live_total_count_compare >= 0 ? 'up' : 'down'
                        "
                        class="mr6"
                      />{{ item.live_total_count_compare | numRateAbs }}</span
                    >
                  </div>
                </div>
                <div class="list-item f14">
                  <div>
                    <span class="font-color3">视频月发布</span>
                    <span class="font-color2">{{
                      item.aweme_count_di | changeNum
                    }}</span>
                  </div>
                  <div>
                    <span class="font-color3">月环比</span>
                    <span class="font-color2"
                      ><svg-icon
                        v-if="item.aweme_count_di_compare"
                        :icon-class="
                          item.aweme_count_di_compare >= 0 ? 'up' : 'down'
                        "
                        class="mr6"
                      />{{ item.aweme_count_di_compare | numRateAbs }}</span
                    >
                  </div>
                </div>
                <div class="list-item f14" style="border: none">
                  <div style="width: 100%; max-width: 100%">
                    <span class="font-color3">已运营时长</span>
                    <span class="font-color2">{{
                      item.publish_time | formatPublishTime
                    }}</span>
                  </div>

                  <a-button
                    class="detail-button"
                    @click="toDetail(item.author_id)"
                    >查看详情</a-button
                  >
                </div>
                <div class="tip">{{ monthFormat(month) }}累计数据</div>
              </div>
            </div>
          </div>

          <div class="left-btn" @click="scrollList('left')">
            <a-icon type="left" class="font-color2" />
          </div>
          <div class="right-btn" @click="scrollList('right')">
            <a-icon type="right" class="font-color2" />
          </div>
        </div>
      </a-spin>
      <!-- line chart -->
      <div class="chart-container">
        <div class="card-title">
          <div>
            <svg-icon icon-class="title" class="svg-title" />
            <span>发布数据</span>
          </div>
        </div>
        <div class="button-group">
          <button-tab
            :tabData="buttonTabData"
            @tabChange="buttonTabChange"
          ></button-tab>
        </div>
        <div class="chart-div" style="height: 250px">
          <my-chart
            :options="
              getLineOption(releaseData.xData, releaseData[currentButtonTab])
            "
            :id="'line-chart'"
          ></my-chart>
        </div>
      </div>
      <!-- 直播日历 -->
      <div class="calendar_table">
        <live-calendar
          :calendar-data="calendarData"
          :month="month"
          :platform="currentTab"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import MyChart from "@/components/MyChart/MyChart";
import chartLine from "./options/chartLine";
import deepClone from "@/utils/clone-utils";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ButtonTab from "@/components/PlatformTab/ButtonTab";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "benchmarking"
);
import LiveCalendar from "@/components/LiveCalendar/index.vue";
import Order from "./components/Order";
export default {
  components: {
    LiveCalendar,
    PlatformHeader,
    ButtonTab,
    MyChart,
    Order,
  },
  filters: {
    formatPublishTime(time) {
      if (!time) {
        return "0个月0天";
      } else {
        let date = new Date(time.replace(/-/g, "/")).getTime();
        let thisDate = new Date().getTime();
        let duration = (thisDate - date) / 1000;

        //计算出相差年数
        var years = Math.floor(duration / (24 * 3600 * 365));

        //计算出相差月数
        var leave0 = duration % (24 * 3600 * 365);
        var months = Math.floor(leave0 / (24 * 3600 * 30));

        //计算出相差天数
        var leave1 = duration % (24 * 3600 * 30);
        var days = Math.floor(leave1 / (24 * 3600));
        if (years) {
          return `${years}年${months}个月`;
        } else {
          return `${months}个月${days}天`;
        }
      }
    },
  },
  data() {
    return {
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      disabledDate(current) {
        // Can not select days before today and today
        // return current && current >= moment().add(1, 'months').startOf("day");
        return (
          current >=
          moment()
            .startOf("month")
            .subtract("month", -1)
        );
      },
      currentTab: "",
      sortValue: "",
      orderIndex: 0,
      month: moment().subtract(1, "months"),
      authorId: this.$route.query.author_id,
      type: this.$route.query.type,
      positionLeft: 0,
      buttonTabData: [
        {
          label: "粉丝增量",
          value: "fans_count_total_di",
        },
        {
          label: "爆款直播",
          value: "live_hot",
        },
        {
          label: "爆款视频",
          value: "aweme_hot",
        },
        {
          label: "直播场次",
          value: "live_total_count",
        },
        {
          label: "短视频发布",
          value: "aweme_count_di",
        },
        {
          label: "直播场均观看",
          value: "live_avg_total_watch_user_time",
        },
        {
          label: "直播场均互动",
          value: "live_avg_interactive_count",
        },
      ],
      currentButtonTab: "fans_count_total_di",
      currentList: {},
    };
  },
  watch: {
    officialList(val) {
      if (val == null) {
        this.$message.error("请求失败！请联系管理员查看数据");
        return false;
      }
      if (val.list.length > 0) {
        if (val.self) {
          this.currentList = val.self;
        } else {
          this.currentList = {};
          this.$message.warning(
            `当前主体未绑定${this.currentTab == "douyin" ? "抖音" : "快手"}官号`
          );
        }
        this.initData(this.month);
      }
    },
  },
  computed: {
    ...mapState({
      officialList: (state) => _.get(state, "officialList", {}),
      cardLoading: (state) => state.cardLoading,
      releaseData: (state) => _.get(state, "releaseData", []),
      authorMessage: (state) => _.get(state, "authorMessage", {}),
      liveMessage: (state) => _.get(state, "liveMessage", {}),
      topThirtyList: (state) => _.get(state, "topThirtyList", []),
      calendarData: (state) => _.get(state, "calendarData", {}),
      tagList: (state) => _.get(state, "tagList", []),
    }),
  },
  created() {
    // this.getList();

    this.getPrincipalTags();
  },
  mounted() {
    // this.getAuthorMessage({ id: this.currentList.author_id, type: this.currentTab });
    // this.initData();
  },
  methods: {
    ...mapActions([
      "getOfficialList",
      "getReleaseData",
      "getAuthorMessage",
      "getLiveMessage",
      "getTopThirtyLive",
      "getCalendarLive",
      "getPrincipalTags",
    ]),
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.getList();
    },

    hadnleOrderClick(res) {
      console.log(res);
      this.sortValue = res.data.value;
      this.getList();
    },
    getList() {
      this.positionLeft = 0;
      this.getOfficialList({
        start_time: moment(this.month)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(this.month)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        platform: this.currentTab,
        tag_ps: this.sortValue,
      });
    },
    monthFormat(time) {
      return moment(this.month).format("M") + "月";
    },
    scrollList(type) {
      let itemDom = document.getElementsByClassName("card-item")[0];
      console.log(itemDom.style);
      if (type == "left") {
        if (Math.abs(this.positionLeft) < (itemDom.offsetWidth + 12) * 4) {
          this.positionLeft = 0;
          return false;
        }
        this.positionLeft += (itemDom.offsetWidth + 12) * 4;
      } else {
        if (
          Math.abs(this.positionLeft) >
          (itemDom.offsetWidth + 12) * (this.officialList.list.length - 4) - 20
        ) {
          return false;
        }
        this.positionLeft -= (itemDom.offsetWidth + 12) * 4;
      }
    },
    // 顶部卡片点击
    cardClick(item) {
      this.currentList = item;
      // 点击后调用接口
      this.initData(this.month);
    },
    // 点击查看详情跳转
    toDetail(id) {
      this.$router.push({
        path: "/dealer/production",
        query: {
          type: this.currentTab,
          author_id: id,
        },
      });
    },
    // 初始化
    initData(date) {
      let time = date ? date : this.month;
      let year = this.$moment(time).format("YYYY");
      let month = this.$moment(time).format("M");
      let time1 = {
        author_id: this.currentList ? this.currentList.author_id : "",
        year,
        month,
      };
      let range = this.disposeTimeRange(time);
      let time2 = {
        author_id: this.currentList ? this.currentList.author_id : "",
        start_time: range.start_time,
        end_time: range.end_time,
      };
      this.getReleaseData({
        author_id: this.currentList ? this.currentList.author_id : "",
        year: year,
        platform: this.currentTab,
      });
      this.getLiveMessage({ type: this.currentTab, params: time1 });
      this.getTopThirtyLive({ type: this.currentTab, params: time2 });
      this.getCalendarLive({ type: this.currentTab, params: time2 });
    },
    // 时间范围数据处理
    disposeTimeRange(value) {
      let start_time = "";
      let end_time = "";
      if (!!value) {
        start_time = `${this.$moment(value)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(value)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        start_time = moment()
          .month(moment().month() - 1)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        end_time = moment()
          .month(moment().month() - 1)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        this.month = this.$moment()
          .month(moment().month() - 1)
          .startOf("month");
      }
      return { start_time, end_time };
    },
    // 时间变更
    selectMonth(value) {
      if (!value) {
        this.month = moment().subtract(1, "months");
        this.$message.warning("默认选择上月数据");
      }

      this.getList();

      //   this.$emit("changeMonth", this.month);
    },

    buttonTabChange(val) {
      this.currentButtonTab = val;
    },
    // 折线图 set option
    getLineOption(xData, yData) {
      let option = deepClone(chartLine);
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
* {
  margin: 0;
  padding: 0;
}
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}

.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
img {
  height: 100%;
  width: 100%;
}
.month_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 24px;
  margin-bottom: 24px;
}
.main-container {
  width: 100%;
  height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: hidden;
}
.card-container {
  padding: 0 24px;
  // height: 450px;
  height: 474px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  .left-btn {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    @include background_color("background_color36");
    opacity: 0.9;
    position: absolute;
    left: 270px;
    top: 206px;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
  }
  .right-btn {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    @include background_color("background_color36");
    opacity: 0.9;
    position: absolute;
    right: 12px;
    top: 206px;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
  }

  .detail-button {
    width: 160px;
    height: 28px;
    margin-top: 24px;
    @include font_color("font_color2");
    border: 1px solid rgba(0, 0, 0, 0);
    @include border_color("border_color7");
    @include background_color("background_color2");
    margin-right: 8px;
    transition: all 0.5s;
  }
  .detail-button:hover {
    @include background_color("background_color34");
    @include font_color("font_color1");
    @include border_color("border_color11");
  }
  &-left:hover,
  &-left.active {
    @include background_color("background_color36");
  }
  &-left {
    width: 236px;
    height: 100%;
    // padding: 24px;
    cursor: pointer;
    border-radius: 4px;
    @include background_color("background_color2");
    transition: all 0.5s;

    .list-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      font-size: 14px;
      @include font_color("font_color1");
      height: 52px;
      @include border_color("border_color5");
      > img {
        width: 35px;
        height: 35px;
        border-radius: 8px;
        margin-right: 10px;
      }
    }
    .list-item {
      width: 100%;
      min-width: 100%;
      padding: 0 0 0 13%;
      height: 66px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
      > span {
        line-height: 87px;
      }
      > div {
        display: inline-block;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      > div:first-child {
        min-width: 55%;
        width: 55%;
      }
      > div:last-child {
        min-width: 44%;
        width: 44%;
      }
    }
    .tip {
      @include font_color("font_color26");
      position: relative;
      left: 30px;
      bottom: 6px;
      display: none;
    }
    &.active {
      .tip {
        display: inline-block;
      }
    }
    .info {
      margin-top: 24px;
    }
    .line {
      width: calc(100% + 48px);
      height: 1px;
      @include background_color("background_color5");
      position: relative;
      left: -24px;
      margin: 8px 0 10px 0;
    }
    .mr9 {
      margin-right: 9px;
    }
    .mr16 {
      margin-right: 16px;
    }
    .mt12 {
      margin-top: 12px;
    }
    .flex1 {
      display: flex;
      align-items: center;
    }
    .flex2 {
      display: flex;
      align-items: flex-start;
    }
    .label {
      @include font_color("font_color3");
      width: 46px;
      min-width: 46px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      > i {
        font-style: normal;
      }
    }
  }
  &-right {
    width: calc(100% - 260px);
    // height: calc(100% + 16px);
    height: 100%;
    overflow-x: hidden;
    .mr6 {
      margin-right: 6px;
    }
    .right-all {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      transition: all 0.5s;
      .card-item:hover,
      .card-item.active {
        @include background_color("background_color36");
      }
      .card-item {
        transition: all 0.5s;
        border-radius: 4px;
        min-width: calc(22.22% - 10.67px);
        width: calc(22.22% - 10.67px);
        height: 100%;
        margin-right: 12px;
        @include background_color("background_color2");
        cursor: pointer;

        .list-title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 24px;
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          font-size: 14px;
          @include font_color("font_color1");
          height: 52px;
          @include border_color("border_color5");
          > img {
            width: 35px;
            height: 35px;
            border-radius: 8px;
            margin-right: 10px;
          }
        }
        .list-item {
          width: 100%;
          min-width: 100%;
          padding: 0 0 0 13%;
          height: 66px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          @include border_color("border_color5");
          > span {
            line-height: 87px;
          }
          > div {
            display: inline-block;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          > div:first-child {
            min-width: 55%;
            width: 55%;
          }
          > div:last-child {
            min-width: 44%;
            width: 44%;
          }
        }
        .tip {
          @include font_color("font_color26");
          position: relative;
          left: 30px;
          bottom: 6px;
          display: none;
        }
        &.active {
          .tip {
            display: inline-block;
          }
        }
      }
    }
  }
}
.chart-container {
  width: calc(100% - 48px);
  height: 363px;
  margin: 24px 0 0 24px;
  @include background_color("background_color2");
  .card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
    .svg-title {
      font-size: 16px;
      margin-right: 8px;
    }
    > div > span {
      font-size: 16px;
      @include font_color("font_color2");
    }
  }
  .button-group {
    height: 42px;
    padding: 0 0px 0 24px;
    display: flex;
    align-items: center;
  }
}
.calendar_table {
  margin-top: 24px;
  padding: 0 24px;
}
.font-color1 {
  @include font_color("font_color1");
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color3 {
  @include font_color("font_color3");
}
.font-color4 {
  @include font_color("font_color4");
}
.font-color5 {
  @include font_color("font_color5");
}
.font-color6 {
  @include font_color("font_color6");
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f20 {
  font-size: 20px;
}
</style>
