<template>
  <div class="effect-detail-container">
    <!-- 顶部 返回按钮 基本信息介绍 -->
    <DetailTitle :skewData="skewData" :rightValue="dateStr" />
    <!-- 统计数据卡片 -->
    <StatisticsCard
      :title="'数据趋势'"
      :cardData="cardData"
      :tooltip="true"
      :code="'xxqt01'"
    />
    <!-- 经销商贡献 -->
    <div class="dealer-contribution">
      <div class="title">经销商贡献</div>
      <div class="chart-main">
        <div
          class="chart-main-item"
          v-for="(item, index) in contributionData"
          :key="index"
        >
          <TitlePie
            :id="item.id"
            :title="{
              title: item.title,
              icon: item.icon,
            }"
            :chartData="pieData[item.key]"
          />
        </div>
      </div>
    </div>
    <!-- 最佳门店 + 4个卡片 -->
    <div class="bottom-main">
      <div class="best-store">
        <BestStore :list="storeList" />
      </div>

      <div class="bottom-main-right">
        <div
          class="bottom-main-right-item"
          v-for="(item, index) in deepBestData"
          :key="index"
        >
          <BestCard :bestData="item" />
        </div>

        <!-- <div class="bottom-main-right-item">
          <BestCard :bestData="bestData[0]" />
        </div>
        <div class="bottom-main-right-item">
          <BestCard :bestData="bestData[1]" />
        </div>
        <div class="bottom-main-right-item">
          <BestCard :bestData="bestData[2]" />
        </div>
        <div class="bottom-main-right-item">
          <BestCard :bestData="bestData[3]" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import DetailTitle from "./components/DetailTitle";
import StatisticsCard from "./components/StatisticsCard";
import BestStore from "./components/BestStore";
import BestCard from "./components/BestCard";

import deepClone from "@/utils/clone-utils";
import chartLine from "./options/chartLine.js";
import TitlePie from "@/components/chart/TitlePie";
import api from "@/api/effectDetail.js";
// 统计数据卡片展示文案
const cardData = {
  day: [
    {
      name: ["增粉破万门店", "新增", "环比"],
      data: [],
    },
    {
      name: ["直播活跃门店", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频活跃门店", "新增", "环比"],
      data: [],
    },
  ],
  week: [
    {
      name: ["增粉破万门店", "新增", "环比"],
      data: [],
    },
    {
      name: ["直播活跃门店", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频活跃门店", "新增", "环比"],
      data: [],
    },
  ],
  month: [
    {
      name: ["增粉破万门店", "新增", "环比"],
      data: [],
    },
    {
      name: ["直播活跃门店", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频活跃门店", "新增", "环比"],
      data: [],
    },
  ],
};
// 经销商贡献
const contributionData = [
  {
    title: "增粉",
    icon: "user",
    id: "chart1",
    key: "fans_increase",
  },
  {
    title: "直播观看",
    icon: "eye4",
    id: "chart2",
    key: "live_watch",
  },
  {
    title: "视频播放",
    icon: "type-video-1",
    id: "chart3",
    key: "aweme_play",
  },
];
// 最佳卡片数据
const bestData = [
  {
    name: "最佳直播",
    tooltip: "",
    key: "best_live",
  },
  {
    name: "涨粉最多(直播)",
    tooltip: "",
    key: "increase_most_fans_live",
  },
  {
    name: "最佳视频",
    tooltip: "",
    key: "best_aweme",
  },
  {
    name: "传播最快(视频)",
    tooltip: "",
    key: "spread_fastest_aweme",
  },
];
export default {
  name: "effectDetailStore",
  components: {
    DetailTitle,
    StatisticsCard,
    BestStore,
    TitlePie,
    BestCard,
  },
  data() {
    return {
      skewData: ["经销商表现"], // ['活动: T35精英门店运营', '分组: 史蒂夫', '华东大区', '2022-12周', '视频发布']
      dateStr: this.$route.query.dateStr,
      cardData: deepClone(cardData),
      contributionData: deepClone(contributionData),
      pieData: {},
      storeList: [],
      bestData: deepClone(bestData),
      deepBestData: []
    };
  },
  mounted() {},
  computed: {},
  created() {
    this.setSkewData();
    this.getDataTrend();
    this.getContributionByDealer();
    this.getBestStoreByDealer();
    this.getBestLiveVideoByDealer();
  },
  methods: {
    // 设置顶部标题
    setSkewData() {
      console.log(this.$route.query);
      this.skewData = ["经销商表现"];
      // 大区
      if (this.$route.query.rId && this.$route.query.rName) {
        this.skewData.unshift(`${decodeURIComponent(this.$route.query.rName)}`);
      }
      // 活动
      if (this.$route.query.aId && this.$route.query.aName) {
        this.skewData.unshift(
          `活动: ${decodeURIComponent(this.$route.query.aName)}`
        );
      }
    },
    // 获取页面接口通用参数
    getParams() {
      let body = {
        platform: this.$route.query.platform,
        params: {
          id: this.$route.query.aId,
          region_id: this.$route.query.rId,
        },
      };
      return body;
    },
    // 接口请求 - 数据趋势
    getDataTrend() {
      let body = this.getParams();
      // 恢复初始数据
      this.cardData = deepClone(cardData);
      api.getDataTrendByDealer(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          let key = ["day", "week", "month"];
          key.forEach((item) => {
            this.cardData[item][0].data.push(
              data[item].fans_over1w_dealer_count_total
            );
            this.cardData[item][0].data.push(
              data[item].fans_over1w_dealer_count
            );
            this.cardData[item][0].data.push(
              data[item].fans_over1w_dealer_chain
            );
            this.cardData[item][1].data.push(
              data[item].live_active_dealer_count_total
            );
            this.cardData[item][1].data.push(
              data[item].live_active_dealer_count
            );
            this.cardData[item][1].data.push(
              data[item].live_active_dealer_chain
            );
            this.cardData[item][2].data.push(
              data[item].aweme_active_dealer_count_total
            );
            this.cardData[item][2].data.push(
              data[item].aweme_active_dealer_count
            );
            this.cardData[item][2].data.push(
              data[item].aweme_active_dealer_chain
            );
          });
        } else if (res.data.code === 6000) {
          this.$message.warning("数据预计每日12：00跑完~");
        } else {
          console.error("获取数据趋势失败");
        }
      });
    },
    // 接口请求 - 经销商贡献
    getContributionByDealer() {
      let body = this.getParams();
      // 恢复初始数据
      api.getContributionByDealer(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.pieData = data;
        } else {
          console.error("获取经销商贡献失败");
        }
      });
    },
    // 接口请求 - 最佳门店
    getBestStoreByDealer() {
      let body = this.getParams();
      // 恢复初始数据
      this.storeList = [];
      api.getBestStoreByDealer(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.storeList = data;
          console.log("storeList", this.storeList);
        } else {
          console.error("获取经销商贡献失败");
        }
      });
    },
    // 接口请求 - 最佳直播视频
    getBestLiveVideoByDealer() {
      let body = this.getParams();
      // 恢复初始数据
      this.bestData = deepClone(bestData);
      api.getBestLiveVideoByDealer(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getBestLiveVideoByDealer", data);
          this.bestData.forEach((item, index) => {
            let newData = data[this.bestData[index].key];
            this.bestData[index] = {
              ...item,
              avatar: newData.avatar,
              cover: newData.cover,
              dealer_name: newData.dealer_name,
              title: newData.title,
              platform: this.$route.query.platform,
            };
            if (index == 0) {
              this.bestData[index].room_id = newData.room_id;
              this.bestData[index].numberData = [
                {
                  name: "直播观看",
                  value: newData.watch_count,
                },
              ];
            } else if (index == 1) {
              this.bestData[index].room_id = newData.room_id;
              this.bestData[index].numberData = [
                {
                  name: "直播观看",
                  value: newData.watch_count,
                },
                {
                  name: "增粉",
                  value: newData.fans_increase,
                },
              ];
            } else if (index == 2) {
              this.bestData[index].aweme_id = newData.aweme_id;
              this.bestData[index].numberData = [
                {
                  name: "播放",
                  value: newData.play_count,
                },
                {
                  name: "点赞",
                  value: newData.digg_count,
                },
                {
                  name: "评论",
                  value: newData.comment_count,
                },
              ];
            } else if (index == 3) {
              this.bestData[index].aweme_id = newData.aweme_id;
              this.bestData[index].numberData = [
                {
                  name: "单日最高",
                  value: newData.play_count_di,
                },
                {
                  name: "总播放量",
                  value: newData.play_count,
                },
              ];
            }
          });
          console.log(this.bestData);
          this.deepBestData = deepClone(this.bestData)
        } else {
          console.error("获取最佳直播视频失败");
        }
      });
    },

    // 概览切换tab echart - line
    getOverviewCardOptions(item) {
      if (!item) {
        return false;
      }
      console.log("overviewCardChangeTab", item);
      let xData = item.xData;
      let yData = item.yData;
      let option = deepClone(chartLine);
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      this.lineChartOption = option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.effect-detail-container {
  padding: 0 20px 20px;
  .top-title {
    width: 100%;
    height: 60px;
    background: #fff;
  }
  .dealer-contribution {
    width: 100%;
    height: 390px;
    padding: 27px 20px 0;
    background: #2b2f3b;
    border-radius: 16px;
    margin: 20px 0;
    .title {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
    }
    .chart-main {
      width: 100%;
      height: 336px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-item {
        width: 33%;
        height: 100%;
      }
    }
  }
  .bottom-main {
    width: 100%;
    height: 460px;
    display: flex;
    .best-store {
      width: 310px;
      height: 100%;
      background: #2b2f3b;
      border-radius: 16px;
      margin-right: 20px;
    }
    &-right {
      width: calc(100% - 330px);
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      &-item {
        width: calc(50% - 10px);
        height: 220px;
        background: #2b2f3b;
        border-radius: 16px;
        &:nth-child(1) {
          margin-right: 20px;
          margin-bottom: 20px;
        }
        &:nth-child(2) {
          margin-bottom: 20px;
        }
        &:nth-child(3) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>