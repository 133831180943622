<template>
  <div>
    <platform-header
      title="经销商矩阵对标"
      :currentTab="currentTab"
      @tabChange="tabChange"
    >
    </platform-header>
    <div class="tools">
      <a-month-picker
        v-model="searchTime"
        placeholder="Select month"
        class="aft-month-picker default"
        :disabled-date="pickerOptions"
        @change="onChange"
        locale="zh-cn"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-month-picker>
    </div>
    <div class="main-container">
      <div class="out-container">
        <div class="card-list">
          <div
            :class="cardIndex == index ? 'card-item active' : 'card-item'"
            v-for="(item, index) in brandList"
            :key="index"
            @click="cardClick(item, index)"
          >
            <div style="height: 50px; line-height: 50px" class="border-bottom">
              <div class="card-item-left">
                <span class="f20 font-color2 text-overflow">{{
                  item.principal_name
                }}</span>
              </div>
              <div class="card-item-right">
                <span class="font-color1 f14">{{ item.autthor_count }}</span>
                主播
              </div>
            </div>
            <div style="height: 63px" class="border-bottom">
              <div class="card-item-left col">
                <div>活跃门店</div>
                <div class="f14 font-color2">
                  {{ item.active_dealer_count | changeNum }}
                </div>
              </div>
              <div class="card-item-right col">
                <div>占比</div>
                <div class="f14 font-color2">
                  {{ (item.active_dealer_ratio * 100).toFixed(0) }}%
                </div>
              </div>
            </div>
            <div style="height: 63px" class="border-bottom">
              <div class="card-item-left col">
                <div>有效直播</div>
                <div class="f14 font-color5">
                  {{ item.live_effective_count | changeNum }}
                </div>
              </div>
              <div class="card-item-right col">
                <div>占比</div>
                <div class="f14 font-color2">
                  {{ (item.effective_live_ratio * 100).toFixed(0) }}%
                </div>
              </div>
            </div>
            <div style="height: 73px">
              <div class="card-item-left col">
                <div>有效短视频</div>
                <div class="f14 font-color4">
                  {{ item.aweme_effective_count | changeNum }}
                </div>
              </div>
              <div class="card-item-right col">
                <div>占比</div>
                <div class="f14 font-color2">
                  {{ (item.effective_aweme_ratio * 100).toFixed(0) }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-main">
        <div class="chart-card" style="height: 442px">
          <div class="card-title">
            <div>
              <svg-icon icon-class="title" class="svg-title" />
              <span>{{ cardItem.principal_name || "--" }}</span>
            </div>
          </div>

          <div class="matrix-table">
            <img src="@/assets/img/matrix5.png" width="203" alt="" />
            <div class="matrix-table-row row0">
              <div class="matrix-table-row-left"></div>
              <!-- [
                  '门店数量',
                  '传播总价值',
                  '销售总价值',
                  '直播场次',
                  '直播观看',
                  '短视频发布',
                  '短视频点赞',
                ] -->
              <div
                class="matrix-table-row-right matrix-table-row-name"
                v-for="(item, index) in [
                  '门店数量',
                  '传播总价值',
                  '直播场次',
                  '直播观看',
                  '短视频发布',
                  '短视频点赞',
                ]"
                :key="index"
              >
                <span v-if="index == 3">{{
                  currentTab | returnWatchText
                }}</span>
                <span v-else>{{ item }}</span>
              </div>
            </div>
            <div class="matrix-table-row row1">
              <div class="matrix-table-row-left"></div>
              <div
                class="matrix-table-row-right matrix-table-row-number"
                v-for="(item, index) in infoData.dealer_infos[0]"
                :key="index"
              >
                {{ item | changeNum }}
              </div>
            </div>
            <div class="matrix-table-row row2">
              <div class="matrix-table-row-left"></div>
              <div
                class="matrix-table-row-right matrix-table-row-number"
                v-for="(item, index) in infoData.dealer_infos[1]"
                :key="index"
              >
                {{ item | changeNum }}
              </div>
            </div>
            <div class="matrix-table-row row3">
              <div class="matrix-table-row-left"></div>
              <div
                class="matrix-table-row-right matrix-table-row-number"
                v-for="(item, index) in infoData.dealer_infos[2]"
                :key="index"
              >
                {{ item | changeNum }}
              </div>
            </div>
            <div class="matrix-table-row row4">
              <div class="matrix-table-row-left"></div>
              <div
                class="matrix-table-row-right matrix-table-row-number"
                v-for="(item, index) in infoData.dealer_infos[3]"
                :key="index"
              >
                {{ item | changeNum }}
              </div>
            </div>
            <div class="matrix-table-row row5">
              <div class="matrix-table-row-left"></div>
              <div
                class="matrix-table-row-right matrix-table-row-number"
                v-for="(item, index) in infoData.dealer_infos[4]"
                :key="index"
              >
                {{ item | changeNum }}
              </div>
            </div>
          </div>
        </div>
        <div class="chart-card">
          <div class="card-title">
            <div>
              <svg-icon icon-class="title" class="svg-title" />
              <span>直播场次分布</span>
            </div>
          </div>
          <div class="chart-div">
            <my-chart
              :options="
                getDateOption(
                  infoData.xData,
                  infoData.live_count_distributeds,
                  '直播场次'
                )
              "
              :id="'date-chart1'"
            ></my-chart>
          </div>
        </div>
        <div class="chart-card">
          <div class="card-title">
            <div>
              <svg-icon icon-class="title" class="svg-title" />
              <span>短视频分布</span>
            </div>
          </div>
          <div class="chart-div">
            <my-chart
              :options="
                getDateOption(
                  infoData.xData,
                  infoData.aweme_count_distributeds,
                  '短视频'
                )
              "
              :id="'date-chart2'"
            ></my-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import MyChart from "@/components/MyChart/MyChart";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ButtonTab from "@/components/PlatformTab/ButtonTab";
import chartBar from "./options/chartBar";
import chartDate from "./options/chartDate";
import deepClone from "@/utils/clone-utils";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "matrixBenchmarking"
);
export default {
  name: "matrixDetail",
  components: { MyChart, PlatformHeader, ButtonTab },
  data() {
    return {
      msg: "矩阵详情",
      tooltipTitle: "按账号销售价值分类汇总",
      searchTime: moment().subtract(1, "months"),
      cardIndex: 0,
      cardItem: {},
      currentTab: "",
    };
  },
  computed: {
    ...mapState({
      brandList: (state) => state.brandList,
      infoData: (state) => state.infoData,
      matrixData: (state) => state.matrixData,
      dateData: (state) => state.dateData,
    }),
  },
  created() {
    // this.loadBrandList();
  },
  watch: {
    brandList(val) {
      if (val.length == 0) {
        // this.$message.warning('当前账号未绑定主体信息，请联系管理员！')
        return false;
      }
      this.cardItem = val[0];
      this.loadInfoByPid();
    },
  },
  methods: {
    ...mapActions(["getBrandList", "getInfoByPid"]),
    ...mapMutations(["changeState"]),
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment();
    },
    // 顶部卡片点击
    cardClick(item, index) {
      if (this.cardItem == item) {
        return false;
      }
      console.log(item);
      this.cardItem = item;
      this.cardIndex = index;
      this.loadInfoByPid();
    },
    loadBrandList() {
      this.cardItem = {};
      this.getBrandList({
        statistics_begin_date: moment(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        statistics_end_date: moment(this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        platform: this.currentTab,
      });
    },
    loadInfoByPid() {
      this.getInfoByPid({
        statistics_begin_date: moment(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        statistics_end_date: moment(this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        principal_id: this.cardItem.principal_id,
        platform: this.currentTab,
      });
    },
    tabChange(val) {
      console.log(val);
      this.currentTab = val;

      this.cardIndex = 0;
      this.loadBrandList();
      // this.loadInfoByPid()
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      this.loadBrandList();
      // this.loadInfoByPid()
    },
    getDateOption(xData, yData, name) {
      let option = deepClone(chartDate);
      if (xData.length == 0) {
        return option;
      }
      let numberArr = [];
      yData.forEach((item) => {
        numberArr.push(item[2]);
      });
      let maxNum = Math.max.apply(null, numberArr);
      yData = yData.map(function(item) {
        return [item[1], item[0], item[2]];
      });
      option.visualMap.max = maxNum;
      option.xAxis.data = xData;
      option.series[0].data = yData;
      option.series[0].name = name;

      return option;
    },
  },
  beforeDestroy() {
    this.changeState({
      data: {
        aweme_count_distributeds: [],
        dealer_infos: [],
        live_count_distributeds: [],
        weeks: [],
        xData: [],
      },
      name: "infoData",
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.tools {
  width: 100%;
  // max-width: 1440px;
  min-width: 1008px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: flex-end;
  padding: 0 24px;
}
.main-container {
  width: 100%;
  max-width: 1440px;
  min-width: 1008px;
  padding: 0 24px 24px;
  @include font_color("font_color2");
  .out-container {
    height: 252px;
    overflow: hidden;
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    .card-list {
      position: absolute;
      top: 3px;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: auto;
      .card-item:hover,
      .card-item.active {
        @include background_color("background_color36");
      }
      .card-item {
        cursor: pointer;
        width: calc(16.5% - 12px);
        min-width: 188px;
        margin-right: 12px;
        height: 100%;
        @include font_color("font_color3");
        font-size: 12px;
        @include background_color("background_color2");
        border-radius: 4px;
        .border-bottom {
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          @include border_color("border_color6");
        }
        > div {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
        &-left {
          width: 56%;
          height: 100%;
          text-align: left;
        }
        &-left.col,
        &-right.col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        &-right {
          width: 34%;
          height: 100%;
          text-align: left;
        }
      }
      .text-overflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100px;
        display: inline-block;
      }
    }
  }
  .chart-main {
    width: 100%;
    .chart-card {
      width: 100%;
      height: 286px;
      border-radius: 4px;
      @include background_color("background_color2");
      margin-bottom: 24px;
      .matrix-table {
        width: 100%;
        height: 353px;
        color: #fff;
        padding: 0px 30px 0px 135px;
        position: relative;
        top: -7px;
        > img {
          position: absolute;
          top: 16px;
          left: 24px;
        }
        &-row.row0 {
          height: 30px;
          line-height: 30px;
          margin-bottom: 11px;
        }
        .row1 {
          @include background_color("background_color22");
        }
        .row2 {
          @include background_color("background_color38");
        }
        .row3 {
          @include background_color("background_color23");
        }
        .row4 {
          @include background_color("background_color39");
        }
        .row5 {
          @include background_color("background_color24");
        }
        &-row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          height: 44px;
          line-height: 44px;
          margin-bottom: 16px;
          &-name {
            @include font_color("font_color3");
          }
          &-number {
            @include font_color("font_color2");
          }
          &-left {
            width: 135px;
          }
          &-right {
            width: 114px;
            text-align: left;
          }
        }
      }
      .card-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 24px;
        .svg-title {
          font-size: 16px;
          margin-right: 8px;
        }
        > div > span {
          font-size: 16px;
          @include font_color("font_color2");
        }
      }
      .chart-div {
        padding: 0 24px;
        height: 210px;
        width: 100%;
      }
    }
  }
}
.font-color1 {
  @include font_color("font_color1");
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color3 {
  @include font_color("font_color3");
}
.font-color4 {
  @include font_color("font_color4");
}
.font-color5 {
  @include font_color("font_color5");
}
.font-color6 {
  @include font_color("font_color6");
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f20 {
  font-size: 20px;
}
</style>
