<template>
  <div id="progress_box" :class="activeStyle?'activeStyle':''">
    <div
      class="progress_box"
      :style="{height: strokeWidth + 'px','line-height':strokeWidth + 'px','font-size':strokeWidth - 1 + 'px',background:boxBgd,'border-radius': strokeWidth-strokeWidth/2+'px'}"
    >
      <div class="process_rate" :style="{'border-radius': strokeWidth-strokeWidth/2+'px',background:rateBgd,width:percentage+'%'}"></div>
      <div class="rate_tips" v-if="showText&&!showOutside">{{text}}</div>
    </div>
    <div v-if="showText&&showOutside" class="outside_tips" :style="{'padding-left':'5px','line-height':strokeWidth + 'px'}">{{text}}</div>
  </div>
</template>
<script>
export default {
  props: {
    // 进度条的宽度
    strokeWidth: {
      type: Number,
      default: 13
    },
    // 是否显示进度条文字内容
    showText: {
      type: Boolean,
      default: true
    },
    // 提示文字显示的位置
    showOutside: {
      type: Boolean,
      default: false
    },
    // 提示文字
    text: {
      type: [Number, String],
      default: ""
    },
    // 底层盒子背景色
    boxBgd: {
      type: String,
      default: "#F1F2F5"
    },
    // 是否添加active效果
    activeStyle: {
      type: Boolean,
      default: false
    },
    // 所占比例 建议以最高数为基数，最高数为百分之八十
    percentage:{
        type:Number,
        default:80,
        required:true
    },
    // 指示条背景色
    rateBgd:{
        type:String,
        default:'#4a58f3'
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {

  },
  methods: {}
};
</script>
<style lang="scss" scoped>
#progress_box {
  width: 100%;
  display: flex;
  .progress_box {
    display: flex;
    flex:8;
    .process_rate {
      width: 80%;

    }
    .rate_tips {
      padding-left: 5px;
      white-space: nowrap;
    }
  }
}

.activeStyle{
  // margin-top:180px;
  color: #fbb76b;
  .process_rate {
    background: #fbb76b !important;
  }
}
</style>