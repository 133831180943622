import echarts from 'echarts'
import filters from '@/filters'
var option = {
    backgroundColor: "rgba(0,0,0,0)",
    grid: {
        "left": "30",
        "top": "30",
        "right": "40",
        "bottom": "20"
    },
    tooltip: {
        show: true,    // 是否显示提示框组件: 
        trigger: 'axis',
        axisPointer: {
          lineStyle: {
            width: 2,
            color: 'rgba(255, 202, 92, .2)'
          }
        },
        padding: 0,
    },
    xAxis: {
        type: 'category',
        boundaryGap: true,
        axisLine: {
            show: false, //隐藏x轴
        },
        axisTick: {
            show: false,  //刻度线
        },
        axisLabel: {
            show: true, //隐藏刻度值
            showMaxLabel: true,
            textStyle: {
                // color: '#8890A6', // 坐标轴文字颜色
                fontSize: 10, // 坐标轴文字大小
                color: function (value, index) {
                    return '#8890A6'
                }
            },
        },
        data: []
    },
    yAxis: [
        {
            type: 'value',
            position: 'right',
            axisTick: {
                show: false,  //刻度线
            },
            axisLine: {
                show: false, //隐藏y轴
            },
            axisLabel: {
                show: false,
                textStyle: {
                    color: '#8890A6', // 坐标轴文字颜色
                    fontSize: 10, // 坐标轴文字大小
                    padding: [0, 10, 0, 0]
                },
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: ["rgba(255,255,255,0.04)"],
                },
            },
        }, {
            type: 'value',
            position: 'left',
            boundaryGap: false,
            axisLine: {
                show: false, //隐藏x轴
            },
            axisTick: {
                show: false,  //刻度线
            },
            axisLabel: {
                show: false,
                textStyle: {
                    color: '#8890A6', // 坐标轴文字颜色
                    fontSize: 10, // 坐标轴文字大小
                    padding: [0, 10, 0, 0]
                },
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: ["rgba(255,255,255,0.04)"],
                },
            },
            scale: true,/*按比例显示*/
        },
    ],
    series: [
        {
            name: 'value',
            data: [],
            type: 'bar',
            itemStyle: {
                normal: {
                    color: '#2B2F3B',
                },
                // emphasis: {
                //     color: '#815CFF',
                // }
            },
            yAxisIndex: 1,
            barMaxWidth: 17,
        },
        {
            name: '环比',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            showSymbol: false,
            symbolSize: 8,
            itemStyle: {
                normal: {
                    color: '#FFCA5C'
                }
            },
            data: []
        },
    ]
};
export default option