var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap"},[_c('a-spin',{staticClass:"spin-class",attrs:{"spinning":_vm.officialLoading}}),_c('div',{staticClass:"title-div"},[_vm._m(0),_c('div',{staticClass:"route-div",on:{"click":_vm.routerTo}},[_c('span',{staticClass:"route"},[_vm._v("查看更多")]),_c('span',{staticClass:"arrow"},[_c('svg-icon',{staticStyle:{"font-size":"24px"},attrs:{"icon-class":"arrow-right"}})],1)])]),(_vm.officialList && _vm.officialList.author)?_c('div',{staticClass:"info-div"},[_c('WebpImage',{attrs:{"src":_vm.officialList.author.avatar,"width":'60px',"height":'60px',"radius":'50%'}}),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"font2"},[_vm._v(_vm._s(_vm.officialList.author.nickname))]),_c('div',{staticClass:"font1"},[_vm._v(" "+_vm._s(_vm.$parent.currentTab == "douyin" ? "抖音" : _vm.$parent.currentTab == "kuaishou" ? "快手" : _vm.$parent.currentTab == "dongchedi" ? "懂车帝" : "平台")+"号: "+_vm._s(_vm.officialList.author.code)+" ")])])],1):_vm._e(),(_vm.$parent.currentTab != 'dongchedi')?_c('div',{staticClass:"ranking-chart"},[_c('div',{staticClass:"ranking-chart-title"},[_vm._v(_vm._s(_vm.currentObj.title || "-"))]),_c('div',{staticClass:"ranking-chart-main"},[_c('MyChart',{attrs:{"options":_vm.getCardChartOption(_vm.officialTrendData),"id":'ranking-chart2'}})],1)]):_vm._e(),(_vm.officialList && _vm.officialList.author)?_c('a-table',{staticClass:"table-style border hide-select",attrs:{"columns":_vm.columns,"data-source":_vm.officialList.list,"rowKey":(data) => data.title,"customRow":_vm.rowClick,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
    },"pagination":false,"locale":{ emptyText: '暂无数据' }},scopedSlots:_vm._u([{key:"ranking",fn:function(scope){return _c('div',{staticClass:"td-style td-left ranking",staticStyle:{"margin-right":"120px"}},[_c('span',{class:scope.ranking <= 3 ? 'bc sp' : 'bc',staticStyle:{"margin":"0 0px 0 20px"}},[_vm._v(_vm._s(scope.ranking))]),(scope.ranking < 1000)?_c('span',{staticClass:"font-color23"},[_vm._v(_vm._s(Math.abs(scope.ranking_over_last_month) || ""))]):_vm._e(),(scope.ranking_over_last_month > 0 && scope.ranking < 1000)?_c('svg-icon',{staticStyle:{"font-size":"10px"},attrs:{"icon-class":"caret-up"}}):_vm._e(),(scope.ranking_over_last_month < 0 && scope.ranking < 1000)?_c('svg-icon',{staticStyle:{"font-size":"10px"},attrs:{"icon-class":"caret-down"}}):_vm._e()],1)}},{key:"name",fn:function(scope){return _c('div',{staticClass:"td-style"},[_c('span',{staticClass:"table-name"},[_vm._v(_vm._s(scope.title))])])}},{key:"number",fn:function(scope){return _c('div',{staticClass:"td-style td-right"},[_c('span',{staticClass:"table-number"},[_vm._v(_vm._s(_vm._f("changeNum")(scope.parameter)))])])}}],null,false,2954870278)},[_c('div',{staticStyle:{"display":"flex","padding-right":"20px"},attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('div',[_vm._v("数据")])]),_c('div',{staticStyle:{"padding-left":"20px"},attrs:{"slot":"customTitleRanking"},slot:"customTitleRanking"},[_c('span',[_vm._v("排名")]),_c('AftTooltip',{staticStyle:{"top":"1.8px","left":"4px"},attrs:{"code":'pgpm02'}})],1)]):_vm._e(),(_vm.liveChartData && _vm.liveChartData.xData.length > 0)?_c('div',{staticClass:"title-div title2-div"},[_vm._m(1)]):_vm._e(),(_vm.liveChartData && _vm.liveChartData.xData.length > 0)?_c('div',{staticClass:"chart-div"},[_c('my-chart',{attrs:{"options":_vm.getBarOptions(
          _vm.liveChartData.xData,
          _vm.liveChartData.watch_count,
          _vm.liveChartData.interaction_count,
          _vm.liveChartData.title
        ),"id":"barChart"}})],1):_vm._e(),(!_vm.officialList || !_vm.officialList.author)?_c('Empty',{staticStyle:{"margin-top":"270px"},attrs:{"title":'暂无该平台官号'}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"title"},[_vm._v("品牌官号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"title2"},[_vm._v("最近10场直播")])])
}]

export { render, staticRenderFns }