<template>
  <div class="intention-recognition-container">
    <div class="intention-title-box" style="margin-top: 10px;">
      <svg-icon :icon-class="'ag-icon3'" class="icon" />
      <span>高意向用户</span>
    </div>
    <div class="user-list">
      <div class="user-item" v-for="(item, index) in tabData.user_list1" :key="index">
        <UserCard :infoData="item" />
      </div>
    </div>
    <div class="intention-title-box">
      <svg-icon :icon-class="'ag-icon4'" class="icon" />
      <span>关注用户</span>
    </div>
    <div class="user-list">
      <div class="user-item" v-for="(item, index) in tabData.user_list2" :key="index">
        <UserCard :infoData="item" />
      </div>
    </div>
    <div class="intention-title-box">
      <svg-icon :icon-class="'ag-icon5'" class="icon" />
      <span>互动用户</span>
    </div>
    <div class="user-list">
      <div class="user-item" v-for="(item, index) in tabData.user_list3" :key="index">
        <UserCard :infoData="item" />
      </div>
    </div>
  </div>
</template>

<script>
import UserCard from "./UserCard";
export default {
  name: "TabContent3",
  props: {
    tabData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { UserCard },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scope>
.intention-recognition-container {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  .intention-title-box {
    height: 22px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    > .icon {
      font-size: 20px;
    }
    > span {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
      line-height: 22px;
      margin-left: 4px;
    }
  }
  .user-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .user-item {
      width: 64px;
      height: 64px;
      margin-right: 16px;
      margin-bottom: 24px;
    }
  }
}
</style>
