<template>
  <div class="hot-video-card" ref="card">
    <div class="hot-video-card-top">
      <span class="title">{{title}}</span>
    </div>
    <div class="hot-video-card-list" ref="scrollList">
      <VideoItem 
        :type="String(index + 1)"
        :accountType="item.account_type"
        v-for="(item, index) in list"
        :key="String(index + 1)"
        :videoData="getVideoData(item)"
      />
     
    </div>
    <div class="scroll-button left-button" @click="leftScroll">
      <svg-icon icon-class="arrow-down" class="icon" />
    </div>
    <div class="scroll-button right-button" @click="rightScroll">
      <svg-icon icon-class="arrow-down" class="icon" />
    </div>
  </div>
</template>

<script>
import VideoItem from "@/components/Item/VideoItem2";

export default {
  name: "HotVideo",
  props: {
    title: {
      type: String,
      default: "-",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // fans/live/video
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    VideoItem
  },
  data() {
    return {
    };
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {
    getVideoData(item) {
      let { avatar, cover,  room_id, aweme_id, aweme_url, title, dealer_name, nickname } = item;
      let obj = {
        avatar,
        cover,
        room_id,
        aweme_id,
        aweme_url,
        title,
        dealer_name,
        nickname,
        platform: this.$route.query.platform
      };
      if (this.type == "fans") {
        obj.data = [
          { name: "观看:", value: item.watch_count },
          { name: "增粉", value: item.fans_add },
          { name: "评论:", value: item.comment_count },
          { name: "点赞:", value: item.digg_count },
        ];
      } else if (this.type == "live") {
        obj.data = [
          { name: "观看人数:", value: item.live_watch_count },
          { name: "观看人次:", value: item.live_watch_time },
          { name: "评论:", value: item.comment_count },
          { name: "点赞:", value: item.digg_count },
        ];
      } else if (this.type == "video") {
        obj.data = [
          // { name: "播放:", value: item.play_count },
          { name: "点赞:", value: item.like_count },
          { name: "评论:", value: item.comment_count },
          { name: "转发:", value: item.share_count },
        ];
      }
      return obj;
    },
    rightScroll() {
      let scrollWidth = 132 * 4;
      let listLeft = this.$refs.scrollList.offsetLeft;
      let totalWidth = this.$refs.scrollList.offsetWidth;
      let cardWidth = this.$refs.card.offsetWidth;
      if (totalWidth <= cardWidth) {
        return false
      }
      if (Math.abs(listLeft) + scrollWidth + cardWidth > totalWidth) {
        this.$refs.scrollList.style.left = (cardWidth - totalWidth) + 'px'
      } else {
        this.$refs.scrollList.style.left = listLeft - scrollWidth + "px";
      }
    },
    leftScroll() {
      let scrollWidth = 132 * 4;
      let listLeft = this.$refs.scrollList.offsetLeft;
      if (listLeft + scrollWidth > 0) {
        this.$refs.scrollList.style.left = "0px";
      } else {
        this.$refs.scrollList.style.left = listLeft + scrollWidth + "px";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.hot-video-card {
  width: 100%;
  height: 272px;
  background: #2b2f3b;
  border-radius: 16px;
  padding: 26px 0px 20px 20px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  &-top {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      margin-right: 20px;
    }
  }
  &-list {
    position: absolute;
    left: 0;
    top: 55px;
    height: 182px;
    padding-left: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    transition: all 0.4s;
    >div {
      margin-right: 30px;
    }
  }
  .scroll-button {
    position: absolute;
    top: 27px;
    width: 24px;
    height: 24px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.left-button {
      right: 56px;
      transform: rotate(90deg);
    }
    &.right-button {
      right: 20px;
      transform: rotate(-90deg);
    }
    &:hover {
      color: #fff;
    }
  }
}
</style>