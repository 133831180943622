<template>
  <a-modal
    title="导拍模板"
    :visible="visible"
    width="600px"
    @cancel="visible = false"
  >
    <a-table
      :pagination="false"
      :columns="columns"
      :dataSource="list"
      :loading="isLoading"
      :rowClassName="() => 'temp-row'"
      rowKey="guideshootId"
      :scroll="{ y: 300 }"
      :locale="{ emptyText: '暂无数据' }"
      :customRow="rowClick"
      style="min-height: 350px"
    >
      <template slot="guideshootName" slot-scope="record">
        <div style="color: #ffca5c">
          {{ record.guideshootName }}
        </div>
      </template>
    </a-table>
    <div slot="footer" class="modal-footer">
      <a-pagination
        v-if="form.total > 0"
        v-model="form.page"
        size="small"
        :pageSize="form.size"
        :total="form.total"
        @change="pageChange"
      />
    </div>
  </a-modal>
</template>

<script>
import api from "@/api/marketingActivityList";
export default {
  components: {},
  data() {
    return {
      visible: false,
      isLoading: false,

      columns: [
        {
          title: "模版名称",
          align: "left",
          width: "180px",
          scopedSlots: { customRender: "guideshootName" },
        },
        {
          dataIndex: "onStatusTime",
          title: "上架时间",
          align: "left",
          width: 150,
        },
      ],
      form: {
        activityId: "",
        relevancyStatus: 2,
        page: 1,
        size: 10,
        total: 0,
      },
      list: [],
    };
  },
  methods: {
    /**
     * 打开成员列表弹窗
     * @param {string} activityId 活动id
     */
    openModal(activityId) {
      Object.assign(this.$data, this.$options.data());
      this.form.activityId = activityId;
      this.visible = true;
      this.getTemplateList();
    },
    /**
     * 获取模板列表
     */
    getTemplateList() {
      this.isLoading = true;
      api
        .getTemplateList(this.form)
        .then((res) => {
          this.list = res.data.data.list;
          this.form.total = res.data.data.total;
        })
        .finally(() => (this.isLoading = false));
    },
    /**
     * 页数改变
     * @param {number} page 修改的页数
     */
    pageChange(page) {
      this.form.page = page;
      this.getTemplateList();
    },
    /**
     * 点击表格行触发
     * @param {Object} record - 行数据
     * @return Function
     */
    rowClick: function(record) {
      return {
        on: {
          click: () => {
            this.$emit("preview", record.originalSampleUrl);
          },
        },
      };
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep .ant-modal-header {
  padding: 24px;
  border-bottom: none;
}
::v-deep .ant-modal-close-x {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
::v-deep .ant-modal-body {
  padding: 0 24px;
}
::v-deep .ant-modal-footer {
  padding: 20px 24px;
  border-top: none;
}

// 表格
::v-deep .ant-table-header,
::v-deep
  .ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  background: #1a1d26;
}
::v-deep .ant-table-thead > tr > th {
  background: #242832;
  color: rgba(255, 255, 255, 0.88);
  font-size: 12px;
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
::v-deep .ant-table-tbody > tr > td {
  color: rgba(255, 255, 255, 0.88);
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
::v-deep .ant-table-placeholder {
  background: #1a1d26;
  border-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.88);
}

// 分页
::v-deep .ant-pagination-item a,
::v-deep .ant-pagination-disabled a,
::v-deep .ant-pagination-disabled:hover a,
::v-deep .ant-pagination-disabled:focus a,
::v-deep .ant-pagination-disabled .ant-pagination-item-link,
::v-deep .ant-pagination-disabled:hover .ant-pagination-item-link,
::v-deep .ant-pagination-disabled:focus .ant-pagination-item-link,
::v-deep .ant-pagination-prev a,
::v-deep .ant-pagination-next a,
::v-deep
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #9aa5be;
}
::v-deep .ant-pagination-prev:focus .ant-pagination-item-link,
::v-deep .ant-pagination-next:focus .ant-pagination-item-link,
::v-deep .ant-pagination-prev:hover .ant-pagination-item-link,
::v-deep .ant-pagination-next:hover .ant-pagination-item-link,
::v-deep
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #ffca5c;
}
::v-deep .ant-pagination-item-active {
  border: 1px solid transparent;
  background: none;
  a {
    color: #ffca5c;
  }
}

::v-deep .temp-row {
  cursor: pointer;
}
</style>
