<template>
  <div id="chartid" :class="className" :style="styleObject" :chartData="chartData" />
</template>
<script>
import echarts from "echarts";
import resize from "@/mixins/resize";
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: "400px", height: "200px" };
      }
    },
    chartData: {
      type: Array,
      default: () => {
        return [
        ];
      }
    }
  },
  data() {
    return {
      chart: null
    };
  },

  computed: {
    styleObject: function() {
      const obj = this.boxStyle;
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";

        obj[attr] =
          unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    } 
  },
  watch: {
    chartData() {
      this.initChart();
    }
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById('chartid'));
      this.chart.setOption({
            tooltip: {
              show: true,
              position: 'top',
              backgroundColor: 'rgba(37, 50, 85, 0.9)',
              textStyle: {
                  color: '#fff',
                  fontSize: 30
              }
            },
        series: [{
            type: "wordCloud",
            // 网格大小，各项之间间距
            gridSize: 15,
            // 形状 circle 圆，cardioid  心， diamond 菱形，
            // triangle-forward 、triangle 三角，star五角星
            shape: 'circle',
            // 字体大小范围
            sizeRange: [8, 30],
            // 文字旋转角度范围
            rotationRange: [0, 90],
            // 旋转步值
            rotationStep: 90,
            // 自定义图形
            // maskImage: maskImage,
            left: 'center',
            top: 'center',
            right: null,
            bottom: null,
            // 画布宽
            width: '90%',
            // 画布高
            height: '80%',
            // 是否渲染超出画布的文字
            drawOutOfBound: false,
            textStyle: {
                normal: {
                    color: function() {
                        return 'rgb(' + [
                            Math.round(Math.random() * 200 + 55),
                            Math.round(Math.random() * 200 + 55),
                            Math.round(Math.random() * 200 + 55)
                        ].join(',') + ')';
                    }
                },
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: '#2ac'
                }
            },
            data:this.chartData
        }]
      });
    }
  }
};
</script>