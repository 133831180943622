import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // ---- 公共 ----
  // 区域列表
  getAreaList() {
    return request({
      url: `/jjt/dealer/dealers/region-list`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then(res => {
      let list = res.data.data.region_list || []
      list.map(item => {
        item.value = item.id
        item.label = item.name
      })
      return list
    })
  },
  // 活动列表
  getActivityList({
    platform
  }) {
    return request({
      url: '/jjt/business/policy/assessment/activity-list',
      baseURL: getBaseUrl(platform),
      method: 'get',
    })
  },
  // ---- 组件大盘 ----
  //概览
  getOverviewV1({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/component/overview/v1',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },

  //挂载诊断
  getComponentV1({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/component/mountDiagnosis/v1',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  getComponentV2({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/component/mountDiagnosis/v2',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  // 时间趋势
  getComponentTime({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/component/time',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  // 大区透视
  getComponentRegion({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/component/region',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  // 门店排行
  getStoreList({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/list',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
}