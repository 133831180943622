<template style="width: 100%; height: 100%">
  <div :id="id" :style="defaultStyle"></div>
</template>

<script>
import echarts from "echarts";
import resize from "@/mixins/resize";
import chartPie_assistant from "../chartPie_assistant.js";
import deepClone from "@/utils/clone-utils";
export default {
  name: "MyChart",
  mixins: [resize],
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    id: {
      type: String,
    },
    defaultStyle: {
      type: Object,
      default: () => {
        return {
          width: "100%",
          height: "100%",
        };
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  created() {
    this.initChart();
  },
  computed: {},
  watch: {},
  methods: {
    initChart() {
      this.$nextTick(() => {
        let dom = document.getElementById(this.id);
        if (dom) {
          this.chart = echarts.init(dom);
          let options = deepClone(chartPie_assistant);
          options.series[0].data = this.chartData;
          options.series[1].data = this.chartData;
          this.chart.setOption(options, true);
          window.onresize = this.chart.resize;
          // 默认第一个高亮
          this.chart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });

          // 点击事件
          this.chart.on("click", (e) => {
            let arr = this.chartData;
            // 将数组中带有选中的属性删除掉
            arr.map((item) => {
              if (item.selected) {
                delete item.selected;
                delete item.label;
              }
            });
            // 找到当前点击的在数组中的index
            let index = arr.findIndex((item) => item.name == e.name);
            // 将数组中对应index的值 赋上 选中属性
            arr[index].selected = true;
            arr[index].label = {
              normal: {
                show: true,
              },
            };
            // 一定要在最后重新设置option，以实时刷新
            this.chart.setOption(options);
          });
          // 鼠标移入事件
          this.chart.on("mouseover", (e) => {
            let arr = this.chartData;
            let index = 0;
            /**
             *  移入时候，删除选中的label值，避免重叠覆盖
             *  用for循环可以break跳出循环，节省性能
             */
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].selected) {
                index = i; // 记录选中扇区的index
                // delete arr[i].label;
                break;
              }
            }
            // 判断如果当前选中的扇区和移入的扇区不一样，就取消高亮状态
            if (e.name != arr[index].name) {
              this.chart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: index,
              });
            }
            this.chart.setOption(options);
          });
          // 鼠标移出事件
          this.chart.on("mouseout", (e) => {
            let arr = this.chartData;
            let index = 0;
            // 移出的时候找到选中的值，并赋上label属性
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].selected) {
                index = i; // 记录选中扇区的index
                arr[i].label = {
                  normal: {
                    show: true,
                  },
                };
              }
            }
            // 移出的时候将选中扇区设置为高亮状态
            this.chart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: index,
            });
            this.chart.setOption(options);
          });
        }
      });
    },
    resizeChart() {
      this.chart.resize();
    },
  },
};
</script>
<style lang="less" scope>
</style>
