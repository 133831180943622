<template>
  <div
    class="scroll-view"
    ref="scrollView"
    @scroll="handleScroll"
  >
    <div class="scroll-view-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollView',
  props: {
    scrollY: {
      type: Boolean,
      default: false
    },
    lowerThreshold: {
      type: [Number, String],
      default: 50
    }
  },
  methods: {
    //
    handleScroll (e) {
      if (this.scrollY) {
        const { clientHeight, scrollTop, scrollHeight } = e.target

      if ((clientHeight + scrollTop) >= (scrollHeight - this.lowerThreshold) ) {
          this.$emit('scroll-to-lower')
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.scroll-view {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #565d72;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-track-piece {
    border-radius: 3px;
    background: rgba(255,255,255,0);
  }

  &-content {
    padding: 0 4px 0 0;
  }
}
</style>
