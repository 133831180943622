<template>
  <div class="tabs-content2">
    <div class="tabs-main">
      <div class="tabs-main-left">
        <div class="title-div">
          <span class="title">线索来源</span>
          <NewTabs2 :tabData="tabData" @tabChange="tabChange" v-if="isArea" />
        </div>
        <div class="chart-div">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(overviewData[tabsKey2])"
            :id="'pie-chart-2'"
          />
        </div>
      </div>
      <div class="tabs-main-right">
        <NewTabs
          :tabData="tabsData"
          @tabChange="changeTabs"
          :labelStyle="{ lineHeight: '44px' }"
        />
        <div class="data-list" v-if="statisticsData[tabsKey]">
          <div class="data-list-item">
            <span class="font1"
              >{{ tabsKey == "live" ? "直播" : "视频" }}流量</span
            >
            <span class="font2">{{
              statisticsData[tabsKey].flow | changeNum
            }}</span>
          </div>
          <div class="data-list-item">
            <span class="font1"
              >{{ tabsKey == "live" ? "直播" : "视频" }}线索</span
            >
            <span class="font2">{{
              statisticsData[tabsKey].sales_cue_count | changeNum
            }}</span>
          </div>
          <div class="data-list-item">
            <span class="font1">转化率</span>
            <div>
              <span class="font2">{{
                statisticsData[tabsKey].clue_conversion_rate | numRate3
              }}</span>
              <span class="font3">{{
                statisticsData[tabsKey].day_chain | numRate
              }}</span>
              <svg-icon
                v-if="statisticsData[tabsKey].day_chain > 0"
                icon-class="caret-up"
                style="font-size: 10px"
              />
              <svg-icon
                v-if="statisticsData[tabsKey].day_chain < 0"
                icon-class="caret-down"
                style="font-size: 10px"
              />
              <div class="tag">
                <span class="tag2" v-if="statisticsData[tabsKey].state == '1'"
                  >健康</span
                >
                <span class="tag1" v-else>偏低</span>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-div">
          <div class="icon-div" style="top: 38px;"></div>
          <div class="icon-div" style="top: 99px;"></div>
          <MyChart
            v-if="chartShow"
            :options="getBarOption(statisticsData[tabsKey])"
            :id="'bar-chart1'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewTabs from "@/components/PlatformTab/NewTabs";
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartBar6 from "../../options/chartBar6.js";
import chartPie5 from "../../options/chartPie5.js";
import api from "@/api/conversionMarket.js";
export default {
  name: "TabsContent2",
  components: { NewTabs, NewTabs2, MyChart },
  data() {
    return {
      chartShow: true,
      tabsData: [
        {
          label: "直播流量",
          value: "live",
        },
        {
          label: "视频流量",
          value: "aweme",
        },
      ],
      tabsKey: "live",
      tabData: [
        {
          label: "线索渠道",
          value: "cluesData",
        },
        {
          label: "大区",
          value: "regionData",
        },
      ],
      tabsKey2: "cluesData",
      isArea: true,
      statisticsData: {
        live: {},
        aweme: {},
      },
      overviewData: {
        regionData: [],
        cluesData: [],
      },
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      if (this.$parent.area_id.key) {
        this.isArea = false;
        this.tabsKey2 = "cluesData";
      } else {
        this.isArea = true;
      }
      this.getClueChannel();
    },
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
    },
    tabChange(val) {
      if (this.tabsKey2 == val) {
        return false;
      }
      this.tabsKey2 = val;
    },
    getPieOption(data) {
      let option = deepClone(chartPie5);
      let total = data.reduce((acc, cur) => {
        acc += cur.value;
        return acc;
      }, 0);
      option.title[0].text = total;
      option.series[0].data = data;

      return option;
    },
    getBarOption(data) {
      let option = deepClone(chartBar6);
      let xData = [];
      let yData = [];
      if (this.tabsKey == "live") {
        xData = ["销售线索", "意向评论", "直播流量"];
      } else {
        xData = ["销售线索", "意向评论", "视频流量"];
      }
      yData = data
        ? [data.sales_cue_count, data.intent_comment, data.flow]
        : [null, null, null];
      option.yAxis[0].data = xData;
      option.series[0].data = yData;
      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getClueChannel() {
      let data = this.getParentsParams();
      api.getClueChannel(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          console.log("getClueChannel", data);
          this.statisticsData = data;
          this.overviewData = {
            regionData: (data.region || []).map((item) => {
              return {
                name: item.region_name,
                value: item.sales_cue_count,
              };
            }),
            cluesData: [
              {
                name: "直播组件",
                value: _.get(data, "clues_channel.live_component", 0),
              },
              {
                name: "视频组件",
                value: _.get(data, "clues_channel.video_component", 0),
              },
              {
                name: "私信线索",
                value: _.get(data, "clues_channel.private_letter", 0),
              },
              {
                name: "个人主页",
                value: _.get(data, "clues_channel.homepage", 0),
              },
              // {
              //   name: "行业锚点",
              //   value: _.get(data, "clues_channel.industry_anchor", 0),
              // },
              // {
              //   name: "预约组件",
              //   value: _.get(data, "clues_channel.reservation_component", 0),
              // },
              {
                name: "其他",
                value: _.get(data, "clues_channel.surplus", 0),
              },
            ],
          };
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content2 {
  width: 100%;
  height: 360px;
  .tabs-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    &-left {
      width: 400px;
      height: 100%;
      background: #2b2f3b;
      border-radius: 16px;
      margin-right: 16px;
      padding: 20px;
      .title-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > .title {
          font-size: 14px;
          color: #ffffff;
        }
      }
      .chart-div {
        width: 100%;
        height: 318px;
      }
    }
    &-right {
      width: calc(100% - 416px);
      height: 100%;
      background: #2b2f3b;
      border-radius: 16px;
      padding: 20px;
      .font1 {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.45);
        line-height: 22px;
      }
      .font2 {
        font-size: 24px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 32px;
      }
      .font3 {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
        margin: 0 6px 0 12px;
      }
      .tag {
        width: 40px;
        height: 20px;
        margin-left: 9px;
        > span {
          width: 40px;
          height: 20px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
        }
        .tag1 {
          background: rgba(255, 158, 61, 0.3);
          color: #ff9e3d;
        }
        .tag2 {
          background: rgba(39, 195, 70, 0.3);
          color: #27c346;
        }
      }
      .data-list {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 52px;
          > div {
            display: flex;
            align-items: center;
          }
        }
      }
      .chart-div {
        width: 100%;
        height: 182px;
        position: relative;
        .icon-div {
          width: 23px;
          height: 55px;
          position: absolute;
          top: 0px;
          left: 0;
          border: 1px solid #ffca5c;
          border-right: none;
          border-radius: 6px 0 0 6px;
          &::before {
            width: 5px;
            height: 5px;
            border-radius: 5px;
            display: inline-block;
            content: "";
            position: absolute;
            font-size: 5px;
            right: 0px;
            top: -3px;
            background: #ffca5c;
          }
          &::after {
            width: 5px;
            height: 5px;
            border-radius: 5px;
            display: inline-block;
            content: "";
            position: absolute;
            font-size: 5px;
            right: 0px;
            bottom: -2.5px;
            background: #ffca5c;
          }
        }
      }
    }
  }
}
</style>
