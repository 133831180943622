import filters from '@/filters'
var xData = ['S', 'A', 'B', 'C', 'D']
var lastYearData = [3, 20, 62, 34, 55];
var thisYearData = [11, 38, 23, 39, 66];
var background = "rgba(0,0,0,0)"; //背景 
var option = {
    baseOption: {
        backgroundColor: background,
        timeline: {
            show: false,
            top: 0,
            data: []
        },
        grid: [{
            show: false,
            left: '3%',
            top: 0,
            bottom: 20,
            containLabel: true,
            width: '44%'
        }, {
            show: false,
            left: 'center',
            top: -15,
            bottom: 55,
            width: '0%'
        }, {
            show: false,
            right: '3%',
            top: 0,
            bottom: 20,
            containLabel: true,
            width: '45%'
        }],
        xAxis: [{
            type: 'value',
            inverse: true,
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            position: 'bottom',
            axisLabel: {
                show: true,
                color: 'rgba(255,255,255,0.4)',
                formatter: function (value, index) {
                    return filters.changeNum(value);
                }
            },
            splitLine:{
                 show: false
            },
        }, {
            gridIndex: 1,
            show: false
        }, {
            gridIndex: 2,
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            position: 'bottom',
            axisLabel: {
                show: true,
                color: 'rgba(255,255,255,0.4)',
                formatter: function (value, index) {
                    return filters.changeNum(value);
                }
            },
             splitLine:{
                 show: false
            },
        }],
        yAxis: [{
            type: 'category',
            inverse: true,
            position: 'right',
            axisLine: {
                show: false
            },
           
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            data: xData
        }, {
            gridIndex: 1,
            type: 'category',
            inverse: true,
            position: 'left',
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: true,
                padding:[30,0,0,0],
                textStyle: {
                    color: '#ffffff',
                    fontSize: 20
                },
                align: "center"

            },
            data: xData.map(function(value) {
                return {
                    value: value,
                    textStyle: {
                        align: 'center'
                    }
                }
            })
        }, {
            gridIndex: 2,
            type: 'category',
            inverse: true,
            position: 'left',
            axisLine: {
                 show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false

            },
            data: xData
        }],
        series: []

    },
    options: []
}

option.options.push({
    series: [{
            name: "2017",
            type: "bar",
            barWidth: 25,
            stack: "1",
            itemStyle: {
                normal: {
                    color: '#FAA065',
                    barBorderRadius: [4,0,0,4]
                }
            },
            label: {
                normal: {
                    show: true,
                    position: 'insideRight',
                    textStyle: {
                        color: '#ffffff',
                        fontSize: '14'
                    },
                    formatter: function(params) {
                        return filters.changeNum(params.value)
                    }
                }
            },
            data: [],
            animationEasing: "elasticOut"
        },
        {
            name: "2018",
            type: "bar",
            stack: "2",
            barWidth: 25,
            xAxisIndex: 2,
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    color: '#7A91FF',
                    barBorderRadius: [0,4,4,0]
                }
            },
            label: {
                normal: {
                    show: true,
                    position: 'insideLeft',
                    textStyle: {
                        color: '#ffffff',
                        fontSize: '14'
                    },
                    formatter: function(params) {
                        return filters.changeNum(params.value)
                    }
                }
            },
            data: [],
            animationEasing: "elasticOut"
        },
    ]
})
export default option