<template>
  <div class="component-wrap">
    <a-spin :spinning="officialLoading" class="spin-class" />
    <div class="title-div">
      <div>
        <span class="title">品牌官号</span>
      </div>
      <div @click="routerTo" class="route-div">
        <span class="route">查看更多</span>
        <span class="arrow">
          <svg-icon icon-class="arrow-right" style="font-size: 24px" />
        </span>
      </div>
    </div>
    <div class="info-div" v-if="officialList && officialList.author">
      <WebpImage
        :src="officialList.author.avatar"
        :width="'60px'"
        :height="'60px'"
        :radius="'50%'"
      />
      <div class="info-item">
        <div class="font2">{{ officialList.author.nickname }}</div>
        <div class="font1">
          {{
            $parent.currentTab == "douyin"
              ? "抖音"
              : $parent.currentTab == "kuaishou"
              ? "快手"
              : $parent.currentTab == "dongchedi"
              ? "懂车帝"
              : "平台"
          }}号: {{ officialList.author.code }}
        </div>
      </div>
    </div>
    <div class="ranking-chart" v-if="$parent.currentTab != 'dongchedi'">
      <div class="ranking-chart-title">{{ currentObj.title || "-" }}</div>
      <div class="ranking-chart-main">
        <MyChart
          :options="getCardChartOption(officialTrendData)"
          :id="'ranking-chart2'"
        />
      </div>
    </div>

    <a-table
      v-if="officialList && officialList.author"
      :columns="columns"
      :data-source="officialList.list"
      :rowKey="(data) => data.title"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
      }"
      :pagination="false"
      class="table-style border hide-select"
      :locale="{ emptyText: '暂无数据' }"
    >
      <div slot="customTitle" style="display: flex; padding-right: 20px">
        <div>数据</div>
      </div>
      <div slot="customTitleRanking" style="padding-left: 20px">
        <span>排名</span>
        <AftTooltip :code="'pgpm02'" style="top: 1.8px; left: 4px" />
      </div>
      <div
        slot="ranking"
        slot-scope="scope"
        class="td-style td-left ranking"
        style="margin-right: 120px"
      >
        <span
          style="margin: 0 0px 0 20px"
          :class="scope.ranking <= 3 ? 'bc sp' : 'bc'"
          >{{ scope.ranking }}</span
        >
        <span class="font-color23" v-if="scope.ranking < 1000">{{
          Math.abs(scope.ranking_over_last_month) || ""
        }}</span>
        <svg-icon
          v-if="scope.ranking_over_last_month > 0 && scope.ranking < 1000"
          icon-class="caret-up"
          style="font-size: 10px"
        />
        <svg-icon
          v-if="scope.ranking_over_last_month < 0 && scope.ranking < 1000"
          icon-class="caret-down"
          style="font-size: 10px"
        />
      </div>
      <div slot="name" slot-scope="scope" class="td-style">
        <span class="table-name">{{ scope.title }}</span>
      </div>
      <div slot="number" slot-scope="scope" class="td-style td-right">
        <span class="table-number">{{ scope.parameter | changeNum }}</span>
      </div>
    </a-table>

    <div
      class="title-div title2-div"
      v-if="liveChartData && liveChartData.xData.length > 0"
    >
      <div>
        <span class="title2">最近10场直播</span>
      </div>
    </div>
    <div
      class="chart-div"
      v-if="liveChartData && liveChartData.xData.length > 0"
    >
      <my-chart
        :options="
          getBarOptions(
            liveChartData.xData,
            liveChartData.watch_count,
            liveChartData.interaction_count,
            liveChartData.title
          )
        "
        id="barChart"
      />
    </div>
    <Empty
      style="margin-top: 270px"
      :title="'暂无该平台官号'"
      v-if="!officialList || !officialList.author"
    />
  </div>
</template>

<script>
import * as vuex from "vuex";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import barOptions from "../options/barOptions";
import Tooltip from "@/components/Tooltip/Tooltip";
import Empty from "@/components/Empty/Empty";
import WebpImage from "@/components/WebpImage/WebpImage";
import chartRankingLine from "../options/chartRankingLine.js";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "brandSummary"
);
export default {
  name: "CardOfficial",
  data() {
    return {
      columns: [
        {
          align: "left",
          slots: { title: "customTitleRanking" },
          scopedSlots: { customRender: "ranking" },
        },
        {
          title: "名称",
          align: "left",
          scopedSlots: { customRender: "name" },
        },
        {
          align: "right",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "number" },
        },
      ],
      selectedRowKeys: [],
      currentObj: {},
    };
  },
  components: {
    MyChart,
    Tooltip,
    Empty,
    WebpImage,
  },
  computed: {
    ...mapState({
      officialList: (state) => state.officialList, // 品牌官号排名
      liveChartData: (state) => state.liveChartData, // 直播top10
      officialLoading: (state) => state.officialLoading,
      officialTrendData: (state) => state.officialTrendData,
    }),
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions([
      "getBrandOfficialRanks",
      "getLiveTopTen",
      "getOfficialTrend",
    ]),
    ...mapMutations(["changeState"]),
    async initData() {
      // todoD
      let author_id =
        this.$parent.currentTab == "douyin"
          ? this.$parent.dy_author_id
          : this.$parent.currentTab == "kuaishou"
          ? this.$parent.ks_author_id
          : this.$parent.dcd_author_id;
      if (this.$parent.principal_id && (!author_id || author_id == "null")) {
        this.changeState({ data: null, name: "officialList" });
        this.changeState({ data: null, name: "liveChartData" });
        this.$message.warning(
          `当前品牌未绑定${
            this.$parent.currentTab == "douyin"
              ? "抖音号"
              : this.$parent.currentTab == "kuaishou"
              ? "快手号"
              : "懂车帝"
          }`
        );
        return false;
      }
      let params = {
        platform: this.$parent.currentTab,
        author_id: author_id,
      };
      if (this.$parent.principal_id) {
        params.principal_id = this.$parent.principal_id;
      }
      await this.getBrandOfficialRanks(params);
      this.getLiveTopTen(params);

      if (
        this.officialList &&
        this.officialList.list &&
        this.officialList.list.length > 0
      ) {
        this.selectedRowKeys = [this.officialList.list[0].title];
        this.currentObj = this.officialList.list[0];
        this.getOfficialTrendData();
      }
    },
    getCardChartOption(data) {
      let option = deepClone(chartRankingLine);
      let xData = data.map((item) => {
        return item.month + "月";
      });
      let yData = data.map((item) => {
        return {
          value: item.ranking,
          number: item.value,
        };
      });
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      option.series[0].name = this.currentObj.title;
      return option;
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            let keys = [];
            keys.push(record.title);
            console.log(record, this.selectedRowKeys);
            this.selectedRowKeys = keys;
            this.currentObj = record;
            this.getOfficialTrendData();
          },
        },
      };
    },
    getOfficialTrendData() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          indicators_type: this.currentObj.indicators_type,
        },
      };
      if (this.$parent.principal_id) {
        data.params.principal_id = this.$parent.principal_id;
      }
      this.getOfficialTrend(data);
    },
    //   查看更多跳转
    routerTo() {
      this.$router.push({
        path: "/brand/benchmarking",
      });
    },
    getBarOptions(xData, yData1, yData2, titleData) {
      console.log(yData2);
      if (!yData2 || yData2.length == 0) {
        return false;
      }
      // yData2 = yData2.map((item) => {
      //   return (item * 100).toFixed(0);
      // });
      let option = deepClone(barOptions);
      option.legend.data[1].name = this.filtrate.returnWatchText(
        this.$parent.currentTab
      );
      option.series[1].name = this.filtrate.returnWatchText(
        this.$parent.currentTab
      );
      option.xAxis[0].data = xData;
      option.series[1].data = yData1;
      option.series[0].data = yData2;
      option.tooltip.formatter = (params) => {
        let index = params[0].dataIndex;
        return `<div style='background: #3C4151;padding: 12px;border-radius: 8px;'>
            <div style='color: rgba(255,255,255,0.88);font-size: 12px;line-height: 20px;'>${
              titleData[index]
            }</div>
            <div style='color: rgba(255,255,255,0.45);font-size: 12px;line-height: 20px;'>${
              xData[index]
            }</div>
            <div style='display: flex;align-items: center;color: #fff;font-size: 12px;line-height: 12px;margin: 8px 0 6px;'>
              <span style='width: 4px;height: 4px;margin-right: 5px;border-radius: 50%;background: ${
                params[0].color
              }'></span>
              <span style='margin-right: 6px;'>${params[0].seriesName}:</span>
              <span>${this.$options.filters.changeNum(
                Number(params[0].value)
              )}</span>
            </div>
            <div style='display: flex;align-items: center;color: #fff;font-size: 12px;line-height: 12px;'>
              <span style='width: 4px;height: 4px;margin-right: 5px;border-radius: 50%;background: ${
                params[1].color
              }'></span>
              <span style='margin-right: 6px;'>${params[1].seriesName}:</span>
              <span>${this.$options.filters.changeNum(
                Number(params[1].value)
              )}</span>
            </div>
          </div>`;
      };
      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.component-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 380px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    &.title2-div {
      height: 52px;
      padding-top: 10px;
    }
    .title {
      @include font_color("font_color52");
      font-size: 16px;
    }
    .title2 {
      @include font_color("font_color52");
      font-size: 14px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      @include font_color("font_color28");
      .route {
        font-size: 12px;
        line-height: 24px;
      }
      .arrow {
        font-size: 24px;
      }
    }
  }
  .info-div {
    height: 68px;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #fff;
    }
    .info-item {
      height: 40px;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .font1 {
        @include font_color("font_color56");
        font-size: 12px;
      }
      .font2 {
        @include font_color("font_color52");
        font-size: 14px;
      }
    }
  }
  .ranking-chart {
    width: 100%;
    height: 183px;
    padding: 0 20px;
    &-title {
      height: 22px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
    }
    &-main {
      width: 100%;
      height: 161px;
    }
  }
  .table-style {
    .ranking > span {
      min-width: 24px;
      padding: 0 8px;
      height: 24px;
      border-radius: 12px;
      display: inline-block;
      text-align: center;
      line-height: 24px;
    }
    .bc {
      @include background_color("background_color51");
      @include font_color("font_color22");
    }
    .bc.sp {
      @include background_color("background_color88");
      @include font_color("font_color55");
    }
    .table-name {
      font-size: 14px;
      @include font_color("font_color52");
    }
    .table-number {
      font-size: 14px;
      @include font_color("font_color52");
    }
  }
  .td-style {
    height: 42px;
    display: flex;
    align-items: center;
  }
  .td-left {
    text-align: left;
  }
  .td-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .td-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }
  .font-color21 {
    @include font_color("font_color21");
  }
  .font-color23 {
    @include font_color("font_color23");
  }
  .chart-div {
    width: 100%;
    height: 200px;
  }
}
</style>
