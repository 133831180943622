var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand_rectangle"},[_c('div',{staticClass:"title-div"},[_c('span',{staticClass:"title"},[_vm._v("冠军总览")]),_c('NewTabs2',{ref:"NewTabs2",attrs:{"tabData":_vm.tabsData},on:{"tabChange":_vm.tabChange}})],1),_c('div',{staticClass:"tab-main"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabsKey == '1'),expression:"tabsKey == '1'"}],staticClass:"tabs-item"},[_c('div',{staticClass:"tabs-item-title"},[_c('svg-icon',{staticStyle:{"font-size":"20px"},attrs:{"icon-class":"champion"}}),_c('span',[_vm._v("单场直播冠军")])],1),_c('div',{staticClass:"tabs-item-content"},[_c('div',{staticClass:"img-box",on:{"click":function($event){$event.stopPropagation();return _vm.toDetail(_vm.championsData.live, 'live')}}},[_c('BaseImage',{staticStyle:{"background-color":"#21232c"},attrs:{"src":_vm.championsData.live.cover || '',"default-image":_vm.defaultCoverImg,"fit":"contain","width":"63px","height":"84px","radius":"4px"}}),_c('div',{staticClass:"img-shadow"},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":"play3"}})],1)],1),_c('div',{staticClass:"title-box",attrs:{"title":_vm.championsData.live.title || '-'}},[_c('div',[_vm._v(" "+_vm._s(_vm.championsData.live.title || "-")+" ")]),(
              _vm.championsData &&
              _vm.championsData.live &&
              _vm.championsData.live.defending_champion_count
            )?_c('span',{staticClass:"tag"},[_vm._v("已卫冕"+_vm._s(_vm.championsData.live.defending_champion_count || "-")+"周")]):_vm._e(),(
              _vm.championsData &&
              _vm.championsData.live &&
              _vm.championsData.live.defending_champion_count == 0
            )?_c('span',{staticClass:"tag"},[_vm._v("本周加冕")]):_vm._e()]),_c('div',{staticClass:"data-box"},[_c('span',[_vm._v(_vm._s(_vm._f("returnWatchText")(_vm.$parent.currentTab))+" "),_c('AftTooltip',{staticStyle:{"top":"0px","left":"4px"},attrs:{"code":'qjzb11'}})],1),_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(_vm.championsData.live.watch_count)))])])]),_c('div',{staticClass:"tabs-item-bottom"},[_c('div',[_c('span',{staticClass:"fc1"},[_vm._v("主播账号")]),_c('span',{staticClass:"fc2 ml12"},[_vm._v(_vm._s(_vm.championsData.live.nickname || "-"))])]),_c('div',[_c('span',{staticClass:"fc1"},[_vm._v("开播日期")]),_c('span',{staticClass:"fc3 ml12"},[_vm._v(_vm._s(_vm.championsData.live.publish_time || "-"))])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabsKey == '2'),expression:"tabsKey == '2'"}],staticClass:"tabs-item"},[_c('div',{staticClass:"tabs-item-title"},[_c('svg-icon',{staticStyle:{"font-size":"20px"},attrs:{"icon-class":"champion"}}),_c('span',[_vm._v("视频播放冠军")])],1),_c('div',{staticClass:"tabs-item-content"},[_c('div',{staticClass:"img-box",on:{"click":function($event){$event.stopPropagation();return _vm.toDetail(_vm.championsData.aweme, 'aweme')}}},[_c('BaseImage',{staticStyle:{"background-color":"#21232c"},attrs:{"src":_vm.championsData.aweme.aweme_cover || '',"default-image":_vm.defaultCoverImg,"fit":"contain","width":"63px","height":"84px","radius":"4px"}}),_c('div',{staticClass:"img-shadow"},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":"play3"}})],1)],1),_c('div',{staticClass:"title-box",attrs:{"title":_vm.championsData.aweme.title || '-'}},[_c('div',[_vm._v(" "+_vm._s(_vm.championsData.aweme.title || "-")+" ")]),(
              _vm.championsData &&
              _vm.championsData.aweme &&
              _vm.championsData.aweme.defending_champion_count
            )?_c('span',{staticClass:"tag"},[_vm._v("已卫冕"+_vm._s(_vm.championsData.aweme.defending_champion_count || "-")+"周")]):_vm._e(),(
              _vm.championsData &&
              _vm.championsData.aweme &&
              _vm.championsData.aweme.defending_champion_count == 0
            )?_c('span',{staticClass:"tag"},[_vm._v("本周加冕")]):_vm._e()]),_c('div',{staticClass:"data-box"},[_c('span',[_vm._v("播放量"),_c('AftTooltip',{staticStyle:{"top":"0px","left":"4px"},attrs:{"code":'pgsp01'}})],1),_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(_vm.championsData.aweme.play_volume_count)))])])]),_c('div',{staticClass:"tabs-item-bottom"},[_c('div',[_c('span',{staticClass:"fc1"},[_vm._v("主播账号")]),_c('span',{staticClass:"fc2 ml12"},[_vm._v(_vm._s(_vm.championsData.aweme.nickname || "-"))])]),_c('div',[_c('span',{staticClass:"fc1"},[_vm._v("发布日期")]),_c('span',{staticClass:"fc3 ml12"},[_vm._v(_vm._s(_vm.championsData.aweme.publish_time || "-"))])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabsKey == '3'),expression:"tabsKey == '3'"}],staticClass:"tabs-item"},[_c('div',{staticClass:"tabs-item-title"},[_c('svg-icon',{staticStyle:{"font-size":"20px"},attrs:{"icon-class":"champion"}}),_c('span',[_vm._v("营销价值冠军")])],1),_c('div',{staticClass:"tabs-item-content"},[_c('div',{staticClass:"img-box",staticStyle:{"height":"63px"}},[_c('BaseImage',{attrs:{"src":_vm.championsData.market_value.avatar || '',"default-image":_vm.defaultAvatarImg,"fit":"cover","width":"63px","height":"63px","radius":"4px"}})],1),_c('div',{staticClass:"title-box",attrs:{"title":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.championsData.market_value.nickname || "-")+" ")]),(
              _vm.championsData &&
              _vm.championsData.market_value &&
              _vm.championsData.market_value.defending_champion_count
            )?_c('span',{staticClass:"tag"},[_vm._v("已卫冕"+_vm._s(_vm.championsData.market_value.defending_champion_count || "-")+"周")]):_vm._e(),(
              _vm.championsData &&
              _vm.championsData.market_value &&
              _vm.championsData.market_value.defending_champion_count == 0
            )?_c('span',{staticClass:"tag"},[_vm._v("今日加冕")]):_vm._e()]),_c('div',{staticClass:"data-box"},[_c('span',[_vm._v("营销价值"),_c('AftTooltip',{staticStyle:{"top":"0px","left":"4px"},attrs:{"code":'pgqt03'}})],1),_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(_vm.championsData.market_value.market_value)))])])]),_c('div',{staticClass:"tabs-item-bottom"},[_c('div',[_c('span',{staticClass:"fc1"},[_vm._v("账号等级")]),_c('span',{staticClass:"fc3 ml12"},[_vm._v(_vm._s(_vm.championsData.market_value.level || "-"))])]),_c('div',[_c('span',{staticClass:"fc1"},[_vm._v("直播&视频")]),_c('span',{staticClass:"fc3 ml12"},[_vm._v(_vm._s(_vm.championsData.market_value.aweme_live_count || "-"))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }