<template>
  <div class="table-item1">
    <div :class="['card-title', active ? 'active' : '']">
      {{ list.title || "-" }}
    </div>
    <div class="table-header" v-if="list.data && list.data.length">
      <div class="table-header-col">排名</div>
      <div class="table-header-col">门店名称/大区</div>
      <div class="table-header-col">{{ list.targetName || "-" }}</div>
    </div>
    <div class="table-body" v-if="list.data && list.data.length">
      <div
        class="table-body-item"
        v-for="(item, index) in returnList(list.data)"
        :key="index"
      >
        <div class="table-body-item-col">
          <span :class="['ranking', `ranking${index + 1}`]">{{
            index + 1
          }}</span>
        </div>
        <div class="table-body-item-col">
          <BaseImage
            :src="item.dealerAuthorAvatar || ''"
            :default-image="defaultAvatarImg"
            width="48px"
            height="48px"
            fit="cover"
            round
          />
          <div class="info-right">
            <span>{{ item.dealerName || "-" }}</span>
            <span>{{ item.regionName || "-" }}</span>
          </div>
        </div>
        <div class="table-body-item-col">
          {{ item[list.targetKey] | changeNumNoRound }}
        </div>
      </div>
    </div>
    <div class="empty-box" v-else>
      <Empty title="暂无数据" />
    </div>
  </div>
</template>

<script>
import defaultAvatarImg from "@/assets/default/default1-1.png";
import BaseImage from "@/components/BaseImage";
import Empty from "@/components/Empty/Empty";

export default {
  name: "TableItem1",
  components: {
    BaseImage,
    Empty,
  },
  props: {
    list: {
      type: Object,
      default: () => {
        return {};
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultAvatarImg
    };
  },
  computed: {},
  watch: {},
  methods: {
    returnList(data) {
      if (data && data.length) {
        return data.slice(0, 3);
      } else {
        return [];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.table-item1 {
  width: 100%;
  height: 314px;
  padding-right: 7px;
  cursor: pointer;
  .card-title {
    width: 100%;
    height: 54px;
    background: #242832;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.88);
    line-height: 54px;
    padding-left: 20px;
    border-radius: 16px 16px 0 0;
    &.active {
      color: #ffcc55;
      background: #413e3d;
      position: relative;
      &::before {
        content: "";
        height: 0px;
        width: 0px;
        content: "";
        position: absolute;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #413e3d;
        border-right: 7px solid transparent;
        right: -13px;
        top: 20px;
      }
    }
  }
  .table-header {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.55);
    background: #2b2f3b;
    > div:nth-child(1) {
      width: 60px;
      text-align: center;
    }
    > div:nth-child(2) {
      width: calc(100% - 140px);
      text-align: left;
      padding-left: 6px;
    }
    > div:nth-child(3) {
      width: 80px;
      text-align: right;
      padding-right: 20px;
    }
  }
  .table-body {
    width: 100%;
    height: 216px;
    background: #2b2f3b;
    border-radius: 0 0 16px 16px;
    &-item {
      height: 72px;
      width: 100%;
      border-bottom: 1px solid #3c4252;
      display: flex;
      align-items: center;
      &:last-child {
        border: none;
      }
      &-col:nth-child(1) {
        width: 60px;
        text-align: center;
      }
      &-col:nth-child(2) {
        width: calc(100% - 140px);
        display: flex;
        align-items: center;
        .info-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 8px;
          width: calc(100% - 56px);
          > span:first-child {
            width: 100%;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.88);
            line-height: 22px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
          > span:last-child {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.55);
            line-height: 20px;
            margin-top: 4px;
          }
        }
      }
      &-col:nth-child(3) {
        width: 80px;
        text-align: right;
        padding-right: 20px;
        font-size: 14px;
        font-weight: bold;
        color: #ffcc55;
      }
    }
  }
  .empty-box {
    background: #2b2f3b;
    height: 260px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 16px 16px;
  }
}
.ranking {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.12);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  &.ranking1,
  &.ranking2,
  &.ranking3 {
    background: rgba(255, 204, 85, 0.12);
    color: #ffcc55;
    font-weight: bold;
  }
}
</style>
