var option = {
  legend: {
      show: true,
      top: '3%',
      textStyle: {
          color: '#fff'
      },
      data:[]
  },
  grid: {  
    top: '20%',
    left: '1%',  
    right: '5%',  
    bottom: '5%',  
    containLabel: true  
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisLabel: {
      show: true,
        textStyle: {
          color: '#959595',  //更改坐标轴文字颜色
          fontSize : 10      //更改坐标轴文字大小
        }
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#fff',
      }
    },
    data: [],
  },
  yAxis: {
    type: "value",
    splitNumber: 4, 
    axisTick: {
      inside: false,
    },
    axisLabel: {
      show: true,
        textStyle: {
          color: '#959595',  //更改坐标轴文字颜色
          fontSize : 10      //更改坐标轴文字大小
        }
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
      },
    },
  },
  tooltip:{
    trigger: 'axis',
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
        color: '#fff',
    },
  },
  series: []
}

  export default option
