<template>
  <div class="container">
    <platform-header title="车型线索统计" :showPlatform="false" />
    <div class="content">
      <div class="iframe-div" v-if="iframeSrc">
        <iframe
          :src="iframeSrc"
          frameborder="0"
          width="100%"
          height="100%"
          allowtransparency
        ></iframe>
      </div>
      <div class="empty-div" v-else>
        <Empty />
      </div>
    </div>
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Empty from "@/components/Empty/Empty";
import _ from "lodash";
export default {
  components: {
    PlatformHeader,
    Empty
  },
  data() {
    return {
      iframeSrc:
        "https://metabase.afanticar.com/public/question/b7f82c2a-d4ba-4034-b648-761eb1e42f48#theme=night&bordered=false&titled=false"
    };
  },
  created() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .content {
    border-radius: 16px;
    width: calc(100% - 40px);
    margin-left: 20px;
    height: calc(100vh - 164px);
    overflow-y: hidden;
    @include background_color("background_color50");
    > div {
      width: 100%;
      height: 100%;
    }
    .empty-div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>