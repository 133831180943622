<template>
  <div
    style="width: 100%; height: 100%; background-color: #150b42"
    class="box_live"
  >
    <div id="box">
      <div class="body">
        <div class="header">
          <div>
            <span class="title">
              {{ currentActivityItem.activityName || "-" }}</span
            >
            <span class="switch-button" @click="switchActivity">
              <span>切换活动</span>
              <svg-icon icon-class="arrow-down" class="arrow-icon" />
            </span>
          </div>
          <PlatformHeaderScreen
            title=""
            :tabData="tabData"
            @tabChange="tabChange"
          />
        </div>
        <div class="date-box">
          活动日期：{{ currentActivityItem.startTime }} 至
          {{ currentActivityItem.endTime }}
        </div>
        <div class="content">
          <div class="content-left">
            <div class="data-box">
              <BlurCard />
              <div class="data-box-container">
                <div class="data-box-container-time">
                  <span>{{ isStart ? "已累计作战" : "距离活动开始" }}</span>
                  <div>
                    <div class="time-item" v-if="totalTime.days">
                      <span>{{ totalTime.days }}</span>
                      <span>天</span>
                    </div>
                    <div class="time-item">
                      <span>{{ totalTime.hours }}</span>
                      <span>小时</span>
                    </div>
                    <div class="time-item">
                      <span>{{ totalTime.minutes }}</span>
                      <span>分钟</span>
                    </div>
                    <div class="time-item">
                      <span>{{ totalTime.seconds }}</span>
                      <span>秒</span>
                    </div>
                  </div>
                </div>
                <div class="card-list">
                  <ActivityCard
                    :title="'应参门店'"
                    code="hdzb02"
                    :background="
                      'linear-gradient(180deg, rgba(255, 202, 92, 0.90) 0%, rgba(255, 202, 92, 0.10) 100%)'
                    "
                    >{{
                      statisticsData.totalStore | changeNumNoRound
                    }}</ActivityCard
                  >
                  <ActivityCard :title="'当前在播'">{{
                    liveList.liveSize | changeNumNoRound
                  }}</ActivityCard>
                  <ActivityCard :title="'今日累计观看'" :code="'hdzb01'">{{
                    statisticsData.dailyWatch | changeNumNoRound
                  }}</ActivityCard>
                  <ActivityCard :title="'累计开播场次'">{{
                    statisticsData.totalShow | changeNumNoRound
                  }}</ActivityCard>
                  <ActivityCard :title="'活动累计观看'">{{
                    statisticsData.totalWatch | changeNumNoRound
                  }}</ActivityCard>
                  <ActivityCard :title="'累计开播时长'">{{
                    Math.abs(statisticsData.totalTime) | disposeLongTime_h2
                  }}</ActivityCard>
                </div>
              </div>
            </div>
            <div class="live-container">
              <div class="live-container-title">
                <span>直播实况</span>
                <div class="refresh-button" @click="setVideoUrl(false)">
                  <span>换一批</span>
                  <svg-icon icon-class="refresh" class="refresh-icon" />
                </div>
              </div>
              <div class="live-container-main">
                <div class="live-list">
                  <BlurCard />
                  <div class="live-list-container">
                    <div class="live-list-container-header">
                      <div class="title">
                        <div>
                          <svg-icon
                            icon-class="activity-live"
                            class="live-icon"
                          />
                          <span>直播列表</span>

                          <AftTooltip
                            :code="'qjzb02'"
                            style="top: -1px; left: 4px"
                          />
                        </div>
                        <svg-icon
                          icon-class="refresh"
                          class="refresh-icon"
                          @click="getLiveList(false)"
                        />
                      </div>
                      <div
                        :class="
                          currentVideos.left == liveList.officer
                            ? 'official-item active'
                            : 'official-item'
                        "
                        @click="currentVideos.left = liveList.officer"
                        v-if="
                          liveList.officer && liveList.officer.authorNickname
                        "
                      >
                        <WebpImage
                          :src="liveList.officer.authorAvatar"
                          :width="'40px'"
                          :height="'40px'"
                          :radius="'10px'"
                          class="mr-12"
                        />
                        <span class="official-item-title">{{
                          liveList.officer.authorNickname
                        }}</span>
                        <svg-icon icon-class="live3" class="live-icon" />
                        <svg-icon icon-class="eye" class="eye-icon" />
                        <span class="number">{{
                          liveList.officer.roomWatchCount | changeNumNoRound
                        }}</span>
                      </div>
                      <div
                        v-else
                        style="
                          line-height: 40px;
                          padding-left: 20px;
                          color: #fff;
                        "
                      >
                        官号暂未直播
                      </div>
                    </div>
                    <div class="live-list-container-main">
                      <div
                        :class="
                          currentVideos.left == item
                            ? 'main-item active'
                            : 'main-item'
                        "
                        v-for="(item, index) in liveList.list"
                        :key="index"
                        @click="changeLeftVideo(item)"
                      >
                        <WebpImage
                          :src="item.authorAvatar"
                          :width="'26px'"
                          :height="'26px'"
                          :radius="'50%'"
                          class="mr-12"
                        />
                        <span class="main-item-title">{{
                          item.dealerName
                        }}</span>
                        <svg-icon icon-class="live3" class="live-icon" />
                        <svg-icon icon-class="eye" class="eye-icon" />
                        <span class="number">{{
                          item.roomWatchCount | changeNumNoRound
                        }}</span>
                      </div>

                      <Empty
                        v-if="!liveList.list || liveList.list.length == 0"
                        :image="require('@/assets/img/empty2.png')"
                        :title="'暂无直播'"
                        class="empty"
                        :height="'82px'"
                      />
                    </div>
                  </div>
                </div>
                <div class="live-video-left">
                  <BlurCard />
                  <div class="live-video-left-container">
                    <PlayerItem
                      :id="'official'"
                      v-if="
                        currentVideos.left.playUrlFlv264 ||
                          currentVideos.left.playUrlFlv265
                      "
                      :h264url="returnNewUrl(currentVideos.left.playUrlFlv264)"
                      :h265url="returnNewUrl(currentVideos.left.playUrlFlv265)"
                      :isShowTooltip="false"
                      :isAutoPlay="false"
                      :poster="currentVideos.left.cover"
                    />
                    <!-- <FlvVideo
                      :options="currentVideos.left"
                      :platform="platform"
                      v-if="
                        currentVideos.left &&
                          currentVideos.left.playUrlFlv264Old
                      "
                      :id="'official'"
                    /> -->
                  </div>
                  <div class="live-video-left-bottom" v-if="currentVideos.left">
                    <div class="title">
                      {{ currentVideos.left.title }}
                    </div>
                    <div class="info">
                      <div>
                        <WebpImage
                          :src="currentVideos.left.authorAvatar"
                          :width="'26px'"
                          :height="'26px'"
                          :radius="'50%'"
                          class="mr-12"
                        />
                        <span>{{
                          currentVideos.left.dealerName ||
                            currentVideos.left.authorNickname
                        }}</span>
                      </div>
                      <div>
                        <svg-icon icon-class="eye" class="eye-icon" />

                        <span>{{
                          currentVideos.left.roomWatchCount | changeNumNoRound
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <Empty
                    v-if="
                      !currentVideos.left || !currentVideos.left.authorNickname
                    "
                    :image="require('@/assets/img/empty2.png')"
                    :title="'暂无直播'"
                    class="empty"
                    style="margin-top: 243px"
                    :height="'82px'"
                  />
                </div>
                <div class="live-video-right">
                  <div
                    class="video-item"
                    v-for="(item, index) in currentVideos.right"
                    :key="index"
                  >
                    <BlurCard />
                    <div class="video-item-container">
                      <PlayerItem
                        :id="'flv' + index"
                        v-if="item.playUrlFlv264 || item.playUrlFlv265"
                        :h264url="returnNewUrl(item.playUrlFlv264)"
                        :h265url="returnNewUrl(item.playUrlFlv265)"
                        :isShowTooltip="false"
                        :isAutoPlay="false"
                        :poster="item.cover"
                      />
                      <!-- <FlvVideo
                        :options="item"
                        :platform="platform"
                        :id="'flv' + index"
                        v-if="item && item.dealerName"
                      /> -->
                    </div>
                    <div
                      class="video-item-bottom"
                      v-if="item && item.dealerName"
                    >
                      <div class="title">
                        {{ item.title || "-" }}
                      </div>
                      <div class="info">
                        <div>
                          <WebpImage
                            :src="item.authorAvatar"
                            :width="'14px'"
                            :height="'14px'"
                            :radius="'50%'"
                            class="mr-6"
                          />
                          <span>{{ item.dealerName || "-" }}</span>
                        </div>
                        <div>
                          <svg-icon icon-class="eye" class="eye-icon" />
                          <span>{{
                            item.roomWatchCount | changeNumNoRound
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <Empty
                      v-else
                      :image="require('@/assets/img/empty2.png')"
                      :title="'暂无直播'"
                      class="empty mt-97"
                      :height="'82px'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-right">
            <BlurCard />
            <div class="content-right-container">
              <div class="content-right-container-title">
                <span>声量线索实时分析中台</span>
                <AftTooltip :code="'hdqt01'" style="top: 0px; left: 6px" />
              </div>
              <div class="content-right-container-card">
                <ActivityCard :size="'small'" :title="'车系相关'">{{
                  this.clueData.series | changeNumNoRound
                }}</ActivityCard>
                <ActivityCard :size="'small'" :title="'价格相关'">{{
                  this.clueData.price | changeNumNoRound
                }}</ActivityCard>
                <ActivityCard :size="'small'" :title="'配置相关'">{{
                  this.clueData.setting | changeNumNoRound
                }}</ActivityCard>
                <ActivityCard :size="'small'" :title="'品牌相关'">{{
                  this.clueData.brand | changeNumNoRound
                }}</ActivityCard>
                <ActivityCard :size="'small'" :title="'线索相关'">{{
                  this.clueData.clue | changeNumNoRound
                }}</ActivityCard>
              </div>
              <div class="content-right-container-series">
                <div
                  class="content-right-container-series-card"
                  v-for="(item, index) in seriesData"
                  :key="index"
                >
                  <img
                    :src="item.image ? ossHost + item.image : defaultSeries"
                    alt=""
                  />
                  <span class="name">{{ item.series }}</span>
                  <span class="number">{{ item.count }}</span>
                </div>
                <div
                  style="
                    width: 100%;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.6);
                  "
                  v-if="!seriesData || seriesData.length == 0"
                >
                  暂无数据
                </div>
              </div>
              <div class="content-right-container-comment">
                <transition-group name="list-complete" tag="p">
                  <div
                    class="comment-list list-complete-item"
                    v-for="item in commentList"
                    :key="item.msg_id"
                  >
                    <div
                      class="comment-list-series"
                      v-if="
                        item.series_detail_tags &&
                          item.series_detail_tags.length > 0
                      "
                    >
                      <div class="series-container">
                        <span>提及车系:</span>
                        <div>
                          <div
                            class="series-item"
                            v-for="(item, index) in item.series_detail_tags"
                            :key="index"
                          >
                            <img
                              :src="
                                item.image
                                  ? ossHost + item.image
                                  : defaultSeries
                              "
                              alt=""
                            />
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>{{ item.series }}</span>
                              </template>
                              <span>{{ item.series }}</span>
                            </a-tooltip>
                          </div>
                        </div>
                      </div>
                      <img src="@/assets/img/mention.png" alt="" />
                    </div>
                    <div class="comment-list-top">
                      <img :src="item.user.avatar" alt="" />
                      <div>
                        <span>{{ item.user.nickname }}</span>
                        <span>{{ formatTime2(item.publish_time) }}</span>
                      </div>
                    </div>
                    <div class="comment-list-main">{{ item.content }}</div>
                    <div
                      class="comment-list-tag"
                      v-if="item.comment_tags && item.comment_tags.length > 0"
                    >
                      <span
                        v-for="(item2, index2) in item.comment_tags"
                        :key="index2"
                        >{{ item2 }}</span
                      >
                    </div>
                  </div>
                </transition-group>
              </div>
              <!-- <Empty
                :image="require('@/assets/img/empty2.png')"
                :title="'暂无消息'"
                class="empty"
                style="margin-top: 300px"
                :height="'119px'"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <SwitchActivity
      :currentItem="currentActivityItem"
      @changeActivity="changeActivity"
      :activityStatus="'2'"
      ref="SwitchActivity"
    />
  </div>
</template>
<script>
import Paho from "@/utils/mqttws31.min.js";
import MqttRemoval from "@/utils/mqttRemoval";
import BlurCard from "@/components/card/BlurCard2";
import ActivityCard from "./components/activityCard";
import SvgIcon from "../../components/SvgIcon/SvgIcon.vue";
import WebpImage from "../../components/WebpImage/WebpImage.vue";
import api from "@/api/activityScreenN.js";
import { getPrincipalId, getUserId } from "@/utils/auth";
import FlvVideo from "@/components/FlvVideo/FlvVideo";
import Tooltip from "@/components/Tooltip/Tooltip";
import Empty from "@/components/Empty/Empty";
import "videojs-contrib-hls";
import PlayerItem from "@/components/PlayerItem/PlayerItem";
import PlatformHeaderScreen from "@/components/PlatformTab/PlatformHeaderScreen";
import SwitchActivity from "@/pages/marketing/components/SwitchActivity";
export default {
  components: {
    BlurCard,
    ActivityCard,
    WebpImage,
    SvgIcon,
    FlvVideo,
    Tooltip,
    Empty,
    PlayerItem,
    PlatformHeaderScreen,
    SwitchActivity,
  },
  data() {
    return {
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
      ],
      currentTab: "douyin",
      currentActivityItem: {
        startTime: "",
        endTime: "",
        activityName: "-",
      },
      ossHost: process.env.VUE_APP_OSS_LIVE_HOST + "/",
      defaultSeries: require("../../assets/default/default-series.png"),
      tooltipTitle: "统计今天开播直播的线索评论，有一定时间延时",
      activityId: "",
      platform: "",
      activityDetail: {}, // 活动详情
      statisticsData: {}, // 头部信息
      screenWidth: 1920,
      screenHeight: 1080,
      totalTime: {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
      },
      isStart: false, // 活动是否开始
      totalTimer: null, // 累计作战时长定时器
      autoLiveTimer: null, // 直播列表切换定时器
      autoStatisticsTimer: null, // 顶部信息刷新定时器
      addMonitorTimer: null, // 添加监控接口定时器
      client: null, // 创建一个客户端实例
      timerWl: null,
      timerHl: null,
      removal: null,
      // 记录直播列表数据
      liveList: {
        officer: {},
        list: [],
      },
      // 当前展示视频数据
      currentVideos: {
        left: {},
        right: [],
      },
      mqttConfigData: null, // mqtt配置信息
      // 右上角的线索数据
      clueData: {
        brand: null,
        clue: null,
        price: null,
        series: null,
        setting: null,
      },
      seriesData: [], // 车系
      commentList: [], // 评论列表
      temporaryComment: [], // 临时存储评论列表
      commentBool: true, // 控制1s内添加评论dom频率
    };
  },
  created() {
    this.currentActivityItem = {
      id: this.$route.query.aId,
      startTime: this.$route.query.startTime,
      endTime: this.$route.query.endTime,
      activityName: this.$route.query.aName,
    };
    this.initData();
    this.track("切换活动");
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timerWl) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timerWl = true;
        let that = this;
        let screen_timer = setTimeout(function() {
          document.getElementById(
            "box"
          ).style.transform = `scale(${that.screenWidth / 1920})`;
          document.getElementById("box").style.height =
            that.screenHeight / (that.screenWidth / 1920) + "px";
          clearTimeout(screen_timer);
          that.timerWl = false;
        }, 400);
      }
    },
    screenHeight() {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timerHl) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.timerHl = true;
        let that = this;
        let screen_timer = setTimeout(function() {
          document.getElementById("box").style.height =
            that.screenHeight / (that.screenWidth / 1920) + "px";
          clearTimeout(screen_timer);
          that.timerHl = false;
        }, 400);
      }
    },
    // 控制评论最大数值，满100减50
    commentList(val) {
      if (val.length > 100) {
        this.commentList = this.commentList.slice(0, 50);
      }
    },
  },
  mounted() {
    // let clear = setTimeout(() => {
    //   this.showLoading = false;
    //   clearTimeout(clear);
    // }, 1500);
    // 监听屏幕变化
    this.resizeWidth();
    window.addEventListener(
      "resize",
      () => {
        this.resizeWidth();
      },
      false
    );
  },
  methods: {
    // 默认选择活动埋点
    track(action) {
      const trackData = {
        action,
        default: 1,
        activityId: this.currentActivityItem.id,
        activityName: this.currentActivityItem.activityName,
      };
      this.$trackRequest(this.$route, this.$trackEvent.CLICK, trackData);
    },
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      this.initData();
    },
    // 切换活动点击
    switchActivity() {
      this.$refs.SwitchActivity.showDrawer();
    },
    // 切换活动触发
    async changeActivity(item) {
      this.currentActivityItem = item;
      this.initData();
    },
    // 获取到id后加载全部接口
    initData() {
      // 清空评论列表
      this.clueData = {
        brand: null,
        clue: null,
        price: null,
        series: null,
        setting: null,
      };
      this.commentList = [];
      this.seriesData = [];
      // 添加直播监控
      this.addRoomListener();
      // 获取mqtt配置信息
      this.getMqttSetting();
      // 获取历史数据
      this.getClueHistory();

      // 继续调用头部信息和直播列表
      this.getTopStatistics();
      this.getLiveList(true);

      // 启动累计时长定时器
      this.getTotalTime();
    },
    // 获取头部信息
    getTopStatistics() {
      this.setStatisticsTimer();
      let params = {
        activityId: this.currentActivityItem.id,
        platform: this.currentTab == "douyin" ? "1" : "2",
      };
      api.getTopStatistics(params).then((res) => {
        if (res.data.code === 200) {
          this.statisticsData = res.data.data;
        } else {
          this.$message.warning("获取统计信息失败");
        }
      });
    },
    // 获取直播列表
    getLiveList(refresh) {
      this.setLiveTimer();
      let params = {
        activityId: this.currentActivityItem.id,
        platform: this.currentTab == "douyin" ? "1" : "2",
      };
      api.getLiveList(params).then((res) => {
        if (res.data.code === 200) {
          this.liveList = res.data.data;
          if (refresh) {
            console.log("refresh", refresh);
            this.setVideoUrl(true);
          }
          console.log(this.currentVideos);
        } else {
          this.liveList = {
            officer: null,
            list: null,
          };
          this.setVideoUrl(false);
          this.$message.warning("获取直播列表失败");
        }
      });
    },
    // 获取声量线索历史接口
    getClueHistory() {
      let data = {
        params: {
          platform_type: this.getPlatformType(),
          activity_id: this.currentActivityItem.id,
        },
        platform: "mqtt",
      };
      api.getHistoryData(data).then((res) => {
        console.log(res.data);
        if (res.data.code === 0) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.forEach((item) => {
              this.onMessageArrived(item);
            });
          }
        } else {
          this.$message.warning("暂无数据", 1);
        }
      });
    },
    // 获取mqtt配置接口
    getMqttSetting() {
      let data = {
        params: {
          platform_type: this.getPlatformType(),
          activity_id: this.currentActivityItem.id,
          device_id: getUserId(),
        },
        platform: "mqtt",
      };
      api.getClientSetting(data).then((res) => {
        console.log(res.data);
        if (res.data.code === 0) {
          this.mqttConfigData = res.data.data;
          this.removal = new MqttRemoval();
          this.MQTTconnect();
        } else {
          this.$message.warning("暂无数据", 1);
        }
      });
    },
    // 添加监听接口
    addRoomListener() {
      this.setAddMonitorTimer();
      let data = {
        data: {
          platform_type: this.getPlatformType(),
          activity_id: this.currentActivityItem.id,
        },
        platform: "mqtt",
      };
      api.addMonitorActivity(data).then((res) => {
        console.log(res.data);
        if (res.data.code === 0) {
        } else {
          this.$message.warning("暂无数据", 1);
        }
      });
    },
    // 平台转化数字
    getPlatformType() {
      if (this.currentTab == "douyin") {
        return 1;
      } else if (this.currentTab == "kuaishou") {
        return 2;
      } else {
        this.$message.warning("暂未开放当前平台");
        return false;
      }
    },
    // 添加评论dom
    setComment(msg) {
      console.log("this.temporaryComment", this.temporaryComment);
      this.temporaryComment.unshift(msg);
      if (this.commentBool) {
        console.log("concatComment");
        this.commentList = this.temporaryComment.concat(this.commentList);
        this.temporaryComment = [];
        this.commentBool = false;
        let cTimer = setTimeout(() => {
          console.log("this.commentBool => true");
          this.commentBool = true;
          clearTimeout(cTimer);
        }, 1000);
      }
    },
    changeLeftVideo(item) {
      this.currentVideos.left = {};
      setTimeout(() => {
        this.currentVideos.left = item;
      }, 500);
    },
    // 换一批视频
    setVideoUrl(isFirst) {
      console.log("liveList", this.liveList);
      this.currentVideos = {
        left: {},
        right: [{}, {}, {}, {}],
      };
      setTimeout(() => {
        // isFirst只有初始化或定时刷新左侧直播列表时触发
        if (isFirst) {
          if (this.liveList.officer) {
            this.currentVideos.left = this.liveList.officer;
            this.currentVideos.right = this.liveList.list.slice(0, 4);
          } else if (!this.liveList.list) {
            this.currentVideos.left = {};
            this.currentVideos.right = [];
          } else if (this.liveList.list.length == 1) {
            this.currentVideos.left = this.liveList.list[0];
            this.currentVideos.right = [];
          } else if (this.liveList.list.length > 1) {
            this.currentVideos.left = this.liveList.list[0];
            this.currentVideos.right = this.liveList.list.slice(1, 5);
          }
        } else {
          if (this.liveList.officer) {
            this.currentVideos.left = this.liveList.officer;
            this.currentVideos.right = this.randomNum(this.liveList.list, 4);
          } else if (!this.liveList.list) {
            this.currentVideos.left = {};
            this.currentVideos.right = [];
          } else if (this.liveList.list.length == 1) {
            this.currentVideos.left = this.liveList.list[0];
            this.currentVideos.right = [];
          } else if (this.liveList.list.length > 1) {
            let newData = this.randomNum(this.liveList.list.slice(1), 4);
            this.currentVideos.left = this.liveList.list[0];
            this.currentVideos.right = newData;
          }
        }
        this.pushObj(this.currentVideos.right);
        console.log("currentVideos", this.currentVideos);
      }, 500);
    },
    // 填满视频位置
    pushObj(arr) {
      if (arr.length < 4) {
        arr.push({});
        this.pushObj(arr);
      }
    },
    // 格式化时间
    formatTime(time) {
      return moment(time).format("YYYY年MM月DD日");
    },
    // 格式化时间
    formatTime2(time) {
      return moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
    // 累计时间计算
    getTotalTime() {
      this.totalTimer = setInterval(() => {
        let t1 = moment(this.currentActivityItem.startTime);
        let t2 = moment();
        let dura = t2.format("x") - t1.format("x");
        this.isStart = dura > 0;
        dura = Math.abs(dura);
        let tempTime = moment.duration(dura);
        this.totalTime = {
          days: Math.floor(tempTime.asDays()),
          hours: tempTime.hours(),
          minutes: tempTime.minutes(),
          seconds: tempTime.seconds(),
        };
        // 当前时间大于结束时间清空定时器
        if (
          Number(moment().format("x")) >=
          Number(moment(this.currentActivityItem.endTime).format("x"))
        ) {
          console.log("jiehus", this.activityDetail);
          clearInterval(this.totalTimer); // 清除定时器
          this.totalTimer = null;
        }
      }, 1000);
    },
    // 屏幕变化设置比
    resizeWidth() {
      this.screenWidth = document.body.clientWidth;
      this.screenHeight = document.body.clientHeight;
    },

    returnNewUrl(url) {
      if (url) {
        try {
          let newUrl = url.replace(/^http/, "https");
          return newUrl;
        } catch (err) {
          // this.$message.warning(err);
        }
      } else {
        // this.$message.warning("未获取到当前直播地址");
      }
    },
    // 创建mqtt实例，并连接
    MQTTconnect() {
      console.log("this.client", this.client);
      this.client && this.clientDestory();
      console.log("this.client", this.client);
      this.client = new Paho.MQTT.Client(
        this.mqttConfigData.endPoint,
        443,
        this.mqttConfigData.clientId
      );
      this.client.connect({
        userName: this.mqttConfigData.userName, //连接账号
        password: this.mqttConfigData.password, //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: (message) => {
          setTimeout(this.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4,
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },

    //当客户端连接
    onConnect() {
      console.log("onConnect");
      //建立连接后，进行订阅并发送消息。
      this.client.subscribe(
        // todo
        // "afanti_live_dev/bulletAndStatistics/1/1001640782113112064_1003034671594102784",
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic,
        { qos: 0 }
      );
    },

    // 当客户端失去连接时调用
    onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    },
    /**当消息到达时
    /* type 2101:线索数据，2102:车系相关，3001：下播，1101：评论
    / * 抖音无法获取礼物数**/
    onMessageArrived(message) {
      let newJson = {};
      if (message.payloadString) {
        newJson = JSON.parse(message.payloadString);
      } else {
        newJson = message;
      }
      // console.log("onMessageArrived:", newJson.data);
      // 判断类型
      switch (newJson.type) {
        case "2101":
          // 线索数据
          this.clueData = newJson.data;
          break;
        case "2102":
          // 车系相关
          this.seriesData = newJson.data;
          break;
        case "3001":
          // 左侧列表有直播下播时---刷新列表
          this.getLiveList(false);
          break;
        case "1101":
          // 评论
          this.setComment(newJson.data);
          break;
        default:
          break;
      }
    },
    // 去重
    unique(arr, val) {
      const res = new Map();
      return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
    },
    // 随机抽取
    randomNum(data, n) {
      // if (typeof num !== " number" || typeof n !== "number") return false; //对象检测
      var aData = [];
      if (data.length <= n) {
        for (var j = 0; j < data.length; j++) {
          aData.push(data[j]);
        }
        return aData;
      } //如果n大于num就生成0到num-1的每一个数
      else {
        while (aData.length < n) {
          var random = Math.round(Math.random() * data.length) - 1;
          if (aData.indexOf(data[random]) == -1) {
            if (data[random]) {
              aData.push(data[random]);
            }
          }
        }
        return aData;
      }
    },
    // 重置直播定时器
    setLiveTimer() {
      clearTimeout(this.autoLiveTimer);
      this.autoLiveTimer = null;
      this.autoLiveTimer = setTimeout(() => {
        this.getLiveList(true);
      }, 1000 * 60 * 5);
    },
    // 重置顶部数据定时器
    setStatisticsTimer() {
      clearTimeout(this.autoStatisticsTimer);
      this.autoStatisticsTimer = null;
      this.autoStatisticsTimer = setTimeout(() => {
        this.getTopStatistics();
      }, 1000 * 60 * 5);
    },

    // 重置添加监控定时器
    setAddMonitorTimer() {
      clearTimeout(this.addMonitorTimer);
      this.addMonitorTimer = null;
      this.addMonitorTimer = setTimeout(() => {
        this.addRoomListener();
      }, 1000 * 60 * 15);
    },

    // 下播信息弹窗
    info() {
      const h = this.$createElement;
      this.$info({
        title: "当前直播已结束",
        content: h("div", {}, [h("p", "请返回上一页面查看其他直播。")]),
        onOk() {},
      });
    },
    flv_destroy() {},
    clientDestory() {
      this.client.unsubscribe(
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
      );
      this.client.disconnect(); // 断开连接
      this.client = null;
    },
  },
  beforeDestroy() {
    this.clientDestory();
    window.removeEventListener("resize", this.resizeWidth);
    this.flv_destroy();
  },
};
</script>
<style lang="scss" scoped>
.box_live {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
@mixin hideSCroll {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
@mixin containerBox {
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
@mixin ellipsis {
  display: inline-block;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
#box {
  width: 1920px;
  // height: 1074px;
  // width: 100%;
  height: 100%;
  transform-origin: left top;
  background-color: #150b42;
}
.body {
  width: 100%;
  height: 100%;
  background: url("../../assets/live_images/bg_ac2.png");
  background-size: 100% 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    height: 82px;
    padding: 42px 0 0;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      .title {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
      }

      .switch-button {
        font-size: 14px;
        color: #ffcc55;
        margin-left: 8px;
        cursor: pointer;
        .arrow-icon {
          margin-left: 4px;
          font-size: 12px;
        }
      }
    }
  }
  .date-box {
    width: 100%;
    height: 22px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.88);
    line-height: 22px;
    margin: 10px 0 16px;
  }
  .content {
    width: 100%;
    // height: 900px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-left {
      width: 1207px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .data-box {
        width: 100%;
        height: 142px;
        border-radius: 26px;
        overflow: hidden;
        position: relative;
        &-container {
          border-radius: 26px;
          display: flex;
          @include containerBox();
          &-time {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 287px;
            height: 100%;
            padding-left: 30px;
            > span {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.6);
              margin-bottom: 18px;
            }
            > div {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              font-size: 20px;
              color: #fff;
              .time-item {
                span:first-child {
                  font-weight: bold;
                  font-style: oblique;
                }
              }
            }
          }
          .card-list {
            width: 900px;
            height: 100%;
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
          }
        }
      }
      .live-container {
        width: 100%;
        // height: 758px;
        flex: 1;
        display: flex;
        flex-direction: column;
        &-title {
          width: 100%;
          height: 92px;
          padding: 20px 0 0 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > span {
            color: #fff;
            font-size: 20px;
          }
          .refresh-button {
            cursor: pointer;
            width: 92px;
            height: 32px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #8890a6;
            padding: 0 6px 0 14px;
            > span {
              font-size: 14px;
              color: #fff;
            }
            .refresh-icon {
              font-size: 20px;
            }
          }
        }
        &-main {
          width: 100%;
          // height: 666px;
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .live-list {
            width: 370px;
            // height: 100%;
            margin-bottom: 30px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            &-container {
              display: flex;
              flex-direction: column;
              border-radius: 16px;
              @include containerBox();
              &-header {
                width: 100%;
                height: 128px;
                background: rgba(0, 0, 0, 0.24);
                .title {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  height: 68px;
                  padding: 0 20px;
                  > div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                  }
                  .live-icon {
                    font-size: 28px;
                    margin: 0 10px 0 2px;
                  }
                  span {
                    font-size: 14px;
                    color: #fff;
                  }
                  .refresh-icon {
                    font-size: 20px;
                    color: #8890a6;
                    cursor: pointer;
                  }
                }
                .official-item {
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  padding-left: 20px;
                  &-title {
                    font-size: 16px;
                    color: #fff;
                    width: 132px;
                    @include ellipsis();
                  }
                  .live-icon {
                    font-size: 10px;
                    color: #ffcc55;
                    margin: 0 26px 0 6px;
                    opacity: 0;
                  }
                  .eye-icon {
                    font-size: 22px;
                    color: rgba(255, 255, 255, 0.2);
                    margin-right: 4px;
                  }
                  .number {
                    color: #fff;
                    font-size: 16px;
                    font-style: italic;
                    font-weight: bold;
                  }
                }
                .official-item.active {
                  .official-item-title {
                    color: #ffcc55;
                  }
                  .live-icon {
                    opacity: 1;
                  }
                  .eye-icon {
                    color: #ffcc55;
                  }
                  .number {
                    color: #ffcc55;
                  }
                }
              }
              &-main {
                // height: 538px;
                flex: 1;
                // height: 100%;
                overflow-y: auto;
                // overflow: hidden;
                .main-item {
                  &:last-child {
                    margin-bottom: 20px;
                  }
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  padding-left: 20px;
                  margin-top: 22px;
                  height: 26px;
                  cursor: pointer;
                  &-title {
                    font-size: 14px;
                    color: #fff;
                    width: 116px;
                    @include ellipsis();
                  }
                  .live-icon {
                    font-size: 10px;
                    color: #ffcc55;
                    margin: 0 49px 0 6px;
                    opacity: 0;
                  }
                  .eye-icon {
                    font-size: 22px;
                    color: rgba(255, 255, 255, 0.2);
                    margin-right: 4px;
                  }
                  .number {
                    color: #fff;
                    font-size: 14px;
                    font-style: italic;
                    font-weight: bold;
                  }
                }
                .main-item.active {
                  .main-item-title {
                    color: #ffcc55;
                  }
                  .live-icon {
                    opacity: 1;
                  }
                  .eye-icon {
                    color: #ffcc55;
                  }
                  .number {
                    color: #ffcc55;
                  }
                }
              }
            }
          }
          .live-video-left {
            width: 373px;
            // height: 664px;
            // height: 100%;
            margin-bottom: 30px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            &:hover {
              .live-video-left-bottom {
                opacity: 0;
                pointer-events: none;
              }
            }
            &-container {
              border-radius: 16px;
              @include containerBox();
            }
            &-bottom {
              position: absolute;
              bottom: 0;
              z-index: 101;
              width: 100%;
              height: 80px;
              background: rgba(0, 0, 0, 0.7);
              padding: 13px 20px 12px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              transition: all 0.3s ease-in;
              .title {
                width: 90%;
                height: 17px;
                font-size: 12px;
                color: #ffffff;
                opacity: 0.7;
                line-height: 17px;
                @include ellipsis();
              }
              .info {
                width: 100%;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                > div:first-child {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  > span {
                    color: #ffffff;
                    font-size: 12px;
                  }
                }
                > div:last-child {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  > .eye-icon {
                    font-size: 20px;
                    color: #fff;
                    opacity: 0.7;
                    margin-right: 6px;
                  }
                  > span {
                    color: #fff;
                    font-size: 12px;
                    font-weight: bold;
                    font-style: italic;
                  }
                }
              }
            }
          }
          .live-video-right {
            width: 384px;
            // height: 664px;
            height: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .video-item {
              width: 176px;
              // height: 316px;
              border-radius: 16px;
              position: relative;
              overflow: hidden;
              margin-bottom: 32px;
              &:hover {
                .video-item-bottom {
                  opacity: 0;
                  pointer-events: none;
                }
              }
              &-container {
                border-radius: 16px;
                @include containerBox();
              }

              &-bottom {
                position: absolute;
                bottom: 0;
                z-index: 101;
                width: 100%;
                height: 50px;
                background: rgba(0, 0, 0, 0.7);
                padding: 6px 10px 7px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                transition: all 0.3s ease-in;
                .title {
                  width: 90%;
                  height: 14px;
                  font-size: 10px;
                  color: #ffffff;
                  opacity: 0.7;
                  line-height: 14px;
                  @include ellipsis();
                }
                .info {
                  width: 100%;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  > div:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    > span {
                      color: #ffffff;
                      font-size: 10px;
                      @include ellipsis();
                      width: 52px;
                    }
                  }
                  > div:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    > .eye-icon {
                      font-size: 20px;
                      color: #fff;
                      opacity: 0.7;
                      margin-right: 4px;
                    }
                    > span {
                      color: #fff;
                      font-size: 10px;
                      font-weight: bold;
                      font-style: italic;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &-right {
      border-radius: 26px;
      width: 553px;
      height: 100%;
      position: relative;
      overflow: hidden;
      margin-bottom: 60px;
      &-container {
        display: flex;
        flex-direction: column;
        border-radius: 26px;
        @include containerBox();
        &-title {
          width: 100%;
          height: 90px;
          padding: 36px 0 26px;
          text-align: center;
          position: relative;
          font-size: 20px;
          color: #ffffff;
          line-height: 28px;
          font-weight: bold;
          > .tooltip {
            position: absolute;
            right: 24px;
            top: 40px;
          }
        }
        &-card {
          width: 100%;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 28px;
          margin-bottom: 20px;
        }
        &-series {
          width: calc(100% - 56px);
          margin-left: 28px;
          height: 131px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 0px 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          &-card {
            width: 90px;
            height: 100%;
            padding: 0 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-right: 12px;
            img {
              width: 58px;
              height: 43px;
              margin: 8px 0 7px;
            }
            span.name {
              font-size: 12px;
              color: rgba(255, 255, 255, 0.6);
              margin: 4px 0 5px;
            }
            span.number {
              font-size: 16px;
              color: #fff;
              font-weight: bold;
              font-style: italic;
            }
          }
        }
        &-comment {
          width: calc(100% - 32px);
          margin-left: 28px;
          // height: 568px;
          flex: 1;
          overflow-y: auto;
          margin-top: 12px;
          .comment-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            transition: all 0.7s;
            &-top {
              width: 100%;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin: 20px 0 12px;
              padding-left: 10px;
              > img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 12px;
              }
              > div {
                display: flex;
                flex-direction: column;
                > span:first-child {
                  font-size: 14px;
                  color: rgba(255, 255, 255, 0.8);
                }
                > span:last-child {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.6);
                }
              }
            }
            &-main {
              background: linear-gradient(180deg, #ffffff 0%, #ffe6a8 100%);
              border-radius: 0px 22px 22px 22px;
              padding: 7px 18px 7px 24px;
              font-size: 14px;
              line-height: 20px;
              color: #000000;
              font-weight: bold;
              margin-left: 56px;
              max-width: 400px;
              min-width: 50px;
              margin-bottom: 16px;
              word-wrap: break-word;
              word-break: break-all;
            }
            &-tag {
              padding-left: 56px;
              height: 18px;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 22px;
              > span {
                width: 38px;
                height: 18px;
                background: rgba(0, 0, 0, 0.2);
                border-radius: 11px;
                font-size: 10px;
                color: rgba(255, 255, 255, 0.6);
                text-align: center;
                margin-right: 8px;
              }
            }
            &-series {
              width: 497px;
              height: 49px;
              margin-bottom: 16px;
              display: flex;
              flex-direction: column;
              .series-container {
                width: 100%;
                height: 42px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > span {
                  font-size: 14px;
                  font-weight: 500;
                  color: rgba(255, 255, 255, 0.4);
                }
                > div {
                  width: 408px;
                  height: 42px;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  flex-wrap: nowrap;
                  .series-item {
                    width: 130px;
                    height: 42px;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 12px 12px 0px 0px;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.8);
                    margin-left: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 9px;
                    > img {
                      width: 56px;
                      height: 36px;
                    }
                    > span {
                      width: 50px;
                      height: 20px;
                      line-height: 20px;
                      display: inline-block;
                      font-size: 14px;
                      color: rgba(255, 255, 255, 0.8);
                      margin-left: 6px;
                      @include ellipsis();
                    }
                  }
                }
              }
              > img {
                width: 100%;
                height: 7px;
              }
            }
          }
        }
      }
    }
  }
}
.empty {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 102;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
}
.mr-6 {
  margin-right: 6px;
}
.mr-12 {
  margin-right: 12px;
}
.mt-97 {
  margin-top: 97px;
}

.list-complete-item {
  display: inline-block;
}
.list-complete-enter-active,
.list-complete-leave-active {
  transition: all 0.7s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0.3;
  transform: translate(-250px, -10px);
}
</style>
