import { render, staticRenderFns } from "./BestStore2.vue?vue&type=template&id=177ebfdc&scoped=true"
import script from "./BestStore2.vue?vue&type=script&lang=js"
export * from "./BestStore2.vue?vue&type=script&lang=js"
import style0 from "./BestStore2.vue?vue&type=style&index=0&id=177ebfdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "177ebfdc",
  null
  
)

export default component.exports