<template>
  <div>
    <platform-header
      title="车系话题详情"
      ref="platformTab"
      :showPlatform="false"
    />

    <div class="tabs">
      <svg-icon :icon-class="'topic-title'" class="icon" />
      <span>{{ trendData.topic_name || "-" }}</span>
    </div>
    <div class="tools">
      <ButtonTab :tabData="buttonTabData" @tabChange="buttonTabChange" />
      <date-tab
        :type="dateType"
        @changeType="changeType"
        @changeDate="searchTimeTable"
        ref="dateTab"
      ></date-tab>
    </div>
    <div class="main-container">
      <div class="my-card">
        <div style="width: 340px">
          <img
            :src="
              trendData.series.image ? ossHost + trendData.series.image : ''
            "
            width="252"
            alt=""
          />
          <span class="card-series-name">{{
            trendData.series.name || "-"
          }}</span>
        </div>
        <div style="width: calc(100% - 340px)">
          <my-chart :options="lineOptions" id="seriesChart" />
        </div>
      </div>

      <div class="tools" style="padding: 0">
        <ButtonTab :tabData="buttonListData" @tabChange="buttonListChange" />
      </div>
      <!-- 最佳主播 -->
      <div class="author-card">
        <BestAuthor
          :title="'影响力主播'"
          @changeTab="bestAuthorChangeTab"
          :list="bestAuthorData"
          :type="'video'"
        />
      </div>
      <div class="video-card">
        <HotVideo :title="'热门视频'" :list="videoData" :type="'video'" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/api/modelDetailN.js";
import MyChart from "@/components/MyChart/MyChart";
import chartLine from "./chartLine_topic";
import deepClone from "@/utils/clone-utils";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ButtonTab from "@/components/PlatformTab/NewButtonTab";
import HotVideo from "./components/HotVideo";
import DateTab from "@/components/DateTab/DateTab2";
import echarts from "echarts";
import BestAuthor from "./components/BestAuthor";

export default {
  name: "modelTopicDetail",
  components: {
    PlatformHeader,
    MyChart,
    ButtonTab,
    HotVideo,
    DateTab,
    BestAuthor,
  },
  data() {
    return {
      msg: "车系话题详情",
      defaultAvatar: require("@/assets/img/defult-avatar.png"),
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      tabData: [],
      currentTab: "",
      seriesId: "",
      topicId: "",
      buttonTabData: [
        {
          label: "播放量趋势",
          value: "1",
        },
        {
          label: "视频数趋势",
          value: "2",
        },
      ],
      currentButtonTab: "1",
      buttonListData: [
        {
          label: "全部主播",
          value: "1",
        },
        {
          label: "经销商KOS",
          value: "2",
        },
        {
          label: "行业KOL",
          value: "3",
        },
      ],
      currentButtonList: "1",
      dateType: "month",

      disabledDate(current) {
        // Can not select days before today and today
        return current && current >= moment().endOf("day");
      },
      lineOptions: null,
      trendData: {
        series: {},
      },
      authorTab: "2",
      bestAuthorData: [],
      videoData: [],
    };
  },
  computed: {},
  created() {
    this.$nextTick(() => {
      this.$refs.dateTab.disabledDate = (current) => {
        return (
          current.valueOf() <= moment("2022-05-31 23:59:59").valueOf() ||
          current >= moment().endOf("day")
        );
      };
      if (this.$route.query.date) {
        this.$refs.dateTab.searchMonthTime = moment(this.$route.query.date);
        this.$refs.dateTab.monthStartTime =
          moment(this.$route.query.date)
            .startOf("month")
            .format("YYYY-MM-DD") + " 00:00:00";
        this.$refs.dateTab.monthEndTime =
          moment(this.$route.query.date)
            .endOf("month")
            .format("YYYY-MM-DD") + " 23:59:59";
      }
      this.currentTab = this.$route.query.platform;
      if (this.$route.query.id && this.$route.query.topicId) {
        this.seriesId = this.$route.query.id;
        this.topicId = this.$route.query.topicId;
        this.loadAll();
      } else {
        this.$message.error("未获取车系和话题id，无法访问该页面");
      }
    });
  },
  mounted() {},
  watch: {
    trendData(val) {
      if (val) {
        this.lineOptions = this.getLineOption();
      }
    },
  },
  methods: {
    tabChange(val) {},
    // 顶部趋势type切换
    buttonTabChange(val) {
      this.currentButtonTab = val;
      this.getTrendByTopic();
    },
    // 全部 KOS KOL 切换
    buttonListChange(val) {
      this.currentButtonList = val;
      this.getVideoByTopic();
      this.getAuthorByTopic();
    },
    // 加载全部
    loadAll() {
      this.getTrendByTopic();
      this.getVideoByTopic();
      this.getAuthorByTopic();
    },

    // 最佳主播 tab切换
    bestAuthorChangeTab(val) {
      console.log("bestAuthorChangeTab", val);
      this.authorTab = val;
      this.getAuthorByTopic();
    },
    getLineOption() {
      if (this.trendData == null) {
        return {};
      }
      const colorList = ["rgba(129, 92, 255, 1)", "rgba(255, 202, 92, 1)"];
      const colorList2 = ["rgba(129, 92, 255, 0)", "rgba(255, 202, 92, 0)"];
      let option = deepClone(chartLine);
      option.xAxis[0].data = this.trendData.timeArr;
      console.log(this.trendData);
      this.trendData.nameArr.forEach((item, index) => {
        option.series.push({
          name: item,
          type: "line",
          data: this.trendData.dataArr[index],
          symbolSize: 1,
          symbol: "circle",
          smooth: true,
          yAxisIndex: index,
          showSymbol: false,
          lineStyle: {
            width: 2,
            color: colorList[index],
          },
          itemStyle: {
            normal: {
              color: colorList[index],
              borderColor: colorList[index],
            },
          },
          areaStyle: {
            //区域填充样式
            normal: {
              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: colorList[index],
                  },
                  {
                    offset: 1,
                    color: colorList2[index],
                  },
                ],
                false
              ),
            },
          },
        });
      });
      console.log(option);
      return option;
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      this.loadAll();
    },
    // 切换时间查询
    searchTimeTable() {
      this.loadAll();
    },
    getParams(type) {
      let begin_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      let body = {
        platform: this.currentTab,
        params: {
          series_id: this.seriesId,
          topic_id: this.topicId,
          start_time: begin_time,
          end_time: end_time,
        },
      };
      if (type == "author") {
        // todo
        body.params.type = this.authorTab;
        body.params.author_type = this.currentButtonList;
      } else if (type == "video") {
        body.params.author_type = this.currentButtonList;
        body.params.current = 1;
        body.params.size = 20;
      } else if (type == "trend") {
        body.params.type = this.currentButtonTab;
      }
      return body;
    },
    // 数据趋势
    getTrendByTopic() {
      let body = this.getParams("trend");
      api.getTrendByTopic(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          if (this.currentButtonTab == 1) {
            data.nameArr = ["播放总量", "播放增量"];
          } else {
            data.nameArr = ["视频总量", "视频增量"];
          }
          data.dataArr = [[], []];
          data.timeArr = data.trend.map((item) => {
            return item.statistics_day;
          });
          data.dataArr[0] = data.trend.map((item) => {
            return item.count;
          });
          data.dataArr[1] = data.trend_di.map((item) => {
            return item.count;
          });
          this.trendData = data;
          console.log("getTrendByTopic", data);
        } else {
          console.error("获取数据趋势失败");
        }
      });
    },
    // 主播列表
    getAuthorByTopic() {
      let body = this.getParams("author");
      api.getAuthorByTopic(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.bestAuthorData = data;
          console.log("getAuthorByTopic", data);
        } else {
          console.error("获取主播列表失败");
        }
      });
    },
    // 视频列表
    getVideoByTopic() {
      let body = this.getParams("video");
      api.getVideoByTopic(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.videoData = data.list || [];
          console.log("getVideoByTopic", data);
        } else {
          console.error("获取视频列表失败");
        }
      });
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.card-title-icon {
  @include font_color("font_color5");
  font-size: 18px;
  margin: 0 8px;
}
.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 20px;
}
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 20px;
  height: 40px;
  .icon {
    font-size: 26px;
  }
  > span {
    font-size: 24px;
    font-weight: bold;
    margin-left: 8px;
    @include font_color("font_color20");
  }
}
.main-container {
  width: 100%;
  padding: 0 20px 20px;
  .author-card {
    @include background_color("background_color2");
    border-radius: 16px;
    height: 182px;
  }
  .video-card {
    @include background_color("background_color2");
    border-radius: 16px;
    height: 272px;
    margin-top: 20px;
  }
  .my-card {
    @include background_color("background_color81");
    height: 268px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @include font_color("font_color21");
    border-radius: 16px;
    margin-bottom: 30px;

    > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .card-series-name {
      @include font_color("font_color1");
    }
  }
}
</style>
