<template>
  <div
    class="video-item"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
    ref="item"
  >
    <div :class="['video-item-cover', imgType == 'live' ? '' : 'col']">
      <WebpImage
        :scale="'1-1'"
        :src="videoData.cover"
        :width="imgType == 'live' || 'fans' ? '136px' : '102px'"
        :height="imgType == 'live' || 'fans' ? '136px' : 'auto'"
        :radius="'8px'"
      />
      <div :class="['ranking', `type${type}`]">{{ type }}</div>
      <div class="play-box">
        <svg-icon icon-class="play5" v-if="imgType != 'fans'" class="icon" />
        <span>{{ getPlayNumber(videoData) }}</span>
        <span v-if="imgType == 'fans'" style="margin-left: 6px;">增粉</span>
      </div>
      <div class="shadow-box" @click="openDetail(videoData)">
        <svg-icon icon-class="play6" class="icon" />
        <span>查看详情</span>
      </div>
    </div>
    <div class="video-item-name">
      {{ videoData.title }}
    </div>
    <div class="modal-detail" ref="modal">
      <div class="modal-detail-container">
        <div class="title-box">
          {{ videoData.title }}
        </div>
        <div class="data-box-all">
          <div
            class="data-box"
            v-for="(item, index) in videoData.data"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <span>{{ item.value | changeNum }}</span>
          </div>
        </div>
        <div class="type-box">
          <img src="@/assets/img/type-lanv.png" v-if="accountType && accountType == '2'" />
          <img src="@/assets/img/type-ordinary.png" v-else />
          <span>{{ videoData.nickname || "-" }}</span>
        </div>
        <div class="store-box">
          <img src="@/assets/img/type-store.png" />
          <span>{{ videoData.dealer_name || "-" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "AuthorItem",
  components: {
    WebpImage,
  },
  props: {
    // 排名
    type: {
      type: String,
      default: "",
    },
    accountType: {
      type: String,
      default: "",
    },
    videoData: {
      type: Object,
      defautl: () => {
        return {};
      },
    },
    imgType: {
      type: String,
      default: "live",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    // 移入
    mouseOver(e) {
      // 操作dom 获取p标签改变其样式
      let top =
        this.$refs.item.getBoundingClientRect().top -
        this.$refs.modal.getBoundingClientRect().height -
        14 +
        "px";
      let left = this.$refs.item.getBoundingClientRect().left - 66 + "px";
      this.$refs.modal.style.top = top;
      this.$refs.modal.style.left = left;
      this.$refs.modal.style.display = "inline-block";
    },
    // 移出
    mouseLeave(e) {
      this.$refs.modal.style.display = "none";
    },
    openDetail(item) {
      if (item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${item.platform}`;
        window.open(url);
      } else if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${item.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无ID，无法查看详情");
      }
    },
    getPlayNumber(item) {
      if (item.platform == "douyin" || item.platform == "dongchedi") {
        return this.$options.filters.changeNum(item.data[1].value);
      } else {
        return this.$options.filters.changeNum(item.data[0].value);
      }
      // return this.$options.filters.changeNum(item.data[0].value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.video-item {
  width: 102px;
  position: relative;
  cursor: pointer;
  &:hover {
    .video-item-name {
      color: #fff;
    }
    .play-box {
      opacity: 0;
    }
    .shadow-box {
      opacity: 1;
    }
  }
  &-cover {
    width: 102px;
    height: 136px;
    border-radius: 8px;
    background: #3c4252;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &.col {
      flex-direction: column;
    }
    .ranking {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 22px;
      height: 22px;
      border-radius: 4px;
      text-align: center;
      line-height: 22px;
      font-size: 12px;
      font-weight: blod;
      color: #000000;
      display: none;
      &.type1 {
        background: #ffca5c;
        display: inline-block;
      }
      &.type2 {
        background: #8baffe;
        display: inline-block;
      }
      &.type3 {
        background: #f5a679;
        display: inline-block;
      }
    }
    .play-box {
      width: 100%;
      height: 34px;
      position: absolute;
      top: 102px;
      left: 0;
      display: flex;
      align-items: center;
      border-radius: 0 0 8px 8px;
      padding-left: 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
      .icon {
        font-size: 18px;
        color: #fff;
        margin: 0 2px 0 0px;
      }
      > span {
        font-size: 12px;
        color: #fff;
      }
    }
    .shadow-box {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 102px;
      height: 136px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 8px;
      .icon {
        font-size: 20px;
        color: #fff;
        margin-bottom: 11px;
      }
      > span {
        font-size: 12px;
        color: #fff;
      }
    }
  }
  &-name {
    width: 100%;
    height: 34px;
    font-size: 12px;
    color: #8890a6;
    line-height: 17px;
    display: inline-block;
    text-align: left;
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .modal-detail {
    width: 234px;
    background: #3c4252;
    box-shadow: 0px 3px 9px 0px rgba(20, 22, 28, 0.26);
    border-radius: 8px;
    position: fixed;
    z-index: 999;
    display: none;
    &-container {
      width: 100%;
      padding: 12px;
      .title-box {
        font-size: 12px;
        color: #ffffff;
        font-weight: bold;
      }
      .data-box-all {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
      }
      .data-box {
        width: 46%;
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        > span:first-child {
          font-size: 12px;
          color: #9ca8ba;
        }
        > span:last-child {
          font-size: 12px;
          color: #ffffff;
          margin-left: 8px;
        }
      }
      .type-box {
        display: flex;
        align-items: center;
        margin-top: 10px;
        > img {
          width: 22px;
          height: 22px;
        }
        > span {
          font-size: 12px;
          color: #ffffff;
          margin-left: 8px;
        }
      }
      .store-box {
        display: flex;
        align-items: center;
        > img {
          width: 22px;
          height: 22px;
        }
        > span {
          font-size: 12px;
          color: #c8cede;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>