<template>
  <div class="container">
    <platform-header
      title="短视频违规监测"
      :currentTab="currentTab"
      :showTooltip="true"
      code="avt01"
      @tabChange="tabChange"
    />
    <div class="content">
      <div class="chart-div">
        <div class="chart-div-title">
          <svg-icon
            :icon-class="'title'"
            class="chart-div-title-icon"
          />违规视频个数&周环比
        </div>
        <div class="chart-div-content">
          <my-chart
            :options="
              getBarOptions(
                videoChartData.xData,
                videoChartData.aweme_count,
                videoChartData.aweme_count_compare
              )
            "
            id="barChart"
          />
        </div>
      </div>
      <div class="tools">
        <div>
          <a-input-search
            v-model.trim="search_val"
            v-emoji
            @search="search(true)"
            @pressEnter="search(true)"
            style="width: 300px; margin-right: 8px"
            class="aft-search"
            placeholder="搜索门店名、账号昵称或门店编码关键字"
          />
          <a-select
            v-model="regionValue"
            :suffixIcon="suffixIcon"
            style="width: 120px; height: 36px；"
            placeholder="选择大区"
            @change="
              groupValue = undefined;
              search(true);
            "
          >
            <!-- <a-select-option value="">全部</a-select-option> -->
            <a-select-option
              :value="item.id"
              v-for="item in areaData"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="groupValue"
            :suffixIcon="suffixIcon"
            style="width: 120px; height: 36px; margin: 0 8px"
            placeholder="选择分组"
            @change="
              regionValue = undefined;
              search(true);
            "
          >
            <a-select-option value="">不限分组</a-select-option>
            <a-select-option
              :value="item.group_code"
              v-for="item in groupData"
              :key="item.group_code"
            >
              {{ item.group_name }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="type"
            :suffixIcon="suffixIcon"
            style="width: 164px"
            @change="handleChange"
            ><a-select-option value=""> 全部违规 </a-select-option>
            <a-select-option
              :value="item.tagCode"
              v-for="item in monitorTagList"
              :key="item.tagCode"
              >{{ item.tagName }}</a-select-option
            >
          </a-select>
        </div>
        <date-tab
          :type="dateType"
          @changeType="changeType"
          @changeDate="search(true)"
          ref="dateTab"
        ></date-tab>
      </div>
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :rowKey="(data, index) => data.aweme_id"
          :scroll="{ y: 'calc(100vh - 370px)' }"
          :pagination="false"
          :locale="locale"
          :loading="tableLoading"
          :customRow="openDetail"
          class="aft-table default"
        >
          <div slot="firstTitle" style="padding-left: 16px">视频标题</div>
          <div slot="customTitle" style="display: flex; align-items: center">
            <div>播放量</div>
          </div>
          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            {{ (current - 1) * pageSize + Number(index) + 1 }}
          </div>
          <div slot="liveTitle" slot-scope="scope" class="table-account">
            <div class="table-account-left">
              <div class="img-box">
                <div class="img-div">
                  <WebpImage
                    :scale="'3-4'"
                    :src="scope.aweme_cover"
                    :width="'60px'"
                    :height="'80px'"
                    :radius="'0px'"
                    style="margin-right: 8px"
                  />
                </div>
                <div class="shadow">
                  <svg-icon icon-class="play2" class="icon" />
                </div>
              </div>
              <a-tooltip>
                <template slot="title">{{ scope.aweme_title || "-" }}</template>
                <span class="font-color4 f14">{{
                  scope.aweme_title || "-"
                }}</span>
              </a-tooltip>
            </div>
          </div>
          <div slot="dealer_name" slot-scope="scope" class="font-color2 f14">
            <a-tooltip>
              <template slot="title">{{ scope.dealer_name || "-" }}</template>
              <div>
                <div class="ellipsis">
                  <img
                    src="@/assets/img/type-store.png"
                    width="20px"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 2px;
                    "
                    alt=""
                  />
                  <span>{{ scope.dealer_name || "-" }}</span>
                </div>
              </div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">{{ scope.nickname || "-" }}</template>
              <div>
                <div class="ellipsis">
                  <img
                    src="@/assets/img/type-lanv.png"
                    v-if="scope.verification_type == 2"
                    width="20"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 2px;
                    "
                    alt=""
                  />
                  <img
                    src="@/assets/img/type-ordinary.png"
                    v-else
                    width="20"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 2px;
                    "
                    alt=""
                  />
                  <span>{{ scope.nickname || "-" }}</span>
                </div>
              </div>
            </a-tooltip>
          </div>
          <div
            slot="dealer_store_code"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.dealer_store_code || "-" }}</span>
          </div>
          <div slot="publish_time" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.publish_time || "-" }}</span>
          </div>
          <div slot="watch_count" slot-scope="scope" class="font-color6 f14">
            <span>{{ scope.watch_count | changeNum }}</span>
          </div>
          <div slot="comment_count" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.comment_count | changeNum }}</span>
          </div>
          <div slot="play_volume" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.play_volume | changeNum }}</span>
          </div>
          <div slot="digg_count" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.digg_count | changeNum }}</span>
          </div>
          <div slot="share_count" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.share_count | changeNum }}</span>
          </div>
          <div
            slot="interactive_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.interactive_count | changeNum }}</span>
          </div>
          <div slot="changes" slot-scope="scope" class="font-color3 f14">
            <div class="table-tag-div" @click.stop>
              <span
                class="my-tag"
                v-for="(item, index) in scope.tag_list || []"
                :key="index"
              >
                <a-popover
                  :visible="
                    current_visible == scope.aweme_id &&
                      current_tag == item.tag &&
                      tag_visible &&
                      options.length > 0
                  "
                  trigger="click"
                  @visibleChange="
                    (visible) =>
                      handleClickChange(visible, scope.aweme_id, item.tag)
                  "
                >
                  <div slot="content" class="popover-content">
                    <div class="popover-title">修改异动情况</div>
                    <a-radio-group v-model="tag_radio" :options="options" />
                    <div class="popover-tools">
                      <span class="btn btn1" @click="closePopover">取消</span>
                      <span class="btn btn2" @click="editTags(scope)"
                        >确定</span
                      >
                    </div>
                  </div>
                  {{ item.tag }}
                  <svg-icon
                    icon-class="edit"
                    class="edit-icon"
                    v-if="options.length > 0"
                  />
                </a-popover>
              </span>
              <!-- <a-tag
                color="#FFA73E"
                style="margin: 4px 0"
                v-for="(item, index) in scope.tag_list || []"
                :key="index"
                >{{ item.tag }}</a-tag
              > -->
            </div>
          </div>
        </a-table>
      </div>
      <div class="page-div aft-pagination">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import barOptions from "./options/barOptions";
import DateTab from "@/components/DateTab/DateTab2";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "videoMonitoring"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";
import api from "@/api/videoMonitoring.js";
import { getPrincipalId } from "@/utils/auth";
const options = [
  { label: "刷量", value: "2" },
  { label: "数据波动", value: "1" },
];
export default {
  components: {
    PlatformHeader,
    MyChart,
    DateTab,
    WebpImage,
  },
  data() {
    return {
      currentTab: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      dateType: "month",
      regionValue: undefined,
      groupValue: undefined,
      search_val: "",
      type: "",
      tableLoading: false,
      columns: [
        {
          key: "liveTitle",
          slots: { title: "firstTitle" },
          align: "left",
          width: "24%",
          scopedSlots: { customRender: "liveTitle" },
        },
        {
          key: "dealer_name",
          title: "门店名称/账号名称",
          align: "left",
          width: "15%",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          key: "changes",
          title: "异动情况",
          width: "11%",
          align: "center",
          scopedSlots: { customRender: "changes" },
        },
        {
          key: "dealer_store_code",
          title: "门店编码",
          width: "8.5%",
          align: "left",
          scopedSlots: { customRender: "dealer_store_code" },
        },
        {
          key: "publish_time",
          title: "发布时间",
          width: "9.3%",
          align: "left",
          scopedSlots: { customRender: "publish_time" },
        },
        {
          key: "play_volume",
          width: "8%",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "play_volume" },
        },
        {
          key: "digg_count",
          title: "点赞数",
          width: "8.7%",
          align: "left",
          scopedSlots: { customRender: "digg_count" },
        },
        {
          key: "comment_count",
          title: "评论数",
          width: "7.4%",
          align: "left",
          scopedSlots: { customRender: "comment_count" },
        },
        {
          key: "share_count",
          title: "转发数",
          width: "7.4%",
          align: "left",
          scopedSlots: { customRender: "share_count" },
        },
        {
          key: "interactive_count",
          title: "互动量",
          width: "8.7%",
          align: "left",
          scopedSlots: { customRender: "interactive_count" },
        },
      ],
      current: 1,
      pageSize: 20,
      total: 0,
      tableData: [],
      tag_visible: false,
      options,
      tag_radio: "2",
      tag_visible: false,
      current_visible: "",
      current_tag: "",

      isFirstInit: true,
    };
  },
  watch: {
    videoListData(val) {
      console.log(val);
      this.tableData = val.list;
      this.total = val.total;
    },
  },
  computed: {
    ...mapState({
      areaData: (state) => state.areaData,
      groupData: (state) => state.groupData,
      videoListData: (state) => state.videoListData,
      videoChartData: (state) => state.videoChartData,
      monitorTagList: (state) => state.monitorTagList,
    }),
  },
  created() {},
  async mounted() {},
  methods: {
    ...mapActions([
      "getAreaInfo",
      "getAllGroup",
      "getMonitorTag",
      "getVideoList",
      "getVideoChart",
    ]),
    async initData() {
      return Promise.all([
        this.getAreaInfo(),
        this.getAllGroup(),
        this.getMonitorTag({ types: 1, principalId: getPrincipalId() }),
      ]).then(() => {
        if (this.areaData.length == 0 && this.groupData.length == 0) {
          this.$message.error("当前账号没有绑定大区或者分组，请联系管理员");
          return false;
        }
        if (this.areaData && this.areaData.length > 0) {
          this.regionValue = this.areaData[0].id;
        } else {
          this.groupValue = this.groupData[0].group_code;
        }
        // 违规标签判断
        const tagList = this.monitorTagList.map((item) => item.tagName);
        this.options = options.filter((item) => tagList.includes(item.label));
      });
    },
    // 页码切换
    pageChange(val) {
      console.log(val);
      this.search();
    },
    async tabChange(val) {
      this.currentTab = val;
      if (this.isFirstInit) {
        this.isFirstInit = false;
        await this.initData();
      }
      this.search(true);
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      // this.search(true);
    },
    async search(isFirst) {
      this.tableLoading = true;
      let begin_time = "";
      let end_time = "";
      console.log(this.$refs.dateTab);
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      if (isFirst) {
        this.current = 1;
      }
      let params = {
        area_id: this.regionValue || "",
        group_code: this.groupValue || "",
        start_time: begin_time,
        end_time: end_time,
        illegal_type: this.type,
        field: this.search_val,
        current: this.current,
        size: this.pageSize,
        platform: this.currentTab,
      };
      this.getVideoChart(params);
      await this.getVideoList(params);
      this.tableLoading = false;
    },
    handleChange(val) {
      this.search(true);
    },
    getBarOptions(xData, yData1, yData2) {
      console.log(yData2);
      if (!yData2 || yData2.length == 0) {
        return false;
      }
      yData2 = yData2.map((item) => {
        return (item * 100).toFixed(0);
      });
      let option = deepClone(barOptions);
      option.xAxis[0].data = xData;
      option.series[0].data = yData1;
      option.series[1].data = yData2;
      return option;
    },
    handleClickChange(visible, key, tag) {
      console.log(visible, key);
      if (visible) {
        this.tag_visible = true;
        this.current_visible = key;
        this.current_tag = tag;
      } else {
        this.closePopover();
      }
    },
    closePopover() {
      this.tag_visible = false;
      this.current_visible = "";
      this.current_tag = "";
      this.tag_radio = "2";
    },
    // 修改tags
    editTags(item) {
      let data = {
        before_tags: item.tags,
        id: item.aweme_id,
        platform: this.currentTab,
        type: 1,
        tags: this.tag_radio,
        source: "jjt",
      };
      this.closePopover();
      api.editTags(data).then((res) => {
        if (res.code === 0 && res.data) {
          this.$message.success("修改成功");
          this.search();
        } else {
          console.error("修改标签失败");
        }
      });
    },
    openDetail(record, index) {
      return {
        on: {
          click: () => {
            if (record.aweme_id) {
              let url = `/videoDetail?id=${record.aweme_id}&platform=${this.currentTab}`;
              window.open(url);
            } else {
              this.$message.error("暂无视频ID，无法查看详情");
            }
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .platform-div {
    width: 100%;
    height: 40px;
    @include background_color("background_color2");
    text-align: right;
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include font_color("font_color2");
    .button {
      width: 112px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      background: none;
      .btn-icon {
        font-size: 18px;
        margin-right: 4px;
      }
    }
    .btn-color1 {
      @include font_color("font_color4");
      @include background_color("background_color1");
      @include border_color("font_color4");
    }
    .btn-color2 {
      @include font_color("font_color7");
      @include background_color("background_color1");
      @include border_color("font_color7");
      margin-left: 24px;
    }
  }
  .tools {
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  .content {
    height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 0 24px;
    .chart-div {
      width: 100%;
      height: 300px;
      margin-bottom: 24px;
      padding: 19px 24px 24px;
      @include background_color("background_color2");
      &-title {
        @include font_color("font_color2");
        font-size: 16px;
        margin-bottom: 16px;
        &-icon {
          @include font_color("font_color5");
          font-size: 18px;
          margin: 0 8px;
        }
      }
      &-content {
        width: 100%;
        height: 220px;
      }
    }
    .table {
      // height: calc(100vh - 590px);
      // overflow-y: auto;
      padding-bottom: 26px;
      margin-top: 24px;
      &-account {
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        padding-left: 16px;
        &-left {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          @include font_color("font_color3");
          .img-box {
            margin-right: 8px;
            border-radius: 4px;
            background: #000;
            position: relative;
            .img-div {
              width: 60px;
              height: 80px;
              @include background_color("background_color43");
              display: flex;
              align-items: center;
            }
            .shadow {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              @include background_color("background_color20");
              .icon {
                position: absolute;
                top: calc(50% - 13px);
                left: calc(50% - 15px);
                font-size: 30px;
                @include font_color("font_color6");
              }
            }
          }
          > span {
            height: 42px;
            width: calc(100% - 70px);
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
      }

      .table-tag-div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .my-tag {
          margin: 4px 0;
          padding: 0 8px;
          height: 22px;
          line-height: 22px;
          font-size: 12px;
          color: #ffffff;
          background: #ffa73e;
          border-radius: 4px;
          .edit-icon {
            opacity: 0.65;
          }
          &:hover {
            .edit-icon {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .page-div {
    position: fixed;
    bottom: 0;
    width: calc(100% - 252px);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    @include background_color("background_color1");
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }

  .btn-color1 {
    @include font_color("font_color1");
    @include background_color("background_color34");
    border: none;
  }
  .btn-color2 {
    @include font_color("font_color1");
    @include background_color("background_color35");
    border: none;
  }
  .btn-color3 {
    @include font_color("font_color1");
    @include background_color("background_color40");
    border: none;
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .ellipsis {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}
.popover-content {
  width: 228px;
  height: 114px;
  background: #2b2f3b;
  padding: 4px 0;
  .popover-title {
    font-size: 14px;
    color: #ffffff;
    line-height: 22px;
    margin-bottom: 12px;
  }
  .popover-tools {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    .btn {
      width: 52px;
      height: 24px;
      border-radius: 20px;
      cursor: pointer;
      line-height: 24px;
      font-size: 14px;
      text-align: center;
      &.btn1 {
        background: #1c1e27;
        color: #ffffff;
      }
      &.btn2 {
        background: #ffca5c;
        color: #1c1e27;
        margin-left: 8px;
      }
    }
  }
}
</style>
<style>
.ant-radio-wrapper {
  margin-right: 24px !important;
}
</style>
