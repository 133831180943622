var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-list-container"},[_c('div',{staticClass:"data-box"},[_c('div',{staticClass:"data-box-left"},[_c('div',{staticClass:"card-title"},[_vm._v("数据概览")]),(_vm.currentTarget)?_c('div',{staticClass:"card-tips"},[_c('span',[_vm._v(_vm._s(_vm.getArrayLastItem(_vm.currentTarget.targetChart).start_time.slice( 5, 10 ))+" - "+_vm._s(_vm.getArrayLastItem(_vm.currentTarget.targetChart).end_time.slice(5, 10))+"，"+_vm._s(_vm.currentTarget.targetName)+"：")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.getArrayLastItem(_vm.currentTarget.targetChart).value)))]),_c('span',[_vm._v("，环比增长：")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("numRate2")(_vm.getArrayLastItem(_vm.currentTarget.targetChart).ratio)))])]):_vm._e(),_c('div',{staticClass:"card-container"},_vm._l((_vm.targetData),function(item){return _c('div',{key:item.targetCountKey,class:[
            'card-container-item',
            _vm.currentTarget &&
            _vm.currentTarget.targetCountKey == item.targetCountKey
              ? 'active'
              : '',
          ],on:{"click":function($event){_vm.currentTarget = item}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.targetName))]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("changeNum")(item.targetCount)))])])}),0)]),_c('div',{staticClass:"data-box-right"},[_c('div',{staticClass:"chart-box"},[(_vm.chartShow)?_c('MyChart',{attrs:{"options":_vm.getBarOption(_vm.currentTarget && _vm.currentTarget.targetChart),"id":'bar-chart-clue'}}):_vm._e()],1)])]),_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"title"},[_vm._v("留资总量")]),_c('a-table',{staticClass:"aft-table default",attrs:{"columns":_vm.columns,"data-source":_vm.tableData,"rowKey":(data) => data.dealer_id,"loading":_vm.tableLoading,"pagination":false,"locale":_vm.locale},scopedSlots:_vm._u([{key:"account",fn:function(text, record){return _c('div',{staticClass:"table-account"},[_c('div',{staticClass:"table-account-left"},[_c('WebpImage',{staticClass:"img",attrs:{"src":record.avatar,"width":'48px',"height":'48px',"radius":'50%'}}),_c('div',[_c('div',[_c('span',{staticStyle:{"font-size":"14px","color":"#fff"}},[_vm._v(_vm._s(record.dealer_name))])]),_c('div',[_c('span',{staticStyle:{"font-size":"12px","color":"#d0d6e6"}},[_vm._v(_vm._s(record.dealer_company_name))])])])],1),_c('div')])}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }