var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-content4"},[_c('div',{staticClass:"tabs-content4-main"},[_vm._m(0),(_vm.regionData && _vm.regionData.length > 0)?_c('div',{staticClass:"chart-div"},[_c('div',{staticClass:"chart-div-left"},[_c('div',{staticClass:"table-container"},[_vm._m(1),_c('div',{staticClass:"table-container-body"},_vm._l((_vm.regionData),function(item,index){return _c('div',{key:index,class:[
                'table-item',
                _vm.currentLabel == item.area_name ? 'active' : '',
              ]},[_c('div',[_vm._v(_vm._s(item.area_name || "暂无大区"))]),_c('div',[_vm._v(_vm._s(_vm._f("changeNum")(item.dealer_count)))]),_c('div',[_vm._v(_vm._s(_vm._f("changeNum")(item.total_flow)))]),_c('div',[_vm._v(_vm._s(_vm._f("changeNum")(item.sales_cue_count)))]),_c('div',[_vm._v(_vm._s(_vm._f("numRate3")(item.clue_conversion_rate)))]),_c('div',[_c('span',{staticStyle:{"margin":"0 6px 0 0"}},[_vm._v(_vm._s(_vm._f("numRate")(item.day_rate)))]),(item.day_rate >= 0)?_c('svg-icon',{staticStyle:{"font-size":"10px"},attrs:{"icon-class":"caret-up"}}):_vm._e(),(item.day_rate < 0)?_c('svg-icon',{staticStyle:{"font-size":"10px"},attrs:{"icon-class":"caret-down"}}):_vm._e()],1)])}),0)])]),_c('div',{staticClass:"chart-div-right"},[(_vm.chartShow)?_c('MyChart',{attrs:{"options":_vm.getBarOption(_vm.regionData),"id":'bar-chart-4'}}):_vm._e()],1)]):_c('div',{staticClass:"empty-box"},[_c('Empty',{attrs:{"title":"暂无数据"}})],1)]),_c('div',{staticClass:"tabs-content4-bottom"},[_vm._m(2),_c('div',{staticClass:"chart-div"},[_c('div',{staticClass:"chart-div-item"},[(_vm.chartShow)?_c('MyChart',{attrs:{"options":_vm.getPieOption(_vm.pieData.live_clue_dealer, '直播线索'),"id":'pie-chart-3'}}):_vm._e()],1),_c('div',{staticClass:"chart-div-item"},[(_vm.chartShow)?_c('MyChart',{attrs:{"options":_vm.getPieOption(_vm.pieData.video_clue_dealer, '视频线索'),"id":'pie-chart-4'}}):_vm._e()],1),_c('div',{staticClass:"chart-div-item"},[(_vm.chartShow)?_c('MyChart',{attrs:{"options":_vm.getPieOption(_vm.pieData.private_clue_dealer, '私信线索'),"id":'pie-chart-5'}}):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-div"},[_c('span',{staticClass:"title"},[_vm._v("大区对比")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container-header"},[_c('div',[_vm._v("大区")]),_c('div',[_vm._v("门店数")]),_c('div',[_vm._v("总流量")]),_c('div',[_vm._v("线索量")]),_c('div',[_vm._v("线索转化率")]),_c('div',[_vm._v("转化率变化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-div"},[_c('span',{staticClass:"title"},[_vm._v("标杆门店")])])
}]

export { render, staticRenderFns }