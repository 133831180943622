var option = {
  color: ['#FFCC55', '#5B8FF9', '#61DDAA', '#65789B', '#7262FD', '#78D3F8', '#9661BC', '#F6903D', '#F76965', '#008685', '#FAA2C6', '#FBE5A2', '#BDD2FD', '#BDEFDB', '#C2C8D5'],
  legend: {
    bottom: '20',
    left: 'center',
    textStyle: {
      color: 'rgba(255,255,255,0.88)'
    }
  },
  series: [
    {
      name: '',
      type: 'pie',
      startAngle: 135,
      radius: ['30%', '60%'],
      center: ['50%', '50%'],
      clockwise: true,
      label: {
        normal: {
          formatter: function (params, ticket, callback) {
            return `${params.percent}%`
          },
          padding: [0, 0],
        },
      },
      labelLine: {
        length: 10,
        length2: 20,
      },
      itemStyle: {
        normal: {
          shadowColor: 'rgba(0,0,0,0.4)',
          shadowBlur: 15,
        },
      },
      data: [],
    },
  ],
}

export default option