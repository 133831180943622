<template>
  <div class="clue-card-container">
    <div class="clue-card-container-item">
      <div class="item-title">昨日最佳</div>
      <div style="width: 100%">
        <div class="flex">
          <span class="number-box">
            <span class="label">销量线索:</span>
            <span class="fs18 fc1 fb">{{
              getObj("yesterday_best_store").sale_clue_count | changeNum
            }}</span>
          </span>
        </div>
        <div class="flex">
          <span class="number-box">
            <span class="label">声量线索:</span>
            <span class="fs14 fc2 fb">{{
              getObj("yesterday_best_store").volume_clue_count | changeNum
            }}</span>
          </span>
          <span class="number-box">
            <span class="label">视频播放:</span>
            <span class="fs14 fc2 fb">{{
              getObj("yesterday_best_store").aweme_play_count | changeNum
            }}</span>
          </span>
          <span class="number-box">
            <span class="label">直播观看:</span>
            <span class="fs14 fc2 fb">{{
              getObj("yesterday_best_store").live_watch_count | changeNum
            }}</span>
          </span>
        </div>
      </div>
      <div class="author-card">
        <WebpImage
          :scale="'1-1'"
          :src="getObj('yesterday_best_store').avatar || ''"
          :width="'46px'"
          :height="'46px'"
          :radius="'50%'"
        />
        <div>
          <span>{{ getObj("yesterday_best_store").dealer_name || "-" }}</span>
          <span>{{ getObj("yesterday_best_store").store_code || "-" }}</span>
        </div>
      </div>
    </div>
    <div class="clue-card-container-item">
      <div class="item-title">本周最佳</div>
      <div style="width: 100%">
        <div class="flex">
          <span class="number-box">
            <span class="label">销量线索:</span>
            <span class="fs18 fc1 fb">{{
              getObj("week_best_store").sale_clue_count | changeNum
            }}</span>
          </span>
        </div>
        <div class="flex">
          <span class="number-box">
            <span class="label">声量线索:</span>
            <span class="fs14 fc2 fb">{{
              getObj("week_best_store").volume_clue_count | changeNum
            }}</span>
          </span>
          <span class="number-box">
            <span class="label">视频播放:</span>
            <span class="fs14 fc2 fb">{{
              getObj("week_best_store").aweme_play_count | changeNum
            }}</span>
          </span>
          <span class="number-box">
            <span class="label">直播观看:</span>
            <span class="fs14 fc2 fb">{{
              getObj("week_best_store").live_watch_count | changeNum
            }}</span>
          </span>
        </div>
      </div>
      <div class="author-card">
        <WebpImage
          :scale="'1-1'"
          :src="getObj('week_best_store').avatar || ''"
          :width="'46px'"
          :height="'46px'"
          :radius="'50%'"
        />
        <div>
          <span>{{ getObj("week_best_store").dealer_name || "-" }}</span>
          <span>{{ getObj("week_best_store").store_code || "-" }}</span>
        </div>
      </div>
    </div>
    <div class="clue-card-container-item">
      <div class="item-title">增长最快</div>
      <div style="width: 100%">
        <div class="flex">
          <span style="width: 100%">
            <span class="label">声量线索:</span>
            <span class="fs14 fc3">本周新增</span>
            <span class="fs14 fc1 w1">{{ getObj("increate_fastest_best_store").volume_clue_count | changeNum}}</span>
            <span class="fs14 fc3">环比增长</span>
            <span class="fs14 fc1 w1">{{ getObj("increate_fastest_best_store").volume_clue_chain | numRate}}</span>
          </span>
        </div>
        <div class="flex">
          <span style="width: 100%">
            <span class="label">销量线索:</span>
            <span class="fs14 fc3">本周新增</span>
            <span class="fs14 fc1 w1">{{ getObj("increate_fastest_best_store").sale_clue_count | changeNum}}</span>
            <span class="fs14 fc3">环比增长</span>
            <span class="fs14 fc1 w1">{{ getObj("increate_fastest_best_store").sale_clue_chain | numRate}}</span>
          </span>
        </div>
      </div>
      <div class="author-card">
        <WebpImage
          :scale="'1-1'"
          :src="getObj('increate_fastest_best_store').avatar || ''"
          :width="'46px'"
          :height="'46px'"
          :radius="'50%'"
        />
        <div>
          <span>{{ getObj("increate_fastest_best_store").dealer_name || "-" }}</span>
          <span>{{ getObj("increate_fastest_best_store").store_code || "-" }}</span>
        </div>
      </div>
    </div>
    <div class="clue-card-container-item">
      <div class="item-title">转化效率最高</div>
      <div style="width: 100%">
        <div class="flex">
          <span class="number-box" style="width: 50%;">
            <span class="label">线索转化率:</span>
            <span class="fs18 fc1 fb">{{ getObj("transformation_rate_best_store").clue_transformation_rate | numRate}}</span>
          </span>
        </div>
        <div class="flex">
          <span class="number-box">
            <span class="label">销售线索:</span>
            <span class="fs14 fc2 fb">{{ getObj("transformation_rate_best_store").sale_clue_count | changeNum}}</span>
          </span>
          <span class="number-box">
            <span class="label">声量线索:</span>
            <span class="fs14 fc2 fb">{{ getObj("transformation_rate_best_store").volume_clue_count | changeNum}}</span>
          </span>
          <span class="number-box"> </span>
        </div>
      </div>
      <div class="author-card">
        <WebpImage
          :scale="'1-1'"
          :src="getObj('transformation_rate_best_store').avatar || ''"
          :width="'46px'"
          :height="'46px'"
          :radius="'50%'"
        />
        <div>
          <span>{{ getObj("transformation_rate_best_store").dealer_name || "-" }}</span>
          <span>{{ getObj("transformation_rate_best_store").store_code || "-" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "ClueBestCard",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    WebpImage,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {
    getObj(key) {
      return this.data[key] || {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.clue-card-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  &-item {
    width: calc(50% - 10px);
    height: 220px;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 27px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    &:nth-child(1) {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    &:nth-child(2) {
      margin-bottom: 20px;
    }
    &:nth-child(3) {
      margin-right: 20px;
    }

    .item-title {
      width: 100%;
      height: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #ffca5c;
      line-height: 25px;
    }
    .flex {
      width: 100%;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .number-box {
        width: 33%;
      }
      .label {
        color: #8890a6;
        font-size: 12px;
        margin-right: 10px;
      }
      .fb {
        font-weight: bold;
      }
      .fs12 {
        font-size: 12px;
      }
      .fs14 {
        font-size: 14px;
      }
      .fs18 {
        font-size: 18px;
      }
      .fc1 {
        color: #ffca5c;
      }
      .fc2 {
        color: #d0d6e6;
      }
      .fc3 {
        color: #8890a6;
      }
      .w1 {
        width: 60px;
        display: inline-block;
      }
    }
    .author-card {
      width: 100%;
      height: 70px;
      background: linear-gradient(270deg, #54c8ca 0%, #38869c 100%);
      border-radius: 16px;
      display: flex;
      align-items: center;
      > div:first-child {
        border: 1px solid #ffffff;
        margin: 0 16px 0 20px;
      }
      > div:last-child {
        height: 46px;
        padding: 6px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > span:first-child {
          font-size: 14px;
          color: #ffffff;
          font-weight: bold;
        }
        > span:last-child {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
</style>