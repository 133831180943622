import Nr from '@/utils/NetworldRequest'
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()

function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const platformRequest = (platform) => {
  let baseURL = getBaseUrl(platform)

  return new Nr({
    baseURL: baseURL
  }).getAxios()
}

export default {
    // 获取大区
    getAreaInfo() {
        return request.get('/jjt/dealer/dealers/region-info')
    },
    // 获取全部分组
    getAllGroup() {
        return request.get('/jjt/group/selectAllGroup')
    },
    //获取列表
    getAuthorList(body) {
        return platformRequest(body.platform).get('/jjt/dealers/author-authorization', { params: body.params })
    },
    //获取门店账号授权比例
    getAuthorizationRate(body) {
        return platformRequest(body.platform).get('/jjt/dealers/authorization-rate', { params: body.params })
    },
    //获取门店装修质检列表
    getStoreRenovationList(body) {
        return platformRequest(body.platform).get('/jjt/dealer/decoration/list', { params: body.params })
    },
    //获取门店装修质检疑似违规、违规数
    getViolationCount(body) {
        return platformRequest(body.platform).get('/jjt/dealer/decoration/violation-count', { params: body.params })
    },
}