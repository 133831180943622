<template>
  <div :class="shadowShow ? 'select-container open' : 'select-container'">
    <div class="button-total">
      <div class="left-button" @click="excelClick">
        <svg-icon :icon-class="'excel'" class="icon" />
        <span>Excel</span>
      </div>
      <div class="right-button" @click="openShadow">
        <svg-icon :icon-class="'setting'" class="icon" />
        <span>指标</span>
      </div>
    </div>
    <div class="excel-modal-container">
      <div class="excel-modal-shadow" @click="closeShadow"></div>
      <div class="excel-modal-main">
        <div class="excel-modal-main-title">选择指标</div>
        <div class="excel-modal-main-container">
          <div v-for="item in modalData" :key="item.title">
            <IconTitle
              :icon="item.icon"
              :title="item.title"
              :type="3"
              class="icon-title"
            />
            <div class="button-group">
              <div
                class="checkbox-item"
                v-for="ci in item.children"
                :key="ci.title"
                @click="checkChange(ci)"
              >
                <svg-icon
                  icon-class="md-check_box"
                  class="check"
                  v-if="includesKey(ci.key)"
                />
                <span class="uncheck" v-else></span>
                <span class="label">{{ ci.title }}</span>
                <AftTooltip v-if="ci.title === '直播观看人数' || ci.title === '直播观看人次'" :code="'qjzb11'" style="top: 0px; left: 4px" />
              </div>
              <!-- <div
                :class="
                  currentCheck == ci.key ? 'hover-button active' : 'hover-button'
                "
                v-for="ci in item.children"
                :key="ci.title"
                @click="buttonClick(ci)"
              >
                <span>{{ ci.title }}</span>

                <CardTooltip class="tooltip-icon" v-if="ci.tooltipTitle">
                  <slot>
                    <div v-html="ci.tooltipTitle"></div>
                  </slot>
                </CardTooltip>
              </div> -->
            </div>
          </div>
        </div>
        <div class="excel-modal-main-bottom">
          <span class="btn1" @click="closeShadow">取消</span>
          <span class="btn2" @click="sureClick">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CardTooltip from "@/components/Tooltip/CardTooltip";
import IconTitle from "@/components/IconTitle/IconTitle";
import deepClone from "@/utils/clone-utils";
export default {
  name: "ExcelSetting",
  components: {
    // CardTooltip,
    IconTitle,
  },
  props: {
    modalData: {
      type: Array,
      defult: [],
    },
    currentCheck: {
      type: Array,
      defult: [],
    },
  },
  data() {
    return {
      shadowShow: false,
      activeCheck: this.currentCheck,
      lastCheck: [],
    };
  },
  created() {},
  mounted() {
    document.querySelector(".excel-modal-container").style.display = "none";
  },
  computed: {},
  watch: {},
  methods: {
    openShadow() {
      this.lastCheck = deepClone(this.currentCheck);
      this.activeCheck = deepClone(this.currentCheck);
      document.querySelector(".excel-modal-container").style.display =
        "inline-block";
      setTimeout(() => {
        this.shadowShow = true;
      }, 10);
    },
    closeShadow() {
      if (this.shadowShow) {
        this.shadowShow = false;
        setTimeout(() => {
          document.querySelector(".excel-modal-container").style.display =
            "none";
        }, 200);
      }
      this.activeCheck = deepClone(this.lastCheck);
      this.$emit("update:currentCheck", this.activeCheck);
    },
    excelClick() {
      this.$emit("excelClick");
    },
    checkChange(item) {
      if (this.currentCheck.includes(item.key)) {
        if (this.activeCheck.length <= 1) {
          this.$message.warning("最少须选中一个指标");
          return false;
        }
        let arr = [];
        this.activeCheck.forEach((i) => {
          if (i != item.key) {
            arr.push(i);
          }
        });
        console.log("arr", arr);
        this.activeCheck = arr;
        this.$emit("update:currentCheck", this.activeCheck);
      } else {
        if (this.activeCheck.length >= 5) {
          this.$message.warning("最多可选中五个指标");
          return false;
        }
        this.activeCheck.push(item.key);
        this.$emit("update:currentCheck", this.activeCheck);
      }
    },
    // 确定点击
    sureClick() {
      this.lastCheck = deepClone(this.activeCheck);
      this.$emit("update:currentCheck", this.activeCheck);
      this.closeShadow();
    },
    includesKey(key) {
      return this.currentCheck.includes(key);
    },
    // buttonClick(item) {
    //   console.log(item);
    //   this.activeCheck = item.key;
    //   this.$emit("update:currentCheck", this.activeCheck);
    //   this.$emit("changeSetting", item);
    //   this.closeShadow();
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.select-container {
  width: 191px;
  height: 40px;
  position: relative;
  .button-total {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .left-button {
      width: 113px;
      background: #00b732;
      border-radius: 8px 0px 0px 8px;
      .icon {
        font-size: 22px;
        margin-right: 6px;
      }
      > span {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .right-button {
      width: 78px;
      background: #2b2f3b;
      border-radius: 0px 8px 8px 0px;
      .icon {
        font-size: 20px;
        margin-right: 6px;
      }
      > span {
        font-size: 14px;
        font-weight: bold;
        color: #9ca8ba;
      }
    }
  }
  .excel-modal-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    .excel-modal-shadow {
      width: 100vw;
      height: 100vh;
      position: fixed;
      background: rgba(0, 0, 0, 0);
      z-index: 998;
      left: 0;
      top: 0;
      display: none;
    }
    .excel-modal-main {
      width: 742px;
      opacity: 0;
      height: 0px;
      background: #3c4252;
      box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.3);
      border-radius: 16px;
      position: absolute;
      top: 0px;
      right: 0;
      z-index: 999;
      transition: all 0.2s;
      padding: 24px 0 0 0;
      &-title {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        margin-bottom: 30px;
      }
      &-container {
        width: 100%;
        height: calc(100% - 120px);
        padding: 0 26px;
        overflow-y: auto;
        > div {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          > .icon-title {
            width: 68px;
            padding-top: 3px;
            margin-right: 20px;
          }
          > .button-group {
            width: 616px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            .checkbox-item {
              height: 28px;
              margin-right: 18px;
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              .uncheck {
                width: 12px;
                height: 12px;
                border-radius: 2px;
                border: 2px solid #626a7e;
                margin: 2px;
              }
              .check {
                font-size: 16px;
                color: #ffca5c;
              }
              .label {
                margin-left: 8px;
                font-size: 12px;
                color: #d0d6e6;
              }
            }
          }
        }
      }
      &-bottom {
        height: 64px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        > span {
          margin-right: 14px;
          width: 76px;
          height: 36px;
          border-radius: 8px;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
        }
        .btn1 {
          background: #1c1e27;
          color: rgba(255, 255, 255, 0.6);
        }
        .btn2 {
          background: #ffca5c;
          color: #1c1e27;
        }
      }
    }
  }

  &.open {
    .select-button {
      .icon {
        transform: rotate(180deg);
      }
    }
    .excel-modal-container {
      .excel-modal-shadow {
        display: block;
      }
      .excel-modal-main {
        opacity: 1;
        height: 363px;
      }
    }
  }
}
</style>
