import filters from '@/filters'
var option = {
  tooltip: {
    trigger: 'axis',
    // backgroundColor: 'rgba(37, 50, 85, 0)',
    // formatter: function (params) {
    //   let str = `<div style="background: #3C4252;border-radius: 8px;width: 271px;height: 66px;">
    //         <div style="width: 100%;
    //             height: 25px;
    //             line-height: 25px;
    //             font-size: 10px;
    //             color: #C8CEDE;
    //             border-bottom: 1px solid rgba(255,255,255, 0.1);
    //             padding-left: 20px;">
    //             ${params[0].name}
    //         </div>
    //         <div style="display:flex;align-items: center;height: 40px;">
    //         <span style="font-size: 12px;color: #D0D6E6;font-weight: bold;margin-left: 20px;">${params[0].seriesName}:</span>
    //         <span style="font-size: 14px;color: #FFCA5C;font-weight: bold;margin-left: 8px;">${params[0].data}%</span>
    //         <span style="font-size: 12px;color: #D0D6E6;font-weight: bold;margin-left: 24px;">${params[1].seriesName}:</span>
    //         <span style="font-size: 14px;color: #FFCA5C;font-weight: bold;margin-left: 8px;">${params[1].data}%</span>
    //         </div>
    //     </div>`;
    //   return str;
    // }
  },
  legend: {
    top: 0,
    left: 20,
    itemWidth: 12,
    itemHeight: 7,
    inactiveColor: '#3C4252',
    // icon: 'path://M512.005117 958.708971C265.683035 958.708971 65.290005 758.316965 65.290005 511.99386c0-246.310825 200.39303-446.703855 446.715111-446.703855 246.310825 0 446.703855 200.39303 446.703855 446.703855C958.708971 758.316965 758.316965 958.708971 512.005117 958.708971zM512.005117 169.716356c-188.738595 0-342.289784 153.545048-342.289784 342.277504 0 188.738595 153.551188 342.289784 342.289784 342.289784 188.733479 0 342.278527-153.551188 342.278527-342.289784C854.283644 323.261405 700.738595 169.716356 512.005117 169.716356z',
    textStyle: {
      fontSize: 12,
      color: 'rgba(255,255,255,0.65)',
      padding: [0, 20, 0, 4]
    },
    // data: [ '直播线索', '视频线索','私信','个人主页','行业锚点','预约组件','其他']
    data: [ '直播线索', '视频线索','私信','个人主页','其他']
  },
  grid: {
    "left": "70",
    "top": "60",
    "right": "40",
    "bottom": "60"
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      show: true, //隐藏x轴
    },
    axisTick: {
      show: true,  //刻度线
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6', // 坐标轴文字颜色
        fontSize: 10, // 坐标轴文字大小
        padding: [0, 10, 0, 0]
      },
    },
    data: []
  },
  yAxis: {
    type: 'value',
    position: 'left',
    axisTick: {
      show: true,  //刻度线
    },
    axisLine: {
      show: false, //隐藏y轴
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6', // 坐标轴文字颜色
        fontSize: 10, // 坐标轴文字大小
        padding: [0, 10, 0, 0]
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["rgba(255,255,255,0.04)"],
      },
    },
  },
  series: [
    {
      name: '直播线索',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#FFCC55',
        },
      },
      data: []
    },
    {
      name: '视频线索',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#5B8FF9',
        },
      },
      data: []
    },
    {
      name: '私信',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#61DDAA',
        },
      },
      data: []
    },
    {
      name: '个人主页',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#65789B',
        },
      },
      data: []
    },
    // {
    //   name: '行业锚点',
    //   smooth: true,
    //   type: 'line',
    //   symbol: "circle",
    //   showSymbol: true,
    //   symbolSize: 6,
    //   itemStyle: {
    //     normal: {
    //       color: '#7262FD',
    //     },
    //   },
    //   data: []
    // },
    // {
    //   name: '预约组件',
    //   smooth: true,
    //   type: 'line',
    //   symbol: "circle",
    //   showSymbol: true,
    //   symbolSize: 6,
    //   itemStyle: {
    //     normal: {
    //       color: '#78D3F8',
    //     },
    //   },
    //   data: []
    // },
    {
      name: '其他',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#9661BC',
        },
      },
      data: []
    },
  ]
};
export default option