<template>
  <a-spin class="left-spin" :spinning="loading">
    <div class="container">
      <div ref="imgWrapper" :class="[isToPicture ? 'img-wrapper' : '']">
        <div class="second-header">
          <div>
            <span class="title">每日战报</span>
          </div>
          <div>
            <span class="date-label">数据统计截止时间：</span>
            <span class="date-value"> {{ data.updateTime }} </span>
          </div>
        </div>
        <a-alert
          v-if="data.isNote && !data.isReadyNote"
          class="alert-tip"
          message="笔记数据暂未更新，请稍后查看"
          banner
        />
        <div class="tools">
          <div class="tools-left">
            <div
              class="daily-button arrow left"
              @click="handleDatePickerListChange('pre')"
              v-if="dateList.length && !isToPicture"
            >
              <svg-icon
                icon-class="date-arrow-right"
                class="icon"
                :class="preAble ? '' : 'disabled'"
              ></svg-icon>
            </div>
            <ButtonGroup
              style="margin:0 8px"
              :default-value="currentDateIndex"
              :tab-data="datePickerList"
              @tabChange="handleDateChange"
            />
            <div
              class="daily-button arrow"
              @click="handleDatePickerListChange('next')"
              v-if="dateList.length && !isToPicture"
            >
              <svg-icon
                icon-class="date-arrow-right"
                class="icon"
                :class="nextAble ? '' : 'disabled'"
              ></svg-icon>
            </div>
          </div>
          <div class="tools-right" v-if="!isToPicture">
            <div class="daily-button" size="large" @click="handleExportPicture">
              <a-icon type="export" />
              <div class="ml-8">导出图片</div>
            </div>
          </div>
        </div>

        <!-- 统计开始 -->
        <div class="statistics-card" :style="rowStyle">
          <div class="statistics-card-item">
            <div class="d-flex justify-content-between">
              <div class="title">
                门店参与
              </div>
              <div>
                <span class="sub-title">
                  参与门店
                </span>
                <span class="ml-8">{{ data.dealerCount }}</span>
              </div>
            </div>
            <div class="d-flex card-data">
              <div class="flex-fill d-flex">
                <div>
                  <a-progress
                    type="circle"
                    :percent="Number(data.dealerJoinRate)"
                    :show-info="false"
                    :width="48"
                    :stroke-width="8"
                    stroke-color="#FFCA5C"
                  />
                </div>
                <div
                  class="d-flex flex-column justify-content-start flex-1 ml-8"
                >
                  <div class="sub-title">参与率</div>
                  <div class="count">{{ data.dealerJoinRate }}%</div>
                </div>
              </div>
              <div class="flex-fill">
                <div class="sub-title">累计参与门店</div>
                <div class="count">
                  {{ data.totalDealerCount }}
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-card-item" v-if="data.isLive">
            <div class="d-flex justify-content-between">
              <div class="title">
                直播
                <question-tooltip
                  title="由于部分历史数据无法回溯，直播发布和累积直播仅从创建活动的当天时间开始统计，累计观看量从活动开始时间统计"
                  v-if="ifShowTooltip"
                />
              </div>
              <div>
                <span class="sub-title">参与门店</span>
                <span class="ml-8">{{ data.liveDealerCount }}</span>
              </div>
            </div>
            <div class="d-flex card-data">
              <div class="flex-fill">
                <div class="sub-title">直播发布数</div>
                <div class="count">{{ data.liveCount }}</div>
              </div>
              <div class="flex-fill">
                <div class="sub-title">活动累计直播数</div>
                <div class="count">
                  {{ data.liveTotalCount }}
                </div>
              </div>
              <div class="flex-fill">
                <div class="sub-title">活动累计观看量</div>
                <div class="count">
                  {{ data.liveWatchTotalCount | changeNumNoRound }}
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-card-item" v-if="data.isVideo">
            <div class="d-flex justify-content-between">
              <div class="title">
                视频
                <question-tooltip
                  title="由于部分历史数据无法回溯，视频发布和累积视频发布仅从创建活动的当天时间开始统计，累计播放量从活动开始时间统计"
                  v-if="ifShowTooltip"
                />
              </div>
              <div>
                <span class="sub-title">参与门店</span>
                <span class="ml-8">{{ data.awemePublishDealerCount }}</span>
              </div>
            </div>
            <div class="d-flex card-data">
              <div class="flex-fill">
                <div class="sub-title">视频发布数</div>
                <div class="count">
                  {{ data.awemePublishCount }}
                </div>
              </div>
              <div class="flex-fill">
                <div class="sub-title">活动累计发布数</div>
                <div class="count">
                  {{ data.awemePublishTotalCount }}
                </div>
              </div>
              <div class="flex-fill">
                <div class="sub-title">活动累计播放量</div>
                <div class="count">
                  {{ data.awemeWatchTotalCount | changeNumNoRound }}
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-card-item" v-if="data.isNote">
            <div class="d-flex justify-content-between">
              <div class="title">
                笔记
                <question-tooltip
                  title="由于部分历史数据无法回溯，笔记发布和累计笔记发布仅从创建活动的当天时间开始统计"
                  v-if="ifShowTooltip"
                />
              </div>
              <div>
                <span class="sub-title">参与门店</span>
                <span class="ml-8">{{ data.noteDealerCount }}</span>
              </div>
            </div>
            <div class="d-flex card-data">
              <div class="flex-fill">
                <div class="sub-title">笔记发布数</div>
                <div class="count">
                  {{ data.noteCount }}
                </div>
              </div>
              <div class="flex-fill">
                <div class="sub-title">活动累计发布数</div>
                <div class="count">
                  {{ data.noteTotalCount }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 统计结束 -->

        <div class="title-header" style="margin-top:4px">
          每日排行榜
          <question-tooltip
            title="由于部分历史数据无法回溯，部分榜单可能出现暂无数据的情况，建议查看最新日期的战报榜单"
            v-if="!isToPicture"
          />
        </div>

        <!-- 排行开始 -->
        <div class="rank-card" :style="rowStyle">
          <div class="rank-card-item">
            <div class="card-title">
              <span>门店行动数Top10</span>
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column card-body">
              <template v-if="data.dealerActionTop.length">
                <div class="d-flex align-items-center card-body-header">
                  <div class="rank-number">排名</div>
                  <div class="rank-area">门店/大区</div>
                  <div class="rank-count">活动发布量</div>
                </div>
                <div class="flex-fill card-body-list">
                  <div
                    class="d-flex align-items-center card-body-list-item"
                    v-for="(item, index) in data.dealerActionTop"
                    :key="index"
                  >
                    <div class="rank-number">
                      <div :class="['ranking', `ranking${index + 1}`]">
                        {{ index + 1 }}
                      </div>
                    </div>
                    <div class="rank-area">
                      <div class="d-flex">
                        <div class="avatar">
                          <BaseImage
                            :src="item.authorAvatar"
                            :default-image="defaultAvatarImg"
                            fit="cover"
                            width="24px"
                            height="24px"
                            radius="12px"
                          />
                        </div>
                        <div class="ml-8">
                          <div class="dealer-name">{{ item.dealerName }}</div>
                          <div class="region-name">{{ item.regionName }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="rank-count">
                      {{ item.count }}
                    </div>
                  </div>
                  <Empty
                    v-if="data.dealerActionTop.length == 0"
                    style="margin-top:30px"
                  ></Empty>
                </div>
              </template>
              <div
                class="flex-fill d-flex justify-content-center align-items-center"
                v-else
              >
                <Empty />
              </div>
            </div>
          </div>

          <div class="rank-card-item" v-if="data.isLive">
            <div class="card-title">
              <span>直播观看量Top10</span>
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column card-body">
              <template v-if="data.dealerLiveTop.length">
                <div class="d-flex align-items-center card-body-header">
                  <div class="rank-number">排名</div>
                  <div class="rank-area">门店/大区</div>
                  <div class="rank-count">观看量</div>
                </div>
                <div class="flex-fill card-body-list">
                  <div
                    class="d-flex align-items-center card-body-list-item"
                    v-for="(item, index) in data.dealerLiveTop"
                    :key="index"
                  >
                    <div class="rank-number">
                      <div :class="['ranking', `ranking${index + 1}`]">
                        {{ index + 1 }}
                      </div>
                    </div>
                    <div class="rank-area">
                      <div class="d-flex">
                        <div class="avatar">
                          <BaseImage
                            :src="item.authorAvatar"
                            :default-image="defaultAvatarImg"
                            fit="cover"
                            width="24px"
                            height="24px"
                            radius="12px"
                          />
                        </div>
                        <div class="ml-8">
                          <div class="dealer-name">{{ item.dealerName }}</div>
                          <div class="region-name">{{ item.regionName }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="rank-count">
                      {{ formatNum(item.count) }}
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="flex-fill d-flex justify-content-center align-items-center"
                v-else
              >
                <Empty />
              </div>
            </div>
          </div>

          <div class="rank-card-item" v-if="data.isVideo">
            <div class="card-title">
              <span>视频播放量Top10</span>
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column card-body">
              <template v-if="data.dealerAwemeTop.length">
                <div class="d-flex align-items-center card-body-header">
                  <div class="rank-number">排名</div>
                  <div class="rank-area">门店/大区</div>
                  <div class="rank-count">播放量</div>
                </div>
                <div class="flex-fill card-body-list">
                  <div
                    class="d-flex align-items-center card-body-list-item"
                    v-for="(item, index) in data.dealerAwemeTop"
                    :key="index"
                  >
                    <div class="rank-number">
                      <div :class="['ranking', `ranking${index + 1}`]">
                        {{ index + 1 }}
                      </div>
                    </div>
                    <div class="rank-area">
                      <div class="d-flex">
                        <div class="avatar">
                          <BaseImage
                            :src="item.authorAvatar || ''"
                            :default-image="defaultAvatarImg"
                            fit="cover"
                            width="24px"
                            height="24px"
                            radius="12px"
                          />
                        </div>
                        <div class="ml-8">
                          <div class="dealer-name">{{ item.dealerName }}</div>
                          <div class="region-name">{{ item.regionName }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="rank-count">
                      {{ formatNum(item.count) }}
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="flex-fill d-flex justify-content-center align-items-center"
                v-else
              >
                <Empty />
              </div>
            </div>
          </div>

          <div class="rank-card-item" v-if="data.isNote">
            <div class="card-title">
              <span>笔记点赞量Top10</span>
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column card-body">
              <template v-if="data.dealerNoteTop.length">
                <div class="d-flex align-items-center card-body-header">
                  <div class="rank-number">排名</div>
                  <div class="rank-area">门店/大区</div>
                  <div class="rank-count">点赞量</div>
                </div>
                <div class="flex-fill card-body-list">
                  <div
                    class="d-flex align-items-center card-body-list-item"
                    v-for="(item, index) in data.dealerNoteTop"
                    :key="index"
                  >
                    <div class="rank-number">
                      <div :class="['ranking', `ranking${index + 1}`]">
                        {{ index + 1 }}
                      </div>
                    </div>
                    <div class="rank-area">
                      <div class="d-flex">
                        <div class="avatar">
                          <BaseImage
                            :src="item.authorAvatar || ''"
                            :default-image="defaultAvatarImg"
                            fit="cover"
                            width="24px"
                            height="24px"
                            radius="12px"
                          />
                        </div>
                        <div class="ml-8">
                          <div class="dealer-name">{{ item.dealerName }}</div>
                          <div class="region-name">{{ item.regionName }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="rank-count">
                      {{ formatNum(item.count) }}
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="flex-fill d-flex justify-content-center align-items-center"
                v-else
              >
                <Empty />
              </div>
            </div>
          </div>

          <div class="rank-card-item">
            <div class="card-title">
              <span>区域参与排名</span>
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column card-body">
              <template v-if="data.regionJoinTop.length">
                <div class="d-flex align-items-center card-body-header">
                  <div class="rank-number">排名</div>
                  <div class="rank-area">区域</div>
                  <div class="rank-count">参与门店数</div>
                </div>
                <div class="flex-fill card-body-list">
                  <div
                    class="d-flex align-items-center card-body-list-item"
                    v-for="(item, index) in data.regionJoinTop"
                    :key="index"
                  >
                    <div class="rank-number">
                      <div :class="['ranking', `ranking${index + 1}`]">
                        {{ index + 1 }}
                      </div>
                    </div>
                    <div class="rank-area">
                      <div>
                        <div>{{ item.regionName }}</div>
                      </div>
                    </div>
                    <div class="rank-count">{{ item.dealerCount }}</div>
                  </div>
                </div>
              </template>
              <div
                class="flex-fill d-flex justify-content-center align-items-center"
                v-else
              >
                <Empty />
              </div>
            </div>
          </div>

          <div class="rank-card-item" v-if="data.isVideo">
            <div class="card-title">
              <span>区域视频Top1</span>
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column card-body">
              <template v-if="data.regionAwemeTop.length">
                <div class="d-flex align-items-center card-body-header">
                  <div class="rank-area">大区/门店</div>
                  <div class="rank-title-count">标题/播放量/互动量</div>
                </div>
                <div class="flex-fill card-body-list">
                  <div
                    class="d-flex align-items-center card-body-list-item"
                    v-for="(item, index) in data.regionAwemeTop"
                    :key="index"
                  >
                    <div class="rank-area">
                      <div class="d-flex flex-column area-dsc">
                        <div class="dealer-name">{{ item.regionName }}</div>
                        <div class="region-name">
                          <TextEllipsis
                            :value="item.dealerName"
                            :key="item.regionId"
                          ></TextEllipsis>
                        </div>
                      </div>
                    </div>
                    <div class="rank-title-count">
                      <div class="d-flex flex-column">
                        <div class="title">
                          <TextEllipsis
                            :value="item.title"
                            :key="item.regionId"
                          ></TextEllipsis>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div class="sub-title">
                            播放量
                            <span class="count">{{
                              formatNum(item.watchCount)
                            }}</span>
                          </div>
                          <div class="sub-title">
                            互动量
                            <span class="count">{{
                              formatNum(item.interactCount)
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="flex-fill d-flex justify-content-center align-items-center"
                v-else
              >
                <Empty />
              </div>
            </div>
          </div>

          <div class="rank-card-item" v-if="data.isLive">
            <div class="card-title">
              <span>区域直播Top1</span>
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column card-body">
              <template v-if="data.regionLiveTop.length">
                <div class="d-flex align-items-center card-body-header">
                  <div class="rank-area">大区/门店</div>
                  <div class="rank-title-count">标题/观看量/互动量</div>
                </div>
                <div class="flex-fill card-body-list">
                  <div
                    class="d-flex align-items-center card-body-list-item"
                    v-for="(item, index) in data.regionLiveTop"
                    :key="index"
                  >
                    <div class="rank-area">
                      <div class="d-flex flex-column">
                        <div class="dealer-name">{{ item.regionName }}</div>
                        <div class="region-name">
                          <TextEllipsis
                            :value="item.dealerName"
                            :key="item.regionId"
                          ></TextEllipsis>
                        </div>
                      </div>
                    </div>
                    <div class="rank-title-count">
                      <div class="d-flex flex-column">
                        <div class="title">
                          <TextEllipsis
                            :value="item.title"
                            :key="item.regionId"
                          ></TextEllipsis>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div class="sub-title">
                            观看量
                            <span class="count">{{
                              formatNum(item.watchCount)
                            }}</span>
                          </div>
                          <div class="sub-title">
                            互动量
                            <span class="count">{{
                              formatNum(item.interactCount)
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="flex-fill d-flex justify-content-center align-items-center"
                v-else
              >
                <Empty />
              </div>
            </div>
          </div>

          <div class="rank-card-item" v-if="data.isNote">
            <div class="card-title">
              <span>区域笔记Top1</span>
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column card-body">
              <template v-if="data.regionNoteTop.length">
                <div class="d-flex align-items-center card-body-header">
                  <div class="rank-area">大区/门店</div>
                  <div class="rank-title-count">标题/点赞量</div>
                </div>
                <div class="flex-fill card-body-list">
                  <div
                    class="d-flex align-items-center card-body-list-item"
                    v-for="(item, index) in data.regionNoteTop"
                    :key="index"
                  >
                    <div class="rank-area">
                      <div class="d-flex flex-column">
                        <div class="dealer-name">{{ item.regionName }}</div>
                        <div class="region-name">
                          <TextEllipsis
                            :value="item.dealerName"
                            :key="item.regionId"
                          ></TextEllipsis>
                        </div>
                      </div>
                    </div>
                    <div class="rank-title-count">
                      <div class="d-flex flex-column">
                        <div class="title">
                          <TextEllipsis
                            :value="item.title"
                            :key="item.regionId"
                          ></TextEllipsis>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div class="sub-title">
                            点赞量
                            <span class="count">{{
                              formatNum(item.likeCount)
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="flex-fill d-flex justify-content-center align-items-center"
                v-else
              >
                <Empty />
              </div>
            </div>
          </div>
        </div>
        <!-- 排行结束 -->
      </div>

      <div class="tools" style="margin-top:24px; margin-bottom: 0;">
        <div class="title-header" style="padding:0">
          每日活动零产统计
          <question-tooltip title="仅统计应参与门店的零产情况" />
        </div>
        <div class="tools-right">
          <div class="daily-button" size="large" @click="handleExportExcel">
            <a-icon type="export" />
            <div class="ml-8">导出Excel</div>
          </div>
        </div>
      </div>

      <div class="rank-card" style="margin-bottom:20px">
        <div class="rank-card-item">
          <div class="card-title no-bg">
            <span>未参与活动门店</span>
          </div>
          <div class="d-flex flex-column card-body">
            <template
              v-if="
                data.activityNoJoinDealers &&
                  data.activityNoJoinDealers.length > 0
              "
            >
              <div class="d-flex align-items-center card-body-header">
                <div class="rank-area">区域/门店数量</div>
              </div>
              <div class="flex-fill card-body-list">
                <a-collapse :bordered="false" expand-icon-position="right">
                  <template #expandIcon="props">
                    <div>
                      <svg-icon
                        icon-class="arrow-down"
                        :class="[props.isActive ? 'rotate-180' : '']"
                      ></svg-icon>
                    </div>
                  </template>
                  <a-collapse-panel
                    v-for="(item, index) in data.activityNoJoinDealers"
                    :header="`${item.regionName} \(${item.dealerList.length}\)`"
                    :key="index"
                  >
                    <div
                      class="area-name"
                      v-for="(dItem, dIndex) in item.dealerList"
                      :key="dIndex"
                    >
                      {{ dItem.dealerName }}
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </template>
            <div
              class="flex-fill d-flex justify-content-center align-items-center"
              v-else
            >
              <Empty />
            </div>
          </div>
        </div>

        <div class="rank-card-item" v-if="data.isLive">
          <div class="card-title no-bg">
            <span>未直播门店</span>
          </div>
          <div class="d-flex flex-column card-body">
            <template
              v-if="data.liveNoJoinDealers && data.liveNoJoinDealers.length > 0"
            >
              <div class="d-flex align-items-center card-body-header">
                <div class="rank-area">区域/门店数量</div>
              </div>
              <div class="flex-fill card-body-list">
                <a-collapse :bordered="false" expand-icon-position="right">
                  <template #expandIcon="props">
                    <div>
                      <svg-icon
                        icon-class="arrow-down"
                        :class="[props.isActive ? 'rotate-180' : '']"
                      ></svg-icon>
                    </div>
                  </template>
                  <a-collapse-panel
                    v-for="(item, index) in data.liveNoJoinDealers"
                    :header="`${item.regionName} \(${item.dealerList.length}\)`"
                    :key="index"
                  >
                    <div
                      class="area-name"
                      v-for="(dItem, dIndex) in item.dealerList"
                      :key="dIndex"
                    >
                      {{ dItem.dealerName }}
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </template>
            <div
              class="flex-fill d-flex justify-content-center align-items-center"
              v-else
            >
              <Empty />
            </div>
          </div>
        </div>

        <div class="rank-card-item" v-if="data.isVideo">
          <div class="card-title no-bg">
            <span>未发布视频门店</span>
          </div>
          <div class="d-flex flex-column card-body">
            <template
              v-if="
                data.awemeNoJoinDealers && data.awemeNoJoinDealers.length > 0
              "
            >
              <div class="d-flex align-items-center card-body-header">
                <div class="rank-area">区域/门店数量</div>
              </div>
              <div class="flex-fill card-body-list">
                <a-collapse :bordered="false" expand-icon-position="right">
                  <template #expandIcon="props">
                    <div>
                      <svg-icon
                        icon-class="arrow-down"
                        :class="[props.isActive ? 'rotate-180' : '']"
                      ></svg-icon>
                    </div>
                  </template>
                  <a-collapse-panel
                    v-for="(item, index) in data.awemeNoJoinDealers"
                    :header="`${item.regionName} \(${item.dealerList.length}\)`"
                    :key="index"
                  >
                    <div
                      class="area-name"
                      v-for="(dItem, dIndex) in item.dealerList"
                      :key="dIndex"
                    >
                      {{ dItem.dealerName }}
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </template>
            <div
              class="flex-fill d-flex justify-content-center align-items-center"
              v-else
            >
              <Empty />
            </div>
          </div>
        </div>

        <div class="rank-card-item" v-if="data.isNote">
          <div class="card-title no-bg">
            <span>未发布笔记门店</span>
          </div>
          <div class="d-flex flex-column card-body">
            <template
              v-if="data.noteNoJoinDealers && data.noteNoJoinDealers.length > 0"
            >
              <div class="d-flex align-items-center card-body-header">
                <div class="rank-area">区域/门店数量</div>
              </div>
              <div class="flex-fill card-body-list">
                <a-collapse :bordered="false" expand-icon-position="right">
                  <template #expandIcon="props">
                    <div>
                      <svg-icon
                        icon-class="arrow-down"
                        :class="[props.isActive ? 'rotate-180' : '']"
                      ></svg-icon>
                    </div>
                  </template>
                  <a-collapse-panel
                    v-for="(item, index) in data.noteNoJoinDealers"
                    :header="`${item.regionName} \(${item.dealerList.length}\)`"
                    :key="index"
                  >
                    <div
                      class="area-name"
                      v-for="(dItem, dIndex) in item.dealerList"
                      :key="dIndex"
                    >
                      {{ dItem.dealerName }}
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </template>
            <div
              class="flex-fill d-flex justify-content-center align-items-center"
              v-else
            >
              <Empty />
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import api from "@/api/dailyAchievements.js";
import { format, parseISO, addDays, isSameDay } from "date-fns";
import exportTable from "@/utils/exportTable";
import QuestionTooltip from "@/components/Tooltip/QuestionTooltip";
import Empty from "@/components/Empty/Empty";
import html2canvas from "html2canvas";
import TextEllipsis from "@/components/TextEllipsis/TextEllipsis.vue";
import BaseImage from "@/components/BaseImage";
import defaultAvatarImg from "@/assets/default/default1-1.png";
import { aliImgTransform } from "@/utils/util";
import moment from "moment";

export default {
  name: "activityRanking",
  components: {
    ButtonGroup,
    QuestionTooltip,
    Empty,
    TextEllipsis,
    BaseImage,
  },
  data() {
    return {
      defaultAvatarImg,
      loading: false,
      isToPicture: false,

      activityId: "",

      dateList: [],
      datePickerList: [],
      currentDateIndex: -1,
      preAble: false,
      nextAble: false,

      data: {
        activityNoJoinDealers: [],
        awemeNoJoinDealers: [],
        dealerActionTop: [],
        dealerAwemeTop: [],
        dealerLiveTop: [],
        liveNoJoinDealers: [],
        regionAwemeTop: [],
        regionJoinTop: [],
        regionLiveTop: [],
      },
      ifShowTooltip: false,
    };
  },
  computed: {
    rowStyle() {
      // let column = 1;
      // if (this.data.isLive) column++;
      // if (this.data.isVideo) column++;
      // if (this.data.isNote) column++;
      // if (column > 3) column = 3;
      return {
        "grid-template-columns": `repeat(3, 1fr)`,
      };
    },
  },
  async created() {
    // 判断页面来源
    if (this.$route.query.aId) {
      this.activityId = this.$route.query.aId;
    }
  },
  async mounted() {
    await this.getActivityDateList();
    if (this.dateList.length === 0) return;
    this.getActivityDailyAchievements();
  },
  methods: {
    /**
     * 初始化日期选择
     * @param {number} startIndex 日期列表裁剪开始索引
     * @param {number} endIndex 日期列表裁剪结束索引
     */
    initDatePickerList(startIndex, endIndex) {
      if (startIndex < 0) {
        endIndex += 0 - startIndex;
      } else if (endIndex > this.dateList.length) {
        startIndex -= endIndex - this.dateList.length;
      }
      startIndex = startIndex < 0 ? 0 : startIndex;
      endIndex =
        endIndex > this.dateList.length ? this.dateList.length : endIndex;
      this.datePickerList = this.dateList.slice(startIndex, endIndex);
      // 判断按钮状态
      if (this.datePickerList.length > 0) {
        this.preAble = this.datePickerList[0] !== this.dateList[0];
        this.nextAble =
          this.datePickerList[this.datePickerList.length - 1] !==
          this.dateList[this.dateList.length - 1];
      } else {
        this.preAble = false;
        this.nextAble = false;
      }
    },
    /**
     * 根据活动id获取活动战报日期列表
     */
    getActivityDateList() {
      return api
        .getActivityDateList({
          activityId: this.activityId,
        })
        .then((res) => {
          const { code, data } = res.data;
          if (code === 200) {
            let currentDateIndex = 0;
            const lastDate = addDays(new Date(), -1);
            this.dateList = data.map((item, index) => {
              const parseDate = parseISO(item.time);
              if (isSameDay(lastDate, parseDate)) currentDateIndex = index;
              return {
                label: item.date,
                value: index,
                date: format(parseDate, "yyyy-MM-dd"),
              };
            });
            this.currentDateIndex = currentDateIndex;
            let startIndex = currentDateIndex - 2;
            let endIndex = currentDateIndex + 3;
            this.initDatePickerList(startIndex, endIndex);
          }
        });
    },
    /**
     * 日期选择
     * @param {number} index 日期列表索引
     */
    handleDateChange(index) {
      this.currentDateIndex = index;
      this.getActivityDailyAchievements();
    },
    /**
     * 日期上下页切换
     * @param {string} type pre 上一页 next 下一页
     */
    handleDatePickerListChange(type) {
      const showCount = 5;
      let startIndex = this.datePickerList[0].value;
      let endIndex =
        this.datePickerList[this.datePickerList.length - 1].value + 1;

      if (type === "pre") {
        if (startIndex === 0) return;
        startIndex -= showCount;
        endIndex -= showCount;
      } else if (type === "next") {
        if (endIndex === this.dateList.length) return;
        startIndex += showCount;
        endIndex += showCount;
      }
      this.initDatePickerList(startIndex, endIndex);
    },
    /**
     * 获取每日战报详情
     */
    getActivityDailyAchievements() {
      this.loading = true;
      const params = {
        activityId: this.activityId,
        date: this.dateList[this.currentDateIndex].date,
      };
      api
        .getActivityDailyAchievements(params)
        .then((res) => {
          this.data = res.data.data;
          this.ifShowTooltip = moment(this.$route.query.startTime)
            .startOf("day")
            .isBefore(moment(this.data.createTime).startOf("day"));
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 导出图片
     */
    handleExportPicture() {
      this.loading = true;
      this.isToPicture = true;
      console.log(window.devicePixelRatio);
      setTimeout(() => {
        html2canvas(this.$refs.imgWrapper, {
          allowTaint: true, // 默认值 false 是否允许跨域图像污染画布
          useCORS: true, // 默认值 false 是否尝试使用CORS从服务器加载图像
          scale: 3,
        })
          .then((canvas) => {
            this.loading = false;
            this.isToPicture = false;
            const link = document.createElement("a");
            link.href = canvas.toDataURL();
            const date = format(new Date(), "yyyy-MM-dd");
            link.setAttribute("download", `每日战报${date}-23:59:59.jpg`);
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(() => {
            this.loading = false;
            this.isToPicture = false;
          });
      }, 0);
    },
    /**
     * 导出excel
     */
    handleExportExcel() {
      exportTable(
        this.$http,
        {
          activityId: this.activityId,
          date: this.dateList[this.currentDateIndex].date,
        },
        "afantiactivity",
        "/jjt/activity/web/alert/report/noJoinDealersExport",
        "filename"
      );
    },
    formatNum(num) {
      return (num ?? "") !== "" ? num.toLocaleString() : "-";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
@import "@/assets/styles/flex.scss";

@mixin font_w88($fontSize) {
  font-size: $fontSize;
  @include font_color("font_color58");
}

@mixin font_grey($fontSize) {
  font-size: $fontSize;
  @include font_color("font_color23");
}

@mixin font_yellow($fontSize) {
  font-size: $fontSize;
  color: #ffcc55;
}

@mixin text_ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::v-deep .container .ant-progress {
  &-inner {
    background-color: transparent !important;
  }

  &-circle {
    path:first-child {
      stroke: #41444f !important;
    }
  }
}

::v-deep .card-body .ant-collapse-item {
  border-bottom: none;
}

::v-deep .card-body .ant-collapse-header {
  background: #242832;
  color: rgba(255, 255, 255, 0.88);
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
}

::v-deep .card-body .ant-collapse-borderless,
.card-body .ant-collapse-content {
  background: transparent !important;
}

::v-deep .card-body .ant-collapse-content-box {
  padding: 0 20px;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.container {
  position: relative;
  @include font_color("font_color58");

  & > .img-wrapper {
    position: relative;
    background: #1c1e27;

    .rank-card-item {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .card-body {
      min-height: 390px;
      flex: 1;
    }
  }

  .ml-8 {
    margin-left: 8px;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .daily-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 40px;
    padding: 0 18px;
    border-radius: 8px;
    line-height: 40px;
    font-size: 14px;
    border: none;
    @include background_color("background_color42");
    @include font_color("font_color20");
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
      color: rgba(255, 255, 255, 0.88);

      &.disabled {
        color: rgba(255, 255, 255, 0.3);
      }
    }

    &.arrow {
      width: 40px;
      padding: 0;
    }

    &.left {
      rotate: (180deg);
    }
  }

  .second-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;

    & > div {
      display: flex;
      align-items: center;

      .title {
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .date-label {
      font-size: 14px;
      color: #9ca8ba;
    }

    .date-value {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
    }
  }

  .alert-tip {
    margin-bottom: 16px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 9px 40px;
    border-radius: 6px;
    background: #4d3621;

    ::v-deep .ant-alert-message {
      color: rgba(255, 255, 255, 0.88);
    }

    ::v-deep .ant-alert-icon {
      color: #ff9026;
    }
  }

  .tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    &-left {
      display: flex;
      align-items: center;
    }
  }

  .title-header {
    font-size: 16px;
    padding: 0 20px;
  }
}

.statistics-card {
  display: grid;
  grid-template-columns: repeat(auto, 1fr);
  grid-gap: 15px;
  padding: 20px;

  &-item {
    @include background_color("background_color2");
    border-radius: 16px;
    padding: 20px;

    .title {
      font-size: 16px;
    }

    .sub-title {
      font-size: 14px;
      @include font_color("font_color23");
    }

    .card-data {
      margin-top: 20px;
    }

    .count {
      font-size: 20px;
      @include font_color("font_color20");
    }
  }
}

.rank-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  align-items: stretch;
  padding: 16px 20px 0 16px;

  .card-title {
    width: 100%;
    height: 72px;
    background: url("../../../assets/live_images/list-title-bc.png") no-repeat
        right 0,
      linear-gradient(90deg, #ffe674 0%, #ffc840 100%);
    background-size: cover;
    padding-left: 30px;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &.no-bg {
      background: none;
      padding: 20px 20px 10px 20px;
      height: auto;
      @include background_color("background_color2");

      > span {
        line-height: 1;
        @include font_color("font_color58");
      }
    }

    & > span {
      font-size: 16px;
      font-weight: bold;
      color: #845303;
      line-height: 32px;
    }

    .line {
      width: 24px;
      height: 2px;
      background: #845303;
    }
  }

  .card-body {
    height: 390px;
    border-radius: 0 0 16px 16px;
    @include background_color("background_color2");

    &-header {
      // height: 40px;
      padding: 10px 0;
      flex-shrink: 0;
      @include font_grey(12px);

      & > :first-child {
        padding-left: 20px;
      }

      & > :last-child {
        padding-right: 20px;
      }
    }

    &-list {
      // padding: 0 20px;
      overflow-y: auto;
    }

    &-list-item {
      padding: 10px 0;
      border-bottom: 1px solid #3c4252;
      cursor: pointer;

      &:hover {
        background: #20232c;
      }

      &:last-child {
        border-bottom: none;
      }

      & > :first-child {
        padding-left: 20px;
      }

      & > :last-child {
        padding-right: 20px;
      }

      .rank-count {
        @include font_yellow(14px);
      }
    }

    .rank-number {
      flex: 1;
      flex-shrink: 0;

      .ranking {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: rgba(255, 255, 255, 0.12);
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);
        line-height: 24px;
        border-radius: 50%;
        text-align: center;

        &.ranking1,
        &.ranking2,
        &.ranking3 {
          background: rgba(255, 204, 85, 0.12);
          color: #ffcc55;
          font-weight: bold;
        }
      }
    }

    .rank-area {
      flex: 4;
      flex-shrink: 0;
      overflow: hidden;

      .avatar {
        width: 24px;
        height: 24px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 12px;
        }
      }

      .dealer-name {
        @include font_w88(14px);
        @include text_ellipsis();
      }

      .region-name {
        @include font_grey(12px);
        @include text_ellipsis();
      }
    }

    .rank-count {
      flex: 2;
      flex-shrink: 0;
      text-align: right;
    }

    .rank-title-count {
      flex: 6;
      overflow: hidden;

      .title {
        @include text_ellipsis();
      }

      .sub-title {
        @include font_grey(12px);
      }

      .count {
        @include font_yellow(12px);
      }
    }

    .area-name {
      height: 50px;
      line-height: 50px;
      @include font_color("font_color58");
    }
  }
}
</style>
