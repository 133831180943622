<template>
  <div>
    <div class="title">直播详情</div>
    <a-spin :spinning="spinning">
      <div class="main-container">
        <div class="main-container-left">
          <div class="main-container-left-card">
            <div class="top">
              <div class="img-div">
                <WebpImage
                  :src="liveDetail ? liveDetail.cover : null"
                  :width="'72px'"
                  :height="'72px'"
                  :radius="'0px'"
                />
              </div>
              <div class="mask-play" @click="openLive(liveDetail)">
                <svg-icon
                  v-if="!liveDetail.play_url"
                  :icon-class="'no-video'"
                  class="lock-icon"
                />
                <svg-icon
                  v-if="
                    liveDetail.play_url &&
                      liveDetail.url_state == ('Archive' || 'Unknown') &&
                      liveDetail.is_restore_completed == '1'
                  "
                  :icon-class="'unlock'"
                  class="lock-icon"
                />
                <svg-icon
                  v-else-if="
                    liveDetail.play_url &&
                      liveDetail.url_state == ('Archive' || 'Unknown') &&
                      liveDetail.is_restore_completed == ('0' || '3')
                  "
                  :icon-class="'lock'"
                  class="lock-icon"
                />
                <svg-icon
                  v-else-if="liveDetail.play_url"
                  :icon-class="'play'"
                  class="mask-icon"
                />
              </div>

              <div>
                <a-tooltip>
                  <template slot="title">{{ liveDetail.title }}</template>

                  <div class="ellipsis">
                    {{ liveDetail.title }}
                  </div>
                </a-tooltip>
                <div style="display: flex">
                  <WebpImage
                    :src="liveDetail.avatar"
                    :width="'24px'"
                    :height="'24px'"
                    :radius="'50%'"
                    style="margin-right: 8px"
                  />
                  <a-tooltip>
                    <template slot="title">{{ liveDetail.nickname }}</template>
                    <span style="width: calc(100% - 10px)" class="ellipsis-1">{{
                      liveDetail.nickname
                    }}</span>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="card-detail">
              <div class="row">
                <span class="card-detail-label"><i>平</i><i>台: </i></span
                ><span class="card-detail-txt">{{ liveDetail.plat_form }}</span>
              </div>
              <div class="row">
                <span class="card-detail-label"><i>日</i><i>期: </i></span
                ><span class="card-detail-txt">{{ liveDetail.full_date }}</span>
              </div>
              <div class="row">
                <span class="card-detail-label"
                  ><i>开</i><i>播</i><i>时</i><i>间: </i></span
                ><span class="card-detail-txt">{{
                  liveDetail.new_publish_time
                }}</span>
              </div>
              <div class="row">
                <span class="card-detail-label"
                  ><i>下</i><i>播</i><i>时</i><i>间: </i></span
                ><span class="card-detail-txt">{{
                  liveDetail.new_finish_time
                }}</span>
              </div>
              <div class="lock-div">
                <div class="btn3" v-if="!liveDetail.play_url">暂无视频</div>
                <a-button
                  type="primary"
                  class="btn1"
                  @click="unlockClick"
                  v-if="
                    liveDetail.play_url &&
                      liveDetail.url_state == ('Archive' || 'Unknown') &&
                      liveDetail.is_restore_completed == ('0' || '3')
                  "
                  :loading="lockLoading"
                >
                  解冻
                </a-button>
                <div
                  class="btn3"
                  v-if="
                    liveDetail.play_url &&
                      liveDetail.url_state == ('Archive' || 'Unknown') &&
                      liveDetail.is_restore_completed == '1'
                  "
                >
                  解冻中
                </div>
                <div class="btn2" @click="toRecord">解冻记录</div>
              </div>
              <div class="count-div">
                <div
                  class="count-item"
                  v-for="(item, index) in liveDetail.count_data"
                  :key="index"
                >
                  <span>
                    {{ item.title }}
                    <AftTooltip
                      v-if="
                        item.title == '观看人数' || item.title == '观看人次'
                      "
                      :code="'qjzb11'"
                      style="top: 2px; left: 4px"
                    />
                  </span>
                  <span class="important3" v-if="item.title == '直播时长'">{{
                    item.number | formatDuration
                  }}</span>
                  <!-- <span
                  class="important3"
                  v-else-if="item.title == '人均观看时长'"
                  >{{ item.number | formatDuration2 }}</span
                > -->
                  <span
                    class="important3"
                    v-else-if="item.title == '平均在线'"
                    >{{
                      (item.number == null ? "-" : Number(item.number))
                        | changeNum2
                    }}</span
                  >
                  <span class="important3" v-else>{{
                    (item.number == null ? "-" : Number(item.number))
                      | changeNum
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-container-right">
          <a-tabs default-active-key="tab1">
            <a-tab-pane key="tab1" tab="趋势表现">
              <div class="tab1-time" @dblclick="routerToAssistant">
                *监测始于 {{ liveDetail.publish_time }}
              </div>
              <div
                class="chart-top"
                v-if="liveDetail && liveDetail.statistics_even_life_time"
              >
                <span class="chart-title"
                  ><svg-icon
                    :icon-class="'title'"
                    class="card-title-icon"
                  />获赞变化趋势</span
                >
              </div>
              <div
                class="chart-div"
                v-if="liveDetail && liveDetail.statistics_even_life_time"
              >
                <my-chart
                  :options="
                    getLineOption(
                      liveDetail.statistics_even_life_time.time,
                      liveDetail.statistics_even_life_time.like_count
                    )
                  "
                  :id="'line-chart-digg'"
                ></my-chart>
              </div>

              <div
                class="chart-top"
                v-if="liveDetail && liveDetail.statistics_even_life_time"
              >
                <span class="chart-title"
                  ><svg-icon
                    :icon-class="'title'"
                    class="card-title-icon"
                  />评论变化趋势</span
                >
              </div>
              <div
                class="chart-div"
                v-if="liveDetail && liveDetail.statistics_even_life_time"
              >
                <my-chart
                  :options="
                    getLineOption(
                      liveDetail.statistics_even_life_time.time,
                      liveDetail.statistics_even_life_time.comment_count
                    )
                  "
                  :id="'line-chart-comment'"
                ></my-chart>
              </div>

              <div
                class="chart-top"
                v-if="liveDetail && liveDetail.statistics_even_life_time"
              >
                <span class="chart-title"
                  ><svg-icon :icon-class="'title'" class="card-title-icon" />{{
                    platform | returnWatchText
                  }}趋势</span
                >
              </div>
              <div
                class="chart-div"
                v-if="liveDetail && liveDetail.statistics_even_life_time"
              >
                <my-chart
                  :options="
                    getLineOption(
                      liveDetail.statistics_even_life_time.time,
                      liveDetail.statistics_even_life_time.watch_cnt
                    )
                  "
                  :id="'line-chart-share'"
                ></my-chart>
              </div>
            </a-tab-pane>
            <a-tab-pane
              key="tab2"
              tab="观众画像"
              v-if="platform != 'dongchedi'"
            >
              <div class="tab1-time" @dblclick="routerToAssistant">
                *监测始于 {{ liveDetail.publish_time }}
              </div>
              <div class="chart-top">
                <span class="chart-title"
                  ><svg-icon
                    :icon-class="'title'"
                    class="card-title-icon"
                  />地域分析</span
                >
              </div>
              <div class="chart-div audience">
                <div class="card-bg" style="margin-right: 24px">
                  <div class="table-container">
                    <div class="table-title">
                      <span style="width: 20%">序号</span>
                      <span style="width: 52%">省份名称</span>
                      <span style="width: 28%">访客占比</span>
                    </div>
                    <div
                      style="margin-top: 60px;"
                      v-if="
                        platform == 'douyin' && liveDetail.open_user_info != 1
                      "
                    >
                      <Empty title="主播已隐藏观众信息"></Empty>
                    </div>
                    <div
                      v-else
                      class="table-content"
                      v-for="(item, index) in areaList.province || []"
                      :key="index"
                    >
                      <span style="width: 20%">
                        <a-badge
                          :offset="[3, 18]"
                          :color="
                            Number(index + 1) == 1
                              ? '#FAA065'
                              : Number(index + 1) == 2
                              ? '#22C5E6'
                              : '#7A91FF'
                          "
                          :number-style="{
                            boxShadow: '0',
                          }"
                          v-if="Number(index) < 3"
                        >
                          0{{ index + 1 }}
                        </a-badge>
                        <span v-else
                          ><span v-if="index < 9">0</span>{{ index + 1 }}</span
                        >
                      </span>
                      <span style="width: 52%">{{ item.province }}</span>
                      <span style="width: 28%">{{
                        item.percentage | numRateAbs
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="card-bg">
                  <div class="table-container">
                    <div class="table-title">
                      <span style="width: 20%">序号</span>
                      <span style="width: 52%">城市名称</span>
                      <span style="width: 28%">访客占比</span>
                    </div>
                    <div
                      style="margin-top: 60px;"
                      v-if="
                        platform == 'douyin' && liveDetail.open_user_info != 1
                      "
                    >
                      <Empty title="主播已隐藏观众信息"></Empty>
                    </div>
                    <div
                      v-else
                      class="table-content"
                      v-for="(item, index) in areaList.city || []"
                      :key="index"
                    >
                      <span style="width: 20%">
                        <a-badge
                          :offset="[3, 18]"
                          :color="
                            Number(index + 1) == 1
                              ? '#FAA065'
                              : Number(index + 1) == 2
                              ? '#22C5E6'
                              : '#7A91FF'
                          "
                          :number-style="{
                            boxShadow: '0',
                          }"
                          v-if="Number(index) < 3"
                        >
                          0{{ index + 1 }}
                        </a-badge>
                        <span v-else
                          ><span v-if="index < 9">0</span>{{ index + 1 }}</span
                        >
                      </span>
                      <span style="width: 52%">{{ item.city }}</span>
                      <span style="width: 28%">{{
                        item.percentage | numRateAbs
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="chart-top">
                <span class="chart-title"
                  ><svg-icon
                    :icon-class="'title'"
                    class="card-title-icon"
                  />性别分析</span
                >
              </div>
              <div
                style="margin-top: 35px;"
                v-if="platform == 'douyin' && liveDetail.open_user_info != 1"
              >
                <Empty title="主播已隐藏观众信息"></Empty>
              </div>
              <div v-else class="chart-div">
                <my-chart
                  :options="getGenderOption(genderList)"
                  :id="'fansGenderChart'"
                />
              </div>
            </a-tab-pane>
            <a-tab-pane key="tab3" tab="评论分析" force-render>
              <div class="tab1-time" @dblclick="routerToAssistant">
                *监测始于 {{ liveDetail.publish_time }}
              </div>
              <div class="chart-top">
                <span class="chart-title"
                  ><svg-icon
                    :icon-class="'title'"
                    class="card-title-icon"
                  />直播间评论</span
                >
              </div>
              <div class="praised-wrap" v-if="commentsData">
                <div class="pyramids-box">
                  <div class="row">
                    <span class="name">所有评论</span>
                    <span class="num">{{
                      commentsData.total || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="row">
                    <span class="name">有效评论</span>
                    <span class="num">{{
                      commentsData.validate_comment_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="row">
                    <span class="name">意向评论</span>
                    <span class="num">{{
                      commentsData.clues_count || 0 | changeNum
                    }}</span>
                  </div>
                </div>
                <div class="data-box">
                  <div class="comment-title">
                    <svg-icon :icon-class="'comment'" class="card-title-icon" />
                    评论总数
                    <span class="total-num">{{ commentsData.total }}</span>
                  </div>
                  <div
                    class="row"
                    v-for="(item, index) in commentsData.types"
                    :key="index"
                  >
                    <div class="name">
                      {{
                        item.type == "SERIES"
                          ? "车系"
                          : item.type == "PRICE"
                          ? "价格"
                          : item.type == "BRAND_OR_MANUFACTOR"
                          ? "品牌"
                          : item.type == "CONFIG"
                          ? "配置"
                          : item.type == "CLUE"
                          ? "意向"
                          : "--"
                      }}相关
                    </div>
                    <div class="rate">
                      <span
                        ><svg-icon
                          :icon-class="'md-pie_chart'"
                          class="card-title-icon"/></span
                      >{{ (item.count / commentsData.total) | numRate2 }}
                    </div>
                    <div class="num">
                      <span
                        ><svg-icon
                          :icon-class="'comment'"
                          class="card-title-icon"/></span
                      >{{ item.count | changeNum }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="space-80"></div>

              <div class="chart-top">
                <span class="chart-title"
                  ><svg-icon
                    :icon-class="'title'"
                    class="card-title-icon"
                  />评论分类</span
                >
              </div>
              <div class="search-wrap">
                <div class="type-wrap">
                  <span
                    :class="
                      comment_type === item.id ? 'selected radio' : 'radio'
                    "
                    v-for="item in comment_type_list"
                    :key="item.id"
                    @click="onChangeType(item.id)"
                  >
                    {{ item.label }}
                  </span>
                </div>
                <a-input
                  v-model="keyword"
                  placeholder="输入关健字搜索"
                  class="search-input"
                  @pressEnter="onSearchKeyword"
                >
                  <a-icon slot="prefix" type="search" class="search-btn" />
                </a-input>
              </div>

              <div class="comment-list-wrap">
                <a-spin tip="" :spinning="commentsLoading">
                  <div class="comment-title">
                    <svg-icon :icon-class="'comment'" class="card-title-icon" />
                    评论列表
                    <span
                      class="num"
                      v-if="commentsList && commentsList.pagination"
                      >（{{ commentsList.pagination.total }}）</span
                    >
                  </div>
                  <div
                    class="list-wrap"
                    v-infinite-scroll="handleInfiniteOnLoad"
                    infinite-scroll-disabled="busy"
                    infinite-scroll-distance="100"
                  >
                    <div
                      class="wrap"
                      v-for="(item, index) in comments_total_list"
                      :key="index"
                    >
                      <div class="row">
                        <span
                          class="avatar"
                          :style="`background-image: url(${item.avatar})`"
                        ></span>
                        <span class="name">{{ item.user_name }}</span>
                        <span class="time">{{ item.publish_time }}</span>
                      </div>
                      <div class="txt">{{ item.content }}</div>
                    </div>
                    <div class="no-more" v-if="noMore">没有更多数据了</div>
                  </div>
                </a-spin>
              </div>
              <div class="space-80"></div>

              <div class="chart-top">
                <span class="chart-title"
                  ><svg-icon
                    :icon-class="'title'"
                    class="card-title-icon"
                  />词云分析</span
                >
              </div>
              <div class="chart-div">
                <my-chart
                  :options="getWordCloudOption(hotwordsList)"
                  :id="'cloud-chart-word'"
                ></my-chart>
              </div>
            </a-tab-pane>
            <a-tab-pane key="tab4" tab="车系热度">
              <div class="tab1-time" @dblclick="routerToAssistant">
                *监测始于 {{ liveDetail.publish_time }}
              </div>
              <div class="chart-top">
                <span class="chart-title"
                  ><svg-icon
                    :icon-class="'title'"
                    class="card-title-icon"
                  />车型热度TOP5</span
                >
              </div>
              <div class="hot-top-box">
                <ul
                  class="audience-address series-list"
                  v-if="seriesList && seriesList.length > 0"
                >
                  <li
                    v-for="(item, index) in seriesList"
                    :key="index"
                    @click="routerTo(item)"
                  >
                    <div class="car-img">
                      <img :src="item.image" alt="汽车图片" />
                    </div>
                    <div class="content">
                      <div class="province">
                        <span>{{ item.series_name }}</span>
                        <span>{{ item.count || 0 }}</span>
                      </div>
                      <Progress
                        :stroke-width="10"
                        :show-outside="true"
                        :percentage="item.rate"
                      />
                    </div>
                  </li>
                </ul>
                <div
                  v-else
                  class="font-color3"
                  style="margin-top: 50px; text-align: center; flex: 1"
                >
                  暂无数据
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </a-spin>
    <a-modal
      v-model="dialogVisible"
      ref="modal"
      title="直播回放"
      @cancel="handleClose"
      :footer="null"
    >
      <video
        id="videoElement"
        autoplay
        controls
        muted
        style="width: 100%; height: 100%; border-radius: 10px"
      ></video>
    </a-modal>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import Progress from "./components/Progress";
import chartLine from "./chartLine";
import chartWord from "./chartWord";
import chartPie from "./chartPie";
import deepClone from "@/utils/clone-utils";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import infiniteScroll from "vue-infinite-scroll";
import WebpImage from "@/components/WebpImage/WebpImage";
import api from "@/api/globalDetail";
import Empty from "@/components/Empty/Empty.vue";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  "globalDetail"
);
export default {
  name: "liveDetail",
  directives: { infiniteScroll },
  components: { MyChart, Progress, WebpImage, Empty },
  data() {
    return {
      msg: "直播详情",
      defaultAvatar: require("@/assets/img/defult-avatar.png"),
      flvPlayer: null,
      dialogVisible: false,
      comment_type: "",
      comment_type_list: [
        { id: "", label: "全部评论" },
        { id: "SERIES", label: "车系" },
        { id: "BRAND_OR_MANUFACTOR", label: "品牌" },
        { id: "PRICE", label: "价格" },
        { id: "CONFIG", label: "配置" },
        { id: "CLUE", label: "意向" },
      ],
      keyword: "",
      comments_total_list: [],
      busy: false,
      current: 1,
      page_size: 25,
      noMore: false,
      word_base_list: [],
      lockLoading: false, // 解冻按钮loading状态
      platform: this.$route.query.platform,
      spinning: false,
    };
  },

  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatDuration2(number) {
      if (!number || number === 0) {
        return "0分0秒";
      } else {
        let m = (number / 60) | 0;
        let s = number % 60;
        return `${m}分${s}秒`;
      }
    },
  },
  created() {
    this.changeState({ data: {}, name: "livedetail" });
  },
  async mounted() {
    console.log("livedetail", this.liveDetail);

    if (this.$route.query.id) {
      this.platform = this.$route.query.platform;
      let params = {
        room_id: this.$route.query.id,
        platform: this.$route.query.platform,
      };
      this.spinning = true;
      await this.getLiveDetail(params);
      this.spinning = false;
      this.getCommentsDetail(params);
      // this.getCommentsList(params)
      this.getTypeCommentsList();

      this.getHotwordsList(params);
      this.getAreaList(params);
      this.getGenderList(params);
      this.getSeriesList(params);
    } else {
      console.log("没有获取到直播间信息");
    }
  },
  computed: {
    ...mapState({
      liveDetail: (state) => state.liveDetail,
      commentsData: (state) => state.commentsData,
      commentsList: (state) => state.commentsList,
      commentsLoading: (state) => state.commentsLoading,
      hotwordsList: (state) => state.hotwordsList,
      areaList: (state) => state.areaList,
      genderList: (state) => state.genderList,
      seriesList: (state) => state.seriesList,
    }),
  },
  methods: {
    ...mapActions([
      "getLiveDetail",
      "getCommentsDetail",
      "getCommentsList",
      "getHotwordsList",
      "getAreaList",
      "getGenderList",
      "getSeriesList",
    ]),
    ...mapMutations(["changeState"]),
    getLineOption(xData, yData) {
      let option = deepClone(chartLine);
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      return option;
    },
    getWordCloudOption(wData) {
      let option = deepClone(chartWord);
      if (wData) {
        wData.map((item) => {
          item.name = item.word;
          item.value = item.frequence;
        });
      }
      option.series[0].data = wData || this.word_base_list;
      console.log("guanjianci:", wData, option);
      return option;
    },
    getGenderOption(data) {
      let option = deepClone(chartPie);

      let series_data = [];
      let desc = [];
      if (data && data.gender && data.gender.length > 0) {
        data.gender.map((item, index) => {
          item == 1
            ? desc.push("男")
            : item == 2
            ? desc.push("女")
            : desc.push("未知");
          series_data.push({
            value: data.percentage[index],
            name: desc[index],
          });
        });
      }

      option.legend.data = desc;
      option.series[0].data = series_data;
      return option;
    },
    // 点击直播回放按钮
    openLive(detail) {
      console.log(detail);
      if (detail.play_url) {
        if (detail.url_state == "Archive" || detail.url_state == "Unknown") {
          if (
            detail.is_restore_completed == "0" ||
            detail.is_restore_completed == "3"
          ) {
            this.$message.warning(
              "直播视频已被冻结,可点击解冻按钮解冻 ( 预计1分钟解冻 )"
            );
            return false;
          } else if (detail.is_restore_completed == "1") {
            this.$message.warning(
              "解冻中,可点击解冻记录查看进度 ( 24h 后冻结 )"
            );
            return false;
          }
        }
        this.dialogVisible = true;
        console.log(detail.play_url);
        this.getStream(detail.play_url);
      } else {
        this.$message.warning("暂无该直播视频，请联系管理员...");
      }
    },
    // 初始化flv
    getStream(source) {
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          console.log(1, source);
          this.flvPlayer = flvjs.createPlayer({
            type: source.indexOf(".mp4") > 0 ? "mp4" : "flv",
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          // this.flvPlayer.currentTime = 600
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            this.handleClose();
            this.$message.warning("暂无该直播视频，请联系管理员...");
          });
        }
      });
    },
    // 关闭 flv 视频弹窗事件
    handleClose() {
      this.dialogVisible = false;
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
      }
      this.$refs.modal.$destroyAll();
    },
    onChangeType(id) {
      this.comment_type = id;
      this.current = 1;
      this.noMore = false;
      this.getTypeCommentsList();
    },
    onSearchKeyword() {
      this.current = 1;
      this.noMore = false;
      this.getTypeCommentsList();
    },
    //根据类型/关键词搜索评论列表
    getTypeCommentsList() {
      // if(this.commentsLoading) {
      //   return
      // }
      this.busy = true;
      if (this.noMore) {
        return false;
      }

      new Promise((resolve, reject) => {
        let params = {
          room_id: this.$route.query.id,
          platform: this.$route.query.platform,
          current: this.current,
          page_size: this.page_size,
        };
        !this.comment_type ? "" : (params.type = this.comment_type);
        !this.keyword ? "" : (params.word = this.keyword);
        resolve(this.getCommentsList(params));
      }).then((res) => {
        this.busy = false;

        if (this.current == 1) {
          this.comments_total_list = [];
        }
        if (
          this.commentsList &&
          this.commentsList.list &&
          this.commentsList.list.length > 0
        ) {
          const arr = this.comments_total_list;
          this.comments_total_list = arr.concat(this.commentsList.list);
        } else {
          this.comments_total_list = [];
        }
        if (
          this.commentsList &&
          this.commentsList.pagination &&
          this.current >= this.commentsList.pagination.pages
        ) {
          this.noMore = true;
          return false;
        }
        this.current++;
      });
    },
    // 滚动加载评论
    handleInfiniteOnLoad() {
      if (
        this.commentsList &&
        this.commentsList.pagination &&
        this.current <= this.commentsList.pagination.pages
      ) {
        this.getTypeCommentsList();
      } else {
        this.busy = true;
      }
    },
    routerTo(item) {
      let routeUrl = `/model/modelDetail?id=${item.series_id}&platform=${this.$route.query.platform}`;
      window.open(routeUrl, "_blank");
    },
    unlockClick() {
      if (this.lockLoading) {
        return false;
      }
      console.log(this.liveDetail);
      // 调用解冻接口
      this.lockLoading = true;
      let body = {
        room_id: this.$route.query.id,
        platform: this.$route.query.platform,
      };
      api.updeteRetore(body).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$message.success("解冻成功");
          this.lockLoading = false;
          this.getLiveDetail(body);
        } else {
          this.$message.error("解冻失败");
          this.lockLoading = false;
        }
      });
    },
    toRecord() {
      let routeUrl = this.$router.resolve({
        path: `/liveAndVideo/thawingRecord`,
        query: {
          platform: this.$route.query.platform,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    routerToAssistant() {
      if (this.$route.query.id) {
        let url = `/liveAssistant?id=${this.$route.query.id}&platform=${this.$route.query.platform}`;
        this.$router.push(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
  },
  beforeDestroy() {
    if (this.flvPlayer) {
      this.flvPlayer.destroy();
    }
    this.$refs.modal.$destroyAll();
    this.changeState({ data: null, name: "liveDetail" });
    this.changeState({ name: "current", data: 1 });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.main-container {
  width: 100%;
  @include font_color("font_color2");
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  &-left {
    width: 260px;
    height: calc(100vh - 160px);
    min-height: 644px;
    margin-right: 24px;
    border-radius: 8px;
    &-card {
      padding: 24px 16px 24px 24px;
      height: 100%;
      background: #242832;
      border-radius: 8px;
      .top {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-bottom: 20px;
        position: relative;

        .mask-play {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 72px;
          height: 96px;
          cursor: pointer;
          @include background_color("background_color20");
          position: absolute;
          top: 0px;
          left: 0px;
          .mask-icon {
            @include font_color("font_color1");
            font-size: 40px;
          }
          .lock-icon {
            font-size: 20px;
          }
        }

        .ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .ellipsis-1 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          @include font_color("font_color3");
        }
        > img {
          width: 72px;
          height: 96px;
          @include border_color("border_color7");
          margin-right: 8px;
        }
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        }

        > .img-div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 72px;
          height: 96px;
          @include background_color("background_color43");
          margin-right: 8px;
          > img {
            width: 72px;
            max-height: 96px;
          }
        }
      }
      .card-detail {
        padding: 10px 0 50px;
        line-height: 30px;
        .row {
          display: flex;
        }
        &-label {
          margin-right: 8px;
          display: flex;
          justify-content: space-between;
          width: 62px;
          color: #8890a6;
          i {
            font-style: normal;
          }
        }
        &-txt {
          flex: 1;
        }
        .important {
          @include font_color("font_color4");
        }
        .important2 {
          @include font_color("font_color7");
        }
        .lock-div {
          display: flex;
          margin-top: 16px;
          > div,
          > .btn1 {
            width: 100px;
            height: 36px;
            border-radius: 8px;
            cursor: pointer;
            text-align: center;
            line-height: 36px;
            border: none;
          }
          .btn1 {
            @include font_color("font_color17");
            @include background_color("background_color34");
            margin-right: 12px;
          }
          .btn2 {
            @include font_color("font_color26");
            @include background_color("background_color79");
          }
          .btn3 {
            @include font_color("font_color23");
            @include background_color("background_color62");
            margin-right: 12px;
            cursor: not-allowed;
          }
        }
        .count-div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 40px;
          .count-item {
            display: flex;
            flex-direction: column;
            line-height: 20px;
            font-size: 12px;
            width: 50%;
            padding-left: 8px;
            margin-bottom: 24px;
            border-left: 3px solid rgba(0, 0, 0, 0);
            @include border_color("border_color8");
            @include font_color("font_color3");
            .important3 {
              @include font_color("font_color1");
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  &-right {
    width: calc(100% - 284px);
    .tab1-time {
      text-align: right;
      font-size: 12px;
      @include font_color("font_color3");
      margin-bottom: 12px;
    }
    .chart-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .chart-title {
        font-size: 16px;
        @include font_color("font_color2");

        .card-title-icon {
          @include font_color("font_color5");
          font-size: 18px;
          margin: 0 8px;
        }
      }
    }
    .chart-div {
      height: 340px;
      margin-bottom: 32px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color1");
    }

    .comment-title {
      font-size: 12px;
    }
    .praised-wrap {
      margin-top: 22px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      .pyramids-box {
        margin-top: 20px;
        width: 250px;
        height: 204px;
        background-image: url("../../assets/img/matrix3.png");
        .row {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 52px;
          & + .row {
            margin-top: 24px;
          }
        }
        .name {
          padding-right: 8px;
          font-size: 12px;
          @include font_color("font_color1");
        }
        .num {
          font-size: 16px;
          @include font_color("font_color1");
        }
      }
      .data-box {
        flex: 1;
        margin-left: 40px;
        .row {
          margin-top: 8px;
          display: flex;
          align-items: center;
          width: 305px;
          height: 36px;
          line-height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(37, 50, 85, 1);
          @include border_color("border_color1");
        }
        .name {
          padding: 0 12px;
          margin-right: 30px;
          text-align: center;
          @include font_color("font_color3");
          @include background_color("background_color28");
        }
        span {
          padding-right: 4px;
          @include font_color("font_color3");
        }
        .rate {
          width: 100px;
          @include font_color("font_color2");
        }
        .num {
          flex: 1;
          @include font_color("font_color2");
        }
      }
    }

    .space-80 {
      margin-top: 80px;
    }

    .search-wrap {
      margin: 16px 8px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .type-wrap {
        display: flex;
      }
      .radio {
        margin-right: 18px;
        cursor: pointer;
        display: block;
        @include font_color("font_color3");
        &.selected {
          @include font_color("font_color1");
        }
      }
      .search-input {
        width: 246px;
        height: 36px;
        /deep/ .ant-input::placeholder {
          @include font_color("font_color15");
        }
      }
      .search-btn {
        cursor: pointer;
        @include font_color("font_color3");
      }
    }
    .comment-list-wrap {
      position: relative;
      z-index: 1000;
      margin: 0 8px;
      width: 530px;
      .comment-title {
        margin-bottom: 16px;
      }
      .list-wrap {
        // max-height: 370px;
        height: 370px;
        overflow-y: auto;
        // overflow:vertical
      }
      .wrap + .wrap {
        margin-top: 16px;
      }
      .row {
        display: flex;
        align-items: center;
      }
      .avatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        overflow: hidden;
      }
      .name {
        margin: 0 8px;
        @include font_color("font_color2");
      }
      .time {
        @include font_color("font_color3");
      }
      .txt {
        margin: 8px 0 0 28px;
        @include font_color("font_color1");
      }
      .no-more {
        text-align: center;
        margin: 10px 0;
      }
    }
  }

  @mixin hideSCroll {
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
  .audience {
    display: flex;
    margin: 10px 5px 70px;
    width: 100%;

    .card-bg {
      @include background_color("background_color2");
      height: 329px;
      overflow-y: auto;
      width: calc(50% - 12.5px);

      .table-container {
        padding: 0 16px;
        .table-title {
          height: 52px;
          line-height: 52px;
          display: flex;
          flex-direction: row;
          @include font_color("font_color2");
          font-size: 14px;
        }
        .table-content {
          display: flex;
          flex-direction: row;
          height: 56px;
          line-height: 56px;
          border-top: 1px solid rgba(0, 0, 0, 0);
          @include border_color("border_color1");
          @include font_color("font_color2");
        }
        div span {
          @include font_color("font_color2");
        }
        .table-content span:last-child {
          @include font_color("font_color3");
        }
      }
    }
    &-chart {
      flex: 1;
    }
    &-address {
      flex: 1;
      height: 100%;
      overflow-y: auto;
      padding-left: 30px;
      @include hideSCroll();
      width: 80%;
      li {
        width: 100%;
        font-size: 12px;
        color: #8f94a2;
        padding-bottom: 16px;
        .province {
          display: flex;
          justify-content: space-between;
          padding-bottom: 4px;
          padding-right: 3px;
        }
      }
    }
    &-address.series-list {
      li {
        transition: all 0.5s;
        cursor: pointer;
      }
      li:hover {
        @include background_color("background_color14");
      }
    }
  }

  .hot-top-box {
    display: flex;
    margin: 10px 5px 70px;
    li {
      display: flex;
      align-items: center;
    }
    .car-img {
      width: 132px;
      max-height: 100px;
      overflow: hidden;
      margin-right: 16px;
    }
    img {
      max-width: 100%;
    }
    .content {
      flex: 1;
      padding-right: 3px;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/styles/_handle.scss";
.ant-badge-dot {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
