<template>
  <a-drawer
    placement="right"
    :width="736"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>查看活动</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <a-spin :spinning="detailLoading">
          <div class="drawer-main-flex">
            <div class="flex-label">活动名称：</div>
            <div class="flex-value">
              {{ currentItem.activityName || "-" }}
            </div>
          </div>
          <div class="drawer-main-flex">
            <div class="flex-label">活动日期：</div>
            <div class="flex-value">
              {{ returnDate(currentItem.startTime) }} 至
              {{ returnDate(currentItem.endTime) }}
            </div>
          </div>
          <div class="drawer-main-flex">
            <div class="flex-label">参与范围：</div>
            <div class="flex-value">
              <template
                v-if="currentItem.scopeList && currentItem.scopeList.length > 0"
              >
                <template v-if="currentItem.scope === 1">
                  <span>全部经销商</span>
                </template>
                <template v-else>
                  {{ scopeData[currentItem.scope] }} ·
                  <span
                    v-for="(item, index) in currentItem.scopeList"
                    :key="item.scopeId"
                  >
                    {{ item.scopeName }}
                    <span v-if="index < currentItem.scopeList.length - 1"
                      >,</span
                    >
                  </span>
                </template>
              </template>
              <template v-else>-</template>
            </div>
          </div>
          <div class="drawer-main-flex">
            <div class="flex-label">活动成员：</div>
            <div class="flex-value">
              <template v-if="currentItem.dealerNum">
                {{ currentItem.dealerNum }}
                <span class="btn" @click="openMemberListModal">查看名单</span>
              </template>
              <template v-else>-</template>
            </div>
          </div>

          <!-- <div class="drawer-main-flex">
            <div class="flex-label">参与范围：</div>
            <div class="flex-value">
              <template
                v-if="currentItem.scopeList && currentItem.scopeList.length > 0"
              >
                <template v-if="currentItem.scope === 1">
                  <span>全部经销商</span>
                </template>
                <template v-else>
                  <span
                    v-for="(item, index) in currentItem.scopeList"
                    :key="item.scopeId"
                  >
                    {{ item.scopeName }}
                    <span v-if="index < currentItem.scopeList.length - 1"
                      >,</span
                    >
                  </span>
                </template>
              </template>
              <template v-else>-</template>
            </div>
          </div> -->
          <div class="line"></div>
          <div class="drawer-main-flex" v-if="currentItem.platform">
            <div class="flex-label">参与平台：</div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(item, index) in currentItem.platform.split(',')"
                :key="index"
              >
                {{ platformData[item] || "-" }}
              </div>
            </div>
          </div>
          <div class="drawer-main-flex" v-if="currentItem.statisticsRange">
            <div class="flex-label">参与渠道：</div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(item, index) in currentItem.statisticsRange.split(',')"
                :key="index"
              >
                {{ statisticsRangeData[item] || "-" }}
              </div>
            </div>
          </div>
          <div
            class="drawer-main-flex"
            v-if="currentItem.videoSeriesKeywordName"
          >
            <div class="flex-label">视频标题含车系名：</div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(
                  item, index
                ) in currentItem.videoSeriesKeywordName.split(',')"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="drawer-main-flex" v-if="currentItem.videoKeyword">
            <div class="flex-label">
              视频标题含{{
                currentItem.videoKeywordRelation === "or" ? "任意" : "全部"
              }}关键词：
            </div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(item, index) in currentItem.videoKeyword.split(',')"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div
            class="drawer-main-flex"
            v-if="currentItem.liveSeriesKeywordName"
          >
            <div class="flex-label">直播标题含车系名：</div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(item, index) in currentItem.liveSeriesKeywordName.split(
                  ','
                )"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="drawer-main-flex" v-if="currentItem.liveKeyword">
            <div class="flex-label">
              直播标题含{{
                currentItem.liveKeywordRelation === "or" ? "任意" : "全部"
              }}关键词：
            </div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(item, index) in currentItem.liveKeyword.split(',')"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="drawer-main-flex">
            <div class="flex-label">视频创作来源：</div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-if="
                  videoCreateSourceData[Number(currentItem.videoCreateSource)]
                "
              >
                {{
                  videoCreateSourceData[Number(currentItem.videoCreateSource)]
                }}
              </div>
              <div class="flex-value" v-else>"-"</div>
            </div>
          </div>
          <div class="drawer-main-flex">
            <div class="flex-label">活动导拍模版：</div>
            <div class="flex-value">
              <template v-if="currentItem.activityGuideshootCount">
                {{ currentItem.activityGuideshootCount }}
                <span class="btn" @click="openTemplatePreviewListModal">
                  查看模版
                </span>
              </template>
              <template v-else>-</template>
            </div>
          </div>
          <!-- 
          <div
            class="drawer-main-flex"
            v-if="currentItem.videoSeriesKeywordName"
          >
            <div class="flex-label">视频标题含车系名：</div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(
                  item, index
                ) in currentItem.videoSeriesKeywordName.split(',')"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div> -->
          <!-- <div
            class="drawer-main-flex"
            v-if="returnVideoTopic(currentItem.videoTopic).length > 0"
          >
            <div class="flex-label">抖音话题：</div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(item, index) in returnVideoTopic(
                  currentItem.videoTopic
                )"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="drawer-main-flex">
            <div class="flex-label">视频创作来源：</div>
            <div class="flex-tag">
              <div class="flex-value">
                {{
                  videoCreateSourceData[
                    Number(currentItem.videoCreateSource)
                  ] || "-"
                }}
              </div>
            </div>
          </div>
          <div
            class="drawer-main-flex"
            v-if="currentItem.liveSeriesKeywordName"
          >
            <div class="flex-label">直播标题含车系名：</div>
            <div class="flex-tag">
              <div
                class="flex-tag-item"
                v-for="(item, index) in currentItem.liveSeriesKeywordName.split(
                  ','
                )"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div> -->
          <div class="line"></div>
          <div class="drawer-main-flex">
            <div class="flex-label">活动封面图：</div>
            <div class="preview-img-box" v-if="currentItem.cover">
              <BaseImage
                :src="currentItem.cover"
                :defaultImage="defaultCoverImg"
                fit="cover"
                width="214px"
                height="120px"
                style="background-color: #000"
              />
              <div class="shadow-box" @click="showImage(currentItem.cover)">
                <a-icon type="eye" class="icon" />
              </div>
            </div>
            <div class="flex-value" v-else>-</div>
          </div>
          <div class="drawer-main-flex">
            <div class="flex-label">活动简介图：</div>
            <div class="preview-img-box" v-if="currentItem.introductionImage">
              <BaseImage
                :src="currentItem.introductionImage"
                :defaultImage="defaultCoverImg"
                fit="cover"
                width="214px"
                height="120px"
                style="background-color: #000"
              />
              <div
                class="shadow-box"
                @click="showImage(currentItem.introductionImage)"
              >
                <a-icon type="eye" class="icon" />
              </div>
            </div>
            <div class="flex-value" v-else>-</div>
          </div>
          <div class="drawer-main-flex">
            <div class="flex-label">获奖名单：</div>
            <div class="" v-if="this.awardList.length > 0">
              <div
                class="form-item"
                v-for="(item, index) in awardList"
                :key="index"
                style="width: 534px"
              >
                <a-form-model
                  :model="item"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  style="padding: 16px"
                >
                  <a-form-model-item label="获奖名称" prop="awardName">
                    <span class="text">{{ item.awardName }}</span>
                  </a-form-model-item>
                  <a-form-model-item
                    prop="awardImageList"
                    style="position: relative"
                  >
                    <span slot="label">
                      获奖名单图
                      <span class="ml-8"
                        >({{ item.awardImageList.length }}/5)</span
                      >
                    </span>
                    <a-upload
                      :show-upload-list="{ showRemoveIcon: false }"
                      list-type="picture-card"
                      :file-list="item.awardImageList"
                      @preview="(file) => showImage(file.url)"
                      class="aft-upload drawer"
                    >
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="获奖说明" v-if="item.awardExplain">
                    <span class="text">{{ item.awardExplain }}</span>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
            <div class="flex-value" v-else>-</div>
          </div>
        </a-spin>
      </div>
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal
      :visible="videoVisible"
      :footer="null"
      @cancel="videoModalClose"
      ref="modal"
      style="z-index: 1100"
    >
      <video
        id="videoElement"
        autoplay
        controls
        muted
        style="width: 100%; height: 100%; border-radius: 10px"
      ></video>
    </a-modal>

    <MemberListModal ref="MemberListModal" />
    <TemplatePreviewListModal
      ref="TemplatePreviewListModal"
      @preview="getAuthUrl"
    />
  </a-drawer>
</template>

<script>
const platformData = {
  1: "抖音",
  2: "快手",
  3: "懂车帝",
};
const statisticsRangeData = {
  1: "视频",
  2: "直播",
};
const videoCreateSourceData = {
  0: "全部视频",
  1: "仅使用活动模板",
  2: "使用任意模板",
};
const scopeData = {
  1: "全部经销商",
  2: "账号分组",
  3: "门店分组",
  4: "大区范围",
  5: "战区范围",
};
import api from "@/api/marketingActivityList";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import MemberListModal from "./MemberListModal.vue";
import TemplatePreviewListModal from "./TemplatePreviewListModal.vue";
import BaseImage from "@/components/BaseImage";
import defaultCoverImg from "@/assets/default/default3-4.png";

export default {
  components: {
    MemberListModal,
    TemplatePreviewListModal,
    BaseImage,
  },
  props: {},
  data() {
    return {
      defaultCoverImg,

      visible: false,
      previewVisible: false,
      videoVisible: false,
      previewImage: "",
      currentItem: {},
      detailLoading: false,
      tableLoading: false,
      templateData: [],
      flvPlayer: null,
      platformData,
      statisticsRangeData,
      videoCreateSourceData,
      scopeData,
      awardList: [],
    };
  },
  created() {},
  methods: {
    /**
     * 打开抽屉
     * @param {object} item 选择的任务item
     */
    showDrawer(item) {
      Object.assign(this.$data, this.$options.data());
      this.currentItem = {};
      this.visible = true;
      this.getData(item.id);
      // this.getTemplateManage();
    },

    // 关闭抽屉
    onClose() {
      this.visible = false;
    },
    async getData(id) {
      try {
        this.detailLoading = true;
        await this.getActivityDetail(id);
        await Promise.all([this.getMatrixMemberNum(), this.getAwardDetail(id)]);
        this.detailLoading = false;
      } catch (error) {
        this.detailLoading = false;
      }
    },
    // 获取活动详情
    getActivityDetail(id) {
      const params = {
        activityId: id,
      };
      return api
        .getActivityDetail(params)
        .then((res) => {
          if (res.data.code == 200) {
            this.currentItem = res.data.data || {};
          } else {
            this.onClose();
            this.$message.error("获取详情失败");
          }
        })
        .catch(() => {});
    },
    /**
     * 查询活动成员数量及媒体号数
     */
    getMatrixMemberNum() {
      const params = {
        activityId: this.currentItem.id,
        scope: this.currentItem.scope,
        scopeId: this.currentItem.scopeId,
      };
      return api
        .getMatrixMemberNum(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.$set(this.currentItem, "dealerNum", res.data.data.dealerNum);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {});
    },
    /**
     * 获取获奖名单详情
     * @param {string} id 活动id
     */
    getAwardDetail(id) {
      const params = {
        activityId: id,
      };
      return api
        .getAwardsImageV_2_6_0(params)
        .then((res) => {
          if (res.data.code == 200) {
            this.awardList = res.data.data.map((item) => ({
              ...item,
              awardImageList: item.awardImageList.map((url, index) => ({
                uid: "-1",
                name: `${index}.png`,
                status: "done",
                url: url,
                response: { url: url },
              })),
            }));
          } else {
            this.$message.error("获取获奖名单失败");
          }
        })
        .catch(() => {});
    },

    // 获取抖音话题
    returnVideoTopic(value) {
      return value ? JSON.parse(value) : [];
    },

    getTemplateManage() {
      let params = {
        activity_id: this.currentItem.id,
        status: 2,
      };
      this.tableLoading = true;
      this.templateData = [];
      api
        .getTemplateManage(params)
        .then((res) => {
          console.log("getTemplateManage", res);
          if (res.data.code === 0) {
            this.templateData = res.data.data;
          } else {
            this.$message.error(res.message || "获取模版失败");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    /**
     * 打开成员列表弹窗
     */
    openMemberListModal() {
      this.$refs.MemberListModal.openModal(this.currentItem.id);
    },
    /**
     * 打开导拍模版列表弹窗
     */
    openTemplatePreviewListModal() {
      this.$refs.TemplatePreviewListModal.openModal(this.currentItem.id);
    },
    // 关闭图片预览
    handleCancel() {
      this.previewVisible = false;
    },
    // 打开图片预览
    showImage(url) {
      this.previewImage = url;
      this.previewVisible = true;
    },
    // 视频地址通过内容中台获取权限
    getAuthUrl(source) {
      let body = {
        resource_url: source,
      };
      api
        .getAuthUrl(body)
        .then((res) => {
          console.log("getAuthUrl", res);
          if (res.data.code === 200) {
            this.getStream(res.data.data);
          } else {
            this.$message.error(res.data.message || "获取视频权限失败");
          }
        })
        .catch((err) => {
          this.$message.error("获取视频权限失败");
          console.error(err);
        });
    },
    // 初始化flv
    getStream(source) {
      this.videoVisible = true;
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          console.log(1, source);
          this.flvPlayer = flvjs.createPlayer({
            type: source.indexOf(".mp4") > 0 ? "mp4" : "flv",
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            this.videoModalClose();
            this.$message.warning("视频播放失败，请联系管理员...");
          });
        }
      });
    },
    // 关闭 flv 视频弹窗事件
    videoModalClose() {
      this.videoVisible = false;
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
      }
      this.$refs.modal.$destroyAll();
    },
    returnDate(str) {
      if (str) {
        return str.slice(0, 10);
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
::v-deep .form-item .ant-form-item-control {
  line-height: 1;
}
::v-deep .form-item .ant-form-item {
  margin-bottom: 0;
}
::v-deep .form-item .ant-form-item + .ant-form-item {
  margin-top: 24px;
}

.drawer-container {
  width: 100%;
  height: 100vh;
  background: #20232c;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 56px);
    padding: 0 24px 24px;
    overflow-y: auto;
    .line {
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.15);
      margin-top: 24px;
    }
    &-flex {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 24px;
      .flex-label {
        width: 158px;
        min-width: 158px;
        text-align: right;
        line-height: 28px;
        font-size: 14px;
        color: #8890a6;
      }
      .flex-value {
        line-height: 28px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
        .btn {
          margin-left: 8px;
          color: #ffcc55;
          cursor: pointer;
        }
      }
      .flex-tag {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        &-item {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 28px;
          padding: 0 8px;
          background: #1d1d1d;
          border-radius: 4px;
          border: 1px solid #434343;
          height: 28px;
          text-align: center;
          margin-right: 4px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .form-item {
        border: 1px solid #323744;
        border-radius: 8px;
        margin-bottom: 16px;
        background: #1a1d26;
        .text {
          color: rgba(255, 255, 255, 0.88);
          font-size: 14px;
          line-height: 22px;
        }
        .delete-button {
          width: 100%;
          height: 48px;
          border-top: 1px solid #323744;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 0.88);
          cursor: pointer;
          > span {
            margin-left: 10px;
          }
          &:hover {
            color: #ffcc55;
          }
        }
      }
      .preview-img-box {
        display: inline-block;
        height: 120px;
        position: relative;
        &:hover {
          .shadow-box {
            opacity: 1;
          }
        }
        .shadow-box {
          width: 100%;
          height: 100%;
          color: rgba(255, 255, 255, 0.85);
          background: rgba(0, 0, 0, 0.4);
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          font-size: 16px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .template-box {
        display: flex;
        flex-direction: column;
        .template-item {
          line-height: 28px;
          color: #ffca5b;
          cursor: pointer;
        }
      }
    }
    &-split {
      width: 100%;
      height: 1px;
      background: #3e424a;
      margin-top: 24px;
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 88px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.btn1 {
        background: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}
</style>