<template>
  <div class="multiple-group-container">
    <div
      class="item"
      v-for="(item, index) in multipleData"
      :key="item.group_code"
      @click.stop="itemClick(item, index)"
    >
      <!-- <a-icon
        type="check-circle"
        :class="item.type ? 'icon active' : 'icon'"
        :theme="item.type ? 'filled' : ''"
      /> -->
      <span v-if="item.favorite!=0" class="icon"></span>
      <svg-icon v-if="item.favorite==0" icon-class="md-check_box" class="svg"/>

      <div :class="item.favorite ==0 ? 'name active' : 'name'" :title="item.group_name">{{ item.group_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultipleGroup",
  props: {
    multipleData: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    itemClick(item, index) {
      this.$emit("multipleChange", index);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.multiple-group-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .item {
    display: flex;
    margin-bottom: 15px;
    cursor: pointer;
    height: 24px;
    > span {
      width: 20px;
      height: 20px;
      padding: 3px;
      border: 3px solid rgba(0, 0, 0, 0);
      @include border_color("border_color7");
      border-radius: 3px;
      transition: all 0.5s;
      line-height: 6px;
      text-align: center;
      line-height: 20px;
      // > span {
      //   width: 8px;
      //   height: 8px;
      //   border-radius: 3px;
      //   transition: all 0.5s;
      //   display: inline-block;
      //   background: rgba(0, 0, 0, 0);
      // }
    }
    .svg {
      @include font_color("font_color5");
      font-size: 26px;
      margin-right: 6px;
      position: relative;
      top: -3px;
      left: -3px;
    }
    // > span.active {
    //   @include background_color("background_color15");
    //   @include border_color("border_color4");
    //   > span {
    //     // @include background_color("background_color15");
    //   }
    // }
    .name {
      width: calc(100% - 30px);
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      transition: all 0.5s;
      font-size: 14px;
      @include font_color("font_color3");
    }
    .name.active {
      @include font_color("font_color1");
    }
    .icon {
      margin-right: 12px;
    }
  }
}
</style>
