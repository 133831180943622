<template>
  <div>
    <platform-header
      title="经销商概览"
      :currentTab="currentTab"
      @tabChange="tabChange"
    >
    </platform-header>
    <!-- 矩阵活跃 -->
    <MatrixBox ref="MatrixBox" :platform="currentTab" />
    <!-- 数据看板 -->
    <BoardBox ref="BoardBox" @changeParams="boardChangeParams" />
    <!-- 转化分析 -->
    <ConversionBox v-show="false" ref="ConversionBox" />
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "dealerOfOneself"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MatrixBox from "./components/MatrixBox";
import BoardBox from "./components/BoardBox";
import ConversionBox from "./components/ConversionBox";

export default {
  name: "dealerOfOneself",
  components: {
    MatrixBox,
    BoardBox,
    ConversionBox,
    PlatformHeader,
  },
  data() {
    return {
      currentTab: "",
      currentParams: {},
    };
  },
  mounted() {
    // this.$refs.MatrixBox.getMatrixActivationData(this.currentTab);
    // this.$refs.MatrixBox.getHistoryLevelData(this.currentTab);
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(["changeState"]),
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      // 刷新各个组件数据
      this.$nextTick(() => {
        this.$refs.MatrixBox.getMatrixActivationData(this.currentTab);
        this.$refs.MatrixBox.getHistoryLevelData(this.currentTab);
        this.getConversionBoxData();
        this.getBoardBoxData();
      });
    },
    // BoardBox 组件 修改查询参数时触发
    boardChangeParams(params) {
      // region_id 、group_code 、week 、month 、year 、start_time 、end_time 改变时重新获取
      this.currentParams = params;
      // 更新 ConversionBox 组件接口
      this.getConversionBoxData();
      this.getBoardBoxData();
    },
    // 获取 ConversionBox 数据
    getConversionBoxData() {
      this.$refs.ConversionBox.getAnalysisData({
        platform: this.currentTab,
        params: this.currentParams,
      });
    },
    // 获取 BoardBox 数据
    getBoardBoxData() {
      this.$refs.BoardBox.getBoardData({
        platform: this.currentTab,
        params: this.currentParams,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
// 公用
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.item-center {
  display: flex;
  align-items: center;
}
.mb20 {
  margin-bottom: 20px;
}
// 主体区域
.wrap {
  padding: 0 20px;
  .box-main {
    @include background_color("background_color80");
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 28px;
  }
  // 转化分析
  .conversion-main {
    height: 300px;
  }
}
</style>
