<template>
  <a-drawer
    placement="right"
    width="560"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span
          >上传获奖名单<span class="ml-8">({{ form.length }}/10)</span></span
        >
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <a-spin :spinning="detailLoading">
        <div class="drawer-main">
          <a-alert
            message="新增、删除或修改获奖内容，将同步风火轮App中的评奖结果内容"
            banner
            class="top-tips"
          />
          <div class="add-button" @click="addPrice">
            <a-icon type="plus" />
            <span>新增获奖内容</span>
          </div>
          <div class="form-list">
            <div class="form-item" v-for="(item, index) in form" :key="index">
              <a-form-model
                :model="item"
                :rules="rules"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                ref="ruleForm"
                style="padding: 16px"
              >
                <a-form-model-item label="获奖名称" prop="awardName">
                  <a-input
                    v-model.trim="item.awardName"
                    class="aft-input drawer"
                    placeholder="请输入获奖名称，不超过15个字符"
                    :maxLength="15"
                  />
                  <span class="count">{{ item.awardName.length || 0 }}/15</span>
                </a-form-model-item>
                <a-form-model-item
                  prop="awardImageList"
                  style="position: relative; margin-top: 24px"
                >
                  <span slot="label">
                    获奖名单图
                    <span class="ml-8"
                      >({{ item.awardImageList.length }}/5)</span
                    >
                  </span>
                  <a-upload
                    :custom-request="customRequest"
                    accept="image/png, image/jpg, image/jpeg"
                    list-type="picture-card"
                    :file-list="item.awardImageList"
                    @preview="handlePreview"
                    :before-upload="(e) => beforeUploadImg(e, index)"
                    @change="(e) => handleChange(e, index)"
                    class="aft-upload drawer"
                  >
                    <div v-if="item.awardImageList.length < 5">
                      <a-icon type="plus" />
                    </div>
                  </a-upload>
                  <span class="upload-label">支持jpg/png/jpeg 格式</span>
                </a-form-model-item>
                <a-form-model-item label="获奖说明" prop="awardExplain">
                  <a-input
                    v-model.trim="item.awardExplain"
                    type="textarea"
                    class="aft-input drawer"
                    placeholder="请输入获奖说明"
                    style="width: 480px"
                    :maxLength="100"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                  />
                  <span class="count textarea">
                    {{ item.awardExplain.length || 0 }}/100
                  </span>
                </a-form-model-item>
              </a-form-model>
              <div class="delete-button" @click="deletePrice(index)">
                <svg-icon icon-class="delete" />
                <span>删除</span>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
      <div class="drawer-tools">
        <a-button class="btn btn1" @click="onClose">取消</a-button>
        <a-button
          class="btn btn2"
          @click="drawerSubmit"
          :loading="isAddGroupLoading"
          >确定</a-button
        >
      </div>
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-drawer>
</template>

<script>
import ossUploadMixin from "@/mixins/ossUploadMixin";
import api from "@/api/marketingActivityList";
import deepClone from "@/utils/clone-utils";
import { getBase64, aliImgTransform } from "@/utils/util";

const defaultForm = {
  awardName: "",
  awardImageList: [],
  awardExplain: "",
};
export default {
  mixins: [ossUploadMixin],
  components: {},
  props: {},
  data() {
    return {
      visible: false,
      form: [deepClone(defaultForm)],
      rules: {
        awardName: {
          required: true,
          message: "请输入获奖名称，不超过15个字符",
        },
        awardImageList: {
          required: true,
          message: "请上传获奖名单图",
          trigger: "change",
        },
      },
      previewVisible: false,
      previewImage: "",
      isAddGroupLoading: false,
      currentItem: {},
      upload_file_params: {
        business_code: "jjt", //所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
        need_compress: 2, //是否需要压缩处理-针对图片(1:是  2:否  默认是)
        type: 1, //文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
      },
      detailLoading: false,
    };
  },
  created() {},
  methods: {
    /**
     * 显示drawer
     * @param {object} currentItem 当前活动数据
     */
    showDrawer(currentItem) {
      Object.assign(this.$data, this.$options.data());
      this.currentItem = currentItem;
      this.visible = true;
      this.getAwardDetail(this.currentItem.id);
    },
    /**
     * 关闭drawer
     */
    onClose() {
      try {
        if (this.$refs.ruleForm) {
          for (const form of this.$refs.ruleForm) {
            form.resetFields();
          }
        }
      } catch (error) {}
      this.$nextTick(() => {
        this.visible = false;
      });
    },
    /**
     * 获取获奖名单详情
     * @param {string} id 活动id
     */
    getAwardDetail(id) {
      let params = {
        activityId: id,
      };
      this.detailLoading = true;
      api
        .getAwardsImageV_2_6_0(params)
        .then((res) => {
          if (res.data.code == 200) {
            this.setForm(res.data.data);
          } else {
            this.onClose();
            this.$message.error("获取获奖名单失败");
          }
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
    /**
     * 将详情会写到form里
     */
    setForm(list) {
      let tempList = deepClone(list);
      if (tempList.length > 0) {
        this.form = tempList.map((item, listIndex) => ({
          ...item,
          awardImageList: item.awardImageList.map((url, index) => ({
            uid: `${listIndex}_${index}`,
            name: `${listIndex}_${index}.png`,
            status: "done",
            url: aliImgTransform(url, 100),
            originUrl: url,
            response: { url: url },
          })),
        }));
      } else {
        this.form = [deepClone(defaultForm)];
      }
      // if (item.awardsImage) {
      //   this.form = {
      //     awardImageList: [
      //       {
      //         uid: "-1",
      //         name: "1.png",
      //         status: "done",
      //         url: item.awardsImage,
      //         response: { url: item.awardsImage },
      //       },
      //     ],
      //   };
      // } else {
      //   this.form = [deepClone(defaultForm)];
      // }
    },
    /**
     * 新增获奖内容
     */
    addPrice() {
      if (this.form.length === 10) {
        this.$message.warning("最多支持上传10组获奖内容");
        return;
      }
      this.form.push({
        ...deepClone(defaultForm),
      });
    },
    /**
     * 删除获奖内容
     * @param {number} index 获奖内容列表索引
     */
    deletePrice(index) {
      if (this.form.length <= 1) {
        this.$message.warning("至少保留一组获奖内容");
        return false;
      }
      this.form.splice(index, 1);
    },
    /**
     * 图片预览
     * @param {File} file 图片文件
     */
    async handlePreview(file) {
      if (!file.originUrl && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.originUrl || file.preview;
      this.previewVisible = true;
    },
    /**
     * 关闭预览窗口
     */
    handleCancel() {
      this.previewVisible = false;
    },
    /**
     * 文件改变
     * @param {File[]} fileList 文件列表
     * @param {number} index 获奖内容列表索引
     */
    handleChange({ fileList }, index) {
      console.log("handleChange", fileList);
      this.form[index].awardImageList = fileList;
    },

    /**
     * 自定义上传
     * @param {File} file 文件
     * @param {Function} onSuccess 上传成功回调
     * @param {Function} onProgress 进度条回调
     */
    async customRequest({ file, onSuccess, onProgress }) {
      const formData = new FormData();
      formData.append("business_code", this.upload_file_params.business_code);
      formData.append("need_compress", this.upload_file_params.need_compress);
      formData.append("type", this.upload_file_params.type);
      formData.append("file", file);
      await this.normalUploadFile(file, onSuccess, formData);
    },
    /**
     * 上传之前文件校验
     * @param {File} file 要上传的文件
     * @return {boolean} true 校验通过 false 校验不通过
     */
    beforeUploadImg(file, index) {
      const isJpgOrPng =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isJpgOrPng) {
        this.$message.error("上传的图片格式只能为jpg/png/jpeg!");
      }
      const isLt1M = file.size / 1024 / 1024 < 4;
      if (!isLt1M) {
        this.$message.error("图片格式不超过4M!");
      }
      if (!(isJpgOrPng && isLt1M)) {
        setTimeout(() => {
          this.form[index].awardImageList.pop();
        }, 200);
      }
      return isJpgOrPng && isLt1M;
    },
    /**
     * 保存获奖内容
     */
    async drawerSubmit() {
      let allBool = true;
      let uploadBool = true;
      for (let index = 0; index < this.$refs.ruleForm.length; index++) {
        if (!uploadBool) break;
        const item = this.$refs.ruleForm[index];
        item.validate((valid) => {
          if (valid) {
            // 图片上传判断
            const imgList = this.form[index].awardImageList;
            for (const img of imgList) {
              if (!img.response || !img.response.url) {
                uploadBool = false;
                break;
              }
            }
          } else {
            allBool = false;
          }
        });
        if (!allBool) break;
      }
      if (!uploadBool) {
        this.$message.error("图片未上传完成");
        return;
      }
      if (allBool) {
        this.isAddGroupLoading = true;
        const list = this.form.map((item) => ({
          ...item,
          awardImageList: item.awardImageList.map((img) => img.response.url),
        }));
        // 处理参数
        let params = {
          activityId: this.currentItem.id,
          awardsDetailList: list,
        };
        api
          .updateAwardsImageV_2_6_0(params)
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("提交成功");
              this.onClose();
              this.$emit("getList");
            } else {
              this.$message.error(res.data.message || "提交失败");
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.isAddGroupLoading = false;
          });
      } else {
        this.$message.error("校验不通过,无法提交");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
::v-deep .ant-alert-warning {
  background: #4d3621;
  border-radius: 6px;
}
::v-deep .ant-alert-warning .ant-alert-icon {
  color: #ff9026;
}
::v-deep .ant-alert-message {
  color: rgba(255, 255, 255, 0.88);
}

.ml-8 {
  margin-left: 8px;
}

.drawer-container {
  width: 100%;
  height: 100vh;
  background: #20232c;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100vh - 116px);
    padding: 0 24px;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    .top-tips {
      margin-top: 24px;
    }
    .add-button {
      width: 512px;
      height: 40px;
      border-radius: 6px;
      border: 1px dashed rgba(255, 255, 255, 0.2);
      color: rgba(255, 255, 255, 0.88);
      background: #1a1d26;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 16px 0;
      user-select: none;
      > span {
        margin-left: 8px;
      }
      &:hover {
        color: #ffcc55;
      }
    }
    .form-list {
      flex: 1;
      overflow-y: auto;
      .form-item {
        border: 1px solid #323744;
        border-radius: 8px;
        margin-bottom: 16px;
        background: #1a1d26;
        .count {
          color: #8890a6;
          position: absolute;
          right: 16px;
          &.textarea {
            line-height: 1;
            bottom: 0;
          }
        }
        .delete-button {
          width: 100%;
          height: 48px;
          border-top: 1px solid #323744;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 0.88);
          cursor: pointer;
          > span {
            margin-left: 10px;
          }
          &:hover {
            color: #ffcc55;
          }
        }
      }
    }
    .upload-label {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.45);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      margin-left: 8px;
      &.btn1 {
        background: #2b2f3b;
        border-color: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        border-color: #ffca5b;
        color: #000000;
      }
    }
  }
}
</style>
