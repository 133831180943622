<template>
  <div class="card-container" @click="cardClick">
    <div class="info-box">
      <WebpImage
        :src="type == 'video' ? cardData.aweme_cover : cardData.cover"
        :scale="'3-4'"
        :width="'81px'"
        :height="'106px'"
        :radius="'8px'"
      />
      <div class="info-box-right">
        <div>
          <span :class="['tag', `tag-${type}`]">{{
            type == "video" ? "视频" : "直播"
          }}</span>
          <span class="title">{{
            type == "video" ? cardData.aweme_title : cardData.title
          }}</span>
        </div>
        <div>
          <svg-icon
            :icon-class="
              cardData.verification_type == 2
                ? 'account-type-blue'
                : 'account-type-grey'
            "
            class="account-svg"
          />
          <span>{{ cardData.nickname }}</span>
        </div>
        <div class="date">{{ cardData.publish_time }}</div>
      </div>
    </div>
    <div class="data-box" v-if="type == 'video'">
      <div class="data-box-item">
        <div class="label">视频播放</div>
        <div class="number">
          {{ cardData.play_volume | changeNum }}
        </div>
      </div>
      <div class="data-box-item">
        <div class="label">视频点赞</div>
        <div class="number">
          {{ cardData.digg_count | changeNum }}
        </div>
      </div>
      <div class="data-box-item">
        <div class="label">视频评论</div>
        <div class="number">
          {{ cardData.comment_count | changeNum }}
        </div>
      </div>
      <div class="data-box-item">
        <div class="label">视频转发</div>
        <div class="number">
          {{ cardData.share_count | changeNum }}
        </div>
      </div>
    </div>
    <div class="data-box" v-else>
      <div class="data-box-item">
        <div class="label">{{ platform | returnWatchText }}
          <AftTooltip :code="'qjzb11'" style="top: 2px; left: 4px" />
        </div>
        <div class="number" v-if="platform == 'kuaishou'">
          {{ cardData.statistics_total_watch_user_count | changeNum }}
        </div>
        <div class="number" v-else>
          {{ cardData.statistics_total_watch_user_time | changeNum }}
        </div>
      </div>
      <div class="data-box-item">
        <div class="label">直播时长</div>
        <div class="number">
          {{ cardData.statistics_live_time | disposeLongTime_m }}
        </div>
      </div>
      <div class="data-box-item">
        <div class="label">平均在线</div>
        <div class="number2">
          {{ cardData.statistics_avg_online_user | changeNum }}
        </div>
      </div>
      <div class="data-box-item">
        <div class="label">直播互动</div>
        <div class="number2">
          {{ cardData.lice_interaction_count | changeNum }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "WorksCard",
  components: { WebpImage },
  props: {
    // live or video
    type: {
      type: String,
      default: "live",
    },
    platform: {
      type: String,
      default: "douyin",
    },
    cardData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    cardClick() {
      if (this.type == "video") {
        this.videoClick(this.cardData);
      }
      if (this.type == "live") {
        this.liveClick(this.cardData);
      }
    },
    liveClick(item) {
      if (item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${this.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
    videoClick(item) {
      if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${this.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

.card-container {
  width: 100%;
  height: 214px;
  background: #2b2f3b;
  border-radius: 16px;
  padding: 20px 0 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  .info-box {
    width: 100%;
    height: 106px;
    display: flex;
    &-right {
      width: calc(100% - 115px);
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-items: flex-start;
      > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        &:nth-child(1) {
          > .tag {
            width: 56px;
            height: 22px;
            background: #ff4160;
            border-radius: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 22px;
            text-align: center;
          }
          > .tag-video {
            background: #815cff;
          }
          > .tag-live {
            background: #ff4160;
          }
          > .title {
            width: calc(100% - 10px);
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            margin-left: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }
        }
        &:nth-child(2) {
          margin: 6px 0 34px;
          .account-svg {
            font-size: 20px;
          }
          > span {
            font-size: 12px;
            font-weight: bold;
            color: #9ca8ba;
            margin-left: 4px;
          }
        }
        &:nth-child(3) {
          font-size: 12px;
          color: #6a7080;
        }
      }
    }
  }
  .data-box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    &-item {
      width: 78px;
      height: 44px;
      display: flex;
      flex-direction: column;
      .label {
        font-size: 12px;
        color: #9ca8ba;
        line-height: 16px;
        margin-bottom: 12px;
      }
      .number {
        font-size: 14px;
        color: #ffca5c;
        line-height: 16px;
      }
      .number2 {
        font-size: 14px;
        color: #fff;
        line-height: 16px;
      }
    }
  }
}
</style>
