<template>
  <div :id="id" :class="className" :style="styleObject" :pieData="pieData" />
</template>

<script>
import echarts from "echarts";
import resize from "@/mixins/resize";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "pie-chart"
    },
    id: {
      type: String,
      default: "pie-chart"
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: "874px", height: "300px" };
      }
    },
    pieData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.initChart();
  },
  computed: {
    styleObject: function() {
      const obj = this.boxStyle;
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";

        obj[attr] =
          unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    pieData(val) {
      console.log('initChart pieData', val)
      this.initChart();
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id));
      console.log('性别分析接口',this.pieData)
      let bgColor = "#fff";
      let color = [
        "#CB6162",
        "#00B9C5",
      ];
      this.chart.setOption({
        color: color,
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
          backgroundColor: 'rgba(37, 50, 85, 0.9)',
          textStyle: {
              color: '#fff',
          },
        },
        legend:{
            show:false
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            // center: ["50%", "50%"],
            data: this.chartData,
            hoverAnimation: false,
            label: {
              normal: {
                position: "outside",
                alignTo: "none",
                bleedMargin: 1,
                formatter: '{b} {d}% '
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "12",
                fontWeight: "bold"
              }
            },
            itemStyle: {
              borderWidth: 10,
              borderColor: "#000710"
            },
            data: [
              {
                value: this.pieData.gender.percentage[0],
                name: this.pieData.gender.desc[0]
              },
              {
                value: this.pieData.gender.percentage[1],
                name: this.pieData.gender.desc[1]
              }
            ]
          }
        ]
      });
    }
  }
};
</script>
