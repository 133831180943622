/** 
* tabData: 按钮组数据
* tabChange: 点击按钮触发的方法
* example: <platform-tab :tabData="tabData" @tabChange="tabChange"></platform-tab>
*/

<template>
  <div>
    <a-button
      v-for="(item, index) in tabData"
      v-show="isButton"
      :key="'btn' + index"
      round
      :class="
        currentClick == item.value ? 'active-btn-class btn-class' : 'btn-class'
      "
      :style="defaultBtnStyle"
      @click="tabClick(item.value)"
      >{{ item.label }}</a-button
    >
    <span
      v-for="(item, index) in tabData"
      v-show="!isButton"
      :key="index"
      :class="
        currentClick == item.value
          ? 'active-span-class span-class'
          : 'span-class'
      "
      :style="defaultSpanStyle"
      @click="tabClick(item.value)"
    >
      {{ item.label }}
    </span>
  </div>
</template>

<script>
export default {
  name: "PlatformTab",
  components: {
  },
  props: {
    tabData: {
      type: Array,
    },
    isButton: {
      type: Boolean,
      default: true,
    },
    defaultBtnStyle: {
      type: Object,
      default: () => {
        return {
          margin: "0 0 16px 16px",
        };
      },
    },
    defaultSpanStyle: {
      type: Object,
      default: () => {
        return {
          margin: "0 32px 0 0",
        };
      },
    },
  },
  data() {
    return {
      btnModel: "two",
      currentClick: this.tabData.length ? this.tabData[0].value : "",
    };
  },
  created() {},
  computed: {},
  watch: {
    tabData(val) {
      this.currentClick = val.length ? val[0].value : "";
    },
  },
  methods: {
    tabClick(value) {
      this.currentClick = value;
      this.$emit("tabChange", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.span-class.active-span-class {
  @include font_color("font_color1");
}
.span-class {
  cursor: pointer;
  @include font_color("font_color3");
}
.btn-class.active-btn-class {
  @include background_color("background_color15");
  @include font_color("font_color17");
  font-weight: bold;
}
.btn-class {
  cursor: pointer;
  @include font_color("font_color3");
  min-width: 92px;
  height: 30px;
  border: none;
  line-height: 30px;
  border-radius: 8px;
  padding: 0 19px;
  background: none;
}
</style>
