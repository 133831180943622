import Nr from '@/utils/NetworldRequest'
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()

function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const platformRequest = (platform) => {
  let baseURL = getBaseUrl(platform)

  return new Nr({
    baseURL: baseURL
  }).getAxios()
}

export default {
  // fans -----------------
  // 效果详情 - 粉丝 - 数据趋势
  getDataTrendByFans(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/fans/data-trend`, { params: body.params })
  },
  // 效果详情 - 粉丝 - 粉丝概览
  getOverviewByFans(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/fans/overview`, { params: body.params })
  },
  // 效果详情 - 粉丝 - 门店分布
  getDistributeByFans(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/fans/dealer-distribute`, { params: body.params })
  },
  // 效果详情 - 粉丝 - 最佳主播
  getBestAuthorByFans(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/fans/best-author`, { params: body.params })
  },
  // 效果详情 - 粉丝 - 直播增粉
  getBestLiveByFans(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/fans/best-live`, { params: body.params })
  },

  // live -----------------
  // 效果详情 - 直播 - 数据趋势
  getDataTrendByLive(body) {
    return platformRequest(body.platform).get(`/jjt/activity/live/data-trend`, { params: body.params })
  },
  // 效果详情 - 直播 - 粉丝概览
  getOverviewByLive(body) {
    return platformRequest(body.platform).get(`/jjt/activity/live/overview`, { params: body.params })
  },
  // 效果详情 - 直播 - 门店分布
  getDistributeByLive(body) {
    return platformRequest(body.platform).get(`/jjt/activity/live/distribute`, { params: body.params })
  },
  // 效果详情 - 直播 - 最佳主播
  getBestAuthorByLive(body) {
    return platformRequest(body.platform).get(`/jjt/activity/live/best-author`, { params: body.params })
  },
  // 效果详情 - 直播 - 直播增粉
  getBestLiveByLive(body) {
    return platformRequest(body.platform).get(`/jjt/activity/live-hot`, { params: body.params })
  },

  // video -----------------
  // 效果详情 - 视频 - 数据趋势
  getDataTrendByVideo(body) {
    return platformRequest(body.platform).get(`/jjt/activity/aweme/data-trend`, { params: body.params })
  },
  // 效果详情 - 视频 - 粉丝概览
  getOverviewByVideo(body) {
    return platformRequest(body.platform).get(`/jjt/activity/aweme/overview`, { params: body.params })
  },
  // 效果详情 - 视频 - 门店分布
  getDistributeByVideo(body) {
    return platformRequest(body.platform).get(`/jjt/activity/aweme/distribute`, { params: body.params })
  },
  // 效果详情 - 视频 - 最佳主播
  getBestAuthorByVideo(body) {
    return platformRequest(body.platform).get(`/jjt/activity/aweme/best-author`, { params: body.params })
  },
  // 效果详情 - 视频 - 直播增粉
  getBestLiveByVideo(body) {
    return platformRequest(body.platform).get(`/jjt/activity/aweme/best-aweme`, { params: body.params })
  },

  // 门店 ------------------
  // 效果详情 - 门店 - 数据趋势
  getDataTrendByDealer(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/dealer/data-trend`, { params: body.params })
  },
  // 效果详情 - 门店 - 经销商贡献
  getContributionByDealer(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/dealer/dealer-contribution`, { params: body.params })
  },
  // 效果详情 - 门店 - 最佳门店
  getBestStoreByDealer(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/dealer/best-stores`, { params: body.params })
  },
  // 效果详情 - 门店 - 最佳直播视频
  getBestLiveVideoByDealer(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/dealer/best-live-aweme`, { params: body.params })
  },

  // 线索 ------------------
  // 效果详情 - 线索 - 数据趋势
  getDataTrendByClue(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/clue/data-trend`, { params: body.params })
  },
  // 效果详情 - 线索 - 经销商贡献
  getContributionByClue(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/clue/dealer-contribution`, { params: body.params })
  },
  // 效果详情 - 线索 - 最佳门店
  getBestStoreByClue(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/clue/best-stores`, { params: body.params })
  },
  // 效果详情 - 线索 - 最佳门店(多个指标)
  getSomeStoreByClue(body) {
    return platformRequest(body.platform).get(`/jjt/activity/${body.params.id}/clue/multi-best-stores`, { params: body.params })
  },
}