<template>
  <div class="account-detail-container">
    <div class="account-detail-container-left">
      <div class="header-detail">
        <div class="header-detail-top">
          <div class="avatar-box">
            <WebpImage
              :src="authorDetailN.avatar"
              :width="'54px'"
              :height="'54px'"
              :radius="'50%'"
            />
            <svg-icon
              :icon-class="`platform-color-${type}`"
              class="platform-icon"
            />
          </div>
          <div class="header-info">
            <div class="header-info-nickname">
              <span>{{ authorDetailN.nickname || "-" }}</span>
            </div>
            <div class="header-info-dealername">
              <svg-icon icon-class="store-icon" class="small-svg" />
              <span class="icons_dealer_name"
                >{{ authorDetailN.dealer_area_names || "-" }}-{{
                  authorDetailN.dealer_name || "-"
                }}</span
              >
            </div>
            <div class="header-info-tagbox">
              <div
                class="tag tag1"
                v-for="(item, index) in authorDetailN.tag_names"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="header-detail-bottom">
          <div>
            <div>粉丝总量</div>
            <div>
              <span v-if="type == 'kuaishou'">{{
                authorDetailN.fans_count_total | changeNum
              }}</span>
              <span v-else>{{ authorDetailN.fans_count | changeNum }}</span>
            </div>
          </div>
          <div>
            <div>
              点赞总量<AftTooltip
                :code="'qjqt03'"
                style="top: 0px; left: 6px"
              />
            </div>
            <div>
              <span>{{ authorDetail.like_count_total | changeNum }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="account-detail-container-right">
      <div class="matrix-top">
        <div>
          <span :class="['fc2', `fc2-${authorDetail.level}`]">{{
            authorDetail.level || "-"
          }}</span>
          <span class="label">账号等级</span>
        </div>
        <div>
          <span class="fc1"
            >¥{{ authorDetail.marketing_value | changeNum }}</span
          >
          <span class="label">上月营销价值</span>
        </div>
      </div>
      <div class="matrix-bottom">
        <div
          class="matrix-bottom-list"
          v-if="matrixList && matrixList.length > 0"
        >
          <div
            :class="[
              'matrix-bottom-item',
              currentMatrix == item.id ? 'active' : '',
            ]"
            v-for="(item, index) in matrixList"
            :key="index"
            @mouseenter="currentMatrix = item.id"
          >
            <svg-icon class="icon" icon-class="monitoring" />
            <div>
              <span class="title"
                >{{ item.exception_name }}·{{ item.event_name }}</span
              >
              <span class="date">{{ item.statistic_day.slice(0, 10) }}</span>
            </div>
          </div>
        </div>
        <div class="matrix-bottom-empty" v-else>
          <div class="matrix-bottom-item active">
            <svg-icon class="icon" icon-class="monitoring" />
            <div>
              <span class="title">账号健康度监测中…</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
import api from "@/api/production";
export default {
  components: {
    WebpImage,
  },
  data() {
    return {
      type: this.$route.query.type,
      matrixList: [],
      currentMatrix: 1,
      authorDetail: {},
      authorDetailN: {},
    };
  },
  mounted() {
    this.getAuthorDetail();
    this.getAuthorDetailN();
    this.getMatrixList();
  },
  methods: {
    getAuthorDetail() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
        },
      };
      api.getAuthorDetail(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.authorDetail = res.data.data || {};
        } else {
          console.error("获取账号详情失败");
        }
      });
    },
    getAuthorDetailN() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
        },
      };
      api.getAuthorDetailN(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.authorDetailN = res.data.data || {};
        } else {
          console.error("获取账号详情失败");
        }
      });
    },
    getMatrixList() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
        },
      };
      api.getMatrixList(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.matrixList = res.data.data || [];
          if (this.matrixList && this.matrixList.length > 0) {
            this.currentMatrix = this.matrixList[0].id;
            if (this.matrixList.length > 10) {
              this.matrixList = this.matrixList.slice(0, 10);
            }
          }
        } else {
          console.error("获取矩阵异动列表失败");
        }
      });
    },
    getParams() {
      return this.$parent.getParams();
    },
  },
};
</script>

<style lang="scss" scoped>
// @include font_color("font_color20");
@import "@/assets/styles/_handle.scss";
.account-detail-container {
  width: 100%;
  height: 190px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &-left {
    width: 480px;
    height: 100%;
    border-radius: 16px;
    @include background_color("background_color2");

    .header-detail {
      width: 100%;
      height: 100%;
      @include background_color("background_color2");
      border-radius: 16px;
      &-top {
        height: 99px;
        width: 100%;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        .avatar-box {
          width: 54px;
          height: 54px;
          position: relative;
          margin: 0 16px 0 20px;
          .platform-icon {
            position: absolute;
            font-size: 20px;
            bottom: -2px;
            right: -2px;
          }
        }
        .header-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 370px;
          height: 100%;
          &-nickname {
            display: flex;
            align-items: center;
            > span {
              font-size: 16px;
              @include font_color("font_color20");
            }
          }
          &-dealername {
            display: flex;
            align-items: center;
            margin: 4px 0 8px;
            .small-svg {
              color: #9ca8ba;
              margin-right: 4px;
            }
            > img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              margin-bottom: 2px;
            }
            span {
              @include font_color("font_color22");
              font-size: 12px;
            }
          }
          &-tagbox {
            width: 100%;
            height: 20px;
            display: flex;
            .tag {
              height: 20px;
              font-size: 12px;
              line-height: 20px;
              padding: 0 8px;
              border-radius: 4px;
              margin-left: 4px;
              &.tag1 {
                color: #ffcc55;
                background: rgba(255, 204, 85, 0.2);
              }
              &.tag2 {
                color: #24bf79;
                background: rgba(39, 195, 70, 0.2);
              }
            }
          }
        }
      }

      &-bottom {
        width: 100%;
        height: 78px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-left: 20px;
        > div {
          width: 146px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 15px 0 16px 0px;
          > div {
            @include font_color("font_color20");
            font-size: 20px;
            font-weight: bold;
            &:first-child {
              @include font_color("font_color23");
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  &-right {
    width: calc(100% - 500px);
    height: 100%;
    border-radius: 16px;
    @include background_color("background_color2");
    .matrix-top {
      height: 94px;
      width: 100%;
      border-bottom: 1px solid;
      padding: 0 20px;
      @include border_color("border_color24");
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-start;
      > div {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .label {
          font-size: 12px;
          color: #8890a6;
          margin-top: 6px;
        }
        .fc1 {
          font-size: 20px;
          font-weight: bold;
          line-height: 34px;
          color: #fff;
        }
        .fc2 {
          font-size: 30px;
          font-weight: bold;
          line-height: 34px;
          color: #fff;
          &-S {
            @include font_color("font_color27");
          }
          &-A {
            @include font_color("font_color26");
          }
          &-B {
            @include font_color("font_color29");
          }
          &-C {
            @include font_color("font_color31");
          }
          &-D {
            @include font_color("font_color21");
          }
        }
      }
    }
    .matrix-bottom {
      height: 78px;
      width: 100%;
      padding: 0 20px;
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
      }
      .matrix-bottom-item {
        width: 44px;
        height: 49px;
        border-radius: 8px;
        border: 1px solid #3c4252;
        transition: all 0.4s;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 11px;
        overflow: hidden;
        margin-left: 10px;
        cursor: pointer;
        > div {
          min-width: 102px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .title {
            font-size: 12px;
            font-weight: bold;
            color: #ffffff;
          }
          .date {
            font-size: 12px;
            color: #9ca8ba;
          }
        }
        .icon {
          font-size: 22px;
          margin-right: 12px;
          min-width: 22px;
        }
        &.active {
          width: 210px;
          border: 1px solid #5d6572;
          padding-left: 16px;
        }
      }
    }
  }
}
</style>