<template>
  <div>
    <platform-header
      title="门店趋势统计"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <div>
        <ButtonGroup :tab-data="tabList" @tabChange="handleTypeClick" />

        <a-select
          v-model="area_id"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin-left: 20px"
          placeholder="选择大区"
          @change="selectChange('area')"
        >
          <a-select-option
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>

        <a-select
          style="width: 132px; height: 40px; margin-left: 10px"
          v-model="groupObj"
          :suffixIcon="suffixIcon"
          @change="selectChange('group')"
          placeholder="选择分组"
          :labelInValue="true"
        >
          <a-select-option :value="undefined">不限分组</a-select-option>
          <a-select-option v-for="d in groupList" :key="d.group_code">
            {{ d.group_name }}
          </a-select-option>
        </a-select>
        <a-input-search
          v-model.trim="word"
          style="width: 300px; margin-left: 10px"
          class="aft-search"
          :placeholder="
            `搜索门店名、${
              tabType == '1' ? '公司名' : '账号昵称'
            }或门店编码关键字`
          "
          @search="inputSearch"
          @blur="inputSearch"
        />
      </div>
      <div>
        <ExcelTypeButton
          :type="excelType"
          @changeExcelType="changeExcelType"
          style="margin-right: 10px"
        />

        <ExcelSetting
          v-hide-by-username
          :modalData="selectData"
          v-bind:currentCheck.sync="currentCheck"
          @excelClick="excelClick"
        />
      </div>
    </div>
    <div class="tools">
      <MySelect
        :modalData="selectData"
        v-bind:currentKey.sync="currentKey"
        @changeItem="mySelectChange"
      >
      </MySelect>
      <a-range-picker
        v-model="dateValue"
        ref="range-picker"
        class="aft-range-picker default"
        :disabled-date="disabledDate"
        locale="zh-cn"
        format="YYYY-MM-DD"
        style="width: 220px"
        @change="dateChange"
        @calendarChange="dateCalendarChange"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
        <template slot="renderExtraFooter">
          <button-tab
            :tabData="rangeData"
            @tabChange="rangeClick"
            :defaultBtnStyle="buttonStyle"
            v-bind:currentValue.sync="currentDateRange"
          ></button-tab>
        </template>
      </a-range-picker>
    </div>
    <a-spin :spinning="totalSpinning">
      <div class="total-box">
        <div class="left-title">
          <IconTitle
            :icon="getChartTitle('icon')"
            :title="getChartTitle('title')"
            :type="1"
          />
        </div>
        <div class="total-chart">
          <MyChart :options="getTopChartOption(totalData)" :id="'top-chart'" />
        </div>
        <div class="total-number" v-if="showTotal">
          合计: {{ totalData.total | changeNum }}
        </div>
        <div class="total-number" v-else></div>
      </div>
    </a-spin>
    <a-spin :spinning="tableSpinning">
      <div class="table-container">
        <div class="table-header">
          <div>{{ tabType == "1" ? "门店名/公司名" : "账号/门店名" }}</div>
          <div></div>
          <div>最新{{ showTotal ? "/合计" : "" }}</div>
          <div>环比增长</div>
        </div>
        <div class="table-main" v-if="tableData && tableData.length > 0">
          <div
            class="table-item"
            v-for="(item, index) in tableData"
            :key="index"
          >
            <div v-if="tabType == '1'">
              <span>{{ item.dealer_name }}</span>
              <span>{{ item.dealer_company_name }}</span>
            </div>
            <div v-else>
              <span>{{ item.nickname }}</span>
              <span>{{ item.dealer_name }}</span>
            </div>
            <div>
              <MyChart
                :options="getCardChartOption(item, index)"
                :id="'card-chart' + index"
              />
            </div>
            <div>
              <span>{{ item.latest_value | changeNum }}</span>
              <span v-if="showTotal">合计: {{ item.total | changeNum }}</span>
            </div>
            <div>
              <!-- <span class="negative-number" v-if="item.latest_ratio < 0">{{
                item.latest_ratio | numRate
              }}</span>
              <span class="positive-number" v-else
                >+{{ item.latest_ratio | numRate }}</span
              > -->
              <div>
                <span class="ratio-number">{{
                  item.latest_ratio | numRate
                }}</span>
                <svg-icon
                  v-if="item.latest_ratio > 0"
                  icon-class="caret-up"
                  class="ratio-icon"
                />
                <svg-icon
                  v-if="item.latest_ratio < 0"
                  icon-class="caret-down"
                  class="ratio-icon"
                />
              </div>
              <div
                class="hover-button"
                @click="routeToProduction(item)"
                v-if="tabType == '2'"
              >
                <svg-icon icon-class="arrow-right2" class="icon" />
              </div>
            </div>
          </div>
        </div>
        <div class="no-result" v-else>
          <Empty />
        </div>
      </div>
    </a-spin>

    <div class="page-div aft-pagination">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "storeTrend"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import MySelect from "@/components/MySelect/MySelect";
import ExcelSetting from "@/components/MySelect/ExcelSetting";
import ButtonTab from "@/components/PlatformTab/BindButtonTab";
import IconTitle from "@/components/IconTitle/IconTitle";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartBarLine from "./options/chartBarLine.js";
import chartLine from "./options/chartLine.js";
import Empty from "@/components/Empty/Empty";
import exportTable from "@/utils/exportTable";

const buttonStyle = {
  minWidth: "56px",
  height: "28px",
  fontSize: "12px",
};
const rangeData = [
  {
    label: "近4周",
    value: "2,4",
  },
  {
    label: "近8周",
    value: "2,8",
  },
  {
    label: "近12周",
    value: "2,12",
  },
  {
    label: "近24周",
    value: "2,24",
  },
  {
    label: "近3月",
    value: "3,3",
  },
  {
    label: "近6月",
    value: "3,6",
  },
  {
    label: "近12月",
    value: "3,12",
  },
];
const selectDefaultData = [
  {
    title: "粉丝",
    icon: "ti-fans",
    children: [
      {
        title: "粉丝增量",
        key: "101",
        color: "#80DC1E",
      },
    ],
  },
  {
    title: "视频",
    icon: "ti-video",
    children: [
      {
        title: "视频发布",
        key: "102",
        color: "#815CFF",
      },
      {
        title: "视频播放量",
        key: "103",
        color: "#815CFF",
      },
      {
        title: "爆款视频",
        key: "104",
        color: "#815CFF",
      },
      {
        title: "视频条均播放",
        key: "105",
        color: "#815CFF",
      },
    ],
  },
  {
    title: "直播",
    icon: "ti-live",
    children: [
      {
        title: "直播场次",
        key: "106",
        color: "#E85A34",
      },
      {
        title: "有效直播场次",
        key: "107",
        color: "#E85A34",
        tooltipTitle: "有效直播：直播时长 ≥30min",
      },
      {
        title: "爆款直播",
        key: "108",
        color: "#E85A34",
      },
      {
        title: "直播观看人次",
        key: "110",
        color: "#E85A34",
      },
      {
        title: "直播观看人数",
        key: "109",
        color: "#E85A34",
      },
      {
        title: "直播场均观看人数",
        key: "111",
        color: "#E85A34",
      },
      {
        title: "直播场均观看人次",
        key: "112",
        color: "#E85A34",
      },
    ],
  },
  {
    title: "线索",
    icon: "ti-clue",
    children: [
      {
        title: "声量线索",
        key: "113",
        color: "#5EF2F4",
        tooltipTitle:
          "用户私信 + 短视频、直播线索评论购车意向人数；<br/>每日月增量累计去重",
      },
      // {
      //   title: "销售线索",
      //   key: "114",
      //   color: "#5EF2F4",
      //   tooltipTitle:
      //     "私信、直播、短视频、主页、行业工具等渠道的(电话)留资数量；<br/>每日月增量累计去重",
      // },
    ],
  },
];
export default {
  components: {
    PlatformHeader,
    ExcelTypeButton,
    ButtonGroup,
    MySelect,
    ExcelSetting,
    ButtonTab,
    IconTitle,
    MyChart,
    Empty,
  },
  data() {
    return {
      currentTab: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      tabList: [
        {
          label: "门 店",
          value: "1",
        },
        {
          label: "账 号",
          value: "2",
        },
      ],
      tabType: "1",
      groupObj: undefined,
      area_id: "",
      word: "",
      excelType: false,
      selectData: deepClone(selectDefaultData),
      currentKey: "101",
      currentCheck: ["101"],
      buttonStyle,
      offsetDays: 86400000 * 99, //最多选择100天
      rangeData,
      dateValue: [],
      selectDate: "",
      currentDateRange: "2,4",
      date_type: 2, // 1:日 2:周 3:月
      tableData: [],
      current: 1,
      pageSize: 20,
      total: 0,
      currentSelectItem: {
        title: "粉丝增量",
        key: "101",
        color: "#80DC1E",
      },
      totalData: {
        xData: [],
        yData1: [],
        yData2: [],
        total: "",
      },
      showTotal: true,
      totalSpinning: false,
      tableSpinning: false,
    };
  },
  computed: {
    ...mapState({
      areaList: (state) => state.areaList, // 区域列表
      groupList: (state) => state.groupList,
      trendList: (state) => state.trendList,
      trendTotalData: (state) => state.trendTotalData,
      trendTotalCode: (state) => state.trendTotalCode,
    }),
  },
  watch: {
    areaList(val) {
      if (!val || val.length <= 0) {
        // this.changeState({ data: [], name: "tableData" });
        return false;
      }
      this.area_id = val[0].id;
      // this.getTableData();
    },
  },
  async created() {
    // 默认选择日期控件自定义按钮的第一个
    this.dateValue = this.setButtonDate(this.rangeData[0].value);
    // 获取大区跟分组列表
    Promise.all([this.getAreaList(), this.getSortGroup()]).then(() => {
      if (this.areaList.length == 0 && this.groupList.length == 0) {
        this.$message.error("当前账号没有绑定大区或者分组，请联系管理员");
        return false;
      }
      if (this.areaList && this.areaList.length > 0) {
        this.area_id = this.areaList[0].id;
      } else {
        this.groupObj = this.groupList[0];
      }

      // 查询
      // this.searchList(true);
      // this.searchTotal();
    });
  },
  methods: {
    ...mapActions([
      "getAreaList",
      "getSortGroup",
      "getTrendList",
      "getTrendTotal",
    ]),
    ...mapMutations(["changeState"]),
    // 平台切换
    tabChange(val) {
      this.currentTab = val;

      if (val == "dongchedi") {
        this.selectData = deepClone(selectDefaultData).slice(0, 3);
      } else {
        this.selectData = deepClone(selectDefaultData);
      }
      console.log("this.selectData", this.selectData);
      this.currentKey = "101";
      this.currentSelectItem = {
        title: "粉丝增量",
        key: "101",
        color: "#80DC1E",
      };
      this.currentCheck = ["101"];
      // 查询
      this.searchList(true);
      this.searchTotal();
    },
    // 切换顶部tab（门店/账号）
    handleTypeClick(val) {
      this.tabType = val;

      // 查询
      this.searchList(true);
      this.searchTotal();
    },
    // 下拉框分组、大区切换
    selectChange(type) {
      if (type == "area") {
        this.currentSelectType = "area";
        this.groupObj = undefined;
      } else {
        this.currentSelectType = "group";
        this.area_id = undefined;
      }

      // 查询
      this.searchList(true);
      this.searchTotal();
    },
    mySelectChange(item) {
      console.log("mySelectChange", item);
      this.currentCheck = [item.key];
      this.currentSelectItem = item;
      if (
        this.currentSelectItem.title.indexOf("总") >= 0 ||
        this.currentSelectItem.title.indexOf("均") >= 0
      ) {
        this.showTotal = false;
      } else {
        this.showTotal = true;
      }
      // 查询
      this.searchList(true);
      this.searchTotal();
    },
    // 导出按钮类型切换
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    disabledDate(current) {
      if (this.dateValue[0]) {
        let selectV = moment(this.dateValue[0], "YYYY-MM-DD").valueOf();
        return (
          (current && current >= moment().subtract(1, "days")) ||
          current > moment(new Date(selectV + this.offsetDays), "YYYY-MM-DD") ||
          current < moment(new Date(selectV - this.offsetDays), "YYYY-MM-DD")
        );
      } else {
        return false;
      }
    },
    // 日期切换选择
    dateChange(value) {
      console.log("dateChange", value);
      // 清空自定义按钮选择状态
      this.currentDateRange = "";
      this.selectDate = "";
      this.date_type = 1;
      // 查询
      this.searchList(true);
      this.searchTotal();
    },
    dateCalendarChange(date) {
      this.selectDate = date[0];
    },
    // 输入框查询
    inputSearch() {
      // 查询
      this.searchList(true);
      this.searchTotal();
    },
    // 日期自定义按钮点击
    rangeClick(value) {
      console.log("rangeClick", value);
      // 选中自定义按钮
      this.currentDateRange = value;
      // 为日期选择框展示的日期范围赋值
      this.date_type = value.split(",")[0];
      this.dateValue = this.setButtonDate(value);
      // 关闭日期弹窗
      this.$refs["range-picker"].$refs.picker.sOpen = false;

      // 查询
      this.searchList(true);
      this.searchTotal();
    },
    // 通过自定义按钮选择日期 为日期选择框展示的日期范围赋值（例子:  近四周:2,4   2:周 3:月）
    setButtonDate(value) {
      let startTime = "";
      let endTime = "";
      if (value && value.split(",").length == 2) {
        let type = value.split(",")[0];
        let num = value.split(",")[1];
        if (type == 2) {
          // 周
          startTime = moment()
            .startOf("week")
            .subtract("days", 7 * num);
          endTime = moment()
            .startOf("week")
            .subtract("days", 1);
          console.log([startTime, endTime]);
          return [startTime, endTime];
        } else if (type == 3) {
          // 月
          console.log(num);
          startTime = moment()
            .subtract(num, "months")
            .startOf("month");
          endTime = moment()
            .subtract(1, "months")
            .endOf("month");
          console.log([startTime, endTime]);
          return [startTime, endTime];
        }
      } else {
        return ["", ""];
      }
    },
    getChartTitle(type) {
      let icon = "";
      let title = "";
      this.selectData.forEach((item) => {
        item.children.forEach((i) => {
          if (i.key == this.currentKey) {
            icon = item.icon;
            title = i.title;
          }
        });
      });
      if (type == "icon") {
        return icon;
      } else {
        return title;
      }
    },

    // 页码切换
    pageChange(val) {
      console.log("pageChange", val);
      this.current = val;
      this.searchList();
    },
    // 查询列表
    async searchList(isFirst) {
      this.tableSpinning = true;
      if (isFirst) {
        this.current = 1;
      }
      let data = this.getParams(true);
      await this.getTrendList(data);
      this.tableSpinning = false;
      if (this.trendList && this.trendList.list) {
        this.total = this.trendList.pagination.total;
        this.tableData = this.trendList.list;
        this.trendList.list.forEach((i, index) => {
          let xData = [];
          let yData1 = []; // 周环比
          let yData2 = []; // 实时数据
          i.list.forEach((item) => {
            xData.unshift(item.date_desc);
            yData1.unshift(item.ratio);
            yData2.unshift(item.value);
          });
          this.tableData[index].xData = xData;
          this.tableData[index].yData1 = yData1;
          this.tableData[index].yData2 = yData2;
        });
      } else {
        this.total = 0;
        this.tableData = [];
      }
    },
    // 查询总计
    async searchTotal() {
      this.totalSpinning = true;
      let data = this.getParams(true);
      await this.getTrendTotal(data);
      this.totalSpinning = false;
      // totalData
      let xData = [];
      let yData1 = []; // 周环比
      let yData2 = []; // 实时数据
      if (this.trendTotalData && this.trendTotalData.list) {
        this.trendTotalData.list.forEach((item) => {
          xData.unshift(item.date_desc);
          yData1.unshift(item.value);
          yData2.unshift(item.ratio);
        });
      }
      this.totalData.xData = xData;
      this.totalData.yData1 = yData1;
      this.totalData.yData2 = yData2;
      this.totalData.total = this.trendTotalData.total;
      console.log("searchTotal res", this.trendTotalCode);
      if (this.trendTotalCode == 6000 && this.date_type == 1) {
        // 结束时间 小于等于 前天，则false
        if (
          Date.parse(this.dateValue[1].format("YYYY-MM-DD")) <=
          Date.parse(
            moment()
              .subtract(2, "days")
              .format("YYYY-MM-DD")
          )
        ) {
          return false;
        }

        // 如果 开始时间 为昨日，则开始结束时间都变为前天
        // 如果 结束时间 为昨日，则结束时间为前天
        this.dateValue = [
          this.dateValue[0].format("YYYY-MM-DD") ==
          moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
            ? moment().subtract(2, "days")
            : this.dateValue[0],
          moment().subtract(2, "days"),
        ];

        this.$message.warning(
          "昨日数据预计12：00跑完，页面展示的数据统计时间往前推一天"
        );
        // 查询
        this.searchList(true);
        this.searchTotal();
      }
    },

    // 获取接口参数
    getParams(type) {
      let data = {
        platform: this.currentTab,
        params: {
          account_type: this.tabType,
          indicator_type: this.currentKey,
          date_type: this.date_type,
          region_id: this.area_id,
          group_code: this.groupObj ? this.groupObj.key : "",
          keyword: this.word,
        },
      };
      if (this.date_type == 2 || this.date_type == 3) {
        data.params.date_value = this.currentDateRange.split(",")[1];
      } else {
        data.params.start_time_str =
          this.dateValue[0].format("YYYY-MM-DD") + " 00:00:00";
        data.params.end_time_str =
          this.dateValue[1].format("YYYY-MM-DD") + " 23:59:59";
      }
      if (type) {
        data.params.current = this.current;
        data.params.page_size = this.pageSize;
      }
      return data;
    },
    // 导出
    excelClick() {
      console.log("excelClick currentCheck", this.currentCheck);
      let params = {
        account_type: this.tabType,
        indicator_types: this.currentCheck.join(","),
        date_type: this.date_type,
        region_id: this.area_id,
        group_code: this.groupObj ? this.groupObj.key : "",
        group_name: this.groupObj ? this.groupObj.label.trim() : "",
        keyword: this.word,
        flag: this.excelType ? 1 : 0,
      };
      if (this.date_type == 2 || this.date_type == 3) {
        params.date_value = this.currentDateRange.split(",")[1];
      } else {
        params.start_time_str =
          this.dateValue[0].format("YYYY-MM-DD") + " 00:00:00";
        params.end_time_str =
          this.dateValue[1].format("YYYY-MM-DD") + " 23:59:59";
      }
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/dealers/dealer-trend/export",
        "filename"
      );
    },

    // 顶部柱状图 + 折线图
    getTopChartOption(data) {
      let option = deepClone(chartBarLine);
      option.series[0].data = data.yData1;
      option.series[1].data = data.yData2;
      option.series[0].itemStyle.normal.color = this.currentSelectItem.color;
      option.series[0].name = this.currentSelectItem.title;
      option.xAxis.data = data.xData;
      option.tooltip.formatter = (params) => {
        let str = `<div style="background: #3C4252;border-radius: 8px;width: 183px;height: 93px;">
            <div style="width: 100%;
                height: 25px;
                line-height: 25px;
                font-size: 10px;
                color: #C8CEDE;
                border-bottom: 1px solid rgba(255,255,255, 0.1);
                padding-left: 8px;">
                ${params[0].axisValue}${
          this.date_type == "2" ? "周" : this.date_type == "3" ? "月" : ""
        }
            </div>
            <div style="display:flex;align-items: center;height: 24px;margin-top: 10px;">
            <span style="display:inline-block;margin:0 12px 0 20px;border-radius:4px;width:4px;height:4px;background-color:${
              this.currentSelectItem.color
            };"></span>
            <span style="font-size: 12px;color: #9CA8BA;font-weight: bold;">${
              params[0].seriesName
            }:</span>
            <span style="font-size: 12px;color: #fff;font-weight: bold;margin-left: 8px;">${this.$options.filters.changeNum(
              params[0].data
            )}</span>
            </div>
        `;
        if (params.length == 2) {
          str += `
            <div style="display:flex;align-items: center;height: 24px;">
            <span style="display:inline-block;margin:0 12px 0 20px;border-radius:4px;width:4px;height:4px;background-color:#FFCA5C;"></span>
            <span style="font-size: 12px;color: #9CA8BA;font-weight: bold;">${
              params[1].seriesName
            }:</span>
            <span style="font-size: 12px;color: #fff;font-weight: bold;margin-left: 8px;">${this.$options.filters.numRate2(
              params[1].data
            )}</span>
            </div>`;
        }
        str += `</div>`;
        return str;
      };
      return option;
    },
    getCardChartOption(item, index) {
      let option = deepClone(chartLine);
      option.xAxis.data = item.xData;
      option.tooltip.formatter = (params) => {
        let str = `<div style="background: #3C4252;border-radius: 8px;width: 183px;height: 93px;">
            <div style="width: 100%;
                height: 25px;
                line-height: 25px;
                font-size: 10px;
                color: #C8CEDE;
                border-bottom: 1px solid rgba(255,255,255, 0.1);
                padding-left: 8px;">
                ${params[0].axisValue}${
          this.date_type == "2" ? "周" : this.date_type == "3" ? "月" : ""
        }
            </div>
            <div style="display:flex;align-items: center;height: 24px;margin-top: 10px;">
            <span style="display:inline-block;margin:0 12px 0 20px;border-radius:4px;width:4px;height:4px;background-color:${
              params[0].color
            };"></span>
            <span style="font-size: 12px;color: #9CA8BA;font-weight: bold;">${
              params[0].seriesName
            }:</span>
            <span style="font-size: 12px;color: #fff;font-weight: bold;margin-left: 8px;">${this.$options.filters.changeNum(
              params[0].data
            )}</span>
            </div>
        `;
        if (params && params[1]) {
          str += `
          <div style="display:flex;align-items: center;height: 24px;">
          <span style="display:inline-block;margin:0 12px 0 20px;border-radius:4px;width:4px;height:4px;background-color:#FFCA5C;"></span>
          <span style="font-size: 12px;color: #9CA8BA;font-weight: bold;">${
            params[1].seriesName
          }:</span>
          <span style="font-size: 12px;color: #fff;font-weight: bold;margin-left: 8px;">${this.$options.filters.numRate2(
            params[1].data
          )}</span>
          </div>`;
        }
        str += "</div>";
        return str;
      };
      option.series[1].data = item.yData1;
      option.series[0].data = item.yData2;
      option.series[0].name = this.currentSelectItem.title;
      option.series[0].itemStyle.normal.color = this.currentSelectItem.color;
      return option;
    },
    routeToProduction(item) {
      if (this.tabType == "1") {
        // window.open(
        //   `/dealer/dealerDetail?id=${item.dealer_id}&platform=${this.currentTab}`
        // );
      } else if (this.tabType == "2") {
        this.$router.push({
          path: "/dealer/production",
          query: {
            type: this.currentTab,
            author_id: item.author_id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      margin-left: 24px;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }
}
.total-box {
  width: calc(100% - 40px);
  height: 104px;
  margin-left: 20px;
  background: #2b2f3b;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-title {
    width: 200px;
    padding: 0 0 0 40px;
  }
  .total-chart {
    width: calc(100% - 470px);
    height: 100%;
  }
  .total-number {
    width: 270px;
    padding-right: 30px;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: #ffca5c;
  }
}
.table-container {
  .table-header {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      color: #9ca8ba;
      font-size: 14px;
    }
    > div:nth-child(1) {
      width: 220px;
      padding-left: 20px;
    }
    > div:nth-child(2) {
      width: calc(100% - 510px);
    }
    > div:nth-child(3) {
      width: 145px;
    }
    > div:nth-child(4) {
      width: 145px;
    }
  }
  .table-main {
    height: calc(100vh - 512px);
    overflow-y: auto;
    .table-item {
      height: 120px;
      background: #2b2f3b;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: 1px solid #363a46;
      > div {
        // padding-left: 20px;
        height: 100%;
      }
      > div:nth-child(1) {
        width: 220px;
        padding-left: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        > span:nth-child(1) {
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
          margin-bottom: 4px;
        }
        > span:nth-child(2) {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.65);
          line-height: 20px;
        }
      }
      > div:nth-child(2) {
        width: calc(100% - 510px);
      }
      > div:nth-child(3) {
        width: 145px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        > span:nth-child(1) {
          font-size: 16px;
          color: #ffcc55;
          line-height: 24px;
          margin-bottom: 4px;
        }
        > span:nth-child(2) {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.65);
          line-height: 20px;
        }
      }
      > div:nth-child(4) {
        width: 145px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .positive-number {
          @include font_color("font_color26");
        }
        .negative-number {
          @include font_color("font_color40");
        }
        .ratio-number {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
        }
        .ratio-icon {
          font-size: 10px;
          margin-left: 4px;
        }
        .hover-button {
          width: 36px;
          height: 36px;
          border-radius: 8px;
          background: #2b2f3b;
          text-align: center;
          cursor: pointer;
          opacity: 0;
          margin-right: 20px;
          > .icon {
            font-size: 10px;
            margin-top: 13px;
            color: #9ca8ba;
          }
          &:hover {
            opacity: 1;
            background: #ffca5c;
            > .icon {
              color: #1c1e27;
            }
          }
        }
      }

      &:hover {
        background: #242832;
        > div:nth-child(4) {
          .hover-button {
            opacity: 1;
          }
        }
      }
    }
  }
}

.page-div {
  text-align: right;
  padding: 20px;
}
.no-result {
  width: 100%;
  height: calc(100vh - 540px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
