import echarts from 'echarts'
import filters from '@/filters';

var option = {
  grid: {
    left: "20",
    right: "40",
    bottom: "4",
    top: "20",
    containLabel: true,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: '#ddd'
      }
    },
    backgroundColor: '#3C4252',
    textStyle: {
      color: '#fff',
      lineHeight: 26,
    },
    padding: [5, 10],
    extraCssText: 'box-shadow: 0 0 5px rgba(0,0,0,0.3)'
  },

  xAxis: [{
    show: true,
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)',
      }
    },
    splitArea: {
      // show: true,
      color: '#8890A6',
      lineStyle: {
        color: '#8890A6'
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6',
        fontSize: 10
      },
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: [],

  }],
  yAxis: [{
    type: 'value',
    min: 0,
    // max: 140,
    splitNumber: 3,
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)'
      }
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      show: true,
      formatter: (value, index) => {
        return  filters.changeNum(value);
      },
      textStyle: {
        color: '#8890A6',
        fontSize: 10
      },
    },
    axisTick: {
      show: false,
    },
  }],
  series: []
}

export default option