import Nr from '@/utils/NetworldRequest'
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}


const request = (platform) => {
    let baseURL = getBaseUrl(platform)
  
    return new Nr({
        baseURL: baseURL
    }).getAxios()
}

export default {
    getLiveList(body) {
        return request(body.platform).get('/jjt/overview/dealer/live/rank', { params: body.params })
    },
    getVideoList(body) {
        return request(body.platform).get('/jjt/aweme/awemeWeekHotList', { params: body.params })
    }
}