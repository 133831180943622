<template>
  <div>
    <a-modal
      class="new-aft-modal"
      :title="title"
      :visible="visible"
      :footer="null"
      width="720px"
      @cancel="visible = false"
    >
      <div class="content">
        <div
          class="form-item"
          v-for="(item, index) in awardList"
          :key="index"
          style="width: 534px"
        >
          <a-form-model
            :model="item"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
            style="padding: 16px"
          >
            <a-form-model-item label="获奖名称" prop="awardName">
              <span class="text">{{ item.awardName }}</span>
            </a-form-model-item>
            <a-form-model-item prop="awardImageList" style="position: relative">
              <span slot="label">
                获奖名单图
                <span class="ml-8">({{ item.awardImageList.length }}/5)</span>
              </span>
              <a-upload
                :show-upload-list="{ showRemoveIcon: false }"
                list-type="picture-card"
                :file-list="item.awardImageList"
                @preview="(file) => showImage(file.originUrl)"
                class="aft-upload drawer"
              >
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="获奖说明" v-if="item.awardExplain">
              <span class="text">{{ item.awardExplain }}</span>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
    <ImgPreviewModal ref="ImgPreviewModal" />
  </div>
</template>

<script>
import ImgPreviewModal from "./ImgPreviewModal.vue";
import { aliImgTransform } from "@/utils/util.js";

export default {
  components: { ImgPreviewModal },
  data() {
    return {
      visible: false,

      changeStatus: "",
      awardList: [],
    };
  },
  computed: {
    title() {
      let result = "";
      if (this.changeStatus === "获奖名单") {
        result = "获奖名单";
      } else {
        result = `变更${this.changeStatus}`;
      }
      return result;
    },
  },
  methods: {
    openModal(changeStatus, list) {
      Object.assign(this.$data, this.$options.data());
      this.changeStatus = changeStatus;
      this.awardList = list.map((item) => ({
        ...item,
        awardImageList: item.awardImageList.map((url, index) => ({
          uid: "-1",
          name: `${index}.png`,
          status: "done",
          url: aliImgTransform(url, 100),
          originUrl: url,
          response: { url: url },
        })),
      }));
      this.visible = true;
    },
    showImage(url) {
      this.$refs.ImgPreviewModal.openModal(url);
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep .ant-modal-header {
  padding: 24px;
  border-bottom: none;
  color: #21242c;
}
::v-deep .ant-modal-close-x {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
::v-deep .ant-modal-body {
  padding: 0 24px;
}
::v-deep .ant-modal-footer {
  padding: 20px 24px;
  border-top: none;
}

::v-deep .form-item .ant-form-item-control {
  line-height: 1;
}
::v-deep .form-item .ant-form-item {
  margin-bottom: 0;
}
::v-deep .form-item .ant-form-item + .ant-form-item {
  margin-top: 24px;
}
::v-deep .ant-upload-list-item {
  border: none;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
}

.content {
  height: 456px;
  overflow-y: auto;
}

.form-item {
  border: 1px solid #323744;
  border-radius: 8px;
  margin-bottom: 16px;
  background: rgba(26, 29, 38, 1);
  .text {
    color: rgba(255, 255, 255, 0.88);
    font-size: 14px;
    line-height: 22px;
  }
  .delete-button {
    width: 100%;
    height: 48px;
    border-top: 1px solid #323744;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.88);
    cursor: pointer;
    > span {
      margin-left: 10px;
    }
    &:hover {
      color: #ffcc55;
    }
  }
}
</style>
