<template>
  <div>
    <platform-header
      title="车系表现"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="main-container">
      <div class="tools">
        <div>
          <ButtonGroup :tab-data="buttonTabData" @tabChange="buttonTabChange" />
        </div>
        <div>
          <a-month-picker
            v-model="searchTime"
            class="aft-month-picker default"
            placeholder="Select month"
            :disabled-date="disabledDate"
            @change="onChange"
            locale="zh-cn"
          >
            <div slot="suffixIcon">
              <svg-icon :icon-class="'range-picker'" />
            </div>
          </a-month-picker>
        </div>
      </div>
      <div class="table">
        <div class="header">
          <div class="row">
            <div class="col col-1">车系名称</div>
            <div class="col col-2">播放量</div>
            <div class="col col-2">视频量</div>
            <div class="col col-2">相关话题数</div>
            <!-- <div class="col col-2">KOS参与比例</div> -->
            <div class="col col-3">热搜话题</div>
            <div class="col col-4">操作</div>
          </div>
        </div>
        <a-spin :spinning="tableLoading">
          <div class="table-body" v-if="true">
            <div
              class="row-main"
              v-for="item in tableData"
              :key="item.series.id"
            >
              <div class="row-item">
                <div class="col col-1">
                  <div class="series-box">
                    <img
                      :src="ossHost + item.series.image"
                      width="106"
                      alt=""
                    />
                    <div>
                      <span>{{ item.series.name || "-" }}</span>
                    </div>
                  </div>
                </div>
                <div class="col col-2">{{ item.play_volume | changeNum }}</div>
                <div class="col col-2">{{ item.aweme_count | changeNum }}</div>
                <div class="col col-2">{{ item.topic_count | changeNum }}</div>
                <!-- <div class="col col-2">{{ 31232 | changeNum }}</div> -->
                <div class="col col-3">
                  <div class="topic-box">
                    <div v-for="item in item.topic_list" :key="item.topic_id">
                      <a-tooltip>
                        <template slot="title">#{{ item.topic_name }}</template>
                        #{{ item.topic_name }}
                      </a-tooltip>
                    </div>
                  </div>
                </div>
                <div class="col col-4">
                  <div class="action-box">
                    <span @click="routerTo(item.series)">详情</span>
                    <span @click="getCompetitor(item)"
                      >竞品对比
                      <svg-icon
                        icon-class="arrow-down"
                        :class="[
                          'icon',
                          currentRow && currentRow.series.id == item.series.id
                            ? 'active'
                            : '',
                        ]"
                    /></span>
                  </div>
                </div>
              </div>
              <div
                class="children-main"
                v-if="currentRow && currentRow.series.id == item.series.id"
              >
                <div class="loading-box" v-if="childrenLoading">
                  <a-spin :spinning="childrenLoading"></a-spin>
                </div>
                <div class="children-list" v-else>
                  <div
                    class="children-list-item"
                    v-for="(item, index) in childrenData"
                    :key="index"
                  >
                    <div class="col col-1">
                      <div class="series-box">
                        <img
                          :src="ossHost + item.series.image"
                          width="68"
                          alt=""
                        />
                        <div>
                          <span>{{ item.series.name || "-" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col col-2">
                      {{ item.play_volume | changeNum }}
                    </div>
                    <div class="col col-2">
                      {{ item.aweme_count | changeNum }}
                    </div>
                    <div class="col col-2">
                      {{ item.topic_count | changeNum }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="no-result" v-else>
            <Empty title="暂无数据" />
          </div>
        </a-spin>
      </div>
      <div class="statistics">
        <div class="statistics-item">
          <span class="label">本品车系数量</span>
          <span class="number">{{
            statisticsData.series_count_summary | changeNum
          }}</span>
        </div>
        <div class="statistics-item">
          <span class="label">播放总量</span>
          <span class="number">{{
            statisticsData.play_volume_summary | changeNum
          }}</span>
        </div>
        <div class="statistics-item">
          <span class="label">视频总数</span>
          <span class="number">{{
            statisticsData.aweme_count_summary | changeNum
          }}</span>
        </div>
        <div class="statistics-item">
          <span class="label">相关话题总数</span>
          <span class="number">{{
            statisticsData.topic_count_summary | changeNum
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import moment from "moment";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import chartLine from "./chartLine";
import chartBar from "./chartBar";
import deepClone from "@/utils/clone-utils";
import Empty from "@/components/Empty/Empty";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import api from "@/api/modelPerformanceN.js";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "modelPerformanceN"
);
export default {
  name: "modelPerformanceN",
  components: { PlatformHeader, MyChart, ButtonGroup, Empty },
  data() {
    return {
      msg: "车系表现",
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      currentTab: "",

      buttonTabData: [
        {
          label: "按播放量排序",
          value: "play_volume",
        },
        {
          label: "按视频数排序",
          value: "aweme_count",
        },
      ],
      currentButtonTab: "play_volume",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      searchTime: moment().subtract(1, "months"),
      disabledDate(current) {
        // Can not select days before today and today
        // return current && current >= moment().add(1, 'months').startOf("day");
        return (
          current.valueOf() <= moment("2022-05-31 23:59:59").valueOf() ||
          current >= moment().endOf("day")
        );
      },
      currentRow: null,
      barOptions: null,
      tableLoading: false,
      childrenLoading: false,
      tableData: [],
      childrenData: [],
      statisticsData: {},
    };
  },

  watch: {},
  created() {
    // this.loadTableData();
  },
  methods: {
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.loadTableData();
    },
    // 播放、视频 tab切换
    buttonTabChange(val) {
      this.currentButtonTab = val;
      this.currentRow = null;
      this.tableData = this.sortTable(this.tableData);
    },
    loadTableData() {
      if (!this.searchTime) {
        this.$message.warning("时间必选，默认选择上月!");
        this.searchTime = moment().subtract(1, "months");
      }
      this.currentRow = null;
      let params = {
        start_time: moment(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        platform: this.currentTab,
      };
      this.tableLoading = true;
      api.getSeriesList(params).then((res) => {
        this.tableLoading = false;
        console.log("getSeriesList", res);
        if (res.code == 0) {
          this.tableData = this.sortTable(res.data.list);
          const {
            aweme_count_summary,
            play_volume_summary,
            series_count_summary,
            topic_count_summary,
          } = res.data;
          this.statisticsData = {
            aweme_count_summary,
            play_volume_summary,
            series_count_summary,
            topic_count_summary,
          };
        } else {
          this.tableData = [];
          this.statisticsData = {};
          this.$message.warning("获取车系列表失败");
        }
      });
    },
    // 排序列表
    sortTable(arr) {
      if (!arr || arr.length == 0) {
        return [];
      }
      let newArr = arr.sort((a, b) => {
        return b[this.currentButtonTab] - a[this.currentButtonTab];
      });
      return newArr;
    },
    onChange(date, dateString) {
      this.loadTableData();
    },
    getCompetitor(item) {
      this.childrenData = [];
      if (this.currentRow == item) {
        this.currentRow = null;
        return false;
      }
      this.currentRow = item;
      let params = {
        start_time: moment(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        series_id: item.series.id,
        platform: this.currentTab,
      };
      this.childrenLoading = true;
      api.getCompetitorById(params).then((res) => {
        this.childrenLoading = false;
        console.log("getCompetitorById", res);
        if (res.code == 0) {
          this.childrenData = this.sortTable(res.data);
        } else {
          this.$message.warning("获取竞品列表失败");
        }
      });
    },
    routerTo(row) {
      console.log(row);
      let routeUrl = `modelDetailN?id=${row.id}&platform=${
        this.currentTab
      }&date=${moment(this.searchTime).format("YYYY-MM")}`;
      window.open(routeUrl, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.main-container {
  width: 100%;
  height: calc(100vh - 192px);
  padding: 0 24px;
  .tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .table {
    @include background_color("background_color2");

    border-radius: 8px 8px 0 0;
    overflow: hidden;
    .header {
      @include background_color("background_color42");
      .row {
        height: 52px;
        font-size: 14px;
        @include font_color("font_color22");
        display: flex;
        align-items: center;
        .col {
          height: 52px;
          line-height: 52px;
          display: inline-block;
          &.col-1 {
            width: 30.5%;
            padding-left: 24px;
          }
          &.col-2 {
            width: 10%;
          }
          &.col-3 {
            width: 28.5%;
          }
          &.col-4 {
            width: 11%;
          }
        }
      }
    }
    &-body {
      height: calc(100vh - 370px);
      overflow-y: auto;
      .row-main {
        .row-item {
          height: 106px;
          display: flex;
          flex-wrap: nowrap;
          background: #2b2f3b;
          border-bottom: 1px solid #3c4252;
          .col {
            height: 106px;
            display: flex;
            align-items: center;
            font-size: 24px;
            color: rgba(255, 255, 255, 0.85);
            &.col-1 {
              width: 30.5%;
              padding-left: 24px;
            }
            &.col-2 {
              width: 10%;
            }
            &.col-3 {
              width: 28.5%;
            }
            &.col-4 {
              width: 11%;
            }
            .series-box {
              width: 100%;
              height: 100%;
              padding-right: 32px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 13px;
                > span:first-child {
                  font-size: 16px;
                  color: rgba(255, 255, 255, 0.85);
                  line-height: 24px;
                }
              }
            }

            .topic-box {
              color: rgba(255, 255, 255, 0.65);
              line-height: 22px;
              width: calc(100% - 32px);
              height: 74px;
              margin: 16px 0;
              overflow-y: auto;
              font-size: 14px;
              > div {
                width: calc(100% - 16px);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
              }
            }

            .action-box {
              font-size: 14px;
              color: #ffcc55;
              line-height: 22px;
              > span {
                cursor: pointer;
              }
              > span:last-child {
                margin-left: 16px;
                .icon {
                  transition: all 0.3s;
                  font-size: 9px;
                  margin-left: 4px;
                }
                .icon.active {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
        .children-main {
          background: #1a1d26;
          border-left: 1px solid #3c4252;
          border-right: 1px solid #3c4252;
          transition: all 0.3s;
          .loading-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 212px;
          }
          .children-list {
            transition: all 0.3s;
            &-item {
              height: 106px;
              display: flex;
              align-items: center;
              font-size: 16px;
              color: rgba(255, 255, 255, 0.85);
              border-bottom: 1px solid #3c4252;
              .col {
                height: 106px;
                display: flex;
                align-items: center;
                &.col-1 {
                  width: 30.5%;
                  padding-left: 54px;
                }
                &.col-2 {
                  width: 10%;
                }
                &.col-3 {
                  width: 28.5%;
                }
                &.col-4 {
                  width: 11%;
                }
                .series-box {
                  width: 100%;
                  height: 100%;
                  padding-right: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 13px;
                    > span:first-child {
                      font-size: 14px;
                      color: rgba(255, 255, 255, 0.85);
                      line-height: 22px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .no-result {
      height: calc(100vh - 370px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .statistics {
    width: 100%;
    height: 54px;
    background: #242832;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-radius: 0 0 8px 8px;
    margin: 30px 0;
    &-item {
      margin-right: 60px;
      .label {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.45);
      }
      .number {
        font-size: 14px;
        margin-left: 8px;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}
</style>
