<template>
  <div>
    <platform-header
      title="账号授权管理"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <div class="left">
        <div class="button" @click="goBack">
          <span class="icon"
            ><svg-icon icon-class="arrow-right" class="arrow-right"
          /></span>
          <span>返回</span>
        </div>
        <a-select
          v-model="regionValue"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin-right: 20px"
          placeholder="选择大区"
        >
          <a-select-option value="" v-if="isAllRegion"
            >不限大区</a-select-option
          >
          <a-select-option
            :value="item.id"
            v-for="item in areaData"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>

        <a-input-search
          v-model.trim="keyword"
          style="width: 356px; height: 40px; margin-right: 20px"
          class="aft-search"
          placeholder="搜索门店名、账号昵称或门店编码关键字"
        />
      </div>
      <div class="right">
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button class="button" @click="exportExcel" :loading="btn_Loaidng">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>

    <div class="tools2">
      <a-select
        v-model="groupValue"
        :suffixIcon="suffixIcon"
        style="width: 256px; height: 40px; margin-right: 20px"
        placeholder="选择分组"
      >
        <a-select-option value="">不限分组</a-select-option>
        <a-select-option
          :value="item.group_code"
          v-for="item in groupData"
          :key="item.group_code"
        >
          {{ item.group_name }}
        </a-select-option>
      </a-select>
      <a-select
        v-model="stateValue"
        :suffixIcon="suffixIcon"
        style="width: 132px; height: 40px; margin-right: 20px"
        placeholder="授权状态"
      >
        <a-select-option value="">全部状态</a-select-option>
        <a-select-option
          :value="item.id"
          v-for="item in stateData[currentTab]"
          :key="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-button class="search-btn" @click="onSearch">确定</a-button>
    </div>
    <div class="info">
      <div class="platform" v-if="currentTab === 'douyin'">
        <svg-icon icon-class="douyin" class="icon" />
        <span class="name">抖音</span>
      </div>
      <div class="platform" v-else-if="currentTab === 'kuaishou'">
        <svg-icon icon-class="kuaishou2" class="icon" />
        <span class="name">快手</span>
      </div>
      <div class="platform" v-else>
        <svg-icon icon-class="dongchedi" class="icon" />
        <span class="name">懂车帝</span>
      </div>
      <div class="txt">
        {{ currentTab == "dongchedi" ? "头条授权" : "服务平台授权" }}比例：<span
          >{{ p1Rate | numRateAbs2 }}</span
        >
      </div>
      <div class="txt">
        {{
          currentTab == "dongchedi" ? "卖车通授权" : "开放平台授权"
        }}比例：<span>{{ p2Rate | numRateAbs2 }}</span>
      </div>
      <div class="txt" v-if="currentTab == 'douyin'">
        高级数据授权比例：<span>{{ p3Rate | numRateAbs2 }}</span>
      </div>
    </div>
    <div class="content">
      <div class="table">
        <a-table
          :columns="currentTab == 'douyin' ? columns_douyin : columns"
          :data-source="tableData"
          :scroll="{ x: '100%', y: 'calc(100vh - 460px)' }"
          :rowKey="(data, index) => index"
          :pagination="false"
          :loading="loading"
          :locale="{ emptyText: '暂无数据' }"
        >
          <div slot="customTitle1">
            <span style="font-size: 12px">{{
              currentTab == "dongchedi" ? "头条授权" : "服务平台授权"
            }}</span>
          </div>
          <div slot="customTitle2">
            <span style="font-size: 12px">{{
              currentTab == "dongchedi" ? "卖车通授权" : "开放平台授权"
            }}</span>
          </div>
          <div slot="customTitle3">
            <span style="font-size: 12px">高级数据授权</span>
          </div>
          <div slot="dealer_name" slot-scope="text, record">
            <div class="dealer-title">
              <img
                src="@/assets/img/type-store.png"
                width="20"
                style="margin-right: 4px"
                alt=""
              />{{ record.dealer_name || "-" }}
            </div>

            <div class="nickname">
              <img
                src="@/assets/img/type-lanv.png"
                v-if="record.verification_type == 2"
                width="16"
                style="
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
                  margin-bottom: 2px;
                "
                alt=""
              />
              <img
                src="@/assets/img/type-ordinary.png"
                v-else
                width="16"
                style="
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
                  margin-bottom: 2px;
                "
                alt=""
              />
              {{ record.nickname || "-" }}
            </div>
          </div>
          <div slot="code" slot-scope="text, record">
            <div class="dealer-title">{{ record.code || "-" }}</div>
          </div>
          <div slot="oauth_status1" slot-scope="text, record">
            <span
              class="yellow"
              v-if="record.oauth_status1 == 0 || !record.oauth_status1"
              >未授权</span
            >
            <span class="txt" v-else-if="record.oauth_status1 == 1">{{
              record.oauth_day1 ? `已授权${record.oauth_day1}天` : "已授权"
            }}</span>
            <span class="orange" v-else-if="record.oauth_status1 == 2">{{
              record.oauth_expire_day1
                ? `已失效${record.oauth_expire_day1}天`
                : "授权失效"
            }}</span>
            <span class="orange" v-else></span>
          </div>
          <div slot="oauth_status2" slot-scope="text, record">
            <span
              class="yellow"
              v-if="
                record.oauth_status2 == 0 ||
                  record.oauth_status2 == 3 ||
                  record.oauth_status2 == 6 ||
                  !record.oauth_status2
              "
              >未授权</span
            >
            <span class="txt" v-else-if="record.oauth_status2 == 1">{{
              record.oauth_day2 ? `已授权${record.oauth_day2}天` : "已授权"
            }}</span>
            <span class="orange" v-else-if="record.oauth_status2 == 2">{{
              record.oauth_expire_day2
                ? `已失效${record.oauth_expire_day2}天`
                : "授权失效"
            }}</span>
            <span
              class="txt"
              v-else-if="record.oauth_status2 == 4 || record.oauth_status2 == 5"
              >授权中</span
            >
          </div>
          <div slot="oauth_status3" slot-scope="text, record">
            <span
              class="yellow"
              v-if="
                record.oauth_status3 == 0 ||
                  record.oauth_status3 == 6 ||
                  !record.oauth_status3
              "
              >未授权</span
            >
            <span class="txt" v-else-if="record.oauth_status3 == 1">{{
              record.oauth_day3 ? `已授权${record.oauth_day3}天` : "已授权"
            }}</span>
            <span class="orange" v-else-if="record.oauth_status3 == 4">{{
              record.oauth_expire_day3
                ? `已失效${record.oauth_expire_day3}天`
                : "授权失效"
            }}</span>
            <span class="txt" v-else-if="record.oauth_status3 == 5"
              >授权中</span
            >
          </div>
        </a-table>
      </div>
      <div class="page-div aft-pagination">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/accountAuthorization.js";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import exportTable from "@/utils/exportTable";
import { getToken, getPrincipalId } from "@/utils/auth";

export default {
  components: {
    PlatformHeader,
    ExcelTypeButton,
  },
  data() {
    return {
      currentTab: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      btn_Loaidng: false,
      regionValue: undefined,
      groupValue: undefined,
      stateValue: undefined,
      keyword: "",
      areaData: [],
      groupData: [],
      stateData: {
        douyin: [
          { id: 0, name: "未授权-服务" },
          { id: 1, name: "已授权-服务" },
          { id: 2, name: "授权失效-服务" },
          { id: 3, name: "未授权-开放" },
          { id: 4, name: "已授权-开放" },
          { id: 5, name: "授权失效-开放" },
          { id: 6, name: "授权中-开放" },
          { id: 7, name: "未授权-高级" },
          { id: 8, name: "已授权-高级" },
          { id: 9, name: "授权失效-高级" },
          { id: 10, name: "授权中-高级" },
        ],
        kuaishou: [
          { id: 0, name: "未授权-服务" },
          { id: 1, name: "已授权-服务" },
          { id: 2, name: "授权失效-服务" },
          { id: 3, name: "未授权-开放" },
          { id: 4, name: "已授权-开放" },
          { id: 5, name: "授权失效-开放" },
          { id: 6, name: "授权中-开放" },
        ],
        dongchedi: [
          { id: 0, name: "未授权-头条" },
          { id: 1, name: "已授权-头条" },
          { id: 2, name: "授权失效-头条" },
          { id: 3, name: "未授权-卖车通" },
          { id: 4, name: "已授权-卖车通" },
          { id: 5, name: "授权失效-卖车通" },
        ],
      },
      p1Rate: 0,
      p2Rate: 0,
      p3Rate: 0,
      tableData: [],
      excelType: false,
      columns_douyin: [
        {
          dataIndex: "area_name",
          title: "大区",
          width: "10%",
          align: "center",
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称/账号昵称",
          width: "23%",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "store_code",
          title: "门店编码",
          width: "12%",
          align: "center",
        },
        {
          dataIndex: "code",
          title: "平台号",
          scopedSlots: { customRender: "code" },
          width: "12%",
          align: "center",
        },
        {
          dataIndex: "oauth_status1",
          slots: { title: "customTitle1" },
          scopedSlots: { customRender: "oauth_status1" },
          width: "12%",
          align: "center",
        },
        {
          dataIndex: "oauth_status2",
          slots: { title: "customTitle2" },
          scopedSlots: { customRender: "oauth_status2" },
          width: "14%",
          align: "center",
        },
        {
          show: false,
          dataIndex: "oauth_status3",
          slots: { title: "customTitle3" },
          scopedSlots: { customRender: "oauth_status3" },
          width: "14%",
          align: "center",
        },
      ],
      columns: [
        {
          dataIndex: "area_name",
          title: "大区",
          width: "10%",
          align: "center",
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称/账号昵称",
          width: "36%",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "store_code",
          title: "门店编码",
          width: "12%",
          align: "center",
        },
        {
          dataIndex: "code",
          title: "平台号",
          scopedSlots: { customRender: "code" },
          width: "12%",
          align: "center",
        },
        {
          dataIndex: "oauth_status1",
          slots: { title: "customTitle1" },
          scopedSlots: { customRender: "oauth_status1" },
          width: "12%",
          align: "center",
        },
        {
          dataIndex: "oauth_status2",
          slots: { title: "customTitle2" },
          scopedSlots: { customRender: "oauth_status2" },
          width: "15%",
          align: "center",
        },
      ],
      loading: false,
      current: 1,
      pageSize: 20,
      total: 0,
      isAllRegion: false,
      currentParams: null,
    };
  },
  async created() {
    await this.getAreaInfo();
    this.getAllGroup();
  },
  methods: {
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.currentParams = null;
      this.regionValue = undefined;
      this.groupValue = undefined;
      this.stateValue = undefined;
      this.keyword = "";
      this.current = 1;
      this.getAuthorizationRate();
      this.getList();
    },
    // 获取大区
    getAreaInfo() {
      api.getAreaInfo().then((res) => {
        if (res.data.code === 0) {
          this.areaData = res.data.data.region_list || [];
          if (res.data.data.type === 10) {
            this.isAllRegion = true;
            this.regionValue = "";
          } else {
            this.regionValue = this.areaData[0].id;
          }
        } else {
          this.areaData = [];
          this.regionValue = undefined;
          console.error("获取大区失败");
        }

        // this.getAuthorizationRate();
        // this.getList();
      });
    },
    // 获取全部分组
    getAllGroup() {
      api.getAllGroup().then((res) => {
        if (Array.isArray(res.data.data)) {
          this.groupData = res.data.data || [];
        } else {
          this.groupData = [];
          console.error("获取大区失败");
        }
      });
    },
    //获取门店账号授权比例
    getAuthorizationRate() {
      let body = {
        platform: this.currentTab,
        params: {
          region_id: this.regionValue,
          group_id: this.groupValue,
        },
      };

      api.getAuthorizationRate(body).then((res) => {
        if (res.data.code === 0) {
          this.p1Rate = res.data.data.p1 || 0;
          this.p2Rate = res.data.data.p2 || 0;
          this.p3Rate = res.data.data.p3 || 0;
        } else {
          this.p1Rate = 0;
          this.p2Rate = 0;
          this.p3Rate = 0;
          console.error("获取大区失败");
        }
      });
    },
    //获取列表
    getList(params) {
      console.log("this.regionValue", this.regionValue);
      this.loading = true;
      let body = {
        platform: this.currentTab,
        params: {
          region_id: this.currentParams
            ? this.currentParams.region_id
            : undefined,
          group_id: this.currentParams
            ? this.currentParams.group_id
            : undefined,
          status: this.currentParams ? this.currentParams.status : undefined,
          keyword: this.currentParams ? this.currentParams.keyword : "",
          current: this.current,
          page_size: this.pageSize,
        },
      };
      // 只有确定的时候
      if (params) {
        body.params.region_id = params.region_id;
        body.params.group_id = params.group_id;
        body.params.status = params.status;
        body.params.keyword = params.keyword;
      }
      console.log(this.currentParams);
      console.log("body", body);

      api.getAuthorList(body).then((res) => {
        this.loading = false;

        if (res.data.code === 0) {
          this.tableData = res.data.data.list || [];
          this.total = res.data.data.pagination.total || 0;
        } else {
          this.tableData = [];
          this.total = 0;
          console.error("获取授权列表失败");
        }
      });
    },
    // 页码切换
    pageChange(val) {
      this.getList();
    },
    onSearch() {
      this.current = 1;
      this.getAuthorizationRate();
      this.currentParams = {
        region_id: this.regionValue,
        group_id: this.groupValue,
        status: this.stateValue,
        keyword: this.keyword,
      };
      this.getList(this.currentParams);
    },
    goBack() {
      this.$router.push({
        path: `/dealer/storeManagement`,
      });
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    exportExcel() {
      console.log("excelType", this.excelType);
      let params = {
        region_id: this.regionValue || undefined,
        group_id: this.groupValue || undefined,
        status: this.stateValue,
        keyword: this.keyword || "",
        flag: this.excelType ? 1 : 0,
      };
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/dealers/author-authorization/export",
        "filename"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}

.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.tools {
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  .left {
    display: flex;
    align-items: center;
    .button {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 24px;
      margin-right: 30px;
      font-size: 14px;
      @include font_color("font_color22");
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 2px;
        height: 24px;
        @include background_color("background_color62");
      }
      .icon {
        margin-right: 6px;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        display: block;
        overflow: hidden;
      }
      &:hover .icon {
        @include background_color("background_color2");
      }
      .arrow-right {
        font-size: 22px;
        transform: rotate(180deg);
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    .button {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }
}
.tools2 {
  padding: 0 24px 0 130px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  .search-btn {
    @include border_color("border_color4");
    @include font_color("font_color17");
    @include background_color("background_color13");
    width: 80px;
    height: 36px;
    border-radius: 8px;
  }
}
.info {
  display: flex;
  align-items: center;
  margin: 0 24px 10px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  @include background_color("background_color5");
  .platform {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100%;
    @include background_color("background_color26");
    .icon {
      font-size: 20px;
      @include font_color("font_color23");
    }
    .name {
      padding-left: 5px;
      font-size: 14px;
      @include font_color("font_color21");
    }
  }
  .txt {
    padding: 0 24px;
    font-size: 14px;
    @include font_color("font_color21");
    & + .txt {
      border-left: 1px solid;
      @include border_color("border_color16");
    }
    span {
      @include font_color("font_color4");
    }
  }
}
.content {
  padding: 0 24px;
  .table {
    height: calc(100vh - 396px);
    border-radius: 16px;
    overflow-y: auto;
    @include font_color("font_color1");
    .dealer-title {
      font-size: 14px;
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .nickname {
      margin-top: 4px;
      max-width: 240px;
      font-size: 12px;
      @include font_color("font_color22");
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span {
      font-size: 14px;
    }
    .orange {
      @include font_color("font_color27");
    }
    .yellow {
      @include font_color("font_color4");
    }
  }
}
.page-div {
  text-align: right;
  padding: 20px 0;
}

/deep/ .ant-table-body {
  overflow-x: unset !important;
}
/deep/ .ant-table-thead > tr > th {
  height: 74px !important;
  background-color: #2b2f3b !important;
}
/deep/ .ant-table-tbody > tr {
}
/deep/ .ant-table-tbody > tr > td {
  height: 88px;
  color: #d0d6e6;
  border-bottom: 1px solid #323744 !important;
  background-color: #2b2f3b !important;
}
</style>
