
var option = {
  backgroundColor: 'rgba(0,0,0,0)',
  legend: {
    left: 0,
    top: 20,
    icon: 'circle',
    itemWidth: 6,
    itemHeight: 6,
    textStyle: {
      color: '#fff',
    },
    data: []
  },
  grid: {
    left: '20',
    right: '60',
    bottom: '20',
    containLabel: true
  },

  yAxis: {
    type: 'category',
    inverse: true,
    axisLine: {
      show: true,
    },
    axisTick: {
      show: false,
    },
    axisPointer: {
      label: {
        show: true,
        //margin: 30
      },
    },
    postion: 'right',
    data: [],
  },
  xAxis: [{
    type: 'value',

    splitLine: {
      show: true,
      lineStyle: {
        type: "solid",
        color: 'rgba(50, 55, 68, 0.4)'
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6',  //更改坐标轴文字颜色
        fontSize: 10      //更改坐标轴文字大小
      }
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
  }

  ],
  series: [
  ]
}

export default option