<template>
  <a-drawer
    placement="right"
    width="378"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>切换活动</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <a-spin :spinning="spinning" style="width: 100%">
          <div
            :class="[
              'activity-item',
              currentItem.id == item.id ? 'active' : '',
            ]"
            v-for="(item, index) in activityList"
            :key="index"
            @click="changeActivity(item)"
            v-track:click="{
              action: '切换活动',
              activityId: item.id,
              activityName: item.activityName,
            }"
          >
            <div class="activity-item-title">
              {{ item.activityName || "-" }}
            </div>
            <div class="activity-item-date">
              <span class="time"
                >{{ item.startTime || "-" }} 至 {{ item.endTime || "-" }}</span
              >
              <span class="status icon1" v-if="item.activityStatus == 2">{{
                returnTagText(item)
              }}</span>
              <span class="status icon2" v-else-if="item.activityStatus == 3">{{
                returnTagText(item)
              }}</span>
              <span class="status icon3" v-else>{{ returnTagText(item) }}</span>
            </div>
          </div>
        </a-spin>
      </div>
      <div class="drawer-tools">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
  </a-drawer>
</template>

<script>
import api from "@/api/marketingActivityList.js";
import Empty from "@/components/Empty/Empty";

export default {
  components: { Empty },
  props: {
    currentItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    activityStatus: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      visible: false,
      activityList: [],
      current: 1,
      pageSize: 10,
      total: 0,
      spinning: false,
    };
  },
  methods: {
    showDrawer() {
      this.current = 1;
      this.total = 0;
      this.activityList = [];
      this.visible = true;
      this.getActivityList();
    },

    // 关闭抽屉
    onClose() {
      this.visible = false;
    },
    // 获取活动列表
    getActivityList() {
      let params = {
        page: this.current,
        size: this.pageSize,
        isTaskRate: false,
        activityStatus: this.activityStatus,
      };
      this.spinning = true;
      api
        .getActivityList(params)
        .then((res) => {
          if (res.data.code == 200) {
            this.activityList = res.data.data.list || [];
            this.total = res.data.data.total || 0;
          } else {
            this.activityList = [];
            this.$message.error(res.data.message || "获取活动列表失败");
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },

    returnTagText(item) {
      if (item.activityStatus == 2) {
        let m1 = moment(moment(item.startTime).format("YYYY-MM-DD"));
        let m2 = moment(moment().format("YYYY-MM-DD"));
        console.log(m2);
        let day = m2.diff(m1, "day");
        return `已进行${day + 1}天`;
      } else if (item.activityStatus == 3) {
        return "已结束";
      } else {
        let m1 = moment(moment().format("YYYY-MM-DD"));
        let m2 = moment(moment(item.startTime).format("YYYY-MM-DD"));
        let day = m2.diff(m1, "day");
        return `距离开始剩${day}天`;
      }
    },
    // 调用父组件方法，刷新活动对应数据
    changeActivity(item) {
      this.$emit("changeActivity", item);
      this.onClose();
    },
    // 切换页码
    pageChange() {
      this.getActivityList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 116px);
    padding: 16px 24px 0;
    overflow-y: auto;
    overflow-x: hidden;
    .activity-item {
      width: 330px;
      height: 80px;
      background: #2b2f3b;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      padding: 16px;
      cursor: pointer;
      &.active {
        background: #3b3835;
        box-shadow: inset 0px -1px 0px 0px #363a46;
        border: 1px solid #ffcc55;
      }
      &-title {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 22px;
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        margin-bottom: 5px;
      }
      &-date {
        height: 22px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .status {
          padding: 0 8px;
          height: 22px;
          border-radius: 4px;
          font-size: 12px;
          text-align: center;
          line-height: 22px;
          margin-left: 12px;
        }
        .icon1 {
          color: #27c346;
          background: rgba(39, 195, 70, 0.2);
        }
        .icon2 {
          color: #8890a6;
          background: #3a3e4a;
        }
        .icon3 {
          color: #ff9e3d;
          background: rgba(255, 158, 61, 0.2);
        }
        .time {
          color: #8890a6;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
