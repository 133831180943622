<template>
  <div class="container">
    <platform-header title="活动列表" :showPlatform="false" />
    <div class="tools">
      <div>
        <ButtonGroup
          :tab-data="tabsList"
          @tabChange="changeTabs"
          @tabNameChange="changeTabsName"
        />
        <a-input-search
          v-model.trim="activityName"
          v-emoji
          @search="
            trackAction = '搜索活动';
            getActivityList(true);
          "
          @pressEnter="
            trackAction = '搜索活动';
            getActivityList(true);
          "
          class="aft-search"
          placeholder="搜索活动名称"
          style="width: 240px; margin-left: 16px"
        >
        </a-input-search>
      </div>
      <div class="create-button" @click="createActivity" v-if="isAllRegion">
        <svg-icon
          icon-class="icon-add"
          style="margin-right: 12px; font-size: 12px"
        />
        <span>创建活动</span>
      </div>
    </div>

    <a-spin class="content2-wrap" :spinning="loading">
      <div class="content2">
        <div class="content2__left">
          <a-spin class="left-spin" :spinning="leftLoading">
            <ScrollView
              v-if="tableData.length > 0"
              scroll-y
              @scroll-to-lower="handleScrollToLower"
            >
              <div>
                <ActItem
                  v-for="item in tableData"
                  :key="item.id"
                  :data="item"
                  :is-active="item.id === currActId"
                  @click="handleActItemClick(item)"
                />
              </div>
            </ScrollView>
            <div class="empty-wrap" v-else>
              <Empty />
            </div>
          </a-spin>
        </div>
        <ActivityDetail
          :isAllRegion="isAllRegion"
          @operate="handleOperate"
          ref="ActivityDetail"
        />
      </div>
    </a-spin>

    <a-modal
      v-model="successModal"
      :closable="false"
      :footer="null"
      dialogClass="modal-class"
    >
      <div class="modal-container">
        <a-icon type="check-circle" theme="filled" class="icon" />
        <span>创建活动成功，是否继续创建任务？</span>
      </div>
      <div class="modal-footer">
        <div class="btn btn1" @click="successModal = false">取消</div>
        <div class="btn btn2" @click="openCreateTask">确认</div>
      </div>
    </a-modal>
    <FormDrawer
      ref="FormDrawer"
      :groupList="groupList"
      :seriesList="seriesList"
      :topicList="topicList"
      @callback="createActivityCallback"
    />
    <CreateTaskDrawer
      :activity="currAct"
      ref="CreateTaskDrawer"
      @getList="getActivityList(true)"
    />
    <WinnerDrawer ref="WinnerDrawer" @getList="fetchActivityData" />
    <WinnerPreviewModal ref="WinnerPreviewModal" />
    <ReadDrawer ref="ReadDrawer" />
    <TemplateDrawer ref="TemplateDrawer" />
    <HistoryDrawer ref="HistoryDrawer" />
    <MemberListModal ref="MemberListModal" />
    <TemplatePreviewListModal
      ref="TemplatePreviewListModal"
      @preview="(data) => $refs['VideoPreviewModal'].getAuthUrl(data)"
    />
    <ImgPreviewModal ref="ImgPreviewModal" />
    <VideoPreviewModal ref="VideoPreviewModal" />
  </div>
</template>

<script>
import ScrollView from "@/components/ScrollView";
import BaseImage from "@/components/BaseImage";
import defaultCoverImg from "@/assets/default/default3-4.png";
import ac_api from "@/api/marketingActivityList";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ActivityDetail from "./components/ActivityDetail";
import _ from "lodash";
import Empty from "@/components/Empty/Empty";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import FormDrawer from "../components/NewFormDrawer";
import CreateTaskDrawer from "../components/CreateTaskDrawer";
import WinnerDrawer from "../components/WinnerDrawer";
import ReadDrawer from "../components/ReadDrawer";
import TemplateDrawer from "../components/TemplateDrawer";
import ActItem from "@/components/ActItem";
import { getPrincipalId } from "@/utils/auth";
import HistoryDrawer from "../components/HistoryDrawer";
import MemberListModal from "../components/MemberListModal.vue";
import TemplatePreviewListModal from "../components/TemplatePreviewListModal.vue";
import ImgPreviewModal from "../components/ImgPreviewModal.vue";
import VideoPreviewModal from "../components/VideoPreviewModal.vue";
import WinnerPreviewModal from "../components/WinnerPreviewModal.vue";
import { tabsList } from "./constant";

export default {
  components: {
    ScrollView,
    BaseImage,
    PlatformHeader,
    ActivityDetail,
    Empty,
    ButtonGroup,
    FormDrawer,
    CreateTaskDrawer,
    WinnerDrawer,
    ReadDrawer,
    TemplateDrawer,
    ActItem,
    HistoryDrawer,
    MemberListModal,
    TemplatePreviewListModal,
    ImgPreviewModal,
    VideoPreviewModal,
    WinnerPreviewModal,
  },
  data() {
    return {
      defaultCoverImg,
      tabsList,
      currentTabsKey: "0",
      currentTabsValue: "全部",
      activityName: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      tableData: [],
      loading: false,
      current: 1,
      pageSize: 10,
      total: 0,
      currentItem: {},
      selectValue: "",
      seriesList: [],
      groupList: {
        2: [],
        3: [],
      },
      topicList: [],
      currentTopicId: "",
      currentTopicItem: {},
      tableLoading: false,
      successModal: false,
      currentActivityId: "",
      isAllRegion: false,
      leftLoading: false,
      loadMoreStatus: "more",
      currActId: 1,
      currAct: {},
      rightLoading: false,
      // 是否埋点
      trackAction: "状态筛选",
    };
  },
  created() {
    this.getAreaInfo();
    this.getTopicList();
    this.getActivityList(true);
    this.getSeriesList();
    this.getAllGroup(2);
    this.getAllGroup(3);
  },
  methods: {
    // 默认选择活动埋点
    track() {
      const trackData = {
        action: this.trackAction,
        default: 1,
        activityId: this.currAct.id,
        activityName: this.currAct.activityName,
        tabsName: this.currentTabsValue,
      };
      this.trackAction = "";
      this.$trackRequest(this.$route, this.$trackEvent.CLICK, trackData);
    },
    // tabs切换
    changeTabs(val) {
      this.currentTabsKey = val;
    },
    changeTabsName(val) {
      this.currentTabsValue = val;
      this.trackAction = "状态筛选";
      this.getActivityList(true);
    },
    // 处理滚动到底部
    handleScrollToLower() {
      if (
        this.loadMoreStatus === "loading" ||
        this.loadMoreStatus === "noMore"
      ) {
        this.leftLoading = false;
        return false;
      }

      this.current++;
      this.getActivityList();
    },
    // 获取活动列表
    async getActivityList(isFirst) {
      if (isFirst) {
        this.loadMoreStatus = "more";
        this.current = 1;
      }

      this.leftLoading = true;
      this.loadMoreStatus = "loading";

      try {
        let params = {
          activityName: this.activityName,
          activityStatus: this.currentTabsKey,
          isTaskRate: false,
          page: this.current,
          size: this.pageSize,
        };

        const res = await ac_api.getActivityList(params);
        const { code, data, message } = res.data;

        if (code === 200) {
          if (isFirst) {
            this.tableData = [];
            await this.$nextTick();
          }

          if ((data?.list || []).length < this.pageSize) {
            this.loadMoreStatus = "noMore";
          } else {
            this.loadMoreStatus = "more";
          }

          this.tableData = this.tableData.concat(data?.list || []);

          if (isFirst) {
            if (this.tableData.length > 0) {
              this.currActId = this.tableData[0].id;
              this.currAct = this.tableData[0];
              // 首次进入,埋点操作
              if (this.trackAction) {
                this.track();
              }

              this.fetchActivityData();

              // 首页加载自动获取第二页,防止高度不够不能出现滚动条
              this.handleScrollToLower();
            } else {
              this.leftLoading = false;
              this.currActId = "";
              this.currAct = {};
              this.fetchActivityData();
            }
          } else {
            this.leftLoading = false;
          }
        } else {
          this.leftLoading = false;
          this.$message.error(message);
          this.loadMoreStatus = "more";
          this.tableData = [];
          this.currActId = "";
          this.currAct = {};
          this.fetchActivityData();
        }
      } catch (e) {
        console.error(e);
        this.leftLoading = false;
        this.loadMoreStatus = "more";
        this.tableData = [];
        this.currActId = "";
        this.currAct = {};
        this.fetchActivityData();
      }
    },
    handleActItemClick(item) {
      this.currActId = item.id;
      this.currAct = item;
      this.fetchActivityData();
    },
    // 获取活动数据
    async fetchActivityData() {
      this.$refs["ActivityDetail"].initData(this.currAct);
    },
    // 获取大区
    getAreaInfo() {
      ac_api.getAreaInfo().then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.type === 10) {
            this.isAllRegion = true;
          } else {
            this.isAllRegion = false;
          }
        }
      });
    },
    // 获取抖音话题列表
    getTopicList() {
      ac_api.getTopicList().then((res) => {
        if (res.data.code === 0) {
          this.topicList = res.data.data || [];
        } else {
          console.error("获取抖音话题列表失败");
        }
      });
    },
    // 获取车系列表
    getSeriesList() {
      ac_api.getSeriesList().then((res) => {
        if (res.data.code === 0) {
          this.seriesList = res.data.data || [];
        } else {
          this.seriesList = [];
          console.error("获取车系列表失败");
        }
      });
    },
    // 获取分组
    getAllGroup(type) {
      let params = {
        principalId: getPrincipalId(),
        page_num: 1,
        page_size: 100,
        type: type,
        state: 1,
        // applicableService: 'event_contest'
      };
      ac_api.getAllGroup(params).then((res) => {
        if (res.data.code === 200) {
          this.groupList[type] = res.data.data.list || [];
        } else {
          this.groupList[type] = [];
          console.error("获取分组失败");
        }
      });
    },
    // 获取详情数据
    getTopicDetail(item) {
      this.currentTopicId = item.id;
      this.currentTopicItem = item;
    },

    // 创建活动
    createActivity() {
      if (!this.isAllRegion) {
        this.$message.warning("当前账号不可创建活动,请联系管理员");
        return false;
      }
      this.currentItem = {};
      this.$refs.FormDrawer.showDrawer("add", this.currentItem);
    },
    /**
     * 查看变更历史
     * @param activityId 活动id
     */
    openHistoryDrawer(activityId) {
      this.$refs.HistoryDrawer.openDrawer(activityId);
    },
    // 编辑活动
    editActivity(item) {
      console.log(item);
      this.currentItem = item;
      this.$refs.FormDrawer.showDrawer("edit", this.currentItem);
    },
    // 上传获奖图片
    uploadWinnerPic(item) {
      this.currentItem = item;
      this.$refs.WinnerDrawer.showDrawer(this.currentItem);
    },
    // 查看配置
    openReadDrawer(item) {
      this.currentItem = item;
      this.$refs.ReadDrawer.showDrawer(this.currentItem);
    },
    // 确认导拍模版
    openTemplateDrawer(item) {
      this.currentItem = item;
      this.$refs.TemplateDrawer.showDrawer(this.currentItem);
    },
    // 创建活动成功回调
    createActivityCallback(id) {
      if (id) {
        this.successModal = true;
        this.currentActivityId = id;
      }
      this.getActivityList(true);
    },

    // 打开创建任务弹窗
    openCreateTask() {
      this.successModal = false;
      this.$refs.CreateTaskDrawer.showDrawer(this.currentActivityId);
    },
    // 跳转页面
    jumpToPage(item, noteData) {
      const {
        id,
        activityName,
        startTime,
        endTime,
        activityStatus,
        videoCreateSource,
        isLive,
        isVideo,
        isNote,
        isTask,
        platforms,
      } = this.currAct;
      let href = this.$router.resolve({
        path: item.path,
        query: {
          aId: id,
          aName: activityName,
          startTime,
          endTime,
          activityStatus,
          videoCreateSource,
          isLive,
          isVideo,
          isNote,
          isTask,
          platforms,
          isShowNoteContent: noteData?.isShowNoteContent,
          isShowNoteVideo: noteData?.isShowNoteVideo,
        },
      }).href;
      if (
        href.includes("/jjt-new/") &&
        process.env.NODE_ENV === "development"
      ) {
        const PLUS_HOST = process.env.VUE_APP_PLUS_HOST;
        href = href.replace(
          /^(https?:\/\/)?([^:^/]*)(:\d*)?(.*)$/,
          `$1${PLUS_HOST}$4`
        );
      }
      window.open(href);
    },
    formatStatus(val) {
      let text;

      if (val.activityStatus === 1) {
        const m1 = this.$moment(this.$moment().format("YYYY-MM-DD"));
        const m2 = this.$moment(
          this.$moment(val.startTime).format("YYYY-MM-DD")
        );
        const day = m2.diff(m1, "day");

        text = `距开始剩${day}天`;
      }

      if (val.activityStatus === 2) {
        const m1 = this.$moment(
          this.$moment(val.startTime).format("YYYY-MM-DD")
        );
        const m2 = this.$moment(this.$moment().format("YYYY-MM-DD"));
        const day = m2.diff(m1, "day");

        text = `已进行${day + 1}天`;
      }

      val.activityStatus === 3 && (text = "已结束");

      return text;
    },

    // 绑定活动删除
    onActivityDel(actItem) {
      this.$confirm({
        icon: () => <a-icon type="info-circle" theme="filled" />,
        title: "删除后将无法恢复，确认要删除这个活动吗？",
        width: 460,
        onOk: async () => {
          try {
            const params = {
              activityId: actItem.id,
            };
            const res = await ac_api.delActivity(params);
            const { code, data, message } = res.data;

            if (code === 200) {
              console.log(data);
              this.$message.success("删除活动成功");
              this.getActivityList(true);
            } else {
              this.$message.error(message);
            }
          } catch (e) {
            console.error(e);
          }
        },
      });
    },
    /**
     * 打开成员列表弹窗
     */
    openMemberListModal(currentItem) {
      this.$refs.MemberListModal.openModal(currentItem.id);
    },
    /**
     * 打开导拍模版列表弹窗
     */
    openTemplatePreviewListModal(currentItem) {
      this.$refs.TemplatePreviewListModal.openModal(currentItem.id);
    },
    showImage(url) {
      this.$refs.ImgPreviewModal.openModal(url);
    },
    handleOperate({ type, currentItem, data, noteData }) {
      switch (type) {
        case "openTemplateDrawer":
          this.openTemplateDrawer(currentItem);
          break;
        case "edit":
          this.editActivity(currentItem);
          break;
        case "delete":
          this.onActivityDel(currentItem);
          break;
        case "jumpToPage":
          let nowTime = new Date(
            Date.parse(
              this.$moment()
                .format("YYYY-MM-DD")
                .replace(/-/g, "/")
            )
          );
          let activityStartTime = new Date(
            Date.parse(
              this.$moment(currentItem.startTime)
                .subtract(-1, "days")
                .format("YYYY-MM-DD")
                .replace(/-/g, "/")
            )
          );
          console.log(nowTime < activityStartTime);
          if (data.name === "每日战报" && nowTime < activityStartTime) {
            this.$message.warning("每日战报数据需要T+1天更新");
          } else {
            this.jumpToPage(data, noteData);
          }
          break;
        case "openMemberListModal":
          this.openMemberListModal(currentItem);
          break;
        case "openTemplatePreviewListModal":
          this.openTemplatePreviewListModal(currentItem);
          break;
        case "showImage":
          this.showImage(data);
          break;
        case "openHistoryDrawer":
          this.openHistoryDrawer(data);
          break;
        case "uploadWinnerPic":
          this.uploadWinnerPic(currentItem);
          break;
        case "openWinnerPreviewModal":
          this.$refs["WinnerPreviewModal"].openModal("获奖名单", data);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  > div {
    display: flex;
    align-items: center;
  }
  .create-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 40px;
    border-radius: 8px;
    line-height: 40px;
    font-size: 14px;
    border: none;
    margin-left: 24px;
    @include background_color("background_color42");
    @include font_color("font_color20");
    cursor: pointer;
  }
}
.page-div {
  text-align: right;
  padding: 20px 20px 20px 0;
}
.create-modal-container {
  @include background_color("background_color2");
  width: 100%;
  min-height: 470px;
  padding-bottom: 40px;
  &-title {
    font-size: 16px;
    text-align: center;
    @include font_color("font_color20");
    margin-bottom: 30px;
  }
  &-tip {
    font-size: 12px;
    text-align: left;
    @include font_color("font_color23");
    margin: 10px 0 24px;
  }
  &-tools {
    width: 100%;
    padding: 0 24px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;
    position: absolute;
    left: 0;
    bottom: 30px;
  }
  .btn {
    width: 210px;
    height: 40px;
    @include background_color("background_color1");
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
  }
  .btn.btn1 {
    @include font_color("font_color23");
    margin-left: 6px;
  }
  .btn.btn2 {
    @include font_color("font_color24");
    cursor: not-allowed;
    margin-right: 6px;
  }
  .btn.btn2.active {
    @include background_color("background_color34");
    @include font_color("font_color25");
    cursor: pointer;
  }
}
// 弹窗

.modal-container {
  display: flex;
  align-items: center;
  height: 24px;
  .icon {
    font-size: 21px;
    color: #24bf79;

    margin-right: 17px;
  }
  > span {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 24px;
  }
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  .btn {
    height: 32px;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    border: none;
    display: inline-block;
    cursor: pointer;
  }
  .btn1 {
    width: 74px;
    background: #2b2f3b;
    color: rgba(255, 255, 255, 0.65);
  }
  .btn2 {
    width: 65px;
    background: #ffcc55;
    color: rgba(0, 0, 0, 0.88);
    margin-left: 8px;
  }
}

/deep/ .ant-input {
  height: 40px;
}
</style>
<style lang="scss" scoped>
/deep/ .modal-class {
  width: 360px;
  height: 136px;
  background: #1a1d26;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.32),
    0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 12px 48px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .ant-modal-body {
    width: 100%;
    height: 100%;
    padding: 32px 32px 24px 34px !important;
  }
  .ant-modal-content {
    width: 100%;
    height: 100%;
    background: #1a1d26 !important;
    box-shadow: none !important;
  }
}
</style>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 65px);

  .content2-wrap {
    flex: 1;
    overflow: hidden;

    /deep/ .ant-spin {
      &-container {
        height: 100%;
      }
    }

    .content2 {
      display: flex;
      padding: 0 20px;
      height: 100%;

      &__left {
        margin: 0 15px 0 0;
        width: 330px;
        height: 100%;
        overflow: hidden;
      }
    }
  }

  .left-spin {
    height: 100%;
  }

  .empty-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
</style>
