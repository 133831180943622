<template>
  <div class="container">
    <platform-header
      title="矩阵异动"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <a-month-picker
        v-model="searchTime"
        class="aft-month-picker default"
        placeholder="Select month"
        :disabled-date="disabledDate"
        @change="onChange"
        locale="zh-cn"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-month-picker>
    </div>
    <div class="spin-div">
      <a-spin :spinning="loading"></a-spin>
      <div class="list-wrap" v-if="matrixList && matrixList.length > 0">
        <div
          class="list-item"
          v-for="(item, index) in matrixList"
          :key="index"
          @click="routerTo(item)"
        >
          <div class="list-item-left" v-if="item && item.type">
            <div class="type-image">
              <img
                src="@/assets/matrix/matrix-type2.png"
                alt="记录突破"
                v-if="item.type == 1"
              />
              <img
                src="@/assets/matrix/matrix-type1.png"
                alt="数据波动"
                v-if="item.type == 2"
              />
              <img
                src="@/assets/matrix/matrix-type6.png"
                alt="发布异动"
                v-if="item.type == 3"
              />
              <img
                src="@/assets/matrix/matrix-type4.png"
                alt="账号等级上升"
                v-if="item.type == 4 && item.extra_json.lift == 1"
              />
              <img
                src="@/assets/matrix/matrix-type5.png"
                alt="账号等级下降"
                v-if="item.type == 4 && item.extra_json.lift == 0"
              />
              <img
                src="@/assets/matrix/matrix-type3.png"
                alt="排名变化"
                v-if="item.type == 5 || item.type == 6"
              />
            </div>
            <div class="title-and-name">
              <div class="title" v-if="item.type == 1">
                {{ item.title || "-" }}
              </div>
              <a-tooltip placement="topLeft" v-if="item.type == 2">
                <template slot="title">
                  <span>{{ item.title || "-" }}</span>
                </template>
                <div class="title">{{ item.title || "-" }}</div>
              </a-tooltip>
              <div class="title" v-if="item.type == 3">
                上周直播场次下降:
                <b style="font-size: 18px;">{{
                  item.extra_json.history - item.extra_json.newest
                }}</b>
                场
              </div>
              <div
                class="title"
                v-if="item.type == 4 && item.extra_json.lift == 1"
              >
                上月账号等级升级为{{ item.extra_json.newest }}
              </div>
              <div
                class="title"
                v-if="item.type == 4 && item.extra_json.lift == 0"
              >
                上月账号等级降级为{{ item.extra_json.newest }}
              </div>
              <div class="title" v-if="item.type == 5">
                月度营销价值进入行业前20
              </div>
              <div class="title" v-if="item.type == 6">
                月度营销价值进入行业前100
              </div>
              <div class="name">
                <WebpImage
                  :scale="'1-1'"
                  :src="item.avatar"
                  :width="'20px'"
                  :height="'20px'"
                  :radius="'10px'"
                />
                <span>{{ item.nickname || "-" }}</span>
              </div>
            </div>
          </div>
          <div class="list-item-right" v-if="item && item.type">
            <div class="extra type1" v-if="item.type == 1">
              <span>{{ item.extra_json.newest | changeNum }}</span
              ><span> / {{ item.extra_json.history | changeNum }}</span>
            </div>
            <div class="extra type2" v-if="item.type == 2">
              <span>数据波动</span>
            </div>
            <div class="extra type1" v-if="item.type == 3">
              <span>{{ item.extra_json.newest }}场</span
              ><span> / {{ item.extra_json.history }}场</span>
            </div>
            <div class="extra type1" v-if="item.type == 4">
              <span
                :class="
                  item.extra_json.newest == 'S'
                    ? 'fc-s'
                    : item.extra_json.newest == 'A'
                    ? 'fc-a'
                    : item.extra_json.newest == 'B'
                    ? 'fc-b'
                    : item.extra_json.newest == 'C'
                    ? 'fc-c'
                    : 'fc-d'
                "
                >{{ item.extra_json.newest }}</span
              ><span> / {{ item.extra_json.history }}</span>
            </div>
            <div class="extra type1" v-if="item.type == 5 || item.type == 6">
              <span>{{
                item.extra_json.newest ? `NO.${item.extra_json.newest}` : "-"
              }}</span
              ><span>
                /
                {{
                  item.extra_json.history
                    ? `NO.${item.extra_json.history}`
                    : "-"
                }}</span
              >
            </div>
            <div class="time">{{ item.ctime }}</div>
          </div>
        </div>
      </div>
      <div class="empty-div" v-else>
        <Empty title="暂无数据" v-if="!loading" />
      </div>
    </div>
    <div class="page-tools">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import WebpImage from "@/components/WebpImage/WebpImage";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Empty from "@/components/Empty/Empty";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "matrixChangeMonitoring"
);
export default {
  data() {
    return {
      currentTab: "",
      searchTime: moment().subtract(0, "months"),
      disabledDate(current) {
        return (
          current >=
          moment()
            .startOf("month")
            .subtract("month", -1)
        );
      },
      current: 1,
      pageSize: 20,
      total: 0,
      matrixList: [],
      loading: false,
    };
  },
  components: {
    WebpImage,
    PlatformHeader,
    Empty,
  },
  computed: {
    ...mapState({
      matrixData: (state) => state.matrixData,
    }),
  },
  watch: {
    matrixData(val) {
      console.log("matrixData", val);
      if (!val.type) {
        this.loading = false;
      }
      if (val) {
        this.matrixList = val.list || [];
        this.total = val.total || 0;
      } else {
        this.matrixList = [];
        this.total = 0;
      }
    },
  },
  created() {
    // this.loadList(true);
  },
  mounted() {},
  methods: {
    ...mapActions(["getNewMonitList"]),
    ...mapMutations(["changeState"]),
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      this.loadList(true);
    },
    loadList(isFirst) {
      if (isFirst) {
        this.current = 1;
      }
      this.loading = true;
      this.getNewMonitList({
        start_time:
          this.searchTime.startOf("month").format("YYYY-MM-DD") + " 00:00:00",
        end_time:
          this.searchTime.endOf("month").format("YYYY-MM-DD") + " 23:59:59",
        current: this.current,
        size: this.pageSize,
        platform: this.currentTab,
      });
    },
    // 日期切换
    onChange(date, dateString) {
      if (!date) {
        this.searchTime = moment().subtract(0, "months");
      }
      this.loadList(true);
    },
    // 分页
    pageChange() {
      console.log(this.current);
      this.loadList();
    },
    routerTo(item) {
      if (item.type == 1) {
        let routeUrl = this.$router.resolve({
          path: "/liveDetail",
          query: {
            id: item.biz_id,
            platform: this.currentTab,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else if (item.type == 2) {
        let routeUrl = this.$router.resolve({
          path: "/videoDetail",
          query: {
            id: item.biz_id,
            platform: this.currentTab,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else if (item.type == 3) {
        this.$router.push({
          path: "/dealer/regionalStores",
        });
      } else if (item.type == 4) {
        this.$router.push({
          path: "/dealer/regionalStores",
        });
      } else if (item.type == 5 || item.type == 6) {
        this.$router.push({
          path: "/brand/industryTop",
        });
      } else {
        this.$message.warning("禁止跳转");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  width: 100%;
  // min-width: 1226px;
  height: calc(100vh - 64px);
}
.tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
}
.list-wrap {
  padding: 0 20px;
  width: 100%;
  height: calc(100vh - 304px);
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  .list-item {
    width: calc(50% - 5px);
    min-width: 584px;
    height: 104px;
    padding: 24px 0;
    @include background_color("background_color50");
    border-radius: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-left {
      width: calc(100% - 180px);
      min-width: 398px;
      max-width: 598px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .type-image {
        width: 56px;
        height: 56px;
        @include background_color("background_color5");
        border-radius: 11px;
        overflow: hidden;
        margin: 0 18px 0 24px;
        > img {
          width: 56px;
          height: 56px;
        }
      }
      .title-and-name {
        width: calc(100% - 90px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        .title {
          font-size: 16px;
          @include font_color("font_color20");
          width: 100%;
          max-width: 500px;
          min-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .name {
          display: flex;
          align-items: center;
          font-size: 14px;
          @include font_color("font_color22");
          span {
            margin-left: 10px;
          }
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      width: 176px;
      padding-right: 24px;
      height: 100%;
      .extra.type1 {
        span:first-child {
          @include font_color("font_color20");
          font-size: 18px;
          font-weight: bold;
          line-height: 19px;

          &.fc-s {
            @include font_color("font_color27");
          }
          &.fc-a {
            @include font_color("font_color26");
          }
          &.fc-b {
            @include font_color("font_color29");
          }
          &.fc-c {
            @include font_color("font_color31");
          }
          &.fc-d {
            @include font_color("font_color39");
          }
        }
        span:last-child {
          @include font_color("font_color21");
          font-size: 14px;
        }
      }
      .extra.type2 {
        @include font_color("font_color26");
        font-size: 14px;
      }
      .time {
        font-size: 14px;
        @include font_color("font_color23");
      }
    }
  }
  .list-item:hover {
    @include background_color("background_color5");
    .list-item {
      &-left {
        .type-image {
          @include background_color("background_color62");
        }
        .title-and-name {
          .title {
            @include font_color("font_color26");
          }
        }
      }
    }
  }
  .list-item:nth-child(2n) {
    margin-left: 10px;
  }
}

.spin-div,
.empty-div {
  height: calc(100% - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tools {
  height: 86px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 1430px) {
  .list-item:nth-child(2n) {
    margin-left: 0px !important;
  }
}
</style>
