<template>
  <div class="container">
    <platform-header
      :title="'活动闭环管理'"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <div class="activity-button">
        <img src="@/assets/img/activity.png" alt="" />
        <span>{{ activityDetail ? activityDetail.activityName : "-" }}</span>
      </div>

      <div class="btn-wrap">
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button class="button2" @click="exportExcel">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>
    <div class="tools2">
      <div class="left">
        <div class="button" @click="goBack">
          <span class="icon"
            ><svg-icon icon-class="arrow-right" class="arrow-right"
          /></span>
          <span>返回</span>
        </div>

        <a-select
          v-model="region_id"
          style="width: 132px; height: 40px; margin-right: 10px"
          placeholder="选择大区"
          @change="selectChange('area')"
        >
          <a-select-option
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <!-- <date-tab
        :type="dateType"
        @changeType="changeType"
        @changeDate="searchTimeTable"
        ref="dateTab"
      ></date-tab> -->
      <span class="time-span" v-if="activityDetail"
        >活动时间： {{ activityDetail.startTime }} ~
        {{ activityDetail.endTime }}</span
      >
    </div>
    <div class="content">
      <div class="content-header">
        <span class="font1" style="margin: 0 10px 0 30px"
          >活动期内已获得：</span
        >
        <span class="font1">声量线索</span>
        <span class="font2" v-if="clueCount">{{
          clueCount.voice_volume_cue_count | changeNum
        }}</span>
        <span class="font1" style="margin-left: 26px">销售线索</span>
        <span class="font2" v-if="clueCount">{{
          clueCount.sales_cue_count | changeNum
        }}</span>
      </div>
      <div class="table">
        <a-table
          :columns="lTadsV == 1 ? all_columns : all_columns"
          :data-source="activityTableData"
          :rowKey="(data) => data.author_id"
          :pagination="false"
          :loading="activityTaleLoading"
          :scroll="{ y: 'calc(100vh - 486px)' }"
          :locale="locale"
          :customRow="openDetail"
          class="aft-table default"
        >
          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            <span :class="`ranking-span`">{{
              (current - 1) * pageSize + Number(index) + 1
            }}</span>
          </div>
          <div slot="account" slot-scope="text, record" class="table-account">
            <div class="table-account-left">
              <WebpImage
                :src="record.avatar"
                :width="'48px'"
                :height="'48px'"
                :radius="'50%'"
                class="img"
              />
              <!-- <img :src="record.avatar" width="48" height="48" alt="" /> -->
              <div>
                <div>
                  <span class="font-color2 f16">{{ record.dealer_name }}</span>
                </div>
                <div>
                  <span class="font-color3 f12">{{
                    record.dealer_company_name
                  }}</span>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div
            slot="dealer_store_code"
            slot-scope="text"
            class="font-color3 f14"
          >
            <span>{{ text || "-" }}</span>
          </div>
          <div slot="private_letter_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">私信</span>
            </div>
          </div>
          <div slot="aweme_cue_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">短视频线索</span>
            </div>
          </div>
          <div slot="live_cue_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">直播线索</span>
            </div>
          </div>
          <div slot="voice_volume_cue_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">声量线索</span>
            </div>
          </div>
          <div slot="sales_cue_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">销售线索</span>
            </div>
          </div>
        </a-table>
      </div>
    </div>

    <div class="page-div">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="activityTablePagination ? activityTablePagination.total : 0"
        @change="pageChange"
      />
    </div>
    <!-- 新建分组弹窗 -->
    <div class="add-modal" v-show="addModalShow" @click="addModalShow = false">
      <div class="add-modal-container" @click.stop>
        <a-input v-model.trim="addGroupValue" placeholder="输入新建分组名称" />
        <div class="action-div">
          <span
            :class="!!addGroupValue ? '' : 'active'"
            @click="addGroupDealer(0)"
            >取消</span
          >
          <span
            :class="!!addGroupValue ? 'active' : ''"
            @click="addGroupDealer(1)"
            >确定</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "closedLoop"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import DateTab from "@/components/DateTab/DateTab2";
import MultipleGroup from "@/components/MultipleGroup/MultipleGroup";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import WebpImage from "@/components/WebpImage/WebpImage";
import _http from "@/api/closedLoop";
import Empty from "@/components/Empty/Empty";
import chartPie from "./options/chartPie";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import exportTable from "@/utils/exportTable";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
export default {
  components: {
    PlatformHeader,
    DateTab,
    MultipleGroup,
    ButtonGroup,
    WebpImage,
    MyChart,
    Empty,
    ExcelTypeButton,
  },
  data() {
    return {
      activityId: "", // 活动id
      activityName: "",
      excelType: false,
      month: null,
      region_id: "",
      lTadsV: "1",
      tooltipTitle1: "抖音主播账号授权后，可查看对应视频播放数",
      tooltipTitle2: "产生私信、留资、电话拨打等意向互动行为的用户人数(去重)",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      currentTab: "",
      tab: "tab1",
      dateType: "month",
      dealer_id: "",
      all_columns: [
        {
          title: "排名",
          width: "6%",
          scopedSlots: { customRender: "num" },
          align: "center",
        },
        {
          dataIndex: "dealer_company_name",
          title: "门店名称",
          width: "19%",
          scopedSlots: { customRender: "account" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          width: "7%",
          scopedSlots: { customRender: "dealer_store_code" },
          align: "center",
        },
        {
          dataIndex: "private_letter_count",
          title: "私信",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "private_letter_count" },
        },
        {
          dataIndex: "aweme_cue_count",
          title: "短视频线索",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "aweme_cue_count" },
        },
        {
          dataIndex: "live_cue_count",
          title: "直播线索",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "live_cue_count" },
        },
        {
          dataIndex: "voice_volume_cue_count",
          title: "声量线索",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "voice_volume_cue_count" },
        },
        {
          dataIndex: "sales_cue_count",
          title: "销售线索",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "sales_cue_count" },
        },
      ],
      current: 1,
      pageSize: 20,
      addModalShow: false,
      addGroupValue: "",
      currentSelectType: "area", //下拉框互斥标示
    };
  },
  computed: {
    ...mapState({
      areaList: (state) => state.areaList, // 区域列表
      activityTableData: (state) => state.activityTableData,
      groupList: (state) => state.groupList,
      activityTaleLoading: (state) => state.activityTaleLoading,
      activityTablePagination: (state) => state.activityTablePagination,
      multipleData: (state) => state.multipleData,
      chartData: (state) => state.chartData,
      activityDetail: (state) => state.activityDetail,
      clueCount: (state) => state.clueCount,
    }),
  },
  watch: {
    areaList(val) {
      if (!val || val.length <= 0) {
        this.changeState({ data: [], name: "activityTableData" });
        return false;
      }
      this.region_id = val[0].id;
      // 查询列表 和线索数
      this.getTableData();
      this.getClueData();
    },
    activityDetail(val) {
      console.log("activityDetail", val);
      if (val.platform == 1) {
        this.tabData = [{ label: "抖音", value: "douyin" }];
        this.currentTab = "douyin";
      } else if (val.platform == 2) {
        this.tabData = [{ label: "快手", value: "kuaishou" }];
        this.currentTab = "kuaishou";
      } else if (val.platform == 3) {
        this.tabData = [{ label: "懂车帝", value: "dongchedi" }];
        this.currentTab = "dongchedi";
      }
    },
  },
  created() {
    this.$nextTick(() => {
      console.log(this.$route.query.id);
      if (this.$route.query.id) {
        this.activityId = this.$route.query.id;
        // 获取详情
        let params = {
          id: this.activityId,
        };
        let platform = "agg";
        this.getActivityDetail({ params, platform });
        // 查询大区列表
        this.getAreaList();
      } else {
        this.$message.error("未查询到当前id");
      }
    });
  },
  mounted() {},
  methods: {
    ...mapActions([
      "getActivityDetail",
      "getAreaList",
      "getActivityClosedLoopList",
      "getClosedLoopChart",
      "getClueCount",
    ]),
    ...mapMutations(["changeState"]),
    // 跳转门店
    gotoStore() {
      this.$router.push({
        path: "storeSearch",
      });
    },
    // 获取表格数据
    getTableData() {
      let platform = this.currentTab;
      let params = {
        activity_id: this.activityId,
        current: this.current,
        page_size: this.pageSize,
        region_id: this.region_id || "",
      };
      this.getActivityClosedLoopList({ params, platform });
    },
    getClueData() {
      let platform = this.currentTab;
      let params = {
        activity_id: this.activityId,
        region_id: this.region_id || "",
      };
      this.getClueCount({ params, platform });
    },
    // 时间范围数据处理
    disposeTimeRange(value) {
      let start_time = "";
      let end_time = "";
      if (!!value) {
        start_time = `${this.$moment(value)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(value)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        start_time = moment()
          .month(moment().month())
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        end_time = moment()
          .month(moment().month())
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        this.month = this.$moment()
          .month(moment().month())
          .startOf("month");
      }
      return { start_time, end_time };
    },
    // 直播/视频 tab切换
    buttonTabChange(val) {
      this.lTadsV = val;
      this.current = 1;
      this.getTableData();
    },
    tabChange(val) {
      this.currentTab = val;
      this.current = 1;
      this.getTableData();
    },
    // 下拉框分组切换
    selectChange(type) {
      console.log(type);
      if (type == "area") {
        this.currentSelectType = "area";
        this.group_code = undefined;
      } else {
        this.currentSelectType = "group";
        this.region_id = undefined;
      }
      this.current = 1;
      this.getTableData();
      this.getClueData();
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      this.searchTimeTable();
    },
    // 时间变更
    searchTimeTable() {
      this.getTableData();
    },
    // 页码切换
    pageChange() {
      this.getTableData();
    },
    multipleChange(index) {
      this.multipleData[index].favorite = this.multipleData[index].favorite
        ? 0
        : 1;
    },
    hideAllModal() {
      this.addModalShow = false;
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 导出Excel
    exportExcel() {
      let params = {
        activity_id: this.activityId,
        region_id: this.region_id || "",
        flag: this.excelType ? 1 : 0,
      };
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/closedLoop/list/export/new",
        "filename"
      );
    },

    goBack() {
      this.$router.push({
        path: `/activityManagement`,
      });
    },

    openDetail(record, index) {
      return {
        on: {
          click: () => {
            window.open(
              `/dealer/dealerDetail?id=${record.dealer_id}&platform=${this.currentTab}`
            );
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .tools {
    padding: 0 20px;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    .btn-wrap {
      display: flex;
      align-items: center;
    }
    .activity-button {
      padding: 0 14px 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: bold;
      @include font_color("font_color25");
      @include background_color("background_color15");
      height: 40px;
      border-radius: 8px;
      > img {
        margin-right: 10px;
        width: 28px;
        height: 28px;
      }
    }
    .button2 {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }
  .tools2 {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .button {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 24px;
        margin-right: 30px;
        font-size: 14px;
        @include font_color("font_color22");
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 2px;
          height: 24px;
          @include background_color("background_color62");
        }
        .icon {
          margin-right: 6px;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          display: block;
          overflow: hidden;
        }
        &:hover .icon {
          @include background_color("background_color2");
        }
        .arrow-right {
          font-size: 22px;
          transform: rotate(180deg);
        }
      }
    }
    .time-span {
      @include font_color("font_color22");
      line-height: 40px;
    }
  }
  .content {
    width: calc(100% - 48px);
    margin-left: 24px;
    border-radius: 16px;
    @include background_color("background_color50");
    &-header {
      width: 100%;
      height: 72px;
      border-radius: 16px 16px 0 0;
      @include background_color("background_color50");
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .font1 {
        font-size: 14px;
        @include font_color("font_color22");
      }
      .font2 {
        font-size: 14px;
        font-weight: bold;
        @include font_color("font_color26");
      }
    }
    .table {
      height: calc(100vh - 426px);
      overflow-y: auto;
      &-account {
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        &-left {
          display: flex;
          flex-direction: row;
          @include font_color("font_color3");
          > .img {
            border-radius: 50%;
            @include border_color("border_color6");
          }
          > div {
            margin-left: 8px;
            > div {
              width: 160px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }
      }
      .td-hover-number {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        > span {
          font-size: 14px;
          font-weight: bold;
          @include font_color("font_color21");
        }
        > .bottom-title {
          font-size: 12px;
          @include font_color("font_color23");
          margin-top: 4px;
        }
      }
    }
    .ranking-span {
      width: 28px;
      height: 20px;
      border-radius: 14px;
      display: inline-block;
      @include background_color("background_color51");
      @include font_color("font_color22");
      font-size: 12px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
      &.ranking-span1 {
        @include background_color("background_color34");
        @include font_color("font_color25");
      }
      &.ranking-span2 {
        @include background_color("background_color18");
        @include font_color("font_color25");
      }
      &.ranking-span3 {
        @include background_color("background_color57");
        @include font_color("font_color25");
      }
    }
  }
  .page-div {
    text-align: center;
    padding: 26px 0;
  }
  .store-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    z-index: 100;
    &-container {
      position: absolute;
      right: 24px;
      bottom: 115px;
      width: 272px;
      height: 412px;
      border-radius: 4px;
      @include background_color("background_color27");
      @include font_color("font_color2");
      font-size: 14px;
      &-title {
        font-size: 16px;
        padding: 24px 0 20px;
        text-align: center;
      }
      .list {
        padding: 0 32px;
        height: 280px;
        overflow-y: auto;
      }
      .action-div {
        padding: 16px 12px 16px 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        > div {
          cursor: pointer;
          height: 32px;
          line-height: 18px;
          @include font_color("font_color2");
          .icon {
            font-size: 30px;
            @include font_color("font_color4");
            position: relative;
            top: 3px;
          }
        }
        .btn {
          width: 60px;
          height: 32px;
          border-radius: 4px;
          @include background_color("background_color5");
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          cursor: pointer;
        }
        .btn1 {
          @include font_color("font_color3");
          margin: 0 8px 0 20px;
        }
        .btn2 {
          @include font_color("font_color1");
        }
      }
    }
  }
  .add-modal {
    position: absolute;
    right: 24px;
    bottom: 115px;
    width: 272px;
    height: 412px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0);
    z-index: 110;
    &-container {
      position: absolute;
      bottom: 0;
      left: 0;
      @include background_color("background_color29");
      width: 100%;
      height: 116px;
      padding: 24px;
      .action-div {
        display: flex;
        justify-content: space-around;
        > span {
          padding: 16px;
          margin-top: 8px;
          cursor: pointer;
          @include font_color("font_color3");
        }
        > span.active {
          @include font_color("font_color2");
        }
      }
    }
  }
  .chart-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    z-index: 100;
    &-container {
      position: absolute;
      border-radius: 16px;
      top: 308px;
      right: 20%;
      @include background_color("background_color51");
      width: 726px;
      height: 438px;
      padding: 24px;
      @include box_shadow("box_shadow4");
      .chart-title {
        text-align: center;
        font-size: 16px;
        @include font_color("font_color1");
      }
    }
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .font-color26 {
    @include font_color("font_color26");
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .f20 {
    font-size: 20px;
  }
}
.badge_box {
  display: flex;
  margin-left: 10px;
  li {
    margin-right: 8px;
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/_handle.scss";
.ant-badge-dot {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.tooltip_name {
  .ant-tooltip-inner {
    @include background_color("background_color28");
    font-size: 12px;
    @include font_color("font_color1");
  }
  .ant-tooltip-arrow::before {
    // 这里是小三角形
    @include background_color("background_color28");
  }
}
</style>
