<template>
  <div class="tabs-wrap">
    <div
      v-for="(item, index) in tabData"
      :key="'tabs' + index"
      :class="
        item.value === currentClick ? 'active-tab-class tab-class' : 'tab-class'
      "
      @click="tabClick(item)"
    >
      <span :style="labelStyle">{{ item.label }}</span>
      <span class="line"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabsKey: {
      type: String,
      default: "",
    },
    tabData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    labelStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentClick: this.tabData.length ? this.tabData[0].value : "",
    };
  },
  watch: {
    tabData() {
      this.currentClick =
        this.tabsKey || (this.tabData.length ? this.tabData[0].value : "");
      this.$emit(
        "tabNameChange",
        this.tabData.length ? this.tabData[0].label : ""
      );
    },
  },
  methods: {
    tabClick({ label, value }, noRequest = false) {
      if (this.loading) return;
      this.currentClick = value;
      this.$emit("tabChange", value, noRequest);
      this.$emit("tabNameChange", label);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tab-class.active-tab-class {
  @include font_color("font_color26");
  .line {
    @include background_color("background_color34");
  }
}
.tab-class {
  cursor: pointer;
  @include font_color("font_color51");
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
  .line {
    margin-top: 4px;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    @include font_color("font_color26");
  }
}
.tab-class:last-child {
  margin-right: 0;
}
.tabs-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
