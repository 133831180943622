<template>
  <div class="wrap">
    <div class="title-box">
      <svg-icon icon-class="champion2" style="font-size: 22px" />
      <span>卫冕主播</span>
    </div>
    <div class="champion-card">
      <div
        :class="
          currentHover == 1 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 1"
        @click="openProduction(championsData.live)"
        v-show="championsData.live && championsData.live.author_id"
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div
              class="img-box"
              @click.stop="toDetail(championsData.live, 'live')"
            >
              <BaseImage
                :src="championsData.live.cover || ''"
                :default-image="defaultCoverImg"
                fit="contain"
                width="47px"
                height="62px"
                radius="4px"
                style="background-color: #21232c;"
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" style="font-size: 18px" />
              </div>
            </div>
            <div>
              <div>
                <span class="f14 fc20">单场直播冠军</span>
                <span
                  class="f12 fc26"
                  v-if="
                    championsData &&
                    championsData.live &&
                    championsData.live.defending_champion_count
                  "
                  >已卫冕{{
                    championsData.live.defending_champion_count || "-"
                  }}周</span
                >
                <span class="f12 fc26" v-else>本周加冕</span>
              </div>
              <div>
                <svg-icon
                  icon-class="eye2"
                  style="font-size: 22px; margin-right: 6px"
                />
                <span class="f16 fw fc26">{{
                  championsData.live.watch_count | changeNum
                }}</span>
              </div>
            </div>
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>开播日期</span>
              <span>{{ championsData.live.publish_time || "-" }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.live.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          currentHover == 2 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 2"
        @click="openProduction(championsData.aweme)"
        v-show="championsData.aweme && championsData.aweme.author_id"
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div
              class="img-box"
              @click.stop="toDetail(championsData.aweme, 'aweme')"
            >
              <BaseImage
                :src="championsData.aweme.aweme_cover || ''"
                :default-image="defaultCoverImg"
                fit="contain"
                width="47px"
                height="62px"
                radius="4px"
                style="background-color: #21232c;"
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" style="font-size: 18px" />
              </div>
            </div>
            <div>
              <div>
                <span class="f16 fc20">视频播放冠军</span>
                <span
                  class="f12 fc26"
                  v-if="
                    championsData &&
                    championsData.aweme &&
                    championsData.aweme.defending_champion_count
                  "
                  >已卫冕{{
                    championsData.aweme.defending_champion_count || "-"
                  }}周</span
                >
                <span class="f12 fc26" v-else>本周加冕</span>
              </div>
              <div>
                <svg-icon
                  icon-class="eye2"
                  style="font-size: 22px; margin-right: 6px"
                />
                <span class="f16 fw fc26">{{
                  championsData.aweme.play_volume_count | changeNum
                }}</span>
                <!-- <AftTooltip :code="'pgsp01'" style="top: 0px; left: 4px" /> -->
              </div>
            </div>
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>发布日期</span>
              <span>{{ championsData.aweme.publish_time || "-" }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.aweme.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          currentHover == 3 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 3"
        @click="openProduction(championsData.market_value)"
        v-show="
          championsData.market_value && championsData.market_value.author_id
        "
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div class="img-box hidden">
              <BaseImage
                :src="championsData.market_value.avatar || ''"
                :default-image="defaultAvatarImg"
                fit="cover"
                width="47px"
                height="47px"
                radius="4px"
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" style="font-size: 18px" />
              </div>
            </div>
            <div>
              <div>
                <span class="f16 fc20">营销价值冠军</span>
                <span
                  class="f12 fc26"
                  v-if="
                    championsData &&
                    championsData.market_value &&
                    championsData.market_value.defending_champion_count
                  "
                  >已卫冕{{
                    championsData.market_value.defending_champion_count || "-"
                  }}天</span
                >
                <span class="f12 fc26" v-else>今日加冕</span>
              </div>
              <div>
                <span class="f16 fc27"
                  >¥{{
                    championsData.market_value.market_value | changeNum
                  }}</span
                >
                <AftTooltip :code="'pgqt03'" style="top: 0px; left: 4px" />
              </div>
            </div>
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>直播&视频</span>
              <span>{{ championsData.market_value.aweme_live_count }}</span>
            </div>
            <div class="info-item">
              <span>账号等级</span>
              <span>{{ championsData.market_value.level }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.market_value.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/bulletinBoard";
import { getPrincipalId } from "@/utils/auth";
import defaultAvatarImg from "@/assets/default/default1-1.png";
import defaultCoverImg from "@/assets/default/default3-4.png";
import BaseImage from "@/components/BaseImage";

export default {
  name: "RightWrap2",
  components: {
    BaseImage
  },
  data() {
    return {
      defaultAvatarImg,
      defaultCoverImg,
      currentHover: 1,
      championsData: {
        aweme: {},
        live: {},
        market_value: {},
      },
    };
  },
  computed: {},
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getChampions();
    },
    getChampions() {
      let body = {
        params: {
          principal_id: getPrincipalId(),
        },
        platform: this.$parent.currentTab,
      };
      api.getChampions(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getChampions", data);
          this.championsData = data;
        } else {
          console.error("获取榜单失败");
          this.championsData = {
            aweme: {},
            live: {},
            market_value: {},
          };
        }
      });
    },
    toDetail(data, type) {
      if (data && data.room_id && type == 'live') {
        let url = `/liveDetail?id=${data.room_id}&platform=${this.$parent.currentTab}`
        window.open(url)
      } else if (data && data.aweme_id && type == 'aweme') {
        let url = `/videoDetail?id=${data.aweme_id}&platform=${this.$parent.currentTab}`;
        window.open(url)
      } else {
        this.$message.error("暂无ID，无法查看详情");
      }
    },
    openProduction(item) {
      if (item && item.author_id) {
        this.$router.push({
          path: "/dealer/production",
          query: {
            type: this.$parent.currentTab,
            author_id: item.author_id,
          },
        });
      } else {
        this.$message.warning("未获取到authorId!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  .title-box {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    > span {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-left: 10px;
    }
  }
  .champion-card {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    &-item {
      width: 100%;
      height: 94px;
      background: #000000;
      transition: all 0.3s;
      overflow-y: hidden;
      cursor: pointer;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      &:first-child {
        border-top: none;
      }

      &-main {
        width: 100%;
        height: 100%;
        &-top {
          width: 100%;
          height: 94px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 16px 20px;
          > .img-box:hover {
            > .img-shadow {
              display: flex;
            }
          }
          > .img-box.hidden:hover {
            > .img-shadow {
              display: none;
            }
          }
          > .img-box {
            width: 47px;
            height: 62px;
            border-radius: 4px;
            margin-right: 20px;
            position: relative;
            > .img-shadow {
              width: 47px;
              height: 62px;
              @include background_color("background_color56");
              position: absolute;
              top: 0;
              left: 0;
              align-items: center;
              justify-content: center;
              display: none;
            }
          }
          > div {
            width: 186px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            > div {
              width: 186px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &:last-child {
                justify-content: flex-start;
              }
            }
          }
        }
        &-bottom {
          width: 100%;
          height: 100px;
          @include border_color("border_color13");
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .info-item {
            font-size: 12px;
            padding: 0 20px;
            margin-bottom: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span:first-child {
              @include font_color("font_color22");
            }
            span:last-child {
              margin-left: 10px;
              @include font_color("font_color20");
            }
          }
        }
      }
    }
    &-item.open {
      height: 170px;
    }
  }

  .fw {
    font-weight: bold;
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .f18 {
    font-size: 18px;
  }
  .fc20 {
    @include font_color("font_color20");
  }
  .fc21 {
    @include font_color("font_color21");
  }
  .fc22 {
    @include font_color("font_color22");
  }
  .fc26 {
    @include font_color("font_color26");
  }
  .fc27 {
    @include font_color("font_color27");
  }
  .bc1 {
    @include background_color("background_color1");
  }
  .bc5 {
    @include background_color("background_color5");
  }
}
</style>
