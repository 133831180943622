<template>
  <a-modal
    title="成员名单"
    :visible="visible"
    width="600px"
    @cancel="visible = false"
  >
    <a-table
      :pagination="false"
      :columns="columns"
      :dataSource="list"
      :loading="isLoading"
      rowKey="dealerId"
      :scroll="{ y: 300 }"
      :locale="{ emptyText: '暂无数据' }"
      style="min-height: 350px"
    >
      <div slot="regionName" slot-scope="row" class="td-box">
        {{ row.regionName }}
      </div>
      <div slot="dealerName" slot-scope="row" class="td-box">
        {{ row.dealerName }}
      </div>
      <template slot="authors" slot-scope="row">
        <div v-for="(item, index) in row.authors" :key="index" class="author">
          {{ item.authorName }}
        </div>
      </template>
    </a-table>
    <div slot="footer" class="modal-footer">
      <a-pagination
        v-if="form.total > 0"
        v-model="form.page"
        size="small"
        :pageSize="form.size"
        :total="form.total"
        @change="pageChange"
      />
    </div>
  </a-modal>
</template>

<script>
import api from "@/api/marketingActivityList";
export default {
  components: {},
  data() {
    return {
      visible: false,
      isLoading: false,

      columns: [
        {
          title: "区域",
          align: "left",
          width: "180px",
          scopedSlots: { customRender: "regionName" },
        },
        {
          title: "门店名称",
          align: "left",
          scopedSlots: { customRender: "dealerName" },
        },
        {
          title: "账号名称",
          align: "left",
          scopedSlots: { customRender: "authors" },
        },
      ],
      form: {
        activityId: "",
        page: 1,
        size: 10,
        total: 0,
      },
      list: [],
    };
  },
  methods: {
    /**
     * 打开成员列表弹窗
     * @param {string} activityId 活动id
     */
    openModal(activityId) {
      Object.assign(this.$data, this.$options.data());
      this.form.activityId = activityId;
      this.visible = true;
      this.getMatrixMemberList();
    },
    /**
     * 获取成员列表
     */
    getMatrixMemberList() {
      this.isLoading = true;
      api
        .getMatrixMemberList(this.form)
        .then((res) => {
          this.list = res.data.data.list;
          console.log("this.list:", this.list);
          this.form.total = res.data.data.total;
        })
        .finally(() => (this.isLoading = false));
    },
    /**
     * 页数改变
     * @param {number} page 修改的页数
     */
    pageChange(page) {
      this.form.page = page;
      this.getMatrixMemberList();
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep .ant-modal-header {
  padding: 24px;
  border-bottom: none;
}
::v-deep .ant-modal-close-x {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
::v-deep .ant-modal-body {
  padding: 0 24px;
}
::v-deep .ant-modal-footer {
  padding: 20px 24px;
  border-top: none;
}

// 表格
::v-deep .ant-table-header,
::v-deep
  .ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  background: #1a1d26;
}
::v-deep .ant-table-header {
  overflow-y: hidden !important;
}
::v-deep .ant-table-thead > tr > th {
  background: #242832;
  color: rgba(255, 255, 255, 0.88);
  font-size: 12px;
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
::v-deep .ant-table-tbody > tr > td {
  color: rgba(255, 255, 255, 0.88);
  border-bottom-color: rgba(255, 255, 255, 0.12);
  padding: 0;
  height: 1px;
}
::v-deep .ant-table-placeholder {
  background: #1a1d26;
  border-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.88);
}

// 分页
::v-deep .ant-pagination-item a,
::v-deep .ant-pagination-disabled a,
::v-deep .ant-pagination-disabled:hover a,
::v-deep .ant-pagination-disabled:focus a,
::v-deep .ant-pagination-disabled .ant-pagination-item-link,
::v-deep .ant-pagination-disabled:hover .ant-pagination-item-link,
::v-deep .ant-pagination-disabled:focus .ant-pagination-item-link,
::v-deep .ant-pagination-prev a,
::v-deep .ant-pagination-next a,
::v-deep
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #9aa5be;
}
::v-deep .ant-pagination-prev:focus .ant-pagination-item-link,
::v-deep .ant-pagination-next:focus .ant-pagination-item-link,
::v-deep .ant-pagination-prev:hover .ant-pagination-item-link,
::v-deep .ant-pagination-next:hover .ant-pagination-item-link,
::v-deep
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #ffca5c;
}
::v-deep .ant-pagination-item-active {
  border: 1px solid transparent;
  background: none;
  a {
    color: #ffca5c;
  }
}

.td-box {
  padding: 15px 6px;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
}

.author {
  height: 50px;
  padding-left: 6px;
  display: flex;
  align-items: center;
  & + .author {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
}
</style>
