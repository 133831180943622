<template>
  <div class="container">
    <platform-header
      title="考核结果"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <div class="left">
        <div class="button" @click="goBack">
          <span class="icon"
            ><svg-icon icon-class="arrow-right" class="arrow-right"
          /></span>
          <span>返回</span>
        </div>

        <div style="display: flex">
          <a-select
            class="aft-select default"
            v-model="activity_id"
            placeholder="选择活动"
            style="width: 196px; margin-right: 10px"
            :allowClear="true"
            @change="loadResultList(true)"
          >
            <a-select-option
              v-for="item in activityList"
              :value="item.id"
              :key="item.id"
              >{{ item.activity_name }}</a-select-option
            >
          </a-select>
          <a-select
            class="aft-select default"
            v-model="regionId"
            placeholder="选择大区"
            style="width: 196px; margin-right: 10px"
            @change="regionChange"
          >
            <a-select-option
              v-for="item in areaList.region_list || []"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-select
            class="aft-select default"
            v-model="groupValue"
            placeholder="选择分组"
            style="width: 196px"
            @change="groupChange"
          >
            <a-select-option value="">全部分组</a-select-option>
            <a-select-option v-for="item in groupData" :key="item.group_code">{{
              item.group_name
            }}</a-select-option>
          </a-select>
        </div>
      </div>
      <div>
        <span class="font-color22" style="margin-right: 10px"
          >考核截止时间:</span
        >
        <a-date-picker
          @change="onChange($event)"
          format="YYYY-MM-DD"
          v-model="deadline"
          class="aft-range-picker default"
          style="margin: 0 10px 0 0;"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-date-picker>
        <a-button class="button2" @click="exportClick">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>

    <div class="tools">
      <div></div>
    </div>
    <div class="content-table">
      <a-table
        :columns="columns1"
        :scroll="{ y: 'calc(100vh - 340px)' }"
        :data-source="(resultData && resultData.list) || []"
        :rowKey="(data) => data.dealer_id"
        :pagination="false"
        class="aft-table default ranking"
        :locale="locale"
        :loading="tableLoading"
        @change="tableChange"
      >
        <div slot="sort" slot-scope="text, records, index" class="table-sort">
          <span
            :class="
              records.sort == '1'
                ? 'ranking1'
                : records.sort == '2'
                ? 'ranking2'
                : records.sort == '3'
                ? 'ranking3'
                : 'ranking'
            "
            >{{ index + 1 + (current - 1) * pageSize }}</span
          >
        </div>
      </a-table>

      <div class="page-div">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import _ from "lodash";
import { getToken, getPrincipalId } from "@/utils/auth";
import exportTable from "@/utils/exportTable";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "assessment"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Tabs from "@/components/PlatformTab/Tabs";

import ButtonTab from "@/components/PlatformTab/NewButtonTab";
import Empty from "@/components/Empty/Empty";
import DateTab from "@/components/DateTab/DateTab2";
import Tooltip from "@/components/Tooltip/Tooltip";

export default {
  components: {
    PlatformHeader,
    Tabs,
    ButtonTab,
    DateTab,
    Tooltip,
    Empty,
  },
  data() {
    return {
      activitySortType: null, // null/'up'/'down'
      activitySortIndex: null, // null/'dealer'/Number(index)
      tooltipTitle: "输入本月活动关键字, 用逗号分隔,比如：新途观，新途昂",
      currentTab: "",
      activity_id: undefined,
      sort_field: "",
      sort: "",
      deadline: moment(moment().format("YYYY-MM-") + "10"),
      columns1: [
        {
          dataIndex: "sort",
          title: "排名",
          width: "80px",
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称",
          width: "200px",
          align: "left",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "dealer_area_names",
          title: "区域",
          align: "center",
          scopedSlots: { customRender: "dealer_area_names" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          align: "center",
          scopedSlots: { customRender: "dealer_store_code" },
          customRender: (text) => {
            return <div>{this.$options.filters.stringIsNull(text)}</div>;
          },
        },
        {
          dataIndex: "effective_play_volume",
          key: "effective_play_volume3",
          title: "视频播放量",
          align: "center",
          sorter: (a, b) => a.effective_play_volume - b.effective_play_volume,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "aweme_interaction_count",
          title: "视频互动量",
          align: "center",
          sorter: (a, b) =>
            a.aweme_interaction_count - b.aweme_interaction_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "event_activity_count",
          key: "event_activity_count1",
          title: "营销视频数量",
          align: "center",
          sorter: (a, b) => a.event_activity_count - b.event_activity_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "digg_count",
          title: "点赞",
          align: "center",
          sorter: (a, b) => a.digg_count - b.digg_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "comment_count",
          title: "评论",
          align: "center",
          sorter: (a, b) => a.comment_count - b.comment_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "share_count",
          title: "转发",
          align: "center",
          sorter: (a, b) => a.share_count - b.share_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
      ],
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      current: 1,
      pageSize: 20,
      total: 0,
      groupValue: "",
      regionId: undefined,
      searchTime: moment().subtract(1, "months"),
      disabledDate(itemDate) {
        // Can not select days before today and today
        if (itemDate) {
          let curMonth = moment().month();
          let itemMonth = itemDate.month();
          return itemMonth >= curMonth; // > 包括当月，>= 不包括当月
        } else {
          return false;
        }
      },

      isFirstInit: true,
    };
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activityList,
      areaList: (state) => state.areaList,
      groupData: (state) => state.groupData,
      tableLoading: (state) => state.tableLoading,
      resultData: (state) => state.resultData,
    }),
  },
  watch: {
    resultData(val) {
      console.log("watch", val);
      this.total = val.total;
    },
    areaList(val) {
      console.log("areaList", val);
    },
    activityList(val) {
      console.log("activityList", val);
      this.changeState({ data: { list: [] }, name: "resultData" });

      if (!val || val.length == 0) {
        this.$message.warning("暂无活动，请联系管理员");
        return false;
      }
      this.activity_id = val[0].id;
      this.loadResultList(true);
    },
  },
  async created() {},
  mounted() {},
  methods: {
    ...mapActions([
      "getAreaList",
      "getActivityData",
      "getActivityList",
      "getList",
      "getAllGroup",
      "getResultList",
    ]),
    ...mapMutations(["changeState"]),
    async initData() {
      Promise.all([
        this.getAreaList(),
        this.getAllGroup(),
        this.getActivityList({
          platform: this.currentTab,
        }),
      ]).then(() => {
        if (
          !this.areaList.region_list &&
          this.areaList.region_list.length == 0
        ) {
          this.$message.warning("当前账号未绑定大区，请联系管理员");
          return false;
        }
        if (!this.activityList || this.activityList.length == 0) {
          this.$message.warning("暂无活动，请联系管理员");
          return false;
        }
        this.activity_id = this.activityList[0].id;
        this.regionId = this.areaList.region_list[0].value;
        // this.loadResultList(true);
      });
    },
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().startOf("month");
    },
    // 大区选择
    regionChange() {
      this.loadResultList(true);
    },
    // 切换分组
    groupChange(val) {
      console.log(val);
      this.loadResultList(true);
    },
    // 平台切换
    async tabChange(val) {
      this.currentTab = val;
      this.activity_id = undefined;
      if (this.isFirstInit) {
        this.isFirstInit = false;
        await this.initData();
      } else {
        await this.getActivityList({
          platform: this.currentTab,
        });
      }

      // 考核
      this.currentButtonTab2 = 1;
      // this.loadResultList(true);
    },
    // 商务考核排序更改触发
    tableChange(pagination, filters, sorter) {
      console.log("sorter", sorter);
      console.log(sorter.field);
      console.log(sorter.order);
      if (sorter.order) {
        this.sort_field = sorter.field;
        this.sort = sorter.order == "ascend" ? "asc" : "desc";
      } else {
        this.sort_field = "";
        this.sort = "";
      }
      this.loadResultList();
    },
    sortFun(property, type) {
      return function(a, b) {
        var value1 = a[property];
        var value2 = b[property];
        if (type == "up") {
          return value2 - value1;
        } else {
          return value1 - value2;
        }
      };
    },
    sortFun2(index, type) {
      return function(a, b) {
        console.log(a, b);
        var value1 = a.list[index].count;
        var value2 = b.list[index].count;
        if (type == "up") {
          return value2 - value1;
        } else {
          return value1 - value2;
        }
      };
    },
    // 加载考核列表
    loadResultList(isFirst) {
      if (isFirst) {
        this.total = 0;
        this.current = 1;
        this.changeState({ data: { list: [] }, name: "resultData" });
      }

      let start_time = "";
      let end_time = "";

      let params = {
        start_time: start_time,
        end_time: end_time,
        region_id: this.regionId || "",
        group_code: this.groupValue,
        current: this.current,
        size: this.pageSize,
        platform: this.currentTab,
        deadline: moment(this.deadline).format("YYYY-MM-DD") + " 23:59:59",
      };

      // 事件活动激励-视频
      params.sort_field = this.sort_field;
      params.sort = this.sort;
      params.activity_id = this.activity_id || " ";
      this.getResultList(params);
    },
    // 考核-分页
    pageChange() {
      console.log(this.current);
      this.loadResultList();
    },
    // 切换日期
    onChange(time) {
      this.loadResultList(true);
    },

    goBack() {
      this.$router.push({
        path: `/dealer/assessment`,
      });
    },
    // 导出
    exportClick() {
      let start_time = "";
      let end_time = "";
      // 考核
      let params = {
        start_time: start_time,
        end_time: end_time,
        region_id: this.regionId || "",
        group_code: this.groupValue,
        deadline: moment(this.deadline).format("YYYY-MM-DD") + " 23:59:59",
      };
      // 事件活动激励-视频
      params.sort_field = this.sort_field;
      params.sort = this.sort;
      params.activity_id = this.activity_id || " ";
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/business/policy/assessment/event/activity/motivate/aweme/result/exportExcel",
        "filename"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;

  .tools {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    > div {
      display: flex;
      align-items: center;
      .button {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 24px;
        font-size: 14px;
        margin-right: 15px;
        @include font_color("font_color22");
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 2px;
          height: 24px;
          @include background_color("background_color62");
        }
        .icon {
          margin-right: 6px;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          display: block;
          overflow: hidden;
        }
        &:hover .icon {
          @include background_color("background_color2");
        }
        .arrow-right {
          font-size: 22px;
          transform: rotate(180deg);
        }
      }
      .search-btn {
        @include border_color("border_color4");
        @include font_color("font_color17");
        @include background_color("background_color13");
        width: 80px;
        height: 36px;
        border-radius: 8px;
      }

      .button2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 113px;
        height: 40px;
        border-radius: 8px;
        line-height: 40px;
        font-size: 14px;
        border: none;
        @include font_color("font_color1");
        @include background_color("background_color63");
        .btn-icon {
          font-size: 18px;
          margin-right: 7px;
          @include font_color("font_color34");
        }
        &:hover .btn-icon {
          @include font_color("font_color35");
        }
      }
    }
  }
  .content {
    padding: 0 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
    .first-list {
      width: 92px;
      min-width: 92px;
      margin-right: 13px;
    }
    .first-list-item {
      font-size: 14px;
      @include font_color("font_color2");
      height: 68px;
      line-height: 68px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
    }
    .other-list {
      width: 200px;
      margin-right: 13px;
    }
    .other-title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .activity-first-list {
      width: 92px;
      min-width: 92px;
    }
    .activity-first-list.dealer-list {
      width: 150px;
    }
    .activity-first-list-item {
      font-size: 14px;
      @include font_color("font_color2");
      height: 68px;
      line-height: 68px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
    }
    .list-bg {
      // cursor: pointer;
      // -webkit-transition: all 0.3s;
      // transition: all 0.3s;
      @include background_color("background_color2");
    }
    // .list-bg:hover,
    // .list-bg.active {
    //   @include background_color("background_color42");
    //   .list-item > span {
    //     @include font_color("font_color6");
    //     font-weight: bold;
    //   }
    // }
    .list-title {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      font-size: 14px;
      @include font_color("font_color1");
      height: 52px;
      line-height: 52px;
      @include border_color("border_color5");
    }
    .sort-div {
      cursor: pointer;
      width: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      .default-color {
        @include font_color("font_color21");
      }
      .active-color {
        @include font_color("font_color26");
      }
    }
    .list-item {
      width: 100%;
      min-width: 170px;
      padding: 0 36px;
      height: 68px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
      > span {
        line-height: 87px;
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .content-table {
    padding: 0 20px;
    .table-sort {
      height: 58px;
      line-height: 58px;
      .ranking {
        font-size: 14px;
        @include font_color("font_color21");
      }
      .ranking1 {
        font-size: 14px;
        font-weight: bold;
        @include font_color("font_color26");
      }
      .ranking2 {
        font-size: 14px;
        font-weight: bold;
        @include font_color("font_color29");
      }
      .ranking3 {
        font-size: 14px;
        font-weight: bold;
        @include font_color("font_color36");
      }
    }

    .page-div {
      text-align: right;
      padding: 10px 0;
    }
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .font-color22 {
    @include font_color("font_color22");
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
}
</style>
