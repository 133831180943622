<template>
  <div>
    <div class="title">直播详情</div>
    <a-spin :spinning="spinning">
      <div class="main-container">
        <div class="main-container-left">
          <LiveDetailCard v-if="liveDetail" :liveDetail="liveDetail" />
        </div>
        <div :class="['main-container-right', pieChartData && pieChartData.length > 0 ? '' : 'empty']">
          <div class="main-container-right-title">智能意向分析</div>
          <div class="chart-box" v-if="pieChartData && pieChartData.length > 0">
            <PieChart
              v-if="pieChartData && pieChartData.length > 0"
              ref="pieChart"
              :chartData="pieChartData"
              id="pieChart"
            />
          </div>
          <div class="right-bottom" v-show="pieChartData && pieChartData.length > 0">
            <MySelectThree
              v-if="selectData && selectData.length > 0"
              :modalData="selectData"
              :currentItem="currentItem"
              @changeItem="changeSelect"
            />
            <div class="right-bottom-main">
              <div class="comment-list-wrap">
                <a-spin tip="" :spinning="iaCommentsLoading">
                  <div class="comment-title">
                    <span class="title">高意向潜客</span>
                    <span
                      class="num"
                      v-if="iaCommentsList && iaCommentsList.pagination"
                      >（{{ iaCommentsList.pagination.total }}）</span
                    >
                  </div>
                  <div
                    class="list-wrap"
                    v-infinite-scroll="handleInfiniteOnLoad"
                    infinite-scroll-disabled="busy"
                    infinite-scroll-distance="100"
                  >
                    <div
                      class="wrap"
                      v-for="(item, index) in comments_total_list"
                      :key="index"
                    >
                      <div class="row">
                        <WebpImage
                          :src="item.avatar"
                          :width="'36px'"
                          :height="'36px'"
                          :radius="'50%'"
                        />
                        <div class="flex-col">
                          <span class="name">{{ item.nickname || '-' }}</span>
                          <span class="time">{{ item.publish_time || '-' }}</span>
                        </div>
                      </div>
                      <div class="txt">
                        {{ item.content || "-" }}
                      </div>
                    </div>
                    <div class="no-more" v-if="noMore">没有更多数据了</div>
                  </div>
                </a-spin>
              </div>
              <div class="bar-chart-box">
                <div class="title">意向</div>
                <div class="chart-box" v-if="pieChartData && pieChartData.length > 0">
                  <MyChart
                    :options="getBarOption(barChartData)"
                    id="barChart"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="right-empty" v-show="!pieChartData || pieChartData.length == 0">
            <Empty title="暂无数据" />
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import PieChart from "./components/PieChart";
import deepClone from "@/utils/clone-utils";
import infiniteScroll from "vue-infinite-scroll";
import WebpImage from "@/components/WebpImage/WebpImage";
import Tooltip from "@/components/Tooltip/Tooltip";
import LiveDetailCard from "./components/LiveDetailCard";
import MySelectThree from "@/components/MySelect/MySelectThree";
import chartPie_analysis from "./chartPie_analysis.js";
import chartBar from "./chartBar.js";
import Empty from "@/components/Empty/Empty";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers("globalDetail");

export default {
  name: "intentionAnalysis",
  directives: { infiniteScroll },
  components: {
    MyChart,
    PieChart,
    WebpImage,
    Tooltip,
    LiveDetailCard,
    MySelectThree,
    Empty
  },
  data() {
    return {
      busy: false,
      current: 1,
      page_size: 25,
      noMore: false,
      comments_total_list: [],
      platform: this.$route.query.platform,
      spinning: false,
      selectData: [],
      currentItem: {},
      pieChartData: [],
      barChartData: {
        xData: [],
        yData: [],
      },
    };
  },

  created() {
    this.changeState({ data: {}, name: "liveDetail" });
    this.changeState({ data: null, name: "iaCommentsList" });
    this.changeState({ data: false, name: "iaCommentsLoading" });
  },
  async mounted() {
    console.log("liveDetail", this.liveDetail);

    if (this.$route.query.id) {
      this.platform = this.$route.query.platform;
      let params = {
        room_id: this.$route.query.id,
        platform: this.$route.query.platform,
      };
      this.spinning = true;
      await this.getLiveDetail(params);
      this.spinning = false;
      this.selectData =
        this.liveDetail.statistics_comment_classify_analysis || [];
      if (this.liveDetail.statistics_comment_classify_analysis) {
        this.setBarData(
          this.liveDetail.statistics_comment_classify_analysis[0].detail[0]
            .detail
        );

        this.currentItem =
          this.liveDetail.statistics_comment_classify_analysis[0].detail[0].detail[0];
      }
      // 评论
      this.getTypeCommentsList();
      // pie chart 相关
      let newData = [];
      this.liveDetail.statistics_comment_classify_analysis.forEach(
        (item, index) => {
          item.detail.forEach((i, idx) => {
            let { count, name } = i;
            if (index == 0 && idx == 0) {
              newData.push({
                name,
                value: count,
                selected: true,
                label: { normal: { show: true } },
              });
            } else {
              newData.push({
                name,
                value: count,
              });
            }
          });
        }
      );
      this.pieChartData = newData;
    } else {
      console.log("没有获取到直播间信息");
    }
  },
  computed: {
    ...mapState({
      liveDetail: (state) => state.liveDetail,
      iaCommentsList: (state) => state.iaCommentsList,
      iaCommentsLoading: (state) => state.iaCommentsLoading,
    }),
  },
  methods: {
    ...mapActions(["getLiveDetail", "getIaCommentsList"]),
    ...mapMutations(["changeState"]),
    // 自定义按钮点击
    changeSelect(item, tabData) {
      console.log("item", item);
      console.log("this.currentItem", this.currentItem);
      if (tabData) {
        console.log(tabData);
        this.setBarData(tabData);
      }
      this.currentItem = item;
      this.current = 1;
      this.noMore = false;
      this.getTypeCommentsList();
    },
    setBarData(arr) {
      let data = {
        xData: arr.map((i) => {
          return i.name;
        }),
        yData: arr.map((i) => {
          return i.count;
        }),
      };
      this.barChartData = data;
    },

    //根据类型/关键词搜索评论列表
    getTypeCommentsList() {
      if (!this.currentItem || !this.currentItem.code) {
        return;
      }
      this.busy = true;
      if (this.noMore) {
        return false;
      }

      new Promise((resolve, reject) => {
        let body = {
          platform: this.$route.query.platform,
          params: {
            page_num: this.current,
            page_size: this.page_size,
            room_id: this.$route.query.id,
            classify_type: this.currentItem.code,
          },
        };
        resolve(this.getIaCommentsList(body));
      }).then((res) => {
        this.busy = false;

        if (this.current == 1) {
          this.comments_total_list = [];
        }
        if (
          this.iaCommentsList &&
          this.iaCommentsList.list &&
          this.iaCommentsList.list.length > 0
        ) {
          const arr = this.comments_total_list;
          this.comments_total_list = arr.concat(this.iaCommentsList.list);
        } else {
          this.comments_total_list = [];
        }
        if (
          this.iaCommentsList &&
          this.iaCommentsList.pagination &&
          this.current >= this.iaCommentsList.pagination.pages
        ) {
          this.noMore = true;
          return false;
        }
        this.current++;
      });
    },
    // 滚动加载评论
    handleInfiniteOnLoad() {
      if (
        this.iaCommentsList &&
        this.iaCommentsList.pagination &&
        this.current <= this.iaCommentsList.pagination.pages
      ) {
        this.getTypeCommentsList();
      } else {
        this.busy = true;
      }
    },
    getPieOption(data) {
      let option = deepClone(chartPie_analysis);
      option.series[0].data = data;
      option.series[1].data = data;
      // this.setChartMethord(option);
      return option;
    },
    getBarOption(data) {
      let colorArr = [
        "#5CE6F2",
        "#6AF095",
        "#D4BE54",
        "#D45A54",
        "#A46AFA",
        "#FAAC6A",
      ];
      let option = null;
      if (!option) {
        option = deepClone(chartBar);
      }
      option.legend.data = data.xData;
      option.series = [];
      for (let i = 0; i < data.xData.length; i++) {
        option.series.push({
          name: data.xData[i],
          type: "bar",
          barWidth: "16",
          itemStyle: {
            normal: {
              show: true,
              color: colorArr[i],
              barBorderRadius: [0, 8, 8, 0],
            },
          },
          zlevel: 2,
          barGap: "200%",
          label: {
            show: true,
            fontSize: 12,
            color: "#fff",
            position: "right",
            padding: [0, 0, 0, 10],
          },
          data: [data.yData[i]],
        });
      }
      return option;
    },
    setChartMethord(options) {
      if (!this.$refs.pieChart || !this.$refs.pieChart.chart) {
        return {};
      }
      if (!options.series[0].data || options.series[0].data.length == 0) {
        return {};
      }
      let option = deepClone(options);
      let myChart = this.$refs.pieChart.chart;
    },
  },
  beforeDestroy() {
    if (this.flvPlayer) {
      this.flvPlayer.destroy();
    }
    this.changeState({ data: null, name: "liveDetail" });
    this.changeState({ name: "current", data: 1 });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.title {
  font-weight: bold;
  font-size: 18px;
  padding: 28px 20px 35px;
  @include font_color("font_color20");
}
.main-container {
  width: 100%;
  @include font_color("font_color2");
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: row;
  &-left {
    width: 308px;
    height: calc(100vh - 174px);
    min-height: 855px;
    margin-right: 20px;
  }
  &-right {
    width: calc(100% - 328px);
    height: calc(100vh - 174px);
    min-height: 855px;
    @include background_color("background_color80");
    background: url("../../assets/live_images/intention_background.png")
      no-repeat center 0;
    background-size: contain;
    border-radius: 16px;
    &.empty {
      background-image: none;
      @include background_color("background_color80");
    }
    &-title {
      font-size: 18px;
      font-weight: bold;
      @include font_color("font_color20");
      line-height: 25px;
      padding: 15px 20px;
    }

    > .chart-box {
      width: 100%;
      height: 300px;
    }
    .right-bottom {
      margin-top: 10px;
      width: 100%;
      height: 445px;
      padding: 0 20px;
      &-main {
        margin-top: 25px;
        height: 380px;
        display: flex;
        flex-wrap: nowrap;
        > div {
          width: 50%;
          height: 100%;
        }

        .comment-list-wrap {
          position: relative;
          z-index: 1;
          margin: 0 8px;
          .comment-title {
            margin-bottom: 16px;
            .title {
              font-size: 18px;
              color: #fff;
              font-weight: bold;
              padding: 0;
            }
            .num {
              font-size: 12px;
              color: #8890a6;
            }
          }
          .list-wrap {
            // max-height: 370px;
            height: 350px;
            overflow-y: auto;
            // overflow:vertical
          }
          .wrap + .wrap {
            margin-top: 16px;
          }
          .row {
            display: flex;
            align-items: center;
          }
          .avatar {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            overflow: hidden;
          }
          .flex-col {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
          }
          .name {
            color: #d0d6e6;
            font-weight: bold;
            font-size: 14px;
          }
          .time {
            color: #8890a6;
            font-size: 12px;
          }
          .txt {
            font-size: 14px;
            color: #ffffff;
            line-height: 20px;
            margin: 10px 0 20px;
            padding: 0 44px;
          }
          .no-more {
            text-align: center;
            margin: 10px 0;
          }
        }
        > .bar-chart-box {
          .title {
            height: 25px;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 25px;
            padding: 0;
          }
          .chart-box {
            height: 370px;
          }
        }
      }
    }
    .right-empty {
      width: 100%;
      height: calc(100% - 60px);
      padding-top: calc(50% - 100px);
    }
  }
}
</style>
