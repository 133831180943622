<template>
  <a-drawer
    placement="right"
    width="560"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>{{ type == "add" ? "新建" : "编辑" }}活动</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <div class="drawer-tips">
          <a-icon type="exclamation-circle" theme="filled" class="icon" />
          <span>提交后将同步修改关联活动配置</span>
        </div>
        <a-form-model
          :model="form"
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          ref="ruleForm"
          :rules="rules"
        >
          <a-form-model-item
            label="活动名称"
            prop="name"
            style="margin-top: 24px"
          >
            <a-input
              v-model="form.name"
              class="aft-input drawer"
              placeholder="请输入活动名称"
              style="width: 512px"
              :maxLength="40"
            />
          </a-form-model-item>

          <a-form-model-item label="时间范围" prop="timeArr">
            <a-range-picker
              v-model="form.timeArr"
              class="aft-range-picker drawer"
              style="width: 346px"
            >
            </a-range-picker>
          </a-form-model-item>

          <!-- <a-form-model-item label="风火轮可见" prop="fhl">
            <a-switch
              class="aft-switch drawer"
              :disabled="type == 'edit'"
              v-model="form.fhl"
            />
          </a-form-model-item> -->
          <a-form-model-item label="统计范围" prop="checkData">
            <a-checkbox-group
              v-model="form.checkData"
              class="aft-checkbox drawer"
            >
              <a-checkbox value="video"> 视频 </a-checkbox>
              <a-checkbox value="live"> 直播 </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
          <a-form-model-item
            label="视频关键字"
            prop="video_value"
            v-show="form.checkData.indexOf('video') >= 0"
          >
            <a-input
              v-model="form.video_value"
              :maxLength="60"
              class="aft-input drawer"
              placeholder="最多三个关键字，用逗号分隔"
              style="width: 512px"
            />
          </a-form-model-item>
          <a-form-model-item
            label="直播关键字"
            prop="live_value"
            v-show="form.checkData.indexOf('live') >= 0"
          >
            <a-input
              v-model="form.live_value"
              class="aft-input drawer"
              :maxLength="60"
              placeholder="最多三个关键字，用逗号分隔"
              style="width: 512px"
            />
          </a-form-model-item>
          <a-form-model-item
            label="活动头图"
            prop="fileList1"
            style="position: relative"
            v-if="form.fhl"
          >
            <a-upload
              :custom-request="customRequest"
              accept="image/png, image/jpg, image/jpeg"
              list-type="picture-card"
              :file-list="form.fileList1"
              @preview="handlePreview"
              :before-upload="beforeUploadImg1"
              @change="handleChange1"
              class="aft-upload drawer"
            >
              <div v-if="form.fileList1.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传照片</div>
              </div>
            </a-upload>
            <span class="upload-label"
              >支持jpg/png/jpeg 格式，建议比例16:9</span
            >
          </a-form-model-item>
          <a-form-model-item
            label="活动简介图"
            prop="fileList2"
            style="position: relative"
            v-if="form.fhl"
          >
            <a-upload
              :custom-request="customRequest"
              accept="image/png, image/jpg, image/jpeg"
              list-type="picture-card"
              :file-list="form.fileList2"
              :before-upload="beforeUploadImg2"
              @preview="handlePreview"
              @change="handleChange2"
              class="aft-upload drawer"
            >
              <div v-if="form.fileList2.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传照片</div>
              </div>
            </a-upload>
            <span class="upload-label"
              >支持jpg/png/jpeg 格式，建议宽度大于1242</span
            >
          </a-form-model-item>

          <a-form-model-item label="同步创建平台" prop="platform">
            <a-checkbox-group
              v-model="form.platform"
              class="aft-checkbox drawer"
            >
              <a-checkbox value="1" disabled> 抖音 </a-checkbox>
              <a-checkbox value="2" disabled> 快手 </a-checkbox>
              <a-checkbox value="3" disabled> 懂车帝 </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-tools">
        <span class="btn btn1" @click="onClose">取消</span>
        <a-button
          class="btn btn2"
          @click="drawerSubmit"
          :loading="isAddGroupLoading"
          >确定</a-button
        >
      </div>
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-drawer>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import ossUploadMixin from "@/mixins/ossUploadMixin";
import api from "@/api/activityManagement";
import deepClone from "@/utils/clone-utils";
const defaultForm = {
  name: "",
  timeArr: [],
  video_value: "",
  live_value: "",
  checkData: [],
  fhl: false,
  fileList1: [],
  fileList2: [],
  platform: ["1", "2", "3"],
};
export default {
  mixins: [ossUploadMixin],
  components: {},
  props: {},
  data() {
    return {
      visible: false,
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      form: deepClone(defaultForm),
      rules: {
        name: {
          required: true,
          message: "请输入活动名称",
          trigger: "blur",
        },
        timeArr: {
          required: true,
          message: "请选择时间范围",
          trigger: "blur",
        },
        checkData: [
          {
            required: true,
            message: "请至少选择一种活动类型",
            trigger: "change",
          },
        ],
        fileList1: {
          required: true,
          message: "请上传活动头图",
          trigger: "change",
        },
        fileList2: {
          required: true,
          message: "请上传活动简介图",
          trigger: "change",
        },
      },
      previewVisible: false,
      previewImage: "",
      isAddGroupLoading: false,
      type: "add",
      currentItem: {},
      upload_file_params: {
        business_code: "jjt", //所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
        need_compress: 2, //是否需要压缩处理-针对图片(1:是  2:否  默认是)
        type: 1, //文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
      },
    };
  },
  created() {},
  methods: {
    showDrawer(type, currentItem) {
      this.type = type;
      this.currentItem = currentItem;
      if (this.type == "add") {
        this.form = deepClone(defaultForm);
      } else {
        console.log("this.currentItem", this.currentItem);
        let checkData = [];
        let item = this.currentItem;
        if (item.is_video) {
          checkData.push("video");
        }
        if (item.is_live) {
          checkData.push("live");
        }
        this.form = {
          id: item.id,
          name: item.activity_name,
          timeArr: [moment(item.start_time), moment(item.end_time)],
          video_value: item.other_video_key_word,
          live_value: item.other_live_key_word,
          checkData: checkData,
          fhl: item.type == 1 ? true : false,
          fileList1: [],
          fileList2: [],
          platform: item.platform_type ? item.platform_type.split(",") : [String(item.platform)],
        };
        if (this.form.fhl) {
          this.form.fileList1 = [
            {
              uid: "-1",
              name: "1.png",
              status: "done",
              url: item.cover,
              response: { url: item.cover },
            },
          ];
          this.form.fileList2 = [
            {
              uid: "-1",
              name: "2.png",
              status: "done",
              url: item.photo_intro,
              response: { url: item.photo_intro },
            },
          ];
        }
      }
      this.visible = true;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange1({ fileList }) {
      console.log("handleChange", fileList);
      this.form.fileList1 = fileList;
    },
    handleChange2({ fileList }) {
      console.log("handleChange", fileList);
      this.form.fileList2 = fileList;
    },

    async customRequest({ file, onSuccess, onProgress }) {
      const formData = new FormData();
      formData.append("business_code", this.upload_file_params.business_code);
      formData.append("need_compress", this.upload_file_params.need_compress);
      formData.append("type", this.upload_file_params.type);
      formData.append("file", file);

      await this.normalUploadFile(file, onSuccess, formData);
    },
    beforeUploadImg1(file) {
      const isJpgOrPng =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isJpgOrPng) {
        this.$message.error("上传的图片格式只能为jpg/png/jpeg!");
      }
      const isLt1M = file.size / 1024 / 1024 < 4;
      if (!isLt1M) {
        this.$message.error("图片格式不超过4M!");
      }
      if (!(isJpgOrPng && isLt1M)) {
        setTimeout(() => {
          this.form.fileList1 = [];
        }, 200);
      }

      return isJpgOrPng && isLt1M;
    },
    beforeUploadImg2(file) {
      const isJpgOrPng =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isJpgOrPng) {
        this.$message.error("上传的图片格式只能为jpg/png/jpeg!");
      }
      const isLt1M = file.size / 1024 / 1024 < 4;
      if (!isLt1M) {
        this.$message.error("图片格式不超过4M!");
      }
      if (!(isJpgOrPng && isLt1M)) {
        setTimeout(() => {
          this.form.fileList2 = [];
        }, 200);
      }

      return isJpgOrPng && isLt1M;
    },

    // 活动弹窗-取消
    onClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
      this.visible = false;
    },
    // 活动弹窗-确定
    async drawerSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        try {
          console.log(this.form);
          if (valid) {
            this.isAddGroupLoading = true;
            console.log(this.form);
            // 处理参数
            let body = {
              activity_name: this.form.name,
              start_time:
                moment(this.form.timeArr[0]).format("YYYY-MM-DD") + " 00:00:00",
              end_time:
                moment(this.form.timeArr[1]).format("YYYY-MM-DD") + " 23:59:59",
              is_video: this.form.checkData.indexOf("video") >= 0 ? 1 : 0,
              is_live: this.form.checkData.indexOf("live") >= 0 ? 1 : 0,
              platform_type: this.form.platform.join(","),
              type: this.form.fhl ? 1 : 0,
            };
            // 只有风火轮活动可见
            if (this.form.fhl) {
              body.cover = this.form.fileList1[0].response.url;
              body.photo_intro = this.form.fileList2[0].response.url;
            } else {
              body.cover = "";
              body.photo_intro = "";
            }
            // 勾选视频
            if (this.form.checkData.indexOf("video") >= 0) {
              body.other_video_key_word = this.form.video_value;
            } else {
              body.other_video_key_word = "";
            }
            // 勾选直播
            if (this.form.checkData.indexOf("live") >= 0) {
              body.other_live_key_word = this.form.live_value;
            } else {
              body.other_live_key_word = "";
            }

            console.log("this.currentItem", this.currentItem);
            // 修改时添加id
            if (this.type == "edit" && this.currentItem.id) {
              body.id = this.currentItem.id;
            }

            console.log(body);
            api
              .saveOrUpdateActivity(body)
              .then((res) => {
                console.log("saveOrUpdateActivity", res);
                if (res.data.code === 0) {
                  this.$message.success("提交成功");
                  this.onClose();
                  this.$emit("getList", true);
                } else if (res.data.code === 1) {
                  this.$message.error(res.data.msg || "提交失败");
                } else {
                  this.$message.error(res.message || "提交失败");
                }
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                this.isAddGroupLoading = false;
              });
          } else {
            this.$message.error("校验不通过,无法提交");
          }
        } catch (err) {
          console.error(err);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 116px);
    padding: 0 24px;
    overflow-y: auto;
    .upload-label {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.45);
      position: absolute;
      top: 0;
      left: 0;
    }
    .drawer-tips {
      width: 100%;
      height: 40px;
      background: #1e2b48;
      border-radius: 2px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      margin-top: 24px;
      .icon {
        color: #3c7eff;
        margin: 0 8px 0 17px;
      }
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 88px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.btn1 {
        background: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}
</style>