<template>
  <div class="store-statistics-container">
    <div class="table">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :scroll="{ x: '100%', y: '484px' }"
        :rowKey="(data, index) => data.dealer_id + index"
        :pagination="false"
        :locale="locale"
        :loading="tableLoading"
        class="aft-table default x-scroll"
      >
        <div
          slot="num"
          slot-scope="text, records, index"
          class="font-color2 f14"
        >
          {{ (current - 1) * pageSize + Number(index) + 1 }}
        </div>
        <div slot="customTitle">
          <div>门店/公司名</div>
        </div>
        <div slot="watchCountTitle" style="display: flex; align-items: center">
          <div>观看人数</div>
          <AftTooltip :code="'qjzb11'" style="top: 0px; left: 4px" />
        </div>
        <div slot="watchTimeTitle" style="display: flex; align-items: center">
          <div>观看人次</div>
          <AftTooltip :code="'qjzb11'" style="top: 0px; left: 4px" />
        </div>

        <div slot="tooltipTitle" style="display: flex; align-items: center">
          <div>视频播放</div>
          <AftTooltip :code="'mtsp01'" style="top: 0px; left: 4px" />
        </div>
        <span slot="customAction" @click="actionClick"
          ><a-tooltip>
            <template slot="title"> 列设置 </template>
            <a-icon type="setting" class="action-ico" theme="filled" />
          </a-tooltip>
        </span>
        <div slot="account" slot-scope="scope" class="table-account">
          <div class="table-account-left">
            <div>
              <div>
                <a-tooltip>
                  <template slot="title">{{
                    scope.dealer_name || "-"
                  }}</template>

                  <span class="font-color2 f16">
                    <img
                      src="@/assets/img/type-store.png"
                      width="20"
                      style="margin-right: 4px; margin-bottom: 2px"
                      alt=""
                    />
                    {{ scope.dealer_name || "-" }}</span
                  >
                </a-tooltip>
              </div>
              <div>
                <a-tooltip>
                  <template slot="title">{{
                    scope.company_name || "-"
                  }}</template>

                  <span class="font-color3 f12">
                    <img
                      src="@/assets/img/type-company.png"
                      width="16"
                      style="margin: 0 6px 2px 2px"
                      alt=""
                    />
                    {{ scope.company_name || "-" }}
                  </span>
                </a-tooltip>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div slot="storeCode" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.store_code || "-" }}</span>
        </div>
        <div slot="fans_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.fans_count | changeNum }}</span>
        </div>
        <div slot="fans_count_total" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.fans_count_total | changeNum }}</span>
        </div>
        <div slot="fans_tt_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.fans_tt_count | changeNum }}</span>
        </div>
        <div slot="fans_hs_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.fans_hs_count | changeNum }}</span>
        </div>
        <div slot="aweme_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.aweme_count | changeNum }}</span>
        </div>
        <div slot="aweme_like_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.aweme_like_count | changeNum }}</span>
        </div>
        <div
          slot="aweme_comment_count"
          slot-scope="scope"
          class="font-color3 f14"
        >
          <span>{{ scope.aweme_comment_count | changeNum }}</span>
        </div>
        <div
          slot="aweme_share_count"
          slot-scope="scope"
          class="font-color3 f14"
        >
          <span>{{ scope.aweme_share_count | changeNum }}</span>
        </div>
        <div slot="aweme_play_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.aweme_play_count | changeNum }}</span>
        </div>
        <div slot="aweme_activation" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.aweme_activation | changeNum }}</span>
        </div>
        <div slot="live_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_count | changeNum }}</span>
        </div>
        <div slot="live_time" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_time | disposeLongTime_h }}</span>
        </div>
        <div slot="live_watch_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_watch_count | changeNum }}</span>
        </div>
        <div slot="live_watch_time" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_watch_time | changeNum }}</span>
        </div>
        <div slot="live_like_count" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_like_count | changeNum }}</span>
        </div>
        <div
          slot="live_comment_count"
          slot-scope="scope"
          class="font-color3 f14"
        >
          <span>{{ scope.live_comment_count | changeNum }}</span>
        </div>
        <div slot="live_activation" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_activation | changeNum }}</span>
        </div>
        <div slot="live_fans_add" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_fans_add | changeNum }}</span>
        </div>
        <div
          slot="action"
          slot-scope="scope"
          class="font-color3 f14"
          @click="routerDetail(scope)"
        >
          <span v-if="scope.author_id">查看</span>
        </div>
      </a-table>
    </div>
    <div class="page-div aft-pagination">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>

    <!-- 指标弹窗 -->
    <div class="setting-modal" v-show="settingModalShow" @click="settingClose">
      <div class="setting-modal-container" @click.stop>
        <div class="setting-modal-container-title">
          选择展示指标
          <!-- <span>(最多6个)</span> -->
        </div>
        <div class="list">
          <a-checkbox-group
            v-model="currentCheck"
            @change="tableChange"
            style="width: 100%"
          >
            <a-row>
              <a-col
                :span="8"
                v-for="(item, index) in tableSetting"
                :key="index"
                :style="{
                  'margin-bottom': '16px',
                  display:
                    (item.label == '头条粉丝' || item.label == '火山粉丝') &&
                    platform != 'douyin'
                      ? 'none'
                      : 'inline-block',
                }"
              >
                <!-- <a-checkbox :value="item.value" :checked="false" :disabled="true" v-if="(item.label == '头条粉丝' || item.label == '火山粉丝') && platform != 'douyin'">{{ item.label }}</a-checkbox> -->
                <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
        <div class="action-div">
          <span class="btn btn1" @click="settingClose">取消</span>
          <span class="btn btn2" @click="settingSure">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const tableSetting = [
  {
    value: "fans_count/粉丝增量/0",
    label: "粉丝增量",
  },
  {
    value: "fans_count_total/粉丝总量/1",
    label: "粉丝总量",
  },
  {
    value: "fans_tt_count/头条粉丝/2",
    label: "头条粉丝",
  },
  {
    value: "fans_hs_count/火山粉丝/3",
    label: "火山粉丝",
  },
  {
    value: "aweme_count/视频发布/4",
    label: "视频发布",
  },
  {
    value: "aweme_like_count/视频点赞/5",
    label: "视频点赞",
  },
  {
    value: "aweme_comment_count/视频评论/6",
    label: "视频评论",
  },
  {
    value: "aweme_share_count/视频转发/7",
    label: "视频转发",
  },
  {
    value: "aweme_play_count/视频播放/8",
    label: "视频播放",
  },
  {
    value: "aweme_activation/视频活跃度/9",
    label: "视频活跃度",
  },
  {
    value: "live_count/直播场次/10",
    label: "直播场次",
  },
  {
    value: "live_time/直播时长/11",
    label: "直播时长",
  },
  {
    value: "live_watch_count/观看人数/12",
    label: "观看人数",
  },
  {
    value: "live_watch_time/观看人次/13",
    label: "观看人次",
  },
  {
    value: "live_like_count/直播点赞/14",
    label: "直播点赞",
  },
  {
    value: "live_comment_count/直播评论/15",
    label: "直播评论",
  },
  {
    value: "live_activation/直播活跃度/16",
    label: "直播活跃度",
  },
  {
    value: "live_fans_add/直播增粉/17",
    label: "直播增粉",
  },
];
const allCheck = [
  "fans_count/粉丝增量/0",
  "fans_count_total/粉丝总量/1",
  "fans_tt_count/头条粉丝/2",
  "fans_hs_count/火山粉丝/3",
  "aweme_count/视频发布/4",
  "aweme_like_count/视频点赞/5",
  "aweme_comment_count/视频评论/6",
  "aweme_share_count/视频转发/7",
  "aweme_play_count/视频播放/8",
  "aweme_activation/视频活跃度/9",
  "live_count/直播场次/10",
  "live_time/直播时长/11",
  "live_watch_count/观看人数/12",
  "live_watch_time/观看人次/13",
  "live_like_count/直播点赞/14",
  "live_comment_count/直播评论/15",
  "live_activation/直播活跃度/16",
  "live_fans_add/直播增粉/17",
];

import Empty from "@/components/Empty/Empty";
import Tooltip from "@/components/Tooltip/Tooltip";
import * as vuex from "vuex";
import _ from "lodash";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("storeCompetition");
import deepClone from "@/utils/clone-utils";

export default {
  name: "StoreStatistics",
  components: {
    Empty,
    Tooltip,
  },
  props: {
    platform: {
      type: String,
      default: "douyin",
    },
  },
  data() {
    return {
      locale: {
        emptyText: <Empty style="margin-top: 120px;" title="暂无数据" />,
      },
      firstColumns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "num" },
          fixed: "left",
        },
        {
          key: "account",
          align: "left",
          slots: { title: "customTitle" },
          width: 200,
          scopedSlots: { customRender: "account" },
          fixed: "left",
        },
        {
          key: "storeCode",
          title: "门店编码",
          align: "left",
          width: 150,
          scopedSlots: { customRender: "storeCode" },
          fixed: "left",
        },
        {
          key: "action",
          width: 70,
          align: "left",
          slots: { title: "customAction" },
          scopedSlots: { customRender: "action" },
          fixed: "right",
        },
      ],
      columns: [],
      tableSetting: deepClone(tableSetting),
      current: 1,
      pageSize: 5,
      total: 0,
      tableData: [],
      tableLoading: false,
      settingModalShow: false,
      currentCheck: [
        "fans_count_total/粉丝总量/1",
        "aweme_count/视频发布/4",
        "aweme_play_count/视频播放/8",
        "live_count/直播场次/10",
        "live_watch_time/观看人次/13",
      ],
      oldCheck: deepClone(allCheck),
      currentRegionId: "",
    };
  },
  computed: {
    ...mapState({
      competitionData: (state) => state.competitionData,
    }),
  },
  created() {},
  watch: {
    competitionData(val) {
      console.log("competitionData", val);
      this.tableData = val.list;
      this.total = val.pagination.total;
    },
  },
  methods: {
    ...mapActions(["getCompetitionList"]),
    getComponentsData(id) {
      this.currentRegionId = id;
      this.search(true);
      this.setTableCol(true);
    },

    async search(isFirst) {
      console.log("isFirst", isFirst);
      this.tableLoading = true;
      let begin_time =
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD") +
        " 00:00:00";
      let end_time =
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD") +
        " 23:59:59";
      if (isFirst) {
        this.current = 1;
      }
      let params = {
        is_dealer: "1",
        begin_time: begin_time,
        end_time: end_time,
        current: this.current,
        page_size: this.pageSize,
        platform: this.platform,
        area_id: this.currentRegionId,
      };
      console.log(params);
      await this.getCompetitionList(params);
      this.tableLoading = false;
    },
    // 指标弹窗 确定点击
    settingSure() {
      this.settingModalShow = false;
      this.oldCheck = deepClone(this.currentCheck);
      this.setTableCol();
    },
    // 指标弹窗 取消点击
    settingClose() {
      this.settingModalShow = false;
      this.currentCheck = deepClone(this.oldCheck);
    },
    // 页码切换
    pageChange(val) {
      console.log("pageChange", val);
      this.current = val;
      this.search();
    },
    // 指标点击
    actionClick() {
      this.settingModalShow = true;

      console.log("this.currentCheck111", this.currentCheck);
      console.log("this.oldCheck111", this.oldCheck);
      console.log("this.tableSetting111", this.tableSetting);
    },
    tableChange(val) {
      // this.currentCheck = val
      // if (val.length > 6) {
      //   this.currentCheck = val.slice(0, 6);
      //   this.$message.warning("指标选择不能超过6个");
      // }
      console.log(val);

      console.log("this.currentCheck", this.currentCheck);
      console.log("this.oldCheck", this.oldCheck);
      console.log("this.tableSetting", this.tableSetting);
    },
    setTableCol(isCheckAll) {
      this.columns = _.cloneDeep(this.firstColumns);
      // if (isCheckAll) {
      //   this.currentCheck = deepClone(allCheck);
      //   this.oldCheck = deepClone(allCheck);
      // }
      this.tableSetting = deepClone(tableSetting);
      this.currentCheck = this.currentCheck.sort(this.sortSetting);
      this.currentCheck = this.currentCheck.filter((item) => {
        if (
          (item.split("/")[0] == "fans_tt_count" ||
            item.split("/")[0] == "fans_hs_count") &&
          this.platform != "douyin"
        ) {
          return false;
        } else {
          return true;
        }
      });
      this.oldCheck = this.currentCheck.filter((item) => {
        if (
          (item.split("/")[0] == "fans_tt_count" ||
            item.split("/")[0] == "fans_hs_count") &&
          this.platform != "douyin"
        ) {
          return false;
        } else {
          return true;
        }
      });
      this.currentCheck.forEach((item) => {
        let newItem = {
          key: item.split("/")[0],
          title: item.split("/")[1],
          align: "center",
          width: 100,
          scopedSlots: { customRender: item.split("/")[0] },
        };
        if (item.split("/")[0] == "aweme_play_count") {
          delete newItem.title;
          newItem.slots = { title: "tooltipTitle" };
        }
        if (item.split("/")[0] == "live_watch_count") {
          delete newItem.title;
          newItem.slots = { title: "watchCountTitle" };
        }
        if (item.split("/")[0] == "live_watch_time") {
          delete newItem.title;
          newItem.slots = { title: "watchTimeTitle" };
        }
        this.columns.splice(-1, 0, newItem);
      });
      // console.log('this.tableSetting',this.tableSetting)
    },
    sortSetting(a, b) {
      return a.split("/")[2] - b.split("/")[2];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.store-statistics-container {
  height: 544px;
  width: 100%;

  .table {
    height: 484px;
    background: #2b2f3b;
    border-radius: 16px;
    &-account {
      display: flex;
      flex-direction: row;
      margin: 16px 0;
      &-left {
        display: flex;
        flex-direction: row;
        @include font_color("font_color3");
        > div {
          margin-left: 8px;
          > div {
            width: 160px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
      }
    }
    .action-ico {
      @include font_color("font_color5");
      cursor: pointer;
      font-size: 16px;
      margin-left: 20px;
    }
  }
  .page-div {
    text-align: right;
    padding: 20px 0 0;
  }
}
.setting-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 999;
  &-container {
    position: absolute;
    right: 24px;
    top: 260px;
    width: 424px;
    height: 344px;
    border-radius: 4px;
    @include background_color("background_color27");
    @include font_color("font_color2");
    font-size: 14px;
    &-title {
      font-size: 16px;
      padding: 24px 0 20px;
      text-align: center;
      > span {
        font-size: 14px;
        @include font_color("font_color3");
      }
    }
    .list {
      padding: 0 32px;
      height: 210px;
    }
    .action-div {
      padding: 16px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      > div {
        cursor: pointer;
        height: 32px;
        line-height: 18px;
        @include font_color("font_color2");
        .icon {
          font-size: 30px;
          @include font_color("font_color4");
          position: relative;
          top: 3px;
        }
      }
      .btn {
        width: 60px;
        height: 32px;
        border-radius: 4px;
        @include background_color("background_color5");
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        cursor: pointer;
      }
      .btn1 {
        @include font_color("font_color3");
        margin-right: 24px;
      }
      .btn2 {
        @include font_color("font_color1");
      }
    }
  }
}
</style>
