import echarts from 'echarts'
var option = {
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisLabel: {
        show: false,
          textStyle: {
            color: '#959595',  //更改坐标轴文字颜色
            fontSize : 10      //更改坐标轴文字大小
          }
      },
      data: []
    },
    yAxis: {
      type: "value",
      splitNumber: 4, 
      axisTick: {
        inside: false,
      },
      axisLabel: {
        show: false,
          textStyle: {
            color: '#959595',  //更改坐标轴文字颜色
            fontSize : 10      //更改坐标轴文字大小
          }
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: ["rgba(255,255,255,0.2)"],
        },
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },

      backgroundColor: 'rgba(37, 50, 85, 0.9)',
      textStyle: {
          color: '#fff',
      },
    },
    grid: {
      left: "5",
      top: "20",
      right: "10",
      bottom: "20",
      containLabel: true,
    },
    series: [
      {
        data: [],
        smooth: true,
        type: "line",
        symbol: 'circle', //折线点设置为实心点
        symbolSize: 4, //折线点的大小
        itemStyle: {
          color: "rgba(75, 196, 126, 0.7)",
        },
        // areaStyle: {
        //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        //     {
        //       offset: 0,
        //       color: "rgba(74,88,243,0.4)",
        //     },
        //     {
        //       offset: 1,
        //       color: "rgba(74,88,243,0.1)",
        //     },
        //   ]),
        // },
      },
    ],
  }

  export default option
