var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"title-box"},[_c('svg-icon',{staticClass:"icon",attrs:{"icon-class":'ti-bulletin'}}),_c('span',[_vm._v("本月曝光量")])],1),_c('div',{staticClass:"number-box"},[_c('div',[_c('svg-icon',{staticClass:"icon f16",attrs:{"icon-class":'sti-live'}}),_c('span',{staticClass:"f14 fw fc1 ml8"},[_vm._v("直播观看人次：")]),_c('span',{staticClass:"f14 fw fc3"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.matrixData.live_day.live_watch_count)))]),_c('span',{staticClass:"f12 fc2 ml20"},[_vm._v("昨日：")]),_c('span',{staticClass:"f12 fc1 mr8"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.matrixData.live_day.new_scope_live_watch_count)))]),(_vm.matrixData.live_day.live_watch_count_chain)?_c('svg-icon',{staticClass:"f12",attrs:{"icon-class":_vm.matrixData.live_day.live_watch_count_chain >= 0
            ? 'ratio-up'
            : 'ratio-down'}}):_vm._e(),_c('span',{class:_vm.matrixData.live_day.live_watch_count_chain >= 0
            ? 'fc-up'
            : 'fc-down'},[_vm._v(_vm._s(_vm._f("numRateAbs")(_vm.matrixData.live_day.live_watch_count_chain)))]),_c('span',{staticClass:"f12 fc2 ml20"},[_vm._v("本周：")]),_c('span',{staticClass:"f12 fw fc1 mr8"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.matrixData.live_week.new_scope_live_watch_count)))]),(_vm.matrixData.live_week.live_watch_count_chain)?_c('svg-icon',{staticClass:"f12",attrs:{"icon-class":_vm.matrixData.live_week.live_watch_count_chain >= 0
            ? 'ratio-up'
            : 'ratio-down'}}):_vm._e(),_c('span',{class:_vm.matrixData.live_week.live_watch_count_chain >= 0
            ? 'fc-up'
            : 'fc-down'},[_vm._v(_vm._s(_vm._f("numRateAbs")(_vm.matrixData.live_week.live_watch_count_chain)))])],1),_c('div',[_c('svg-icon',{staticClass:"icon f16",attrs:{"icon-class":'sti-video'}}),_c('span',{staticClass:"f14 fw fc1 ml8"},[_vm._v("短视频播放量：")]),_c('span',{staticClass:"f14 fw fc3"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.matrixData.aweme_day.aweme_play_total)))]),_c('span',{staticClass:"f12 fc2 ml20"},[_vm._v("昨日：")]),_c('span',{staticClass:"f12 fc1 mr8"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.matrixData.aweme_day.aweme_play_di)))]),(_vm.matrixData.aweme_day.aweme_play_chain)?_c('svg-icon',{staticClass:"f12",attrs:{"icon-class":_vm.matrixData.aweme_day.aweme_play_chain >= 0
            ? 'ratio-up'
            : 'ratio-down'}}):_vm._e(),_c('span',{class:_vm.matrixData.aweme_day.aweme_play_chain >= 0 ? 'fc-up' : 'fc-down'},[_vm._v(_vm._s(_vm._f("numRateAbs")(_vm.matrixData.aweme_day.aweme_play_chain)))]),_c('span',{staticClass:"f12 fc2 ml20"},[_vm._v("本周：")]),_c('span',{staticClass:"f12 fw fc1 mr8"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.matrixData.aweme_week.aweme_play_di)))]),(_vm.matrixData.aweme_week.aweme_play_chain)?_c('svg-icon',{staticClass:"f12",attrs:{"icon-class":_vm.matrixData.aweme_week.aweme_play_chain >= 0
            ? 'ratio-up'
            : 'ratio-down'}}):_vm._e(),_c('span',{class:_vm.matrixData.aweme_week.aweme_play_chain >= 0 ? 'fc-up' : 'fc-down'},[_vm._v(_vm._s(_vm._f("numRateAbs")(_vm.matrixData.aweme_week.aweme_play_chain)))])],1)]),_c('div',{staticClass:"chart-box"},[_c('my-chart',{attrs:{"options":_vm.getLineOption(_vm.xData, _vm.yData1, _vm.yData2),"id":'line-chart'}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }