<template>
  <div class="component-wrap">
    <a-spin :spinning="rankingLoading" class="spin-class" />
    <div class="title-div">
      <div>
        <span class="title">品牌排名</span>
      </div>
      <div @click="routerTo" class="route-div">
        <span class="route">查看更多</span>
        <span class="arrow">
          <svg-icon icon-class="arrow-right" style="font-size: 24px" />
        </span>
      </div>
    </div>
    <div class="info-div" v-if="rankingList">
      <div>
        <WebpImage
          :src="rankingList.avatar ? ossHost + rankingList.avatar : ''"
          :width="'60px'"
          :height="'60px'"
          :radius="'16px'"
        />
        <div class="info-name">{{ rankingList.principal_name || "-" }}</div>
      </div>
      <div>
        <div class="info-item">
          <div class="font1">粉丝总数</div>
          <div class="font2">{{ rankingList.fans_count | changeNum }}</div>
        </div>
        <div class="info-item">
          <div class="font1">
            <span>{{ $parent.currentTab | returnWatchText }}</span
            ><AftTooltip :code="'pgzb01'" style="top: 0px; left: 4px" />
          </div>
          <div class="font2">
            {{ rankingList.live_total_watch_user_time | changeNum }}
          </div>
        </div>
        <div class="info-item">
          <div class="font1">
            <span>营销价值</span
            ><AftTooltip :code="'pgqt01'" style="top: 0px; left: 4px" />
          </div>
          <div class="font2">
            ¥{{ rankingList.marketing_value | changeNum }}
          </div>
        </div>
      </div>
    </div>
    <div class="ranking-chart">
      <div class="ranking-chart-title">{{ currentObj.title || "-" }}</div>
      <div class="ranking-chart-main">
        <MyChart
          :options="getCardChartOption(rankingTrendData)"
          :id="'ranking-chart'"
        />
      </div>
    </div>
    <a-table
      :columns="columns"
      v-if="rankingList"
      :data-source="rankingList.list"
      :rowKey="(data) => data.title"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
      }"
      :pagination="false"
      class="table-style border hide-select"
      :locale="{ emptyText: '暂无数据' }"
    >
      <div slot="customTitle" style="padding-left: 20px">
        <div>
          排名<AftTooltip :code="'pgpm01'" style="top: 1.8px; left: 4px" />
        </div>
      </div>
      <div slot="ranking" slot-scope="scope" class="td-style td-left ranking">
        <span
          style="margin: 0 0px 0 20px"
          :class="scope.ranking <= 3 ? 'bc sp' : 'bc'"
          >{{ scope.ranking || "-" }}</span
        >
        <span
          class="font-color23"
          v-if="scope.ranking_over_last_month && scope.ranking < 1000"
          >{{ Math.abs(scope.ranking_over_last_month) || "" }}</span
        >
        <svg-icon
          v-if="scope.ranking_over_last_month > 0 && scope.ranking < 1000"
          icon-class="caret-up"
          style="font-size: 10px"
        />
        <svg-icon
          v-if="scope.ranking_over_last_month < 0 && scope.ranking < 1000"
          icon-class="caret-down"
          style="font-size: 10px"
        />
      </div>
      <div slot="name" slot-scope="scope" class="td-style">
        <span class="table-name">{{ scope.title }}</span>
      </div>
    </a-table>
  </div>
</template>

<script>
import * as vuex from "vuex";
import WebpImage from "@/components/WebpImage/WebpImage";
import MyChart from "@/components/MyChart/MyChart";
import chartRankingLine from "../options/chartRankingLine.js";
import deepClone from "@/utils/clone-utils";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "brandSummary"
);
export default {
  name: "CardRanking",
  data() {
    return {
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      tooltipTitle: "粉丝总数：最新总数据；直播观看、营销价值：本月增量 ",
      columns: [
        {
          width: "200px",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "ranking" },
        },
        {
          title: "名称",
          align: "left",
          scopedSlots: { customRender: "name" },
        },
        // {
        //   title: "增降",
        //   width: "80px",
        //   align: "center",
        //   scopedSlots: { customRender: "increase" },
        // },
      ],
      selectedRowKeys: [],
      currentObj: {},
    };
  },
  computed: {
    ...mapState({
      rankingList: (state) => state.rankingList, // 品牌排名
      rankingLoading: (state) => state.rankingLoading,
      rankingTrendData: (state) => state.rankingTrendData,
    }),
  },
  components: {
    WebpImage,
    MyChart,
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions(["getBrandRanking", "getRankingTrend"]),
    ...mapMutations(["changeState"]),
    //   查看更多跳转
    async initData() {
      await this.getBrandRanking({
        platform: this.$parent.currentTab,
        principal_id: this.$parent.principal_id,
      });
      if (
        this.rankingList &&
        this.rankingList.list &&
        this.rankingList.list.length > 0
      ) {
        this.selectedRowKeys = [this.rankingList.list[0].title];
        this.currentObj = this.rankingList.list[0];
        this.getRankingTrendData();
      }
    },
    getCardChartOption(data) {
      let option = deepClone(chartRankingLine);
      let xData = data.map((item) => {
        return item.month + "月";
      });
      let yData = data.map((item) => {
        return {
          value: item.ranking,
          number: item.value,
        };
      });
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      option.series[0].name = this.currentObj.title;
      return option;
    },

    rowClick(record, index) {
      return {
        on: {
          click: () => {
            let keys = [];
            keys.push(record.title);
            console.log(record, this.selectedRowKeys);
            this.selectedRowKeys = keys;
            this.currentObj = record;
            this.getRankingTrendData();
          },
        },
      };
    },
    getRankingTrendData() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          indicators_type: this.currentObj.indicators_type,
        },
      };
      if (this.$parent.principal_id) {
        data.params.principal_id = this.$parent.principal_id;
      }
      this.getRankingTrend(data);
    },
    routerTo() {
      this.$router.push({
        path: "/brand/generalList",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.component-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 380px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .title {
      @include font_color("font_color52");
      font-size: 16px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      @include font_color("font_color28");
      .route {
        font-size: 12px;
        line-height: 24px;
      }
      .arrow {
        font-size: 24px;
      }
    }
  }
  .info-div {
    height: 68px;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > div:first-child {
      display: flex;
      align-items: center;
    }
    > div:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-hover {
      position: absolute;
      bottom: -20px;
      right: 20px;
      font-size: 12px;
      @include font_color("font_color22");
    }
    .info-name {
      white-space: nowrap;
      @include font_color("font_color52");
      font-size: 16px;
      margin-left: 16px;
    }
    .info-item {
      white-space: nowrap;
      margin-left: 24px;
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      .font1 {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        @include font_color("font_color23");
        font-size: 12px;
      }
      .font2 {
        @include font_color("font_color20");
        font-size: 18px;
      }
    }
  }
  .ranking-chart {
    width: 100%;
    height: 183px;
    padding: 0 20px;
    &-title {
      height: 22px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
    }
    &-main {
      width: 100%;
      height: 161px;
    }
  }
  .table-style {
    .ranking > span {
      min-width: 24px;
      padding: 0 8px;
      height: 24px;
      border-radius: 12px;
      display: inline-block;
      text-align: center;
      line-height: 24px;
    }
    .bc {
      @include background_color("background_color51");
      @include font_color("font_color22");
    }
    .bc.sp {
      @include background_color("background_color88");
      @include font_color("font_color55");
    }
    .table-name {
      font-size: 14px;
      @include font_color("font_color52");
    }
  }
  .td-style {
    height: 42px;
    display: flex;
    align-items: center;
  }
  .td-left {
    text-align: left;
  }
  .td-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .td-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .font-color21 {
    @include font_color("font_color21");
  }
  .font-color23 {
    @include font_color("font_color23");
  }
}
</style>
