var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"live-detail-card"},[_c('div',{staticClass:"title-box"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.liveDetail.title))]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.liveDetail.title)+" ")])],2),_c('div',{staticStyle:{"display":"flex"}},[_c('WebpImage',{staticStyle:{"margin-right":"10px"},attrs:{"src":_vm.liveDetail.avatar,"width":'22px',"height":'22px',"radius":'50%'}}),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.liveDetail.nickname))]),_c('span',{staticClass:"nickname ellipsis-1"},[_vm._v(_vm._s(_vm.liveDetail.nickname))])],2)],1)],1),_c('div',{staticClass:"top"},[_c('div',{staticClass:"img-div"},[_c('WebpImage',{attrs:{"src":_vm.liveDetail ? _vm.liveDetail.cover : null,"width":'72px',"height":'72px',"radius":'0px'}})],1),_c('div',{staticClass:"mask-play",on:{"click":function($event){return _vm.openLive(_vm.liveDetail)}}},[(!_vm.liveDetail.play_url)?_c('svg-icon',{staticClass:"lock-icon",attrs:{"icon-class":'no-video'}}):_vm._e(),(
          _vm.liveDetail.play_url &&
          _vm.liveDetail.url_state == ('Archive' || 'Unknown') &&
          _vm.liveDetail.is_restore_completed == '1'
        )?_c('svg-icon',{staticClass:"lock-icon",attrs:{"icon-class":'unlock'}}):(
          _vm.liveDetail.play_url &&
          _vm.liveDetail.url_state == ('Archive' || 'Unknown') &&
          _vm.liveDetail.is_restore_completed == ('0' || '3')
        )?_c('svg-icon',{staticClass:"lock-icon",attrs:{"icon-class":'lock'}}):(_vm.liveDetail.play_url)?_c('svg-icon',{staticClass:"mask-icon",attrs:{"icon-class":'play'}}):_vm._e()],1),_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('span',{staticClass:"row-main-txt"},[_vm._v(_vm._s(_vm.liveDetail.plat_form))])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('span',{staticClass:"row-main-txt"},[_vm._v(_vm._s(_vm.liveDetail.full_date))])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('span',{staticClass:"row-main-txt"},[_vm._v(_vm._s(_vm.liveDetail.new_publish_time))])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('span',{staticClass:"row-main-txt"},[_vm._v(_vm._s(_vm.liveDetail.new_finish_time))])])])]),_c('div',{staticClass:"card-detail"},[_c('div',{staticClass:"lock-div"},[(!_vm.liveDetail.play_url)?_c('div',{staticClass:"btn3"},[_vm._v("暂无视频")]):_vm._e(),(
          _vm.liveDetail.play_url &&
          _vm.liveDetail.url_state == ('Archive' || 'Unknown') &&
          _vm.liveDetail.is_restore_completed == ('0' || '3')
        )?_c('a-button',{staticClass:"btn1",attrs:{"type":"primary","loading":_vm.lockLoading},on:{"click":_vm.unlockClick}},[_vm._v(" 解冻 ")]):_vm._e(),(
          _vm.liveDetail.play_url &&
          _vm.liveDetail.url_state == ('Archive' || 'Unknown') &&
          _vm.liveDetail.is_restore_completed == '1'
        )?_c('div',{staticClass:"btn3"},[_vm._v(" 解冻中 ")]):_vm._e(),_c('div',{staticClass:"btn2",on:{"click":_vm.toRecord}},[_vm._v("解冻记录")])],1),_c('div',{staticClass:"count-div"},_vm._l((_vm.liveDetail.count_data),function(item,index){return _c('div',{key:index,staticClass:"count-item"},[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]),(item.title == '直播时长')?_c('span',{staticClass:"important3"},[_vm._v(_vm._s(_vm._f("formatDuration")(item.number)))]):(item.title == '平均在线')?_c('span',{staticClass:"important3"},[_vm._v(_vm._s(_vm._f("changeNum2")((item.number == null ? "-" : Number(item.number)))))]):_c('span',{staticClass:"important3"},[_vm._v(_vm._s(_vm._f("changeNum")((item.number == null ? "-" : Number(item.number)))))])])}),0)]),_c('a-modal',{ref:"modal",attrs:{"title":"直播回放","footer":null},on:{"cancel":_vm.handleClose},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('video',{staticStyle:{"width":"100%","height":"100%","border-radius":"10px"},attrs:{"id":"videoElement","autoplay":"","controls":"","muted":""},domProps:{"muted":true}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"row-main-label"},[_c('i',[_vm._v("平")]),_c('i',[_vm._v("台: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"row-main-label"},[_c('i',[_vm._v("日")]),_c('i',[_vm._v("期: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"row-main-label"},[_c('i',[_vm._v("开")]),_c('i',[_vm._v("播")]),_c('i',[_vm._v("时")]),_c('i',[_vm._v("间: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"row-main-label"},[_c('i',[_vm._v("下")]),_c('i',[_vm._v("播")]),_c('i',[_vm._v("时")]),_c('i',[_vm._v("间: ")])])
}]

export { render, staticRenderFns }