import filters from '@/filters'


const settingData_0 = [
  {
    text: "剔除刷量视频",
    value: "1",
    bool: false,
  },
  {
    text: "剔除挂播直播",
    value: "4",
    bool: false,
  },
  {
    text: "剔除已删除/隐藏视频",
    value: "2",
    bool: false,
  },
  {
    text: "剔除播放低于500的视频",
    value: "3",
    bool: false,
  },
  {
    text: "剔除时长低于30min的直播",
    value: "5",
    bool: false,
  },
];
const settingData_1 = [
  {
    text: "剔除刷量视频",
    value: "1",
    bool: false,
  },
  {
    text: "剔除已删除/隐藏视频",
    value: "2",
    bool: false,
  },
  {
    text: "剔除播放低于500的视频",
    value: "3",
    bool: false,
  },
];
const settingData_2 = [
  {
    text: "剔除挂播直播",
    value: "4",
    bool: false,
  },
  {
    text: "剔除时长低于30min的直播",
    value: "5",
    bool: false,
  }
];
const settingData_3 = [
  {
    text: "剔除刷量视频",
    value: "1",
    bool: false,
  },
  {
    text: "剔除挂播直播",
    value: "4",
    bool: false,
  },
  {
    text: "剔除已删除/隐藏视频",
    value: "2",
    bool: false,
  },
  {
    text: "剔除播放低于500的视频",
    value: "3",
    bool: false,
  },
  {
    text: "剔除时长低于30min的直播",
    value: "5",
    bool: false,
  },
];
const columns_0 = [
  {
    title: '序号',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: 86,
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称/公司名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: 288,
    scopedSlots: { customRender: 'dealer_name' }
  },
  {
    title: '门店编号',
    dataIndex: 'dealer_store_code',
    key: 'dealer_store_code',
    align: 'left',
    width: 100,
    customRender: function (text, record, index) {
      return text || '-'
    }
  },
  {
    title: '区域',
    dataIndex: 'dealer_area_names',
    key: 'dealer_area_names',
    align: 'left',
    width: 72,
    customRender: function (text, record, index) {
      return text || '-'
    }
  },
  {
    title: '活动期间增粉',
    dataIndex: 'fans_count_total_di',
    key: 'fans_count_total_di',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '直播场次',
    dataIndex: 'live_count',
    key: 'live_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '直播时长',
    dataIndex: 'statistics_live_time',
    key: 'statistics_live_time',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.toHover(text) || '-'
    }
  },
  {
    slots: { title: "customTitle" },
    dataIndex: 'watch_count',
    key: 'watch_count',
    align: 'left',
    width: '8.4%',
    scopedSlots: { customRender: 'watch_count' }
  },
  {
    title: '直播互动',
    dataIndex: 'live_interactive',
    key: 'live_interactive',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频发布',
    dataIndex: 'aweme_count',
    key: 'aweme_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频播放',
    dataIndex: 'play_volume',
    key: 'play_volume',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频互动',
    dataIndex: 'aweme_interactive',
    key: 'aweme_interactive',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
]
const columns_1 = [
  {
    title: '序号',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: 86,
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称/公司名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: 288,
    scopedSlots: { customRender: 'dealer_name' }
  },
  {
    title: '门店编号',
    dataIndex: 'dealer_store_code',
    key: 'dealer_store_code',
    align: 'left',
    width: 100,
    customRender: function (text, record, index) {
      return text || '-'
    }
  },
  {
    title: '区域',
    dataIndex: 'dealer_area_names',
    key: 'dealer_area_names',
    align: 'left',
    width: 72,
    customRender: function (text, record, index) {
      return text || '-'
    }
  },
  {
    title: '视频发布',
    dataIndex: 'aweme_count',
    key: 'aweme_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频播放',
    dataIndex: 'play_volume',
    key: 'play_volume',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频点赞',
    dataIndex: 'digg_count',
    key: 'digg_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频评论',
    dataIndex: 'comment_count',
    key: 'comment_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频转发',
    dataIndex: 'share_count',
    key: 'share_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
]
const columns_2 = [
  {
    title: '序号',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: 86,
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称/公司名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: 288,
    scopedSlots: { customRender: 'dealer_name' }
  },
  {
    title: '门店编号',
    dataIndex: 'dealer_store_code',
    key: 'dealer_store_code',
    align: 'left',
    width: 100,
    customRender: function (text, record, index) {
      return text || '-'
    }
  },
  {
    title: '区域',
    dataIndex: 'dealer_area_names',
    key: 'dealer_area_names',
    align: 'left',
    width: 72,
    customRender: function (text, record, index) {
      return text || '-'
    }
  },
  {
    title: '直播场次',
    dataIndex: 'live_count',
    key: 'live_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '直播时长',
    dataIndex: 'statistics_live_time',
    key: 'statistics_live_time',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.toHover(text) || '-'
    }
  },
  {
    slots: { title: "customTitle" },
    dataIndex: 'watch_count',
    key: 'watch_count',
    align: 'left',
    width: '8.4%',
    scopedSlots: { customRender: 'watch_count' }
  },
  {
    title: '直播涨粉',
    dataIndex: 'statistics_fans_add',
    key: 'statistics_fans_add',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '直播点赞',
    dataIndex: 'statistics_total_like_count',
    key: 'statistics_total_like_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '直播评论',
    dataIndex: 'statistics_total_comment_count',
    key: 'statistics_total_comment_count',
    align: 'left',
    width: '8.4%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
]
const columns_3 = [
  {
    title: '序号',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: 86,
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '区域',
    dataIndex: 'dealer_area_names',
    key: 'dealer_area_names',
    align: 'left',
    width: 72,
    customRender: function (text, record, index) {
      return text || '-'
    }
  },
  {
    title: '门店数',
    dataIndex: 'dealer_count',
    key: 'dealer_count',
    align: 'left',
    width: '10.5%',
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '直播场次',
    dataIndex: 'live_count',
    key: 'live_count',
    align: 'left',
    width: '10.5%',
    sorter: (a, b) => a.live_count - b.live_count,
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '直播时长',
    dataIndex: 'statistics_live_time',
    key: 'statistics_live_time',
    align: 'left',
    width: '10.5%',
    sorter: (a, b) => a.statistics_live_time - b.statistics_live_time,
    customRender: function (text, record, index) {
      return filters.toHover(text) || '-'
    }
  },
  {
    slots: { title: "customTitle" },
    dataIndex: 'watch_count',
    key: 'watch_count',
    align: 'left',
    width: '10.5%',
    sorter: true,
    scopedSlots: { customRender: 'watch_count' }
  },
  {
    title: '直播互动',
    dataIndex: 'live_interactive',
    key: 'live_interactive',
    align: 'left',
    width: '10.5%',
    sorter: (a, b) => a.live_interactive - b.live_interactive,
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频发布',
    dataIndex: 'aweme_count',
    key: 'aweme_count',
    align: 'left',
    width: '10.5%',
    sorter: (a, b) => a.aweme_count - b.aweme_count,
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频播放',
    dataIndex: 'play_volume',
    key: 'play_volume',
    align: 'left',
    width: '10.5%',
    sorter: (a, b) => a.play_volume - b.play_volume,
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
  {
    title: '视频互动',
    dataIndex: 'aweme_interactive',
    key: 'aweme_interactive',
    align: 'left',
    width: '10.5%',
    sorter: (a, b) => a.aweme_interactive - b.aweme_interactive,
    customRender: function (text, record, index) {
      return filters.changeNum(text) || '-'
    }
  },
]

export const settingData = [
  settingData_0,
  settingData_1,
  settingData_2,
  settingData_3
]
export const columnsList = [
  columns_0,
  columns_1,
  columns_2,
  columns_3
]

export const apiList = [
  "getHonorListByDealer",
  "getHonorListByAweme",
  "getHonorListByLive",
  "getHonorListByRegion"
]

export const exportApiList = [
  "/jjt/activity/honor/roll/dealer/export",
  "/jjt/activity/honor/roll/aweme/export",
  "/jjt/activity/honor/roll/live/export",
  "/jjt/activity/honor/roll/region/export"
]
