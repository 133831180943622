<template>
  <common-layout>
    <div class="top">
      <div class="header">
        <img alt="logo" class="logo" src="@/assets/img/logo5.png" />
      </div>
      <div class="desc"></div>
    </div>
    <div class="login" v-if="loginType">
      <div class="login-title">
        <div class="left-bac"></div>
        <span>{{
          judgeTicket || feishuTicket || qywx_ticket ? "绑 定" : "登 录"
        }}</span>
        <div class="right-bac"></div>
      </div>
      <div v-if="judgeTicket" class="wx-info">
        <svg-icon icon-class="wx-logo" style="font-size: 48px" />
        <div class="scan-text">扫码成功<br />请输入账号密码完成绑定</div>
      </div>
      <div v-if="feishuTicket" class="wx-info">
        <div class="scan-text">飞书登录成功<br />请输入账号密码完成绑定</div>
      </div>
      <div v-if="qywx_ticket" class="wx-info">
        <div class="scan-text">企微登录成功<br />请输入账号密码完成绑定</div>
      </div>
      <a-form @submit="onSubmit" :form="form" class="scan-form">
        <a-alert
          type="error"
          :closable="false"
          v-show="error"
          :message="error"
          showIcon
          style="margin-bottom: 24px"
        />
        <a-form-item>
          <a-input
            class="input-item"
            autocomplete="autocomplete"
            placeholder=""
            size="large"
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: '请输入账号', whitespace: true },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input-password
            class="input-item"
            size="large"
            placeholder=""
            autocomplete="autocomplete"
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: '请输入密码', whitespace: true },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-button
            :loading="logging"
            class="login-btn"
            size="large"
            htmlType="submit"
            type="primary"
          >
            {{ judgeTicket ? "登 录" : "登 录" }}
          </a-button>
        </a-form-item>
        <div
          class="login_code"
          v-if="!judgeTicket && !feishuTicket && !qywx_ticket"
        >
          其他登录方式:
          <a-icon type="wechat" class="icon" @click="sweepCode" />
        </div>
      </a-form>
    </div>
    <div class="login_other" v-else>
      <div v-if="isWxWork && isMobile" class="tip-text">
        <h3>温馨提示</h3>
        <div>此应用仅支持企业微信PC版。</div>
        <div>如使用移动设备，请在您的企业微信PC端访问。</div>
      </div>
      <div v-else-if="isFeishu || isWxWork" class="feishu-text">
        <span v-if="errorText" style="text-align: center;">{{
          errorText
        }}</span>
        <span style="text-align: center;" v-else>
          登录中 <a-icon type="loading" />
        </span>
      </div>
      <template v-else>
        <div class="live_tools_header_title_components">
          <div class="login-title">
            <div class="left-bac"></div>
            <span>扫 码</span>
            <div class="right-bac"></div>
          </div>
        </div>
        <div class="main">
          <div class="code_box">
            <a-spin :spinning="sweepLoading">
              <img :src="ticketData.ticket" />
            </a-spin>
          </div>

          <p class="code_desc">
            <a-icon
              type="wechat"
              class="icon"
              style="font-size: 18px; margin-right: 10px"
            />
            请使用微信扫码登录
          </p>
        </div>
        <a class="footer" @click="passwordLogin" v-if="isShowInput">
          账号密码登录
        </a>
        <div class="box"></div>
      </template>
    </div>
  </common-layout>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
import { getAES } from "@/utils/aes";
import { getUrlParams } from "@/utils";
import authAPI from "@/api/auth";

import matrixMemberApi from "@/api/matrixMember";
import {
  setToken,
  setUserId,
  setPrincipalId,
  setPrincipalLogo,
  setPrincipalList,
  setPrincipalName,
  setPrincipalState,
  setName,
  setUserName,
  setLogoType,
  getToken,
  getUserId,
  getRedirectPath,
  removeRedirectPath,
  setRegionTrackData,
  setTrackPath,
} from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
// import {setAuthorization} from '@/utils/request'
import { loadRoutes } from "@/utils/routerUtil";
// import {mapMutations} from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers("auth");

export default {
  name: "Login",
  components: { CommonLayout },
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      loginType: false,
      timer: null,
      judgeTicket: false,
      headimgurl: "",
      wx_nickname: "",
      isShowInput: false,
      isFeishu: false,
      feishuAppid: "",
      feishuTicket: "",
      errorText: "",
      isWxWork: false,
      qywx_ticket: "",
      isMobile: false,
    };
  },
  computed: {
    ...mapState({
      principalsList: (state) => state.principalsList,
      list: (state) => state.list,
      ticketData: (state) => state.ticketData,
      sweepLoading: (state) => state.sweepLoading,
    }),
  },
  created() {
    // 进入登录清除品牌id
    setPrincipalId("");
    setTrackPath({});
    window.routeMap = undefined;

    this.isFeishu = !!(window.h5sdk && window.tt);

    const userAgent = navigator.userAgent.toLowerCase();
    this.isWxWork = /wxwork/i.test(userAgent);

    this.isMobile = /phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone/i.test(
      userAgent
    );

    if (!this.isFeishu && !this.isWxWork) {
      this.sweepCode();
    }
    // 内部使用账号密码登录方式
    this.isShowInput = this.$route.query.debug == 1 ? true : false;
  },
  mounted() {
    this.feishuAuth();
    this.wxWorkAuth();
  },
  methods: {
    ...mapActions([
      "login",
      "getAuthority",
      "getPrincipals",
      "getSweepCode",
      "loginFeishuAction",
      "loginWxWorkAction",
      "getRegionUserInfo",
      "getPlatformSetting",
    ]),
    feishuAuth() {
      if (!window.h5sdk) {
        console.log("invalid h5sdk");
        return;
      }
      const urlParams = getUrlParams();
      const larkId = urlParams.lark_id || localStorage.feishuLarkId;
      authAPI
        .getLarkAppId({ id: larkId })
        .then((res) => {
          const { appId } = res.data.data;
          this.feishuAppid = appId;
          console.log("getLarkAppId", res);
          // 通过 ready 接口确认环境准备就绪后才能调用 API
          window.h5sdk.ready(() => {
            // 调用 JSAPI tt.requestAuthCode 获取 authorization code
            tt.requestAuthCode({
              appId: appId,
              // 获取成功后的回调
              success: (r) => {
                localStorage.feishuLarkId = larkId;
                console.log("getAuthCode succeed", r);
                this.loginFeishuAction({
                  oauth_config_id: larkId,
                  code: r.code,
                  grant_type: "feishu_union_id",
                  client_module_id: 6,
                }).then((result) => {
                  console.log("loginFeishuAction", result);
                  const feishuData = result.data;
                  if (feishuData.code === 50201001) {
                    this.$message.warning(feishuData.message);
                    this.feishuTicket = feishuData.data.feishu_ticket;
                    this.loginType = true;
                  }
                  if (feishuData.code === 50201003) {
                    this.$message.warning(feishuData.message);
                    this.errorText =
                      "飞书授权code无效, 浏览器3秒后会自动刷新重新授权";
                    this.loginType = false;
                    setTimeout(() => {
                      window.location.reload();
                    }, 3 * 1000);
                  }
                  if (feishuData.code === 426) {
                    this.$message.warning(feishuData.message);
                    this.loginType = true;
                  }
                  if (feishuData.code === 427) {
                    this.$message.warning(feishuData.message);
                    this.error = feishuData.message;
                    this.loginType = true;
                  }

                  if (feishuData.data?.token) {
                    this.afterLogin(result);
                  }
                });
              },
              // 获取失败后的回调
              fail: (err) => {
                console.log(`getAuthCode failed, err:`, JSON.stringify(err));
                this.errorText = JSON.stringify(err);
                this.loginType = false;
              },
            });
          });
        })
        .catch((err) => {
          console.log("getLarkAppId", err);
        });
    },
    wxWorkAuth() {
      if (!this.isWxWork || this.isMobile) return;

      const urlParams = getUrlParams();
      const suiteId = urlParams.suiteId || localStorage.wxWorkSuiteId;

      const code = urlParams.code;
      const state = urlParams.state;

      if (suiteId && !code && !state) {
        localStorage.wxWorkSuiteId = suiteId;
        authAPI.getWxAuthUrl({ suiteId: suiteId }).then((res) => {
          console.log("getWxAuthUrl", res);
          if (res.data.code === 200) {
            const oauthUrl = res.data.data;
            window.location.href = oauthUrl;
          } else {
            this.$message.warning(res.data.msg);
            this.errorText = res.data.msg;
          }
        });
      } else {
        this.loginWxWorkAction({
          suite_id: state,
          qy_wechat: code,
          grant_type: "qy_wechat",
          qy_wechat_type: "tp_code",
        }).then((result) => {
          console.log("loginWxWorkAction", result);
          const wxWorkData = result.data;
          if (wxWorkData.code === 50301001) {
            this.$message.warning(wxWorkData.message);
            this.qywx_ticket = wxWorkData.data.qywx_ticket;
            this.loginType = true;
          }
          if (wxWorkData.code === 50301003) {
            this.$message.warning(wxWorkData.message);
            this.errorText =
              "企业微信授权code无效, 浏览器3秒后会自动刷新重新授权";
            this.loginType = false;
            setTimeout(() => {
              window.location.href = location.origin + "/login";
            }, 3 * 1000);
          }
          if (wxWorkData.code === 426) {
            this.$message.warning(wxWorkData.message);
            this.loginType = true;
          }
          if (wxWorkData.code === 427) {
            this.$message.warning(wxWorkData.message);
            this.error = wxWorkData.message;
            this.loginType = true;
          }

          if (wxWorkData.data?.token) {
            this.afterLogin(result);
          }
        });
      }
    },
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true;
          const username = this.form.getFieldValue("username");
          const password = this.form.getFieldValue("password");
          const grant_type = "password"; // password 与 ticket 模式
          if (this.isFeishu) {
            let data = {
              username,
              password: getAES(password),
              grant_type: "password",
              feishu_ticket: this.feishuTicket,
              // client_module_id: 6,
            };
            this.loginFeishuAction(data)
              .then((res) => {
                console.log("then", res);
                this.afterLogin(res);
              })
              .catch((err) => {
                console.log("catch", err);
                this.$message.error(err.data.message);
              });
          } else if (this.isWxWork) {
            let data = {
              username,
              password: getAES(password),
              grant_type: "password",
              qywx_ticket: this.qywx_ticket,
            };
            this.loginWxWorkAction(data)
              .then((res) => {
                console.log("then", res);
                this.afterLogin(res);
              })
              .catch((err) => {
                console.log("catch", err);
                this.$message.error(err.data.message);
              });
          } else {
            let data = {
              username,
              password: getAES(password),
              grant_type,
              ticket: this.judgeTicket ? this.ticketData.key : "",
              // client_module_id: 6,
            };
            this.login(data)
              .then((res) => {
                this.afterLogin(res);
              })
              .catch((err) => {
                this.logging = false;
                this.$message.error(err.data.msg);
                // if (err.data.code == 50101002) {
                //   this.$message.error("该微信已绑定其他账号！");
                // }
                console.log(err);
              });
          }
        }
      });
    },
    async afterLogin(res) {
      this.logging = false;
      try {
        const loginRes = res.data;
        console.log("loginRes:", loginRes);
        if (loginRes.code == 200) {
          const {
            token,
            id,
            token_head,
            name,
            username,
            customer_type,
          } = loginRes.data;
          setToken(token_head + token);
          setUserId(id);
          setName(name);
          setUserName(username);
          setLogoType(customer_type);

          await this.getAuthority();

          await this.getPrincipals();

          if (this.principalsList.length > 0 && this.list.length > 0) {
            // 存入主体id
            setPrincipalId(this.principalsList[0].principal_id);
            setPrincipalLogo(this.principalsList[0].logo);
            setPrincipalName(this.principalsList[0].principal_name);
            setPrincipalState(this.principalsList[0].state);
            // setPrincipalList(this.principalsList);
            localStorage.setItem(
              "principal_list",
              JSON.stringify(this.principalsList)
            );
            this.$store.commit(
              "auth/SET_PRINCIPAL_LOGO",
              this.principalsList[0].logo
            );
            const regionUserInfo = await this.getRegionUserInfo(
              this.principalsList[0].principal_id
            );
            await this.getPlatformSetting(this.principalsList[0].principal_id);
            console.log("region:", regionUserInfo);
            setRegionTrackData({
              account_type: regionUserInfo.accountType,
              username: regionUserInfo.username,
              mobile: regionUserInfo.mobile,
              region_village:
                regionUserInfo.accountType === 10 ? [] : regionUserInfo.region,
            });
            // 加载路由
            let config = [
              {
                name: "首页",
                path: "/",
                redirect: "/login",
                router: "root",
                children: this.list,
              },
            ];
            loadRoutes(config);

            this.$trackRequest(this.$route, this.$trackEvent.LOGIN, {});

            const { data } = await matrixMemberApi.getMemberListApi({
              platform: 0,
              principalId: this.principalsList[0].principal_id,
              page: 1,
              size: 1,
            });
            if (data?.data?.total == 0) {
              if (process.env.NODE_ENV === "development") {
                const PLUS_HOST = process.env.VUE_APP_PLUS_HOST;
                window.location.href = `${PLUS_HOST}/jjt-new/matrix/init-import`;
              } else {
                window.location.href = `${process.env.VUE_APP_HOST}/jjt-new/matrix/init-import`;
              }
              return;
            }

            // 默认跳转第一个路由
            if (getRedirectPath()) {
              window.location.href = getRedirectPath();
              removeRedirectPath();
            } else {
              if (this.list[0].children && this.list[0].children.length > 0) {
                const index = this.list[0].children.findIndex(
                  (child) => child.hidden !== 1
                );

                const firstChildPath = this.list[0].children[0].path;

                if (firstChildPath.indexOf("jjt-new") > -1) {
                  if (process.env.NODE_ENV === "development") {
                    const PLUS_HOST = process.env.VUE_APP_PLUS_HOST;
                    window.location.href = `${PLUS_HOST}${firstChildPath}`;
                  } else {
                    window.location.href = `${process.env.VUE_APP_HOST}${firstChildPath}`;
                  }
                  return;
                }

                index > -1
                  ? this.$router.push(firstChildPath)
                  : this.$router.push(this.list[0].path);
              } else {
                this.$router.push(this.list[0].path);
              }
            }
          } else {
            console.log("没有菜单权限或没有配置主体，请联系管理员");
            this.errorText = "没有菜单权限或没有配置主体，请联系管理员";
          }
        } else {
          this.error = loginRes.message || loginRes.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 微信二维码获取
    sweepCode() {
      this.loginType = false;
      this.getSweepCode();
      this.circulationLogin();
    },
    // 轮循登录接口
    circulationLogin() {
      this.timer = setInterval(() => {
        this.loginTick();
      }, 2000);
    },
    // 带ticket登录
    loginTick(type) {
      const username = this.form.getFieldValue("username");
      const password = this.form.getFieldValue("password");
      let data = {
        username: username ? username : "",
        password: password ? password : "",
        grant_type: type ? "password" : "ticket",
        ticket: this.ticketData.key,
        // client_module_id: 6,
      };
      this.login(data)
        .then((res) => {
          console.log("轮循登录返回", res);
          clearInterval(this.timer);
          this.afterLogin(res);
        })
        .catch((res) => {
          console.log("出错处理", res);
          switch (res.data.code) {
            case 50101001:
              console.log("未扫码");
              break;
            case 50101004:
              this.$message.warning(res.data.msg);
              this.passwordLogin();
              this.headimgurl = res.data.data ? res.data.data.headimgurl : "";
              this.wx_nickname = res.data.data ? res.data.data.wx_nickname : "";
              this.judgeTicket = true;
              break;
            case 427:
              this.$message.error(res.data.msg);
              clearInterval(this.timer);
              this.sweepCode();
              break;
            default:
              break;
          }
        });
    },
    passwordLogin() {
      this.loginType = true;
      clearInterval(this.timer);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.common-layout /deep/ {
  background-color: #000;

  .ant-input-affix-wrapper .ant-input {
    background: #224564;
    border: none;
    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 0px #fff inset;
      -webkit-text-fill-color: #fff;
      caret-color: #fff;
    }
  }
  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    color: rgba(255, 255, 255, 0.2);
  }
  .ant-input-password-icon {
    color: rgba(255, 255, 255, 0.6);
  }
}
</style>

<style lang="less" scoped>
.common-layout {
  .top {
    text-align: center;
    .header {
      height: 111px;
      line-height: 111px;
      a {
        text-decoration: none;
      }
      .logo {
        height: 111px;
        vertical-align: top;
      }
      .title {
        font-size: 33px;
        color: @title-color;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }
    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    width: 430px;
    padding: 0 50px;
    background: #1c1e27;
    border-radius: 26px;
    margin: 0 auto;
    @media screen and (max-width: 576px) {
      width: 95%;
    }
    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }
    .icon {
      font-size: 24px;
      color: rgba(255, 255, 255, 0.65);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
    .login_code {
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .wx-info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    > img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .scan-text {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .scan-form {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .login-btn {
    width: 100%;
    margin: 30px 0;
    background: #ffca5c;
    border: none;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.88);
  }

  .login-title {
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > span {
      font-size: 18px;
      color: #fff;
      margin: 0 13px;
    }
    .left-bac {
      width: 60px;
      height: 4px;
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 2px;
      opacity: 0.3;
    }
    .right-bac {
      width: 60px;
      height: 4px;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      border-radius: 2px;
      opacity: 0.3;
    }
  }
  .login_other {
    position: relative;
    width: 430px;
    height: 449px;
    padding: 0 10px;
    background: #1c1e27;
    border-radius: 26px;
    margin: 0 auto;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }
    .live_tools_header_title_components {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .title {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        &::before {
          width: 8px;
          height: 16px;
          border-radius: 8px;
          content: "";
          display: inline-block;
          width: 6px;
          height: 14px;
          background-image: linear-gradient(0deg, #4f66ff, #7789ff);
          margin-right: 10px;
          margin-top: 1px;
        }
      }
    }
    .main {
      padding-top: 60px;
      .code_box {
        width: 200px;
        height: 200px;
        background: #fff;
        margin: 0 auto;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .code_desc {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        line-height: 12px;
        color: #0ad068;
      }
      .icons_sweep {
        margin-right: 4px;
        //  color: #0ad068;
      }
    }
    .footer {
      display: block;
      color: #bcc2d9;
      font-size: 12px;
      text-align: right;
      margin-right: 24px;
      &:hover {
        color: #4f66ff;
      }
    }

    .icon-computer {
      position: absolute;
      z-index: 9;
      top: 10px;
      right: 10px;
      color: #505773;
      font-size: 45px;
      line-height: 48px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      &:hover {
        color: #646d8f;
      }
    }
  }
  .tip-text {
    padding-top: 150px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    height: 100%;
    h3 {
      color: #fff;
      font-weight: 400;
    }
  }
  .feishu-text {
    color: #fff;
    font-size: 14px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      flex: 1;
    }
  }
}
</style>
