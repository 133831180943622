<template>
  <a-drawer
    class="act-select-drawer"
    :visible="visible"
    width="378"
    placement="right"
    @close="onCancel"
  >
    <div class="drawer-title" slot="title">
      <span>{{ title }}</span>
      <span v-if="mode === 'multipleSelect'">
        （{{ selectedActIds.length }}/10）
      </span>
    </div>
    <div class="drawer-content">
      <a-spin class="drawer-content-body" :spinning="loading">
        <div class="act-search-wrap">
          <a-input-search
            v-model.trim="actName"
            placeholder="输入活动名称，按Enter进行搜索"
            size="large"
            @pressEnter="onSearch"
            @search="onSearch"
          />
        </div>
        <div class="act-list-wrap" v-if="actList.length > 0">
          <div class="act-list">
            <ActItem
              v-for="item in actList"
              :key="item.id"
              :data="item"
              :is-active="selectedActIds.includes(item.id)"
              :is-disabled-track="mode === 'multipleSelect'"
              @click="onActItemClick(item)"
            />
          </div>
          <a-pagination
            v-model="current"
            size="small"
            :pageSize="pageSize"
            :total="total"
            @change="onPaginationChange"
          />
        </div>
        <div class="act-empty-wrap" v-else>
          <Empty />
        </div>
      </a-spin>
      <div class="drawer-content-footer" v-if="mode === 'multipleSelect'">
        <a-button @click="onCancel">
          取消
        </a-button>
        <a-button type="primary" @click="onConfirm">
          确定
        </a-button>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import ActItem from "../ActItem";
import Empty from "@/components/Empty/Empty";
import api from "@/api/marketingActivityList.js";

export default {
  name: "ActSelectDrawer",
  components: {
    ActItem,
    Empty,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "switch", // switch or multipleSelect
    },
    defaultSelectedActList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      actName: "",
      loading: false,
      actList: [],
      current: 1,
      pageSize: 10,
      total: 0,
      selectedActIds: [],
      selectedActList: [],
    };
  },
  computed: {
    title() {
      return this.mode === "switch" ? "切换活动" : "选择活动";
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.selectedActIds = this.defaultSelectedActList.map((item) => {
          return item.id;
        });
        this.selectedActList = this.defaultSelectedActList.map((item) => {
          return { ...item };
        });
        this.getActivityList();
      } else {
        this.actList = [];
        this.current = 1;
        this.total = 0;
        this.selectedActIds = [];
        this.selectedActList = [];
      }
    },
  },
  methods: {
    // 获取活动列表
    async getActivityList() {
      this.loading = true;
      try {
        let params = {
          activityName: this.actName,
          isTaskRate: false,
          activityStatus: this.activityStatus,
          page: this.current,
          size: this.pageSize,
        };
        const res = await api.getActivityList(params);

        this.loading = false;

        if (res.data.code == 200) {
          this.actList = res.data.data.list || [];
          this.total = res.data.data.total || 0;
        } else {
          this.actList = [];
          this.$message.error(res.data.message || "获取活动列表失败");
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    onSearch() {
      this.current = 1;
      this.getActivityList();
    },
    onPaginationChange(val) {
      this.getActivityList();
    },
    onActItemClick(item) {
      if (this.mode === "switch") {
        this.selectedActIds = [];
        this.selectedActList = [];
        this.selectedActIds.push(item.id);
        this.selectedActList.push({ ...item });
        this.$emit("update:visible", false);
        this.$emit("switch", item.id, { ...item });
      }

      if (this.mode === "multipleSelect") {
        const index = this.selectedActIds.indexOf(item.id);

        if (index > -1) {
          this.selectedActIds.splice(index, 1);
          this.selectedActList.splice(index, 1);
        } else {
          if (this.selectedActIds.length < 10) {
            this.selectedActIds.push(item.id);
            this.selectedActList.push({ ...item });
          } else {
            this.$message.warning("最多选择10场活动");
          }
        }
      }
    },
    onCancel() {
      this.$emit("update:visible", false);
    },
    onConfirm() {
      if (this.mode === "multipleSelect" && this.selectedActIds.length === 0) {
        this.$message.warning("请至少选择一个活动");
        return;
      }
      this.$emit("update:visible", false);
      this.$emit(
        "confirm",
        [...this.selectedActIds],
        this.selectedActList.map((item) => {
          return { ...item };
        })
      );
    },
  },
};
</script>

<style lang="less" scoped>
.act-select-drawer {
  /deep/ .ant-drawer {
    &-wrapper-body {
      background: #1a1d26 !important;
    }

    &-header {
      background: #1a1d26;
      border-bottom: 1px solid rgba(#fff, 0.12);
    }

    &-body {
      padding: 0;
    }

    &-title {
      color: rgba(#fff, 0.85);
    }

    &-close {
      color: rgba(#fff, 0.45);
    }
  }

  .drawer-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 55px);
    overflow: hidden;

    &-body {
      flex: 1;
      overflow: hidden;

      /deep/ .ant-spin {
        &-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
        }
      }
    }

    &-footer {
      padding: 12px 16px;
      text-align: right;
      border-top: 1px solid rgba(#fff, 0.12);

      .ant-btn {
        margin-left: 8px;
        color: rgba(#fff, 0.85);
        background-color: #2b2f3b;
        border-color: #2b2f3b;

        &-primary {
          color: rgba(#000, 0.88);
          background-color: #ffcc55;
          border-color: #ffcc55;
        }
      }
    }
  }

  .act-search-wrap {
    padding: 16px 24px;

    /deep/ .ant-input-search {
      .ant-input {
        color: rgba(#fff, 0.85);
        background-color: #2b2f3b;
        border: 1px solid #2b2f3b;
        border-radius: 8px;

        &::placeholder {
          color: #464d5d;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        &:focus {
          border-color: #ffcc55;
          box-shadow: none;
        }
      }

      &-icon {
        color: #464d5d;
      }
    }
  }

  .act-list-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .act-list {
      flex: 1;
      padding: 16px 24px;
      overflow-x: hidden;
      overflow-y: auto;

      .act-item {
        margin: 0 0 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .ant-pagination {
      padding: 16px 24px;
      text-align: right;
    }
  }

  .act-empty-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}
</style>
