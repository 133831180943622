<template>
  <div class="tabs-content1">
    <div class="tabs-content1-left">
      <div class="title-div">
        <span class="title">流量转化</span>
      </div>
      <div class="chart-div" v-if="chartShow">
        <div class="data-box">
          <div class="data-box-main">
            <div class="font1">线索转化率</div>
            <div class="font2">
              {{ overviewData.clue_conversion_rate | numRate3 }}
            </div>
            <div>
              <span class="font1">较昨日</span>
              <span class="font3">{{ overviewData.day_chain | numRate }}</span>
              <svg-icon
                v-if="overviewData.day_chain > 0"
                icon-class="caret-up"
                style="font-size: 10px; margin-left: 4px"
              />
              <svg-icon
                v-if="overviewData.day_chain < 0"
                icon-class="caret-down"
                style="font-size: 10px; margin-left: 4px"
              />
            </div>
            <div class="tag">
              <span class="tag2" v-if="overviewData.state == '1'">健康</span>
              <span class="tag1" v-else>偏低</span>
            </div>
          </div>
          <div class="data-box-line"></div>
        </div>
        <MyChart
          style="position: relative; z-index: 30"
          :options="getFunnelOption(funnelData)"
          :id="'funnel-chart-1'"
        />
      </div>
    </div>
    <div class="tabs-content1-right">
      <div class="title-div">
        <span class="title">线索来源</span>
        <NewTabs2 :tabData="tabData" @tabChange="tabChange" v-if="isArea" />
      </div>
      <div class="chart-div">
        <MyChart
          v-if="chartShow"
          :options="getPieOption(overviewData[tabsKey])"
          :id="'pie-chart-1'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartPie5 from "../../options/chartPie5.js";
import chartFunnel from "../../options/chartFunnel.js";
import api from "@/api/conversionMarket.js";
export default {
  name: "TabsContent1",
  components: { NewTabs2, MyChart },
  data() {
    return {
      chartShow: true,
      tabData: [
        {
          label: "线索渠道",
          value: "cluesData",
        },
        {
          label: "大区",
          value: "regionData",
        },
      ],
      tabsKey: "cluesData",
      funnelData: [],
      overviewData: {
        regionData: [],
        cluesData: [],
      },
      isArea: true,
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      if (this.$parent.area_id.key) {
        this.isArea = false;
        this.tabsKey = "cluesData";
      } else {
        this.isArea = true;
      }
      this.getOverviewSurvey();
    },
    tabChange(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
    },
    getFunnelOption(data) {
      let option = deepClone(chartFunnel);
      option.series[0].data = data;
      option.series[1].data = data;
      return option;
    },
    getPieOption(data) {
      let option = deepClone(chartPie5);
      let total = data.reduce((acc, cur) => {
        acc += cur.value;
        return acc;
      }, 0);
      option.title[0].text = total;
      option.series[0].data = data;

      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getOverviewSurvey() {
      let data = this.getParentsParams();
      api.getOverviewSurvey(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          this.overviewData = {
            ...data,
            regionData: (data.region || []).map((item) => {
              return {
                name: item.region_name,
                value: item.sales_cue_count,
              };
            }),
            cluesData: [
              {
                name: "直播组件",
                value: _.get(data, "clues_channel.live_component", 0),
              },
              {
                name: "视频组件",
                value: _.get(data, "clues_channel.video_component", 0),
              },
              {
                name: "私信线索",
                value: _.get(data, "clues_channel.private_letter", 0),
              },
              {
                name: "个人主页",
                value: _.get(data, "clues_channel.homepage", 0),
              },
              // {
              //   name: "行业锚点",
              //   value: _.get(data, "clues_channel.industry_anchor", 0),
              // },
              // {
              //   name: "预约组件",
              //   value: _.get(data, "clues_channel.reservation_component", 0),
              // },
              {
                name: "其他",
                value: _.get(data, "clues_channel.surplus", 0),
              },
            ],
          };
          this.funnelData = [
            {
              value: data.total_flow,
              name: "总流量",
              itemStyle: { color: "#3C7EFF" },
            },
            {
              value: data.sales_cue_count,
              name: "销售线索",
              itemStyle: { color: "#FFCA5C" },
            },
          ];
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content1 {
  width: 100%;
  height: 360px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  .title-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .title {
      font-size: 14px;
      color: #ffffff;
    }
  }
  &-left {
    width: calc(65% - 16px);
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 20px;
    .chart-div {
      width: 100%;
      height: 318px;
      position: relative;
      .data-box {
        width: calc(50% - 22px);
        height: 181px;
        position: absolute;
        top: 72px;
        left: 22px;
        &-main {
          width: 120px;
          height: 129px;
          background: #2b2f3b;
          position: absolute;
          left: 0;
          top: 29px;
          z-index: 20;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .font1 {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.45);
            line-height: 22px;
          }
          .font2 {
            font-size: 24px;
            color: #ffffff;
            line-height: 32px;
            margin-bottom: 8px;
          }
          .font3 {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 22px;
            margin-left: 8px;
          }
          .tag {
            width: 40px;
            height: 20px;
            margin-top: 3px;
            > span {
              width: 40px;
              height: 20px;
              border-radius: 4px;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              display: inline-block;
            }
            .tag1 {
              background: rgba(255, 158, 61, 0.3);
              color: #ff9e3d;
            }
            .tag2 {
              background: rgba(39, 195, 70, 0.3);
              color: #27c346;
            }
          }
        }
        &-line {
          position: absolute;
          width: calc(100% - 25px);
          height: 100%;
          border: 1px solid rgba(255, 255, 255, 0.2);
          top: 0;
          right: 0;
          z-index: 10;
        }
      }
    }
  }
  &-right {
    width: 35%;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    margin-left: 16px;
    padding: 20px;
    .chart-div {
      width: 100%;
      height: 318px;
      display: flex;
      flex-wrap: nowrap;
      > div {
        width: 50%;
        height: 100%;
      }
    }
  }
}
</style>
