import Nr from "@/utils/NetworldRequest";
const request = new Nr().getAxios();
function getBaseUrl(serviceName) {
  return new Nr().getBaseUrl(serviceName);
}

const activity_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API,
}).getAxios();

export default {
  // 区域列表
  getAreaList() {
    return request({
      url: `/jjt/dealer/dealers/region-list`,
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then((res) => {
      let list = res.data.data.region_list || [];
      if (res.data.data.type == 10) {
        list.unshift({
          id: "",
          name: "不限大区",
        });
      }
      list.map((item) => {
        item.value = item.id;
        item.label = item.name;
      });
      return list;
    });
  },
  // 大区下拉列表
  getAreaInfoByActivity(params) {
    // return activity_request.get('/jjt/activity/web/statistic/regionList', { params: params })
    return activity_request.get("/base/listRegionAll", { params });
  },
  // 门店下拉列表
  getDealerListByRegion(params) {
    return activity_request.get(
      "/jjt/activity/web/statistic/region/dealerList",
      { params: params }
    );
  },
  // 大区排名
  getHonorListByRegion(params) {
    return activity_request.get("/jjt/activity/web/statistic/regionTopList", {
      params: params,
    });
  },
  // 获取战区列表
  getWarZoneList(params) {
    return activity_request.get("/base/listCombatZone", { params });
  },
  // 战区排名
  getWarZoneTopList(params) {
    return activity_request.get("/jjt/activity/web/statistic/warZoneTopList", {
      params,
    });
  },
  // 门店排名
  getHonorListByDealer(params) {
    return activity_request.get("/jjt/activity/web/statistic/dealerTopList", {
      params: params,
    });
  },
  // 短视频
  getHonorListByAweme(params) {
    return activity_request.get("/jjt/activity/web/statistic/awemeList", {
      params: params,
    });
  },
  // 直播
  getHonorListByLive(params) {
    return activity_request.get("/jjt/activity/web/statistic/liveList", {
      params: params,
    });
  },
  // 笔记
  getHonorListByNote(params) {
    return activity_request.get("/jjt/activity/web/statistic/noteList", {
      params: params,
    });
  },

  // 通过2级id获取其他平台id
  getActivityId(params) {
    return request({
      url: `/dealer/topic/activity/ids`,
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params,
    }).then((res) => {
      res = res.data;
      return res;
    });
  },
  // 活动数据更新时间
  getUpdateTime(params) {
    return activity_request.get("/jjt/activity/web/statistic/updateTime", {
      params: params,
    });
  },
};
