<template>
  <div class="container">
    <platform-header
      title="闭环管理"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <ButtonGroupTab
        :tabData="btnData"
        @tabChange="buttonTabChange"
        :showTag="true"
        ref="buttonGroup"
      />

      <div class="btn-wrap">
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button class="button2" @click="exportExcel">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>
    <div class="tools">
      <div>
        <a-select
          v-model="area_id"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin-right: 10px"
          placeholder="选择大区"
          @change="selectChange('area')"
        >
          <a-select-option
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          style="width: 132px; height: 40px; margin-right: 10px"
          v-model="group_code"
          :suffixIcon="suffixIcon"
          @change="selectChange('group')"
          placeholder="选择分组"
        >
          <a-select-option :value="undefined">不限分组</a-select-option>
          <a-select-option v-for="d in groupList" :key="d.group_code">
            {{ d.group_name }}
          </a-select-option>
        </a-select>
        <a-select
          class="aft-select default"
          v-model="activity_id"
          :suffixIcon="suffixIcon"
          placeholder="选择活动"
          style="width: 196px"
          :allowClear="true"
          @change="activityChange"
        >
          <a-select-option value="">全部活动</a-select-option>
          <a-select-option
            v-for="item in activityList"
            :value="item.id"
            :key="item.id"
            >{{ item.activity_name }}</a-select-option
          >
        </a-select>
      </div>
      <date-tab
        :type="dateType"
        @changeType="changeType"
        @changeDate="searchTimeTable"
        ref="dateTab"
        v-show="!activity_id"
      ></date-tab>
      <span v-show="activity_id" class="time-style">{{
        currentActivityDate
      }}</span>
    </div>
    <div class="content">
      <div class="content-header">
        <span class="font1" style="margin-left: 30px">声量线索</span>
        <span class="font2" v-if="clueCountSum">{{
          clueCountSum.voice_volume_cue_count | changeNum
        }}</span>
        <span class="font1" style="margin-left: 26px">销售线索</span>
        <span class="font2" v-if="clueCountSum">{{
          clueCountSum.sales_cue_count | changeNum
        }}</span>
      </div>
      <div class="table">
        <a-table
          :columns="lTadsV == 1 ? all_columns : all_columns"
          :data-source="tableData"
          :rowKey="(data) => data.dealer_store_code"
          :pagination="false"
          :loading="taleLoading"
          :scroll="{ y: 'calc(100vh - 486px)' }"
          :locale="locale"
          :customRow="openDetail"
          class="aft-table default"
        >
          <div slot="watchTitle">
            {{ currentTab | returnWatchText }}
          </div>

          <div
            slot="customTitle1"
            style="display: flex; justify-content: center"
          >
            <div>视频播放</div>
          </div>
          <!-- <div slot="customTitle2" style="display: flex">
            <div>意向用户人数</div>
          </div> -->
          <div slot="live_total_watch_user_time" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">{{
                currentTab | returnWatchText
              }}</span>
            </div>
          </div>
          <div slot="aweme_play_volume_total_di" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">视频播放</span>
            </div>
          </div>
          <div slot="private_letter_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">私信</span>
            </div>
          </div>
          <div slot="aweme_cue_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">短视频线索</span>
            </div>
          </div>
          <div slot="live_cue_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">直播线索</span>
            </div>
          </div>
          <div slot="voice_volume_cue_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">声量线索</span>
            </div>
          </div>
          <div slot="sales_cue_count" slot-scope="text">
            <div class="td-hover-number">
              <span>{{ text | changeNum }}</span>
              <span class="bottom-title table-show-icon">销售线索</span>
            </div>
          </div>
          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            <span> {{ (current - 1) * pageSize + Number(index) + 1 }}</span>
          </div>
          <div slot="account" slot-scope="text, record" class="table-account">
            <div class="table-account-left">
              <WebpImage
                :src="record.avatar"
                :width="'48px'"
                :height="'48px'"
                :radius="'50%'"
                class="img"
              />
              <!-- <img :src="record.avatar" width="48" height="48" alt="" /> -->
              <div>
                <div>
                  <span class="font-color2 f16">{{ record.dealer_name }}</span>
                </div>
                <div>
                  <span class="font-color3 f12">{{
                    record.dealer_company_name
                  }}</span>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div
            slot="dealer_store_code"
            slot-scope="text"
            class="font-color3 f14"
          >
            <span>{{ text || "-" }}</span>
          </div>

          <!-- <div
            slot="leads_user_id_size"
            slot-scope="text, record"
            class="font-color26 f14"
          >
            <div class="td-hover-box" @click.stop="chartModalClick(record)">
              <span>{{ text | changeNum }}</span>
              <img
                class="table-show-icon"
                src="@/assets/img/chart-tip.png"
                alt=""
                width="20"
                height="20"
                style="margin-left: 10px"
              />
              <img
                class="table-show-icon active"
                src="@/assets/img/chart-tip-active.png"
                alt=""
                width="20"
                height="20"
                style="margin-left: 10px"
              />
            </div>
          </div> -->
          <div slot="action" slot-scope="scope" class="font-color3 f14">
            <a-icon
              type="star"
              class="font-color6 f20"
              theme="filled"
              @click.stop="starClick(scope.dealer_id)"
              v-if="scope.favorite == 1"
            />
            <a-icon
              type="star"
              class="font-color3 f20"
              @click.stop="starClick(scope.dealer_id)"
              v-else
            />
          </div>
        </a-table>
      </div>
    </div>

    <div class="page-div aft-pagination">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="tablePagination.total"
        @change="pageChange"
      />
    </div>
    <!-- 收藏门店弹窗 -->
    <div class="store-modal" v-show="storeModalShow" @click="hideAllModal">
      <div class="store-modal-container" @click.stop>
        <div class="store-modal-container-title">收藏门店</div>
        <div class="list">
          <multiple-group
            :multipleData="multipleData"
            @multipleChange="multipleChange"
          ></multiple-group>
        </div>
        <div class="action-div">
          <div @click="showAddGroup"><span class="icon">+</span> 新建分组</div>
          <span class="btn btn1" @click="closeModal">取消</span>
          <span class="btn btn2" @click="groupBindDealer">确定</span>
        </div>
      </div>
    </div>
    <!-- 新建分组弹窗 -->
    <div class="add-modal" v-show="addModalShow" @click="addModalShow = false">
      <div class="add-modal-container" @click.stop>
        <a-input v-model.trim="addGroupValue" placeholder="输入新建分组名称" />
        <div class="action-div">
          <span
            :class="!!addGroupValue ? '' : 'active'"
            @click="addGroupDealer(0)"
            >取消</span
          >
          <span
            :class="!!addGroupValue ? 'active' : ''"
            @click="addGroupDealer(1)"
            >确定</span
          >
        </div>
      </div>
    </div>
    <!-- 图表弹窗 -->
    <div
      class="chart-modal"
      v-show="chartModalShow"
      @click="chartModalShow = false"
    >
      <div class="chart-modal-container" @click.stop>
        <div class="chart-title">意向用户比例</div>
        <div
          class="chart-div"
          v-show="
            !chartLoading &&
              chartData &&
              chartData.province &&
              !(
                chartData.province.length == 0 && chartData.province.length == 0
              )
          "
        >
          <my-chart
            :options="getPieOption(chartData)"
            :id="'pie-chart'"
            :defaultStyle="{
              width: '678px',
              height: '368px',
            }"
          ></my-chart>
        </div>
        <Empty
          v-show="
            chartData &&
              chartData.province &&
              chartData.province.length == 0 &&
              chartData.province.length == 0 &&
              !chartLoading
          "
          style="margin-top: 100px"
        />
        <div
          v-if="chartLoading"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 300px;
          "
        >
          <a-spin :spinning="chartLoading"></a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "closedLoop"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import DateTab from "@/components/DateTab/DateTab2";
import MultipleGroup from "@/components/MultipleGroup/MultipleGroup";
import ButtonGroupTab from "@/components/PlatformTab/ButtonGroupTab";
import WebpImage from "@/components/WebpImage/WebpImage";
import _http from "@/api/closedLoop";
import Empty from "@/components/Empty/Empty";
import chartPie from "./options/chartPie";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import exportTable from "@/utils/exportTable";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
export default {
  components: {
    PlatformHeader,
    DateTab,
    MultipleGroup,
    ButtonGroupTab,
    WebpImage,
    MyChart,
    Empty,
    ExcelTypeButton,
  },
  data() {
    return {
      excelType: false,
      month: null,
      group_code: undefined,
      area_id: "",
      lTadsV: "1",
      activity_id: undefined,
      tooltipTitle1: "抖音主播账号授权后，可查看对应视频播放数",
      tooltipTitle2: "产生私信、留资、电话拨打等意向互动行为的用户人数(去重)",
      locale: {
        emptyText: <Empty style="margin-top: 140px;" title="暂无数据" />,
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      btnData: [
        {
          label: "全部意向",
          value: "1",
          tag: "ALL",
        },
      ],
      currentTab: "",
      tab: "tab1",
      dateType: "month",
      dealer_id: "",
      all_columns: [
        {
          title: "排名",
          width: "6%",
          scopedSlots: { customRender: "num" },
          align: "center",
        },
        {
          dataIndex: "dealer_company_name",
          title: "门店名称",
          width: "19%",
          scopedSlots: { customRender: "account" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          width: "7%",
          scopedSlots: { customRender: "dealer_store_code" },
          align: "center",
        },
        {
          dataIndex: "live_total_watch_user_time",
          slots: { title: "watchTitle" },
          width: "7%",
          align: "center",
          scopedSlots: { customRender: "live_total_watch_user_time" },
        },
        {
          dataIndex: "aweme_play_volume_total_di",
          slots: { title: "customTitle1" },
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "aweme_play_volume_total_di" },
        },
        {
          dataIndex: "private_letter_count",
          title: "私信",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "private_letter_count" },
        },
        {
          dataIndex: "aweme_cue_count",
          title: "短视频线索",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "aweme_cue_count" },
        },
        {
          dataIndex: "live_cue_count",
          title: "直播线索",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "live_cue_count" },
        },
        {
          dataIndex: "voice_volume_cue_count",
          title: "声量线索",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "voice_volume_cue_count" },
        },
        {
          dataIndex: "sales_cue_count",
          title: "销售线索",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "sales_cue_count" },
        },
        // {
        //   dataIndex: "leads_user_id_size",
        //   slots: { title: "customTitle2" },
        //   width: "11%",
        //   align: "center",
        //   scopedSlots: { customRender: "leads_user_id_size" },
        // },
        {
          key: "action",
          title: "操作",
          width: "9%",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      current: 1,
      pageSize: 20,
      storeModalShow: false,
      addModalShow: false,
      chartModalShow: false,
      chartLoading: false,
      addGroupValue: "",
      currentSelectType: "area", //下拉框互斥标示
      currentActivityDate: "",

      isFirstInit: true,
    };
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activityList,
      areaList: (state) => state.areaList, // 区域列表
      tableData: (state) => state.tableData,
      groupList: (state) => state.groupList,
      taleLoading: (state) => state.taleLoading,
      tablePagination: (state) => state.tablePagination,
      multipleData: (state) => state.multipleData,
      chartData: (state) => state.chartData,
      clueCountSum: (state) => state.clueCountSum,
    }),
  },
  watch: {
    areaList(val) {
      console.log("3456789876543567", val);
      if (!val || val.length <= 0) {
        this.changeState({ data: [], name: "tableData" });
        return false;
      }
      this.area_id = val[0].id;
      this.getTableData();
    },
  },
  created() {
    // this.$nextTick(() => {
    //   this.getAreaList();
    //   // this.getTableData();
    //   this.getSortGroup();
    //   this.getActivityList({
    //     platform: this.currentTab,
    //   });
    // });
  },
  mounted() {},
  methods: {
    ...mapActions([
      "getActivityList",
      "getAreaList",
      "getClosedLoopList",
      "getSortGroup",
      "getDealerGroup",
      "getClosedLoopChart",
      "getClueCountSum",
    ]),
    ...mapMutations(["changeState"]),
    async initData() {
      await this.getAreaList();
      await this.getSortGroup();
    },
    // 跳转门店
    gotoStore() {
      this.$router.push({
        path: "storeSearch",
      });
    },
    // 获取表格数据
    getTableData() {
      if (!this.month) {
        this.getTimeDate();
        setTimeout(() => {
          this.getTableData();
        }, 200);
        return false;
      }
      let rang = this.month;
      console.log("rang", rang);
      let platform = this.currentTab;
      let params = {
        start_time: rang.start_time,
        end_time: rang.end_time,
        current: this.current,
        page_size: this.pageSize,
        group_id: this.group_code || "",
        area_id: this.area_id || "",
        activity_id: this.activity_id,
      };
      this.getClosedLoopList({ params, platform });
      this.getClueCountSum({ params, platform });
    },
    // 时间范围数据处理
    disposeTimeRange(value) {
      let start_time = "";
      let end_time = "";
      if (!!value) {
        start_time = `${this.$moment(value)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(value)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        start_time = moment()
          .month(moment().month())
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        end_time = moment()
          .month(moment().month())
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        this.month = this.$moment()
          .month(moment().month())
          .startOf("month");
      }
      return { start_time, end_time };
    },
    // 直播/视频 tab切换
    buttonTabChange(val) {
      this.lTadsV = val;
      this.current = 1;
      this.getTableData();
    },
    async tabChange(val) {
      this.current = 1;
      this.currentTab = val;
      this.activity_id = undefined;
      if (this.isFirstInit) {
        this.isFirstInit = false;
        await this.initData();
      }
      await this.getActivityList({
        platform: this.currentTab,
      });

      this.getTableData();
    },
    // 下拉框分组切换
    selectChange(type) {
      console.log(type);
      if (type == "area") {
        this.currentSelectType = "area";
        this.group_code = undefined;
      } else {
        this.currentSelectType = "group";
        this.area_id = undefined;
      }
      this.current = 1;
      this.getTableData();
    },
    activityChange(val) {
      this.current = 1;
      let currentItem = this.activityList.find((item) => {
        return item.id == this.activity_id;
      });
      if (val) {
        this.currentActivityDate = `活动时间: ${currentItem.start_time} ~ ${currentItem.end_time}`;
      }
      this.getTableData();
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      this.searchTimeTable();
    },
    // 获取时间组件时间
    getTimeDate() {
      let start_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      this.month = {
        start_time,
        end_time,
      };
    },
    // 时间变更
    searchTimeTable() {
      this.getTimeDate();
      this.getTableData();
    },
    // 页码切换
    pageChange() {
      this.getTableData();
    },
    multipleChange(index) {
      this.multipleData[index].favorite = this.multipleData[index].favorite
        ? 0
        : 1;
    },
    // 关闭分组
    closeModal() {
      this.storeModalShow = false;
    },
    // 获取经销商相关分组
    starClick(id) {
      console.log("dealer_id", id);
      this.dealer_id = id;
      let params = {
        dealer_id: id,
      };
      this.getDealerGroup({ params });
      this.storeModalShow = true;
    },
    hideAllModal() {
      this.storeModalShow = false;
      this.addModalShow = false;
    },
    // 展开添加
    showAddGroup() {
      this.addModalShow = true;
    },
    // 添加分组
    addGroupDealer(type) {
      if (type) {
        if (!this.addGroupValue) {
          this.$message.error("请输入分组！");
          return;
        }
        let params = {
          new_or_copy: 0,
          name: this.addGroupValue,
        };
        _http.addShopGroup(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("新增分组成功！");
            this.starClick(this.dealer_id);
          } else {
            this.$message.error("新增分组失败！");
          }
        });
      } else {
        this.addModalShow = false;
        this.addGroupValue = "";
      }
    },
    // 确认绑定分组
    groupBindDealer() {
      let data = {
        dealer_id: this.dealer_id,
        group_list: this.multipleData,
      };
      _http.addCollectGrounp(data).then((res) => {
        if (res.data.code == 0) {
          this.storeModalShow = false;
          this.$message.success("绑定分组成功！");
          this.getTableData();
        } else {
          this.$message.error("绑定分组失败！");
        }
      });
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 导出Excel
    exportExcel() {
      if (!this.month) {
        this.getTimeDate();
      }
      let rang = this.month;
      let params = {
        start_time: rang.start_time,
        end_time: rang.end_time,
        group_id: this.group_code || "",
        area_id: this.area_id || "",
        flag: this.excelType ? 1 : 0,
        activity_id: this.activity_id,
      };
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/closedLoop/list/export",
        "filename"
      );
    },

    openDetail(record, index) {
      return {
        on: {
          click: () => {
            window.open(
              `/dealer/dealerDetail?id=${record.dealer_id}&platform=${this.currentTab}`
            );
          },
        },
      };
    },
    async chartModalClick(item) {
      this.chartModalShow = true;
      console.log(item);

      if (!this.month) {
        this.getTimeDate();
      }
      let rang = this.month;
      let platform = this.currentTab;
      let params = {
        start_time: rang.start_time,
        end_time: rang.end_time,
        dealer_id: item.dealer_id,
      };
      this.chartLoading = true;
      await this.getClosedLoopChart({ params, platform });
      console.log("返回了", this.chartData);
      this.chartLoading = false;
    },
    // 饼图 set option
    getPieOption(data) {
      if (!data) {
        return false;
      }
      let option = deepClone(chartPie);
      option.series[0].data = data.province;
      option.series[1].data = data.city;
      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .tools {
    padding: 0 20px;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    .time-style {
      line-height: 40px;
      font-size: 14px;
      @include font_color("font_color26");
    }
    .btn-wrap {
      display: flex;
      align-items: center;
    }
    .button2 {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }
  .tools2 {
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .content {
    // padding: 0 24px;
    border-radius: 16px;
    width: calc(100% - 48px);
    margin-left: 24px;
    height: calc(100vh - 356px);
    overflow-y: hidden;
    @include background_color("background_color50");
    &-header {
      width: 100%;
      height: 72px;
      border-radius: 16px 16px 0 0;
      @include background_color("background_color50");
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .font1 {
        font-size: 14px;
        @include font_color("font_color22");
      }
      .font2 {
        font-size: 14px;
        font-weight: bold;
        @include font_color("font_color26");
      }
    }
    .table {
      height: calc(100vh - 426px);
      overflow-y: auto;
      &-account {
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        &-left {
          display: flex;
          flex-direction: row;
          @include font_color("font_color3");
          > .img {
            border-radius: 50%;
            @include border_color("border_color6");
          }
          > div {
            margin-left: 8px;
            > div {
              width: 160px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }
      }

      .td-hover-number {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        > span {
          font-size: 14px;
          font-weight: bold;
          @include font_color("font_color21");
        }
        > .bottom-title {
          font-size: 12px;
          @include font_color("font_color23");
          margin-top: 4px;
        }
      }
      .td-hover-box {
        padding: 30px 0;
      }
      .td-hover-box:hover {
        .table-show-icon {
          display: none;
        }
        .table-show-icon.active {
          display: inline-block;
        }
      }
    }
  }
  .page-div {
    text-align: right;
    padding: 20px 24px 20px 0;
  }
  .store-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    z-index: 100;
    &-container {
      position: absolute;
      right: 24px;
      bottom: 115px;
      width: 272px;
      height: 412px;
      border-radius: 4px;
      @include background_color("background_color27");
      @include font_color("font_color2");
      font-size: 14px;
      &-title {
        font-size: 16px;
        padding: 24px 0 20px;
        text-align: center;
      }
      .list {
        padding: 0 32px;
        height: 280px;
        overflow-y: auto;
      }
      .action-div {
        padding: 16px 12px 16px 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        > div {
          cursor: pointer;
          height: 32px;
          line-height: 18px;
          @include font_color("font_color2");
          .icon {
            font-size: 30px;
            @include font_color("font_color4");
            position: relative;
            top: 3px;
          }
        }
        .btn {
          width: 60px;
          height: 32px;
          border-radius: 4px;
          @include background_color("background_color5");
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          cursor: pointer;
        }
        .btn1 {
          @include font_color("font_color3");
          margin: 0 8px 0 20px;
        }
        .btn2 {
          @include font_color("font_color1");
        }
      }
    }
  }
  .add-modal {
    position: absolute;
    right: 24px;
    bottom: 115px;
    width: 272px;
    height: 412px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0);
    z-index: 110;
    &-container {
      position: absolute;
      bottom: 0;
      left: 0;
      @include background_color("background_color29");
      width: 100%;
      height: 116px;
      padding: 24px;
      .action-div {
        display: flex;
        justify-content: space-around;
        > span {
          padding: 16px;
          margin-top: 8px;
          cursor: pointer;
          @include font_color("font_color3");
        }
        > span.active {
          @include font_color("font_color2");
        }
      }
    }
  }
  .chart-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    z-index: 100;
    &-container {
      position: absolute;
      border-radius: 16px;
      top: 308px;
      right: 20%;
      @include background_color("background_color51");
      width: 726px;
      height: 438px;
      padding: 24px;
      @include box_shadow("box_shadow4");
      .chart-title {
        text-align: center;
        font-size: 16px;
        @include font_color("font_color1");
      }
    }
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .font-color26 {
    @include font_color("font_color26");
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .f20 {
    font-size: 20px;
  }
}
.badge_box {
  display: flex;
  margin-left: 10px;
  li {
    margin-right: 8px;
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/_handle.scss";
.ant-badge-dot {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.tooltip_name {
  .ant-tooltip-inner {
    @include background_color("background_color28");
    font-size: 12px;
    @include font_color("font_color1");
  }
  .ant-tooltip-arrow::before {
    // 这里是小三角形
    @include background_color("background_color28");
  }
}
</style>
