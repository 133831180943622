export const accountTypeList = [
  {
    label: '全部账号类型',
    value: ''
  },
  {
    label: '蓝V认证账号',
    value: 1
  },
  {
    label: '非蓝V认证账号',
    value: 2
  }
]

export const businessTypeList = [
  {
    label: '全部业务类型',
    value: ''
  },
  {
    label: '二手车',
    value: '4'
  },
  {
    label: '大区号',
    value: '30'
  }
]
