<template>
  <div class="container">
    <div class="header">
      <div class="title">活动复盘</div>
      <div class="detail">
        <div class="platform" v-if="platform == 'douyin'">
          <svg-icon icon-class="platform-dy" class="icon" />
        </div>
        <div class="platform" v-else-if="platform == 'kuaishou'">
          <svg-icon icon-class="platform-ks" class="icon" />
        </div>
        <div class="platform" v-else>
          <svg-icon icon-class="platform-dcd" class="icon" />
        </div>
        <div class="name">{{ activity_name }}</div>
      </div>
      <div class="time">
        <span>{{ setTitleDate(start_time, 1) }}</span
        ><span>至{{ setTitleDate(end_time, 1) }}</span>
      </div>
    </div>
    <div class="card-list-wrap">
      <div class="card-box">
        <div class="thead">
          <div class="title yellow">参与门店</div>
          <div class="title yellow">账号数</div>
        </div>
        <div class="tbody">
          <div class="data">
            <span>{{ basicInfoData.dealer_count | formatValue }}</span
            >{{ basicInfoData.dealer_count | formatUnit }}
          </div>
          <div class="num">
            <span>{{ basicInfoData.author_count | formatValue }}</span
            >{{ basicInfoData.author_count | formatUnit }}
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="thead">
          <div class="title yellow">活动涨粉</div>
          <div class="title">累计粉丝</div>
        </div>
        <div class="tbody">
          <div class="data">
            <span>{{ basicInfoData.activity_fans_add | formatValue }}</span
            >{{ basicInfoData.activity_fans_add | formatUnit }}
          </div>
          <div class="num">
            <span>{{ basicInfoData.total_fans_count | formatValue }}</span
            >{{ basicInfoData.total_fans_count | formatUnit }}
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="thead">
          <div class="title yellow">视频总播放</div>
          <div class="title">视频发布量</div>
        </div>
        <div class="tbody">
          <div class="data">
            <span>{{ basicInfoData.aweme_play_count | formatValue }}</span
            >{{ basicInfoData.aweme_play_count | formatUnit }}
          </div>
          <div class="num">
            <span>{{ basicInfoData.aweme_publish_count | formatValue }}</span
            >{{ basicInfoData.aweme_publish_count | formatUnit }}
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="thead">
          <div class="title yellow">直播观看量</div>
          <div class="title">直播场次</div>
        </div>
        <div class="tbody">
          <div class="data">
            <span>{{ basicInfoData.live_watch_count | formatValue }}</span
            >{{ basicInfoData.live_watch_count | formatUnit }}
          </div>
          <div class="num">
            <span>{{ basicInfoData.live_count | formatValue }}</span
            >{{ basicInfoData.live_count | formatUnit }}
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="thead">
          <div class="title yellow">销售线索</div>
          <div class="title">声量线索</div>
        </div>
        <div class="tbody">
          <div class="data">
            <span>{{ basicInfoData.leads_count | formatValue }}</span
            >{{ basicInfoData.leads_count | formatUnit }}
          </div>
          <div class="num">
            <span>{{ basicInfoData.clue_count | formatValue }}</span
            >{{ basicInfoData.clue_count | formatUnit }}
          </div>
        </div>
      </div>
    </div>
    <div class="tools">
      <Order
        :show-title="false"
        :order-list="tabsList"
        :order-index="orderIndex"
        @order-click="handleOrderClick"
        style="position: relative; left: -16px"
      />
      <div>
        <a-select
          v-model="regionValue"
          :suffixIcon="suffixIcon"
          style="width: 126px; height: 40px"
          placeholder="选择大区"
          @change="onChangeRegion"
        >
          <a-select-option key=""> 不限大区 </a-select-option>
          <a-select-option
            :value="item.id"
            v-for="item in regionData"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="tab-content">
      <div class="video-content" v-if="orderIndex == 0">
        <div class="row row-1">
          <div class="card">
            <div class="title-box">
              <div class="title">
                <span>概览</span
                ><AftTooltip :code="'hfqt01'" style="top: -3px; left: 6px" />
              </div>
            </div>
            <div class="tab-box tab-box-1">
              <div
                :class="['box', awemeOverviewActive == 1 ? 'active' : null]"
                @click="awemeOverviewClick(1)"
              >
                <div class="title"><span>总播放量</span></div>
                <div class="num">
                  <span>{{ awemeOverviewData.play_volume | formatValue }}</span
                  >{{ awemeOverviewData.play_volume | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', awemeOverviewActive == 2 ? 'active' : null]"
                @click="awemeOverviewClick(2)"
              >
                <div class="title"><span>视频数</span></div>
                <div class="num">
                  <span>{{ awemeOverviewData.aweme_count | formatValue }}</span
                  >{{ awemeOverviewData.aweme_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', awemeOverviewActive == 3 ? 'active' : null]"
                @click="awemeOverviewClick(3)"
              >
                <div class="title">
                  <span>发布门店数</span>
                  <AftTooltip :code="'hfsp01'" style="top: 1px; left: 4px" />
                </div>
                <div class="num">
                  <span>{{ awemeOverviewData.dealer_count | formatValue }}</span
                  >{{ awemeOverviewData.dealer_count | formatUnit }}
                </div>
              </div>
            </div>
            <div class="chart-box">
              <my-chart
                :options="getLineOption('play_line_1')"
                :id="'play-line-chart-1'"
              ></my-chart>
            </div>
          </div>
          <div class="card">
            <div class="title-box">
              <div class="title">
                <span>爆款短视频</span>
              </div>
              <div>
                <a-select
                  v-model="hotAwemeType"
                  :suffixIcon="suffixIcon"
                  style="width: 126px; height: 40px"
                  placeholder="播放量"
                  class="tab-select"
                  @change="onChangeHotAwemeType"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in hotAwemeTypeList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="tab-box tab-box-2">
              <div
                :class="['box', hotAwemeActive == 1 ? 'active' : null]"
                @click="hotAwemeClick(1)"
                style="padding-right: 10px"
              >
                <div class="title">
                  <span>爆款视频数</span>
                </div>
                <div class="num">
                  <span>{{ hotAwemeData.aweme_count | formatValue }}</span
                  >{{ hotAwemeData.aweme_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', hotAwemeActive == 2 ? 'active' : null]"
                @click="hotAwemeClick(2)"
              >
                <div class="title">
                  <span>播放量</span>
                </div>
                <div class="num">
                  <span>{{ hotAwemeData.play_volume | formatValue }}</span
                  >{{ hotAwemeData.play_volume | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', hotAwemeActive == 3 ? 'active' : null]"
                @click="hotAwemeClick(3)"
              >
                <div class="title">
                  <span>点赞量</span>
                </div>
                <div class="num">
                  <span>{{ hotAwemeData.digg_count | formatValue }}</span
                  >{{ hotAwemeData.digg_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', hotAwemeActive == 4 ? 'active' : null]"
                @click="hotAwemeClick(4)"
              >
                <div class="title">
                  <span>评论量</span>
                </div>
                <div class="num">
                  <span>{{ hotAwemeData.comment_count | formatValue }}</span
                  >{{ hotAwemeData.comment_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', hotAwemeActive == 5 ? 'active' : null]"
                @click="hotAwemeClick(5)"
              >
                <div class="title">
                  <span>转发量</span>
                </div>
                <div class="num">
                  <span>{{ hotAwemeData.share_count | formatValue }}</span
                  >{{ hotAwemeData.share_count | formatUnit }}
                </div>
              </div>
            </div>
            <div class="chart-box">
              <my-chart
                :options="getLineOption('play_line_2')"
                :id="'play-line-chart-2'"
              ></my-chart>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card">
            <div class="title-box">
              <div class="title">
                <span>视频排行</span>
              </div>
            </div>
            <div class="list-box" v-if="awemeRankListData.length > 0">
              <div
                class="item"
                v-for="(item, index) in awemeRankListData"
                :key="index"
                @click="handleVideoItemClick(item)"
              >
                <WebpImage
                  :src="item.aweme_cover || ''"
                  :width="'84px'"
                  :height="'112px'"
                  :radius="'8px'"
                />
                <div class="content">
                  <div class="title">{{ item.aweme_title || "-" }}</div>
                  <div class="detail">
                    <WebpImage
                      :src="item.avatar || ''"
                      :width="'20px'"
                      :height="'20px'"
                      :radius="'10px'"
                      class="avatar"
                    />
                    <div class="name">{{ item.nickname || "-" }}</div>
                  </div>
                  <div class="data">
                    <div class="col">
                      <div class="name">营销价值</div>
                      <div class="num linght">
                        <span>￥{{ item.marketing_value || 0 }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">视频播放</div>
                      <div class="num">
                        <span v-if="item.play_volume">{{
                          item.play_volume | formatValue
                        }}</span
                        ><span v-else>-</span
                        >{{ item.play_volume | formatUnit }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">视频互动</div>
                      <div class="num">
                        <span>{{
                          (Number(item.digg_count) +
                            Number(item.comment_count) +
                            Number(item.share_count))
                            | formatValue
                        }}</span
                        >{{
                          (Number(item.digg_count) +
                            Number(item.comment_count) +
                            Number(item.share_count))
                            | formatUnit
                        }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">发布时间</div>
                      <div class="num">
                        <span>{{ item.publish_time | formatDate }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">视频时长</div>
                      <div class="num">
                        <span>{{ item.duration }}</span
                        >s
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">视频点赞</div>
                      <div class="num">
                        <span>{{ item.digg_count | formatValue }}</span
                        >{{ item.digg_count | formatUnit }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">视频评论</div>
                      <div class="num">
                        <span>{{ item.comment_count | formatValue }}</span
                        >{{ item.comment_count | formatUnit }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">视频转发</div>
                      <div class="num">
                        <span>{{ item.share_count | formatValue }}</span
                        >{{ item.share_count | formatUnit }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Empty v-else style="padding: 60px 0" title="暂无数据" />
          </div>
        </div>
      </div>
      <div class="live-content" v-else-if="orderIndex == 1">
        <div class="row row-1">
          <div class="card">
            <div class="title-box">
              <div class="title">
                <span>概览</span>
              </div>
            </div>
            <div class="tab-box tab-box-1">
              <div
                :class="['box', liveOverviewActive == 1 ? 'active' : null]"
                @click="liveOverviewClick(1)"
              >
                <div class="title">
                  <span>观看量</span>
                </div>
                <div class="num">
                  <span>{{ liveOverviewData.watch_volume | formatValue }}</span
                  >{{ liveOverviewData.watch_volume | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', liveOverviewActive == 2 ? 'active' : null]"
                @click="liveOverviewClick(2)"
              >
                <div class="title">
                  <span>直播数</span>
                </div>
                <div class="num">
                  <span>{{ liveOverviewData.live_count | formatValue }}</span
                  >{{ liveOverviewData.live_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', liveOverviewActive == 3 ? 'active' : null]"
                @click="liveOverviewClick(3)"
                v-if="platform != 'kuaishou'"
              >
                <div class="title">
                  <span>直播增粉</span>
                </div>
                <div class="num">
                  <span>{{
                    liveOverviewData.fans_add_count | formatValue
                  }}</span
                  >{{ liveOverviewData.fans_add_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', liveOverviewActive == 4 ? 'active' : null]"
                @click="liveOverviewClick(4)"
              >
                <div class="title">
                  <span>发布门店数</span>
                </div>
                <div class="num">
                  <span>{{ liveOverviewData.dealer_count | formatValue }}</span
                  >{{ liveOverviewData.dealer_count | formatUnit }}
                </div>
              </div>
            </div>
            <div class="chart-box">
              <my-chart
                :options="getLineOption('live_line_1')"
                :id="'live-line-chart-1'"
              ></my-chart>
            </div>
          </div>
          <div class="card">
            <div class="title-box">
              <div class="title">
                <span>爆款直播</span>
              </div>
              <div>
                <a-select
                  v-model="hotLiveType"
                  :suffixIcon="suffixIcon"
                  style="width: 126px; height: 40px"
                  placeholder="播放量"
                  class="tab-select"
                  @change="onChangeHotLiveType"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in hotLiveTypeList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="tab-box tab-box-3">
              <div
                :class="['box', hotLiveActive == 1 ? 'active' : null]"
                @click="hotLiveClick(1)"
              >
                <div class="title">
                  <span>观看量</span>
                </div>
                <div class="num">
                  <span>{{ hotLiveData.watch_volume | formatValue }}</span
                  >{{ hotLiveData.watch_volume | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', hotLiveActive == 2 ? 'active' : null]"
                @click="hotLiveClick(2)"
              >
                <div class="title">
                  <span>直播数</span>
                </div>
                <div class="num">
                  <span>{{ hotLiveData.live_count | formatValue }}</span
                  >{{ hotLiveData.live_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', hotLiveActive == 3 ? 'active' : null]"
                @click="hotLiveClick(3)"
              >
                <div class="title">
                  <span>点赞量</span>
                </div>
                <div class="num">
                  <span>{{ hotLiveData.digg_count | formatValue }}</span
                  >{{ hotLiveData.digg_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', hotLiveActive == 4 ? 'active' : null]"
                @click="hotLiveClick(4)"
              >
                <div class="title">
                  <span>评论量</span>
                </div>
                <div class="num">
                  <span>{{ hotLiveData.comment_count | formatValue }}</span
                  >{{ hotLiveData.comment_count | formatUnit }}
                </div>
              </div>
              <div
                :class="['box', hotLiveActive == 5 ? 'active' : null]"
                @click="hotLiveClick(5)"
                v-if="platform != 'kuaishou'"
              >
                <div class="title">
                  <span>直播增粉</span>
                </div>
                <div class="num">
                  <span>{{ hotLiveData.fans_add_count | formatValue }}</span
                  >{{ hotLiveData.fans_add_count | formatUnit }}
                </div>
              </div>
            </div>
            <div class="chart-box">
              <my-chart
                :options="getLineOption('live_line_2')"
                :id="'live-line-chart-2'"
              ></my-chart>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card">
            <div class="title-box">
              <div class="title">
                <span>直播排行</span>
              </div>
            </div>
            <div class="list-box" v-if="liveRankListData.length > 0">
              <div
                class="item"
                v-for="(item, index) in liveRankListData"
                :key="index"
                @click="handleLiveItemClick(item)"
              >
                <WebpImage
                  :src="item.cover || ''"
                  :width="'84px'"
                  :height="'112px'"
                  :radius="'8px'"
                />
                <div class="content">
                  <div class="title">{{ item.title || "-" }}</div>
                  <div class="detail">
                    <WebpImage
                      :src="item.avatar || ''"
                      :width="'20px'"
                      :height="'20px'"
                      :radius="'10px'"
                      class="avatar"
                    />
                    <div class="name">{{ item.nickname || "-" }}</div>
                  </div>
                  <div class="data">
                    <div class="col">
                      <div class="name">营销价值</div>
                      <div class="num linght">
                        <span>￥{{ item.marketing_value || 0 }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">观看人次</div>
                      <div class="num">
                        <span>{{
                          item.statistics_total_watch_user_time | formatValue
                        }}</span
                        >{{
                          item.statistics_total_watch_user_time | formatUnit
                        }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">开播时间</div>
                      <div class="num">
                        <span>{{ item.publish_time | formatDate }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">直播时长</div>
                      <div class="num">
                        <span>{{
                          item.statistics_live_time | disposeLongTime_m
                        }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">在线峰值</div>
                      <div class="num">
                        <span>{{
                          item.statistics_max_online_user | formatValue
                        }}</span
                        >{{ item.statistics_max_online_user | formatUnit }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">集赞总数</div>
                      <div class="num">
                        <span>{{
                          item.statistics_total_like_count | formatValue
                        }}</span
                        >{{ item.statistics_total_like_count | formatUnit }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">评论总数</div>
                      <div class="num">
                        <span>{{
                          item.statistics_total_comment_count | formatValue
                        }}</span
                        >{{ item.statistics_total_comment_count | formatUnit }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="name">单场增粉</div>
                      <div class="num" v-if="platform == 'kuaishou'">
                        <span>-</span>
                      </div>
                      <div class="num" v-else>
                        <span>{{ item.statistics_fans_add | formatValue }}</span
                        >{{ item.statistics_fans_add | formatUnit }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dealer-content" v-else-if="orderIndex == 2">
        <div class="row">
          <div class="card">
            <div class="title-box">
              <div class="title"><span>经销商贡献</span></div>
            </div>
            <div class="dealer-list">
              <div class="item">
                <div class="title-box">
                  <div class="title">
                    <svg-icon icon-class="user" class="icon" /><span>增粉</span>
                  </div>
                </div>
                <div class="chart-box">
                  <my-chart
                    :options="
                      getPieOption(dealerContributionData.fans_increase)
                    "
                    :id="'pie-chart-1'"
                  ></my-chart>
                </div>
              </div>
              <div class="item">
                <div class="title-box">
                  <div class="title">
                    <svg-icon icon-class="eye4" class="icon" /><span
                      >直播观看</span
                    >
                  </div>
                </div>
                <div class="chart-box">
                  <my-chart
                    :options="getPieOption(dealerContributionData.live_watch)"
                    :id="'pie-chart-2'"
                  ></my-chart>
                </div>
              </div>
              <div class="item">
                <div class="title-box">
                  <div class="title">
                    <svg-icon icon-class="type-video-1" class="icon" /><span
                      >视频播放</span
                    >
                  </div>
                </div>
                <div class="chart-box">
                  <my-chart
                    :options="getPieOption(dealerContributionData.aweme_play)"
                    :id="'pie-chart-3'"
                  ></my-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-2">
          <div class="col card">
            <div class="title-box">
              <div class="title">
                <span class="yellow">最佳门店</span
                ><AftTooltip :code="'hfqt02'" style="top: -2px; left: 6px" />
              </div>
            </div>
            <div class="hot-list-box" v-if="dealerBestListData.length > 0">
              <div
                class="item"
                v-for="(item, index) in dealerBestListData"
                :key="index"
                @click="onClickDealerBest(index)"
              >
                <div class="avatar"></div>
                <WebpImage
                  :src="item.avatar"
                  :width="'54px'"
                  :height="'54px'"
                  :radius="'27px'"
                />
                <div class="content">
                  <p class="title">{{ item.dealer_name || "-" }}</p>
                  <p class="txt">
                    共发布<span
                      ><em>{{ item.aweme_count | formatValue }}</em
                      >{{ item.aweme_count | formatUnit }}条</span
                    >视频
                  </p>
                  <p class="txt">
                    收获<span
                      ><em>{{ item.aweme_play_count | formatValue }}</em
                      >{{ item.aweme_play_count | formatUnit }}</span
                    >观看
                    <!-- <span
                      ><em>{{ item.leads_information_count | formatValue }}</em
                      >{{ item.leads_information_count | formatUnit }}条</span
                    >销售线索 -->
                  </p>
                </div>
              </div>
            </div>
            <Empty v-else style="padding-top: 140px" title="暂无数据" />
          </div>
          <div class="col">
            <div class="card">
              <div class="title-box">
                <div class="title">
                  <span class="yellow">最佳直播</span
                  ><AftTooltip :code="'hfqt03'" style="top: -2px; left: 6px" />
                </div>
              </div>
              <div class="top-list-box">
                <div
                  class="item"
                  @click="handleLiveItemClick(dealerTopListData.best_live)"
                >
                  <WebpImage
                    :src="dealerTopListData.best_live.cover || ''"
                    :width="'84px'"
                    :height="'112px'"
                    :radius="'8px'"
                  />
                  <div class="content">
                    <div class="title">
                      {{ dealerTopListData.best_live.title || "-" }}
                    </div>
                    <div class="detail">
                      <WebpImage
                        :src="dealerTopListData.best_live.avatar || ''"
                        :width="'20px'"
                        :height="'20px'"
                        :radius="'10px'"
                        class="avatar"
                      />
                      <div class="name">
                        {{ dealerTopListData.best_live.dealer_name || "-" }}
                      </div>
                    </div>
                    <div class="data">
                      <div class="col">
                        <div class="name">直播观看：</div>
                        <div class="num">
                          <span>{{
                            dealerTopListData.best_live.watch_count
                              | formatValue
                          }}</span
                          >{{
                            dealerTopListData.best_live.watch_count | formatUnit
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="title-box">
                <div class="title">
                  <span class="yellow">最佳视频</span
                  ><AftTooltip :code="'hfqt04'" style="top: -2px; left: 6px" />
                </div>
              </div>
              <div class="top-list-box">
                <div
                  class="item"
                  @click="handleVideoItemClick(dealerTopListData.best_aweme)"
                >
                  <WebpImage
                    :src="dealerTopListData.best_aweme.cover || ''"
                    :width="'84px'"
                    :height="'112px'"
                    :radius="'8px'"
                  />
                  <div class="content">
                    <div class="title">
                      {{ dealerTopListData.best_aweme.title || "-" }}
                    </div>
                    <div class="detail">
                      <WebpImage
                        :src="dealerTopListData.best_aweme.avatar || ''"
                        :width="'20px'"
                        :height="'20px'"
                        :radius="'10px'"
                        class="avatar"
                      />
                      <div class="name">
                        {{ dealerTopListData.best_aweme.dealer_name || "-" }}
                      </div>
                    </div>
                    <div class="data">
                      <div class="col">
                        <div class="name">播放：</div>
                        <div class="num">
                          <span>{{
                            dealerTopListData.best_aweme.play_count
                              | formatValue
                          }}</span
                          >{{
                            dealerTopListData.best_aweme.play_count | formatUnit
                          }}
                        </div>
                      </div>
                      <div class="col">
                        <div class="name">点赞：</div>
                        <div class="num">
                          <span>{{
                            dealerTopListData.best_aweme.digg_count
                              | formatValue
                          }}</span
                          >{{
                            dealerTopListData.best_aweme.digg_count | formatUnit
                          }}
                        </div>
                      </div>
                      <div class="col">
                        <div class="name">评论：</div>
                        <div class="num">
                          <span>{{
                            dealerTopListData.best_aweme.comment_count
                              | formatValue
                          }}</span
                          >{{
                            dealerTopListData.best_aweme.comment_count
                              | formatUnit
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="title-box">
                <div class="title">
                  <span class="yellow">涨粉最多（直播）</span
                  ><AftTooltip :code="'hfqt05'" style="top: -2px; left: 6px" />
                </div>
              </div>
              <div class="top-list-box">
                <div
                  class="item"
                  @click="
                    handleLiveItemClick(
                      dealerTopListData.increase_most_fans_live
                    )
                  "
                >
                  <WebpImage
                    :src="dealerTopListData.increase_most_fans_live.cover || ''"
                    :width="'84px'"
                    :height="'112px'"
                    :radius="'8px'"
                  />
                  <div class="content">
                    <div class="title">
                      {{
                        dealerTopListData.increase_most_fans_live.title || "-"
                      }}
                    </div>
                    <div class="detail">
                      <WebpImage
                        :src="
                          dealerTopListData.increase_most_fans_live.avatar || ''
                        "
                        :width="'20px'"
                        :height="'20px'"
                        :radius="'10px'"
                        class="avatar"
                      />
                      <div class="name">
                        {{
                          dealerTopListData.increase_most_fans_live
                            .dealer_name || "-"
                        }}
                      </div>
                    </div>
                    <div class="data">
                      <div class="col">
                        <div class="name">直播观看：</div>
                        <div class="num" v-if="platform == 'kuaishou'">
                          <span>-</span>
                        </div>
                        <div class="num" v-else>
                          <span>{{
                            dealerTopListData.increase_most_fans_live
                              .watch_count | formatValue
                          }}</span
                          >{{
                            dealerTopListData.increase_most_fans_live
                              .watch_count | formatUnit
                          }}
                        </div>
                      </div>
                      <div class="col">
                        <div class="name">增粉：</div>
                        <div class="num" v-if="platform == 'kuaishou'">
                          <span>-</span>
                        </div>
                        <div class="num" v-else>
                          <span>{{
                            dealerTopListData.increase_most_fans_live
                              .fans_increase | formatValue
                          }}</span
                          >{{
                            dealerTopListData.increase_most_fans_live
                              .fans_increase | formatUnit
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="title-box">
                <div class="title">
                  <span class="yellow">传播最快（视频）</span
                  ><AftTooltip :code="'hfqt06'" style="top: -2px; left: 6px" />
                </div>
              </div>
              <div class="top-list-box">
                <div
                  class="item"
                  @click="
                    handleVideoItemClick(dealerTopListData.spread_fastest_aweme)
                  "
                >
                  <WebpImage
                    :src="dealerTopListData.spread_fastest_aweme.cover || ''"
                    :width="'84px'"
                    :height="'112px'"
                    :radius="'8px'"
                  />
                  <div class="content">
                    <div class="title">
                      {{ dealerTopListData.spread_fastest_aweme.title || "-" }}
                    </div>
                    <div class="detail">
                      <WebpImage
                        :src="
                          dealerTopListData.spread_fastest_aweme.avatar || ''
                        "
                        :width="'20px'"
                        :height="'20px'"
                        :radius="'10px'"
                        class="avatar"
                      />
                      <div class="name">
                        {{
                          dealerTopListData.spread_fastest_aweme.dealer_name ||
                          "-"
                        }}
                      </div>
                    </div>
                    <div class="data">
                      <div class="col">
                        <div class="name">单日最高：</div>
                        <div class="num">
                          <span>{{
                            dealerTopListData.spread_fastest_aweme.play_count_di
                              | formatValue
                          }}</span
                          >{{
                            dealerTopListData.spread_fastest_aweme.play_count_di
                              | formatUnit
                          }}
                        </div>
                      </div>
                      <div class="col">
                        <div class="name">总播放量：</div>
                        <div class="num">
                          <span>{{
                            dealerTopListData.spread_fastest_aweme.play_count
                              | formatValue
                          }}</span
                          >{{
                            dealerTopListData.spread_fastest_aweme.play_count
                              | formatUnit
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("activityReview");
import Order from "@/pages/brand/components/Order";
import _ from "lodash";
import Tabs from "@/components/PlatformTab/Tabs";
import Tooltip from "@/components/Tooltip/Tooltip";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartLine from "./options/chartLine.js";
import chartPie from "./options/chartPie.js";
import echarts from "echarts";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";

export default {
  components: {
    Order,
    Tabs,
    Tooltip,
    MyChart,
    WebpImage,
    Empty,
  },
  filters: {
    formatValue(num) {
      if (typeof num != "number") {
        return "-";
      }
      if (num) {
        if (Math.abs(num) >= 100000000) {
          num = (num / 100000000).toFixed(1);
        } else if (Math.abs(num) >= 10000) {
          num = (num / 10000).toFixed(1);
        }
        return num;
      } else {
        return 0;
      }
    },
    formatUnit(num) {
      let unit = "";
      if (num) {
        if (Math.abs(num) >= 100000000) {
          unit = "亿";
        } else if (Math.abs(num) >= 10000) {
          unit = "w";
        }
      }
      return unit;
    },
  },
  data() {
    return {
      activity_name: this.$route.query.name,
      activity_id: this.$route.query.id,
      platform: this.$route.query.platform || "douyin",
      start_time: this.$route.query.start_time,
      end_time: this.$route.query.end_time,
      tabsList: [
        {
          text: "视频表现",
          value: "0",
          field: 0,
        },
        {
          text: "直播表现",
          value: "1",
          field: 1,
        },
        {
          text: "经销商表现",
          value: "2",
          field: 2,
        },
      ],
      regionValue: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      orderIndex: 0,

      awemeOverviewActive: 1,
      awemeOverviewChartData: [],
      hotAwemeActive: 1,
      hotAwemeChartData: [],
      hotAwemeType: 1,
      hotAwemeTypeList: [
        { id: 1, name: "播放量>5万" },
        { id: 2, name: "播放量>10万" },
        { id: 3, name: "播放量>50万" },
        { id: 4, name: "播放量>100万" },
      ],

      liveOverviewActive: 1,
      liveOverviewChartData: [],
      hotLiveActive: 1,
      hotLiveChartData: [],
      hotLiveType: 1,
      hotLiveTypeList: [
        { id: 1, name: "观看量>1万" },
        { id: 2, name: "观看量>3万" },
        { id: 3, name: "观看量>5万" },
        { id: 4, name: "观看量>10万" },
        { id: 5, name: "观看量>30万" },
      ],

      dealerBestActive: 0,
    };
  },
  computed: {
    ...mapState({
      regionData: (state) => state.regionData, //区域

      basicInfoData: (state) => state.basicInfoData, //基础信息

      awemeOverviewData: (state) => state.awemeOverviewData, //视频概览,
      hotAwemeData: (state) => state.hotAwemeData, //爆款视频,
      awemeRankListData: (state) => state.awemeRankListData, //视频排行,

      liveOverviewData: (state) => state.liveOverviewData, //直播概览,
      hotLiveData: (state) => state.hotLiveData, //爆款直播,
      liveRankListData: (state) => state.liveRankListData, //直播排行,

      dealerContributionData: (state) => state.dealerContributionData, //经销商贡献,
      dealerBestListData: (state) => state.dealerBestListData, //经销商最佳门店
      dealerTopListData: (state) => state.dealerTopListData, //经销商最佳
    }),
  },
  created() {
    this.getAreaList();
    this.getBasicData();
    this.getAwemeData();
    // this.getLiveData()
    // this.getDealerData()
  },
  mounted() {},
  methods: {
    ...mapActions([
      "getAreaList",
      "getBasicInfo",
      "getAwemeOverview",
      "getAwemeRankList",
      "getHotAweme",
      "getLiveOverview",
      "getLiveRankList",
      "getHotLive",
      "getDealerContribution",
      "getDealerBest",
      "getDealerTop",
    ]),
    ...mapMutations(["changeState"]),
    handleOrderClick(res) {
      this.orderIndex = res.index;
      switch (this.orderIndex) {
        case 0:
          this.getAwemeData();
          break;
        case 1:
          this.getLiveData();
          break;
        case 2:
          this.getDealerData();
          break;
      }
    },
    onChangeRegion() {
      switch (this.orderIndex) {
        case 0:
          this.getAwemeData();
          break;
        case 1:
          this.getLiveData();
          break;
        case 2:
          this.getDealerData();
          break;
      }
    },
    getBasicData() {
      let data = {
        params: {
          activity_id: this.activity_id,
        },
        platform: this.platform,
      };
      this.getBasicInfo(data);
    },
    //视频表现 - 数据
    getAwemeData() {
      let data = {
        params: {
          activity_id: this.activity_id,
          area_id: this.regionValue,
        },
        platform: this.platform,
      };

      this.getAwemeOverview(data).then(() => {
        this.awemeOverviewClick(this.awemeOverviewActive);
      });

      this.getHotAweme({
        params: {
          activity_id: this.activity_id,
          area_id: this.regionValue,
          type: this.hotAwemeType,
        },
        platform: this.platform,
      }).then(() => {
        this.hotAwemeClick(this.hotAwemeActive);
      });

      this.getAwemeRankList(data);
    },
    //视频表现 - 概览切换
    awemeOverviewClick(type) {
      let data = [];
      switch (type) {
        case 1:
          data = this.awemeOverviewData.play_volume_action_day || [];
          break;
        case 2:
          data = this.awemeOverviewData.aweme_count_action_day || [];
          break;
        case 3:
          data = this.awemeOverviewData.dealer_count_action_day || [];
          break;
      }
      this.awemeOverviewChartData = data;
      this.awemeOverviewActive = type;
    },
    //视频表现 - 爆款短视频
    hotAwemeClick(type) {
      let data = [];
      switch (type) {
        case 1:
          data = this.hotAwemeData.hot_aweme_action_day || [];
          break;
        case 2:
          data = this.hotAwemeData.play_volume_action_day || [];
          break;
        case 3:
          data = this.hotAwemeData.digg_count_action_day || [];
          break;
        case 4:
          data = this.hotAwemeData.commment_count_action_day || [];
          break;
        case 5:
          data = this.hotAwemeData.share_count_action_day || [];
          break;
      }
      this.hotAwemeChartData = data;
      this.hotAwemeActive = type;
    },
    //视频表现 - 详情跳转
    handleVideoItemClick(item) {
      if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${this.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
    //视频表现 - 爆款短视频 - 播放量修改
    onChangeHotAwemeType() {
      this.getHotAweme({
        params: {
          activity_id: this.activity_id,
          area_id: this.regionValue,
          type: this.hotAwemeType,
        },
        platform: this.platform,
      }).then((res) => {
        this.hotAwemeClick(this.hotAwemeActive);
      });
    },
    //直播表现 - 数据
    getLiveData() {
      let data = {
        params: {
          activity_id: this.activity_id,
          area_id: this.regionValue,
        },
        platform: this.platform,
      };
      this.getLiveOverview(data).then(() => {
        this.liveOverviewClick(this.liveOverviewActive);
      });

      this.getHotLive({
        params: {
          activity_id: this.activity_id,
          area_id: this.regionValue,
          type: this.hotLiveType,
        },
        platform: this.platform,
      }).then(() => {
        this.hotLiveClick(this.hotLiveActive);
      });

      this.getLiveRankList(data);
    },
    //直播表现 - 概览切换
    liveOverviewClick(type) {
      let data = [];
      switch (type) {
        case 1:
          data = this.liveOverviewData.watch_volume_action_day || [];
          break;
        case 2:
          data = this.liveOverviewData.live_count_action_day || [];
          break;
        case 3:
          data = this.liveOverviewData.fans_add_count_action_day || [];
          break;
        case 4:
          data = this.liveOverviewData.dealer_count_action_day || [];
          break;
      }
      this.liveOverviewChartData = data;
      this.liveOverviewActive = type;
    },
    //直播表现 - 爆款直播
    hotLiveClick(type) {
      let data = [];
      switch (type) {
        case 1:
          data = this.hotLiveData.watch_volume_action_day || [];
          break;
        case 2:
          data = this.hotLiveData.live_count_action_day || [];
          break;
        case 3:
          data = this.hotLiveData.digg_count_action_day || [];
          break;
        case 4:
          data = this.hotLiveData.commment_count_action_day || [];
          break;
        case 5:
          data = this.hotLiveData.fans_add_count_action_day || [];
          break;
      }
      this.hotLiveChartData = data;
      this.hotLiveActive = type;
    },
    //直播表现 - 详情跳转
    handleLiveItemClick(item) {
      console.log(item);
      if (item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${this.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
    //直播表现 - 爆款直播 - 播放量修改
    onChangeHotLiveType() {
      this.getHotLive({
        params: {
          activity_id: this.activity_id,
          area_id: this.regionValue,
          type: this.hotLiveType,
        },
        platform: this.platform,
      }).then(() => {
        this.hotLiveClick(this.hotLiveActive);
      });
    },
    //经销商表现 - 数据
    getDealerData() {
      let data = {
        params: {
          activity_id: this.activity_id,
          area_id: this.regionValue,
        },
        platform: this.platform,
      };
      this.getDealerContribution(data);
      this.getDealerBest(data);
      this.getDealerTop(data);
    },
    onClickDealerBest(index) {
      this.dealerBestActive = index;
    },
    // 折线图 set option
    getLineOption(type) {
      let option = deepClone(chartLine);
      // option.xAxis[0].data = xData;
      // option.series[0].data = yData;

      let color_1 = "rgba(255, 202, 92, 1)"; //黄色
      let color_2 = "rgba(129, 92, 255, 1)"; //紫色
      let color_3 = "rgba(231, 89, 52, 1)"; //橙色

      let color_4 = "rgba(129, 92, 255, .2)"; //紫色
      let color_5 = "rgba(231, 89, 52, .2)"; //橙色

      let xData = [];
      let yData = [];

      switch (type) {
        case "play_line_1":
          xData = this.formatChartValue(this.awemeOverviewChartData).xData;
          yData = this.formatChartValue(this.awemeOverviewChartData).yData;
          break;

        case "play_line_2":
          xData = this.formatChartValue(this.hotAwemeChartData).xData;
          yData = this.formatChartValue(this.hotAwemeChartData).yData;

          option.tooltip.axisPointer.lineStyle.color = color_4;
          option.series[0].areaStyle.normal.color =
            new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "rgba(129, 92, 255, 0.4)",
                },
                {
                  offset: 0.8,
                  color: "rgba(129, 92, 255, 0)",
                },
              ],
              false
            );
          option.series[0].itemStyle.color = color_2;
          option.series[0].itemStyle.borderColor = color_2;
          break;

        case "live_line_1":
          xData = this.formatChartValue(this.liveOverviewChartData).xData;
          yData = this.formatChartValue(this.liveOverviewChartData).yData;
          break;

        case "live_line_2":
          xData = this.formatChartValue(this.hotLiveChartData).xData;
          yData = this.formatChartValue(this.hotLiveChartData).yData;

          option.tooltip.axisPointer.lineStyle.color = color_5;
          option.series[0].areaStyle.normal.color =
            new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "rgba(231, 89, 52, 0.4)",
                },
                {
                  offset: 0.8,
                  color: "rgba(231, 89, 52, 0)",
                },
              ],
              false
            );
          option.series[0].itemStyle.color = color_3;
          option.series[0].itemStyle.borderColor = color_3;
          break;
      }

      option.xAxis[0].data = xData;
      option.series[0].data = yData;

      return option;
    },
    //格式化图表所需数据
    formatChartValue(arr) {
      let list = {
        xData: [],
        yData: [],
      };
      arr.map((item) => {
        list.xData.push(this.$options.filters.formatDate(item.date));
        list.yData.push(Number(item.value));
      });
      return list;
    },
    // 饼图 set option
    getPieOption(data) {
      let option = deepClone(chartPie);
      option.series[0].data = data;
      return option;
    },
    setTitleDate(time, type) {
      return this.$options.filters.formatDateZH(time, type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  padding: 0 20px 20px;
}
.header {
  padding: 30px 0 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 18px;
    font-weight: 500;
    @include font_color("font_color20");
  }
  .detail {
    display: flex;
    align-items: center;
    .platform {
      margin-right: 10px;
      display: block;
      font-size: 18px;
      @include font_color("font_color20");
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      @include font_color("font_color20");
    }
  }
  .time {
    color: #8890a6;
    line-height: 1.2em;
    @include font_color("font_color23");
    span {
      display: block;
      text-align: right;
    }
  }
}
.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
  margin-bottom: 10px;
  .select {
    @include background_color("background_color2");
    @include font_color("font_color21");
  }
}
.card-list-wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  padding-bottom: 30px;
  .card-box {
    padding: 18px 20px;
    border-radius: 16px;
    line-height: 1;
    @include background_color("background_color2");
    &:first-child {
      @include background_color("background_color77");
    }
  }
  .thead,
  .tbody {
    display: flex;
    justify-content: space-between;
  }
  .thead {
    align-items: center;
  }
  .tbody {
    align-items: flex-end;
    margin-top: 15px;
  }
  .title {
    font-size: 12px;
    @include font_color("font_color23");
  }
  .data {
    font-size: 22px;
    @include font_color("font_color4");
    span {
      font-weight: bold;
    }
  }
  .num {
    font-size: 18px;
    @include font_color("font_color43");
    span {
      font-weight: bold;
    }
  }
  .yellow {
    @include font_color("font_color4");
  }
}
.chart-box {
  height: 250px;
  margin: 0 -20px;
}
.tab-content {
  .row {
    &-1 {
      display: grid;
      grid-template-columns: 37% 1fr;
      grid-gap: 20px;
    }
    &-2 {
      display: grid;
      grid-template-columns: 30% 1fr 1fr;
      grid-gap: 20px;
    }
  }
  .card {
    padding: 20px;
    margin-top: 20px;
    border-radius: 16px;
    @include background_color("background_color2");
  }
  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    .title {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
        font-weight: 500;
        @include font_color("font_color20");
        &.yellow {
          @include font_color("font_color4");
        }
      }
    }
  }
  .tab-box {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    &-1 .active {
      @include border_color("border_color4");
      .title span {
        @include font_color("font_color4");
      }
      .num {
        @include font_color("font_color4");
      }
    }
    &-2 .active {
      @include border_color("border_color22");
      .title span {
        @include font_color("font_color44");
      }
      .num {
        @include font_color("font_color44");
      }
    }
    &-3 .active {
      @include border_color("border_color23");
      .title span {
        @include font_color("font_color27");
      }
      .num {
        @include font_color("font_color27");
      }
    }
    .box {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 25px;
      min-width: 90px;
      line-height: 1;
      cursor: pointer;
      border-left: 2px solid transparent;
      transition: all 0.3s;
      .title {
        margin-bottom: 10px;
        font-size: 12px;
        white-space: nowrap;
        @include font_color("font_color23");
      }
      .num {
        font-size: 22px;
        @include font_color("font_color43");
        span {
          font-weight: bold;
        }
      }
      .tooltip {
        font-size: 12px;
        margin: 0 0 0 4px !important;
      }
    }
  }
  .list-box {
    margin-right: -20px;
    .item {
      display: flex;
      padding: 20px 20px 0 0;
      margin-left: 10px;
      cursor: pointer;
      & + .item {
        margin-top: 20px;
        border-top: 1px solid;
        @include border_color("border_color18");
      }
    }
    .cover {
      width: 84px;
      height: 112px;
      border-radius: 8px;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      @include background_color("background_color73");
    }
    .content {
      flex: 1;
      margin-left: 20px;
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      @include font_color("font_color21");
    }
    .detail {
      display: flex;
      align-items: center;
      margin: 8px 0 18px;
      .avatar {
        margin-right: 8px;
      }
      .name {
        font-size: 14px;
        @include font_color("font_color23");
      }
    }
    .data {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 10px;
      .name {
        font-size: 12px;
        @include font_color("font_color23");
      }
      .num {
        margin-top: 5px;
        font-size: 16px;
        @include font_color("font_color43");
        span {
          font-weight: bold;
        }
      }
      .linght {
        @include font_color("font_color27");
      }
    }
  }
  .dealer-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-top: 12px;
    text-align: center;
    .item {
      .title-box {
        display: inline-block;
        .title {
          display: flex;
          align-items: center;
          padding: 0 22px;
          height: 36px;
          border-radius: 18px;
          @include background_color("background_color1");
        }
        .icon {
          margin-right: 4px;
          font-size: 25px;
        }
        span {
          font-size: 12px;
          font-weight: 500;
          @include font_color("font_color5");
        }
      }
    }
    .chart-box {
      height: 300px;
    }
  }
  .hot-list-box {
    padding: 9px 0 0;
    margin: 0 -10px;
    .item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      margin: 10px 0;
      border-radius: 8px;
      &.active {
        @include background_color("background_color78");
      }
      &:last-child {
        padding-bottom: 5px;
        margin-bottom: 0;
      }
    }
    .content {
      flex: 1;
      margin-left: 20px;
    }
    p {
      margin: 0;
      line-height: 1.5;
    }
    .title {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      @include font_color("font_color20");
    }
    .txt {
      font-size: 12px;
      @include font_color("font_color23");
      span {
        @include font_color("font_color26");
      }
      em {
        font-style: normal;
        font-weight: bold;
      }
    }
  }
  .top-list-box {
    .item {
      display: flex;
      padding: 20px 0 10px 0;
      cursor: pointer;
    }
    .cover {
      width: 84px;
      height: 112px;
      border-radius: 8px;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      @include background_color("background_color73");
    }
    .content {
      flex: 1;
      margin-left: 20px;
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      @include font_color("font_color21");
    }
    .detail {
      display: flex;
      align-items: center;
      margin: 8px 0 18px;
      .avatar {
        margin-right: 8px;
      }
      .name {
        font-size: 14px;
        @include font_color("font_color23");
      }
    }
    .data {
      display: flex;
      flex-wrap: wrap;
      .col {
        display: flex;
        align-items: center;
        margin-right: 14px;
      }
      .name {
        font-size: 12px;
        @include font_color("font_color23");
      }
      .num {
        margin-left: 4px;
        font-size: 14px;
        @include font_color("font_color26");
        span {
          font-weight: bold;
        }
      }
    }
  }
}

/deep/ .tab-content .tab-select .ant-select-selection {
  background-color: #3c4252 !important;
}
</style>