import Nr from '@/utils/NetworldRequest'

function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const request = (new Nr).getAxios()

export default {
  // 获取分组列表
  getGroupList (params) {
    return request({
      url: `/jjt/account/group/drop-down/list`,
      method: 'get',
      params,
      baseURL: getBaseUrl('agg')
    }).then(res => {
      return res.data
    })
  },
  // 获取主题列表
  getTopicList (params) {
    return request({
      url: `/jjt/closedLoop/topic/list`,
      method: 'get',
      params,
      baseURL: getBaseUrl('douyin')
    }).then(res => {
      return res.data
    })
  },
  // 基础激励(直播)
  getBasedIncentiveLiveDetail (params) {
    return request({
      url: `/businessPolicy/based-incentive-live-detail`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      return res.data
    })
  },
  // 基础激励数据(直播)
  getBasedIncentiveLiveCount (params) {
    return request({
      url: `/businessPolicy/based-incentive-live-count`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      return res.data
    })
  },
  // 爆款激励(直播)
  getHotLiveDetail (params) {
    return request({
      url: `/businessPolicy/hot-live-detail`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      return res.data
    })
  },
  // 爆款激励数据(直播)
  getHotLiveCount (params) {
    return request({
      url: `/businessPolicy/hot-live-count`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      return res.data
    })
  },
  // 事件营销激励(直播)
  getEventMarketingLive (params) {
    return request({
      url: `/businessPolicy/live-event-marketing`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      return res.data
    })
  },
  // 违规统计(直播)
  getIlleglSurveillanceLive (params) {
    return request({
      url: `/businessPolicy/illegal/surveillance/live`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 基础激励(视频)
  getBasedIncentiveAwemeDetail (params) {
    return request({
      url: `/businessPolicy/based-incentive-aweme-detail`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 基础激励数据(视频)
  getBasedIncentiveAwemeCount (params) {
    return request({
      url: `/businessPolicy/based-incentive-aweme-count`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      return res.data
    })
  },
  // 爆款激励(视频)
  getHotAwemeDetail (params) {
    return request({
      url: `/businessPolicy/hotAweme`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      return res.data
    })
  },
  // 事件营销激励(视频)
  getEventMarketingAweme (params) {
    return request({
      url: `/businessPolicy/eventAweme`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 违规统计(视频)
  getIlleglSurveillanceAweme (params) {
    return request({
      url: `/businessPolicy/illegal/surveillance/aweme`,
      method: 'get',
      params,
      baseURL: getBaseUrl('hongqi')
    }).then(res => {
      res = res.data
      return res
    })
  }
}
