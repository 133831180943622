/**
* <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
* excelType: false
* changeExcelType() {
*   this.excelType = !this.excelType
* }
*/
<template>
  <div :class="type ? 'excel-button-container active' : 'excel-button-container'" @click="changeButton">
    <span>空值置0</span>
    <div class="swich-container">
      <span class="swich-button"></span>
      <span class="text">{{type ? '开' : '关'}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExcelTypeButton",
  props: {
    type: {
      type: Boolean,
      defult: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    changeButton() {
      this.$emit('changeExcelType')
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.excel-button-container {
  width: 143px;
  height: 40px;
  @include background_color("background_color50");
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > span {
    font-size: 14px;
    @include font_color("font_color23");
    margin-right: 10px;
  }
  .swich-container {
    width: 54px;
    height: 26px;
    @include background_color("background_color1");
    @include box_shadow("box_shadow5");
    border-radius: 20px;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    cursor: pointer;
    .swich-button {
      width: 20px;
      height: 20px;
      @include background_color("background_color51");
      border-radius: 20px;
      margin: 3px;
      display: inline-block;
      transition: all 0.3s;
    }
    .text {
      font-size: 12px;
      @include font_color("font_color23");
      margin: 0 11px 0 5px;
      // transition: all 0.3s;
    }
  }
}
.excel-button-container.active {
  @include background_color("background_color50");
  > span {
    @include font_color("font_color21");
  }
  .swich-container {
    @include font_color("font_color23");
    @include border_color("border_color21");
    border: 1px solid;
    @include box_shadow("box_shadow5");
    flex-direction: row-reverse;
    .swich-button {
      @include background_color("background_color63");
      margin: 3px;
      display: inline-block;
    }
    .text {
      @include font_color("font_color40");
      margin: 0 5px 0 11px;
    }
  }
}
</style>
