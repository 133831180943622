<template>
  <div>
    <platform-header
      title="矩阵详情"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="main-container">
      <div class="main-container-left">
        <div class="main-container-left-top">
          <img :src="ossHost + detailData.principal_logo" width="72" alt="" />
          <div class="name">{{ detailData.principal_name }}</div>
        </div>
        <!-- <div class="interaction">
          <span>互动量</span>
          <span>{{ detailData.interaction_total | changeNum }}</span>
        </div> -->
        <div class="pyramid" style="margin-top:24px;">
          <div class="pyramid_head common_ds">
            <div class="text_style">
              <span>{{ detailData.offical_count | changeNum }}</span>
              <br />
              <span>官号</span>
            </div>
          </div>
          <div class="pyramid_bear common_ds">
            <div class="text_style">
              <span>{{ detailData.dealer_count | changeNum }}</span>
              <br />
              <span>经销商</span>
            </div>
          </div>
          <div class="pyramid_loin common_ds">
            <div class="text_style">
              <span>{{ detailData.kol_count | changeNum }}</span>
              <br />
              <span>KOL/KOC</span>
            </div>
          </div>
        </div>
        <!-- <a-tooltip placement="rightTop">
          <template slot="title">
            <span>指数说明</span>
          </template>
          <div class="tootip">指数说明</div>
        </a-tooltip> -->
        <div class="matrix-data">
          <div class="matrix-data-item border1">
            <span>{{ detailData.fans_total | changeNum }}</span>
            <span>粉丝总量</span>
          </div>
          <!-- <div class="matrix-data-item border1">
            <span>{{ detailData.live_total | changeNum }}</span>
            <span>直播场次</span>
          </div> -->
          <div class="matrix-data-item border1">
            <span>{{ detailData.account_total | changeNum }}</span>
            <span>相关账号</span>
          </div>
          <div class="matrix-data-item border1">
            <span>{{ detailData.aweme_total | changeNum }}</span>
            <span>相关视频</span>
          </div>
          <!-- <div class="divding"></div>
          <div class="matrix-data-item border2">
            <span>{{ detailData.interaction_total | changeNum }}</span>
            <span>互动总量</span>
          </div>
          <div class="matrix-data-item border2">
            <span>{{ detailData.comment_total | changeNum }}</span>
            <span>评论总数</span>
          </div>
          <div class="matrix-data-item border2">
            <span>{{ detailData.like_total | changeNum }}</span>
            <span>获赞总数</span>
          </div>
          <div class="matrix-data-item border2">
            <span>{{ detailData.share_total | changeNum }}</span>
            <span>转发总数</span>
          </div> -->
        </div>
      </div>
      <div class="main-container-right">
        <a-tabs v-model="account_type" @change="tabChange2">
          <a-tab-pane
            key="0"
            :tab="
              `全部(${matrixList.offical_count +
                matrixList.dealer_count +
                matrixList.kol_count || '--'})`
            "
          ></a-tab-pane>
          <a-tab-pane
            key="1"
            :tab="`官号(${matrixList.offical_count || '--'})`"
          ></a-tab-pane>
          <a-tab-pane
            key="2"
            :tab="`账号(${matrixList.dealer_count || '--'})`"
          ></a-tab-pane>
          <a-tab-pane
            key="3"
            :tab="`KOL(${matrixList.kol_count || '--'})`"
          ></a-tab-pane>
        </a-tabs>
        <!-- <div class="tip">数据更新于2021-03-12 17:34｜每周更新</div> -->
        <!-- <platform-tab
          :tabData="accountTabData"
          @tabChange="accountTabChange"
          background="none"
          activeBackground="none"
          :isButton="false"
          style="margin: 8px 0 24px 16px;"
        ></platform-tab> -->
        <div class="table">
          <a-table
            :columns="columns"
            :data-source="matrixList.list"
            :rowKey="(data) => data.author_id"
            :customRow="rowClick"
            :scroll="{ y: '755px' }"
            :pagination="false"
            :locale="locale"
            class="aft-table default"
          >
            <!-- <span
              slot="customInteraction"
              style="cursor: pointer"
              @click="sortClick"
              >互动总量<a-icon type="swap" class="rotate-ico"
            /></span> -->
            <div
              slot="num"
              slot-scope="text, records, index"
              class="font-color2 f14"
            >
              {{ (current - 1) * pageSize + Number(index) + 1 }}
            </div>
            <div slot="account" slot-scope="scope" class="table-account">
              <!-- <img :src="scope.avatar" width="70" alt="" /> -->
              <WebpImage
                :src="scope.avatar"
                :width="'50px'"
                :height="'50px'"
                :radius="'50%'"
                style="margin-right: 10px;border: 1px solid #fff;"
              />
              <div>
                <div>
                  <span class="font-color1 f16">{{ scope.nickName }}</span>
                  <!-- <a-tag class="mcn">MCN</a-tag>
                  <span>{{ scope.mcn }}</span> -->
                </div>
                <div>
                  <span class="font-color3 f12"
                    >{{
                      currentTab == "douyin"
                        ? "抖音"
                        : currentTab == "kuaishou"
                        ? "快手"
                        : currentTab == "dongchedi"
                        ? "懂车帝"
                        : "账"
                    }}号: {{ scope.code }}</span
                  >
                  <!-- <a-icon
                    type="check-circle"
                    style="color: #faad15; margin-left: 10px"
                  />
                  <span>{{ scope.tag }}</span> -->
                </div>
              </div>
            </div>
            <div slot="interaction" slot-scope="scope" class="font-color2 f14">
              <span>{{ scope.interaction | changeNum }}</span>
            </div>
            <div slot="fansCount" slot-scope="scope" class="font-color13 f14">
              <span>{{ scope.fansCount | changeNum }}</span>
            </div>
          </a-table>
        </div>
        <div style="text-align: center; margin-top: 15px">
          <a-pagination
            v-model="current"
            size="small"
            :pageSize="pageSize"
            :total="total"
            @change="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import PlatformTab from "@/components/PlatformTab/PlatformTab";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "matrixDetail"
);
export default {
  name: "matrixDetail",
  components: { PlatformHeader, PlatformTab, WebpImage },
  data() {
    return {
      msg: "矩阵详情",
      currentTab: "",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      accountTabData: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "头部账号",
          value: 1,
        },
        {
          label: "肩部账号",
          value: 2,
        },
        {
          label: "腰部账号",
          value: 3,
        },
        {
          label: "尾部账号",
          value: 4,
        },
      ],
      columns: [
        {
          title: "排序",
          width: "10%",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          key: "account",
          title: "账号",
          align: "left",
          width: "50%",
          scopedSlots: { customRender: "account" },
        },
        // {
        //   key: "interaction",
        //   width: '20%',
        //   align: "left",
        //   slots: { title: "customInteraction" },
        //   scopedSlots: { customRender: "interaction" },
        // },
        {
          key: "fansCount",
          title: "粉丝数",
          width: "20%",
          align: "left",
          scopedSlots: { customRender: "fansCount" },
        },
      ],
      brandList: [
        {
          avatar:
            "https://p3.douyinpic.com/aweme/1080x1080/314ae00038f9b26b374a8.webp?from=2956013662",
          code: 743274324,
          nickName: "猴哥说车",
          tag: "资深车评人",
          mcn: "南京头条",
          interaction: 14025000,
          fansCount: 1230000,
          id: 1,
        },
        {
          avatar:
            "https://p3.douyinpic.com/aweme/1080x1080/314ae00038f9b26b374a8.webp?from=2956013662",
          code: 18723274324,
          nickName: "猴哥说车2",
          tag: "资深车评人",
          mcn: "南京头条",
          interaction: 14021000,
          fansCount: 1230000,
          id: 2,
        },
        {
          avatar:
            "https://p3.douyinpic.com/aweme/1080x1080/314ae00038f9b26b374a8.webp?from=2956013662",
          code: 872374324,
          nickName: "猴哥说车3",
          tag: "资深车评人",
          mcn: "南京头条",
          interaction: 15020000,
          fansCount: 1230000,
          id: 3,
        },
      ],
      current: 1,
      pageSize: 20,
      total: 0,
      account_type: "2",
      pyramid_position: "0",
      sore_type: true,
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      avatar: this.$store.state.auth.avatar,
      name: this.$store.state.auth.name,
    };
  },
  computed: {
    ...mapState({
      matrixList: (state) => state.matrixList,
      detailData: (state) => state.detailData,
    }),
  },
  created() {
    console.log(this.matrixList, "matrixList");
    // this.loadDetail();
    // this.loadMartixList(true);
  },
  watch: {
    matrixList(val) {
      console.log(val);
      this.total = val.pagination.total;
    },
  },
  methods: {
    ...mapActions(["getMatrixList", "getInfoDetail"]),
    ...mapMutations(["changeState"]),
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.loadMartixList(true);
      this.loadDetail();
    },
    pageChange(val) {
      console.log(val);
      this.loadMartixList();
    },
    sortClick() {
      this.sore_type = !this.sore_type;
      this.loadMartixList();
    },
    loadDetail() {
      let params = {
        platform: this.currentTab,
      };
      this.getInfoDetail(params);
    },
    loadMartixList(isFirst) {
      if (isFirst) {
        this.current = 1;
      }
      let params = {
        platform: this.currentTab,
        account_type: this.account_type,
        current: this.current,
        page_size: this.pageSize,
        pyramid_position: this.pyramid_position,
        sore_type: this.sore_type ? "DESC" : "ASC",
      };
      console.log(params);
      this.getMatrixList(params);
    },
    accountTabChange(val) {
      console.log(val);
      this.pyramid_position = String(val);
      this.loadMartixList(true);
    },
    tabChange2(val) {
      console.log(val);
      this.loadMartixList(true);
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            console.log(record);
            this.$router.push({
              path: "/dealer/production",
              query: {
                type: this.currentTab,
                author_id: record.author_id,
              },
            });
          },
        },
      };
    },
  },
  beforeDestroy() {
    this.changeState({ data: [], name: "matrixList" });
    this.changeState({ data: {}, name: "detailData" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
  position: relative;

  .beta-icon {
    width: 36px;
    height: 14px;
    border-radius: 7px;
    background: #ff4160;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: 4px;
    right: 0px;
  }
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.main-container {
  width: 100%;
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 24px 0px;
  @include font_color("font_color2");
  &-left {
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 906px;
    @include background_color("background_color5");
    border: 1px solid rgba(0, 0, 0, 0);
    @include border_color("border_color1");
    border-radius: 4px;
    &-top {
      width: 100%;
      height: 194px;
      background: url("../../assets/img/brand-background.png");
      padding: 43px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      > img {
        border-radius: 4px;
        border: 4px solid rgba(0, 0, 0, 0);
        @include border_color("border_color2");
      }
    }
    .name {
      font-size: 16px;
      font-weight: bold;
      @include font_color("font_color1");
    }
    .interaction {
      width: 220px;
      height: 50px;
      @include background_color("background_color25");
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 20px 0 32px;
      span:first-child {
        font-size: 12px;
        @include font_color("font_color2");
        margin: 0 16px 0 24px;
      }
      span:last-child {
        font-weight: bold;
        @include font_color("font_color4");
        font-size: 20px;
      }
    }
    .pyramid {
      display: flex;
      flex-direction: column;
      background: url("../../assets/img/matrix2.png");
      height: 242px;
      width: 210px;
      text-align: center;
      padding-top: 30px;
      .text_style {
        > span:first-child {
          font-size: 16px;
          font-weight: bold;
          @include font_color("font_color1");
        }
        > span:last-child {
          font-size: 12px;
          @include font_color("font_color1");
        }
      }
      .common_ds {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
      }
      .pyramid_loin {
        flex: 1;
        // background-image: linear-gradient(0deg, #fbbc6c 0%, #fc9965 100%);
        // clip-path: polygon(19% 2%, 81% 2%, 95% 100%, 5% 100%);
      }
      .pyramid_bear {
        margin: 4px 0;
        flex: 1;
        // background-image: linear-gradient(180deg, #64a9f3 0%, #516ef3 100%);
        // clip-path: polygon(33% 2%, 67% 2%, 81% 100%, 19% 100%);
      }
      .pyramid_head {
        flex: 1;
        // background-image: linear-gradient(0deg, #27c77d 0%, #27b388 100%);
        // clip-path: polygon(50% 2%, 50% 2%, 67% 100%, 33% 100%);
      }
    }
    .tootip {
      width: 100%;
      text-align: right;
      color: #3bd8c4;
    }
    .matrix-data {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 24px;
      .divding {
        width: 100%;
        margin: 24px 0 0;
        height: 1px;
        border-top: 1px solid rgba(0, 0, 0, 0);
        @include border_color("border_color1");
      }
      &-item {
        width: 50%;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-left: 3px solid rgba(0, 0, 0, 0);
        margin-top: 24px;

        > span:first-child {
          @include font_color("font_color1");
          font-size: 16px;
        }
        > span:last-child {
          @include font_color("font_color3");
          font-size: 12px;
        }
      }
      &-item.border1 {
        @include border_color("border_color8");
      }
      &-item.border2 {
        @include border_color("border_color9");
      }
    }
  }
  &-right {
    width: calc(100% - 260px);
    height: 900px;
    padding-left: 20px;
    .rotate-ico {
      transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    }
    .tip {
      text-align: right;
      color: #999;
    }
    .table {
      color: #fff;
      &-account {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
        height: 70px;

        // > img {
        //   width: 50px;
        //   height: 50px;
        //   border: 1px solid #fff;
        //   border-radius: 50%;
        //   margin-right: 10px;
        // }
        > div {
          text-align: left;
          .mcn {
            color: #1d74c6;
            border: 1px solid #1d74c6;
            background: none;
            margin-left: 10px;
          }
        }
      }
      .font-color1 {
        @include font_color("font_color1");
      }
      .font-color2 {
        @include font_color("font_color2");
      }
      .font-color3 {
        @include font_color("font_color3");
      }
      .font-color13 {
        @include font_color("font_color13");
      }
      .f12 {
        font-size: 12px;
      }
      .f14 {
        font-size: 14px;
      }
      .f16 {
        font-size: 16px;
      }
    }
  }
}
</style>
