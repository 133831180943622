<template>
  <a-drawer
    placement="right"
    width="728"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>确认导拍模版</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <div class="drawer-tips">
          <a-icon type="exclamation-circle" theme="filled" class="icon" />
          <span>导拍模版确认后经销商可见</span>
        </div>
        <div class="empty-box" v-if="!tableLoading && templateData.length == 0">
          <Empty :title="'暂无模版'" />
        </div>
        <template v-if="tableLoading || templateData.length > 0">
          <div class="drawer-table">
            <div class="drawer-table-header">
              <div class="header-col col1">名称</div>
              <div class="header-col col2">生产日期</div>
              <div class="header-col col3">操作</div>
            </div>
            <div class="drawer-table-body">
              <div class="loading-box" v-if="tableLoading">
                <a-spin :spinning="tableLoading" />
              </div>
              <div
                class="loading-box"
                v-if="!tableLoading && templateData.length == 0"
              >
                <Empty :title="'暂无模版'" />
              </div>
              <div
                class="drawer-table-item"
                v-for="(item, index) in templateData"
                :key="index"
              >
                <div
                  class="col1 fc1 ellipsis"
                  :title="item.guideshootName"
                  @click="getAuthUrl(item.originalSampleUrl)"
                >
                  {{ item.guideshootName || "-" }}
                </div>
                <div class="col2 fc2">{{ returnDate(item.ctime) }}</div>
                <div class="col3 action-col">
                  <span
                    class="action-text fc1"
                    v-if="item.relevancyStatus == 1"
                    @click="editTemplate(item.id, 2)"
                    >确认</span
                  >
                  <span class="action-text fc3" v-if="item.relevancyStatus == 2"
                    >已确认</span
                  >
                  <span
                    class="action-text fc1"
                    @click="getAuthUrl(item.originalSampleUrl)"
                    >预览</span
                  >
                  <span
                    class="action-text fc1"
                    @click="editTemplate(item.id, 3)"
                    >删除</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="page-div aft-pagination">
            <a-pagination
              v-model="current"
              size="small"
              :pageSize="pageSize"
              :total="total"
              @change="pageChange"
            />
          </div>
        </template>
      </div>
      <div class="drawer-tools">
        <a-button class="btn btn1" @click="onClose">取消</a-button>
        <a-button
          class="btn btn2"
          @click="editTemplate('all', 2)"
          :loading="isSubmit"
        >
          确定当前页
        </a-button>
        <a-button
          class="btn btn2"
          @click="sureAllTemplate"
          :loading="isSubmitAll"
        >
          确定全部
        </a-button>
      </div>
    </div>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="videoModalClose"
      ref="modal"
    >
      <video
        id="videoElement2"
        autoplay
        controls
        muted
        style="width: 100%; height: 100%; border-radius: 10px"
      ></video>
    </a-modal>
  </a-drawer>
</template>

<script>
import api from "@/api/marketingActivityList";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import Empty from "@/components/Empty/Empty";
export default {
  components: { Empty },
  props: {},
  data() {
    return {
      visible: false,
      previewVisible: false,
      previewImage: "",
      isSubmit: false,
      isSubmitAll: false,
      currentItem: {},
      flvPlayer: null,
      tableLoading: false,
      templateData: [],
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {},
  methods: {
    showDrawer(currentItem) {
      this.currentItem = currentItem;
      console.log("this.currentItem", this.currentItem);
      this.visible = true;
      this.getTemplateList(true);
    },

    // 活动弹窗-取消
    onClose() {
      this.visible = false;
    },
    pageChange() {
      this.getTemplateList();
    },
    getTemplateList(isFirst) {
      if (isFirst) {
        this.current = 1;
      }
      let params = {
        activityId: this.currentItem.id,
        page: this.current,
        size: this.pageSize,
      };
      this.tableLoading = true;
      this.templateData = [];
      api
        .getTemplateList(params)
        .then((res) => {
          console.log("getTemplateList", res);
          if (res.data.code === 200) {
            this.templateData = res.data.data.list || [];
            this.total = res.data.data.total || 0;
          } else {
            this.$message.error(res.data.message || "获取模版失败");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    // 视频地址通过内容中台获取权限
    getAuthUrl(source) {
      if (source.indexOf("huoshan") < 0) {
        let body = {
          resource_url: source,
        };
        api
          .getAuthUrl(body)
          .then((res) => {
            console.log("getAuthUrl", res);
            if (res.data.code === 200) {
              this.getStream(res.data.data);
            } else {
              this.$message.error(res.data.message || "获取视频权限失败");
            }
          })
          .catch((err) => {
            this.$message.error("获取视频权限失败");
            console.error(err);
          });
      } else {
        this.getStream(source);
      }
    },
    // 初始化flv
    getStream(source) {
      this.previewVisible = true;
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement2 = document.getElementById("videoElement2");
          console.log(1, source);
          let type =
            source.indexOf(".mp4") > 0 || source.indexOf("huoshan") > 0
              ? "mp4"
              : "flv";
          this.flvPlayer = flvjs.createPlayer({
            type,
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement2);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            this.videoModalClose();
            this.$message.warning("视频播放失败，请联系管理员...");
          });
        }
      });
    },
    // 编辑点击事件
    editTemplate(id, status) {
      let template_ids = [];
      if (id == "all") {
        template_ids = this.templateData.map((item) => {
          return item.id;
        });
      } else {
        template_ids.push(id);
      }
      if (status == 2) {
        if (template_ids.length === 0) {
          this.$message.warning({
            content: "暂无模版",
          });
        } else {
          this.sureTemplate(template_ids);
        }
      } else {
        this.deleteTemplate(template_ids);
      }
    },
    /**
     * 关联全部模板
     */
    sureAllTemplate() {
      if (this.templateData.length === 0) {
        this.$message.warning({
          content: "暂无模版",
        });
        return;
      }
      const params = {
        activityId: this.currentItem.id,
      };
      this.isSubmitAll = true;
      api
        .sureAllTemplate(params)
        .then((res) => {
          console.log("sureTemplate", res);
          if (res.data.code === 200) {
            this.$message.success("确认关联模版成功");
            this.getTemplateList();
          } else {
            this.$message.error(res.data.message || "确认关联模版失败");
          }
        })
        .finally(() => (this.isSubmitAll = false));
    },
    // 编辑模版状态接口
    sureTemplate(template_ids) {
      let body = {
        ids: template_ids,
      };
      this.isSubmit = true;
      api
        .sureTemplate(body)
        .then((res) => {
          console.log("sureTemplate", res);
          if (res.data.code === 200) {
            this.$message.success("确认关联模版成功");
            this.getTemplateList(true);
          } else {
            this.$message.error(res.data.message || "确认关联模版失败");
          }
        })
        .finally(() => {
          this.isSubmit = false;
        });
    },
    // 删除模版接口
    deleteTemplate(template_ids) {
      let body = {
        ids: template_ids,
      };
      api.deleteTemplate(body).then((res) => {
        console.log("deleteTemplate", res);
        if (res.data.code === 200) {
          this.$message.success("删除模版成功");
          this.getTemplateList();
        } else {
          this.$message.error(res.data.message || "删除模版失败");
        }
      });
    },
    // 关闭 flv 视频弹窗事件
    videoModalClose() {
      this.previewVisible = false;
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
      }
      this.$refs.modal.$destroyAll();
    },
    returnDate(str) {
      if (str) {
        return str.slice(0, 10);
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 116px);
    padding: 0 24px;
    overflow-y: auto;
    .drawer-tips {
      width: 100%;
      height: 40px;
      background: #1e2b48;
      border-radius: 2px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      margin-top: 24px;
      .icon {
        color: #3c7eff;
        margin: 0 8px 0 17px;
      }
    }
    .empty-box {
      width: 100%;
      height: calc(100% - 64px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .drawer-table {
      width: 100%;
      margin-top: 15px;
      &-header {
        width: 100%;
        height: 50px;
        background: #242832;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.85);
      }
      &-body {
        width: 100%;
        height: calc(100vh - 324px);
        overflow-y: auto;
        .loading-box {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .drawer-table-item {
          height: 50px;
          border-bottom: 1px solid #3c4252;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          font-size: 14px;
          .ellipsis {
            padding-right: 88px;
            display: inline-block;
            overflow: hidden;
            flex: 1;
            vertical-align: middle;
            width: 280px;
            height: 50px;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
            line-height: 50px;
            cursor: pointer;
          }
          .action-col {
            display: flex;
            > .action-text {
              margin-right: 16px;
              cursor: pointer;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      .col1 {
        width: 387px;
        padding-left: 24px;
      }
      .col2 {
        width: 132px;
      }
      .col3 {
        width: 161px;
        padding-right: 24px;
      }
      .fc1 {
        color: #ffca5b;
      }
      .fc2 {
        color: rgba(255, 255, 255, 0.85);
      }
      .fc3 {
        color: #8890a6;
      }
    }
    .page-div {
      text-align: right;
      margin-top: 20px;
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      margin-left: 8px;
      &.btn1 {
        background: #2b2f3b;
        border-color: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        border-color: #ffca5b;
        color: #000000;
      }
    }
  }
}
</style>
