<template>
  <div class="lexical-analysis--container">
    <div>
      <div class="title-container">
        <svg-icon :icon-class="'ag-icon1'" class="icon" />
        <span>主播话术</span>
      </div>
      <div class="tag-container">
        <div
          class="tag-item"
          v-for="(item, index) in tabData.archor.tags"
          :key="index"
        >
          {{ `${item.tag_name}(${item.tag_times})` }}
        </div>
      </div>
      <div class="main-container">
        <div class="main-container-content">
          <!-- <div v-for="(item, index) in tabData.archor.content" :key="index">
            {{ item }}
          </div> -->
          <div v-html="tabData.archor.content"></div>
        </div>
      </div>
    </div>
    <div>
      <div class="title-container">
        <svg-icon :icon-class="'ag-icon2'" class="icon" />
        <span>用户互动</span>
      </div>
      <div class="tag-container">
        <div
          class="tag-item"
          v-for="(item, index) in tabData.comment.tags"
          :key="index"
        >
          {{ `${item.tag_name}(${item.tag_times})` }}
        </div>
      </div>
      <div class="main-container">
        <div
          class="comment-item"
          v-for="(item, index) in tabData.comment.comment_list"
          :key="index"
        >
          <WebpImage
            :scale="'1-1'"
            :src="item.avatar"
            :width="'64px'"
            :height="'64px'"
            :radius="'50%'"
            class="img"
          />
          <div class="comment-item-right">
            <div>{{ item.content || "-" }}</div>
            <div>
              <span>{{ item.nickname || "-" }}</span>
              <span>{{ item.time }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "TabContent2",
  components: { WebpImage },
  props: {
    tabData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scope>
.lexical-analysis--container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  > div {
    width: 50%;
    height: 100%;
    padding: 0 20px;

    .title-container {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      margin-bottom: 2px;
      .icon {
        font-size: 20px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
        margin-left: 8px;
      }
    }
    .tag-container {
      display: flex;
      flex-wrap: wrap;
      height: 56px;
      overflow: hidden;
      .tag-item {
        padding: 0 8px;
        height: 24px;
        background: rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 24px;
        text-align: center;
        margin: 4px 4px 0 0;
      }
    }
    .main-container {
      width: 100%;
      height: 236px;
      margin-top: 16px;
    }
  }
  > div:first-child {
    .main-container {
      padding: 16px;
      background: #242832;
      border-radius: 12px;
      &-content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
      }
    }
  }
  > div:last-child {
    .main-container {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .comment-item {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        > .img {
          margin: 4px 23px 0 0;
        }
        &-right {
          width: calc(100% - 70px);
          min-height: 72px;
          background: #242832;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          padding: 13px;
          position: relative;
          > div:first-child {
            font-size: 14px;
            font-weight: 500;
            color: #ffcc55;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-height: 44px;
          }
          > div:last-child {
            display: flex;
            align-items: center;
            margin-top: 4px;
            > span:first-child {
              font-size: 12px;
              color: rgba(255, 255, 255, 0.65);
              line-height: 20px;
            }
            > span:last-child {
              font-size: 12px;
              color: rgba(255, 255, 255, 0.45);
              line-height: 20px;
              margin-left: 8px;
            }
          }
          &::before {
            display: block;
            content: "◆";
            position: absolute;
            font-size: 16px;
            left: -8px;
            top: 29px;
            color: #242832;
          }
        }
      }
    }
  }
}
</style>
