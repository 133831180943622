import Nr from "@/utils/NetworldRequest";

const activity_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API,
}).getAxios();

export default {
  getTaskDetail(params) {
    return activity_request.get("/jjt/activity/web/task/detail", {
      params,
    });
  },
  exportTaskDetail(params) {
    return activity_request.get("/jjt/activity/web/task/detailExport", {
      params,
    });
  },
};
