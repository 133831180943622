<template>
  <div class="container" style="position: relative">
    <platform-header
      title="门店查询"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tab-tools">
      <button-group :tabData="btnData" @tabChange="btnChange"></button-group>
      <div>
        <!-- 暂时先屏蔽!!! -->
        <!-- <RouterButton
          :title="'门店排行'"
          :icon="'rb-store-ranking'"
          :path="'storeRanking'"
          style="margin-right: 10px"
        /> -->
        <!-- 10-9 金琳让屏蔽 -->
        <!-- <RouterButton
          :title="'标杆门店'"
          :icon="'rb-regional-stores'"
          :path="'/dealer/regionalStores'"
          style="margin-right: 10px"
        /> -->
        <RouterButton
          :title="'门店收藏'"
          :icon="'rb-store-collection'"
          :path="'/dealer/storeCollection'"
        />
      </div>
    </div>
    <!-- <div class="title">
      <div @click="back">门店收藏</div>
    </div> -->
    <div class="tools">
      <div class="f16 font-color2">
        <a-select
          v-model="area_id"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin: 0 10px 0 0px"
          placeholder="选择大区"
        >
          <a-select-option
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          style="width: 132px; height: 40px; margin-right: 10px"
          v-model="group_code"
          :suffixIcon="suffixIcon"
          placeholder="选择分组"
        >
          <a-select-option :value="undefined">不限分组</a-select-option>
          <a-select-option v-for="d in groupList" :key="d.group_code">
            {{ d.group_name }}
          </a-select-option>
        </a-select>
        <a-select
          style="width: 132px; height: 40px; margin-right: 10px"
          v-if="currentBtn == 2"
          v-model="account_type"
          :suffixIcon="suffixIcon"
          placeholder="选择账号类型"
        >
          <a-select-option v-for="d in accountTypeList" :key="d.value">
            {{ d.label }}
          </a-select-option>
        </a-select>
        <a-select
          style="width: 132px; height: 40px; margin-right: 10px"
          v-if="currentBtn == 2"
          v-model="verification_type"
          :suffixIcon="suffixIcon"
          placeholder="选择认证类型"
        >
          <a-select-option v-for="d in attestationList" :key="d.value">
            {{ d.label }}
          </a-select-option>
        </a-select>
        <a-input
          v-if="currentBtn == 1"
          v-model.trim="storeWord"
          class="aft-input default"
          placeholder="搜索门店名、公司名或门店编码关键字"
          style="width: 236px; margin-right: 10px"
        >
        </a-input>
        <a-input
          v-if="currentBtn == 2"
          v-model.trim="accountWord"
          class="aft-input default"
          placeholder="搜索门店名、账号昵称或平台号关键字"
          style="width: 236px; margin-right: 10px"
        />
        <a-button class="search-btn" @click="loadList">确定</a-button>
        <!-- <br v-if="currentBtn == 2" />
        <a-radio-group
          v-if="currentBtn == 2"
          v-model="typeRadio"
          :options="radioOptions"
          @change="loadList"
        /> -->
      </div>
    </div>

    <div class="content" v-show="currentBtn == 1">
      <a-table
        :columns="currentTab == 'dongchedi' ? columns_d : columns"
        :data-source="tableData"
        :pagination="false"
        :rowKey="(data) => data.author_id || data.dealer_id"
        :scroll="{ y: 'calc(100vh - 408px)' }"
        :customRow="rowClick"
        :loading="tableStoreLoading"
        :rowClassName="rowClassName"
        :expanded-row-keys.sync="expandedRowKeys"
        :expandIconColumnIndex="-1"
        :locale="locale"
        class="aft-table default"
      >
        <div slot="fansCountTitle" style="display: flex; align-items: center">
          <div>
            主页粉丝数
            <AftTooltip code="qjfss01" style="top: 2px; left: 4px" />
          </div>
        </div>
        <div slot="diggCountTitle" style="display: flex; align-items: center">
          <div>
            主页获赞数
            <AftTooltip code="qjqt03" style="top: 2px; left: 4px" />
          </div>
        </div>
        <div slot="videoTitle" style="display: flex; align-items: center">
          <div>
            主页视频数
            <AftTooltip code="qjspp01" style="top: 2px; left: 4px" />
          </div>
        </div>
        <div
          slot="num"
          slot-scope="text, records, index"
          class="font-color3 f14"
          style="padding: 17px 0"
        >
          <span v-if="records.children">{{
            (current - 1) * pageSize + Number(index) + 1
          }}</span>
        </div>
        <div slot="storeName" slot-scope="scope" class="font-color2 f14">
          <span
            v-if="scope.children"
            style="display: flex; align-items: center; line-height: 30px"
          >
            <WebpImage
              :src="scope.avatar"
              :width="'30px'"
              :height="'30px'"
              :radius="'50%'"
              style="margin-right: 8px"
            />
            <span>{{ scope.dealer_name || "-" }}</span>
          </span>
          <div v-else class="table-name">
            <WebpImage
              :src="scope.avatar"
              :width="'20px'"
              :height="'20px'"
              :radius="'50%'"
              style="margin-right: 8px"
            />
            <span>{{ scope.nickname || "--" }}</span>
            <img
              src="@/assets/img/type-lanv.png"
              v-if="scope.verification_type == 2"
              width="16px"
              style="width: 16px; height: 16px; margin-right: 4px"
              alt=""
            />
            <img
              src="@/assets/img/type-ordinary.png"
              v-else
              width="16"
              style="margin-right: 4px"
              alt=""
            />
            <!-- <svg-icon icon-class="attestation" /> -->
            <!-- <svg-icon icon-class="fans" /> -->
          </div>
        </div>
        <div slot="storeCode" slot-scope="scope" class="font-color3 f14">
          <span v-if="scope.children">{{ scope.store_code || "-" }}</span>
        </div>
        <div slot="level" slot-scope="scope" class="font-color3 f14">
          <span
            :class="
              scope.level == 'S'
                ? 'font-color9'
                : scope.level == 'A'
                ? 'font-color5'
                : ''
            "
            >{{ scope.level || "-" }}</span
          >
        </div>
        <div
          slot="platform"
          slot-scope="scope"
          :class="scope.isChildren ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.platform || "-" }}</span>
        </div>
        <div
          slot="video"
          slot-scope="scope"
          :class="scope.isChildren ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.aweme_count | changeNum }}</span>
        </div>
        <div
          slot="live"
          slot-scope="scope"
          :class="scope.isChildren ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.live_count | changeNum }}</span>
        </div>
        <div
          slot="diggCount"
          slot-scope="scope"
          :class="scope.isChildren ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.like_count | changeNum }}</span>
        </div>
        <div
          slot="fansCount"
          slot-scope="scope"
          :class="scope.isChildren ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.fans_count | changeNum }}</span>
        </div>
        <div slot="action" slot-scope="scope" class="font-color3 f14">
          <div class="table-action" v-if="scope.children">
            <a-icon
              type="star"
              class="font-color6"
              :theme="scope.collected ? 'filled' : 'outlined'"
              @click.stop="starClick(scope.dealer_id)"
            />
            <a-icon
              type="down"
              :rotate="currentRow == scope ? 180 : 0"
              style="margin-left: 10px"
              @click.stop="arrowClick(scope)"
            />
          </div>
          <div
            v-else
            class="font-color4 f14"
            style="padding: 15px 0"
            @click="routerToAccount(scope)"
          >
            查看
          </div>
        </div>
      </a-table>
    </div>
    <div class="content" v-show="currentBtn != 1">
      <a-table
        :columns="currentTab == 'dongchedi' ? columns2_d : columns2"
        :data-source="tableData2"
        :rowKey="(data) => data.author_id"
        :scroll="{ y: 'calc(100vh - 408px)' }"
        :loading="tableLoading"
        :pagination="false"
        :locale="locale"
        class="aft-table default"
      >
        <div slot="fansCountTitle" style="display: flex; align-items: center">
          <div>
            主页粉丝数
            <AftTooltip code="qjfss01" style="top: 2px; left: 4px" />
          </div>
        </div>
        <div slot="diggCountTitle" style="display: flex; align-items: center">
          <div>
            主页获赞数
            <AftTooltip code="qjqt03" style="top: 2px; left: 4px" />
          </div>
        </div>
        <div slot="videoTitle" style="display: flex; align-items: center">
          <div>
            主页视频数
            <AftTooltip code="qjspp01" style="top: 2px; left: 4px" />
          </div>
        </div>
        <div
          slot="num"
          slot-scope="text, records, index"
          class="font-color2 f14"
          style="padding: 30px 0"
        >
          {{ (current - 1) * pageSize + Number(index) + 1 }}
        </div>
        <div slot="account" slot-scope="scope" class="table-account">
          <WebpImage
            :src="scope.avatar"
            :width="'48px'"
            :height="'48px'"
            :radius="'50%'"
            style="margin-right: 8px"
          />
          <div>
            <div class="f16 font-color2 nickname">
              <img
                src="@/assets/img/type-lanv.png"
                v-if="scope.verification_type == 2"
                width="20px"
                style="
                  width: 20px;
                  height: 20px;
                  margin-right: 4px;
                  margin-bottom: 2px;
                "
                alt=""
              />
              <img
                src="@/assets/img/type-ordinary.png"
                v-else
                width="20"
                style="margin-right: 4px; margin-bottom: 2px"
                alt=""
              />{{ scope.nickname || "-" }}
            </div>
            <div>
              <span class="f12 font-color3">
                <img
                  src="@/assets/img/type-store.png"
                  width="16"
                  style="margin-right: 4px; margin-bottom: 2px"
                  alt=""
                />{{ scope.dealer_name || "-" }}</span
              >
            </div>
          </div>
        </div>
        <div slot="storeCode" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.store_code || "-" }}</span>
        </div>
        <div slot="platform" class="font-color2 f14">
          <span>{{
            currentTab == "douyin"
              ? "抖音"
              : currentTab == "kuaishou"
              ? "快手"
              : currentTab == "dongchedi"
              ? "懂车帝"
              : "-"
          }}</span>
        </div>
        <div slot="video" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.aweme_count | changeNum }}</span>
        </div>
        <!-- <div slot="live" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_count | changeNum }}</span>
        </div> -->
        <div slot="videoActivity" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.aweme_activation | changeNum }}</span>
        </div>
        <div slot="liveActivity" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.live_activation | changeNum }}</span>
        </div>
        <div slot="diggCount" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.like_count | changeNum }}</span>
        </div>
        <div slot="fansCount" slot-scope="scope" class="font-color3 f14">
          <span>{{ scope.fans_count | changeNum }}</span>
        </div>
        <div slot="level" slot-scope="scope" class="font-color3 f14">
          <span
            :class="
              scope.level == 'S'
                ? 'font-color9'
                : scope.level == 'A'
                ? 'font-color5'
                : ''
            "
            >{{ scope.level || "-" }}</span
          >
        </div>
        <div
          slot="action"
          slot-scope="scope"
          class="font-color3 f14 table-action"
        >
          <span @click="routerToAccount(scope)">查看</span>
        </div>
      </a-table>
    </div>
    <div class="page-div aft-pagination">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
    <!-- 收藏门店弹窗 -->
    <div class="store-modal" v-show="storeModalShow" @click="hideAllModal">
      <div class="store-modal-container" @click.stop>
        <div class="store-modal-container-title">收藏门店</div>
        <div class="list">
          <multiple-group
            :multipleData="multipleData"
            @multipleChange="multipleChange"
          ></multiple-group>
        </div>
        <div class="action-div">
          <div @click="showAddGroup"><span class="icon">+</span> 新建分组</div>
          <span class="btn btn1" @click="closeModal">取消</span>
          <span class="btn btn2" @click="groupBindDealer">确定</span>
        </div>
      </div>
    </div>
    <!-- 新建分组弹窗 -->
    <div class="add-modal" v-show="addModalShow" @click="addModalShow = false">
      <div class="add-modal-container" @click.stop>
        <a-input v-model.trim="addGroupValue" placeholder="输入新建分组名称" />
        <div class="action-div">
          <span
            :class="!!addGroupValue ? '' : 'active'"
            @click="addModalShow = false"
            >取消</span
          >
          <span :class="!!addGroupValue ? 'active' : ''" @click="addGroupDealer"
            >确定</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const accountTypeList = [
  {
    label: "经销商账号",
    value: "1",
  },
  {
    label: "大区号",
    value: "2",
  },
];
const attestationList = [
  {
    label: "全部认证类型",
    value: " ",
  },
  {
    label: "蓝V认证账号",
    value: "1",
  },
  {
    label: "非蓝V认证账号",
    value: "2",
  },
];
import * as vuex from "vuex";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import MultipleGroup from "@/components/MultipleGroup/MultipleGroup";
import WebpImage from "@/components/WebpImage/WebpImage";
import RouterButton from "@/components/RouterButton/RouterButton";
import Empty from "@/components/Empty/Empty";
import _http from "@/api/storeSearch";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "storeSearch"
);

export default {
  components: {
    PlatformHeader,
    ButtonGroup,
    MultipleGroup,
    RouterButton,
    WebpImage,
  },
  data() {
    return {
      defultAvatar: require("@/assets/img/defult-avatar.png"),
      storeWord: "",
      accountWord: "",
      group_code: undefined,
      area_id: "",
      accountTypeList,
      attestationList,
      account_type: "1",
      verification_type: undefined,
      radioOptions: [
        { label: "全部账号", value: " " },
        { label: "蓝V认证账号", value: "1" },
        { label: "非蓝V认证账号", value: "2" },
      ],
      locale: {
        emptyText: <Empty style="margin-top: 20px;" title="暂无数据" />,
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      currentTab: "",
      btnData: [
        {
          label: "搜门店",
          value: "1",
        },
        {
          label: "搜账号",
          value: "2",
        },
      ],
      currentBtn: "1",
      columns: [
        {
          title: "序号",
          width: "6%",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          title: "门店名称",
          width: "31%",
          align: "left",
          scopedSlots: { customRender: "storeName" },
        },
        {
          title: "门店编码",
          width: "9%",
          align: "left",
          scopedSlots: { customRender: "storeCode" },
        },
        {
          // title: "粉丝",
          width: "8.3%",
          align: "left",
          slots: { title: "fansCountTitle" },
          scopedSlots: { customRender: "fansCount" },
        },
        {
          // title: "获赞",
          width: "8.3%",
          align: "left",
          slots: { title: "diggCountTitle" },
          scopedSlots: { customRender: "diggCount" },
        },
        {
          // title: "视频",
          width: "8.3%",
          align: "left",
          slots: { title: "videoTitle" },
          scopedSlots: { customRender: "video" },
        },
        // {
        //   title: "直播",
        //   width: "7.6%",
        //   align: "left",
        //   scopedSlots: { customRender: "live" },
        // },
        {
          title: "平台",
          width: "7%",
          align: "left",
          scopedSlots: { customRender: "platform" },
        },
        {
          title: "门店/账号等级",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "level" },
        },
        {
          title: "操作",
          width: "6.3%",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      columns2: [
        {
          title: "序号",
          width: "5.3%",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          key: "account",
          title: "账号昵称/门店名称",
          align: "left",
          width: "26.6%",
          scopedSlots: { customRender: "account" },
        },
        {
          key: "storeCode",
          title: "门店编码",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "storeCode" },
        },
        {
          // title: "粉丝",
          width: "8.3%",
          align: "left",
          slots: { title: "fansCountTitle" },
          scopedSlots: { customRender: "fansCount" },
        },
        {
          // title: "获赞",
          width: "8.3%",
          align: "left",
          slots: { title: "diggCountTitle" },
          scopedSlots: { customRender: "diggCount" },
        },
        {
          // title: "视频",
          width: "8.3%",
          align: "left",
          slots: { title: "videoTitle" },
          scopedSlots: { customRender: "video" },
        },
        // {
        //   title: "直播",
        //   width: "5.3%",
        //   align: "left",
        //   scopedSlots: { customRender: "live" },
        // },
        {
          title: "视频活跃度",
          width: "9%",
          align: "left",
          scopedSlots: { customRender: "videoActivity" },
        },
        {
          title: "直播活跃度",
          width: "9%",
          align: "left",
          scopedSlots: { customRender: "liveActivity" },
        },
        {
          title: "平台",
          width: "5.3%",
          align: "left",
          scopedSlots: { customRender: "platform" },
        },
        {
          title: "账号等级",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "level" },
        },
        {
          key: "action",
          title: "操作",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      columns_d: [
        {
          title: "序号",
          width: "6%",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          title: "门店名称",
          width: "31%",
          align: "left",
          scopedSlots: { customRender: "storeName" },
        },
        {
          title: "门店编码",
          width: "9%",
          align: "left",
          scopedSlots: { customRender: "storeCode" },
        },
        {
          // title: "主页粉丝数",
          width: "8.3%",
          align: "left",
          slots: { title: "fansCountTitle" },
          scopedSlots: { customRender: "fansCount" },
        },
        {
          // title: "获赞",
          width: "8.3%",
          align: "left",
          slots: { title: "diggCountTitle" },
          scopedSlots: { customRender: "diggCount" },
        },
        // {
        //   title: "视频",
        //   width: "7.6%",
        //   align: "left",
        //   scopedSlots: { customRender: "video" },
        // },
        {
          title: "平台",
          width: "7%",
          align: "left",
          scopedSlots: { customRender: "platform" },
        },
        {
          title: "门店/账号等级",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "level" },
        },
        {
          title: "操作",
          width: "6.3%",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      columns2_d: [
        {
          title: "序号",
          width: "5.3%",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          key: "account",
          title: "账号昵称/门店名称",
          align: "left",
          width: "26.6%",
          scopedSlots: { customRender: "account" },
        },
        {
          key: "storeCode",
          title: "门店编码",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "storeCode" },
        },
        {
          // title: "粉丝",
          width: "8.3%",
          align: "left",
          slots: { title: "fansCountTitle" },
          scopedSlots: { customRender: "fansCount" },
        },
        {
          // title: "获赞",
          width: "8.3%",
          align: "left",
          slots: { title: "diggCountTitle" },
          scopedSlots: { customRender: "diggCount" },
        },
        // {
        //   // title: "视频",
        //   width: "8.3%",
        //   align: "left",
        //   slots: { title: "videoTitle" },
        //   scopedSlots: { customRender: "video" },
        // },
        // {
        //   title: "直播",
        //   width: "5.3%",
        //   align: "left",
        //   scopedSlots: { customRender: "live" },
        // },
        {
          title: "视频活跃度",
          width: "9%",
          align: "left",
          scopedSlots: { customRender: "videoActivity" },
        },
        {
          title: "直播活跃度",
          width: "9%",
          align: "left",
          scopedSlots: { customRender: "liveActivity" },
        },
        {
          title: "平台",
          width: "5.3%",
          align: "left",
          scopedSlots: { customRender: "platform" },
        },
        {
          title: "账号等级",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "level" },
        },
        {
          key: "action",
          title: "操作",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      tableData2: [],
      childrenData: [],
      expandedRowKeys: [],
      currentRow: {},
      current: 1,
      pageSize: 20,
      total: 0,
      storeModalShow: false,
      addModalShow: false,
      addGroupValue: "",
      tableLoading: false,
      tableStoreLoading: false,

      isFirstInit: true,
    };
  },
  computed: {
    ...mapState({
      storeData: (state) => state.storeData,
      accountData: (state) => state.accountData,
      areaList: (state) => state.areaList,
      groupList: (state) => state.groupList,
      multipleData: (state) => state.multipleData,
    }),
  },
  watch: {
    storeData(val) {
      this.tableData = val.list;
      this.total = val.pagination.total;
    },
    accountData(val) {
      this.tableData2 = val.list;
      this.total = val.pagination.total;
    },
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions([
      "getStoreList",
      "getAccountList",
      "getAreaList",
      "getSortGroup",
      "getDealerGroup",
    ]),
    async initData() {
      Promise.all([this.getAreaList(), this.getSortGroup()]).then(() => {
        console.log(this.areaList);
        if (!this.areaList && this.areaList.length == 0) {
          this.$message.warning("当前账号未绑定大区，请联系管理员");
          return false;
        }
        this.area_id = this.areaList[0].id;
      });
    },
    async loadStoreList(isFirst) {
      this.tableStoreLoading = true;
      if (isFirst) {
        this.current = 1;
      }
      // 关闭当前行
      this.currentRow = {};
      // 清空子表格数据
      this.expandedRowKeys = [];
      await this.getStoreList({
        area_id: this.area_id || "",
        group_code: this.group_code || "",
        word: this.storeWord,
        current: this.current,
        page_size: this.pageSize,
        platform: this.currentTab,
      });
      this.tableStoreLoading = false;
    },
    async loadAccountList(isFirst) {
      if (isFirst) {
        this.current = 1;
      }
      this.tableLoading = true;
      await this.getAccountList({
        area_id: this.area_id || "",
        group_code: this.group_code || "",
        word: this.accountWord,
        account_type: this.account_type,
        verification_type: this.verification_type,
        current: this.current,
        page_size: this.pageSize,
        platform: this.currentTab,
      });
      this.tableLoading = false;
    },
    loadList(isFirst) {
      this.expandedRowKeys = [];
      if (this.currentBtn == 1) {
        this.loadStoreList(isFirst);
      } else {
        this.loadAccountList(isFirst);
      }
    },
    // 平台切换
    async tabChange(val) {
      console.log(val);
      this.expandedRowKeys = [];
      this.currentTab = val;
      if (this.isFirstInit) {
        this.isFirstInit = false;
        await this.initData();
      }
      this.loadList(true);
    },
    // 搜门店/搜账号切换
    btnChange(val) {
      if (val) {
        this.currentBtn = val;
      }
      this.loadList(true);
    },

    pageChange(num) {
      this.loadList();
    },
    multipleChange(index) {
      this.multipleData[index].favorite = this.multipleData[index].favorite
        ? 0
        : 1;
    },
    // 关闭分组
    closeModal() {
      this.storeModalShow = false;
    },
    // 获取经销商相关分组
    starClick(id) {
      console.log("dealer_id", id);
      this.dealer_id = id;
      let params = {
        dealer_id: id,
      };
      this.getDealerGroup({ params });
      this.storeModalShow = true;
    },
    hideAllModal() {
      this.storeModalShow = false;
      this.addModalShow = false;
    },
    // 展开添加
    showAddGroup() {
      this.addModalShow = true;
      this.addGroupValue = "";
    },
    // 添加分组
    addGroupDealer() {
      if (this.addGroupValue.length <= 0) {
        this.$message.warning("请输入分组名");
        return false;
      }
      if (!!this.addGroupValue) {
        let params = {
          new_or_copy: 0,
          name: this.addGroupValue,
        };
        this.addModalShow = false;

        _http.addShopGroup(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("新增分组成功！");
            this.starClick(this.dealer_id);
          } else {
            this.$message.error("新增分组失败！");
          }
        });
      } else {
        this.addModalShow = false;
      }
    },
    // 确认绑定分组
    groupBindDealer() {
      let data = {
        dealer_id: this.dealer_id,
        group_list: this.multipleData,
      };
      _http.addCollectGrounp(data).then((res) => {
        if (res.data.code == 0) {
          this.storeModalShow = false;
          this.$message.success("绑定分组成功！");
          this.loadStoreList(true);
        } else {
          this.$message.error("绑定分组失败！");
        }
      });
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            console.log(record, 444);
            if (record.author_id) {
              return false;
            }
            window.open(
              `/dealer/dealerDetail?id=${record.dealer_id}&platform=${this.currentTab}`
            );
          },
        },
      };
    },
    arrowClick(record) {
      console.log(record, 444);
      if (!record.children) {
        return false;
      }
      if (record.children.length <= 0) {
        this.$message.warning("当前门店没有绑定的账号");
        return false;
      }
      if (this.currentRow == record) {
        console.log("yiyang111111");
        console.log(this.currentRow);
        // 关闭当前行
        this.currentRow = {};
        // 清空子表格数据
        this.expandedRowKeys = [];
      } else {
        console.log("buyiyang122222");
        console.log(this.currentRow);
        // 展开当前行
        this.currentRow = record;
        this.expandedRowKeys = [String(record.dealer_id)];
      }
    },
    //table行class
    rowClassName(record, index) {
      let className = "";
      if (record == this.currentRow) {
        className = "rowActive";
      }
      return className;
    },
    routerToAccount(obj) {
      console.log(obj);
      const routerPath = this.$router.resolve({
        path: "/dealer/production",
        query: {
          type: this.currentTab,
          author_id: obj.author_id,
        },
      });
      window.open(routerPath.href, "_blank");
    },
    back() {
      this.$router.push({
        path: "storeCollection",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
  position: relative;

  .beta-icon {
    width: 36px;
    height: 14px;
    border-radius: 7px;
    background: #ff4160;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: 4px;
    right: 0px;
  }
}

.title {
  font-weight: bold;
  font-size: 20px;
  padding: 0px 24px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @include font_color("font_color2");
  > div {
    width: 88px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    @include font_color("font_color6");
    @include border_color("border_color10");
  }
}
.tab-tools {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 14px;
}
.tools {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.content {
  padding: 0 24px;
  .table-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      margin: 0 4px 0 6px;
      max-width: calc(100% - 40px);
      display: inline-block;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  .table-account {
    > img {
      margin-right: 8px;
    }
    display: flex;
    flex-direction: row;
    .nickname {
      width: 100%;
      display: inline-block;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  .table-action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.store-modal {
  position: absolute;
  width: 100%;
  height: calc(100vh - 75px);
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 100;
  &-container {
    position: absolute;
    right: 24px;
    bottom: 115px;
    width: 272px;
    height: 412px;
    border-radius: 4px;
    @include background_color("background_color27");
    @include font_color("font_color2");
    font-size: 14px;
    &-title {
      font-size: 16px;
      padding: 24px 0 20px;
      text-align: center;
    }
    .list {
      padding: 0 32px;
      height: 280px;
      overflow-y: auto;
    }
    .action-div {
      padding: 16px 12px 16px 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      > div {
        cursor: pointer;
        height: 32px;
        line-height: 18px;
        @include font_color("font_color2");
        .icon {
          font-size: 30px;
          @include font_color("font_color4");
          position: relative;
          top: 3px;
        }
      }
      .btn {
        width: 60px;
        height: 32px;
        border-radius: 4px;
        @include background_color("background_color5");
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        cursor: pointer;
      }
      .btn1 {
        @include font_color("font_color3");
        margin: 0 8px 0 20px;
      }
      .btn2 {
        @include font_color("font_color1");
      }
    }
  }
}
.add-modal {
  position: absolute;
  right: 24px;
  bottom: 115px;
  width: 272px;
  height: 412px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  z-index: 110;
  &-container {
    position: absolute;
    bottom: 0;
    left: 0;
    @include background_color("background_color29");
    width: 100%;
    height: 116px;
    padding: 24px;
    .action-div {
      display: flex;
      justify-content: space-around;
      > span {
        padding: 16px;
        margin-top: 8px;
        cursor: pointer;
        @include font_color("font_color3");
      }
      > span.active {
        @include font_color("font_color2");
      }
    }
  }
}

.search-btn {
  @include border_color("border_color4");
  @include font_color("font_color17");
  @include background_color("background_color13");
  width: 80px;
  height: 40px;
  border-radius: 8px;
  // margin-left: 12px;
}
.rowActive {
  @include background_color("background_color42");
}
.page-div {
  text-align: right;
  padding: 20px 24px 20px 0;
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color3 {
  @include font_color("font_color3");
}
.font-color4 {
  @include font_color("font_color4");
}
.font-color5 {
  @include font_color("font_color5");
}
.font-color6 {
  @include font_color("font_color6");
  font-size: 24px;
}
.font-color9 {
  @include font_color("font_color9");
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
</style>
