<template>
  <div>
    <div class="title-div">
      <span>账号详情</span>
    </div>
    <!-- 顶部公用账号详情、矩阵等信息 -->
    <AccountDetailN />
    <!-- tools -->
    <div class="tools">
      <NewTabs
        :tabData="$route.query.type == 'dongchedi' ? btnTabsList2 : btnTabsList"
        @tabChange="handleBtnTabsChange"
        :labelStyle="{ lineHeight: '44px' }"
      />
      <DateTabAll
        v-if="listTab != '4'"
        :type="currentDateParams.type"
        :typeArray="['week', 'month']"
        :monthSubtract="0"
        @changeDate="changeDate"
        ref="dateTab"
      />
      <DateTabAll
        v-else
        :type="currentDateParams2.type"
        :typeArray="['week']"
        @changeDate="changeDate2"
        ref="dateTab2"
      />
    </div>
    <div class="tab-main-container">
      <LiveListN
        ref="LiveListN"
        v-if="$route.query.type != 'dongchedi'"
        v-show="listTab == '2'"
      />
      <VideoListN ref="VideoListN" v-show="listTab == '3'" />
      <FansPortraitN
        ref="FansPortraitN"
        v-if="$route.query.type != 'dongchedi'"
        v-show="listTab == '4'"
      />
      <ClueListN
        ref="ClueListN"
        v-if="$route.query.type != 'dongchedi'"
        v-show="listTab == '5'"
      />
    </div>
  </div>
</template>

<script>
import AccountDetailN from "./components/AccountDetailN";
import LiveListN from "./components/LiveListN";
import VideoListN from "./components/VideoListN";
import ClueListN from "./components/ClueListN";
import FansPortraitN from "./components/FansPortraitN";

import NewTabs from "@/components/PlatformTab/NewTabs";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import DateTabAll from "@/components/DateTab/DateTabAll";
const btnTabsList = [
  {
    label: "视频",
    value: "3",
  },
  {
    label: "直播",
    value: "2",
  },
  {
    label: "粉丝",
    value: "4",
  },
  {
    label: "线索",
    value: "5",
  },
];
const btnTabsList2 = [
  {
    label: "视频",
    value: "3",
  },
];
export default {
  components: {
    AccountDetailN,
    LiveListN,
    VideoListN,
    ClueListN,
    FansPortraitN,
    NewTabs,
    ButtonGroup,
    DateTabAll,
  },
  data() {
    return {
      btnTabsList,
      btnTabsList2,
      listTab: "3",
      currentDateParams: {
        endTime: moment()
          .subtract(0, "months")
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        startTime: moment()
          .subtract(0, "months")
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        type: "month",
      },
      currentDateParams2: {
        endTime: moment()
          .subtract(1, "week")
          .day(7)
          .format("YYYY-MM-DD HH:mm:ss"),
        startTime: moment()
          .subtract(1, "week")
          .day(1)
          .format("YYYY-MM-DD HH:mm:ss"),
        type: "week",
      },
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    // 直播、视频切换
    handleBtnTabsChange(val) {
      console.log("直播、视频切换", val);
      if (this.listTab == val) {
        return false;
      }
      this.listTab = val;
      if (this.listTab == "3") {
        this.$refs.VideoListN.resizeChart();
      }
      if (this.listTab == "2") {
        this.$refs.LiveListN.resizeChart();
      }
      if (this.listTab == "4") {
        this.$refs.FansPortraitN.resizeChart();
      }
      if (this.listTab == "5") {
        this.$refs.ClueListN.resizeChart();
      }
    },
    // 切换日期
    changeDate(data) {
      console.log("changeDate1", data);
      this.currentDateParams = data;

      this.$refs.VideoListN.initData();
      this.$refs.LiveListN.initData();
      this.$refs.ClueListN.initData();
    },
    // 切换日期
    changeDate2(data) {
      console.log("changeDate2", data);
      this.currentDateParams2 = data;

      this.$refs.FansPortraitN.initData();
    },
    // 获取参数
    getParams() {
      let params = {
        type: this.$route.query.type,
        author_id: this.$route.query.author_id,
        start_time: this.currentDateParams.startTime,
        end_time: this.currentDateParams.endTime,
        dateType:
          this.currentDateParams.type == "month"
            ? 3
            : this.currentDateParams.type == "week"
            ? 2
            : 1,
      };
      return params;
    },
    // 获取参数
    getFansParams() {
      let params = {
        type: this.$route.query.type,
        author_id: this.$route.query.author_id,
        start_time: this.currentDateParams2.startTime,
        end_time: this.currentDateParams2.endTime,
        dateType: 2,
      };
      return params;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.title-div {
  font-size: 20px;
  @include font_color("font_color20");
  height: 80px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tools {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  margin: 30px 0 20px;
  display: flex;
  justify-content: space-between;
}
.tab-main-container {
  padding: 0 20px;
}
</style>