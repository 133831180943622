<template>
  <div class="brand-ranking-container" :style="{ width: width }">
    <WebpImage
      :scale="scale"
      :src="avatar"
      :width="width"
      :height="height"
      :radius="radius"
    />
    <div class="name" v-if="name" :style="{ color: textColor }">{{ name }}</div>
    <span
      :class="
        self
          ? `ranking-tag ranking-tag-self`
          : `ranking-tag ranking-tag-${ranking}`
      "
      v-if="ranking"
      >{{ ranking }}</span
    >
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "BrandRanking",
  components: {
    WebpImage,
  },
  props: {
    avatar: {
      type: String,
      default: "",
    },
    scale: {
      type: String,
      default: "1-1",
    },
    width: {
      type: String,
      default: "60px",
    },
    height: {
      type: String,
      default: "60px",
    },
    radius: {
      type: String,
      default: "16px",
    },
    ranking: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "#D0D6E6",
    },
    self: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";
.brand-ranking-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .name {
    color: #d0d6e6;
    font-size: 12px;
    margin-top: 12px;
    width: 48px;
    text-align: center;
  }
  .ranking-tag {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    line-height: 22px;
    text-align: center;
    color: #ffca5c;
    background: #3c4252;
    font-size: 12px;
    top: -8px;
    right: -8px;
  }
  .ranking-tag.ranking-tag-1 {
    background: #ffca5c;
    color: #1c1e27;
  }
  .ranking-tag.ranking-tag-2 {
    background: #b7ccf9;
    color: #1c1e27;
  }
  .ranking-tag.ranking-tag-3 {
    background: #de9c6e;
    color: #1c1e27;
  }
  .ranking-tag.ranking-tag-self {
    color: #ffca5c;
    font-weight: bold;
    background: #3c4252;
  }
}
</style>
