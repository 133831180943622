<template>
  <div class="best-author-card" ref="card">
    <div class="best-author-card-top">
      <span class="title">{{ title }}</span>
      <ButtonTab
        :tabData="buttonData"
        @tabChange="buttonChange"
        :defaultBtnStyle="buttonStyle"
      />
    </div>
    <div class="best-author-card-list" ref="scrollList">
      <AuthorItem
        :type="String(index + 1)"
        :accountType="String(item.account_type)"
        v-for="(item, index) in list"
        :key="String(index + 1)"
        :authorData="getAuthorData(item)"
        :showModal="false"
      />
    </div>
    <Empty
      :title="'暂无数据'"
      v-if="!list || list.length == 0"
    />
    <div class="scroll-button left-button" @click="leftScroll">
      <svg-icon icon-class="arrow-down" class="icon" />
    </div>
    <div class="scroll-button right-button" @click="rightScroll">
      <svg-icon icon-class="arrow-down" class="icon" />
    </div>
  </div>
</template>

<script>
import ButtonTab from "@/components/PlatformTab/NewButtonTab";
import AuthorItem from "@/components/Item/AuthorItem";
import Empty from "@/components/Empty/Empty";

const buttonStyle = {
  minWidth: "74px",
  width: "74px",
  height: "26px",
  "line-height": "26px",
  fontSize: "12px",
  "border-radius": "8px",
  background: "#242832",
};
export default {
  name: "BestAuthor",
  props: {
    title: {
      type: String,
      default: "-",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // fans/live/video
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    ButtonTab,
    AuthorItem,
    Empty,
  },
  data() {
    return {
      buttonStyle,
      buttonData: [
        {
          label: "按粉丝量",
          value: "2",
        },
        {
          label: "按播放量",
          value: "1",
        },
      ],
      currentButtonData: "2",
    };
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {
    buttonChange(val) {
      this.currentButtonData = val;
      this.$refs.scrollList.style.left = "0px";
      this.$emit("changeTab", val);
    },
    getAuthorData(item) {
      let { avatar, author_id, dealer_name, nickname } = item;
      let obj = {
        avatar,
        author_id,
        dealer_name,
        nickname,
        platform: this.$route.query.platform,
      };
      if (this.type == "fans") {
        obj.data = [
          { name: "累计增粉:", value: item.fans_count_increase },
          { name: "粉丝总量:", value: item.fans_count },
        ];
      } else if (this.type == "live") {
        obj.data = [
          { name: "观看人数:", value: item.live_watch_count },
          { name: "观看人次:", value: item.live_watch_time },
          { name: "直播场次:", value: item.live_count },
        ];
      } else if (this.type == "video") {
        obj.data = [
          { name: "累计播放:", value: item.play_count },
          { name: "视频发布:", value: item.aweme_count },
        ];
      }
      return obj;
    },
    rightScroll() {
      let scrollWidth = 100 * 4;
      let listLeft = this.$refs.scrollList.offsetLeft;
      let totalWidth = this.$refs.scrollList.offsetWidth;
      let cardWidth = this.$refs.card.offsetWidth;
      if (totalWidth <= cardWidth) {
        return false;
      }
      if (Math.abs(listLeft) + scrollWidth + cardWidth > totalWidth) {
        this.$refs.scrollList.style.left = cardWidth - totalWidth + "px";
      } else {
        this.$refs.scrollList.style.left = listLeft - scrollWidth + "px";
      }
    },
    leftScroll() {
      let scrollWidth = 100 * 4;
      let listLeft = this.$refs.scrollList.offsetLeft;
      if (listLeft + scrollWidth > 0) {
        this.$refs.scrollList.style.left = "0px";
      } else {
        this.$refs.scrollList.style.left = listLeft + scrollWidth + "px";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.best-author-card {
  width: 100%;
  height: 182px;
  background: #2b2f3b;
  border-radius: 16px;
  padding: 26px 0px 20px 20px;
  position: relative;
  overflow: hidden;
  &-top {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      margin-right: 20px;
    }
  }
  &-list {
    position: absolute;
    left: 0;
    top: 55px;
    height: 92px;
    padding-left: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    transition: all 0.4s;
    > div {
      margin-right: 30px;
    }
  }
  .scroll-button {
    position: absolute;
    top: 27px;
    width: 24px;
    height: 24px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.left-button {
      right: 56px;
      transform: rotate(90deg);
    }
    &.right-button {
      right: 20px;
      transform: rotate(-90deg);
    }
    &:hover {
      color: #fff;
    }
  }
}
</style>