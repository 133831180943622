<template>
  <div class="user-card-container">
    <div class="avater-box" @click="openModal">
      <WebpImage
        :src="infoData.avatar"
        :width="'64px'"
        :height="'64px'"
        :radius="'50%'"
      />
      <div class="shadow-box">详情</div>
    </div>
    <a-modal
      :visible="userVisible"
      :width="671"
      :closable="false"
      :bodyStyle="{ padding: 0 }"
      :footer="null"
      @cancel="handleCancel"
    >
      <div class="user-modal-container">
        <div class="user-modal-container-left">
          <div class="user-modal-header">
            <div class="header-box1">
              <div class="label">购车预算</div>
              <div class="value">{{ infoData.budget || "-" }}</div>
            </div>
            <div class="header-box2">
              <div class="label">城市</div>
              <div class="value">{{ infoData.city || "-" }}</div>
            </div>
            <div class="header-box3">
              <div class="label">留资状态</div>
              <svg-icon
                :icon-class="'resources-true'"
                v-if="infoData.tel"
                class="icon"
              />
              <svg-icon :icon-class="'resources-false'" v-else class="icon" />
            </div>
            <WebpImage
              :src="infoData.avatar"
              :width="'60px'"
              :height="'60px'"
              :radius="'50%'"
            />
          </div>
          <div class="user-modal-series">
            <div class="user-modal-series-left">
              <div class="title">近90天意向车系</div>
              <div class="series-box">
                <div
                  class="series-img"
                  v-for="(item, index) in infoData.series_list.slice(0, 4)"
                  :key="index"
                >
                  <WebpImage
                    :src="ossHost + `car_img/${item.series_id}.png`"
                    :scale="'-series2'"
                    :width="'116px'"
                    :height="'auto'"
                    :radius="'8px'"
                  />
                </div>
              </div>
            </div>
            <div class="user-modal-series-right">
              <div class="title">近期意向车系</div>
              <div class="series-box">
                <div
                  class="series-img"
                  v-for="(item, index) in infoData.near_series_list.slice(0, 2)"
                  :key="index"
                >
                  <WebpImage
                    :src="ossHost + `car_img/${item.series_id}.png`"
                    :scale="'-series2'"
                    :width="'116px'"
                    :height="'auto'"
                    :radius="'8px'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-modal-container-right">
          <div class="close-box">
            <a-icon type="close" class="icon" />
          </div>
          <div class="label">用户昵称</div>
          <div class="value1">{{ infoData.nickname || "-" }}</div>
          <div class="label">粉丝ID</div>
          <div class="value2">{{ infoData.fans_id || "-" }}</div>
          <div class="label">最近活跃</div>
          <div class="value3">{{ infoData.last_time || "-" }}</div>
          <div class="label">购车意向</div>
          <div class="modal-series-tag">
            <div
              class="tag-item"
              v-for="(item, index) in infoData.series_list"
              :key="index"
            >
              {{ item.series_name || "-" }}
            </div>
            <div
              class="tag-item"
              v-for="(item, index) in infoData.near_series_list"
              :key="'near' + index"
            >
              {{ item.series_name || "-" }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "UserCard",
  components: {
    WebpImage,
  },
  props: {
    infoData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      userVisible: false,
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    openModal() {
      console.log(this.infoData);
      this.userVisible = true;
    },

    handleCancel() {
      this.userVisible = false;
    },
  },
};
</script>
<style lang="scss" scope>
.user-card-container {
  width: 64px;
  height: 64px;
  cursor: pointer;
  .avater-box {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    .shadow-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      color: #ffffff;
      line-height: 64px;
      text-align: center;
      display: none;
    }
    &:hover {
      .shadow-box {
        display: inline-block;
      }
    }
  }
}
.user-modal-container {
  width: 671px;
  height: 441px;
  background: #1c1e27;
  display: flex;
  flex-wrap: nowrap;
  &-left {
    width: 471px;
    height: 100%;
    padding: 0 30px;
    .user-modal-header {
      width: 100%;
      height: 120px;
      border-bottom: 1px dashed #303136;
      display: flex;
      align-items: center;
      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        > .label {
          font-size: 14px;
          color: #8890a6;
          line-height: 22px;
        }
        > .value {
          font-size: 24px;
          color: #ffcc55;
          line-height: 32px;
          margin-top: 1px;
        }
        > .icon {
          font-size: 24px;
          margin-top: 5px;
        }
      }
      > .header-box1 {
        width: 139px;
        height: 60px;
      }
      > .header-box2 {
        width: 102px;
        height: 60px;
      }
      > .header-box3 {
        width: 110px;
        height: 60px;
      }
    }
    .user-modal-series {
      width: 100%;
      height: 270px;
      display: flex;
      flex-wrap: nowrap;
      margin-top: 20px;
      > div {
        .title {
          height: 22px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
          margin-bottom: 8px;
        }
      }
      &-left {
        width: 272px;
        height: 100%;
        border-right: 1px dashed #303136;
        .series-box {
          height: 240px;
          width: 100%;
          display: grid;
          grid-template-columns: 116px 116px;
          grid-template-rows: 87px 87px;
          column-gap: 16px;
          row-gap: 16px;
          .series-img {
            width: 116px;
            height: 87px;
            display: flex;
            align-items: center;
          }
        }
      }
      &-right {
        width: 139px;
        height: 100%;
        padding-left: 20px;
        .series-box {
          height: 240px;
          width: 100%;
          display: grid;
          grid-template-columns: 116px;
          grid-template-rows: 87px;
          column-gap: 16px;
          row-gap: 16px;
          .series-img {
            width: 116px;
            height: 87px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  &-right {
    width: 200px;
    height: 100%;
    border-left: 1px solid #303136;
    background: #242832;
    padding-left: 26px;
    .close-box {
      height: 54px;
      width: 100%;
      padding: 20px 0 10px 130px;
      .icon {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.3);
        padding: 5px;
        cursor: pointer;
      }
    }
    > .label {
      font-size: 14px;
      color: #8890a6;
      line-height: 22px;
      margin-bottom: 4px;
    }
    > .value1 {
      font-size: 14px;
      color: #ffffff;
      line-height: 22px;
      margin-bottom: 20px;
    }
    > .value2 {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
      margin-bottom: 20px;
    }
    > .value3 {
      font-size: 24px;
      font-weight: 500;
      color: #ffcc55;
      line-height: 32px;
      margin-bottom: 20px;
    }
    .modal-series-tag {
      height: 147px;
      overflow-y: auto;
      > .tag-item {
        padding: 0 8px;
        height: 24px;
        background: rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 24px;
        text-align: center;
        min-width: 55px;
        margin-bottom: 4px;
        margin-right: 4px;
        display: inline-block;
      }
    }
  }
}
</style>
