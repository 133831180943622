<template>
  <div class="board-card" @click="routerTo">
    <div
      class="card-main"
      :style="{
        'background-image': `radial-gradient(
      rgba(${color}, 0.1) 10%,
      rgba(0, 0, 0, 0) 70%
    )`,
      }"
    >
      <div class="card-main-left">
        <div class="title">{{ title }}</div>
        <div class="number">{{ number }}</div>
        <div class="ratio">
          <span :class="ratio >= 0 ? 'fc-up' : 'fc-down'">{{
            ratio | numRateAbs
          }}</span>
          <svg-icon
            v-if="ratio"
            class="ratio-icon"
            :icon-class="ratio >= 0 ? 'ratio-up' : 'ratio-down'"
          />
        </div>
      </div>
      <div class="card-main-right">
        <my-chart
          :options="getLineOption(chartData.xData, chartData.yData)"
          :id="`chart${title}`"
        ></my-chart>
      </div>
    </div>
    <div class="router-main">
      <span>{{ routerName }}</span>
      <svg-icon class="icon" icon-class="arrow-right" />
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import cardLine from "./options/cardLine";
export default {
  name: "BoardCard",
  components: {
    MyChart,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {
          xData: [],
          yData: [],
        };
      },
    },
    color: {
      type: String,
      default: "255,255,255",
    },
    title: {
      type: String,
      default: "-",
    },
    number: {
      default: "-",
    },
    ratio: {
      type: Number,
      default: 0,
    },
    routerName: {
      type: String,
      default: "-",
    },
  },
  methods: {
    getLineOption(xData, yData) {
      let option = deepClone(cardLine);
      option.xAxis.data = xData;
      option.series[0].data = yData;
      option.series[0].itemStyle.normal.color = `rgba(${this.color})`;

      return option;
    },
    routerTo() {
      this.$emit('routerTo')
    }
  },
};
</script>

<style lang="scss" scoped>
.board-card {
  width: 100%;
  height: 170px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  .card-main {
    width: 100%;
    height: 118px;
    background-repeat: no-repeat;
    background-position: calc(100% + 118px) bottom;
    background-size: 236px 236px;
    // background-image: radial-gradient(
    //   rgba(128, 220, 30, 0.1) 10%,
    //   rgba(0, 0, 0, 0) 70%
    // );
    display: flex;
    flex-wrap: nowrap;
    &-left {
      width: 122px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 16px;
      .title {
        color: #9ca8ba;
        font-size: 14px;
      }
      .number {
        font-size: 26px;
        margin: 12px 0 6px;
        color: #ffffff;
        font-weight: bold;
      }
      .ratio {
        display: flex;
        align-items: center;
        font-size: 14px;
        .fc-up {
          color: #e85a34;
        }
        .fc-down {
          color: #45b36b;
        }
        &-icon {
          font-size: 10px;
          margin-left: 4px;
          margin-top: 4px;
        }
      }
    }
    &-right {
      width: calc(100% - 122px);
      height: 100%;
    }
  }
  .router-main {
    width: 100%;
    height: 52px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #9ca8ba;
    padding: 0 16px;
    transition: all 0.3s;
    .icon {
      font-size: 30px;
    }
  }
  &:hover {
    .router-main {
      background: #000000;
      color: #fff;
    }
  }
}
</style>
