/** 
* tabData: 按钮组数据
* color: 按钮文字色
* activeColor: 选中时按钮文字色
* tabChange: 点击按钮触发的方法
* example: <platform-tab :tabData="tabData" @tabChange="tabChange"></platform-tab>
*/

<template>
  <div>
    <span
      v-for="(item, index) in tabData"
      :key="index"
      :class="currentClick == item.value ? 'defult-color active' : 'defult-color'"
      :style="{
        'cursor': 'pointer',
        'margin-right': '12px',
      }"
      @click="tabClick(item.value)"
    ><svg-icon :icon-class="item.value" class="icon"></svg-icon>{{item.label}}</span>
  </div>
</template>

<script>
export default {
  name: "PlatformTab",
  props: {
    tabData: {
      type: Array,
    },
    color: {
      type: String,
      default: "#d1f786",
    },
    activeColor: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {
      currentClick: this.tabData[0].value,
    };
  },
  created() {},
  computed: {},
  watch: {
  },
  methods: {
    tabClick(value) {
      this.currentClick = value;
      this.$emit("tabChange", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_handle.scss';
.defult-color {
  @include font_color("font_color3");
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
  margin-left: 32px;
  .icon {
    font-size: 24px;
    display: none;
  }
}
.defult-color.active {
  @include font_color("font_color5");
  .icon {
    display: inline-block;
  }
}
</style>
