import filters from "@/filters";

const columns1_1 = [
  {
    title: "排名",
    width: 76,
    scopedSlots: { customRender: "num" },
    align: "center",
    // fixed: "left",
  },
  {
    dataIndex: "dealer_company_name",
    title: "门店名称",
    width: 400,
    scopedSlots: { customRender: "account" },
    // fixed: "left",
  },
  {
    dataIndex: "live",
    title: "直播",
    // width: 100,
    align: "left",
    customRender: function(text, record, index, node, title = "直播") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "aweme",
    title: "视频",
    // width: 100,
    align: "left",
    customRender: function(text, record, index, node, title = "视频") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "homepage",
    title: "个人主页",
    // width: 100,
    align: "left",
    customRender: function(text, record, index, node, title = "个人主页") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "private_letter",
    title: "私信",
    // width: 100,
    align: "left",
    customRender: function(text, record, index, node, title = "私信") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "other",
    title: "其他",
    // width: 100,
    align: "left",
    customRender: function(text, record, index, node, title = "其他") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "total",
    title: "合计",
    key: "total",
    // width: 100,
    align: "left",
    customRender: function(text, record, index, node, title = "合计") {
      return renderFun(text, record, index, node, title);
    },
  },
];

const columns1_2 = [
  {
    title: "排名",
    width: 76,
    scopedSlots: { customRender: "num" },
    align: "center",
    // fixed: "left",
  },
  {
    dataIndex: "dealer_company_name",
    title: "门店名称",
    width: 400,
    scopedSlots: { customRender: "account" },
    // fixed: "left",
  },
  {
    dataIndex: "intended_car_series",
    title: "有意向车系",
    // width: 135,
    align: "center",
    customRender: function(text, record, index, node, title = "有意向车系") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "no_intended_car_series",
    title: "无意向车系",
    // width: 135,
    align: "center",
    customRender: function(text, record, index, node, title = "无意向车系") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "total",
    title: "合计",
    // width: 135,
    align: "center",
    customRender: function(text, record, index, node, title = "合计") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    key: "action",
    title: "操作",
    width: 100,
    align: "left",
    scopedSlots: { customRender: "action2" },
    // fixed: "right",
  },
];

const columns1_3 = [
  {
    title: "排名",
    width: 76,
    scopedSlots: { customRender: "num" },
    align: "center",
    fixed: "left",
  },
  {
    dataIndex: "dealer_company_name",
    title: "门店名称",
    width: 400,
    scopedSlots: { customRender: "account" },
    fixed: "left",
  },
  {
    dataIndex: "local_city_count",
    title: "同城",
    width: 135,
    align: "center",
    customRender: function(text, record, index, node, title = "同城") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "diff_city_same_province_count",
    title: "异地（省内）",
    width: 135,
    align: "center",
    customRender: function(text, record, index, node, title = "异地") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "diff_city_diff_province_count",
    title: "异地（省外）",
    width: 135,
    align: "center",
    customRender: function(text, record, index, node, title = "异地") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    dataIndex: "unknow_city_count",
    title: "无明确购车地",
    width: 135,
    align: "center",
    customRender: function(text, record, index, node, title = "无明确购车地") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    title: "合计",
    dataIndex: "total",
    key: "total",
    width: 135,
    align: "center",
    customRender: function(text, record, index, node, title = "线索总数") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    key: "action",
    title: "操作",
    width: 100,
    align: "left",
    scopedSlots: { customRender: "action2" },
    fixed: "right",
  },
];

const columns2 = [
  {
    title: "排名",
    width: "6%",
    scopedSlots: { customRender: "num" },
    align: "center",
  },
  {
    dataIndex: "dealer_company_name",
    title: "门店名称",
    width: "19%",
    scopedSlots: { customRender: "account" },
  },
  {
    dataIndex: "dealer_store_code",
    title: "门店编码",
    width: "8%",
    scopedSlots: { customRender: "dealer_store_code" },
    align: "center",
  },
  {
    dataIndex: "live_total_watch_user_time",
    slots: { title: "watchTitle" },
    width: "7%",
    align: "center",
    scopedSlots: { customRender: "live_total_watch_user_time" },
  },
  {
    dataIndex: "aweme_play_volume_total_di",
    slots: { title: "customTitle1" },
    width: "8%",
    align: "center",
    scopedSlots: { customRender: "aweme_play_volume_total_di" },
  },
  // {
  //   dataIndex: "private_letter_count",
  //   title: "私信",
  //   width: "8%",
  //   align: "center",
  //   customRender: (text, record, index, node, title = "私信") => {
  //     return renderFun(text, record, index, node, title);
  //   },
  // },
  {
    dataIndex: "voice_volume_cue_count",
    title: "声量线索",
    width: "8%",
    align: "center",
    customRender: function(text, record, index, node, title = "声量线索") {
      return renderFun(text, record, index, node, title);
    },
  },
  {
    key: "action",
    title: "操作",
    width: "8%",
    align: "left",
    scopedSlots: { customRender: "action" },
  },
];

const sum1 = [
  {
    label: "直播线索",
    value: "live",
  },
  {
    label: "视频线索",
    value: "aweme",
  },
  {
    label: "个人主页线索",
    value: "homepage",
  },
  {
    label: "私信线索",
    value: "private_letter",
  },
  {
    label: "其他",
    value: "other",
  },
];

const sum2 = [
  {
    label: "有意向车系",
    value: "intended_car_series",
  },
  {
    label: "无意向车系",
    value: "no_intended_car_series",
  },
];

const sum3 = [
  {
    label: "同城",
    value: "local_city_count",
  },
  {
    label: "异地（省内）",
    value: "diff_city_same_province_count",
  },
  {
    label: "异地（省外）",
    value: "diff_city_diff_province_count",
  },
  {
    label: "无明确购车地",
    value: "unknow_city_count",
  },
];

function renderFun(text, record, index, node, title) {
  let className = "td-hover-number";
  if (title == "线索总数" || title == "声量线索") {
    className = "td-hover-number fc26";
  }
  // return (
  //   <div class={className}>
  //     <span>{filters.changeNum(text)}</span>
  //     <span class="bottom-title table-show-icon">{title}</span>
  //   </div>
  // );
  return filters.changeNum(text);
}
export { columns1_1, columns1_2, columns1_3, columns2, sum1, sum2, sum3 };

// export const settingData = [
//   settingData_0,
//   settingData_1,
//   settingData_2,
//   settingData_3
// ]
// export const columnsList = [
//   columns_0,
//   columns_1,
//   columns_2,
//   columns_3
// ]

// export const apiList = [
//   "getHonorListByDealer",
//   "getHonorListByAweme",
//   "getHonorListByLive",
//   "getHonorListByRegion"
// ]

// export const exportApiList = [
//   "/jjt/activity/honor/roll/dealer/export",
//   "/jjt/activity/honor/roll/aweme/export",
//   "/jjt/activity/honor/roll/live/export",
//   "/jjt/activity/honor/roll/region/export"
// ]
