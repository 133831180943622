<template>
  <div style="width: 100%; height: calc(100vh - 65px); overflow: auto">
    <div class="window-min-width">
      <platform-header
        title="大区首页"
        :currentTab="currentTab"
        @tabChange="tabChange"
      />
      <div class="main-container">
        <DataOverview
          :platform="currentTab"
          @changeRegion="changeRegion"
          ref="DataOverview"
        />
        <!-- <div class="component-title">
          <div class="title">近期活动</div>
          <div class="more" @click="routerTo('/activityManagement')">
            <span>查看更多</span>
            <a-icon type="right" class="more-icon" />
          </div>
        </div>
        <NearActivity :platform="currentTab" ref="NearActivity" /> -->
        <div class="component-title">
          <div class="title">门店统计（{{ year }}-{{ month }}）</div>
          <div class="more" @click="routerTo('/dealer/storeCompetition')">
            <span>查看更多</span>
            <a-icon type="right" class="more-icon" />
          </div>
        </div>
        <StoreStatistics :platform="currentTab" ref="StoreStatistics" />
        <div class="component-title">
          <div class="title">竞品区域对比（{{ year }}-{{ month }}）</div>
          <div class="more" @click="routerTo('/dealer/regionContrast')">
            <span>查看更多</span>
            <a-icon type="right" class="more-icon" />
          </div>
        </div>
        <CompetitorsContrast :platform="currentTab" ref="CompetitorsContrast" />
      </div>
    </div>
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import DataOverview from "./components/DataOverview";
// import NearActivity from "./components/NearActivity";
import StoreStatistics from "./components/StoreStatistics";
import CompetitorsContrast from "./components/CompetitorsContrast";

export default {
  name: "regionHome",
  components: {
    PlatformHeader,
    DataOverview,
    // NearActivity,
    StoreStatistics,
    CompetitorsContrast,
  },
  data() {
    return {
      currentTab: "",
      year: moment()
        .subtract(1, "months")
        .format("YYYY"),
      month: moment()
        .subtract(1, "months")
        .format("MM"),
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    tabChange(val) {
      console.log("切换平台", val);
      this.currentTab = val;
      this.$nextTick(() => {
        if (this.$refs.DataOverview.currentRegionItem.id) {
          this.initAllData(this.$refs.DataOverview.currentRegionItem.id);
        }
      });
    },
    initAllData(id, name) {
      this.$refs.DataOverview.getInfo();
      this.$refs.DataOverview.getMatrixActivationData();
      this.$refs.DataOverview.getDetails();

      // this.$refs.NearActivity.getActivityList(id, name);
      this.$refs.StoreStatistics.getComponentsData(id);
      this.$refs.CompetitorsContrast.getComponentsData(id);
    },
    // 切换大区
    changeRegion(id, name) {
      this.initAllData(id, name);
    },
    // 跳转
    routerTo(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.window-min-width {
  min-width: 1218px;
}
.main-container {
  padding: 0 20px 20px;

  .component-title {
    height: 24px;
    width: 100%;
    margin: 32px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .title {
      font-size: 16px;
      color: #ffffff;
    }
    > .more {
      font-size: 14px;
      color: #8890a6;
      display: flex;
      align-items: center;
      cursor: pointer;
      .more-icon {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }
}
</style>
