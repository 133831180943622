var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-list-container"},[_c('div',{staticClass:"data-box"},[_c('div',{staticClass:"data-box-left"},[_c('div',{staticClass:"card-title"},[_vm._v("数据概览")]),(_vm.currentTarget)?_c('div',{staticClass:"card-tips"},[_c('span',[_vm._v(_vm._s(_vm.getArrayLastItem(_vm.currentTarget.targetChart).start_time.slice( 5, 10 ))+" - "+_vm._s(_vm.getArrayLastItem(_vm.currentTarget.targetChart).end_time.slice(5, 10))+"，"+_vm._s(_vm.currentTarget.targetName)+"：")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.getArrayLastItem(_vm.currentTarget.targetChart).value)))]),_c('span',[_vm._v("，环比增长：")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("numRate2")(_vm.getArrayLastItem(_vm.currentTarget.targetChart).ratio)))])]):_vm._e(),_c('div',{staticClass:"card-container"},_vm._l((_vm.targetData),function(item){return _c('div',{key:item.targetCountKey,class:[
            'card-container-item',
            _vm.currentTarget &&
            _vm.currentTarget.targetCountKey == item.targetCountKey
              ? 'active'
              : '',
          ],on:{"click":function($event){_vm.currentTarget = item}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.targetName))]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("changeNum")(item.targetCount)))])])}),0)]),_c('div',{staticClass:"data-box-right"},[_c('div',{staticClass:"chart-box"},[(_vm.chartShow)?_c('MyChart',{attrs:{"options":_vm.getBarOption(_vm.currentTarget && _vm.currentTarget.targetChart),"id":'bar-chart-video'}}):_vm._e()],1)])]),_c('div',{staticStyle:{"margin":"16px 0"}},[_c('ButtonGroup',{attrs:{"tab-data":_vm.$route.query.type == 'dongchedi' || _vm.getPrincipalState() != '3'
          ? _vm.tabList2
          : _vm.tabList},on:{"tabChange":_vm.handleTypeClick}})],1),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('a-table',{staticClass:"aft-table default",attrs:{"columns":_vm.columns,"data-source":_vm.tabType == '2' ? _vm.videoList2 : _vm.tabType == '3' ? _vm.videoList3 : _vm.videoList1,"scroll":{ y: 'calc(100vh - 406px)' },"rowKey":(data) => data.aweme_id,"pagination":false,"locale":_vm.locale,"loading":_vm.tableLoading,"customRow":_vm.openDetail},scopedSlots:_vm._u([{key:"videoTitle",fn:function(scope){return _c('div',{staticClass:"table-account"},[_c('div',{staticClass:"table-account-left"},[_c('div',{staticClass:"img-box"},[_c('div',{staticClass:"img-div"},[_c('WebpImage',{attrs:{"scale":'3-4',"src":scope.aweme_cover,"width":'60px',"height":'80px',"radius":'8px'}})],1),_c('div',{staticClass:"shadow"},[_c('svg-icon',{staticClass:"icon",attrs:{"icon-class":"play8"}})],1)]),_c('div',{staticClass:"box-right"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(scope.aweme_title || "-"))]),_c('span',{staticClass:"box-right-title"},[_vm._v(_vm._s(scope.aweme_title || "-"))])],2),(_vm.tabType == '3')?_c('div',{staticClass:"box-right-container"},_vm._l((_vm.getTagList(scope.tag_list)),function(item,index){return _c('a-tag',{key:index,staticStyle:{"margin-right":"8px"},attrs:{"color":"#FFA73E"}},[_vm._v(_vm._s(item))])}),1):_vm._e()],1)])])}}])},[_c('div',{staticStyle:{"padding-left":"16px"},attrs:{"slot":"firstTitle"},slot:"firstTitle"},[_vm._v("视频标题")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }