<template>
  <PanleWrap>
    <div class="list_header">
      <platform-header
        title="品牌经销商作品榜"
        :currentTab="platform"
        @tabChange="handlePlatformClick"
      />
      <div class="tools">
        <div class="btn-box">
          <ButtonGroupTab
            :tab-data="tabList"
            @tabChange="handleTypeClick"
            :showTag="true"
          />
          <Order
            :order-list="orderList"
            :order-index="orderIndex"
            @order-click="hadnleOrderClick"
            :titleText="'月度排名:'"
            style="margin-left: 30px"
          />
        </div>
        <div class="btn-box">
          <div class="my-ranking" @click="scrollMyRankig">
            我的排名：<span>{{
              my_ranking.ranking ? my_ranking.ranking : "-"
            }}</span>
          </div>
          <a-month-picker
            v-model="searchTime"
            placeholder="Select month"
            class="aft-month-picker default"
            :disabled-date="disabledDate"
            @change="onChange"
            locale="zh-cn"
          >
            <div slot="suffixIcon">
              <svg-icon :icon-class="'range-picker'" />
            </div>
          </a-month-picker>
        </div>
      </div>
    </div>

    <div class="list-content">
      <div class="data-list-box">
        <a-spin :spinning="mixin_isSpinning">
          <div class="data-wrap" v-if="dataList.length > 0 && isLive">
            <div
              :class="
                active_index === item.principal_id ? 'item active' : 'item'
              "
              v-for="(item, index) in dataList"
              :key="item.principal_id"
              :ref="`item_${item.principal_id}`"
              @click="handleItemClickInfo(item)"
            >
              <div class="ranking-wrap">
                <div
                  :class="
                    item.ranking === 1
                      ? 'num first'
                      : item.ranking === 2
                      ? 'num second'
                      : item.ranking === 3
                      ? 'num third'
                      : item.principal_id === my_ranking.principal_id
                      ? 'num other'
                      : 'num'
                  "
                >
                  <span v-if="my_ranking.principal_id === item.principal_id"
                    >我的排名：</span
                  >
                  {{ item.ranking || "-" }}
                </div>
              </div>

              <div class="info-wrap">
                <div class="left">
                  <span class="avatar">
                    <img :src="`${OSS_HOST}${item.avatar}`" alt="" />
                  </span>
                  <h3 class="principal-name">
                    {{ item.principal_name || "暂无名称" }}
                  </h3>
                </div>
                <div class="right">
                  <div class="title">
                    <span>互动量：</span>
                    <span class="number">{{
                      item.live_interactive_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-box">
                    <div class="count-item">
                      <span>活跃账号</span>
                      <span class="number highlight">{{
                        item.live_author_count || 0 | changeNum
                      }}</span>
                    </div>
                    <div class="count-item">
                      <span>{{ platform | returnWatchText }}</span>
                      <span class="number">{{
                        item.live_total_watch_user_time || 0 | changeNum
                      }}</span>
                    </div>
                    <div class="count-item">
                      <span>直播场次</span>
                      <span class="number">{{
                        item.live_total_count || 0 | changeNum
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-wrap" v-if="dataList.length > 0 && isVideo">
            <div
              :class="
                active_index === item.principal_id ? 'item active' : 'item'
              "
              v-for="(item, index) in dataList"
              :key="item.principal_id"
              :ref="`item_${item.principal_id}`"
              @click="handleItemClickInfo(item)"
            >
              <div class="ranking-wrap">
                <div
                  :class="
                    item.ranking === 1
                      ? 'num first'
                      : item.ranking === 2
                      ? 'num second'
                      : item.ranking === 3
                      ? 'num third'
                      : item.author_id === my_ranking.author_id
                      ? 'num other'
                      : 'num'
                  "
                >
                  <span v-if="my_ranking.author_id === item.author_id"
                    >我的排名：</span
                  >
                  {{ item.ranking }}
                </div>
              </div>

              <div class="info-wrap">
                <div class="left">
                  <span class="avatar">
                    <img :src="`${OSS_HOST}${item.avatar}`" alt="" />
                  </span>
                  <h3 class="principal-name">
                    {{ item.principal_name || "暂无名称" }}
                  </h3>
                </div>
                <div class="right">
                  <div class="title">
                    <span>互动量：</span>
                    <span class="number">{{
                      item.aweme_relation_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-box">
                    <div class="count-item">
                      <span>活跃账号</span>
                      <span class="number highlight">{{
                        item.aweme_author_count || 0 | changeNum
                      }}</span>
                    </div>
                    <div class="count-item">
                      <span>日均发布</span>
                      <span class="number">{{
                        item.aweme_avg_count || 0 | changeNum
                      }}</span>
                    </div>
                    <div class="count-item">
                      <span>视频发布</span>
                      <span class="number">{{
                        item.aweme_count || 0 | changeNum
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template
            v-if="
              mixin_isBizError || mixin_isNetworldError || dataList.length === 0
            "
          >
            <div
              class="font-color3"
              style="text-align: center; width: 100%; padding-top: 20px"
            >
              <Empty style="margin: calc(20vh + 25px) 0 20vh" />
            </div>
          </template>
        </a-spin>
      </div>
      <div class="top-10-box">
        <div class="top-title">
          <svg-icon icon-class="top10-month" class="icon" />
          <div class="txt">
            月度<span v-if="isLive">直播</span><span v-else>视频</span>TOP10
          </div>
        </div>
        <a-spin :spinning="loading" style="width: 100%; min-height: 300px">
          <div class="top-data-list" v-if="topList.length > 0 && isLive">
            <div
              class="item"
              v-for="item in topList"
              :key="item.principal_id"
              @click="handleLiveItemClick(item)"
            >
              <div class="left">
                <BaseImage
                  :src="item.cover || ''"
                  :default-image="defaultCoverImg"
                  fit="contain"
                  width="90px"
                  height="120px"
                  radius="6px"
                  style="background-color: #21232c;"
                />
                <svg-icon icon-class="play3" class="play" />
              </div>
              <div class="right">
                <a-tooltip>
                  <template slot="title">{{
                    item.title || "暂无标题"
                  }}</template>
                  <span class="title">{{ item.title || "暂无标题" }}</span>
                </a-tooltip>

                <div class="name">
                  <BaseImage
                    :src="item.avatar || ''"
                    :default-image="defaultAvatarImg"
                    fit="cover"
                    width="16px"
                    height="16px"
                    round
                    style="margin-right: 6px;"
                  />
                  <span>{{ item.nickname || "暂无名称" }}</span>
                </div>
                <div class="count-box">
                  <div class="count-item count-item-1">
                    <span>营销价值</span>
                    <span class="price"
                      ><i>¥</i>{{ item.live_marketing_value || 0 }}</span
                    >
                  </div>
                  <div class="count-item count-item-2">
                    <span>{{ platform | returnWatchText }}</span>
                    <span class="number">{{
                      item.statistics_total_watch_user_time || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item count-item-4">
                    <span>开播时间</span>
                    <span class="number">{{
                      item.publish_time || 0 | formatPublishTime
                    }}</span>
                  </div>
                  <div class="count-item count-item-4">
                    <span>直播时长</span>
                    <span class="number">{{
                      item.statistics_live_time || 0 | formatDuration
                    }}</span>
                  </div>
                  <div class="count-item count-item-3">
                    <span>在线峰值</span>
                    <span class="number">{{
                      item.statistics_max_online_user || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item count-item-2">
                    <span>集赞总数</span>
                    <span class="number">{{
                      item.statistics_total_like_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item count-item-2">
                    <span>评论总数</span>
                    <span class="number">{{
                      item.statistics_total_comment_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item count-item-2">
                    <span @click.stop
                      >单场增粉<AftTooltip
                        :code="'qjzb10'"
                        style="top: 2px; left: 4px"
                    /></span>
                    <span class="number">{{
                      item.statistics_fans_add || 0 | changeNum
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="top-data-list" v-if="topList.length > 0 && isVideo">
            <div
              class="item"
              v-for="item in topList"
              :key="item.principal_id"
              @click="handleVideoItemClick(item)"
            >
              <div class="left">
                <BaseImage
                  :src="item.aweme_cover || ''"
                  :default-image="defaultCoverImg"
                  fit="contain"
                  width="90px"
                  height="120px"
                  radius="6px"
                  style="background-color: #21232c;"
                />
                <svg-icon icon-class="play3" class="play" />
              </div>
              <div class="right">
                <a-tooltip>
                  <template slot="title">{{
                    item.aweme_title || "暂无标题"
                  }}</template>
                  <span class="title">{{
                    item.aweme_title || "暂无标题"
                  }}</span>
                </a-tooltip>

                <div class="name">
                  <BaseImage
                    :src="item.avatar || ''"
                    :default-image="defaultAvatarImg"
                    fit="cover"
                    width="16px"
                    height="16px"
                    round
                    style="margin-right: 6px;"
                  />
                  <span>{{ item.nickname || "暂无名称" }}</span>
                </div>
                <div class="count-box">
                  <div class="count-item count-item-6">
                    <span>营销价值</span>
                    <span class="price"
                      ><i>¥</i>{{ item.aweme_marketing_value || 0 }}</span
                    >
                  </div>
                  <div class="count-item count-item-5">
                    <span>视频播放</span>
                    <span class="number">{{
                      item.play_volume | changeNum
                    }}</span>
                  </div>
                  <div class="count-item count-item-3">
                    <span>视频互动</span>
                    <span class="number">{{
                      item.aweme_active_power || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item count-item-5">
                    <span>发布时间</span>
                    <span class="number">{{
                      item.publish_time | formatPublishTime
                    }}</span>
                  </div>
                  <div class="count-item count-item-5">
                    <span>视频时长</span>
                    <span class="number">{{ item.duration || 0 }}s</span>
                  </div>
                  <div class="count-item count-item-3">
                    <span>视频点赞</span>
                    <span class="number">{{
                      item.digg_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item count-item-3">
                    <span>视频评论</span>
                    <span class="number">{{
                      item.comment_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item count-item-3">
                    <span>视频转发</span>
                    <span class="number">{{
                      item.share_count || 0 | changeNum
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-spin>
        <template v-if="!loading && topList.length === 0">
          <Empty style="margin: 20vh 0" />
        </template>
      </div>
    </div>
  </PanleWrap>
</template>

<script>
import defaultAvatarImg from "@/assets/default/default1-1.png";
import defaultCoverImg from "@/assets/default/default3-4.png";
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/brand.js";
import _ from "lodash";
import moment from "moment";
import mixin from "./mixins/mixin";
import Order from "./components/Order";
import ButtonGroupTab from "@/components/PlatformTab/ButtonGroupTab";
import Platform from "./components/Platform";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import BaseImage from "@/components/BaseImage";
import Tooltip from "@/components/Tooltip/Tooltip";
import Empty from "@/components/Empty/Empty";

const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;

//直播条件筛选
const liveOrderList = [
  {
    text: "直播互动",
    value: "1",
  },
  {
    text: "活跃账号",
    value: "4",
  },
  {
    text: "观看人次",
    value: "2",
  },
  {
    text: "直播场次",
    value: "3",
  },
];

//视频条件筛选
const videoOrderList = [
  {
    text: "互动总量",
    value: "1",
  },
  {
    text: "日均发布",
    value: "2",
  },
  {
    text: "视频发布",
    value: "3",
  },
  {
    text: "活跃账号",
    value: "4",
  },
];

export default {
  name: "dealerWorksList",
  components: {
    PanleWrap,
    Order,
    Platform,
    PlatformHeader,
    ButtonGroupTab,
    BaseImage,
    Tooltip,
    Empty,
  },
  mixins: [mixin],
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatPublishTime(str) {
      if (!str) {
        return "暂无时间";
      } else {
        return moment(str).format("YYYY/MM/DD");
      }
    },
  },
  data() {
    return {
      defaultAvatarImg,
      defaultCoverImg,
      searchTime: moment().subtract(1, "months"),
      disabledDate(current) {
        return (
          current >=
          moment()
            .startOf("month")
            .subtract("month", -1)
        );
      },
      startTime: "",
      endTime: "",
      tooltipTitle: "抖音主播账号授权后，可查看对应视频播放数",
      tabList: [
        {
          label: "直播",
          value: "1",
          tag: "LIVE",
        },
        {
          label: "视频",
          value: "2",
          tag: "VIDEO",
        },
      ],
      platform: "", // 平台(抖音:douyin,快手:kuaishou)
      type: "1",
      liveSort: "1",
      videoSort: "1",
      orderIndex: 0,
      liveIndex: 0,
      videoIndex: 0,
      orderList: liveOrderList,
      dataList: [],
      OSS_HOST,
      isLive: true,
      isVideo: false,
      active_index: 0,
      my_ranking: {},
      loading: true,
      topList: [],
    };
  },
  async created() {
    let timeObj = this.calcStartEndTime(moment().subtract(1, "months"));
    this.startTime = timeObj.startTime;
    this.endTime = timeObj.endTime;
    this.getList = this.requestDecorator(() => {
      this.loading = true;
      this.active_index = 0;

      const body = {
        platform: this.platform,
        params: {
          start_time: this.startTime,
          end_time: this.endTime,
          publish: "dealer",
          sort: this.type === "1" ? this.liveSort : this.videoSort,
        },
      };

      if (this.type === "1") {
        return api.getNewBrandLiveList(body).then((res) => {
          this.dataList = _.get(res, "data.data.list", []);
          this.my_ranking = _.get(res, "data.data.self", {});
          this.active_index = _.get(res, "data.data.self.principal_id", "");
          return res;
        });
      } else {
        return api.getNewBrandVideoList(body).then((res) => {
          this.dataList = _.get(res, "data.data.list", []);
          this.my_ranking = _.get(res, "data.data.self", {});
          this.active_index = _.get(res, "data.data.self.principal_id", "");
          return res;
        });
      }
    });

    // await this.awaitGetList();
  },
  methods: {
    getList() {},
    awaitGetList() {
      new Promise((resolve, reject) => {
        resolve(this.getList());
      }).then((res) => {
        if (this.active_index) {
          this.scrollItemView(this.active_index);
        }
        this.getTopList(this.active_index);
      });
    },
    scrollItemView(id) {
      this.active_index = id;
      this.loading = false;
      console.log(id, this.$refs[`item_${id}`], this.$refs[`item_${id}`][0]);

      if (id) {
        let dom = this.$refs[`item_${id}`][0];
        dom.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },
    getTopList(principal_id) {
      const body = {
        platform: this.platform,
        params: {
          start_time: this.startTime,
          end_time: this.endTime,
          publish: "dealer",
          sort: this.type === "1" ? this.liveSort : this.videoSort,
          principal_id: principal_id || "",
        },
      };

      if (this.isLive) {
        api.getNewDealerLiveList(body).then((res) => {
          this.loading = false;

          if (res.data.code === 0) {
            this.topList = _.get(res, "data.data", []);
          } else {
            this.topList = [];
            console.error("获取top10失败");
          }
        });
      } else {
        api.getNewDealerVideoList(body).then((res) => {
          this.loading = false;

          if (res.data.code === 0) {
            this.topList = _.get(res, "data.data", []);
          } else {
            this.topList = [];
            console.error("获取top10失败");
          }
        });
      }
    },
    hadnleOrderClick(res) {
      if (this.type === "1") {
        this.liveSort = res.data.value;
        this.liveIndex = res.index;
      } else {
        this.videoSort = res.data.value;
        this.videoIndex = res.index;
      }

      this.orderIndex = res.index;
      // this.getList();
      this.awaitGetList();
    },
    handlePlatformClick(val) {
      this.platform = val;
      liveOrderList[2].text = this.filtrate.returnWatchText(this.platform);
      // this.getList();
      this.awaitGetList();
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      let obj = this.calcStartEndTime(date);
      this.startTime = obj.startTime;
      this.endTime = obj.endTime;
      // this.getList();
      this.awaitGetList();
    },
    calcStartEndTime(date) {
      let start = date.startOf("month").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("month").format("YYYY-MM-DD HH:mm:ss");
      return {
        startTime: start,
        endTime: end,
      };
    },
    handleLiveItemClick(item) {
      console.log(item);
      if (item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${this.platform}`;
        // this.$router.push(`/liveDetail?id=${item.room_id}`)
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
    handleVideoItemClick(item) {
      if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${this.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
    handleItemClickInfo(item) {
      this.active_index = item.principal_id;
      this.loading = true;
      this.getTopList(item.principal_id);
    },
    handleTypeClick(val) {
      this.type = val;
      if (val === "1") {
        this.orderList = liveOrderList;
        this.orderIndex = this.liveIndex;
        this.isLive = true;
        this.isVideo = false;
      } else {
        this.orderList = videoOrderList;
        this.orderIndex = this.videoIndex;
        this.isLive = false;
        this.isVideo = true;
      }
      this.loading = true;
      // this.getList();
      this.awaitGetList();
    },
    scrollMyRankig() {
      if (this.my_ranking.principal_id) {
        let dom = this.$refs[`item_${this.my_ranking.principal_id}`][0];
        dom.scrollIntoView({
          //滚动到指定节点
          block: "start",
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

img {
  max-width: 100%;
}

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.list_header {
  margin-bottom: 20px;
  > h3 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    @include font_color("font_color2");
    margin: 0;
  }
}
.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 20px;
}

.btn-box {
  display: flex;
  align-items: center;
}

.my-ranking {
  padding: 0 30px 0 16px;
  margin-right: 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  cursor: pointer;
  @include background_color("background_color51");
  span {
    padding-left: 6px;
    @include font_color("font_color4");
  }
}
.list-content {
  display: flex;
  justify-content: space-between;
  padding: 0 14px 0 20px;
}

.count-box {
  display: flex;
  .count-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    @include font_color("font_color3");
    .number {
      margin-top: 10px;
      font-weight: bold;
      @include font_color("font_color1");
    }
  }
}

.data-list-box {
  position: relative;
  width: 25%;
  min-width: 330px;
  .data-wrap {
    height: calc(100vh - 260px);
    overflow-y: auto;
  }
  .item {
    position: relative;
    padding: 20px 0;
    margin-right: 4px;
    line-height: 1.5;
    border-radius: 16px;
    cursor: pointer;
    @include background_color("background_color2");
    & + .item {
      margin-top: 10px;
    }
    &.active {
      .principal-name,
      .count-item .number {
        @include font_color("font_color4");
      }
    }
  }
  .ranking-wrap {
    position: absolute;
    top: 0;
    right: 0;
    .num {
      padding: 0 12px;
      min-width: 32px;
      height: 26px;
      box-sizing: border-box;
      line-height: 26px;
      font-size: 12px;
      border-radius: 0px 16px 0px 16px;
      text-align: center;
      @include font_color("font_color22");
      @include background_color("background_color51");

      &.first {
        @include font_color("font_color17");
        @include background_color("background_color15");
      }
      &.second {
        @include font_color("font_color17");
        @include background_color("background_color18");
      }
      &.third {
        @include font_color("font_color17");
        @include background_color("background_color49");
      }
      &.other {
        @include font_color("font_color1");
      }
    }
  }
  .info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .principal-name {
      margin: 10px 0 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      @include font_color("font_color1");
    }
    .title {
      margin-bottom: 15px;
      font-size: 14px;
      .number {
        font-weight: bold;
        @include font_color("font_color4");
      }
    }
  }
  .left {
    padding: 0 2%;
    font-size: 0;
    width: 34%;
    text-align: center;
  }
  .right {
    flex: 1;
    max-width: 66%;
  }
  .avatar {
    width: 44px;
    height: 44px;
    display: inline-block;
    border-radius: 6px;
    overflow: hidden;
  }
  .count-box {
    .count-item {
      width: 33.33%;
    }
  }
}

.top-10-box {
  padding-top: 24px;
  margin-left: 20px;
  flex: 1;
  max-width: 75%;
  height: calc(100vh - 260px);
  overflow-y: auto;
  border-radius: 16px 0px 0px 0px;
  @include background_color("background_color5");
  .top-title {
    display: flex;
    align-items: center;
    padding: 0 20px;
    .icon {
      font-size: 25px;
    }
    .txt {
      padding-left: 10px;
      font-size: 14px;
      @include font_color("font_color22");
    }
  }
  .top-data-list {
    width: 100%;
    min-height: 300px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    cursor: pointer;
    & + .item {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      @include border_color("border_color2");
    }
    .left {
      position: relative;
      margin-right: 20px;
      width: 90px;
      min-width: 90px;
      height: 120px;
      display: inline-block;
      border-radius: 6px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      @include background_color("background_color1");
      .play {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        font-size: 25px;
        transition: all 0.3s;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.3s;
      }
      &:hover {
        &:after {
          @include background_color("background_color56");
        }
        .play {
          opacity: 1;
        }
      }
    }
    .right {
      flex: 1;
    }
    .title {
      // width: 41%;
      max-width: 450px;
      font-size: 16px;
      @include font_color("font_color1");
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .name {
      margin: 4px 0 17px;
      display: flex;
      align-items: center;
      span {
        @include font_color("font_color22");
        font-size: 12px;
      }
    }
  }
  .count-box {
    .price {
      font-size: 18px;
      line-height: 1.2;
      @include font_color("font_color27");
      i {
        padding-right: 6px;
        font-size: 14px;
        font-style: normal;
      }
    }
    .count-item-1 {
      width: 18%;
    }
    .count-item-2 {
      width: 11%;
    }
    .count-item-3 {
      width: 11%;
    }
    .count-item-4 {
      width: 13%;
    }
    .count-item-5 {
      width: 14%;
    }
    .count-item-6 {
      width: 20%;
    }
  }
}
</style>
