import echarts from 'echarts'
import filters from '@/filters'
var option = {
  backgroundColor: '#15161A',
  tooltip: {
    trigger: 'item',
    backgroundColor: "rgba(0,0,0,0)", //设置背景图片 rgba格式
    formatter: function (params) {
      let str = `<div style="background: #3C4252;border-radius: 8px;width: 271px;height: 66px;">
            <div style="width: 100%;
                height: 25px;
                line-height: 25px;
                font-size: 10px;
                color: #C8CEDE;
                border-bottom: 1px solid rgba(255,255,255, 0.1);
                padding-left: 20px;">
                ${params.name}
            </div>
            <div style="display:flex;align-items: center;height: 40px;">
            <span style="font-size: 12px;color: #D0D6E6;font-weight: bold;margin-left: 20px;">${params.seriesName}:</span>
            <span style="font-size: 14px;color: #FFCA5C;font-weight: bold;margin-left: 8px;">${filters.changeNum(params.data.number)}</span>
            <span style="font-size: 12px;color: #D0D6E6;font-weight: bold;margin-left: 24px;">${'行业排名'}:</span>
            <span style="font-size: 14px;color: #FFCA5C;font-weight: bold;margin-left: 8px;">${params.data.value}</span>
            </div>
        </div>`;
      return str;
    }
  },
  grid: {
    top: '15',
    left: '20',
    right: '20',
    bottom: '30',
    // containLabel: true
  },
  xAxis: [{
    type: 'category',
    axisLine: {
      show: false,
      onZero: false,
    },
    offset: 10,
    axisLabel: {
      color: '#8890A6'
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    },
    // boundaryGap: false,
    data: [],

  }],

  yAxis: [{
    type: 'value',
    splitLine: {
      show: true,
      lineStyle: {
        color: '#323744'
      }
    },
    min: 1,
    minInterval: 1,
    axisLine: {
      show: false,
    },
    axisLabel: {
      show: false,
      textStyle: {
        color: '#d1e6eb',

      },
    },
    axisTick: {
      show: false,
    },
    inverse: true
  }],
  series: [{
    name: '粉丝总量',
    type: 'line',
    showAllSymbol: true,
    symbol: 'roundRect',
    symbolSize: 30,
    lineStyle: {
      normal: {
        color: "rgba(255, 202, 92, 0.4)",
      },
    },
    label: {
      show: true,
      position: 'inside',
      textStyle: {
        color: '#D0D6E6'
      }
    },
    emphasis: {
      label: {
        textStyle: {
          color: '#FFCA5C'
        }
      }
    },
    itemStyle: {
      normal: {
        color: "#2B2F3B",
      },
      emphasis: {
        color: '#3C4252',
      }
    },
    tooltip: {
      show: true
    },
    areaStyle: {
      normal: {
        color: 'none'
      }
    },
    data: []
  }
  ]
}

export default option