
var days = ['周日', '周六', '周五',
        '周四', '周三', '周二', '周一'];

var option = {
    tooltip: {
        position: 'top'
    },
    animation: false,
    grid: {
        height: '50%',
        top: '0%'
    },
    xAxis: {
        type: 'category',
        data: [],
        splitArea: {
            show: true
        },
        axisLabel: { //  改变x轴字体颜色和大小
            textStyle: {
                color: "rgba(250,250,250,0.6)",
                fontSize: 14
            }
        }
    },
    yAxis: {
        type: 'category',
        data: days,
        splitArea: {
            show: true
        },
        axisLabel: { //  改变x轴字体颜色和大小
            textStyle: {
                color: "rgba(250,250,250,0.6)",
                fontSize: 14
            }
        }
    },
    visualMap: {
        min: 0,
        max: 239,
        calculable: true,
        orient: 'horizontal',
        // inRange: {
        //     color: ['#D6D3B4', '#D99C1F', '#D96A55']
        // },
        left: 'center',
        bottom: '15%',
        textStyle: {
            color: "#fff"
        }
    },
    series: [{
        name: '直播场次',
        type: 'heatmap',
        data: [],
        label: {
            show: true
        },
        emphasis: {
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
        }
    }]
};
export default option