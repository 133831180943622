var attackSourcesColor1 = ['#E75934', '#FFC840', '#81AFFF', '#F5A679', '#CCD2E3'];

var option = {
  backgroundColor: 'rgba(0,0,0,0)',
  tooltip: {
      show: false
  },
  grid: {
      left: '0',
      right: '0',
      width: '90%',
      bottom: '16px',
      top: '-10px',
      containLabel: true,
  },
  xAxis: {
      type: 'value',

      splitLine: {
          show: false,
      },
      axisLabel: {
          show: false,
      },
      axisTick: {
          show: false,
      },
      axisLine: {
          show: false,
      },
  },
  yAxis: [
      {
          type: 'category',
          inverse: true,
          axisLine: {
              show: false,
          },
          axisTick: {
              show: false,
          },
          axisPointer: {
              label: {
                  show: true,
              },
          },
          postion: 'right',
          data: [],
          axisLabel: {
              show: false,
              fontSize: 10,
              align: 'left',
              padding: [2, 0, 0, 0],
              color: '#000',
            
          },
      },
  ],
  series: [
      {
          zlevel: 1,
          name: '',
          type: 'bar',
          barWidth: '14px',
          animationDuration: 500,
          data: [],
          align: 'center',
          itemStyle: {
              normal: {
                  barBorderRadius: 4
              },
          },
      },
      {
        type: 'bar',
        itemStyle: {
            normal: {
                color: '#2B2F3B',
                barBorderRadius: 4
            },
        },
        label: {
            normal: {
                show: true,
                position: "right",
                distance: 10,
                textStyle: {
                    color: "#fff",
                    fontSize: 14
                }
            }
        },

        silent: true,
        barWidth: '14px',
        barGap: '-100%',
        data: [],
    }
  ],
}

export default option