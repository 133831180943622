<template>
  <div class="assistant-chart">
    <div class="assistant-chart-main">
      <div class="assistant-chart-main-title">
        <div v-if="currentButtonClick == '2'">
          <span class="card-title">本场数据</span>
          <span class="tooltip-name">进场人数</span>
          <AftTooltip :code="'qjzb07'" style="top: -4px" />
          <span class="tooltip-name">推流速度</span>
          <AftTooltip :code="'qjzb08'" style="top: -4px" />
          <span class="tooltip-name">预估留存率</span>
          <AftTooltip :code="'qjzb09'" style="top: -4px" />
        </div>
        <div v-else>
          <span class="card-title">本场数据</span>
        </div>
        <CardButtonGroup
          v-show="platform == 'douyin'"
          :tabData="buttonData"
          @tabChange="buttonClick"
        />
      </div>
      <div class="chart-box">
        <div id="chart"></div>
      </div>
      <!-- 片段 -->
      <div class="fragment-box">
        <div class="fragment-box-list">
          <span>片段</span>
          <div
            :class="[
              'fragment-item',
              item.status == 2
                ? 'success'
                : item.status == 6
                ? 'error'
                : 'default',
              currentItem == item ? 'active' : '',
            ]"
            v-for="(item, index) in demoData"
            :key="index"
            @click="currentItem = item"
          >
            <a-popover placement="topLeft">
              <template slot="content">
                <div class="tooltip-content">
                  <div class="tooltip-content-title">
                    <svg-icon
                      :icon-class="'card-tooltip'"
                      style="font-size: 26px; margin-right: 8px"
                    />
                    <span>智能复盘小助手</span>
                  </div>
                  <div class="tooltip-content-main">
                    {{item.tooltip}}
                  </div>
                </div>
              </template>
              <div class="fragment-item-container" v-if="item.tooltip">
                <svg-icon :icon-class="'fragment-warning'" />
              </div>
            </a-popover>
          </div>
        </div>
        <div class="fragment-box-lenged">
          <span class="lenged-icon error"></span>
          <span class="label">数据异常</span>
          <span class="lenged-icon success"></span>
          <span class="label">数据优异</span>
        </div>
      </div>
      <!-- tabs -->
      <div class="tabs-div">
        <NewTabs :tabData="tabsData" @tabChange="changeTabs" />
      </div>
      <div class="tabs-content">
        <TabContent1
          v-if="tabsKey == '1'"
          :tabData="currentItem && currentItem['tab_a']"
        />
        <TabContent2
          v-if="tabsKey == '2'"
          :tabData="currentItem && currentItem['tab_b']"
        />
        <TabContent3
          v-if="tabsKey == '3'"
          :tabData="currentItem && currentItem['tab_c']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import resize from "@/mixins/resize";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import WebpImage from "@/components/WebpImage/WebpImage";
import chartLine from "../chartLine_assistant";
import tooltipPlaySvg from "../tooltipPlaySvg";
import CardButtonGroup from "@/components/PlatformTab/CardButtonGroup";
import NewTabs from "@/components/PlatformTab/NewTabs";
import TabContent1 from "./TabContent1";
import TabContent2 from "./TabContent2";
import TabContent3 from "./TabContent3";
import demoDataJs from "../demoData.js";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers("globalDetail");
const buttonData = [
  {
    label: "直播数据",
    value: "1",
  },
  {
    label: "平台数据",
    value: "2",
  },
];
const selectData = [
  {
    key: "watch_cnt",
    name: "在线人数",
    color: "#FFCA5C",
    rgbColor: "255, 202, 92",
  },
  {
    key: "like_count",
    name: "点赞数",
    color: "#BA8CFE",
    rgbColor: "186, 140, 254",
  },
  {
    key: "comment_count",
    name: "评论数",
    color: "#5CE6F2",
    rgbColor: "92, 230, 242",
  },
  {
    key: "review",
    name: "回放",
    color: "#8CFEA2",
    rgbColor: "140, 254, 162",
  },
];
const oceanSelectData = [
  {
    key: "online_ucnt",
    name: "在线人数",
    color: "#FFCA5C",
    rgbColor: "255, 202, 92",
  },
  {
    key: "watch_cnt",
    name: "进场人数",
    color: "#81AFFF",
    rgbColor: "129, 175, 255",
  },
  {
    key: "leave_ucnt",
    name: "离场人数",
    color: "#FE8C8C",
    rgbColor: "254, 140, 140",
  },
  {
    key: "push_flow_speed",
    name: "推流速度",
    color: "#FEF58B",
    rgbColor: "254, 245, 139",
  },
  {
    key: "comment_cnt",
    name: "评论数",
    color: "#5CE6F1",
    rgbColor: "92, 230, 241",
  },
  {
    key: "retention_rate",
    name: "预估留存率",
    color: "#CEFE8E",
    rgbColor: "206, 254, 142",
  },
  {
    key: "review",
    name: "回放",
    color: "#8CFEA2",
    rgbColor: "140, 254, 162",
  },
];
export default {
  name: "AssistantChart",
  components: {
    MyChart,
    WebpImage,
    CardButtonGroup,
    NewTabs,
    TabContent1,
    TabContent2,
    TabContent3,
  },
  props: {},
  data() {
    return {
      chart: null,
      platform: this.$route.query.platform,
      selectData,
      oceanSelectData,
      hookTooltip: "",
      buttonData,
      currentButtonClick: "1",
      tabsData: [
        {
          label: "视频透视",
          value: "1",
        },
        {
          label: "话术分析",
          value: "2",
        },
        {
          label: "意向识别",
          value: "3",
        },
      ],
      tabsKey: "1",
      demoData: demoDataJs.tab_fragment,
      currentItem: {},
    };
  },
  created() {
    this.changeState({ data: {}, name: "liveDetail" });
  },
  async mounted() {
    this.$nextTick(() => {
      console.log(123, this.demoData);
      this.currentItem = this.demoData[0];
    });
    if (this.$route.query.id) {
      this.platform = this.$route.query.platform;
      let params = {
        room_id: this.$route.query.id,
        platform: this.$route.query.platform,
      };
      if (this.$route.query.platform == "douyin") {
        await Promise.all([
          this.getReviewAssistant(params),
          this.getReviewAssistantOcean(params),
        ]);
      } else {
        await Promise.all([this.getReviewAssistant(params)]);
      }
      this.spinning = false;
      this.$nextTick(() => {
        this.initChart();
      });

      console.log(document.querySelector(".chart-box"));
      document.querySelector(".chart-box").onmouseleave = () => {
        console.log("onmouseout");
        this.chart.dispatchAction({
          type: "hideTip",
        });
      };
    } else {
      console.log("没有获取到直播间信息");
    }
  },
  computed: {
    ...mapState({
      reviewAssistantData: (state) => state.reviewAssistantData,
      reviewAssistantOceanData: (state) => state.reviewAssistantOceanData,
    }),
  },
  watch: {
    hookTooltip(val) {
      let playBtn = document.querySelectorAll("#play-btn");
      if (playBtn.length > 0) {
        playBtn[0].onclick = this.playVideo;
      }
    },
  },
  methods: {
    ...mapActions(["getReviewAssistant", "getReviewAssistantOcean"]),
    ...mapMutations(["changeState"]),
    buttonClick(value) {
      this.currentButtonClick = value;
      this.initChart();
    },
    initChart() {
      this.chart = echarts.init(document.getElementById("chart"));
      let options = deepClone(chartLine);
      let newData =
        this.currentButtonClick == "1"
          ? this.reviewAssistantData
          : this.reviewAssistantOceanData;
      let newSelectData =
        this.currentButtonClick == "1" ? this.selectData : this.oceanSelectData;
      if (this.currentButtonClick == "1") {
        options.legend.selected = {
          在线人数: true,
          点赞数: true,
          评论数: true,
          回放: true,
        };
      } else {
        options.legend.selected = {
          在线人数: true,
          进场人数: true,
          离场人数: true,
          推流速度: false,
          评论数: false,
          预估留存率: false,
          回放: true,
        };
      }
      // 绑定接口数据
      options.xAxis[0].data =
      newData.time && newData.time.map((item) => {
          return item.slice(10, 16);
        }) || [];
      options.series = [];
      newSelectData.forEach((item) => {
        options.series.push({
          name: item.name,
          type: "line",
          smooth: true,
          connectNulls: item.key == "review" ? false : true,
          // symbol: 'path://M512.005117 958.708971C265.683035 958.708971 65.290005 758.316965 65.290005 511.99386c0-246.310825 200.39303-446.703855 446.715111-446.703855 246.310825 0 446.703855 200.39303 446.703855 446.703855C958.708971 758.316965 758.316965 958.708971 512.005117 958.708971zM512.005117 169.716356c-188.738595 0-342.289784 153.545048-342.289784 342.277504 0 188.738595 153.551188 342.289784 342.289784 342.289784 188.733479 0 342.278527-153.551188 342.278527-342.289784C854.283644 323.261405 700.738595 169.716356 512.005117 169.716356z',
          symbol: "circle",
          showSymbol: false,
          symbolSize: 6,
          data: newData[item.key] || [],
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: `rgba(${item.rgbColor}, 0.5)`,
                  },
                  {
                    offset: 0.8,
                    color: `rgba(${item.rgbColor}, 0)`,
                  },
                ],
                false
              ),
            },
          },
          itemStyle: {
            normal: {
              color: item.color,
            },
          },
        });
      });
      options.tooltip.formatter = (params, ticket, callback) => {
        this.hookTooltip = params;
        console.log("params", params);
        let itemStr = "";
        for (let i = 0; i < params.length; i++) {
          if (params[i].seriesName != "回放") {
            itemStr += `<div class="tooltip-item">
            <span style="width: 4px;height: 4px;background: ${
              params[i].color
            };display: inline-block;border-radius: 2px;"></span>
            <span style="font-size: 12px;color: #9ca8ba;margin: 0 8px;">${
              params[i].seriesName
            }</span>
            <span style="font-size: 12px;font-weight: bold;color: #ffffff;">${
              params[i].value == undefined
                ? "-"
                : this.$options.filters.changeNum(Number(params[i].value))
            }</span>
            </div>`;
          } else if (newData.live_video_record[params[i].dataIndex]) {
            itemStr += `<div class="tooltip-item">
            <span style="width: 4px;height: 4px;background: ${params[i].color};display: inline-block;border-radius: 2px;"></span>
            <span style="font-size: 12px;color: #9ca8ba;margin: 0 8px;">${params[i].seriesName}</span>
            <span style="font-size: 12px;font-weight: bold;color: #ffffff;">Play</span>
            </div>`;
          }
        }
        let playStr = "";
        if (newData.live_video_record[params[0].dataIndex]) {
          playStr = `<div id="play-btn" style="" onclick="playVideo">
          <span>${tooltipPlaySvg}</span>
          <span>查看回放</span>
          </div>`;
        }
        return (
          `<div style="width: 198px;min-height: 118px;background: #3c4252;border-radius: 8px;display: flex;flex-direction: column;">
          <div style="height: 33px;width: 100%;padding-left: 12px;line-height: 33px;font-size: 12px;color: #ffffff;border-bottom: 1px solid rgba(255, 255, 255, 0.1);display: flex;flex-direction: row;justify-content: space-between;">
          <span>${params[0].axisValue}</span>` +
          playStr +
          `</div>
          <div style="display: flex;flex-wrap: nowrap;align-items: flex-start;padding: 10px 12px;">
          <div style="width: calc(100% - 48px);display: flex;flex-direction: column;">` +
          itemStr +
          `</div>` +
          `</div>
        </div>`
        );
      };
      console.log(123213123, options);
      let newOption = deepClone(options);
      this.chart.setOption(newOption, {
        notMerge: true,
      });
      window.onresize = this.chart.resize;
    },
    playVideo() {
      let newData =
        this.currentButtonClick == "1"
          ? this.reviewAssistantData
          : this.reviewAssistantOceanData;
      let time = newData.live_video_record[this.hookTooltip[0].dataIndex];
      console.log(time);
      // todo
      this.$emit('openLiveByTime', time)
      // this.$refs.LiveDetailCard.openLiveByTime(time);
    },

    // tabs切换
    changeTabs(val) {
      this.tabsKey = val;
    },
  },
  mixins: [resize],
};
</script>
<style lang="scss" scope>
.assistant-chart {
  @import "@/assets/styles/_handle.scss";
  width: 100%;
  height: 100%;
  &-main {
    width: 100%;
    height: 100%;
    background: #1c1e27;
    border-radius: 8px;
    &-title {
      font-size: 18px;
      font-weight: bold;
      @include font_color("font_color20");
      line-height: 25px;
      padding: 19px 20px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        align-items: center;
        .card-title {
          margin-right: 6px;
        }
        .tooltip-name {
          font-size: 12px;
          color: #8890a6;
          margin: 0 8px 0 12px;
        }
      }
    }
    .chart-box {
      width: 100%;
      height: 400px;
      position: relative;
      #chart {
        width: 100%;
        height: 100%;
      }
      .tooltip-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 198px;
        background: #3c4252;
        box-shadow: 0px 3px 9px 0px rgba(20, 22, 28, 0.26);
        border-radius: 8px;
        min-height: 118px;
        flex-direction: column;
        &-time {
          height: 33px;
          width: 100%;
          padding-left: 12px;
          line-height: 33px;
          font-size: 12px;
          color: #ffffff;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
        &-main {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          padding: 10px 12px;
          &-list {
            width: calc(100% - 48px);
            display: flex;
            flex-direction: column;
            > .tooltip-item {
              display: flex;
              align-items: center;
              height: 22px;
              .tip {
                width: 4px;
                height: 4px;
                background: #fff;
                display: inline-block;
                border-radius: 2px;
              }
              .label {
                font-size: 12px;
                color: #9ca8ba;
                margin: 0 8px;
              }
              .number {
                font-size: 12px;
                font-weight: bold;
                color: #ffffff;
              }
            }
          }
          .play-box {
            width: 48px;
            height: 64px;
            background: #2b2f3b;
            border-radius: 4px;
            font-size: 22px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
    .fragment-box {
      width: 100%;
      height: 112px;
      &-list {
        width: 100%;
        height: 60px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow-x: auto;
        padding-right: 20px;
        > span {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.65);
          margin: 0 12px 0 45px;
        }
        .fragment-item {
          flex: 1;
          height: 60px;
          cursor: pointer;
          opacity: 0.2;
          font-size: 20px;
          &-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &:last-child {
            border: none;
          }
          &.default {
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.4) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            box-shadow: inset 1px 0px 0px 0px rgba(255, 255, 255, 0.04);
            &:hover {
              opacity: 0.4;
            }
            &.active {
              opacity: 1;
            }
          }
          &.success {
            background: linear-gradient(
              180deg,
              #ffca5c 0%,
              rgba(255, 202, 92, 0.05) 100%
            );
            box-shadow: inset 1px 0px 0px 0px rgba(255, 202, 92, 0.7);
            &:hover {
              opacity: 0.4;
            }
            &.active {
              opacity: 1;
            }
          }
          &.error {
            background: linear-gradient(
              180deg,
              rgba(255, 133, 20, 0.8) 0%,
              rgba(255, 134, 20, 0.05) 100%
            );
            box-shadow: inset 1px 0px 0px 0px rgba(255, 133, 20, 0.8);
            &:hover {
              opacity: 0.4;
            }
            &.active {
              opacity: 1;
            }
          }
        }
      }
      &-lenged {
        width: 100%;
        height: 52px;
        display: flex;
        align-items: center;
        .lenged-icon {
          width: 12px;
          height: 12px;
          display: inline-block;
          border-radius: 50%;
          &.success {
            margin-left: 24px;
            background: linear-gradient(
              180deg,
              #ffca5c 0%,
              rgba(255, 202, 92, 0.05) 99%
            );
          }
          &.error {
            margin-left: 80px;
            background: linear-gradient(
              180deg,
              rgba(255, 43, 20, 0.8) 0%,
              rgba(255, 44, 20, 0.05) 100%
            );
          }
        }
        .label {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 4px;
        }
      }
    }
    .tabs-div {
      width: calc(100% - 40px);
      margin: 12px 0 10px 20px;
      border-bottom: 1px solid #303136;
    }
    .tabs-content {
      width: 100%;
      height: 418px;
      background-image: url("../../../assets/img/assistant-background.png");
      background-size: 832px auto;
      background-position: bottom center;
      background-repeat: no-repeat;
    }
  }
}
.tooltip-content {
  width: 300px;
  // height: 134px;
  padding: 0 0 12px;
  background: rgba(43, 47, 59, 0.8);
  border-radius: 12px 12px 12px 12px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  &-title {
    width: 100%;
    height: 42px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
  }
}
</style>

<style>
#play-btn {
  color: #8890a6;
  display: flex;
  align-items: center;
  padding-right: 12px;
  cursor: pointer;
  font-size: 12px;
}
#play-btn > span:first-child {
  font-size: 10px;
  margin-right: 4px;
  position: relative;
  top: 4px;
}
#play-btn:hover {
  color: #ffca5c;
}
</style>
