<template>
  <section class="page" id="page">
    <div class="page-title">
      <span>区域对比</span>
      <div>
        <a-button class="ml-8" size="large" @click="actSelectVisible = true">
          <a-icon type="setting" theme="filled" class="setting-icon" />
          <span>选择活动</span>
        </a-button>
        <a-button
          v-hide-by-username
          class="ml-8"
          size="large"
          @click="handleExport"
        >
          <a-icon type="export" />
          <span>导出图片</span>
        </a-button>
      </div>
    </div>

    <div class="table-wrap" ref="tableWrap">
      <div class="rate-tips">
        <!-- <span class="tips-box">
          <i class="icon-circle icon-circle-task"></i>任务总完成率
          <AftTooltip code="hdzb06" class="ml-4" />
        </span>
        <span>
          <i class="icon-circle icon-circle-store"></i>门店参与率
          <AftTooltip code="hdzb04" class="ml-4" />
        </span> -->
        <span> <i class="icon-circle icon-circle-top"></i>前3排名 </span>
        <span> <i class="icon-circle icon-circle-bottom"></i>后3排名 </span>
      </div>
      <a-table
        class="aft-table default x-scroll"
        v-if="isShowTable"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :rowKey="(item) => item.regionId"
        :pagination="false"
        :scroll="tableScroll"
      >
        <div
          v-for="(item, index) in activityInfoList"
          :key="`completionRate_${index}_title`"
          :slot="`activity_${index}_title`"
        >
          <div class="name-title">
            <TextEllipsis
              :value="item.activityName"
              :key="`completionRate_${new Date().getTime()}`"
              :limit="2"
            ></TextEllipsis>
          </div>
          <div class="colspan-title-box">
            <div
              class="cell"
              v-for="(task, taskIndex) in item.taskList"
              :key="taskIndex"
            >
              <TextEllipsis
                :value="task.taskName"
                :key="`completionRate_${new Date().getTime()}`"
                :limit="2"
              ></TextEllipsis>
            </div>
          </div>
        </div>
        <div
          class="activity-column"
          v-for="(item, index) in activityInfoList"
          :key="item.id"
          :slot="`activity_${index}`"
          slot-scope="text, record"
        >
          <div
            class="cell"
            v-for="(cell, cellIndex) in record[item.activityId]"
            :class="handleTopBottom(cell)"
            :key="cellIndex"
          >
            <span v-if="cell.taskPerformance"> {{ cell.taskPerformance }}</span>
            <span v-else-if="cell.completionRate !== '-'">
              {{ cell.completionRate }}%
            </span>
            <span v-else> - </span>
          </div>
        </div>
      </a-table>
      <div class="empty-wrap" v-if="!isShowTable">
        <Empty />
      </div>
    </div>

    <!-- 选择活动 -->
    <ActSelectDrawer
      :visible.sync="actSelectVisible"
      mode="multipleSelect"
      :default-selected-act-list="selectedActList"
      @confirm="onActSelectConfirm"
    />
  </section>
</template>

<script>
import Empty from "@/components/Empty/Empty";
import ActSelectDrawer from "@/components/ActSelectDrawer";
import actListApi from "@/api/marketingActivityList";
import actRegionProgressApi from "@/api/activityRegionProgress";
import exportTable from "@/utils/exportTable";
import { random } from "lodash";
import TextEllipsis from "@/components/TextEllipsis/TextEllipsis.vue";

export default {
  name: "activityRegionProgress",
  components: {
    Empty,
    ActSelectDrawer,
    TextEllipsis,
  },
  data() {
    return {
      isShowTable: false,
      loadMoreStatus: "more",
      selectedActList: [],
      activityInfoList: [],
      actList: [],
      actPagination: {
        page: 1,
        size: 20,
      },
      loading: false,
      columns: [],
      data: [],
      tableScroll: {},
      actSelectVisible: false,
      regionRoleType: 10,
    };
  },
  computed: {
    isRoleMain() {
      return this.regionRoleType == 10;
    },
    isRoleOne() {
      return this.regionRoleType == 20;
    },
    isRoleTwo() {
      return this.regionRoleType == 30;
    },
  },
  mounted() {
    this.getActivityList();
    window.addEventListener("resize", this.setTableScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setTableScroll);
  },
  methods: {
    handleTopBottom(record, item) {
      let res = "";
      switch (record[`dealerRank`]) {
        case 1:
          res = "top-color";
          break;
        case 2:
          res = "bottom-color";
          break;
        default:
          break;
      }
      return res;
    },
    // 获取活动列表
    async getActivityList() {
      this.loadMoreStatus = "loading";
      try {
        const { page, size } = this.actPagination;
        const params = {
          activityStatus: 0,
          isTaskRate: true,
          page,
          size,
        };
        const res = await actListApi.getActivityList(params);
        const { code, data, message } = res.data;

        if (code === 200) {
          if (page === 1) {
            this.actList = data?.list || [];
            this.selectedActList = this.actList.slice(0, 6);
            this.setColumns();
            this.isShowTable = true;
            this.setTableScroll();
            this.fetchRegionReport();
          } else {
            this.actList = this.actList.concat(
              (data?.list || []).map((item) => {
                return { label: item.activityName, value: item.id };
              })
            );
          }

          if ((data?.list || []).length < size) {
            this.loadMoreStatus = "noMore";
          } else {
            this.loadMoreStatus = "more";
          }
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
      }
    },
    onActSelectConfirm(ids, selectedActList) {
      console.log(selectedActList);
      this.selectedActList = selectedActList;
      this.isShowTable = false;
      this.setColumns();
      this.setTableScroll();
      this.isShowTable = true;
      this.fetchRegionReport();
    },
    setColumns() {
      const columns = [
        {
          title: "区域/活动",
          dataIndex: "regionName",
          key: "regionName",
          width: 114,
          align: "left",
          fixed: "left",
          customRender: function(text, record, index) {
            return <div style="paddingLeft: 13px">{{ text }}</div> || "-";
          },
        },
      ];

      this.activityInfoList.forEach((actItem, index) => {
        columns.push({
          align: "left",
          width: 130 * actItem.taskList.length,
          slots: {
            title: `activity_${index}_title`,
          },
          scopedSlots: {
            customRender: `activity_${index}`,
          },
        });
      });

      this.columns = columns;
    },
    setTableScroll() {
      const tableWrap = this.$refs.tableWrap;
      const tableScroll = {
        y: "calc(100vh -172px)",
      };
      const calcX = (cols) => {
        return cols.reduce((total, currCol) => {
          return total + currCol.width;
        }, 0);
      };
      const x = calcX(this.columns);

      if (x > tableWrap.offsetWidth) {
        this.columns[0].fixed = "left";
        tableScroll.x = x;
      } else {
        this.columns[0].fixed = false;
      }

      this.tableScroll = tableScroll;
    },
    async fetchRegionReport() {
      this.loading = true;
      try {
        const params = {
          activityIds: this.selectedActList.map((item) => item.id).join(","),
        };
        const res = await actRegionProgressApi.fetchRegionReport(params);
        const { code, data, message } = res.data;

        this.loading = false;

        if (code === 200) {
          this.regionRoleType = data.regionRoleType;
          this.activityInfoList = data.activityInfoList;
          this.setTableData(data.regionInfoList || []);
          this.setColumns();
          this.setTableScroll();
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    setTableData(list) {
      const data = [];
      list.forEach((item) => {
        const { regionId, regionName } = item;
        const obj = { regionId, regionName };
        const regionDataDetail = item.regionDataDetail || [];

        const result = { regionId, regionName };

        regionDataDetail.forEach((detail) => {
          if (result[detail.activityId]) {
            result[detail.activityId].push(detail);
          } else {
            result[detail.activityId] = [detail];
          }

          detail.taskId = random(1, 999999);
          obj[`taskName_${detail.activityId}`] =
            detail.taskName || "task" + random(1, 99999);
          obj[`completionRate_${detail.taskId}`] = detail.completionRate;
          obj[`taskPerformance_${detail.taskId}`] =
            detail.taskPerformance || random(0, 1);
        });
        data.push(result);
      });

      this.data = data;
      console.log("data:", data);
      console.log(this.data);
    },
    handleExport() {
      try {
        // 埋点操作
        console.log(this.selectedActList);
        const trackData = {
          action: "导出图片",
          activityData: JSON.stringify(
            this.selectedActList.map((item) => ({
              id: item.id,
              activityName: item.activityName,
            }))
          ),
        };
        this.$trackRequest(this.$route, this.$trackEvent.CLICK, trackData);
      } catch (error) {}

      exportTable(
        this.$http,
        { activityIds: this.selectedActList.map((item) => item.id).join(",") },
        "afantiactivity",
        "/jjt/activity/web/report/regionImage",
        "filename"
      );
    },
  },
};
</script>

<style lang="less" scoped>
#page {
  padding: 0 24px;
  height: calc(100vh - 64px);

  .page-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    > div {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  .ml-4 {
    margin-left: 4px;
  }

  .ml-8 {
    margin-left: 8px;
  }

  .ant-btn {
    padding: 0 16px;
    color: #9ca8ba;
    font-size: 14px;
    background: #2b2f3b;
    border: 1px solid #2b2f3b;
    border-radius: 8px;
  }

  .table-wrap {
    width: 100%;

    .rate-tips {
      margin: 0 0 16px;

      span {
        display: inline-flex;
        align-items: center;
        margin: 0 16px 0 0;
        color: rgba(255, 255, 255, 0.88);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        .icon-circle {
          display: inline-block;
          margin: 0 8px 0 0;
          width: 8px;
          height: 8px;
          background: #ffcc55;
          border-radius: 4px;

          &-store {
            background: rgba(255, 255, 255, 0.88);
          }
          &-top {
            background: #24bf79;
          }
          &-bottom {
            background: #f75c5c;
          }
        }
      }
    }

    /deep/ .ant-table {
      &-header-column {
        display: block;
      }
      &-thead {
        & > tr {
          & > th {
            padding: 0 !important;
            height: 105px;
            color: rgba(255, 255, 255, 0.55);
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            background-color: #262834 !important;
            border-right: 1px solid rgba(255, 255, 255, 0.08);
            border-bottom: 1px solid rgba(255, 255, 255, 0.08);

            &:first-child {
              padding: 11px 24px !important;
              border-bottom-color: transparent;
            }

            .name-title {
              display: flex;
              align-items: center;
              height: 62px;
              padding: 13px 8px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.08);
              & > div {
                max-height: 44px;
                overflow: hidden;
              }
            }
            .colspan-title-box {
              display: flex;
              align-items: center;
              height: 42px;
              font-size: 12px;
              & > div {
                flex: 1;
                padding: 0 8px;
                flex-shrink: 0;
                white-space: nowrap;
                min-width: 110px;
                // &:first-child {
                border-right: 1px solid rgba(255, 255, 255, 0.08);
                overflow: hidden;
                // }
                &:last-child {
                  border-right: none;
                }
              }
            }
          }

          &:last-child {
            & > th {
              border-bottom-color: transparent;
            }
          }
        }
      }

      &-tbody {
        & > tr {
          & > td {
            padding: 0 !important;
            color: rgba(255, 255, 255, 0.85);
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            border-right: 1px solid rgba(255, 255, 255, 0.08);
            background: #15171e !important;
            cursor: default !important;
            &:first-child {
              color: rgba(255, 255, 255, 0.85) !important;
            }
          }
        }
      }
    }

    .activity-column {
      display: flex;
      align-items: center;
      height: 48px;
      & > div {
        flex: 1;
        color: rgba(255, 255, 255, 0.88);

        &:first-child {
          color: rgba(255, 255, 255, 0.88);
        }

        &:last-child {
          color: rgba(255, 255, 255, 0.88);
        }
      }
    }
  }

  .empty-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 176px);
  }
}
.cell {
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  &:last-child {
    border-right: none;
  }
  span {
    padding-left: 8px;
  }
}
.top-color {
  // background-color: #554326;
  color: #24bf79 !important;
}
.bottom-color {
  // background-color: #3d4866;
  color: #f75c5c !important;
}
</style>
