<template>
  <div class="data-all-container">
    <div class="data-overview-container">
      <div class="title">数据总览</div>
      <div class="tools-div">
        <WebpImage
          :src="ossHost + infoData.avatar"
          :width="'52px'"
          :height="'52px'"
          :radius="'8px'"
        />
        <div class="tools-div-right">
          <a-dropdown>
            <a class="select-text" @click="(e) => e.preventDefault()">
              <span>{{ currentRegionItem.name || "-" }}</span>
              <a-icon type="down" />
            </a>
            <a-menu
              class="avatar-menu aft-menu"
              slot="overlay"
              @click="changeRegion"
            >
              <a-menu-item :key="index" v-for="(item, index) in regionList">
                {{ item.name }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <span>{{ infoData.principal_name || "-" }}</span>
        </div>
      </div>
      <div class="overview-div">
        <!-- <div class="overview-div-item">
          <span class="label">门店账号数量</span>
          <span class="value">{{ infoData.account_count | changeNum }}</span>
        </div> -->
        <div class="overview-div-item">
          <span class="label">粉丝总量</span>
          <span class="value">{{
            detailData.fans_count_total | changeNum
          }}</span>
        </div>
      </div>
    </div>

    <div class="box-main matrix-main">
      <div class="level-box">
        <div class="level-title">
          <span>各等级账号数:</span>
          <span>{{ getMonth(1) }}月评级</span>
        </div>

        <div class="chart-main">
          <div class="chart-main-left">
            <div>S</div>
            <div>A</div>
            <div>B</div>
            <div>C</div>
            <div>D</div>
          </div>
          <div class="chart-main-right">
            <my-chart
              ref="level_chart"
              :id="'level_chart'"
              :options="getLevelChartOption(levelChartData)"
            />
          </div>
        </div>
      </div>
      <div class="data-box">
        <div class="flex-between">
          <div>
            <span>账号/门店</span>
            <button-tab
              :tabData="levelData"
              @tabChange="levelChange"
              :defaultBtnStyle="levelButtonStyle"
            ></button-tab>
          </div>
        </div>
        <div class="card-list">
          <IconCard
            v-for="(item, index) in levelCardData"
            :key="index"
            :icon="item.icon"
            :background="item.background"
            :color="item.color"
            :name="item.name"
            :value="item.value"
            :tooltipTitle="item.tooltipTitle"
          >
            <div v-if="item.tooltipTitle">{{ item.tooltipTitle }}</div>
          </IconCard>
        </div>
        <div class="tip">
          <span>*{{ getMonth(2) }}</span>
          <span>当月最新情况</span>
        </div>
      </div>
      <!-- <a-spin :spinning="spinning" class="spin"> </a-spin> -->
    </div>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
import api from "@/api/regionHome";
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "dealerOfOneself"
);
import deepClone from "@/utils/clone-utils";
import MyChart from "@/components/MyChart/MyChart";
import IconCard from "@/components/IconCard/IconCard";
import chartLevel from "../options/chartLevel";
import ButtonTab from "@/components/PlatformTab/NewButtonTab";
const levelButtonStyle = {
  minWidth: "56px",
  width: "56px",
  height: "28px",
  fontSize: "12px",
};
export default {
  name: "DataOverview",
  props: {
    platform: {
      type: String,
      default: "douyin",
    },
  },
  components: {
    WebpImage,
    MyChart,
    IconCard,
    ButtonTab,
  },
  data() {
    return {
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      regionList: [],
      currentRegionItem: {},
      infoData: {},
      detailData: {},
      year: moment()
        .subtract(1, "months")
        .format("YYYY"),
      month: moment()
        .subtract(1, "months")
        .format("MM"),
      spinning: false,
      levelButtonStyle,
      levelData: [
        {
          label: "全部",
          value: "全部",
        },
        {
          label: "S级",
          value: "S",
        },
        {
          label: "A级",
          value: "A",
        },
        {
          label: "B级",
          value: "B",
        },
        {
          label: "C级",
          value: "C",
        },
        {
          label: "D级",
          value: "D",
        },
      ],
      currentLevel: "全部",
      levelCardData: [
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-account-b",
          name: "活跃账号",
          value: "-",
          tooltipTitle: "当月直播场次或视频发布大于0的账号数",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-store-b",
          name: "活跃门店",
          value: "-",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-store-b",
          name: "门店活跃比例",
          value: "-",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-account-b",
          name: "账号总数",
          value: "-",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-store-b",
          name: "门店数量",
          value: "-",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-store-b",
          name: "门店占比",
          value: "-",
        },
      ],
      levelChartData: {
        xData: ["S", "A", "B", "C", "D"],
        yData: [],
      },
    };
  },
  created() {
    this.getAreaList();
  },
  computed: {
    ...mapState({
      // accountData: (state) => state.accountData, // 品牌数据
      matrixData: (state) => state.matrixData, // 矩阵活跃数据
    }),
  },
  watch: {},
  methods: {
    ...mapActions(["getMatrixActivation"]),
    ...mapMutations(["changeState"]),
    getComponentsData() {
      // this.getInfo();
      // this.getDetails();
      // this.getMatrixActivationData();

      this.$emit(
        "changeRegion",
        this.currentRegionItem.id,
        this.currentRegionItem.name
      );
    },

    getAreaList() {
      api.getAreaList().then((res) => {
        if (res && res.length > 0) {
          this.regionList = res;
          this.currentRegionItem = res[0];
          this.getComponentsData();
        } else {
          this.$message.warning("当前账号未绑定大区，请联系管理员！");
        }
      });
    },
    getInfo() {
      let data = {
        platform: this.platform,
        params: {
          area_id: this.currentRegionItem.id,
          year: this.year,
          month: this.month,
        },
      };
      api.getInfo(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.infoData = res.data;
        } else {
          this.$message.warning("获取主体详情失败！");
        }
      });
    },
    getDetails() {
      let data = {
        platform: this.platform,
        params: {
          area_id: this.currentRegionItem.id,
        },
      };
      api.getDetails(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.detailData = res.data;
        } else {
          this.$message.warning("获取主体详情失败！");
        }
      });
    },
    changeRegion(val) {
      console.log("changeRegion", val);
      this.currentRegionItem = this.regionList[val.key];
      this.getComponentsData();
    },

    // 等级切换
    levelChange(val) {
      this.currentLevel = val;
      this.setMatrixData();
    },
    async getMatrixActivationData() {
      this.spinning = true;
      await this.getMatrixActivation({
        platform: this.platform,
        params: {
          region_id: this.currentRegionItem.id,
        },
      });
      this.setMatrixData(true);
      this.spinning = false;
    },
    setMatrixData(type) {
      let newYData = [];
      this.matrixData.forEach((item, index) => {
        if (type && item.level) {
          newYData.push(item.account_count);
        }
        if (item.level_name == this.currentLevel) {
          this.levelCardData[0].value = this.$options.filters.changeNum(
            item.active_account_count
          );
          this.levelCardData[1].value = this.$options.filters.changeNum(
            item.active_dealer_count
          );
          this.levelCardData[2].value = this.$options.filters.numRate(
            item.active_dealer_rate
          );
          this.levelCardData[3].value = this.$options.filters.changeNum(
            item.account_count
          );
          this.levelCardData[4].value = this.$options.filters.changeNum(
            item.dealer_count
          );
          this.levelCardData[5].value = this.$options.filters.numRate(
            item.dealer_rate
          );
        }
      });
      if (newYData && newYData.length > 0) {
        this.levelChartData.yData = newYData;
      }
    },
    // 等级图表
    getLevelChartOption(data) {
      let option = null;
      if (!option) {
        option = deepClone(chartLevel);
      }
      let colorArr = ["#E75934", "#FFC840", "#81AFFF", "#F5A679", "#CCD2E3"];
      let xData = [];
      if (data && data.xData && data.xData.length > 0) {
        data.yData.forEach((item, index) => {
          xData.push({
            itemStyle: {
              color: colorArr[index],
            },
            value: item,
          });
        });
      }
      let yData = data.yData || [];
      option.series[0].data = xData;
      option.yAxis[0].data = yData;
      option.series[1].label.normal.formatter = function(data) {
        return yData[data.dataIndex];
      };
      let maxNum = Math.max(...yData);
      option.series[1].data = [maxNum, maxNum, maxNum, maxNum, maxNum];
      return option;
    },
    // 固定展示日期
    getMonth(type) {
      if (type == 1) {
        return moment()
          .subtract(1, "months")
          .format("M");
      }
      if (type == 2) {
        return moment().format("YYYY.MM");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.data-all-container {
  width: 100%;
  height: 312px;
  background: #2b2f3b;
  border-radius: 16px;
  display: flex;
  align-items: center;
  .data-overview-container {
    width: 207px;
    height: 100%;
    padding: 24px 0 24px 24px;
    .title {
      height: 24px;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
    }
    .tools-div {
      width: 100%;
      height: 95px;
      display: flex;
      align-items: center;
      padding: 22px 0 21px;
      &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
        .select-text {
          font-size: 18px;
          color: #ffffff;
          > span {
            margin-right: 4px;
          }
        }
        > span {
          font-size: 12px;
          color: #8890a6;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }
    .overview-div {
      width: 100%;
      height: 190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-item {
        width: 180px;
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        &:last-child {
          margin-block: 26px;
        }
        .label {
          font-size: 14px;
          color: #8890a6;
          line-height: 22px;
        }
        .value {
          font-size: 24px;
          color: #ffffff;
          line-height: 32px;
        }
      }
    }
  }

  .matrix-main {
    padding-top: 52px;
    width: calc(100% - 207px);
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    .level-box {
      width: calc(100% - 500px);
      height: 100%;
      .level-title {
        height: 68px;
        padding: 20px 0;
        > span {
          font-weight: bold;
          font-size: 14px;
          @include font_color("font_color20");
          &:last-child {
            @include font_color("font_color26");
            margin-left: 10px;
          }
        }
      }
      .chart-main {
        width: 100%;
        height: 176px;
        display: flex;
        flex-wrap: nowrap;
        &-left {
          width: 24px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          padding: 0 0 2px 0;
          > div {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            line-height: 14px;
            text-align: center;
            font-size: 12px;
            &:nth-child(1) {
              @include background_color("background_color52");
              @include font_color("font_color27");
            }
            &:nth-child(2) {
              @include background_color("background_color74");
              @include font_color("font_color26");
            }
            &:nth-child(3) {
              @include background_color("background_color54");
              @include font_color("font_color29");
            }
            &:nth-child(4) {
              @include background_color("background_color60");
              @include font_color("font_color31");
            }
            &:nth-child(5) {
              @include background_color("background_color55");
              @include font_color("font_color21");
            }
          }
        }
        &-right {
          width: calc(100% - 24px);
          height: 100%;
        }
      }
    }
    .data-box {
      width: 500px;
      height: 100%;
      padding: 0 20px 20px 0;
      .flex-between {
        display: flex;
        align-items: center;
        height: 34px;
        margin: 16px 0;
        > div:first-child {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          > span {
            font-size: 12px;
            font-weight: bold;
            @include font_color("font_color20");
            margin-right: 12px;
          }
        }
      }
      .card-list {
        display: flex;
        flex-wrap: wrap;
        > div {
          margin-right: 12px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 12px;
          }
        }
      }
      .tip {
        font-size: 12px;
        color: #626a7e;
        line-height: 14px;
        margin-top: 8px;
        > span:first-child {
          margin-right: 10px;
        }
      }
    }
    .spin {
      width: 100%;
      padding: 115px 0;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
    }
  }
}
</style>
