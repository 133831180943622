<template>
  <div>
    <header>
      <platform-header
        title="区域标杆门店"
        :currentTab="currentTab"
        @tabChange="changeTab"
      />
      <div style="padding: 0 24px">
        <div class="top_ads">
          <div class="img_box">
            <img :src="brands_logo" class="img_style" />
          </div>
          <div class="address_rg">
            <platform-tab
              :tabData="areaList.region_list || []"
              @tabChange="changeArea"
            ></platform-tab>
          </div>
        </div>
      </div>
    </header>
    <div class="account-container">
      <div class="account-container-tools">
        <div>
          <span class="font-color2" style="margin: 0 12px">门店最佳账号</span>
          <a-dropdown>
            <a class="font-color3" @click="(e) => e.preventDefault()">
              门店其他账号 <a-icon type="down" />
            </a>
            <a-menu slot="overlay" @click="accountSelect">
              <a-menu-item :key="item.id" v-for="item in accountList">
                {{ item.name }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div>
          <span
            :class="currentRight == 'recent' ? 'font-color2' : 'font-color3'"
            @click="rightTabClick('recent')"
            >最新发布</span
          >
          <span
            :class="currentRight == 'best' ? 'font-color2' : 'font-color3'"
            @click="rightTabClick('best')"
            style="margin: 0 12px"
            >近30天最佳</span
          >
        </div>
      </div>
      <div class="account-container-main">
        <div class="main-loading" v-if="leftLoading || rightLoading">
          <a-spin :spinning="leftLoading || rightLoading"> </a-spin>
        </div>

        <div class="col1">
          <div class="h86 card1">
            <WebpImage
              :src="leftInfoData.avatar"
              :width="'48px'"
              :height="'48px'"
              :radius="'50%'"
              style="margin-right: 8px"
            />
            <div>
              <div class="font-color1 f16 ellipsis">
                {{ leftInfoData.nickname || "-" }}
              </div>
              <div class="f14 ellipsis">
                <!-- todoD -->
                {{ currentTab == "douyin" ? "抖音" : "快手" }}号:
                {{ leftInfoData.code || "-" }}
              </div>
            </div>
          </div>
          <div class="h86 card2">
            <span class="font-color2 f12">账号等级</span>
            <span class="font-color5 f48">{{ leftInfoData.level }}</span>
          </div>
          <div class="h86 card3">
            <div style="line-height: 24px">
              <span class="font-color2 f12" style="margin-top: 27px"
                >近15天/累计</span
              >
              <span class="font-color2 f12">环比</span>
            </div>
            <div>
              <span class="font-color3 f14">直播(场)</span>
              <span class="font-color6 f20"
                >{{ leftInfoData.live_count_increase | changeNum
                }}<span class="font-color3 f14"
                  >/{{ leftInfoData.live_count | changeNum }}</span
                ></span
              >
              <div style="margin-left: 0px">
                <span class="f14 font-color3">{{
                  leftInfoData.live_count_rate | numRateAbs
                }}</span>
                <svg-icon
                  v-if="leftInfoData.live_count_rate"
                  :icon-class="
                    leftInfoData.live_count_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon>
              </div>
            </div>
            <div>
              <span class="font-color3 f14">视频(个)</span>
              <span class="font-color6 f20"
                >{{ leftInfoData.aweme_count_increase | changeNum
                }}<span class="font-color3 f14"
                  >/{{ leftInfoData.aweme_count | changeNum }}</span
                ></span
              >
              <div style="margin-left: 0px">
                <span class="f14 font-color3">{{
                  leftInfoData.aweme_count_rate | numRateAbs
                }}</span>
                <svg-icon
                  v-if="leftInfoData.aweme_count_rate"
                  :icon-class="
                    leftInfoData.aweme_count_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon>
              </div>
            </div>
          </div>
          <div class="h86 card3">
            <div style="line-height: 24px">
              <span class="font-color2 f12" style="margin-top: 27px"
                >账号规模</span
              >
              <span class="font-color2 f12">近15天增长</span>
            </div>
            <div>
              <span class="font-color3 f14">粉丝</span>
              <span class="font-color2 f20">{{
                leftInfoData.fans_count | changeNum
              }}</span>
              <div style="margin-left: 0px">
                <span class="f14 font-color3">{{
                  Math.abs(leftInfoData.fans_count_increase) || "-"
                }}</span>
                <svg-icon
                  v-if="leftInfoData.fans_count_increase"
                  :icon-class="
                    leftInfoData.fans_count_increase >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon>
              </div>
            </div>
            <div>
              <span class="font-color3 f14"
                >获赞总数
                <AftTooltip :code="'qjqt03'" style="top: 0px; left: 4px" />
              </span>
              <span class="font-color2 f20">{{
                leftInfoData.digg_count | changeNum
              }}</span>
              <div style="margin-left: 0px">
                <span class="f14 font-color3">{{
                  Math.abs(leftInfoData.digg_count_increase) || "-"
                }}</span>
                <svg-icon
                  v-if="leftInfoData.digg_count_increase"
                  :icon-class="
                    leftInfoData.digg_count_increase >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="col2">
          <div class="h86 card4">
            <img src="@/assets/img/matrix-card2.png" alt="" />
            <div>
              <div class="font-color2 f20">
                {{ leftInfoData.live_watch_count | changeNum }}
              </div>
              <div class="font-color3 f14">
                {{ currentTab | returnWatchText }}
              </div>
              <div style="text-align: left; margin-top: 4px">
                <svg-icon
                  v-if="leftInfoData.live_watch_count_rate"
                  :icon-class="
                    leftInfoData.live_watch_count_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon
                ><span class="f14 font-color3">{{
                  leftInfoData.live_watch_count_rate | numRateAbs
                }}</span>
              </div>
            </div>
          </div>
          <div class="h86 card4">
            <img src="@/assets/img/matrix-card2.png" alt="" />
            <div>
              <div class="font-color2 f20">
                {{ leftInfoData.live_online_avg | changeNum }}
              </div>
              <div class="font-color3 f14">平均在线</div>
              <div style="text-align: left; margin-top: 4px">
                <svg-icon
                  v-if="leftInfoData.live_online_avg_rate"
                  :icon-class="
                    leftInfoData.live_online_avg_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon
                ><span class="f14 font-color3">{{
                  leftInfoData.live_online_avg_rate | numRateAbs
                }}</span>
              </div>
            </div>
          </div>
          <div class="h86 card4">
            <img src="@/assets/img/matrix-card3.png" alt="" />
            <div>
              <div class="font-color2 f20">
                {{ leftInfoData.aweme_play_count | changeNum }}
              </div>
              <div class="font-color3 f14">视频播放</div>
              <div style="text-align: left; margin-top: 4px">
                <svg-icon
                  v-if="leftInfoData.aweme_play_count_rate"
                  :icon-class="
                    leftInfoData.aweme_play_count_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon
                ><span class="f14 font-color3">{{
                  leftInfoData.aweme_play_count_rate | numRateAbs
                }}</span>
              </div>
            </div>
          </div>
          <div class="h86 card4">
            <img src="@/assets/img/matrix-card3.png" alt="" />
            <div>
              <div class="font-color2 f20">
                {{ leftInfoData.aweme_comment_count | changeNum }}
              </div>
              <div class="font-color3 f14">视频评论</div>
              <div style="text-align: left; margin-top: 4px">
                <svg-icon
                  v-if="leftInfoData.aweme_comment_count_rate"
                  :icon-class="
                    leftInfoData.aweme_comment_count_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon
                ><span class="f14 font-color3">{{
                  leftInfoData.aweme_comment_count_rate | numRateAbs
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col3">
          <div class="h86 card4">
            <img src="@/assets/img/matrix-card2.png" alt="" />
            <div>
              <div class="font-color2 f20">
                {{ leftInfoData.live_time | toMin }}
              </div>
              <div class="font-color3 f14">直播时长</div>
              <div style="text-align: left; margin-top: 4px">
                <svg-icon
                  v-if="leftInfoData.live_time_rate"
                  :icon-class="leftInfoData.live_time_rate >= 0 ? 'up' : 'down'"
                  class="f14"
                ></svg-icon
                ><span class="f14 font-color3">{{
                  leftInfoData.live_time_rate | numRateAbs
                }}</span>
              </div>
            </div>
          </div>
          <div class="h86 card4">
            <img src="@/assets/img/matrix-card2.png" alt="" />
            <div>
              <div class="font-color2 f20">
                {{ leftInfoData.live_polling_count | changeNum }}
              </div>
              <div class="font-color3 f14">直播互动</div>
              <div style="text-align: left; margin-top: 4px">
                <svg-icon
                  v-if="leftInfoData.live_polling_count_rate"
                  :icon-class="
                    leftInfoData.live_polling_count_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon
                ><span class="f14 font-color3">{{
                  leftInfoData.live_polling_count_rate | numRateAbs
                }}</span>
              </div>
            </div>
          </div>
          <div class="h86 card4">
            <img src="@/assets/img/matrix-card3.png" alt="" />
            <div>
              <div class="font-color2 f20">
                {{ leftInfoData.aweme_like_count | changeNum }}
              </div>
              <div class="font-color3 f14">视频点赞</div>
              <div style="text-align: left; margin-top: 4px">
                <svg-icon
                  v-if="leftInfoData.aweme_like_count_rate"
                  :icon-class="
                    leftInfoData.aweme_like_count_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon
                ><span class="f14 font-color3">{{
                  leftInfoData.aweme_like_count_rate | numRateAbs
                }}</span>
              </div>
            </div>
          </div>
          <div class="h86 card4">
            <img src="@/assets/img/matrix-card3.png" alt="" />
            <div>
              <div class="font-color2 f20">
                {{ leftInfoData.aweme_share_count | changeNum }}
              </div>
              <div class="font-color3 f14">视频转发</div>
              <div style="text-align: left; margin-top: 4px">
                <svg-icon
                  v-if="leftInfoData.aweme_share_count_rate"
                  :icon-class="
                    leftInfoData.aweme_share_count_rate >= 0 ? 'up' : 'down'
                  "
                  class="f14"
                ></svg-icon
                ><span class="f14 font-color3">{{
                  leftInfoData.aweme_share_count_rate | numRateAbs
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col4">
          <div class="h179 card5">
            <img src="@/assets/img/matrix-card2.png" alt="" />
            <div>
              <div
                class="top"
                @click="routerToLive(liveAndAwemeData[currentRight].live)"
              >
                <!-- <img :src="liveAndAwemeData[currentRight].live.cover" alt="" /> -->
                <WebpImage
                  :src="liveAndAwemeData[currentRight].live.cover"
                  :scale="'3-4'"
                  :width="'60px'"
                  :height="'80px'"
                  :radius="'4px'"
                  style="margin-right: 8px"
                />
                <div>
                  <a-tooltip>
                    <template slot="title">{{
                      liveAndAwemeData[currentRight].live.title || "暂无直播"
                    }}</template>
                    <div class="font-color2 f14 ellipsis2">
                      {{
                        liveAndAwemeData[currentRight].live.title || "暂无直播"
                      }}
                    </div>
                  </a-tooltip>
                  <div class="font-color3 f12">
                    {{
                      liveAndAwemeData[currentRight].live.publish_time || "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div>
                  <div>{{ currentTab | returnWatchText }}</div>
                  <div class="font-color6">
                    {{
                      liveAndAwemeData[currentRight].live
                        .statistics_total_watch_user_time | changeNum
                    }}
                  </div>
                </div>
                <div>
                  <div>直播时长</div>
                  <div class="font-color6">
                    {{
                      liveAndAwemeData[currentRight].live.statistics_live_time
                        | toMin
                    }}
                  </div>
                </div>
                <div>
                  <div>平均在线</div>
                  <div>
                    {{
                      liveAndAwemeData[currentRight].live
                        .statistics_avg_online_user | changeNum
                    }}
                  </div>
                </div>
                <div>
                  <div>直播互动</div>
                  <div>
                    {{
                      liveAndAwemeData[currentRight].live
                        .statistics_total_polling_count | changeNum
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="h179 card5">
            <img src="@/assets/img/matrix-card3.png" alt="" />
            <div>
              <div
                class="top"
                @click="routerToAweme(liveAndAwemeData[currentRight].aweme)"
              >
                <WebpImage
                  :src="liveAndAwemeData[currentRight].aweme.aweme_cover"
                  :scale="'3-4'"
                  :width="'60px'"
                  :height="'80px'"
                  :radius="'4px'"
                  style="margin-right: 8px"
                />
                <!-- <img :src="liveAndAwemeData[currentRight].aweme.aweme_cover" alt="" /> -->
                <div>
                  <a-tooltip>
                    <template slot="title">{{
                      liveAndAwemeData[currentRight].aweme.aweme_title ||
                        "暂无短视频"
                    }}</template>
                    <div class="font-color2 f14 ellipsis2">
                      {{
                        liveAndAwemeData[currentRight].aweme.aweme_title ||
                          "暂无短视频"
                      }}
                    </div>
                  </a-tooltip>
                  <div class="font-color3 f12">
                    {{
                      liveAndAwemeData[currentRight].aweme.publish_time || "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div>
                  <div>视频播放</div>
                  <div class="font-color4">
                    {{
                      liveAndAwemeData[currentRight].aweme.play_volume
                        | changeNum
                    }}
                  </div>
                </div>
                <div>
                  <div>视频点赞</div>
                  <div class="font-color4">
                    {{
                      liveAndAwemeData[currentRight].aweme.digg_count
                        | changeNum
                    }}
                  </div>
                </div>
                <div>
                  <div>视频评论</div>
                  <div>
                    {{
                      liveAndAwemeData[currentRight].aweme.comment_count
                        | changeNum
                    }}
                  </div>
                </div>
                <div>
                  <div>视频转发</div>
                  <div>
                    {{
                      liveAndAwemeData[currentRight].aweme.share_count
                        | changeNum
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-warp">
      <div class="card" style="position: relative">
        <!-- TOP5门店 -->
        <div class="card-title">
          <svg-icon :icon-class="'title'" class="card-title-icon" />区域标杆门店
        </div>
        <a class="top_count" @click="changeShowCount">{{
          this.showListCount ? "查看TOP30门店" : "收起"
        }}</a>
        <div class="table_box">
          <a-table
            class="table2"
            :columns="columns"
            :row-key="(record) => record.dealer_id"
            :data-source="tableList"
            :loading="tableLoading"
            @change="handleTableChange"
            :pagination="false"
            :locale="{ emptyText: '暂无数据' }"
            :rowClassName="rowClassName"
            :customRow="openDetail"
          >
            <div slot="watchTitle">{{ currentTab | returnWatchText }}</div>
            <div slot="addFansTitle">
              涨粉<AftTooltip :code="'qjzb10'" style="top: 2px; left: 4px" />
            </div>

            <div slot="customTitle" style="display: flex">
              <div style="line-height: 32px; margin-right: 12px">
                经销商名称
              </div>
              <div>
                <a-month-picker
                  :disabled-date="disabledDate"
                  v-model="topMonth"
                  @change="onChangeDate"
                  placeholder="请选择"
                  class="aft-month-picker default"
                  style="width: 100px"
                >
                  <div slot="suffixIcon">
                    <svg-icon :icon-class="'range-picker'" />
                  </div>
                </a-month-picker>
              </div>
            </div>

            <div
              slot="awemePlayCount"
              style="display: flex; align-items: center"
            >
              <div>播放</div>
            </div>

            <div slot="name" slot-scope="text">
              <!-- <a-tooltip placement="topLeft" :title="text"> -->
              <span
                class="font-color2"
                style="height: 52px; display: block; line-height: 52px"
                :title="text"
                >{{ text || "-" }}</span
              >
              <!-- </a-tooltip> -->
            </div>

            <div slot="fans_count" slot-scope="text">
              <span class="font-color3">{{ text | changeNum }}</span>
            </div>
            <div slot="aweme_count" slot-scope="text">
              <span class="font-color7">{{ text | changeNum }}</span>
            </div>
            <div slot="aweme_like_count" slot-scope="text">
              <span class="font-color3">{{ text | changeNum }}</span>
            </div>
            <div slot="aweme_comment_count" slot-scope="text">
              <span class="font-color3">{{ text | changeNum }}</span>
            </div>
            <div slot="aweme_share_count" slot-scope="text">
              <span class="font-color3">{{ text | changeNum }}</span>
            </div>
            <div slot="aweme_play_count" slot-scope="text">
              <span class="font-color3">{{
                text.aweme_play_count | changeNum
              }}</span>
            </div>
            <div slot="live_count" slot-scope="text">
              <span class="font-color7">{{ text | changeNum }}</span>
            </div>
            <div slot="live_like_count" slot-scope="text">
              <span class="font-color3">{{ text | changeNum }}</span>
            </div>
            <div slot="live_comment_count" slot-scope="text">
              <span class="font-color3">{{ text | changeNum }}</span>
            </div>
            <div slot="live_watch_count" slot-scope="text">
              <span class="font-color3">{{ text | changeNum }}</span>
            </div>
            <div slot="fans_count_increase" slot-scope="text">
              <span class="font-color3">{{ text | changeNum }}</span>
            </div>
            <div slot="polling_count" slot-scope="text">
              <span class="font-color6">{{ text | changeNum }}</span>
            </div>
            <!-- <template slot="live_total_time" slot-scope="text">
              {{moment(text).format('D天 H时 mm分 ss秒')}}
            </template> -->
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlatformTab from "@/components/PlatformTab/PlatformTab";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartLine from "../topics/chartLine.js";
import echarts from "echarts";
import WebpImage from "@/components/WebpImage/WebpImage";
import * as vuex from "vuex";
import moment from "moment";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "regionalStores"
);

export default {
  name: "RegionalPerspective",
  components: {
    PlatformHeader,
    PlatformTab,
    MyChart,
    WebpImage,
  },
  data() {
    return {
      currentTab: "",
      currentRight: "recent",
      tooltipTitle: "抖音主播账号授权后，可查看对应视频播放数",
      regionKey: 0,
      contentKey: 1,
      topMonth: null,
      tableOder: "",
      showListCount: true,
      tableList: [],

      brands_logo: this.$store.state.auth.avatar,
      brands_name: this.$store.state.auth.name,
      filteredInfo: null,
      sortedInfo: null,
      currentRow: {},
    };
  },
  computed: {
    ...mapState({
      tableLoading: (state) => state.tableLoading,
      leftLoading: (state) => state.leftLoading,
      rightLoading: (state) => state.rightLoading,
      areaList: (state) => state.areaList,
      dealerTopList: (state) => state.dealerTopList,
      accountList: (state) => state.accountList,
      leftInfoData: (state) => state.leftInfoData,
      liveAndAwemeData: (state) => state.liveAndAwemeData,
    }),
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};

      const columns = [
        {
          // title: "经销商名称",
          dataIndex: "dealer_name",
          key: "dealer_name",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "name" },
          width: "22.48%",
        },
        {
          title: "视频",
          dataIndex: "aweme_count",
          sorter: (a, b) => a.aweme_count - b.aweme_count,
          sortOrder: sortedInfo.columnKey === "aweme_count" && sortedInfo.order,
          scopedSlots: { customRender: "aweme_count" },
          width: "8%",
        },
        // {
        //   title: "涨粉",
        //   dataIndex: "fans_count",
        //   key: "fans_count",
        //   scopedSlots: { customRender: "fans_count" },
        // },
        {
          title: "获赞",
          dataIndex: "aweme_like_count",
          key: "aweme_like_count",
          scopedSlots: { customRender: "aweme_like_count" },
        },
        {
          title: "评论",
          dataIndex: "aweme_comment_count",
          key: "aweme_comment_count",
          scopedSlots: { customRender: "aweme_comment_count" },
        },
        {
          title: "分享",
          dataIndex: "aweme_share_count",
          key: "aweme_share_count",
          scopedSlots: { customRender: "aweme_share_count" },
        },
        {
          key: "aweme_play_count",
          width: "8%",
          slots: { title: "awemePlayCount" },
          scopedSlots: { customRender: "aweme_play_count" },
        },
        {
          title: "直播",
          dataIndex: "live_count",
          key: "live_count",
          sorter: (a, b) => a.live_count - b.live_count,
          sortOrder: sortedInfo.columnKey === "live_count" && sortedInfo.order,
          scopedSlots: { customRender: "live_count" },
          width: "8%",
        },
        {
          title: "获赞",
          dataIndex: "live_like_count",
          key: "live_like_count",
          scopedSlots: { customRender: "live_like_count" },
        },
        {
          title: "评论",
          dataIndex: "live_comment_count",
          key: "live_comment_count",
          scopedSlots: { customRender: "live_comment_count" },
        },
        {
          dataIndex: "live_watch_count",
          key: "live_watch_count",
          slots: { title: "watchTitle" },
          scopedSlots: { customRender: "live_watch_count" },
        },
        {
          dataIndex: "fans_count_increase",
          key: "fans_count_increase",
          slots: { title: "addFansTitle" },
          scopedSlots: { customRender: "fans_count_increase" },
        },
        {
          title: "时长",
          dataIndex: "live_total_time",
          key: "live_total_time",
          scopedSlots: { customRender: "live_total_time" },
        },
        {
          title: "总互动量",
          dataIndex: "polling_count",
          key: "polling_count",
          scopedSlots: { customRender: "polling_count" },
          width: "8%",
        },
      ];
      return columns;
    },
  },

  watch: {
    accountList(val) {},
  },
  methods: {
    ...mapActions([
      "getAreaList",
      "getDealerList",
      "getAccountList",
      "getAccountInfo",
      "getLiveAndAweme",
    ]),
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().endOf("month");
    },
    accountSelect({ key }) {
      this.getAccountInfo({
        author_id: key,
        platform: this.currentTab,
      });
      this.getLiveAndAweme({
        author_id: key,
        platform: this.currentTab,
      });
    },

    //table行class
    rowClassName(record, index) {
      let className = "";
      if (record.dealer_id == this.currentRow.dealer_id) {
        className = "table-row-active";
      }
      return className;
    },
    // 初始化数据
    async initData() {
      await this.getAreaList();
      if (this.areaList.region_list.length) {
        this.regionKey = this.areaList.region_list[0].value;
      } else {
        this.$message.error("本品牌尚未配置区域信息，请您知悉");
      }
      // this.getDealerListData();
    },
    // 获取上个月是时间
    getLastMonth() {
      let start_time = moment()
        .month(moment().month() - 1)
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      let end_time = moment()
        .month(moment().month() - 1)
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      return { start_time, end_time };
    },
    // 切换平台-todoD
    async changeTab(key) {
      this.currentTab = key;
      this.contentKey = key == "douyin" ? 1 : key == "kuaishou" ? 2 : 1;
      if (!this.regionKey) {
        await this.initData();
      }
      this.getDealerListData(this.topMonth);
    },
    // 最近发布/近30天最佳 切换
    rightTabClick(type) {
      this.currentRight = type;
    },
    // 切换区域
    async changeArea(id) {
      this.regionKey = id;
      this.getDealerListData(this.topMonth);
    },
    // 获取top5门店
    async getDealerListData(time) {
      let start_time = "";
      let end_time = "";
      if (time) {
        start_time = `${this.$moment(time)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(time)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        let date = this.getLastMonth();
        start_time = date.start_time;
        end_time = date.end_time;
        this.topMonth = this.$moment()
          .month(moment().month() - 1)
          .startOf("month");
      }
      let params = {
        area_id: this.regionKey,
        begin_time: start_time,
        end_time: end_time,
        order: this.tableOder,
      };
      await this.getDealerList({ type: this.contentKey, params });
      this.disposeDealerTop();
    },
    // 处理top5数据
    disposeDealerTop() {
      if (this.dealerTopList.length) {
        this.currentRow = this.dealerTopList[0];
        console.log(1);
        this.loadInfoBtId(this.dealerTopList[0].dealer_id);
      }
      if (this.showListCount) {
        this.tableList = this.dealerTopList.slice(0, 5);
      } else {
        this.tableList = this.dealerTopList;
      }
    },
    async loadInfoBtId(id) {
      // 获取账号列表
      await this.getAccountList({
        dealer_id: id,
        platform: this.currentTab,
      });

      // 通过账号列表获取中间卡片信息
      if (this.accountList.length) {
        this.getAccountInfo({
          author_id: this.accountList[0].id,
          platform: this.currentTab,
        });
        this.getLiveAndAweme({
          author_id: this.accountList[0].id,
          platform: this.currentTab,
        });
      } else {
        this.$message.warning("当前门店没有绑定账号，请联系管理员！");
      }
    },
    changeShowCount() {
      this.showListCount = !this.showListCount;
      this.disposeDealerTop();
    },
    // 跳转详情
    openDetail(record, index) {
      return {
        on: {
          click: () => {
            console.log("hadskjfhskjdhaskjdhk");
            console.log(2, record);
            this.currentRow = record;
            this.loadInfoBtId(record.dealer_id);
            // this.$router.push(`dealerDetail?id=${record.dealer_id}`);
          },
        },
      };
    },
    // 表格变更
    handleTableChange(pagination, filters, sorter) {
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    //日历不能选大于当前时间的月份
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    onChangeDate(date, dateString) {
      this.getDealerListData(dateString);
    },
    routerToLive(item) {
      console.log(item);
      if (item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${this.currentTab}`;
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
    routerToAweme(item) {
      if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${this.currentTab}`;
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
  },
  mounted() {
    // this.initData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.font-color1 {
  @include font_color("font_color1");
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color3 {
  @include font_color("font_color3");
}
.font-color4 {
  @include font_color("font_color4");
}
.font-color5 {
  @include font_color("font_color5");
}
.font-color6 {
  @include font_color("font_color6");
}
.font-color7 {
  @include font_color("font_color7");
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f20 {
  font-size: 20px;
}
.f48 {
  font-size: 48px;
}
.picker-box {
  position: relative;
  top: -5px;
}
.content_btn {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  text-align: center;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
}
.active_content {
  background-color: rgb(112, 136, 128);
}
.top_ads {
  height: 128px;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0);
  @include background_color("background_color2");
  display: flex;
  .img_box {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    border: 4px solid rgba(0, 0, 0, 0);
    @include border_color("border_color2");
  }
  .address_rg {
    width: calc(100% - 260px);
    max-width: 800px;
  }
}
.img_style {
  height: 100%;
  width: 100%;
}
.chart {
  height: 300px;
}
.card-warp {
  padding: 0 24px;
  margin-top: 18px;
  .card {
    @include background_color("background_color2");
    padding: 14px 16px;
    &-title {
      font-size: 16px;
      @include font_color("font_color2");
      margin-bottom: 12px;
      &-icon {
        @include font_color("font_color5");
        margin-right: 8px;
      }
    }
  }
}
.top_count {
  position: absolute;
  right: 27px;
  top: 20px;
  @include font_color("font_color3");
}
.account-container {
  width: 100%;
  height: 416px;
  padding: 0 24px;
  &-tools {
    height: 48px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > div > span {
      cursor: pointer;
    }
  }
  &-main {
    width: 100%;
    height: 368px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include font_color("font_color3");
    position: relative;
    > .main-loading {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      > div {
        width: 100%;
        height: 100%;
        padding: 140px 0;
        @include background_color("background_color20");
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        border-radius: 4px;
        @include background_color("background_color2");
      }
    }
    .card1 {
      display: flex;
      align-items: center;
      padding: 0 20px;
      @include background_color("background_color36");
      > div {
        line-height: 24px;
        width: calc(100% - 50px);
      }
    }
    .card2 {
      display: flex;
      align-items: center;
      padding: 25px 16px;
      > span {
        width: 33%;
      }
    }
    .card3 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 6px 12px 6px 16px;
      > div {
        display: flex;
        flex-direction: column;
        width: 33%;
        min-width: 33%;
      }
    }
    .card4 {
      padding: 4px 8px 10px 16px;
      position: relative;
      > img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 68px;
        opacity: 0.5;
      }
    }
    .card5 {
      padding: 20px 16px;
      position: relative;
      > img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 136px;
        opacity: 0.5;
      }
      > div {
        .top {
          height: 80px;
          margin-bottom: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          > div > div:first-child {
            margin-bottom: 8px;
          }
        }
        .bottom {
          height: 40px;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          > div {
            height: 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 14px;
            > div:first-child {
              font-size: 12px;
            }
          }
        }
      }
    }
    .col1 {
      width: 27.75%;
    }
    .col2 {
      width: 18.11%;
    }
    .col3 {
      width: 18.11%;
    }
    .col4 {
      width: 32.73%;
    }
    .h86 {
      height: 86.5px;
    }
    .h179 {
      height: 179px;
    }
  }
}
</style>
<style lang="less">
.table_box {
  margin-bottom: 28px;
  // .ant-table-body{
  //       background: rgba(0, 0, 0, 0.35) !important;
  // }
  // .ant-table-tbody > tr > td {
  //   color: #fff !important;
  // }
  .top_count {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    text-align: right;
    margin: 8px 6px;
  }
}
</style>
