var option = {
    title: {
        show: true,
        text: '',
        x: 'center',
        y: 'bottom',
        textStyle: {
            color: '#828282'
        },
    },
    backgroundColor: 'rgba(0,0,0,0)',
    color: ['#22C5E6', '#7A91FE', '#FAA056', '#147F9C', '#4960AA', '#966946'],
    tooltip: {
        show: false,
        trigger: 'item',
        backgroundColor: 'rgba(37, 50, 85, 0.9)',
        textStyle: {
            color: '#fff',
        },
        formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    toolbox: {
        show: true,

    },
    legend: {
        icon: 'circle',
        orient: 'vertical',
        left: '10%',
        align: 'left',
        top: 'middle',
        textStyle: {
            color: '#828282'
        },
    },
    series: [
        {
            name: '',
            type: 'pie',
            radius: [0, '50%'],
            label: {
                formatter: '{c}%',
                padding: [0, -30, 10, -30],
            },
            labelLine: {
                normal: {
                    length: 10,
                    length2: 30,
                }
            },
            itemStyle: {
                borderColor: '#828282',
                borderWidth: 0.5
            },
            data: []
        }
    ]
}

export default option