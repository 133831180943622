<template>
  <div class="best-card" @click="cardClick(bestData)">
    <div class="title-box">
      <div class="title">
        <span class="yellow">{{ bestData.name }}</span>
      </div>
    </div>
    <div class="top-list-box">
      <div class="item">
        <WebpImage
          :src="bestData.cover || ''"
          :width="'84px'"
          :height="'112px'"
          :radius="'8px'"
        />
        <div class="content">
          <div class="title">
            {{ bestData.title || "-" }}
          </div>
          <div class="detail">
            <WebpImage
              :src="bestData.avatar || ''"
              :width="'20px'"
              :height="'20px'"
              :radius="'10px'"
              class="avatar"
            />
            <div class="name">
              {{ bestData.dealer_name || "-" }}
            </div>
          </div>
          <div class="data">
            <div
              class="col"
              v-for="(item, index) in bestData.numberData"
              :key="index"
            >
              <div class="name">{{ item.name }}:</div>
              <div class="num">
                <span>{{ item.value | changeNum }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip/Tooltip";
import Empty from "@/components/Empty/Empty";
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "BestCard",
  props: {
    bestData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Tooltip,
    Empty,
    WebpImage,
  },
  created() {
  },
  methods: {
    cardClick(data) {
      if (data.room_id) {
        let url = `/liveDetail?id=${data.room_id}&platform=${data.platform}`;
        window.open(url);
      } else if (data.aweme_id) {
        let url = `/videoDetail?id=${data.aweme_id}&platform=${data.platform}`;
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.best-card {
  width: 100%;
  height: 100%;
  padding: 20px 20px 30px;
  background: #2b2f3b;
  border-radius: 16px;
  &:hover {
    background: #363a4a;
  }
}
.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  .title {
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 500;
      @include font_color("font_color20");
      &.yellow {
        @include font_color("font_color4");
      }
    }
  }
}
.top-list-box {
  .item {
    display: flex;
    padding: 20px 0 10px 0;
    cursor: pointer;
  }
  .cover {
    width: 84px;
    height: 112px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    @include background_color("background_color73");
  }
  .content {
    flex: 1;
    margin-left: 20px;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    height: 51px;
    @include font_color("font_color21");
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .detail {
    display: flex;
    align-items: center;
    margin: 8px 0 18px;
    .avatar {
      margin-right: 8px;
    }
    .name {
      font-size: 14px;
      @include font_color("font_color23");
    }
  }
  .data {
    display: flex;
    flex-wrap: wrap;
    .col {
      display: flex;
      align-items: center;
      margin-right: 14px;
    }
    .name {
      font-size: 12px;
      @include font_color("font_color23");
    }
    .num {
      margin-left: 4px;
      font-size: 14px;
      color: #d0d6e6;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>