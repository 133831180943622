var option = {
    backgroundColor: 'rgba(0,0,0,0)',
    color: ['#22C5E6', '#7A91FE', '#FAA056', '#147F9C', '#4960AA', '#966946'],
    legend: {
        top: 10,
        left: 200,
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
            fontSize: 14,
            color: '#96A4F4',
            padding: [3, 0, 0, 0]
        },
        data: ['总分享数', '总评论数', '总点赞数']
    },
    tooltip: {
        show: true,
        backgroundColor: 'rgba(37, 50, 85, 0.9)',
        textStyle: {
            color: '#fff',
        }
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '13%',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        axisLabel: {
            color: '#a4a4a4'
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false,
        },
        data: []
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: '#a4a4a4'
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false,
        },
        splitLine: {
            show: false
        },
    },
    series: [

    ]
};
export default option