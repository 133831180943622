<template>
  <div :class="shadowShow ? 'select-container open' : 'select-container'">
    <div class="select-button" @click="openShadow">
      <span
        >{{ modalData[selectedIndex1].detail[selectedIndex2].name || "-" }}:
        {{
          modalData[selectedIndex1].detail[selectedIndex2].count | changeNum
        }}</span
      >
      <svg-icon :icon-class="'select-arrow'" class="icon" />
    </div>
    <div class="button-tab">
      <div
        :class="['button-tab-item', selectedIndex3 == index ? 'active' : '']"
        v-for="(item, index) in tabData"
        :key="index"
        @click="tabClick(item, index)"
      >
        <span>{{ item.count | changeNum }}</span>
        <span>{{ item.name || "-" }}</span>
      </div>
    </div>
    <div class="modal-container">
      <div class="modal-shadow" @click="closeShadow"></div>
      <div class="modal-main">
        <div class="modal-main-container">
          <div class="list list1">
            <div
              :class="['list-item', selectedIndex1 == index ? 'active' : '']"
              v-for="(item, index) in modalData"
              :key="index"
              @click="optionClick1(item, index)"
            >
              <span>{{ item.name || "-" }}: {{ item.count | changeNum }}</span>
              <svg-icon :icon-class="'arrow-right'" class="icon" />
            </div>
          </div>
          <div
            class="list list2"
            v-if="modalData[selectedIndex1] && modalData[selectedIndex1].detail"
          >
            <div
              :class="['list-item', selectedIndex2 == index ? 'active' : '']"
              v-for="(item, index) in modalData[selectedIndex1].detail"
              :key="index"
              @click="optionClick2(item, index)"
            >
              <span>{{ item.name || "-" }}: {{ item.count | changeNum }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import IconTitle from "@/components/IconTitle/IconTitle";
export default {
  name: "MySelectThree",
  components: {
    // CardTooltip,
  },
  props: {
    modalData: {
      type: Array,
      defult: [],
    },
    currentItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      shadowShow: false,
      activeKey: this.currentItem,
      selectedIndex1: 0, // 第一级
      selectedIndex2: 0, // 第二级
      selectedIndex3: 0, // 第三级
      tabData: [],
    };
  },
  created() {
    if (this.modalData && this.modalData.length > 0) {
      this.tabData = this.modalData[0].detail[0].detail;
    }
  },
  mounted() {
    document.querySelector(".modal-container").style.display = "none";
  },
  computed: {},
  watch: {},
  methods: {
    openShadow() {
      document.querySelector(".modal-container").style.display = "inline-block";
      setTimeout(() => {
        this.shadowShow = true;
      }, 10);
    },
    closeShadow() {
      if (this.shadowShow) {
        this.shadowShow = false;

        this.selectedIndex3 = 0;
        this.tabData =
          this.modalData[this.selectedIndex1].detail[
            this.selectedIndex2
          ].detail;
        this.activeKey = this.tabData[0];
        this.$emit("update:currentItem", this.activeKey);
        this.$emit("changeItem", this.tabData[0], this.tabData);
        setTimeout(() => {
          document.querySelector(".modal-container").style.display = "none";
        }, 200);
      }
    },

    tabClick(item, index) {
      if (this.selectedIndex3 == index) {
        return false;
      }
      this.selectedIndex3 = index;
      this.activeKey = item;
      this.$emit("update:currentItem", this.activeKey);
      this.$emit("changeItem", item);
    },
    optionClick1(item, index) {
      if (this.selectedIndex1 == index) {
        return false;
      }
      this.selectedIndex1 = index;
      this.selectedIndex2 = 0;
    },
    optionClick2(item, index) {
      this.selectedIndex2 = index;
      this.closeShadow();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.select-container {
  height: 40px;
  position: relative;
  display: flex;
  .select-button {
    width: 180px;
    height: 100%;
    border-radius: 8px;
    padding: 10px 12px 10px 16px;
    @include background_color("background_color46");
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    > span {
      @include font_color("font_color20");
      font-weight: bold;
    }
    .icon {
      @include font_color("font_color23");
      transition: all 0.2s;
    }
  }
  .button-tab {
    display: flex;
    height: 40px;
    &-item {
      cursor: pointer;
      height: 40px;
      background: #2b2f3b;
      border-radius: 8px;
      margin-left: 12px;
      padding: 4px 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      > span:first-child {
        height: 17px;
        font-size: 12px;
        font-weight: bold;
        color: #c8cede;
        line-height: 17px;
      }
      > span:last-child {
        height: 14px;
        font-size: 12px;
        color: #9ca8ba;
        line-height: 14px;
      }
      &.active {
        background: #ffca5c;
        > span:first-child {
          color: #1c1e27;
        }
        > span:last-child {
          color: #1c1e27;
        }
      }
    }
  }
  .modal-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    .modal-shadow {
      width: 100vw;
      height: 100vh;
      position: fixed;
      background: rgba(0, 0, 0, 0);
      z-index: 998;
      left: 0;
      top: 0;
      display: none;
    }
    .modal-main {
      width: 400px;
      opacity: 0;
      height: 0px;
      background: rgba(45, 50, 64, 0.74);
      backdrop-filter: blur(10px);
      box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.3);
      border-radius: 16px;
      position: absolute;
      top: 0px;
      left: 0;
      z-index: 999;
      transition: all 0.2s;
      &-container {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-wrap: nowrap;
        .list {
          width: 50%;
          height: 100%;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          padding: 14px 0;
          &-item {
            width: 100%;
            min-height: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
            color: #d0d6e6;
            cursor: pointer;
            &:hover {
              background: rgba(0, 0, 0, 0.4);
            }
            > span {
              font-weight: bold;
              font-size: 14px;
            }
            .icon {
              font-size: 20px;
            }
          }
        }
        .list1 {
          border-right: 1px solid #242832;
          .list-item.active {
            color: #ffca5c;
          }
        }
        .list2 {
          .list-item.active {
            color: #ffca5c;
            background: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

  &.open {
    .select-button {
      border: 1px solid #ffca5c;
      .icon {
        transform: rotate(180deg);
      }
      > span {
        @include font_color("font_color26");
      }
    }
    .modal-container {
      .modal-shadow {
        display: block;
      }
      .modal-main {
        opacity: 1;
        height: 202px;
      }
    }
  }
}
</style>
