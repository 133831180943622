<template>
  <div
    class="author-item"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
    @click="openDetail"
    ref="item"
  >
    <div :class="['author-item-avatar', `type${type}`]">
      <WebpImage
        :scale="'1-1'"
        :src="authorData.avatar"
        :width="'42px'"
        :height="'42px'"
        :radius="'50%'"
      />
    </div>
    <div class="author-item-name">{{ authorData.nickname }}</div>
    <div class="modal-detail" ref="modal">
      <div class="modal-detail-container">
        <div
          class="data-box"
          v-for="(item, index) in authorData.data"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <span>{{ item.value | changeNum }}</span>
        </div>
        <div class="type-box">
          <img src="@/assets/img/type-lanv.png" v-if="accountType && accountType == '2'" />
          <img src="@/assets/img/type-ordinary.png" v-else />
          <span>{{ authorData.nickname }}</span>
        </div>
        <div class="store-box">
          <img src="@/assets/img/type-store.png" />
          <span>{{ authorData.dealer_name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "AuthorItem",
  components: {
    WebpImage,
  },
  props: {
    // 指示条背景色
    type: {
      type: String,
      default: "",
    },
    accountType: {
      type: String,
      default: "",
    },
    authorData: {
      type: Object,
      defautl: () => {
        return {};
      },
    },
    showModal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    // 移入
    mouseOver(e) {
      if (!this.showModal) {
        return false
      }
      // 操作dom 获取p标签改变其样式
      let top = this.$refs.item.getBoundingClientRect().top - this.$refs.modal.getBoundingClientRect().height - 14 + "px";
      let left = this.$refs.item.getBoundingClientRect().left - 61 + "px";
      this.$refs.modal.style.top = top;
      this.$refs.modal.style.left = left;
      this.$refs.modal.style.display = "inline-block";
    },
    // 移出
    mouseLeave(e) {
      if (!this.showModal) {
        return false
      }
      this.$refs.modal.style.display = "none";
    },
    openDetail() {
      if (!this.showModal) {
        return false
      }
      console.log(this.authorData)
      if (this.authorData.author_id) {
        let url = `/dealer/production?author_id=${this.authorData.author_id}&type=${this.authorData.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无author ID，无法跳转作品发布");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.author-item {
  position: relative;
  cursor: pointer;
  &:hover {
    .author-item-name {
      color: #fff;
    }
  }
  &-avatar {
    width: 44px;
    height: 44px;
    padding: 1px;
    border-radius: 50% 4px 50% 50%;
    margin: 0 auto;
    &.type1 {
      background: #ffca5c;
    }
    &.type2 {
      background: #8baffe;
    }
    &.type3 {
      background: #f5a679;
    }
  }
  &-name {
    width: 70px;
    height: 34px;
    font-size: 12px;
    color: #8890a6;
    line-height: 17px;
    display: inline-block;
    text-align: center;
    margin-top: 12px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .modal-detail {
    width: 194px;
    background: #3c4252;
    box-shadow: 0px 3px 9px 0px rgba(20, 22, 28, 0.26);
    border-radius: 8px;
    // position: absolute;
    // top: -130px;
    // left: -80px;
    position: fixed;
    z-index: 999;
    display: none;
    &-container {
      width: 100%;
      height: 100%;
      padding: 12px;
      .data-box {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        > span:first-child {
          font-size: 12px;
          color: #9ca8ba;
        }
        > span:last-child {
          font-size: 12px;
          color: #ffffff;
          margin-left: 8px;
        }
      }
      .type-box {
        display: flex;
        align-items: center;
        margin-top: 10px;
        > img {
          width: 22px;
          height: 22px;
        }
        > span {
          font-size: 12px;
          color: #ffffff;
          margin-left: 8px;
        }
      }
      .store-box {
        display: flex;
        align-items: center;
        > img {
          width: 22px;
          height: 22px;
        }
        > span {
          font-size: 12px;
          color: #c8cede;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>