<template>
  <div
    class="blur-card"
    :style="{
      width: width + 'px',
      height: height + 'px',
      top: top + 'px',
      left: left + 'px',
    }"
  ></div>
</template>

<script>
export default {
  name: "BlurCard",
  props: ["width", "height", "top", "left"],
};
</script>

<style scoped lang="less">
.blur-card {
  filter: blur(16px);
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3) url("../../assets/live_images/bg_ac.png")
    no-repeat fixed -8px -1px;
}
</style>
