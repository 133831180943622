<template>
  <div class="tabs-content4">
    <div class="tabs-content4-main">
      <div class="title-div">
        <span class="title">大区对比</span>
      </div>
      <div class="chart-div" v-if="regionData && regionData.length > 0">
        <div class="chart-div-left">
          <div class="table-container">
            <div class="table-container-header">
              <div>大区</div>
              <div>视频线索</div>
              <div>直播线索</div>
              <div>私信线索</div>
              <div>线索总数</div>
              <div>店均</div>
              <div>店均变化</div>
            </div>
            <div class="table-container-body">
              <div
                :class="[
                  'table-item',
                  currentLabel == item.area_name ? 'active' : '',
                ]"
                v-for="(item, index) in regionData"
                :key="index"
              >
                <div>{{ item.area_name || "暂无大区" }}</div>
                <div>{{ item.aweme_clue | changeNum }}</div>
                <div>{{ item.live_clue | changeNum }}</div>
                <div>{{ item.private_letter | changeNum }}</div>
                <div>{{ item.sales_count | changeNum }}</div>
                <div>{{ item.avg_dealer_sales | changeNum }}</div>
                <div>
                  <span style="margin: 0 6px 0 0">{{
                    item.day_chain | numRate
                  }}</span>
                  <svg-icon
                    v-if="item.day_chain >= 0"
                    icon-class="caret-up"
                    style="font-size: 10px"
                  />
                  <svg-icon
                    v-if="item.day_chain < 0"
                    icon-class="caret-down"
                    style="font-size: 10px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-div-right">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(pieData)"
            :id="'pie-chart-2'"
          />
        </div>
      </div>

      <div class="empty-box" v-else>
        <Empty title="暂无数据" />
      </div>
    </div>
    <div class="chart-div-bottom">
      <div class="title-div">
        <span class="title">门店分级</span>
      </div>
      <div class="chart-div-main">
        <MyChart
          v-if="chartShow"
          :options="getLineOption(lineData)"
          :id="'line-chart-2'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import chartPie4 from "../../options/chartPie4.js";
import chartLine3 from "../../options/chartLine3.js";
import deepClone from "@/utils/clone-utils";
import api from "@/api/clueMarket.js";
import Empty from "@/components/Empty/Empty";
export default {
  name: "TabsContent4",
  components: { MyChart, Empty },
  data() {
    return {
      chartShow: true,
      regionData: [],
      currentLabel: "",
      pieData: [],
      lineData: {
        xData: [],
        yData1: [],
        yData2: [],
        yData3: [],
        yData4: [],
      },
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getComponentRegion();
      this.getComponentLevel();
      this.currentLabel = this.$parent.area_id.label.trim();
    },
    getPieOption(data) {
      let option = deepClone(chartPie4);
      let total = data.reduce((sum, val) => {
        return sum + (val.value || 0);
      }, 0);
      option.title[0].text = this.$options.filters.changeNum(total);
      option.series[0].data = data;
      return option;
    },
    getLineOption(data) {
      let option = deepClone(chartLine3);
      option.xAxis.data = data.xData;
      option.series[0].data = data.yData1;
      option.series[1].data = data.yData2;
      option.series[2].data = data.yData3;
      option.series[3].data = data.yData4;
      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getComponentRegion() {
      let data = this.getParentsParams();
      api.getComponentRegion(data).then((res) => {
        console.log("getComponentRegion", res);
        const { data, code } = res.data;
        if (code == 0) {
          this.regionData = data || [];
          this.pieData = data.map((item) => {
            return {
              value: item.sales_count || 0,
              name: item.area_name || "暂无大区",
            };
          });
        }
      });
    },
    getComponentLevel() {
      let data = this.getParentsParams();
      api.getComponentLevel(data).then((res) => {
        console.log("getComponentLevel", res);
        const { data, code } = res.data;
        if (code == 0) {
          if (!data || data.length == 0) {
            this.lineData = {
              xData: [],
              yData1: [],
              yData2: [],
              yData3: [],
              yData4: [],
            };
            return false;
          }
          this.lineData = {
            xData: data.map((item) => {
              return item.date;
            }),
            yData1: data.map((item) => {
              return item.level1;
            }),
            yData2: data.map((item) => {
              return item.level2;
            }),
            yData3: data.map((item) => {
              return item.level3;
            }),
            yData4: data.map((item) => {
              return item.level4;
            }),
          };
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content4 {
  width: 100%;
  &-main {
    width: 100%;
    height: 392px;
    background: #2b2f3b;
    border-radius: 16px;
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      > .title {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .chart-div {
      width: 100%;
      height: 336px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      &-left {
        width: 55%;
        height: 100%;
        .table-container {
          width: 100%;
          padding: 0 30px 0 20px;
          &-header {
            width: 100%;
            height: 50px;
            background: #242832;
            box-shadow: inset 0px -1px 0px 0px #3c4252;
            display: flex;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 50px;
            > div {
              flex: 1;
              text-align: right;
              padding: 0 20px 0 0;
              &:first-child {
                text-align: left;
                max-width: 100px;
                padding-left: 20px;
              }
              &:last-child {
                min-width: 120px;
              }
            }
          }
          &-body {
            width: 100%;
            height: 260px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            .table-item {
              width: 100%;
              height: 50px;
              background: rgba(255, 255, 255, 0.01);
              box-shadow: inset 0px -1px 0px 0px #3c4252;
              display: flex;
              font-size: 14px;
              color: rgba(255, 255, 255, 0.85);
              line-height: 50px;
              > div {
                flex: 1;
                text-align: right;
                padding: 0 20px;
                &:first-child {
                  text-align: left;
                  max-width: 100px;
                }
                &:last-child {
                  min-width: 120px;
                }
              }
              &.active {
                background: #3b3835;
                box-shadow: inset 0px -1px 0px 0px #363a46,
                  inset 2px 0px 0px 0px #ffcc55;
              }
            }
          }
        }
      }
      &-right {
        width: 45%;
        height: 100%;
      }
    }

    .empty-box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .chart-div-bottom {
    width: 100%;
    height: 429px;
    background: #2b2f3b;
    border-radius: 16px;
    margin-top: 16px;
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      > .title {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .chart-div-main {
      width: 100%;
      height: 367px;
    }
  }
}
</style>
