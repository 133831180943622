import echarts from 'echarts'
import filters from '@/filters'

var option = {
  tooltip: {
    trigger: 'axis',
    position: function (point, params, dom, rect, size) {
      var x = 0; // x坐标位置
      var y = 0; // y坐标位置
      // 当前鼠标位置
      var pointX = point[0];
      var pointY = point[1];
      // 提示框大小
      var boxWidth = size.contentSize[0];
      var boxHeight = size.contentSize[1];

      // boxWidth > pointX 说明鼠标左边放不下提示框
      if (boxWidth > pointX) {
        x = pointX + 10;
      } else { // 左边放的下
        x = pointX - boxWidth - 10;
      }

      // boxHeight > pointY 说明鼠标上边放不下提示框
      if (boxHeight > pointY) {
        y = 5;
      } else { // 上边放得下
        y = pointY - boxHeight;
      }
      return [x, y]
    },
    backgroundColor: "rgba(0,0,0,0)", //设置背景图片 rgba格式
    formatter: function (params) {
      let str = `<div style="background: #3C4252;border-radius: 8px;width: 183px;height: 93px;">
            <div style="width: 100%;
                height: 25px;
                line-height: 25px;
                font-size: 10px;
                color: #C8CEDE;
                border-bottom: 1px solid rgba(255,255,255, 0.1);
                padding-left: 8px;">
                ${params[0].axisValue}
            </div>
            <div style="display:flex;align-items: center;height: 24px;margin-top: 10px;">
            <span style="display:inline-block;margin:0 12px 0 20px;border-radius:4px;width:4px;height:4px;background-color:${params[0].color};"></span>
            <span style="font-size: 12px;color: #9CA8BA;font-weight: bold;">${params[0].seriesName}:</span>
            <span style="font-size: 12px;color: #fff;font-weight: bold;margin-left: 8px;">${filters.changeNum(params[0].data)}</span>
            </div>
        `
      if (params && params[1]) {
        str += `
          <div style="display:flex;align-items: center;height: 24px;">
          <span style="display:inline-block;margin:0 12px 0 20px;border-radius:4px;width:4px;height:4px;background-color:#FFCA5C;"></span>
          <span style="font-size: 12px;color: #9CA8BA;font-weight: bold;">${params[1].seriesName}:</span>
          <span style="font-size: 12px;color: #fff;font-weight: bold;margin-left: 8px;">${filters.numRate2(params[1].data)}</span>
          </div>`
      }
      str += "</div>"
      return str;
    }
  },

  grid: {
    left: '70',
    right: '70',
    top: '16',
    bottom: '16',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      show: false, //隐藏x轴
    },
    axisTick: {
      show: false,  //刻度线
    },
    axisLabel: {
      show: false, //隐藏刻度值
    },
    data: []
  },
  yAxis: [
    {
      type: 'value',
      name: '视频发布',
      position: 'right',
      boundaryGap: false,
      axisLine: {
        show: false, //隐藏x轴
      },
      axisTick: {
        show: false,  //刻度线
      },
      axisLabel: {
        show: false, //隐藏刻度值
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ["rgba(255,255,255,0.04)"],
        },
      },
      scale: true,/*按比例显示*/
    }, {
      type: 'value',
      name: '环比',
      boundaryGap: false,
      axisLine: {
        show: false, //隐藏x轴
      },
      axisTick: {
        show: false,  //刻度线
      },
      axisLabel: {
        show: false, //隐藏刻度值
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ["rgba(255,255,255,0.04)"],
        },
      },
      scale: true,/*按比例显示*/
    },
  ],
  series: [
    {
      name: '视频发布',
      type: 'bar',
      yAxisIndex: 1,
      barMaxWidth: 44,
      itemStyle: {
        normal: {
          color: '#815CFF',
          barBorderRadius: [8, 8, 0, 0]
        },
      },
      data: []
    },
    {
      name: '环比',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      showSymbol: true,
      symbolSize: 4,
      itemStyle: {
        normal: {
          color: 'rgba(255, 202, 92, 0.4)'
        }
      },
      data: []
    },
  ]
}

export default option