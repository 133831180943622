<template>
  <div class="container">
    <platform-header
      title="商务政策"
      :currentTab="currentTab"
      @tabChange="tabChange"
    >
    </platform-header>
    <div class="tabs">
      <div>
        <Tabs :tabData="tabsList" @tabChange="changeTabs" />
        <div class="beta-icon">beta</div>
        <div class="beta-icon2">beta</div>
      </div>
      <div>
        <date-tab
          style="margin: 0 10px"
          :type="dateType"
          @changeType="changeType"
          @changeDate="onChange"
          ref="dateTab"
        ></date-tab>
        <a-button
          class="button3"
          @click="routerToResult"
          v-show="currentTabsKey == '2' && currentButtonTab2 == '3'"
        >
          <svg-icon icon-class="button-activity" class="btn-icon" />
          <span>考核结果</span>
        </a-button>
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button class="button2" @click="exportClick">
          <svg-icon icon-class="button-export" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>
    <div class="tools">
      <button-tab
        v-show="currentTabsKey == '1'"
        :tabData="buttonTabData"
        @tabChange="buttonTabChange"
      ></button-tab>
      <button-tab
        ref="assessmentTab"
        v-show="currentTabsKey == '2'"
        :tabData="buttonTabData2"
        @tabChange="buttonTabChange2"
      ></button-tab>
      <div v-show="currentTabsKey == '3'"></div>
      <div style="display: flex">
        <!-- <a-input-search
          v-show="currentTabsKey == '2' && currentButtonTab2 == '4'"
          class="aft-search"
          v-model="searchInput"
          placeholder="请输入本月活动关键字"
          style="width: 186px; margin-right: 10px"
          @search="onSearch"
        /> -->
        <a-select
          v-if="
            currentTabsKey == '2' &&
              (currentButtonTab2 == '3' || currentButtonTab2 == '4')
          "
          class="aft-select default"
          :suffixIcon="suffixIcon"
          v-model="activity_id"
          placeholder="选择活动"
          style="width: 196px; margin-right: 10px"
          :allowClear="true"
          @change="loadAssessmentlist(true)"
        >
          <a-select-option :value="0">全部活动</a-select-option>
          <a-select-option
            v-for="item in activityList"
            :value="item.id"
            :key="item.id"
            >{{ item.activity_name }}</a-select-option
          >
        </a-select>
        <a-select
          v-if="currentTabsKey == '1' || currentTabsKey == '2'"
          class="aft-select default"
          v-model="regionId"
          :suffixIcon="suffixIcon"
          placeholder="选择大区"
          style="width: 196px"
          @change="regionChange"
        >
          <a-select-option
            v-for="item in areaList.region_list || []"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <a-select
          v-if="currentTabsKey == '2' || currentTabsKey == '3'"
          class="aft-select default"
          v-model="groupValue"
          :suffixIcon="suffixIcon"
          placeholder="选择分组"
          style="width: 196px; margin-left: 10px"
          @change="groupChange"
        >
          <a-select-option value="">不限分组</a-select-option>
          <a-select-option v-for="item in groupData" :key="item.group_code">{{
            item.group_name
          }}</a-select-option>
        </a-select>
        <!-- <a-month-picker
        style="margin: 0 10px"
        class="aft-month-picker default"
        v-model="searchTime"
        dropdownClassName="my-calendar-class"
        placeholder="Select month"
        :disabled-date="pickerOptions"
        @change="onChange"
        locale="zh-cn"
      /> -->
      </div>
    </div>
    <div class="content content1" v-show="currentTabsKey == '1'">
      <!-- 区域 -->
      <div class="first-list">
        <div class="list-title" v-if="listData && listData.areas.length > 0">
          区域
        </div>
        <div
          class="first-list-item"
          v-for="(item, index) in listData.areas"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div
        class="other-list tab1-list"
        v-for="item in listData.items"
        :key="item.title"
      >
        <div class="list-title">{{ item.title }}</div>
        <div class="list-item f14" v-for="(i, index) in item.list" :key="index">
          <span class="font-color2">{{ i.count | changeNum }}</span>
          <div>
            <span class="font-color3">{{
              currentButtonTab == "proportion"
                ? "占比"
                : currentButtonTab == "tagetValue"
                ? "目标值"
                : currentButtonTab == "finishRatio"
                ? "完成率"
                : ""
            }}</span>
            <span class="font-color2" v-if="currentButtonTab == 'tagetValue'">{{
              i[currentButtonTab]
            }}</span>
            <span class="font-color2" v-else>{{
              i[currentButtonTab] | numRate
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <Empty
      style="margin-top: 240px"
      title="暂无数据"
      v-show="
        currentTabsKey == '1' && (!listData || listData.areas.length == 0)
      "
    />

    <div class="content-table" v-show="currentTabsKey == '2'">
      <a-table
        :columns="
          currentButtonTab2 == 1 && !isYqhq
            ? columns1
            : currentButtonTab2 == 1 && isYqhq
            ? columns1_yqhq
            : currentButtonTab2 == 2 && !isYqhq
            ? columns2
            : currentButtonTab2 == 2 && isYqhq
            ? columns2_yqhq
            : currentButtonTab2 == 3
            ? columns3
            : columns4
        "
        :scroll="{ y: 'calc(100vh - 390px)' }"
        :data-source="(assessmentlistData && assessmentlistData.list) || []"
        :rowKey="(data) => data.dealer_id"
        :pagination="false"
        class="aft-table default ranking"
        :locale="locale"
        :loading="tableLoading"
        @change="tableChange"
      >
        <div slot="playTitle" style="display: flex; align-items: center">
          <div>视频播放</div>
        </div>
        <div slot="statistics_fans_add"></div>
        <div slot="filterIcon">
          <AftTooltip :code="'qjzb10'" style="top: 16px; left: -10px" />
        </div>
        <div slot="sort" slot-scope="text, records, index" class="table-sort">
          <span
            :class="
              records.sort == '1'
                ? 'ranking1'
                : records.sort == '2'
                ? 'ranking2'
                : records.sort == '3'
                ? 'ranking3'
                : 'ranking'
            "
            >{{ index + 1 + (current - 1) * pageSize }}</span
          >
        </div>
      </a-table>

      <div class="page-div">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
    <div class="content" v-show="currentTabsKey == '3'">
      <div
        class="activity-first-list"
        style="border-radius: 16px 0 0 16px"
        v-show="activityData && activityData.length > 0"
      >
        <div class="list-title">排名</div>
        <div
          class="activity-first-list-item"
          v-for="(item, index) in activityData"
          :key="index"
        >
          <span
            :class="
              index == 0
                ? 'font-color26'
                : index == 1
                ? 'font-color29'
                : index == 2
                ? 'font-color36'
                : 'font-color23'
            "
            >{{ index + 1 }}</span
          >
        </div>
      </div>
      <!-- 区域 -->
      <div
        class="activity-first-list"
        v-show="activityData && activityData.length > 0"
      >
        <div class="list-title">区域</div>
        <div
          class="activity-first-list-item"
          v-for="(item, index) in activityData"
          :key="index"
        >
          {{ item.area }}
        </div>
      </div>
      <div
        class="activity-first-list"
        v-show="activityData && activityData.length > 0"
      >
        <div class="list-title">平台</div>
        <div
          class="activity-first-list-item"
          v-for="(item, index) in activityData"
          :key="index"
        >
          {{
            currentTab == "douyin"
              ? "抖音"
              : currentTab == "kuaishou"
              ? "快手"
              : currentTab == "dongchedi"
              ? "懂车帝"
              : "-"
          }}
        </div>
      </div>
      <div
        class="activity-first-list dealer-list"
        style="width: 164px; min-width: 164px"
      >
        <div
          class="list-title other-title"
          style="width: 164px"
          v-show="activityData && activityData.length > 0"
        >
          <span>经销商数</span>
          <div class="sort-div" @click="sortClick('dealer')">
            <a-icon
              type="caret-up"
              :class="
                activitySortIndex === 'dealer' && activitySortType == 'up'
                  ? 'active-color'
                  : 'default-color'
              "
            />
            <a-icon
              type="caret-down"
              :class="
                activitySortIndex === 'dealer' && activitySortType == 'down'
                  ? 'active-color'
                  : 'default-color'
              "
            />
          </div>
        </div>
        <div
          class="activity-first-list-item"
          v-for="(item, index) in activityData"
          :key="index"
        >
          {{ item.dealer_count | changeNum }}
        </div>
      </div>
      <div
        class="other-list"
        v-for="(item, index) in activityData && activityData.length > 0
          ? activityData[0].list
          : []"
        :key="index"
      >
        <div class="list-title other-title">
          <div class="list-title-left">
            <span>{{ item.title || "-" }}</span>
            <div class="sort-div" @click="sortClick(index, 'left')">
              <a-icon
                type="caret-up"
                :class="
                  activitySortIndex === index &&
                  activitySortType == 'up' &&
                  activitySortPosition == 'left'
                    ? 'active-color'
                    : 'default-color'
                "
              />
              <a-icon
                type="caret-down"
                :class="
                  activitySortIndex === index &&
                  activitySortType == 'down' &&
                  activitySortPosition == 'left'
                    ? 'active-color'
                    : 'default-color'
                "
              />
            </div>
          </div>
          <div class="list-title-right">
            <span>占比</span>
            <div class="sort-div" @click="sortClick(index, 'right')">
              <a-icon
                type="caret-up"
                :class="
                  activitySortIndex === index &&
                  activitySortType == 'up' &&
                  activitySortPosition == 'right'
                    ? 'active-color'
                    : 'default-color'
                "
              />
              <a-icon
                type="caret-down"
                :class="
                  activitySortIndex === index &&
                  activitySortType == 'down' &&
                  activitySortPosition == 'right'
                    ? 'active-color'
                    : 'default-color'
                "
              />
            </div>
          </div>
        </div>
        <div class="list-item f14" v-for="(i, ni) in activityData" :key="ni">
          <span class="font-color2">{{ i.list[index].count | changeNum }}</span>
          <div>
            <!-- <span class="font-color3">占比</span> -->
            <span class="font-color26">{{
              i.list[index].proportion | numRate
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <Empty
      style="margin-top: 240px"
      title="暂无数据"
      v-show="
        currentTabsKey == '3' && (!activityData || activityData.length == 0)
      "
    />
  </div>
</template>

<script>
import * as vuex from "vuex";
import _ from "lodash";
import { getToken, getPrincipalId } from "@/utils/auth";
import exportTable from "@/utils/exportTable";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "assessment"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Tabs from "@/components/PlatformTab/Tabs";

import ButtonTab from "@/components/PlatformTab/NewButtonTab";
import Empty from "@/components/Empty/Empty";
import DateTab from "@/components/DateTab/DateTab2";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";

export default {
  components: {
    PlatformHeader,
    Tabs,
    ButtonTab,
    DateTab,
    Empty,
    ExcelTypeButton,
  },
  data() {
    return {
      activitySortType: null, // null/'up'/'down'
      activitySortIndex: null, // null/'dealer'/Number(index)
      activitySortPosition: null, // null/'left'/'right'
      excelType: false,
      dateType: "month",
      tooltipTitle: "输入本月活动关键字, 用逗号分隔,比如：新途观，新途昂",
      tabsList: [
        {
          label: "商务政策指标",
          value: "1",
        },
        {
          label: "商务政策考核",
          value: "2",
        },
        {
          label: "大区排名",
          value: "3",
        },
      ],
      buttonTabData: [
        {
          label: "占比",
          value: "proportion",
        },
        {
          label: "目标值",
          value: "tagetValue",
        },
        {
          label: "完成率",
          value: "finishRatio",
        },
      ],
      buttonTabData2: [
        {
          label: "短视频",
          value: "1",
        },
        {
          label: "直播",
          value: "2",
        },
        {
          label: "事件营销激励（视频）",
          value: "3",
        },
        {
          label: "事件营销激励（直播）",
          value: "4",
        },
      ],
      currentTab: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      currentTabsKey: "1",
      currentButtonTab: "proportion",
      currentButtonTab2: "1",
      searchInput: "",
      activity_id: 0,
      sort_field1: "",
      sort1: "",
      sort_field2: "",
      sort2: "",
      sort_field3: "",
      sort3: "",
      sort_field4: "",
      sort4: "",
      columns1: [
        {
          dataIndex: "sort",
          title: "排名",
          width: "80px",
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称",
          width: "170px",
          align: "left",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "three_hundred_top",
          title: "有效视频播放前300",
          align: "center",
          scopedSlots: { customRender: "three_hundred_top" },
          customRender: (text) => {
            return (
              <div>{this.$options.filters.is_three_hundred_top(text)}</div>
            );
          },
        },
        {
          dataIndex: "dealer_area_names",
          title: "区域",
          width: "70px",
          align: "center",
          scopedSlots: { customRender: "dealer_area_names" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          align: "center",
          width: "110px",
          scopedSlots: { customRender: "dealer_store_code" },
          customRender: (text) => {
            return <div>{this.$options.filters.stringIsNull(text)}</div>;
          },
        },
        {
          dataIndex: "effective_aweme_count",
          title: "有效短视频",
          align: "center",
          sorter: (a, b) => a.effective_aweme_count - b.effective_aweme_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "effective_play_volume",
          key: "effective_play_volume1",
          title: "有效短视频播放",
          align: "center",
          sorter: (a, b) => a.effective_play_volume - b.effective_play_volume,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "one_hundred_thousand_play_count",
          key: "one_hundred_thousand_play_count1",
          title: "10W爆款短视频",
          align: "center",
          sorter: (a, b) =>
            a.one_hundred_thousand_play_count -
            b.one_hundred_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "five_hundred_thousand_play_count",
          title: "50W爆款短视频",
          align: "center",
          sorter: (a, b) =>
            a.five_hundred_thousand_play_count -
            b.five_hundred_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "million_play_count",
          title: "100W爆款短视频",
          width: "130px",
          align: "center",
          sorter: (a, b) => a.million_play_count - b.million_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
      ],
      columns1_yqhq: [
        {
          dataIndex: "sort",
          title: "排名",
          width: "80px",
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称",
          width: "170px",
          align: "left",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "dealer_area_names",
          title: "区域",
          width: "70px",
          align: "center",
          scopedSlots: { customRender: "dealer_area_names" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          align: "center",
          width: "110px",
          scopedSlots: { customRender: "dealer_store_code" },
          customRender: (text) => {
            return <div>{this.$options.filters.stringIsNull(text)}</div>;
          },
        },
        {
          dataIndex: "effective_aweme_count",
          title: "有效短视频",
          align: "center",
          sorter: (a, b) => a.effective_aweme_count - b.effective_aweme_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "effective_play_volume",
          key: "effective_play_volume1",
          title: "有效短视频播放",
          align: "center",
          sorter: (a, b) => a.effective_play_volume - b.effective_play_volume,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "fifty_thousand_play_count",
          key: "fifty_thousand_play_count",
          title: "5W爆款短视频",
          align: "center",
          sorter: (a, b) =>
            a.fifty_thousand_play_count - b.fifty_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "one_hundred_thousand_play_count",
          key: "one_hundred_thousand_play_count1",
          title: "10W爆款短视频",
          align: "center",
          sorter: (a, b) =>
            a.one_hundred_thousand_play_count -
            b.one_hundred_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "five_hundred_thousand_play_count",
          title: "50W爆款短视频",
          align: "center",
          sorter: (a, b) =>
            a.five_hundred_thousand_play_count -
            b.five_hundred_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "million_play_count",
          title: "100W爆款短视频",
          width: "130px",
          align: "center",
          sorter: (a, b) => a.million_play_count - b.million_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
      ],

      columns2: [
        {
          dataIndex: "sort",
          title: "排名",
          width: "80px",
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称",
          width: "200px",
          align: "left",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "three_hundred_top",
          title: "有效直播数前300",
          align: "center",
          scopedSlots: { customRender: "three_hundred_top" },
          customRender: (text) => {
            return (
              <div>{this.$options.filters.is_three_hundred_top(text)}</div>
            );
          },
        },
        {
          dataIndex: "dealer_area_names",
          title: "区域",
          align: "center",
          scopedSlots: { customRender: "dealer_area_names" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          align: "center",
          scopedSlots: { customRender: "dealer_store_code" },
          customRender: (text) => {
            return <div>{this.$options.filters.stringIsNull(text)}</div>;
          },
        },
        {
          dataIndex: "effective_live_count",
          title: "有效直播",
          align: "center",
          sorter: (a, b) => a.effective_live_count - b.effective_live_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "effective_play_volume",
          key: "effective_play_volume2",
          title: "有效直播观看",
          align: "center",
          sorter: (a, b) => a.effective_play_volume - b.effective_play_volume,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "ten_thousand_play_count",
          title: "1W爆款直播",
          align: "center",
          sorter: (a, b) =>
            a.ten_thousand_play_count - b.ten_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "five_thousand_play_count",
          title: "5W爆款直播",
          align: "center",
          sorter: (a, b) =>
            a.five_thousand_play_count - b.five_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "one_hundred_thousand_play_count",
          key: "one_hundred_thousand_play_count2",
          title: "10W爆款直播",
          align: "center",
          sorter: (a, b) =>
            a.one_hundred_thousand_play_count -
            b.one_hundred_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
      ],
      columns2_yqhq: [
        {
          dataIndex: "sort",
          title: "排名",
          width: "70px",
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称",
          width: "160px",
          align: "left",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "dealer_area_names",
          title: "区域",
          align: "center",
          scopedSlots: { customRender: "dealer_area_names" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          align: "center",
          scopedSlots: { customRender: "dealer_store_code" },
          customRender: (text) => {
            return <div>{this.$options.filters.stringIsNull(text)}</div>;
          },
        },
        {
          dataIndex: "effective_live_count",
          title: "有效直播",
          align: "center",
          sorter: (a, b) => a.effective_live_count - b.effective_live_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "effective_play_volume",
          key: "effective_play_volume2",
          title: "有效直播观看",
          align: "center",
          sorter: (a, b) => a.effective_play_volume - b.effective_play_volume,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "ten_thousand_play_count",
          title: "1W爆款直播",
          align: "center",
          sorter: (a, b) =>
            a.ten_thousand_play_count - b.ten_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "three_wplay_count",
          title: "3W爆款直播",
          align: "center",
          sorter: (a, b) => a.three_wplay_count - b.three_wplay_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "five_thousand_play_count",
          title: "5W爆款直播",
          align: "center",
          sorter: (a, b) =>
            a.five_thousand_play_count - b.five_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "one_hundred_thousand_play_count",
          key: "one_hundred_thousand_play_count2",
          title: "10W爆款直播",
          align: "center",
          sorter: (a, b) =>
            a.one_hundred_thousand_play_count -
            b.one_hundred_thousand_play_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "thirty_wplay_count",
          key: "thirty_wplay_count",
          title: "30W爆款直播",
          align: "center",
          sorter: (a, b) => a.thirty_wplay_count - b.thirty_wplay_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
      ],

      columns3: [
        {
          dataIndex: "sort",
          title: "排名",
          width: "80px",
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称",
          width: "200px",
          align: "left",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "dealer_area_names",
          title: "区域",
          align: "center",
          scopedSlots: { customRender: "dealer_area_names" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          align: "center",
          scopedSlots: { customRender: "dealer_store_code" },
          customRender: (text) => {
            return <div>{this.$options.filters.stringIsNull(text)}</div>;
          },
        },
        {
          dataIndex: "effective_play_volume",
          key: "effective_play_volume3",
          align: "center",
          slots: { title: "playTitle" },
          sorter: (a, b) => a.effective_play_volume - b.effective_play_volume,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "aweme_interaction_count",
          title: "视频互动量",
          align: "center",
          sorter: (a, b) =>
            a.aweme_interaction_count - b.aweme_interaction_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "event_activity_count",
          key: "event_activity_count1",
          title: "营销视频数量",
          align: "center",
          sorter: (a, b) => a.event_activity_count - b.event_activity_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "digg_count",
          title: "点赞",
          align: "center",
          sorter: (a, b) => a.digg_count - b.digg_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "comment_count",
          title: "评论",
          align: "center",
          sorter: (a, b) => a.comment_count - b.comment_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "share_count",
          title: "转发",
          align: "center",
          sorter: (a, b) => a.share_count - b.share_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
      ],
      columns4: [
        {
          dataIndex: "sort",
          title: "排名",
          width: "80px",
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          dataIndex: "dealer_name",
          title: "门店名称",
          width: "200px",
          align: "left",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "dealer_area_names",
          title: "区域",
          align: "center",
          scopedSlots: { customRender: "dealer_area_names" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          align: "center",
          scopedSlots: { customRender: "dealer_store_code" },
          customRender: (text) => {
            return <div>{this.$options.filters.stringIsNull(text)}</div>;
          },
        },
        {
          dataIndex: "statistics_total_watch_user_time",
          title: "观看人次",
          align: "center",
          sorter: (a, b) =>
            a.statistics_total_watch_user_time -
            b.statistics_total_watch_user_time,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "statistics_fans_add",
          title: "直播涨粉",
          // slots: { title: "addFansTitle" },
          scopedSlots: {
            filterDropdown: "statistics_fans_add",
            filterIcon: `filterIcon`,
          },
          align: "center",
          sorter: (a, b) => a.statistics_fans_add - b.statistics_fans_add,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "event_activity_count",
          key: "event_activity_count2",
          title: "营销直播场次",
          align: "center",
          sorter: (a, b) => a.event_activity_count - b.event_activity_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "statistics_total_like_count",
          title: "点赞",
          align: "center",
          sorter: (a, b) =>
            a.statistics_total_like_count - b.statistics_total_like_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
        {
          dataIndex: "statistics_total_comment_count",
          title: "评论",
          align: "center",
          sorter: (a, b) =>
            a.statistics_total_comment_count - b.statistics_total_comment_count,
          customRender: (text) => {
            return <div>{this.$options.filters.changeNum(text)}</div>;
          },
        },
      ],

      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      current: 1,
      pageSize: 20,
      total: 0,
      groupValue: "",
      regionId: undefined,
      searchTime: moment().subtract(1, "months"),
      isYqhq: false, // 判断是否是一汽红旗
      disabledDate(itemDate) {
        // Can not select days before today and today
        if (itemDate) {
          let curMonth = moment().month();
          let itemMonth = itemDate.month();
          return itemMonth >= curMonth; // > 包括当月，>= 不包括当月
        } else {
          return false;
        }
      },
    };
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activityList,
      activityData: (state) => state.activityData,
      areaList: (state) => state.areaList,
      listData: (state) => state.listData,
      assessmentlistData: (state) => state.assessmentlistData,
      groupData: (state) => state.groupData,
      tableLoading: (state) => state.tableLoading,
    }),
  },
  watch: {
    assessmentlistData(val) {
      console.log("watch", val);
      this.isYqhq = val.principal_name == "yqhq" ? true : false;
      this.total = val.total;
    },
    areaList(val) {
      console.log("areaList", val);
      if (!val.region_list && val.region_list.length == 0) {
        this.$message.warning("当前账号未绑定大区，请联系管理员");
        return false;
      }
      this.regionId = val.region_list[0].value;
      this.loadAssessmentlist(true);
      this.loadList();
    },
  },
  created() {
    this.$nextTick(() => {
      this.changeState({
        data: {
          areas: [],
          items: [],
        },
        name: "listData",
      });
      this.getAreaList();
      // this.loadList();
      // this.loadAssessmentlist(true);
      this.loadActivityData();
      this.getAllGroup();
      console.log(90, this.$http);
      // this.getActivityList({
      //   platform: this.currentTab,
      // });
    });
  },
  mounted() {},
  methods: {
    ...mapActions([
      "getAreaList",
      "getActivityData",
      "getActivityList",
      "getList",
      "getAssessmentlist1",
      "getAssessmentlist2",
      "getAssessmentlist3",
      "getAssessmentlist4",
      "getAllGroup",
    ]),
    ...mapMutations(["changeState"]),
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().startOf("month");
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      this.loadList();
      this.loadAssessmentlist(true);
      this.loadActivityData();
    },
    // 大区选择
    regionChange() {
      this.loadList();
      this.loadAssessmentlist(true);
    },
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      this.columns4[4].title = this.filtrate.returnWatchText(this.currentTab);
      this.getActivityList({
        platform: this.currentTab,
      });
      this.activity_id = undefined;
      // 指标
      this.loadList();

      // 考核
      this.currentButtonTab2 = 1;
      this.$refs.assessmentTab.currentClick = 1;
      this.loadAssessmentlist(true);

      // 活动
      this.loadActivityData();
    },
    // tabs切换
    changeTabs(val) {
      this.currentTabsKey = val;
    },
    // 商务考核排序更改触发
    tableChange(pagination, filters, sorter) {
      console.log("sorter", sorter);
      console.log(sorter.field);
      console.log(sorter.order);
      if (sorter.order) {
        if (this.currentButtonTab2 == 1) {
          this.sort_field1 = sorter.field;
          this.sort1 = sorter.order == "ascend" ? "asc" : "desc";
        } else if (this.currentButtonTab2 == 2) {
          this.sort_field2 = sorter.field;
          this.sort2 = sorter.order == "ascend" ? "asc" : "desc";
        } else if (this.currentButtonTab2 == 3) {
          this.sort_field3 = sorter.field;
          this.sort3 = sorter.order == "ascend" ? "asc" : "desc";
        } else if (this.currentButtonTab2 == 4) {
          this.sort_field4 = sorter.field;
          this.sort4 = sorter.order == "ascend" ? "asc" : "desc";
        }
      } else {
        if (this.currentButtonTab2 == 1) {
          this.sort_field1 = "";
          this.sort1 = "";
        } else if (this.currentButtonTab2 == 2) {
          this.sort_field2 = "";
          this.sort2 = "";
        } else if (this.currentButtonTab2 == 3) {
          this.sort_field3 = "";
          this.sort3 = "";
        } else if (this.currentButtonTab2 == 4) {
          this.sort_field4 = "";
          this.sort4 = "";
        }
      }
      this.loadAssessmentlist();
    },
    // 大区排名排序
    sortClick(value, type) {
      console.log(value, type);
      this.activitySortPosition = type;
      if (this.activitySortIndex == value) {
        // 当选择的排序方式与当前相同时
        if (this.activitySortType == null) {
          this.activitySortType = "up";
        } else if (this.activitySortType == "up") {
          this.activitySortType = "down";
        } else {
          this.activitySortType = "up";
        }
      } else {
        // 当选择新的排序字段时
        this.activitySortIndex = value;
        this.activitySortType = "up";
      }
      console.log(this.activitySortIndex, this.activitySortType);
      if (this.activitySortIndex == "dealer") {
        let newData = this.activityData;
        newData.sort(this.sortFun("dealer_count", this.activitySortType));
        this.changeState({ data: newData, name: "activityData" });
      }
      if (this.activitySortIndex >= 0 && this.activitySortIndex != null) {
        let newData = this.activityData;
        newData.sort(
          this.sortFun2(
            this.activitySortIndex,
            this.activitySortType,
            this.activitySortPosition
          )
        );
        this.changeState({ data: newData, name: "activityData" });
      }
    },
    sortFun(property, type) {
      return function(a, b) {
        console.log(1, a, b);
        var value1 = a[property];
        var value2 = b[property];
        if (type == "up") {
          return value1 - value2;
        } else {
          return value2 - value1;
        }
      };
    },
    sortFun2(index, type, position) {
      return function(a, b) {
        console.log(2, a, b);
        var value1;
        var value2;
        if (position == "left") {
          value1 = a.list[index].count;
          value2 = b.list[index].count;
        } else {
          value1 = a.list[index].proportion;
          value2 = b.list[index].proportion;
        }
        if (type == "up") {
          return value1 - value2;
        } else {
          return value2 - value1;
        }
      };
    },
    // 加载指标列表
    loadList() {
      let start_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      this.getList({
        start_time: start_time,
        end_time: end_time,
        region_id: this.regionId || "",
        platform: this.currentTab,
      });
    },
    // 获取活动列表
    loadActivityData() {
      let start_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }

      this.activitySortIndex = null;
      this.activitySortType = null;
      this.getActivityData({
        start_time: start_time,
        end_time: end_time,
        group_code: this.groupValue,
        platform: this.currentTab,
      });
    },
    // 加载考核列表
    loadAssessmentlist(isFirst) {
      if (isFirst) {
        this.total = 0;
        this.current = 1;
        this.changeState({ data: { list: [] }, name: "assessmentlistData" });
      }

      let start_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      let params = {
        start_time: start_time,
        end_time: end_time,
        region_id: this.regionId || "",
        group_code: this.groupValue,
        current: this.current,
        size: this.pageSize,
        platform: this.currentTab,
      };
      if (this.currentButtonTab2 == 1) {
        // 短视频
        params.sort_field = this.sort_field1;
        params.sort = this.sort1;
        this.getAssessmentlist1(params);
      } else if (this.currentButtonTab2 == 2) {
        // 直播
        params.sort_field = this.sort_field2;
        params.sort = this.sort2;
        this.getAssessmentlist2(params);
      } else if (this.currentButtonTab2 == 3) {
        // 事件活动激励-视频
        params.sort_field = this.sort_field3;
        params.sort = this.sort3;
        params.keyword = "";
        params.activity_id = this.activity_id || " ";
        this.getAssessmentlist3(params);
      } else if (this.currentButtonTab2 == 4) {
        // 事件活动激励-直播
        params.sort_field = this.sort_field4;
        params.sort = this.sort4;
        // params.keyword = this.searchInput;
        params.keyword = "";
        params.activity_id = this.activity_id || " ";
        this.getAssessmentlist4(params);
      }
    },
    // 指标列表tab切换
    buttonTabChange(val) {
      this.currentButtonTab = val;
    },
    // 考核列表tab切换
    buttonTabChange2(val) {
      this.currentButtonTab2 = val;
      console.log(val);
      this.loadAssessmentlist(true);
    },
    // 考核-事件活动激励-搜索框
    onSearch() {
      console.log(this.searchInput);
      this.loadAssessmentlist(true);
    },
    // 考核-分页
    pageChange() {
      console.log(this.current);
      this.loadAssessmentlist();
    },
    // 切换日期
    onChange() {
      this.loadList();
      this.loadAssessmentlist(true);
      this.loadActivityData();
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 导出
    exportClick() {
      let start_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      if (this.currentTabsKey == "1") {
        // 指标
        let params = {
          start_time: start_time,
          end_time: end_time,
          region_id: this.regionId || "",
          flag: this.excelType ? 1 : 0,
        };
        exportTable(
          this.$http,
          params,
          this.currentTab,
          "/jjt/dealers/busi/data/exportExcel",
          "filename"
        );
      } else if (this.currentTabsKey == "2") {
        // 考核
        let params = {
          start_time: start_time,
          end_time: end_time,
          region_id: this.regionId || "",
          group_code: this.groupValue,
        };
        if (this.currentButtonTab2 == 1) {
          // 短视频
          params.sort_field = this.sort_field1;
          params.sort = this.sort1;
          params.flag = this.excelType ? 1 : 0;
          exportTable(
            this.$http,
            params,
            this.currentTab,
            "/jjt/business/policy/assessment/aweme/exportExcel",
            "filename"
          );
        } else if (this.currentButtonTab2 == 2) {
          // 直播
          params.sort_field = this.sort_field2;
          params.sort = this.sort2;
          params.flag = this.excelType ? 1 : 0;
          exportTable(
            this.$http,
            params,
            this.currentTab,
            "/jjt/business/policy/assessment/live/exportExcel",
            "filename"
          );
        } else if (this.currentButtonTab2 == 3) {
          // 事件活动激励-视频
          params.sort_field = this.sort_field3;
          params.sort = this.sort3;
          params.keyword = "";
          params.activity_id = this.activity_id || " ";
          params.flag = this.excelType ? 1 : 0;
          exportTable(
            this.$http,
            params,
            this.currentTab,
            "/jjt/business/policy/assessment/event/activity/motivate/aweme/exportExcel",
            "filename"
          );
        } else if (this.currentButtonTab2 == 4) {
          // 事件活动激励-直播
          params.sort_field = this.sort_field4;
          params.sort = this.sort4;
          // params.keyword = this.searchInput;
          params.keyword = "";
          params.activity_id = this.activity_id || " ";
          params.flag = this.excelType ? 1 : 0;
          exportTable(
            this.$http,
            params,
            this.currentTab,
            "/jjt/business/policy/assessment/event/activity/motivate/live/exportExcel",
            "filename"
          );
        }
      } else if (this.currentTabsKey == "3") {
        let params = {
          start_time: start_time,
          end_time: end_time,
          group_code: this.groupValue,
          flag: this.excelType ? 1 : 0,
        };
        exportTable(
          this.$http,
          params,
          this.currentTab,
          "/jjt/dealers/busi/data/activity/exportExcel",
          "filename"
        );
      } else {
        console.log("暂无当前tab");
      }
    },
    // 切换分组
    groupChange(val) {
      console.log(val);
      this.loadAssessmentlist(true);
      this.loadActivityData();
    },
    routerToResult() {
      this.$router.push({
        path: `/dealer/assessmentResult`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .tabs {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    > div {
      display: flex;
    }
    .beta-icon {
      width: 36px;
      height: 14px;
      border-radius: 7px;
      background: #ff4160;
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      position: relative;
      top: 0px;
      margin-left: 4px;
    }
    .beta-icon2 {
      width: 36px;
      height: 14px;
      border-radius: 7px;
      background: #ff4160;
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      position: relative;
      top: 0px;
      left: -148px;
      margin-left: 4px;
    }

    .button2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      margin-left: 10px;
      border: none;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 24px;
        margin-right: 10px;
        @include font_color("font_color20");
      }
    }
    .button3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      @include font_color("font_color1");
      @include background_color("background_color59");
      margin-right: 10px;
      .btn-icon {
        font-size: 24px;
        margin-right: 10px;
        @include font_color("font_color20");
      }
    }
  }
  .tools {
    height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    margin: 24px 0 20px;
  }
  .content1 {
    .list-title {
      line-height: 70px;
    }
  }
  .content {
    padding: 0 0px 20px;
    max-height: calc(100vh - 300px);
    width: calc(100% - 40px);
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
    border-radius: 16px;
    @include background_color("background_color2");
    // .tab1-list {
    //   &:last-child {
    //     border-radius: 16px 0 0 16px;
    //   }
    // }
    .first-list {
      width: 92px;
      min-width: 92px;
      &:first-child {
        border-radius: 16px 0 0 16px;
      }
    }
    .first-list-item {
      font-size: 14px;
      @include font_color("font_color2");
      height: 58px;
      line-height: 58px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color16");
      @include background_color("background_color2");
    }
    .other-list {
      min-width: 164px;
      max-width: 164px;
      width: 164px;
      &:last-child {
        > div:first-child {
          border-radius: 0 16px 0 0;
        }
        > div:last-child {
          border-radius: 0 0 16px 0;
        }
      }
      cursor: pointer;

      .title-tooltip {
        opacity: 0;
      }
      .other-title {
        @include font_color("font_color22");
      }
      &:hover {
        .list-title,
        .other-title {
          transition: all 0.5s;
          @include background_color("background_color5");
          @include font_color("font_color20");

          .title-tooltip {
            opacity: 1;
          }
        }
        .list-item {
          transition: all 0.5s;
          @include background_color("background_color5");
        }
      }
    }
    .other-title {
      display: flex;
      align-items: center;
      justify-content: center;
      @include background_color("background_color2");
      @include font_color("font_color22");
      height: 70px;
    }
    .activity-first-list {
      width: 92px;
      min-width: 92px;
      .list-title {
        line-height: 70px;
        @include font_color("font_color22");
      }
    }
    .activity-first-list.dealer-list {
      width: 164px;
      cursor: pointer;
      .title-tooltip {
        opacity: 0;
      }
      &:hover {
        .other-title {
          transition: all 0.5s;
          @include background_color("background_color5");
          @include font_color("font_color20");
          .title-tooltip {
            opacity: 1;
          }
        }
        .activity-first-list-item {
          transition: all 0.5s;
          @include background_color("background_color5");
        }
      }
    }
    .activity-first-list-item {
      font-size: 14px;
      @include font_color("font_color2");
      height: 58px;
      line-height: 58px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color16");
      @include background_color("background_color2");
    }
    .list-title {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      font-size: 12px;
      @include font_color("font_color1");
      @include border_color("border_color16");
      @include background_color("background_color2");
      height: 70px;
      &-left {
        width: 78px;
        display: flex;
        align-items: center;
        > span {
          width: 50px;
          display: inline-block;
        }
      }
      &-right {
        width: 54px;
        display: flex;
        align-items: center;
        > span {
          width: 26px;
          display: inline-block;
        }
      }
    }
    .sort-div {
      cursor: pointer;
      width: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      .default-color {
        @include font_color("font_color21");
      }
      .active-color {
        @include font_color("font_color26");
      }
    }
    .list-item {
      width: 100%;
      padding: 0 36px;
      height: 58px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color16");
      @include background_color("background_color2");
      > span {
        line-height: 58px;
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .content-table {
    padding: 0 20px;
    .table-sort {
      height: 58px;
      line-height: 58px;
      .ranking {
        font-size: 14px;
        @include font_color("font_color21");
      }
      .ranking1 {
        font-size: 14px;
        font-weight: bold;
        @include font_color("font_color26");
      }
      .ranking2 {
        font-size: 14px;
        font-weight: bold;
        @include font_color("font_color29");
      }
      .ranking3 {
        font-size: 14px;
        font-weight: bold;
        @include font_color("font_color36");
      }
    }

    .page-div {
      text-align: right;
      padding: 10px 0;
    }
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .font-color23 {
    @include font_color("font_color23");
  }
  .font-color26 {
    @include font_color("font_color26");
  }
  .font-color29 {
    @include font_color("font_color29");
  }
  .font-color36 {
    @include font_color("font_color36");
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
}
</style>
