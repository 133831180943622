<template>
  <div class="video-card-container" :style="parentStyle" v-if="videoData">
    <div class="video-card-container-top">
      <img :src="videoData.aweme_cover" alt="" :width="imgWidth">
      <div class="mask" :style="mskPadding">
        <div>
          <img :src="videoData.avatar || (videoData.author && videoData.author.avatar) || defultImg" alt="头像">
          <div>
            <span class="name">{{videoData.nickname || (videoData.author && videoData.author.nickname) || '---'}}</span>
            <span class="number">{{platform == 'douyin' ? '抖音' : '快手'}}号: {{videoData.code || '---'}}</span>
          </div>
          <!-- <a-tooltip>
            <template slot="title">
              {{videoData.nickname || (videoData.author && videoData.author.nickname) || '---'}}
            </template>
            <span class="name">{{videoData.nickname || (videoData.author && videoData.author.nickname) || '---'}}</span>
          </a-tooltip>
          <a-tooltip>
            <template slot="title">
              抖音号 {{videoData.code || '---'}}
            </template>
            <span class="number">抖音号 {{videoData.code || '---'}}</span>
          </a-tooltip> -->
        </div>
      </div>
      <div class="mask-button">
        <a-button class="play-btn" @click="openVideo(videoData)"><svg-icon :icon-class="'play'" style="font-size: 20px;position: relative;top: 2px;margin-right: 6px;"></svg-icon>播放视频</a-button>
        <a-button class="detail-btn" @click="toDetail(videoData.aweme_id,videoData.avatar,videoData.code,videoData.nickname)" v-if="isShowDetailButton" ><svg-icon :icon-class="'detail'" style="font-size: 20px;position: relative;top: 2px;margin-right: 6px;"></svg-icon>视频详情</a-button>
      </div>
    </div>
    <div class="video-card-container-bottom">
      <div>
        <div class="title">{{ videoData.aweme_title }}</div>
        <div class="action">
          <div>
            <svg-icon :icon-class="'digg'" class="digg-icon"></svg-icon>
            <span>{{ editNumer(videoData.digg_count) }}</span>
          </div>
          <div>
            <svg-icon :icon-class="'comment'" class="comment-icon"></svg-icon>
            <span>{{ editNumer(videoData.comment_count) }}</span>
          </div>
          <div>
            <svg-icon :icon-class="'share'" class="share-icon"></svg-icon>
            <span>{{ editNumer(videoData.share_count) }}</span>
          </div>
        </div>
        <div class="time">{{ videoData.publish_time }}</div>
      </div>
    </div>
  </div>
</template>

<script>

// videoData传参示例
// videoData: {
//   "comment_count":"3",
//   "share_count":"1",
//   "aweme_title":"SUV销量冠军哈弗H6的合金模型 1:18红标版",
//   "aweme_id":"6736329142041677060",
//   "code":"632099856",
//   "aweme_topic":[],
//   "digg_count":"16",
//   "publish_time":"2019-09-14 09:17:34",
//   "nickname":"汽车模型.普鲸\uD83D\uDE04",
//   "aweme_cover":"http://p9-dy.byteimg.com/large/tos-cn-p-0015/aa5835e2e9f74bf68c98c032a51beddf.jpeg?from=2563711402_large",
//   "avatar":"https://p1-dy-ipv6.byteimg.com/aweme/720x720/2d5890002f0541744dd5b.jpeg"
// }

export default {
  name: "VideoCard",
  components: {

  },
  props: {
    videoData: {
      type: Object
    },
    platform: {
      type: String,
      default: 'douyin'
    },
    parentStyle: {
      type: Object,
      default: () => { return { width: '240px', height: '410px', margin: '0 36px 12px 0' } }
    },
    imgWidth: {
      type: String,
      default: '240px'
    },
    mskPadding: {
      style: Object,
      default: () => { return { padding: '8px 0' } }
    },
    isShowDetailButton: {
      style: Boolean,
      default: true
    }
  },
  data() {
    return {
      defultImg: 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg'
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    editNumer(num) {
      if (parseInt(num) >= 10000) {
        return (num / 10000).toFixed(2) + 'W'
      } else {
        return num
      }
    },
    // 打开视频详情的抽屉，传id
    toDetail(id,avatar,code,nickname) {
      if(!avatar&&!code&&!nickname){
        this.$message({
          message: '暂无主播视频详情！',
          type: 'warning'
        });
        return ;
      }
      let routeUrl = `/videoDetail?id=${id}&platform=${this.platform}`
      window.open(routeUrl, '_blank')
    },
    // todoD
    openVideo(data) {
      if (this.platform == 'douyin') {
        window.open(`https://www.douyin.com/share/video/${data.aweme_id}/?mid=6828109921754843918`)
      } else {
        window.open(data.aweme_url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_handle.scss';
  .video-card-container {
    width: 240px;
    height: 410px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    :hover .mask-button {
      opacity: 1!important;
    }

    &-top {
      height: 310px;
      border-radius: 4px 4px 0 0;
      overflow: hidden;
      position: relative;
      background: #000;

      .mask {
        background: rgba(3, 3, 3, 0.7);
        width: 100%;
        height: 52px;
        position: absolute;
        top: 258px;
        left: 0;
        z-index: 50;
        >div {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 36px;
            border-radius: 24px;
            margin: 0 8px 0 12px;
          }
          .name {
            font-size: 14px;
            height: 16px;
            line-height: 16px;
            @include font_color("font_color2");
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            word-break: break-all;
          }
          .number {
            font-size: 12px;
            @include font_color("font_color3");
            margin-top: 2px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            word-break: break-all;
          }
        }
      }

      .mask-button {
        opacity: 0;
        @include background_color("background_color19");
        width: 100%;
        height: 100%;
        padding: 107px 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 60;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .play-btn {
          width: 120px;
          height: 36px;
          border: none;
          @include font_color("font_color1");
          @include background_color("background_color17");
        }
        .detail-btn {
          width: 120px;
          height: 36px;
          border: none;
          @include font_color("font_color1");
          @include background_color("background_color18");
        }
      }
    }

    &-bottom {
      height: 100px;
      padding: 8px 12px;
      @include background_color("background_color4");
      border-radius: 0 0 5px 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-size: 14px;
        @include font_color("font_color2");
        height: 40px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .time {
        font-size: 12px;
        @include font_color("font_color3");
        height: 25px;
        line-height: 25px;
      }
      .action {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > div {
          margin-right: 12px;
        }
        .digg-icon {
          margin-right: 4px;
          @include font_color("font_color8");
        }
        .comment-icon {
          margin-right: 4px;
          @include font_color("font_color7");
        }
        .share-icon {
          margin-right: 4px;
          @include font_color("font_color9");
        }

        span {
          @include font_color("font_color2");
          font-size: 12px;
          margin-top: 4px;
        }
      }
      img {
        width:30px;
        height: 30px;
        border: 1px solid #ddd;
        border-radius: 15px;
      }
    }
  }

</style>
