import Nr from '@/utils/NetworldRequest'

const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()

const activity_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API
}).getAxios()


export default {
  // 创建任务
  createTask(body) {
    return activity_request.post('/jjt/activity/web/task/create', body)
  },
  // 编辑任务
  editTask(body) {
    return activity_request.post('/jjt/activity/web/task/update', body)
  },
  // 获取任务列表
  getTaskList(params) {
    return activity_request.get('/jjt/activity/web/task/list', {params: params})
  },
  // 删除任务
  deleteTask(params) {
    return activity_request.get('/jjt/activity/web/task/delete', {params: params})
  },
 // 任务中心
 getProfileTask(params) {
  return activity_request.get('/jjt/activity/web/profile/task', { params: params })
},

  // 获取大区
  getAreaInfo() {
    return request.get('/jjt/dealer/dealers/region-info')
  },
  getAreaInfoByActivity(params) {
    return activity_request.get('/jjt/activity/web/statistic/regionList', {params: params})
  },



  // old -------------------------------
  //获取活动列表
  // getActivityList(body) {
  //     return platformRequest(body.platform).get('/jjt/closedLoop/activity/topic/list', { params: body.params })
  // },
  // //获取抖音话题列表
  // getTopicList(body) {
  //     return platformRequest(body.platform).get('/jjt/closedLoop/topic/list')
  // },
  // saveOrUpdateTopic(body) {
  //   return platformRequest(body.platform).post('/jjt/closedLoop/saveOrUpdate', body.params)
  // },
  // // 获取话题活动详情
  // getActivityDetail(body) {
  //     return platformRequest(body.platform).get('/jjt/closedLoop/activity/topic/one', { params: body.params })
  // },
  // //获取车系列表
  // getSeriesList(body) {
  //     return platformRequest(body.platform).get('/jjt/principal/series/list')
  // },

  // // 新版风火轮活动相关 -----

  // // 编辑、创建活动
  // saveOrUpdateActivity(body) {
  //   return request.post('/jjt/activity/saveOrUpdate', body)
  // },
  // // 上传获奖图
  // saveOrUpdateActivityV2(body) {
  //   return request.post('/jjt/activity/saveOrUpdate/v2', body)
  // },

  // // 获取模版列表
  // getTemplateManage(params) {
  //   return request.get('/jjt/template-manage/query', {params: params})
  // },

  // // 编辑、删除模版
  // editTemplateManage(body) {
  //   return request.post('/jjt/template-manage/edit', body)
  // },

  // // 视频地址通过内容中台获取权限
  // getAuthUrl(body) {
  //   return platformRequest('contentcenter').post('/v1/content/vod/getAuthUrl', body)
  // },

}