<template>
  <div class="container">
    <div class="title">
      <span>门店收藏</span>
      <div></div>
    </div>
    <div class="tools">
      <a-button class="button btn-color1" @click="openAddModal"
        >新建分组</a-button
      >
      <a-button class="button btn-color2" @click="openCopyModal"
        >复制分组</a-button
      >
    </div>
    <div class="content">
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :rowKey="(data) => data.id"
          :pagination="false"
          :scroll="{y: 'calc(100vh - 310px)'}"
          :locale="{emptyText: '暂无数据'}"
          class="aft-table default"
        >
          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            {{ (current - 1) * pageSize + Number(index) + 1 }}
          </div>
          <div slot="account" slot-scope="scope" class="table-account">
            <a-input
              v-if="scope == tableIptShow"
              v-model.trim="tableIpt"
              :autofocus="true"
              @blur="tableBlur(scope)"
            ></a-input>
            <div v-else>
              <svg-icon icon-class="md-folder_open" style="margin-right: 8px;font-size: 22px;"/>
              <span>{{scope.name}}</span>
              <a-icon
                type="edit"
                theme="filled"
                class="edit-icon"
                @click="editName(scope)"
              />
            </div>
          </div>
          <div slot="storeCode" slot-scope="scope" class="font-color4 f14">
            <span>{{scope.group_code}}</span>
          </div>
          <div slot="storeCount" slot-scope="scope" class="font-color2 f14">
            <span>{{scope.count}}</span>
          </div>
          <div slot="createTime" slot-scope="scope" class="font-color3 f14">
            <span>{{scope.ctime}}</span>
          </div>
          <div slot="editTime" slot-scope="scope" class="font-color3 f14">
            <span>{{scope.mtime}}</span>
          </div>
          <div
            slot="action"
            slot-scope="scope"
            class="font-color3 f14 table-action"
          >
            <span @click="batchCollection(scope)">批量收藏</span>
            <span style="margin-left: 24px" @click="routerTo(scope)">查看</span>
            <span style="margin-left: 24px" @click="deleteClick(scope)"
              >删除</span
            >
          </div>
        </a-table>
      </div>
      <div class="page-div">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>

    <!-- 收藏门店弹窗 -->

    <div class="add-modal" v-show="addModalShow" @click="addModalShow = false">
      <div class="add-modal-container" @click.stop>
        <a-input v-model.trim="addGroupValue" placeholder="输入新建分组名称" />
        <div class="action-div">
          <span
            :class="addGroupValue.length > 0 ? '' : 'active'"
            @click="addModalShow = false"
            >取消</span
          >
          <span
            :class="addGroupValue.length > 0 ? 'active' : ''"
            @click="addModalSure"
            >确定</span
          >
        </div>
      </div>
    </div>

    <div
      class="add-modal"
      v-show="copyModalShow"
      @click="copyModalShow = false"
    >
      <div class="add-modal-container copy" @click.stop>
        <a-input v-model.trim="copyGroupValue" placeholder="输入复制分组编号" />
        <div class="action-div">
          <span
            :class="copyGroupValue.length > 0 ? '' : 'active'"
            @click="copyModalShow = false"
            >取消</span
          >
          <span
            :class="copyGroupValue.length > 0 ? 'active' : ''"
            @click="copyModalSure"
            >确定</span
          >
        </div>
      </div>
    </div>

    <div
      class="delete-modal"
      v-show="deleteModalShow"
      @click="deleteModalShow = false"
    >
      <div class="delete-modal-container" @click.stop>
        <div class="delete-modal-container-title">删除分组</div>
        <div class="content">即将删除该分组，请您再次确认</div>
        <div class="action-div">
          <span class="btn btn1" @click="sureDelete">确定</span>
          <span class="btn btn2" @click="deleteModalShow = false">取消</span>
        </div>
      </div>
    </div>

    <!-- 批量收藏弹窗 -->
    <a-modal v-model="batchCollectionModal" :closable="false" :centered="true" :footer="null" width="500px">
      <div class="batch-modal-container">
        <div class="batch-modal-container-title">批量收藏</div>
        <a-textarea style="resize:none;" placeholder="请输入门店编码" v-model.trim="batchValue" :autosize="{ minRows: 9, maxRows: 9 }" />
        <div class="batch-modal-container-tip">* 多个编码请用英文逗号隔开，最多只能输入1000个编码...</div>
        <div class="batch-modal-container-tools">
          <span class="btn btn1" @click="batchCancel">取消</span>
          <span :class="batchValue ? 'btn btn2 active' : 'btn btn2'" @click="batchSure">确定</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "storeCollection"
);
import TopPlatformTab from "@/components/PlatformTab/TopPlatformTab";
import DateTab from "@/components/DateTab/DateTab";

export default {
  components: {
    TopPlatformTab,
    DateTab,
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          width: "10%",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          key: "account",
          title: "收藏列表",
          align: "left",
          width: "32%",
          scopedSlots: { customRender: "account" },
        },
        {
          key: "storeCode",
          title: "分组编号",
          width: "10%",
          align: "left",
          scopedSlots: { customRender: "storeCode" },
        },
        {
          key: "storeCount",
          title: "门店数量",
          width: "10%",
          align: "left",
          scopedSlots: { customRender: "storeCount" },
        },
        {
          key: "createTime",
          title: "创建时间",
          width: "10%",
          align: "left",
          scopedSlots: { customRender: "createTime" },
        },
        {
          key: "editTime",
          title: "修改时间",
          width: "10%",
          align: "left",
          scopedSlots: { customRender: "editTime" },
        },
        {
          key: "action",
          title: "操作",
          width: "18%",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      current: 1,
      pageSize: 20,
      total: 0,
      tableData: [],
      settingModalShow: false,
      tableIpt: "",
      tableIptShow: false,
      addGroupValue: "",
      addModalShow: false,
      copyGroupValue: "",
      copyModalShow: false,
      deleteModalShow: false,
      currentRow: {},
      currentBatch: {},
      batchCollectionModal: false,
      batchValue: ''
    };
  },
  computed: {
    ...mapState({
      addGroupRes: (state) => state.addGroupRes,
      groupData: (state) => state.groupData,
      editGroupRes: (state) => state.editGroupRes,
      deleteGroupRes: (state) => state.deleteGroupRes,
      addBatchRes: (state) => state.addBatchRes,
    }),
  },
  watch: {
    groupData(val) {
      this.tableData = val.list
      this.total = val.pagination.total
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    ...mapActions(["addGroup", "getGroup", "editGroupName", "deleteGroup", "addBatchDealer"]),
    // 查询列表
    getList() {
      this.getGroup({
        current: this.current,
        page_size: this.pageSize
      })
    },
    // 页码切换
    pageChange(val) {
      this.getList()
    },
    // 指标点击
    actionClick() {
      this.settingModalShow = true;
    },
    editName(obj) {
      this.tableIpt = obj.name;
      this.tableIptShow = obj;
    },
    async tableBlur(obj) {
      if (this.tableIpt.length <= 0) {
        this.$message.warning('分组名字不可为空！')
        return false
      }
      this.tableIptShow = false;
      await this.editGroupName({
        id: obj.id,
        name: this.tableIpt
      })
      if ( this.editGroupRes == 'success') {
        this.getList()
        this.$message.success('修改成功')
      } else {
        this.$message.error('修改失败')
      }

      // 刷新列表
    },
    openAddModal() {
      this.addGroupValue = "";
      this.addModalShow = true;
    },
    openCopyModal() {
      this.copyGroupValue = "";
      this.copyModalShow = true;
    },
    deleteClick(obj) {
      this.currentRow = obj
      this.deleteModalShow = true;
    },
    async sureDelete() {
      this.deleteModalShow = false;
      this.current = 1

      await this.deleteGroup({
        group_id: this.currentRow.id
      })

      if ( this.deleteGroupRes == 'success') {
        this.getList()
        this.$message.success('删除成功')
      } else {
        this.$message.error('删除失败')
      }
    },
    // 新建分组确认点击
    async addModalSure() {
      if (this.addGroupValue.length <= 0) {
        this.$message.warning('请输入新建分组名字！')
        return false
      }
      this.addModalShow = false;
      let params = {
        new_or_copy: 0,
        name: this.addGroupValue,
      };
      await this.addGroup(params);
      if ( this.addGroupRes == 'success') {
        this.getList()
        this.$message.success('创建成功')
      } else {
        this.$message.error('创建失败')
      }
    },
    // 复制分组确认点击
    async copyModalSure() {
      if (this.copyGroupValue.length <= 0) {
        this.$message.warning('请输入复制分组编号！')
        return false
      }
      this.copyModalShow = false;
      let params = {
        new_or_copy: 1,
        group_code: this.copyGroupValue,
      };
      await this.addGroup(params);
      if ( this.addGroupRes == 'success') {
        this.getList()
        this.$message.success('创建成功')
      } else {
        this.$message.error(this.addGroupRes || '创建失败')
      }
    },
    routerTo(obj) {
      this.$router.push({
        path: 'storeCollectionDetail',
        query: {
          name: obj.name,
          group_code: obj.group_code
        }
      })
    },
    // 批量收藏-显示
    batchCollection(obj) {
      console.log(obj)
      this.batchCollectionModal = true
      this.currentBatch = obj
    },
    // 批量收藏-取消
    batchCancel() {
      this.batchCollectionModal = false
      this.batchValue = ''
    },
    // 批量收藏-确定
    async batchSure() {
      if (this.batchValue) {
        console.log('提交。。', this.currentBatch)
        console.log('提交。。', this.batchValue)
        this.batchCollectionModal = false
        await this.addBatchDealer({
          group_code: this.currentBatch.group_code,
          dealers: this.batchValue
        })
        this.batchValue = ''
        if ( this.addBatchRes == 'success') {
          this.$message.success('收藏成功')
          this.getList()
        } else {
          this.$message.error(this.addBatchRes || '收藏失败')
        }
        console.log('addBatchRes',this.addBatchRes)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .platform-div {
    width: 100%;
    height: 40px;
    @include background_color("background_color2");
    text-align: right;
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include font_color("font_color2");
  }
  .tools {
    padding: 0 24px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    .button {
      width: 112px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      background: none !important;
      @include font_color("font_color3");
      @include border_color("border_color7");
      margin-left: 24px;
    }
  }
  .content {
    padding: 0 24px;
    .table {
      height: calc(100vh - 260px);
      overflow-y: auto;
      &-account {
        width: 100%;
        height: 68px;
        display: flex;
        align-items: center;
        > div {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          @include font_color("font_color2");
        }
        span {
          width: calc(100% - 60px);
          display: inline-block;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .edit-icon {
          display: none;
        }
      }
      &-account:hover {
        .edit-icon {
          display: inline-block;
        }
      }
      &-action {
        span {
          cursor: pointer;
        }
        span:first-child:hover {
          @include font_color("font_color4");
        }
        span:last-child:hover {
          @include font_color("font_color8");
        }
      }
    }
  }
  .page-div {
    text-align: center;
    padding: 26px 0;
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
}

.add-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  z-index: 110;
  &-container {
    position: absolute;
    top: 105px;
    right: 161px;
    border-radius: 4px;
    @include background_color("background_color27");
    width: 272px;
    height: 116px;
    padding: 24px;
    .action-div {
      display: flex;
      justify-content: space-around;
      > span {
        padding: 16px;
        margin-top: 8px;
        cursor: pointer;
        @include font_color("font_color3");
      }
      > span.active {
        @include font_color("font_color2");
      }
    }
  }
  &-container.copy {
    right: 24px;
  }
}

.delete-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 100;
  &-container {
    position: absolute;
    left: calc(50% - 130px);
    top: calc(50% - 78px);
    width: 260px;
    height: 156px;
    border-radius: 4px;
    @include background_color("background_color27");
    @include font_color("font_color2");
    font-size: 14px;
    &-title {
      font-size: 16px;
      padding: 24px 0 20px;
      @include font_color("font_color2");
      text-align: center;
    }
    .content {
      @include font_color("font_color2");
      text-align: center;
    }
    .action-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      > div {
        cursor: pointer;
        height: 32px;
        line-height: 18px;
        @include font_color("font_color2");
        .icon {
          font-size: 30px;
          @include font_color("font_color4");
          position: relative;
          top: 3px;
        }
      }
      .btn {
        width: 60px;
        height: 32px;
        border-radius: 4px;
        @include background_color("background_color5");
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        cursor: pointer;
      }
      .btn1 {
        @include font_color("font_color3");
        margin-right: 24px;
      }
      .btn2 {
        @include font_color("font_color1");
      }
    }
  }
}

.batch-modal-container {
  @include background_color("background_color2");
  width: 100%;
  height: 340px;
  &-title {
    font-size: 16px;
    text-align: center;
    @include font_color("font_color20");
    margin-bottom: 20px;
  }
  &-tip {
    font-size: 12px;
    text-align: left;
    @include font_color("font_color23");
    margin: 10px 0 24px;
  }
  &-tools {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .btn {
    width: 210px;
    height: 40px;
    @include background_color("background_color1");
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
  }
  .btn.btn1 {
    @include font_color("font_color20");

  }
  .btn.btn2 {
    @include font_color("font_color24");
    cursor: not-allowed; 
  }
  .btn.btn2.active {
    @include background_color("background_color34");
    @include font_color("font_color25");
    cursor: pointer;
  }
}
</style>