import echarts from 'echarts'
var option = {
    backgroundColor: 'rgba(0,0,0,0)',
    tooltip: {
        show: true,
        backgroundColor: 'rgba(37, 50, 85, 0.9)',
        textStyle: {
            color: '#fff',
        }
    },
    xAxis: [{
        type: 'category',
        data: [],
        axisLabel: {
            margin: 10,
            color: '#a4a4a4'
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        }
    }],
    yAxis: [{
        axisLabel: {
            color: '#a4a4a4',
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        splitLine: {
            show: false
        }
    }],
    series: [{
        type: 'bar',
        data: [],
        barWidth: '20px',
        itemStyle: {
            normal: {
                label: {
                    textStyle: {
                        color: '#828282'
                    }
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(102,159,239,1)'
                }, {
                    offset: 1,
                    color: 'rgba(170,202,246,1)'
                }], false)
            }
        },
        label: {
            normal: {
                show: true,
                position: 'top'

            }
        }
    }]
}

export default option