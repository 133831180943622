import CryptoJS from "crypto-js";

var key = "4146414E544943415238383838383838"; //密钥
var iv = "383838383838384146414E5449434152"; //偏移量

export const getAES = function (data) {
  if (data === null) return null;
  let encrypted = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Hex.parse(key),
    {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  );
  let enced = encrypted.ciphertext.toString()
  return enced.toUpperCase();
}

export const getDAES = function (data) {
  if (data === null || data === undefined) return null;
  let encrypted = CryptoJS.AES.decrypt(
    CryptoJS.format.Hex.parse(data),
    CryptoJS.enc.Hex.parse(key),
    {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  );
  return CryptoJS.enc.Utf8.stringify(encrypted);
}