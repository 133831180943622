<template>
  <div class="video-list-container">
    <div class="data-box">
      <div class="data-box-left">
        <div class="card-title">数据概览</div>
        <div class="card-tips" v-if="currentTarget">
          <span
            >{{
              getArrayLastItem(currentTarget.targetChart).start_time.slice(
                5,
                10
              )
            }}
            -
            {{
              getArrayLastItem(currentTarget.targetChart).end_time.slice(5, 10)
            }}，{{ currentTarget.targetName }}：</span
          >
          <span class="number">{{
            getArrayLastItem(currentTarget.targetChart).value | changeNum
          }}</span>
          <span>，环比增长：</span>
          <span class="number">{{
            getArrayLastItem(currentTarget.targetChart).ratio | numRate2
          }}</span>
        </div>
        <div class="card-container">
          <div
            :class="[
              'card-container-item',
              currentTarget &&
              currentTarget.targetCountKey == item.targetCountKey
                ? 'active'
                : '',
            ]"
            v-for="item in targetData"
            :key="item.targetCountKey"
            @click="currentTarget = item"
          >
            <span class="label">{{ item.targetName }}</span>
            <span class="number">{{ item.targetCount | changeNum }}</span>
          </div>
        </div>
      </div>
      <div class="data-box-right">
        <div class="chart-box">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(currentTarget && currentTarget.targetChart)"
            :id="'bar-chart-video'"
          />
        </div>
      </div>
    </div>
    <div style="margin: 16px 0">
      <ButtonGroup
        :tab-data="
          $route.query.type == 'dongchedi' || getPrincipalState() != '3'
            ? tabList2
            : tabList
        "
        @tabChange="handleTypeClick"
      />
    </div>
    <div style="margin-bottom: 20px">
      <a-table
        :columns="columns"
        :data-source="
          tabType == '2' ? videoList2 : tabType == '3' ? videoList3 : videoList1
        "
        :scroll="{ y: 'calc(100vh - 406px)' }"
        :rowKey="(data) => data.aweme_id"
        :pagination="false"
        :locale="locale"
        :loading="tableLoading"
        :customRow="openDetail"
        class="aft-table default"
      >
        <div slot="firstTitle" style="padding-left: 16px">视频标题</div>

        <div slot="videoTitle" slot-scope="scope" class="table-account">
          <div class="table-account-left">
            <div class="img-box">
              <div class="img-div">
                <WebpImage
                  :scale="'3-4'"
                  :src="scope.aweme_cover"
                  :width="'60px'"
                  :height="'80px'"
                  :radius="'8px'"
                />
              </div>
              <div class="shadow">
                <svg-icon icon-class="play8" class="icon" />
              </div>
            </div>
            <div class="box-right">
              <a-tooltip>
                <template slot="title">{{ scope.aweme_title || "-" }}</template>
                <span class="box-right-title">{{
                  scope.aweme_title || "-"
                }}</span>
              </a-tooltip>
              <div class="box-right-container" v-if="tabType == '3'">
                <a-tag
                  color="#FFA73E"
                  v-for="(item, index) in getTagList(scope.tag_list)"
                  :key="index"
                  style="margin-right: 8px"
                  >{{ item }}</a-tag
                >
              </div>
            </div>
          </div>
        </div></a-table
      >
    </div>
  </div>
</template>

<script>
const tabList = [
  {
    label: "全部视频",
    value: "1",
  },
  {
    label: "爆款视频",
    value: "2",
  },
  {
    label: "违规视频",
    value: "3",
  },
];
const tabList2 = [
  {
    label: "全部视频",
    value: "1",
  },
  {
    label: "爆款视频",
    value: "2",
  },
];
const targetData = {
  3: [
    {
      targetName: "视频发布",
      targetCountKey: "total_aweme_cnt_count",
      targetCount: "",
      targetChartKey: "total_aweme_cnt_chart",
      targetChart: [],
    },
    {
      targetName: "视频播放",
      targetCountKey: "aweme_play_count",
      targetCount: "",
      targetChartKey: "total_aweme_play_chart",
      targetChart: [],
    },
  ],
  2: [
    {
      targetName: "视频发布",
      targetCountKey: "total_aweme_cnt_count",
      targetCount: "",
      targetChartKey: "total_aweme_cnt_chart",
      targetChart: [],
    },
    {
      targetName: "视频播放",
      targetCountKey: "aweme_play_count",
      targetCount: "",
      targetChartKey: "total_aweme_play_chart",
      targetChart: [],
    },
    // {
    //   targetName: "条均播放",
    //   targetCountKey: "avg_aweme_play_count",
    //   targetCount: "",
    //   targetChartKey: "total_avg_aweme_play_chart",
    //   targetChart: [],
    // },
  ],
};
import api from "@/api/production";
import Empty from "@/components/Empty/Empty";
import WebpImage from "@/components/WebpImage/WebpImage";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import deepClone from "@/utils/clone-utils";
import MyChart from "@/components/MyChart/MyChart";
import chartBar3 from "../options/chartBar3.js";
import { getPrincipalState } from "@/utils/auth";
export default {
  components: {
    MyChart,
    WebpImage,
    ButtonGroup,
  },
  data() {
    return {
      awemeDetail: {},
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      videoList1: [],
      tableLoading: false,
      tabList: deepClone(tabList),
      tabList2: deepClone(tabList2),
      tabType: "1",
      targetData: [],
      currentTarget: null,
      columns: [
        {
          key: "videoTitle",
          slots: { title: "firstTitle" },
          align: "left",
          width: "30%",
          scopedSlots: { customRender: "videoTitle" },
        },
        {
          key: "publish_time",
          title: "发布时间",
          width: "15%",
          sorter: (a, b) =>
            new Date(a.publish_time).getTime() -
            new Date(b.publish_time).getTime(),
          align: "center",
          customRender: (scope) => scope.publish_time,
        },
        {
          key: "digg_count",
          title: "点赞数",
          sorter: (a, b) => a.digg_count - b.digg_count,
          align: "center",
          customRender: (scope) =>
            this.$options.filters.changeNum(scope.digg_count),
        },
        {
          key: "comment_count",
          title: "评论数",
          sorter: (a, b) => a.comment_count - b.comment_count,
          align: "center",
          customRender: (scope) =>
            this.$options.filters.changeNum(scope.comment_count),
        },
        {
          key: "share_count",
          title: "转发数",
          sorter: (a, b) => a.share_count - b.share_count,
          align: "center",
          customRender: (scope) =>
            this.$options.filters.changeNum(scope.share_count),
        },
        {
          key: "play_volume",
          title: "播放量",
          sorter: (a, b) => a.play_volume - b.play_volume,
          align: "center",
          customRender: (scope) =>
            this.$options.filters.changeNum(scope.play_volume),
        },
        this.getParams().type != "dongchedi"
          ? {
              key: "play_total_count_completion_rate",
              title: "完播率",
              sorter: (a, b) =>
                a.play_total_count_completion_rate -
                b.play_total_count_completion_rate,
              align: "center",
              customRender: (scope) =>
                this.$options.filters.numRate(
                  scope.play_total_count_completion_rate
                ),
            }
          : { width: 0 },
      ],
      chartShow: true,
    };
  },
  computed: {
    videoList2() {
      let data = this.videoList1.filter((item) => {
        return item.aweme_type && item.aweme_type.includes('1');
      });
      return data;
    },
    videoList3() {
      let data = this.videoList1.filter((item) => {
        return item.aweme_type && item.aweme_type.includes('2');
      });
      return data;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getVideoDataChart();
      this.getVideoDataList();
    },
    // 视频数据概览
    getVideoDataChart() {
      let parentParams = this.getParams();
      console.log("parentParams", parentParams);
      let body = {
        type: parentParams.type,
        params: {
          author_id: parentParams.author_id,
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
          date_type: parentParams.dateType,
        },
      };
      // 清空targetData
      this.targetData = [];
      this.currentTarget = null;
      api.getVideoDataChart(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          // 按月和周拿到当前需要展示的指标
          let defaultData = deepClone(targetData)[parentParams.dateType];
          // 补全接口数据到targetData
          this.targetData = defaultData.map((item) => {
            return {
              ...item,
              targetCount: res.data.data[item.targetChartKey][0].value,
              targetChart: res.data.data[item.targetChartKey].reverse(),
            };
          });
          this.currentTarget = this.targetData[0];
        } else {
          console.error("获取视频数据概览失败");
        }
      });
    },

    // 视频列表
    getVideoDataList() {
      let parentParams = this.getParams();
      let body = {
        type: parentParams.type,
        data: {
          author_ids: [parentParams.author_id],
          start_time: parentParams.start_time,
          end_time: parentParams.end_time,
          date_type: parentParams.dateType,
        },
      };
      this.videoList1 = [];
      api.getVideoDataList(body).then((res) => {
        if (res.data.code === 0) {
          this.videoList1 = res.data.data;
          let newTab = [];
          if (
            this.$route.query.type == "dongchedi" ||
            getPrincipalState() != "3"
          ) {
            newTab = deepClone(tabList2);
          } else {
            newTab = deepClone(tabList);
          }
          newTab = newTab.map((item) => {
            return {
              label: item.label + `(${this["videoList" + item.value].length})`,
              value: item.value,
            };
          });
          this.tabList = newTab;
          this.tabList2 = newTab;
          console.log("getVideoDataList", res.data);
        } else {
          console.error("获取视频列表失败");
        }
      });
    },

    getBarOption(data) {
      let parentParams = this.getParams();
      let option = deepClone(chartBar3);
      if (data && data.length) {
        let xData = data.map((item) => {
          return item.item;
        });
        option.xAxis.data = xData;
        if (parentParams.dateType == 3) {
          option.xAxis.axisLabel.formatter = "近{value}月";
        } else if (parentParams.dateType == 2) {
          option.xAxis.axisLabel.formatter = "近{value}周";
        }
        option.tooltip.formatter = (params) => {
          let newParams = params[0];
          return `
          <div style="background: #3C4252;line-height: 24px;font-size: 12px;padding: 4px;">
            <div style="color: rgba(255,255,255,0.88);font-weight: bold;">${newParams.data.start_time.slice(
              0,
              10
            )} - ${newParams.data.end_time.slice(0, 10)}</div>
            <div><span style="color: rgba(255,255,255,0.55);">${
              this.currentTarget.targetName
            }: </span><span style="color: #fff;">${this.$options.filters.changeNum(
            newParams.data.value
          )}</span></div>
            <div><span style="color: rgba(255,255,255,0.55);">环比增长: </span><span style="color: #fff;">${this.$options.filters.numRate2(
              newParams.data.ratio
            )}</span></div>
          </div>`;
        };
        option.series[0].data = data;
      }
      return option;
    },
    // 切换视频类型tab
    handleTypeClick(val) {
      this.tabType = val;
    },
    getParams() {
      return this.$parent.getParams();
    },
    getPrincipalState() {
      return getPrincipalState();
    },
    openDetail(record, index) {
      return {
        on: {
          click: () => {
            if (record.aweme_id) {
              let url = `/videoDetail?id=${record.aweme_id}&platform=${
                this.getParams().type
              }`;
              window.open(url);
            } else {
              this.$message.error("暂无视频ID，无法查看详情");
            }
          },
        },
      };
    },
    getTagList(arr) {
      if (arr && arr.length) {
        let newArr = arr.map((item) => {
          return item.tag;
        });
        return newArr;
      } else {
        return [];
      }
    },
    getArrayLastItem(arr) {
      if (arr && arr.length) {
        return arr[arr.length - 1];
      } else {
        return {};
      }
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.video-list-container {
  width: 100%;
  .data-box {
    width: 100%;
    height: 304px;
    display: flex;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-wrap: nowrap;
    &-left {
      width: 436px;
      height: 100%;
      .card-title {
        height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
        line-height: 24px;
      }
      .card-tips {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #242832;
        border-radius: 8px;
        margin: 20px 0 16px;
        padding-left: 12px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        .number {
          color: #ffcc55;
        }
      }
      .card-container {
        width: 100%;
        height: 164px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
        &-item {
          width: 140px;
          height: 78px;
          background: none;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 12px;
          .label {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.55);
            line-height: 22px;
            margin-bottom: 4px;
          }
          .number {
            font-size: 20px;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.88);
            line-height: 28px;
          }
          &:hover {
            background: #242832;
          }
          &.active {
            background: #ffcc55;
            .label {
              color: rgba(0, 0, 0, 0.55);
            }
            .number {
              color: rgba(0, 0, 0, 0.88);
            }
          }
        }
      }
    }
    &-right {
      width: calc(100% - 436px);
      height: 100%;
      .chart-box {
        width: 100%;
        height: 100%;
      }
    }
  }
  .aft-table {
    .ant-table-row:hover {
      .table-account {
        &-left {
          @include font_color("font_color26");
          .shadow {
            display: inline-block;
          }
        }
      }
    }
  }
  .table {
    height: calc(100vh - 350px);
    overflow-y: auto;
    &-account {
      display: flex;
      flex-direction: row;
      margin: 16px 0;
      padding-left: 16px;
      &-left {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        @include font_color("font_color20");
        .img-box {
          min-width: 60px;
          margin-right: 8px;
          border-radius: 4px;
          // background: #000;
          position: relative;
          overflow: hidden;
          .img-div {
            width: 60px;
            height: 80px;
            // @include background_color("background_color43");
            display: flex;
            align-items: center;
          }
          .shadow {
            display: none;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            @include background_color("background_color20");
            .icon {
              position: absolute;
              top: calc(50% - 10px);
              left: calc(50% - 10px);
              font-size: 20px;
              @include font_color("font_color6");
            }
          }
        }
        .box-right {
          width: calc(100% - 70px);
          display: flex;
          flex-direction: column;
          &-title {
            height: 42px;
            width: 100%;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
          &-container {
            margin-top: 4px;
            display: flex;
            > span {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
</style>