<template>
  <div class="tabs-wrap">
    <div
      v-for="(item, index) in tabData"
      :key="'tabs' + index"
      :class="
        currentClick == item.value ? 'active-tab-class tab-class' : 'tab-class'
      "
      @click="tabClick(item.value)"
    >
      <span>{{ item.label }}</span>
      <span class="line"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabData: {
      type: Array,
    },
  },
  data() {
    return {
      currentClick: this.tabData[0].value,
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    tabClick(value) {
      this.currentClick = value;
      this.$emit("tabChange", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tab-class.active-tab-class {
  @include font_color("font_color26");
  font-weight: bold;
  .line {
    @include background_color("background_color34");
  }
}
.tab-class {
  cursor: pointer;
  @include font_color("font_color21");
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 40px;
  .line {
    margin-top: 12px;
    width: 44px;
    height: 4px;
    border-radius: 2px;
    @include font_color("font_color26");
  }
}
.tab-class:last-child {
  margin-right: 0;
}
.tabs-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
