<template>
  <div style="width: 100%;height: calc(100vh - 65px); overflow: auto">
    <div class="window-min-width">
      <platform-header
        title=""
        :tabData="tabData"
        @tabChange="tabChange"
      />
      <div class="main-container">
        <div class="left-container">
          <!-- 卡片 -->
          <div class="left-wrap1">
            <LeftWrap1 ref="LeftWrap1" />
          </div>
          <!-- 本月曝光量 -->
          <div class="left-wrap left-wrap2">
            <LeftWrap2 ref="LeftWrap2" />
          </div>
          <!-- 活动轮播 -->
          <div class="left-wrap left-wrap3">
            <LeftWrap3 ref="LeftWrap3" />
          </div>
          <!-- 正在直播 -->
          <div class="left-wrap left-wrap4">
            <LeftWrap4 ref="LeftWrap4" />
          </div>
        </div>
        <div class="right-container">
          <!-- 品牌 + 矩阵异动 -->
          <div class="right-wrap1">
            <RightWrap1 ref="RightWrap1" />
          </div>
          <!-- 卫冕主播 -->
          <div class="right-wrap2">
            <RightWrap2 ref="RightWrap2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import LeftWrap1 from "./components/LeftWrap1"
import LeftWrap2 from "./components/LeftWrap2"
import LeftWrap3 from "./components/LeftWrap3"
import LeftWrap4 from "./components/LeftWrap4"
import RightWrap1 from "./components/RightWrap1"
import RightWrap2 from "./components/RightWrap2"

export default {
  name: "bulletinBoard",
  components: {
    PlatformHeader,
    MyChart,
    LeftWrap1,
    LeftWrap2,
    LeftWrap3,
    LeftWrap4,
    RightWrap1,
    RightWrap2,
  },
  data() {
    return {
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
        {
          label: "懂车帝",
          value: "dongchedi",
        },
      ],
      currentTab: "douyin",
    };
  },
  computed: {
  },
  created() {
  },
  watch: {},
  methods: {
    tabChange(val) {
      console.log('切换平台', val);
      this.currentTab = val;
      this.initAllData()
    },
    initAllData() {
      this.$refs.LeftWrap1.initData()
      this.$refs.LeftWrap2.initData()
      this.$refs.LeftWrap3.initData()
      this.$refs.LeftWrap4.initData()
      this.$refs.RightWrap1.initData()
      this.$refs.RightWrap2.initData()
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.window-min-width {
  min-width: 1218px;
}
.main-container {
  width: 100%;
  height: 832px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .left-container {
    width: calc(100% - 310px);
    height: 100%;
    display: flex;
    flex-direction: column;
    .left-wrap {
      width: 100%;
      margin-top: 10px;
      border-radius: 16px;
      background: #10121A;
    }
    .left-wrap1 {
      width: 100%;
      height: 170px;
    }
    .left-wrap2 {
      height: 412px;
    }
    .left-wrap3 {
      height: 54px;
    }
    .left-wrap4 {
      height: 166px;
    }
  }
  .right-container {
    width: 290px;
    height: 100%;
    .right-wrap1 {
      width: 100%;
      height: 408px;
      background: #000;
      border-radius: 16px;
    }
    .right-wrap2 {
      width: 100%;
      height: 414px;
      margin-top: 10px;
      background: #000;
      border-radius: 16px;
    }
  }
}
</style>
