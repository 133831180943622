<template>
  <div class="calendar-container">
    <!-- 日历 -->
    <!-- <a-spin tip="Loading..." :spinning="calenderLoading"> -->

    <div class="card-title">
      <!-- <span>直播日历</span> -->
      <span>*点击直播场次可筛选日期</span>
    </div>
    <div class="calendar">
      <ul class="calendar-box calendar-header">
        <li v-for="(item, index) in week" :key="index">
          {{ item }}
        </li>
      </ul>
      <ul class="calendar-box calendar_content">
        <li v-for="index in dateWeek" :key="index" class="big_border"></li>
        <li
          v-for="item in dayList"
          :key="item.id"
          :class="
            item.live_infos && item.id == activeLive
              ? 'big_border day_cell active'
              : item.live_infos
              ? 'big_border day_cell'
              : 'big_border'
          "
        >
          <span style="line-height: 32px">{{ item.day }}</span>
          <span class="topic_length" v-if="item.live_infos">
            <a
              :class="
                item.id == activeLive
                  ? 'live_count active_live'
                  : 'live_count common_style'
              "
              @click="selectDay(item.id)"
              ><span>直播场次:</span> {{ item.live_infos.length }}</a
            >
          </span>
        </li>
        <li v-for="index in lassWeek" :key="index * 31" class="big_border"></li>
      </ul>
      <div class="live-list">
        <!-- <div class="live-list-title">直播列表</div> -->
        <div class="live-list-main">
          <div
            class="live-list-main-item"
            v-for="(item, index) in tableList"
            @click="gotoVideoDetail(item)"
            :key="index"
          >
            <div class="cover-box">
              <WebpImage
                :scale="'3-4'"
                :src="item.cover"
                :width="'84px'"
                :height="'auto'"
                :radius="'0px'"
              />
              <svg-icon
                v-if="item.type == 2"
                icon-class="living2"
                class="living-btn"
              />
            </div>
            <div class="right-box">
              <div class="right-box-top">
                <span class="right-box-top-title">{{ item.title || "-" }}</span>

                <div :class="item.type == 2 ? 'disabled' : ''">
                  <span>详情</span>
                  <svg-icon icon-class="arrow-right" class="arrow-icon" />
                </div>
              </div>
              <div class="right-box-info">
                <a-tag
                  color="#FFA73E"
                  v-for="(i, index) in getTagList(item.tag_name, item.tag_list)"
                  :key="index"
                  v-show="i && showTag"
                  style="margin-right: 8px"
                  >{{ i }}</a-tag
                >

                <!-- <img :src="item.author.avatar" />
                <span>{{ item.author.nickname }}</span> -->
              </div>
              <div class="right-box-data">
                <div>
                  <span>直播时长</span>
                  <span>{{
                    item.statistics_live_time | disposeLongTime_m
                  }}</span>
                </div>
                <div
                  v-if="platform == 'douyin' || $route.query.type == 'douyin'"
                >
                  <span>观众平均停留时长</span>
                  <span>{{
                    item.statistics_avg_online_time | disposeLongTime_m
                  }}</span>
                </div>

                <div>
                  <span>开播时间</span>
                  <span>{{ item.publish_time }}</span>
                </div>
                <div>
                  <span>最高观看人数</span>
                  <span>{{
                    (item.type == 1 ? item.statistics_max_online_user : null)
                      | changeNum
                  }}</span>
                </div>
                <div>
                  <span>总{{ $route.query.type | returnWatchText }}</span>
                  <span>{{
                    (item.type == 1
                      ? item.statistics_total_watch_user_time
                      : null) | changeNum
                  }}</span>
                </div>
                <div>
                  <span>单场增粉</span>
                  <span>{{
                    (item.type == 1 ? item.statistics_fans_add : null)
                      | changeNum
                  }}</span>
                </div>
                <div>
                  <span>互动量</span>
                  <span>{{
                    (item.type == 1
                      ? item.statistics_total_polling_count
                      : null) | changeNum
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <Empty
            v-if="!tableList || tableList.length == 0"
            style="margin-top: 120px"
            title="暂无直播"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as vuex from "vuex";
import Empty from "@/components/Empty/Empty";
import WebpImage from "@/components/WebpImage/WebpImage";
const { mapState } = vuex.createNamespacedHelpers("production");
export default {
  name: "CalendarLive",
  components: {
    WebpImage,
    Empty,
  },
  props: {
    calendarData: {
      type: Object,
      require: true,
    },
    month: {
      type: Object,
      require: true,
    },
    platform: {
      type: String,
      require: false,
    },
    showTag: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      calenderLoading: (state) => state.calenderLoading,
    }),
  },
  data() {
    return {
      week: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      dateWeek: [],
      lassWeek: [],
      dayList: [],
      tableList: [],
      currentDate: moment().format("YYYY-MM-DD"),
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      columns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          title: "直播",
          dataIndex: "title",
          width: "30%",
          scopedSlots: { customRender: "title_img" },
        },
        {
          title: "直播时长",
          dataIndex: "statistics_live_time",
          width: "10%",
          scopedSlots: { customRender: "live_time" },
        },
        {
          title: "开播时间",
          dataIndex: "publish_time",
          width: "10%",
          scopedSlots: { customRender: "publish_time" },
        },

        {
          title: "最高观看人数",
          dataIndex: "statistics_max_online_user",
          width: "12%",
          scopedSlots: { customRender: "statistics_max_online_user" },
        },
        {
          title: "总观看人次",
          dataIndex: "statistics_total_watch_user_time",
          width: "10%",
          scopedSlots: { customRender: "statistics_total_watch_user_time" },
        },
        {
          title: "单场增粉",
          dataIndex: "statistics_fans_add",
          width: "10%",
          scopedSlots: { customRender: "statistics_fans_add" },
        },
        {
          title: "互动量",
          dataIndex: "statistics_total_polling_count",
          width: "10%",
          scopedSlots: { customRender: "statistics_total_polling_count" },
        },
        {
          title: "操作",
          dataIndex: "actions",
          width: "8%",
          scopedSlots: { customRender: "actions" },
        },
      ],
      activeLive: 0,
    };
  },
  created() {
    this.getMonth(this.month);
  },
  watch: {
    calendarData: {
      handler(val) {
        console.log("calendarData", val);
        this.tableList = [];
        this.getMonth(this.month);
        this.diffCalender(val);
      },
      deep: true,
    },
  },
  methods: {
    // 获取某月1号星期几
    getNowMonthWeek(date) {
      let week = this.$moment(date).startOf("month").day();
      this.dateWeek = week;
    },
    // 获取某月最后一天星期几
    getLassMontWeek(date) {
      let num = this.$moment(date).endOf("month").day();
      this.lassWeek = 6 - num;
    },
    // 计算月份信息
    getMonth(date) {
      this.getNowMonthWeek(date);
      this.getLassMontWeek(date);
      let monthLength = this.$moment(date).daysInMonth();
      let dayStr = this.$moment(date).format("YYYY-MM");
      let time_date = this.$moment().format("MMM Do");
      this.monthTips = dayStr;
      let dateList = [];
      for (let i = 1; i <= monthLength; i++) {
        let obj = {
          id: i >= 10 ? `${dayStr}-${i}` : `${dayStr}-0${i}`,
          day: i >= 10 ? i : `0${i}`,
          date: time_date,
        };
        dateList.push(obj);
      }
      this.dayList = dateList;
    },
    diffCalender(value) {
      console.log("传入对value值看看", value);
      let calenderArr = this.dayList;
      calenderArr.forEach((ele) => {
        for (let i in value) {
          if (i == ele.id) {
            ele.live_infos = value[i];
            this.tableList = this.tableList.concat(value[i]);
          }
        }
      });
      this.tableList = this.tableList.sort((a, b) => {
        return Date.parse(b.publish_time) - Date.parse(a.publish_time);
      });
      console.log(123, this.tableList);
      this.dayList = calenderArr;
    },
    // 时长计算
    diffTimeLong(value) {
      let time = "";
      var days = parseInt(value / 1440);
      var hours = parseInt((value % 1440) / 60);
      var minutes = parseInt((value % 1440) % 60);
      if (days > 0) {
        time += days + "天";
      }
      if (hours > 0) {
        time += hours + "小时";
      }
      if (minutes > 0) {
        time += minutes + "分钟";
      }
      return time;
    },
    // 选择筛选某天直播
    selectDay(id) {
      this.activeLive = id;
      for (let i in this.calendarData) {
        if (i == id) {
          this.tableList = this.calendarData[i];
        }
      }
    },
    // 跳转详情
    gotoVideoDetail(item) {
      if (item.type == 2) {
        this.$message.warning("当前直播暂未结束，不能查看直播详情！");
        return false;
      }
      if (!item.room_id) {
        this.$message.warning("当前直播数据数据异常，请稍后重试。");
        return false;
      }
      let routeUrl = this.$router.resolve({
        path: `/liveDetail?id=${item.room_id}&platform=${
          this.platform || this.$route.query.type
        }`,
      });
      window.open(routeUrl.href, "_blank");
    },

    rowClick(record, index) {
      return {
        on: {
          click: () => {
            console.log(record);
            this.gotoVideoDetail(record.room_id);
          },
        },
      };
    },
    getTagList(str, arr) {
      if (str) {
        return str.split(",");
      } else if (arr && arr.length) {
        let newArr = arr.map((item) => {
          return item.tag;
        });
        return newArr || [];
      } else {
        return [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
@import "@/assets/styles/_handle.scss";
.calendar-container {
  @include background_color("background_color50");
  padding: 0 20px;
  border-radius: 16px;
  .live-list {
    width: 100%;
    @include background_color("background_color50");
    padding: 26px 0;
    &-title {
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      @include font_color("font_color20");
    }
    &-main {
      width: calc(100% + 40px);
      position: relative;
      left: -20px;
      height: 450px;
      overflow-y: auto;
      &-item {
        cursor: pointer;
        width: 100%;
        height: 153px;
        // border-bottom: 1px solid;
        // @include border_color("border_color24");
        padding: 21px 0 20px 30px;
        display: flex;
        align-items: center;
        .cover-box {
          width: 84px;
          height: 112px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          @include background_color("background_color45");
          overflow: hidden;
          margin-right: 20px;
          position: relative;
          .living-btn {
            position: absolute;
            bottom: -5px;
            left: 14px;
            font-size: 57px;
          }
        }
        .right-box {
          width: calc(100% - 104px);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          > div {
            width: 100%;
          }
          &-top {
            height: 26px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            &-title {
              width: calc(100% - 100px);
              font-size: 14px;
              font-weight: bold;
              @include font_color("font_color20");
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            > div {
              height: 26px;
              @include font_color("font_color23");
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 10px 0 14px;
              .arrow-icon {
                font-size: 26px;
              }
            }
            > div.disabled {
              opacity: 0.5;
            }
          }
          &-info {
            height: 20px;
            margin: 5px 0 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            > span {
              padding: 0 7px;
            }
            // > img {
            //   width: 20px;
            //   height: 20px;
            //   margin-right: 8px;
            //   border-radius: 50%;
            // }
            // > span {
            //   font-size: 14px;
            //   @include font_color("font_color23");
            // }
          }
          &-data {
            height: 44px;
            display: flex;
            justify-content: space-between;
            > div {
              width: 118px;
              height: 44px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              &:nth-child(2) {
                width: 176px;
              }
              > span:first-child {
                @include font_color("font_color23");
                font-size: 12px;
              }
              > span:last-child {
                @include font_color("font_color43");
                font-size: 16px;
                font-weight: bold;
              }
            }
          }
        }
      }
      &-item:hover {
        @include background_color("background_color5");
        .right-box {
          &-top {
            > span {
              @include font_color("font_color26");
            }
            > div {
              @include font_color("font_color21");
            }
          }
          &-data {
            > div {
              > span:last-child {
                @include font_color("font_color26");
              }
            }
          }
        }
      }
    }
  }
}

.card-title {
  display: flex;
  align-items: flex-end;
  padding: 0 0px 17px;
  height: 35px;
  width: 100%;
  > span:first-child {
    font-size: 18px;
    font-weight: 500;
    @include font_color("font_color20");
    line-height: 25px;
  }
  > span:last-child {
    font-size: 12px;
    @include font_color("font_color23");
    margin-left: 8px;
  }
}
.calendar {
  width: 100%;
  @include background_color("background_color1");
  border-radius: 4px;
  margin-bottom: 24px;
  .calendar-header.calendar-box {
    padding: 0px 0 6px;
    @include background_color("background_color2");
    > li {
      @include font_color("font_color47");
    }
  }
  &-box {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    li {
      flex: 0 0 13.74%;
      margin-right: 7px;
      padding-left: 19px;
      font-size: 12px;
      height: 32px;
      @include font_color("font_color20");
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.calendar_content {
  @include background_color("background_color50");
  .big_border {
    @include border_color("border_color24");
    @include background_color("background_color50");
    height: 32px;
    font-size: 12px;
    border: 1px solid;
    margin-right: 7px;
    border-bottom: none;
    &:hover {
      @include background_color("background_color78");
    }
    &.active {
      @include background_color("background_color78");
    }
  }
  .day_cell {
    // @include font_color("font_color2");
    display: flex;
    .topic_length {
      display: inline-block;
      margin: 6px 0 0 16%;

      .live_count {
        display: inline-block;
        height: 20px;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        &:hover {
          width: 86px;
          font-weight: bold;
          @include font_color("font_color41");
          @include background_color("background_color13");
          > span {
            display: inline-block;
          }
        }
      }
      .common_style {
        width: 50px;
        @include font_color("font_color26");
        @include background_color("background_color51");
        > span {
          display: none;
        }
      }
      .active_live {
        width: 50px;
        font-weight: bold;
        @include font_color("font_color41");
        @include background_color("background_color13");
        > span {
          display: none;
        }
      }
      .live_tips {
        margin-top: 4px;
        font-size: 12px;
        @include font_color("font_color3");
      }
    }
  }
  li:nth-child(7n) {
    margin-right: 0;
  }
  li:nth-last-child(-n + 7) {
    border-bottom: 1px solid;
  }
  li:nth-child(1) {
    border-radius: 8px 0 0 0;
  }
  li:nth-child(7) {
    border-radius: 0 8px 0 0;
  }
  li:nth-last-child(1) {
    border-radius: 0 0 8px 0;
  }
  li:nth-last-child(7) {
    border-radius: 0 0 0 8px;
  }
}
</style>
<style lang="scss">
.ant-table-pagination.ant-pagination {
  float: none !important;
  margin: 16px 0;
  text-align: center;
}
</style>