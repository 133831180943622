<template>
  <div class="task-progress-container">
    <div class="container-title">
      <div class="title-text">任务进度·{{ taskData.taskName }}</div>
      <div class="title-button">
        <a-button class="button" type="primary" @click="exportExcel">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>导出Excel</span>
        </a-button>
      </div>
    </div>
    <p class="sub-title">任务目标：{{ text }}</p>
    <p class="sub-title">任务说明：{{ taskData.taskExplain || '-'}}</p>
    <div class="card">
      <span class="card-item">总体数据</span>
      <div class="card-item">
        <span class="card-item-title">任务完成率</span>
        <div class="card-item-num">{{ taskData.taskRate }}%</div>
        <a-progress
          class="card-item-progress"
          :percent="taskData.taskRate"
          strokeColor="#FFCA5C"
          :showInfo="false"
        />
      </div>
      <div class="card-item">
        <span class="card-item-title">当前完成/目标值</span>
        <div class="card-item-num">
          {{ taskData.finishCount | changeNumNoRound }}/{{
            taskData.targetValue | changeNumNoRound
          }}
        </div>
        <a-progress
          class="card-item-progress"
          :percent="finishCountRate"
          strokeColor="#FFCA5C"
          :showInfo="false"
        />
      </div>
      <div class="card-item">
        <span class="card-item-title">完成门店/总数</span>
        <div class="card-item-num">
          {{ taskData.finishDealerCount }}/{{ taskData.allDealerCount }}
        </div>
        <a-progress
          class="card-item-progress"
          :percent="finishDealerCountRate"
          strokeColor="#FFCA5C"
          :showInfo="false"
        />
      </div>
    </div>
    <div class="collapse">
      <a-collapse @change="onChange">
        <a-collapse-panel
          class="main"
          :class="
            (item.dealerDetailList &&
              item.dealerDetailList.length &&
              item.isDataPermission) ||
            (item.warZoneTaskDetailList && item.warZoneTaskDetailList.length)
              ? 'no-arrow'
              : ''
          "
          :key="item.regionId + item.regionName"
          v-for="item in taskData.regionTaskDetailList"
          :showArrow="
            (item.warZoneTaskDetailList && item.warZoneTaskDetailList.length>0) ||
              (item.dealerDetailList && item.dealerDetailList.length>0)
          "
          :disabled="
            !(
              (item.warZoneTaskDetailList &&
                item.warZoneTaskDetailList.length) ||
              (item.dealerDetailList && item.dealerDetailList.length)
            )
          "
        >
          <template #header>
            <div class="collapse-header">
              <div class="regionName">
                <span class="regionNameText">{{ item.regionName }}</span>
              </div>
              <div>{{ item.taskRate }}%</div>
              <div>
                {{ item.finishCount | changeNumNoRound }}/{{
                  item.targetValue | changeNumNoRound
                }}
              </div>
              <div>{{ item.finishDealerCount }}/{{ item.allDealerCount }}</div>
            </div>
          </template>
          <!-- 战区完成详情 -->
          <template
            v-if="
              item.warZoneTaskDetailList && item.warZoneTaskDetailList.length
            "
          >
            <a-collapse
              v-for="(row, index) in item.warZoneTaskDetailList"
              :key="row.warZoneId"
              :class="
                index === item.warZoneTaskDetailList &&
                item.warZoneTaskDetailList.length - 1
                  ? 'last-sub-row'
                  : ''
              "
            >
              <a-collapse-panel
                class="sub"
                :disabled="!row.isDataPermission"
                :showArrow="row.isDataPermission"
                @click="onClickSub(row)"
              >
                <template #header>
                  <div class="collapse-header">
                    <div>
                      <span class="col-title">{{ row.warZoneName }}</span>
                    </div>
                    <div>{{ row.taskRate }}%</div>
                    <div>
                      {{ row.finishCount | changeNumNoRound }}/{{
                        row.targetValue | changeNumNoRound
                      }}
                    </div>
                    <div>
                      {{ row.finishDealerCount }}/{{ row.allDealerCount }}
                    </div>
                  </div>
                </template>
                <template
                  v-if="row.dealerDetailList && row.dealerDetailList.length"
                >
                  <div
                    class="collapse-content"
                    v-for="(dealer, index) in row.dealerDetailList"
                    :key="dealer.dealerId"
                  >
                    <div>
                      <span class="col-title">{{ dealer.dealerName }}</span>
                    </div>
                    <div></div>
                    <div>
                      {{ dealer.finishCount | changeNumNoRound }}/{{
                        dealer.targetValue | changeNumNoRound
                      }}
                    </div>
                    <div>
                      <TaskProgressTag
                        :value="dealer.isFinish"
                      ></TaskProgressTag>
                    </div>
                  </div>
                </template>
              </a-collapse-panel>
            </a-collapse>
          </template>
          <!-- 没有战区,只有门店 -->
          <template
            v-else-if="item.dealerDetailList && item.dealerDetailList.length"
          >
            <div
              class="collapse-content"
              v-for="(dealer, index) in item.dealerDetailList"
              :key="dealer.dealerId"
            >
              <div>
                <span class="col-title">{{ dealer.dealerName }}</span>
              </div>
              <div></div>
              <div>
                {{ dealer.finishCount | changeNumNoRound }}/{{
                  dealer.targetValue | changeNumNoRound
                }}
              </div>
              <div>
                <TaskProgressTag :value="dealer.isFinish"></TaskProgressTag>
              </div>
            </div>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import api from "@/api/taskProgress.js";
import Empty from "@/components/Empty/Empty";
import TaskProgressTag from "./taskProgressTag.vue";
import exportTable from "@/utils/exportTable";

export default {
  name: "taskProgress",
  components: {
    Empty,
    TaskProgressTag,
  },
  data() {
    return {
      taskId: "",
      loading: false,
      taskData: {
        allDealerCount: 0,
        finishCount: 0,
        finishDealerCount: 0,
        id: "",
        regionTaskDetailList: [
          {
            allDealerCount: 0,
            dealerDetailList: [],
            finishCount: 0,
            finishDealerCount: 0,
            regionId: "",
            regionName: "",
            targetValue: 0,
            taskRate: 0,
            warZoneTaskDetailList: [],
          },
          {
            allDealerCount: 0,
            dealerDetailList: [
              {
                dealerId: "0",
                dealerName: "0",
                finishCount: 0,
                isFinish: 0,
                targetValue: 0,
              },
            ],
            finishCount: 0,
            finishDealerCount: 0,
            regionId: "",
            regionName: "",
            targetValue: 0,
            taskRate: 0,
            warZoneTaskDetailList: [],
          },
        ],
        targetValue: 0,
        taskExplain: "",
        taskName: "0",
        taskRate: 0,
        taskType: 0,
        text: "",
      },
    };
  },
  computed: {
    finishCountRate() {
      return (
        Number(
          (this.taskData.finishCount / this.taskData.targetValue).toFixed(2)
        ) * 100
      );
    },
    finishDealerCountRate() {
      return (
        Number(
          (
            this.taskData.finishDealerCount / this.taskData.allDealerCount
          ).toFixed(2)
        ) * 100
      );
    },
  },
  created() {
    this.taskId = this.$route.query.taskId;
    this.text = this.$route.query.text;
    console.log("this.$route", this.$route);
  },
  mounted() {
    this.getList();
  },
  methods: {
    onClickMain(item) {
      if (
        item.dealerDetailList &&
        item.dealerDetailList.length &&
        !item.isDataPermission
      ) {
        this.$message.warning("仅支持查看所属区域门店详情");
      }
    },
    onClickSub(item) {
      if (!item.isDataPermission) {
        this.$message.warning("仅支持查看所属区域门店详情");
      }
    },
    exportExcel() {
      exportTable(
        this.$http,
        {
          taskId: this.taskId,
        },
        "afantiactivity",
        "/jjt/activity/web/task/detailExport",
        "filename"
      );
    },
    onChange() {},
    getList() {
      this.loading = true;
      api
        .getTaskDetail({
          taskId: this.taskId,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.taskData = res.data.data || [];
          } else {
            this.taskData = [];
            this.$message.error(res.data.message || "获取数据失败");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.task-progress-container {
  padding: 20px;
  .container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .title-text {
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.88);
  }
  .btn-icon {
    transform: rotate(90deg);
    margin-right: 5px;
  }
  .button {
    @include font_color("font_color2");
    width: 120px;
    background: #2b2f3b;
    border-color: #2b2f3b;
    border-radius: 8px;
  }
  .sub-title {
    margin: 8px 0 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.55);
  }
  .card {
    margin-top: 20px;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    height: 110px;
    background: #2b2f3b;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-item {
    flex: 1;
  }
  .collapse {
    margin-top: 20px;
  }
  .card-item {
    color: rgba(255, 255, 255, 0.88);
  }
  .card-item-title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.55);
  }
  .card-item-num {
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.88);
  }
  .card-item-progress {
    width: 140px;
  }
  .collapse {
    /deep/ .ant-collapse {
      background-color: #242832;
      border: 1px solid #242832;
      border-radius: 8px;
    }
    /deep/.ant-collapse-content > .ant-collapse-content-box {
      background-color: #242832;
      padding: 0;
    }
    .main /deep/.ant-collapse-header {
      background-color: #2b2f3b;
      .ant-collapse-arrow {
        color: rgba(255, 255, 255, 0.88);
      }
    }
    .sub /deep/.ant-collapse-header {
      background-color: #242832;
      .ant-collapse-arrow {
        color: rgba(255, 255, 255, 0.88);
      }
    }
    /deep/.ant-collapse > .ant-collapse-item {
      border-bottom: 1px solid #3c4252;
    }
    /deep/.ant-collapse-content {
      border-top: 1px solid #3c4252;
    }

    /deep/.ant-collapse-item:last-child > .ant-collapse-content {
      border-radius: 0;
    }

    /deep/.ant-collapse > .ant-collapse-item:last-child {
      border-radius: 0;
    }

    .collapse-header,
    .collapse-content {
      color: rgba(255, 255, 255, 0.88);
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        flex: 1;
      }
    }
    .collapse-content {
      padding-left: 40px;
      border-bottom: 1px solid #3c4252;
      &:last-of-type {
        border-bottom: none;
      }
    }

    .sub /deep/.ant-collapse-arrow {
      left: 30px;
    }

    .last-sub-row .sub {
      border-bottom: none;
    }

    .main {
      .collapse-header {
        .col-title {
          display: inline-block;
          padding-left: 45px;
        }
      }
      .collapse-content {
        padding: 12px 16px;
        padding-left: 40px;
        background-color: #242832;
        .col-title {
          display: inline-block;
          padding-left: 20px;
        }
      }
    }
  }
  .no-arrow {
    /deep/.ant-collapse-header {
      padding-left: 20px;
    }
    .regionName {
      .regionNameText {
        display: inline-block;
        padding-left: 40px;
      }
    }
  }
}
</style>
