<template>
  <div class="container">
    <platform-header
      title="视频排行"
      :currentTab="currentTab"
      @tabChange="tabChange"
      :tooltipName="'数据说明'"
      :showTooltip="true"
      :code="'spqt01'"
    />
    <div class="tools">
      <ButtonGroup :tab-data="tabList" @tabChange="handleTypeClick" />
      <div class="export-wrap">
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button
          v-hide-by-username
          class="button2"
          @click="exportClick"
          :loading="exportLoading"
        >
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>
    <div :class="['tools', 'arrow-tools', isToolsOpen ? 'opened' : '']">
      <div>
        <div>
          <!-- 大区 -->
          <a-select
            v-model="regionValue"
            :suffixIcon="suffixIcon"
            style="width: 130px; height: 40px"
            placeholder="选择大区"
            @change="
              groupValue = undefined;
              search(true);
            "
          >
            <a-select-option value="">不限大区</a-select-option>
            <a-select-option
              :value="item.id"
              v-for="item in regionData.region_list"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <!-- 分组 -->
          <a-select
            v-model="groupValue"
            :suffixIcon="suffixIcon"
            style="margin: 0 0 0 12px; width: 130px; height: 40px"
            placeholder="选择分组"
            @change="
              regionValue = undefined;
              search(true);
            "
          >
            <a-select-option value="">不限分组</a-select-option>
            <a-select-option
              :value="item.group_code"
              v-for="item in groupData"
              :key="item.group_code"
            >
              {{ item.group_name }}
            </a-select-option>
          </a-select>
          <!-- 违规类型 -->
          <a-select
            v-model="violation_type"
            class="aft-select"
            placeholder="请选择违规类型"
            :suffixIcon="suffixIcon"
            @change="search(true)"
            style="margin: 0 0 0 12px; width: 164px; height: 40px"
          >
            <a-select-option value="">全部类型</a-select-option>
            <a-select-option value="1" v-if="isBrushVolume"
              >刷量</a-select-option
            >
            <a-select-option value="2">非刷量</a-select-option>
          </a-select>
          <!-- 活动名称 -->
          <!-- <a-select
            v-model="topic_id"
            class="aft-select"
            placeholder="请选择活动名称"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :suffixIcon="suffixIcon"
            showArrow
            @change="search(true)"
            style="margin: 0 0 0 12px; width: 180px; height: 40px"
          >
            <a-select-option value="">全部活动</a-select-option>
            <a-select-option
              v-for="item in activityData || []"
              :key="item.id"
              :value="item.id"
            >
              {{ item.activity_name }}
            </a-select-option>
          </a-select> -->
          <span class="split"></span>
          <span class="arrow-btn" @click="isToolsOpen = !isToolsOpen">
            <svg-icon icon-class="select-arrow" class="arrow-btn-icon" />
          </span>
        </div>
        <div>
          <!-- 视频标题 -->
          <span class="tools-label">视频标题： </span>
          <a-input-search
            v-model.trim="title_ipt"
            v-emoji
            @search="search(true)"
            @pressEnter="search(true)"
            style="width: 180px; margin-right: 12px"
            class="aft-search"
            placeholder="搜索关键字"
          />
          <!-- 门店/账号名称 -->
          <span class="tools-label">门店/账号名称： </span>
          <a-input-search
            v-model.trim="name_ipt"
            v-emoji
            @search="search(true)"
            @pressEnter="search(true)"
            style="width: 180px; margin-right: 12px"
            class="aft-search"
            placeholder="搜索关键字"
          />
          <!-- 账号类型 -->
          <a-select
            class="aft-select"
            v-model="verification_type"
            :suffixIcon="suffixIcon"
            placeholder="请选择账号类型"
            style="width: 150px; height: 40px"
            @change="search(true)"
          >
            <a-select-option
              v-for="item in accountTypeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <!-- 业务用途 -->
          <a-select
            class="aft-select"
            v-model="author_tag_id"
            :suffixIcon="suffixIcon"
            placeholder="请选择业务用途"
            style="margin-right: 12px;width: 150px; height: 40px"
            @change="search(true)"
          >
            <a-select-option
              v-for="item in businessTypeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <!-- 视频标签 -->
          <a-select
            class="aft-select"
            v-model="module_type"
            :suffixIcon="suffixIcon"
            placeholder="请选择视频标签"
            style="width: 150px; height: 40px"
            @change="search(true)"
          >
            <a-select-option value="">全部视频标签</a-select-option>
            <a-select-option value="1">模板视频</a-select-option>
            <a-select-option value="2">非模板视频</a-select-option>
          </a-select>
        </div>
      </div>
      <date-tab
        :type="dateType"
        @changeType="changeType"
        @changeDate="search(true)"
        ref="dateTab"
      ></date-tab>
    </div>
    <div class="content">
      <div :class="['table', isToolsOpen ? 'opened' : '']">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :scroll="{ y: `calc(100vh - ${isToolsOpen ? '504' : '408'}px)` }"
          :rowKey="(data) => data.aweme_id"
          :pagination="false"
          :locale="locale"
          :loading="tableLoading"
          :customRow="openDetail"
          class="aft-table default"
        >
          <div slot="firstTitle" style="padding-left: 16px">视频标题</div>
          <div slot="customTitle" style="display: flex">
            <div>播放量</div>
          </div>
          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            {{ (current - 1) * pageSize + Number(index) + 1 }}
          </div>
          <div slot="videoTitle" slot-scope="scope" class="table-account">
            <div class="table-account-left">
              <div class="img-box">
                <div class="img-div">
                  <WebpImage
                    :scale="'3-4'"
                    :src="scope.aweme_cover"
                    :width="'60px'"
                    :height="'80px'"
                    :radius="'0px'"
                  />
                </div>
                <div class="shadow">
                  <svg-icon icon-class="play" class="icon" />
                </div>
              </div>
              <a-tooltip>
                <template slot="title">{{ scope.aweme_title || "-" }}</template>
                <span class="font-color4 f14">{{
                  scope.aweme_title || "-"
                }}</span>
              </a-tooltip>
            </div>
          </div>
          <div slot="name" slot-scope="scope" class="font-color2 f14">
            <a-tooltip>
              <template slot="title">{{ scope.dealer_name || "-" }}</template>
              <div>
                <div class="ellipsis">
                  <img
                    src="@/assets/img/type-store.png"
                    width="20px"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 2px;
                    "
                    alt=""
                  />
                  <span>{{ scope.dealer_name || "-" }}</span>
                </div>
              </div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">{{ scope.nickname || "-" }}</template>
              <div>
                <div class="ellipsis">
                  <img
                    src="@/assets/img/type-lanv.png"
                    v-if="scope.verification_type == 2"
                    width="20"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 2px;
                    "
                    alt=""
                  />
                  <img
                    src="@/assets/img/type-ordinary.png"
                    v-else
                    width="20"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 2px;
                    "
                    alt=""
                  />
                  <span>{{ scope.nickname || "-" }}</span>
                </div>
              </div>
            </a-tooltip>
          </div>
          <div slot="storeCode" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.dealer_store_code || "-" }}</span>
          </div>
          <div slot="startTime" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.publish_time || "-" }}</span>
          </div>
          <div slot="diggCount" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.digg_count | changeNum }}</span>
          </div>
          <div slot="commentCount" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.comment_count | changeNum }}</span>
          </div>
          <div slot="shareCount" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.share_count | changeNum }}</span>
          </div>
          <div slot="play_volume" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.play_volume | changeNum }}</span>
          </div>
          <div
            slot="interactionCount"
            slot-scope="scope"
            class="font-color6 f14"
          >
            <span>{{ scope.aweme_interaction_count | changeNum }}</span>
          </div>
        </a-table>
      </div>
      <div class="page-div aft-pagination">
        <span class="total-text">共 {{ total || 0 }} 项数据</span>
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "liveRanking"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import TopPlatformTab from "@/components/PlatformTab/TopPlatformTab";
import DateTab from "@/components/DateTab/DateTab2";
import MultipleGroup from "@/components/MultipleGroup/MultipleGroup";
import { getToken, getPrincipalId } from "@/utils/auth";
import WebpImage from "@/components/WebpImage/WebpImage";
import exportTable from "@/utils/exportTable";
import Empty from "@/components/Empty/Empty";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import { accountTypeList, businessTypeList } from "./constant";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";

export default {
  components: {
    PlatformHeader,
    TopPlatformTab,
    DateTab,
    MultipleGroup,
    WebpImage,
    ExcelTypeButton,
    ButtonGroup,
  },
  data() {
    return {
      defultAvatar: require("@/assets/img/defult-avatar.png"),
      excelType: false,
      exportLoading: false,
      currentTab: "",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      dateType: "month",
      regionValue: undefined,
      groupValue: undefined,
      topic_id: undefined,
      accountTypeList,
      verification_type: undefined,
      businessTypeList,
      author_tag_id: "",
      violation_type: undefined,
      module_type: undefined,
      title_ipt: "",
      isToolsOpen: false,
      name_ipt: "",
      orderList: [
        {
          text: "互动量",
          value: "2",
          field: "aweme_interaction_count",
        },
        {
          text: "播放量",
          value: "1",
          field: "play_volume",
        },
      ],
      tabList: [
        {
          label: "按互动量排序",
          value: "2",
        },
        {
          label: "按播放量排序",
          value: "1",
        },
      ],
      sortValue: "1",
      orderIndex: 0,
      sortValue: "2",
      tableLoading: false,
      columns: [
        {
          key: "videoTitle",
          slots: { title: "firstTitle" },
          align: "left",
          width: "30%",
          scopedSlots: { customRender: "videoTitle" },
        },
        {
          key: "name",
          title: "门店名称/账号名称",
          align: "left",
          width: "20%",
          scopedSlots: { customRender: "name" },
        },
        {
          key: "storeCode",
          title: "门店编码",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "storeCode" },
        },
        {
          key: "startTime",
          title: "发布时间",
          width: "10%",
          align: "left",
          scopedSlots: { customRender: "startTime" },
        },
        {
          key: "diggCount",
          title: "点赞数",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "diggCount" },
        },
        {
          key: "commentCount",
          title: "评论数",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "commentCount" },
        },
        {
          key: "shareCount",
          title: "转发数",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "shareCount" },
        },
        {
          key: "play_volume",
          width: "8%",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "play_volume" },
        },
        {
          key: "interactionCount",
          title: "互动量",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "interactionCount" },
        },
      ],
      current: 1,
      pageSize: 20,
      total: 0,
      tableData: [],

      isBrushVolume: false,
    };
  },
  computed: {
    ...mapState({
      regionData: (state) => state.regionData, //区域
      groupData: (state) => state.groupData,
      videoData: (state) => state.videoData,
      activityData: (state) => state.activityData,
      monitorTagList: (state) => state.monitorTagList,
    }),
  },
  watch: {
    videoData(val) {
      console.log(val);
      this.tableData = val.list;
      this.total = val.total;
    },
  },
  created() {
    this.getAreaList();
    this.getAllGroup();
    // this.getActivityList({
    //   platform: this.currentTab,
    // });
    // this.$nextTick(() => {
    //   this.search(true);
    // });
  },
  mounted() {},
  methods: {
    ...mapActions([
      "getAreaList",
      "getAllGroup",
      "getVideoList",
      "getActivityList",
      "getMonitorTag",
    ]),
    ...mapMutations(["changeState"]),
    // 直播/视频 tab切换
    async tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.topic_id = undefined;
      this.changeState({ data: [], name: "activityData" });
      await this.getMonitorTag({ types: 1, principalId: getPrincipalId() });
      await this.getActivityList({ platform: this.currentTab });
      this.search(true);
      // 是否存在刷量
      this.isBrushVolume = (this.monitorTagList || [])
        .map((item) => item.tagName)
        .includes("刷量");
    },
    hadnleOrderClick(res) {
      console.log(res);
      this.sortValue = res.data.value;
      this.search(true);
    },
    handleTypeClick(val) {
      this.sortValue = val;
      this.search(true);
    },
    async search(isFirst) {
      this.tableLoading = true;
      let begin_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      if (isFirst) {
        this.current = 1;
      }
      let body = {
        params: {
          region_id: this.regionValue || "",
          group_code: this.groupValue || "",
          activity_id: this.topic_id || "",
          verification_type: this.verification_type || "",
          author_tag_id: this.author_tag_id || "",
          account_type: this.author_tag_id == 30 ? this.author_tag_id : "",
          illegal_type: this.violation_type || "",
          module_type: this.module_type || "",
          field: this.name_ipt || "",
          title_field: this.title_ipt || "",
          start_time: begin_time,
          end_time: end_time,
          current: this.current,
          size: this.pageSize,
          sort: this.sortValue,
        },
        platform: this.currentTab,
      };
      await this.getVideoList(body);
      this.tableLoading = false;
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 导出
    exportClick() {
      let begin_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      let params = {
        region_id: this.regionValue || "",
        group_code: this.groupValue || "",
        activity_id: this.topic_id || "",
        verification_type: this.verification_type || "",
        author_tag_id: this.author_tag_id || "",
        account_type: this.author_tag_id == 30 ? this.author_tag_id : "",
        illegal_type: this.violation_type || "",
        module_type: this.module_type || "",
        field: this.name_ipt || "",
        title_field: this.title_ipt || "",
        sort: this.sortValue,
        start_time: begin_time,
        end_time: end_time,
        flag: this.excelType ? 1 : 0,
      };
      this.exportLoading = true;
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/aweme/top/exportExcel",
        "filename",
        this.exportCallback
      );
    },
    exportCallback() {
      this.exportLoading = false;
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      // this.search(true);
    },
    // 页码切换
    pageChange(val) {
      console.log(val);
      this.search();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.trim().toLowerCase()) >= 0
      );
    },
    openVideo(url) {
      window.open(url);
    },
    openDetail(record, index) {
      return {
        on: {
          click: () => {
            if (record.aweme_id) {
              let url = `/videoDetail?id=${record.aweme_id}&platform=${this.currentTab}`;
              window.open(url);
            } else {
              this.$message.error("暂无视频ID，无法查看详情");
            }
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .platform-div {
    width: 100%;
    height: 40px;
    @include background_color("background_color2");
    text-align: right;
    position: relative;

    .beta-icon {
      width: 36px;
      height: 14px;
      border-radius: 7px;
      background: #ff4160;
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      position: absolute;
      top: 4px;
      right: 0px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include font_color("font_color2");
  }
  .arrow-tools {
    height: 40px;
    overflow: hidden;
    transition: all 0.3s;
    > div:first-child > div {
      display: flex;
      align-items: center;
      height: 40px;
      margin-top: 8px;
      &:first-child {
        margin: 0;
      }
    }
    &.opened {
      height: 136px;
      .arrow-btn {
        background: #2b2f3b;
        &-icon {
          color: #9ca8ba;
          transform: rotate(180deg);
        }
      }
    }
    .split {
      width: 1px;
      height: 28px;
      background: #2b2f3b;
      margin: 6px 13px;
      display: inline-block;
    }
    .arrow-btn {
      width: 40px;
      height: 40px;
      background: #473f30;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      &-icon {
        font-size: 12px;
        color: #ffca5c;
      }
    }
  }
  .tools {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    &-label {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
    }
    .export-wrap {
      display: flex;
      align-items: center;
    }
    .button {
      width: 112px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      background: none;
    }
    .btn-color1 {
      @include font_color("font_color4");
      @include background_color("background_color1");
      @include border_color("font_color4");
    }
    .btn-color2 {
      @include font_color("font_color7");
      @include background_color("background_color1");
      @include border_color("font_color7");
      margin-left: 24px;
    }
    .button2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      margin-left: 10px;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }
  .content {
    padding: 0 24px;
    .table {
      height: calc(100vh - 350px);
      overflow-y: auto;
      transition: all 0.3s;
      &.opened {
        height: calc(100vh - 446px);
      }
      &-account {
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        padding-left: 16px;
        &-left {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          @include font_color("font_color3");
          .img-box {
            margin-right: 8px;
            border-radius: 4px;
            // background: #000;
            position: relative;
            overflow: hidden;
            .img-div {
              width: 60px;
              height: 80px;
              // @include background_color("background_color43");
              display: flex;
              align-items: center;
            }
            .shadow {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              @include background_color("background_color20");
              .icon {
                position: absolute;
                top: calc(50% - 13px);
                left: calc(50% - 15px);
                font-size: 30px;
                @include font_color("font_color6");
              }
            }
          }
          > span {
            height: 42px;
            width: calc(100% - 70px);
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
      }
    }
  }
  .page-div {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .total-text {
      font-size: 14px;
      color: #9ca8ba;
    }
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .ellipsis {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}
</style>
