<template>
  <div class="tab-content-container">
    <div class="center-img">
      <WebpImage
        :scale="'9-16'"
        :src="tabData.img_list[currentImg]"
        :width="'148px'"
        :height="'244px'"
        :radius="'8px'"
      />
      <img src="@/assets/img/scanning.gif" alt="" class="scanning-gif" />
    </div>
    <div class="tags-box reverse" style="top: 61px; right: calc(50% + 117px)">
      <div class="big-tag">
        <div>车型识别</div>
        <div>Model identification</div>
      </div>
      <div class="tag-list">
        <span
          class="tag-item"
          v-for="(item, index) in tabData.model_identification"
          :key="index"
          >{{ item }}</span
        >
      </div>
    </div>
    <div class="tags-box reverse" style="top: 172px; right: calc(50% + 163px)">
      <div class="big-tag">
        <div>直播画面布局判定</div>
        <div>Live picture layout determination</div>
      </div>
      <div class="tag-list">
        <span
          class="tag-item"
          v-for="(item, index) in tabData.live_picture_layout_determination"
          :key="index"
          >{{ item }}</span
        >
      </div>
    </div>
    <div class="tags-box" style="top: 21px; left: calc(50% + 128px)">
      <div class="big-tag">
        <div>人车比例</div>
        <div>Proportion of people and vehicles</div>
      </div>
      <div class="tag-list">
        <span
          class="tag-item"
          v-for="(item, index) in tabData.proportion_of_people_and_vehicles"
          :key="index"
          >{{ item }}</span
        >
      </div>
    </div>
    <div class="tags-box" style="top: 126px; left: calc(50% + 177px)">
      <div class="big-tag">
        <div>车顶牌识别</div>
        <div>Roof plate identification</div>
      </div>
      <div class="tag-list">
        <span
          class="tag-item"
          v-for="(item, index) in tabData.roof_plate_identification"
          :key="index"
          >{{ item }}</span
        >
      </div>
    </div>
    <div class="tags-box" style="top: 227px; left: calc(50% + 128px)">
      <div class="big-tag">
        <div>对话镜头识别</div>
        <div>Conversation shot recognition</div>
      </div>
      <div class="tag-list">
        <span
          class="tag-item"
          v-for="(item, index) in tabData.conversation_shot_recognition"
          :key="index"
          >{{ item }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "TabContent1",
  components: { WebpImage },
  props: {
    tabData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currentImg: 0,
      timer: null,
    };
  },
  created() {},
  computed: {},
  watch: {
    tabData(val) {
      console.log(111111, this.tabData.img_list);
      clearInterval(this.timer);
      this.timer = null;
      if (!this.tabData.img_list || this.tabData.img_list.length == 0) {
        return false;
      }
      this.currentImg = 0;
      this.timer = setInterval(() => {
        if (this.currentImg < this.tabData.img_list.length - 1) {
          this.currentImg++;
        } else {
          this.currentImg = 0;
        }
        console.log(11223, this.currentImg)
      }, 2000);
    },
  },
  methods: {},
};
</script>
<style lang="scss" scope>
.tab-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  .center-img {
    width: 168px;
    height: 261.5px;
    background-image: url("../../../assets/img/img-border.png");
    background-size: 168px 261.5px;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 21px;
    left: calc(50% - 84px);
    .scanning-gif {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .tags-box {
    width: 332px;
    height: 68px;
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    .big-tag {
      width: 182px;
      height: 68px;
      border: 1px solid transparent;
      border-radius: 8px;
      transition: all 0.3s;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #494031, #494031),
        linear-gradient(
          161deg,
          rgba(255, 202, 92, 0.5),
          rgba(255, 202, 92, 0.1),
          rgba(255, 202, 92, 0.5)
        );
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > div:first-child {
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.45);
        line-height: 24px;
      }
      > div:last-child {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
        line-height: 18px;
        transform: scale(0.83333);
        transform-origin: center center;
        white-space: nowrap;
      }
    }
    .tag-list {
      width: 150px;
      height: 68px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .tag-item {
        padding: 0 8px;
        min-width: 64px;
        height: 20px;
        font-size: 12px;
        color: #ffcc55;
        line-height: 20px;
        text-align: center;
        margin-left: 8px;
        margin-bottom: 4px;
        background: rgba(255, 204, 85, 0.3);
        border-radius: 4px;
        opacity: 0.5;
        transition: all 0.3s;
      }
    }
    &.reverse {
      flex-direction: row-reverse;
      .tag-list {
        flex-direction: row-reverse;
        .tag-item {
          margin-right: 8px;
          margin-left: 0px;
        }
      }
    }
    &:hover {
      .big-tag {
        background-image: linear-gradient(
            to bottom,
            rgba(255, 210, 118, 1),
            #494031
          ),
          linear-gradient(
            161deg,
            rgba(255, 202, 92, 0.5),
            rgba(255, 202, 92, 0.1),
            rgba(255, 202, 92, 0.5)
          );
      }
      .tag-list {
        .tag-item {
          opacity: 1;
        }
      }
    }
  }
}
</style>
