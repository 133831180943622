/** * tabData: 按钮组数据 * tabChange: 点击按钮触发的方法 * example:
<platform-tab :tabData="tabData" @tabChange="tabChange"></platform-tab>
*/

<template>
  <div :class="isTabBtn ? 'tab-btn-wrap' : ''">
    <a-button
      v-for="(item, index) in tabData"
      :key="'btn' + index"
      round
      :class="
        currentClick == item.value ? 'active-btn-class btn-class' : 'btn-class'
      "
      :style="defaultBtnStyle"
      @click="tabClick(item)"
    >
      <svg-icon :icon-class="item.icon" class="icon" v-if="isTabBtn"></svg-icon>
      <span class="label">{{ item.label }}</span>
      <span
        v-if="showTag"
        :class="
          currentClick == item.value
            ? 'active-tag-class tag-class'
            : 'tag-class'
        "
        >{{ item.tag }}</span
      >
    </a-button>
  </div>
</template>

<script>
export default {
  name: "ButtonGroup",
  props: {
    tabData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    defaultBtnStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showTag: {
      type: Boolean,
      default: false,
    },
    isTabBtn: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: [String, Number],
    },
  },
  data() {
    return {
      currentClick: this.tabData.length ? this.tabData[0].value : "",
    };
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler(newVal, oldVal) {
        newVal && (this.currentClick = newVal);
      },
    },
    tabData() {
      if (
        this.defaultValue !== undefined &&
        this.defaultValue !== null &&
        this.defaultValue !== ""
      ) {
        this.currentClick = this.defaultValue;
      } else {
        this.currentClick = this.tabData.length ? this.tabData[0].value : "";
      }
    },
  },
  methods: {
    tabClick({ label, value }) {
      this.currentClick = value;
      this.$emit("tabChange", value);
      this.$emit("tabNameChange", label);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.btn-class.active-btn-class {
  @include background_color("background_color13");
  @include font_color("font_color25");
  font-weight: bold;
  .label {
    border-right: none;
  }
}
.btn-class {
  cursor: pointer;
  @include font_color("font_color22");
  min-width: 60px;
  height: 40px;
  border: none;
  line-height: 30px;
  padding: 5px 0;
  border-radius: 0px !important;
  @include background_color("background_color2");

  .label {
    width: 100%;
    height: 24px;
    padding: 0 15px;
    line-height: 24px;
    display: inline-block;
    border-right: 1px solid rgba(28, 30, 39, 0.4);
  }
  &:last-child {
    .label {
      border-right: none;
    }
  }
}
.tag-class.active-tag-class {
  @include background_color("background_color48");
}
.tag-class {
  @include font_color("font_color20");
  border-radius: 5px 0px 5px 0px;
  @include background_color("background_color47");
  font-size: 12px;
  height: 14px;
  display: inline-block;
  padding: 0 4px;
  line-height: 14px;
  margin-left: 8px;
  .icon {
    display: none;
  }
}
.btn-class:first-child {
  border-radius: 8px 0 0 8px !important;
}
.btn-class:last-child {
  border-radius: 0 8px 8px 0 !important;
  border-right: 1px solid rgba(0, 0, 0, 0);
}
.btn-class:first-child.btn-class:last-child {
  border-radius: 8px 8px 8px 8px !important;
}

.tab-btn-wrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-class {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px !important;
    & + .btn-class {
      margin-left: 10px;
    }
    .icon {
      margin-right: 4px;
      display: inline-block;
      font-size: 24px;
      @include font_color("font_color23");
    }
    &.active-btn-class {
      .icon {
        @include font_color("font_color25");
      }
    }
  }
}
</style>
