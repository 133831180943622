<template>
  <div style="padding-bottom: 20px">
    <platform-header
      title="竞品区域对比"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <div class="tools-left">
        <a-select
          v-model="regionValue"
          style="width: 126px; height: 36px; margin-right: 8px"
          placeholder="选择大区"
          @change="regionClick"
        >
          <!-- <a-select-option value="" key="" v-if="regionData.type == 10">全部</a-select-option> -->
          <a-select-option
            :value="item.id"
            v-for="item in regionData.region_list"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="tools-right">
        <a-month-picker
          v-model="searchTime"
          class="aft-month-picker default"
          placeholder="Select month"
          :disabled-date="pickerOptions"
          @change="onChange"
          locale="zh-cn"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-month-picker>
      </div>
    </div>
    <div class="contrast-container">
      <div>
        <MyPrincipal :infoData="infoData" ref="MyPrincipal" />
      </div>
      <div>
        <EnemyPrincipal
          :infoData="otherInfoData"
          :enemyData="enemyData"
          @changePrincipal="changePrincipal"
          ref="EnemyPrincipal"
        />
      </div>
    </div>
    <div class="chart-div">
      <div class="chart-title">
        <NewTabs :tabData="barTabs" @tabChange="changeBarTabs" />
      </div>
      <div class="chart-total" v-if="barTabsKey == '1'">
        <span>{{ infoData.account_count | changeNum }}</span>
        <span>合计</span>
        <span>{{ otherInfoData.account_count | changeNum }}</span>
      </div>
      <div class="chart-total" v-else>
        <span>{{ infoData.marketing_value | changeNum }}</span>
        <span>合计</span>
        <span>{{ otherInfoData.marketing_value | changeNum }}</span>
      </div>
      <div class="chart-main">
        <my-chart
          :options="
            barTabsKey == '1'
              ? getBarOption(infoData.chartData1, otherInfoData.chartData1)
              : getBarOption(infoData.chartData2, otherInfoData.chartData2)
          "
          :id="'bar-chart1'"
        ></my-chart>
      </div>
    </div>
    <div class="chart-div" style="height: 371px">
      <div class="chart-title">
        <NewTabs :tabData="lineTabs" @tabChange="changeLineTabs" />
      </div>
      <div class="chart-title2">
        <NewTabs2
          ref="NewTabs2"
          v-if="secondLineTabs[lineTabsKey].length > 0"
          :tabData="secondLineTabs[lineTabsKey]"
          @tabChange="changeSecondLineTabs"
        />
      </div>
      <div class="chart-main" style="height: 282px">
        <my-chart
          :options="getLineOption(lineChartData)"
          :id="'line-chart1'"
        ></my-chart>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartBar from "./options/chartBar";
import chartLine from "./options/chartLine";
import chartLine2 from "./options/chartLine2";
import MyPrincipal from "./components/MyPrincipal";
import EnemyPrincipal from "./components/EnemyPrincipal";
import api from "@/api/regionContrast.js";

import NewTabs from "@/components/PlatformTab/NewTabs";
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "regionContrast"
);

export default {
  components: {
    PlatformHeader,
    MyChart,
    MyPrincipal,
    EnemyPrincipal,
    NewTabs,
    NewTabs2,
  },
  data() {
    return {
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      searchTime: moment().subtract(1, "months"),
      currentTab: "",
      regionValue: "",
      brandValue: "",
      barTabs: [
        {
          label: "门店账号数量",
          value: "1",
        },
        {
          label: "营销价值",
          value: "2",
        },
      ],
      barTabsKey: "1",
      lineTabs: [
        {
          label: "粉丝增量",
          value: "1",
        },
        {
          label: "短视频",
          value: "2",
        },
        {
          label: "直播",
          value: "3",
        },
      ],
      lineTabsKey: "1",
      secondLineTabs: {
        1: [],
        2: [
          {
            label: "视频发布",
            value: "1",
          },
          {
            label: "播放总量",
            value: "2",
          },
        ],
        3: [
          {
            label: "直播场次",
            value: "1",
          },
          {
            label: "直播时长",
            value: "2",
          },
          {
            label: "直播观看",
            value: "3",
          },
        ],
      },
      secondLineTabsKey: "1",
      enemyPrincipalId: "",
      lineChartData: {},

      isFirstInit: true,
    };
  },
  watch: {
    enemyData(val) {
      console.log(val);
    },
  },
  computed: {
    ...mapState({
      regionData: (state) => state.regionData, //大区
      enemyData: (state) => state.enemyData, //竞品
      infoData: (state) => state.infoData, //区域
      otherInfoData: (state) => state.otherInfoData, //区域
    }),
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions([
      "getAreaList",
      "getEnemyPrincipal",
      "getInfo",
      "getInfoById",
    ]),
    async initData() {
      return Promise.all([this.getAreaList(), this.getEnemyPrincipal()]).then(
        () => {
          if (
            this.regionData.region_list.length == 0 ||
            this.enemyData.length == 0
          ) {
            this.$message.error("当前品牌未绑定大区或竞品，请联系管理员");
            return false;
          }
          if (this.regionData.type == 10) {
            // todo
            this.regionValue = this.regionData.region_list[0].id;
            // this.regionValue == ''
          } else {
            this.regionValue = this.regionData.region_list[0].id;
          }
          this.enemyPrincipalId = this.enemyData[0].principal_id;
        }
      );
    },
    async loadInfo() {
      this.getInfo({
        area_id: this.regionValue,
        year: moment(this.searchTime).format("YYYY"),
        month: moment(this.searchTime).format("MM"),
        platform: this.currentTab,
      });
      if (!this.enemyPrincipalId) {
        return false;
      }
      this.getInfoById({
        area_id: this.regionValue,
        principal_id: this.enemyPrincipalId,
        year: moment(this.searchTime).format("YYYY"),
        month: moment(this.searchTime).format("MM"),
        platform: this.currentTab,
      });
      this.getLineChartData();
      // 门店Top10
      this.getMyPrincipalStoreList();
      this.getEnemyPrincipalStoreList();
    },
    getMyPrincipalStoreList() {
      let data = {
        platform: this.currentTab,
        params: {
          area_id: this.regionValue,
          year: moment(this.searchTime).format("YYYY"),
          month: moment(this.searchTime).format("MM"),
        },
      };
      this.$refs.MyPrincipal.getTopStoreList(data);
    },
    getEnemyPrincipalStoreList() {
      let data = {
        platform: this.currentTab,
        params: {
          area_id: this.regionValue,
          principal_id: this.enemyPrincipalId,
          year: moment(this.searchTime).format("YYYY"),
          month: moment(this.searchTime).format("MM"),
        },
      };
      this.$refs.EnemyPrincipal.getTopStoreList(data);
    },
    getLineChartData() {
      let params = {
        area_id: this.regionValue,
        principal_id: this.enemyPrincipalId,
        year: moment(this.searchTime).format("YYYY"),
        month: moment(this.searchTime).format("MM"),
      };
      if (this.lineTabsKey == "1") {
        api
          .getLineByFans({
            params,
            platform: this.currentTab,
          })
          .then((res) => {
            this.setLineData(res);
          });
      } else if (this.lineTabsKey == "2") {
        api
          .getLineByAweme({
            params: {
              ...params,
              type: this.secondLineTabsKey,
            },
            platform: this.currentTab,
          })
          .then((res) => {
            this.setLineData(res);
          });
      } else if (this.lineTabsKey == "3") {
        api
          .getLineByLive({
            params: {
              ...params,
              type: this.secondLineTabsKey,
            },
            platform: this.currentTab,
          })
          .then((res) => {
            this.setLineData(res);
          });
      }
    },
    setLineData(res) {
      if (res.data.code === 0) {
        let newData = res.data.data;
        this.lineChartData = {
          xData: newData.self_area_dtos.map((item) => {
            return item.statistics_day.slice(0, 10);
          }),
          yData1: newData.self_area_dtos.map((item) => {
            return item.value_count;
          }),
          yData2: newData.competitor_area_dtos.map((item) => {
            return item.value_count;
          }),
          name1: newData.self_principal_name,
          name2: newData.competitor_principal_name,
        };
      } else {
        console.error("获取line chart失败");
      }
    },
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().startOf("month");
    },
    async tabChange(val) {
      console.log(val);
      if (this.isFirstInit) {
        this.isFirstInit = false;
        await this.initData();
      }
      this.currentTab = val;
      this.loadInfo();
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      this.loadInfo();
    },
    getBarOption(data1, data2) {
      let option = deepClone(chartBar);
      option.options[0].series[0].data = data1;
      option.options[0].series[1].data = data2;
      return option;
    },
    getLineOption(data) {
      let option = deepClone(chartLine);
      if (this.lineTabsKey == "3" && this.secondLineTabsKey == "2") {
        option = deepClone(chartLine2);
      }

      if (!data.xData) {
        return option;
      }
      option.xAxis.data = data.xData;
      option.series[0].data = data.yData1;
      option.series[1].data = data.yData2;
      option.series[0].name = data.name1;
      option.series[1].name = data.name2;
      return option;
    },
    regionClick() {
      this.loadInfo();
    },
    brandClick() {
      this.loadInfo();
    },
    // tabs切换
    changeBarTabs(val) {
      this.barTabsKey = val;
    },
    // tabs切换
    changeLineTabs(val) {
      this.lineTabsKey = val;
      this.secondLineTabsKey = "1";
      if (this.$refs.NewTabs2) {
        this.$refs.NewTabs2.currentClick = "1";
      }
      this.getLineChartData();
    },
    changeSecondLineTabs(val) {
      this.secondLineTabsKey = val;
      this.getLineChartData();
    },
    changePrincipal(id) {
      this.enemyPrincipalId = id;
      this.loadInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  &-left {
    width: calc(50% - 12px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  &-right {
    width: calc(50% - 12px);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.contrast-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  > div {
    width: calc(50% - 10px);
    border: 1px solid #2b2f3b;
    border-radius: 0px 0px 12px 12px;
    overflow: hidden;
  }
}
.chart-div {
  width: calc(100% - 40px);
  margin-left: 20px;
  height: 396px;
  margin-top: 20px;
  border-radius: 12px;
  @include background_color("background_color2");
  .chart-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    .svg-title {
      font-size: 16px;
      margin-right: 8px;
    }
    > div > span {
      font-size: 16px;
      @include font_color("font_color2");
    }
  }
  .chart-title2 {
    height: 22px;
    padding-left: 20px;
  }
  .chart-total {
    height: 26px;
    display: flex;
    justify-content: center;
    span {
      position: relative;
      left: -7px;
      font-size: 14px;
      font-weight: bold;
      @include font_color("font_color51");
      margin: 0 7px;
    }
    span:first-child {
      width: 200px;
      text-align: right;
      @include font_color("font_color26");
    }
    span:last-child {
      width: 200px;
      text-align: left;
      @include font_color("font_color29");
    }
  }
  .chart-main {
    padding: 0 24px;
    width: 100%;
    height: 300px;
  }
}
</style>
