import { render, staticRenderFns } from "./taskCenter.vue?vue&type=template&id=25513b4d&scoped=true"
import script from "./taskCenter.vue?vue&type=script&lang=js"
export * from "./taskCenter.vue?vue&type=script&lang=js"
import style0 from "./taskCenter.vue?vue&type=style&index=0&id=25513b4d&prod&lang=scss&scoped=true"
import style1 from "./taskCenter.vue?vue&type=style&index=1&id=25513b4d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25513b4d",
  null
  
)

export default component.exports