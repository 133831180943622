<template>
  <div
    style="width: 100%; height: 100%; background-color: #000710"
    class="box_live"
  >
    <Loading v-if="showLoading" />
    <div id="box">
      <div class="body">
        <div class="head">
          <div class="logo"></div>
          <div class="live_name">
            <div class="live_message">
              <a-avatar :src="infoData.cover_url" :size="44" />
              <div class="comment_content">
                <p>{{ infoData.nickname }}</p>
                <div class="content">{{ infoData.title }}</div>
              </div>
            </div>
          </div>
          <div class="watch_link">
            <!-- <a>
          <svg-icon :icon-class="'iPhone'" style="color:#CB6162"/>  
          手机看直播
          </a>-->
          </div>
          <div class="tips_left">
            <div>直播开始时间</div>
            <div>
              {{ infoData.start_time ? infoData.start_time.slice(0, 16) : "" }}
            </div>
          </div>
          <div class="tips_right">
            <div>
              <svg-icon icon-class="live" style="color: #cb6162" />直播中
            </div>
            <div>直播时长：{{ diffTimeText }}</div>
          </div>
        </div>
        <div class="content">
          <!-- 左侧内容 -->
          <div class="box_left">
            <div
              class="chart"
              v-for="(item, index) in lineChartList"
              :key="index"
            >
              <div class="chart-title">
                <div class="img_box">
                  <img src="../../assets/live_images/arrows.png" />
                </div>
                {{ item.name }}
              </div>
              <aside>
                <!-- <span style="color: #61c2c6; font-size: 10px">增量</span>
                <span style="color: #2a90f4; font-size: 10px">总量</span> -->
              </aside>
              <LiveLine
                :id="item.id"
                :box-style="{ width: '100', height: '80' }"
                :chart-data="item.chartDate"
                :ref="item.id"
              />
            </div>
          </div>
          <!-- 中心内容 -->
          <div class="center_box">
            <div class="fans">
              <!-- todoD -->
              <ul class="data_box" v-if="type_p == 'douyin'">
                <li v-for="(item, index) in fansDYList" :key="index">
                  <span>{{ item.name }}</span>
                  <span>{{ item.value || 0 }}</span>
                </li>
              </ul>
              <ul class="data_box" v-else>
                <li v-for="(item, index) in fansKSList" :key="index">
                  <span>{{ item.name }}</span>
                  <span>{{ item.value || 0 }}</span>
                </li>
              </ul>
            </div>
            <div class="live">
              <div class="live-box">
                <header class="topCar">
                  <div class="img_box">
                    <img src="../../assets/live_images/arrows.png" alt />
                  </div>
                  观众关注车型 TOP5
                </header>
                <div class="live-box-content">
                  <ul
                    class="hot_car"
                    v-if="hotCarList && hotCarList.length > 0"
                  >
                    <li v-for="(item, index) in hotCarList" :key="index">
                      <div class="content">
                        <div class="img_box">
                          <img :src="item.image" alt />
                        </div>
                        <p>{{ item.series }}</p>
                      </div>
                      <div class="progress">
                        <div class="progress_box">
                          <div
                            class="process_rate"
                            :style="{
                              'background-image':
                                index > 0
                                  ? index > 2
                                    ? 'linear-gradient(270deg, #005B8D 0%, rgba(0,89,136,0.00) 100%)'
                                    : 'linear-gradient(270deg, #007E8F 0%, rgba(0,126,139,0.00) 100%)'
                                  : 'linear-gradient(270deg, #66131F 0%, rgba(227,73,84,0.00) 100%)',
                              width: item.rate + '%',
                            }"
                          ></div>
                          <img
                            src="../../assets/live_images/rateA.png"
                            v-if="index == 0"
                          />
                          <img
                            src="../../assets/live_images/rateB.png"
                            v-if="index >= 1 && index <= 2"
                          />
                          <img
                            src="../../assets/live_images/rateC.png"
                            v-if="index > 2"
                          />
                        </div>
                        <div class="outside_tips">{{ item.count }}</div>
                      </div>
                    </li>
                  </ul>
                  <div
                    v-else
                    class="font-color3"
                    style="padding-top: 100px; text-align: center"
                  >
                    暂无数据
                  </div>
                </div>
              </div>
              <!-- 实时直播 -->
              <div class="video_box">
                <section style="width: 100%; height: 100%">
                  <video
                    id="live-video"
                    class="video-js vjs-default-skin"
                    style="
                      width: calc(100% - 2px);
                      height: calc(100% - 12px);
                      border-radius: 10px;
                    "
                    autoplay
                    muted
                    controls
                    ref="liveVideo"
                    preload="auto"
                  ></video>
                </section>
              </div>
              <div class="live-box">
                <div class="live-box-right">
                  <header class="comment_header">
                    <div class="img_box">
                      <img src="../../assets/live_images/arrows.png" alt />
                    </div>
                    评论
                  </header>
                  <div class="live-box-content">
                    <!-- <div class="date">{{liveRecord.publish_time?liveRecord.publish_time.slice(5,10):''}}</div> -->
                    <ul class="comment_lists">
                      <transition-group name="list-complete" tag="p">
                        <li
                          v-for="item in commentList"
                          :key="item.id"
                          class="list-complete-item"
                        >
                          <a-avatar :src="item.avatar" />
                          <div class="comment_content">
                            <p>
                              {{ item.name
                              }}<span
                                style="
                                  margin-left: 20px;
                                  color: #cb6162;
                                  font-weight: bold;
                                "
                                v-if="item.isBlack"
                                >黑粉</span
                              >
                            </p>
                            <div class="comment">{{ item.content }}</div>
                          </div>
                        </li>
                      </transition-group>
                    </ul>
                  </div>
                </div>
                <div class="live-box-right">
                  <header class="topCar">
                    <div class="img_box">
                      <img src="../../assets/live_images/arrows.png" alt />
                    </div>
                    禁言列表
                  </header>
                  <div class="live-box-content">
                    <!-- <div class="date">{{liveRecord.publish_time?liveRecord.publish_time.slice(5,10):''}}</div> -->
                    <ul class="comment_lists">
                      <transition-group name="list-complete" tag="p">
                        <li
                          v-for="(item, index) in forbiddenData"
                          :key="index"
                          class="list-complete-item"
                        >
                          <a-avatar
                            :src="
                              item.avatar ||
                              require('@/assets/img/defult-avatar.png')
                            "
                          />
                          <div class="comment_content">
                            <p>
                              {{ item.nickname
                              }}<span style="margin-left: 20px">{{
                                item.ctime
                              }}</span>
                            </p>
                            <div class="comment">{{ item.content }}</div>
                          </div>
                        </li>
                      </transition-group>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右侧内容 -->
          <div class="box_right">
            <div class="chart">
              <div class="chart-title">
                <div class="img_box">
                  <img src="../../assets/live_images/arrows.png" alt />
                </div>
                性别分布
              </div>
              <div
                v-if="!genderList"
                class="font-color3"
                style="text-align: center; padding-top: 40px"
              >
                暂无数据
              </div>
              <PieChart
                :id="'fansGenderChart'"
                :box-style="{ width: '100', height: '90' }"
                :pie-data="genderList"
                v-else
              />
            </div>
            <div class="chart">
              <div class="chart-title">
                <div class="img_box">
                  <img src="../../assets/live_images/arrows.png" alt />
                </div>
                地域分布
              </div>
              <MapChart
                :id="'MapChart'"
                :box-style="{ width: '100', height: '90' }"
                :chart-data="mapChartList"
              />
            </div>
            <div class="chart">
              <div class="chart-title">
                <div class="img_box">
                  <img src="../../assets/live_images/arrows.png" alt />
                </div>
                省份分布
              </div>
              <table class="city_table_rate">
                <tr>
                  <th>省份</th>
                  <th>占比</th>
                </tr>
                <!-- <tbody> -->
                <tr
                  v-for="(item, index) in provinceSort"
                  :key="index"
                  class="tr_overflow"
                >
                  <td>{{ item.province }}</td>
                  <td>
                    {{ filtrate.disposeDecimal(item.count * 100, 2, true) }}%
                  </td>
                </tr>
                <!-- </tbody> -->
              </table>
            </div>
            <div class="chart">
              <div class="chart-title">
                <div class="img_box">
                  <img src="../../assets/live_images/arrows.png" alt />
                </div>
                评论词云
              </div>
              <WordCould
                :id="'WordCouldNOW'"
                :box-style="{ width: '100', height: '90' }"
                :chart-data="hotWordList"
              />
            </div>
          </div>
        </div>
        <div class="foot"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserId } from "@/utils/auth";
import LiveLine from "./charts/LiveLine";
import PieChart from "./charts/PieChart";
import WordCould from "./charts/WordCould";
import MapChart from "./charts/MapChart";
import Paho from "@/utils/mqttws31.min.js";
import videojs from "video.js";
import "videojs-contrib-hls";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import Loading from "@/components/Loading";
import { createNamespacedHelpers } from "vuex";
import Progress from "@/components/Item/Progress";
import MqttRemoval from "@/utils/mqttRemoval";
import blackList from "@/utils/balckList";
import _http from "@/api/fullScreenLive.js";
import indexVue from "../topics/observe/index.vue";
const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers("fullScreenLive");

export default {
  components: {
    LiveLine,
    WordCould,
    PieChart,
    MapChart,
    Loading,
    Progress,
  },
  data() {
    return {
      diffTimeText: "",
      commentList: [],
      long_timer: "",
      client: null, // 创建一个客户端实例
      showLoading: true,
      timer: null,
      screenWidth: 1920,
      timerWl: null,
      removal: null,
      // blackList: [],
      room_id: this.$route.query.room_id,
      videoUrl: "",
      hotCarList: [], // 车型top5
      mapChartList: [], // 地域分布
      provinceSort: [], // 省份排名
      hotWordList: [], // 评论词云
      genderList: null, // 性别分布
      lineChartList: [
        {
          name: "观看人数趋势",
          chartDate: {
            name: ["总量"],
            xAxis: [],
            yAxis: [[], []],
          },
          id: "lineA",
        },
        {
          name: "粉丝变化趋势",
          chartDate: {
            name: ["总量"],
            xAxis: [],
            yAxis: [[], []],
          },
          id: "lineB",
        },
        {
          name: "评论人数趋势",
          chartDate: {
            name: ["总量"],
            xAxis: [],
            yAxis: [[], []],
          },
          id: "lineC",
        },
        {
          name: "送礼数趋势",
          chartDate: {
            name: ["总量"],
            xAxis: [],
            yAxis: [[], []],
          },
          id: "lineD",
        },
      ], // 左侧折线统计图
      fansDYList: [
        {
          name: "累计观看人数",
          value: 0,
        },
        {
          name: "粉丝变化",
          value: 0,
        },
        {
          name: "观看人数峰值",
          value: 0,
        },
        {
          name: "直播前粉丝数",
          value: 0,
        },
      ],
      fansKSList: [
        {
          name: "累计观看人数",
          value: 0,
        },
        {
          name: "当前在线人数",
          value: 0,
        },
        {
          name: "观看人数峰值",
          value: 0,
        },
        {
          name: "累计点赞数",
          value: 0,
        },
      ],
      infoData: {}, //基本信息
      type_p: this.$route.query.type,
      player: null,
    };
  },
  computed: {
    ...mapState({
      mqttConfigData: (state) => state.mqttConfigData, // mqtt
      forbiddenData: (state) => state.forbiddenData, // 禁言
      current_no: (state) => state.current_no, // 禁言
      page_size_f: (state) => state.page_size_f, // 禁言
      isHaveForbidden: (state) => state.isHaveForbidden, // 禁言
      isFirstForbidden: (state) => state.isFirstForbidden, // 禁言
    }),
  },
  created() {
    this.changeState({ data: [], name: "forbiddenData" });
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timerWl) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timerWl = true;
        let that = this;
        let screen_timer = setTimeout(function () {
          document.getElementById("box").style.transform = `scale(${
            that.screenWidth / 1920
          })`;
          clearTimeout(screen_timer);
          that.timerWl = false;
        }, 400);
      }
    },
    mqttConfigData() {
      this.removal = new MqttRemoval();
      this.MQTTconnect();
    },
    isHaveForbidden(val) {
      console.log("禁言", val);
      if (val) {
        this.loadForbiddenList();
      }
    },
  },
  mounted() {
    let clear = setTimeout(() => {
      this.showLoading = false;
      clearTimeout(clear);
    }, 1500);
    // 监听屏幕变化
    this.resizeWidth();
    window.addEventListener(
      "resize",
      () => {
        this.resizeWidth();
      },
      false
    );
    // 禁言列表定时
    function Timer() {
      this.timeID = null;
      this.func = null;
    }

    Timer.prototype.repeat = function (func, ms) {
      if (this.func === null) {
        this.func = func;
      }
      // 确保一个 Timer 实例只能重复一个 func
      if (this.func !== func) {
        return;
      }
      this.timeID = setTimeout(() => {
        func();
        this.repeat(func, ms);
      }, ms);
    };
    Timer.prototype.clear = function () {
      clearTimeout(this.timeID);
    };
    this.timer = new Timer();

    // 获取视频链接
    this.getVideoUrl();
    this.getHistoryComment();
  },
  methods: {
    // 屏幕变化设置比
    resizeWidth() {
      this.screenWidth = document.body.clientWidth;
    },
    format(percentage) {
      return this.filtrate.disposeDecimal(percentage, 2, true);
    },
    ...mapActions(["getMqttConfig", "getInfo", "getForbiddenList"]),
    ...mapMutations(["changeState"]),
    // 添加直播监听，mqtt配置
    addLiveMonitor() {
      // platformType :快手2，抖音：1
      let data = {
        room_id: this.room_id,
        platform_type: this.type_p == "kuaishou" ? 2 : 1,
      };
      // 添加直播监听
      _http.getMqttLiveRoom(data).then((res) => {
        console.log(res);
      });
      let params = {
        platform_type: this.type_p == "kuaishou" ? 2 : 1,
        author_id: this.$route.query.author_id,
        device_id: getUserId(),
      };
      this.getMqttConfig(params);
    },
    // 历史评论接口
    getHistoryComment() {
      // platformType :快手2，抖音：1
      let data = {
        room_id: this.room_id,
        platform_type: this.type_p == "kuaishou" ? 2 : 1,
      };
      // 添加直播监听
      _http.getHistoryComment(data).then((res) => {
        console.log(res);
        if (!res.data.data || res.data.data.length == 0) {
          this.commentList = [];
          return false;
        }
        this.commentList = res.data.data.map((item) => {
          return {
            id: `${parseInt(Math.random() * 10000)}${Date.parse(new Date())}`,
            name: item.userName,
            content: item.content,
            avatar: item.avatar
              ? item.avatar
              : require("../../assets/img/defult-avatar.png"),
            isBlack: false,
          };
        });
      });
    },
    // 获取直播视频链接 ，获取基本信息
    getVideoUrl() {
      let params = {
        author_id: this.$route.query.author_id,
        room_id: this.room_id
      }
      _http.getLiveVideo(params, this.type_p).then((res) => {
        if (res.data.code == 0) {
          this.infoData = res.data.data || {};
          if (this.infoData.finish || !res.data.data) {
            this.info();
            return false;
          }
          // 获取初始数据 （确认正在开播的时候再调取相关数据）
          this.getInitDatas();
          this.timer.repeat(this.loadForbiddenList, 30000);
          this.loadForbiddenList();

          this.diffTimeText = this.diffTime(res.data.data.start_time);
          this.long_timer = setInterval(() => {
            this.diffTimeText = this.diffTime(res.data.data.start_time);
          }, 1000 * 60);
          if (flvjs.isSupported()) {
            let videoElement = document.getElementById("live-video");
            this.player = flvjs.createPlayer({
              type: "flv",
              url: this.returnNewUrl(res.data.data.play_url_flv264_old),
            });
            this.player.attachMediaElement(videoElement);
            this.player.load();
            this.player.play();
          }

          // this.player = videojs("live-video");
          // this.player.src({
          //   src: res.data.data.play_url.replace(/^http/, "https"),
          // });
        }
      });
    },
    // 初始数据-todoD
    getInitDatas() {
      let params = {
        room_id: this.room_id,
        platform_type: this.type_p == "douyin" ? 1 : 2,
      };
      _http
        .getInitDatas({ params })
        .then((res) => {
          if (res.data.data.length) {
            res.data.data.forEach((el) => {
              this.onMessageArrived(el);
            });
          }
          this.addLiveMonitor();
        })
        .catch((res) => {
          console.log("获取历史数据出错", res);
          this.addLiveMonitor();
        });
    },
    // 禁言列表
    loadForbiddenList() {
      let params = {
        current: this.current_no || 1,
        page_size: this.page_size_f || 20,
        live_room_id: this.room_id,
        platform: this.type_p,
      };
      if (!this.isFirstForbidden && this.forbiddenData.length > 0) {
        params.from_time = this.forbiddenData[0].ctime;
      }
      this.getForbiddenList(params);
    },
    // getLineData(){
    //   this.getCommentTrend();
    //   this.getLiveRoomTrend();
    // },
    // 创建mqtt实例，并连接
    MQTTconnect() {
      this.client = new Paho.MQTT.Client(
        this.mqttConfigData.endPoint,
        443,
        this.mqttConfigData.clientId
      );
      this.client.connect({
        userName: this.mqttConfigData.userName, //连接账号
        password: this.mqttConfigData.password, //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: (message) => {
          setTimeout(this.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4,
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },

    //当客户端连接
    onConnect() {
      console.log("onConnect");
      //建立连接后，进行订阅并发送消息。
      this.client.subscribe(
        // "afanti_live/bulletAndStatistics/3/123",
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic,
        { qos: 0 }
      );
    },

    // 当客户端失去连接时调用
    onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    },
    /**当消息到达时
    /* type 1001:评论，1004:礼物，1005：进入直播间，1006:关注,1007:点赞；
    /* 2001:在线数据，观众关注车型top5；2002:观众地区分布的接口格式；2003:评论热词；2004:观众性别分布;
    /* 2005:观看人数趋势总量、观看人数趋势增量；2006:观看人数趋势总量、观看人数趋势增量；2007:评论人数趋势总量、评论人数趋势增量；
    /* 2008:评论人数趋势总量、评论人数趋势增量；
    /* 2009:累计观看人数、粉丝变化、观看人数峰值；
    / * 抖音无法获取礼物数**/
    onMessageArrived(message) {
      let newJson = {};
      if (message.payloadString) {
        newJson = JSON.parse(message.payloadString);
      } else {
        newJson = message;
      }
      console.log("onMessageArrived:", newJson);
      // let bool = this.removal.isRemoval(newJson); // 判断重复推送
      // let comment_type = ["1001", "1004", "1005", "1006", "1007"];
      let comment_type = ["1001", "1004", "1006"];
      let commentData = "";
      const that = this;
      let result = false;
      // 判断类型
      switch (newJson.type) {
        case "1001":
          // if (bool) {
          result = blackList.some(function (item) {
            if (item.name == newJson.data.userName) {
              return true;
            }
          });
          commentData = newJson.data.content;
          // }
          break;
        case "1004":
          commentData = `${newJson.data.giftName}`;
          break;
        // case "1005":
        //   result = blackList.some(function (item) {
        //     if (item.name == newJson.data.name) {
        //       return true;
        //     }
        //   });
        //   commentData = "进入直播间";
        //   break;
        case "1006":
          commentData = "点了关注";
          break;
        // case "1007":
        //   commentData = "点了赞";
        //   break;
        case "2001":
          this.hotCarList = this.setHotCar(newJson.data);
          break;
        case "2002":
          this.provinceSort = newJson.data.slice(0, 4);
          this.mapChartList = newJson.data;
          break;
        case "2003":
          this.hotWordList = newJson.data;
          break;
        case "2004":
          this.setGender(newJson.data);
          break;
        case "2005":
          this.setLeftLine(newJson, 0, "user_count");
          // this.lineChartList[0].chartDate.xAxis.push(newJson.data.time);
          // this.lineChartList[0].chartDate.yAxis[0].push(
          //   newJson.data.user_count
          // );
          // this.$refs.lineA[0].initChart();
          break;
        case "2006":
          this.setLeftLine(newJson, 1, "follow_count_increment");

          // this.lineChartList[1].chartDate.xAxis.push(newJson.data.time);
          // this.lineChartList[1].chartDate.yAxis[0].push(
          //   newJson.data.follow_count
          // );
          // this.$refs.lineB[0].initChart();
          break;
        case "2007":
          this.setLeftLine(newJson, 2, "comment_count");
          // this.lineChartList[2].chartDate.xAxis.push(newJson.data.time);
          // this.lineChartList[2].chartDate.yAxis[0].push(
          //   newJson.data.comment_count
          // );
          // this.$refs.lineC[0].initChart();
          break;
        case "2008":
          this.setLeftLine(newJson, 3, "gift_count");
          // this.lineChartList[3].chartDate.xAxis.push(newJson.data.time);
          // this.lineChartList[3].chartDate.yAxis[0].push(
          //   newJson.data.gift_count
          // );
          // this.$refs.lineD[0].initChart();
          break;
        case "2009":
          if (this.type_p == "kuaishou") {
            this.fansKSList[1].value = newJson.data.online_count;
            this.fansKSList[3].value = newJson.data.digg_count;
            this.fansKSList[0].value = newJson.data.accumulated_watching_count;
            this.fansKSList[2].value = newJson.data.watching_peak_count;
          } else {
            this.fansDYList[1].value = newJson.data.fans_change;
            this.fansDYList[3].value = newJson.data.fans_count;
            this.fansDYList[0].value = newJson.data.accumulated_watching_count;
            this.fansDYList[2].value = newJson.data.watching_peak_count;
          }
          break;
        case "3001":
          this.info();
          break;
        default:
          break;
      }
      // 添加弹幕信息
      if (comment_type.indexOf(newJson.type) != -1) {
        if (this.commentList.length > 100) {
          _.debounce(() => {
            this.commentList.splice(50, 49);
          }, 2000);
        }
        let comment_bool = false
        for (let i = 0; i < this.commentList.length; i++) {
          if (newJson.data.msgId == this.commentList[i].msgId) {
            comment_bool = true
          }
        }
        if (!comment_bool) {
          this.commentList.unshift({
            id: `${parseInt(Math.random() * 10000)}${Date.parse(new Date())}`,
            msgId: newJson.data.msgId,
            name: newJson.data.userName,
            content: commentData,
            avatar: newJson.data.avatar
              ? newJson.data.avatar
              : require("../../assets/img/defult-avatar.png"),
            isBlack: result,
          });
        }
      }
    },
    returnNewUrl(url) {
      if (url) {
        try {
          let newUrl = url.replace(/^http/, "https")
          if (this.type_p == 'douyin') {
            let array = newUrl.split('.flv')
            newUrl = array[0] + '_sd.flv'
          }
          return newUrl
        }
        catch(err) {
          this.$message.warning(err)
        }
      } else {
        this.$message.warning('未获取到当前直播地址')
      }
    },
    // 去重
    unique(arr, val) {
      const res = new Map();
      return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
    },

    timeSortFun() {
      return function (a, b) {
        var value1 = moment(a.time).valueOf();
        var value2 = moment(b.time).valueOf();
        return value1 - value2;
      };
    },
    xSortFun() {
      return function (a, b) {
        var value1 = moment(a).valueOf();
        var value2 = moment(b).valueOf();
        return value1 - value2;
      };
    },
    setLeftLine(obj, index, type) {
      console.log(obj);
      // 初始加载
      if (obj && obj.datalist && obj.datalist.length > 0) {
        let newData = [];
        newData = this.unique(obj.datalist, "time");
        newData.sort(this.timeSortFun());

        this.lineChartList[index].chartDate.xAxis = newData.map((item) => {
          return moment(item.time).format("YYYY-MM-DD HH:mm:ss");
        });
        this.lineChartList[index].chartDate.yAxis[0] = newData.map((item) => {
          return item[type];
        });
      }
      // mqtt加载
      if (obj && obj.data) {
        let setIndex = this.lineChartList[index].chartDate.xAxis.indexOf(
          obj.data.time
        );
        if (setIndex < 0) {
          this.lineChartList[index].chartDate.xAxis.push(obj.data.time);
          this.lineChartList[index].chartDate.yAxis[0].push(obj.data[type]);

          let x_b = 0; //设置用来调换位置的值
          let x_a = this.lineChartList[index].chartDate.xAxis; //冒泡排序
          let y_b = 0; //设置用来调换位置的值
          let y_a = this.lineChartList[index].chartDate.yAxis[0]; //冒泡排序
          for (var i = 0; i < x_a.length; i++) {
            for (var j = 0; j < x_a.length; j++) {
              if (moment(x_a[j]).valueOf() > moment(x_a[j + 1]).valueOf()) {
                x_b = x_a[j];
                x_a[j] = x_a[j + 1];
                x_a[j + 1] = x_b;
                y_b = y_a[j];
                y_a[j] = y_a[j + 1];
                y_a[j + 1] = y_b;
              }
            }
          }
        }
      }
      // 渲染图表
      if (index == 0) {
        this.$refs.lineA[0].initChart();
      } else if (index == 1) {
        this.$refs.lineB[0].initChart();
      } else if (index == 2) {
        this.$refs.lineC[0].initChart();
      } else if (index == 3) {
        this.$refs.lineD[0].initChart();
      }
    },

    // 下播信息弹窗
    info() {
      const h = this.$createElement;
      this.$info({
        title: "当前直播已结束",
        content: h("div", {}, [h("p", "请返回上一页面查看其他直播。")]),
        onOk() {},
      });
    },
    // 处理top5
    setHotCar(data) {
      let newData = data;
      let count = data.map((item) => item.count);
      count.sort(function (a, b) {
        return b - a;
      });
      for (let i = 0; i < data.length; i++) {
        newData[i].image =
          process.env.VUE_APP_OSS_LIVE_HOST + "/" + data[i].image;
        newData[i].rate = (data[i].count / count[0]) * 80;
        newData[i].count = this.filtrate.disposeDecimal(
          data[i].count,
          0,
          false
        );
        newData[i].series = data[i].series;
      }
      return newData.slice(0, 5);
    },
    // 处理男女分布
    setGender(data) {
      if (!data.count.length || !data.gender.length) {
        this.genderList = null;
        return;
      }
      let manCount = 0;
      let grilCount = 0;
      for (let i = 0; i < data.gender.length; i++) {
        if (data.gender[i] == 1) {
          manCount = data.count[i];
        } else if (data.gender[i] == 2) {
          grilCount = data.count[i];
        }
      }
      let total = manCount + grilCount;
      let man = (manCount / total).toFixed(2);
      let girl = (grilCount / total).toFixed(2);
      this.genderList = {
        gender: { percentage: [man, girl], desc: ["男", "女"] },
      };
      console.log("genderList", this.genderList);
    },
    diffTime(time) {
      let startTime = this.$moment(time);
      let currentTime = this.$moment();
      let diffTime = currentTime.diff(startTime, "minute"); //计算相差的分钟数
      let h = Math.floor(diffTime / 60); //相差的小时数
      let mm = diffTime % 60; //计算相差小时后余下的分钟数
      return `${h}小时${mm}分钟`;
    },
    flv_destroy() {
      this.player.pause();
      this.player.unload();
      this.player.detachMediaElement();
      this.player.destroy();
      this.player = null;
    },
  },
  beforeDestroy() {
    this.timer.clear();
    clearInterval(this.long_timer);
    this.client.unsubscribe(
      // "afanti_live/bulletAndStatistics/3/123"
      this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
    );
    this.client.disconnect(); // 断开连接
    this.client = null;
    // 销毁video实例
    const videoDom = this.$refs.liveVideo;
    videojs(videoDom).dispose();
    this.commentList = [];
    window.removeEventListener("resize", this.resizeWidth);
    this.flv_destroy();
  },
};
</script>
<style lang="scss" scoped>
.box_live {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
@mixin hideSCroll {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
#box {
  width: 1920px;
  height: 1074px;
  transform-origin: left top;
  background-color: #000710;
}
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-50px);
}
//  .list-complete-leave-to
// /* .list-complete-leave-active for below version 2.1.8 */ {
//   opacity: 0;
//   transform: translateY(-30px);
// }
.list-complete-leave-active {
  position: absolute;
}
.body {
  width: 100%;
  height: 100%;
  background: url("../../assets/live_images/bgd.png");
  background-size: 100% 100%;
  padding: 20px;
  .head {
    height: 11%;
    background: url("../../assets/live_images/topbgd.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
    .logo {
      flex: 1;
      margin: 10px 40px;
      width: 117px;
      height: 39px;
      background: url("../../assets/live_images/logo.png") no-repeat;
      background-size: 100% 100%;
    }
    .live_name {
      // width: 1130px;
      flex: 8;
      .live_message {
        display: flex;
        // align-items: center;
        justify-content: center;
        color: #ffffff;
        padding-top: 10px;
        .comment_content {
          padding-left: 10px;
          p {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .content {
            font-size: 14px;
            opacity: 0.4;
            margin: 5px 0;
          }
        }
      }
    }
    .watch_link {
      flex: 1;
      font-size: 12px;
      color: #f5f5f6;
      margin: 20px 0;
    }
    .tips_left {
      position: absolute;
      left: 28%;
      top: 28%;
      font-size: 12px;
      color: #8b8e92;
      opacity: 0.6;
    }
    .tips_right {
      position: absolute;
      right: 28%;
      top: 28%;
      font-size: 12px;
      color: #8b8e92;
      opacity: 0.6;
      text-align: right;
    }
  }
  .foot {
    height: 2%;
    background: url("../../assets/live_images/foot.png") no-repeat;
    background-size: 100% 100%;
  }
  .content {
    display: flex;
    height: 85%;
    width: 100%;
    .box_left,
    .box_right {
      flex: 2;
      display: flex;
      flex-direction: column;
      height: 92%;
      margin: 4% 1%;
      .chart {
        flex: 1;
        background: url("../../assets/live_images/chartBgds.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 10px;
        position: relative;
        &-title {
          font-size: 14px;
          // position: absolute;
          // top:-1%;
          // left:15%;
          vertical-align: text-top;
          margin-left: 6%;
          margin-top: -7px;
          color: #cb6162;
          display: flex;
          .img_box {
            height: 12px;
            width: 12px;
            img {
              height: 80%;
              width: 100%;
            }
          }
        }
        aside {
          display: flex;
          justify-content: space-between;
          padding: 5px 20px;
        }
      }
    }

    .center_box {
      height: 100%;
      flex: 6;
      .fans {
        width: 94%;
        height: 20%;
        margin: 2% 3%;
        background: url("../../assets/live_images/cntbgd.png") no-repeat;
        background-size: 100% 100%;
        .data_box {
          width: 80%;
          margin: 0 10%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            // flex: 1;
            display: flex;
            flex-direction: column;
            // align-items: center;
            :first-child {
              color: #fff;
              font-size: 14px;
              padding-bottom: 5px;
            }
            :last-child {
              font-size: 36px;
              color: #17ecff;
              text-shadow: 0 2px 6px rgba(172, 250, 255, 0.76);
            }
          }
        }
      }
      .live {
        width: 100%;
        height: 74%;
        display: flex;
        .video_box {
          flex: 1;
          margin: 0 1%;
          background: url("../../assets/live_images/video.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-box {
          flex: 1;
          padding: 0 10px;
          height: 100%;
          &-right {
            height: 50%;
            overflow: hidden;
          }
          .topCar {
            background: url("../../assets/live_images/bgd17.png");
          }
          .comment_header {
            background: url("../../assets/live_images/bgd18.png");
          }
          header {
            width: 80%;
            height: 28px;
            line-height: 28px;
            color: #cb6162;
            padding-left: 10px;
            // border-bottom: 1px solid #00b9c5;
            // border-left:1px solid #00b9c5;
            display: flex;
            .img_box {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
            img {
              height: 100%;
              width: 100%;
            }
          }
          &-content {
            margin-top: -1px;
            height: 95%;
            background: url("../../assets/live_images/centent.png");
            background-size: 100% 100%;
            padding-left: 10px;
            .date {
              font-size: 12px;
              color: #787980;
              text-align: center;
              padding: 10px 0;
            }
            .comment_lists {
              height: 100%;
              overflow-y: auto;
              display: block;
              @include hideSCroll();
              padding: 20px 0;
              // transition: all 1s;
              li {
                display: flex;
                font-size: 12px;
                color: #fff;
                padding-bottom: 20px;
                .comment_content {
                  flex: 1;
                  padding-left: 15px;
                  p {
                    opacity: 0.6;
                    margin-bottom: 4px;
                  }
                  .comment {
                    border: 1px solid #2b2b2e;
                    border-radius: 0 8px 8px 8px;
                    // height: 24px;
                    line-height: 16px;
                    padding: 4px;
                  }
                }
              }
            }
            .hot_car {
              // width: 100%;
              height: 100%;
              padding-bottom: 5%;
              display: block;
              li {
                height: 19%;
                padding: 4% 0;
                .content {
                  display: flex;
                  align-items: center;
                  .img_box {
                    height: 70%;
                    width: 30%;
                    margin-right: 20px;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  p {
                    font-size: 14px;
                    color: #ffffff;
                  }
                }
                .progress {
                  width: 95%;
                  display: flex;

                  .progress_box {
                    flex: 8;
                    height: 13px;
                    border: 1px solid rgba(111, 113, 121, 0.3);
                    border-radius: 5px;
                    display: flex;
                    .process_rate {
                      height: 10px;
                    }
                    img {
                      height: 10px;
                      margin-left: -2px;
                    }
                  }
                  .outside_tips {
                    padding-left: 4px;
                    font-size: 12px;
                    line-height: 12px;
                    color: #fff;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .city_table_rate {
    width: 90%;
    margin: 5% auto;
    height: 85%;
    tr {
      display: flex;
      width: 100%;
      height: 20%;
      th {
        opacity: 0.19;
        background: linear-gradient(
          180deg,
          rgba(0, 235, 255, 0.86) 0%,
          rgba(0, 235, 255, 0.52) 52%,
          rgba(0, 235, 255, 0.81) 100%
        );
      }
      td {
        border-top: 1px solid #252733;
      }
      th,
      td {
        height: 100%;
        flex: 1;
        font-size: 12px;
        color: #ffffff;
        opacity: 0.56;
        // padding: 2% 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>