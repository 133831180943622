import { render, staticRenderFns } from "./TabsContent4.vue?vue&type=template&id=7641b757&scoped=true"
import script from "./TabsContent4.vue?vue&type=script&lang=js"
export * from "./TabsContent4.vue?vue&type=script&lang=js"
import style0 from "./TabsContent4.vue?vue&type=style&index=0&id=7641b757&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7641b757",
  null
  
)

export default component.exports