<template style="width: 100%; height: 100%">
  <div :id="id" :style="defaultStyle"></div>
</template>

<script>
import echarts from "echarts";
import resize from "@/mixins/resize";
export default {
  name: "MyChart",
  props: {
    options: {
      type: Object,
    },
    id: {
      type: String,
    },
    defaultStyle: {
      type: Object,
      default: () => {
        return {
          width: "100%",
          height: "100%",
        };
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  created() {
    // console.log('my chart cerated',this.options)
    if (this.options) {
      setTimeout(() => {
        this.initChart();
      }, 100);
    }
  },
  computed: {},
  watch: {
    options(val) {
      // console.log('my chart ready',val)
      if (val) {
        setTimeout(() => {
          this.initChart();
        }, 100);
      }
    },
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        let dom = document.getElementById(this.id);
        if (dom) {
          this.chart = echarts.init(dom);
          this.chart.setOption(this.options, true);
          window.onresize = this.chart.resize;
        }
      });
    },
    resizeChart() {
      this.chart.resize();
    },
  },
  mixins: [resize],
};
</script>
<style lang="less" scope>
</style>
