var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"placement":"right","width":"378","closable":false,"visible":_vm.visible},on:{"close":_vm.onClose}},[_c('div',{staticClass:"drawer-container"},[_c('div',{staticClass:"drawer-title"},[_c('span',[_vm._v("切换活动")]),_c('a-icon',{staticClass:"close-icon",attrs:{"type":"close"},on:{"click":_vm.onClose}})],1),_c('div',{staticClass:"drawer-main"},[_c('a-spin',{staticStyle:{"width":"100%"},attrs:{"spinning":_vm.spinning}},_vm._l((_vm.activityList),function(item,index){return _c('div',{directives:[{name:"track",rawName:"v-track:click",value:({
            action: '切换活动',
            activityId: item.id,
            activityName: item.activityName,
          }),expression:"{\n            action: '切换活动',\n            activityId: item.id,\n            activityName: item.activityName,\n          }",arg:"click"}],key:index,class:[
            'activity-item',
            _vm.currentItem.id == item.id ? 'active' : '',
          ],on:{"click":function($event){return _vm.changeActivity(item)}}},[_c('div',{staticClass:"activity-item-title"},[_vm._v(" "+_vm._s(item.activityName || "-")+" ")]),_c('div',{staticClass:"activity-item-date"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(item.startTime || "-")+" 至 "+_vm._s(item.endTime || "-"))]),(item.activityStatus == 2)?_c('span',{staticClass:"status icon1"},[_vm._v(_vm._s(_vm.returnTagText(item)))]):(item.activityStatus == 3)?_c('span',{staticClass:"status icon2"},[_vm._v(_vm._s(_vm.returnTagText(item)))]):_c('span',{staticClass:"status icon3"},[_vm._v(_vm._s(_vm.returnTagText(item)))])])])}),0)],1),_c('div',{staticClass:"drawer-tools"},[_c('a-pagination',{attrs:{"size":"small","pageSize":_vm.pageSize,"total":_vm.total},on:{"change":_vm.pageChange},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }