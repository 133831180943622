<template>
  <a-spin class="bottom-wrap" :spinning="loading">
    <!-- <div class="bottom-wrap-tools"> -->
      <!-- <a-select
        class="aft-select default"
        v-model="regionValue"
        placeholder="选择大区"
        style="width: 122px; margin-right: 8px"
        @change="regionChange"
      >
        <a-select-option value="" v-if="isAllRegion">
          不限大区
        </a-select-option>
        <a-select-option
          v-for="item in regionList || []"
          :key="item.id"
          :value="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select> -->
      <!-- <a-range-picker
        v-model="dateValue"
        @calendarChange="calendarChange"
        class="aft-range-picker default"
        :disabled-date="disabledDate"
        @change="dateChange"
        locale="zh-cn"
        format="YYYY-MM-DD"
        style="width: 246px;"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-range-picker> -->
    <!-- </div> -->
    <div class="bottom-wrap-card">
      <div v-for="item in cardData" :key="item.title">
        <IconTitle :icon="item.icon" :title="item.title" :type="2" />
        <div class="right">
          <HoverCard
            v-for="ci in item.children"
            :ref="`ac-${ci.name}`"
            :key="ci.name"
            :background="item.background"
            :color="item.color"
            :icon="item.cIcon"
            :name="ci.name"
            :value="ci.value"
            :tooltip-code="ci.tooltipCode"
          />
        </div>
      </div>
      <a-spin :spinning="spinning" class="spin" v-show="spinning"> </a-spin>
    </div>
  </a-spin>
</template>

<script>
const cardData = [
  {
    title: "门店",
    icon: "ti-store",
    background: "linear-gradient(270deg, #7FDB1E 0%, #C5FF88 100%)",
    color: "#284608",
    cIcon: "ci-store-g",
    children: [
      // {
      //   name: "活动期间增粉",
      //   key: "addFansCount",
      //   value: "-"
      // },
      {
        name: "参与门店",
        key: "storeCount",
        value: "-",
        tooltipCode: 'hdzb03'
      }
    ]
  },
  {
    title: "视频",
    icon: "ti-video",
    background: "linear-gradient(90deg, #B795FF 0%, #815CFF 100%)",
    color: "#2C1A6A",
    cIcon: "ci-video-p",
    children: [
      {
        name: "视频发布",
        key: "awemePublishCount",
        value: "-",
        tooltipCode: ''
      },
      {
        name: "视频播放",
        key: "awemeWatchCount",
        value: "-",
        tooltipCode: ''
      },
      {
        name: "视频互动",
        key: "awemeInteractCount",
        value: "-",
        tooltipCode: ''
      }
    ]
  },
  {
    title: "直播",
    icon: "ti-live",
    background: "linear-gradient(90deg, #F59264 0%, #E75934 100%)",
    color: "#461A0E",
    cIcon: "ci-live-r",
    children: [
      {
        name: "直播场次",
        key: "liveCount",
        value: "-",
        tooltipCode: ''
      },
      {
        name: "直播观看",
        key: "liveWatchCount",
        value: "-",
        tooltipCode: ''
      },
      {
        name: "直播互动",
        key: "liveInteractCount",
        value: "-",
        tooltipCode: ''
      }
    ]
  }
];
import api from "@/api/activityOverview";
import IconTitle from "@/components/IconTitle/IconTitle";
import HoverCard from "@/components/AsyncCard/HoverCard";
import deepClone from "@/utils/clone-utils";

export default {
  name: "BottomWrap",
  components: {
    IconTitle,
    HoverCard
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    currentItem: {
      type: Object,
      default: () => {
        return {};
      }
    },
    cardData: {
      type: Array
    }
  },
  data() {
    return {
      // cardData,
      spinning: false,
      // 大区下拉相关
      regionList: [],
      regionValue: undefined,
      isAllRegion: false,
      // 日期相关
      dateValue: []
    };
  },
  computed: {},
  created() {},
  // watch: {
  //   currentItem(val) {
  //     if (val && val.id) {
  //       this.initData();
  //     }
  //   }
  // },
  methods: {
    // 加载当前页面所有接口
    async initData() {
      this.setDateRange();
      const region_result = await this.getAreaInfoByActivity();
      this.setRegionList(region_result);
    },

    setDateRange() {
      console.log(this.currentItem);
      this.$nextTick(() => {
        this.dateValue = [
          moment(this.currentItem.startTime),
          moment(this.currentItem.endTime)
        ];
      })
    },

    // 获取大区下拉列表
    getAreaInfoByActivity() {
      let params = {
        activityId: this.currentItem.id
      };
      return api.getAreaInfoByActivity(params);
    },
    setRegionList(res) {
      if (res.data.code == 200 && res.data.data.region_list.length > 0) {
        this.regionList = res.data.data.region_list || [];
        if (res.data.data.type === 10) {
          this.isAllRegion = true;
          this.regionValue = "";
        } else {
          this.regionValue = this.regionList[0].id;
        }
        this.getActivityData();
      } else {
        this.regionList = [];
        this.$message.warning(res.data.message || "获取大区列表失败");
      }
    },

    // 接口请求 - 获取活动数据
    getActivityData() {
      this.loading = true
      let params = {
        activityId: this.currentItem.id,
        startTime: moment(this.dateValue[0]).format("YYYY-MM-DD"),
        endTime: moment(this.dateValue[1]).format("YYYY-MM-DD"),
        regionId: this.regionValue
      };
      this.cardData = deepClone(cardData)
      api.getActivityData(params).then(res => {
        this.loading = false

        if (res.data.code === 200) {
          console.log("getActivityData", res.data.data);
          let data = res.data.data
          // this.cardData[0].children[0].value = this.$options.filters.changeNum(data.addFansCount)
          this.cardData[0].children[0].value = this.$options.filters.changeNumNoRound(data.storeCount)
          this.cardData[1].children[0].value = this.$options.filters.changeNumNoRound(data.awemePublishCount)
          this.cardData[1].children[1].value = this.$options.filters.changeNumNoRound(data.awemeWatchCount)
          this.cardData[1].children[2].value = this.$options.filters.changeNumNoRound(data.awemeInteractCount)
          this.cardData[2].children[0].value = this.$options.filters.changeNumNoRound(data.liveCount)
          this.cardData[2].children[1].value = this.$options.filters.changeNumNoRound(data.liveWatchCount)
          this.cardData[2].children[2].value = this.$options.filters.changeNumNoRound(data.liveInteractCount)
        } else {
          this.$message.error(res.data.message || "获取活动数据失败");
        }
      }).catch(() => {
        this.loading = false    
      });
    },

    // 大区选择
    regionChange() {
      this.getActivityData();
    },

    // 日期切换
    calendarChange(date) {
      this.dateValue[0] = date[0];
    },
    dateChange(date) {
      if (date.length == 0) {
        this.dateValue = [
          moment(this.currentItem.startTime),
          moment(this.currentItem.endTime)
        ];
      }
      this.getActivityData();
    },
    disabledDate(current) {
      return (
        current < moment(this.currentItem.startTime) ||
        current > moment(this.currentItem.endTime)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.bottom-wrap {
  width: 100%;
  height: 100%;
  &-tools {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  &-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    padding: 20px 0 20px 36px;
    width: 100%;
    // height: 342px;
    background: #2b2f3b;
    border-radius: 16px;
    
    > .spin {
      width: 100%;
      height: 100%;
      padding: 192px 0;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
    }

    > div {
      display: flex;
      align-items: center;
      margin: 16px 0 0;
      height: 90px;
      width: 100%;

      .right {
        padding-left: 30px;
        display: flex;
        justify-content: flex-start;
        > div {
          margin-left: 12px;
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
