import Nr from "@/utils/NetworldRequest";
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_BFF_API,
}).getAxios();

export default {
  getMemberListApi(params) {
    return request.get("/matrixMember/pageList", {
      params,
    });
  },
};
