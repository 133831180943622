<template>
  <div
    style="width: 100%; height: 100%; background-color: #150b42"
    class="box_live"
  >
    <div id="box">
      <div class="body">
        <div class="header">
          <div class="header-left">
            <svg-icon :icon-class="platform" class="platform-icon" />
            <span>#</span>
            <span class="title">{{ activityDetail.activity_name || "-" }}</span>
          </div>
          <div class="header-right">
            <div>
              <span>活动时间</span>
              <span
                >{{ formatTime(activityDetail.start_time) }}-{{
                  formatTime(activityDetail.end_time)
                }}</span
              >
            </div>
            <img src="@/assets/img/activity2.png" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="content-left">
            <div class="data-box">
              <BlurCard :width="1239" :height="142" :top="0" :left="-14" />
              <div class="data-box-container">
                <div class="data-box-container-time">
                  <span>已累计作战</span>
                  <div>
                    <div class="time-item" v-if="totalTime.days">
                      <span>{{ totalTime.days }}</span>
                      <span>天</span>
                    </div>
                    <div class="time-item">
                      <span>{{ totalTime.hours }}</span>
                      <span>小时</span>
                    </div>
                    <div class="time-item">
                      <span>{{ totalTime.minutes }}</span>
                      <span>分钟</span>
                    </div>
                    <div class="time-item">
                      <span>{{ totalTime.seconds }}</span>
                      <span>秒</span>
                    </div>
                  </div>
                </div>
                <div class="card-list">
                  <ActivityCard
                    :title="'活动参与门店'"
                    :background="'linear-gradient(180deg, #FEB990 0%, #FFC8A8 100%)'"
                    >{{ statisticsData.dealer_count | changeNum }}</ActivityCard
                  >
                  <ActivityCard :title="'当前在播'">{{
                    statisticsData.online_live_count | changeNum
                  }}</ActivityCard>
                  <ActivityCard :title="'今日累计观看'" :code="'hdzb01'">{{
                    statisticsData.today_watch_count | changeNum
                  }}</ActivityCard>
                  <ActivityCard :title="'累计开播场次'">{{
                    statisticsData.live_count | changeNum
                  }}</ActivityCard>
                  <ActivityCard :title="'活动累计观看'">{{
                    statisticsData.live_play_count | changeNum
                  }}</ActivityCard>
                  <ActivityCard :title="'累计开播时长'">{{
                    statisticsData.live_duration_count | disposeLongTime_h2
                  }}</ActivityCard>
                </div>
              </div>
            </div>
            <div class="live-container">
              <div class="live-container-title">
                <span>直播实况</span>
                <div class="refresh-button" @click="setVideoUrl(false)">
                  <span>换一批</span>
                  <svg-icon icon-class="refresh" class="refresh-icon" />
                </div>
              </div>
              <div class="live-container-main">
                <div class="live-list">
                  <BlurCard :width="392" :height="666" :top="0" :left="-14" />
                  <div class="live-list-container">
                    <div class="live-list-container-header">
                      <div class="title">
                        <div>
                          <svg-icon
                            icon-class="activity-live"
                            class="live-icon"
                          />
                          <span>直播列表</span>

                          <AftTooltip :code="'qjzb02'" style="top: -1px; left: 4px" />
                        </div>
                        <svg-icon
                          icon-class="refresh"
                          class="refresh-icon"
                          @click="getNewLiveList"
                        />
                      </div>
                      <div
                        :class="
                          currentVideos.left == liveList.officer
                            ? 'official-item active'
                            : 'official-item'
                        "
                        @click="currentVideos.left = liveList.officer"
                        v-if="liveList.officer && liveList.officer.nickname"
                      >
                        <WebpImage
                          :src="liveList.officer.avatar"
                          :width="'40px'"
                          :height="'40px'"
                          :radius="'10px'"
                          class="mr-12"
                        />
                        <span class="official-item-title">{{
                          liveList.officer.nickname
                        }}</span>
                        <svg-icon icon-class="live3" class="live-icon" />
                        <svg-icon icon-class="eye" class="eye-icon" />
                        <span class="number" v-if="platform == 'kuaishou'">{{
                          liveList.officer.unify_online_cnt | changeNum
                        }}</span>
                        <span class="number" v-else>{{
                          liveList.officer.unify_watch_cnt | changeNum
                        }}</span>
                      </div>
                      <div
                        v-else
                        style="
                          line-height: 40px;
                          padding-left: 20px;
                          color: #fff;
                        "
                      >
                        官号暂未直播
                      </div>
                    </div>
                    <div class="live-list-container-main">
                      <div
                        :class="
                          currentVideos.left == item
                            ? 'main-item active'
                            : 'main-item'
                        "
                        v-for="(item, index) in liveList.list"
                        :key="index"
                        @click="currentVideos.left = item"
                      >
                        <WebpImage
                          :src="item.avatar"
                          :width="'26px'"
                          :height="'26px'"
                          :radius="'50%'"
                          class="mr-12"
                        />
                        <span class="main-item-title">{{
                          item.dealer_name
                        }}</span>
                        <svg-icon icon-class="live3" class="live-icon" />
                        <svg-icon icon-class="eye" class="eye-icon" />
                        <span class="number" v-if="platform == 'kuaishou'">{{
                          item.unify_online_cnt | changeNum
                        }}</span
                        ><span class="number" v-else>{{
                          item.unify_watch_cnt | changeNum
                        }}</span>
                      </div>

                      <Empty
                        v-if="!liveList.list || liveList.list.length == 0"
                        :image="require('@/assets/img/empty2.png')"
                        :title="'暂无直播'"
                        class="empty"
                        style="margin-top: 116px"
                        :height="'139px'"
                      />
                    </div>
                  </div>
                </div>
                <div class="live-video-left">
                  <BlurCard :width="395" :height="666" :top="0" :left="-14" />
                  <div class="live-video-left-container">
                    <FlvVideo
                      :options="currentVideos.left"
                      :platform="platform"
                      v-if="
                        currentVideos.left && currentVideos.left.play_url_flv264_old
                      "
                      :id="'official'"
                    />
                  </div>
                  <div
                    class="live-video-left-bottom"
                    v-if="
                      currentVideos.left && currentVideos.left.play_url_flv264_old
                    "
                  >
                    <div class="title">
                      {{ currentVideos.left.title }}
                    </div>
                    <div class="info">
                      <div>
                        <WebpImage
                          :src="currentVideos.left.avatar"
                          :width="'26px'"
                          :height="'26px'"
                          :radius="'50%'"
                          class="mr-12"
                        />
                        <span>{{
                          currentVideos.left.dealer_name ||
                          currentVideos.left.nickname
                        }}</span>
                      </div>
                      <div>
                        <svg-icon icon-class="eye" class="eye-icon" />
                         
                        <span v-if="platform == 'kuaishou'">{{
                          currentVideos.left.unify_online_cnt | changeNum
                        }}</span>
                        <span v-else>{{
                          currentVideos.left.unify_watch_cnt | changeNum
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <Empty
                    v-if="!currentVideos.left || !currentVideos.left.nickname"
                    :image="require('@/assets/img/empty2.png')"
                    :title="'暂无直播'"
                    class="empty"
                    style="margin-top: 243px"
                    :height="'139px'"
                  />
                </div>
                <div class="live-video-right">
                  <div
                    class="video-item"
                    v-for="(item, index) in currentVideos.right"
                    :key="index"
                  >
                    <BlurCard :width="206" :height="316" :top="0" :left="-16" />
                    <div class="video-item-container">
                      <FlvVideo
                        :options="item"
                        :platform="platform"
                        :id="'flv' + index"
                        v-if="item && item.dealer_name"
                      />
                    </div>
                    <div
                      class="video-item-bottom"
                      v-if="item && item.dealer_name"
                    >
                      <div class="title">
                        {{ item.title || "-" }}
                      </div>
                      <div class="info">
                        <div>
                          <WebpImage
                            :src="item.avatar"
                            :width="'14px'"
                            :height="'14px'"
                            :radius="'50%'"
                            class="mr-6"
                          />
                          <span>{{ item.dealer_name }}</span>
                        </div>
                        <div>
                          <svg-icon icon-class="eye" class="eye-icon" />
                          <span v-if="platform == 'kuaishou'">{{ item.unify_online_cnt | changeNum }}</span>
                          <span v-else>{{ item.unify_watch_cnt | changeNum }}</span>
                        </div>
                      </div>
                    </div>
                    <Empty
                      v-else
                      :image="require('@/assets/img/empty2.png')"
                      :title="'暂无直播'"
                      class="empty mt-97"
                      :height="'82px'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-right">
            <BlurCard :width="590" :height="896" :top="0" :left="-14" />
            <div class="content-right-container">
              <div class="content-right-container-title">
                <span>声量线索实时分析中台</span>
                <AftTooltip :code="'hdqt01'" style="top: 0px; left: 6px" />
              </div>
              <div class="content-right-container-card">
                <ActivityCard :size="'small'" :title="'车系相关'">{{
                  this.clueData.series | changeNum
                }}</ActivityCard>
                <ActivityCard :size="'small'" :title="'价格相关'">{{
                  this.clueData.price | changeNum
                }}</ActivityCard>
                <ActivityCard :size="'small'" :title="'配置相关'">{{
                  this.clueData.setting | changeNum
                }}</ActivityCard>
                <ActivityCard :size="'small'" :title="'品牌相关'">{{
                  this.clueData.brand | changeNum
                }}</ActivityCard>
                <ActivityCard :size="'small'" :title="'线索相关'">{{
                  this.clueData.clue | changeNum
                }}</ActivityCard>
              </div>
              <div class="content-right-container-series">
                <div
                  class="content-right-container-series-card"
                  v-for="(item, index) in seriesData"
                  :key="index"
                >
                  <img
                    :src="item.image ? ossHost + item.image : defaultSeries"
                    alt=""
                  />
                  <span class="name">{{ item.series }}</span>
                  <span class="number">{{ item.count }}</span>
                </div>
                <div
                  style="
                    width: 100%;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.6);
                  "
                  v-if="!seriesData || seriesData.length == 0"
                >
                  暂无数据
                </div>
              </div>
              <div class="content-right-container-comment">
                <transition-group name="list-complete" tag="p">
                  <div
                    class="comment-list list-complete-item"
                    v-for="item in commentList"
                    :key="item.msg_id"
                  >
                    <div
                      class="comment-list-series"
                      v-if="
                        item.series_detail_tags &&
                        item.series_detail_tags.length > 0
                      "
                    >
                      <div class="series-container">
                        <span>提及车系:</span>
                        <div>
                          <div
                            class="series-item"
                            v-for="(item, index) in item.series_detail_tags"
                            :key="index"
                          >
                            <img
                              :src="
                                item.image
                                  ? ossHost + item.image
                                  : defaultSeries
                              "
                              alt=""
                            />
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>{{ item.series }}</span>
                              </template>
                              <span>{{ item.series }}</span>
                            </a-tooltip>
                          </div>
                        </div>
                      </div>
                      <img src="@/assets/img/mention.png" alt="" />
                    </div>
                    <div class="comment-list-top">
                      <img :src="item.user.avatar" alt="" />
                      <div>
                        <span>{{ item.user.nickname }}</span>
                        <span>{{ formatTime2(item.publish_time) }}</span>
                      </div>
                    </div>
                    <div class="comment-list-main">{{ item.content }}</div>
                    <div
                      class="comment-list-tag"
                      v-if="item.comment_tags && item.comment_tags.length > 0"
                    >
                      <span
                        v-for="(item2, index2) in item.comment_tags"
                        :key="index2"
                        >{{ item2 }}</span
                      >
                    </div>
                  </div>
                </transition-group>
              </div>
              <!-- <Empty
                :image="require('@/assets/img/empty2.png')"
                :title="'暂无消息'"
                class="empty"
                style="margin-top: 300px"
                :height="'119px'"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Paho from "@/utils/mqttws31.min.js";
import MqttRemoval from "@/utils/mqttRemoval";
import BlurCard from "@/components/card/BlurCard";
import ActivityCard from "./components/activityCard";
import SvgIcon from "../../components/SvgIcon/SvgIcon.vue";
import WebpImage from "../../components/WebpImage/WebpImage.vue";
import api from "@/api/activityScreen.js";
import { getPrincipalId, getUserId } from "@/utils/auth";
import FlvVideo from "@/components/FlvVideo/FlvVideo";
import Tooltip from "@/components/Tooltip/Tooltip";
import Empty from "@/components/Empty/Empty";
export default {
  components: {
    BlurCard,
    ActivityCard,
    WebpImage,
    SvgIcon,
    FlvVideo,
    Tooltip,
    Empty,
  },
  data() {
    return {
      ossHost: process.env.VUE_APP_OSS_LIVE_HOST + "/",
      defaultSeries: require("../../assets/default/default-series.png"),
      tooltipTitle: "统计今天开播直播的线索评论，有一定时间延时",
      activityId: "",
      platform: "",
      activityDetail: {}, // 活动详情
      statisticsData: {}, // 头部信息
      player: null,
      screenWidth: 1920,
      totalTime: {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
      },
      totalTimer: null, // 累计作战时长定时器
      autoLiveTimer: null, // 直播列表切换定时器
      autoStatisticsTimer: null, // 顶部信息刷新定时器
      addMonitorTimer: null, // 添加监控接口定时器
      client: null, // 创建一个客户端实例
      timerWl: null,
      removal: null,
      // 记录直播列表数据
      liveList: {
        officer: {},
        list: [],
      },
      // 当前展示视频数据
      currentVideos: {
        left: {},
        right: [],
      },
      mqttConfigData: null, // mqtt配置信息
      // 右上角的线索数据
      clueData: {
        brand: null,
        clue: null,
        price: null,
        series: null,
        setting: null,
      },
      seriesData: [], // 车系
      commentList: [], // 评论列表
      temporaryComment: [], // 临时存储评论列表
      commentBool: true, // 控制1s内添加评论dom频率
    };
  },
  created() {
    this.activityId = this.$route.query.id;
    this.platform = this.$route.query.platform;
    // 获取活动详情
    this.getActivityDetail();
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timerWl) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timerWl = true;
        let that = this;
        let screen_timer = setTimeout(function () {
          document.getElementById("box").style.transform = `scale(${
            that.screenWidth / 1920
          })`;
          clearTimeout(screen_timer);
          that.timerWl = false;
        }, 400);
      }
    },
    // 控制评论最大数值，满100减50
    commentList(val) {
      if (val.length > 100) {
        this.commentList = this.commentList.slice(0, 50);
      }
    },
  },
  mounted() {
    // let clear = setTimeout(() => {
    //   this.showLoading = false;
    //   clearTimeout(clear);
    // }, 1500);
    // 监听屏幕变化
    this.resizeWidth();
    window.addEventListener(
      "resize",
      () => {
        this.resizeWidth();
      },
      false
    );
  },
  methods: {
    // 获取活动详情
    getActivityDetail() {
      let data = {
        params: {
          activity_id: this.activityId,
        },
        platform: "agg",
      };
      api.getActivityDetail(data).then((res) => {
        if (res.data.code === 0) {
          this.activityDetail = res.data.data || {};
          if (res.data.data && res.data.data.state == 1) {
            // 添加直播监控
            this.addRoomListener();
            // 获取mqtt配置信息
            this.getMqttSetting();
            // 获取历史数据
            this.getClueHistory();
            // 继续调用头部信息和直播列表
            this.getStatistics();
            this.getLiveList(true);
            // 启动累计时长定时器
            this.getTotalTime();
          } else {
            this.$message.warning("当前活动未开启或未绑定分组");
            this.pushObj(this.currentVideos.right);
          }
        } else {
          this.$message.warning("获取活动详情失败");
        }
      });
    },
    // 获取头部信息
    getStatistics() {
      this.setStatisticsTimer();
      let data = {
        params: {
          activity_id: this.activityId,
          start_time: this.activityDetail.start_time,
          end_time: this.activityDetail.end_time,
          group_code: this.activityDetail.group_code,
        },
        platform: this.platform,
      };
      api.getStatistics(data).then((res) => {
        if (res.data.code === 0) {
          this.statisticsData = res.data.data;
        } else {
          this.$message.warning("获取统计信息失败");
        }
      });
    },
    // 获取直播列表
    getLiveList(refresh) {
      this.setLiveTimer();
      let data = {
        params: {
          activity_id: this.activityId,
          group_code: this.activityDetail.group_code,
          principal_id: getPrincipalId(),
        },
        platform: this.platform,
      };
      api.getLiveList(data).then((res) => {
        if (res.data.code === 0) {
          this.liveList = res.data.data;
          if (refresh) {
            console.log("refresh", refresh);
            this.setVideoUrl(true);
          }
          console.log(this.currentVideos);
        } else {
          this.$message.warning("获取直播列表失败");
        }
      });
    },
    // 强制刷新直播列表
    getNewLiveList() {
      let data = {
        params: {
          activity_id: this.activityId,
          group_code: this.activityDetail.group_code,
          principal_id: getPrincipalId(),
        },
        platform: this.platform,
      };
      api.getNewLiveList(data).then((res) => {
        if (res.data.code === 0) {
          this.liveList = res.data.data;
        } else {
          this.$message.warning("获取直播列表失败");
        }
      });
    },
    // 获取声量线索历史接口
    getClueHistory() {
      let data = {
        params: {
          platform_type: this.getPlatformType(),
          activity_id: this.activityId,
          group_code: this.activityDetail.group_code,
        },
        platform: "mqtt",
      };
      api.getHistoryData(data).then((res) => {
        console.log(res.data);
        if (res.data.code === 0) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.forEach((item) => {
              this.onMessageArrived(item);
            });
          }
        } else {
          this.$message.warning("获取历史数据失败");
        }
      });
    },
    // 获取mqtt配置接口
    getMqttSetting() {
      let data = {
        params: {
          platform_type: this.getPlatformType(),
          activity_id: this.activityId,
          group_code: this.activityDetail.group_code,
          device_id: getUserId(),
        },
        platform: "mqtt",
      };
      api.getClientSetting(data).then((res) => {
        console.log(res.data);
        if (res.data.code === 0) {
          this.mqttConfigData = res.data.data;
          this.removal = new MqttRemoval();
          this.MQTTconnect();
        } else {
          this.$message.warning("获取mqtt配置失败");
        }
      });
    },
    // 添加监听接口
    addRoomListener() {
      this.setAddMonitorTimer();
      let data = {
        data: {
          platform_type: this.getPlatformType(),
          activity_id: this.activityId,
          group_code: this.activityDetail.group_code,
        },
        platform: "mqtt",
      };
      api.addMonitorActivity(data).then((res) => {
        console.log(res.data);
        if (res.data.code === 0) {
        } else {
          this.$message.warning("添加监控失败");
        }
      });
    },
    // 平台转化数字
    getPlatformType() {
      if (this.platform == "douyin") {
        return 1;
      } else if (this.platform == "kuaishou") {
        return 2;
      } else {
        this.$message.warning("暂未开放当前平台");
        return false;
      }
    },
    // 添加评论dom
    setComment(msg) {
      console.log("this.temporaryComment", this.temporaryComment);
      this.temporaryComment.unshift(msg);
      if (this.commentBool) {
        console.log("concatComment");
        this.commentList = this.temporaryComment.concat(this.commentList);
        this.temporaryComment = [];
        this.commentBool = false;
        let cTimer = setTimeout(() => {
          console.log("this.commentBool => true");
          this.commentBool = true;
          clearTimeout(cTimer);
        }, 1000);
      }
    },
    // 换一批视频
    setVideoUrl(isFirst) {
      console.log("liveList", this.liveList);
      // isFirst只有初始化或定时刷新左侧直播列表时触发
      if (isFirst) {
        if (this.liveList.officer) {
          this.currentVideos.left = this.liveList.officer;
          this.currentVideos.right = this.liveList.list.slice(0, 4);
        } else if (this.liveList.list.length == 1) {
          this.currentVideos.left = this.liveList.list[0];
          this.currentVideos.right = [];
        } else if (this.liveList.list.length > 1) {
          this.currentVideos.left = this.liveList.list[0];
          this.currentVideos.right = this.liveList.list.slice(1, 5);
        }
      } else {
        if (this.liveList.officer) {
          this.currentVideos.left = this.liveList.officer;
          this.currentVideos.right = this.randomNum(this.liveList.list, 4);
        } else if (this.liveList.list.length == 1) {
          this.currentVideos.left = this.liveList.list[0];
          this.currentVideos.right = [];
        } else if (this.liveList.list.length > 1) {
          let newData = this.randomNum(this.liveList.list.slice(1), 4);
          this.currentVideos.left = this.liveList.list[0];
          this.currentVideos.right = newData;
        }
      }
      this.pushObj(this.currentVideos.right);
      console.log("currentVideos", this.currentVideos);
    },
    // 填满视频位置
    pushObj(arr) {
      if (arr.length < 4) {
        arr.push({});
        this.pushObj(arr);
      }
    },
    // 格式化时间
    formatTime(time) {
      return moment(time).format("YYYY年MM月DD日");
    },
    // 格式化时间
    formatTime2(time) {
      return moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
    // 累计时间计算
    getTotalTime() {
      this.totalTimer = setInterval(() => {
        let t1 = moment(this.activityDetail.start_time);
        let t2 = moment();
        let dura = t2.format("x") - t1.format("x");
        let tempTime = moment.duration(dura);
        this.totalTime = {
          days: Math.floor(tempTime.asDays()),
          hours: tempTime.hours(),
          minutes: tempTime.minutes(),
          seconds: tempTime.seconds(),
        };
        // 当前时间大于结束时间清空定时器
        if (
          Number(moment().format("x")) >=
          Number(moment(this.activityDetail.end_time).format("x"))
        ) {
          clearInterval(this.totalTimer); // 清除定时器
          this.totalTimer = null;
        }
      }, 1000);
    },
    // 屏幕变化设置比
    resizeWidth() {
      this.screenWidth = document.body.clientWidth;
    },
    // 创建mqtt实例，并连接
    MQTTconnect() {
      this.client = new Paho.MQTT.Client(
        this.mqttConfigData.endPoint,
        443,
        this.mqttConfigData.clientId
      );
      this.client.connect({
        userName: this.mqttConfigData.userName, //连接账号
        password: this.mqttConfigData.password, //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: (message) => {
          setTimeout(this.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4,
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },

    //当客户端连接
    onConnect() {
      console.log("onConnect");
      //建立连接后，进行订阅并发送消息。
      this.client.subscribe(
        // todo
        // "afanti_live_dev/bulletAndStatistics/1/1001640782113112064_1003034671594102784",
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic,
        { qos: 0 }
      );
    },

    // 当客户端失去连接时调用
    onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    },
    /**当消息到达时
    /* type 2101:线索数据，2102:车系相关，3001：下播，1101：评论
    / * 抖音无法获取礼物数**/
    onMessageArrived(message) {
      let newJson = {};
      if (message.payloadString) {
        newJson = JSON.parse(message.payloadString);
      } else {
        newJson = message;
      }
      // console.log("onMessageArrived:", newJson.data);
      // 判断类型
      switch (newJson.type) {
        case "2101":
          // 线索数据
          this.clueData = newJson.data;
          break;
        case "2102":
          // 车系相关
          this.seriesData = newJson.data;
          break;
        case "3001":
          // 左侧列表有直播下播时---刷新列表
          this.getNewLiveList();
          break;
        case "1101":
          // 评论
          this.setComment(newJson.data);
          break;
        default:
          break;
      }
    },
    // 去重
    unique(arr, val) {
      const res = new Map();
      return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
    },
    // 随机抽取
    randomNum(data, n) {
      // if (typeof num !== " number" || typeof n !== "number") return false; //对象检测
      var aData = [];
      if (data.length <= n) {
        for (var j = 0; j < data.length; j++) {
          aData.push(data[j]);
        }
        return aData;
      } //如果n大于num就生成0到num-1的每一个数
      else {
        while (aData.length < n) {
          var random = Math.round(Math.random() * data.length) - 1;
          if (aData.indexOf(data[random]) == -1) {
            if (data[random]) {
              aData.push(data[random]);
            }
          }
        }
        return aData;
      }
    },
    // 重置直播定时器
    setLiveTimer() {
      clearTimeout(this.autoLiveTimer);
      this.autoLiveTimer = null;
      this.autoLiveTimer = setTimeout(() => {
        this.getLiveList(true);
      }, 1000 * 60 * 5);
    },
    // 重置顶部数据定时器
    setStatisticsTimer() {
      clearTimeout(this.autoStatisticsTimer);
      this.autoStatisticsTimer = null;
      this.autoStatisticsTimer = setTimeout(() => {
        this.getStatistics();
      }, 1000 * 60 * 5);
    },

    // 重置添加监控定时器
    setAddMonitorTimer() {
      clearTimeout(this.addMonitorTimer);
      this.addMonitorTimer = null;
      this.addMonitorTimer = setTimeout(() => {
        this.addRoomListener();
      }, 1000 * 60 * 15);
    },

    // 下播信息弹窗
    info() {
      const h = this.$createElement;
      this.$info({
        title: "当前直播已结束",
        content: h("div", {}, [h("p", "请返回上一页面查看其他直播。")]),
        onOk() {},
      });
    },
    flv_destroy() {
      this.player.pause();
      this.player.unload();
      this.player.detachMediaElement();
      this.player.destroy();
      this.player = null;
    },
  },
  beforeDestroy() {
    this.client.unsubscribe(
      this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
    );
    this.client.disconnect(); // 断开连接
    this.client = null;
    window.removeEventListener("resize", this.resizeWidth);
    this.flv_destroy();
  },
};
</script>
<style lang="scss" scoped>
.box_live {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
@mixin hideSCroll {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
@mixin containerBox {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
@mixin ellipsis {
  display: inline-block;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
#box {
  width: 1920px;
  height: 1074px;
  transform-origin: left top;
  background-color: #150b42;
}
.body {
  width: 100%;
  height: 100%;
  background: url("../../assets/live_images/bg_ac.png");
  background-size: 100% 100%;
  padding: 0 60px;
  .header {
    width: 100%;
    height: 124px;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .platform-icon {
        color: #fff;
        font-size: 30px;
        margin-right: 20px;
      }
      > span {
        color: #ffc8a8;
        font-size: 30px;
      }
      .title {
        color: #fff;
      }
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        font-size: 14px;
        color: #fff;
        line-height: 20px;
        opacity: 0.8;
      }
      > img {
        width: 46px;
        height: 46px;
        margin-left: 4px;
      }
    }
  }
  .content {
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-left {
      width: 1207px;
      height: 100%;
      .data-box {
        width: 100%;
        height: 142px;
        border-radius: 26px;
        overflow: hidden;
        position: relative;
        &-container {
          border-radius: 26px;
          display: flex;
          @include containerBox();
          &-time {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 287px;
            height: 100%;
            padding-left: 30px;
            > span {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.6);
              margin-bottom: 18px;
            }
            > div {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              font-size: 20px;
              color: #fff;
              .time-item {
                span:first-child {
                  font-weight: bold;
                  font-style: oblique;
                }
              }
            }
          }
          .card-list {
            width: 900px;
            height: 100%;
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
          }
        }
      }
      .live-container {
        width: 100%;
        height: 758px;
        &-title {
          width: 100%;
          height: 92px;
          padding: 20px 0 0 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > span {
            color: #fff;
            font-size: 20px;
          }
          .refresh-button {
            cursor: pointer;
            width: 92px;
            height: 32px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #8890a6;
            padding: 0 6px 0 14px;
            > span {
              font-size: 14px;
              color: #fff;
            }
            .refresh-icon {
              font-size: 20px;
            }
          }
        }
        &-main {
          width: 100%;
          height: 666px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .live-list {
            width: 370px;
            height: 666px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            &-container {
              border-radius: 16px;
              @include containerBox();
              &-header {
                width: 100%;
                height: 128px;
                background: rgba(0, 0, 0, 0.24);
                .title {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  height: 68px;
                  padding: 0 20px;
                  > div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                  }
                  .live-icon {
                    font-size: 28px;
                    margin: 0 10px 0 2px;
                  }
                  span {
                    font-size: 14px;
                    color: #fff;
                  }
                  .refresh-icon {
                    font-size: 20px;
                    color: #8890a6;
                    cursor: pointer;
                  }
                }
                .official-item {
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  padding-left: 20px;
                  &-title {
                    font-size: 16px;
                    color: #fff;
                    width: 132px;
                    @include ellipsis();
                  }
                  .live-icon {
                    font-size: 10px;
                    color: #ffc8a8;
                    margin: 0 26px 0 6px;
                    opacity: 0;
                  }
                  .eye-icon {
                    font-size: 22px;
                    color: rgba(255, 255, 255, 0.2);
                    margin-right: 4px;
                  }
                  .number {
                    color: #fff;
                    font-size: 16px;
                    font-style: italic;
                    font-weight: bold;
                  }
                }
                .official-item.active {
                  .official-item-title {
                    color: #ffc8a8;
                  }
                  .live-icon {
                    opacity: 1;
                  }
                  .eye-icon {
                    color: #ffc8a8;
                  }
                  .number {
                    color: #ffc8a8;
                  }
                }
              }
              &-main {
                height: 538px;
                overflow-y: auto;
                .main-item {
                  &:last-child {
                    margin-bottom: 20px;
                  }
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  padding-left: 20px;
                  margin-top: 22px;
                  height: 26px;
                  cursor: pointer;
                  &-title {
                    font-size: 14px;
                    color: #fff;
                    width: 116px;
                    @include ellipsis();
                  }
                  .live-icon {
                    font-size: 10px;
                    color: #ffc8a8;
                    margin: 0 49px 0 6px;
                    opacity: 0;
                  }
                  .eye-icon {
                    font-size: 22px;
                    color: rgba(255, 255, 255, 0.2);
                    margin-right: 4px;
                  }
                  .number {
                    color: #fff;
                    font-size: 14px;
                    font-style: italic;
                    font-weight: bold;
                  }
                }
                .main-item.active {
                  .main-item-title {
                    color: #ffc8a8;
                  }
                  .live-icon {
                    opacity: 1;
                  }
                  .eye-icon {
                    color: #ffc8a8;
                  }
                  .number {
                    color: #ffc8a8;
                  }
                }
              }
            }
          }
          .live-video-left {
            width: 373px;
            height: 664px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            &-container {
              border-radius: 16px;
              @include containerBox();
            }
            &-bottom {
              position: absolute;
              bottom: 0;
              z-index: 101;
              width: 100%;
              height: 80px;
              background: rgba(0, 0, 0, 0.7);
              padding: 13px 20px 12px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .title {
                width: 90%;
                height: 17px;
                font-size: 12px;
                color: #ffffff;
                opacity: 0.7;
                line-height: 17px;
                @include ellipsis();
              }
              .info {
                width: 100%;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                > div:first-child {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  > span {
                    color: #ffffff;
                    font-size: 12px;
                  }
                }
                > div:last-child {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  > .eye-icon {
                    font-size: 20px;
                    color: #fff;
                    opacity: 0.7;
                    margin-right: 6px;
                  }
                  > span {
                    color: #fff;
                    font-size: 12px;
                    font-weight: bold;
                    font-style: italic;
                  }
                }
              }
            }
          }
          .live-video-right {
            width: 384px;
            height: 664px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .video-item {
              width: 176px;
              height: 316px;
              border-radius: 16px;
              position: relative;
              overflow: hidden;
              margin-bottom: 32px;
              &-container {
                border-radius: 16px;
                @include containerBox();
              }

              &-bottom {
                position: absolute;
                bottom: 0;
                z-index: 101;
                width: 100%;
                height: 50px;
                background: rgba(0, 0, 0, 0.7);
                padding: 6px 10px 7px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title {
                  width: 90%;
                  height: 14px;
                  font-size: 10px;
                  color: #ffffff;
                  opacity: 0.7;
                  line-height: 14px;
                  @include ellipsis();
                }
                .info {
                  width: 100%;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  > div:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    > span {
                      color: #ffffff;
                      font-size: 10px;
                      @include ellipsis();
                      width: 52px;
                    }
                  }
                  > div:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    > .eye-icon {
                      font-size: 20px;
                      color: #fff;
                      opacity: 0.7;
                      margin-right: 4px;
                    }
                    > span {
                      color: #fff;
                      font-size: 10px;
                      font-weight: bold;
                      font-style: italic;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &-right {
      width: 553px;
      height: 100%;
      position: relative;
      overflow: hidden;
      &-container {
        border-radius: 26px;
        @include containerBox();
        &-title {
          width: 100%;
          height: 90px;
          padding: 36px 0 26px;
          text-align: center;
          position: relative;
          font-size: 20px;
          color: #ffffff;
          line-height: 28px;
          font-weight: bold;
          > .tooltip {
            position: absolute;
            right: 24px;
            top: 40px;
          }
        }
        &-card {
          width: 100%;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 28px;
          margin-bottom: 20px;
        }
        &-series {
          width: calc(100% - 56px);
          margin-left: 28px;
          height: 131px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 0px 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          &-card {
            width: 90px;
            height: 100%;
            padding: 0 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-right: 12px;
            img {
              width: 58px;
              height: 43px;
              margin: 8px 0 7px;
            }
            span.name {
              font-size: 12px;
              color: rgba(255, 255, 255, 0.6);
              margin: 4px 0 5px;
            }
            span.number {
              font-size: 16px;
              color: #fff;
              font-weight: bold;
              font-style: italic;
            }
          }
        }
        &-comment {
          width: calc(100% - 32px);
          margin-left: 28px;
          height: 568px;
          overflow-y: auto;
          margin-top: 12px;
          .comment-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            transition: all 0.7s;
            &-top {
              width: 100%;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin: 20px 0 12px;
              padding-left: 10px;
              > img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 12px;
              }
              > div {
                display: flex;
                flex-direction: column;
                > span:first-child {
                  font-size: 14px;
                  color: rgba(255, 255, 255, 0.8);
                }
                > span:last-child {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.6);
                }
              }
            }
            &-main {
              background: linear-gradient(180deg, #ffffff 0%, #ffc8a8 100%);
              border-radius: 0px 22px 22px 22px;
              padding: 7px 18px 7px 24px;
              font-size: 14px;
              line-height: 20px;
              color: #000000;
              font-weight: bold;
              margin-left: 56px;
              max-width: 400px;
              min-width: 50px;
              margin-bottom: 16px;
              word-wrap: break-word;
              word-break: break-all;
            }
            &-tag {
              padding-left: 56px;
              height: 18px;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 22px;
              > span {
                width: 38px;
                height: 18px;
                background: rgba(0, 0, 0, 0.2);
                border-radius: 11px;
                font-size: 10px;
                color: rgba(255, 255, 255, 0.6);
                text-align: center;
                margin-right: 8px;
              }
            }
            &-series {
              width: 497px;
              height: 49px;
              margin-bottom: 16px;
              display: flex;
              flex-direction: column;
              .series-container {
                width: 100%;
                height: 42px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > span {
                  font-size: 14px;
                  font-weight: 500;
                  color: rgba(255, 255, 255, 0.4);
                }
                > div {
                  width: 408px;
                  height: 42px;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  flex-wrap: nowrap;
                  .series-item {
                    width: 130px;
                    height: 42px;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 12px 12px 0px 0px;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.8);
                    margin-left: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 9px;
                    > img {
                      width: 56px;
                      height: 36px;
                    }
                    > span {
                      width: 50px;
                      height: 20px;
                      line-height: 20px;
                      display: inline-block;
                      font-size: 14px;
                      color: rgba(255, 255, 255, 0.8);
                      margin-left: 6px;
                      @include ellipsis();
                    }
                  }
                }
              }
              > img {
                width: 100%;
                height: 7px;
              }
            }
          }
        }
      }
    }
  }
}
.empty {
  position: relative;
  z-index: 102;
}
.mr-6 {
  margin-right: 6px;
}
.mr-12 {
  margin-right: 12px;
}
.mt-97 {
  margin-top: 97px;
}

.list-complete-item {
  display: inline-block;
}
.list-complete-enter-active,
.list-complete-leave-active {
  transition: all 0.7s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0.3;
  transform: translate(-250px, -10px);
}
</style>