<template>
  <a-popover
    v-model="visible"
    trigger="click"
    placement="bottomRight"
    overlayClassName="popover-content"
  >
    <div slot="content" class="popover-content-container" id="popover">
      <div class="popover-title">数据条件筛选</div>
      <div class="popover-tip" v-if="tipText">{{ tipText }}</div>
      <div class="popover-list">
        <!-- <div
          class="popover-list-item"
          v-for="(item, index) in settingData"
          :key="index"
        >
          <span class="label">{{ item.text }}</span>
          <a-input/>
          <a-switch
            class="aft-switch drawer"
            v-model="item.bool"
            @change="changeSwitch"
          />
        </div> -->
        <a-form-model ref="form" :model="form">
          <template v-for="(item, index) in form.settingData">
            <!-- text -->
            <a-form-model-item
              class="form-item-text"
              v-if="item.type === 'text'"
              :key="item.value"
            >
              <div class="item-con">
                <div>
                  <span class="text">
                    {{ item.text }}
                  </span>
                </div>
                <a-switch
                  class="aft-switch drawer"
                  v-model="item.bool"
                  @change="changeSwitch(item, index)"
                />
              </div>
            </a-form-model-item>
            <!-- input -->
            <a-form-model-item
              class="form-item-input"
              :class="item.value"
              v-if="item.type === 'input'"
              :key="item.value"
              :prop="`settingData.${index}.inputValue`"
              :rules="item.inputRules"
              :ref="item.value"
            >
              <div class="item-con">
                <div>
                  <span class="text">
                    {{ item.text }}
                  </span>
                  <a-input
                    @blur="
                      connectInput(item, $event) &
                        $refs[item.value][0].onFieldBlur()
                    "
                    v-model.trim="item.inputValue"
                    style="width: 100px"
                    @change="handleInputChange(item, index)"
                  />
                  <span class="text" v-if="item.text2">
                    {{ item.text2 }}
                  </span>
                </div>
                <a-switch
                  class="aft-switch drawer"
                  v-model="item.bool"
                  @change="changeSwitch(item, index)"
                />
              </div>
            </a-form-model-item>
          </template>
        </a-form-model>
      </div>
    </div>
    <div class="popover-button">
      <a-icon type="setting" theme="filled" class="setting-icon" />
      <span>数据条件筛选</span>
    </div>
  </a-popover>
</template>

<script>
export default {
  name: "SwitchPopover",
  props: {
    settingData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tipText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      form: {
        settingData: [],
      },
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$refs.form.clearValidate();
        console.log("气泡卡片关闭触发", this.settingData);
        this.$emit("colsePopover", this.settingData);
      }
    },
    settingData: {
      immediate: true,
      handler() {
        this.form.settingData = this.settingData;
      },
    },
  },
  methods: {
    hide() {
      this.visible = false;
    },
    handleInputChange(item, index) {
      const prop = `settingData.${index}.inputValue`;
      if (item.inputType === "number") {
        item.inputValue = item.inputValue.replace(/\D/g, "");
      }
      this.$refs.form.validateField(prop, (errMsg) => {
        if (errMsg && item.bool === true) {
          item.bool = false;
          this.changeSwitch(item, index);
        }
      });
    },
    connectInput(item, event) {
      console.log(event.target.value, "input里面的数据");
      console.log(item, "input里面的item");
      if (item.bool) {
        this.$emit("changeSwitch", this.settingData);
      }
    },
    changeSwitch(item, index) {
      if (item.type === "text") {
        this.$emit("changeSwitch", this.settingData);
      } else if (item.type === "input") {
        if (item.bool) {
          const prop = `settingData.${index}.inputValue`;
          this.$refs.form.validateField(prop, (errMsg) => {
            if (!errMsg) {
              this.$emit("changeSwitch", this.settingData);
            } else {
              item.bool = false;
            }
          });
        } else {
          this.$emit("changeSwitch", this.settingData);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
// 错误信息偏移
::v-deep .isDropUserDefinedLiveWatch .ant-form-explain,
::v-deep .isDropFiveHundredAweme .ant-form-explain,
::v-deep .isDropThirtyMinLive .ant-form-explain {
  padding-left: 87px;
  padding-top: 4px;
}

.popover-content-container {
  .popover-title {
    height: 42px;
    padding: 4px 0 16px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.84);
    line-height: 22px;
  }
  .popover-tip {
    color: #9ca8ba;
    font-size: 12px;
    margin-bottom: 16px;
  }
  .popover-list {
    height: auto;
    overflow-y: hidden;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 22px;
      margin-bottom: 16px;
      .label {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
        margin-right: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.popover-button {
  height: 40px;
  padding: 0 16px;
  background: #2b2f3b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .setting-icon {
    font-size: 16px;
    color: #9ca8ba;
    margin-right: 4px;
  }
  > span {
    font-size: 14px;
    color: #9ca8ba;
  }
}

/deep/ .ant-form {
  &-explain {
    color: #ea5656;
    font-size: 12px;
  }
}

.form-item-text {
  margin-top: 16px;
  margin-bottom: 0;

  &:first-child {
    margin-top: 0;
  }

  /deep/ .ant-form-item {
    &-control {
      line-height: 22px;
    }
  }
}

.form-item-input {
  margin-top: 8px;
  margin-bottom: 0;

  &:first-child {
    margin-top: 0;
  }
}

/deep/ .ant-input {
  height: 40px;
  color: rgba(255, 255, 255, 0.88);
  background-color: #1a1d26;
  border-color: #1a1d26;
  border-radius: 8px;
  outline: 0;
  box-shadow: none;

  &:focus {
    border-color: #1a1d26;
  }
}

/deep/ .has-error {
  .ant-input {
    border-color: #ea5656;

    &:hover {
      background-color: #1a1d26;
      border-color: #ea5656;
    }
  }
}

/deep/ .ant-switch {
  margin: 0 0 0 16px;
}

.item-con {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    color: #9ca8ba;
    font-size: 14px;
  }
}
</style>
