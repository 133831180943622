<template>
  <div>
    <a-drawer
      placement="right"
      width="736"
      :closable="false"
      :visible="visible"
      @close="onClose"
      class="new-aft-modal"
    >
      <div class="drawer-container">
        <div class="drawer-title">
          <span>变更历史</span>
          <a-icon type="close" class="close-icon" @click="onClose" />
        </div>
        <div class="drawer-main">
          <a-table
            :pagination="false"
            :columns="columns"
            :dataSource="list"
            :loading="loading"
            :rowKey="(_, index) => index"
            :scroll="{ y: 'calc(100vh - 176px)' }"
            :locale="{ emptyText: '暂无数据' }"
            style="height: calc(100% - 26px)"
          >
            <div slot="changeType" slot-scope="row">
              <div
                v-for="(item, index) in row.activityChangeDetailList"
                :key="index"
                class="change-item"
              >
                {{ item.changeTypeMessage }}
              </div>
            </div>
            <div slot="changeBefore" slot-scope="row">
              <div
                v-for="(item, index) in row.activityChangeDetailList"
                :key="index"
                class="change-item"
              >
                <div
                  class="preview-img-box"
                  v-if="item.changeType === 2 || item.changeType === 3"
                >
                  <BaseImage
                    :src="
                      item.changeBefore
                        ? aliImgTransform(item.changeBefore, 100)
                        : ''
                    "
                    :defaultImage="defaultCoverImg"
                    fit="cover"
                    width="41px"
                    height="41px"
                    style="background-color: #000"
                  />
                  <div class="shadow-box" @click="showImage(item.changeBefore)">
                    <a-icon type="eye" class="icon" />
                  </div>
                </div>
                <a
                  class="link"
                  v-else-if="item.changeType === 6"
                  @click="openWinnerPreviewModal('前', item.changeBefore)"
                >
                  查看详情
                </a>
                <TextEllipsis
                  v-else
                  :value="item.changeBefore"
                  :key="`changeBefore_${new Date().getTime()}`"
                  :limit="2"
                ></TextEllipsis>
              </div>
            </div>
            <div slot="changeAfter" slot-scope="row">
              <div
                v-for="(item, index) in row.activityChangeDetailList"
                :key="index"
                class="change-item"
              >
                <div
                  class="preview-img-box"
                  v-if="item.changeType === 2 || item.changeType === 3"
                >
                  <BaseImage
                    :src="
                      item.changeAfter
                        ? aliImgTransform(item.changeAfter, 100)
                        : ''
                    "
                    :defaultImage="defaultCoverImg"
                    fit="cover"
                    width="41px"
                    height="41px"
                    style="background-color: #000"
                  />
                  <div class="shadow-box" @click="showImage(item.changeAfter)">
                    <a-icon type="eye" class="icon" />
                  </div>
                </div>
                <a
                  class="link"
                  v-else-if="item.changeType === 6"
                  @click="openWinnerPreviewModal('后', item.changeAfter)"
                >
                  查看详情
                </a>
                <TextEllipsis
                  v-else
                  :value="item.changeAfter"
                  :key="`changeAfter_${new Date().getTime()}`"
                  :limit="2"
                ></TextEllipsis>
              </div>
            </div>
          </a-table>
          <div class="pagination">
            <a-pagination
              v-if="form.total > 0"
              v-model="form.page"
              size="small"
              :pageSize="form.size"
              :total="form.total"
              @change="getChangeHistoryList"
            />
          </div>
        </div>
      </div>
    </a-drawer>

    <ImgPreviewModal ref="ImgPreviewModal" />
    <WinnerPreviewModal ref="WinnerPreviewModal" />
  </div>
</template>

<script>
import api from "@/api/marketingActivityList";
import TextEllipsis from "@/components/TextEllipsis/TextEllipsis.vue";
import BaseImage from "@/components/BaseImage";
import defaultCoverImg from "@/assets/default/default3-4.png";
import ImgPreviewModal from "./ImgPreviewModal.vue";
import WinnerPreviewModal from "./WinnerPreviewModal.vue";
import { aliImgTransform } from "@/utils/util";

const changeTypeObject = {
  1: "活动时间",
  2: "活动简介图",
  3: "活动封面图",
  4: "视频关键词",
  5: "直播关键词",
  6: "评奖内容",
  7: "活动标题",
  8: "风火轮可见",
  9: "笔记关键词",
};

export default {
  components: { TextEllipsis, BaseImage, ImgPreviewModal, WinnerPreviewModal },
  data() {
    return {
      aliImgTransform,
      visible: false,
      loading: false,
      form: {
        activityId: "",
        page: 1,
        size: 10,
        total: 0,
      },
      columns: [
        {
          title: "变更时间",
          align: "left",
          dataIndex: "ctime",
          width: 160,
        },
        {
          title: "变更人",
          align: "left",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "变更配置",
          align: "left",
          scopedSlots: { customRender: "changeType" },
          width: 100,
        },
        {
          title: "变更前内容",
          scopedSlots: { customRender: "changeBefore" },
        },
        {
          title: "变更后内容",
          scopedSlots: { customRender: "changeAfter" },
        },
      ],
      list: [],
      changeTypeObject,
      defaultCoverImg,
    };
  },
  methods: {
    openDrawer(activityId) {
      Object.assign(this.$data, this.$options.data());
      this.form.activityId = activityId;
      this.visible = true;
      this.getChangeHistoryList();
    },
    onClose() {
      this.visible = false;
    },
    async getChangeHistoryList() {
      try {
        this.loading = true;
        const res = await api.getChangeHistoryList(this.form);
        this.loading = false;
        const { code, data, message } = res.data;
        this.list = data.list;
        this.form.total = data.total;
      } catch (error) {
        this.loading = false;
      }
    },
    showImage(url) {
      this.$refs.ImgPreviewModal.openModal(url);
    },
    openWinnerPreviewModal(changeStatus, list) {
      const winnerList = JSON.parse(list);
      this.$refs.WinnerPreviewModal.openModal(changeStatus, winnerList);
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep .ant-drawer-body {
  padding: 0;
}

// 表格
::v-deep .ant-table-header,
::v-deep
  .ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  background: #1a1d26;
}
::v-deep .ant-table-header {
  overflow-y: hidden !important;
}
::v-deep .ant-table-thead > tr > th {
  background: #242832;
  color: rgba(255, 255, 255, 0.88);
  font-size: 12px;
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
::v-deep .ant-table-tbody > tr > td {
  color: rgba(255, 255, 255, 0.88);
  border-bottom-color: rgba(255, 255, 255, 0.12);
  padding: 0;
  &:first-child,
  &:nth-child(2) {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
}
::v-deep .ant-table-placeholder {
  background: #1a1d26;
  border-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.88);
}

// 分页
::v-deep .ant-pagination-item a,
::v-deep .ant-pagination-disabled a,
::v-deep .ant-pagination-disabled:hover a,
::v-deep .ant-pagination-disabled:focus a,
::v-deep .ant-pagination-disabled .ant-pagination-item-link,
::v-deep .ant-pagination-disabled:hover .ant-pagination-item-link,
::v-deep .ant-pagination-disabled:focus .ant-pagination-item-link,
::v-deep .ant-pagination-prev a,
::v-deep .ant-pagination-next a,
::v-deep
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #9aa5be;
}
::v-deep .ant-pagination-prev:focus .ant-pagination-item-link,
::v-deep .ant-pagination-next:focus .ant-pagination-item-link,
::v-deep .ant-pagination-prev:hover .ant-pagination-item-link,
::v-deep .ant-pagination-next:hover .ant-pagination-item-link,
::v-deep
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #ffca5c;
}
::v-deep .ant-pagination-item-active {
  border: 1px solid transparent;
  background: none;
  a {
    color: #ffca5c;
  }
}

.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 56px);
    padding: 24px;

    .change-item {
      padding: 18px 0;
      height: 80px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      overflow: hidden;
      word-break: break-all;
      & + .change-item {
        border-top: 1px solid rgba(255, 255, 255, 0.08);
      }

      .link {
        color: #ffcc55;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    // 图片预览
    .preview-img-box {
      display: inline-block;
      width: 41px;
      height: 41px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      &:hover {
        .shadow-box {
          opacity: 1;
        }
      }
      .shadow-box {
        width: 100%;
        height: 100%;
        color: rgba(255, 255, 255, 0.85);
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .pagination {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
