import echarts from 'echarts'
import filters from '@/filters'

var option = {
  grid: {
    left: "25",
    right: "25",
    bottom: "0",
    top: "5%",
    containLabel: true,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        width: 2,
        color: 'rgba(255, 202, 92, .2)'
      }
    },
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
      color: '#fff',
    },
    padding: [5, 10],
    extraCssText: 'box-shadow: 0 0 5px rgba(0,0,0,0.3)',
    position: function (point, params, dom, rect, size) {
        // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
        // 提示框位置
        var x = 0; // x坐标位置
        var y = -40; // y坐标位置
      
        // 当前鼠标位置
        var pointX = point[0];
      
        // 外层div大小
        var viewWidth = size.viewSize[0];
      
        // 提示框大小
        var boxWidth = size.contentSize[0];
      
        // boxWidth > pointX 说明鼠标左边放不下提示框
        // pointX + boxWidth * .5 > viewWidth 说明鼠标右边放不下提示框
        if (boxWidth > pointX) {
            x = 5;
        } else if(pointX + boxWidth * .5 > viewWidth) {
            x = pointX - boxWidth + 20;
        } else { // 左边放的下
            x = pointX - boxWidth * .5;
        }
      
        return [x, y];
    },
    axisPointer: {
        type: 'line',
        lineStyle: {
            type: 'solid',
            color: 'rgba(255, 255, 255, .1)',
            shadowColor: 'transparent',
            shadowBlur: 0
        },
    },
    backgroundColor: 'none',
    borderWidth: '0',
    padding: 0,
    formatter: function (params, ticket, callback) {
        let _params = params[0]
        return `
            <div style="background: #3C4252;border-radius: 8px;box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);color: #fff; line-height: 1.2em;min-width: 96px; box-sizing: border-box;">
                <div style="padding: 8px 25px;font-size: 10px;position: relative;">
                    <div style="position: absolute;top: 8px;left: 5px;font-size:36px;color:${_params.color};">·</div>${_params.axisValue}
                </div>
                <div style="padding: 10px 25px;font-size: 13px;font-weight: bold;border-top: 1px solid rgba(255, 255, 255, .1);">
                    ${Number(_params.data)}
                </div>
            </div>
        `
    }
  },

  xAxis: [{
    show: true,
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: '#3C4252',
      },
    },
    splitArea: {
      // show: true,
      color: '#828282',
      lineStyle: {
        color: '#828282'
      },
    },
    axisLabel: {
      color: '#828282'
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: [],

  }],
  yAxis: [{
    type: 'value',
    splitNumber: 4,
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)'
      }
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#828282',
      },
      formatter: function(value) {
          return filters.changeNum(Number(value))
      }
    },
    axisTick: {
      show: false,
    },
  }],
  series: [{
    type: 'line',
    smooth: true,
    symbol: 'circle',
    symbolSize: 8,
    data: [],
    areaStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(255, 202, 92, 0.4)'
        }, {
          offset: 0.8,
          color: 'rgba(255, 202, 92, 0)'
        }], false),
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowBlur: 10
      },
    },
    itemStyle: {
        // 线和拐点的颜色
        opacity: 0,
        color: 'rgba(255, 202, 92, 1)',
        borderColor: 'rgba(255, 202, 92, 1)',
        borderWidth: 2, //拐点的线宽
    },
    emphasis: {
        // 鼠标hover上去的时候，拐点的颜色和样式
        itemStyle: {
            opacity: 1,
            color: '#2B2F3B',
        },
    },   

  }]
}

export default option