<template>
  <div class="wrap">
    <div class="title-box">
      <div class="title-box-left">
        <svg-icon :icon-class="'area-title'" class="icon" />
        <span class="title">正在直播</span>
        <span class="date">{{ date }}</span>
        <span class="time">{{ time }}</span>
      </div>
      <div class="title-box-right" @click="routerTo">
        <span>进入直播地图</span>
        <svg-icon class="icon" icon-class="arrow-right" />
      </div>
    </div>
    <div class="card-box">
      <div class="card1">
        <span>正在直播门店</span>
        <span>{{ liveData.living_count | changeNum }}</span>
      </div>
      <div class="card2">
        <span>全网正在直播</span>
        <span>{{ liveData.all_living_count | changeNum }}</span>
      </div>
      <div class="card1">
        <span>今日直播门店</span>
        <span>{{ liveData.live_count | changeNum }}</span>
      </div>
      <div class="card2">
        <span>全网今日直播</span>
        <span>{{ liveData.all_live_count | changeNum }}</span>
      </div>
      <div class="card1">
        <span>今日累计观看</span>
        <span>{{ liveData.live_total_watch_count | changeNum }}</span>
      </div>
      <div class="card2">
        <span>全网累计观看</span>
        <span>{{ liveData.all_live_total_watch_count | changeNum }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/bulletinBoard";
import { getPrincipalId, getUserId } from "@/utils/auth";
export default {
  name: "LeftWrap4",
  components: {},
  data() {
    return {
      date: moment().format("YYYY-MM-DD"), // 当前日期
      time: moment().format("HH:mm"), // 当前时间
      currentTimer: null, // 当前时间定时器
      liveData: {},
    };
  },
  computed: {},
  created() {
    this.initData();
    this.setCurrentTimer();
  },
  watch: {},
  methods: {
    initData() {
      this.getLiveOnlineData();
    },
    // 接口请求 - 数据趋势
    getLiveOnlineData() {
      let body = {
        params: {
          principal_id: getPrincipalId(),
          user_id: getUserId(),
        },
        platform: this.$parent.currentTab,
      };
      api.getLiveOnlineData(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getLiveOnlineData", data);
          this.liveData = data;
        } else {
          console.error("获取直播指标失败");
          this.liveData = {};
        }
      });
    },
    // 当前时间更新
    setCurrentTimer() {
      this.currentTimer = setInterval(() => {
        this.date = moment().format("YYYY-MM-DD");
        this.time = moment().format("HH:mm");
      }, 1000);
    },
    routerTo() {
      this.$router.push({
        path: "/topic/liveAreaN",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  .title-box {
    width: 100%;
    height: 64px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon {
        font-size: 22px;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        margin: 0 28px 0 10px;
      }
      .date {
        font-size: 14px;
        color: #9ca8ba;
      }
      .time {
        font-size: 14px;
        font-weight: bold;
        color: #ffca5c;
        margin-left: 14px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      color: #9ca8ba;
      &:hover {
        color: #fff;
      }
      > span {
        font-size: 12px;
      }
      > .icon {
        font-size: 24px;
      }
    }
  }
  .card-box {
    width: 100%;
    height: 82px;
    padding: 0 20px;
    display: flex;
    > div {
      flex: 1;
      margin-left: 10px;
      background: #0c0e16;
      border-radius: 12px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > span:first-child {
        font-size: 14px;
      }
      > span:last-child {
        font-size: 18px;
        font-weight: bold;
        margin-top: 12px;
      }
      &:first-child {
        margin: 0;
      }
    }
    > .card1 {
      > span:first-child {
        color: #ffca5c;
      }
      > span:last-child {
        color: #ffca5c;
      }
    }
    > .card2 {
      > span:first-child {
        color: #9ca8ba;
      }
      > span:last-child {
        color: #ffffff;
      }
    }
  }
}
</style>
