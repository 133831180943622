<template>
  <div>
    <platform-header
      title="矩阵透视"
      :currentTab="currentTab"
      :isAll="true"
      @tabChange="tabChange"
    />
    <div class="tools-div">
      <a-select
        v-model="area_id"
        :suffixIcon="suffixIcon"
        style="width: 200px; height: 40px"
        placeholder="选择大区"
        @change="changeRegion"
      >
        <a-select-option
          :value="item.value"
          v-for="item in regionTabs"
          :key="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
      <div class="tools-div-right">
        <a-week-picker
          class="aft-range-picker default"
          placeholder="Select week"
          v-model="searchWeekTime"
          :disabled-date="disabledDate"
          @change="onChangeWeek"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-week-picker>
        <span class="setting-button" @click="openSettingDrawer">
          <svg-icon icon-class="setting-fill" />
        </span>
      </div>
    </div>
    <a-spin :spinning="chartLoading">
      <div class="center-container">
        <div class="center-container-title">
          <span>短</span>
          <span>视</span>
          <span>频</span>
          <span>能</span>
          <span>力</span>
        </div>
        <div class="center-container-main">
          <div class="y-col">
            <span
              :class="['y-item', currentY == item.value ? 'active' : '']"
              v-for="(item, index) in yData"
              :key="index"
              @click="yClick(item)"
              >{{ item.label }}</span
            >
          </div>
          <div class="grid-container">
            <div class="grid-container-main">
              <div
                :class="[
                  'grid-item',
                  item.aweme_level == currentY ? 'y-active' : '',
                  item.live_level == currentX ? 'x-active' : '',
                ]"
                v-for="(item, index) in chartData"
                :key="index"
                @click="gridClick(item)"
              >
                <span
                  :class="['tag', `tag${i.clue_level}`]"
                  v-for="(i, idx) in item.ceil"
                  :key="idx"
                >
                  <a-popover trigger="hover">
                    <template slot="content">
                      <div class="store-list">
                        <div
                          v-for="(name, idx2) in i.dealer_list"
                          :key="idx2"
                          @click="openDetail(name.dealer_id)"
                        >
                          {{ name.dealer_name }}
                        </div>
                      </div>
                    </template>
                    {{ i.dealer_count }}
                  </a-popover>
                </span>
              </div>
            </div>
            <div class="x-col">
              <span
                :class="['x-item', currentX == item.value ? 'active' : '']"
                v-for="(item, index) in xData"
                :key="index"
                @click="xClick(item)"
                >{{ item.label }}</span
              >
            </div>
            <div class="x-title">直播能力</div>
          </div>
        </div>
        <div class="center-container-right">
          <div class="legend-box">
            <div class="legend-title">短视频能力</div>
            <div class="legend-title2">
              {{ detailData.aweme[0].indicator_desc_name || "-" }}
            </div>
            <div class="legend-main">
              <div class="legend-main-line"></div>
              <div
                class="legend-main-item"
                v-for="(item, index) in ['S', 'A', 'B', 'C', 'D']"
                :key="index"
              >
                <span class="legend-main-item-tag">{{ item }}</span>
                <span class="legend-main-item-text">{{
                  getLegendValue("aweme", 5 - index)
                }}</span>
              </div>
            </div>
          </div>
          <div class="legend-box">
            <div class="legend-title">直播能力</div>
            <div class="legend-title2">
              {{ detailData.live[0].indicator_desc_name || "-" }}
            </div>
            <div class="legend-main">
              <div class="legend-main-line"></div>
              <div
                class="legend-main-item"
                v-for="(item, index) in ['Ⅴ', 'Ⅳ', 'Ⅲ', 'Ⅱ', 'Ⅰ']"
                :key="index"
              >
                <span class="legend-main-item-tag">{{ item }}</span>
                <span class="legend-main-item-text">{{
                  getLegendValue("live", 5 - index)
                }}</span>
              </div>
            </div>
          </div>
          <div class="legend-box">
            <div class="legend-title">留资能力</div>
            <div class="legend-title2">
              {{ detailData.clue[0].indicator_desc_name || "-" }}
            </div>
            <div class="legend-main">
              <div class="legend-main-line"></div>
              <div
                class="legend-main-item"
                v-for="(item, index) in ['5', '4', '3', '2', '1']"
                :key="index"
              >
                <span :class="`legend-main-item-tag tag${item}`"></span>
                <span class="legend-main-item-text">{{
                  getLegendValue("clue", 5 - index)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="center-container-tips">
          <svg-icon class="icon-tips" icon-class="tips2" />
          点击上方图表中的区间，即可查看区间内门店数量分布
        </div>
      </div>
    </a-spin>
    <div class="bottom-container" v-show="isShow">
      <div class="bottom-container-title">门店数量分布</div>
      <div class="current-title">
        <div class="current-title-item" v-if="currentY">
          <span class="tag">{{ yData[5 - Number(currentY)].label }}</span>
          <span class="title">{{
            detailData.aweme[0].indicator_desc_name
          }}</span>
          <span class="value">{{ getLegendValue("aweme", currentY) }}</span>
        </div>
        <div class="current-title-item" v-if="currentX">
          <span class="tag">{{ xData[Number(currentX) - 1].label }}</span>
          <span class="title">{{
            detailData.live[0].indicator_desc_name
          }}</span>
          <span class="value">{{ getLegendValue("live", currentX) }}</span>
        </div>
      </div>
      <div class="count-box">
        <div class="count-box-item">{{ currentAllData["5"].length || 0 }}</div>
        <div class="count-box-item">{{ currentAllData["4"].length || 0 }}</div>
        <div class="count-box-item">{{ currentAllData["3"].length || 0 }}</div>
        <div class="count-box-item">{{ currentAllData["2"].length || 0 }}</div>
        <div class="count-box-item">{{ currentAllData["1"].length || 0 }}</div>
      </div>
      <div class="card-container">
        <div class="card-item">
          <div class="title">
            门店 · {{ getLegendValue("clue", "5") }}
            {{ detailData.clue[0].indicator_desc_name || "-" }}
          </div>
          <div
            class="store-container"
            v-if="currentAllData['5'] && currentAllData['5'].length > 0"
          >
            <div
              class="store-item"
              v-for="(item, index) in currentAllData['5']"
              :key="index"
              @click="openDetail(item.dealer_id)"
            >
              <span>{{ item.dealer_name }}</span>
              <span>{{ item.clue_count | changeNum }}</span>
            </div>
          </div>
          <Empty style="margin-top: 80px" v-else />
        </div>
        <div class="card-item">
          <div class="title">
            门店 ·
            {{ getLegendValue("clue", "4") }}
            {{ detailData.clue[0].indicator_desc_name || "-" }}
          </div>
          <div
            class="store-container"
            v-if="currentAllData['4'] && currentAllData['4'].length > 0"
          >
            <div
              class="store-item"
              v-for="(item, index) in currentAllData['4']"
              :key="index"
              @click="openDetail(item.dealer_id)"
            >
              <span>{{ item.dealer_name }}</span>
              <span>{{ item.clue_count | changeNum }}</span>
            </div>
          </div>
          <Empty style="margin-top: 80px" v-else />
        </div>
        <div class="card-item">
          <div class="title">
            门店 ·
            {{ getLegendValue("clue", "3") }}
            {{ detailData.clue[0].indicator_desc_name || "-" }}
          </div>
          <div
            class="store-container"
            v-if="currentAllData['3'] && currentAllData['3'].length > 0"
          >
            <div
              class="store-item"
              v-for="(item, index) in currentAllData['3']"
              :key="index"
              @click="openDetail(item.dealer_id)"
            >
              <span>{{ item.dealer_name }}</span>
              <span>{{ item.clue_count | changeNum }}</span>
            </div>
          </div>
          <Empty style="margin-top: 80px" v-else />
        </div>
        <div class="card-item">
          <div class="title">
            门店 ·
            {{ getLegendValue("clue", "2") }}
            {{ detailData.clue[0].indicator_desc_name || "-" }}
          </div>
          <div
            class="store-container"
            v-if="currentAllData['2'] && currentAllData['2'].length > 0"
          >
            <div
              class="store-item"
              v-for="(item, index) in currentAllData['2']"
              :key="index"
              @click="openDetail(item.dealer_id)"
            >
              <span>{{ item.dealer_name }}</span>
              <span>{{ item.clue_count | changeNum }}</span>
            </div>
          </div>
          <Empty style="margin-top: 80px" v-else />
        </div>
        <div class="card-item">
          <div class="title">
            门店 · {{ getLegendValue("clue", "1") }}
            {{ detailData.clue[0].indicator_desc_name || "-" }}
          </div>
          <div
            class="store-container"
            v-if="currentAllData['1'] && currentAllData['1'].length > 0"
          >
            <div
              class="store-item"
              v-for="(item, index) in currentAllData['1']"
              :key="index"
              @click="openDetail(item.dealer_id)"
            >
              <span>{{ item.dealer_name }}</span>
              <span>{{ item.clue_count | changeNum }}</span>
            </div>
          </div>
          <Empty style="margin-top: 80px" v-else />
        </div>
      </div>
    </div>
    <SettingDrawer
      ref="SettingDrawer"
      :platform="currentTab"
      :detailData="detailData"
      @getDetail="getPerspectiveDetail"
    />
  </div>
</template>

<script>
import api from "@/api/matrixPerspective.js";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import SettingDrawer from "./components/SettingDrawer";
import Empty from "@/components/Empty/Empty";
import demoData from "./demo.js";
import deepClone from "@/utils/clone-utils";
import { getPrincipalId } from "@/utils/auth";

export default {
  name: "matrixDetail",
  components: {
    PlatformHeader,
    SettingDrawer,
    Empty
  },
  data() {
    return {
      msg: "矩阵透视",
      currentTab: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      disabledDate(current) {
        return current && current >= moment().startOf("week");
      },
      area_id: "",
      regionTabs: [
        {
          label: "全部区域",
          value: "",
        },
      ],
      searchWeekTime: moment().subtract(7, "days"),
      yData: [
        {
          label: "S",
          value: "5",
        },
        {
          label: "A",
          value: "4",
        },
        {
          label: "B",
          value: "3",
        },
        {
          label: "C",
          value: "2",
        },
        {
          label: "D",
          value: "1",
        },
      ],
      xData: [
        {
          label: "Ⅰ",
          value: "1",
        },
        {
          label: "Ⅱ",
          value: "2",
        },
        {
          label: "Ⅲ",
          value: "3",
        },
        {
          label: "Ⅳ",
          value: "4",
        },
        {
          label: "Ⅴ",
          value: "5",
        },
      ],
      currentX: "",
      currentY: "",
      detailData: {
        aweme: [{ level_info_data: {} }],
        live: [{ level_info_data: {} }],
        clue: [{ level_info_data: {} }],
      },
      chartData: demoData,
      chartLoading: false,
      currentAllData: {
        5: [],
        4: [],
        3: [],
        2: [],
        1: [],
      },
      isShow: false,
    };
  },
  computed: {},
  created() {
    this.getAreaList();
    // this.getPerspectiveDetail();
  },
  methods: {
    tabChange(val) {
      this.currentTab = val;
      this.getPerspectiveDetail();
    },
    // 获取大区列表
    getAreaList() {
      api.getAreaList().then((res) => {
        if (res && res.length > 0) {
          this.regionTabs = this.regionTabs.concat(res);
        }
      });
    },
    // 获取详情
    getPerspectiveDetail() {
      let params = {
        platform:
          this.currentTab == "douyin"
            ? "1"
            : this.currentTab == "kuaishou"
            ? "2"
            : this.currentTab == "dongchedi"
            ? "3"
            : "0",
      };
      api.getPerspectiveDetail(params).then((res) => {
        let { code, data } = res.data;
        if (code == 0) {
          this.detailData = data;
          this.getPerspectiveChart();
        } else {
          this.$message.warning("获取指标详情失败！");
        }
      });
    },
    // 矩阵chart接口
    getPerspectiveChart() {
      // 清除当前选中x、y轴
      this.currentX = "";
      this.currentY = "";
      // 清除底部汇总数据
      this.currentAllData = {
        5: [],
        4: [],
        3: [],
        2: [],
        1: [],
      };

      let params = {
        platform:
          this.currentTab == "douyin"
            ? "1"
            : this.currentTab == "kuaishou"
            ? "2"
            : this.currentTab == "dongchedi"
            ? "3"
            : "0",
        principal_id: getPrincipalId(),
        area_id: this.area_id || "",
        live_indicator_id: this.detailData.live[0].id,
        aweme_indicator_id: this.detailData.aweme[0].id,
        clue_indicator_id: this.detailData.clue[0].id,
        start_time:
          this.searchWeekTime.isoWeekday(1).format("YYYY-MM-DD") + " 00:00:00",
        end_time:
          this.searchWeekTime.isoWeekday(7).format("YYYY-MM-DD") + " 23:59:59",
      };
      this.chartLoading = true;
      api.getPerspectiveChart(params).then((res) => {
        this.chartLoading = false;
        let { code, data, msg } = res.data;
        if (code == 0) {
          this.chartData = data;
        } else if (code == 6000) {
          this.chartData = deepClone(demoData);
          this.$message.warning(msg || "获取矩阵透视失败！");
        } else {
          this.chartData = deepClone(demoData);
          this.$message.warning("获取矩阵透视失败！");
        }
      });
    },
    // 切换大区
    changeRegion() {
      this.getPerspectiveChart();
    },
    // 日期切换
    onChangeWeek() {
      this.getPerspectiveChart();
    },
    // x轴点击
    xClick(item) {
      if (this.currentX == item.value) {
        this.currentX = "";
      } else {
        this.currentX = item.value;
      }
      this.getGridAllData();
    },
    // y轴点击
    yClick(item) {
      if (this.currentY == item.value) {
        this.currentY = "";
      } else {
        this.currentY = item.value;
      }
      this.getGridAllData();
    },
    // 格子点击
    gridClick(item) {
      this.isShow = true;
      this.currentX = item.live_level;
      this.currentY = item.aweme_level;
      this.getGridAllData();
    },
    sortList(arr) {
      let newArr = deepClone(arr);
      newArr.sort((a, b) => {
        return b.clue_count - a.clue_count;
      });
      return newArr;
    },
    // 获取选中所有数据
    getGridAllData() {
      this.currentAllData = {
        5: [],
        4: [],
        3: [],
        2: [],
        1: [],
      };
      if (this.currentX && this.currentY) {
        this.chartData.forEach((item) => {
          if (
            this.currentX == item.live_level &&
            this.currentY == item.aweme_level
          ) {
            if (item.ceil && item.ceil.length > 0) {
              item.ceil.forEach((i) => {
                this.currentAllData[i.clue_level] = this.currentAllData[
                  i.clue_level
                ].concat(i.dealer_list);
              });
            }
          }
        });
      } else {
        this.chartData.forEach((item) => {
          if (
            this.currentX == item.live_level ||
            this.currentY == item.aweme_level
          ) {
            if (item.ceil && item.ceil.length > 0) {
              item.ceil.forEach((i) => {
                this.currentAllData[i.clue_level] = this.currentAllData[
                  i.clue_level
                ].concat(i.dealer_list);
              });
            }
          }
        });
      }
      this.currentAllData = {
        5: this.sortList(this.currentAllData[5]),
        4: this.sortList(this.currentAllData[4]),
        3: this.sortList(this.currentAllData[3]),
        2: this.sortList(this.currentAllData[2]),
        1: this.sortList(this.currentAllData[1]),
      };
    },
    // 获取格式化legend
    getLegendValue(type, value) {
      if (value == 1) {
        return (
          "≤" +
          this.$options.filters.changeNum(
            Number(this.detailData[type][0].level_info_data.dvalue)
          )
        );
      }
      if (value == 4) {
        return (
          this.$options.filters.changeNum(
            Number(this.detailData[type][0].level_info_data.aleft_value)
          ) +
          "-" +
          this.$options.filters.changeNum(
            Number(this.detailData[type][0].level_info_data.aright_value)
          )
        );
      }
      if (value == 3) {
        return (
          this.$options.filters.changeNum(
            Number(this.detailData[type][0].level_info_data.bleft_value)
          ) +
          "-" +
          this.$options.filters.changeNum(
            Number(this.detailData[type][0].level_info_data.bright_value)
          )
        );
      }
      if (value == 2) {
        return (
          this.$options.filters.changeNum(
            Number(this.detailData[type][0].level_info_data.cleft_value)
          ) +
          "-" +
          this.$options.filters.changeNum(
            Number(this.detailData[type][0].level_info_data.cright_value)
          )
        );
      }
      if (value == 5) {
        return (
          ">" +
          this.$options.filters.changeNum(
            Number(this.detailData[type][0].level_info_data.svalue)
          )
        );
      }
    },
    openSettingDrawer() {
      this.$refs.SettingDrawer.showDrawer();
    },
    openDetail(id) {
      let url = `/dealer/dealerDetail?id=${id}`;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tools-div {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  &-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .setting-button {
    width: 40px;
    height: 40px;
    background: #2b2f3b;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    color: #687486;
    margin-left: 10px;
  }
}
.center-container {
  position: relative;
  width: calc(100% - 40px);
  height: 644px;
  margin-left: 20px;
  background: #2b2f3b;
  border-radius: 8px;
  padding: 32px 0 60px 0;
  display: flex;
  flex-wrap: nowrap;

  &-title {
    width: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.88);
    line-height: 22px;
  }

  &-main {
    width: calc(100% - 370px);
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .y-col {
      display: flex;
      flex-direction: column;
      .y-item {
        width: 36px;
        height: 94px;
        background: #242832;
        border: 1px solid #363a46;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        line-height: 94px;
        text-align: center;
        border-left: none;
        cursor: pointer;
        position: relative;
        z-index: 10;
        &:nth-child(2) {
          top: -1px;
        }
        &:nth-child(3) {
          top: -2px;
        }
        &:nth-child(4) {
          top: -3px;
        }
        &:nth-child(5) {
          top: -4px;
        }
        &.active {
          background: #ffcc55;
          border: none;
          color: rgba(0, 0, 0, 0.88);
          z-index: 20;
        }
      }
    }
    .grid-container {
      width: calc(100% - 36px);
      height: 100%;
      display: flex;
      flex-direction: column;
      &-main {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        .grid-item {
          height: 94px;
          padding: 17px 10px;
          background: #2b2f3b;
          border: 1px solid #363a46;
          cursor: pointer;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 24px;
          row-gap: 12px;
          position: relative;
          z-index: 10;
          &:nth-child(n + 6) {
            top: -1px;
          }
          &:nth-child(n + 11) {
            top: -2px;
          }
          &:nth-child(n + 16) {
            top: -3px;
          }
          &:nth-child(n + 21) {
            top: -4px;
          }
          &.x-active,
          &.y-active {
            z-index: 20;
            background: #403f3e;
            border-color: #4a4848;
          }
          &.x-active.y-active {
            z-index: 30;
            background: #534d41;
            border-color: #ffcc55;
          }

          .tag {
            width: 24px;
            height: 24px;
            display: inline-block;
            border-radius: 12px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.88);
            line-height: 24px;
            text-align: center;
            margin: 0 auto;
            > span {
              width: 24px;
              height: 24px;
              display: inline-block;
            }
            &.tag1 {
              background: #c7c7c7;
            }
            &.tag2 {
              background: #fce7b8;
            }
            &.tag3 {
              background: #fdd37b;
            }
            &.tag4 {
              background: #f1a74c;
            }
            &.tag5 {
              background: #f48a6d;
            }
          }
        }
      }
      .x-col {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        .x-item {
          height: 36px;
          background: #242832;
          border: 1px solid #363a46;
          border-bottom: none;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.88);
          line-height: 36px;
          text-align: center;
          cursor: pointer;
          position: relative;
          z-index: 10;
          top: -5px;
          &.active {
            background: #ffcc55;
            border: none;
            color: rgba(0, 0, 0, 0.88);
            z-index: 20;
          }
        }
      }
      .x-title {
        width: 100%;
        height: 22px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        line-height: 22px;
        text-align: right;
        margin-top: 3px;
        padding-right: 4px;
      }
    }
  }

  &-right {
    width: 320px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 26px;
    .legend-box {
      width: 130px;
      height: 279px;
      // background: red;
      &:first-child {
        margin-right: 34px;
      }
      .legend-title {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        line-height: 22px;
      }
      .legend-title2 {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.45);
        line-height: 20px;
        margin-bottom: 13px;
      }
      .legend-main {
        position: relative;
        display: flex;
        flex-direction: column;
        &-line {
          position: absolute;
          width: 2px;
          height: 200px;
          background: #ffcc55;
          opacity: 0.3;
          left: 11px;
          z-index: 10;
        }
        &-item {
          width: 100%;
          height: 24px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          position: relative;
          z-index: 20;
          &-tag {
            width: 24px;
            height: 24px;
            background: #141414;
            border: 2px solid #ffcc55;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.88);
            line-height: 20px;
            margin-right: 8px;
            text-align: center;
            border-radius: 50%;
            &.tag1 {
              border: none;
              background: #c7c7c7;
            }
            &.tag2 {
              border: none;
              background: #fce7b8;
            }
            &.tag3 {
              border: none;
              background: #fdd37b;
            }
            &.tag4 {
              border: none;
              background: #f1a74c;
            }
            &.tag5 {
              border: none;
              background: #f48a6d;
            }
          }
          &-text {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.88);
            line-height: 24px;
          }
        }
      }
    }
  }

  &-tips {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    bottom: 20px;
    left: 20px;
    z-index: 1;
    padding: 0 14px;
    height: 40px;
    color: rgba(255, 255, 255, 0.88);
    font-size: 14px;
    font-weight: 400;
    line-height: 22;
    background: rgba(60, 126, 255, 0.2);
    border-radius: 6px;

    .icon-tips {
      margin-right: 7px;
      font-size: 16px;
    }
  }
}
.bottom-container {
  width: calc(100% - 40px);
  background: #2b2f3b;
  border-radius: 8px;
  padding: 20px;
  margin: 16px 20px;
  &-title {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.88);
    line-height: 24px;
  }
  .current-title {
    height: 24px;
    display: flex;
    margin: 20px 0 15px;
    &-item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .tag {
        width: 24px;
        height: 24px;
        background: #141414;
        border: 2px solid #ffcc55;
        text-align: center;
        border-radius: 50%;
        line-height: 20px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
      }
      .title {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.45);
        line-height: 24px;
        margin: 0 8px;
      }
      .value {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        line-height: 24px;
      }
    }
  }
  .count-box {
    width: 100%;
    height: 34px;
    display: flex;
    margin-bottom: 14px;
    &-item {
      width: 100px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.88);
      &:nth-child(1) {
        background: #f48a6d;
      }
      &:nth-child(2) {
        background: #f1a74c;
      }
      &:nth-child(3) {
        background: #fdd37b;
      }
      &:nth-child(4) {
        background: #fce7b8;
      }
      &:nth-child(5) {
        background: #c7c7c7;
      }
    }
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 13px;
    .card-item {
      width: 100%;
      height: 370px;
      background: #242732;
      border-radius: 0 0 4px 4px;
      margin-right: 13px;
      margin-top: 3px;
      border-top: 4px solid;
      &:nth-child(1) {
        border-color: #f48a6d;
      }
      &:nth-child(2) {
        border-color: #f1a74c;
      }
      &:nth-child(3) {
        border-color: #fdd37b;
      }
      &:nth-child(4) {
        border-color: #fce7b8;
      }
      &:nth-child(5) {
        border-color: #c7c7c7;
      }
      .title {
        height: 54px;
        width: 100%;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        line-height: 54px;
        padding: 0 16px;
      }
      .store-container {
        height: 312px;
        width: 100%;
        overflow-y: auto;
        .store-item {
          cursor: pointer;
          width: 100%;
          height: 48px;
          line-height: 48px;
          padding: 0 16px;
          box-shadow: inset 0px -1px 0px 0px #363a46;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.88);
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          > span:first-child {
            position: absolute;
            top: 0;
            left: 16px;
            width: calc(100% - 100px);
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > span:last-child {
            position: absolute;
            top: 0;
            right: 16px;
            width: 80px;
            height: 100%;
            text-align: right;
          }
        }
      }
    }
  }
}

.store-list {
  width: 238px;
  max-height: 228px;
  background: #2b2f3b;
  overflow-y: auto;
  > div {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.88);
    line-height: 22px;
    margin-top: 4px;
    cursor: pointer;
  }
}

@media (max-width: 2160px) {
  .bottom-container {
    .card-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (max-width: 1800px) {
  .bottom-container {
    .card-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
