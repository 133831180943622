<template>
  <div class="model-card-container">
    <div class="model-card-container-title"><svg-icon :icon-class="'title'" class="card-title-icon"></svg-icon>互动声量</div>
    <div class="model-card-container-main">
      <img :src="modelData.image" width="210" alt="" />
      <div>
        <span class="total">{{ modelData.gap_total | changeNum }}</span>
        <br />
        <span>
          <span class="rate">{{ (Math.abs(modelData.gap_rate) * 100) | disposeDecimal }}%</span>
          <a-icon v-if="modelData.gap_rate >= 0" type="rise" class="icon-up" />
          <a-icon v-else type="fall" class="icon-down" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModelCard",
  components: {},
  props: {
    modelData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_handle.scss';
.model-card-container {
  width: 100%;
  height: 100%;
  &-title {
    font-size: 16px;
    margin-bottom: 16px;
    @include font_color("font_color2");
    .card-title-icon {
      @include font_color("font_color5");
      font-size: 18px;
      margin: 0 8px;
    }
  }
  &-main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    > div .total {
      font-size: 24px;
      font-weight: bold;
      @include font_color("font_color4");
    }
    .rate {
      font-size: 12px;
      @include font_color("font_color2");
    }
    .icon-up {
      font-size: 12px;
      @include font_color("font_color7");
      position: relative;
      top: 2px;
      left: 4px;
    }
    .icon-down {
      font-size: 12px;
      @include font_color("font_color8");
      position: relative;
      top: 2px;
      left: 4px;
    }
  }
}
</style>
