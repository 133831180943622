var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"effect-detail-container"},[_c('DetailTitle',{attrs:{"skewData":_vm.skewData,"rightValue":_vm.dateStr}}),_c('StatisticsCard',{attrs:{"title":'数据趋势',"cardData":_vm.cardData,"routerData":{
      name: '闭环管理',
      path: '/dealer/closedLoop',
    },"tooltip":true,"code":'xxqt01'}}),_c('div',{staticClass:"dealer-contribution"},[_c('div',{staticClass:"title"},[_vm._v("经销商贡献")]),_c('div',{staticClass:"chart-main"},_vm._l((_vm.contributionData),function(item,index){return _c('div',{key:index,staticClass:"chart-main-item"},[_c('TitlePie',{attrs:{"id":item.id,"title":{
            title: item.title,
            icon: item.icon,
          },"chartData":_vm.pieData[item.key]}})],1)}),0)]),_c('div',{staticClass:"bottom-main"},[_c('div',{staticClass:"best-store"},[_c('BestStore',{attrs:{"list":_vm.storeList}})],1),_c('div',{staticClass:"bottom-main-right"},[_c('ClueBestCard',{attrs:{"data":_vm.someStoreData}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }