import Nr from "@/utils/NetworldRequest";
const request = new Nr().getAxios();
function getBaseUrl(serviceName) {
  return new Nr().getBaseUrl(serviceName);
}

const activity_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API,
}).getAxios();

export default {
  // 每日战报-根据活动id获取活动战报日期列表
  getActivityDateList(params) {
    return activity_request.get("/jjt/activity/web/alert/report/date", {
      params,
    });
  },

  // 每日战报-根据活动id获取活动战报日期列表
  getActivityDailyAchievements(params) {
    return activity_request.get("/jjt/activity/web/alert/report/details", {
      params,
    });
  },

  // 每日战报-活动零产门店导出
  getNoJoinDealersExport(params) {
    return activity_request.get(
      "/jjt/activity/web/alert/report/noJoinDealersExport",
      {
        params,
      }
    );
  },
};
