<template>
  <div class="chart-modal" v-show="visible" @click="visible = false">
    <div class="chart-modal-container" @click.stop>
      <div class="flex-between">
        <span class="title">车系统计</span>
        <a-icon class="icon" type="close" @click="visible = false" />
      </div>
      <a-spin :spinning="loading" class="list-container">
        <div
          class="chart-div"
          v-show="!loading && chartData && chartData.length > 0"
        >
          <my-chart
            :options="getPieOption(chartData)"
            :id="'pie-chart'"
            :defaultStyle="{
              width: '678px',
              height: '368px',
            }"
          ></my-chart>
        </div>
        <Empty
          v-show="(!chartData || chartData.length === 0) && !loading"
          style="margin-top: 120px"
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import api from "@/api/closedLoop";
import chartPie from "../options/carSeriesPie";
import deepClone from "@/utils/clone-utils";
import Empty from "@/components/Empty/Empty";

export default {
  components: {
    MyChart,
    Empty,
  },
  data() {
    return {
      visible: false,
      loading: false,
      chartData: [],
    };
  },
  methods: {
    async openModal(data) {
      Object.assign(this.$data, this.$options.data());
      this.visible = true;
      this.loading = true;
      await this.getDetailBySeries(data);
      this.loading = false;
    },
    getPieOption(data) {
      let option = deepClone(chartPie);
      option.series[0].data = data;
      return option;
    },
    getDetailBySeries(data) {
      return api.getDetailBySeries(data).then((res) => {
        let { code, data } = res.data;
        if (code == 0) {
          this.chartData = data.map((item) => {
            return {
              name: item.series_name,
              value: item.count,
            };
          });
        } else {
          this.$message.warning("获取车系统计失败");
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";
.chart-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 100;
  &-container {
    position: absolute;
    border-radius: 16px;
    top: calc(50vh - 194px);
    right: calc(50% - 300px);
    width: 726px;
    height: 438px;
    padding: 24px;
    background: #2b2f3b;
    @include box_shadow("box_shadow4");
    .flex-between {
      height: 24px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
      }
      .icon {
        font-size: 16px;
        color: #818388;
        cursor: pointer;
      }
    }
    .list-container {
      height: 368px;
    }
  }
}
</style>
