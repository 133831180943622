<template>
  <div>
    <!-- 矩阵活跃 -->
    <div class="wrap">
      <div class="flex-between mb20">
        <IconTitle :icon="'ti-matrix'" :title="'矩阵活跃'" :type="1" />
        <RouterButton
          :title="'门店趋势统计'"
          :icon="'rb-regional-stores'"
          :path="'/dealer/storeTrend'"
        />
      </div>
      <div class="box-main matrix-main">
        <img src="@/assets/img/accountLevel.png" alt="" />
        <div class="level-box">
          <div class="level-title">
            <span>各等级账号数:</span>
            <span class="current-month">{{ getMonth(1) }}月评级</span>
            <span class="level-history" @click="handleVisible">
              历史评级 <a-icon type="right" />
            </span>
          </div>

          <div class="chart-main">
            <div class="chart-main-left">
              <div>S</div>
              <div>A</div>
              <div>B</div>
              <div>C</div>
              <div>D</div>
            </div>
            <div class="chart-main-right">
              <my-chart
                ref="level_chart"
                :id="'level_chart'"
                :options="getLevelChartOption(levelChartData)"
              />
            </div>
          </div>
        </div>
        <div class="data-box">
          <div class="flex-between">
            <div>
              <span>账号/门店</span>
              <button-tab
                :tabData="levelData"
                @tabChange="levelChange"
                :defaultBtnStyle="levelButtonStyle"
              ></button-tab>
            </div>
            <div>
              <span>{{ getMonth(2) }}</span>
              <span>当月最新情况</span>
            </div>
          </div>
          <div class="card-list">
            <IconCard
              v-for="(item, index) in levelCardData"
              :key="index"
              :icon="item.icon"
              :background="item.background"
              :color="item.color"
              :name="item.name"
              :value="item.value"
              :tooltipTitle="item.tooltipTitle"
            >
              <div v-if="item.tooltipTitle">{{ item.tooltipTitle }}</div>
            </IconCard>
          </div>
        </div>
        <a-spin :spinning="spinning" class="spin"> </a-spin>
      </div>
    </div>
    <a-modal
      wrapClassName="level-history-modal"
      title="账号历史评级"
      :visible="visible"
      :footer="null"
      :width="720"
      :bodyStyle="{ paddingTop: 0 }"
      @cancel="handleVisible"
    >
      <div class="history-modal-content">
        <div class="history-modal-setting">
          <a-popover
            trigger="hover"
            overlayClassName="history-modal-popover"
            placement="bottom"
          >
            <template slot="content">
              <div>选择月份（{{ levelHistoryValue.length }}/3）</div>
              <a-checkbox-group
                v-model="levelHistoryValue"
                @change="onChangeLevelHistoryValue"
              >
                <a-checkbox
                  v-for="item in settingMonth"
                  :key="item"
                  :value="item"
                  :disabled="
                    levelHistoryValue.length > 2 &&
                      !levelHistoryValue.includes(item)
                  "
                >
                  {{ item }}
                </a-checkbox>
              </a-checkbox-group>
            </template>
            <span class="setting-icon">
              <a-icon type="setting" /> 设置对比月
            </span>
          </a-popover>
        </div>
        <my-chart
          v-if="visible"
          id="level-history-chart"
          ref="levelHistoryChart"
          :options="getLevelHistoryOption()"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "dealerOfOneself"
);
import deepClone from "@/utils/clone-utils";
import { generateLastMonths } from "@/utils";
import moment from "moment";
import chartLevel from "../chartLevel";
import chartBarDataset from "../chartBarDataset";
// import echarts from "echarts";
import MyChart from "@/components/MyChart/MyChart";
import IconCard from "@/components/IconCard/IconCard";
import IconTitle from "@/components/IconTitle/IconTitle";
import RouterButton from "@/components/RouterButton/RouterButton";
import ButtonTab from "@/components/PlatformTab/NewButtonTab";
import api from "@/api/dealerOfOneself";
const levelButtonStyle = {
  minWidth: "56px",
  width: "auto",
  height: "28px",
  fontSize: "12px",
};
export default {
  name: "MatrixBox",
  components: {
    IconCard,
    IconTitle,
    RouterButton,
    MyChart,
    ButtonTab,
  },
  props: {
    platform: {
      type: String,
      default: "douyin",
    },
  },
  data() {
    return {
      spinning: false,
      levelButtonStyle,
      levelData: [
        {
          label: "全部等级",
          value: "全部",
        },
        {
          label: "S级",
          value: "S",
        },
        {
          label: "A级",
          value: "A",
        },
        {
          label: "B级",
          value: "B",
        },
        {
          label: "C级",
          value: "C",
        },
        {
          label: "D级",
          value: "D",
        },
      ],
      currentLevel: "全部",
      levelCardData: [
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-account-b",
          name: "活跃账号",
          value: "-",
          tooltipTitle: "当月直播场次或视频发布大于0的账号数",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-store-b",
          name: "活跃门店",
          value: "-",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-store-b",
          name: "门店活跃比例",
          value: "-",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-account-b",
          name: "账号总数",
          value: "-",
        },
        {
          background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
          color: "#1E3C6E",
          icon: "ci-store-b",
          name: "门店数量",
          value: "-",
        },
        // 7-31隐藏,产品需求
        // {
        //   background: "linear-gradient(270deg, #81AFFF 0%, #B7D8FF 100%)",
        //   color: "#1E3C6E",
        //   icon: "ci-store-b",
        //   name: "门店占比",
        //   value: "-",
        // },
      ],
      levelChartData: {
        xData: ["S", "A", "B", "C", "D"],
        yData: [],
      },
      visible: false,
      levelHistoryValue: generateLastMonths(3),
      levelHistoryData: [],
    };
  },
  computed: {
    ...mapState({
      // accountData: (state) => state.accountData, // 品牌数据
      matrixData: (state) => state.matrixData, // 矩阵活跃数据
    }),
    settingMonth() {
      return generateLastMonths();
    },
  },
  created() {},
  methods: {
    ...mapActions(["getMatrixActivation"]),
    ...mapMutations(["changeState"]),
    handleVisible() {
      this.visible = !this.visible;
    },
    onChangeLevelHistoryValue() {
      this.getHistoryLevelData(this.platform);
    },
    getHistoryLevelData(platform) {
      const payload = {
        params: {
          year_month: this.levelHistoryValue.join().replaceAll("-", ""),
        },
        platform,
      };
      api.getHistoryLevel(payload).then((res) => {
        this.levelHistoryData = res;
        this.$ref?.levelHistoryChart?.resizeChart();
      });
    },
    sortDate(datesArray) {
      const momentsArray = datesArray.map((dateString) => moment(dateString));
      momentsArray.sort(
        (a, b) => Math.abs(b.diff(moment())) - Math.abs(a.diff(moment()))
      );
      return momentsArray.map((momentObj) => momentObj.format("YYYY-MM"));
    },
    getLevelHistoryOption() {
      let option = null;
      if (!option) {
        option = deepClone(chartBarDataset);
      }
      option.color = ["#FAC858", "#93BEFF", "#507AFC"];
      option.dataset.dimensions = [
        "level",
        ...this.sortDate(this.levelHistoryValue),
      ];
      option.dataset.source = this.levelHistoryData;
      const barBorderRadius = [20, 20, 0, 0];
      const series = {
        type: "bar",
        barGap: 0,
        cursor: "default",
        label: {
          show: true,
          position: "top",
        },
        itemStyle: {
          barBorderRadius,
        },
      };
      const levelHistoryValueLength = this.levelHistoryValue.length;
      option.series = [];
      for (let index = 0; index < levelHistoryValueLength; index++) {
        option.series.push({
          ...series,
        });
      }
      return option;
    },
    // 等级切换
    levelChange(val) {
      this.currentLevel = val;
      this.setMatrixData();
    },
    async getMatrixActivationData(platform) {
      this.spinning = true;
      await this.getMatrixActivation({ platform: platform });
      this.setMatrixData(true);
      this.spinning = false;
    },
    setMatrixData(type) {
      let newYData = [];
      this.matrixData.forEach((item, index) => {
        if (type && item.level) {
          newYData.push(item.account_count);
        }
        if (item.level_name == this.currentLevel) {
          this.levelCardData[0].value = this.$options.filters.changeNum(
            item.active_account_count
          );
          this.levelCardData[1].value = this.$options.filters.changeNum(
            item.active_dealer_count
          );
          this.levelCardData[2].value = this.$options.filters.numRate(
            item.active_dealer_rate
          );
          this.levelCardData[3].value = this.$options.filters.changeNum(
            item.account_count
          );
          this.levelCardData[4].value = this.$options.filters.changeNum(
            item.dealer_count
          );
          // this.levelCardData[5].value = this.$options.filters.numRate(
          //   item.dealer_rate
          // );
        }
      });
      if (newYData && newYData.length > 0) {
        this.levelChartData.yData = newYData;
      }
    },
    // 等级图表
    getLevelChartOption(data) {
      let option = null;
      if (!option) {
        option = deepClone(chartLevel);
      }
      let colorArr = ["#E75934", "#FFC840", "#81AFFF", "#F5A679", "#CCD2E3"];
      let xData = [];
      if (data && data.xData && data.xData.length > 0) {
        data.yData.forEach((item, index) => {
          xData.push({
            itemStyle: {
              color: colorArr[index],
            },
            value: item,
          });
        });
      }
      let yData = data.yData || [];
      option.series[0].data = xData;
      option.yAxis[0].data = yData;
      option.series[1].label.normal.formatter = function(data) {
        return yData[data.dataIndex];
      };
      let maxNum = Math.max(...yData);
      option.series[1].data = [maxNum, maxNum, maxNum, maxNum, maxNum];
      return option;
    },
    // 固定展示日期
    getMonth(type) {
      if (type == 1) {
        return moment()
          .subtract(1, "months")
          .format("M");
      }
      if (type == 2) {
        return moment().format("YYYY.MM");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
// 公用
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.item-center {
  display: flex;
  align-items: center;
}
.mb20 {
  margin-bottom: 20px;
}
// 主体区域
.wrap {
  padding: 0 20px;
  .box-main {
    @include background_color("background_color80");
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 28px;
  }
  // 矩阵活跃
  .matrix-main {
    height: 244px;
    display: flex;
    justify-content: space-between;
    position: relative;
    > img {
      width: 196px;
      height: 244px;
    }
    .level-box {
      width: calc(100% - 844px);
      height: 100%;
      .level-title {
        height: 68px;
        padding: 20px 0;
        > span {
          font-weight: bold;
          font-size: 14px;
          @include font_color("font_color20");
          &:last-child {
            @include font_color("font_color26");
            margin-left: 10px;
          }
        }
        .current-month {
          color: #ffca5cff;
        }
        .level-history {
          cursor: pointer;
        }
      }
      .chart-main {
        width: 100%;
        height: 176px;
        display: flex;
        flex-wrap: nowrap;
        &-left {
          width: 34px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 26px 0;
          > div {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            line-height: 14px;
            text-align: center;
            font-size: 12px;
            &:nth-child(1) {
              @include background_color("background_color52");
              @include font_color("font_color27");
            }
            &:nth-child(2) {
              @include background_color("background_color74");
              @include font_color("font_color26");
            }
            &:nth-child(3) {
              @include background_color("background_color54");
              @include font_color("font_color29");
            }
            &:nth-child(4) {
              @include background_color("background_color60");
              @include font_color("font_color31");
            }
            &:nth-child(5) {
              @include background_color("background_color55");
              @include font_color("font_color21");
            }
          }
        }
        &-right {
          width: calc(100% - 34px);
          height: 100%;
        }
      }
    }
    .data-box {
      width: 648px;
      height: 100%;
      padding: 0 20px 20px 0;
      .flex-between {
        height: 34px;
        margin: 16px 0 18px 0;
        > div:first-child {
          display: flex;
          align-items: center;
          > span {
            font-size: 12px;
            font-weight: bold;
            @include font_color("font_color20");
            margin-right: 12px;
          }
        }
        > div:last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 12px;
          @include font_color("font_color49");
        }
      }
      .card-list {
        display: flex;
        flex-wrap: wrap;
        padding-right: 148px;
        > div {
          margin-right: 12px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 12px;
          }
        }
      }
    }
    .spin {
      width: 100%;
      padding: 115px 0;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
    }
  }
}
</style>

<style lang="less">
// 历史评级modal
.level-history-modal {
  .ant-modal-header {
    background-color: #1b1d26;
    border-bottom: none;
    .ant-modal-title {
      color: rgba(255, 255, 255, 0.88);
    }
  }
  .ant-modal-close-icon {
    color: rgba(255, 255, 255, 0.55);
  }
  .history-modal-content {
    color: rgba(255, 255, 255, 0.88);
    // width: 720px;
    height: 400px;
  }
  .history-modal-setting {
    display: inline-block;
    cursor: pointer;
  }
  .setting-icon {
    color: #ffcc55;
  }
}

.history-modal-popover {
  height: 300px;
  overflow-y: scroll;
  padding-top: 0;
  .ant-popover-inner-content {
    color: rgba(255, 255, 255, 0.88);
  }
  .ant-checkbox-wrapper {
    display: block;
    margin-top: 8px;
    margin-left: 0;
    color: rgba(255, 255, 255, 0.88);
  }
  .ant-checkbox-disabled {
    & + span {
      color: rgba(255, 255, 255, 0.33);
    }
    .ant-checkbox-inner {
      background-color: #bcbcbc;
      border-color: #bcbcbc !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ffca5c;
    border-color: #ffca5c;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ffca5c;
  }
  .ant-checkbox-checked::after {
    border-color: #ffca5c;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.88);
  }
}
</style>
