<template>
  <div class="tabs-content3">
    <div class="tabs-content3-main">
      <div class="title-div">
        <span class="title">增长趋势</span>
        <NewTabs2 :tabData="tabsData" @tabChange="changeTabs" />
      </div>
      <div class="chart-div">
        <div class="chart-div-left">
          <MyChart
            v-if="chartShow"
            :options="getLineOption(lineData, tabsKey)"
            :id="'line-chart-1'"
          />
          <NewTabs2
            class="tab-box"
            :tabData="chartTabsData"
            @tabChange="changeChartTabs"
          />
        </div>
        <div class="chart-div-right">
          <MyChart
            v-if="chartShow && tabsKey != '2'"
            :options="getBarOption(barData, tabsKey)"
            :id="'bar-chart-2'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import MyChart from "@/components/MyChart/MyChart";
import chartLine2 from "../../options/chartLine2.js";
import chartBar5 from "../../options/chartBar5.js";
import deepClone from "@/utils/clone-utils";
import api from "@/api/clueMarket.js";
export default {
  name: "TabsContent3",
  components: { NewTabs2, MyChart },
  data() {
    return {
      chartShow: true,
      tabsData: [
        {
          label: "细分渠道",
          value: "1",
        },
        {
          label: "不细分",
          value: "2",
        },
      ],
      tabsKey: "1",
      chartTabsData: [
        {
          label: "按日",
          value: "1",
        },
        {
          label: "按月",
          value: "2",
        },
      ],
      chartTabsKey: "1",
      lineData: {
        xData: [],
        yAwemeData: [],
        yLiveData: [],
        yPrivateData: [],
      },
      barData: {
        xData: [],
        yData: [],
      },
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getComponentGrowth();
    },
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
    },
    changeChartTabs(val) {
      if (this.chartTabsKey == val) {
        return false;
      }
      this.chartTabsKey = val;
      this.getComponentData();
    },
    getLineOption(data, type) {
      let option = deepClone(chartLine2);
      option.xAxis.data = data.xData;
      if (type == 2) {
        option.series = [
          {
            name: "销售总数",
            smooth: true,
            type: "line",
            symbol: "circle",
            showSymbol: true,
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: "#ffca5c",
              },
            },
            data: data.yTotal,
          },
        ];
        option.legend.data = ["销售总数"];
        return option;
      }
      option.series[0].data = data.yLiveData;
      option.series[1].data = data.yAwemeData;
      option.series[2].data = data.yPrivateData;
      option.series[3].data = data.yHomepage;
      option.series[4].data = data.ySurplus;
      // option.series[4].data = data.yIndustryAnchor;
      // option.series[5].data = data.yReservation;
      // option.series[6].data = data.yOther;
      return option;
    },
    getBarOption(data, type) {
      let option = deepClone(chartBar5);
      option.series[0].data = data.yData;
      // if (type == 2) {
      //   option.series[0].data = data.yData.slice(1);
      //   option.yAxis[0].data = ["视频线索", "直播线索"];
      //   option.series[0].itemStyle.color = (params) => {
      //     var colorList = ["#3FD4CF", "#ffca5c"];
      //     return colorList[params.dataIndex];
      //   };
      // }
      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
          is_month: this.chartTabsKey == "2" ? true : false,
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getComponentGrowth() {
      let data = this.getParentsParams();
      api.getComponentGrowth(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          let newData = data;
          this.lineData = {
            xData: newData.nodes
              ? newData.nodes.map((item) => {
                  return item.date;
                })
              : [],
            yAwemeData: newData.nodes
              ? newData.nodes.map((item) => {
                  return item.video_component;
                })
              : [],
            yLiveData: newData.nodes
              ? newData.nodes.map((item) => {
                  return item.live_component;
                })
              : [],
            yPrivateData: newData.nodes
              ? newData.nodes.map((item) => {
                  return item.private_letter;
                })
              : [],
            yHomepage: newData.nodes
              ? newData.nodes.map((item) => {
                  return item.homepage;
                })
              : [],
            // yIndustryAnchor: newData.nodes
            //   ? newData.nodes.map((item) => {
            //       return item.industry_anchor;
            //     })
            //   : [],
            // yReservation: newData.nodes
            //   ? newData.nodes.map((item) => {
            //       return item.reservation_component;
            //     })
            //   : [],
            ySurplus: newData.nodes
              ? newData.nodes.map((item) => {
                  return item.surplus;
                })
              : [],
            yTotal: newData.nodes
              ? newData.nodes.map((item) => {
                  return item.sales;
                })
              : [],
          };
          this.barData = {
            yData: [
              newData.surplus_summary || 0,
              // newData.other_summary || 0,
              // newData.reservation_component_summary || 0,
              // newData.industry_anchor_summary || 0,
              newData.homepage_summary || 0,
              newData.private_letter_summary || 0,
              newData.video_component_summary || 0,
              newData.live_component_summary || 0,
            ],
          };
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content3 {
  width: 100%;
  height: 413px;
  &-main {
    width: 100%;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      > .title {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .chart-div {
      width: 100%;
      height: 351px;
      display: flex;
      justify-content: space-between;
      &-left {
        width: 66%;
        height: 100%;
        position: relative;
        .tab-box {
          position: absolute;
          right: 30px;
          top: 0px;
        }
      }
      &-right {
        width: 33%;
        height: 100%;
      }
    }
  }
}
</style>
