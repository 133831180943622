<template>
  <div class="detail-title-container">
    <div class="detail-title-container-left">
      <!-- <span class="back-btn">
        <svg-icon icon-class="arrow-left" class="icon" />
        <span>返回</span>
      </span> -->
      <span class="skew-btn" v-for="(item, index) in skewData" :key="index">
        <div class="skew-btn-main">{{ item }}</div>
      </span>
    </div>
    <div class="detail-title-container-right" v-if="rightValue">
      {{ rightValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailTitle",
  props: {
    rightValue: {
      type: String,
      default: "",
    },
    skewData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {
    buttonChange(val) {
      console.log("buttonChange", val);
      this.currentButtonData = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.detail-title-container {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .back-btn {
      width: 60px;
      height: 26px;
      margin-right: 10px;
      color: #9ca8ba;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 6px 0 4px;
      cursor: pointer;
      .icon {
        font-size: 12px;
      }
      &:hover {
        color: #fff;
      }
    }
    .skew-btn {
      transform: skewX(-30deg);
      padding: 0 26px;
      height: 26px;
      line-height: 26px;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      background: linear-gradient(270deg, #565c6d 0%, #2b2f3b 100%);
      margin-left: 6px;
      &-main {
        transform: skewX(30deg);
      }
      &:last-child {
        color: #000;
        background: linear-gradient(270deg, #ffe795 0%, #ffca5c 100%);
      }
    }
  }
  &-right {
    color: #8890a6;
    font-size: 14px;
    line-height: 60px;
  }
}
</style>