<template>
  <div class="brand_rectangle">
    <div class="platform-area-div">
      <a-select
        class="aft-select blue"
        v-model="regionId"
        :suffixIcon="suffixIcon"
        placeholder="选择大区"
        style="width: 196px; margin-right: 10px"
        @change="regionChange"
      >
        <a-select-option
          v-for="item in areaList.region_list || []"
          :key="item.value"
          :value="item.value"
          >{{ item.label }}</a-select-option
        >
      </a-select>
      <platform-header title="" :tabData="tabData" @tabChange="tabChange" />
    </div>
    <div class="top">
      <div class="top-left">
        <a-icon
          type="clock-circle"
          style="color: #ffca5c; font-size: 28px; margin: 0 6px 0 0"
        />
        <div>
          <span style="color: #d0d6e6">{{ date }}</span>
          <br />
          <span style="color: #ffca5c">{{ time }}</span>
        </div>
      </div>
      <div class="top-right">
        <span style="color: #9ca8ba; font-size: 12px">正直播门店</span>
        <span style="color: #ffca5c; font-weight: bold; font-size: 16px">{{
          mapData ? mapData.living_count : 0
        }}</span>
      </div>
      <div class="top-right" style="margin-left: 10px">
        <span style="color: #9ca8ba; font-size: 12px">今日直播门店</span>
        <span style="color: #ffca5c; font-weight: bold; font-size: 16px">{{
          mapData ? mapData.live_count : 0
        }}</span>
      </div>
      <div class="top-refresh" @click="refreshPage">
        <svg-icon icon-class="refresh2" style="font-size: 32px" />
      </div>
    </div>
    <div id="map"></div>

    <div :class="listOpen ? 'video-list-main open' : 'video-list-main'">
      <div class="video-list-main-title">
        <div>
          <svg-icon
            :icon-class="
              mapData.live_online_dealer_info_pos &&
              mapData.live_online_dealer_info_pos.length > 0
                ? 'living'
                : 'living_no'
            "
            class="live-icon"
          />
          <span>正在直播</span>

          <AftTooltip :code="'qjzb02'" style="top: -2px; left: 4px" />
        </div>
        <svg-icon
          icon-class="refresh"
          class="refresh-icon"
          @click="refreshClick"
        />
        <svg-icon
          icon-class="arrow-down"
          :class="listOpen ? 'arrow-icon open' : 'arrow-icon'"
          @click="openList"
        />
      </div>
      <div class="video-list-main-tip">
        *点击切换直播间
        <span style="margin-left: 55px;">{{
          currentTab == "douyin"
            ? "累计观看人次"
            : currentTab == "kuaishou"
            ? "实时在线人数"
            : currentTab == "dongchedi"
            ? "累计观看人次"
            : "-"
        }}</span>
      </div>
      <div
        class="video-list-main-list"
        ref="rightList"
        v-show="
          mapData &&
          mapData.live_online_dealer_info_pos &&
          mapData.live_online_dealer_info_pos.length > 0
        "
      >
        <div
          :class="
            currentVideoLength == index
              ? 'video-list-main-list-item active'
              : 'video-list-main-list-item'
          "
          v-for="(item, index) in mapData.live_online_dealer_info_pos"
          :key="index"
          @click="liveCardClick(item, index)"
        >
          <div>
            <svg-icon icon-class="fire" class="fire-icon" />
            <span>{{ item.dealer_name || "-" }}</span>
          </div>
          <div>
            <svg-icon icon-class="eye3" class="watch-icon" />
            <span v-if="currentTab == 'kuaishou'">{{
              item.unify_online_cnt | changeNum
            }}</span>
            <span v-else>{{ item.unify_watch_cnt | changeNum }}</span>
          </div>
        </div>
      </div>
      <div
        class="empty-container"
        v-show="
          !mapData ||
          !mapData.live_online_dealer_info_pos ||
          mapData.live_online_dealer_info_pos.length == 0
        "
      >
        <Empty />
      </div>
      <div class="video-list-main-shadow" v-show="listOpen"></div>
    </div>
    <div
      class="video-container"
      v-show="
        mapData &&
        mapData.live_online_dealer_info_pos &&
        mapData.live_online_dealer_info_pos.length > 0
      "
    >
      <!-- 当前直播门店名字 -->
      <a-tooltip v-if="mapData && mapData.data.length > 0">
        <template slot="title">{{
          mapData.data[currentVideoLength].name || ""
        }}</template>

        <div class="video-name">
          {{ mapData.data[currentVideoLength].name || "" }}
        </div>
      </a-tooltip>
      <div class="video-box">
        <!-- hls.js -->
        <section style="width: 100%; height: 100%">
          <video
            ref="video"
            id="videoElement"
            controls
            muted
            style="width: 100%; height: 100%; border-radius: 14px"
          ></video>
        </section>
      </div>
      <!-- <a-button
        type="primary"
        round
        @click="toDetail"
        style="
          width: 100%;
          height: 32px;
          min-height: 32px;
          background: linear-gradient(270deg, #00f1ff 0%, #022dff 100%);
          border: none;
        "
        >点击查看直播实时实况</a-button
      > -->
      <!-- <div class="info-box">
        <a-button
          type="primary"
          v-if="currentTab != 'kuaishou'"
          round
          class="btn"
          >累计观看人数</a-button
        >
        <span class="number" v-if="currentTab != 'kuaishou'">{{
          this.userCount
        }}</span>
        <a-button type="primary" round class="btn">{{
          currentTab == "douyin" ? "粉丝新增" : "在线人数"
        }}</a-button>
        <span class="number">{{
          currentTab == "douyin"
            ? this.addCount
            : currentTab == "kuaishou"
            ? this.addCount_ks
            : currentTab == "dongchedi"
            ? this.addCount_dcd
            : "-"
        }}</span>
      </div> -->

      <div
        class="number-container"
        v-show="
          mapData &&
          mapData.live_online_dealer_info_pos &&
          mapData.live_online_dealer_info_pos.length > 0
        "
      >
        <div v-if="currentTab != 'kuaishou'">
          <span class="label">累计{{ currentTab | returnWatchText }}</span>
          <span class="number">{{ userCount }}</span>
        </div>
        <div>
          <span class="label">{{
            currentTab == "douyin" ? "粉丝新增" : "实时在线人数"
          }}</span>
          <span class="number">{{
            currentTab == "douyin"
              ? addCount
              : currentTab == "kuaishou"
              ? addCount_ks
              : currentTab == "dongchedi"
              ? addCount_dcd
              : "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as vuex from "vuex";
import echarts from "echarts";
import china from "echarts/map/json/china.json";
import MqttRemoval from "@/utils/mqttRemoval";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import Paho from "@/utils/mqttws31.min.js";
import "videojs-contrib-hls";
import Hls from "hls.js";
import { getPrincipalId, getUserId } from "@/utils/auth";
import PlatformHeader from "./components/PlatformHeader";
import Empty from "@/components/Empty/Empty";
import _http from "@/api/brand_rectangle";
import Tooltip from "@/components/Tooltip/Tooltip";

// series
import {
  symbol,
  areaVideo,
  areaArrow,
  videoBorder,
  areaNickname,
} from "./series/series.js";

const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brand_rectangle");
export default {
  name: "brand_rectangle",
  components: {
    PlatformHeader,
    Empty,
    Tooltip,
  },
  data() {
    return {
      defaultAvatar: require("@/assets/default/default1-1.png"),
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
        {
          label: "懂车帝",
          value: "dongchedi",
        },
      ],
      currentTab: "douyin",
      regionId: undefined,
      hls: "",
      removal: null,
      currentVideoLength: 0,
      isClicked: false,
      videoSrc: "",
      userCount: 0,
      addCount: 0,
      addCount_ks: 0,
      addCount_dcd: 0,
      client: null, // 创建一个客户端实例
      date: moment().format("YYYY-MM-DD"), // 当前日期
      time: moment().format("HH:mm"), // 当前时间
      scale: 1, // 地图图片宽高比例
      progress: 0, // 进度条百分比0-100 number
      oldMqttData: null, // 上一个订阅的消息
      currentTimer: null, // 当前时间定时器
      videoTimer: null, // 视频进度条定时器
      // countTimer: null, // 新增粉丝数与在线观看数定时器
      currentMapIndex: -1,
      player: null,
      platformClick: false,
      listOpen: true, // 左侧直播列表是否展开
    };
  },
  watch: {
    mapData() {
      this.isClicked = false;
      this.initChart();
      this.currentVideoLength = 0;
      if (this.mapData.live_online_dealer_info_pos.length > 0) {
        this.videoSrc =
          this.mapData.live_online_dealer_info_pos[
            this.currentVideoLength
          ].play_url_flv264_old;
        // 加载video
        let that = this;
        console.log(that.videoSrc);
        if (this.platformClick) {
          console.log(12321312312312);
          this.flv_destroy();
        }
        this.setNumber(
          this.mapData.live_online_dealer_info_pos[this.currentVideoLength]
        );
        // flv.js
        this.getStream(this.returnNewUrl(that.videoSrc));

        console.log(22, this.player);
        // this.player.off()
        // this.player.on("error", (err) => {
        //   console.log("flv加载失败", err);
        //   this.mapData.live_online_dealer_info_pos[
        //     this.currentVideoLength
        //   ].status = true;
        //   if (!this.isClicked) {
        //     if (
        //       this.currentVideoLength + 1 <=
        //       this.mapData.live_online_dealer_info_pos.length - 1
        //     ) {
        //       this.currentVideoLength += 1;
        //       this.videoSrc = this.mapData.live_online_dealer_info_pos[
        //         this.currentVideoLength
        //       ].play_url;

        //       let that = this;
        //       this.flv_destroy();
        //       this.getStream(that.videoSrc.replace(/^http/, "https"));
        //     } else {
        //       // this.$message.warning("当前暂无直播，请稍后刷新页面重试");
        //     }
        //   }
        // });
      } else {
        this.flv_destroy();
        this.addCount = "-";
        this.addCount_ks = "-";
        this.addCount_dcd = "-";
        this.userCount = "-";
        this.$message.warning("当前暂无直播，请稍后刷新页面重试");
      }
    },
    mqttConfigData(val) {
      // 清除之前的订阅
      if (this.client && this.oldMqttData) {
        this.client.unsubscribe(
          this.oldMqttData.clientSubscribe.live.bulletAndStatisticsTopic
        );
        this.client.disconnect(); // 断开连接
        this.client = null;
      }
      this.oldMqttData = this.mqttConfigData;
      this.removal = new MqttRemoval();
      // clearInterval(this.countTimer);
      // this.loadAllCountInterval(
      //   1,
      //   this.mapData.live_online_dealer_info_pos[this.currentVideoLength].room_id
      // );
      // this.MQTTconnect();
    },
    // allCountData() {
    //   this.userCount = this.allCountData.accumulatedWatchingCount;
    //   this.addCount = this.allCountData.increaseFansCount;
    // },
  },
  computed: {
    ...mapState({
      areaList: (state) => state.areaList,
      mapData: (state) => state.mapData, // 地图数据
      mqttConfigData: (state) => state.mqttConfigData, // 昨日开播数据
      onlineCountData: (state) => state.onlineCountData, // 查询某天直播均值(左侧轮播)
      allCountData: (state) => state.allCountData, // 获取主播粉丝数 和 累计观看人数 (初始值)
    }),
  },
  async created() {
    await this.getAreaList();
    console.log(this.areaList);
    if (!this.areaList.region_list && this.areaList.region_list.length == 0) {
      this.$message.warning("当前账号未绑定大区，请联系管理员");
      this.changeState({ data: null, name: "mapData" });
      this.changeState({ data: [], name: "areaList" });
      return false;
    }
    this.regionId = this.areaList.region_list[0].value;
    await this.getMapData({
      principal_id: getPrincipalId(),
      region_id: this.regionId,
      platform: this.currentTab,
    });
  },
  mounted() {
    // 设置video宽高
    window.onresize = () => {
      return (() => {
        this.chart.resize();
      })();
    };

    this.setCurrentTimer();
  },
  methods: {
    ...mapActions([
      "getAreaList",
      "getMqttConfig",
      "addLiveRoom",
      "getMapData",
      "loadConfig",
      "loadAllCount",
      "loadOnlineCount",
    ]),
    ...mapMutations(["changeState"]),
    // hls.js test

    // 大区选择
    regionChange() {
      this.getMapData({
        principal_id: getPrincipalId(),
        region_id: this.regionId,
        platform: this.currentTab,
      });
    },
    tabChange(val) {
      console.log(val);
      this.currentVideoLength = 0;
      this.platformClick = true;
      this.currentTab = val;
      this.getMapData({
        principal_id: getPrincipalId(),
        region_id: this.regionId,
        platform: this.currentTab,
      });
    },
    videoPause() {
      if (this.hls) {
        console.log("暂停视频");
        this.$refs.video.pause();
        this.hls.destroy();
        this.hls = null;
      }
    },
    setNumber(item) {
      this.userCount = 0;
      this.addCount = 0;
      this.addCount_ks = 0;
      if (this.currentTab == "douyin") {
        this.userCount = item.unify_watch_cnt || 0;
        this.addCount = item.new_fans_count || 0;
      } else if (this.currentTab == "kuaishou") {
        this.userCount = item.unify_watch_cnt || 0;
        this.addCount_ks = item.unify_online_cnt || 0;
      } else if (this.currentTab == "dongchedi") {
        this.userCount = item.unify_watch_cnt || 0;
        this.addCount_dcd = item.unify_online_cnt || 0;
      }
    },
    getStream(source) {
      console.log("准备尝试播放", source);
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById("videoElement");
        this.player = flvjs.createPlayer({
          type: "flv",
          url: source,
        });
        this.player.attachMediaElement(videoElement);
        this.player.load();
        this.player.play();

        // 重新获取配置
        this.loadAllConfig();
      }
    },
    // 获取mqtt配置 并且 添加直播监听-todoD
    loadAllConfig() {
      this.getMqttConfig({
        platform_type:
          this.currentTab == "douyin"
            ? "1"
            : this.currentTab == "kuaishou"
            ? "2"
            : "3",
        author_id:
          this.mapData.live_online_dealer_info_pos[this.currentVideoLength]
            .author_id,
        device_id: getUserId(),
      });
      // todo
      if (this.currentTab == "dongchedi") {
        return false;
      }
      this.addLiveRoom({
        platform_type:
          this.currentTab == "douyin"
            ? "1"
            : this.currentTab == "kuaishou"
            ? "2"
            : "3",
        room_id:
          this.mapData.live_online_dealer_info_pos[this.currentVideoLength]
            .room_id,
      });
    },

    // 地图加载
    initChart() {
      console.log("initChart-initChart");
      var scale = this.scale;
      this.chart = echarts.init(document.getElementById("map"));
      echarts.registerMap("china", china);
      var geoCoordMap = this.mapData.geoCoordMap;
      var data = this.mapData.data;
      data.forEach((item, index) => {
        item.authorId =
          this.mapData.live_online_dealer_info_pos[index].author_id;
        item.roomId = this.mapData.live_online_dealer_info_pos[index].room_id;
        item.avatar =
          this.mapData.live_online_dealer_info_pos[index].adb_author_info
            .avatar || this.defaultAvatar;
        item.nickname =
          this.mapData.live_online_dealer_info_pos[index].adb_author_info
            .nickname || "-";
      });

      /*获取地图数据*/
      var mapName = "china";
      // mychart.showLoading();
      var mapFeatures = echarts.getMap(mapName).geoJson.features;
      // mychart.hideLoading();
      mapFeatures.forEach(function (v) {
        // 地区名称
        var name = v.properties.name;
        // 地区经纬度
        geoCoordMap[name] = v.properties.cp;
      });

      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              cover: data[i].cover,
              avatar: data[i].avatar,
              nickname: data[i].nickname,
              authorId: data[i].authorId,
              roomId: data[i].roomId,
              index: i,
            });
          }
        }
        return res;
      };

      var option = {
        backgroundColor: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#052C80", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#031D5A", // 100% 处的颜色
            },
          ],
          globalCoord: false, // 缺省为 false
        },
        title: {
          top: 20,
          text: "",
          subtext: "",
          x: "center",
          textStyle: {
            color: "#ccc",
          },
        },

        // tooltip: {
        //   trigger: "item",
        //   formatter: function (params) {
        //     if (typeof params.value[2] == "undefined") {
        //       return params.name + " : " + params.value;
        //     } else {
        //       return params.name + " : " + params.value[2];
        //     }
        //   },
        //   backgroundColor: "rgba(37, 50, 85, 0.9)",
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
        visualMap: {
          show: false,
          min: 0,
          max: 500,
          left: "left",
          top: "bottom",
          text: ["高", "低"], // 文本，默认为数值文本
          calculable: true,
          seriesIndex: [1],
          inRange: {},
        },
        geo: {
          map: "china",
          show: true,
          roam: false,
          zoom: 1.08,
          center: [112.114129, 37.550339],
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#3a7fd5",
              borderColor: "#0a53e9", //线
              shadowColor: "#092f8f", //外发光
              shadowBlur: 20,
            },
            emphasis: {
              areaColor: "#0a2dae", //悬浮区背景
            },
          },
        },
        series: [
          // 标记
          symbol(convertData(data)),
          // 地图上的视频图片
          areaVideo(scale, convertData(data), 1, this.currentVideoLength),
          // 地图上的三角箭头
          areaNickname(scale, convertData(data), this.currentVideoLength),
        ],
      };
      // this.chart.off("click");
      // this.chart.on("click", (params) => {
      //   console.log(345, params);
      //   // 如果地图点击过 停止当前视频播放循环
      //   if (params.data && params.data.authorId) {
      //     this.isClicked = true;
      //     this.currentVideoLength = params.data.index;
      //     // 重新渲染mqtt和粉丝数和视频
      //     // 如果当前视频被标记过status =》 true 则当前直播已过期
      //     if (params.data.status) {
      //       this.$message.warning("当前直播已过期!请刷新重试");
      //       return false;
      //     }
      //     this.videoSrc =
      //       this.mapData.live_online_dealer_info_pos[
      //         this.currentVideoLength
      //       ].play_url;

      //     let that = this;
      //     this.flv_destroy();
      //     console.log(
      //       909,
      //       this.mapData.live_online_dealer_info_pos[this.currentVideoLength]
      //     );
      //     this.setNumber(
      //       this.mapData.live_online_dealer_info_pos[this.currentVideoLength]
      //     );
      //     this.getStream(that.videoSrc.replace(/^http/, "https"));
      //   }
      // });
      // this.chart.off("mouseover");
      // this.chart.on("mouseover", (params) => {
      //   if (params.seriesType == "scatter") {
      //     // console.log("mouseover", params);
      //     this.currentMapIndex = params.dataIndex;
      //     this.initChart();
      //   }
      // });
      this.chart.setOption(option);
    },
    // 当前时间更新
    setCurrentTimer() {
      this.currentTimer = setInterval(() => {
        this.date = moment().format("YYYY-MM-DD");
        this.time = moment().format("HH:mm");
      }, 1000);
    },

    MQTTconnect() {
      this.client = new Paho.MQTT.Client(
        this.mqttConfigData.endPoint,
        443,
        this.mqttConfigData.clientId
      );
      this.client.connect({
        userName: this.mqttConfigData.userName, //连接账号
        password: this.mqttConfigData.password, //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: (message) => {
          setTimeout(this.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4,
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },

    //当客户端连接
    onConnect() {
      //建立连接后，进行订阅并发送消息。
      console.log(
        "onConnect",
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
      );
      this.client.subscribe(
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic,
        { qos: 0 }
      );
      // this.client.subscribe(
      //   'afanti_live/bulletAndStatistics/1/89045053026',
      //   { qos: 0 }
      // );
      // this.client.subscribe(
      //   'afanti_live/bulletAndStatistics/1/95128420864',
      //   { qos: 0 }
      // );

      // var message = new Paho.MQTT.Message("Hello");
      // message.destinationName = "World";
      // message.qos = 0;
      // this.client.send(message);
    },

    // 当客户端失去连接时调用
    onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    },

    //当消息到达时
    onMessageArrived(message) {
      // console.log("onMessageArrived:", message);
      console.log("onMessageArrived:", JSON.parse(message));
      let newJson = JSON.parse(message.payloadString);
      // let bool = this.removal.isRemoval(newJson);
      let bool = true;
      // if (bool && newJson.type == 1) {
      //   this.$notify({
      //     title: newJson.data.userName + ":",
      //     message: newJson.data.content,
      //     duration: 1000 * 10,
      //   });
      // } else if (
      //   newJson.type == 2 &&
      //   newJson.data.watchingCount &&
      //   newJson.data.watchingCount != "null"
      // ) {
      //   this.userCount = newJson.data.watchingCount;
      // } else if (bool && newJson.type == 3) {
      //   this.addCount = this.addCount + 1;
      // }
      if (bool && newJson.type == "2009") {
        this.userCount = newJson.data.accumulated_watching_count;
        // todoD
        if (this.currentTab == "douyin") {
          this.addCount = newJson.data.fans_change;
        } else {
          this.addCount = newJson.data.online_count;
        }
      } else if (newJson.type == "3001") {
        this.info();
      }
    },
    toDetail() {
      if (
        this.mapData.data.length > 0 &&
        this.mapData.data[this.currentVideoLength].authorId &&
        this.mapData.data[this.currentVideoLength].roomId
      ) {
        // this.$router.push(
        //   `/live_now?author_id=${
        //     this.mapData.data[this.currentVideoLength].authorId
        //   }&room_id=${this.mapData.data[this.currentVideoLength].roomId}`
        // );
        let routeUrl = this.$router.resolve({
          path: "realTimeLive",
          query: {
            author_id: this.mapData.data[this.currentVideoLength].authorId,
            room_id: this.mapData.data[this.currentVideoLength].roomId,
            type: this.currentTab,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        this.$message.warning("当前暂无直播，无法查看直播实时实况！");
      }
    },
    // 下播信息弹窗
    info() {
      const h = this.$createElement;
      this.$info({
        title: "当前直播已结束",
        content: h("div", {}, [
          h("p", "请切换其他直播或刷新页面获取最新正在直播。"),
        ]),
        onOk() {},
      });
    },
    openList() {
      this.listOpen = !this.listOpen;
      console.log(this.listOpen);
    },
    // 直播卡片点击
    liveCardClick(item, index) {
      if (this.currentVideoLength == index) {
        return false;
      }
      console.log(item, index);
      this.currentVideoLength = index;
      _http
        .getLiveDetail({
          author_id: item.author_id,
          room_id: item.room_id,
          platform: this.currentTab,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            if (res.data.data.finish === false) {
              this.videoSrc =
                this.mapData.live_online_dealer_info_pos[
                  this.currentVideoLength
                ].play_url_flv264_old;
              let that = this;
              this.flv_destroy();
              this.setNumber(
                this.mapData.live_online_dealer_info_pos[
                  this.currentVideoLength
                ]
              );
              this.initChart();
              this.getStream(this.returnNewUrl(that.videoSrc));
            } else {
              this.$message.warning("当前直播已结束，请换一场试试");
              this.refreshClick();
            }
          } else {
            this.$message.warning("当前直播已结束，请换一场试试");
            this.refreshClick();
          }
        });
    },
    returnNewUrl(url) {
      if (url) {
        try {
          let newUrl = url.replace(/^http/, "https");
          if (this.currentTab == "douyin") {
            let array = newUrl.split(".flv");
            newUrl = array[0] + "_sd.flv";
          }
          return newUrl;
        } catch (err) {
          this.$message.warning(err);
        }
      } else {
        this.$message.warning("未获取到当前直播地址");
      }
    },
    // 刷新接口
    refreshClick() {
      this.flv_destroy();
      this.getMapData({
        principal_id: getPrincipalId(),
        region_id: this.regionId,
        platform: this.currentTab,
      });
    },
    // 刷新页面
    refreshPage() {
      this.flv_destroy();
      location.reload();
    },
    flv_destroy() {
      console.log(this.player);
      if (this.player) {
        this.player.pause();
        this.player.unload();
        this.player.detachMediaElement();
        this.player.destroy();
        this.player = null;
      }
    },
  },
  beforeDestroy() {
    // clearInterval(this.countTimer);
    // clearInterval(this.videoTimer);
    clearInterval(this.currentTimer);
    // this.$notify.close();
    console.log("销毁");
    if (this.client) {
      this.client.unsubscribe(
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
      );
      this.client.disconnect(); // 断开连接
      this.client = null;
    }
    // 销毁video实例
    // const videoDom = this.$refs.myVideo;
    // videojs(videoDom).dispose();
    this.flv_destroy();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.platform-area-div {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 100;

  .beta-icon {
    width: 36px;
    height: 14px;
    border-radius: 7px;
    background: #ff4160;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: 4px;
    right: 0px;
  }
}
.brand_rectangle {
  width: 100%;
  height: calc(100vh - 64px);
  min-height: 610px;
  @include background_color("background_color45");
  position: relative;
  // overflow: hidden;

  .top {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 110;
    // width: 20%;
    height: 66px;
    display: flex;
    flex-direction: row;

    &-left {
      min-width: 130px;
      width: 130px;
      height: 100%;
      border-radius: 5px;
      margin-right: 10px;
      background: none;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      > div {
        span:first-child {
          font-size: 14px;
        }
        span:last-child {
          font-size: 18px;
        }
      }
    }

    &-right {
      width: 110px;
      min-width: 110px;
      height: 66px;
      padding: 14px 0 15px;
      border-radius: 16px;
      display: inline-block;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    &-refresh {
      width: 52px;
      height: 52px;
      display: flex;
      @include background_color("background_color73");
      border-radius: 16px;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      margin-top: 7px;
      cursor: pointer;
      &:hover {
        @include background_color("background_color41");
      }
    }
    &-refresh:hover {
      .default {
        display: none;
      }
      .active {
        display: inline-block;
      }
    }
  }
  .tools {
    position: absolute;
    top: 50px;
    right: 20px;
    z-index: 99;
    width: 30%;
    height: 8%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  #map {
    width: 100%;
    height: calc(100% - 14px);
    border-radius: 26px;
    overflow: hidden;
    background: linear-gradient(90deg, #052c80 0%, #031d5a 100%);
  }

  .video-list-main {
    width: 250px;
    height: 44px;
    position: absolute;
    left: 20px;
    top: 106px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    transition: all 0.3s;
    overflow: hidden;
    &.open {
      height: 464px;
    }
    &-title {
      width: 100%;
      height: 44px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      .live-icon {
        font-size: 18px;
      }
      > div {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 140px;
        > span {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          margin-left: 9px;
        }
      }
      .refresh-icon {
        font-size: 16px;
        cursor: pointer;
        margin: 0 32px 0 42px;
        @include font_color("font_color23");
        &:hover {
          @include font_color("font_color20");
        }
      }
      .arrow-icon {
        font-size: 16px;
        cursor: pointer;
        @include font_color("font_color23");
        transition: all 0.3s;
        &.open {
          transform: rotate(180deg);
        }
        &:hover {
          @include font_color("font_color20");
        }
      }
    }
    &-tip {
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      padding-left: 20px;
      color: #ffffff;
    }
    &-list {
      height: 380px;
      overflow-y: auto;
      &-item {
        width: 100%;
        height: 44px;
        color: #d0d6e6;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        cursor: pointer;
        &.active {
          color: #ffca5c;
        }
        &:last-child {
          margin-bottom: 25px;
        }
        > div {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
        }
        > div:first-child {
          width: 180px;
          height: 100%;
          .fire-icon {
            font-size: 16px;
            margin: 0 10px 0 20px;
          }
          > span {
            width: 90px;
            display: inline-block;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
        > div:last-child {
          width: 90px;
          height: 100%;
          .watch-icon {
            font-size: 22px;
            margin-right: 4px;
          }
        }
      }
    }
    &-shadow {
      width: 250px;
      height: 25px;
      background: linear-gradient(180deg, rgba(1, 18, 55, 0) 0%, #011237 100%);
      border-radius: 0 0 16px 16px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 201;
    }
  }
  .empty-container {
    padding-top: 130px;
  }

  .video-container {
    width: 252px;
    height: 504px;
    position: absolute;
    @include background_color("background_color72");
    right: 20px;
    bottom: 20px;
    border-radius: 14px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .video-name {
      height: 56px;
      line-height: 56px;
      padding-left: 16px;
      @include font_color("font_color21");
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .video-box {
      width: 252px;
      height: 448px;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      #my-video {
        video {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .number-container {
      width: 165px;
      height: 195px;
      border-radius: 16px;
      position: absolute;
      left: -175px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include background_color("background_color72");

      > div {
        width: 100%;
        height: 97px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 26px 0 24px 27px;
        .label {
          font-size: 12px;
          @include font_color("font_color34");
        }
        .number {
          font-weight: bold;
          @include font_color("font_color20");
          font-size: 20px;
        }
        &:first-child {
          border-bottom: 1px solid rgba(255, 255, 255, 0.06);
        }
      }
    }
  }
}
.a-notification__title {
  color: #4b53fc !important;
}
.a-notification__content {
  color: #0a2250 !important;
}
</style>
