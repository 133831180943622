import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 区域列表
  getAreaList() {
    return request({
      url: `/jjt/dealer/dealers/region-list`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then(res => {
      let list = res.data.data.region_list || []
      list.map(item => {
        item.value = item.id
        item.label = item.name
      })
      return list
    })
  },
  getDropdownList(params) {
    return request({
      url: '/jjt/matrix/perspective/drop-down/list',
      method: 'get',
      baseURL: getBaseUrl('agg'),
      params,
    })
  },
  getPerspectiveDetail(params) {
    return request({
      url: '/jjt/matrix/perspective/indicator/details',
      method: 'get',
      baseURL: getBaseUrl('agg'),
      params,
    })
  },
  getPerspectiveChart(params) {
    return request({
      url: '/jjt/brand-matrix/chart',
      method: 'get',
      baseURL: getBaseUrl('agg'),
      params,
    })
  },
  editPerspectiveById(data) {
    return request({
      url: `/jjt/matrix/perspective/indicator/edit/${data.id}`,
      method: 'post',
      baseURL: getBaseUrl('agg'),
      data,
    })
  },
}