import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // ---- 公共 ----
  // 区域列表
  getAreaList() {
    return request({
      url: `/jjt/dealer/dealers/region-list`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then(res => {
      let list = res.data.data.region_list || []
      list.map(item => {
        item.value = item.id
        item.label = item.name
      })
      return list
    })
  },
  // 活动列表
  getActivityList({
    platform
  }) {
    return request({
      url: '/jjt/business/policy/assessment/activity-list',
      baseURL: getBaseUrl(platform),
      method: 'get',
    })
  },
  // ---- 转化大盘 ----
  //概览
  getOverviewSurvey({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/clue-transform/survey',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },

  //渠道获客
  getClueChannel({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/clue-transform/channel',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  // 时间趋势
  // 渠道
  getTimeTrendV1({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/clue-transform/timeTrend/v1',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  // 大区
  getTimeTrendV2({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/clue-transform/timeTrend/v2',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  // 大区透视
  getClueRegionV1({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/clue-transform/region/v1',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  getClueRegionV2({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/clue-transform/region/v2',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  // 门店排行
  getStoreList({
    params,
    platform
  }) {
    return request({
      url: '/jjt/cluesCenter/list',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
}