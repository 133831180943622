var option = {
  backgroundColor: 'rgba(0, 0, 0, 0)',
  grid: {
    containLabel: true,
    left: '5%',
    bottom: '10%',
    top: '2%'
  },

  tooltip: {
    trigger: 'axis',
    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
      type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
    },
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
        color: '#fff',
    },
  },
  xAxis: {
    splitNumber: 5,
    axisLabel: {
      show: false
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    }
  },
  yAxis: [{
    data: [],
    axisLabel: {
      fontSize: 12,
      color: '#828282'

    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    }
  }, {
    show: true,
    position: 'left',
    data: []
  }],
  series: [{
    type: 'bar',
    name: '互动声量',
    stack: '2',
    legendHoverLink: false,
    barWidth: 10,
    itemStyle: {
      normal: {
        color: '#7A91FF',
        barBorderRadius:[0,20, 20, 0]
      },
      emphasis: {
        color: '#7A91FF'
      }
    },
    data: []
  }]
}

export default option
