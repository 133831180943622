<template>
  <div>
    <platform-header
      title="车系表现"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="main-container">
      <div class="main-container-left">
        <div class="tools">
          <div>
            <a-month-picker
              v-model="searchTime"
              class="aft-month-picker default"
              placeholder="Select month"
              :disabled-date="disabledDate"
              @change="onChange"
              locale="zh-cn"
            >
              <div slot="suffixIcon">
                <svg-icon :icon-class="'range-picker'" />
              </div>
            </a-month-picker>
          </div>
        </div>
        <div class="table" style="">
          <a-table
            :columns="columns"
            :data-source="seriesList"
            :rowKey="(data) => data.id"
            :loading="tableLoading"
            :rowClassName="rowClassName"
            :customRow="rowClick"
            :pagination="false"
            :scroll="{ y: 'calc(100vh - 304px)' }"
            :locale="locale"
            class="aft-table default"
          >
            <div slot="series" slot-scope="scope">
              <img :src="scope.avatar" width="100" alt="" />
            </div>
            <div slot="interaction" slot-scope="scope">
              <div class="table-interaction">
                <div class="table-interaction-name">{{ scope.seriesName }}</div>
                <!-- <div>{{ scope.status }}</div> -->
                <div class="table-interaction-number">
                  {{ scope.interaction | changeNum }}
                </div>
              </div>
            </div>
            <div
              slot="chart"
              slot-scope="scope"
              style="width: 100%; height: 116px"
            >
              <my-chart
                v-if="scope.id"
                :ref="`lineChart${scope.id}`"
                :id="`lineChart${scope.id}`"
                :options="getLineOption(scope.xData, scope.yData)"
              />
            </div>
            <div slot="topic" slot-scope="scope">
              <div class="table-topic">
                <div v-for="(item, index) in scope.hotTopic" :key="index">
                  <a-tooltip>
                    <template slot="title">#{{ item }}</template>
                    #{{ item }}
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div slot="action" slot-scope="scope">
              <span class="table-action" @click.stop="routerTo(scope)"
                >详情</span
              >
            </div>
          </a-table>
        </div>
      </div>
      <div class="main-container-right">
        <div class="my-card">
          <div class="card-title">
            <svg-icon :icon-class="'title'" class="card-title-icon"></svg-icon>
            <span class="card-title-top">竞品车系声量排行</span>
            <span>综合影响力</span>
          </div>
          <div class="bar-chart">
            <my-chart :options="barOptions" id="barChart" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import moment from "moment";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import chartLine from "./chartLine";
import chartBar from "./chartBar";
import deepClone from "@/utils/clone-utils";
import Empty from "@/components/Empty/Empty";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "modelPerformance"
);
export default {
  name: "modelPerformance",
  components: { PlatformHeader, MyChart },
  data() {
    return {
      msg: "车系表现",
      currentTab: "",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      searchTime: moment().subtract(1, "months"),
      disabledDate(current) {
        // Can not select days before today and today
        // return current && current >= moment().add(1, 'months').startOf("day");
        return (
          current >=
          moment()
            .startOf("month")
            .subtract("month", -1)
        );
      },
      columns: [
        {
          key: "series",
          title: "本品车系",
          width: 132,
          align: "center",
          scopedSlots: { customRender: "series" },
        },
        {
          key: "interaction",
          title: "互动声量",
          width: 132,
          align: "left",
          scopedSlots: { customRender: "interaction" },
        },
        {
          key: "chart",
          title: "互动趋势",
          align: "left",
          scopedSlots: { customRender: "chart" },
        },
        {
          key: "topic",
          title: "热搜话题",
          width: 163,
          align: "left",
          scopedSlots: { customRender: "topic" },
        },
        {
          key: "action",
          title: "操作",
          width: 60,
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      currentRow: {},
      barOptions: null,
    };
  },

  computed: {
    ...mapState({
      tableLoading: (state) => state.tableLoading, // 表格加载
      seriesList: (state) => state.seriesList, // 车系表现列表
      rankingData: (state) => state.rankingData, // 声量排行
    }),
  },
  watch: {
    seriesList(val) {
      this.$nextTick(() => {
        if (val && val.length > 0) {
          // 默认选中第一个
          this.currentRow = val[0];
          this.loadRanking();

          val.forEach((item) => {
            console.log(this.$refs);
            this.$refs[`lineChart${item.id}`].initChart();
          });
        }
      });
    },
    rankingData(val) {
      if (val) {
        this.barOptions = this.getBarOption(
          this.rankingData.xData,
          this.rankingData.yData
        );
      }
    },
  },
  created() {
    // this.loadTableData();
  },
  methods: {
    ...mapActions(["getSeriesList", "getVolumeRanking"]),
    ...mapMutations(["changeState"]),
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.loadTableData();
    },
    loadTableData() {
      if (!this.searchTime) {
        this.$message.warning("时间必选，默认选择上月!");
        this.searchTime = moment().subtract(1, "months");
      }
      let params = {
        statistics_begin_date: moment(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        statistics_end_date: moment(this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        platform: this.currentTab,
      };
      this.getSeriesList(params);
    },
    loadRanking() {
      let params = {
        statistics_begin_date: moment(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        statistics_end_date: moment(this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        series_id: this.currentRow.id,
        platform: this.currentTab,
      };
      this.getVolumeRanking(params);
    },
    getLineOption(xData, yData) {
      let option = deepClone(chartLine);
      option.xAxis.data = xData;
      option.series[0].data = yData;
      return option;
    },
    getBarOption(xData, yData) {
      console.log(xData, yData);
      if (xData.length < 10) {
        let len = 10 - xData.length;
        for (let i = 0; i < len; i++) {
          xData.push("");
          yData.push(null);
        }
      }
      let option = deepClone(chartBar);
      option.yAxis[0].data = xData.reverse();
      option.series[0].data = yData.reverse();
      return option;
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            this.currentRow = record;
            console.log(record);
            this.loadRanking();
          },
        },
      };
    },
    //table行class
    rowClassName(record, index) {
      let className = "";
      if (record.id == this.currentRow.id) {
        className = "table-row-active";
      }
      return className;
    },
    onChange(date, dateString) {
      this.loadTableData();
    },
    routerTo(row) {
      console.log(row);
      let routeUrl = `modelDetail?id=${row.id}&platform=${
        this.currentTab
      }&date=${moment(this.searchTime).format("YYYY-MM")}`;
      window.open(routeUrl, "_blank");
    },
  },
  beforeDestroy() {
    this.changeState({ data: [], name: "seriesList" });
    this.changeState({ data: {}, name: "rankingData" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.main-container {
  width: 100%;
  height: calc(100vh - 192px);
  padding: 0 24px;
  display: flex;
  &-left {
    width: calc(100% - 260px);

    .tools {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 16px;
      padding: 0 24px;
    }
    .table {
      width: 100%;
      height: calc(100% - 48px);
      overflow-y: auto;
    }
    .table-interaction {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #fff;
      &-number {
        font-size: 20px;
        font-weight: bold;
        @include font_color("font_color4");
      }
      &-name {
        @include font_color("font_color1");
        font-size: 14px;
      }
    }
    .table-topic {
      @include font_color("font_color3");
      line-height: 22px;
      height: 114px;
      margin: 16px 0;
      overflow-y: auto;
      > div {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
    .table-action {
      cursor: pointer;
      @include font_color("font_color3");
    }
    .table-action:hover {
      @include font_color("font_color4");
    }
  }

  &-right {
    width: 260px;
    padding: 0 0 0 20px;
    .my-card {
      width: 100%;
      height: 100%;
      @include background_color("background_color4");
      border: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color1");
      border-radius: 4px;
      .card-title {
        font-size: 12px;
        @include font_color("font_color3");
        &-top {
          @include font_color("font_color2");
          font-size: 16px;
          display: inline-block;
          margin: 24px 7px 16px 8px;
        }
        &-icon {
          @include font_color("font_color5");
          font-size: 18px;
          margin-left: 9px;
        }
      }
      .bar-chart {
        width: 100%;
        height: calc(100% - 80px);
      }
    }
  }
}
</style>
