var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"card-box"},[_c('BoardCard',{attrs:{"title":'粉丝总量',"color":'128, 220, 30',"number":_vm.overviewData.fansNumber || '-',"ratio":_vm.overviewData.fans_total_chain,"routerName":'粉丝中心',"chartData":{
        xData: _vm.overviewData.xData,
        yData: _vm.overviewData.yDataFans,
      }},on:{"routerTo":function($event){return _vm.routerTo('fans')}}})],1),_c('div',{staticClass:"card-box"},[_c('BoardCard',{attrs:{"title":'直播观看',"color":'232, 90, 52',"number":_vm.overviewData.liveNumber || '-',"ratio":_vm.overviewData.live_watch_count_chain,"routerName":'直播中心',"chartData":{
        xData: _vm.overviewData.xData,
        yData: _vm.overviewData.yDataLive,
      }},on:{"routerTo":function($event){return _vm.routerTo('live')}}})],1),_c('div',{staticClass:"card-box"},[_c('BoardCard',{attrs:{"title":'视频播放',"color":'129, 92, 255',"number":_vm.overviewData.videoNumber || '-',"ratio":_vm.overviewData.play_volumet_chain,"routerName":'视频中心',"chartData":{
        xData: _vm.overviewData.xData,
        yData: _vm.overviewData.yDataVideo,
      }},on:{"routerTo":function($event){return _vm.routerTo('video')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }