import ossProtype from "@/utils/oss.js";
import _ from "lodash";
import authApi from "@/api/auth";

export default {
  data() {
    return {
      progress: 0,
      is_progress: false,
      _oss: {
        status: "",
        client: null,
      },
      uploader: null,
      uploader_list: [null],
      video_id: 0,
    };
  },
  created() {
    //isOssAuth判断是否需要初始化_initOSSAuth
    // console.log('isOssAuth', this.isOssAuth, this.is_oss_auth, this.custom_request_type)
    // if(this.isOssAuth) {
    //   this._initOSSAuth()
    // }
    this._initOSSAuth();
  },
  methods: {
    _initOSSAuth() {
      if (this._oss) {
        if (this._oss.status === "success" || this._oss.status === "pedding") {
          return;
        }
      }
      let judge_url = window.location.href
        .split("/")
        .indexOf("admin.afanticar.com");
      this.module_id = window.location.search.split("=")[1];
      let bucket = judge_url != -1 ? "fhl-ai-video" : "fhl-ai-video-test";
      this._oss = {
        status: "pedding",
        client: null,
      };
      ossProtype(bucket)
        .then((res) => {
          if (res) {
            this._oss = {
              status: "success",
              client: res,
            };
          } else {
            this._oss = {
              status: "failure",
              client: null,
            };
            this.$message.error(res);
          }
        })
        .catch((err) => {
          this._oss = {
            status: "failure",
            client: null,
          };
          this.$message.error("获取oss客户端失败");
          console.error(err);
        });
    },
    async normalUpload(file, onSuccess, onProgress) {
      try {
        const self = this;
        const time = this.$moment()._d;
        const date = `${time.getFullYear()}-${time.getMonth() +
          1}-${time.getDate()}`;
        const url = this.custom_request_type + "/" + date + "/" + file.name;
        const data = await this._oss.client.put(url, file);

        onSuccess(data, data.url);
      } catch (err) {
        console.error(err);
        this._initOSSAuth();
        this.$message.error(`请重新上传或者刷新页面`);
      }
    },
    async normalUploadFile(file, onSuccess, fileParams, onError) {
      try {
        authApi
          .uploadFile(fileParams)
          .then((res) => {
            if (res.data.code === 200) {
              onSuccess(res.data.data, res.data.data.url);
            } else {
              onError(file);
              this.$message.error("请重新上传或者刷新页面");
            }
          })
          .catch((err) => {
            onError(file, err);
            console.error(err);
          });
      } catch (err) {
        console.error(err);
        this.$message.error(`请重新上传或者刷新页面`);
      }
    },
    async normalUploadVideo(file, onSuccess, fileParams, onError) {
      try {
        console.log("fileParams", fileParams);
        authApi
          .getUploadVideoConfig(fileParams)
          .then((res) => {
            if (res.data.code === 200) {
              onSuccess(res.data.data, file);
            } else {
              this.$message.error("请重新上传或者刷新页面");
            }
          })
          .catch((err) => {
            onError(file, err);
            console.error(err);
          });
      } catch (err) {
        console.error(err);
        this.$message.error(`请重新上传或者刷新页面`);
      }
    },
  },
};
