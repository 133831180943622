<template>
  <div class="bg_box">
    <div class="h-title">营销日历</div>
    <div class="select_box">
      <svg-icon icon-class="brand" class="icon_brand" />
      <a-select
        class="icon-select"
        :addonBefore="'svg'"
        show-search
        v-model="brand"
        :suffixIcon="suffixIcon"
        placeholder="选择品牌搜索"
        style="width: 240px"
        :filter-option="filterOption"
        :not-found-content="fetching ? undefined : null"
        @change="handleChange"
        :max-tag-count="1"
      >
        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
        <a-select-option v-for="d in brandsData" :key="d.id">
          {{ d.principal_name }}
        </a-select-option>
      </a-select>
    </div>
    <!-- 日历 -->
    <my-calender
      @changeMonth="getCalendarData"
      :calender-data="calendarList"
      ref="childMonth"
    />
  </div>
</template>
<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "topicMarketing"
);
import debounce from "lodash.debounce";
import MyCalender from "@/components/MyCalender";
export default {
  data() {
    // this.searchBrand = debounce(this.searchBrand, 800);//品牌输入搜索
    return {
      brand: [],
      plantsVisible: false,
      detail_date: "",
      detail_day: "",
      svg: '<svg-icon :icon-class="brand" />',
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
    };
  },
  components: {
    MyCalender,
  },
  computed: {
    ...mapState({
      brandsData: (state) => state.brandsData, // 品牌数据
      fetching: (state) => state.fetching,
      calendarList: (state) => state.calendarList,
    }),
  },
  methods: {
    ...mapActions(["getBrandsData", "getCalendar"]),
    // 输入搜索
    // searchBrand(value) {
    //   this.getBrandsData({ id: value });
    // },
    //日历更改
    handleChange() {
      let month = this.$refs.childMonth.month;
      this.getCalendarData(month);
    },
    // 获取日历信息
    getCalendarData(value) {
      let start_time = "";
      let end_time = "";
      if (value) {
        start_time = `${this.$moment(value)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(value)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        start_time = `${this.$moment()
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment()
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      }
      let params = {
        start_time,
        end_time,
        principal_id: this.brand,
      };
      // console.log(11, params);
      this.getCalendar({ params });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  mounted() {
    this.getCalendarData();
    this.getBrandsData();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.bg_box {
  @include background_color("background_color3");
}
.select_box {
  margin-left: 32px;
  position: relative;
  .icon_brand {
    position: absolute;
    left: 4px;
    top: 10px;
    @include font_color("font_color2");
    z-index: 2;
    font-size: 20px;
  }
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>
<style lang="scss" scope>
@import "@/assets/styles/_handle.scss";
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  left: 16px !important;
}
.icon-select .ant-select-selection-selected-value {
  margin-left: 20px !important;
}
.ant-badge-status-text {
  @include font_color("font_color2");
}
</style>