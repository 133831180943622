<template>
  <div
    class="base-image"
    :class="{
      'base-image--round': this.round
    }"
    :style="style"
  >
    <img
      class="base-image__img"
      :src="innerSrc"
      :alt="alt"
      :style="{
        objectFit: fit
      }"
      @load="handleLoad"
      @error="handleError"
    >
  </div>
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      default: ""
    },
    defaultImage: {
      type: String,
      default: ""
    },
    alt: String,
    fit: {
      type: String,
      default: "fill"
    },
    width: [Number, String],
    height: [Number, String],
    radius: [Number, String],
    round: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      innerSrc: this.defaultImage,
      loading: true,
      error: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (typeof this.width === "number") {
        style.width = `${this.width}px`;
      } else {
        style.width = this.width;
      }

      if (typeof this.height === "number") {
        style.height = `${this.height}px`;
      } else {
        style.height = this.height;
      }

      if (typeof this.radius === "number") {
        style.borderRadius = `${this.height}px`;
        style.overflow = 'hidden';
      } else {
        style.borderRadius = this.radius;
        style.overflow = 'hidden';
      }

      return style;
    },
  },
  watch: {
    src: {
      immediate: true,
      handler() {
        this.innerSrc = this.defaultImage;
        this.loading = true;
        this.error = false;
        this.src && (
          this.innerSrc = this.src.split(/(\.heif|\.heic)/)[0]
        );
      }
    },
  },
  methods: {
    handleLoad(e) {
      this.loading = false;
      this.$emit('load', e);
    },
    handleError(e) {
      this.innerSrc = this.defaultImage;
      this.loading = false;
      this.error = true;
      this.$emit('error', e);
    }
  }
}
</script>

<style lang="less" scoped>
.base-image {
  &__img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &--round {
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
