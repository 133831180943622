import filters from '@/filters'
import echarts from 'echarts'
var option = {
  backgroundColor: "rgba(0,0,0,0)",
  tooltip: {
    trigger: 'axis',
    show: false,
    axisPointer: { // 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
  },
  legend: {
    selectedMode: false,
    show: false
  },
  grid: {
    left: '3%',
    right: '40',
    bottom: '35',
    containLabel: true
  },
  xAxis: [{
    type: 'value',
    axisLine: {
      show: true, //隐藏x轴
      lineStyle: {
        color: ["rgba(255,255,255,0.2)"],
      },
    },
    axisTick: {
      show: true,  //刻度线
    },
    splitLine: {
      show: false,  //刻度线
    },
    axisLabel: {
      show: true,
      // formatter: '{value}%',
      textStyle: {
        color: '#6E7079', // 坐标轴文字颜色
        fontSize: 12, // 坐标轴文字大小
        padding: [0, 10, 0, 0]
      },
    },
  }],
  yAxis: [{
    splitLine: {
      show: true,
      lineStyle: {
        color: ["rgba(255,255,255,0.2)"],
      },
    },
    axisLine: { //y轴
      show: true,
      lineStyle: {
        color: ["rgba(255,255,255,0.2)"],
      },
    },
    type: 'category',
    axisTick: {
      show: false
    },
    data: [],
    axisLabel: {
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: 12
      }
    }
  }],
  series: [{
    name: 'test',
    type: 'bar',
    barMaxWidth: 40, // 柱子宽度
    label: {
      show: true,
      position: 'right', // 位置
      color: 'rgba(255,255,255,0.85)',
      fontSize: 12,
      distance: 5, // 距离
      formatter: function(params) {
        return filters.changeNum(params.value)
      },
      // formatter: '{c}%'
    }, // 柱子上方的数值
    itemStyle: {
      barBorderRadius: [0, 8, 8, 0], // 圆角（左上、右上、右下、左下）
      color: function (params) {

        var colorList = [
          "#F756A9",
          "#E13DDB",
          "#8E51DA",
          "#3C7EFF",
          "#5AAAFB",
          "#3FD4CF",
          "#27C346",
          "#B8E24B",
          "#FBE94B",
          "#FFCC55",
          "#FF9E3D",
          "#F9925A",
          "#F76965",
          "#ABABAC",
          "#929293",
        ];

        return colorList[params.dataIndex]

      }
    },
    data: []
  },]
}
export default option