<template>
  <div
    class="task-item"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
    ref="item"
  >
    <div class="card-title">
      <div class="card-title-left">
        {{ taskData.taskName || "-" }}
      </div>
      <div class="card-title-right">
        <a style="color: #FFCC55" @click="jumpTo">详情</a>
      </div>
    </div>
    <div class="data-list">
      <div class="data-item">
        <div class="data-item-value">
          <span class="font1">{{ taskData.completionRate }}%</span>
        </div>
        <div class="data-item-label">
          <span class="font3">任务完成率</span>
        </div>
      </div>
      <div class="data-item">
        <div class="data-item-value">
          <span class="font1">{{ taskData.completionCount }}</span>
          <span class="font2">/{{ taskData.allCount }}</span>
        </div>
        <div class="data-item-label">
          <span class="font3">完成门店/总数</span>
        </div>
      </div>
    </div>
    <a-progress
      :percent="(taskData.completionCount / taskData.allCount) * 100"
      :show-info="false"
      strokeColor="#FFCA5C"
    />
    <div class="modal-detail" ref="modal">
      <div class="modal-detail-container">
        <div class="card-title">
          {{ taskData.taskName || "-" }}
        </div>
        <div class="task-type">
          {{ taskTypeData[taskData.taskType] }}达到
          {{ taskData.targetValue | changeNum }}
        </div>
        <div class="task-explain">{{ taskData.taskExplain || "-" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskItem",
  components: {},
  props: {
    taskData: {
      type: Object,
      defautl: () => {
        return {};
      },
    },
  },
  data() {
    return {
      taskTypeData: {
        1: "视频发布数",
        2: "直播场次",
        3: "累计视频播放量",
        4: "累计直播观看量",
        5: "模板视频数",
        6: "播放≥500视频数",
        7: "时长≥30min直播场次",
        8: "播放≥500模板视频数",
        9: "时长≥60min直播场次",
        10: "时长≥90min直播场次",
        11: "时长≥120min直播场次",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 移入
    mouseOver(e) {
      // 操作dom 获取p标签改变其样式
      let top =
        this.$refs.item.getBoundingClientRect().top -
        this.$refs.modal.getBoundingClientRect().height -
        14 +
        "px";
      let left = this.$refs.item.getBoundingClientRect().left + "px";
      this.$refs.modal.style.top = top;
      this.$refs.modal.style.left = left;
      this.$refs.modal.style.display = "inline-block";
    },
    // 移出
    mouseLeave(e) {
      this.$refs.modal.style.display = "none";
    },
    jumpTo() {
      this.$router.push({
        path: `/marketing/taskProgress?taskId=${this.taskData.id}`,
        query: {
          text:
            this.taskTypeData[this.taskData.taskType] +
            "达到" +
            this.$options.filters.changeNum(this.taskData.targetValue),
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.task-item {
  width: 240px;
  min-width: 240px;
  height: 156px;
  min-height: 156px;
  margin-right: 8px;
  border-radius: 8px;
  background: #242832;
  padding: 20px 20px 24px;
  position: relative;
  // cursor: pointer;
  &:hover {
    background: #20242d;
  }
  .card-title {
    width: 100%;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    &-left {
      width: calc(100% - 40px);
      height: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      &.hover {
        display: none;
      }
    }
    &-right {
      .delete-icon {
        cursor: pointer;
        color: #687486;
        &:hover {
          color: #ffcc55;
        }
      }
    }
  }
  .font1 {
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.88);
  }
  .font2 {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.55);
  }
  .font3 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.55);
  }

  .modal-detail {
    width: 275px;
    height: 166px;
    background: #2b2f3b;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.12),
      0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 6px 16px -8px rgba(0, 0, 0, 0.32);
    border-radius: 8px;
    position: fixed;
    z-index: 999;
    display: none;
    &-container {
      width: 100%;
      height: 100%;
      padding: 20px;
      .card-title {
        height: 22px;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
        line-height: 22px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }

      .task-type {
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        color: #ffcc55;
        margin: 8px 0;
      }
      .task-explain {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.55);
        line-height: 22px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.data {
  &-list {
    display: flex;
    margin: 16px 0 8px;
  }

  &-item {
    margin: 0 24px 0 0;

    &-value {
      margin: 0 0 4px;
    }
  }
}
</style>
