var option = {
  color: ['#FFCA5C', '#F76965'],
  title: [{
    text: '90%',
    left: 'center',
    top: '40%',
    padding: [0, 0],
    textStyle: {
      color: '#fff',
      fontSize: 24,
      fontWeight: 'normal',
      align: 'center',
      fontFamily: 'DIN'
    }
  }, {
    text: '健康',
    left: 'center',
    top: '50%',
    padding: [7, 8],
    backgroundColor: 'rgba(39,195,70,0.3)',
    borderRadius: 4,
    textStyle: {
      color: '#27C346',
      fontSize: 14,
      fontWeight: 'normal',
      align: 'center',
    }
  }],
  series: [
    {
      name: '组件挂载率',
      type: 'pie',
      startAngle: 135,
      radius: ['35%', '65%'],
      center: ['50%', '50%'],
      clockwise: true,
      label: {
        normal: {
          formatter: function (params, ticket, callback) {
            return `${params.data.name}: ${params.percent}%`
          },
          padding: [0, 0],
        },
      },
      labelLine: {
        length: 10,
        length2: 20,
      },
      itemStyle: {
        normal: {
          shadowColor: 'rgba(0,0,0,0.4)',
          shadowBlur: 15,
        },
      },
      data: [],
    },
  ],
}

export default option