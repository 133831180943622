var fontSize = 16;
var legendColor = "#fff";
let yAxisData = ['S级', 'A级', 'B级', 'C级', 'D级'];
let leftData = [38, 21, 23, 28, 23]
let oldData = [23, 34, 25, 31, 19]
let rightSum = sum(oldData)
let rightData = [{
    sum: rightSum,
    value: 23
},{
    sum: rightSum,
    value: 34
},{
    sum: rightSum,
    value: 25
},{
    sum: rightSum,
    value: 31
},{
    sum: rightSum,
    value: 19
}]
function sum(arr) {
    var s = 0;
    for (var i = arr.length - 1; i >= 0; i--) {
        s += arr[i];
    }
    return s;
}
console.log('rightSum', rightSum)
let option = {
    backgroundColor: '#082034',
    tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
            type: 'none'
        },
        textStyle: {
            fontSize: fontSize
        },
        formatter: function(params) {
            if (params[0].data.sum) {
                return params[0].seriesName + ':' + params[0].value + '<br/>' + '占比:' + ((params[0].data.value / params[0].data.sum) * 100).toFixed(1) + '%'
            } else {
                return params[0].seriesName + ':' + params[0].value
            }
        }
    },
    title: [{
        text: '账号数量',
        textStyle: {
            fontSize: fontSize,
            color: legendColor,
            fontWeight: 'normal'
        },
        top: '6%',
        left: '25%'
    }, {
        text: '传播价值',
        textStyle: {
            fontSize: fontSize,
            color: legendColor,
            fontWeight: 'normal'
        },
        top: '6%',
        left: '55%'
    }],
    grid: [{
        show: false,
        left: '15%',
        top: '15%',
        width: '35%',
        containLabel: true,
        bottom: 60
    }, {
        show: false,
        left: '6%',
        top: '15%',
        bottom: 60,
        width: '0%',
        // containLabel: true,
    }, {
        show: false,
        left: '50%',
        top: '15%',
        bottom: 60,
        containLabel: true,
        width: '35%',
    }],
    xAxis: [{
        type: 'value',
        inverse: true,
        axisLabel: {
            show: false,
            color: '#979797',
            margin: 0
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        splitLine: {
            show: false
        }
    }, {
        gridIndex: 1,
        show: true,
        axisLabel: {
            show: false,
            color: '#979797',
            margin: 0
        },
        splitLine: {
            lineStyle: {
                color: '#979797',
                type: 'dashed'
            }
        }
    }, {
        gridIndex: 2,
        type: 'value',
        axisLabel: {
            show: false,
            color: '#979797',
            margin: 0
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        splitLine: {
            show: false
        }
    }],
    yAxis: [
        {
            type: 'category',
            inverse: true,
            position: 'right',
            axisLabel: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            data: yAxisData
        },
        {
            type: 'category',
            inverse: true,
            gridIndex: 1,
            position: 'left',
            axisLabel: {
                align: 'left',
                fontSize: 20,
                color: `rgba(255, 255, 255, 0.7)`
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            data: yAxisData
        },
        {
            type: 'category',
            inverse: true,
            gridIndex: 2,
            position: 'left',
            axisLabel: {
                show: false
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false
            },
            data: yAxisData
        }
    ],
    series: [
        {
            type: 'bar',
            barWidth: 20,
            name: '账号数量',
            stack: '总1',
            label: {
                normal: {
                    show: true,
                    position: 'insideRight',
                    textStyle: {
                        color: '#ffffff',
                        fontSize: '12'
                    }
                }
            },
            itemStyle: {
                normal: {
                    color: '#FAA065',
                    barBorderRadius: [6, 0, 0, 6]
                }
            },
            data: []
        },
        {
            type: 'bar',
            barWidth: 20,
            xAxisIndex: 2,
            yAxisIndex: 2,
            name: '传播价值',
            stack: '总2',
            label: {
                normal: {
                    show: true,
                    position: 'insideLeft',
                    textStyle: {
                        color: '#ffffff',
                        fontSize: '12'
                    }
                }
            },
            itemStyle: {
                normal: {
                    color: '#7A91FF',
                    barBorderRadius: [0, 6, 6, 0]
                }
            },
            data: []
        }
    ]
};

export default option