<template>
  <PanleWrap>
    <div class="brand_header">
      <h3>品牌影响力</h3>
    </div>
    <div class="tools">
      <div>
        <Order
          :order-list="orderList"
          @order-click="hadnleOrderClick"
          style="top: 5px"
        />
      </div>
      <div>
        <a-month-picker
          v-model="searchTime"
          placeholder="Select month"
          :disabled-date="disabledDate"
          @change="onChange"
          locale="zh-cn"
          style="margin-right: 24px;"
        />
      </div>
    </div>
    <a-spin :spinning="mixin_isSpinning">
      <div class="brand_content clearfix">
        <template v-if="brandList.length > 0">
          <div
            class="brand_item_wrap"
            v-for="(item, idx) in brandList"
            :key="item.principal_id"
          >
            <div class="brand_scroll_wrap" @click="handleItemClick(item, idx)">
              <!-- <router-link
              :to="{
                name: 'influenceDetail',
                params: { principal_id: item.principal_id },
              }"
            > -->
              <div class="brand_info">
                <i style="background: #fff;">
                  <img :src="`${OSS_HOST}${item.avatar || ''}`" alt="" />
                </i>
                <div>
                  <h3>
                    {{ item.principal_name || "暂无" }}
                  </h3>
                  <div>
                    <p>互动量</p>
                    <b>{{ item.polling_count | changeNum }}</b>
                  </div>
                </div>
              </div>
              <div class="brand_data">
                <div>
                  <h3>粉丝总量</h3>
                  <p>{{ item.polling_fans_count | changeNum }}</p>
                </div>
                <div>
                  <h3>相关账号</h3>
                  <p>{{ item.polling_author_count | changeNum }}</p>
                </div>
                <div>
                  <h3>直播场次</h3>
                  <p>{{ item.polling_live_count | changeNum }}</p>
                </div>
                <div>
                  <h3>视频发布</h3>
                  <p>{{ item.polling_aweme_count | changeNum }}</p>
                </div>
              </div>
              <!-- </router-link> -->
            </div>
          </div>
        </template>
        <template
          v-if="
            mixin_isBizError || mixin_isNetworldError || brandList.length === 0
          "
        >
          <div class="font-color3" style="text-align: center; width: 100%; padding-top: 20px">
            暂无数据
          </div>
        </template>
      </div>
    </a-spin>
    <div class="pagination_wrap">
      <a-pagination
        v-model="pagination.current"
        size="small"
        :page-size="pagination.size"
        :total="pagination.total"
        show-less-items
        @change="handleCurrentPageChange"
      />
    </div>
  </PanleWrap>
</template>

<script>
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/brand.js";
import _ from "lodash";
import mixin from "./mixins/mixin";
import Order from "./components/Order";
import moment from 'moment'
const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;
export default {
  components: { PanleWrap, Order },
  mixins: [mixin],
  data() {
    return {
      searchTime: moment().subtract(1,'months'),
      disabledDate(current) {
        // Can not select days before today and today
        return current && current >= moment().endOf('day');
      },
      brandList: [],
      OSS_HOST,
      pagination: {
        size: 16,
        current: 1,
        total: 0,
      },
      order: "desc",
      date: "",
      // '1':互动量,'2':相关账号,'3':视频发布,'4':直播场次,'5':粉丝总量
      sort: 'pollingCount',
      orderList: [
        {
          text: "互动量",
          value: 'pollingCount',
        },
        {
          text: "相关账号",
          value: 'pollingAuthorCount',
        },
        {
          text: "视频发布",
          value: 'pollingAwemeCount',
        },
        {
          text: "直播场次",
          value: 'pollingLiveCount',
        },
        {
          text: "粉丝总量",
          value: 'pollingFansCount',
        },
      ],
    };
  },
  methods: {
    getList() {},
    handleItemClick(item, idx) {
      console.log(item, idx);
      let url = "/influenceDetail?principal_id=" + item.principal_id
      // this.$router.push(
      //   "/brand/influenceDetail?principal_id=" + item.principal_id
      // );
      window.open(url)
    },
    hadnleOrderClick(res) {
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.sort = res.data.value;
      this.getList();
    },
    handleCurrentPageChange(data) {
      this.pagination.current = data;
      this.getList();
    },
    onChange(date, dateString) {
      this.date = dateString
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.getList()
    },
  },
  async created() {
    // defind getList
    this.date = moment().format('YYYY-MM')
    this.getList = this.requestDecorator(() => {
      const params = {
        date: this.searchTime.format('YYYY-MM'),
        sort: this.sort,
        order: this.order,
        current: this.pagination.current,
        size: this.pagination.size,
      };
      return api.getInfluenceList(params).then((res) => {
        this.brandList = _.get(res, "data.data.list", []);
        let pagination = {
          current: _.get(res, "data.data.pageNum", 1),
          total: _.get(res, "data.data.total", 0),
          size: 16,
        };
        this.pagination = pagination;
        return res;
      });
    });
    await this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_handle.scss';
.brand_header {
  > h3 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 24px;
    @include font_color("font_color2");
    margin: 0;
  }
}
.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 24px;
  min-width: 997px;
  max-width: 1324px;
}
.brand_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 997px;
  max-width: 1324px;
  padding: 0 24px 50px;
  overflow: auto;
  .brand_item_wrap {
    padding: 24px;
    width: calc(25% - 25px);
    margin: 0 24px 20px 0;
    height: 255px;
    @include background_color("background_color26");
    // @include border_color("border_color1");
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 16px;
    > .brand_scroll_wrap {
      width: 100%;
      height: 100%;
      // overflow-y: scroll;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      cursor: pointer;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
    .brand_info {
      > i {
        display: block;
        width: 80px;
        height: 80px;
        border: 4px solid rgba(0, 0, 0, 0);
        @include border_color("border_color2");
        border-radius: 4px;
        > img {
          display: block;
          width: 72px;
          height: 72px;
        }
      }
      > div {
        > h3 {
          @include font_color("font_color1");
          font-weight: bold;
          font-size: 18px;
          margin: 0; // 重置样式
          width: 80px;
          height: 50px;
          display: inline-block;
        }
        > div {
          padding: 8px 0 8px 8px;
          width: 80px;
          text-align: left;
          // @include background_color("background_color10");
          > b {
            display: block;
            font-size: 18px;
            @include font_color("font_color4");
          }
          > p {
            font-size: 12px;
            @include font_color("font_color2");
            margin: 0; // 重置样式
          }
        }
      }
    }
    .brand_data {
      > div {
        > h3 {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 0;
        }
        > p {
          margin: 0;
        }
      }
    }

      > .brand_scroll_wrap {
        display: flex;
        > .brand_info {
          width: 50%;
          > i {
            margin-bottom: 10px;
          }
          > div {
            text-align: center;
            width: 80px;
            > h3 {
              // margin-bottom: 30px;
            }
          }
        }
        // @offset: 20px;
        > .brand_data {
          width: 50%;
          padding-left: 24px;
          > div {
            width: 80px;
            padding-left: 8px;
            margin-bottom: 10px;
            border-left: 3px solid rgba(0, 0, 0, 0);
            @include border_color("border_color2");
            > p {
              position: relative;
              @include font_color("font_color1");
              font-size: 16px;
            }
            > h3 {
              position: relative;
              @include font_color("font_color3");
              font-size: 12px;
            }
          }
        }
      }
  }
}

.pagination_wrap {
  position: fixed;
  z-index: 99;
  left: 200px;
  bottom: 0;
  padding: 20px;
  text-align: center;
  width: calc(100% - 200px);
  @include background_color("background_color1");
}
</style>