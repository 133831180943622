<template>
  <div class="item">
    <div class="title-box">
      <div class="title">
        <svg-icon :icon-class="title.icon" class="icon" /><span>{{title.title}}</span>
      </div>
    </div>
    <div class="chart-box">
      <my-chart
        :options="getPieOption(chartData)"
        :id="id"
      ></my-chart>
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import chartPie from "./options/chartPie.js";
import deepClone from "@/utils/clone-utils";
export default {
  components: {
    MyChart,
  },
  name: "TitlePie",
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: Object,
      default: () => {
        return {
          icon: '',
          title: '-'
        }
      }
    },
    chartData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {
    getPieOption(data) {
      let option = deepClone(chartPie);
      option.series[0].data = data;
      console.log(111, data)
      return option;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-box {
    display: inline-block;
    .title {
      display: flex;
      align-items: center;
      padding: 0 22px;
      height: 36px;
      border-radius: 18px;
      @include background_color("background_color1");
    }
    .icon {
      margin-right: 4px;
      font-size: 25px;
    }
    span {
      font-size: 12px;
      font-weight: 500;
      @include font_color("font_color5");
    }
  }
}
.chart-box {
  width: 100%;
  height: calc(100% - 40px);
}
</style>
