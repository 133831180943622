<template>
  <PanleWrap>
    <div class="list_header">
      <platform-header
        title="行业平均表现"
        :currentTab="currentTab"
        @tabChange="tabChange"
      />
      <div class="tools">
        <a-month-picker
          v-model="searchTime"
          class="aft-month-picker default"
          placeholder="Select month"
          :disabled-date="pickerOptions"
          @change="onChange"
          locale="zh-cn"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-month-picker>
      </div>
      <div class="main-container">
        <a-row type="flex" :gutter="[24, 24]" class="row-style">
          <a-col flex="auto">
            <div class="card-bg h349">
              <div class="card-title">
                <div>
                  <svg-icon icon-class="title" class="svg-title" />
                  <span>平均发布情况</span>
                </div>
                <a-select v-model="selectValue" style="width: 170px">
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in barSelectData"
                    :key="index"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="chart-div" style="height: 281px">
                <my-chart
                  :options="getBarOption(barData.xData, barData[selectValue])"
                  :id="'bar-chart'"
                ></my-chart>
              </div>
            </div>
          </a-col>
          <a-col flex="350px">
            <div class="card-bg h349">
              <div class="card-title">
                <div>
                  <svg-icon icon-class="title" class="svg-title" />
                  <span>营销价值</span>
                </div>
              </div>
              <div class="chart-div" style="height: 281px">
                <my-chart
                  :options="getPieOption(communicationData)"
                  :id="'pie-chart1'"
                ></my-chart>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="[24, 24]" class="row-style">
          <a-col flex="auto" style="padding: 24px 12px">
            <div class="card-bg h329">
              <div class="table-container">
                <div class="table-title">
                  <span style="width: 19%">直播</span>
                  <span style="width: 23%">爆款/月</span>
                  <span style="width: 23%">场/月</span>
                  <span style="width: 35%">观看人次/场均</span>
                </div>
                <div
                  class="table-content"
                  v-for="(item, index) in brandInfo"
                  :key="index"
                >
                  <span style="width: 19%">{{ item.account_level }}</span>
                  <span style="width: 23%">{{
                    item.average_popular_live
                  }}</span>
                  <span style="width: 23%">{{ item.live_count }}</span>
                  <span style="width: 35%">{{ item.average_watche_live }}</span>
                </div>
              </div>
            </div>
          </a-col>
          <a-col flex="auto" style="padding: 24px 12px"
            ><div class="card-bg h329">
              <div class="table-container">
                <div class="table-title">
                  <span style="width: 19%">短视频</span>
                  <span style="width: 23%">爆款/月</span>
                  <span style="width: 23%">个/月</span>
                  <span style="width: 35%">平均播放/视频</span>
                </div>
                <div
                  class="table-content"
                  v-for="(item, index) in brandInfo"
                  :key="index"
                >
                  <span style="width: 19%">{{ item.account_level }}</span>
                  <span style="width: 23%">{{
                    item.average_popular_aweme
                  }}</span>
                  <span style="width: 23%">{{ item.aweme_count }}</span>
                  <span style="width: 35%">{{ item.average_play_volume }}</span>
                </div>
              </div>
            </div></a-col
          >
          <a-col flex="350px" style="padding: 24px 12px"
            ><div class="card-bg h329">
              <!-- <div class="card-title">
                <div>
                  <svg-icon icon-class="title" class="svg-title" />
                  <span>销售价值</span>
                </div>
              </div>
              <div class="chart-div" style="height: 281px">
                <my-chart
                  :options="getPieOption(salesData)"
                  :id="'pie-chart2'"
                ></my-chart>
              </div> -->
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="[24, 24]" class="row-style">
          <a-col :span="24">
            <div class="card-bg h397">
              <div class="card-title">
                <div>
                  <svg-icon icon-class="title" class="svg-title" />
                  <span>月度直播场次分布</span>
                </div>
                <a-select
                  v-model="selectDateValue"
                  :suffixIcon="suffixIcon"
                  style="width: 170px"
                  @change="selectDateChange"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in dateSelectData"
                    :key="index"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="chart-div" style="height: 351px">
                <my-chart
                  :options="
                    getDateOption(
                      calendarData.xData,
                      calendarData[selectDateValue]
                    )
                  "
                  :id="'date-chart'"
                ></my-chart>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </PanleWrap>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import chartBar from "./options/chartBar";
import chartPie from "./options/chartPie";
import chartDate from "./options/chartDate";
import PanleWrap from "./layout/PanelWrap";
import deepClone from "@/utils/clone-utils";
import _ from "lodash";
import moment from "moment";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "brandPerformance"
);

export default {
  components: {
    MyChart,
    PanleWrap,
    PlatformHeader,
  },
  data() {
    return {
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      searchTime: moment().subtract(1, "months"),
      disabledDate(itemDate) {
        // Can not select days before today and today
        if (itemDate) {
          let curMonth = moment().month();
          let itemMonth = itemDate.month();
          return itemMonth > curMonth; // > 包括当月，>= 不包括当月
        } else {
          return false;
        }
      },
      startTime: "",
      endTime: "",
      currentTab: "", // 平台(抖音:douyin,快手:kuaishou)
      selectValue: "fans_count",
      barSelectData: [
        {
          label: "粉丝总量",
          value: "fans_count",
        },
        {
          label: "直播观看",
          value: "live_watch_count",
        },
        {
          label: "直播场次",
          value: "live_count",
        },
        {
          label: "短视频发布数",
          value: "aweme_count",
        },
        {
          label: "爆款短视频数",
          value: "average_popular_aweme",
        },
      ],
      selectDateValue: "live_count_data",
      dateSelectData: [
        {
          label: "全部直播",
          value: "live_count_data",
        },
        {
          label: "有效直播",
          value: "valid_live_data",
        },
        {
          label: "爆款直播",
          value: "popular_live_data",
        },
      ],
    };
  },
  created() {
    // this.loadReleasedAvg();
    // this.loadCalendar();
  },
  computed: {
    ...mapState({
      brandInfo: (state) => state.brandInfo,
      barData: (state) => state.barData,
      communicationData: (state) => state.communicationData,
      salesData: (state) => state.salesData,
      calendarData: (state) => state.calendarData,
    }),
  },
  methods: {
    ...mapActions(["getReleasedAvg", "getReleasedCalendar"]),
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().startOf("month");
    },
    loadReleasedAvg() {
      let params = {
        platform: this.currentTab,
        start_time: moment(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(this.searchTime)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      this.getReleasedAvg(params);
    },
    loadCalendar() {
      let params = {
        platform: this.currentTab,
      };
      this.getReleasedCalendar(params);
    },
    tabChange(val) {
      this.currentTab = val;
      this.loadReleasedAvg();
      this.loadCalendar();
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      this.loadReleasedAvg();
    },
    selectChange(val) {
      console.log(val);
    },
    selectDateChange(val) {
      // this.loadCalendar()
    },

    // 柱状图 set option
    getBarOption(xData, yData) {
      let option = deepClone(chartBar);
      option.yAxis.data = xData;
      option.series[0].data = yData;
      return option;
    },
    // 饼图 set option
    getPieOption(data) {
      let option = deepClone(chartPie);
      option.series[0].data = data;
      return option;
    },
    getDateOption(xData, yData) {
      let option = deepClone(chartDate);
      let numberArr = [];
      yData.forEach((item) => {
        numberArr.push(item[2]);
      });
      let maxNum = Math.max.apply(null, numberArr);
      yData = yData.map(function(item) {
        return [item[1], item[0], item[2]];
      });
      option.visualMap.max = maxNum;
      option.xAxis.data = xData;
      option.series[0].data = yData;

      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include font_color("font_color2");
}
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.tools {
  width: 100%;
  max-width: 1424px;
  min-width: 1008px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: flex-end;
  padding: 0 24px;
}
.h329 {
  height: 329px;
}
.h349 {
  height: 349px;
}
.h397 {
  height: 397px;
}
.h437 {
  height: 437px;
}
.row-style {
  max-width: 1448px;
  min-width: 1032px;
  padding: 0 24px;
  flex-wrap: nowrap;
}
.card-bg {
  @include background_color("background_color2");
}
.card-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
  .svg-title {
    font-size: 16px;
    margin-right: 8px;
  }
  > div > span {
    font-size: 16px;
    @include font_color("font_color2");
  }
}
.main-container {
  width: 100%;
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}
.table-container {
  padding: 0 16px;
  .table-title {
    height: 52px;
    line-height: 52px;
    display: flex;
    flex-direction: row;
    @include font_color("font_color2");
    font-size: 14px;
  }
  .table-content {
    display: flex;
    flex-direction: row;
    height: 56px;
    line-height: 56px;
    border-top: 1px solid rgba(0, 0, 0, 0);
    @include border_color("border_color1");
    @include font_color("font_color3");
  }
  div span:first-child {
    @include font_color("font_color2");
  }
  div:nth-child(2) span {
    @include font_color("font_color6");
  }
  div:nth-child(3) span {
    @include font_color("font_color5");
  }
  div:nth-child(4) span {
    @include font_color("font_color4");
  }
}
</style>
