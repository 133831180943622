import filters from '@/filters'
var option = {
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    top: 0,
    left: 'center',
    itemWidth: 12,
    itemHeight: 7,
    inactiveColor: '#3C4252',
    // icon: 'path://M512.005117 958.708971C265.683035 958.708971 65.290005 758.316965 65.290005 511.99386c0-246.310825 200.39303-446.703855 446.715111-446.703855 246.310825 0 446.703855 200.39303 446.703855 446.703855C958.708971 758.316965 758.316965 958.708971 512.005117 958.708971zM512.005117 169.716356c-188.738595 0-342.289784 153.545048-342.289784 342.277504 0 188.738595 153.551188 342.289784 342.289784 342.289784 188.733479 0 342.278527-153.551188 342.278527-342.289784C854.283644 323.261405 700.738595 169.716356 512.005117 169.716356z',
    textStyle: {
      fontSize: 12,
      color: 'rgba(255,255,255,0.65)',
      padding: [0, 20, 0, 4]
    },
    data: ['＜5线索门店', '5-10线索门店', '10-50线索门店', '50+线索门店']
  },
  grid: {
    "left": "70",
    "top": "40",
    "right": "100",
    "bottom": "60"
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      show: true, //隐藏x轴
    },
    axisTick: {
      show: true,  //刻度线
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6', // 坐标轴文字颜色
        fontSize: 10, // 坐标轴文字大小
        padding: [0, 10, 0, 0]
      },
    },
    data: []
  },
  yAxis: {
    type: 'value',
    position: 'left',
    axisTick: {
      show: true,  //刻度线
    },
    axisLine: {
      show: false, //隐藏y轴
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6', // 坐标轴文字颜色
        fontSize: 10, // 坐标轴文字大小
        padding: [0, 10, 0, 0]
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["rgba(255,255,255,0.04)"],
      },
    },
  },
  series: [
    {
      name: '＜5线索门店',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#FAC858',
        },
      },
      data: []
    },
    {
      name: '5-10线索门店',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#8E51DA',
        },
      },
      data: []
    },
    {
      name: '10-50线索门店',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#3C7EFF',
        },
      },
      data: []
    },
    {
      name: '50+线索门店',
      smooth: true,
      type: 'line',
      symbol: "circle",
      showSymbol: true,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: '#F76965',
        },
      },
      data: []
    }
  ]
};
export default option