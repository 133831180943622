
<template>
  <div class="detail-container">
    <div class="platform-div">
      <top-platform-tab
        ref="platformTab"
        :tabData="tabData"
        @tabChange="handlePlatformClick"
      ></top-platform-tab>
    </div>
    <div class="title">品牌详情</div>
    <div class="influence_wrap">
      <div class="brand_wrap">
        <div class="brand_info">
          <i>
            <img :src="`${OSS_HOST}${principalInfo.avatar || ''}`" width="72" alt="" />
          </i>
          <h3>{{ principalInfo.principal_name || "暂无名称" }}</h3>
        </div>
        <div class="data_info_wrap">
          <div class="data_item_wrap">
            <div class="data_item border1">
              <b>{{ principalInfo.polling_dealer_count | changeNum }}</b>
              <p>入驻经销商</p>
            </div>
            <div class="data_item border1">
              <b>{{ principalInfo.polling_author_count | changeNum }}</b>
              <p>相关账号</p>
            </div>
          </div>
          <div class="data_item_wrap">
            <div class="data_item border1">
              <b>{{ principalInfo.polling_live_count | changeNum }}</b>
              <p>直播场次</p>
            </div>
            <div class="data_item border1">
              <b>{{ principalInfo.polling_aweme_count | changeNum }}</b>
              <p>相关视频</p>
            </div>
          </div>
          <div class="divding"></div>
          <div class="data_item_wrap">
            <div class="data_item border2">
              <b>{{ principalInfo.polling_count | changeNum }}</b>
              <p>互动总量</p>
            </div>
            <div class="data_item border2">
              <b>{{ principalInfo.polling_like_count | changeNum }}</b>
              <p>获赞总数</p>
            </div>
          </div>
          <div class="data_item_wrap">
            <div class="data_item border2">
              <b>{{ principalInfo.polling_commet_count | changeNum }}</b>
              <p>评论总数</p>
            </div>
            <div class="data_item border2">
              <b>{{ principalInfo.polling_share_count | changeNum }}</b>
              <p>转发总数</p>
            </div>
          </div>
        </div>
      </div>

      <div class="influence_list_wrap">
        <div class="tools">
          <a-month-picker
            v-model="searchTime"
            placeholder="Select month"
            :disabled-date="disabledDate"
            @change="onChange"
            locale="zh-cn"
          />
        </div>
        <div class="play_type_wrap">
          <span
            v-for="(item, idx) in publishTypeList"
            :key="item.value"
            :class="[publishTypeIdx === idx ? 'play_type_check' : '']"
            @click="handleTypeClick(item, idx)"
            >{{ item.text }}
            <b v-if="item.value === publishType">{{ pagination.total || 0 }}</b>
          </span>
        </div>
        <platform-tab
          :tabData="publishList"
          @tabChange="handlePublishClick"
          background="none"
          activeBackground="none"
          :isButton="false"
          style="margin: 8px 0 24px 16px;"
        ></platform-tab>
        <div class="influence_list_head">
          <span style="width: 10%">排序</span>
          <span style="width: 58%">{{publishType == 'aweme' ? '视频' : '直播'}}</span>
          <span style="width: 16%">点赞量</span>
          <span style="width: 16%">互动总量</span>
        </div>
        <a-spin :spinning="mixin_isSpinning">
          <div class="list_content">
            <template v-if="detailList.length > 0">
              <div
                class="video_item"
                v-for="(item, idx) in detailList"
                :key="idx"
              >
                <div class="serial_number">{{ idx + 1  + (pagination.current - 1) * pagination.size}}</div>
                <div class="video_content">
                  <div class="cover_wrap" @click="routerTo(item)">
                    <i>
                      <img
                        :src="item.cover || ''"
                        :alt="item.cover || '暂无封面'"
                        width="24"
                      />
                    </i>
                  </div>
                  <div class="video_info_wrap">
                    <p @click="routerTo(item)">
                      {{ item.title || "暂无标题" }}
                    </p>
                    <h3 @click="routerToPro(item)">
                      <i>
                        <img :src="item.author_avatar || ''" alt="" />
                      </i>
                      <span class="font-color2" style="font-size: 14px;">{{ item.nickname || "暂无" }}</span> 
                      <br>
                      <span class="font-color3" style="position: relative;top: 5px;">发布于{{item.publish_time | formatPt}}</span>
                    </h3>
                  </div>
                  <div class="video_like">{{ item.like_count || 0 }}</div>
                  <div class="video_interactive">
                    {{ item.interaction_count || 0 }}
                  </div>
                </div>
              </div>
            </template>
            <template
              v-if="
                mixin_isBizError ||
                mixin_isNetworldError ||
                detailList.length === 0
              "
            >
              <div class="font-color3" style="text-align: center; width: 100%; padding-top: 20px">
                暂无数据
              </div>
            </template>
          </div>
        </a-spin>
        <div class="pagination_wrap">
          <a-pagination
            v-model="pagination.current"
            size="small"
            :total="pagination.total"
            :page-size="pagination.size"
            :page-size-options="pageSizeOptions"
            show-less-items
            @change="handleCurrentPageChange"
          />
          <!-- show-size-changer
            @showSizeChange="handlePageSizeChange" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/brand.js";
import _ from "lodash";
import moment from "moment";
import mixin from "./mixins/mixin";
import Platform from "./components/Platform";
import PlatformTab from "@/components/PlatformTab/PlatformTab";
import TopPlatformTab from "@/components/PlatformTab/TopPlatformTab";

const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;
export default {
  components: { PanleWrap, Platform, PlatformTab, TopPlatformTab },
  mixins: [mixin],
  filters: {
    formatPt(str) {
      if (!str) {
        return "-";
      }
      return moment(str).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    return {
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
      ],
      platform: "douyin",
      searchTime: moment().subtract(1,'months'),
      disabledDate(itemDate) {
        // Can not select days before today and today
        if (itemDate) {
          let curMonth = moment().month();
          let itemMonth = itemDate.month();
          return itemMonth > curMonth; // > 包括当月，>= 不包括当月
        } else {
          return false;
        }
      },
      pageSizeOptions: ["10", "20", "50"],
      principalInfo: {},
      detailList: [],
      OSS_HOST,
      pagination: {
        size: 10,
        current: 1,
        total: 0,
      },
      startTime: "",
      endTime: "",
      publishTypeList: [
        {
          text: "视频",
          value: "aweme",
          // count: 12345,
        },
        {
          text: "直播",
          value: "live",
          // count: 123,
        },
      ],
      publishList: [
        {
          label: "全部",
          value: "all",
        },
        {
          label: "官号发布",
          value: "officer",
        },
        {
          label: "经销商发布",
          value: "dealer",
        },
      ],
      publish: "all", // 发布来源(全部:all,官号:officer,经销商官号:dealer) ,
      publishIdx: 0,
      publishType: "aweme", //  发布类型(视频:aweme,直播:live) ,
      publishTypeIdx: 0,
    };
  },
  methods: {
    getList() {},
    getInfo() {
      const params = {
        // startTime: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
        // endTime: moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'),
        startTime: this.startTime,
        endTime: this.endTime,
        pollingId: this.$route.query.principal_id,
      };
      return api.getPrincipalInfo(params).then((res) => {
        this.principalInfo = _.get(res, "data.data", {}) || {};
      });
    },
    handlePlatformClick(val) {
      this.resetPagination();
      this.platform = val;
      this.getList();
    },
    handleTypeClick(item, idx) {
      let data = _.clone(item);
      this.publishType = data.value;
      this.publishTypeIdx = idx;
      this.resetPagination();
      this.getList();
    },
    handlePublishClick(val) {
      this.publish = val;
      this.resetPagination();
      this.getList();
    },
    handleCurrentPageChange(data) {
      this.pagination.current = data;
      this.getList();
    },
    handlePageSizeChange(current, pageSize) {
      let pagination = {
        current: 1,
        total: 0,
        size: pageSize,
      };
      this.pagination = pagination;
      this.getList();
    },
    resetPagination() {
      this.pagination.current = 1;
      this.pagination.total = 0;
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      let obj = this.calcStartEndTime(date);
      this.startTime = obj.startTime;
      this.endTime = obj.endTime;
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.getList();
      this.getInfo();
    },
    calcStartEndTime(date) {
      let start = date.startOf("month").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("month").format("YYYY-MM-DD HH:mm:ss");
      return {
        startTime: start,
        endTime: end,
      };
    },
    routerTo(item) {
      if (this.publishType == 'aweme') {
        if (item.id) {
          let url = `/videoDetail?id=${item.id}&platform=${this.platform}`;
          window.open(url);
        } else {
          this.$message.error("暂无视频ID，无法查看详情");
        }
      }
      if (this.publishType == 'live') {
        if(item.id) {
          let url = `/liveDetail?id=${item.id}&platform=${this.platform}`
          window.open(url)
        } else {
          this.$message.error('暂无直播房间ID，无法查看详情')
        }
      }
    },
    routerToPro(item) {
      console.log(item)

      this.$router.push({
        path: '/dealer/production',
        query: {
          type: this.platform,
          author_id: item.author_id
        }
      })
    }
  },
  // todoD
  async created() {
    this.$nextTick(() => {
      this.$refs.platformTab.currentClick = this.$route.query.platform
    })
    this.platform = this.$route.query.platform
    let timeObj = this.calcStartEndTime(moment().subtract(1,'months'));
    this.startTime = timeObj.startTime;
    this.endTime = timeObj.endTime;
    this.getList = this.requestDecorator(() => {
      const body = {
        current: this.pagination.current,
        size: this.pagination.size,
        endTime: this.endTime,
        startTime: this.startTime,
        platform:
          this.platform == "douyin"
            ? "dy"
            : this.platform == "kuaishou"
            ? "ks"
            : "",
        principalId: this.$route.query.principal_id,
        publish: this.publish, // 发布来源(全部:all,官号:officer,经销商官号:dealer) ,
        publishType: this.publishType, //  发布类型(视频:aweme,直播:live) ,
      };
      return api.getPrincipalDetailList(body).then((res) => {
        this.detailList = _.get(res, "data.data.list", []);
        let pagination = {
          current: _.get(res, "data.data.pageNum", 1),
          total: _.get(res, "data.data.total", 0),
          size: this.pagination.size,
        };
        this.pagination = pagination;
        return res;
      });
    });
    try {
      const [infoRes, detailListRes] = await Promise.allSettled([
        this.getInfo(),
        this.getList(),
      ]);
      console.log(infoRes, detailListRes);
      if (infoRes.status === "fulfilled") {
        // this.principalInfo = _.get(infoRes, "value.data.data", {});
      }
    } catch (err) {
      console.error(err);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.detail-container {
  width: 100%;
}
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.influence_wrap {
  display: flex;
  padding: 0 24px;
  height: calc(100vh - 200px);
  .brand_wrap {
    width: 260px;
    height: 100%;
    margin-right: 20px;
    // border: 1px solid @border-color;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0);
    @include border_color("border_color1");
    @include background_color("background_color5");
    .brand_info {
      width: 100%;
      height: 194px;
      background: url('../../assets/img/brand-background.png');
      padding: 43px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      > i {
        > img {
          border-radius: 4px;
          border: 4px solid rgba(0, 0, 0, 0);
          @include border_color("border_color2");
        }
      }
      > h3 {
        font-size: 16px;
        @include font_color("font_color1");
        text-align: center;
        margin: 0;
      }
    }
    .data_info_wrap {
      padding: 0 24px 24px;
      .divding {
        width: 100%;
        margin: 24px 0 0;
        height: 1px;
        border-top: 1px solid rgba(0, 0, 0, 0);
        @include border_color("border_color1");
      }
      .data_item_wrap {
        display: flex;
        justify-content: flex-start;
        padding: 0 0px;
        .data_item {
          width: 50%;
          height: 42px;
          font-size: 12px;
          margin-top: 24px;
          padding-left: 8px;
          border-left: 3px solid rgba(0, 0, 0, 0);
          > p {
            @include font_color("font_color3");
            font-size: 12px;
          }
          > b {
            @include font_color("font_color1");
            font-size: 16px;
            font-weight: inherit;
          }
        }

      .data_item.border1 {
        @include border_color("border_color8");
      }
      .data_item.border2 {
        @include border_color("border_color9");
      }
      }
    }
  }
  .influence_list_wrap {
    width: calc(100% - 260px);
    .tools {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 16px;
    }
    .influence_list_head {
      display: flex;
      width: 100%;
      height: 36px;
      line-height: 36px;
      padding: 0 16px;
      justify-content: flex-start;
      font-size: 14px;
      @include background_color("background_color5");
      @include font_color("font_color2");
      > span {
        display: inline-block;
        text-align: left;
      }
      > span:last-child {
        text-align: left;
      }
    }
    .list_content {
      width: 100%;
      height: calc(100vh - 400px);
      overflow-y: auto;
      .video_item {
        width: 100%;
        height: 126px;
        padding: 16px 16px 22px;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        @include border_color("border_color1");
        display: flex;
        @include font_color("font_color2");
        .serial_number {
          line-height: 87px;
          width: 10%;
        }
        .video_content {
          cursor: pointer;
          display: flex;
          width: 90%;
          .cover_wrap {
            margin-right: 12px;
            > i {
              display: block;
              width: 66px;
              height: 88px;
              > img {
                display: block;
                width: 66px;
                height: 88px;
              }
            }
          }
          .video_info_wrap {
            display: inline-block;
            width: calc(64.44% - 78px);
            .font-color2 {
              @include font_color("font_color2");
            }
            .font-color3 {
              @include font_color("font_color3");
            }
            > p {
              width: 100%;
              max-width: 500px;
              display: inline-block;
              margin: 0;
              height: 40px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            > h3 {
              font-size: 12px;
              font-weight: 500;
              > i {
                position: relative;
                top: 5px;
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border-radius: 50%;
                overflow: hidden;
                > img {
                  display: block;
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
          .video_like {
            width: 17.78%;
            line-height: 87px;
          }
          .video_interactive {
            width: 17.78%;
            line-height: 87px;
            text-align: left;
            @include font_color("font_color4");
          }
        }
      }
    }
  }
}
.play_type_wrap {
  position: relative;
  height: 36px;
  padding: 0 16px;
  line-height: 36px;
  border-radius: 2px;
  font-size: 14px;
  @include background_color("background_color2");

  > span {
    position: relative;
    display: inline-block;
    margin-right: 32px;
    height: 36px;
    cursor: pointer;
    transition: all 0.3s linear;
    @include font_color("font_color3");

    > b {
      // color: @number-color;
      font-weight: 400;
    }
  }
  > span.play_type_check {
    @include font_color("font_color1");
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    @include border_color("border_color4");
    &::after {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      height: 1px;
      // background: @number-color;
      content: "  ";
    }
  }
}

.publish_type_wrap {
  position: relative;
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  font-size: 12px;
  margin-bottom: 10px;
  > span {
    position: relative;
    display: inline-block;
    padding: 0 15px;
    cursor: pointer;
    transition: all 0.3s linear;
  }
}
.pagination_wrap {
  width: calc(100% - 455px);
  text-align: center;
  position: fixed;
  z-index: 99;
  bottom: 0;
  padding: 20px;
}
</style>