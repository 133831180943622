<template>
  <div>
    <platform-header
      title="门店装修质检"
      :currentTab="currentTab"
      @tabChange="tabChange"
      :showTooltip="true"
      :code="'mzqt01'"
    >
    </platform-header>
    <div class="tools">
      <div class="left">
        <div class="button" @click="goBack">
          <span class="icon"
            ><svg-icon icon-class="arrow-right" class="arrow-right"
          /></span>
          <span>返回</span>
        </div>
        <a-select
          v-model="regionValue"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin-right: 10px"
          placeholder="选择大区"
        >
          <a-select-option value="" v-if="isAllRegion"
            >不限大区</a-select-option
          >
          <a-select-option
            :value="item.id"
            v-for="item in areaData"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="qc_field"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin-right: 10px"
          placeholder="选择类型"
        >
          <a-select-option
            :value="item.value"
            v-for="(item, index) in typeList"
            :key="index"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="qc_result"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin-right: 10px"
          placeholder="选择级别"
        >
          <a-select-option
            :value="item.value"
            v-for="(item, index) in levelList"
            :key="index"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="groupValue"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin-right: 20px"
          placeholder="选择分组"
        >
          <a-select-option value="">不限分组</a-select-option>
          <a-select-option
            :value="item.group_code"
            v-for="item in groupData"
            :key="item.group_code"
          >
            {{ item.group_name }}
          </a-select-option>
        </a-select>
        <a-button class="search-btn" @click="onSearch">确定</a-button>
      </div>
      <div class="right">
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button class="button" @click="exportExcel" :loading="btn_Loaidng">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>
    <div class="info-div">
      <div class="tip">提示：头像、背景图每日更新，质检结果每月更新</div>
      <div class="info">
        <div>
          <span class="sign sign1"></span>
          <span>标准</span>
          <span class="fc-20">（{{ countData.violation_count1 || 0 }}）</span>
        </div>
        <div>
          <span class="sign sign2"></span>
          <span>合格</span>
          <span class="fc-20">（{{ countData.violation_count2 || 0 }}）</span>
        </div>
        <div>
          <span class="sign sign3"></span>
          <span>疑似违规</span>
          <span class="fc-20">（{{ countData.violation_count3 || 0 }}）</span>
        </div>
        <div>
          <span class="sign sign4"></span>
          <span>违规</span>
          <span class="fc-20">（{{ countData.violation_count4 || 0 }}）</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :scroll="{ x: '100%', y: 'calc(100vh - 392px)' }"
          :rowKey="(data, index) => index"
          :pagination="false"
          :loading="loading"
          :locale="locale"
        >
          <div slot="dealer_name_title">
            <div>
              门店名称/账号昵称<span class="fc-20">（{{ total || "-" }}）</span>
            </div>
          </div>
          <div slot="dealer_name" slot-scope="text, record" class="author-card">
            <WebpImage
              :src="record.avatar"
              :width="'48px'"
              :height="'48px'"
              :radius="'50%'"
              style="margin-right: 12px"
            />
            <div style="width: calc(100% - 80px)">
              <div class="dealer-title">{{ record.dealer_name || "-" }}</div>
              <div class="nickname">
                {{ record.nickname || "-" }}
              </div>
            </div>
          </div>
          <div slot="store_code" slot-scope="text, record">
            <div class="dealer-title">{{ record.store_code || "-" }}</div>
          </div>
          <div slot="avatar_qc_result" slot-scope="text, record">
            <span
              v-if="!record.avatar_qc_result || record.avatar_qc_result == '-'"
              >-</span
            >
            <div v-else :class="returnSignClass(record.avatar_qc_result)"></div>
          </div>
          <div slot="nickname_qc_result" slot-scope="text, record">
            <span
              v-if="
                !record.nickname_qc_result || record.nickname_qc_result == '-'
              "
              >-</span
            >
            <div
              v-else
              :class="returnSignClass(record.nickname_qc_result)"
            ></div>
          </div>
          <div slot="code_qc_result" slot-scope="text, record">
            <span v-if="!record.code_qc_result || record.code_qc_result == '-'"
              >-</span
            >
            <div v-else :class="returnSignClass(record.code_qc_result)"></div>
          </div>
          <div slot="signature_qc_result" slot-scope="text, record">
            <span
              v-if="
                !record.signature_qc_result || record.signature_qc_result == '-'
              "
              >-</span
            >
            <div
              v-else
              :class="returnSignClass(record.signature_qc_result)"
            ></div>
          </div>
          <div slot="backgroup_qc_result" slot-scope="text, record">
            <span
              v-if="
                !record.backgroup_qc_result || record.backgroup_qc_result == '-'
              "
              >-</span
            >
            <div
              v-else
              :class="returnSignClass(record.backgroup_qc_result)"
            ></div>
          </div>
          <div slot="action" slot-scope="text, record">
            <div class="action-btn" @click="openDetail(record)">查看</div>
          </div>
        </a-table>
      </div>
      <div class="page-div aft-pagination">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
    <!-- 详情弹窗 -->
    <div
      class="detail-modal"
      v-show="detailModalShow"
      @click="detailModalShow = false"
    >
      <div class="detail-modal-container" @click.stop>
        <div class="form-item">
          <div class="label">
            <div :class="returnSignClass(currentClick.avatar_qc_result)"></div>
            <span class="label-name">账号头像</span>
          </div>
          <div class="form-content">
            <WebpImage
              :src="currentClick.avatar"
              :width="'36px'"
              :height="'36px'"
              :radius="'50%'"
              style="margin-top: -8px"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="label">
            <div
              :class="returnSignClass(currentClick.nickname_qc_result)"
            ></div>
            <span class="label-name">账号名称</span>
          </div>
          <div class="form-content">{{ currentClick.nickname || "-" }}</div>
        </div>
        <div class="form-item">
          <div class="label">
            <div :class="returnSignClass(currentClick.code_qc_result)"></div>
            <span class="label-name">平台账号</span>
          </div>
          <div class="form-content">{{ currentClick.code || "-" }}</div>
        </div>
        <div class="form-item">
          <div class="label">
            <div
              :class="returnSignClass(currentClick.signature_qc_result)"
            ></div>
            <span class="label-name">账号简介</span>
          </div>
          <div class="form-content signature">
            {{ currentClick.signature }}
          </div>
        </div>
        <div class="form-item">
          <div class="label">
            <div
              :class="returnSignClass(currentClick.backgroup_qc_result)"
            ></div>
            <span class="label-name">主页背景图</span>
          </div>
          <div class="form-content">
            <WebpImage
              :src="currentClick.backgroup_img"
              :width="'240px'"
              :height="'auto'"
              :radius="'8px'"
            />
          </div>
        </div>
        <!-- <div
          v-if="detailLoading"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 300px;
          "
        >
          <a-spin :spinning="detailLoading"></a-spin>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/accountAuthorization.js";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import { getToken, getPrincipalId } from "@/utils/auth";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";
import exportTable from "@/utils/exportTable";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";

export default {
  components: {
    PlatformHeader,
    WebpImage,
    ExcelTypeButton,
  },
  data() {
    return {
      currentTab: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      btn_Loaidng: false,
      regionValue: undefined,
      groupValue: undefined,
      areaData: [],
      groupData: [],
      tableData: [],
      qc_field: undefined, // => typeList
      qc_result: undefined, // => levelList
      typeList: [
        {
          label: "全部类型",
          value: "",
        },
        {
          label: "账号头像",
          value: "avatar_qc_result",
        },
        {
          label: "账号名称",
          value: "nickname_qc_result",
        },
        {
          label: "平台账号",
          value: "code_qc_result",
        },
        {
          label: "账号简介",
          value: "signature_qc_result",
        },
        {
          label: "主页背景图",
          value: "backgroup_qc_result",
        },
      ],
      levelList: [
        {
          label: "全部级别",
          value: "",
        },
        {
          label: "标准",
          value: "GREEN",
        },
        {
          label: "合格",
          value: "YELLOW",
        },
        {
          label: "疑似违规",
          value: "ORANGE",
        },
        {
          label: "违规",
          value: "RED",
        },
      ],
      columns: [
        {
          dataIndex: "region_name",
          title: "大区",
          width: "9%",
          align: "center",
        },
        {
          dataIndex: "dealer_name",
          // title: "门店名称/账号昵称",
          width: "26%",
          slots: { title: "dealer_name_title" },
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          dataIndex: "store_code",
          title: "门店编码",
          width: "9%",
          scopedSlots: { customRender: "store_code" },
          align: "center",
        },
        {
          dataIndex: "avatar_qc_result",
          title: "账号头像",
          scopedSlots: { customRender: "avatar_qc_result" },
          width: "9%",
          align: "center",
        },
        {
          dataIndex: "nickname_qc_result",
          title: "账号名称",
          scopedSlots: { customRender: "nickname_qc_result" },
          width: "9%",
          align: "center",
        },
        {
          dataIndex: "code_qc_result",
          title: "平台账号",
          scopedSlots: { customRender: "code_qc_result" },
          width: "9%",
          align: "center",
        },
        {
          dataIndex: "signature_qc_result",
          title: "账号简介",
          scopedSlots: { customRender: "signature_qc_result" },
          width: "9%",
          align: "center",
        },
        {
          dataIndex: "backgroup_qc_result",
          title: "主页背景图",
          scopedSlots: { customRender: "backgroup_qc_result" },
          width: "9%",
          align: "center",
        },
        {
          dataIndex: "action",
          title: "操作",
          scopedSlots: { customRender: "action" },
          width: "11%",
          align: "center",
        },
      ],
      loading: false,
      current: 1,
      pageSize: 20,
      total: 0,
      isAllRegion: false,
      currentParams: null,
      detailModalShow: false,
      // detailLoading: false
      currentClick: {},
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      excelType: false,
      countData: {
        violation_count1: "", // 标准
        violation_count2: "", //合格
        violation_count3: "", // 疑似违规
        violation_count4: "", //违规
      },
    };
  },
  async created() {
    await this.getAreaInfo();
    this.getAllGroup();
  },
  methods: {
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      // this.currentParams = null;
      // this.regionValue = undefined;
      // this.groupValue = undefined;
      this.stateValue = undefined;
      this.current = 1;
      this.getList();
      this.getViolationCount();
    },
    // 获取大区
    getAreaInfo() {
      api.getAreaInfo().then((res) => {
        if (res.data.code === 0) {
          this.areaData = res.data.data.region_list || [];
          if (res.data.data.type === 10) {
            this.isAllRegion = true;
            this.regionValue = "";
          } else {
            this.regionValue = this.areaData[0].id;
          }
        } else {
          this.areaData = [];
          this.regionValue = undefined;
          console.error("获取大区失败");
        }
        this.currentParams = {
          region_id: this.regionValue,
        };

        // this.getList();
        // this.getViolationCount();
      });
    },
    // 获取全部分组
    getAllGroup() {
      api.getAllGroup().then((res) => {
        if (Array.isArray(res.data.data)) {
          this.groupData = res.data.data || [];
        } else {
          this.groupData = [];
          console.error("获取大区失败");
        }
      });
    },
    //获取列表
    getList(params) {
      console.log("this.regionValue", this.regionValue);
      this.loading = true;
      let body = {
        platform: this.currentTab,
        params: {
          region_id: this.currentParams
            ? this.currentParams.region_id
            : undefined,
          group_code: this.currentParams
            ? this.currentParams.group_code
            : undefined,
          qc_field: this.currentParams
            ? this.currentParams.qc_field
            : undefined,
          qc_result: this.currentParams
            ? this.currentParams.qc_result
            : undefined,
          current: this.current,
          page_size: this.pageSize,
        },
      };
      // 只有确定的时候
      if (params) {
        body.params.region_id = params.region_id;
        body.params.group_code = params.group_code;
        body.params.qc_field = params.qc_field;
        body.params.qc_result = params.qc_result;
      }
      console.log(this.currentParams);
      console.log("body", body);

      api.getStoreRenovationList(body).then((res) => {
        this.loading = false;

        if (res.data.code === 0) {
          this.tableData = res.data.data.list || [];
          this.total = res.data.data.pagination.total || 0;
        } else {
          this.tableData = [];
          this.total = 0;
          console.error("获取门店装修质检列表失败");
        }
      });
    },
    getViolationCount() {
      let body = {
        platform: this.currentTab,
        params: {
          region_id: this.regionValue,
          group_code: this.groupValue,
          qc_field: this.qc_field,
          qc_result: this.qc_result,
        },
      };
      api.getViolationCount(body).then((res) => {
        if (res.data.code === 0) {
          this.countData = res.data.data;
        } else {
          console.error("获取门店装修质检疑似违规、违规数失败");
        }
      });
    },
    // 页码切换
    pageChange(val) {
      this.getList();
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 标记类名处理
    returnSignClass(color) {
      if (color == "GREEN") {
        return "sign sign1";
      } else if (color == "YELLOW") {
        return "sign sign2";
      } else if (color == "ORANGE") {
        return "sign sign3";
      } else if (color == "RED") {
        return "sign sign4";
      } else {
        return "sign";
      }
    },
    onSearch() {
      this.current = 1;
      this.currentParams = {
        region_id: this.regionValue,
        group_code: this.groupValue,
        qc_field: this.qc_field,
        qc_result: this.qc_result,
      };
      this.getList(this.currentParams);
      this.getViolationCount();
    },
    goBack() {
      this.$router.push({
        path: `/dealer/storeManagement`,
      });
    },
    openDetail(item) {
      console.log(item);
      this.currentClick = item;
      this.detailModalShow = true;
    },
    exportExcel() {
      let params = {
        region_id: this.regionValue || undefined,
        group_code: this.groupValue || undefined,
        qc_field: this.qc_field || undefined,
        qc_result: this.qc_result || undefined,
        flag: this.excelType ? 1 : 0,
      };

      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/dealer/decoration/list/export",
        "filename"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}

.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.tools {
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  .left {
    display: flex;
    align-items: center;
    .button {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 24px;
      margin-right: 30px;
      font-size: 14px;
      @include font_color("font_color22");
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 2px;
        height: 24px;
        @include background_color("background_color62");
      }
      .icon {
        margin-right: 6px;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        display: block;
        overflow: hidden;
      }
      &:hover .icon {
        @include background_color("background_color2");
      }
      .arrow-right {
        font-size: 22px;
        transform: rotate(180deg);
      }
    }
    .search-btn {
      @include border_color("border_color4");
      @include font_color("font_color17");
      @include background_color("background_color13");
      width: 80px;
      height: 36px;
      border-radius: 8px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .button {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }
}
.info-div {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  .tip {
    @include font_color("font_color23");
    font-size: 12px;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
    margin-bottom: 18px;
    @include font_color("font_color21");
    padding: 0 24px;
    .sign {
      margin: 0 12px 0 30px;
    }
  }
}
.sign {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.sign1 {
  @include background_color("background_color63");
}
.sign2 {
  @include background_color("background_color34");
}
.sign3 {
  @include background_color("background_color70");
}
.sign4 {
  @include background_color("background_color71");
}
.author-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content {
  padding: 0 24px;
  .table {
    height: calc(100vh - 328px);
    border-radius: 16px;
    overflow-y: auto;
    @include font_color("font_color1");
    .dealer-title {
      font-size: 14px;
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .nickname {
      margin-top: 4px;
      max-width: 240px;
      font-size: 12px;
      width: 100%;
      @include font_color("font_color22");
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .action-btn {
      @include font_color("font_color21");
      font-size: 14px;
      &:hover {
        @include font_color("font_color26");
      }
    }

    span {
      font-size: 14px;
    }
    .orange {
      @include font_color("font_color27");
    }
    .yellow {
      @include font_color("font_color4");
    }
  }
}
.page-div {
  text-align: right;
  padding: 20px 0;
}

.detail-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 100;
  &-container {
    position: absolute;
    border-radius: 16px;
    top: 330px;
    right: 12%;
    @include background_color("background_color51");
    width: 402px;
    height: 450px;
    padding: 32px 24px 8px;
    overflow-y: auto;
    @include box_shadow("box_shadow4");
    .form-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-bottom: 16px;
      .label {
        display: flex;
        align-items: center;
        height: 20px;
        width: 90px;
        font-size: 14px;
        @include font_color("font_color23");
        .sign {
          margin-right: 6px;
        }
      }
      .form-content {
        width: 240px;
        @include font_color("font_color20");
        line-height: 20px;
        font-size: 14px;
      }
      .form-content.signature {
        max-height: 128px;
        overflow-y: auto;
      }
    }
  }
}
.fc-20 {
  @include font_color("font_color20");
}

/deep/ .ant-table-body {
  overflow-x: unset !important;
}
/deep/ .ant-table-thead > tr > th {
  height: 74px !important;
  background-color: #2b2f3b !important;
}
/deep/ .ant-table-tbody > tr {
}
/deep/ .ant-table-tbody > tr > td {
  height: 88px;
  color: #d0d6e6;
  border-bottom: 1px solid #323744 !important;
  background-color: #2b2f3b !important;
}
</style>
