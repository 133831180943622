import echarts from 'echarts'
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min";

var option = {
  tooltip: {
    show: true,
    position: 'top',
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
        color: '#fff',
        fontSize: 30
    }
  },
  series: [{
    type: "wordCloud",
    // 网格大小，各项之间间距
    gridSize: 30,
    // 形状 circle 圆，cardioid  心， diamond 菱形，
    // triangle-forward 、triangle 三角，star五角星
    shape: 'circle',
    // 字体大小范围
    sizeRange: [20, 40],
    // 文字旋转角度范围
    rotationRange: [0, 90],
    // 旋转步值
    rotationStep: 90,
    // 自定义图形
    // maskImage: maskImage,
    left: 'center',
    top: 'center',
    right: null,
    bottom: null,
    // 画布宽
    width: '100%',
    // 画布高
    height: '100%',
    // 是否渲染超出画布的文字
    drawOutOfBound: true,
    textStyle: {
      normal: {
        color: '#FFCA5C'
      },
      emphasis: {
        shadowBlur: 10,
        shadowColor: '#2ac'
      }
    },
    data: []
  }]
};

export default option