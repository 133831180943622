import Nr from "@/utils/NetworldRequest";
import axios from "axios";

const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
}).getAxios();

const dy_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_API,
}).getAxios();

const activity_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API,
}).getAxios();

const businessmix_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_BUSINESSMIX_API,
}).getAxios();

const contentcenter_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_CONTENTCENTER_API,
}).getAxios();

let cancel = null;

export default {
  // // 获取大区列表
  getRegionList(params) {
    return activity_request.get("/base/listRegion", { params });
  },

  //
  // getCombatZoneList(params) {
  //   return activity_request.get('/base/listCombatZone', { params })
  // },

  // 获取战区列表（只通过主体）
  getCombatZoneListByPrincipalOnly(params) {
    return activity_request.get("/jjt/activity/web/manage/listCombatZone", {
      params,
    });
  },

  // 创建活动
  createActivity(body) {
    return activity_request.post("/jjt/activity/web/manage/create", body);
  },
  // 编辑活动
  editActivity(body) {
    return activity_request.post("/jjt/activity/web/manage/update", body);
  },
  // 删除活动（禁用）
  delActivity(params) {
    return activity_request.get("/jjt/activity/web/manage/deleted", { params });
  },
  // 上传获奖图片
  uploadAwardsImage(params) {
    return activity_request.get("/jjt/activity/web/manage/uploadAwardsImage", {
      params: params,
    });
  },
  // 上传获奖图片v2.6.0
  uploadAwardsImageV_2_6_0(data) {
    return activity_request.post("/jjt/activity/web/manage/uploadAwards", data);
  },
  // 编辑获奖图片v2.6.0
  updateAwardsImageV_2_6_0(data) {
    return activity_request.post("/jjt/activity/web/manage/updateAwards", data);
  },
  // 获取获奖名单
  getAwardsImageV_2_6_0(params) {
    return activity_request.get("/acms/activity/awardsResult", {
      params: params,
    });
  },
  // 查询关联模版
  getTemplateList(params) {
    return activity_request.get("/jjt/activity/web/manage/guideshootList", {
      params: params,
    });
  },
  // 批量确认关联模版
  sureTemplate(body) {
    return activity_request.post(
      "/jjt/activity/web/manage/batchConfirmGuideshoot",
      body
    );
  },
  // 确认全部关联模板
  sureAllTemplate(params) {
    return activity_request.get("/jjt/activity/web/manage/allInGuideshoot", {
      params,
    });
  },
  // 批量删除关联模版
  deleteTemplate(body) {
    return activity_request.post(
      "/jjt/activity/web/manage/batchDeleteGuideshoot",
      body
    );
  },

  // 视频地址通过内容中台获取权限
  getAuthUrl(body) {
    return contentcenter_request.post("/v1/content/vod/getAuthUrl", body);
  },

  // 获取活动列表
  getActivityList(params) {
    return activity_request.get("/jjt/activity/web/manage/list", {
      params: params,
    });
  },
  // 获取活动详情
  getActivityDetail(params) {
    return activity_request.get("/jjt/activity/web/manage/detail", {
      params: params,
    });
  },
  //获取车系列表
  getSeriesList() {
    return request.get("/jjt/principal/series/list");
  },
  //获取抖音话题列表
  getTopicList() {
    return dy_request.get("/jjt/closedLoop/topic/list");
  },
  //选择分组
  getAllGroup(params) {
    return businessmix_request.get("/account/group/list", { params: params });
  },
  // 获取大区
  getAreaInfo() {
    return request.get("/jjt/dealer/dealers/region-info");
  },
  // 获取活动数据
  fetchActivityData(params) {
    return activity_request.get("/jjt/activity/web/manage/data", { params });
  },
  // 查询活动成员数量及媒体号数
  getMatrixMemberNum(data) {
    return activity_request.post(
      "/jjt/activity/web/manage/matrixMemberNum",
      data,
      {
        cancelToken: new axios.CancelToken(function(c) {
          if (cancel) {
            cancel("取消请求");
            cancel = null;
          }
          cancel = c;
        }),
      }
    );
  },
  // 查询活动成员列表
  getMatrixMemberList(params) {
    return activity_request.get("/jjt/activity/web/manage/matrixMemberList", {
      params,
    });
  },
  // 查询变更历史列表
  getChangeHistoryList(params) {
    return activity_request.get("/jjt/activity/web/manage/changeHistory", {
      params,
    });
  },

  // 获取是否有小红书
  getHasXhs(params) {
    return activity_request.get("/acms/activity/config/enabledXhs", {
      params,
    });
  },
};
