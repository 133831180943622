import filters from '@/filters'

export const platList = [
  { label: '全部平台', value: 'agg' }
]

export const tabsList = [
  {
    label: '直播',
    value: 0
  },
  {
    label: '视频',
    value: 1
  }
]

export const btnTabsList = [
  {
    label: '基础激励',
    value: 0
  },
  {
    label: '爆款激励',
    value: 1
  },
  {
    label: '事件营销激励',
    value: 2
  },
  {
    label: '违规统计',
    value: 3
  }
]

export const titleList = [
  [
    {
      tabName: '直播',
      title: '基础激励',
      tooltip: '对门店的有效直播进行统计'
    },
    {
      tabName: '直播',
      title: '爆款作品激励',
      tooltip: ''
    },
    {
      tabName: '直播',
      title: '事件营销激励',
      tooltip: ''
    },
    {
      tabName: '直播',
      title: '违规统计',
      tooltip: ''
    }
  ],
  [
    {
      tabName: '视频',
      title: '基础激励',
      tooltip: ''
    },
    {
      tabName: '视频',
      title: '爆款作品激励',
      tooltip: ''
    },
    {
      tabName: '视频',
      title: '事件营销激励',
      tooltip: ''
    },
    {
      tabName: '视频',
      title: '违规统计',
      tooltip: ''
    }
  ]
]

export const data_0_0 = [
  {
    label: '观看量',
    value: null,
    key: 'total_watch_count',
    tooltip: 'sgzb02'
  },
  {
    label: '直播数',
    value: null,
    key: 'total_live_count',
    tooltip: 'sgzb03'
  },
  {
    label: '发布门店数',
    value: null,
    key: 'dealer_publish_count',
    tooltip: 'sgmd04'
  }
]

export const data_0_1 = [
  {
    label: '1W-3W',
    value: null,
    key: 'live_count1',
    tooltip: ''
  },
  {
    label: '3W-5W',
    value: null,
    key: 'live_count2',
    tooltip: ''
  },
  {
    label: '5W-10W',
    value: null,
    key: 'live_count3',
    tooltip: ''
  },
  {
    label: '10W-30W',
    value:  null,
    key: 'live_count4',
    tooltip: ''
  },
  {
    label: '30W+',
    value: null,
    key: 'live_count5',
    tooltip: ''
  }
]

export const data_1_0 = [
  {
    label: '播放量',
    value: null,
    key: 'total_play_count',
    tooltip: ''
  },
  {
    label: '视频数',
    value: null,
    key: 'total_aweme_count',
    tooltip: ''
  },
  {
    label: '发布门店数',
    value: null,
    key: 'dealer_publish_count',
    tooltip: ''
  }
]

export const data_1_1 = [
  {
    label: '5-10W',
    value: null,
    key: 'echelon1_aweme_count',
    tooltip: ''
  },
  {
    label: '10-50W',
    value: null,
    key: 'echelon2_aweme_count',
    tooltip: ''
  },
  {
    label: '50W-100W',
    value: null,
    key: 'echelon3_aweme_count',
    tooltip: ''
  },
  {
    label: '100W+',
    value: null,
    key: 'echelon4_aweme_count',
    tooltip: ''
  }
]

const columns_0_0 = [
  {
    title: '排名',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    with: '9%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: '16%',
    customRender: function (text, record, index) {
      return record.dealer_name || '-'
    }
  },
  {
    title: '门店编号',
    dataIndex: 'store_code',
    key: 'store_code',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return record.store_code || '-'
    }
  },
  {
    title: '（时长≥60min）',
    children: [
      {
        title: '有效直播数',
        dataIndex: 'live_count1',
        key: 'live_count1',
        align: 'center',
        width: '15%',
        customRender: function (text, record, index) {
          return filters.changeNum(record.live_count1) 
        }
      },
      {
        title: '直播观看',
        dataIndex: 'watch_count1',
        key: 'watch_count1',
        align: 'center',
        width: '15%',
        customRender: function (text, record, index) {
          return filters.changeNum(record.watch_count1) 
        }
      }
    ]
  },
  {
    title: '（时长≥90min）',
    children: [
      {
        title: '有效直播数',
        dataIndex: 'live_count2',
        key: 'live_count2',
        align: 'center',
        width: '15%',
        customRender: function (text, record, index) {
          return filters.changeNum(record.live_count2) 
        }
      },
      {
        title: '直播观看',
        dataIndex: 'watch_count2',
        key: 'watch_count2',
        align: 'center',
        width: '15%',
        customRender: function (text, record, index) {
          return filters.changeNum(record.watch_count2) 
        }
      }
    ]
  }
]

const columns_0_1 = [
  {
    title: '排名',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: '9%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: '13%',
    customRender: function (text, record, index) {
      return record.dealer_name || '-'
    }
  },
  {
    title: '门店编号',
    dataIndex: 'store_code',
    key: 'store_code',
    align: 'center',
    width: '13%',
    customRender: function (text, record, index) {
      return record.store_code || '-'
    }
  },
  {
    title: '1W-3W',
    dataIndex: 'live_count1',
    key: 'live_count1',
    align: 'center',
    width: '13%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.live_count1) 
    }
  },
  {
    title: '3W-5W',
    dataIndex: 'live_count2',
    key: 'live_count2',
    align: 'center',
    width: '13%',
    lcustomRender: function (text, record, index) {
      return filters.changeNum(record.live_count2) 
    }
  },
  {
    title: '5W-10W',
    dataIndex: 'live_count3',
    key: 'live_count3',
    align: 'center',
    width: '13%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.live_count3) 
    }
  },
  {
    title: '10W-30W',
    dataIndex: 'live_count4',
    key: 'live_count4',
    align: 'center',
    width: '13%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.live_count4) 
    }
  },
  {
    title: '30W+',
    dataIndex: 'live_count5',
    key: 'live_count5',
    align: 'center',
    width: '13%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.live_count5) 
    }
  }
]

const columns_0_2 = [
  {
    title: '排名',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: '9%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: '16%',
    customRender: function (text, record, index) {
      return record.dealer_name || '-'
    }
  },
  {
    title: '门店编号',
    dataIndex: 'store_code',
    key: 'store_code',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return record.store_code || '-'
    }
  },
  {
    title: '直播观看',
    dataIndex: 'watch_count',
    key: 'watch_count',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.watch_count) 
    }
  },
  {
    title: '直播观看占比',
    dataIndex: 'watch_rate',
    key: 'watch_rate',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.numRate(record.watch_rate) 
    }
  },
  {
    title: '直播互动',
    dataIndex: 'polling_count',
    key: 'polling_count',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.polling_count) 
    }
  },
  {
    title: '直播互动占比',
    dataIndex: 'polling_rate',
    key: 'polling_rate',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.numRate(record.polling_rate) 
    }
  }
]

const columns_0_3 = [
  {
    title: '排名',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: '9%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: '22.75%',
    customRender: function (text, record, index) {
      return record.dealer_name || '-'
    }
  },
  {
    title: '门店编号',
    dataIndex: 'dealer_store_code',
    key: 'dealer_store_code',
    align: 'center',
    width: '22.75%',
    customRender: function (text, record, index) {
      return record.dealer_store_code || '-'
    }
  },
  {
    title: '直播违规',
    dataIndex: 'illegal_count',
    key: 'illegal_count',
    align: 'center',
    width: '22.75%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.illegal_count) 
    }
  },
  {
    title: '直播违规占比',
    dataIndex: 'illegal_rate',
    key: 'illegal_rate',
    align: 'center',
    width: '22.75%',
    customRender: function (text, record, index) {
      return filters.numRate(record.illegal_rate) 
    }
  }
]

const columns_1_0 = [
  {
    title: '排名',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: '9%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: '16%',
    customRender: function (text, record, index) {
      return record.dealer_name || '-'
    }
  },
  {
    title: '门店编号',
    dataIndex: 'store_code',
    key: 'store_code',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return record.store_code || '-'
    }
  },
  {
    title: '（播放≥500）',
    children: [
      {
        title: '有效视频数',
        dataIndex: 'aweme_count1',
        key: 'aweme_count1',
        align: 'center',
        width: '15%',
        customRender: function (text, record, index) {
          return filters.changeNum(record.aweme_count1)
        }
      },
      {
        title: '播放量',
        dataIndex: 'play_count1',
        key: 'play_count1',
        align: 'center',
        width: '15%',
        customRender: function (text, record, index) {
          return filters.changeNum(record.play_count1)
        }
      }
    ]
  },
  {
    title: '（播放≥1000）',
    children: [
      {
        title: '有效视频数',
        dataIndex: 'aweme_count2',
        key: 'aweme_count2',
        align: 'center',
        width: '15%',
        customRender: function (text, record, index) {
          return filters.changeNum(record.aweme_count2)
        }
      },
      {
        title: '播放量',
        dataIndex: 'play_count2',
        key: 'play_count2',
        align: 'center',
        width: '15%',
        customRender: function (text, record, index) {
          return filters.changeNum(record.play_count2)
        }
      }
    ]
  }
]

const columns_1_1 = [
  {
    title: '排名',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: '9%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: '16%',
    customRender: function (text, record, index) {
      return record.dealer_name || '-'
    }
  },
  {
    title: '门店编号',
    dataIndex: 'dealer_store_code',
    key: 'dealer_store_code',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return record.dealer_store_code || '-'
    }
  },
  {
    title: '5-10W',
    dataIndex: 'echelon1_aweme_count',
    key: 'echelon1_aweme_count',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.echelon1_aweme_count)
    }
  },
  {
    title: '10-50W',
    dataIndex: 'echelon2_aweme_count',
    key: 'echelon2_aweme_count',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.echelon2_aweme_count)
    }
  },
  {
    title: '50W-100W',
    dataIndex: 'echelon3_aweme_count',
    key: 'echelon3_aweme_count',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.echelon3_aweme_count)
    }
  },
  {
    title: '100W+',
    dataIndex: 'echelon4_aweme_count',
    key: 'echelon4_aweme_count',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.echelon4_aweme_count)
    }
  }
]

const columns_1_2 = [
  {
    title: '排名',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: '9%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: '16%',
    customRender: function (text, record, index) {
      return record.dealer_name || '-'
    }
  },
  {
    title: '门店编号',
    dataIndex: 'dealer_store_code',
    key: 'dealer_store_code',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return record.dealer_store_code || '-'
    }
  },
  {
    title: '播放量',
    dataIndex: 'play_volume',
    key: 'play_volume',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.play_volume)
    }
  },
  {
    title: '播放量占比',
    dataIndex: 'play_volume_ratio',
    key: 'play_volume_ratio',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.numRate(record.play_volume_ratio)
    }
  },
  {
    title: '视频互动',
    dataIndex: 'interactive',
    key: 'interactive',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.interactive)
    }
  },
  {
    title: '视频互动占比',
    dataIndex: 'interactive_ratio',
    key: 'interactive_ratio',
    align: 'center',
    width: '15%',
    customRender: function (text, record, index) {
      return filters.numRate(record.interactive_ratio)
    }
  }
]

const columns_1_3 = [
  {
    title: '排名',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
    width: '9%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '门店名称',
    dataIndex: 'dealer_name',
    key: 'dealer_name',
    align: 'left',
    width: '22.75%',
    customRender: function (text, record, index) {
      return record.dealer_name || '-'
    }
  },
  {
    title: '门店编号',
    dataIndex: 'dealer_store_code',
    key: 'dealer_store_code',
    align: 'center',
    width: '22.75%',
    customRender: function (text, record, index) {
      return record.dealer_store_code || '-'
    }
  },
  {
    title: '视频违规数',
    dataIndex: 'illegal_count',
    key: 'illegal_count',
    align: 'center',
    width: '22.75%',
    customRender: function (text, record, index) {
      return filters.changeNum(record.illegal_count)
    }
  },
  {
    title: '违规占比',
    dataIndex: 'illegal_rate',
    key: 'illegal_rate',
    align: 'center',
    width: '22.75%',
    customRender: function (text, record, index) {
      return filters.numRate(record.illegal_rate)
    }
  }
]

export const columnsList = [
  [columns_0_0, columns_0_1, columns_0_2, columns_0_3],
  [columns_1_0, columns_1_1, columns_1_2, columns_1_3]
]

export const pagination = { current: 1, page_size: 10, total: 0 }

export const apiList = [
  ['getBasedIncentiveLiveDetail', 'getHotLiveDetail', 'getEventMarketingLive', 'getIlleglSurveillanceLive'],
  ['getBasedIncentiveAwemeDetail', 'getHotAwemeDetail', 'getEventMarketingAweme', 'getIlleglSurveillanceAweme']
]

export const exportApiList = [
  [
    '/businessPolicy/based-incentive-live-detail/export',
    '/businessPolicy/hot-live-detail/export',
    '/businessPolicy/live-event-marketing/export',
    '/businessPolicy/illegal/surveillance/live/export'
  ],
  [
    '/businessPolicy/based-incentive-aweme-detail/export',
    '/businessPolicy/hotAwemeExcel',
    '/businessPolicy/eventAwemeExcel',
    '/businessPolicy/illegal/surveillance/aweme/export'
  ]
]
