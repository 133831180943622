<template style="width: 100%; height: 100%">
  <section style="width: 100%; height: 100%">
    <video
      ref="video"
      :id="id"
      controls
      muted
      style="width: 100%; height: 100%"
    ></video>
  </section>
</template>

<script>
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
export default {
  name: "FlvVideo",
  props: {
    options: {
      type: Object,
    },
    id: {
      type: String,
    },
    platform: {
      type: String,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.options) {
        this.playVideo(this.options.play_url_flv264_old || this.options.playUrlFlv264Old);
      }
    });
    // console.log('my chart cerated',this.options)
  },
  computed: {},
  watch: {
    options(val) {
        console.log('options变化',val)
        this.playVideo(this.options.play_url_flv264_old || this.options.playUrlFlv264Old)
    },
  },
  methods: {
    returnNewUrl(url) {
      if (url) {
        try {
          let newUrl = url.replace(/^http/, "https")
          if (this.platform == 'douyin') {
            let array = newUrl.split('.flv')
            newUrl = array[0] + '_sd.flv'
          }
          return newUrl
        }
        catch(err) {
          this.$message.warning(err)
        }
      } else {
        this.$message.warning('未获取到当前直播地址')
      }
    },
    playVideo(source) {
      this.flv_destroy();
      console.log("准备尝试播放", source);
      let newUrl = this.returnNewUrl(source);
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById(this.id);
        console.log('videoElement', videoElement)
        this.player = flvjs.createPlayer({
          type: "flv",
          url: newUrl,
        });
        this.player.attachMediaElement(videoElement);
        this.player.load();
        this.player.play();
      }
    },

    flv_destroy() {
      if (this.player) {
        this.player.pause();
        this.player.unload();
        this.player.detachMediaElement();
        this.player.destroy();
        this.player = null;
      }
    },
  },
};
</script>
<style lang="less" scope>
</style>
