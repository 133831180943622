import { render, staticRenderFns } from "./hotVideoList.vue?vue&type=template&id=ceab0554&scoped=true"
import script from "./hotVideoList.vue?vue&type=script&lang=js"
export * from "./hotVideoList.vue?vue&type=script&lang=js"
import style0 from "./hotVideoList.vue?vue&type=style&index=0&id=ceab0554&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceab0554",
  null
  
)

export default component.exports