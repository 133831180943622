var option = {
  grid: {
    left: "2%",
    top: "50",
    right: "5%",
    bottom: "0",
    containLabel: true,
  },
  legend: {
    left: '2%',
    data: [],
    textStyle: {
        color: '#fff'
    },
    padding: [10, 0]
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisLabel: {
      show: true,
        textStyle: {
          color: '#959595',  //更改坐标轴文字颜色
          fontSize : 10      //更改坐标轴文字大小
        }
    },
    data: [],
  },
  yAxis: {
    type: "value",
    splitNumber: 4, 
    axisTick: {
      inside: false,
    },
    axisLabel: {
      show: true,
        textStyle: {
          color: '#959595',  //更改坐标轴文字颜色
          fontSize : 10      //更改坐标轴文字大小
        }
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["rgba(255,255,255,0.2)"],
        type: "dashed",
      },
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#6a7985",
      },
    },
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
        color: '#fff',
    },
  },
  series: [],
}

export default option