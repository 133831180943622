import Nr from '@/utils/NetworldRequest'

const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const activity_request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API
}).getAxios()


export default {
  // 获取顶部数据
  getTopStatistics(params) {
    return activity_request.get('/jjt/activity/web/live/date', { params: params })
  },
  // 获取直播列表
  getLiveList(params) {
    return activity_request.get('/jjt/activity/web/live/list', { params: params })
  },

  getClientSetting({
    params,
    platform
  }) {
    return request({
      url: '/jjt/mqtt/activity/v1/client-setting',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
  addMonitorActivity({
    data,
    platform
  }) {
    return request({
      url: '/jjt/mqtt/activity/v1/add-monitor-activity',
      method: 'post',
      baseURL: getBaseUrl(platform),
      data,
    })
  },
  getHistoryData({
    params,
    platform
  }) {
    return request({
      url: '/jjt/mqtt/activity/v1/mqtt-statistic-history',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params,
    })
  },
}