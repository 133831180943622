<template>
  <div style="width: 100%; height: calc(100vh - 65px); overflow: auto">
    <div class="window-min-width">
      <platform-header
        title="品牌概览"
        :currentTab="currentTab"
        @tabChange="tabChange"
      />
      <div class="main-container">
        <div class="top-container">
          <div class="top-container-left">
            <card-ranking ref="cardRanking"></card-ranking>
          </div>
          <div class="top-container-right">
            <card-official ref="cardOfficial"></card-official>
          </div>
        </div>
        <div class="bottom-container">
          <div
            class="bottom-container-left"
            :style="{
              width: `${principal_id == '' ? 'calc(100% - 474px)' : '100%'}`,
            }"
          >
            <card-region ref="cardRegion"></card-region>
          </div>
          <div class="bottom-container-right" v-if="principal_id == ''">
            <div class="bottom-container-right-top">
              <card-area ref="cardArea"></card-area>
            </div>
            <div class="bottom-container-right-bottom">
              <card-brand-list ref="cardBrandList"></card-brand-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import CardRanking from "./components/CardRanking";
import CardOfficial from "./components/CardOfficial";
import CardRegion from "./components/CardRegion";
import CardBrandList from "./components/CardBrandList";
import CardArea from "./components/CardArea";

export default {
  name: "brandSummary",
  components: {
    PlatformHeader,
    MyChart,
    CardRanking,
    CardOfficial,
    CardRegion,
    CardBrandList,
    CardArea,
  },
  data() {
    return {
      msg: "品牌矩阵",
      currentTab: "",
      principal_id: "",
      dy_author_id: "",
      ks_author_id: "",
      dcd_author_id: "",
    };
  },
  computed: {},
  created() {
    console.log("本品概述入口", this.$route.query);
    this.$route.query.principal_id &&
      (this.principal_id = this.$route.query.principal_id);
    this.$route.query.dy_author_id &&
      (this.dy_author_id = this.$route.query.dy_author_id);
    this.$route.query.ks_author_id &&
      (this.ks_author_id = this.$route.query.ks_author_id);
    this.$route.query.dcd_author_id &&
      (this.dcd_author_id = this.$route.query.dcd_author_id);
  },
  watch: {},
  methods: {
    tabChange(val) {
      console.log("切换平台", val);
      this.currentTab = val;
      this.$nextTick(() => {
        this.initAllData();
      });
    },
    initAllData() {
      this.$refs.cardRanking.initData();
      this.$refs.cardOfficial.initData();
      this.$refs.cardRegion.initData();
      if (this.principal_id == "") {
        this.$refs.cardArea.initData();
        this.$refs.cardBrandList.initData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.window-min-width {
  min-width: 1218px;
}
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
  position: relative;

  .beta-icon {
    width: 36px;
    height: 14px;
    border-radius: 7px;
    background: #ff4160;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: 4px;
    right: 0px;
  }
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 20px;
  @include font_color("font_color2");
}
.main-container {
  padding: 0 20px;
  .top-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    &-left {
      @include background_color("background_color2");
      width: calc(50% - 8px);
      height: 934px;
      border-radius: 16px;
      overflow: hidden;
    }
    &-right {
      @include background_color("background_color2");
      width: calc(50% - 8px);
      height: 934px;
      border-radius: 16px;
      overflow: hidden;
    }
  }
  .bottom-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 10px 0;
    &-left {
      @include background_color("background_color2");
      width: calc(100% - 474px);
      height: 730px;
      border-radius: 16px;
      overflow: hidden;
    }
    &-right {
      width: 458px;
      height: 730px;
      &-top {
        width: 100%;
        height: 322px;
        border-radius: 16px;
        @include background_color("background_color2");
        overflow: hidden;
      }
      &-bottom {
        width: 100%;
        height: 392px;
        border-radius: 16px;
        margin-top: 16px;
        @include background_color("background_color2");
        overflow: hidden;
      }
    }
  }
}
.fw {
  font-weight: bold;
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}
</style>
