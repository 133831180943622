<template>
  <div class="container">
    <platform-header
      title="门店排行"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="title">
      <div class="btn-wrap">
        <RouterButton
          :title="'直播周榜'"
          :icon="'rb-hot-live-list'"
          :path="'hotLiveList'"
          style="margin-right: 10px;"
        />
        <RouterButton
          :title="'视频周榜'"
          :icon="'rb-hot-video-list'"
          :path="'hotVideoList'"
        />
        <!-- <a-button class="button btn-color1" @click="gotoStore">
          <svg-icon icon-class="md-search" class="btn-icon" />
          <span>门店查询</span>
        </a-button> -->
        <!-- <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button class="button2" @click="exportExcel" :loading="btn_Loaidng">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button> -->
      </div>
    </div>
    <div class="tools">
      <!-- <a-tabs
        style="width: calc(100% - 605px)"
        @change="liveOrVideo"
        v-model="lTadsV"
      >
        <a-tab-pane key="1" tab="直播LIVE"></a-tab-pane>
        <a-tab-pane key="2" tab="视频VIDEO"></a-tab-pane>
      </a-tabs> -->
      <div style="width: calc(100% - 605px)">
        <ButtonGroupTab
          :tabData="btnData"
          @tabChange="liveOrVideo"
          :showTag="true"
          ref="buttonGroup"
        />
      </div>
      <a-select
        style="width: 240px; height: 36px; margin: 0 24px"
        v-model="group_code"
        :suffixIcon="suffixIcon"
        @change="selectChange"
        placeholder="选择分组"
      >
        <a-select-option :value="undefined">不限分组</a-select-option>
        <a-select-option v-for="d in groupList" :key="d.group_code">
          {{ d.group_name }}
        </a-select-option>
      </a-select>
      <date-tab
        :type="dateType"
        @changeType="changeType"
        @changeDate="searchTimeTable"
        ref="dateTab"
      ></date-tab>
    </div>
    <div class="content">
      <div class="table">
        <a-table
          :columns="lTadsV == 1 ? live_columns : video_columns"
          :data-source="tableData"
          :rowKey="(data) => data.author_id"
          :pagination="false"
          :scroll="{ y: 'calc(100vh - 404px)' }"
          :loading="taleLoading"
          :locale="locale"
          :customRow="openDetail"
          class="aft-table default"
        >
          <div slot="watchTitle">
            {{ currentTab | returnWatchText }}
          </div>
          <div slot="customTitle" style="display: flex; align-items: center">
            <div>播放量</div>
          </div>

          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            <a-badge
              :offset="[3, 3]"
              :color="
                Number(index + 1) == 1
                  ? '#FAA065'
                  : Number(index + 1) == 2
                  ? '#22C5E6'
                  : '#7A91FF'
              "
              :number-style="{
                boxShadow: '0',
              }"
              v-if="Number(index) < 3 && current == 1"
            >
              {{ (current - 1) * pageSize + Number(index) + 1 }}
            </a-badge>
            <span v-else>
              {{ (current - 1) * pageSize + Number(index) + 1 }}
            </span>
          </div>
          <div slot="account" slot-scope="text, record" class="table-account">
            <div class="table-account-left">
              <WebpImage
                :src="record.avatar"
                :width="'48px'"
                :height="'48px'"
                :radius="'50%'"
                class="img"
              />
              <!-- <img :src="record.avatar" width="48" height="48" alt="" /> -->
              <div>
                <a-tooltip>
                  <template slot="title">{{ record.dealer_name }}</template>

                  <div>
                    <img
                      src="@/assets/img/type-store.png"
                      width="20"
                      style="margin-right: 4px; margin-bottom: 2px"
                      alt=""
                    />
                    <span class="font-color2 f16">{{
                      record.dealer_name
                    }}</span>
                  </div>
                </a-tooltip>

                <a-tooltip>
                  <template slot="title">{{
                    record.dealer_company_name
                  }}</template>
                  <div>
                    <img
                      src="@/assets/img/type-company.png"
                      width="16"
                      style="margin-right: 4px; margin-bottom: 2px"
                      alt=""
                    />
                    <span class="font-color3 f12">{{
                      record.dealer_company_name
                    }}</span>
                  </div>
                </a-tooltip>
              </div>
              <ul class="badge_box">
                <li
                  v-for="(item, index) in record.badge"
                  :key="index + record.dealer_id"
                >
                  <a-tooltip overlay-class-name="tooltip_name">
                    <template slot="title"> {{ item.badge_name }} </template>
                    <!-- <img :src="imgUrl(item.badge_type)" /> -->
                    <svg-icon
                      :icon-class="imgUrl(item.badge_type)"
                      style="font-size: 30px"
                    />
                  </a-tooltip>
                </li>
              </ul>
            </div>
            <div></div>
          </div>
          <div
            slot="dealer_store_code"
            slot-scope="text"
            class="font-color3 f14"
          >
            <span>{{ text || "-" }}</span>
          </div>
          <div
            slot="live_total_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope | changeNum }}</span>
          </div>
          <div
            slot="live_total_watch_user_time"
            slot-scope="scope"
            class="font-color4 f14"
          >
            <span>{{ scope | changeNum }}</span>
          </div>
          <div
            slot="aweme_interaction_count"
            slot-scope="scope"
            class="font-color4 f14"
          >
            <span>{{ scope | changeNum }}</span>
          </div>
          <div
            slot="live_total_time"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope | disposeLongTime_h }}</span>
          </div>
          <div slot="action" slot-scope="scope" class="font-color3 f14">
            <a-icon
              type="star"
              class="font-color6 f20"
              theme="filled"
              @click.stop="starClick(scope.dealer_id)"
              v-if="scope.favorite == 1"
            />
            <a-icon
              type="star"
              class="font-color3 f20"
              @click.stop="starClick(scope.dealer_id)"
              v-else
            />
          </div>
        </a-table>
      </div>
      <div class="page-div aft-pagination">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="tablePagination.total"
          @change="pageChange"
        />
      </div>
    </div>
    <!-- 收藏门店弹窗 -->
    <div class="store-modal" v-show="storeModalShow" @click="hideAllModal">
      <div class="store-modal-container" @click.stop>
        <div class="store-modal-container-title">收藏门店</div>
        <div class="list">
          <multiple-group
            :multipleData="multipleData"
            @multipleChange="multipleChange"
          ></multiple-group>
        </div>
        <div class="action-div">
          <div @click="showAddGroup"><span class="icon">+</span> 新建分组</div>
          <span class="btn btn1" @click="closeModal">取消</span>
          <span class="btn btn2" @click="groupBindDealer">确定</span>
        </div>
      </div>
    </div>
    <!-- 新建分组弹窗 -->
    <div class="add-modal" v-show="addModalShow" @click="addModalShow = false">
      <div class="add-modal-container" @click.stop>
        <a-input v-model.trim="addGroupValue" placeholder="输入新建分组名称" />
        <div class="action-div">
          <span
            :class="!!addGroupValue ? '' : 'active'"
            @click="addGroupDealer(0)"
            >取消</span
          >
          <span
            :class="!!addGroupValue ? 'active' : ''"
            @click="addGroupDealer(1)"
            >确定</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "storeSort"
);
import { getToken, getPrincipalId } from "@/utils/auth";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import DateTab from "@/components/DateTab/DateTab2";
import MultipleGroup from "@/components/MultipleGroup/MultipleGroup";
import ButtonGroupTab from "@/components/PlatformTab/ButtonGroupTab";
import WebpImage from "@/components/WebpImage/WebpImage";
import _http from "@/api/storeSort";
import exportTable from "@/utils/exportTable";
import Empty from "@/components/Empty/Empty";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import RouterButton from "@/components/RouterButton/RouterButton";
export default {
  components: {
    PlatformHeader,
    DateTab,
    MultipleGroup,
    ButtonGroupTab,
    WebpImage,
    ExcelTypeButton,
    RouterButton,
  },
  data() {
    return {
      month: null,
      group_code: undefined,
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      lTadsV: "1",
      btnData: [
        {
          label: "直播",
          value: "1",
          tag: "LIVE",
        },
        {
          label: "视频",
          value: "2",
          tag: "VIDEO",
        },
      ],
      currentTab: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      tab: "tab1",
      dateType: "month",
      dealer_id: "",
      btn_Loaidng: false,
      live_columns: [
        {
          title: "排名",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          dataIndex: "dealer_company_name",
          title: "门店名称",
          width: "52%",
          scopedSlots: { customRender: "account" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          width: "8%",
          scopedSlots: { customRender: "dealer_store_code" },
        },
        {
          dataIndex: "live_total_count",
          title: "开播场次",
          width: "8%",
          scopedSlots: { customRender: "live_total_count" },
        },
        {
          dataIndex: "live_total_watch_user_time",
          slots: { title: "watchTitle" },
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "live_total_watch_user_time" },
        },
        {
          dataIndex: "live_total_time",
          title: "开播时长",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "live_total_time" },
        },
        {
          key: "action",
          title: "操作",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      video_columns: [
        {
          title: "排名",
          width: "8%",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          dataIndex: "dealer_company_name",
          title: "门店名称",
          width: "52%",
          scopedSlots: { customRender: "account" },
        },
        {
          dataIndex: "dealer_store_code",
          title: "门店编码",
          width: "8%",
          scopedSlots: { customRender: "dealer_store_code" },
        },
        {
          dataIndex: "aweme_count_di",
          title: "视频发布",
          width: "8%",
          scopedSlots: { customRender: "live_total_count" },
        },
        {
          dataIndex: "aweme_play_volume_total_di",
          width: "8%",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "live_total_watch_user_time" },
        },
        {
          dataIndex: "aweme_interaction_count",
          title: "互动量",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "aweme_interaction_count" },
        },
        {
          key: "action",
          title: "操作",
          width: "8%",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      current: 1,
      pageSize: 20,
      storeModalShow: false,
      addModalShow: false,
      addGroupValue: "",
      excelType: false,
    };
  },
  computed: {
    ...mapState({
      tableData: (state) => state.tableData,
      groupList: (state) => state.groupList,
      taleLoading: (state) => state.taleLoading,
      tablePagination: (state) => state.tablePagination,
      multipleData: (state) => state.multipleData,
    }),
  },
  watch: {
    $route(to, from) {
      this.$nextTick(() => {
        if (this.$route.query.type == "2") {
          this.$refs.buttonGroup.tabClick("2");
          this.lTadsV = "2";
        } else {
          this.$refs.buttonGroup.tabClick("1");
          this.lTadsV = "1";
        }
        this.getSortGroup();
        this.getTableData();
      });
    },
  },
  created() {
    this.$nextTick(() => {
      if (this.$route.query.type == "2") {
        this.$refs.buttonGroup.tabClick("2");
        this.lTadsV = "2";
      } else {
        this.$refs.buttonGroup.tabClick("1");
        this.lTadsV = "1";
      }
      this.getSortGroup();
      // this.getTableData();
    });
  },
  mounted() {},
  methods: {
    ...mapActions(["getLiveSort", "getSortGroup", "getDealerGroup"]),
    // 跳转门店
    gotoStore() {
      this.$router.push({
        path: "storeSearch",
      });
    },
    // 获取表格数据
    getTableData() {
      if (!this.month) {
        this.getTimeDate();
      }
      let rang = this.month;
      let type = {
        liveOrVideo: this.lTadsV,
        terrace: this.currentTab,
      };
      let params = {
        start_time: rang.start_time,
        end_time: rang.end_time,
        current: this.current,
        size: this.pageSize,
        group_code: this.group_code || "",
      };
      this.getLiveSort({ type, params });
    },
    // 时间范围数据处理
    disposeTimeRange(value) {
      let start_time = "";
      let end_time = "";
      if (!!value) {
        start_time = `${this.$moment(value)
          .startOf("month")
          .format("YYYY-MM-DD")} 00:00:00`;
        end_time = `${this.$moment(value)
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      } else {
        start_time = moment()
          .month(moment().month())
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        end_time = moment()
          .month(moment().month())
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        this.month = this.$moment()
          .month(moment().month())
          .startOf("month");
      }
      return { start_time, end_time };
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 徽章路径
    imgUrl(type) {
      let url = "";
      if (this.lTadsV == 1) {
        switch (type) {
          case 1:
            url = "live_no1";
            break;
          case 2:
            url = "live_no2";
            break;
          case 3:
            url = "live_no3";
            break;
          case 4:
            url = "live_able1";
            break;
          case 5:
            url = "live_able2";
            break;
          case 6:
            url = "live_able3";
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case 1:
            url = "video_no1";
            break;
          case 2:
            url = "video_no2";
            break;
          case 3:
            url = "video_no3";
            break;
          case 4:
            url = "video_like1";
            break;
          default:
            break;
        }
      }
      return url;
    },
    // 直播/视频 tab切换
    liveOrVideo(val) {
      this.lTadsV = val;
      this.current = 1;
      this.getTableData();
    },
    tabChange(val) {
      this.current = 1;
      this.currentTab = val;
      this.$nextTick(() => {
        this.getTableData();
      });
    },
    // 下拉框分组切换
    selectChange(val) {
      this.current = 1;
      this.getTableData();
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      this.searchTimeTable();
    },
    // 获取时间组件时间
    getTimeDate() {
      let start_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        start_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      this.month = {
        start_time,
        end_time,
      };
    },
    // 时间变更
    searchTimeTable() {
      this.getTimeDate();
      this.getTableData();
    },
    // 页码切换
    pageChange() {
      this.getTableData();
    },
    multipleChange(index) {
      this.multipleData[index].favorite = this.multipleData[index].favorite
        ? 0
        : 1;
    },
    // 关闭分组
    closeModal() {
      this.storeModalShow = false;
    },
    // 获取经销商相关分组
    starClick(id) {
      console.log("dealer_id", id);
      this.dealer_id = id;
      let params = {
        dealer_id: id,
      };
      this.getDealerGroup({ params });
      this.storeModalShow = true;
    },
    hideAllModal() {
      this.storeModalShow = false;
      this.addModalShow = false;
    },
    // 展开添加
    showAddGroup() {
      this.addModalShow = true;
    },
    // 添加分组
    addGroupDealer(type) {
      if (type) {
        if (!this.addGroupValue) {
          this.$message.error("请输入分组！");
          return;
        }
        let params = {
          new_or_copy: 0,
          name: this.addGroupValue,
        };
        _http.addShopGroup(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("新增分组成功！");
            this.starClick(this.dealer_id);
          } else {
            this.$message.error("新增分组失败！");
          }
        });
      } else {
        this.addModalShow = false;
        this.addGroupValue = "";
      }
    },
    // 确认绑定分组
    groupBindDealer() {
      let data = {
        dealer_id: this.dealer_id,
        group_list: this.multipleData,
      };
      _http.addCollectGrounp(data).then((res) => {
        if (res.data.code == 0) {
          this.storeModalShow = false;
          this.$message.success("绑定分组成功！");
          this.getTableData();
        } else {
          this.$message.error("绑定分组失败！");
        }
      });
    },
    // 导出Excel
    exportExcel() {
      if (!this.month) {
        this.getTimeDate();
      }
      let rang = this.month;
      let params = {
        start_time: rang.start_time,
        end_time: rang.end_time,
        group_code: this.group_code || "",
        flag: this.excelType ? 1 : 0,
      };
      let url =
        this.lTadsV == 1
          ? "/jjt/dealers/live/top/exportExcel"
          : "/jjt/dealers/aweme/top/exportExcel";
      let code = this.lTadsV == 1 ? "直播排名.xlsx" : "视频排名.xlsx";

      exportTable(this.$http, params, this.currentTab, url, code);
    },

    openDetail(record, index) {
      return {
        on: {
          click: () => {
            window.open(
              `/dealer/dealerDetail?id=${record.dealer_id}&platform=${this.currentTab}`
            );
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .platform-div {
    width: 100%;
    height: 40px;
    @include background_color("background_color2");
    text-align: right;
    position: relative;

    .beta-icon {
      width: 36px;
      height: 14px;
      border-radius: 7px;
      background: #ff4160;
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      position: absolute;
      top: 4px;
      right: 0px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    padding: 0px 24px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include font_color("font_color2");
    .btn-wrap {
      display: flex;
      align-items: center;
    }
    .button {
      width: 112px;
      height: 40px;
      line-height: 30px;
      font-size: 14px;
      background: none;
      border-radius: 8px;
      margin-right: 10px;
      .btn-icon {
        font-size: 18px;
        margin-right: 4px;
      }
    }
    .btn-color1 {
      @include font_color("font_color4");
      @include background_color("background_color1");
      @include border_color("font_color4");
    }
    .btn-color2 {
      @include font_color("font_color7");
      @include background_color("background_color1");
      @include border_color("font_color7");
      margin-left: 24px;
    }
    .button2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      margin-left: 10px;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }
  .tools {
    padding: 0 24px;
    display: flex;
    margin-bottom: 12px;
  }
  .content {
    padding: 0 24px;
    .table {
      height: calc(100vh - 346px);
      overflow-y: auto;
      &-account {
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        &-left {
          display: flex;
          flex-direction: row;
          @include font_color("font_color3");
          > .img {
            border-radius: 50%;
            @include border_color("border_color6");
          }
          > div {
            margin-left: 8px;
            > div {
              width: 160px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
  .page-div {
    text-align: right;
    padding: 20px 0;
  }
  .store-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    z-index: 100;
    &-container {
      position: absolute;
      right: 24px;
      bottom: 115px;
      width: 272px;
      height: 412px;
      border-radius: 4px;
      @include background_color("background_color27");
      @include font_color("font_color2");
      font-size: 14px;
      &-title {
        font-size: 16px;
        padding: 24px 0 20px;
        text-align: center;
      }
      .list {
        padding: 0 32px;
        height: 280px;
        overflow-y: auto;
      }
      .action-div {
        padding: 16px 12px 16px 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        > div {
          cursor: pointer;
          height: 32px;
          line-height: 18px;
          @include font_color("font_color2");
          .icon {
            font-size: 30px;
            @include font_color("font_color4");
            position: relative;
            top: 3px;
          }
        }
        .btn {
          width: 60px;
          height: 32px;
          border-radius: 4px;
          @include background_color("background_color5");
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          cursor: pointer;
        }
        .btn1 {
          @include font_color("font_color3");
          margin: 0 8px 0 20px;
        }
        .btn2 {
          @include font_color("font_color1");
        }
      }
    }
  }
  .add-modal {
    position: absolute;
    right: 24px;
    bottom: 115px;
    width: 272px;
    height: 412px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0);
    z-index: 110;
    &-container {
      position: absolute;
      bottom: 0;
      left: 0;
      @include background_color("background_color29");
      width: 100%;
      height: 116px;
      padding: 24px;
      .action-div {
        display: flex;
        justify-content: space-around;
        > span {
          padding: 16px;
          margin-top: 8px;
          cursor: pointer;
          @include font_color("font_color3");
        }
        > span.active {
          @include font_color("font_color2");
        }
      }
    }
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .f20 {
    font-size: 20px;
  }
}
.badge_box {
  display: flex;
  margin-left: 10px;
  li {
    margin-right: 8px;
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/_handle.scss";
.ant-badge-dot {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.tooltip_name {
  .ant-tooltip-inner {
    @include background_color("background_color28");
    font-size: 12px;
    @include font_color("font_color1");
  }
  .ant-tooltip-arrow::before {
    // 这里是小三角形
    @include background_color("background_color28");
  }
}
</style>
