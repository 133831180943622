<template>
  <PanleWrap>
    <div class="list_header">
      <platform-header
        title="本品排名"
        :currentTab="currentTab"
        :isAll="true"
        @tabChange="tabChange"
      >
      </platform-header>
      <div class="tools">
        <ButtonGroup :tabData="buttonData" @tabChange="buttonClick" />
        <!-- <SelectButton :buttonData="buttonData" @buttonClick="buttonClick" /> -->
        <RouterButton :title="'查看全部品牌'" :path="'/brand/generalList'" />
      </div>
      <div class="tools">
        <div class="btn-box">
          <a-select
            v-model="tagValue"
            style="width: 125px"
            :suffixIcon="suffixIcon"
            @change="getList"
          >
            <a-select-option value="0">全部品牌</a-select-option>
            <a-select-option
              :value="item.id"
              v-for="(item, index) in tagList"
              :key="index"
              >{{ item.tag_name }}</a-select-option
            >
          </a-select>
          <a-select
            v-model="indexValue"
            style="width: 125px; margin-left: 8px"
            placeholder="全部指标"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :suffixIcon="suffixIcon"
            showArrow
            @change="changeIndexList"
          >
            <a-select-option
              :value="item.code"
              v-for="item in indexList"
              :key="item.code"
              >{{ item.tag }}</a-select-option
            >
          </a-select>
        </div>
        <div class="btn-box">
          <!-- <ExcelTypeButton
            :type="excelType"
            @changeExcelType="changeExcelType"
            style="margin-left: 10px"
          /> -->
          <a-button v-hide-by-username class="button2" @click="exportExcel">
            <svg-icon icon-class="export" class="btn-icon" />
            <span>Excel导出</span>
          </a-button>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <div class="list-container">
          <div
            class="list-item"
            v-for="(item, index) in rankingList"
            :ref="`item_${item.code}`"
            :key="item.title"
          >
            <div class="list-item-title">
              <IconTitle
                :icon="item.icon"
                :title="item.title"
                :type="4"
                class="icon-title"
              />
              <AftTooltip
                v-if="item.tooltipCode"
                :code="item.tooltipCode"
                style="top: -2px; left: 4px"
              />
            </div>
            <div class="list-item-card">
              <div class="card-left">
                <div class="small-title">Top3 品牌</div>
                <div class="card-left-main">
                  <BrandRanking
                    :avatar="OSS_HOST + ci.principal_logo"
                    :name="ci.principal_name"
                    :ranking="String(ci.rank_num)"
                    v-for="ci in item.brandList"
                    :key="ci.principal_id"
                    :textColor="
                      ci.principal_id == item.self.principal_id ? '#FFCA5C' : ''
                    "
                  />
                </div>
              </div>
              <div class="line"></div>
              <div class="card-right">
                <div class="small-title" style="font-size: 14px">我的排名</div>
                <div class="card-right-main">
                  <BrandRanking
                    v-show="item.self && item.self.rank_num"
                    :avatar="OSS_HOST + item.self.principal_logo"
                    :name="item.self.principal_name"
                    :ranking="String(item.self.rank_num || '-')"
                    :self="true"
                    :textColor="'#fff'"
                  />
                  <div
                    class="small-title"
                    v-show="!item.self.rank_num"
                    style="padding-left: 20px"
                  >
                    -
                  </div>
                </div>
              </div>
              <div class="card-chart">
                <NewTabs2
                  :tabData="chartTabData"
                  @tabChange="changeChartTabs(...arguments, index)"
                />
                <!-- <div class="small-title">我的排名变化</div> -->
                <div
                  class="card-chart-main"
                  v-if="rankingList[index].tabsKey == '1'"
                >
                  <MyChart
                    :options="getCardChartOption(item)"
                    :id="'card-chart' + index"
                    v-if="item.chartDataX.length > 0"
                  />
                  <Empty v-else />
                </div>
                <div
                  class="card-chart-main"
                  v-if="rankingList[index].tabsKey == '2'"
                >
                  <MyChart
                    :options="getLineOption(item)"
                    :id="'line-chart' + index"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </PanleWrap>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/brand.js";
import SelectButton from "@/components/SelectButton/SelectButton";
import WebpImage from "@/components/WebpImage/WebpImage";
import exportTable from "@/utils/exportTable";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import IconTitle from "@/components/IconTitle/IconTitle";
import BrandRanking from "@/components/BrandRanking/BrandRanking";
import MyChart from "@/components/MyChart/MyChart";
import chartRankingLine from "./options/chartRankingLine.js";
import chartLine from "./options/chartLine2";
import deepClone from "@/utils/clone-utils";
import RouterButton from "@/components/RouterButton/RouterButton";
import Empty from "@/components/Empty/Empty";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import NewTabs2 from "@/components/PlatformTab/NewTabs2";

const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;
const rankingDefaultData = [
  {
    title: "粉丝总量排名",
    icon: "ti-ranking-1",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "4",
  },
  {
    title: "粉丝增量排名",
    icon: "ti-ranking-10",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "11",
  },
  {
    title: "总互动量排名",
    icon: "ti-ranking-2",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "1",
  },
  {
    title: "直播互动排名",
    icon: "ti-ranking-3",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "7",
  },
  {
    title: "视频互动排名",
    icon: "ti-ranking-4",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "8",
  },
  {
    title: "总发布量排名",
    icon: "ti-ranking-9",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "10",
  },
  {
    title: "直播场次排名",
    icon: "ti-ranking-6",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "3",
  },
  {
    title: "视频发布排名",
    icon: "ti-ranking-5",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "2",
  },
  {
    title: "直播观看排名",
    icon: "ti-ranking-11",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "13",
    tooltipCode: "qjzb03",
  },
  {
    title: "视频播放排名",
    icon: "ti-video",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "9",
  },
  {
    title: "视频点赞排名",
    icon: "ti-ranking-12",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "12",
  },
  {
    title: "营销价值排名",
    icon: "ti-ranking-7",
    brandList: [],
    self: {},
    chartDataX: [],
    chartDataY: [],
    tabsKey: "1",
    code: "5",
  },
  // {
  //   title: "有效直播客流排名",
  //   icon: "ti-ranking-13",
  //   brandList: [],
  //   self: {},
  //   chartDataX: [],
  //   chartDataY: [],
  //   tabsKey: "1",
  //   code: "6",
  //   tooltipCode: "qjzb01",
  // },
];
export default {
  name: "MyRanking",
  components: {
    PlatformHeader,
    PanleWrap,
    SelectButton,
    WebpImage,
    ExcelTypeButton,
    IconTitle,
    BrandRanking,
    MyChart,
    RouterButton,
    Empty,
    ButtonGroup,
    NewTabs2,
  },
  filters: {},
  data() {
    return {
      excelType: false,
      currentTab: "agg",
      platformKey: 0,
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      buttonData: [
        {
          label: "全部",
          value: "1",
          iconName: "all",
        },
        {
          label: "官号",
          value: "2",
          iconName: "official",
        },
        {
          label: "经销商",
          value: "3",
          iconName: "dealer",
        },
      ],
      currentButtonClick: "1",
      chartTabData: [
        {
          label: "我的排名变化",
          value: "1",
        },
        {
          label: "行业占比变化",
          value: "2",
        },
      ],
      tagList: [],
      tagValue: "0",
      indexList: [],
      indexValue: undefined,
      OSS_HOST,
      spinning: false,
      rankingList: deepClone(rankingDefaultData),
    };
  },
  async created() {
    api.getPrincipalTags().then((res) => {
      this.tagList = res.data;
      return res;
    });

    api.getIndexList().then((res) => {
      console.log(res);
      this.indexList = res.data.data.filter((item) => item.code != 6) || [];
      return res;
    });

    await this.getList();
  },
  methods: {
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      this.platformKey =
        val == "agg"
          ? 0
          : val == "douyin"
          ? 1
          : val == "kuaishou"
          ? 2
          : val == "dongchedi"
          ? 2
          : 0;
      this.sortValue = "1";
      this.getList();
    },
    getList() {
      let params = {
        platform_type: this.platformKey,
        data_type: this.currentButtonClick,
        tag_ps: this.tagValue,
      };
      this.spinning = true;
      api.getMyRankingList(params).then((res) => {
        if (res.data.code == 0 && res.data.data.length > 0) {
          let newData = res.data.data;
          this.rankingList.forEach((item, index) => {
            // 从返回数据中筛选出对应的item
            var resItem = newData.filter((current) => {
              return current.indicators == item.title;
            })[0];
            this.rankingList[index].self = resItem.self;
            this.rankingList[index].brandList = (resItem.list || []).slice(
              0,
              3
            );
            this.rankingList[index].chartDataX = [];
            this.rankingList[index].chartDataY = [];
            this.rankingList[index].chartDataY1 = [];
            this.rankingList[index].chartDataY2 = [];
            if (resItem.ranking_trend) {
              resItem.ranking_trend.forEach((ci) => {
                this.rankingList[index].chartDataX.push(
                  String(ci.rank_month).substr(0, 4) +
                    "." +
                    String(ci.rank_month).substr(4, 6)
                );
                this.rankingList[index].chartDataY.push({
                  value: ci.rank_num,
                  number: ci.numerical_value,
                });
              });
            }
            if (resItem.sum_value) {
              resItem.sum_value.forEach((ci) => {
                this.rankingList[index].chartDataY1.push(ci.numerical_value);
              });
            }
            if (resItem.proportion) {
              resItem.proportion.forEach((ci) => {
                this.rankingList[index].chartDataY2.push(ci.proportion);
              });
            }
          });
          this.spinning = false;
        } else {
          this.$message.warning("获取排名失败！");
          this.rankingList = deepClone(rankingDefaultData);
          this.spinning = false;
        }
      });
    },
    changeIndexList(val) {
      console.log("changeIndexList", val);
      this.scrollIndex(val);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.trim().toLowerCase()) >= 0
      );
    },
    // 全部，官号，经销商 点击
    buttonClick(value) {
      this.currentButtonClick = value;
      this.getList();
    },

    getCardChartOption(item) {
      console.log(111, item);
      let option = deepClone(chartRankingLine);

      option.xAxis[0].data = item.chartDataX;
      option.series[0].data = item.chartDataY;
      option.series[0].name = item.title.substr(0, 4);
      return option;
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    changeChartTabs(value, index) {
      this.rankingList[index].tabsKey = value;
    },
    getLineOption(item) {
      let option = deepClone(chartLine);
      option.xAxis[0].data = item.chartDataX;
      option.series[0].data = item.chartDataY1;
      option.series[1].data = item.chartDataY2;
      option.series[0].name = "行业" + item.title.slice(0, -2);
      return option;
    },
    scrollIndex(code) {
      if (code) {
        let dom = this.$refs[`item_${code}`][0];
        dom.scrollIntoView({
          //滚动到指定节点
          block: "start",
          behavior: "smooth",
        });
      }
    },
    // 导出Excel
    exportExcel() {
      let params = {
        data_type: this.currentButtonClick,
        tag_ps: this.tagValue,
      };
      exportTable(
        this.$http,
        params,
        "agg",
        "/jjt/new/brand/business-influence/rank/export2",
        "filename"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

img {
  max-width: 100%;
}

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.list_header {
  margin-bottom: 20px;
  > h3 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    @include font_color("font_color2");
    margin: 0;
  }
}
.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 20px;
}

.btn-box {
  display: flex;
  align-items: center;
  .button2 {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 113px;
    height: 40px;
    border-radius: 8px;
    line-height: 40px;
    font-size: 14px;
    border: none;
    @include font_color("font_color1");
    @include background_color("background_color63");
    .btn-icon {
      font-size: 18px;
      margin-right: 7px;
      @include font_color("font_color34");
    }
    &:hover .btn-icon {
      @include font_color("font_color35");
    }
  }
}

.list-container {
  width: 100%;
  height: calc(100vh - 290px);
  overflow-y: auto;
  padding: 0 20px;
  .list-item {
    margin-bottom: 10px;
    &-title {
      height: 62px;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
    &-card {
      width: 100%;
      height: 236px;
      border-radius: 16px;
      @include background_color("background_color81");
      padding: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      .small-title {
        font-size: 12px;
        @include font_color("font_color22");
      }
      .card-left {
        width: 386px;
        height: 100%;
        &-main {
          padding: 32px 82px 0 6px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }
      }
      .line {
        width: 1px;
        height: 175px;
        background: #2b2f3b;
        margin-right: 77px;
      }
      .card-right {
        width: 60px;
        height: 100%;
        &-main {
          margin-top: 32px;
        }
      }
      .card-chart {
        width: calc(100% - 574px);
        height: 100%;
        margin-left: 49px;
        &-main {
          width: 100%;
          height: 160px;
          margin-top: 7px;
        }
        .no-result {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 160px;
        }
      }
    }
  }
}
</style>
