import nr from '@/utils/NetworldRequest'
const openNr = (new nr()).getAxios()
import data from './storePolicyLink.json'
console.log(data)
// export default {
//     getBlackList() {
//         return openNr.get(`//afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/json/blackUser.json`)
//     },
// }
// https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/json/storePolicyLink.json
export default data.data