<template>
  <div class="components-market-container">
    <PlatformHeader title="组件大盘" :showPlatform="false" />
    <div class="tools-div">
      <div>
        <a-select
          v-model="area_id"
          :suffixIcon="suffixIcon"
          style="width: 200px; height: 40px"
          placeholder="选择大区"
          @change="changeRegion"
          :labelInValue="true"
        >
          <a-select-option
            :value="item.value"
            v-for="item in regionTabs"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <!-- <ButtonGroup
          :tab-data="toolsTabs"
          @tabChange="changeToolsTabs"
          style="margin-right: 10px"
        /> -->
        <!-- <a-select
          v-model="activity_value"
          :suffixIcon="suffixIcon"
          style="width: 200px; height: 40px"
          placeholder="选择活动"
          @change="changeActivity"
          v-show="toolsTabsKey == '1'"
        >
          <a-select-option
            :value="item.id"
            v-for="item in activityList"
            :key="item.id"
          >
            {{ item.activity_name }}
          </a-select-option>
        </a-select> -->

        <date-tab
          :type="dateType"
          @changeType="changeType"
          @changeDate="changeDate"
          v-show="toolsTabsKey == '2'"
          ref="dateTab"
        ></date-tab>
      </div>
    </div>
    <div class="tabs-div">
      <NewTabs
        :tabData="tabsData"
        @tabChange="changeTabs"
        :labelStyle="{ lineHeight: '44px' }"
      />
    </div>
    <div class="tabs-container-main">
      <TabsContent1 ref="TabsContent1" v-show="tabsKey == '1'" />
      <TabsContent2 ref="TabsContent2" v-show="tabsKey == '2'" />
      <TabsContent3 ref="TabsContent3" v-show="tabsKey == '3'" />
      <TabsContent4 ref="TabsContent4" v-show="tabsKey == '4'" />
    </div>
    <div class="table-container">
      <TableContent ref="TableContent" />
    </div>
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import NewTabs from "@/components/PlatformTab/NewTabs";
import TabsContent1 from "./components/TabsContent1";
import TabsContent2 from "./components/TabsContent2";
import TabsContent3 from "./components/TabsContent3";
import TabsContent4 from "./components/TabsContent4";
import TableContent from "./components/TableContent";
import DateTab from "@/components/DateTab/DateTab2";
import api from "@/api/componentsMarket.js";
export default {
  name: "componentsMarket",
  components: {
    PlatformHeader,
    ButtonGroup,
    NewTabs,
    TabsContent1,
    TabsContent2,
    TabsContent3,
    TabsContent4,
    TableContent,
    DateTab,
  },
  data() {
    return {
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      regionTabs: [
        {
          label: "全品牌",
          value: "",
        },
      ],
      area_id: {
        label: "全品牌",
        key: "",
      },
      // regionTabsKey: "",
      toolsTabs: [
        {
          label: "按活动",
          value: "1",
        },
        {
          label: "按时间",
          value: "2",
        },
      ],
      toolsTabsKey: "2",
      tabsData: [
        {
          label: "概览",
          value: "1",
        },
        {
          label: "挂载诊断",
          value: "2",
        },
        {
          label: "时间趋势",
          value: "3",
        },
        {
          label: "大区透视",
          value: "4",
        },
      ],
      tabsKey: "1",
      activityList: [],
      activity_value: undefined,
      dateType: "month",
      currentTab: "douyin",
      begin_time: "",
      end_time: "",
    };
  },
  computed: {},
  created() {
    Promise.all([this.getAreaList(), this.getActivityList()]).then((res) => {
      if (res[0] && res[0].length > 0) {
        this.regionTabs = this.regionTabs.concat(res[0]);
      }
      // if (res[1].data.code == 0 && res[1].data.data.length > 0) {
      //   this.activityList = res[1].data.data || [];
      //   this.activity_value = res[1].data.data[0].id;
      //   this.getTabsContentData();
      // } else {
      //   this.$message.warning("未查询到活动");
      //   return false;
      // }
    });
  },
  mounted() {
    this.changeDate();
  },
  watch: {},
  methods: {
    // 切换全品牌、大区
    // changeRegionTabs(val) {
    //   if (this.regionTabsKey == val) {
    //     return false;
    //   }
    //   this.regionTabsKey = val;
    // },
    // 切换 按活动、按时间
    // changeToolsTabs(val) {
    //   if (this.toolsTabsKey == val) {
    //     return false;
    //   }
    //   this.toolsTabsKey = val;
    //   this.changeDate();
    // },
    // 切换大区
    changeRegion(item) {
      this.getTabsContentData();
    },
    // 切换活动
    changeActivity() {
      this.getTabsContentData();
    },
    // 切换 显示tabs
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
      this.$refs[`TabsContent${val}`].resizeChart();
      this.$refs.TableContent.getComponentData();
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      // this.search(true);
    },
    changeDate() {
      let begin_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      this.begin_time = begin_time;
      this.end_time = end_time;
      this.getTabsContentData();
    },
    // 获取大区列表
    getAreaList() {
      return api.getAreaList();
    },
    // 获取活动列表
    getActivityList() {
      return api.getActivityList({ platform: this.currentTab });
    },
    // 获取tabs数据
    getTabsContentData() {
      this.$refs.TabsContent1.getComponentData();
      this.$refs.TabsContent2.getComponentData();
      this.$refs.TabsContent3.getComponentData();
      this.$refs.TabsContent4.getComponentData();
      this.$refs.TableContent.getComponentData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.components-market-container {
  .tools-div {
    width: 100%;
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
    }
  }
  .tabs-div {
    width: 100%;
    margin: 16px 0;
    padding: 0 20px;
  }
  .tabs-container-main {
    padding: 0 20px;
    margin-bottom: 16px;
  }
  .table-container {
    padding: 0 20px;
    margin-bottom: 16px;
  }
}
</style>
