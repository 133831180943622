<template>
  <div
    class="act-item"
    :class="{ 'is-active': isActive }"
    @click="handleClick"
    v-track:click="{
      disabled: isDisabledTrack,
      action: '切换活动',
      activityId: data.id,
      activityName: data.activityName,
    }"
  >
    <div class="act-item-name">
      <span
        class="act-tag"
        :class="{
          'act-tag--1': data.activityStatus === 1,
          'act-tag--2': data.activityStatus === 2,
          'act-tag--3': data.activityStatus === 3,
        }"
      >
        {{ data.activityStatus | formatActivityStatus }}
      </span>
      <span class="act-name">
        {{ data.activityName || "-" }}
      </span>
    </div>
    <div class="act-item-time">
      <span class="act-time"> {{ data.startTime }} 至 {{ data.endTime }}</span>
    </div>
    <div
      v-if="isShowTaskRate && data.activityStatus !== 1"
      class="act-rate-box"
    >
      <span class="act-rate">
        <span class="act-rate-label">门店参与率</span>
        <span class="act-rate-value">{{ data.participationRate }}%</span>
      </span>
      <span class="act-time" v-if="data.isTask">
        <span class="act-rate-label">任务完成率</span>
        <span class="act-rate-value">{{ data.completionRate }}%</span>
      </span>
    </div>
    <svg-icon class="act-item-icon" icon-class="right-selected-8px" />
  </div>
</template>

<script>
export default {
  name: "ActItem",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isDisabledTrack: {
      type: Boolean,
      default: false,
    },
    isShowTaskRate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatStatus(val) {
      let text;

      if (val.activityStatus === 1) {
        const m1 = this.$moment(this.$moment().format("YYYY-MM-DD"));
        const m2 = this.$moment(
          this.$moment(val.startTime).format("YYYY-MM-DD")
        );
        const day = m2.diff(m1, "day");

        text = `距开始剩${day}天`;
      }

      if (val.activityStatus === 2) {
        const m1 = this.$moment(
          this.$moment(val.startTime).format("YYYY-MM-DD")
        );
        const m2 = this.$moment(this.$moment().format("YYYY-MM-DD"));
        const day = m2.diff(m1, "day");

        text = `已进行${day + 1}天`;
      }

      val.activityStatus === 3 && (text = "已结束");

      return text;
    },
    handleClick() {
      this.$emit("click");
    },
  },
  filters: {
    formatActivityStatus(val) {
      let text = "-";

      val === 1 && (text = "未开始");
      val === 2 && (text = "进行中");
      val === 3 && (text = "已结束");

      return text;
    },
  },
};
</script>

<style lang="less" scoped>
.act-item {
  position: relative;
  margin: 0 0 16px;
  padding: 16px;
  background: #2b2f3b;
  border: 1px solid #2b2f3b;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;

  &-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &-time {
    margin: 8px 0 0;
  }

  .act-tag {
    margin: 0 8px 0 0;
    padding: 0 8px;
    height: 22px;
    color: #8890a6;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    background: #3a3e4a;
    border-radius: 4px;

    &--1 {
      color: #ff9e3d;
      background: rgba(#ff9e3d, 0.2);
    }

    &--2 {
      color: #27c346;
      background: rgba(#27c346, 0.2);
    }

    &--3 {
      color: #8890a6;
      background: #3a3e4a;
    }
  }

  .act-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1;
    color: rgba(255, 255, 255, 0.88);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    overflow: hidden;
  }

  .act-time {
    color: rgba(255, 255, 255, 0.55);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .act-rate-box {
    margin-top: 16px;
    .act-rate {
      margin-right: 24px;
    }
    .act-rate-label {
      color: rgba(255, 255, 255, 0.55);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .act-rate-value {
      margin-left: 8px;
      color: rgba(255, 255, 255, 0.88);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  &-icon {
    display: none;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: rgba(#000, 0.1);
    border-color: rgba(#000, 0.1);
  }

  &.is-active {
    background: #3b3835;
    border-color: #ffcc55;
    box-shadow: inset 0px -1px 0px 0px #363a46;

    .act-item {
      &-icon {
        display: block;
      }
    }
  }
}
</style>
