export const tabsList = [
  {
    label: "全部",
    value: "0",
  },
  {
    label: "进行中",
    value: "2",
  },
  {
    label: "已结束",
    value: "3",
  },
  {
    label: "未开始",
    value: "1",
  },
];

export const rateList = [
  {
    label: "任务总完成率",
    value: 0,
    valueKey: "activityTaskRate",
    icon: "task3",
  },
  {
    label: "门店参与率",
    value: 0,
    valueKey: "dealerParticipationRate",
    icon: "store5",
  },
];

export const dataList = [
  {
    label: "累计直播场次",
    value: 0,
    valueKey: "liveCount",
    valueType: "num",
    mainValueKey: "regionLiveCount",
    mainValue: 0,
  },
  {
    label: "累计直播观看",
    value: 0,
    valueKey: "liveWatchCount",
    valueType: "num",
    mainValueKey: "regionLiveWatchCount",
    mainValue: 0,
  },
  {
    label: "累计直播互动",
    value: 0,
    valueKey: "liveInteractCount",
    valueType: "num",
    mainValueKey: "regionLiveInteractCount",
    mainValue: 0,
  },
  {
    label: "累计视频发布",
    value: 0,
    valueKey: "awemeCount",
    valueType: "num",
    mainValueKey: "regionAwemeCount",
    mainValue: 0,
  },
  {
    label: "累计视频播放",
    value: 0,
    valueKey: "awemeWatchCount",
    valueType: "num",
    mainValueKey: "regionAwemeWatchCount",
    mainValue: 0,
  },
  {
    label: "累计视频互动",
    value: 0,
    valueKey: "awemeInteractCount",
    valueType: "num",
    mainValueKey: "regionAwemeInteractCount",
    mainValue: 0,
  },
  {
    label: "活动期间增粉",
    value: 0,
    valueKey: "addFansCount",
    valueType: "num",
    mainValueKey: "regionAddFansCount",
    mainValue: 0,
  },
];

export const navList = [
  {
    name: "活动概况",
    icon: "activity-nav-overview",
    status: [1, 2, 3],
    // path: "/marketing/activityOverview",
    path: "/jjt-new/activity/activity-overview",
  },
  {
    name: "每日战报",
    icon: "activity-nav-achievements",
    status: [1, 2, 3],
    path: "/marketing/dailyAchievements",
  },
  {
    name: "任务中心",
    icon: "activity-nav-task",
    status: [1, 2, 3],
    path: "/marketing/taskCenter",
  },
  {
    name: "直播大屏",
    icon: "activity-nav-live",
    status: [2],
    path: "/marketing/activityScreenN",
  },
  {
    name: "活动榜单",
    icon: "activity-nav-rank",
    status: [1, 2, 3],
    // path: "/marketing/activityRanking",
    path: "/jjt-new/activity/activity-ranking",
  },
  {
    name: "活动数据",
    icon: "activity-nav-data",
    status: [1, 2, 3],
    path: "/marketing/activityStatistics",
  },
];

export const platformData = {
  1: "抖音",
  2: "快手",
  3: "小红书",
  4: "视频号",
};
export const statisticsRangeData = {
  1: "视频",
  2: "直播",
  3: "笔记",
};
export const videoCreateSourceData = {
  0: "全部视频",
  1: "仅使用活动模板",
  2: "使用任意模板",
};
export const scopeData = {
  1: "全部经销商",
  2: "账号分组",
  3: "门店分组",
  4: "大区范围",
  5: "战区范围",
};
export const liveTargetTypeData = {
  1: "直播时长",
  2: "直播观看量",
};
export const noteTypeData = {
  1: "标题",
  2: "话题",
  3: "正文",
};
export const videoTypeData = {
  1: "标题",
  2: "话题",
};
