<template>
  <div class="container">
    <div class="second-header">
      <div>
        <!-- <span class="back-btn" @click="goBack">
          <a-icon type="left" />
        </span> -->
        <span class="title"
          >活动榜单 · {{ currentActivityItem.activityName }}</span
        >
        <span class="switch-button" @click="switchActivity">
          <span>切换活动</span>
          <svg-icon icon-class="arrow-down" class="arrow-icon" />
        </span>
      </div>
      <div>
        <platform-header
          title=""
          ref="PlatformHeader"
          :tabData="tabData"
          @tabChange="tabChange"
        />
      </div>
    </div>
    <div class="tabs-div">
      <NewTabs :tabData="tabsData" @tabChange="changeTabs" />
      <div>
        <span class="date-label">活动日期：</span>
        <span class="date-value"
          >{{ currentActivityItem.startTime }} -
          {{ currentActivityItem.endTime }}</span
        >
      </div>
    </div>
    <div class="tools">
      <div class="tools-left">
        <ButtonGroup
          class="mr-16"
          :default-value="rankType"
          :tab-data="rankTypeList"
          @tabChange="handleType2Change"
        />

        <a-select
          class="aft-select default"
          v-model="regionId"
          placeholder="选择大区"
          style="width: 122px; margin-right: 8px"
          @change="regionChange"
          v-if="tabsKey == '2'"
        >
          <a-select-option value="" v-if="isAllRegion"
            >不限大区</a-select-option
          >
          <a-select-option
            v-for="item in regionList || []"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="tools-right">
        <!-- <a-range-picker
          v-model="dateValue"
          @calendarChange="calendarChange"
          class="aft-range-picker default"
          :disabled-date="disabledDate"
          @change="dateChange"
          locale="zh-cn"
          format="YYYY-MM-DD"
          style="width: 220px; margin-right: 8px"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-range-picker> -->
      </div>
    </div>
    <a-spin :spinning="spinning">
      <div class="content">
        <div class="content-left">
          <div
            class="content-left-li"
            v-for="item in listData"
            :key="item.key"
            @click="currentList = item"
          >
            <TableItem1
              :list="item"
              :active="currentList.key == item.key"
              v-if="item.type == 1"
            />
            <TableItem2
              :list="item"
              :active="currentList.key == item.key"
              v-else
            />
          </div>
        </div>
        <div class="content-right">
          <TableList1 :list="currentList" v-if="currentList.type == 1" />
          <TableList2 :list="currentList" v-else />
        </div>
      </div>
    </a-spin>

    <!-- <SwitchActivity
      :currentItem="currentActivityItem"
      @changeActivity="changeActivity"
      ref="SwitchActivity"
    /> -->

    <!-- 切换活动 -->
    <ActSelectDrawer
      :visible.sync="actSelectVisible"
      mode="switch"
      :default-selected-act-list="[currentActivityItem]"
      @switch="changeActivity"
    />
  </div>
</template>

<script>
import ActSelectDrawer from "@/components/ActSelectDrawer";
import Empty from "@/components/Empty/Empty";
import NewTabs from "@/components/PlatformTab/NewTabs";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import deepClone from "@/utils/clone-utils";
import api from "@/api/activityRanking.js";
import ac_api from "@/api/marketingActivityList.js";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
// import SwitchActivity from "../components/SwitchActivity";
import TableItem1 from "./components/TableItem1";
import TableItem2 from "./components/TableItem2";
import TableList1 from "./components/TableList1";
import TableList2 from "./components/TableList2";
import { type2List } from "./constant";

const defaultRankTypeList = type2List;
const defaultList = [
  {
    title: "直播观看",
    key: "bestLiveWatchList",
    type: 2,
    data: [],
    targetName: "观看量",
    targetKey: "liveWatchCount",
    routePath: "/liveDetail",
  },
  {
    title: "门店直播观看",
    key: "dealerLiveWatchList",
    type: 1,
    data: [],
    targetName: "直播观看",
    targetKey: "liveWatchCount",
    routePath: "/dealer/dealerDetail",
  },
  {
    title: "门店直播场次",
    key: "dealerLiveCountList",
    type: 1,
    data: [],
    targetName: "直播场次",
    targetKey: "liveCount",
    routePath: "/dealer/dealerDetail",
  },
  {
    title: "视频播放",
    key: "bestAwemeWatchList",
    type: 2,
    data: [],
    targetName: "播放量",
    targetKey: "awemeWatchCount",
    routePath: "/videoDetail",
  },
  {
    title: "门店视频播放",
    key: "dealerAwemeWatchList",
    type: 1,
    data: [],
    targetName: "视频播放",
    targetKey: "awemeWatchCount",
    routePath: "/dealer/dealerDetail",
  },
  {
    title: "门店视频发布",
    key: "dealerAwemePublishList",
    type: 1,
    data: [],
    targetName: "视频发布",
    targetKey: "awemePublishCount",
    routePath: "/dealer/dealerDetail",
  },
];

export default {
  name: "activityRanking",
  components: {
    ActSelectDrawer,
    Empty,
    NewTabs,
    ButtonGroup,
    PlatformHeader,
    // SwitchActivity,
    TableItem1,
    TableItem2,
    TableList1,
    TableList2,
  },
  data() {
    return {
      tabData: [
        {
          label: "全部平台",
          value: "agg",
        },
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
      ],
      currentTab: "agg",
      tabsData: [
        {
          label: "全国榜单",
          value: "1",
        },
        {
          label: "区域榜单",
          value: "2",
        },
      ],
      tabsKey: "1",
      rankTypeList: [],
      rankType: 1,
      regionList: [],
      regionId: undefined,
      isAllRegion: false,
      activity_id: undefined,
      dateValue: [],
      currentActivityItem: {
        startTime: "",
        endTime: "",
        activityName: "-",
      },
      listData: [],
      currentList: {},
      spinning: false,
      actSelectVisible: false,
    };
  },
  async created() {
    // 判断页面来源
    if (this.$route.query.aId) {
      this.currentActivityItem = {
        id: this.$route.query.aId,
        startTime: this.$route.query.startTime,
        endTime: this.$route.query.endTime,
        activityName: this.$route.query.aName,
        videoCreateSource: this.$route.query.videoCreateSource,
        activityStatus: Number(this.$route.query.activityStatus),
        isLive: Number(this.$route.query.isLive),
        isVideo: Number(this.$route.query.isVideo),
      };
    }
  },
  async mounted() {
    const activity_result = await this.getActivityList();
    this.setActivityList(activity_result);
    this.track("切换活动");

    const region_result = await this.getAreaInfoByActivity();
    this.setRegionList(region_result);
  },
  methods: {
    // 默认选择活动埋点
    track(action) {
      const trackData = {
        action,
        default: 1,
        activityId: this.currentActivityItem.id,
        activityName: this.currentActivityItem.activityName,
      };
      this.$trackRequest(this.$route, this.$trackEvent.CLICK, trackData);
    },
    // 获取活动列表
    getActivityList() {
      let params = {
        page: 1,
        size: 1,
      };
      return ac_api.getActivityList(params);
    },
    setActivityList(res) {
      if (res.data.code == 200 && res.data.data.list.length > 0) {
        if (!this.$route.query.aId) {
          this.currentActivityItem = res.data.data.list[0];
        }

        this.dateValue = [
          moment(this.currentActivityItem.startTime),
          moment(this.currentActivityItem.endTime),
        ];

        this.setRankTypeList();
        this.setRankType();
      } else {
        this.currentActivityItem = {};
        this.$message.error(res.data.message || "获取活动列表失败");
      }
    },
    setRankTypeList() {
      let rankTypeList = deepClone(defaultRankTypeList);

      if (this.currentActivityItem.activityStatus === 3) {
        rankTypeList = rankTypeList.filter((item) => {
          return ![1].includes(item.value);
        });
      }

      this.rankTypeList = rankTypeList;
    },
    setRankType() {
      const { activityStatus } = this.currentActivityItem;

      activityStatus !== 3 && (this.rankType = 1);
      activityStatus === 3 && (this.rankType = 2);
    },
    // 获取大区下拉列表
    getAreaInfoByActivity() {
      let params = {
        activityId: this.currentActivityItem.id,
      };
      return api.getAreaInfoByActivity(params);
    },
    setRegionList(res) {
      if (res.data.code == 200 && res.data.data.region_list.length > 0) {
        this.regionList = res.data.data.region_list || [];
        if (res.data.data.type === 10) {
          this.isAllRegion = true;
          this.regionId = "";
        } else {
          this.regionId = this.regionList[0].id;
        }
        this.getActivityListByTypes();
      } else {
        this.regionList = [];
        this.$message.warning(res.data.message || "获取大区列表失败");
      }
    },
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      this.setRankType();
      this.getActivityListByTypes();
    },
    // tabs切换
    changeTabs(val) {
      this.tabsKey = val;
      this.setRankType();
      this.getActivityListByTypes();
    },
    handleType2Change(val) {
      this.rankType = val;
      this.getActivityListByTypes();
    },
    // 大区选择
    regionChange() {
      this.getActivityListByTypes();
    },
    // 活动榜单列表
    getActivityListByTypes() {
      let params = {
        activityId: this.currentActivityItem.id,
        startTime: moment(this.dateValue[0]).format("YYYY-MM-DD"),
        endTime: moment(this.dateValue[1]).format("YYYY-MM-DD"),
        type:
          this.currentTab == "agg"
            ? "1"
            : this.currentTab == "douyin"
            ? "2"
            : this.currentTab == "kuaishou"
            ? "3"
            : "1",
        rankType: this.rankType,
      };
      if (this.tabsKey == "2") {
        params.regionId = this.regionId;
      }
      this.listData = [];
      this.currentList = {};
      this.spinning = true;
      api
        .getActivityListByTypes(params)
        .then((res) => {
          if (res.data.code == 200) {
            let newDefaultList = deepClone(defaultList);

            // 过滤掉直播相关的
            if (this.currentActivityItem.isLive === 0) {
              const keys = [
                "bestLiveWatchList",
                "dealerLiveWatchList",
                "dealerLiveCountList",
              ];

              newDefaultList = newDefaultList.filter((item) => {
                return !keys.includes(item.key);
              });
            }

            // 过滤掉视频相关的
            if (this.currentActivityItem.isVideo === 0) {
              const keys = [
                "bestAwemeWatchList",
                "dealerAwemeWatchList",
                "dealerAwemePublishList",
              ];

              newDefaultList = newDefaultList.filter((item) => {
                return !keys.includes(item.key);
              });
            }

            this.listData = newDefaultList.reduce((acc, cur) => {
              if (res.data.data[cur.key]) {
                acc.push({
                  ...cur,
                  title:
                    this.rankType === 1
                      ? `${cur.title}日榜`
                      : `${cur.title}总榜`,
                  data: res.data.data[cur.key],
                });
              }
              return acc;
            }, []);
            this.currentList = this.listData[0];
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    // 切换活动点击
    switchActivity() {
      // this.$refs.SwitchActivity.showDrawer();
      this.actSelectVisible = true;
    },
    // 切换活动触发
    async changeActivity(id, item) {
      this.currentList = {};
      this.currentActivityItem = item;
      this.dateValue = [
        moment(this.currentActivityItem.startTime),
        moment(this.currentActivityItem.endTime),
      ];
      this.setRankTypeList();
      this.setRankType();
      this.regionId = undefined;
      const region_result = await this.getAreaInfoByActivity();
      this.setRegionList(region_result);
    },
    // 日期切换
    calendarChange(date) {
      this.dateValue[0] = date[0];
    },
    dateChange(date) {
      if (date.length == 0) {
        this.dateValue = [
          moment(this.currentActivityItem.startTime),
          moment(this.currentActivityItem.endTime),
        ];
      }
      this.getActivityListByTypes();
    },
    disabledDate(current) {
      return (
        current < moment(this.currentActivityItem.startTime) ||
        current > moment(this.currentActivityItem.endTime)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;

  .mr-16 {
    margin-right: 16px;
  }

  .second-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 0 0 20px;
    > div {
      display: flex;
      align-items: center;
      .back-btn {
        width: 40px;
        height: 40px;
        background: #2b2f3b;
        border-radius: 9px;
        margin-right: 12px;
        color: #9ca8ba;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }

      .switch-button {
        font-size: 14px;
        color: #ffcc55;
        margin-left: 8px;
        cursor: pointer;
        .arrow-icon {
          margin-left: 4px;
          font-size: 12px;
        }
      }
    }
  }
  .tabs-div {
    padding: 0 24px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date-label {
      font-size: 14px;
      color: #8890a6;
    }
    .date-value {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
    }
  }

  .tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 24px;

    &-left {
      display: flex;
      align-items: center;
    }
  }

  .content {
    width: 100%;
    height: calc(100vh - 244px);
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    &-left {
      width: 437px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &-li {
        margin-bottom: 16px;
      }
    }
    &-right {
      width: calc(100% - 442px);
      height: calc(100% - 20px);
      background: #2b2f3b;
      border-radius: 16px;
      overflow: hidden;
    }
    .spin-box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
