<template>
  <div :id="id" :class="className" :style="styleObject" :chartData="chartData" :nameTip="nameTips" />
</template>
<script>
import echarts from "echarts";
import resize from "@/mixins/resize";
import _ from "lodash";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: "874px", height: "300px" };
      }
    },
    chartData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    nameTips: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chart: null
    };
  },

  computed: {
    styleObject: function() {
      const obj = this.boxStyle;
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";

        obj[attr] =
          unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    chartData(val) {
      console.log('change line', val)
      this.initChart();
    }
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      const colorList = ["#04EBEB", "#2A90F4"];
      const borderColor = ["rgba(4,235,235,0.1)", "rgba(42,144,244,0.1)"];
      this.chart = echarts.init(document.getElementById(this.id));
      const arr = this.chartData.name;
      const series = [];
      let axis_data =[];
      if(this.chartData.xAxis.length){
        this.chartData.xAxis.forEach(element => {
             axis_data.push(element.slice(11,16))
        });
      }
      for (const key in arr) {
        // let strings = key.toString()
        series.push({
          name: arr[key],
          type: "line",
          // data:  _.get(this, `chartData.yAxis[${key}]`, []).slice(-7),
          data:_.get(this, `chartData.yAxis[${key}]`, []),
          symbolSize: 1,
          symbol: "circle",
          smooth: true,
          yAxisIndex: 1, // 单个图表两个Y轴默认为零
          showSymbol: false,
          lineStyle: {
            width: 1,
            color: colorList[key],
            shadowColor: borderColor[key],
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[key],
              borderColor: borderColor[key]
            }
          }
        });
      }
      this.chart.setOption({
        // backgroundColor: "#fff",
        legend: {
          show: false
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              show: true,
              backgroundColor: "#fff",
              color: "#556677",
              borderColor: "rgba(0,0,0,0)",
              shadowColor: "rgba(0,0,0,0)",
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: 'rgba(37, 50, 85, 0.9)',
          textStyle: {
              color: '#fff',
          },
          padding: [10, 10],
          extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)"
        },
        grid: {
          top: 10,
          bottom: "20px",
          left: "14px",
          right: "54px",
          containLabel: false
        },
        xAxis: [
          {
            type: "category",
            data:axis_data,
            // data:_.get(this,"chartData.xAxis",[]),
            axisLine: {
              lineStyle: {
                color: "#5A5B64",
                opacity: 0.4
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: "#87898F",
                opacity: 0.6
              }
              //   margin: 15
            },
            axisPointer: {
              label: {
                padding: [11, 5, 7],
                backgroundColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#fff" // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#fff" // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#33c0cd" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#33c0cd" // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              }
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#5A5B64",
                opacity: 0.4
              }
            },
            axisLabel: {
              textStyle: {
                color: "#87898F",
                opacity: 0.6
              }
            },
            splitLine: {
              show: false
            }
          },
          {
            type: "value",
            position: "right",
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: "#87898F",
                opacity: 0.6
              },
              formatter: "{value}"
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#5A5B64",
                opacity: 0.4
              }
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: series
      });
    }
  }
};
</script>
