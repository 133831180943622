<template>
  <div>
    <div class="title-div">
      <span>账号详情</span>
    </div>
    <!-- 顶部公用账号详情、矩阵等信息 -->
    <AccountDetail />
    <!-- tools -->
    <div class="tools">
      <button-tabs :tabData="$route.query.type == 'dongchedi' ? btnTabsList2 : btnTabsList" @tabChange="handleBtnTabsChange" />
      <a-month-picker
        v-model="month"
        class="aft-month-picker default"
        placeholder="选择月份"
        @change="selectMonth"
        :disabled-date="pickerOptions"
        locale="zh-cn"
        v-show="listTab != '4'"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-month-picker>
    </div>
    <div class="tab-main-container">
      <LiveList ref="LiveList" v-show="listTab == '2'" />
      <VideoList ref="VideoList" v-show="listTab == '3'" />
      <FansPortrait
        ref="FansPortrait"
        v-if="$route.query.type != 'dongchedi'"
        v-show="listTab == '4'"
      />
    </div>
  </div>
</template>

<script>
import AccountDetail from "./components/AccountDetail";
import LiveList from "./components/LiveList";
import VideoList from "./components/VideoList";
import FansPortrait from "./components/FansPortrait";

import ButtonTabs from "@/components/PlatformTab/NewButtonTab";
const btnTabsList = [
  {
    label: "直播列表",
    value: "2"
  },
  {
    label: "视频列表",
    value: "3"
  },
  {
    label: "粉丝画像",
    value: "4"
  }
];
const btnTabsList2 = [
  {
    label: "直播列表",
    value: "2"
  },
  {
    label: "视频列表",
    value: "3"
  }
];
export default {
  components: {
    AccountDetail,
    LiveList,
    VideoList,
    FansPortrait,
    ButtonTabs
  },
  data() {
    return {
      month: this.$moment(new Date()),
      btnTabsList,
      btnTabsList2,
      listTab: "2"
    };
  },
  mounted() {},
  methods: {
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().endOf("month");
    },
    // 直播、视频切换
    handleBtnTabsChange(val) {
      console.log("直播、视频切换", val);
      if (this.listTab == val) {
        return false;
      }
      this.listTab = val;
      if (this.listTab == "4") {
        this.$refs.FansPortrait.resizeChart();
      }
    },
    // 时间变更
    selectMonth(value) {
      if (!value) {
        this.month = this.$moment()
          .month(moment().month())
          .startOf("month");
        this.$message.warning("默认选择本月数据");
      }
      this.$refs.LiveList.initData();
      this.$refs.VideoList.initData();
      this.$refs.FansPortrait.initData();
      // this.initData(this.month);
      //   this.$emit("changeMonth", this.month);
    },
    // 获取参数
    getParams() {
      return {
        type: this.$route.query.type,
        author_id: this.$route.query.author_id,
        start_time: moment(this.month)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(this.month)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        month: this.month
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.title-div {
  font-size: 20px;
  @include font_color("font_color20");
  height: 80px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tools {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  margin: 30px 0 20px;
  display: flex;
  justify-content: space-between;
}
.tab-main-container {
  padding: 0 20px;
}
</style>