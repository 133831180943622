<template>
  <div
    class="date-container"
    :style="{ width: 227 + 60 * typeArray.length + 'px' }"
  >
    <div
      :class="type == 'week' ? 'active' : ''"
      v-if="typeArray.includes('week')"
    >
      <span class="label" @click="changeType('week')">按周</span>
      <a-week-picker
        v-model="searchWeekTime"
        placeholder="Select week"
        class="aft-range-picker default"
        :disabled-date="weekDisabledDate"
        @change="onChangeWeek"
        locale="zh-cn"
        style="width: 220px; margin-left: 7px"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-week-picker>
    </div>
    <div
      :class="type == 'month' ? 'active' : ''"
      v-if="typeArray.includes('month')"
    >
      <span class="label" @click="changeType('month')">按月</span>
      <a-month-picker
        v-model="searchMonthTime"
        placeholder="Select month"
        class="aft-month-picker default"
        :disabled-date="disabledDate"
        @change="onChangeMonth"
        locale="zh-cn"
        style="width: 220px; margin-left: 7px"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-month-picker>
    </div>
    <div
      :class="type == 'day' ? 'active' : ''"
      v-if="typeArray.includes('day')"
    >
      <span class="label" @click="changeType('day')">自定义</span>
      <a-range-picker
        v-model="searchDayTime"
        @calendarChange="calendarPriceRangeChange"
        class="aft-range-picker default"
        :disabled-date="disabledDate2"
        @change="onChangeDay"
        locale="zh-cn"
        format="YYYY-MM-DD"
        style="width: 220px; margin-left: 7px"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-range-picker>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateTabAll",
  props: {
    type: {
      type: String,
      defult: "month",
    },
    weekDisabledDate: {
      type: Function,
      default: (current) => {
        return current && current >= moment().endOf("day");
      },
    },
    typeArray: {
      type: Array,
      default: () => {
        return ["week", "month", "day"];
      },
    },
    monthSubtract: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      searchMonthTime: moment().subtract(this.monthSubtract, "months"),
      searchWeekTime: moment().subtract(1, "week"),
      searchDayTime: [
        moment().subtract(8, "days"),
        moment().subtract(1, "days"),
      ],
      selectDate: "",
      offsetDays: 86400000 * 99, //最多选择100天
      disabledDate(current) {
        return current >= moment().startOf("month").subtract("month", -1);
      },
      monthStartTime: "",
      monthEndTime: "",
      monthYear: "",
      monthNumber: "",

      weekStartTime: "",
      weekEndTime: "",
      weekYear: "",
      weekNumber: "",

      dayStartTime: "",
      dayEndTime: "",
    };
  },
  mounted() {
    // 月 初始化
    this.monthStartTime = this.setStartAllTime(
      this.searchMonthTime.startOf("month")
    );
    this.monthEndTime = this.setEndAllTime(this.searchMonthTime.endOf("month"));
    this.monthYear = moment(this.monthStartTime).format("YYYY");
    this.monthNumber = moment(this.monthStartTime).format("M");
    // 周 初始化
    this.weekStartTime = this.setStartAllTime(this.searchWeekTime.day(1));
    this.weekEndTime = this.setEndAllTime(this.searchWeekTime.day(7));
    this.weekYear = moment(this.searchWeekTime).format("YYYY");
    this.weekNumber = moment(this.searchWeekTime).format("wo").split("周")[0];
    // 日 初始化
    this.dayStartTime = this.setStartAllTime(this.searchDayTime[0]);
    this.dayEndTime = this.setEndAllTime(this.searchDayTime[1]);
  },
  computed: {},
  watch: {},
  methods: {
    setStartAllTime(time) {
      return time.format("YYYY-MM-DD") + " 00:00:00";
    },
    setEndAllTime(time) {
      return time.format("YYYY-MM-DD") + " 23:59:59";
    },
    //选择完时间 清空限制
    calendarPriceRangeChange(date) {
      this.selectDate = date[0];
    },
    disabledDate2(current) {
      if (this.selectDate) {
        let selectV = moment(this.selectDate, "YYYY-MM-DD").valueOf();
        return (
          current > moment().endOf("day").subtract(1, "days") ||
          current > moment(new Date(selectV + this.offsetDays), "YYYY-MM-DD") ||
          current < moment(new Date(selectV - this.offsetDays), "YYYY-MM-DD")
        );
      } else {
        return current > moment().endOf("day").subtract(1, "days");
      }
    },
    // 日期切换
    onChangeMonth(date, dateString) {
      if (!this.searchMonthTime) {
        this.searchMonthTime = moment().subtract(this.monthSubtract, "months");
      }

      this.monthStartTime = this.setStartAllTime(
        this.searchMonthTime.startOf("month")
      );
      this.monthEndTime = this.setEndAllTime(
        this.searchMonthTime.endOf("month")
      );
      this.monthYear = moment(this.monthStartTime).format("YYYY");
      this.monthNumber = moment(this.monthStartTime).format("M");
      this.emitMonthDate();
    },
    // 日期切换
    onChangeWeek(date, dateString) {
      if (date) {
        this.weekStartTime = this.setStartAllTime(this.searchWeekTime.day(1));
        this.weekEndTime = this.setEndAllTime(this.searchWeekTime.day(7));
        this.weekYear = moment(date).format("YYYY");
        this.weekNumber = moment(date).format("wo").split("周")[0];
        this.emitWeekDate();
      } else {
        this.searchWeekTime = moment().subtract(1, "months");
        // this.$message.warning("禁止取消，默认查询上月数据");
      }
    },
    onChangeDay(date) {
      if (date.length == 0) {
        this.searchDayTime = [
          moment().subtract(8, "days"),
          moment().subtract(1, "days"),
        ];
      }
      this.selectDate = "";
      this.dayStartTime = this.setStartAllTime(this.searchDayTime[0]);
      this.dayEndTime = this.setEndAllTime(this.searchDayTime[1]);
      this.emitDayDate();
    },
    changeType(type) {
      this.$emit("changeType", type);

      if (type === "month") {
        this.emitMonthDate(type);
      } else if (type === "week") {
        this.emitWeekDate(type);
      } else {
        this.emitDayDate(type);
      }
    },
    emitMonthDate(type) {
      this.$emit("changeDate", {
        startTime: this.monthStartTime,
        endTime: this.monthEndTime,
        monthNumber: this.monthNumber,
        monthYear: this.monthYear,
        type: type || this.type,
      });
    },
    emitWeekDate(type) {
      this.$emit("changeDate", {
        startTime: this.weekStartTime,
        endTime: this.weekEndTime,
        weekNumber: this.weekNumber,
        weekYear: this.weekYear,
        type: type || this.type,
      });
    },
    emitDayDate(type) {
      this.$emit("changeDate", {
        startTime: this.dayStartTime,
        endTime: this.dayEndTime,
        type: type || this.type,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.date-container {
  width: 407px;
  height: 40px;
  // border: 1px solid rgba(0, 0, 0, 0);
  // @include border_color("border_color1");
  @include background_color("background_color75");
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  border-radius: 8px;
  > div {
    width: 60px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.5s;
    display: flex;
    overflow: hidden;
    .label {
      height: 40px;
      width: 60px;
      min-width: 60px;
      display: inline-block;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      @include font_color("font_color42");
      cursor: pointer;
    }
  }
  > div.active {
    width: 287px;
    @include background_color("background_color34");
    // @include border_color("border_color1");
    .label {
      @include font_color("font_color41");
    }
  }
  > div.active:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0);
  }
  > div.active:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0);
  }
}
</style>
