<template>
  <div class="date-container">
    <div :class="type == 'month' ? 'active' : ''">
      <span class="label" @click="changeType('month')">按月</span>
      <a-month-picker
        v-model="searchMonthTime"
        placeholder="Select month"
        :disabled-date="disabledDate"
        @change="onChangeMonth"
        locale="zh-cn"
        style="width: 190px; margin-left: 7px"
      />
    </div>
    <div :class="type == 'week' ? 'active' : ''">
      <span class="label" @click="changeType('week')">按周</span>
      <a-week-picker
        v-model="searchWeekTime"
        placeholder="Select week"
        @change="onChangeWeek"
        locale="zh-cn"
        style="width: 190px; margin-left: 7px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DateTab",
  props: {
    type: {
      type: String,
      defult: "month",
    },
  },
  data() {
    return {
      searchMonthTime: moment().subtract(1,'months'),
      searchWeekTime: moment().subtract(1,'months'),
      disabledDate(current) {
        return current && current >= moment().endOf("day");
      },
      monthStartTime: "",
      monthEndTime: "",
      weekStartTime: "",
      weekEndTime: "",
    };
  },
  created() {
    this.monthStartTime =
      this.searchMonthTime.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
    this.monthEndTime =
      this.searchMonthTime.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

    this.weekStartTime =
      this.searchWeekTime.day(1).format("YYYY-MM-DD") + " 00:00:00";
    this.weekEndTime =
      this.searchWeekTime.day(7).format("YYYY-MM-DD") + " 23:59:59";
  },
  computed: {},
  watch: {},
  methods: {
    // 日期切换
    onChangeMonth(date, dateString) {
      if (date) {
        this.monthStartTime =
          this.searchMonthTime.startOf("month").format("YYYY-MM-DD") +
          " 00:00:00";
        this.monthEndTime =
          this.searchMonthTime.endOf("month").format("YYYY-MM-DD") + " 23:59:59";
        this.$emit("changeDate");
      } else {
        this.searchMonthTime = moment().subtract(1,'months')
        this.$message.warning('禁止取消，默认查询上月数据')
      }
    },
    // 日期切换
    onChangeWeek(date, dateString) {
      if (date) {
        this.weekStartTime =
          this.searchWeekTime.day(1).format("YYYY-MM-DD") + " 00:00:00";
        this.weekEndTime =
          this.searchWeekTime.day(7).format("YYYY-MM-DD") + " 23:59:59";
        this.$emit("changeDate");
      } else {
        this.searchWeekTime = moment().subtract(1,'months')
        this.$message.warning('禁止取消，默认查询上月数据')
      }
    },
    changeType(type) {
      this.$emit("changeType", type);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.date-container {
  width: 317px;
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0);
  @include border_color("border_color1");
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  > div {
    width: 60px;
    height: 36px;
    border-radius: 4px;
    transition: all 0.5s;
    display: flex;
    overflow: hidden;
    .label {
      height: 36px;
      width: 60px;
      min-width: 60px;
      display: inline-block;
      font-size: 14px;
      text-align: center;
      line-height: 36px;
      @include font_color("font_color3");
      cursor: pointer;
    }
  }
  > div.active {
    width: 257px;
    @include background_color("background_color4");
    @include border_color("border_color1");
    .label {
      @include font_color("font_color1");
    }
  }
  > div.active:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0);
  }
  > div.active:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0);
  }
}
</style>
