<template>
  <div class="date-container">
    <div :class="type == 'week' ? 'active' : ''">
      <span class="label" @click="changeType('week')">按周</span>
      <a-week-picker
        v-model="searchWeekTime"
        placeholder="Select week"
        class="aft-range-picker default"
        :disabled-date="disabledDate"
        @change="onChangeWeek"
        locale="zh-cn"
        style="width: 220px; margin-left: 7px"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-week-picker>
    </div>
    <div :class="type == 'day' ? 'active' : ''">
      <span class="label" @click="changeType('day')">自定义</span>
      <a-range-picker
        v-model="searchDayTime"
        @calendarChange="calendarPriceRangeChange"
        class="aft-range-picker default"
        :disabled-date="disabledDate2"
        @change="onChangeDay"
        locale="zh-cn"
        format="YYYY-MM-DD"
        style="width: 220px; margin-left: 7px"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-range-picker>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateTab2",
  props: {
    type: {
      type: String,
      defult: "month",
    },
  },
  data() {
    return {
      searchMonthTime: moment().subtract(1, "months"),
      searchWeekTime: moment().subtract(1, "months"),
      searchDayTime: [
        moment().subtract(8, "days"),
        moment().subtract(1, "days"),
      ],
      offsetDays: 86400000 * 100, //最多选择100天
      disabledDate(current) {
        return current && current >= moment().startOf('week');
      },
      monthStartTime: "",
      monthEndTime: "",
      weekStartTime: "",
      weekEndTime: "",
      dayStartTime: "",
      dayEndTime: "",
    };
  },
  created() {
    this.monthStartTime =
      this.searchMonthTime.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
    this.monthEndTime =
      this.searchMonthTime.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

    this.weekStartTime =
      this.searchWeekTime.day(1).format("YYYY-MM-DD") + " 00:00:00";
    this.weekEndTime =
      this.searchWeekTime.day(7).format("YYYY-MM-DD") + " 23:59:59";

    this.dayStartTime =
      this.searchDayTime[0].format("YYYY-MM-DD") + " 00:00:00";
    this.dayEndTime = this.searchDayTime[1].format("YYYY-MM-DD") + " 23:59:59";
  },
  computed: {},
  watch: {},
  methods: {
    //选择完时间 清空限制
    calendarPriceRangeChange(date) {
      this.searchDayTime[0] = date[0];
    },
    disabledDate2(current) {
      if (this.searchDayTime[0]) {
        let selectV = moment(this.searchDayTime[0], "YYYY-MM-DD").valueOf();
        return (
          current > moment(new Date(selectV + this.offsetDays), "YYYY-MM-DD") ||
          current < moment(new Date(selectV - this.offsetDays), "YYYY-MM-DD")
        );
      } else {
        return false;
      }
    },
    // 日期切换
    onChangeWeek(date, dateString) {
      console.log(1, this.searchWeekTime);
      if (!this.searchWeekTime) {
        this.searchWeekTime = moment().subtract(1, "months");
      }
      console.log(this.searchWeekTime.startOf("week").format("YYYY-MM-DD"));
      console.log(this.searchWeekTime.endOf("week").format("YYYY-MM-DD"));
      this.weekStartTime =
        this.searchWeekTime.startOf("week").format("YYYY-MM-DD") +
        " 00:00:00";
      this.weekEndTime =
        this.searchWeekTime.endOf("week").format("YYYY-MM-DD") + " 23:59:59";
      this.$emit("changeDate");
    },
    onChangeDay(date) {
      if (date.length == 0) {
        this.searchDayTime = [
          moment().subtract(8, "days"),
          moment().subtract(1, "days"),
        ];
      }
      this.dayStartTime =
        this.searchDayTime[0].format("YYYY-MM-DD") + " 00:00:00";
      this.dayEndTime =
        this.searchDayTime[1].format("YYYY-MM-DD") + " 23:59:59";
      this.$emit("changeDate");
    },
    changeType(type) {
      this.$emit("changeType", type);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.date-container {
  width: 347px;
  height: 40px;
  // border: 1px solid rgba(0, 0, 0, 0);
  // @include border_color("border_color1");
  @include background_color("background_color75");
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  border-radius: 8px;
  > div {
    width: 60px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.5s;
    display: flex;
    overflow: hidden;
    .label {
      height: 40px;
      width: 60px;
      min-width: 60px;
      display: inline-block;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      @include font_color("font_color42");
      cursor: pointer;
    }
  }
  > div.active {
    width: 287px;
    @include background_color("background_color34");
    // @include border_color("border_color1");
    .label {
      @include font_color("font_color41");
    }
  }
  > div.active:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0);
  }
  > div.active:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0);
  }
}
</style>
