<template>
  <div class="container">
    <div class="second-header">
      <div>
        <span class="back-btn" @click="goBack">
          <a-icon type="left" />
        </span>
        <span class="title">活动榜单 · {{ $route.query.aName }}</span>
      </div>
      <div>
        <platform-header
          title=""
          ref="PlatformHeader"
          :tabData="tabData"
          @tabChange="tabChange"
        />
      </div>
    </div>
    <div class="tabs-div">
      <NewTabs :tabData="tabsData" @tabChange="changeTabs" />
      <div>
        <span class="date-label">活动日期：</span>
        <span class="date-value">{{ $route.query.dateStr }}</span>
      </div>
    </div>
    <div class="tools">
      <div class="left">
        <div style="display: flex">
          <a-select
            class="aft-select default"
            v-model="regionId"
            placeholder="选择大区"
            style="width: 122px; margin-right: 8px"
            @change="regionChange"
            v-if="tabsKey != '3'"
          >
            <a-select-option
              v-for="item in areaList || []"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-select
            class="aft-select default"
            v-model="storeValue"
            placeholder="选择门店"
            style="width: 122px"
            @change="storeChange"
            v-if="tabsKey == '0'"
          >
            <a-select-option value="">全部门店</a-select-option>
            <a-select-option value="1">零产出门店</a-select-option>
          </a-select>
        </div>
      </div>
      <div>
        <a-range-picker
          v-model="dateValue"
          @calendarChange="calendarChange"
          class="aft-range-picker default"
          :disabled-date="disabledDate"
          @change="dateChange"
          locale="zh-cn"
          format="YYYY-MM-DD"
          style="width: 220px; margin-right: 8px"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-range-picker>
        <SwitchPopover
          style="margin-right: 8px"
          :settingData="settingData"
          @colsePopover="changeSwitchPopover"
          @changeSwitch="changeSwitchPopover"
        />
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button class="button2" @click="exportClick">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>
    <div class="content-table">
      <a-table
        :columns="columns"
        :scroll="{ y: 'calc(100vh - 380px)' }"
        :data-source="tableData"
        :rowKey="(data) => data.dealer_id"
        :pagination="false"
        class="aft-table default"
        :locale="locale"
        :loading="tableLoading"
        @change="tableChange"
      >
        <div slot="customTitle">
          <span>{{ currentTab | returnWatchText }}</span>
        </div>
        <div slot="sort" slot-scope="text, records, index" class="table-sort">
          <span>{{ index + 1 + (current - 1) * pageSize }}</span>
        </div>
        <div slot="dealer_name" slot-scope="text, records" class="table-dealer">
          <div>{{ records.dealer_name }}</div>
          <div>{{ records.dealer_company_name }}</div>
        </div>
        <div slot="watch_count" slot-scope="text, records">
          <div v-if="currentTab == 'kuaishou'">
            {{ records.statistics_total_watch_user_count | changeNum }}
          </div>
          <div v-else>
            {{ records.statistics_total_watch_user_time | changeNum }}
          </div>
        </div>
      </a-table>
    </div>

    <div class="page-div aft-pagination">
      <span class="total-text"
        >共 {{ total || 0 }} 项数据，数据统计截止时间：{{
          getUpdateTime()
        }}</span
      >
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import _ from "lodash";
import exportTable from "@/utils/exportTable";

import Empty from "@/components/Empty/Empty";
import NewTabs from "@/components/PlatformTab/NewTabs";
import SwitchPopover from "@/components/SwitchPopover/SwitchPopover";
import deepClone from "@/utils/clone-utils";
import api from "@/api/activityList.js";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";

import { settingData, columnsList, apiList, exportApiList } from "./constant";
export default {
  components: {
    Empty,
    NewTabs,
    SwitchPopover,
    ExcelTypeButton,
    PlatformHeader,
  },
  data() {
    return {
      tabsData: [
        {
          label: "参与门店",
          value: "0",
        },
        {
          label: "短视频",
          value: "1",
        },
        {
          label: "直播",
          value: "2",
        },
        {
          label: "大区排名",
          value: "3",
        },
      ],
      tabsKey: "0",
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
      ],
      excelType: false,
      settingData: settingData[0],
      currentTab: this.$route.query.platform,
      activity_id: undefined,
      columns: columnsList[0],
      sort_field: "",
      sort: "",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      tableData: [],
      tableLoading: false,
      current: 1,
      pageSize: 20,
      total: 0,
      groupValue: "",
      areaList: [],
      regionId: undefined,
      storeValue: undefined,
      dateValue: [
        moment(this.$route.query.dateStr.split("~")[0]),
        moment(this.$route.query.dateStr.split("~")[1]),
      ],
      settingType: "",
    };
  },
  async created() {
    Promise.all([
      api.getAreaList(),
      api.getActivityId({
        activity_id: this.$route.query.aId,
      }),
    ]).then((res) => {
      console.log(res);
      if (res[0] && res[0].length > 0) {
        this.areaList = res[0];
        // 默认选中第一个
        this.regionId = res[0][0].id;
        if (this.$route.query.rId) {
          this.regionId = this.$route.query.rId
        }
      } else {
        this.areaList = [];
        this.$message.warning("当前账号未绑定大区，请联系管理员！");
        return false;
      }
      if (res[1].code == 0) {
        this.idData = res[1].data;
      } else {
        this.$message.warning("获取活动id失败！");
        return false;
      }

      this.$refs.PlatformHeader.tabClick(this.$route.query.platform);
    });
  },
  mounted() {},
  methods: {
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      this.getTableList(true);
    },
    async getTableList(isFirst) {
      if (isFirst) {
        this.current = 1;
      }
      const requestData = this.getRequestData();
      if (!requestData) {
        return false
      }
      this.tableLoading = true;
      const { code, data, msg } = await api[apiList[this.tabsKey]](requestData);
      this.tableLoading = false;
      if (code == 0) {
        if (this.tabsKey == "3") {
          this.tableData = data || [];
          this.total = data.length;
        } else {
          this.tableData = data.list || [];
          this.total = data.total || 0;
        }
      } else {
        this.tableData = [];
        this.total = 0;
        this.$message.warning(msg || "获取列表失败");
      }
    },
    // 获取表格请求参数
    getRequestData() {
      let activity_id =
        this.currentTab == "douyin"
          ? this.idData.dy_id
          : this.currentTab == "kuaishou"
          ? this.idData.ks_id
          : this.dcd_id;
      if (!activity_id) {
        this.tableData = [];
        this.total = 0;
        let platform_name =
          this.currentTab == "douyin"
            ? "抖音"
            : this.currentTab == "kuaishou"
            ? "快手"
            : "懂车帝";
        this.$message.warning(
          `当前活动不统计${platform_name}平台数据`
        );
        return false;
      }
      let requestData = {
        platform: this.currentTab,
        params: {
          activity_id: activity_id,
          start_time:
            moment(this.dateValue[0]).format("YYYY-MM-DD") + " 00:00:00",
          end_time:
            moment(this.dateValue[1]).format("YYYY-MM-DD") + " 23:59:59",
          type: this.settingType,
          current: this.current,
          size: this.pageSize,
        },
      };
      if (this.tabsKey == "0") {
        requestData.params.dealer_type = this.storeValue || "";
        requestData.params.region_id = this.regionId || "";
      }
      if (this.tabsKey == "1") {
        requestData.params.region_id = this.regionId || "";
      }
      if (this.tabsKey == "2") {
        requestData.params.region_id = this.regionId || "";
      }
      if (this.tabsKey == "3") {
        requestData.params.sort_field = this.sort_field;
        requestData.params.sort = this.sort;
      }
      return requestData;
    },
    setSettingType(arr) {
      const newArr = arr.reduce((acc, cur) => {
        if (cur.bool) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
      this.settingType = newArr.join(",");
    },
    // tabs切换
    changeTabs(val) {
      this.tabsKey = val;
      this.columns = deepClone(columnsList[val]);
      this.settingData = deepClone(settingData[val]);
      this.settingType = "";
      this.getTableList(true);
    },
    // 大区选择
    regionChange() {
      this.getTableList(true);
    },
    // 切换门店
    storeChange(val) {
      this.getTableList(true);
    },
    // 内容显示设置切换
    changeSwitchPopover(value) {
      console.log("changeSwitchPopover", value);
      // 给settingType赋值
      this.setSettingType(value);
      this.getTableList(true);
    },
    // 表格排序更改触发
    tableChange(pagination, filters, sorter) {
      console.log("sorter", sorter);
      console.log(sorter.field);
      console.log(sorter.order);
      if (sorter.order) {
        this.sort_field = sorter.field;
        // 快手展示观看人数特殊处理
        if (sorter.field == "watch_count") {
          this.sort_field =
            this.currentTab == "kuaishou"
              ? "statistics_total_watch_user_count"
              : "statistics_total_watch_user_time";
        }
        this.sort = sorter.order == "ascend" ? "asc" : "desc";
      } else {
        this.sort_field = "";
        this.sort = "";
      }
      this.getTableList();
    },
    // 考核-分页
    pageChange() {
      this.getTableList();
    },
    // 返回
    goBack() {
      let path = '/activityManagement'
      if (this.$route.query.reject) {
        path = this.$route.query.reject
      }
      this.$router.push({
        path: path,
      });
    },
    // 日期切换
    calendarChange(date) {
      this.dateValue[0] = date[0];
    },
    dateChange(date) {
      if (date.length == 0) {
        this.dateValue = [
          moment(this.$route.query.dateStr.split("~")[0]),
          moment(this.$route.query.dateStr.split("~")[1]),
        ];
      }
      this.getTableList(true);
    },
    disabledDate(current) {
      return (
        current < moment(this.$route.query.dateStr.split("~")[0]) ||
        current > moment(this.$route.query.dateStr.split("~")[1])
      );
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    // 导出
    exportClick() {
      const requestData = this.getRequestData();
      exportTable(
        this.$http,
        {
          ...requestData.params,
          flag: this.excelType ? 1 : 0,
        },
        requestData.platform,
        exportApiList[this.tabsKey],
        "filename"
      );
    },
    getUpdateTime() {
      return moment().subtract(1, "day").format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .second-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 0 0 20px;
    > div {
      display: flex;
      align-items: center;
      .back-btn {
        width: 40px;
        height: 40px;
        background: #2b2f3b;
        border-radius: 9px;
        margin-right: 12px;
        color: #9ca8ba;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .tabs-div {
    padding: 0 24px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date-label {
      font-size: 14px;
      color: #8890a6;
    }
    .date-value {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
    }
  }
  .tools {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    > div {
      display: flex;
      align-items: center;
      .button {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 24px;
        font-size: 14px;
        margin-right: 15px;
        @include font_color("font_color22");
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 2px;
          height: 24px;
          @include background_color("background_color62");
        }
        .icon {
          margin-right: 6px;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          display: block;
          overflow: hidden;
        }
        &:hover .icon {
          @include background_color("background_color2");
        }
        .arrow-right {
          font-size: 22px;
          transform: rotate(180deg);
        }
      }
      .search-btn {
        @include border_color("border_color4");
        @include font_color("font_color17");
        @include background_color("background_color13");
        width: 80px;
        height: 36px;
        border-radius: 8px;
      }

      .button2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 113px;
        height: 40px;
        border-radius: 8px;
        line-height: 40px;
        font-size: 14px;
        border: none;
        @include font_color("font_color1");
        @include background_color("background_color63");
        .btn-icon {
          font-size: 18px;
          margin-right: 7px;
          @include font_color("font_color34");
        }
        &:hover .btn-icon {
          @include font_color("font_color35");
        }
      }
    }
  }
  .content {
    height: calc(100vh - 320px);
    padding: 0 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
    .first-list {
      width: 92px;
      min-width: 92px;
      margin-right: 13px;
    }
    .first-list-item {
      font-size: 14px;
      @include font_color("font_color2");
      height: 68px;
      line-height: 68px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
    }
    .other-list {
      width: 200px;
      margin-right: 13px;
    }
    .other-title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .activity-first-list {
      width: 92px;
      min-width: 92px;
    }
    .activity-first-list.dealer-list {
      width: 150px;
    }
    .activity-first-list-item {
      font-size: 14px;
      @include font_color("font_color2");
      height: 68px;
      line-height: 68px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
    }
    .list-bg {
      // cursor: pointer;
      // -webkit-transition: all 0.3s;
      // transition: all 0.3s;
      @include background_color("background_color2");
    }
    // .list-bg:hover,
    // .list-bg.active {
    //   @include background_color("background_color42");
    //   .list-item > span {
    //     @include font_color("font_color6");
    //     font-weight: bold;
    //   }
    // }
    .list-title {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      font-size: 14px;
      @include font_color("font_color1");
      height: 52px;
      line-height: 52px;
      @include border_color("border_color5");
    }
    .sort-div {
      cursor: pointer;
      width: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      .default-color {
        @include font_color("font_color21");
      }
      .active-color {
        @include font_color("font_color26");
      }
    }
    .list-item {
      width: 100%;
      min-width: 170px;
      padding: 0 36px;
      height: 68px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color5");
      > span {
        line-height: 87px;
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .content-table {
    width: calc(100% - 40px);
    height: calc(100vh - 320px);
    margin-left: 20px;
    background: #2b2f3b;
    border-radius: 16px;
    .table-sort {
      height: 58px;
      line-height: 58px;
    }
    .table-dealer {
      display: flex;
      flex-direction: column;
      > div {
        height: 22px;
        font-size: 14px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        padding-right: 40px;
        &:first-child {
          font-weight: bold;
          color: rgba(255, 255, 255, 0.85);
        }
        &:last-child {
          color: rgba(255, 255, 255, 0.45);
        }
      }
    }
  }

  .page-div {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .total-text {
      font-size: 14px;
      color: #9ca8ba;
    }
  }

  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .font-color22 {
    @include font_color("font_color22");
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
}
</style>