<template>
  <div class="live-detail-card">
    <div class="title-box">
      <a-tooltip>
        <template slot="title">{{ liveDetail.title }}</template>
        <div class="title">
          {{ liveDetail.title }}
        </div>
      </a-tooltip>
      <div style="display: flex">
        <WebpImage
          :src="liveDetail.avatar"
          :width="'22px'"
          :height="'22px'"
          :radius="'50%'"
          style="margin-right: 10px"
        />
        <a-tooltip>
          <template slot="title">{{ liveDetail.nickname }}</template>
          <span class="nickname ellipsis-1">{{ liveDetail.nickname }}</span>
        </a-tooltip>
      </div>
    </div>
    <div class="top">
      <div class="img-div">
        <WebpImage
          :src="liveDetail ? liveDetail.cover : null"
          :width="'72px'"
          :height="'72px'"
          :radius="'0px'"
        />
      </div>
      <div class="mask-play" @click="openLive(liveDetail)">
        <svg-icon
          v-if="!liveDetail.play_url"
          :icon-class="'no-video'"
          class="lock-icon"
        />
        <svg-icon
          v-if="
            liveDetail.play_url &&
            liveDetail.url_state == ('Archive' || 'Unknown') &&
            liveDetail.is_restore_completed == '1'
          "
          :icon-class="'unlock'"
          class="lock-icon"
        />
        <svg-icon
          v-else-if="
            liveDetail.play_url &&
            liveDetail.url_state == ('Archive' || 'Unknown') &&
            liveDetail.is_restore_completed == ('0' || '3')
          "
          :icon-class="'lock'"
          class="lock-icon"
        />
        <svg-icon
          v-else-if="liveDetail.play_url"
          :icon-class="'play'"
          class="mask-icon"
        />
      </div>
      <div class="row-main">
        <div class="row">
          <span class="row-main-label"><i>平</i><i>台: </i></span
          ><span class="row-main-txt">{{ liveDetail.plat_form }}</span>
        </div>
        <div class="row">
          <span class="row-main-label"><i>日</i><i>期: </i></span
          ><span class="row-main-txt">{{ liveDetail.full_date }}</span>
        </div>
        <div class="row">
          <span class="row-main-label"
            ><i>开</i><i>播</i><i>时</i><i>间: </i></span
          ><span class="row-main-txt">{{ liveDetail.new_publish_time }}</span>
        </div>
        <div class="row">
          <span class="row-main-label"
            ><i>下</i><i>播</i><i>时</i><i>间: </i></span
          ><span class="row-main-txt">{{ liveDetail.new_finish_time }}</span>
        </div>
      </div>
    </div>
    <div class="card-detail">
      <div class="lock-div">
        <div class="btn3" v-if="!liveDetail.play_url">暂无视频</div>
        <a-button
          type="primary"
          class="btn1"
          @click="unlockClick"
          v-if="
            liveDetail.play_url &&
            liveDetail.url_state == ('Archive' || 'Unknown') &&
            liveDetail.is_restore_completed == ('0' || '3')
          "
          :loading="lockLoading"
        >
          解冻
        </a-button>
        <div
          class="btn3"
          v-if="
            liveDetail.play_url &&
            liveDetail.url_state == ('Archive' || 'Unknown') &&
            liveDetail.is_restore_completed == '1'
          "
        >
          解冻中
        </div>
        <div class="btn2" @click="toRecord">解冻记录</div>
      </div>
      <div class="count-div">
        <div
          class="count-item"
          v-for="(item, index) in liveDetail.count_data"
          :key="index"
        >
          <span>
            {{ item.title }}
          </span>
          <span class="important3" v-if="item.title == '直播时长'">{{
            item.number | formatDuration
          }}</span>
          <!-- <span
                  class="important3"
                  v-else-if="item.title == '人均观看时长'"
                  >{{ item.number | formatDuration2 }}</span
                > -->
          <span class="important3" v-else-if="item.title == '平均在线'">{{
            (item.number == null ? "-" : Number(item.number)) | changeNum2
          }}</span>
          <span class="important3" v-else>{{
            (item.number == null ? "-" : Number(item.number)) | changeNum
          }}</span>
        </div>
      </div>
    </div>
    <a-modal
      v-model="dialogVisible"
      ref="modal"
      title="直播回放"
      @cancel="handleClose"
      :footer="null"
    >
      <video
        id="videoElement"
        autoplay
        controls
        muted
        style="width: 100%; height: 100%; border-radius: 10px"
      ></video>
    </a-modal>
  </div>
</template>
<script>
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import WebpImage from "@/components/WebpImage/WebpImage";
import api from "@/api/globalDetail";
export default {
  components: { WebpImage },
  props: {
    liveDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      flvPlayer: null,
      dialogVisible: false,
      lockLoading: false, // 解冻按钮loading状态
    };
  },
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatDuration2(number) {
      if (!number || number === 0) {
        return "0分0秒";
      } else {
        let m = (number / 60) | 0;
        let s = number % 60;
        return `${m}分${s}秒`;
      }
    },
  },
  computed: {},
  watch: {},
  methods: {
    // 点击直播回放按钮
    openLive(detail, time = 0) {
      console.log(detail);
      if (detail.play_url) {
        if (detail.url_state == "Archive" || detail.url_state == "Unknown") {
          if (
            detail.is_restore_completed == "0" ||
            detail.is_restore_completed == "3"
          ) {
            this.$message.warning(
              "直播视频已被冻结,可点击解冻按钮解冻 ( 预计1分钟解冻 )"
            );
            return false;
          } else if (detail.is_restore_completed == "1") {
            this.$message.warning(
              "解冻中,可点击解冻记录查看进度 ( 24h 后冻结 )"
            );
            return false;
          }
        }
        this.dialogVisible = true;
        console.log(detail.play_url);
        this.getStream(detail.play_url, time);
      } else {
        this.$message.warning("暂无该直播视频，请联系管理员...");
      }
    },
    // 初始化flv
    getStream(source, time = 0) {
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          console.log(1, source);
          this.flvPlayer = flvjs.createPlayer({
            type: source.indexOf(".mp4") > 0 ? "mp4" : "flv",
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.currentTime = Number(time) || 0
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            this.handleClose();
            this.$message.warning("暂无该直播视频，请联系管理员...");
          });
        }
      });
    },
    // 关闭 flv 视频弹窗事件
    handleClose() {
      this.dialogVisible = false;
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
      }
    },
    unlockClick() {
      if (this.lockLoading) {
        return false;
      }
      console.log(this.liveDetail);
      // 调用解冻接口
      this.lockLoading = true;
      let body = {
        room_id: this.$route.query.id,
        platform: this.$route.query.platform,
      };
      api.updeteRetore(body).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$message.success("解冻成功");
          this.lockLoading = false;
          this.getLiveDetail(body);
        } else {
          this.$message.error("解冻失败");
          this.lockLoading = false;
        }
      });
    },
    toRecord() {
      let routeUrl = this.$router.resolve({
        path: `/liveAndVideo/thawingRecord`,
        query: {
          platform: this.$route.query.platform,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    openLiveByTime(time) {
      this.openLive(this.liveDetail, time)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.live-detail-card {
  padding: 20px;
  width: 100%;
  height: 100%;
  @include background_color("background_color80");
  border-radius: 16px;
  .title-box {
    .title {
      font-size: 16px;
      font-weight: bold;
      @include font_color("font_color20");
      line-height: 22px;
      margin-bottom: 12px;
    }
    .nickname {
      @include font_color("font_color22");
      width: calc(100% - 32px);
      margin-bottom: 29px;
    }
    .ellipsis-1 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      @include font_color("font_color22");
    }
  }
  .top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 20px;
    position: relative;

    .mask-play {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 96px;
      cursor: pointer;
      @include background_color("background_color20");
      position: absolute;
      top: 0px;
      left: 0px;
      .mask-icon {
        @include font_color("font_color1");
        font-size: 40px;
      }
      .lock-icon {
        font-size: 20px;
      }
    }
    .row-main {
      width: 165px;
      .row {
        display: flex;
      }
      &-label {
        margin-right: 8px;
        display: flex;
        justify-content: space-between;
        width: 62px;
        @include font_color("font_color23");
        i {
          font-style: normal;
        }
      }
      &-txt {
        flex: 1;
        @include font_color("font_color20");
      }
    }
    > img {
      width: 72px;
      height: 96px;
      @include border_color("border_color7");
      margin-right: 8px;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }

    > .img-div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 96px;
      @include background_color("background_color51");
      margin-right: 30px;
      border-radius: 8px;
      > img {
        width: 72px;
        max-height: 96px;
      }
    }
  }
  .card-detail {
    padding: 10px 0 50px;
    line-height: 30px;
    .important {
      @include font_color("font_color4");
    }
    .important2 {
      @include font_color("font_color7");
    }
    .lock-div {
      display: flex;
      margin-top: 16px;
      > div,
      > .btn1 {
        width: 128px;
        height: 36px;
        border-radius: 8px;
        cursor: pointer;
        text-align: center;
        line-height: 36px;
        border: none;
      }
      .btn1 {
        @include font_color("font_color17");
        @include background_color("background_color34");
        margin-right: 12px;
      }
      .btn2 {
        @include font_color("font_color26");
        @include background_color("background_color79");
      }
      .btn3 {
        @include font_color("font_color23");
        @include background_color("background_color62");
        margin-right: 12px;
        cursor: not-allowed;
      }
    }
    .count-div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 40px;
      .count-item {
        display: flex;
        flex-direction: column;
        line-height: 20px;
        font-size: 12px;
        width: 50%;
        padding-left: 14px;
        margin-bottom: 36px;
        @include font_color("font_color3");
        .important3 {
          @include font_color("font_color20");
          font-size: 18px;
          font-weight: bold;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>