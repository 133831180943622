/** 
* tabData: 按钮组数据
* tabChange: 点击按钮触发的方法
* example: <platform-tab :tabData="tabData" @tabChange="tabChange"></platform-tab>
*/

<template>
  <div>
    <a-button
      v-for="(item, index) in tabData"
      :key="'btn' + index"
      round
      :class="currentClick == item.value ? 'active-btn-class btn-class' : 'btn-class'"
      :style="defaultBtnStyle"
      @click="tabClick(item.value)"
    >{{item.label}}</a-button>
  </div>
</template>

<script>
export default {
  name: "ButtonTab",
  props: {
    tabData: {
      type: Array,
    },
    defaultBtnStyle: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },
  data() {
    return {
      currentClick: this.tabData[0].value,
    };
  },
  created() {},
  computed: {},
  watch: {
  },
  methods: {
    tabClick(value) {
      this.currentClick = value;
      this.$emit("tabChange", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_handle.scss';
.btn-class.active-btn-class {
  @include background_color("background_color34");
  @include font_color("font_color17");
  font-weight: bold;
}
.btn-class {
  cursor: pointer;
  @include font_color("font_color22");
  min-width: 90px;
  height: 40px;
  line-height: 30px;
  padding: 0 13px;
  border-radius: 8px;
  border: none;
  background: #2B2F3B;
  margin-right: 10px;
}
.btn-class:last-child {
  margin-right: 0;
}
</style>
