var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"track",rawName:"v-track:click",value:({
    disabled: _vm.isDisabledTrack,
    action: '切换活动',
    activityId: _vm.data.id,
    activityName: _vm.data.activityName,
  }),expression:"{\n    disabled: isDisabledTrack,\n    action: '切换活动',\n    activityId: data.id,\n    activityName: data.activityName,\n  }",arg:"click"}],staticClass:"act-item",class:{ 'is-active': _vm.isActive },on:{"click":_vm.handleClick}},[_c('div',{staticClass:"act-item-name"},[_c('span',{staticClass:"act-tag",class:{
        'act-tag--1': _vm.data.activityStatus === 1,
        'act-tag--2': _vm.data.activityStatus === 2,
        'act-tag--3': _vm.data.activityStatus === 3,
      }},[_vm._v(" "+_vm._s(_vm._f("formatActivityStatus")(_vm.data.activityStatus))+" ")]),_c('span',{staticClass:"act-name"},[_vm._v(" "+_vm._s(_vm.data.activityName || "-")+" ")])]),_c('div',{staticClass:"act-item-time"},[_c('span',{staticClass:"act-time"},[_vm._v(" "+_vm._s(_vm.data.startTime)+" 至 "+_vm._s(_vm.data.endTime))])]),(_vm.isShowTaskRate && _vm.data.activityStatus !== 1)?_c('div',{staticClass:"act-rate-box"},[_c('span',{staticClass:"act-rate"},[_c('span',{staticClass:"act-rate-label"},[_vm._v("门店参与率")]),_c('span',{staticClass:"act-rate-value"},[_vm._v(_vm._s(_vm.data.participationRate)+"%")])]),(_vm.data.isTask)?_c('span',{staticClass:"act-time"},[_c('span',{staticClass:"act-rate-label"},[_vm._v("任务完成率")]),_c('span',{staticClass:"act-rate-value"},[_vm._v(_vm._s(_vm.data.completionRate)+"%")])]):_vm._e()]):_vm._e(),_c('svg-icon',{staticClass:"act-item-icon",attrs:{"icon-class":"right-selected-8px"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }