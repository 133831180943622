<template>
  <a-drawer
    placement="right"
    width="560"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>上传获奖名单</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <a-form-model
          :model="form"
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          ref="ruleForm"
          :rules="rules"
        >
          <a-form-model-item
            label="获奖名单"
            prop="fileList"
            style="position: relative; margin-top: 24px"
          >
            <a-upload
              :custom-request="customRequest"
              accept="image/png, image/jpg, image/jpeg"
              list-type="picture-card"
              :file-list="form.fileList"
              @preview="handlePreview"
              :before-upload="beforeUploadImg"
              @change="handleChange"
              class="aft-upload drawer"
            >
              <div v-if="form.fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传照片</div>
              </div>
            </a-upload>
            <span class="upload-label"
              >支持jpg/png/jpeg 格式，建议宽度大于1242</span
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-tools">
        <span class="btn btn1" @click="onClose">取消</span>
        <a-button
          class="btn btn2"
          @click="drawerSubmit"
          :loading="isAddGroupLoading"
          >确定</a-button
        >
      </div>
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-drawer>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import ossUploadMixin from "@/mixins/ossUploadMixin";
import api from "@/api/activityManagement";
import deepClone from "@/utils/clone-utils";
const defaultForm = {
  fileList: [],
};
export default {
  mixins: [ossUploadMixin],
  components: {},
  props: {},
  data() {
    return {
      visible: false,
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      form: deepClone(defaultForm),
      rules: {
        fileList: {
          required: true,
          message: "请上传获奖名单",
          trigger: "change",
        },
      },
      previewVisible: false,
      previewImage: "",
      isAddGroupLoading: false,
      currentItem: {},
      upload_file_params: {
        business_code: "jjt", //所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
        need_compress: 2, //是否需要压缩处理-针对图片(1:是  2:否  默认是)
        type: 1, //文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
      },
    };
  },
  created() {},
  methods: {
    showDrawer(currentItem) {
      this.currentItem = currentItem;

      console.log("this.currentItem", this.currentItem);
      let item = this.currentItem;
      this.form = {
        id: item.id,
        fileList: [],
      };
      if (item.awards_picture) {
        this.form.fileList = [
          {
            uid: "-1",
            name: "1.png",
            status: "done",
            url: item.awards_picture,
            response: { url: item.awards_picture },
          },
        ];
      }
      this.visible = true;
    },

    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      console.log("handleChange", fileList);
      this.form.fileList = fileList;
    },

    async customRequest({ file, onSuccess, onProgress }) {
      const formData = new FormData();
      formData.append("business_code", this.upload_file_params.business_code);
      formData.append("need_compress", this.upload_file_params.need_compress);
      formData.append("type", this.upload_file_params.type);
      formData.append("file", file);

      await this.normalUploadFile(file, onSuccess, formData);
    },
    beforeUploadImg(file) {
      const isJpgOrPng =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isJpgOrPng) {
        this.$message.error("上传的图片格式只能为jpg/png/jpeg!");
      }
      const isLt1M = file.size / 1024 / 1024 < 4;
      if (!isLt1M) {
        this.$message.error("图片格式不超过4M!");
      }
      if (!(isJpgOrPng && isLt1M)) {
        setTimeout(() => {
          this.form.fileList = [];
        }, 200);
      }
      return isJpgOrPng && isLt1M;
    },

    // 活动弹窗-取消
    onClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
      this.visible = false;
    },
    // 活动弹窗-确定
    async drawerSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        try {
          console.log(this.form);
          if (valid) {
            this.isAddGroupLoading = true;
            console.log(this.form);
            // 处理参数
            let body = {
              id: this.currentItem.id,
              awards_picture: this.form.fileList[0].response.url,
            };
            console.log(body);
            api
              .saveOrUpdateActivityV2(body)
              .then((res) => {
                console.log("saveOrUpdateActivityV2", res);
                if (res.data.code === 0) {
                  this.$message.success("提交成功");
                  this.onClose();
                  this.$emit("getList", false);
                } else if (res.data.code === 1) {
                  this.$message.error(res.data.msg || "提交失败");
                } else {
                  this.$message.error(res.message || "提交失败");
                }
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                this.isAddGroupLoading = false;
              });
          } else {
            this.$message.error("校验不通过,无法提交");
          }
        } catch (err) {
          console.error(err);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 116px);
    padding: 0 24px;
    overflow-y: auto;
    .upload-label {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.45);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 88px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.btn1 {
        background: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}
</style>