<template>
  <div
    :class="cardOpen ? 'card-container' : 'card-container default'"
    @mouseenter="onMouse('mouseenter')"
    @mouseleave="onMouse('mouseleave')"
  >
    <div
      class="left-card"
      :style="{
        background,
        color,
      }"
    >
      <span>{{ value }}</span>
      <span style="position: relative;z-index: 100;">
        <span>{{ name }}<AftTooltip v-if="tooltipCode" :code="tooltipCode" style="top: 2px; left: 4px" /></span>
        <!-- <CardTooltip class="tooltip-icon" v-if="tooltipTitle">
          <slot>{{ tooltipTitle }}</slot>
        </CardTooltip> -->
      </span>
      <svg-icon :icon-class="icon" class="icon" />
    </div>
    <div class="right-card">
      <div>
        <div>
          <span class="label">当{{ getOpenCardText(1) }}{{specialText()}}:</span>
          <span class="number" v-if="rightValue && rightValue.length > 0">{{
            rightValue[0]
          }}</span>
        </div>
        <div>
          <span class="spot"></span>
          <span>{{ dateString }}</span>
        </div>
      </div>
      <div>
        <span class="label">上{{ getOpenCardText(1) }}{{specialText()}}:</span>
        <span class="number" v-if="rightValue && rightValue.length > 0">{{
          rightValue[1]
        }}</span>
      </div>
      <div>
        <span class="label">环比增长:</span>
        <span
          :class="rightValue[3] == '-' ? 'negative-number' : 'positive-number'"
          v-if="rightValue && rightValue.length > 0"
          ><span v-if="rightValue[3] == '+'">{{ rightValue[3] }}</span
          >{{ rightValue[2] }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
// import CardTooltip from "@/components/Tooltip/CardTooltip";
export default {
  name: "AsyncCard",
  components: {
    // CardTooltip,
  },
  props: {
    background: {
      type: String,
      default: "#fff",
    },
    color: {
      type: String,
      default: "#000",
    },
    icon: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "-",
    },
    value: {
      default: "-",
    },

    isOpen: {
      type: Boolean,
      default: false,
    },
    rightValue: {
      type: Array,
      default: [],
    },
    tooltipTitle: {
      default: "",
    },
    tooltipCode: {
      default: "",
    },
    dateType: {
      type: String,
      default: "",
    },
    dateString: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      timer: null,
      cardOpen: this.isOpen,
    };
  },
  watch: {
    isOpen(val) {
      this.cardOpen = val;
    },
    dateType(val) {
      if (val == "day") {
        this.cardOpen = false;
      }
    },
  },
  methods: {
    onMouse(type) {
      if (this.dateType == "day") {
        return false;
      }
      // console.log(type);
      if (type == "mouseenter") {
        this.timer = setTimeout(() => {
          this.openCard();
          clearTimeout(this.timer);
        }, 300);
      } else {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    // 展开卡片
    openCard() {
      this.cardOpen = true;
      this.$emit("closeAllCard", this.name);
    },
    // 收起卡片
    closeCard() {
      this.cardOpen = false;
    },
    // 展开卡片文案展示
    getOpenCardText(type) {
      if (type == 1) {
        return this.dateType == "month"
          ? "月"
          : this.dateType == "week"
          ? "周"
          : "日";
      }
    },
    // 卡片显示特殊判断
    specialText() {
      if (this.name == "粉丝总量") {
        return "总量";
      } else {
        return "新增";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

.card-container {
  width: 353px;
  height: 72px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  display: flex;
  .left-card {
    width: 148px;
    min-width: 148px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px 0 14px 16px;
    font-weight: bold;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    > span:nth-child(1) {
      font-size: 18px;
    }
    > span:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > span {
        font-size: 12px;
        opacity: 0.6;
      }
    }
    .tooltip-icon {
      font-size: 16px;
    }
    .icon {
      transition: all 0.3s;
      position: absolute;
      left: 88px;
      bottom: 2px;
      font-size: 58px;
    }
  }
  .right-card {
    width: 205px;
    min-width: 205px;
    height: 100%;
    font-size: 12px;
    padding: 7px 7px 7px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #626a7e;
        .spot {
          width: 4px;
          height: 4px;
          background: #80dc1e;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
    }
    .label {
      color: #808c9e;
      margin-right: 7px;
    }
    .number {
      color: #d0d6e6;
    }
    .positive-number {
      @include font_color("font_color26");
    }
    .negative-number {
      @include font_color("font_color40");
    }
  }
}
.card-container.default {
  transition: all 0.3s;
  width: 148px;
  .left-card {
    background: linear-gradient(270deg, #1e222c 0%, #2a2d38 100%) !important;
    > span:nth-child(1) {
      color: #fff;
    }
    > span:nth-child(2) {
      color: #9ca8ba;
    }
    .tooltip-icon {
      color: #9ca8ba;
    }

    .icon {
      transform: translateX(60px);
      opacity: 0;
    }
  }
}
</style>
