<template>
  <PanleWrap>
    <div class="list_header">
      <div class="platform-div">
        <top-platform-tab
          :tabData="tabData"
          @tabChange="handlePlatformClick"
        ></top-platform-tab>
      </div>
      <h3>品牌直播榜</h3>
      <div class="tools">
        <Order :order-list="orderList" @order-click="hadnleOrderClick" />
        <div style="margin-left: 50px">
          <a-month-picker
            v-model="searchTime"
            placeholder="Select month"
            :disabled-date="pickerOptions"
            @change="onChange"
            locale="zh-cn"
          />
        </div>
      </div>
    </div>
    <a-spin :spinning="mixin_isSpinning">
      <div class="live_content clearfix">
        <template v-if="liveList.length > 0">

          <div
            class="live_item"
            v-for="item in liveList"
            :key="item.principal_id"
          >
          <div class="live_item_left" @click="handleItemClickLeft(item)">
            <div class="live_item_left_info">
              <img :src="`${OSS_HOST}${item.avatar}`" alt="" />
              <span>{{ item.principal_name || "暂无" }}</span>
            </div>
            <div class="live_item_left_count">
              <div>
                <span>互动量</span>
                <span class="number">{{ item.polling_live_interactive_count | changeNum }}</span>
              </div>
              <div>
                <div class="count-item">
                  <span>活跃账号</span>
                  <span class="number">{{ item.polling_author_count | changeNum }}</span>
                </div>
                <div class="count-item">
                  <span>观看人次</span>
                  <span class="number">{{ item.polling_look_person_count | changeNum }}</span>
                </div>
                <div class="count-item">
                  <span>直播场次</span>
                  <span class="number">{{ item.polling_live_count | changeNum }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="live_item_right" @click="handleItemClick(item)">
            <img :src="item.cover || ''" :alt="item.cover || '暂无封面图'" />
            <div class="live_item_right_info">
              <div class="title">{{ item.title || "暂无标题" }}</div>
              <div class="name">
                <img :src="item.author_avatar || ''" alt="" />
                <span>{{ item.nickname || "暂无名称" }}</span>
              </div>
              <div class="count-container">
                <div class="count-item">
                  <span>观看人次</span>
                  <span class="number color4">{{ item.look_person_count | changeNum }}</span>
                </div>
                <div class="count-item">
                  <span>开播</span>
                  <span class="number">{{ item.publish_time | formatPublishTime }}</span>
                </div>
                <div class="count-item">
                  <span>时长</span>
                  <span class="number">{{ item.duration | formatDuration }}</span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </template>
        <template v-if="mixin_isBizError || mixin_isNetworldError || liveList.length === 0">
          <div class="font-color3" style="text-align: center; width: 100%; padding-top: 20px">
            暂无数据
          </div>
        </template>
      </div>
    </a-spin>
    <div class="pagination_wrap">
      <a-pagination
        v-model="pagination.current"
        :total="pagination.total"
        :pageSize="pagination.size"
        size="small"
        show-less-items
        @change="handleCurrentPageChange"
      />
    </div>
  </PanleWrap>
</template>

<script>
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/brand.js";
import _ from "lodash";
import moment from "moment";
import mixin from "./mixins/mixin";
import Order from "./components/Order";
import Platform from "./components/Platform";
import TopPlatformTab from "@/components/PlatformTab/TopPlatformTab";

const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;
export default {
  components: {
    PanleWrap,
    Order,
    Platform,
    TopPlatformTab
  },
  mixins: [mixin],
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatPublishTime(str) {
      if (!str) {
        return "暂无时间";
      } else {
        return moment(str).format("YYYY/MM/DD");
      }
    },
  },
  data() {
    return {
      searchTime: moment().subtract(1,'months'),
      liveList: [],
      OSS_HOST,
      pagination: {
        size: 8,
        current: 1,
        total: 0,
      },
      startTime: "",
      endTime: "",
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
      ],
      platform: "douyin", // 平台(抖音:douyin,快手:kuaishou)
      sort: "pollingLiveInteractiveCount", // 排序类型(直播互动:pollingLiveInteractiveCount,活跃账号:pollingAuthorCount,观看人次:pollingLookPersonCount,直播场次:pollingLiveCount) ,
      orderList: [
        {
          text: "直播互动",
          value: "pollingLiveInteractiveCount",
        },
        {
          text: "活跃账号",
          value: "pollingAuthorCount",
        },
        {
          text: "观看人次",
          value: "pollingLookPersonCount",
        },
        {
          text: "直播场次",
          value: "pollingLiveCount",
        },
      ],
    };
  },
  methods: {
    getList() {},
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().endOf("month");
    },
    hadnleOrderClick(res) {
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.sort = res.data.value;
      this.getList();
    },
    handlePlatformClick(val) {
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.platform = val;
      this.getList();
    },
    handleCurrentPageChange(data) {
      this.pagination.current = data;
      this.getList();
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      let obj = this.calcStartEndTime(date);
      this.startTime = obj.startTime;
      this.endTime = obj.endTime;
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.getList();
    },
    calcStartEndTime(date) {
      let start = date.startOf("month").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("month").format("YYYY-MM-DD HH:mm:ss");
      return {
        startTime: start,
        endTime: end,
      };
    },
    handleItemClick(item) {
      console.log(item)
      if(item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${this.platform}`
        // this.$router.push(`/liveDetail?id=${item.room_id}`)
        window.open(url)
      } else {
        this.$message.error('暂无直播房间ID，无法查看详情')
      }
    },
    handleItemClickLeft(item) {
      let url = "/influenceDetail?principal_id=" + item.principal_id
      window.open(url)
    }
  },
  // todoD
  async created() {
    let timeObj = this.calcStartEndTime(moment().subtract(1,'months'));
    this.startTime = timeObj.startTime;
    this.endTime = timeObj.endTime;
    this.getList = this.requestDecorator(() => {
      const body = {
        current: this.pagination.current,
        size: this.pagination.size,
        startTime: this.startTime,
        endTime: this.endTime,
        platform: this.platform == 'douyin' ? 'dy' : 'ks',
        sort: this.sort,
      };
      return api.getBrandLiveList(body).then((res) => {
        this.liveList = _.get(res, "data.data.list", []);
        let pagination = {
          current: _.get(res, "data.data.pageNum", 1),
          total: _.get(res, "data.data.total", 0),
          size: 8,
        };
        this.pagination = pagination;
        return res;
      });
    });
    await this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_handle.scss';

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.list_header {
  margin-bottom: 20px;
  > h3 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 24px;
    @include font_color("font_color2");
    margin: 0;
  }
}

.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 24px;
}
.live_content {
  min-width: 997px;
  height: calc(100vh - 270px);
  overflow-y: auto;
  padding: 0 24px 50px;
  .live_item {
    width: 100%;
    height: 176px;
    margin-bottom: 24px;
    border-radius: 4px;
    @include background_color("background_color5");
    // border: 1px solid rgba(0, 0, 0, 0);
    // @include border_color("border_color1");
    cursor: pointer;
    display: flex;
    &_left {
      width: 370px;
      height: 100%;
      border-radius: 4px;
      // border-right: 1px solid rgba(0, 0, 0, 0);
      // @include border_color("border_color1");
      @include background_color("background_color2");
      padding: 24px 16px 20px 24px;
      display: flex;
      &_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px;
        margin-right: 16px;
        > img {
          background: #fff;
          width: 72px;
          height: 72px;
          border-radius: 4px;
          border: 4px solid rgba(0, 0, 0, 0);
          @include border_color("border_color2");
          margin-bottom: 4px;
        }
        > span {
          @include font_color("font_color1");
          font-size: 16px;
          font-weight: bold;
          text-align: center;
        }
      }
      &_count {
        display: flex;
        flex-direction: column;
        width: 232px;
        height: 100%;
        > div:first-child {
          padding: 12px;
          @include background_color("background_color26");
          margin-bottom: 24px;
          span {
            margin-right: 8px;
            font-size: 12px;
            @include font_color("font_color3");
          }
          span.number {
            font-size: 18px;
            font-weight: bold;
            @include font_color("font_color6");
          }
        }
        > div:last-child {
          display: flex;
          .count-item {
            display: flex;
            flex-direction: column;
            border-left: 3px solid rgba(0, 0, 0, 0);
            @include border_color("border_color2");
            padding-left: 8px;
            width: 80px;
            span {
              font-size: 12px;
              @include font_color("font_color3");
            }
            span.number {
              font-size: 14px;
              @include font_color("font_color1");
            }
          }
        }
      }
    }
    &_right {
      width: calc(100% - 370px);
      @include background_color("background_color5");
      height: 100%;
      min-width: 574px;
      max-width: 648px;
      display: flex;
      padding: 16px 24px;
      > img {
        width: 108px;
        height: 144px;
        margin-right: 16px
      }
      &_info {
        width: calc(100% - 124px);
        > .title {
          @include font_color("font_color2");
          font-size: 14px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 16px;
          height: 42px;
        }
        > .name {
          > img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 8px;
          }
          span {
            @include font_color("font_color2");
            font-size: 14px;
          }
        }
        > .count-container {
          display: flex;
          flex-direction: row;
          @include background_color("background_color2");
          border-radius: 4px;
          height: 52px;
          margin-top: 10px;
          .count-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 4px 0;
            margin-left: 32px;
            font-size: 12px;
            @include font_color("font_color3");
            .number {
              font-size: 16px;
              font-weight: bold;
              @include font_color("font_color1");
            }
            .number.color4 {
              @include font_color("font_color4");
            }
          }
        }
      }
    }
  }
}
.pagination_wrap {
  position: fixed;
  z-index: 99;
  left: 200px;
  bottom: 0;
  padding: 20px;
  text-align: center;
  width: calc(100% - 200px);
  @include background_color("background_color1");
}
</style>