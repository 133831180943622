<template>
  <div class="container">
    <platform-header
      title="活动管理"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <ButtonGroup :tab-data="tabsList" @tabChange="changeTabs" />
      <div class="create-button" @click="createActivity">
        <svg-icon
          icon-class="icon-add"
          style="margin-right: 12px; font-size: 12px"
        />
        <span>创建活动</span>
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="content">
        <div class="content-wrap">
          <div class="table">
            <div class="body" v-if="tableData && tableData.length > 0">
              <div
                :class="
                  item.id == currentTopicId && currentTabsKey == '1'
                    ? 'wrap active'
                    : 'wrap'
                "
                v-for="(item, index) in tableData"
                :key="index"
                @click="getTopicDetail(item)"
              >
                <div class="row">
                  <div class="col col-1 table-content">
                    <svg-icon
                      icon-class="arrow-down"
                      class="arrow-icon"
                      :style="{ opacity: item.active_status == '1' ? 1 : 0 }"
                    />
                    <div>
                      <div
                        class="table-content-title"
                        :title="item.activity_name || '-'"
                      >
                        {{ item.activity_name || "-" }}
                      </div>
                      <div class="table-content-bottom">
                        <span class="time"
                          >{{ item.start_time | formatDate }} 至
                          {{ item.end_time | formatDate }}</span
                        >
                        <span
                          class="status icon1"
                          v-if="item.active_status == 1"
                          >{{ returnTagText(item) }}</span
                        >
                        <span
                          class="status icon2"
                          v-else-if="item.active_status == 2"
                          >已结束</span
                        >
                        <span class="status icon3" v-else>{{
                          returnTagText(item)
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col col-2 table-content">
                    <div class="table-content-type-item">
                      <a-tooltip placement="top" v-if="item.is_live == 1">
                        <template slot="title">
                          <span>直播累计</span>
                        </template>
                        <svg-icon icon-class="type-live" class="svg-icon" />
                      </a-tooltip>
                      <div class="count-box" v-if="item.is_live == 1">
                        <span class="count-box-title">直播累计</span>
                        <span class="count-box-number">{{
                          item.live_count | changeNum
                        }}</span>
                      </div>
                    </div>
                    <div class="table-content-type-item">
                      <a-tooltip placement="top" v-if="item.is_video == 1">
                        <template slot="title">
                          <span>视频累计</span>
                        </template>
                        <svg-icon icon-class="type-video" class="svg-icon" />
                      </a-tooltip>
                      <div class="count-box" v-if="item.is_video == 1">
                        <span class="count-box-title">视频累计</span>
                        <span class="count-box-number">{{
                          item.aweme_count | changeNum
                        }}</span>
                      </div>
                    </div>
                    <div class="table-content-type-item">
                      <a-tooltip placement="top">
                        <template slot="title">
                          <span>参与门店</span>
                        </template>
                        <svg-icon icon-class="type-store" class="svg-icon" />
                      </a-tooltip>
                      <div class="count-box">
                        <span class="count-box-title">参与门店</span>
                        <span class="count-box-number">{{
                          item.dealer_count | changeNum
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col col-3 table-content">
                    <!-- todo -->
                    <span
                      v-if="
                        item.active_status == '1' || item.active_status == '2'
                      "
                      @click="openReadDrawer(item)"
                      >查看配置</span
                    >
                    <span
                      v-if="item.active_status == '0'"
                      @click="editActivity(item)"
                      >编辑配置</span
                    >
                    <span
                      v-if="
                        (item.active_status == '1' ||
                          item.active_status == '0') &&
                          item.type == 1
                      "
                      @click="openTemplateDrawer(item)"
                      >确认导拍模版</span
                    >
                    <span
                      v-if="item.active_status == '2' && item.type == 1"
                      @click="uploadWinnerPic(item)"
                      >上传获奖名单</span
                    >
                    <span
                      v-if="
                        item.active_status == '2' && currentTab != 'dongchedi'
                      "
                      @click="activityListClick(item)"
                      >活动榜单</span
                    >

                    <!-- <span
                      v-if="item.active_status == '2' && currentTab != 'dongchedi'"
                      @click="settingRoute(item, 1)"
                      >闭环管理</span
                    >
                    <span
                      v-if="item.active_status == '2' && currentTab != 'dongchedi'"
                      @click="settingRoute(item, 2)"
                      >复盘</span
                    > -->
                  </div>
                </div>
                <div class="row-detail" v-if="currentTabsKey == '1'">
                  <!-- <a-spin :spinning="detailLoading"> -->
                  <div class="row-detail-main">
                    <div
                      v-for="(item, index) in detailData"
                      :key="index"
                      @click="cardClick(item)"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                  <!-- </a-spin> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <div
      class="page-div aft-pagination"
      v-if="tableData && tableData.length > 0"
    >
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
    <Empty style="margin-top: 240px" title="暂无数据" v-else />
    <FormDrawer ref="FormDrawer" @getList="getActivityList" />
    <WinnerDrawer ref="WinnerDrawer" @getList="getActivityList" />
    <ReadDrawer ref="ReadDrawer" />
    <TemplateDrawer ref="TemplateDrawer" />
  </div>
</template>

<script>
import api from "@/api/activityManagement";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import WebpImage from "@/components/WebpImage/WebpImage";
import _ from "lodash";
import Empty from "@/components/Empty/Empty";
import deepClone from "@/utils/clone-utils";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import FormDrawer from "./components/FormDrawer";
import WinnerDrawer from "./components/WinnerDrawer";
import ReadDrawer from "./components/ReadDrawer";
import TemplateDrawer from "./components/TemplateDrawer";
const routeData = [
  { name: "实时大屏" },
  { name: "视频播放" },
  { name: "直播观看" },
  { name: "粉丝增长" },
  { name: "活跃门店" },
  // { name: "留资线索" },
  { name: "活动榜单" },
];
export default {
  components: {
    PlatformHeader,
    WebpImage,
    Empty,
    ButtonGroup,
    FormDrawer,
    WinnerDrawer,
    ReadDrawer,
    TemplateDrawer,
  },
  data() {
    return {
      currentTab: "",
      tabsList: [
        {
          label: "进行中",
          value: "1",
        },
        {
          label: "已结束",
          value: "2",
        },
        {
          label: "未开始",
          value: "0",
        },
      ],
      currentTabsKey: "1",
      searchTime: moment().subtract(1, "months"),
      disabledDate(current) {
        return current && current >= moment().startOf("month");
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      tableData: [],
      loading: false,
      dealersDetailList: null,
      current: 1,
      pageSize: 10,
      total: 0,
      currentItem: {},
      selectValue: "",
      seriesList: [],
      form: {
        name: "",
        timeArr: [],
        video_value: "",
        live_value: "",
        topic_item: [],
        checkData: [],
        video_series: [],
        live_series: [],
      },
      topicList: [],
      currentTopicId: "",
      currentTopicItem: {},
      detailData: deepClone(routeData),
      detailLoading: false,
      tableLoading: false,
      drawerType: "add",
    };
  },
  created() {
    this.getTopicList();
    // this.getActivityList();
    this.getSeriesList();
  },
  methods: {
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.current = 1;
      this.detailData = deepClone(routeData);
      if (val == "dongchedi") {
        this.detailData = this.detailData.slice(1, 5);
      }
      this.getActivityList();
    },

    // tabs切换
    changeTabs(val) {
      this.currentTabsKey = val;
      this.getActivityList();
    },
    getTopicList() {
      let body = {
        platform: "douyin",
      };

      api.getTopicList(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.topicList = res.data.data || [];
        } else {
          console.error("获取话题列表");
        }
      });
    },
    // 获取活动列表
    getActivityList(isFirst) {
      this.loading = true;
      if (isFirst) {
        this.current = 1;
      }

      let body = {
        platform: this.currentTab,
        params: {
          page_num: this.current,
          page_size: this.pageSize,
          activity_status: this.currentTabsKey,
        },
      };

      api.getActivityList(body).then((res) => {
        this.loading = false;

        if (res.data.code === 0) {
          this.tableData = res.data && res.data.data ? res.data.data.list : [];
          this.total =
            res.data && res.data.data ? res.data.data.pagination.total : 0;
          if (this.tableData.length > 0) {
            this.getTopicDetail(this.tableData[0]);
          } else {
            this.currentTopicId = "";
          }
        } else {
          this.tableData = [];
          console.error("获取授权列表失败");
        }
      });
    },
    // 获取活动列表
    getSeriesList() {
      let body = {
        platform: "agg",
      };

      api.getSeriesList(body).then((res) => {
        if (res.data.code === 0) {
          this.seriesList = res.data.data || [];
        } else {
          this.seriesList = [];
          console.error("获取车系列表失败");
        }
      });
    },
    // 获取详情数据
    getTopicDetail(item) {
      if (this.currentTopicId == item.id || this.detailLoading) {
        return false;
      }
      if (this.currentTabsKey != "1") {
        this.currentTopicId = "";
        this.currentTopicItem = {};
        return false;
      }
      this.currentTopicId = item.id;
      this.currentTopicItem = item;
    },

    // 页码切换
    pageChange() {
      this.getActivityList();
    },
    // 创建活动
    createActivity() {
      this.drawerType = "add";
      // 初始化表单
      // this.form = {
      //   name: "",
      //   timeArr: [],
      //   video_value: "",
      //   live_value: "",
      //   topic_item: undefined,
      //   checkData: [],
      // };
      this.currentItem = {};
      this.$refs.FormDrawer.showDrawer("add", this.currentItem);
    },
    settingRoute(item, type) {
      if (type == 1) {
        // 活动闭环管理
        let routeUrl = this.$router.resolve({
          path: "/dealer/activityClosedLoop",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else if (type == 2) {
        // 复盘
        let routeUrl = this.$router.resolve({
          path: "/activityReview",
          query: {
            id: item.id,
            name: item.activity_name,
            platform: this.currentTab,
            start_time: item.start_time,
            end_time: item.end_time,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    // 编辑活动
    editActivity(item) {
      console.log(item);
      this.currentItem = item;
      this.$refs.FormDrawer.showDrawer("edit", this.currentItem);
    },
    uploadWinnerPic(item) {
      this.currentItem = item;
      this.$refs.WinnerDrawer.showDrawer(this.currentItem);
    },
    // 查看配置
    openReadDrawer(item) {
      this.currentItem = item;
      this.$refs.ReadDrawer.showDrawer(this.currentItem);
    },
    // 确认导拍模版
    openTemplateDrawer(item) {
      this.currentItem = item;
      this.$refs.TemplateDrawer.showDrawer(this.currentItem);
    },
    videoSeriesChange(value, option) {
      console.log(value, option);
      console.log(this.form.video_series);
      if (value && value.length > 10) {
        this.form.video_series.pop();
        this.$message.warning("最多关联10个车系");
      }
    },
    liveSeriesChange(value, option) {
      console.log(value, option);
      console.log(this.form.live_series);
      if (value && value.length > 10) {
        this.form.live_series.pop();
        this.$message.warning("最多关联10个车系");
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    activityListClick(item) {
      console.log(item);
      let dateStr =
        moment(item.start_time).format("YYYY-MM-DD") +
        "~" +
        moment(item.end_time).format("YYYY-MM-DD");
      let url = "";
      url = `/activityList?aId=${item.id}&aName=${encodeURIComponent(
        item.activity_name
      )}&platform=${this.currentTab}&dateStr=${dateStr}`;
      window.open(url);
    },
    cardClick(item) {
      console.log(item);
      let url = "";
      switch (item.name) {
        case "实时大屏":
          url = "/dealer/activityScreen";
          break;
        case "视频播放":
          url = "/effectDetailVideo";
          break;
        case "直播观看":
          url = "/effectDetailLive";
          break;
        case "粉丝增长":
          url = "/effectDetailFans";
          break;
        case "活跃门店":
          url = "/effectDetailStore";
          break;
        case "留资线索":
          url = "/effectDetailClue";
          break;
        case "活动榜单":
          url = "/activityList";
          break;
        default:
          break;
      }
      if (url && item.name == "实时大屏") {
        url = `${url}?id=${this.currentTopicId}&platform=${this.currentTab}`;
        window.open(url);
      } else {
        let dateStr =
          moment(this.currentTopicItem.start_time).format("YYYY-MM-DD") +
          "~" +
          moment(this.currentTopicItem.end_time).format("YYYY-MM-DD");
        url = `${url}?aId=${this.currentTopicId}&aName=${encodeURIComponent(
          this.currentTopicItem.activity_name
        )}&platform=${this.currentTab}&dateStr=${dateStr}`;
        console.log(encodeURIComponent(this.currentTopicItem.activity_name));
        window.open(url);
      }
    },
    returnTagText(item) {
      if (item.active_status == 1) {
        let m1 = moment(moment(item.start_time).format("YYYY-MM-DD"));
        let m2 = moment(moment().format("YYYY-MM-DD"));
        let day = m2.diff(m1, "day");
        return `已进行${day + 1}天`;
      } else if (item.active_status == 2) {
        return "已结束";
      } else {
        let m1 = moment(moment().format("YYYY-MM-DD"));
        let m2 = moment(moment(item.start_time).format("YYYY-MM-DD"));
        let day = m2.diff(m1, "day");
        return `距离开始剩${day}天`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  .create-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 40px;
    border-radius: 8px;
    line-height: 40px;
    font-size: 14px;
    border: none;
    margin-left: 24px;
    @include background_color("background_color42");
    @include font_color("font_color20");
    cursor: pointer;
  }
}
.content {
  position: relative;
  margin: 0 20px;
  .top-img {
    width: 260px;
    position: absolute;
    top: -148px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
    }
  }
  .content-wrap {
    overflow: hidden;
  }
  // .header {
  //   @include background_color("background_color1");
  //   .row {
  //     height: 54px;
  //     font-size: 14px;
  //     @include font_color("font_color28");
  //   }
  // }
  .body {
    height: calc(100vh - 296px);
    overflow-y: auto;
    border-radius: 12px;
    @include background_color("background_color5");

    // &:hover {
    //   overflow-y: auto;
    // }
    .wrap {
      position: relative;
      height: 100px;
      border-bottom: 1px solid #3c4252;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;
      &:first-child.active {
        border-top: 1px solid #3c4252;
        border-radius: 12px 12px 0 0;
      }
      &.active {
        height: 148px;
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: #3c4252;
        .row {
          @include background_color("background_color85");
          .arrow-icon {
            transform: rotate(0deg);
          }
        }
        .arrow {
          opacity: 1;
          @include background_color("background_color34");
        }
        .icon {
          transform: rotate(-90deg);
          @include font_color("font_color17");
        }
        .detail-box {
          height: 221px;
        }
        .row-detail {
          height: 48px;
        }
      }
      .rank-img {
        width: 55px;
      }
      .rank {
        padding: 0 5px;
        display: inline-block;
        min-width: 28px;
        height: 20px;
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        border-radius: 14px;
        text-align: center;
        @include font_color("font_color22");
        @include background_color("background_color51");
      }
      .col-live {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 16px 0;
        > div:last-child {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @include font_color("font_color20");
          justify-content: flex-start;
          .col-live-title {
            font-size: 18px;
            text-align: left;
          }
          .col-live-info {
            margin: 10px 0 30px;
            display: flex;
            align-items: center;
            > span {
              margin-left: 10px;
              @include font_color("font_color22");
              font-size: 14px;
            }
          }
          .col-live-count {
            font-size: 16px;
            > span:first-child {
              @include font_color("font_color22");
            }
          }
        }
      }
      .col-brand {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &-item:first-child {
          margin: 0;
        }
        &-item {
          width: 44px;
          height: 66px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-left: 72px;
          > span {
            font-size: 12px;
            line-height: 12px;
            white-space: nowrap;
            @include font_color("font_color21");
          }
        }
      }
      .col-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span:first-child {
          @include font_color("font_color23");
          font-size: 14px;
        }
        span:last-child {
          @include font_color("font_color21");
          font-size: 14px;
        }
      }
      // &:nth-child(1) .dealer-name {
      //     @include font_color("font_color4");
      // }
      // &:nth-child(2) .dealer-name {
      //     @include font_color("font_color29");
      // }
      // &:nth-child(3) .dealer-name {
      //     @include font_color("font_color31");
      // }
    }
    .row {
      width: 100%;
      min-height: 100px;
      font-size: 14px;
      @include font_color("font_color21");
      @include background_color("background_color5");
      &:hover {
        @include background_color("background_color5");
      }
      .arrow-icon {
        font-size: 16px;
        min-width: 16px;
        min-height: 16px;
        display: inline-block;
        color: #fff;
        transform: rotate(-90deg);
        margin-right: 20px;
      }
      .level {
        display: inline-block;
        width: 30px;
        height: 30px;
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        overflow: hidden;
        text-align: center;
        border-radius: 50%;
        @include background_color("background_color55");
      }
      .level-S {
        @include font_color("font_color27");
        @include background_color("background_color52");
      }
      .level-A {
        @include font_color("font_color26");
        @include background_color("background_color53");
      }
      .level-B {
        @include font_color("font_color29");
        @include background_color("background_color54");
      }
      .level-C {
        @include font_color("font_color32");
        @include background_color("background_color60");
      }
    }
    .row-detail {
      width: 100%;
      height: 0px;
      @include background_color("background_color85");
      transition: all 0.3s;
      &-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        > div {
          height: 14px;
          line-height: 14px;
          border-right: 1px solid #3c4252;
          width: 100%;
          text-align: center;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          &:hover {
            color: #ffca5c;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 200;
    // padding: 15px 10px;
    transition: all 0.3s;
  }
  .body .row {
    &:hover {
      @include background_color("background_color5");
    }
  }
  .col {
    &:not(.align-left) {
      text-align: center;
    }
    &.col-1 {
      width: 360px;
      padding-left: 30px;
      text-align: left;
      &.table-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > img {
          margin-right: 18px;
        }
        > div {
          height: 44px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .table-content-title {
            font-size: 14px;
            @include font_color("font_color20");
            line-height: 20px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
          .table-content-bottom {
            height: 22px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .status {
              padding: 0 8px;
              height: 22px;
              border-radius: 4px;
              font-size: 12px;
              text-align: center;
              line-height: 22px;
              margin-left: 12px;
            }
            .icon1 {
              color: #27c346;
              background: rgba(39, 195, 70, 0.2);
            }
            .icon2 {
              color: #8890a6;
              background: #3a3e4a;
            }
            .icon3 {
              color: #ff9e3d;
              background: rgba(255, 158, 61, 0.2);
            }
            .time {
              @include font_color("font_color23");
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
    &.col-2 {
      width: calc(100% - 740px);
      // padding-left: calc(10% - 34px);
      padding-left: 5%;
      text-align: left;
      &.table-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .table-content-type-item {
          &:first-child {
            margin-left: 0;
          }
          width: 30%;
          height: 24px;
          display: flex;
          align-items: flex-start;
          margin-left: 1%;
          .svg-icon {
            font-size: 16px;
            margin-right: 8px;
          }
          .count-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-number {
              font-size: 20px;
              font-weight: bold;
              @include font_color("font_color20");
              opacity: 0.85;
              line-height: 24px;
            }
            &-title {
              font-size: 14px;
              line-height: 16px;
              @include font_color("font_color23");
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    &.col-3 {
      width: 380px;
      &.table-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        @include font_color("font_color26");
        > span {
          margin-right: 16px;
        }
      }
    }
  }
  .dealer-name {
    margin-bottom: 8px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nickname {
    max-width: 240px;
    font-size: 12px;
    @include font_color("font_color22");
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .account-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .avatar {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-right: 14px;
      font-size: 0;
      overflow: hidden;
      .principal-name {
        padding-left: 6px;
        font-size: 12px;
        max-width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include font_color("font_color22");
      }
    }
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    line-height: 0;
    border-radius: 8px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s;
    @include background_color("background_color62");
    .icon {
      font-size: 22px;
      transform: rotate(90deg);
      @include font_color("font_color21");
    }
  }
}
.page-div {
  text-align: right;
  padding: 20px 20px 20px 0;
}
.create-modal-container {
  @include background_color("background_color2");
  width: 100%;
  min-height: 470px;
  padding-bottom: 40px;
  &-title {
    font-size: 16px;
    text-align: center;
    @include font_color("font_color20");
    margin-bottom: 30px;
  }
  &-tip {
    font-size: 12px;
    text-align: left;
    @include font_color("font_color23");
    margin: 10px 0 24px;
  }
  &-tools {
    width: 100%;
    padding: 0 24px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;
    position: absolute;
    left: 0;
    bottom: 30px;
  }
  .btn {
    width: 210px;
    height: 40px;
    @include background_color("background_color1");
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
  }
  .btn.btn1 {
    @include font_color("font_color23");
    margin-left: 6px;
  }
  .btn.btn2 {
    @include font_color("font_color24");
    cursor: not-allowed;
    margin-right: 6px;
  }
  .btn.btn2.active {
    @include background_color("background_color34");
    @include font_color("font_color25");
    cursor: pointer;
  }
}

/deep/ .ant-input {
  height: 40px;
}
</style>
