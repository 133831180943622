import Nr from '@/utils/NetworldRequest'
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()

function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const platformRequest = (platform) => {
  let baseURL = getBaseUrl(platform)

  return new Nr({
    baseURL: baseURL
  }).getAxios()
}

export default {
  //获取活动列表
  getActivityList(body) {
      return platformRequest(body.platform).get('/jjt/closedLoop/activity/topic/list', { params: body.params })
  },
  //获取抖音话题列表
  getTopicList(body) {
      return platformRequest(body.platform).get('/jjt/closedLoop/topic/list')
  },
  saveOrUpdateTopic(body) {
    return platformRequest(body.platform).post('/jjt/closedLoop/saveOrUpdate', body.params)
  },
  // 获取话题活动详情
  getActivityDetail(body) {
      return platformRequest(body.platform).get('/jjt/closedLoop/activity/topic/one', { params: body.params })
  },
  //获取车系列表
  getSeriesList(body) {
      return platformRequest(body.platform).get('/jjt/principal/series/list')
  },

  // 新版风火轮活动相关 -----

  // 编辑、创建活动
  saveOrUpdateActivity(body) {
    return request.post('/jjt/activity/saveOrUpdate', body)
  },
  // 上传获奖图
  saveOrUpdateActivityV2(body) {
    return request.post('/jjt/activity/saveOrUpdate/v2', body)
  },

  // 获取模版列表
  getTemplateManage(params) {
    return request.get('/jjt/template-manage/query', {params: params})
  },

  // 编辑、删除模版
  editTemplateManage(body) {
    return request.post('/jjt/template-manage/edit', body)
  },

  // 视频地址通过内容中台获取权限
  getAuthUrl(body) {
    return platformRequest('contentcenter').post('/v1/content/vod/getAuthUrl', body)
  },

}