<template>
  <div
    class="blur-card"
  ></div>
</template>

<script>
export default {
  name: "BlurCard",
};
</script>

<style scoped lang="less">
.blur-card {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(16px);
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  // background: url("../../assets/live_images/bg_ac2.png") no-repeat fixed -8px -1px;
}
</style>
