<template>
  <div class="wrap">
    <a-carousel :dots="false" ref="carousel" v-if="total > 0">
      <div
        class="activity-item"
        v-for="(item, index) in carouselList"
        :key="index"
        @click="routerTo"
      >
        <div>
          <img src="@/assets/img/activity3.png" alt="" />
          <span class="button">进行中</span>
          <span class="title">{{
            item.activity_name || "-"
          }}</span>
          <span class="label">参与门店：</span>
          <span class="number">{{ item.dealer_count | changeNum }}</span>
          <span class="label">DAY：</span>
          <span class="number">{{ item.day | changeNum }}</span>
        </div>
      </div>
    </a-carousel>
    <div class="button-group" v-if="total > 0">
      <svg-icon
        icon-class="arrow-right"
        class="arrow-icon arrow-left"
        @click="leftClick"
      />
      <svg-icon
        icon-class="arrow-right"
        class="arrow-icon arrow-right"
        @click="rightClick"
      />
    </div>
    <div class="empty-box" v-if="total == 0">
      <div class="empty-box-left">
        <img src="@/assets/img/activity3.png" alt="" />
        <span class="button">未开始</span>
        <span class="title" @click="routerTo">暂无活动</span>
      </div>
      <div class="empty-box-right" @click="routerTo">
        <span>去创建</span
        ><svg-icon icon-class="arrow-right" class="router-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/bulletinBoard";
export default {
  name: "LeftWrap3",
  components: {},
  data() {
    return {
      carouselList: [],
      total: 0,
    };
  },
  computed: {},
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getActivityList();
    },
    // 接口请求 - 活动列表
    getActivityList() {
      let params = {
        platform: this.$parent.currentTab,
      };
      api.getActivityList(params).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getActivityList", data);
          if (data && data.list && data.list.length > 0) {
            this.carouselList = data.list;
            this.total = data.list.length;
          } else {
            // 暂无数据
            this.carouselList = [];
            this.total = 0;
          }
        } else {
          console.error("获取活动列表失败");
          this.carouselList = [];
          this.total = 0;
        }
      });
    },
    // banner left按钮
    leftClick() {
      console.log(this.$refs);
      console.log(this.$refs.carousel);
      this.$refs.carousel.prev();
    },
    // banner right按钮
    rightClick() {
      this.$refs.carousel.next();
    },
    // 跳转到 活动管理 列表
    routerTo() {
      this.$router.push({
        path: "/activityManagement",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  &:hover {
    background: #000;
  }
  .activity-item {
    width: 100%;
    height: 54px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;
    > div {
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > img {
        width: 30px;
        height: 30px;
      }
      .button {
        width: 50px;
        height: 22px;
        line-height: 22px;
        background: #252533;
        text-align: center;
        color: #ffca5c;
        font-size: 12px;
        border-radius: 11px;
        margin: 0 10px 0 20px;
      }
      .title,
      .number {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
      }
      .label {
        font-size: 12px;
        color: #9ca8ba;
        margin-left: 30px;
      }
    }
  }
  .button-group {
    position: absolute;
    width: 58px;
    height: 26px;
    right: 10px;
    top: 6px;
    font-size: 26px;
    color: #9ca8ba;
    .arrow-left {
      transform: rotate(180deg);
      margin-right: 6px;
    }
    .arrow-icon {
      cursor: pointer;
      &.disable {
        opacity: 0.5;
      }
    }
  }
  .empty-box {
    width: 100%;
    height: 54px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;
    &-left {
      height: 54px;
      display: flex;
      align-items: center;
      > img {
        width: 30px;
        height: 30px;
      }
      .button {
        width: 50px;
        height: 22px;
        line-height: 22px;
        background: #222530;
        text-align: center;
        color: #9ca8ba;
        font-size: 12px;
        border-radius: 11px;
        margin: 0 10px 0 20px;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    &-right {
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > span {
        font-size: 12px;
        color: #9ca8ba;
        margin-left: 30px;
      }
      .router-icon {
        font-size: 26px;
        color: #9ca8ba;
        margin-left: 10px;
      }
    }
  }
}
</style>
