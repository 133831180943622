<template>
  <div class="container">
    <div class="top-wrap" style="position: relative">
      <div class="main-title">
        <img :src="require('@/assets/industryTop/title.png')" />
      </div>
      <div class="tools">
        <div class="platform-div-industry">
          <platform-header
            :showTitle="false"
            :currentTab="currentTab"
            @tabChange="tabChange"
          />
        </div>
        <Tabs
          :tabData="tabsList"
          @tabChange="changeTabs"
          style="position: absolute; left: 24px; top: 114px"
        />

        <a-month-picker
          v-model="searchTime"
          class="aft-month-picker default"
          placeholder="Select month"
          :disabled-date="disabledDate"
          :allowClear="false"
          @change="onChange"
          locale="zh-cn"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-month-picker>
      </div>
    </div>

    <div class="content">
      <div class="top-img">
        <img class="img" :src="require('@/assets/industryTop/trophy.png')" />
      </div>
      <div class="content-wrap" v-show="currentTabsKey == '1'">
        <div class="table">
          <div class="header">
            <div class="row">
              <div class="col col-1">排名</div>
              <div class="col col-2 align-left">门店名称/账号昵称</div>
              <div class="col col-3">账号等级</div>
              <div class="col col-4">月度营销价值</div>
              <div class="col col-5">粉丝总量</div>
              <div class="col col-5">直播最高观看</div>
              <div class="col col-5">视频最高播放</div>
              <div class="col col-6">&nbsp;</div>
            </div>
          </div>
          <a-spin :spinning="loading">
            <div class="body" v-if="tableData.length > 0">
              <div
                :class="index === activeIndex ? 'wrap active' : 'wrap'"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div class="row" @click="onClickDealer(item.author_id, index)">
                  <div class="col col-1">
                    <!--
                                    <img class="rank-img" :src="require(`@/assets/industryTop/top-${index+1}.png`)" v-if="index < 3" />
                                -->
                    <span :class="['rank', `rank${index + 1}`]">{{
                      index + 1
                    }}</span>
                  </div>
                  <div class="col col-2 align-left">
                    <div class="dealer-name">
                      {{ item.dealer_name || item.nickname || "-" }}
                    </div>
                    <div class="nickname">{{ item.nickname || "-" }}</div>
                    <div class="account-box">
                      <div
                        class="avatar"
                        v-for="(img, k) in item.avatars"
                        :key="k"
                        v-if="k < 3"
                      >
                        <BaseImage
                          :src="img.avatar ? `${ossHost}${img.avatar}` : ''"
                          :default-image="defaultAvatarImg"
                          fit="cover"
                          width="20px"
                          height="20px"
                          round
                        />
                        <span class="principal-name">{{
                          img.principal_name || "-"
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col col-3">
                    <span :class="`level level-${item.sabcd_level}`">{{
                      item.sabcd_level || "-"
                    }}</span>
                  </div>
                  <div class="col col-4">
                    ¥{{ Number(item.salves_value) | changeNum }}
                  </div>
                  <div class="col col-5">
                    {{ item.fans_count_total | changeNum_point2 }}
                  </div>
                  <div class="col col-5">
                    {{ item.statistics_total_watch_user_time | changeNum }}
                  </div>
                  <div class="col col-5">
                    {{ item.play_volume | changeNum }}
                  </div>
                  <div class="col col-6">
                    <div class="arrow">
                      <svg-icon icon-class="arrow-right" class="icon" />
                    </div>
                  </div>
                </div>
                <div class="detail-box">
                  <div class="tab-box">
                    <span
                      :class="activeType === 1 ? 'tab active' : 'tab'"
                      @click="onChangeType(1)"
                      >最新发布</span
                    >
                    <span
                      :class="activeType === 2 ? 'tab active' : 'tab'"
                      @click="onChangeType(2)"
                      >近30天最佳</span
                    >
                  </div>
                  <div class="list-box">
                    <div
                      class="item"
                      @click="jumpLiveDetal(dealersDetailData.live.room_id)"
                    >
                      <div class="cover">
                        <BaseImage
                          :src="dealersDetailData.live.cover || ''"
                          :default-image="defaultCoverImg"
                          fit="contain"
                          width="90px"
                          height="120px"
                          radius="10px"
                          style="background-color: #21232c;"
                        />
                        <svg-icon icon-class="play3" class="play" />
                      </div>
                      <div class="detail">
                        <div class="title">
                          <span class="tag tag-1">直播</span>
                          <a-tooltip>
                            <template slot="title">{{
                              dealersDetailData.live.title || "-"
                            }}</template>
                            <span class="text">{{
                              dealersDetailData.live.title || "-"
                            }}</span>
                          </a-tooltip>
                        </div>
                        <div class="time">
                          {{ dealersDetailData.live.publish_time || "-" }}
                        </div>
                        <div class="data">
                          <div class="box">
                            <span class="label">直播观看</span>
                            <span class="num">{{
                              dealersDetailData.live
                                .statistics_total_watch_user_time | changeNum
                            }}</span>
                          </div>
                          <div class="box">
                            <span class="label">直播时长</span>
                            <span class="num">{{
                              dealersDetailData.live.statistics_live_time
                                ? `${Math.ceil(
                                    dealersDetailData.live
                                      .statistics_live_time / 60
                                  )}min`
                                : "-"
                            }}</span>
                          </div>
                          <div class="box">
                            <span class="label">平均在线</span>
                            <span class="num">{{
                              dealersDetailData.live.statistics_avg_online_user
                                | changeNum
                            }}</span>
                          </div>
                          <div class="box">
                            <span class="label">直播互动</span>
                            <span class="num">{{
                              dealersDetailData.live
                                .statistics_total_polling_count | changeNum
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="item"
                      @click="jumpVideoDetal(dealersDetailData.aweme.aweme_id)"
                    >
                      <div class="cover">
                        <BaseImage
                          :src="dealersDetailData.aweme.aweme_cover || ''"
                          :default-image="defaultCoverImg"
                          fit="contain"
                          width="90px"
                          height="120px"
                          radius="10px"
                          style="background-color: #21232c;"
                        />
                        <svg-icon icon-class="play3" class="play" />
                      </div>
                      <div class="detail">
                        <div class="title">
                          <span class="tag tag-2">视频</span>
                          <a-tooltip>
                            <template slot="title">{{
                              dealersDetailData.aweme.aweme_title || "-"
                            }}</template>

                            <span class="text">{{
                              dealersDetailData.aweme.aweme_title || "-"
                            }}</span>
                          </a-tooltip>
                        </div>
                        <div class="time">
                          {{ dealersDetailData.aweme.publish_time || "-" }}
                        </div>
                        <div class="data">
                          <div class="box">
                            <span class="label">视频播放</span>
                            <span class="num">{{
                              dealersDetailData.aweme.play_volume | changeNum
                            }}</span>
                          </div>
                          <div class="box">
                            <span class="label">视频点赞</span>
                            <span class="num">{{
                              dealersDetailData.aweme.digg_count | changeNum
                            }}</span>
                          </div>
                          <div class="box">
                            <span class="label">视频评论</span>
                            <span class="num">{{
                              dealersDetailData.aweme.comment_count | changeNum
                            }}</span>
                          </div>
                          <div class="box">
                            <span class="label">视频转发</span>
                            <span class="num">{{
                              dealersDetailData.aweme.share_count | changeNum
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-result" v-else>暂无数据</div>
          </a-spin>
        </div>
      </div>
      <div class="content-wrap" v-show="currentTabsKey == '2'">
        <div class="table">
          <div class="header">
            <div class="row">
              <div class="col" style="width: 200px">排名</div>
              <div class="col" style="width: 554px; text-align: left">直播</div>
              <div
                class="col"
                style="width: calc(100% - 940px); text-align: left"
              >
                品牌
              </div>
              <div class="col" style="width: 186px">直播时间</div>
            </div>
          </div>
          <a-spin :spinning="liveLoading">
            <div class="body" v-if="tableLiveData.length > 0">
              <div
                :class="index === activeIndex ? 'wrap active' : 'wrap'"
                v-for="(item, index) in tableLiveData"
                :key="index"
              >
                <div class="row" @click="jumpLiveDetal(item.room_id)">
                  <div class="col" style="width: 200px">
                    <span :class="['rank', `rank${index + 1}`]">{{
                      index + 1
                    }}</span>
                  </div>
                  <div class="col col-live" style="width: 554px">
                    <BaseImage
                      :src="item.cover || ''"
                      :default-image="defaultCoverImg"
                      fit="contain"
                      width="90px"
                      height="120px"
                      radius="16px"
                      style="background-color: #21232c;"
                    />
                    <div style="flex: 1;">
                      <div class="col-live-title">{{ item.title || "-" }}</div>
                      <div class="col-live-info">
                        <BaseImage
                          :src="item.avatar || ''"
                          :default-image="defaultAvatarImg"
                          fit="cover"
                          width="18px"
                          height="18px"
                          round
                          style="border: 1px solid #ffffff;"
                        />
                        <span>{{ item.nickname || "-" }}</span>
                      </div>
                      <div class="col-live-count">
                        <span>直播观看:</span>
                        <span>{{
                          item.statistics_total_watch_user_time | changeNum
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col col-brand"
                    style="width: calc(100% - 940px); text-align: left"
                  >
                    <div
                      class="col-brand-item"
                      v-for="(item, index) in (item.avatars || []).slice(0, 5)"
                      :key="index"
                    >
                      <BaseImage
                        :src="item.avatar ? `${ossHost}${item.avatar}` : ''"
                        :default-image="defaultAvatarImg"
                        fit="cover"
                        width="44px"
                        height="44px"
                        radius="10px"
                      />
                      <span>{{ item.principal_name || "-" }}</span>
                    </div>
                  </div>
                  <div class="col col-time" style="width: 186px">
                    <span>{{
                      item.publish_time ? item.publish_time.slice(0, 10) : "-"
                    }}</span>
                    <span>{{
                      item.publish_time ? item.publish_time.slice(11, 19) : "-"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-result" v-else>暂无数据</div>
          </a-spin>
        </div>
      </div>
      <div class="content-wrap" v-show="currentTabsKey == '3'">
        <div class="table">
          <div class="header">
            <div class="row">
              <div class="col" style="width: 150px">排名</div>
              <div class="col" style="width: 400px; text-align: left">视频</div>
              <div
                class="col"
                style="width: calc(100% - 870px); text-align: left"
              >
                品牌
              </div>
              <div class="col" style="width: 120px">播放量</div>
              <div class="col" style="width: 200px">发布时间</div>
            </div>
          </div>
          <a-spin :spinning="videoLoading">
            <div class="body" v-if="tableVideoData.length > 0">
              <div
                :class="index === activeIndex ? 'wrap active' : 'wrap'"
                v-for="(item, index) in tableVideoData"
                :key="index"
              >
                <div class="row" @click="jumpVideoDetal(item.aweme_id)">
                  <div class="col" style="width: 150px">
                    <span :class="['rank', `rank${index + 1}`]">{{
                      index + 1
                    }}</span>
                  </div>
                  <div class="col col-live" style="width: 400px">
                    <BaseImage
                      :src="item.aweme_cover || ''"
                      :default-image="defaultCoverImg"
                      fit="contain"
                      width="90px"
                      height="120px"
                      radius="16px"
                      style="background-color: #21232c;"
                    />
                    <div style="flex: 1;">
                      <div
                        class="col-live-title"
                        :title="item.aweme_title || '-'"
                      >
                        {{ item.aweme_title || "-" }}
                      </div>
                      <div class="col-live-info">
                        <BaseImage
                          :src="item.avatar || ''"
                          :default-image="defaultAvatarImg"
                          fit="cover"
                          width="18px"
                          height="18px"
                          round
                          style="border: 1px solid #ffffff;"
                        />
                        <span>{{ item.nickname || "-" }}</span>
                      </div>
                      <!-- <div class="col-live-count">
                        <span>直播观看:</span>
                        <span>{{
                          item.statistics_total_watch_user_time | changeNum
                        }}</span>
                      </div> -->
                    </div>
                  </div>
                  <div
                    class="col col-brand"
                    style="width: calc(100% - 870px); text-align: left"
                  >
                    <div
                      class="col-brand-item"
                      v-for="(item, index) in (item.avatars || []).slice(0, 5)"
                      :key="index"
                    >
                      <BaseImage
                        :src="item.avatar ? `${ossHost}${item.avatar}` : ''"
                        :default-image="defaultAvatarImg"
                        fit="cover"
                        width="44px"
                        height="44px"
                        radius="10px"
                      />
                      <span>{{ item.principal_name || "-" }}</span>
                    </div>
                  </div>
                  <div class="col col-number" style="width: 120px">
                    {{ item.play_volume | changeNum }}
                  </div>
                  <div class="col col-time" style="width: 200px">
                    <span>{{ item.publish_time || "-" }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-result" v-else>暂无数据</div>
          </a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultAvatarImg from "@/assets/default/default1-1.png";
import defaultCoverImg from "@/assets/default/default3-4.png";
import BaseImage from "@/components/BaseImage";
import api from "@/api/industryTop";
import _ from "lodash";
import Tabs from "@/components/PlatformTab/Tabs";
import Tooltip from "@/components/Tooltip/Tooltip";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";

const baseData = {
  aweme: {},
  live: {},
};

export default {
  components: {
    BaseImage,
    PlatformHeader,
    Tabs,
    Tooltip,
  },
  data() {
    return {
      defaultAvatarImg,
      defaultCoverImg,
      tabData: [
        {
          label: "抖音",
          value: "douyin",
          icon: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
          icon: "kuaishou2",
        },
      ],
      tabsList: [
        {
          label: "门店百强榜",
          value: "1",
        },
        {
          label: "直播百强榜",
          value: "2",
        },
        {
          label: "视频百强榜",
          value: "3",
        },
      ],
      currentTabsKey: "1",
      currentTab: "",
      searchTime: moment().subtract(1, "months"),
      disabledDate(current) {
        return current && current >= moment().startOf("month");
      },
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      tableData: [],
      tableLiveData: [],
      tableVideoData: [],
      loading: false,
      liveLoading: false,
      videoLoading: false,
      activeIndex: undefined,
      activeType: 1,
      dealersDetailData: _.cloneDeep(baseData),
      dealersDetailList: null,
    };
  },
  created() {
    // this.searchCurrentList();
  },
  methods: {
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.searchCurrentList();
    },
    // tabs切换
    changeTabs(val) {
      this.currentTabsKey = val;
      this.searchCurrentList();
    },
    onChange(date) {
      this.searchTime = date;
      this.searchCurrentList();
    },
    searchCurrentList() {
      if (this.currentTabsKey == "1") {
        this.getList();
      } else if (this.currentTabsKey == "2") {
        this.getLiveList();
      } else if (this.currentTabsKey == "3") {
        this.getVideoList();
      }
    },
    getList(date) {
      this.loading = true;
      this.activeIndex = undefined;

      let obj = this.calcStartEndTime(this.searchTime);
      let body = {
        platform: this.currentTab,
        params: {
          start_time: obj.start_time,
          end_time: obj.end_time,
          current: this.current,
          page_size: this.pageSize,
        },
      };

      api.getList(body).then((res) => {
        this.loading = false;
        if (res.data.code === 0) {
          this.tableData = res.data.data || [];
        } else if (res.data.code === 6000) {
          this.tableData = [];
          this.searchTime = moment().subtract(2, "months");
          this.getList();
          this.$message.warning(res.data.msg);
        } else {
          this.tableData = [];
          console.error("获取授权列表失败");
        }
      });
    },
    getLiveList(date) {
      this.liveLoading = true;
      this.activeIndex = undefined;

      let obj = this.calcStartEndTime(this.searchTime);
      let body = {
        platform: this.currentTab,
        params: {
          start_time: obj.start_time,
          end_time: obj.end_time,
        },
      };

      api.getLiveList(body).then((res) => {
        this.liveLoading = false;

        if (res.data.code === 0) {
          this.tableLiveData = res.data.data || [];
        } else if (res.data.code === 6000) {
          this.tableLiveData = [];
          this.searchTime = moment().subtract(2, "months");
          this.getLiveList();
          this.$message.warning(res.data.msg);
        } else {
          this.tableLiveData = [];
          console.error("获取直播百强榜失败");
        }
      });
    },
    getVideoList(date) {
      this.videoLoading = true;
      this.activeIndex = undefined;

      let obj = this.calcStartEndTime(this.searchTime);
      let body = {
        platform: this.currentTab,
        params: {
          start_time: obj.start_time,
          end_time: obj.end_time,
        },
      };

      api.getVideoList(body).then((res) => {
        this.videoLoading = false;

        if (res.data.code === 0) {
          this.tableVideoData = res.data.data || [];
        } else if (res.data.code === 6000) {
          this.tableVideoData = [];
          this.searchTime = moment().subtract(2, "months");
          this.getVideoList();
          this.$message.warning(res.data.msg);
        } else {
          this.tableVideoData = [];
          console.error("获取视频百强榜失败");
        }
      });
    },
    calcStartEndTime(date) {
      let start = date.startOf("month").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("month").format("YYYY-MM-DD HH:mm:ss");

      return {
        start_time: start,
        end_time: end,
      };
    },
    onClickDealer(id, index) {
      this.dealersDetailData = _.cloneDeep(baseData);
      if (this.activeIndex === index) {
        this.activeIndex = undefined;
        return;
      }

      this.activeIndex = index;
      this.activeType = 1;
      this.getAuthorList(id);
    },
    // onCollectDetail() {
    //     this.activeIndex = undefined
    // },
    onChangeType(type) {
      this.activeType = type;
      if (type === 1) {
        this.dealersDetailData = this.dealersDetailList.recent;
      } else {
        this.dealersDetailData = this.dealersDetailList.best;
      }
    },
    getAuthorList(id) {
      let body = {
        platform: this.currentTab,
        author_id: id,
      };
      api.getAuthorList(body).then((res) => {
        if (res.data.code == 0) {
          this.dealersDetailData =
            res.data.data.recent || _.cloneDeep(baseData);
          this.dealersDetailList = res.data.data || null;
        } else {
          this.dealersDetailData = _.cloneDeep(baseData);
          this.dealersDetailList = null;
          console.log("获取最新/最佳数据失败");
        }
      });
    },
    jumpLiveDetal(id) {
      if (id) {
        let url = `/liveDetail?id=${id}&platform=${this.currentTab}`;
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
    jumpVideoDetal(id) {
      if (id) {
        let url = `/videoDetail?id=${id}&platform=${this.currentTab}`;
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.container {
  background-image: url("../../assets/industryTop/bg.png");
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  @include background_color("background_color1");
}
.top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .main-title {
    padding: 0 20px;
    width: 306px;
    img {
      width: 100%;
    }
    // font-size: 40px;
    // font-weight: bold;
    // @include font_color("font_color1");
  }
  .tools {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    // margin-bottom: 16px;
    padding: 0 20px;
  }
  .platform-div-industry {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 34px;
    width: 100%;
    height: 40px;
  }
}
.content {
  position: relative;
  margin: 0 20px;
  .top-img {
    width: 260px;
    position: absolute;
    top: -148px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
    }
  }
  .content-wrap {
    border-radius: 16px;
    overflow: hidden;
  }
  .table {
    @include background_color("background_color2");
  }
  .header {
    @include background_color("background_color42");
    .row {
      height: 72px;
      font-size: 14px;
      @include font_color("font_color22");
    }
  }
  .body {
    height: calc(100vh - 250px - 72px);
    overflow-y: auto;
    .wrap {
      border-top: 1px solid;
      @include border_color("border_color16");
      &.active {
        .row {
          @include background_color("background_color42");
        }
        .arrow {
          opacity: 1;
          @include background_color("background_color34");
        }
        .icon {
          transform: rotate(-90deg);
          @include font_color("font_color17");
        }
        .detail-box {
          height: 221px;
        }
      }
      .rank-img {
        width: 55px;
      }
      .rank {
        padding: 0 5px;
        display: inline-block;
        min-width: 28px;
        height: 20px;
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        border-radius: 14px;
        text-align: center;
        @include font_color("font_color22");
        @include background_color("background_color51");
      }
      .rank.rank1 {
        @include background_color("background_color15");
        @include font_color("font_color25");
      }
      .rank.rank2 {
        @include background_color("background_color18");
        @include font_color("font_color25");
      }
      .rank.rank3 {
        @include background_color("background_color49");
        @include font_color("font_color25");
      }
      .col-live {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 16px 48px 16px 0;
        > div:last-child {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @include font_color("font_color20");
          justify-content: flex-start;
          .col-live-title {
            font-size: 14px;
            font-weight: bold;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
          .col-live-info {
            margin: 10px 0 30px;
            display: flex;
            align-items: flex-start;
            > span {
              margin-left: 10px;
              @include font_color("font_color22");
              font-size: 14px;
              line-height: 18px;
              text-align: left;
            }
          }
          .col-live-count {
            font-size: 16px;
            > span:first-child {
              @include font_color("font_color22");
            }
          }
        }
      }
      .col-brand {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &-item:first-child {
          margin: 0;
        }
        &-item {
          width: 44px;
          height: 66px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-left: 48px;
          > span {
            font-size: 12px;
            line-height: 12px;
            white-space: nowrap;
            @include font_color("font_color21");
          }
        }
      }
      .col-number {
        font-size: 14px;
        color: #ffca5c;
      }
      .col-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span:first-child {
          @include font_color("font_color23");
          font-size: 14px;
        }
        // span:last-child {
        //   @include font_color("font_color21");
        //   font-size: 14px;
        // }
      }
      // &:nth-child(1) .dealer-name {
      //     @include font_color("font_color4");
      // }
      // &:nth-child(2) .dealer-name {
      //     @include font_color("font_color29");
      // }
      // &:nth-child(3) .dealer-name {
      //     @include font_color("font_color31");
      // }
    }
    .row {
      min-height: 87px;
      font-size: 14px;
      @include font_color("font_color21");
      .level {
        display: inline-block;
        width: 30px;
        height: 30px;
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        overflow: hidden;
        text-align: center;
        border-radius: 50%;
        @include background_color("background_color55");
      }
      .level-S {
        @include font_color("font_color27");
        @include background_color("background_color52");
      }
      .level-A {
        @include font_color("font_color26");
        @include background_color("background_color53");
      }
      .level-B {
        @include font_color("font_color29");
        @include background_color("background_color54");
      }
      .level-C {
        @include font_color("font_color32");
        @include background_color("background_color60");
      }
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 15px 10px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      @include background_color("background_color5");
      .arrow {
        opacity: 1;
      }
    }
  }
  .col {
    &:not(.align-left) {
      text-align: center;
    }
    &.col-1 {
      width: 6%;
    }
    &.col-2 {
      padding-left: 60px;
      padding-right: 10px;
      width: 30%;
    }
    &.col-3 {
      width: 7%;
    }
    &.col-4 {
      width: 14%;
    }
    &.col-5 {
      width: 12%;
    }
    &.col-6 {
      width: 7%;
    }
  }
  .dealer-name {
    margin-bottom: 8px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nickname {
    max-width: 240px;
    font-size: 12px;
    @include font_color("font_color22");
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .account-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .avatar {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-right: 14px;
      font-size: 0;
      overflow: hidden;
      .principal-name {
        padding-left: 6px;
        font-size: 12px;
        max-width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include font_color("font_color22");
      }
    }
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    line-height: 0;
    border-radius: 8px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s;
    @include background_color("background_color62");
    .icon {
      font-size: 22px;
      transform: rotate(90deg);
      @include font_color("font_color21");
    }
  }
  .detail-box {
    padding: 0 20px;
    overflow: hidden;
    height: 0;
    transition: height 0.3s;
    @include background_color("background_color1");
    .tab-box {
      display: flex;
      align-items: center;
      margin: 20px 0;
      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        margin-right: 20px;
        width: 100px;
        height: 36px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        @include font_color("font_color22");
        @include background_color("background_color2");
        &.active {
          @include font_color("font_color25");
          @include background_color("background_color13");
        }
      }
    }
    .list-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .item {
        display: flex;
        align-items: center;
        width: 50%;
        cursor: pointer;
      }
      .cover {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 30px;
          opacity: 0;
          transform: translate(-50%, -50%);
          transition: opacity 0.3s;
          z-index: 3;
        }
        &:hover {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            @include background_color("background_color66");
          }
          .play {
            opacity: 1;
          }
        }
      }
      .detail {
        flex: 1;
        margin-left: 20px;
        overflow: hidden;
        .title {
          display: flex;
          align-items: center;
          .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            width: 56px;
            height: 22px;
            border-radius: 11px;
            line-height: 1;
            @include font_color("font_color1");
            &.tag-1 {
              @include background_color("background_color64");
            }
            &.tag-2 {
              @include background_color("background_color65");
            }
          }
          .text {
            flex: 1;
            max-width: 65%;
            font-size: 16px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include font_color("font_color1");
          }
        }
        .time {
          margin: 11px 0 15px;
          font-size: 12px;
          line-height: 1.2em;
          @include font_color("font_color22");
        }
        .data {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .box {
          padding-right: 15px;
          width: 25%;
        }
        .label {
          padding-bottom: 15px;
          display: block;
          font-size: 12px;
          line-height: 1.2em;
          @include font_color("font_color23");
        }
        .num {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.2em;
          @include font_color("font_color1");
        }
      }
    }
  }
  .no-result {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    font-size: 14px;
    @include font_color("font_color22");
  }
}

/deep/ .ant-input {
  height: 40px;
}
</style>
