<template>
  <a-tag v-if="value === 1" color="#27C34633" class="success-text">
    已完成
  </a-tag>
  <a-tag v-else-if="value === 0" color="#F7696533" class="unfinished-text">
    未完成
  </a-tag>
</template>

<script>
export default {
  name: "taskProgressTag",
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.success-text {
  color: #24bf79ff;
}
.unfinished-text {
  color: #f75c5cff;
}
</style>
