<template>
  <div class="container">
    <platform-header title="商务政策概览" :tabData="platList" />
    <div class="tabs">
      <Tabs :tabData="tabsList" @tabChange="handleTabsChange" />
    </div>
    <div class="tools">
      <button-tabs :tabData="btnTabsList" @tabChange="handleBtnTabsChange" />
      <date-tab
        ref="dateTab"
        :type="dateType"
        @changeType="handleTypeChange"
        @changeDate="handleDateChange"
      />
    </div>
    <div class="content">
      <div class="content-title">
        <div class="content-title__left">
          <span class="sub-title">{{ contentTitle }}</span>
          <span class="title">({{ tabName }})</span>
          <AftTooltip v-if="contentTitle == '基础激励' && tabName == '直播'" :code="'sgqt01'" style="top: 0px; left: 4px" />
        </div>
        <div class="content-title__right">
          <a-button class="btn-download" @click="handleDataDownload">
            <i class="icon-download"></i>下载数据
          </a-button>
          <a-select
            class="select-group"
            v-model="group_code"
            :suffixIcon="suffixIcon"
            @change="handleGroupChange"
          >
            <a-select-option
              v-for="item in groupList"
              :key="item.groupCode"
              :value="item.groupCode"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="content-data" v-show="showContentData">
        <div class="data-item" v-for="data in contentData" :key="data.key">
          <div class="data-item__label">
            {{ data.label }}
            <!-- <a-tooltip v-if="data.tooltip">
              <svg-icon class="icon-tips" icon-class="tips"></svg-icon>
              <template slot="title">{{ data.tooltip }}</template>
            </a-tooltip> -->
            <AftTooltip
              v-if="data.tooltip"
              :code="data.tooltip"
              style="top: 0px; left: 4px"
            />
          </div>
          <div class="data-item__value">
            <span class="num">{{ data.value | changeNum }}</span>
            <span class="suffix"></span>
          </div>
        </div>
      </div>
      <div class="content-search" v-show="showContentSearch">
        <a-input
          class="input"
          v-if="activeTabValue === 0"
          v-model.trim="keyword"
          placeholder="请输入直播关键字"
          @pressEnter="handleKeywordSearch"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-select
          class="select"
          v-if="activeTabValue === 1"
          v-model="topicId"
          :suffixIcon="suffixIcon"
          @change="handleTopicChange"
        >
          <a-select-option
            v-for="item in topicList"
            :key="item.topic_id"
            :value="item.topic_id"
          >
            {{ item.topic_name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <a-table
          class="content-table content-table--normal"
          v-if="activeBtnTabValue !== 0"
          :loading="loading"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :scroll="{ y: 'calc(100vh - 560px)' }"
          :locale="locale"
        >
          <span
            slot="sort"
            slot-scope="text, record"
            :class="{
              color: true,
              color1: record.sort === 1,
              color2: record.sort === 2,
              color3: record.sort === 3,
            }"
          >
            {{ record.sort }}
          </span>
        </a-table>
        <a-table
          class="content-table content-table--group"
          v-else
          :loading="loading"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :scroll="{ y: 'calc(100vh - 560px)' }"
          :locale="locale"
        >
          <span
            slot="sort"
            slot-scope="text, record"
            :class="{
              color: true,
              color1: record.sort === 1,
              color2: record.sort === 2,
              color3: record.sort === 3,
            }"
          >
            {{ record.sort }}
          </span>
        </a-table>
      </div>
    </div>
    <div class="aft-pagination" v-show="pagination.total > 0">
      <a-pagination
        v-model="pagination.current"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        size="small"
        @change="handlePaginationChange"
      />
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Tabs from "@/components/PlatformTab/Tabs";
import ButtonTabs from "@/components/PlatformTab/NewButtonTab";
import DateTab from "@/components/DateTab/DateTab2";
import exportTable from "@/utils/exportTable";
import api from "@/api/businessPolicyOverview";
import Empty from "@/components/Empty/Empty";
import {
  platList,
  tabsList,
  btnTabsList,
  titleList,
  data_0_0,
  data_0_1,
  data_1_0,
  data_1_1,
  columnsList,
  pagination,
  apiList,
  exportApiList,
} from "./constant";
import { getPrincipalId } from "@/utils/auth";

export default {
  components: {
    PlatformHeader,
    Tabs,
    ButtonTabs,
    DateTab,
    Empty
  },
  data() {
    return {
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      platList,
      tabsList,
      activeTabValue: 0,
      btnTabsList,
      activeBtnTabValue: 0,
      dateType: "month",
      start_time: this.$moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00"),
      end_time: this.$moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59"),
      groupList: [],
      group_code: "",
      contentData: data_0_0,
      loading: false,
      columns: columnsList[0][0],
      dataSource: [],
      pagination: { ...pagination },
      keyword: "",
      topicList: [],
      topicId: "",
      locale: {
        emptyText: <Empty style="margin-top: 20px;" title="暂无数据" />,
      },
    };
  },
  computed: {
    tabName() {
      return titleList[this.activeTabValue][this.activeBtnTabValue].tabName;
    },
    contentTitle() {
      return titleList[this.activeTabValue][this.activeBtnTabValue].title;
    },
    tooltip() {
      return titleList[this.activeTabValue][this.activeBtnTabValue].tooltip;
    },
    showContentData() {
      const { activeTabValue, activeBtnTabValue } = this;

      if (
        activeTabValue === 0 &&
        (activeBtnTabValue === 0 || activeBtnTabValue === 1)
      ) {
        return true;
      } else if (
        activeTabValue === 1 &&
        (activeBtnTabValue === 0 || activeBtnTabValue === 1)
      ) {
        return true;
      } else {
        return false;
      }
    },
    showContentSearch() {
      const { activeBtnTabValue } = this;

      if (activeBtnTabValue === 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    await this.getGroupList();
    this.getTopicList();
    this.getData();
  },
  methods: {
    // 获取分组列表
    async getGroupList() {
      const params = {
        principal_id: getPrincipalId(),
      };
      const { code, data } = await api.getGroupList(params);

      if (code === 0) {
        this.groupList = data;
        this.group_code =
          this.groupList.length > 0 ? this.groupList[0].groupCode : "";
      } else {
        this.groupList = [];
        this.group_code = "";
      }
    },
    // 获取主题列表
    async getTopicList() {
      const { code, data } = await api.getTopicList();

      if (code === 0) {
        this.topicList = data;
        this.topicId = this.topicList[0].topic_id;
      } else {
        this.topicList = [];
      }
    },
    // 获取数据
    async getData() {
      const {
        group_code,
        start_time,
        end_time,
        activeTabValue,
        activeBtnTabValue,
        pagination,
      } = this;
      const { current, page_size } = pagination;

      this.columns = columnsList[activeTabValue][activeBtnTabValue];
      this.dataSource = [];

      this.loading = true;
      try {
        let params = {
          group_code,
          start_time,
          end_time,
          current,
          page_size,
        };

        if (activeTabValue === 0 && activeBtnTabValue === 2) {
          // 事件营销激励(直播)
          params.keyword = this.keyword;
        } else if (activeTabValue === 1 && activeBtnTabValue === 2) {
          // 事件营销激励(视频)
          params.topic_id = this.topicId;
        }

        const { code, data } = await api[
          apiList[activeTabValue][activeBtnTabValue]
        ](params);

        if (code === 0) {
          if (activeTabValue === 1 && activeBtnTabValue === 1) {
            // 爆款激励(视频)
            this.dataSource = data.page_info.list.map((item, index) => {
              return {
                ...item,
                key: index,
                sort: index + 1 + (current - 1) * page_size,
              };
            });
            this.pagination = data.page_info.pagination;
          } else {
            this.dataSource = data.list.map((item, index) => {
              return {
                ...item,
                key: index,
                sort: index + 1 + (current - 1) * page_size,
              };
            });
            this.pagination = data.pagination;
          }
          this.loading = false;

          if (activeTabValue === 0 && activeBtnTabValue === 0) {
            // 基础激励(直播)
            this.getBasedIncentiveLiveCount({
              group_code,
              start_time,
              end_time,
            });
          } else if (activeTabValue === 0 && activeBtnTabValue === 1) {
            // 爆款激励(直播)
            this.getHotLiveCount({ group_code, start_time, end_time });
          } else if (activeTabValue === 1 && activeBtnTabValue === 0) {
            // 基础激励(视频)
            this.getBasedIncentiveAwemeCount({
              group_code,
              start_time,
              end_time,
            });
          } else if (activeTabValue === 1 && activeBtnTabValue === 1) {
            // 爆款激励(视频)
            this.getHotAwemeCount(data);
          }
        } else {
          this.dataSource = [];
          this.pagination = { current: 1, page_size: 10, total: 0 };
          this.loading = false;
        }
      } catch (e) {
        this.dataSource = [];
        this.pagination = { current: 1, page_size: 10, total: 0 };
        this.loading = false;
      }
    },
    // 基础激励数据(直播)
    async getBasedIncentiveLiveCount(params) {
      try {
        const { code, data } = await api.getBasedIncentiveLiveCount(params);

        if (code === 0) {
          this.contentData = data_0_0.map((item) => {
            return { ...item, value: data[item.key] };
          });
        } else {
          this.contentData = { ...data_0_0 };
        }
      } catch (e) {
        this.contentData = { ...data_0_0 };
      }
    },
    // 爆款激励数据(直播)
    async getHotLiveCount(params) {
      try {
        const { code, data } = await api.getHotLiveCount(params);

        if (code === 0) {
          this.contentData = data_0_1.map((item) => {
            return { ...item, value: data[item.key] };
          });
        } else {
          this.contentData = { ...data_0_1 };
        }
      } catch (e) {
        this.contentData = { ...data_0_1 };
      }
    },
    // 基础激励数据(视频)
    async getBasedIncentiveAwemeCount(params) {
      try {
        const { code, data } = await api.getBasedIncentiveAwemeCount(params);

        if (code === 0) {
          this.contentData = data_1_0.map((item) => {
            return { ...item, value: data[item.key] };
          });
        } else {
          this.contentData = { ...data_1_0 };
        }
      } catch (e) {
        this.contentData = { ...data_1_0 };
      }
    },
    // 爆款激励数据(视频)
    getHotAwemeCount(data) {
      this.contentData = data_1_1.map((item) => {
        return { ...item, value: data[item.key] };
      });
    },
    // 处理Tabs改变
    handleTabsChange(val) {
      this.activeTabValue = val;
      this.pagination = { ...pagination };
      this.getData();
    },
    // 处理BtnTabs改变
    handleBtnTabsChange(val) {
      this.activeBtnTabValue = val;
      this.pagination = { ...pagination };
      this.getData();
    },
    // 处理时间范围类型改变
    handleTypeChange(type) {
      this.dateType = type;
    },
    // 处理时间范围改变
    handleDateChange(val) {
      this.start_time = val.startTime;
      this.end_time = val.endTime;
      this.pagination = { ...pagination };
      this.getData();
    },
    // 处理分组改变
    handleGroupChange() {
      this.pagination = { ...pagination };
      this.getData();
    },
    // 处理关键字搜索
    handleKeywordSearch() {
      this.pagination = { ...pagination };
      this.getData();
    },
    // 处理话题改变
    handleTopicChange() {
      this.pagination = { ...pagination };
      this.getData();
    },
    // 处理数据下载
    handleDataDownload() {
      const {
        group_code,
        start_time,
        end_time,
        activeTabValue,
        activeBtnTabValue,
      } = this;
      let params = {
        group_code,
        start_time,
        end_time,
      };

      if (activeTabValue === 0 && activeBtnTabValue === 2) {
        // 事件营销激励(直播)
        params.keyword = this.keyword;
      } else if (activeTabValue === 1 && activeBtnTabValue === 2) {
        // 事件营销激励(视频)
        params.topic_id = this.topicId;
      }

      exportTable(
        this.$http,
        params,
        "hongqi",
        exportApiList[activeTabValue][activeBtnTabValue],
        "filename"
      );
    },
    // 处理分页改变
    handlePaginationChange(page, pageSize) {
      this.pagination.current = page;
      this.pagination.page_size = pageSize;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
}

.platform-container {
  padding: 0;
}

.tabs {
  margin: 20px 0 0;
}

.tools {
  display: flex;
  justify-content: space-between;
  margin: 18px 0 0;
}

.content {
  margin: 20px 0 0;
  padding: 20px 0 0px;
  background: #2b2f3b;
  border-radius: 16px;
  height: calc(100vh - 336px);
}

.content-title {
  display: flex;
  align-items: center;
  padding: 0 20px;

  &__left,
  &__right {
    flex: 1;
  }

  &__right {
    text-align: right;
  }

  .title,
  .sub-title {
    font-size: 18px;
  }

  .title {
    margin: 0 10px;
    color: #ffca5c;
  }

  .sub-title {
    color: #fff;
  }

  .icon-tips {
    margin: 0 0 0 0;
    font-size: 16px;
    cursor: pointer;
  }

  .btn-download {
    height: 40px;
    color: #d0d6e6;
    font-size: 12px;
    background-color: #3c4252;
    border-color: #3c4252;
    border-radius: 8px;

    .icon-download {
      display: inline-block;
      margin: 0 8px 0 0;
      width: 16px;
      height: 16px;
      vertical-align: middle;
      background-image: url("../../assets/icon_cloud_download.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    &:active {
      color: #fff;
      background-color: #363b4a;
      border-color: #363b4a;

      .icon-download {
        background-image: url("../../assets/icon_cloud_download_active.png");
      }
    }
  }

  .select-group /deep/ {
    margin: 0 0 0 10px;
    width: 160px;
    font-size: 12px;

    .ant-select-selection {
      background-color: #3c4252 !important;
    }

    .ant-select-selection-selected-value {
      color: #d0d6e6 !important;
    }
  }
}

.content-data {
  display: flex;
  margin: 18px 0 0;
  padding: 0 30px;

  .data-item {
    margin: 0 30px 0 0;
    width: 112px;

    &__label {
      color: #8890a6;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;

      .icon-tips {
        margin: 0 0 0 4px;
        font-size: 12px;
        cursor: pointer;
      }
    }

    &__value {
      margin: 10px 0 0;
      font-size: 0;

      .num,
      .suffix {
        color: #ffca5c;
        font-size: 22px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 26px;
      }

      .suffix {
        font-weight: normal;
      }
    }
  }
}

.container .content-search /deep/ {
  margin: 18px 0 0;
  padding: 0 30px;

  .input {
    .ant-input {
      width: 200px;
      height: 36px;
      background-color: #3c4252 !important;
      border-color: #3c4252 !important;
      border-radius: 18px;
    }
  }

  .select {
    width: 160px;
    font-size: 12px;

    .ant-select-selection {
      background-color: #3c4252 !important;
    }

    .ant-select-selection-selected-value {
      color: #d0d6e6 !important;
    }
  }
}

.container .content-table {
  margin: 20px 0 0;
  border-top: 1px solid #323744;

  &--normal /deep/ {
    .ant-table-thead {
      & > tr > th {
        height: 70px;
        background-color: #2b2f3b !important;
        border-bottom: 1px solid #323744;
      }
    }

    .ant-table-tbody {
      & > tr > td {
        padding: 21px 7px !important;
        color: #fff;
        border-bottom: 1px solid #323744;
      }
    }
  }

  &--group /deep/ {
    .ant-table-thead {
      & > tr {
        & > th {
          background-color: #2b2f3b !important;
          border-bottom: 1px solid #323744;
        }

        &:first-child {
          & > th {
            padding: 0 7px;
            height: 20px;

            &:nth-child(4) {
              color: #ffca5c !important;
              background-color: rgba(#ffca5c, 0.04) !important;
            }

            &:nth-child(5) {
              color: #ffa05c !important;
              background-color: rgba(#ffa05c, 0.04) !important;
            }
          }
        }

        &:last-child {
          & > th {
            .ant-table-header-column {
              & > div {
                margin: -10px 0 0;
              }
            }
          }
        }
      }
    }

    .ant-table-tbody {
      & > tr > td {
        padding: 21px 7px !important;
        color: #fff;
        border-bottom: 1px solid #323744;
      }
    }
  }

  .color {
    color: #8890a6;
  }

  .color1 {
    color: #ffca5c;
  }

  .color2 {
    color: #8baffe;
  }

  .color3 {
    color: #ff9f5c;
  }
}

.container .aft-pagination /deep/ {
  margin: 16px 0 0;
  text-align: right;

  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0 20px 0 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background-color: #2b2f3b;
    border-radius: 8px;
  }

  .ant-pagination-next {
    margin: 0 0 0 10px;
  }

  .ant-pagination-item {
    margin-right: 0;
    margin: 0 0 0 -10px;
    min-width: 46px;
    height: 36px;
    line-height: 36px;
    background-color: #2b2f3b;
    border-color: #2b2f3b;
    border-radius: 8px;
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin: 0 0 0 -10px;
    width: 46px;
    height: 36px;
    line-height: 36px;
    background-color: #2b2f3b;
    border-radius: 8px;
  }
}
</style>
