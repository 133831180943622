<template>
  <div>
    <div class="title">视频详情</div>
    <div class="main-container">
      <div class="main-container-left" v-if="videoDetail">
        <div class="main-container-left-card">
          <div class="top">
            <div class="img-div">
              <WebpImage
                :scale="'3-4'"
                :src="videoDetail.aweme_cover"
                :width="'72px'"
                :height="'96px'"
                :radius="'0px'"
              />
            </div>
            <div class="mask-play" @click="openVideo(videoDetail)">
              <svg-icon :icon-class="'play'" class="mask-icon" />
            </div>
            <div>
              <a-tooltip>
                <template slot="title">{{
                  videoDetail.aweme_title || "-"
                }}</template>
                <div class="ellipsis">
                  {{ videoDetail.aweme_title || "-" }}
                </div>
              </a-tooltip>
              <div style="display: flex">
                <WebpImage
                  :src="videoDetail.avatar"
                  :width="'24px'"
                  :height="'24px'"
                  :radius="'50%'"
                  style="margin-right: 8px"
                />
                <a-tooltip>
                  <template slot="title">{{
                    videoDetail.nickname || "-"
                  }}</template>
                  <span style="width: calc(100% - 10px)" class="ellipsis-1">{{
                    videoDetail.nickname || "-"
                  }}</span>
                </a-tooltip>
              </div>
            </div>
          </div>
          <div class="card-detail">
            <div>
              <span class="card-detail-label">发布平台: </span
              >{{ videoDetail.platform_name || "-" }}
            </div>
            <div>
              <span class="card-detail-label">发布时间: </span
              >{{ videoDetail.publish_time || "-" }}
            </div>
            <div v-if="getTagByAwemeType(videoDetail.aweme_type)">
              <span class="card-detail-label">视频类型: </span>
              <span class="card-detail-tag">{{
                getTagByAwemeType(videoDetail.aweme_type)
              }}</span>
            </div>
            <div>
              <span class="card-detail-label">相关品牌: </span
              ><span class="important">{{
                videoDetail.brand_names || "-"
              }}</span>
            </div>
            <div style="margin-bottom: 32px">
              <span class="card-detail-label">相关车系: </span
              ><span class="important2">{{
                videoDetail.serise_names || "-"
              }}</span>
            </div>
            <!-- 
            <div>
              <span class="grey-text">数据表现 | 更新于2021-01-01</span
              ><span class="important">更新</span>
            </div> -->
            <a-tag
              color="#f50"
              v-if="videoDetail && videoDetail.aweme_status == 0"
            >
              已删除/隐藏
            </a-tag>

            <div class="count-div">
              <div
                class="count-item"
                v-for="(item, index) in videoDetail.count_data"
                :key="index"
              >
                <span>
                  {{ item.title }}
                </span>
                <span class="important3">{{ item.number | changeNum }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-container-right">
        <a-tabs default-active-key="tab1">
          <a-tab-pane
            key="tab1"
            tab="趋势表现"
            style="height: calc(100vh - 214px); overflow-y: auto"
          >
            <div class="tab1-time">
              *监测始于 {{ videoDetail.publish_time }}
            </div>
            <div class="chart-top">
              <span class="chart-title"
                ><svg-icon
                  :icon-class="'title'"
                  class="card-title-icon"
                />获赞变化趋势</span
              >
              <a-radio-group :value="diggBtn" @change="changeDiggChart">
                <a-radio-button value="total"> 累计总量 </a-radio-button>
                <a-radio-button value="increment"> 每日增量 </a-radio-button>
              </a-radio-group>
            </div>
            <div class="chart-div">
              <my-chart
                :options="
                  getLineOption(
                    videoDetail.digg_tendency.time,
                    diggBtn == 'total'
                      ? videoDetail.digg_tendency.total
                      : videoDetail.digg_tendency.increment
                  )
                "
                :id="'line-chart-digg'"
              ></my-chart>
            </div>

            <div class="chart-top">
              <span class="chart-title"
                ><svg-icon
                  :icon-class="'title'"
                  class="card-title-icon"
                />评论变化趋势</span
              >
              <a-radio-group :value="commentBtn" @change="changeCommentChart">
                <a-radio-button value="total"> 累计总量 </a-radio-button>
                <a-radio-button value="increment"> 每日增量 </a-radio-button>
              </a-radio-group>
            </div>
            <div class="chart-div">
              <my-chart
                :options="
                  getLineOption(
                    videoDetail.comment_tendency.time,
                    commentBtn == 'total'
                      ? videoDetail.comment_tendency.total
                      : videoDetail.comment_tendency.increment
                  )
                "
                :id="'line-chart-comment'"
              ></my-chart>
            </div>

            <div class="chart-top">
              <span class="chart-title"
                ><svg-icon
                  :icon-class="'title'"
                  class="card-title-icon"
                />分享变化趋势</span
              >
              <a-radio-group :value="shareBtn" @change="changeShareChart">
                <a-radio-button value="total"> 累计总量 </a-radio-button>
                <a-radio-button value="increment"> 每日增量 </a-radio-button>
              </a-radio-group>
            </div>
            <div class="chart-div">
              <my-chart
                :options="
                  getLineOption(
                    videoDetail.share_tendency.time,
                    shareBtn == 'total'
                      ? videoDetail.share_tendency.total
                      : videoDetail.share_tendency.increment
                  )
                "
                :id="'line-chart-share'"
              ></my-chart>
            </div>

            <div class="chart-top">
              <span class="chart-title"
                ><svg-icon
                  :icon-class="'title'"
                  class="card-title-icon"
                />播放变化趋势</span
              >
              <a-radio-group :value="playBtn" @change="changePlayChart">
                <a-radio-button value="total"> 累计总量 </a-radio-button>
                <a-radio-button value="increment"> 每日增量 </a-radio-button>
              </a-radio-group>
            </div>
            <div class="chart-div">
              <my-chart
                :options="
                  getLineOption(
                    videoDetail.play_tendency.time,
                    playBtn == 'total'
                      ? videoDetail.play_tendency.total
                      : videoDetail.play_tendency.increment
                  )
                "
                :id="'line-chart-play'"
              ></my-chart>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import chartLine from "./chartLine";
import deepClone from "@/utils/clone-utils";
import WebpImage from "@/components/WebpImage/WebpImage";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers("globalDetail");

export default {
  name: "modelDetail",
  components: { MyChart, WebpImage },
  data() {
    return {
      msg: "视频详情",
      diggBtn: "total",
      commentBtn: "total",
      shareBtn: "total",
      playBtn: "total",
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getVideoDetail({
        aweme_id: this.$route.query.id,
        platform: this.$route.query.platform,
      });
    } else {
      console.log("没有获取到视频信息");
    }
  },
  computed: {
    ...mapState({
      videoDetail: (state) => state.videoDetail,
    }),
  },
  methods: {
    ...mapActions(["getVideoDetail"]),
    ...mapMutations(["changeState"]),
    changeDiggChart(e) {
      console.log(e);
      this.diggBtn = e.target.value;
    },

    changeCommentChart(e) {
      console.log(e);
      this.commentBtn = e.target.value;
    },

    changeShareChart(e) {
      console.log(e);
      this.shareBtn = e.target.value;
    },
    changePlayChart(e) {
      console.log(e);
      this.playBtn = e.target.value;
    },

    getLineOption(xData, yData) {
      let option = deepClone(chartLine);
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      return option;
    },
    getTagByAwemeType(type) {
      if (this.$route.query.platform == "douyin") {
        if (type == 2) {
          return "图片视频";
        } else if (type == 61) {
          return "贴图视频";
        } else if (type == 68) {
          return "图集视频";
        }
      }
      if (this.$route.query.platform == "kuaishou") {
        if (type == "pic") {
          return "图册视频";
        }
      }
      if (this.$route.query.platform == "dongchedi") {
        if (type == 1) {
          return "文章视频";
        }
      }
      return false;
    },
    openVideo(detail) {
      console.log(detail);
      if (detail.aweme_status == 0) {
        this.$message.warning("此视频已被删除/隐藏，无法播放！");
        return false;
      }

      if (this.getTagByAwemeType(detail.aweme_type)) {
        this.$message.warning(
          `此为${this.getTagByAwemeType(detail.aweme_type)}，不可播放！`
        );
        return false;
      }
      if (detail.aweme_url || detail.share_url || detail.aweme_id) {
        // let newUrl = url.replace(/^http:\/\//i, "https://");
        // let newUrl = url;
        if (this.$route.query.platform == "dongchedi") {
          if (detail.aweme_url) {
            if (detail.is_aweme == 1) {
              window.open(detail.aweme_url);
              return false;
            } else if (detail.is_aweme == 2) {
              window.open(detail.share_url);
              return false;
            } else if (detail.is_aweme == 3) {
              this.$message.warning('暂无视频，请联系管理员...')
              return false;
            }
          } else {
            window.open(detail.share_url);
            return false;
          }
        }
        let url = `https://www.douyin.com/video/${detail.aweme_id}`;
        let newUrl = detail.aweme_url || detail.share_url || url;
        console.log(newUrl);
        if (this.$route.query.platform != "douyin" && !detail.aweme_url) {
          this.$message.warning("暂无视频");
          return false;
        }
        window.open(newUrl);
      } else {
        this.$message.warning("暂无视频");
      }
    },
  },
  beforeDestroy() {
    this.changeState({ data: null, name: "videoDetail" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.main-container {
  width: 100%;
  @include font_color("font_color2");
  display: flex;
  padding: 0 24px;
  flex-direction: row;
  &-left {
    width: 260px;
    height: calc(100vh - 160px);
    margin-right: 24px;
    &-card {
      padding: 24px 16px 24px 24px;
      height: 100%;
      background: #242832;
      border-radius: 8px;
      .top {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-bottom: 20px;
        position: relative;

        .mask-play {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 72px;
          height: 96px;
          cursor: pointer;
          @include background_color("background_color20");
          position: absolute;
          top: 0px;
          left: 0px;
          .mask-icon {
            @include font_color("font_color1");
            font-size: 40px;
          }
        }

        .ellipsis {
          width: 138px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .ellipsis-1 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          @include font_color("font_color3");
        }
        > img {
          width: 72px;
          height: 96px;
          @include border_color("border_color7");
          margin-right: 8px;
        }
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        }

        > .img-div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 72px;
          height: 96px;
          @include background_color("background_color43");
          margin-right: 8px;
          > img {
            width: 72px;
            max-height: 96px;
          }
        }
      }
      .card-detail {
        padding: 12px 0 0px;
        line-height: 30px;
        &-label {
          @include font_color("font_color3");
        }
        .important {
          @include font_color("font_color4");
        }
        .important2 {
          @include font_color("font_color7");
        }
        &-tag {
          width: 64px;
          height: 20px;
          background: rgba(255, 204, 85, 0.3);
          border-radius: 4px;
          font-size: 12px;
          color: #ffcc55;
          line-height: 20px;
          text-align: center;
          margin-top: 5px;
          display: inline-block;
        }
        .count-div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 40px;
          .count-item {
            display: flex;
            flex-direction: column;
            line-height: 20px;
            font-size: 12px;
            width: 50%;
            padding-left: 8px;
            margin-bottom: 24px;
            border-left: 3px solid rgba(0, 0, 0, 0);
            @include border_color("border_color8");
            @include font_color("font_color3");
            .important3 {
              @include font_color("font_color1");
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  &-right {
    width: calc(100% - 284px);
    .tab1-time {
      text-align: right;
      font-size: 12px;
      @include font_color("font_color3");
      margin-bottom: 12px;
    }
    .chart-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .chart-title {
        font-size: 16px;
        @include font_color("font_color2");

        .card-title-icon {
          @include font_color("font_color5");
          font-size: 18px;
          margin: 0 8px;
        }
      }
    }
    .chart-div {
      height: 340px;
      margin-bottom: 32px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color1");
    }
  }
}
</style>
