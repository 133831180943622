<template>
  <div class="effect-detail-container">
    <!-- 顶部 返回按钮 基本信息介绍 -->
    <DetailTitle :skewData="skewData" :rightValue="dateStr" />
    <!-- 统计数据卡片 -->
    <StatisticsCard
      :title="'数据趋势'"
      :cardData="cardData"
      :routerData="{
        name: '闭环管理',
        path: '/dealer/closedLoop',
      }"
      :tooltip="true"
      :code="'xxqt01'"
    />
    <!-- 经销商贡献 -->
    <div class="dealer-contribution">
      <div class="title">经销商贡献</div>
      <div class="chart-main">
        <div
          class="chart-main-item"
          v-for="(item, index) in contributionData"
          :key="index"
        >
          <TitlePie
            :id="item.id"
            :title="{
              title: item.title,
              icon: item.icon,
            }"
            :chartData="pieData[item.key]"
          />
        </div>
      </div>
    </div>
    <!-- 最佳门店 + 4个卡片 -->
    <div class="bottom-main">
      <div class="best-store">
        <BestStore :list="storeList" />
      </div>

      <div class="bottom-main-right">
        <ClueBestCard :data="someStoreData" />
      </div>
    </div>
  </div>
</template>

<script>
import DetailTitle from "./components/DetailTitle";
import StatisticsCard from "./components/StatisticsCard";
import BestStore from "./components/BestStore2";
import ClueBestCard from "./components/ClueBestCard";

import deepClone from "@/utils/clone-utils";
import chartLine from "./options/chartLine.js";
import TitlePie from "@/components/chart/TitlePie";
import api from "@/api/effectDetail.js";
// 统计数据卡片展示文案
const cardData = {
  day: [
    {
      name: ["私信", "新增", "环比"],
      data: [],
    },
    {
      name: ["声量线索", "新增", "环比"],
      data: [],
    },
    {
      name: ["销售线索", "新增", "环比"],
      data: [],
    },
  ],
  week: [
    {
      name: ["私信", "新增", "环比"],
      data: [],
    },
    {
      name: ["声量线索", "新增", "环比"],
      data: [],
    },
    {
      name: ["销售线索", "新增", "环比"],
      data: [],
    },
  ],
  month: [
    {
      name: ["私信", "新增", "环比"],
      data: [],
    },
    {
      name: ["声量线索", "新增", "环比"],
      data: [],
    },
    {
      name: ["销售线索", "新增", "环比"],
      data: [],
    },
  ],
};
// 经销商贡献
const contributionData = [
  {
    title: "私信",
    icon: "ti-letter",
    id: "chart1",
    key: "private_message",
  },
  {
    title: "声量线索",
    icon: "ti-clue",
    id: "chart2",
    key: "volumn_clue",
  },
  {
    title: "销售线索",
    icon: "ti-sale",
    id: "chart3",
    key: "sale_clue",
  },
];
export default {
  name: "effectDetailClue",
  components: {
    DetailTitle,
    StatisticsCard,
    BestStore,
    TitlePie,
    ClueBestCard,
  },
  data() {
    return {
      skewData: ["留资线索"], // ['活动: T35精英门店运营', '分组: 史蒂夫', '华东大区', '2022-12周', '视频发布']
      dateStr: this.$route.query.dateStr,
      cardData: deepClone(cardData),
      contributionData: deepClone(contributionData),
      pieData: {},
      storeList: [],
      someStoreData: {},
    };
  },
  mounted() {},
  computed: {},
  created() {
    this.setSkewData();
    this.getDataTrend();
    this.getContributionByClue();
    this.getBestStoreByClue();
    this.getSomeStoreByClue();
  },
  methods: {
    // 设置顶部标题
    setSkewData() {
      console.log(this.$route.query);
      this.skewData = ["留资线索"];
      // 大区
      if (this.$route.query.rId && this.$route.query.rName) {
        this.skewData.unshift(`${decodeURIComponent(this.$route.query.rName)}`);
      }
      // 活动
      if (this.$route.query.aId && this.$route.query.aName) {
        this.skewData.unshift(
          `活动: ${decodeURIComponent(this.$route.query.aName)}`
        );
      }
    },
    // 获取页面接口通用参数
    getParams() {
      let body = {
        platform: this.$route.query.platform,
        params: {
          id: this.$route.query.aId,
          region_id: this.$route.query.rId,
        },
      };
      return body;
    },
    // 接口请求 - 数据趋势
    getDataTrend() {
      let body = this.getParams();
      // 恢复初始数据
      this.cardData = deepClone(cardData);
      api.getDataTrendByClue(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          let key = ["day", "week", "month"];
          key.forEach((item) => {
            this.cardData[item][0].data.push(
              data[item].private_message_count_total
            );
            this.cardData[item][0].data.push(data[item].private_message_count);
            this.cardData[item][0].data.push(data[item].private_message_chain);
            this.cardData[item][1].data.push(
              data[item].volumn_clue_count_total
            );
            this.cardData[item][1].data.push(data[item].volumn_clue_count);
            this.cardData[item][1].data.push(data[item].volumn_clue_chain);
            this.cardData[item][2].data.push(data[item].sale_clue_count_total);
            this.cardData[item][2].data.push(data[item].sale_clue_count);
            this.cardData[item][2].data.push(data[item].sale_clue_chain);
          });
        } else {
          console.error("获取数据趋势失败");
        }
      });
    },
    // 接口请求 - 经销商贡献
    getContributionByClue() {
      let body = this.getParams();
      // 恢复初始数据
      api.getContributionByClue(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.pieData = data;
        } else {
          console.error("获取经销商贡献失败");
        }
      });
    },
    // 接口请求 - 最佳门店
    getBestStoreByClue() {
      let body = this.getParams();
      // 恢复初始数据
      this.storeList = [];
      api.getBestStoreByClue(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.storeList = data;
          console.log("storeList", this.storeList);
        } else {
          console.error("获取最佳门店失败");
        }
      });
    },
    // 接口请求 - 最佳直播视频
    getSomeStoreByClue() {
      let body = this.getParams();
      // 恢复初始数据
      api.getSomeStoreByClue(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.someStoreData = data;
        } else {
          console.error("获取最佳门店指标失败");
        }
      });
    },

    // 概览切换tab echart - line
    getOverviewCardOptions(item) {
      if (!item) {
        return false;
      }
      console.log("overviewCardChangeTab", item);
      let xData = item.xData;
      let yData = item.yData;
      let option = deepClone(chartLine);
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      this.lineChartOption = option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.effect-detail-container {
  padding: 0 20px 20px;
  .top-title {
    width: 100%;
    height: 60px;
    background: #fff;
  }
  .dealer-contribution {
    width: 100%;
    height: 390px;
    padding: 27px 20px 0;
    background: #2b2f3b;
    border-radius: 16px;
    margin: 20px 0;
    .title {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
    }
    .chart-main {
      width: 100%;
      height: 336px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-item {
        width: 33%;
        height: 100%;
      }
    }
  }
  .bottom-main {
    width: 100%;
    height: 460px;
    display: flex;
    .best-store {
      width: 310px;
      height: 100%;
      background: #2b2f3b;
      border-radius: 16px;
      margin-right: 20px;
    }
    &-right {
      width: calc(100% - 330px);
      height: 100%;
    }
  }
}
</style>