import echarts from 'echarts'
import filters from '@/filters'
var option = {
  grid: {
    left: "2%",
    top: "50",
    right: "5%",
    bottom: "30",
    containLabel: true,
  },
  legend: {
    show: true,
    itemWidth: 17,
    itemHeight: 6,
    textStyle: {
      color: '#9CA8BA'
    },
    padding: [10, 0]
  },
  xAxis: {
    type: "category",
    axisLabel: {
      show: true,
      textStyle: {
        color: '#9CA8BA',  //更改坐标轴文字颜色
        fontSize: 12     //更改坐标轴文字大小
      }
    },
    data: [],
  },
  yAxis: {
    type: "value",
    splitNumber: 4,
    axisTick: {
      inside: false,
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#9CA8BA',  //更改坐标轴文字颜色
        fontSize: 12     //更改坐标轴文字大小
      },
      formatter: function (value, index) {
        return filters.changeNum(value);
      }
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["rgba(255,255,255,0.2)"],
        type: "dashed",
      },
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#6a7985",
      },
    },
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
      color: '#fff',
    },
    formatter: function (params) {
      let p1_str = ''
      if (params.length > 1) {
        p1_str = `
        <div>
          ${params[1].marker}
          <span>${params[1].seriesName}</span>
          <span>${filters.changeNum(params[1].value)}</span>
        </div>`
      }
      return `<div>
        <div>${params[0].axisValue}</div>
        <div>
          ${params[0].marker}
          <span>${params[0].seriesName}</span>
          <span>${filters.changeNum(params[0].value)}</span>
        </div>`+ p1_str + `
      </div>`
    }
  },
  series: [{
    name: "",
    type: "line",
    smooth: true,
    itemStyle: {
      normal: {
        color: '#FF8000'
      }
    },
    areaStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(255,128,0,0.4)'
        }, {
          offset: 0.8,
          color: 'rgba(255,128,0,0)'
        }], false),
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowBlur: 10
      },
    },
    data: []
  }, {
    name: "",
    type: "line",
    smooth: true,
    itemStyle: {
      normal: {
        color: '#7A91FF'
      }
    },
    areaStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(122,145,255,0.4)'
        }, {
          offset: 0.8,
          color: 'rgba(122,145,255,0)'
        }], false),
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowBlur: 10
      },
    },
    data: []
  }],
}

export default option