<template>
  <div class="card">
    <div class="title-box">
      <div class="title">
        <span>{{ title }}</span
        >
      </div>
      <div>
        <a-select
          v-model="currentKey"
          :suffixIcon="suffixIcon"
          style="width: 160px; height: 40px"
          class="tab-select"
          @change="onChangeHotAwemeType"
        >
          <a-select-option
            :value="item.key"
            v-for="item in selectData"
            :key="item.key"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="chart-box">
      <MyChart :options="options" id="OverviewSelectCardChart" />
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
export default {
  name: "OverviewCard",
  components: {
    MyChart,
  },
  props: {
    title: {
      type: String,
      default: "-",
    },
    titleTooltip: {
      type: String,
      default: "",
    },
    selectData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currentKey: this.selectData[0].key,
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
    };
  },
  watch: {},
  methods: {
    tabClick(key) {
      this.currentKey = key;
      console.log(key);
    },

    //直播表现 - 爆款直播 - 播放量修改
    onChangeHotAwemeType() {
      console.log(this.currentKey);
      this.$emit('changeSelect', this.currentKey)
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

.card {
  padding: 20px;
  border-radius: 16px;
  @include background_color("background_color2");
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  .title {
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 500;
      @include font_color("font_color20");
      &.yellow {
        @include font_color("font_color4");
      }
    }
  }
}
.tab-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &-1 .active {
    @include border_color("border_color4");
    .title span {
      @include font_color("font_color4");
    }
    .num {
      @include font_color("font_color4");
    }
  }
  &-2 .active {
    @include border_color("border_color22");
    .title span {
      @include font_color("font_color44");
    }
    .num {
      @include font_color("font_color44");
    }
  }
  &-3 .active {
    @include border_color("border_color23");
    .title span {
      @include font_color("font_color27");
    }
    .num {
      @include font_color("font_color27");
    }
  }
  .box {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 25px;
    min-width: 90px;
    line-height: 1;
    cursor: pointer;
    border-left: 2px solid transparent;
    transition: all 0.3s;
    .title {
      margin-bottom: 10px;
      font-size: 12px;
      white-space: nowrap;
      @include font_color("font_color23");
    }
    .num {
      font-size: 22px;
      @include font_color("font_color20");
      span {
        font-weight: bold;
      }
    }
    .tooltip {
      font-size: 12px;
      margin: 0 0 0 4px !important;
    }
  }
}

.chart-box {
  height: calc(100% - 60px);
  margin-top: 20px;
}

/deep/ .title-box .tab-select .ant-select-selection {
    background-color: #3C4252!important;
}
</style>
