import echarts from 'echarts'
import filters from '@/filters'
var option = {
  backgroundColor: "rgba(0,0,0,0)",
  grid: {
    "left": "70",
    "top": "10",
    "right": "100",
    "bottom": "60"
  },
  tooltip: {
    show: true,    // 是否显示提示框组件
    trigger: 'axis',
  },
  position: function (point, params, dom, rect, size) {
    var x = 0; // x坐标位置
    var y = 0; // y坐标位置
    // 当前鼠标位置
    var pointX = point[0];
    var pointY = point[1];
    // 提示框大小
    var boxWidth = size.contentSize[0];
    var boxHeight = size.contentSize[1];

    // boxWidth > pointX 说明鼠标左边放不下提示框
    if (boxWidth > pointX) {
      x = pointX + 10;
    } else { // 左边放的下
      x = pointX - boxWidth - 10;
    }

    // boxHeight > pointY 说明鼠标上边放不下提示框
    if (boxHeight > pointY) {
      y = 5;
    } else { // 上边放得下
      y = pointY - boxHeight;
    }
    return [x, y]
  },
  xAxis: {
    type: 'category',
    boundaryGap: ['20%', '20%'],
    axisLine: {
      show: true, //隐藏x轴
    },
    axisTick: {
      show: true,  //刻度线
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6', // 坐标轴文字颜色
        fontSize: 10, // 坐标轴文字大小
        padding: [0, 10, 0, 0]
      },
    },
    data: []
  },
  yAxis: [
    {
      type: 'value',
      position: 'left',
      axisTick: {
        show: true,  //刻度线
      },
      axisLine: {
        show: false, //隐藏y轴
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: '#8890A6', // 坐标轴文字颜色
          fontSize: 10, // 坐标轴文字大小
          padding: [0, 10, 0, 0]
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["rgba(255,255,255,0.04)"],
        },
      },
    }
  ],
  series: [
    {
      name: '',
      data: [],
      type: 'bar',
      itemStyle: {
        normal: {
          color: '#FFCA5C',
          barBorderRadius: [8, 8, 0, 0]
        },
      },
      barMaxWidth: 50,
    }
  ]
};
export default option