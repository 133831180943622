import echarts from 'echarts'
let color = ['#FAA065', '#7A91FF', '#22C5E6', '#F6E67B', '#FF8E52', '#FFCF83'];
let echartData = [{
    name: "S类",
    value: "3720"
}, {
    name: "A类",
    value: "3720"
},
{
    name: "B类",
    value: "2920"
},
{
    name: "C类",
    value: "2200"
},
{
    name: "D类",
    value: "1420"
}
];

var option = {
    backgroundColor: 'rgba(0,0,0,0)',
    color: color,
    tooltip: {
        trigger: 'item'
    },
    series: [{
        type: 'pie',
        radius: ['45%', '60%'],
        center: ['50%', '50%'],
        data: echartData,
        hoverAnimation: false,
        itemStyle: {
            normal: {
                borderColor: 'rgba(0,0,0,0)',
                borderWidth: 2
            }
        },
        labelLine: {
            normal: {
                length: 10,
                length2: 10,
                lineStyle: {
                    color: '#e6e6e6'
                }
            }
        },
        label: {
            normal: {
                formatter: params => {
                    return (
                        '{icon|●}{name|' + params.name + '}'
                    );
                },
                padding: [0, -10, 0, -10],
                rich: {
                    icon: {
                        fontSize: 16
                    },
                    name: {
                        fontSize: 14,
                        padding: [0, 10, 0, 4],
                        color: '#666666'
                    },
                    value: {
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#333333'
                    }
                }
            }
        },
    }]
}

export default option