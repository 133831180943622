var option = {
  color: ['#FFCA5C', '#F76965'],
  title: [{
    text: '直播组件',
    left: 'center',
    top: '48%',
    padding: [0, 0],
    textStyle: {
      color: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
      align: 'center',
      fontFamily: 'DIN'
    }
  }],
  series: [
    {
      name: '直播组件',
      type: 'pie',
      startAngle: 135,
      radius: ['35%', '65%'],
      center: ['50%', '50%'],
      clockwise: true,
      label: {
        normal: {
          formatter: function (params, ticket, callback) {
            return `${params.data.name}: ${params.percent}%`
          },
          padding: [0, 0],
        },
      },
      labelLine: {
        length: 5,
        length2: 10,
      },
      itemStyle: {
        normal: {
          shadowColor: 'rgba(0,0,0,0.4)',
          shadowBlur: 15,
        },
      },
      data: [],
    },
  ],
}

export default option