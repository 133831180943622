import echarts from 'echarts'
import filters from '@/filters'
var option = {
    backgroundColor: "rgba(0,0,0,0)",
    grid: {
        "left": "70",
        "top": "30",
        "right": "70",
        "bottom": "20"
    },
    tooltip: {
        show: true,    // 是否显示提示框组件
        trigger: 'axis',
        backgroundColor: "rgba(0,0,0,0)", //设置背景图片 rgba格式
    },
    position: function (point, params, dom, rect, size) {
      var x = 0; // x坐标位置
      var y = 0; // y坐标位置
      // 当前鼠标位置
      var pointX = point[0];
      var pointY = point[1];
      // 提示框大小
      var boxWidth = size.contentSize[0];
      var boxHeight = size.contentSize[1];

      // boxWidth > pointX 说明鼠标左边放不下提示框
      if (boxWidth > pointX) {
        x = pointX + 10;
      } else { // 左边放的下
        x = pointX - boxWidth - 10;
      }

      // boxHeight > pointY 说明鼠标上边放不下提示框
      if (boxHeight > pointY) {
        y = 5;
      } else { // 上边放得下
        y = pointY - boxHeight;
      }
      return [x, y]
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
            show: false, //隐藏x轴
        },
        axisTick: {
            show: false,  //刻度线
        },
        axisLabel: {
            show: false, //隐藏刻度值
        },
        data: []
    },
    yAxis: [
        {
            type: 'value',
            position: 'right',
            axisTick: {
                show: false,  //刻度线
            },
            axisLine: {
                show: false, //隐藏y轴
            },
            axisLabel: {
                show: false,
                textStyle: {
                    color: '#8890A6', // 坐标轴文字颜色
                    fontSize: 10, // 坐标轴文字大小
                    padding: [0, 10, 0, 0]
                },
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: ["rgba(255,255,255,0.04)"],
                },
            },
        }, {
            type: 'value',
            position: 'left',
            boundaryGap: false,
            axisLine: {
                show: false, //隐藏x轴
            },
            axisTick: {
                show: false,  //刻度线
            },
            axisLabel: {
                show: false,
                textStyle: {
                    color: '#8890A6', // 坐标轴文字颜色
                    fontSize: 10, // 坐标轴文字大小
                    padding: [0, 10, 0, 0]
                },
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: ["rgba(255,255,255,0.04)"],
                },
            },
            scale: true,/*按比例显示*/
        },
    ],
    series: [
        {
            name: '',
            data: [],
            type: 'bar',
            itemStyle: {
                normal: {
                    color: '#815CFF',
                    barBorderRadius: [8, 8, 0, 0]
                },
            },
            yAxisIndex: 1,
            barMaxWidth: 44,
        },
        {
            name: '环比',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            showSymbol: true,
            symbolSize: 4,
            itemStyle: {
                normal: {
                    color: 'rgba(255, 202, 92, 0.4)'
                }
            },
            data: []
        },
    ]
};
export default option