<template>
  <a-tooltip>
    <template slot="title" v-if="title">
      <pre>{{ title }}</pre>
    </template>
    <div class="text-limit">
      <span class="hide-text" ref="onlyOne">...</span>
      <pre class="hide-text" ref="hideText"></pre>
      <pre>{{ resultStr }}</pre>
    </div>
  </a-tooltip>
</template>

<script>
export default {
  name: "TextLimit",
  props: {
    value: {
      type: String,
    },
    // 显示几行
    limit: {
      type: Number,
      default: 1,
    },
    // 尾巴，默认省略 ...
    tail: {
      type: String,
    },
  },

  watch: {
    value() {
      this.$nextTick(() => {
        this.init();
      });
    },
  },

  data() {
    return {
      resultStr: this.value,
      resultTail: this.tail || "...",
      tooltipDisabled: true,
    };
  },

  computed: {
    title() {
      return this.tooltipDisabled ? "" : this.value;
    },
  },

  methods: {
    init() {
      // 如果没有传进来值，不用处理
      if (!this.value) {
        return;
      }
      // 获取每一行文本的高度
      let lineHeight = this.$refs.onlyOne.clientHeight;
      // 获取隐藏文本标签（通过往这个标签添加文本判断实时的高度来处理最终需要显示的文本）
      let hideText = this.$refs.hideText;
      // 隐藏文本标签添加传进来的文本
      hideText.innerHTML = this.value;
      // 获取隐藏文本高度
      let currentHeight = hideText.clientHeight;
      // 如果小于等于需要限制的行数，不用处理
      let lineNum = Math.ceil(currentHeight / lineHeight);
      if (lineNum <= this.limit) {
        this.resultStr = this.value;
        return;
      }

      // 允许提示
      this.tooltipDisabled = false;
      // 文本长度
      let len = this.value.length;
      // lineNum - 1 是为了获取到的值大于限制行，好做 -- 处理
      let end = Math.ceil(len * (this.limit / (lineNum - 1)));

      // 不断减掉一个字，直达行数达到要求
      while (lineNum > this.limit) {
        end--;
        hideText.innerHTML = this.value.slice(0, end) + this.resultTail;
        currentHeight = this.$refs.hideText.clientHeight;
        lineNum = Math.ceil(currentHeight / lineHeight);
      }

      this.resultStr = this.value.slice(0, end) + this.resultTail;
    },
  },

  mounted() {
    this.init();
    this.$root.$on("textellipsisResize", this.init);
  },
  beforeDestroy() {
    this.$root.$off("textellipsisResize", this.init);
  },
};
</script>

<style scoped lang="scss">
pre {
  width: 100%;
  margin: 0;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
.text-limit {
  position: relative;
  white-space: pre-wrap !important;

  .hide-text {
    position: absolute;
    width: 100%;
    visibility: hidden;
    white-space: pre-wrap !important;
  }
}
</style>
