<template>
  <div class="header-container">
    <span class="title">智能复盘助手</span>
    <div class="statistics-box">
      <div class="statistics-box-item">
        <div class="label">直播时长</div>
        <div class="value">
          {{ liveDetail.statistics_live_time | formatDuration }}
        </div>
      </div>
      <div class="statistics-box-item">
        <div class="label">评论总数</div>
        <div class="value">
          {{ liveDetail.statistics_total_comment_count | changeNum }}
        </div>
      </div>
      <div class="statistics-box-item">
        <div class="label">观看人数</div>
        <div class="value">
          {{ liveDetail.statistics_total_watch_user_count | changeNum }}
        </div>
      </div>
      <div class="statistics-box-item">
        <div class="label">观看人次</div>
        <div class="value">
          {{ liveDetail.statistics_total_watch_user_time | changeNum }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveAssistantHeader",
  components: {},
  props: {
    liveDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
  },
  data() {
    return {};
  },

  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.header-container {
  height: 78px;
  width: 100%;
  background: #1C1E27;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px;
  .title {
    font-size: 24px;
    font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
    font-weight: bold;
    color: #ffffff;
  }
  .statistics-box {
    display: flex;
    &-item {
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
      .label {
        font-size: 14px;
        color: #8890a6;
        line-height: 22px;
      }
      .value {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
}
</style>