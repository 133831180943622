<template>
  <div>
    <platform-header
      title="矩阵传播价值"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <a-month-picker
        v-model="searchTime"
        class="aft-month-picker default"
        placeholder="Select month"
        :disabled-date="pickerOptions"
        @change="onChange"
        locale="zh-cn"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-month-picker>
    </div>
    <div class="main-container">
      <div class="card-list">
        <div
          class="card-item"
          v-for="(item, index) in matrixData.author_level_infos"
          :key="index"
        >
          <div style="height: 50px; line-height: 50px" class="border-bottom">
            <div class="card-item-left">
              <span
                :class="
                  item.level == 'S'
                    ? 'font-color6 f20'
                    : item.level == 'A'
                    ? 'font-color5 f20'
                    : item.level == 'B'
                    ? 'font-color4 f20'
                    : 'font-color1 f20'
                "
                >{{ item.level }}</span
              >
              级
            </div>
            <div class="card-item-right">
              <span class="font-color1 f14">{{ item.count }}</span> 主播
            </div>
          </div>
          <div style="height: 63px" class="border-bottom">
            <div class="card-item-left col">
              <div>{{ currentTab | returnWatchText }}</div>
              <div class="f14 font-color2">
                {{ item.live_watch_count | changeNum }}
              </div>
            </div>
            <div class="card-item-right col">
              <div>占比</div>
              <div class="f14 font-color2">
                {{ roundWithPrecision(item.live_watch_ratio * 100, 1) }}%
              </div>
            </div>
          </div>
          <div style="height: 63px" class="border-bottom">
            <div class="card-item-left col">
              <div>营销价值</div>
              <div class="f14 font-color5">
                {{ item.marketing_value | changeNum }}
              </div>
            </div>
            <div class="card-item-right col">
              <div>占比</div>
              <div class="f14 font-color2">
                {{ roundWithPrecision(item.marketing_value_ratio * 100, 1) }}%
              </div>
            </div>
          </div>
          <!-- <div style="height: 73px">
            <div class="card-item-left col">
              <div>销售价值</div>
              <div class="f14 font-color4">
                {{ item.sale_value | changeNum }}
              </div>
            </div>
            <div class="card-item-right col">
              <div>占比</div>
              <div class="f14 font-color2">
                {{ (item.sale_value_ratio * 100).toFixed(1) }}%
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="info-list">
        <div class="info-item">
          <div>
            <span>粉丝总量</span>
            <span>{{ matrixData.fans_count | changeNum }}</span>
          </div>
          <div>
            <span>月度增长</span>
            <span>{{ matrixData.fans_increase | changeNum }}</span>
          </div>
          <!-- <img src="@/assets/img/matrix-card1.png" class="icon" alt="" /> -->
        </div>
        <div class="info-item">
          <div>
            <span>{{ currentTab | returnWatchText }}</span>
            <span>{{ matrixData.live_watch_count | changeNum }}</span>
          </div>
          <div>
            <span>直播场次</span>
            <span>{{ matrixData.live_count | changeNum }}</span>
          </div>
          <!-- <img src="@/assets/img/matrix-card2.png" class="icon" alt="" /> -->
        </div>
        <div class="info-item">
          <div>
            <span>视频播放</span>
            <span>{{ matrixData.aweme_play_volume | changeNum }}</span>
          </div>
          <div>
            <span>视频发布</span>
            <span>{{ matrixData.aweme_count | changeNum }}</span>
          </div>
          <!-- <img src="@/assets/img/matrix-card3.png" class="icon" alt="" /> -->
        </div>
        <div class="info-item">
          <div>
            <span>营销价值</span>
            <span>¥{{ matrixData.marking_value_count | changeNum }}</span>
          </div>
          <!-- <div>
            <span>销售价值</span>
            <span>¥{{ matrixData.sale_value_count | changeNum }}</span>
          </div> -->
          <!-- <img src="@/assets/img/matrix-card3.png" class="icon" alt="" /> -->
        </div>
      </div>

      <div class="chart-main">
        <div class="chart-main-left">
          <div class="chart-card">
            <div class="card-title">
              <div>
                <svg-icon icon-class="title" class="svg-title" />
                <span>营销价值分布</span>
              </div>
            </div>
            <div class="chart-div">
              <my-chart
                :options="
                  getDateOption(dateData.marketing_distributed, 1000000)
                "
                :id="'date-chart1'"
              ></my-chart>
            </div>
          </div>
          <!-- <div class="chart-card">
            <div class="card-title">
              <div>
                <svg-icon icon-class="title" class="svg-title" />
                <span>销售价值分布</span>
              </div>
            </div>
            <div class="chart-div">
              <my-chart
                :options="getDateOption(dateData.sale_distributed, 100000)"
                :id="'date-chart2'"
              ></my-chart>
            </div>
          </div> -->
        </div>
        <div class="chart-main-right">
          <div class="card-title">
            <div>
              <svg-icon icon-class="title" class="svg-title" />
              <span>各等级贡献占比</span>
            </div>
          </div>
          <div class="button-group">
            <button-tab
              :tabData="buttonTabData"
              @tabChange="buttonTabChange"
            ></button-tab>
          </div>
          <div class="chart-div" style="height: 400px">
            <my-chart
              :options="
                getBarOption(
                  matrixData.leftData,
                  matrixData[`rightData${currentButtonTab}`]
                )
              "
              :id="'bar-chart'"
            ></my-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import MyChart from "@/components/MyChart/MyChart";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ButtonTab from "@/components/PlatformTab/ButtonTab";
import chartBar from "./options/chartBar";
import chartDate from "./options/chartDate";
import deepClone from "@/utils/clone-utils";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "matrixSpread"
);
export default {
  name: "matrixDetail",
  components: { MyChart, PlatformHeader, ButtonTab },
  data() {
    return {
      msg: "矩阵详情",
      searchTime: moment().subtract(1, "months"),
      currentTab: "",
      buttonTabData: [
        // {
        //   label: "销售价值",
        //   value: "1",
        // },
        {
          label: "营销价值",
          value: "2",
        },
        {
          label: "粉丝总数",
          value: "3",
        },
        {
          label: "观看人次",
          value: "4",
        },
        {
          label: "视频发布",
          value: "5",
        },
        {
          label: "视频播放",
          value: "6",
        },
      ],
      currentButtonTab: "2",
    };
  },
  computed: {
    ...mapState({
      matrixData: (state) => state.matrixData,
      dateData: (state) => state.dateData,
    }),
  },
  created() {
    // this.loadMartixData();
    // this.getDateChart({
    //   platform: this.currentTab,
    // });
  },
  watch: {},
  methods: {
    ...mapActions(["getMatrixData", "getDateChart"]),
    ...mapMutations(["changeState"]),
    roundWithPrecision(number, precision) {
      const multiplier = Math.pow(10, precision);
      return Math.round(number * multiplier) / multiplier;
    },
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment();
    },
    loadMartixData() {
      this.getMatrixData({
        year: moment(this.searchTime).format("YYYY"),
        month: moment(this.searchTime).format("MM"),
        platform: this.currentTab,
      });
    },
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.buttonTabData[2].label = this.filtrate.returnWatchText(
        this.currentTab
      );
      this.loadMartixData();
      this.getDateChart({
        platform: this.currentTab,
      });
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      this.loadMartixData();
    },
    buttonTabChange(val) {
      this.currentButtonTab = val;
    },
    sum(arr) {
      var s = 0;
      if (!arr || arr.length == 0) {
        return 0;
      }
      for (var i = arr.length - 1; i >= 0; i--) {
        s += arr[i];
      }
      return s;
    },
    getBarOption(xData, yData) {
      console.log("----------");
      console.log(xData);
      console.log(yData);
      let option = deepClone(chartBar);
      if (!yData || yData.length == 0) {
        return option;
      }
      let sum = this.sum(yData);
      let rightData = yData.map((item) => {
        return {
          sum: sum,
          value: item,
        };
      });
      let newX = deepClone(xData);
      let newY = deepClone(rightData);
      let title = this.buttonTabData[parseInt(this.currentButtonTab) - 2].label;
      option.series[0].data = newX;
      option.series[1].data = newY;
      option.title[1].text = title;
      option.series[1].name = title;
      return option;
    },
    getDateOption(data, max) {
      if (!data || data.length == 0) {
        return false;
      }
      let option = deepClone(chartDate);
      let range = [data[0][0], data[data.length - 1][0]];
      console.log(range);
      option.calendar[0].range = range;
      option.series[0].data = data;
      option.visualMap.max = max;
      return option;
    },
  },
  beforeDestroy() {
    this.changeState({ data: {}, name: "matrixData" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.tools {
  width: 100%;
  max-width: 1424px;
  min-width: 1008px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: flex-end;
  padding: 0 24px;
}
.main-container {
  width: 100%;
  max-width: 1424px;
  min-width: 1008px;
  padding: 0 24px 24px;
  @include font_color("font_color2");
  .card-list {
    // height: 252px;
    height: 175px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 24px;
    .card-item:hover {
      @include background_color("background_color36");
    }
    .card-item {
      cursor: pointer;
      width: calc(20% - 19px);
      height: 100%;
      @include font_color("font_color3");
      font-size: 12px;
      @include background_color("background_color2");
      .border-bottom {
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        @include border_color("border_color6");
      }
      > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
      &-left {
        width: 52%;
        height: 100%;
        text-align: left;
      }
      &-left.col,
      &-right.col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      &-right {
        width: 36%;
        height: 100%;
        text-align: left;
      }
    }
  }
  .info-list {
    height: 68px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 24px;
    .info-item {
      width: calc(25% - 16px);
      height: 100%;
      @include background_color("background_color2");
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: relative;
      > div {
        width: 43%;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 12px;
        @include font_color("font_color2");
        > span:last-child {
          font-size: 20px;
          font-weight: bold;
          @include font_color("font_color1");
        }
      }
      .icon {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 68px;
      }
    }
    .info-item:nth-child(1) {
      background: -webkit-linear-gradient(
        left top,
        #b67746,
        #814324
      ); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        bottom right,
        #b67746,
        #814324
      ); /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        bottom right,
        #b67746,
        #814324
      ); /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to bottom right,
        #b67746,
        #814324
      ); /* 标准的语法 */
    }
    .info-item:nth-child(2) {
      background: -webkit-linear-gradient(
        left top,
        #24a4c7,
        #005079
      ); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        bottom right,
        #24a4c7,
        #005079
      ); /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        bottom right,
        #24a4c7,
        #005079
      ); /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to bottom right,
        #24a4c7,
        #005079
      ); /* 标准的语法 */
    }
    .info-item:nth-child(3) {
      background: -webkit-linear-gradient(
        left top,
        #4960aa,
        #1d267b
      ); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        bottom right,
        #4960aa,
        #1d267b
      ); /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        bottom right,
        #4960aa,
        #1d267b
      ); /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to bottom right,
        #4960aa,
        #1d267b
      ); /* 标准的语法 */
    }
    .info-item:nth-child(4) {
      background: -webkit-linear-gradient(
        left top,
        #d6a84a,
        #9e6416
      ); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        bottom right,
        #d6a84a,
        #9e6416
      ); /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        bottom right,
        #d6a84a,
        #9e6416
      ); /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to bottom right,
        #d6a84a,
        #9e6416
      ); /* 标准的语法 */
    }
  }
  .chart-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 596px;
    width: 100%;
    &-left {
      height: 100%;
      width: calc(100% - 374px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .chart-card {
        width: 100%;
        height: 286px;
        @include background_color("background_color2");
        .card-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 16px 24px;
          .svg-title {
            font-size: 16px;
            margin-right: 8px;
          }
          > div > span {
            font-size: 16px;
            @include font_color("font_color2");
          }
        }
        .chart-div {
          padding: 0 24px;
          height: 210px;
          width: 100%;
        }
      }
    }
    &-right {
      width: 350px;
      height: 100%;
      margin-left: 24px;
      @include background_color("background_color2");
      .card-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 24px;
        .svg-title {
          font-size: 16px;
          margin-right: 8px;
        }
        > div > span {
          font-size: 16px;
          @include font_color("font_color2");
        }
      }
      .button-group {
        height: 130px;
        padding: 0 0px 0 24px;
        display: flex;
        align-items: center;
      }
    }
  }
}
.font-color1 {
  @include font_color("font_color1");
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color3 {
  @include font_color("font_color3");
}
.font-color4 {
  @include font_color("font_color4");
}
.font-color5 {
  @include font_color("font_color5");
}
.font-color6 {
  @include font_color("font_color6");
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f20 {
  font-size: 20px;
}
</style>
