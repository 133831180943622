<template>
  <div class="table-content">
    <div class="table-content-main">
      <div class="table-title">门店排行</div>
      <a-table
        :columns="columns"
        :data-source="storeList"
        :scroll="{
          y: '300px',
        }"
        :rowKey="(data, index) => index"
        :pagination="false"
        :locale="locale"
        :loading="tableLoading"
        class="aft-table default"
      >
        <div slot="customTitle1">
          <span style="padding-left: 20px">门店</span>
        </div>
        <div slot="customTitle2">
          <span style="padding-right: 20px">操作</span>
        </div>
        <div slot="dealer_name" slot-scope="scope" class="store-font">
          <div>{{ scope || "-" }}</div>
        </div>
        <div slot="action" slot-scope="text, record" class="detail-font">
          <span @click="openDetail(record.dealer_id)">详情</span>
        </div>
      </a-table>
      <div class="page-div aft-pagination">
        <a-pagination
          v-if="total && total > 0"
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import filters from "@/filters";
import Empty from "@/components/Empty/Empty";
import api from "@/api/componentsMarket.js";
export default {
  name: "TableContent",
  components: { Empty },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      locale: {
        emptyText: <Empty style="margin: 90px 0;" title="暂无数据" />,
      },
      columns: [
        {
          slots: { title: "customTitle1" },
          // title: "门店",
          dataIndex: "dealer_name",
          key: "dealer_name",
          align: "left",
          scopedSlots: { customRender: "dealer_name" },
        },
        {
          title: "所属大区",
          dataIndex: "region_name",
          key: "region_name",
          align: "right",
          customRender: function (text, record, index) {
            return record.region_name || "-";
          },
        },
        {
          title: "门店编码",
          dataIndex: "dealer_store_code",
          key: "dealer_store_code",
          align: "right",
          customRender: function (text, record, index) {
            return record.dealer_store_code || "-";
          },
        },
        {
          title: "销售线索",
          dataIndex: "sale_clue_count",
          key: "sale_clue_count",
          align: "right",
          customRender: function (text, record, index) {
            return filters.changeNum(record.sale_clue_count);
          },
        },
        {
          title: "直播组件挂载率",
          dataIndex: "live_widget_mount_rate",
          key: "live_widget_mount_rate",
          align: "right",
          customRender: function (text, record, index) {
            return filters.numRate(record.live_widget_mount_rate);
          },
        },
        {
          title: "视频组件挂载率",
          dataIndex: "aweme_widget_mount_rate",
          key: "aweme_widget_mount_rate",
          align: "right",
          customRender: function (text, record, index) {
            return filters.numRate(record.aweme_widget_mount_rate);
          },
        },
        {
          title: "线索转化率",
          dataIndex: "clue_conversion_rate",
          key: "clue_conversion_rate",
          align: "right",
          customRender: function (text, record, index) {
            return filters.numRate3(record.clue_conversion_rate);
          },
        },
        {
          // title: "操作",
          slots: { title: "customTitle2" },
          dataIndex: "action",
          key: "action",
          align: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      storeList: [],
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getStoreList(true);
    },

    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          page_num: this.current,
          page_size: this.pageSize,
        },
      };
      if (this.$parent.tabsKey != '4') {
        data.params.area_id = this.$parent.area_id.key || ""
      }
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    // 页码切换
    pageChange() {
      this.getStoreList();
    },
    getStoreList(isFirst) {
      if (isFirst) {
        this.current = 1;
      }
      this.tableLoading = true;
      let data = this.getParentsParams();
      api.getStoreList(data).then((res) => {
        const { data, code } = res.data;
        this.tableLoading = false;
        if (code == 0) {
          this.total = data.total;
          this.storeList = data.list;
        } else {
          this.total = 0;
          this.storeList = [];
        }
      });
    },
    openDetail(id) {
      window.open(
        `/dealer/dealerDetail?id=${id}&platform=${this.$parent.currentTab}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.table-content {
  width: 100%;
  height: 496px;
  &-main {
    width: 100%;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    .table-title {
      width: 100%;
      height: 62px;
      padding: 0 20px;
      font-size: 14px;
      color: #ffffff;
      line-height: 62px;
    }
    .store-font {
      padding-left: 20px;
    }
    .detail-font {
      color: #ffcc55;
      height: 50px;
      line-height: 50px;
      padding-right: 20px;
    }
  }
}
.page-div {
  text-align: right;
  padding: 20px 0;
}
</style>
