<template>
  <div class="tabs-content3">
    <div class="tabs-content3-main">
      <div class="title-div">
        <span class="title">挂载率趋势</span>
        <!-- <NewTabs2 :tabData="tabsData" @tabChange="changeTabs" /> -->
      </div>
      <div class="chart-div">
        <div class="chart-div-left">
          <MyChart
            v-if="chartShow"
            :options="getLineOption(lineData)"
            :id="'line-chart-1'"
          />
        </div>
        <div class="chart-div-right">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(barData)"
            :id="'bar-chart-2'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import MyChart from "@/components/MyChart/MyChart";
import chartLine from "../../options/chartLine.js";
import chartBar2 from "../../options/chartBar2.js";
import deepClone from "@/utils/clone-utils";
import api from "@/api/componentsMarket.js";
export default {
  name: "TabsContent3",
  components: { NewTabs2, MyChart },
  data() {
    return {
      chartShow: true,
      tabsData: [
        {
          label: "按渠道",
          value: "1",
        },
        {
          label: "按大区",
          value: "2",
        },
      ],
      tabsKey: "1",
      lineData: {
        xData: [],
        yAwemeData: [],
        yLiveData: [],
      },
      barData: {
        xData: [],
        yData: [],
      },
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getComponentTime();
    },
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
    },
    getLineOption(data) {
      let option = deepClone(chartLine);
      option.xAxis.data = data.xData;
      option.series[0].data = data.yLiveData;
      option.series[1].data = data.yAwemeData;
      return option;
    },
    getBarOption(data) {
      let option = deepClone(chartBar2);
      option.series[0].data = data.yData;
      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getComponentTime() {
      let data = this.getParentsParams();
      api.getComponentTime(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          let newData = data;
          this.lineData = {
            xData: newData.aweme_nodes
              ? newData.aweme_nodes.map((item) => {
                  return item.date;
                })
              : [],
            yAwemeData: newData.aweme_nodes
              ? newData.aweme_nodes.map((item) => {
                  return parseInt(item.mount_rate * 100);
                })
              : [],
            yLiveData: newData.live_nodes
              ? newData.live_nodes.map((item) => {
                  return parseInt(item.mount_rate * 100);
                })
              : [],
          };
          this.barData = {
            yData: [
              parseInt((newData.live_mount_rate || 0) * 100),
              parseInt((newData.aweme_mount_rate || 0) * 100),
            ]
          };
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content3 {
  width: 100%;
  height: 413px;
  &-main {
    width: 100%;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      > .title {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .chart-div {
      width: 100%;
      height: 351px;
      display: flex;
      justify-content: space-between;
      &-left {
        width: 66%;
        height: 100%;
      }
      &-right {
        width: 33%;
        height: 100%;
      }
    }
  }
}
</style>
