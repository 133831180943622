import echarts from 'echarts'
var option = {
    title: {
        text: '',
        x: 'center',
        y: 0,
        textStyle: {
            color: '#B4B4B4',
            fontSize: 16,
            fontWeight: 'normal'
        }
    },
    tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.6)',
        formatter: '{b0}<br />{a0}:{c0}<br />{a1}:{c1}%',
    },
    legend: {
        // 修改legend的高度宽度
        itemHeight: 5,
        itemWidth: 24,
        data: [{
            name: '违规视频个数',
            icon: 'rect' // legend的icon
        },
        {
            name: '周环比',
            icon: 'rect'
        }
        ],
        textStyle: {
            color: '#B4B4B4'
        },
        top: '0%',
    },
    grid: {
        "left": "6%",
        "top": "30",
        "right": "100",
        "bottom": "40"
    },
    xAxis: [{
        // type:'category',
        data: [],
        boundaryGap: true,
        axisLine: {
            lineStyle: {
                color: '#B4B4B4'
            }
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            formatter: function (value, index) {
                return value.split('(')[0];
            }
        } 
    }],
    yAxis: [{
        name: '周环比',
        nameTextStyle: {
            color: '#959595'
        },
        splitLine: {
            show: true,
            lineStyle: {
                type: 'dashed',
                color: ["rgba(255,255,255,0.2)"],
            }
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            textStyle: {
                color: '#959595'
            },
            formatter: '{value}% '
        }
    },
    {
        name: '违规视频个数',
        nameTextStyle: {
            color: '#959595'
        },
        splitLine: {
            show: false
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            textStyle: {
                color: '#959595'
            },
            formatter: '{value} '
        }
    },
    ],
    series: [
        {
            name: '违规视频个数',
            type: 'bar',
            barWidth: 30,
            yAxisIndex: 1,
            itemStyle: {
                normal: {
                    color: '#7A91FE',
                    borderWidth: 2,
                    //柱状图圆角
                    barBorderRadius: [4, 4, 0, 0],
                }
            },

            data: []
        },
        {
            name: '周环比',
            type: 'line',
            smooth: true,
            showSymbol: true,
            symbolSize: 12,
            yAxisIndex: 0,
            areaStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'rgba(250,180,101,0.3)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(250,180,101,0)'
                    }
                    ]),
                    shadowColor: 'rgba(250,180,101,0.2)',
                    shadowBlur: 20
                }
            },
            itemStyle: {
                normal: {
                    color: '#FF8000'
                }
            },
            // data中可以使用对象，value代表相应的值，另外可加入自定义的属性
            data: []
        }
    ]
};

export default option