<template>
  <a-modal
    :visible="videoVisible"
    :footer="null"
    @cancel="videoModalClose"
    ref="modal"
    style="z-index: 1100"
  >
    <video
      id="videoElement"
      autoplay
      controls
      muted
      style="width: 100%; height: 100%; border-radius: 10px"
    ></video>
  </a-modal>
</template>

<script>
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import api from "@/api/marketingActivityList";

export default {
  data() {
    return {
      videoVisible: false,
    };
  },
  methods: {
    // 视频地址通过内容中台获取权限
    getAuthUrl(source) {
      if (source.indexOf("huoshan") < 0) {
        let body = {
          resource_url: source,
        };
        api
          .getAuthUrl(body)
          .then((res) => {
            console.log("getAuthUrl", res);
            if (res.data.code === 200) {
              this.getStream(res.data.data);
            } else {
              this.$message.error(res.data.message || "获取视频权限失败");
            }
          })
          .catch((err) => {
            this.$message.error("获取视频权限失败");
            console.error(err);
          });
      } else {
        this.getStream(source);
      }
    },
    // 初始化flv
    getStream(source) {
      this.videoVisible = true;
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          let type =
            source.indexOf(".mp4") > 0 || source.indexOf("huoshan") > 0
              ? "mp4"
              : "flv";
          this.flvPlayer = flvjs.createPlayer({
            type,
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            this.videoModalClose();
            this.$message.warning("视频播放失败，请联系管理员...");
          });
        }
      });
    },
    // 关闭 flv 视频弹窗事件
    videoModalClose() {
      this.videoVisible = false;
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
      }
      this.$refs.modal.$destroyAll();
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
