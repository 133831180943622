import echarts from 'echarts'
let colorList = ['#8BAFFE', '#73D2A4', '#FECB5B', '#BED578', '#73D2A4', '#FF9E9E', '#D0D6E6'];
let echartData = [
];

var option = {
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    series: [
        {
            name: '意向来源（省份）',
            type: 'pie',
            selectedMode: 'single',
            radius: ['25%', '40%'],
            itemStyle: {
                normal: {
                    color: function(params) {
                        return colorList[params.dataIndex]
                    }
                }
            },
            label: {
                normal: {
                    position: 'inner',
                    textStyle: {
                        color: '#1C1E27',
                        fontSize: 12
                    }
                }
            },
            labelLine: {
                show: false
            },
            data: []
        },
        {
            name: '意向来源（城市）',
            type: 'pie',
            radius: ['52%', '65%'],
            itemStyle: {
                normal: {
                    color: function(params) {
                        return colorList[params.dataIndex]
                    }
                }
            },
            label: {
                formatter: '{b|{b}：}{c}  {per|{d}%}  ',
                rich: {
                    b: {
                        fontSize: 16,
                    },
                    per: {
                        color: '#fff',
                        backgroundColor: '#8890A6',
                        padding: [4, 6],
                        borderRadius: 4
                    }
                }
            },
            data: []
        }
    ]
}

export default option