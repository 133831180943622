<template>
  <div class="container">
    <platform-header
      title="解冻记录"
      :currentTab="currentTab"
      @tabChange="tabChange"
      ref="platformHeader"
    />
    <div class="tools">
      <div>
        <a-select
          v-model="regionValue"
          :suffixIcon="suffixIcon"
          style="width: 126px; height: 40px"
          placeholder="选择大区"
          @change="groupValue = undefined"
        >
          <a-select-option value="">不限大区</a-select-option>
          <a-select-option
            :value="item.id"
            v-for="item in regionData.region_list"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="groupValue"
          :suffixIcon="suffixIcon"
          style="margin: 0 10px 0 10px; width: 180px; height: 40px"
          placeholder="选择分组"
          @change="regionValue = undefined"
        >
          <a-select-option value="">不限分组</a-select-option>
          <a-select-option
            :value="item.group_code"
            v-for="item in groupData"
            :key="item.group_code"
          >
            {{ item.group_name }}
          </a-select-option>
        </a-select>

        <a-input
          v-model.trim="searchId"
          class="aft-input default"
          placeholder="搜索门店名、账号昵称或门店编码关键字"
          style="width: 300px"
        />
        <a-button class="search-btn" @click="search(true)">确定</a-button>
      </div>
      <date-tab
        :type="dateType"
        @changeType="changeType"
        @changeDate="search(true)"
        ref="dateTab"
      ></date-tab>
    </div>
    <div class="tip">提示: 直播视频解冻后24h后冻结，需再次解冻</div>
    <div class="content">
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :rowKey="(data) => data.room_id"
          :scroll="{ y: 'calc(100vh - 386px)' }"
          :pagination="false"
          :locale="locale"
          :loading="tableLoading"
          :customRow="openDetail"
          class="aft-table default"
        >
          <div slot="firstTitle" style="padding-left: 16px">直播标题</div>
          <div slot="liveTitle" slot-scope="scope" class="table-account">
            <div class="table-account-left">
              <div class="img-box">
                <div class="img-div">
                  <WebpImage
                    :src="scope.cover"
                    :width="'90px'"
                    :height="'120px'"
                    :radius="'0px'"
                  />
                </div>
              </div>
              <span class="font-color20 f16">{{ scope.title || "-" }}</span>
            </div>
          </div>
          <div slot="name" slot-scope="scope" class="font-color2 f14 ellipsis">
            <a-tooltip>
              <template slot="title">{{ scope.dealer_name || "-" }}</template>
              <div>
                <div class="ellipsis">
                  <img
                    src="@/assets/img/type-store.png"
                    width="20px"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 4px;
                    "
                    alt=""
                  />
                  <span>{{ scope.dealer_name || "-" }}</span>
                </div>
              </div>
            </a-tooltip>

            <a-tooltip>
              <template slot="title">{{ scope.nickname || "-" }}</template>
              <div>
                <div class="ellipsis">
                  <img
                    src="@/assets/img/type-lanv.png"
                    v-if="scope.account_type == 11"
                    width="22"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 2px;
                    "
                    alt=""
                  />
                  <img
                    src="@/assets/img/type-ordinary.png"
                    v-else
                    width="22"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 2px;
                    "
                    alt=""
                  />
                  <span>{{ scope.nickname || "-" }}</span>
                </div>
              </div>
            </a-tooltip>
          </div>
          <div
            slot="dealer_store_code"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.dealer_store_code || "-" }}</span>
          </div>
          <div
            slot="restore_start_time"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span style="text-align: center; display: block">{{
              scope.restore_start_time || "-"
            }}</span>
          </div>
          <div slot="action" slot-scope="scope" class="f14">
            <span v-if="scope.restore_state == 1" class="font-color40"
              >解冻中</span
            >
            <span v-else-if="scope.restore_state == 2" class="font-color21"
              >解冻成功</span
            >
            <span v-else-if="scope.restore_state == 0" class="font-color48"
              >已冻结</span
            >
            <span v-else class="font-color48">解冻失败</span>
            <span
              v-if="scope.restore_state != 1 && scope.restore_state != 2"
              class="action-lock"
              @click.stop="unlockClick(scope.room_id)"
              >解冻</span
            >
          </div>
        </a-table>
      </div>
      <div class="page-div aft-pagination">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "liveRanking"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import DateTab from "@/components/DateTab/DateTab2";
import MultipleGroup from "@/components/MultipleGroup/MultipleGroup";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";
import Tooltip from "@/components/Tooltip/Tooltip";
import api from "@/api/globalDetail";

export default {
  components: {
    PlatformHeader,
    DateTab,
    MultipleGroup,
    WebpImage,
    Tooltip,
  },
  data() {
    return {
      defultAvatar: require("@/assets/img/defult-avatar.png"),
      currentTab: this.$route.query.platform || "",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      dateType: "month",
      regionValue: undefined,
      groupValue: undefined,
      searchId: "",
      tableLoading: false,
      columns: [
        {
          key: "liveTitle",
          slots: { title: "firstTitle" },
          align: "left",
          width: "28%",
          scopedSlots: { customRender: "liveTitle" },
        },
        {
          key: "name",
          title: "门店名称/账号名称",
          align: "left",
          width: "22%",
          scopedSlots: { customRender: "name" },
        },
        {
          key: "dealer_store_code",
          title: "门店编码",
          width: "14%",
          align: "center",
          scopedSlots: { customRender: "dealer_store_code" },
        },
        {
          key: "restore_start_time",
          title: "解冻时间",
          width: "14%",
          align: "center",
          scopedSlots: { customRender: "restore_start_time" },
        },
        {
          key: "action",
          title: "状态/操作",
          width: "16%",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      current: 1,
      pageSize: 20,
      total: 0,
      tableData: [],
      topic_id: undefined,
      currentParams: {},
      lockLoading: false,

      isFirstInit: true,
    };
  },
  watch: {
    recordList(val) {
      this.tableData = val.list;
      this.total = Number(val.total);
    },
  },
  computed: {
    ...mapState({
      regionData: (state) => state.regionData, //区域
      groupData: (state) => state.groupData,
      recordList: (state) => state.recordList,
    }),
  },
  created() {

  },
  async mounted() {
    await this.initData()
    this.search(true)
  },
  methods: {
    ...mapActions(["getAreaList", "getAllGroup", "getThawingRecord"]),
    ...mapMutations(["changeState"]),
    async initData() {
      await this.getAreaList();
      await this.getAllGroup();
      // 默认选择当前月份
      this.$refs.dateTab.searchMonthTime = moment().subtract(0, "months");
      this.$refs.dateTab.disabledDate = (current) => {
        return current && current > moment().endOf("month");
      };
      this.$refs.dateTab.monthStartTime =
        moment()
          .startOf("month")
          .format("YYYY-MM-DD") + " 00:00:00";
      this.$refs.dateTab.monthEndTime =
        moment()
          .endOf("month")
          .format("YYYY-MM-DD") + " 23:59:59";
    },
    // 直播/视频 tab切换
    async tabChange(val) {
      this.currentTab = val;
      this.topic_id = undefined;
      this.$nextTick(async () => {
        if (this.isFirstInit) {
          this.isFirstInit = false;
          await this.initData();
        }
        this.search(true);
      });
    },
    async search(isFirst) {
      this.tableLoading = true;
      if (isFirst) {
        this.current = 1;
        let begin_time = "";
        let end_time = "";
        if (this.dateType == "month") {
          if (
            !this.$refs.dateTab.monthStartTime ||
            !this.$refs.dateTab.monthEndTime
          ) {
            this.$message.warning("请选择日期");
            return false;
          }
          begin_time = this.$refs.dateTab.monthStartTime;
          end_time = this.$refs.dateTab.monthEndTime;
        }
        if (this.dateType == "day") {
          if (
            !this.$refs.dateTab.dayStartTime ||
            !this.$refs.dateTab.dayEndTime
          ) {
            this.$message.warning("请选择日期");
            return false;
          }
          begin_time = this.$refs.dateTab.dayStartTime;
          end_time = this.$refs.dateTab.dayEndTime;
        }
        this.currentParams = {
          region_id: this.regionValue || "",
          group_code: this.groupValue || "",
          start_time: begin_time,
          end_time: end_time,
          page_no: this.current,
          page_size: this.pageSize,
          dealer_name_or_nickname_or_dealer_store_code: this.searchId || "",
          platform: this.currentTab,
        };
      }
      this.currentParams.current = this.current;
      await this.getThawingRecord(this.currentParams);
      this.tableLoading = false;
    },

    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      // this.search(true);
    },
    // 页码切换
    pageChange(val) {
      console.log(val);
      this.search();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    unlockClick(roomid) {
      console.log(roomid);
      if (this.lockLoading) {
        return false;
      }
      // 调用解冻接口
      this.lockLoading = true;

      let params = {
        room_id: roomid,
        platform: this.currentTab,
      };
      api.updeteRetore(params).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$message.success("解冻成功");
          this.lockLoading = false;
          this.search();
        } else {
          this.$message.error("解冻失败");
          this.lockLoading = false;
        }
      });
    },
    openDetail(record, index) {
      return {
        on: {
          click: () => {
            if (record.room_id) {
              let url = `/liveDetail?id=${record.room_id}&platform=${this.currentTab}`;
              window.open(url);
            } else {
              this.$message.error("暂无直播房间ID，无法查看详情");
            }
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .platform-div {
    width: 100%;
    height: 40px;
    @include background_color("background_color2");
    text-align: right;
    position: relative;

    .beta-icon {
      width: 36px;
      height: 14px;
      border-radius: 7px;
      background: #ff4160;
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      position: absolute;
      top: 4px;
      right: 0px;
    }
  }

  .tools {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .search-btn {
      @include border_color("border_color4");
      @include font_color("font_color17");
      @include background_color("background_color13");
      width: 80px;
      height: 40px;
      border-radius: 8px;
      margin-left: 12px;
    }
  }

  .tip {
    @include font_color("font_color23");
    font-size: 12px;
    padding: 0 20px;
    margin-bottom: 16px;
  }
  .content {
    padding: 0 20px;
    .table {
      height: calc(100vh - 330px);
      overflow-y: auto;
      &-account {
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        padding-left: 16px;
        &-left {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          @include font_color("font_color3");
          .img-box {
            margin-right: 16px;
            border-radius: 16px;
            // background: #000;
            position: relative;
            overflow: hidden;
            .img-div {
              width: 90px;
              height: 120px;
              // @include background_color("background_color43");
              display: flex;
              align-items: center;
            }
          }
          > span {
            height: 48px;
            width: calc(100% - 100px);
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
      }
      .action-lock {
        text-decoration: underline;
        cursor: pointer;
        @include font_color("font_color21");
        margin-left: 32px;
        &:hover {
          @include font_color("font_color26");
        }
      }
    }
  }
  .page-div {
    text-align: right;
    padding: 20px 0;
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color20 {
    @include font_color("font_color20");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .font-color21 {
    @include font_color("font_color21");
  }
  .font-color40 {
    @include font_color("font_color40");
  }
  .font-color48 {
    @include font_color("font_color48");
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
  .ellipsis {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}
</style>
