import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default{
    // 闭环管理,
    getActivityDetail({
        params,
        platform
    }){
        return request({
            url: '/dealer/topic/activity/status',
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        })
    },
    getStatistics({
        params,
        platform
    }){
        return request({
            url: '/jjt/activity/big/screen/header/statistics/five',
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        })
    },
    getLiveList({
        params,
        platform
    }){
        return request({
            url: '/jjt/activity/big/screen/live/on/five',
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        })
    },
    getNewLiveList({
        params,
        platform
    }){
        return request({
            url: '/jjt/activity/big/screen/live/on/one',
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        })
    },
    getClientSetting({
        params,
        platform
    }){
        return request({
            url: '/jjt/mqtt/activity/client-setting',
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        })
    },
    addMonitorActivity({
        data,
        platform
    }){
        return request({
            url: '/jjt/mqtt/activity/add-monitor-activity',
            method: 'post',
            baseURL: getBaseUrl(platform),
            data,
        })
    },
    getHistoryData({
        params,
        platform
    }){
        return request({
            url: '/jjt/mqtt/activity/mqtt-statistic-history',
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        })
    },
}