<template>
  <PanleWrap>
    <div class="list_header">
      <div class="platform-div">
        <top-platform-tab
          :tabData="tabData"
          @tabChange="handlePlatformClick"
        ></top-platform-tab>
      </div>
      <h3>品牌视频榜</h3>
      <div class="tools">
        <Order :order-list="orderList" @order-click="handleOrderClick" />
        <div style="margin-left: 50px">
          <a-month-picker
            v-model="searchTime"
            placeholder="Select month"
            :disabled-date="pickerOptions"
            @change="onChange"
            locale="zh-cn"
            style="margin-right: 24px;"
          />
        </div>
      </div>
    </div>
    <a-spin :spinning="mixin_isSpinning">
      <div class="video_wrap clearfix">
        <template v-if="videoList.length > 0">
          <div
            class="video_item"
            v-for="item in videoList"
            :key="item.principal_id"
          >
            <div class="brand_info" @click="handleItemClickLeft(item)">
              <i>
                <img :src="`${OSS_HOST}${item.avatar}`" alt="" />
              </i>
              <h3>{{ item.principal_name || "暂无名称" }}</h3>
            </div>
            <div class="video_item_right" @click="handleItemClick(item)">
              <div class="video_data_wrap">
                <div class="video_data">
                  <div>
                    <p>发布视频</p>
                    <b class="color4">{{ item.polling_aweme_count | changeNum }}</b>
                  </div>
                  <div>
                    <p>日均发布</p>
                    <b class="color7">{{ item.polling_aweme_avg_count | changeNum }}</b>
                  </div>
                  <div>
                    <p>互动总量</p>
                    <strong>{{ item.polling_count | changeNum }}</strong>
                  </div>
                  <div>
                    <p>活跃账号</p>
                    <strong>{{ item.polling_author_count | changeNum }}</strong>
                  </div>
                </div>
                <div class="video_detail" @click="handleVideoClick(item)">
                  <i class="video_detail_imgbox">
                    <img
                      :src="item.cover || ''"
                      :alt="item.cover || '暂无封面'"
                      width="58"
                    />
                  </i>
                  <div class="video_detail_content">
                    <h3>
                      {{ item.title || "暂无名称" }}
                    </h3>
                    <p>
                      发布:{{ item.publish_time | formatPublishTime }} 时长:{{
                        item.duration
                      }}s
                    </p>
                  </div>
                  <div class="video_detail_data">
                    <b>{{ item.aweme_relation_count | changeNum }}</b>
                    <p>互动量</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template
          v-if="
            mixin_isBizError || mixin_isNetworldError || videoList.length === 0
          "
        >
          <div class="font-color3" style="text-align: center; width: 100%; padding-top: 20px">
            暂无数据
          </div>
        </template>
      </div>
    </a-spin>
    <div class="pagination_wrap">
      <a-pagination
        v-model="pagination.current"
        :total="pagination.total"
        size="small"
        show-less-items
        @change="handleCurrentPageChange"
        :pageSize="pagination.size"
      />
    </div>
  </PanleWrap>
</template>

<script>
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/brand.js";
import _ from "lodash";
import moment from "moment";
import mixin from "./mixins/mixin";
import Order from "./components/Order";
import Platform from "./components/Platform";
import TopPlatformTab from "@/components/PlatformTab/TopPlatformTab";
const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;
export default {
  components: {
    PanleWrap,
    Order,
    Platform,
    TopPlatformTab,
  },
  mixins: [mixin],
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatPublishTime(str) {
      if (!str) {
        return "暂无时间";
      } else {
        return moment(str).format("YYYY/MM/DD");
      }
    },
  },
  data() {
    return {
      searchTime: moment().subtract(1,'months'),
      videoList: [],
      OSS_HOST,
      pagination: {
        size: 8,
        current: 1,
        total: 0,
      },
      startTime: "",
      endTime: "",
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
      ],
      platform: "douyin", // 平台(抖音:douyin,快手:kuaishou)
      sort: "pollingCount", //  排序类型(互动总量:pollingCount,日均发布:pollingAwemeAvgCount,视频发布:pollingAwemeCount,活跃账号:pollingAuthorCount) ,

      orderList: [
        {
          text: "互动总量",
          value: "pollingCount",
        },
        {
          text: "日均发布",
          value: "pollingAwemeAvgCount",
        },
        {
          text: "视频发布",
          value: "pollingAwemeCount",
        },
        {
          text: "活跃账号",
          value: "pollingAuthorCount",
        },
      ],
    };
  },
  methods: {
    getList() {},
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().endOf("month");
    },
    handleVideoClick(item) {
      let play_url = item.url; // 播放url
    },
    handleOrderClick(res) {
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.sort = res.data.value;
      this.getList();
    },
    handlePlatformClick(val) {
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.platform = val;
      this.getList();
    },
    handleCurrentPageChange(data) {
      this.pagination.current = data;
      this.getList();
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      let obj = this.calcStartEndTime(date);
      this.startTime = obj.startTime;
      this.endTime = obj.endTime;
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.getList();
    },
    calcStartEndTime(date) {
      let start = date.startOf("month").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("month").format("YYYY-MM-DD HH:mm:ss");
      return {
        startTime: start,
        endTime: end,
      };
    },
    handleItemClick(item) {
      if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${this.platform}`;
        // this.$router.push(`/videoDetail?id=${item.aweme_id}`);
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
    handleItemClickLeft(item) {
      let url = "/influenceDetail?principal_id=" + item.principal_id
      window.open(url)
    }
  },
  // todoD
  async created() {
    let timeObj = this.calcStartEndTime(moment().subtract(1,'months'));
    this.startTime = timeObj.startTime;
    this.endTime = timeObj.endTime;
    this.getList = this.requestDecorator(() => {
      const body = {
        current: this.pagination.current,
        size: this.pagination.size,
        startTime: this.startTime,
        endTime: this.endTime,
        platform: this.platform == "douyin" ? "dy" : "ks",
        sort: this.sort,
      };
      return api.getBrandVideoList(body).then((res) => {
        this.videoList = _.get(res, "data.data.list", []);
        let pagination = {
          current: _.get(res, "data.data.pageNum", 1),
          total: _.get(res, "data.data.total", 0),
          size: 8,
        };
        this.pagination = pagination;
        return res;
      });
    });
    await this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.list_header {
  margin-bottom: 20px;
  > h3 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 24px;
    @include font_color("font_color2");
    margin: 0;
  }
}
.tools {
  width: 100%;
  max-width: 1424px;
  min-width: 1008px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 24px;
}
.video_wrap {
  padding-bottom: 50px;
  padding: 0 24px 50px;
  max-width: 1424px;
  min-width: 1008px;
  height: calc(100vh - 270px);
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .video_item {
    display: flex;
    width: calc(50% - 25px);
    max-width: 688px;
    min-width: 456px;
    height: 186px;
    padding: 24px 20px;
    border-radius: 10px;
    margin: 0 24px 24px 0;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0);
    @include background_color("background_color2");
    // @include border_color("border_color1");
    .brand_info {
      width: 80px;
      > i {
        display: block;
        margin: 0 auto 12px;
        width: 80px;
        height: 80px;
        border-radius: 4px;
        overflow: hidden;
        > img {
          display: block;
          border: 4px solid rgba(0, 0, 0, 0);
          @include border_color("border_color2");
          width: 80px;
          height: 80px;
          background: #fff;
          border-radius: 4px;
        }
      }
      > h3 {
        text-align: center;
        max-width: 80px;
        font-size: 16px;
        font-weight: bold;
        @include font_color("font_color1");
      }
    }
    &_right {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      .video_data_wrap {
        min-width: 312px;
        max-width: 440px;
        width: 73%;
        .video_data {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          @include border_color("border_color1");
          padding-bottom: 12px;

          > div {
            @include font_color("font_color3");
            padding-left: 8px;
            border-left: 3px solid rgba(0, 0, 0, 0);
            @include border_color("border_color2");
            font-size: 12px;
            > p {
              margin-bottom: 5px;
              font-size: 12px;
              @include font_color("font_color3");
            }
            .color4 {
              @include font_color("font_color4");
            }
            .color7 {
              @include font_color("font_color7");
            }
            > b,
            strong {
              font-weight: 400;
              font-size: 16px;
            }
            > strong {
              @include font_color("font_color2");
            }
          }
        }
        .video_detail {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 65px;
          border-radius: 5px;
          &_imgbox {
            width: 58px;
            height: 58px;
            border-radius: 4px;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0);
            @include border_color("border_color2");
            > img {
              min-height: 58px;
              min-width: 58px;
            }
          }
          .video_detail_data {
            width: 48px;
            text-align: center;
            > b {
              display: block;
              font-size: 16px;
              @include font_color("font_color6");
              margin-bottom: 6px;
            }
            > p {
              margin: 0;
              font-size: 12px;
              @include font_color("font_color3");
            }
          }
          .video_detail_content {
            position: relative;
            width: calc(100% - 128px);
            font-size: 12px;
            > i {
              position: absolute;
              left: 0;
              top: 3px;
              display: block;
              width: 56px;
              height: 56px;
              overflow: hidden;
            }
            > h3 {
              width: 100%;
              height: 36px;
              margin-bottom: 8px;
              @include font_color("font_color2");
              font-size: 12px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            > p {
              margin: 0;
              font-size: 12px;
              @include font_color("font_color3");
            }
          }
        }
      }
    }
  }
}
.pagination_wrap {
  position: fixed;
  z-index: 99;
  left: 200px;
  bottom: 0;
  padding: 20px;
  text-align: center;
  width: calc(100% - 200px);
  @include background_color("background_color1");
}
</style>