<template>
  <div class="container">
    <platform-header title="经销商KPI" :showPlatform="false" />
    <div class="tools">
      <NewTabs :tabData="tabsData" @tabChange="changeTabs" />
      <span class="time">数据统计截至：{{ time }}</span>
    </div>
    <div class="content">
      <div class="iframe-div" v-if="iframeSrc">
        <iframe
          :src="iframeSrc"
          frameborder="0"
          width="100%"
          height="100%"
          allowtransparency
        ></iframe>
      </div>
      <div class="empty-div" v-else>
        <Empty />
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "closedLoop"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Empty from "@/components/Empty/Empty";
import NewTabs from "@/components/PlatformTab/NewTabs";
import linkData from "./js/getLink";
import { getPrincipalId } from "@/utils/auth";
import _ from "lodash";
export default {
  components: {
    PlatformHeader,
    Empty,
    NewTabs,
  },
  data() {
    return {
      tabsData: [],
      tabsKey: "",
      iframeSrc: "",
      time: moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
    };
  },
  created() {
    this.tabsData = linkData[getPrincipalId()]
      ? linkData[getPrincipalId()].tabsData
      : [];

    this.tabsKey = this.tabsData.length > 0 ? this.tabsData[0].value : "";

    this.iframeSrc = linkData[getPrincipalId()]
      ? linkData[getPrincipalId()][`${this.tabsKey}`]
      : "";
  },
  methods: {
    // tabs切换
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
      this.iframeSrc = linkData[getPrincipalId()]
        ? linkData[getPrincipalId()][`${this.tabsKey}`]
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .tools {
    padding: 0 20px;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    .time {
      color: rgba(255, 255, 255, 0.85);
    }
  }
  .content {
    border-radius: 16px;
    width: calc(100% - 40px);
    margin-left: 20px;
    height: calc(100vh - 214px);
    overflow-y: hidden;
    @include background_color("background_color50");
    > div {
      width: 100%;
      height: 100%;
    }
    .empty-div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
