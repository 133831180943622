import filters from '@/filters'
var option = {
  tooltip: {
    trigger: 'item',
    formatter: function(params) {
      return `${params.name}: ${filters.changeNum(params.value)}`
    }
  },
  series: [
    {
      name: 'Funnel',
      type: 'funnel',
      left: '10%',
      top: 40,
      bottom: 50,
      width: '80%',
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '80%',
      sort: 'descending',
      label: {
        show: true,
        position: 'outside',
        color: '#fff',
        formatter: function(params) {
          return `${params.name}: ${filters.changeNum(params.value)}`
        }
      },
      itemStyle: {
        color: '#3C7EFF',
        borderWidth: 0
      },
      data: []
    },
    {
      name: 'Funnel',
      type: 'funnel',
      left: '10%',
      top: 40,
      bottom: 50,
      width: '80%',
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '80%',
      sort: 'descending',
      label: {
        show: true,
        position: 'inside',
        color: 'rgba(0,0,0,0.65)'
      },
      itemStyle: {
        color: '#3C7EFF',
        borderWidth: 0
      },
      emphasis: {
        label: {
          fontSize: 16
        }
      },
      data: []
    }
  ]
}

export default option