<template>
  <a-modal
    :visible="previewVisible"
    :footer="null"
    @cancel="previewVisible = false"
  >
    <div style="padding:24px 0">
      <img alt="" style="width: 100%" :src="previewImage" v-if="previewImage" />
      <div v-else class="tips">暂无图片</div>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return { previewVisible: false, previewImage: "" };
  },
  methods: {
    openModal(url) {
      Object.assign(this.$data, this.$options.data());
      this.previewImage = url;
      this.previewVisible = true;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.tips {
  color: rgba(255, 255, 255, 0.88);
}
</style>
