import echarts from 'echarts'
var option = {
  grid: {
    left: "50",
    right: "60",
    bottom: "5%",
    top: "5%",
    containLabel: true,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: '#ddd'
      }
    },
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
      color: '#fff',
    },
    padding: [5, 10],
    extraCssText: 'box-shadow: 0 0 5px rgba(0,0,0,0.3)'
  },

  xAxis: [{
    show: true,
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)',
      }
    },
    splitArea: {
      // show: true,
      color: '#8890A6',
      lineStyle: {
        color: '#8890A6'
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6',
        fontSize: 10
      },
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: [],

  }],
  yAxis: [{
    type: 'value',
    min: 0,
    // max: 140,
    splitNumber: 4,
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)'
      }
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#8890A6',
        fontSize: 10
      },
    },
    axisTick: {
      show: false,
    },
  }],
  series: [{
    name: '直播观看人次',
    type: 'line',
    smooth: true,
    symbol: 'circle',
    symbolSize: 0,
    data: [],
    areaStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(232,90,52, 0.5)'
        }, {
          offset: 0.8,
          color: 'rgba(232,90,52, 0)'
        }], false),
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowBlur: 10
      },
    },
    itemStyle: {
      normal: {
        color: '#E85A34'
      }
    },
  }, {
    name: '短视频播放量',
    type: 'line',
    smooth: true,
    symbol: 'circle',
    symbolSize: 0,
    data: [],
    areaStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(129,92,255, 0.5)'
        }, {
          offset: 0.8,
          color: 'rgba(129,92,255, 0)'
        }], false),
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowBlur: 10
      },
    },
    itemStyle: {
      normal: {
        color: '#815CFF'
      }
    },

  }]
}

export default option