<template>
  <PanleWrap>
    <div class="list_header">
      <platform-header
        title="品牌官号作品榜"
        :currentTab="platform"
        @tabChange="handlePlatformClick"
      />
      <div class="tools">
        <div class="btn-box">
          <ButtonGroupTab
            :tab-data="tabList"
            @tabChange="handleTypeClick"
            :showTag="true"
          />
          <Order
            :order-list="orderList"
            :order-index="orderIndex"
            :titleText="'月度排名:'"
            @order-click="hadnleOrderClick"
            style="margin-left: 30px"
          />
        </div>
        <div class="btn-box">
          <div class="my-ranking" @click="scrollMyRankig">
            我的排名：<span>{{
              my_ranking.ranking ? my_ranking.ranking : "-"
            }}</span>
          </div>
          <a-month-picker
            v-model="searchTime"
            placeholder="Select month"
            class="aft-month-picker default"
            :disabled-date="disabledDate"
            @change="onChange"
            locale="zh-cn"
          >
            <div slot="suffixIcon">
              <svg-icon :icon-class="'range-picker'" />
            </div>
          </a-month-picker>
        </div>
      </div>
    </div>
    <a-spin :spinning="mixin_isSpinning">
      <div class="list-content">
        <template v-if="dataList && dataList.length > 0 && isLive">
          <div
            class="item live-item"
            v-for="item in dataList"
            :key="item.principal_id"
            :ref="`item_${item.principal_id}`"
          >
            <div class="ranking-wrap">
              <div
                :class="
                  item.ranking === 1
                    ? 'num first'
                    : item.ranking === 2
                    ? 'num second'
                    : item.ranking === 3
                    ? 'num third'
                    : item.principal_id === my_ranking.principal_id
                    ? 'num other'
                    : 'num'
                "
              >
                <span v-if="my_ranking.principal_id === item.principal_id"
                  >我的排名：</span
                >
                {{ item.ranking || "-" }}
              </div>
            </div>

            <div class="info-wrap" @click="handleItemClickInfo(item)">
              <div class="left">
                <span class="avatar">
                  <img :src="`${OSS_HOST}${item.avatar}`" alt="" />
                </span>
                <h3 class="principal-name">
                  {{ item.principal_name || "暂无名称" }}
                </h3>
              </div>
              <div class="right">
                <div class="count-box">
                  <div class="count-item">
                    <span>互动量</span>
                    <span class="number highlight">{{
                      item.live_interactive_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>{{ platform | returnWatchText }}</span>
                    <span class="number">{{
                      item.live_total_watch_user_time || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>直播场次</span>
                    <span class="number">{{
                      item.live_total_count || 0 | changeNum
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-wrap" @click="handleLiveItemClick(item)">
              <div class="left">
                <BaseImage
                  :src="item.cover || ''"
                  :default-image="defaultCoverImg"
                  fit="contain"
                  width="80px"
                  height="105px"
                  radius="6px"
                  style="background-color: #21232c;"
                />
              </div>
              <div class="right">
                <div class="title">{{ item.title || "本月暂无直播" }}</div>
                <div class="name">
                  <BaseImage
                    :src="item.author_avatar || ''"
                    :default-image="defaultAvatarImg"
                    fit="cover"
                    width="16px"
                    height="16px"
                    round
                    style="margin-right: 6px"
                  />
                  <span>{{ item.nickname || "-" }}</span>
                </div>
                <div class="count-box">
                  <div class="count-item">
                    <span>{{ platform | returnWatchText }}</span>
                    <span class="number">{{
                      item.statistics_total_watch_user_time || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>开播时间</span>
                    <span class="number">{{
                      item.publish_time | formatPublishTime
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>直播时长</span>
                    <span class="number">{{
                      item.duration || 0 | formatDuration
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="dataList.length > 0 && isVideo">
          <div
            class="item video-item"
            v-for="item in dataList"
            :key="item.principal_id"
            :ref="`item_${item.principal_id}`"
          >
            <div class="ranking-wrap">
              <div
                :class="
                  item.ranking === 1
                    ? 'num first'
                    : item.ranking === 2
                    ? 'num second'
                    : item.ranking === 3
                    ? 'num third'
                    : item.principal_id === my_ranking.principal_id
                    ? 'num other'
                    : 'num'
                "
              >
                <span v-if="my_ranking.principal_id === item.principal_id"
                  >我的排名：</span
                >
                {{ item.ranking }}
              </div>
            </div>

            <div class="info-wrap" @click="handleItemClickInfo(item)">
              <div class="left">
                <span class="avatar">
                  <img :src="`${OSS_HOST}${item.avatar}`" alt="" />
                </span>
                <h3 class="principal-name">
                  {{ item.principal_name || "暂无名称" }}
                </h3>
              </div>
              <div class="right">
                <div class="count-box">
                  <div class="count-item">
                    <span>发布视频</span>
                    <span class="number">{{
                      item.aweme_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>日均发布</span>
                    <span class="number">{{
                      item.aweme_avg_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>互动总量</span>
                    <span class="number">{{
                      item.aweme_relation_count || 0 | changeNum
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-wrap" @click="handleVideoItemClick(item)">
              <div class="left">
                <BaseImage
                  :src="item.cover || ''"
                  :default-image="defaultCoverImg"
                  fit="contain"
                  width="80px"
                  height="105px"
                  radius="6px"
                  style="background-color: #21232c;"
                />
                <!-- <span class="cover" :style="`background-image: url(${item.cover})`">
                            </span> -->
              </div>
              <div class="right">
                <div class="title">{{ item.title || "本月暂无视频" }}</div>
                <div class="count-box">
                  <div class="count-item">
                    <span>互动量</span>
                    <span class="number highlight">{{
                      item.aweme_interactive_count || 0 | changeNum
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>发布时间</span>
                    <span class="number">{{
                      item.publish_time | formatPublishTime
                    }}</span>
                  </div>
                  <div class="count-item">
                    <span>视频时长</span>
                    <span class="number">{{ item.duration || 0 }}s</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <template
        v-if="
          mixin_isBizError || mixin_isNetworldError || dataList.length === 0
        "
      >
        <div
          class="font-color3"
          style="text-align: center; width: 100%; padding-top: 20px"
        >
          暂无数据
        </div>
      </template>
    </a-spin>
  </PanleWrap>
</template>

<script>
import defaultAvatarImg from "@/assets/default/default1-1.png";
import defaultCoverImg from "@/assets/default/default3-4.png";
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/brand.js";
import _ from "lodash";
import moment from "moment";
import mixin from "./mixins/mixin";
import Order from "./components/Order";
import ButtonGroupTab from "@/components/PlatformTab/ButtonGroupTab";
import Platform from "./components/Platform";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import BaseImage from "@/components/BaseImage";

const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;

//直播条件筛选
const liveOrderList = [
  {
    text: "直播互动",
    value: "1",
  },
  // {
  //     text: "活跃账号",
  //     value: "4",
  // },
  {
    text: "观看人次",
    value: "2",
  },
  {
    text: "直播场次",
    value: "3",
  },
];

//视频条件筛选
const videoOrderList = [
  {
    text: "互动总量",
    value: "1",
  },
  {
    text: "日均发布",
    value: "2",
  },
  {
    text: "视频发布",
    value: "3",
  },
  // {
  //     text: "活跃账号",
  //     value: "4",
  // },
];

export default {
  name: "worksList",
  components: {
    PanleWrap,
    Order,
    Platform,
    PlatformHeader,
    ButtonGroupTab,
    BaseImage,
  },
  mixins: [mixin],
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatPublishTime(str) {
      if (!str) {
        return "暂无时间";
      } else {
        return moment(str).format("YYYY/MM/DD");
      }
    },
  },
  data() {
    return {
      defaultAvatarImg,
      defaultCoverImg,
      searchTime: moment().subtract(1, "months"),
      disabledDate(current) {
        return (
          current >=
          moment()
            .startOf("month")
            .subtract("month", -1)
        );
      },
      startTime: "",
      endTime: "",
      tabList: [
        {
          label: "直播",
          value: "1",
          tag: "LIVE",
        },
        {
          label: "视频",
          value: "2",
          tag: "VIDEO",
        },
      ],
      platform: "", // 平台(抖音:douyin,快手:kuaishou)
      type: "1",
      liveSort: "1",
      videoSort: "1",
      orderIndex: 0,
      liveIndex: 0,
      videoIndex: 0,
      orderList: liveOrderList,
      dataList: [],
      OSS_HOST,
      pagination: {
        size: 8,
        current: 1,
        total: 0,
      },
      isLive: true,
      isVideo: false,
      my_ranking: {},
    };
  },
  async created() {
    let timeObj = this.calcStartEndTime(moment().subtract(1, "months"));
    this.startTime = timeObj.startTime;
    this.endTime = timeObj.endTime;
    this.getList = this.requestDecorator(() => {
      const body = {
        platform: this.platform,
        params: {
          start_time: this.startTime,
          end_time: this.endTime,
          publish: "officer",
          sort: this.type === "1" ? this.liveSort : this.videoSort,
        },
      };

      if (this.type === "1") {
        return api.getNewBrandLiveList(body).then((res) => {
          this.dataList = _.get(res, "data.data.list", []);
          this.my_ranking = _.get(res, "data.data.self", {});
          return res;
        });
      } else {
        return api.getNewBrandVideoList(body).then((res) => {
          this.dataList = _.get(res, "data.data.list", []);
          this.my_ranking = _.get(res, "data.data.self", {});
          return res;
        });
      }
    });

    await this.getList();
  },
  methods: {
    getList() {},
    calcStartEndTime(date) {
      let start = date.startOf("month").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("month").format("YYYY-MM-DD HH:mm:ss");
      return {
        startTime: start,
        endTime: end,
      };
    },
    hadnleOrderClick(res) {
      console.log(res);
      if (this.type === "1") {
        this.liveSort = res.data.value;
        this.liveIndex = res.index;
      } else {
        this.videoSort = res.data.value;
        this.videoIndex = res.index;
      }
      this.orderIndex = res.index;

      this.getList();
    },
    handlePlatformClick(val) {
      this.platform = val;
      liveOrderList[1].text = this.filtrate.returnWatchText(this.platform);
      this.getList();
    },
    handleLiveItemClick(item) {
      console.log(item);
      if (item.room_id) {
        let url = `/liveDetail?id=${item.room_id}&platform=${this.platform}`;
        // this.$router.push(`/liveDetail?id=${item.room_id}`)
        window.open(url);
      } else {
        this.$message.error("暂无直播房间ID，无法查看详情");
      }
    },
    handleVideoItemClick(item) {
      if (item.aweme_id) {
        let url = `/videoDetail?id=${item.aweme_id}&platform=${this.platform}`;
        window.open(url);
      } else {
        this.$message.error("暂无视频ID，无法查看详情");
      }
    },
    handleItemClickInfo(item) {
      this.$router.push({
        path: "/dealer/production",
        query: {
          type: this.platform,
          author_id: item.author_id,
        },
      });
    },
    handleTypeClick(val) {
      this.type = val;
      if (val === "1") {
        this.orderList = liveOrderList;
        this.orderIndex = this.liveIndex;
        this.isLive = true;
        this.isVideo = false;
      } else {
        this.orderList = videoOrderList;
        this.orderIndex = this.videoIndex;
        this.isLive = false;
        this.isVideo = true;
      }
      this.getList();
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      let obj = this.calcStartEndTime(date);
      this.startTime = obj.startTime;
      this.endTime = obj.endTime;
      this.getList();
    },
    scrollMyRankig() {
      if (this.my_ranking.principal_id) {
        let dom = this.$refs[`item_${this.my_ranking.principal_id}`][0];
        dom.scrollIntoView({
          //滚动到指定节点
          block: "start",
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

img {
  max-width: 100%;
}

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.list_header {
  margin-bottom: 20px;
  > h3 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    @include font_color("font_color2");
    margin: 0;
  }
}
.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 20px;
}

.btn-box {
  display: flex;
  align-items: center;
}

.my-ranking {
  padding: 0 30px 0 16px;
  margin-right: 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  cursor: pointer;
  @include background_color("background_color51");
  span {
    padding-left: 6px;
    @include font_color("font_color4");
  }
}

.list-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  padding: 0 14px 50px 20px;
  .item {
    position: relative;
    padding: 20px 0;
    line-height: 1.5;
    border-radius: 16px;
    cursor: pointer;
    overflow: hidden;
    @include background_color("background_color2");
  }
  .live-item {
    .detail-wrap {
      .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .video-item {
    .detail-wrap {
      .title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
  .ranking-wrap {
    position: absolute;
    top: 0;
    right: 0;
    .num {
      padding: 0 12px;
      min-width: 32px;
      height: 26px;
      box-sizing: border-box;
      line-height: 26px;
      font-size: 12px;
      border-radius: 0px 16px 0px 16px;
      text-align: center;
      @include font_color("font_color22");
      @include background_color("background_color51");

      &.first {
        @include font_color("font_color17");
        @include background_color("background_color15");
      }
      &.second {
        @include font_color("font_color17");
        @include background_color("background_color18");
      }
      &.third {
        @include font_color("font_color17");
        @include background_color("background_color49");
      }
      &.other {
        @include font_color("font_color1");
      }
    }
  }
  .info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 17px;
    margin-bottom: 17px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .title {
      margin-bottom: 15px;
      font-size: 14px;
      .number {
        font-weight: bold;
        @include font_color("font_color4");
      }
    }
    .count-item {
      .number {
        font-size: 14px;
      }
    }
  }
  .detail-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    .title {
      font-size: 14px;
      @include font_color("font_color1");
    }
    .count-box {
      margin-top: 17px;
    }
  }
  .left {
    padding: 0 2%;
    font-size: 0;
    width: 34%;
    text-align: center;
  }
  .right {
    flex: 1;
    max-width: 66%;
  }
  .principal-name {
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    @include font_color("font_color1");
  }
  .avatar {
    width: 44px;
    height: 44px;
    display: inline-block;
    border-radius: 6px;
    overflow: hidden;
  }
  .cover {
    display: inline-block;
    border-radius: 6px;
    overflow: hidden;
    width: 80px;
    height: 105px;
    background-size: cover;
    background-position: center;
    @include background_color("background_color1");
  }
  .count-box {
    display: flex;
    .count-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 33.33%;
      font-size: 12px;
      @include font_color("font_color3");
      .number {
        font-weight: bold;
        @include font_color("font_color1");
        &.highlight {
          @include font_color("font_color4");
        }
      }
    }
  }
  .name {
    margin-top: 4px;
    display: flex;
    align-items: center;
    span {
      @include font_color("font_color22");
      font-size: 12px;
    }
  }
}

@media (max-width: 1600px) {
  .list-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

.base-image {
  display: inline-block;
}
</style>
