<template>
  <div class="tabs-wrap">
    <div
      v-for="(item, index) in tabData"
      :key="'tabs' + index"
      :class="
        currentClick == item.value ? 'active-tab-class tab-class' : 'tab-class'
      "
      @click="tabClick(item.value)"
    >
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    defaultValue: {
      type: [String, Number]
    },
    tabData: {
      type: Array,
    }
  },
  data() {
    return {
      currentClick: this.tabData[0].value,
    };
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler(newVal, oldVal) {
        newVal && (this.currentClick = newVal)
      }
    }
  },
  methods: {
    tabClick(value) {
      this.currentClick = value;
      this.$emit("tabChange", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tab-class.active-tab-class {
  @include font_color("font_color55");
}
.tab-class {
  cursor: pointer;
  @include font_color("font_color54");
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 24px;
}
.tab-class:last-child {
  margin-right: 0;
}
.tabs-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
