<template>
  <div class="tabs-content4">
    <div class="tabs-content4-main">
      <div class="title-div">
        <span class="title">大区对比</span>
      </div>
      <div class="chart-div" v-if="regionData && regionData.length > 0">
        <div class="chart-div-left">
          <div class="table-container">
            <div class="table-container-header">
              <div>大区</div>
              <div>门店数</div>
              <div>总流量</div>
              <div>线索量</div>
              <div>线索转化率</div>
              <div>转化率变化</div>
            </div>
            <div class="table-container-body">
              <div
                :class="[
                  'table-item',
                  currentLabel == item.area_name ? 'active' : '',
                ]"
                v-for="(item, index) in regionData"
                :key="index"
              >
                <div>{{ item.area_name || "暂无大区" }}</div>
                <div>{{ item.dealer_count | changeNum }}</div>
                <div>{{ item.total_flow | changeNum }}</div>
                <div>{{ item.sales_cue_count | changeNum }}</div>
                <div>{{ item.clue_conversion_rate | numRate3 }}</div>
                <div>
                  <span style="margin: 0 6px 0 0">{{
                    item.day_rate | numRate
                  }}</span>
                  <svg-icon
                    v-if="item.day_rate >= 0"
                    icon-class="caret-up"
                    style="font-size: 10px"
                  />
                  <svg-icon
                    v-if="item.day_rate < 0"
                    icon-class="caret-down"
                    style="font-size: 10px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-div-right">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(regionData)"
            :id="'bar-chart-4'"
          />
        </div>
      </div>

      <div class="empty-box" v-else>
        <Empty title="暂无数据" />
      </div>
    </div>
    <div class="tabs-content4-bottom">
      <div class="title-div">
        <span class="title">标杆门店</span>
      </div>
      <div class="chart-div">
        <div class="chart-div-item">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(pieData.live_clue_dealer, '直播线索')"
            :id="'pie-chart-3'"
          />
        </div>
        <div class="chart-div-item">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(pieData.video_clue_dealer, '视频线索')"
            :id="'pie-chart-4'"
          />
        </div>
        <div class="chart-div-item">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(pieData.private_clue_dealer, '私信线索')"
            :id="'pie-chart-5'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/MyChart/MyChart";
import chartBar8 from "../../options/chartBar8.js";
import deepClone from "@/utils/clone-utils";
import api from "@/api/conversionMarket.js";
import Empty from "@/components/Empty/Empty";
import chartPie5 from "../../options/chartPie5.js";
export default {
  name: "TabsContent4",
  components: { MyChart, Empty },
  data() {
    return {
      chartShow: true,
      regionData: [],
      currentLabel: "",
      pieData: {
        live_clue_dealer: [],
        private_clue_dealer: [],
        video_clue_dealer: [],
      },
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getClueRegionV1();
      this.getClueRegionV2();
      this.currentLabel = this.$parent.area_id.label.trim();
    },
    getBarOption(data) {
      if (data && data.length > 0) {
        let xData = data.map((item) => {
          return item.area_name || "暂无大区";
        });
        let yData = data.map((item) => {
          return item.clue_conversion_rate * 100;
        });
        let option = deepClone(chartBar8);
        option.yAxis[0].data = xData.reverse();
        option.series[0].data = yData.reverse();
        return option;
      } else {
        let option = deepClone(chartBar8);
        return option;
      }
    },
    getPieOption(data, text) {
      let option = deepClone(chartPie5);
      let _data = data || []
      let total = _data.reduce((acc, cur) => {
        acc += cur.clue_count;
        return acc;
      }, 0);
      let newData = _data.map((item) => {
        return {
          name: item.dealer_name,
          value: item.clue_count,
        };
      });
      option.title[0].text = total;
      option.title[1].text = text;

      option.series[0].data = newData;

      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getClueRegionV1() {
      let data = this.getParentsParams();
      api.getClueRegionV1(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          this.regionData = data || [];
        }
      });
    },
    getClueRegionV2() {
      let data = this.getParentsParams();
      api.getClueRegionV2(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          console.log(data);
          this.pieData = data;
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content4 {
  width: 100%;
  height: 824px;
  &-main {
    width: 100%;
    height: 448px;
    background: #2b2f3b;
    border-radius: 16px;
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      > .title {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .chart-div {
      width: 100%;
      height: 386px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      &-left {
        width: 55%;
        height: 100%;
        .table-container {
          width: 100%;
          padding: 0 30px 0 20px;
          &-header {
            width: 100%;
            height: 50px;
            background: #242832;
            box-shadow: inset 0px -1px 0px 0px #3c4252;
            display: flex;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 50px;
            > div {
              flex: 1;
              text-align: right;
              padding: 0 20px 0 0;
              &:first-child {
                text-align: left;
                max-width: 100px;
                padding-left: 20px;
              }
              &:last-child {
                min-width: 130px;
              }
            }
          }
          &-body {
            width: 100%;
            height: 310px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            .table-item {
              width: 100%;
              height: 50px;
              background: rgba(255, 255, 255, 0.01);
              box-shadow: inset 0px -1px 0px 0px #3c4252;
              display: flex;
              font-size: 14px;
              color: rgba(255, 255, 255, 0.85);
              line-height: 50px;
              > div {
                flex: 1;
                text-align: right;
                padding: 0 20px 0 0;
                &:first-child {
                  text-align: left;
                  max-width: 100px;
                  padding-left: 20px;
                }
                &:last-child {
                  min-width: 130px;
                }
              }
              &.active {
                background: #3b3835;
                box-shadow: inset 0px -1px 0px 0px #363a46,
                  inset 2px 0px 0px 0px #ffcc55;
              }
            }
          }
        }
      }
      &-right {
        width: 45%;
        height: 100%;
      }
    }

    .empty-box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-bottom {
    width: 100%;
    height: 360px;
    margin-top: 16px;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 20px;
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > .title {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .chart-div {
      width: 100%;
      height: 298px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      &-item {
        flex: 1;
      }
    }
  }
}
</style>
