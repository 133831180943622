<template>
  <a-spin :spinning="detailLoading" class="loading-box">
    <div class="activity-list-container" v-if="currentItem.id">
      <div class="activity-header-info">
        <div class="activity-name-container">
          <span class="activity-name">
            {{ currentItem.activityName || "-" }}
          </span>
          <span>
            <a
              class="link"
              @click="operate('openHistoryDrawer', currentItem.id)"
              v-if="currentItem.isHistory"
            >
              变更历史
            </a>
            <a
              class="link"
              v-if="
                isAllRegion &&
                  (currentItem.activityStatus === 1 ||
                    currentItem.activityStatus === 2)
              "
              @click="operate('openTemplateDrawer')"
            >
              确认导拍模板
            </a>
            <a
              class="link"
              v-if="isAllRegion && currentItem.activityStatus === 3"
              @click="operate('uploadWinnerPic')"
            >
              上传获奖名单
            </a>
            <a
              class="link"
              v-if="
                isAllRegion &&
                  (currentItem.activityStatus === 1 ||
                    currentItem.activityStatus === 2)
              "
              @click="operate('edit')"
            >
              编辑
            </a>
            <a class="link" @click="operate('delete')" v-if="isAllRegion">
              删除
            </a>
          </span>
        </div>
        <div class="activity-nav-container">
          <div
            class="nav-btn"
            v-for="(item, index) in navList"
            :key="index"
            @click="operate('jumpToPage', item)"
          >
            <svg-icon :icon-class="item.icon" class="icon" />
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="activity-detail-content">
        <div style="margin-top:20px">
          <div class="d-flex justify-content-between align-items-center">
            <div class="title-wrapper">
              <div class="line"></div>
              <span class="title-text">基本信息</span>
            </div>
            <a
              class="link l-8"
              style="margin-bottom:20px"
              @click="operate('openWinnerPreviewModal', awardList)"
              v-if="awardList.length > 0 && currentItem.activityStatus === 3"
            >
              <svg-icon
                icon-class="activity-reward-list"
                style="font-size:16px;margin-right:8px"
              ></svg-icon>
              获奖名单</a
            >
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div>
              <div class="d-flex flex-row content-wrapper">
                <div class="content-label">活动时间/范围</div>
                <div class="align-items-center content-text">
                  <span
                    >{{ currentItem.startTime }} 至
                    {{ currentItem.endTime }}</span
                  >
                  <a-divider type="vertical" />
                  <span>
                    <template
                      v-if="
                        currentItem.scopeList &&
                          currentItem.scopeList.length > 0
                      "
                    >
                      <template v-if="currentItem.scope === 1">
                        <span>全部经销商</span>
                      </template>
                      <template v-else>
                        {{ scopeData[currentItem.scope] }} ·
                        <span
                          v-for="(item, index) in currentItem.scopeList"
                          :key="item.scopeId"
                        >
                          {{ item.scopeName }}
                          <span v-if="index < currentItem.scopeList.length - 1"
                            >,</span
                          >
                        </span>
                      </template>
                    </template>
                    <template v-else>-</template>
                  </span>
                </div>
              </div>
              <div class="d-flex flex-row content-wrapper">
                <div class="content-label has-tag">
                  平台/条件
                </div>
                <div class="align-items-center content-text">
                  <div class="flex-tag ">
                    <div
                      class="flex-tag-item one-line"
                      v-for="(item, index) in currentItem.platform.split(',')"
                      :key="index"
                    >
                      {{ platformData[item] }}
                    </div>
                  </div>
                  <a-divider type="vertical" />
                  <div class="flex-tag ">
                    <div
                      class="flex-tag-item one-line"
                      v-for="(item, index) in currentItem.statisticsRange.split(
                        ','
                      )"
                      :key="index"
                    >
                      {{ statisticsRangeData[item] }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row content-wrapper">
                <div class="content-label has-tag">
                  门店数量&amp;账号
                </div>
                <div class="align-items-center content-text">
                  <template v-if="currentItem.dealerNum">
                    {{ currentItem.dealerNum }}门店
                    <span style="margin-left:8px">
                      {{ currentItem.authorNum }}账号
                    </span>
                    <a class="link l-8" @click="operate('openMemberListModal')"
                      >查看</a
                    >
                  </template>
                  <template v-else>-</template>
                </div>
              </div>
            </div>
            <div>
              <div class="preview-img-box">
                <BaseImage
                  :src="currentItem.tempCover"
                  :defaultImage="defaultCoverImg"
                  fit="cover"
                  width="70px"
                  height="70px"
                  style="background-color: #000"
                />
                <div class="mask"></div>
                <div class="img-name">封面图</div>
                <div
                  class="shadow-box"
                  @click="operate('showImage', currentItem.cover)"
                >
                  <a-icon type="eye" class="icon" />
                </div>
              </div>
              <div class="preview-img-box">
                <BaseImage
                  :src="currentItem.tempIntroductionImage"
                  :defaultImage="defaultCoverImg"
                  fit="cover"
                  width="70px"
                  height="70px"
                  style="background-color: #000"
                />
                <div class="mask"></div>
                <div class="img-name">简介图</div>
                <div
                  class="shadow-box"
                  @click="operate('showImage', currentItem.introductionImage)"
                >
                  <a-icon type="eye" class="icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="currentItem.isVideo">
          <a-divider />
          <div class="video-wrapper">
            <div class="title-wrapper">
              <div class="line"></div>
              <span class="title-text">视频</span>
            </div>
            <div class="d-flex flex-row content-wrapper">
              <div class="content-label">视频创作来源</div>
              <div class="align-items-center content-text">
                <span v-if="currentItem.isVideo">
                  {{ videoCreateSourceData[currentItem.videoCreateSource] }}
                </span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="d-flex flex-row content-wrapper">
              <div class="content-label">视频目标/活动导拍模版</div>
              <div class="align-items-center content-text">
                <span v-if="currentItem.awemeTarget">
                  视频播放量≥{{ currentItem.awemeTarget }}
                </span>
                <span v-else>-</span>
                <a-divider type="vertical" />
                <template v-if="currentItem.activityGuideshootCount">
                  {{ currentItem.activityGuideshootCount }}
                  <a
                    class="link l-8"
                    @click="operate('openTemplatePreviewListModal')"
                  >
                    查看模版
                  </a>
                </template>
                <template v-else>-</template>
              </div>
            </div>
            <div class="d-flex flex-row content-wrapper">
              <div class="content-label has-tag">
                {{
                  `视频${currentItem.videoKeyTypes
                    .split(",")
                    .map((item) => videoTypeData[item])
                    .join("/")}包含${
                    currentItem.videoKeywordRelation === "or" ? "任意" : "全部"
                  }关键词`
                }}
              </div>
              <div class="content-text">
                <div class="flex-tag" v-if="currentItem.videoKeyword">
                  <div
                    class="flex-tag-item"
                    v-for="(item, index) in currentItem.videoKeyword.split(',')"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="currentItem.isLive">
          <a-divider />
          <div class="live-wrapper">
            <div class="title-wrapper">
              <div class="line"></div>
              <span class="title-text">直播</span>
            </div>
            <div class="d-flex flex-row content-wrapper">
              <div class="content-label">直播目标</div>
              <div class="align-items-center content-text">
                <span v-if="currentItem.liveTarget">
                  {{ liveTargetTypeData[currentItem.liveTargetType] }}≥{{
                    currentItem.liveTargetType === 1
                      ? currentItem.liveTarget / 60 + "min"
                      : currentItem.liveTarget
                  }}
                </span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="d-flex flex-row content-wrapper">
              <div class="content-label has-tag">
                {{
                  `标题包含${
                    currentItem.liveKeywordRelation === "or" ? "任意" : "全部"
                  }关键词`
                }}
              </div>
              <div class="content-text">
                <div class="flex-tag" v-if="currentItem.liveKeyword">
                  <div
                    class="flex-tag-item"
                    v-for="(item, index) in currentItem.liveKeyword.split(',')"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="currentItem.isNote">
          <a-divider />
          <div class="note-wrapper">
            <div class="title-wrapper">
              <div class="line"></div>
              <span class="title-text">笔记</span>
            </div>
            <div class="d-flex flex-row content-wrapper">
              <div class="content-label">笔记目标</div>
              <div class="align-items-center content-text">
                <span v-if="currentItem.noteTextTarget">
                  正文字数≥{{ currentItem.noteTextTarget }}
                </span>
                <span
                  style="margin-left: 8px"
                  v-if="
                    currentItem.noteTextTarget && currentItem.noteImageTarget
                  "
                ></span>
                <span v-if="currentItem.noteImageTarget">
                  图片数≥{{ currentItem.noteImageTarget }}
                </span>
                <span
                  v-if="
                    !currentItem.noteTextTarget && !currentItem.noteImageTarget
                  "
                  >-</span
                >
              </div>
            </div>
            <div class="d-flex flex-row content-wrapper">
              <div class="content-label has-tag">
                {{
                  `笔记${currentItem.noteKeyTypes
                    .split(",")
                    .map((item) => noteTypeData[item])
                    .join("/")}包含${
                    currentItem.noteKeywordRelation === "or" ? "任意" : "全部"
                  }关键词`
                }}
              </div>
              <div class="content-text">
                <div class="flex-tag" v-if="currentItem.noteKeyword">
                  <div
                    class="flex-tag-item"
                    v-for="(item, index) in currentItem.noteKeyword.split(',')"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </div>
        </template>

        <div style="height:20px"></div>
      </div>
    </div>
    <div class="activity-list-container empty" v-else>
      <Empty />
    </div>
  </a-spin>
</template>

<script>
import api from "@/api/marketingActivityList";
import MemberListModal from "../../components/MemberListModal.vue";
import TemplatePreviewListModal from "../../components/TemplatePreviewListModal.vue";
import BaseImage from "@/components/BaseImage";
import defaultCoverImg from "@/assets/default/default3-4.png";
import {
  navList as defaultNavList,
  platformData,
  statisticsRangeData,
  videoCreateSourceData,
  scopeData,
  liveTargetTypeData,
  noteTypeData,
  videoTypeData,
} from "../constant";
import LabelContent from "@/components/LabelContent";
import Empty from "@/components/Empty/Empty";
import _ from "lodash";
import { aliImgTransform } from "@/utils/util.js";

export default {
  components: {
    MemberListModal,
    TemplatePreviewListModal,
    BaseImage,
    LabelContent,
    Empty,
  },
  props: {
    isAllRegion: {
      type: Boolean,
    },
  },
  data() {
    return {
      defaultCoverImg,
      previewVisible: false,
      videoVisible: false,
      previewImage: "",
      currentItem: {},
      detailLoading: false,
      tableLoading: false,
      templateData: [],
      flvPlayer: null,
      platformData,
      statisticsRangeData,
      videoCreateSourceData,
      scopeData,
      liveTargetTypeData,
      noteTypeData,
      videoTypeData,
      navList: [],
      awardList: [],
    };
  },
  computed: {
    hasDouyinKuaishou() {
      const platforms = this.currentItem.platform.split(",");
      return platforms.includes("1") || platforms.includes("2");
    },
  },
  methods: {
    /**
     * 获取活动详情
     * @param {object} item 选择的任务item
     */
    initData(item) {
      if (item.id) {
        this.getData(item.id);
      } else {
        this.currentItem = {};
      }
    },
    async getData(id) {
      try {
        this.detailLoading = true;
        await this.getActivityDetail(id);
        await Promise.all([this.getMatrixMemberNum(), this.getAwardDetail(id)]);
        this.detailLoading = false;
      } catch (error) {
        console.log(error);
        this.detailLoading = false;
      }
    },

    /**
     * 获取活动详情
     * @param {string} id 活动id
     * @return {Promise}
     */
    getActivityDetail(id) {
      const params = {
        activityId: id,
      };
      return api
        .getActivityDetail(params)
        .then((res) => {
          if (res.data.code == 200) {
            this.currentItem = res.data.data || {};
            this.currentItem.tempCover = this.currentItem.cover
              ? aliImgTransform(this.currentItem.cover, 100)
              : "";
            this.currentItem.tempIntroductionImage = this.currentItem
              .introductionImage
              ? aliImgTransform(this.currentItem.introductionImage, 100)
              : "";
            this.setNavList();
          } else {
            this.onClose();
            this.$message.error("获取详情失败");
          }
        })
        .catch(() => {});
    },
    /**
     * 查询活动成员数量及媒体号数
     */
    getMatrixMemberNum() {
      const params = {
        activityId: this.currentItem.id,
        scope: this.currentItem.scope,
        scopeId: this.currentItem.scopeId,
      };
      return api
        .getMatrixMemberNum(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.$set(this.currentItem, "dealerNum", res.data.data.dealerNum);
            this.$set(this.currentItem, "authorNum", res.data.data.authorNum);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {});
    },
    /**
     * 获取获奖名单详情
     * @param {string} id 活动id
     */
    getAwardDetail(id) {
      const params = {
        activityId: id,
      };
      return api
        .getAwardsImageV_2_6_0(params)
        .then((res) => {
          if (res.data.code == 200) {
            this.awardList = res.data.data;
          } else {
            this.$message.error("获取获奖名单失败");
          }
        })
        .catch(() => {});
    },
    /**
     * 导航设置
     */
    setNavList() {
      let navList = _.cloneDeep(defaultNavList);
      // 过滤掉直播相关数据
      if (
        this.currentItem.activityStatus === 3 ||
        this.currentItem.isLive === 0 ||
        !this.hasDouyinKuaishou
      ) {
        const names = ["直播大屏"];
        navList = navList.filter((item) => !names.includes(item.name));
      }
      this.navList = navList;
    },
    /**
     * 操作
     * @param {string} type 操作类型
     * @param {object} data 传递数据
     */
    operate(type, data) {
      const noteContentTypes = this.currentItem.noteContentTypes.split(",");
      const noteData = {
        isShowNoteContent: Number(noteContentTypes.includes("1")),
        isShowNoteVideo: Number(noteContentTypes.includes("2")),
      };

      this.$emit("operate", {
        type,
        currentItem: this.currentItem,
        data,
        noteData,
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/flex.scss";

::v-deep .ant-upload-picture-card-wrapper {
  display: block;
}

::v-deep .ant-divider.ant-divider-vertical {
  background: rgba(255, 255, 255, 0.08);
  margin: 0 12px;
}

::v-deep .ant-divider.ant-divider-horizontal {
  background: rgba(255, 255, 255, 0.08);
  margin: 20px 0;
}

.loading-box {
  flex: 1;
}

.activity-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 16px);
  overflow: hidden;

  &.empty {
    justify-content: center;
    align-items: center;
  }

  .link {
    margin-left: 16px;
    color: #ffcc55;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    &.l-8 {
      margin-left: 8px;
    }
  }

  // 头部信息
  .activity-header-info {
    padding: 20px;
    flex-shrink: 0;
    overflow-x: auto;
    background: #2b2f3b;
    border-radius: 16px;

    .activity-name-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .activity-name {
        color: rgba(255, 255, 255, 0.88);
        font-size: 16px;
        font-weight: 600;
      }
    }

    .activity-nav-container {
      display: flex;
      flex-direction: row;
      padding-top: 19px;

      .nav-btn {
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        margin-right: 8px;
        border-radius: 8px;
        background: #3b3835;
        font-size: 14px;
        color: #ffcc55;
        cursor: pointer;
        flex-wrap: nowrap;
        user-select: none;
        flex-shrink: 0;
        font-weight: 400;
        display: flex;
        align-items: center;

        .icon {
          font-size: 16px;
          margin-right: 8px;
        }

        &:hover {
          background: #765b36;
        }
      }
    }
  }

  // 活动详情
  .activity-detail-content {
    flex: 1;
    padding: 0 20px;
    overflow-y: auto;
    background: #2b2f3b;
    border-radius: 16px;
    margin-top: 18px;

    .title-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 20px;

      .line {
        width: 3px;
        height: 12px;
        background: #ffcc55;
        margin-right: 8px;
      }

      .title-text {
        color: rgba(255, 255, 255, 0.88);
        font-weight: 600;
        font-size: 14px;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: row;
      line-height: 24px;

      & + .content-wrapper {
        margin-top: 25px;
      }

      .content-label {
        width: 172px;
        color: rgba(255, 255, 255, 0.55);
        flex-shrink: 0;

        &.has-tag {
          line-height: 28px;
        }
      }

      .content-text {
        flex: 1;
        display: flex;
        flex-direction: row;
        color: rgba(255, 255, 255, 0.88);
      }
    }

    .preview-img-box {
      display: inline-block;
      width: 70px;
      height: 70px;
      position: relative;
      border-radius: 6px;
      overflow: hidden;

      & + .preview-img-box {
        margin-left: 8px;
      }

      &:hover {
        .shadow-box {
          opacity: 1;
        }
      }

      .img-name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        line-height: 20px;
        font-size: 12px;
      }

      .mask {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 49%, #000000 100%);
      }

      .shadow-box {
        width: 100%;
        height: 100%;
        color: rgba(255, 255, 255, 0.85);
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .flex-tag {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      .flex-tag-item {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 28px;
        padding: 0 8px;
        background: #1d1d1d;
        border-radius: 4px;
        border: 1px solid #434343;
        height: 28px;
        text-align: center;
        margin-right: 4px;
        margin-bottom: 10px;

        &.one-line {
          margin-bottom: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .video-wrapper {
      min-height: 120px;
      background: url("~@/assets/img/activity-video.png") no-repeat;
      background-position: right center;
      background-size: auto 120px;
    }

    .live-wrapper {
      min-height: 120px;
      background: url("~@/assets/img/activity-live.png") no-repeat;
      background-position: right center;
      background-size: auto 120px;
    }

    .note-wrapper {
      min-height: 120px;
      background: url("~@/assets/img/activity-note.png") no-repeat;
      background-position: right center;
      background-size: auto 120px;
    }
  }
}
</style>
