<template>
  <div class="title-box">
    <div>{{ title }}</div>
    <a-tooltip
      placement="top"
      :title="tips"
    >
      <svg-icon :icon-class="'tooltip-icon2'" class="tooltip-icon" />
    </a-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    tips: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.title-box {
  display: flex;
  align-items: center;
  .tooltip-icon {
    @include font_color("font_color50");
    @include background_color("background_color51");
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    margin-left: 4px;
  }
}
</style>