<template>
  <div
    class="card-container"
    :style="{
      background,
      color,
      width,
      height,
    }"
    @click="cardClick"
  >
    <div>
      <span>{{ value }}</span>
      <span>
        <span>{{ name }}</span>
        <!-- <CardTooltip class="tooltip-icon" v-if="tooltipTitle">
          <slot>{{ tooltipTitle }}</slot>
        </CardTooltip> -->
      </span>
    </div>
    <svg-icon :icon-class="icon" class="icon" />
    <div class="card-shadow" v-if="showShadow">
      <div>
        <span>点击查看</span>
        <svg-icon icon-class="arrow-right" class="arrow-icon" />
      </div>
    </div>
  </div>
</template>

<script>
// import CardTooltip from "@/components/Tooltip/CardTooltip";
export default {
  name: "IconCard",
  components: {
    // CardTooltip,
  },
  props: {
    background: {
      type: String,
      default: "#fff",
    },
    color: {
      type: String,
      default: "#000",
    },
    width: {
      type: String,
      default: "148px",
    },
    height: {
      type: String,
      default: "72px",
    },
    icon: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "-",
    },
    value: {
      default: "-",
    },
    tooltipTitle: {
      default: "",
    },
    showShadow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    cardClick() {
      this.$emit("cardClick", name);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

.card-container {
  width: 148px;
  height: 72px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  &:hover {
    .card-shadow {
      display: block;
    }
  }
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px 0 14px 16px;
    > span:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
    }
    > span:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > span {
        font-size: 12px;
        opacity: 0.6;
      }
    }
    .tooltip-icon {
      font-size: 16px;
    }
  }
  .icon {
    position: absolute;
    right: 2px;
    bottom: 2px;
    font-size: 58px;
  }
  .card-shadow {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    border-radius: 8px;
    color: #fff;
    > div {
      width: 100%;
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      padding-right: 6px;
      .arrow-icon {
        font-size: 24px;
      }
    }
  }
}
</style>
