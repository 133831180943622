<template>
  <div class="tabs-content3">
    <NewTabs3 :tabData="topTabData" @tabChange="topTabChange" />
    <div class="tabs-content3-main">
      <div class="title-div">
        <span class="title">时间趋势</span>
        <NewTabs2
          :tabData="tabsData"
          @tabChange="changeTabs"
          v-if="isArea && topTabsKey != 'clue_conversion_rate_tab'"
        />
      </div>
      <div class="chart-div">
        <div class="chart-div-left">
          <MyChart
            v-if="chartShow"
            :options="
              getLineOption(
                trendData[tabsKey][topTabsKey],
                trendData[tabsKey].date
              )
            "
            :id="'line-chart-1'"
          />
        </div>
        <div class="chart-div-right">
          <MyChart
            v-if="chartShow && topTabsKey != 'clue_conversion_rate_tab'"
            :options="getBarOption(trendData[tabsKey][topTabsKey])"
            :id="'bar-chart-2'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import NewTabs3 from "@/components/PlatformTab/NewTabs3";
import MyChart from "@/components/MyChart/MyChart";
import chartLine4 from "../../options/chartLine4.js";
import chartLine5 from "../../options/chartLine5.js";
import chartBar7 from "../../options/chartBar7.js";
import deepClone from "@/utils/clone-utils";
import api from "@/api/conversionMarket.js";
export default {
  name: "TabsContent3",
  components: { NewTabs2, NewTabs3, MyChart },
  data() {
    return {
      chartShow: true,
      topTabData: [
        {
          label: "线索量趋势",
          value: "clue_tab",
        },
        {
          label: "流量趋势",
          value: "flow_tab",
        },
        {
          label: "转化率",
          value: "clue_conversion_rate_tab",
        },
      ],
      topTabsKey: "clue_tab",
      tabsData: [
        {
          label: "按渠道",
          value: "v1",
        },
        {
          label: "按大区",
          value: "v2",
        },
      ],
      tabsKey: "v1",
      lineData: {
        xData: [],
        yAwemeData: [],
        yLiveData: [],
      },
      barData: {
        xData: [],
        yData: [],
      },

      trendData: {
        v1: {},
        v2: {},
      },
      isArea: true,
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      if (this.$parent.area_id.key) {
        this.isArea = false;
        this.tabsKey = "v1";
      } else {
        this.isArea = true;
      }
      this.getTimeTrendV1();
      this.getTimeTrendV2();
    },
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
    },
    topTabChange(val) {
      if (this.topTabsKey == val) {
        return false;
      }
      this.topTabsKey = val;
      this.resizeChart();
    },
    getLineOption(data, xData) {
      if (!data) {
        return {};
      }
      let newData = deepClone(data);
      let newDate = deepClone(xData);
      let option = deepClone(chartLine4);
      if (this.topTabsKey == "clue_conversion_rate_tab") {
        newData = this.trendData["v2"]["clue_conversion_rate_tab"];
        newDate = this.trendData["v2"].date;
        option = deepClone(chartLine5);
      }
      let color = [
        "#F756A9",
        "#E13DDB",
        "#8E51DA",
        "#3C7EFF",
        "#5AAAFB",
        "#3FD4CF",
        "#27C346",
        "#B8E24B",
        "#FBE94B",
        "#FFCC55",
        "#FF9E3D",
        "#F9925A",
        "#F76965",
        "#ABABAC",
        "#929293",
      ];
      if (!newData) {
        return {};
      }
      let seriesData = [];
      seriesData = newData.map((item, index) => {
        return {
          name: item.name,
          smooth: true,
          type: "line",
          symbol: "circle",
          showSymbol: true,
          symbolSize: 6,
          itemStyle: {
            normal: {
              color: color[index],
            },
          },
          data: item.data,
        };
      });
      let legendData = [];
      legendData = newData.map((item, index) => {
        return item.name;
      });
      option.legend.data = legendData;
      option.xAxis.data = newDate;
      option.series = seriesData;
      return option;
    },
    getBarOption(data, xData) {
      if (!data) {
        return {};
      }
      let option = deepClone(chartBar7);
      let legendData = [];
      legendData = data.map((item, index) => {
        return item.name;
      });
      option.yAxis[0].data = legendData;
      let seriesData = [];
      data.forEach((item) => {
        seriesData.push(item.total || 0);
      });
      option.series[0].data = seriesData;
      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getTimeTrendV1() {
      let data = this.getParentsParams();
      api.getTimeTrendV1(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          this.trendData.v1 = data || {};
        }
      });
    },
    getTimeTrendV2() {
      let data = this.getParentsParams();
      api.getTimeTrendV2(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          this.trendData.v2 = data || {};
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content3 {
  width: 100%;
  height: 469px;
  &-main {
    width: 100%;
    height: 413px;
    background: #2b2f3b;
    border-radius: 16px;
    margin-top: 24px;
    .title-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      > .title {
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .chart-div {
      width: 100%;
      height: 351px;
      display: flex;
      justify-content: space-between;
      &-left {
        width: 66%;
        height: 100%;
      }
      &-right {
        width: 33%;
        height: 100%;
      }
    }
  }
}
</style>
