<template>
  <div>
    <platform-header title="门店信息管理" :showPlatform="false" />
    <div class="tools">
      <ButtonGroup :tab-data="tabList" @tabChange="handleTypeClick" />
      <RouterButton :title="'账号授权管理'" :path="'/accountAuthorization'" />
    </div>

    <div class="tools2">
      <a-select
        v-model="region_select"
        :suffixIcon="suffixIcon"
        style="width: 132px; height: 40px; margin-right: 20px"
        placeholder="选择大区"
      >
        <a-select-option
          v-for="item in regionData"
          :value="item.id"
          :key="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>

      <a-input
        v-model.trim="search_val"
        v-emoji
        style="width: 356px; margin-right: 20px"
        class="aft-input default"
        placeholder="搜索门店名或门店编码关键字"
      />
      <a-select
        v-model="groupValue"
        :suffixIcon="suffixIcon"
        style="width: 256px; height: 40px; margin-right: 20px"
        placeholder="选择分组"
      >
        <a-select-option value="">不限分组</a-select-option>
        <a-select-option
          :value="item.group_code"
          v-for="item in groupData"
          :key="item.group_code"
        >
          {{ item.group_name }}
        </a-select-option>
      </a-select>
      <a-button class="search-btn" @click="onSearch">确定</a-button>
    </div>

    <div style="padding: 0 20px">
      <div class="content">
        <!-- <div class="flex store-top">
          <div class="img_box">
            <img :src="brands_logo" class="img_style" />
          </div>
          <div class="cols cols-2">
            <platform-tab
              ref="region"
              :tabData="regionData"
              @tabChange="changeRegion"
              v-if="regionData.length > 0"
            ></platform-tab>
          </div>
        </div> -->
        <div class="rows">
          <a-table
            class="table2 aft-table default"
            :columns="columns"
            :row-key="record => record.id"
            :data-source="dealerList"
            :loading="listLoading"
            :pagination="false"
            :scroll="{ y: 'calc(100vh - 390px)' }"
            :locale="locale"
            :customRow="openDetail"
          >
            <div
              slot="num"
              slot-scope="text, records, index"
              class="font-color2 f14"
            >
              {{ (current - 1) * page_size + Number(index) + 1 }}
            </div>
            <div slot="customTitle" style="display: flex">
              <div style="line-height: 32px; margin-right: 12px">
                门店名称<span class="fc-20"
                  >（{{ pagination.total | changeNum }}）</span
                >
              </div>
              <!-- <div>
                <a-input-search
                  placeholder="请输入门店全名或关键字"
                  v-model="search_val"
                  :loading="is_search"
                  @pressEnter="onSearch"
                  @search="onSearch"
                />
              </div> -->
            </div>
            <div
              slot="shopName"
              slot-scope="text, record"
              style="color: #fff; height: 61px; line-height: 61px"
              class="shop-name"
            >
              <a-popover placement="right">
                <template slot="content">
                  <div class="detail-content">
                    <a-spin tip="" :spinning="detailLoading">
                      <div class="detail-wrap" v-if="detailData">
                        <div class="detail-title">
                          {{ detailData.name }}
                        </div>
                        <a-row>
                          <a-col :span="12">
                            <div class="describe">
                              <span>门店编码：</span>
                              <span class="describe-value">{{
                                detailData.store_code || "-"
                              }}</span>
                            </div>
                            <div class="describe">
                              <span>公司名称：</span>
                              <span class="describe-value">{{
                                detailData.company_name || "-"
                              }}</span>
                            </div>
                            <div class="describe">
                              <span>联系电话：</span>
                              <span class="describe-value">{{
                                detailData.sellphone || "-"
                              }}</span>
                            </div>
                          </a-col>
                          <a-col :span="12">
                            <div class="describe">
                              <div class="describe_code">
                                <span>城</span><span></span><span>市：</span>
                              </div>
                              <span class="describe-value">{{
                                detailData.city_name || "-"
                              }}</span>
                            </div>
                            <div class="describe">
                              <div class="describe_code">
                                <span>经</span><span>纬</span><span>度：</span>
                              </div>
                              <span class="describe-value">
                                <span
                                  style="display;inline-block;padding-right:6px"
                                  >{{ detailData.lng || "-" }}</span
                                >
                                <span>{{ detailData.lat || "-" }}</span>
                              </span>
                            </div>
                            <div class="describe">
                              <div class="describe_code">
                                <span>品</span><span></span><span>牌：</span>
                              </div>
                              <span class="describe-value order_color">{{
                                detailData.sale_brand_names || "-"
                              }}</span>
                            </div>
                          </a-col>
                        </a-row>
                      </div>
                    </a-spin>

                    <a-table
                      class="table2 hover-table"
                      :columns="detail_columns"
                      :row-key="record => record.author_id"
                      :data-source="detailList"
                      :loading="detailListLoading"
                      :pagination="false"
                      :scroll="{ y: 240 }"
                      :bordered="false"
                      :locale="{ emptyText: '暂无数据' }"
                    >
                      <div slot="status" slot-scope="text, record">
                        <span v-if="record.status == 0" class="no_accredit"
                          >未授权</span
                        >
                        <span v-else-if="record.status == 1" class="accredit"
                          >已授权</span
                        >
                        <span v-else class="lose_accredit">授权失效</span>
                      </div>
                      <div
                        slot="p1_status"
                        slot-scope="text, record"
                        class="item"
                      >
                        {{
                          record.p1_status == 0
                            ? "未授权"
                            : record.p1_status == 1
                            ? "已授权"
                            : record.p1_status == 2
                            ? "授权失效"
                            : "未授权"
                        }}
                      </div>
                      <div
                        slot="p2_status"
                        slot-scope="text, record"
                        class="item"
                      >
                        <span v-if="record.platform == '懂车帝'">-</span>
                        <span v-else>{{
                          record.p2_status == 0
                            ? "未授权"
                            : record.p2_status == 1
                            ? "已授权"
                            : record.p2_status == 2
                            ? "授权失效"
                            : record.p2_status == 4 || record.p2_status == 5
                            ? "授权中"
                            : "未授权"
                        }}</span>
                      </div>
                      <div
                        slot="p6_status"
                        slot-scope="text, record"
                        class="item"
                      >
                        <span
                          v-if="
                            record.platform == '懂车帝' ||
                              record.platform == '快手'
                          "
                          >-</span
                        >
                        <span v-else>{{
                          record.p6_status == 0 || record.p6_status == 6
                            ? "未授权"
                            : record.p6_status == 1
                            ? "已授权"
                            : record.p6_status == 4
                            ? "授权失效"
                            : record.p6_status == 5
                            ? "授权中"
                            : "未授权"
                        }}</span>
                      </div>
                    </a-table>
                  </div>
                </template>
                <a class="item name" @mouseenter="onGetDetail(record.id)">{{
                  record.name
                }}</a>
                <img
                  @mouseenter="onGetDetail(record.id)"
                  src="@/assets/img/store-tip.png"
                  class="img-tip"
                  width="90"
                  height="28"
                  alt=""
                />
                <img
                  @mouseenter="onGetDetail(record.id)"
                  src="@/assets/img/store-tip-active.png"
                  class="img-tip-active"
                  width="90"
                  height="28"
                  alt=""
                />
              </a-popover>
            </div>
          </a-table>
          <!-- 分页配置 -->
          <base-pagination
            :currentPage="current"
            :pageSize="page_size"
            :total="listTotal"
            @onChange="onChange"
            @onShowSizeChange="onChange"
          >
          </base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "storeManagement"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import PlatformTab from "@/components/PlatformTab/PlatformTab";
import Empty from "@/components/Empty/Empty";
import RouterButton from "@/components/RouterButton/RouterButton";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import api from "@/api/accountAuthorization.js";

const debounce = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  components: {
    PlatformHeader,
    PlatformTab,
    RouterButton,
    ButtonGroup
  },
  data() {
    return {
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      tabList: [
        {
          label: "已匹配账号门店",
          value: "1"
        },
        {
          label: "未匹配账号门店",
          value: "2"
        }
      ],
      tabType: "1",
      region_select: "",
      region_list: [],
      search_val: "",
      is_search: false,
      keyword: "",
      groupData: [],
      groupValue: "",
      columns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "num" }
        },
        {
          // title: "门店名称",
          dataIndex: "name",
          width: "35%",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "shopName" }
        },
        {
          title: "门店编码",
          dataIndex: "store_code",
          width: "13%",
          align: "left",
          customRender: text => {
            return <div class="item">{text || "-"}</div>;
          }
        },
        {
          title: "关联账号",
          dataIndex: "account_count",
          width: "12%",
          align: "left",
          customRender: text => {
            return <div class="item">{text || "-"}</div>;
          }
        },
        {
          title: "服务平台授权",
          dataIndex: "p1_oauth_count",
          width: "10%",
          customRender: text => {
            return <div class="item">{text || 0}</div>;
          }
        },
        {
          title: "开放平台授权",
          dataIndex: "p2_oauth_count",
          width: "10%",
          customRender: text => {
            return <div class="item">{text || 0}</div>;
          }
        },
        {
          title: "高级数据授权",
          dataIndex: "p6_oauth_count",
          width: "10%",
          customRender: text => {
            return <div class="item">{text || 0}</div>;
          }
        },
        {
          title: "城市",
          dataIndex: "city_name",
          width: "10%",
          align: "left",
          customRender: text => {
            return <div class="item">{text || "-"}</div>;
          }
        }
      ],
      current: 1,
      page_size: 20,

      detail_columns: [
        {
          title: "媒体平台",
          dataIndex: "platform",
          width: "20%",
          scopedSlots: { customRender: "platform" }
        },
        {
          title: "平台号",
          dataIndex: "code",
          width: "19%"
        },
        {
          title: "平台昵称",
          dataIndex: "nickname",
          width: "29%"
        },
        {
          title: "服务平台",
          dataIndex: "p1_status",
          width: "17%",
          scopedSlots: { customRender: "p1_status" }
        },
        {
          title: "开放平台",
          dataIndex: "p2_status",
          width: "15%",
          scopedSlots: { customRender: "p2_status" }
        },
        {
          title: "高级数据",
          dataIndex: "p6_status",
          width: "15%",
          scopedSlots: { customRender: "p6_status" }
        }
        // {
        //   title: "授权状态",
        //   dataIndex: "status",
        //   width: "20%",
        //   scopedSlots: { customRender: "status" },
        // },
      ]
    };
  },
  computed: {
    ...mapState({
      regionData: state => state.regionData, //区域
      dealerList: state => state.dealerList, //列表
      listLoading: state => state.listLoading, //列表loading
      listTotal: state => state.listTotal, //列表总数
      pagination: state => state.pagination,
      detailData: state => state.detailData || {}, //门店详情
      detailLoading: state => state.detailLoading, //门店获取状态
      detailList: state => state.detailList, //门店授权列表
      detailListLoading: state => state.detailListLoading
    })
  },
  created() {
    this.region_select = null;
  },
  async mounted() {
    this.getAllGroup();
    await this.getDealerArea();

    console.log(this.regionData);
    this.regionData.length > 0
      ? (this.region_select = this.regionData[0].value)
      : "";
    this.onSearch();
  },
  methods: {
    ...mapActions([
      "getDealerArea",
      "getDealerList",
      "getUnmatchDealerList",
      "getDealerDetail",
      "getDealerAuthorize"
    ]),
    // 获取全部分组
    getAllGroup() {
      api.getAllGroup().then(res => {
        if (Array.isArray(res.data.data)) {
          this.groupData = res.data.data || [];
        } else {
          this.groupData = [];
          console.error("获取大区失败");
        }
      });
    },
    //获取经销商列表
    getList() {
      if (this.tabType == "1") {
        this.getDealerList(this.currentParams);
      } else {
        this.getUnmatchDealerList(this.currentParams);
      }
    },
    // 切换顶部tab
    handleTypeClick(val) {
      this.current = 1;
      this.tabType = val;
      this.onSearch();
    },
    onSearch() {
      this.current = 1;
      this.currentParams = {
        size: this.page_size,
        current: this.current,
        region_id: this.region_select,
        group_code: this.groupValue,
        word: this.search_val
      };
      this.getList();
    },
    onChange(current, page_size) {
      this.current = current;
      this.currentParams.current = current;
      this.page_size = page_size;
      this.getList();
    },
    openDetail(record, index) {
      return {
        on: {
          click: () => {
            let routeUrl = this.$router.resolve({
              path: `/dealer/dealerDetail?id=${record.id}`
            });
            window.open(routeUrl.href, "_blank");
          }
        }
      };
    },
    onGetDetail(id) {
      this.$store.dispatch("storeManagement/resetData");

      let that = this;
      debounce(() => {
        that.getDealerDetail({ dealer_id: id });
        that.getDealerAuthorize({ dealer_id: id });
      }, 500);
    },
    debounce(fn, delay) {
      let timer = null;
      return function() {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(fn, delay);
      };
    },
    jumpRenovation() {
      this.$router.push({
        path: `/storeRenovation`
      });
    },
    jumpAuthorization() {
      this.$router.push({
        path: `/accountAuthorization`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 20px;
  @include font_color("font_color2");
}
.tools {
  padding: 0px 20px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  .btn {
    @include border_color("border_color4");
    @include font_color("font_color17");
    @include background_color("background_color13");
    height: 36px;
    border-radius: 8px;
  }

  .beta {
    width: 36px;
    height: 14px;
    border-radius: 7px;
    background: #ff4160;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: -6px;
    right: 12px;
  }
}
.tools2 {
  padding: 0 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .search-btn {
    @include border_color("border_color4");
    @include font_color("font_color17");
    @include background_color("background_color13");
    width: 80px;
    height: 36px;
    border-radius: 8px;
  }
}
.img_style {
  height: 100%;
  width: 100%;
}
.content {
  border-radius: 5px;
  padding-bottom: 16px;
  .rows {
    &.flex {
      display: flex;
      align-items: center;
    }
  }
  .store-top {
    height: 128px;
    padding: 24px;
    @include background_color("background_color2");
    @include border_color("border_color1");
    display: flex;
    margin-bottom: 28px;
    .img_box {
      width: 80px;
      height: 80px;
      border-radius: 4px;
      border: 4px solid rgba(0, 0, 0, 0);
      @include border_color("border_color2");
    }
    p {
      width: 130px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      @include font_color("font_color1");
    }
  }
  .cols-1 {
    text-align: center;
    width: 30%;
    min-width: 200px;
  }
  .cols-2 {
    width: 100%;
  }

  .space-5 {
    margin-top: 5px;
  }
}
.table2 {
  .item {
    @include font_color("font_color3");
  }
  tr:hover {
    .item {
      @include font_color("font_color26");
    }
    .img-tip {
      display: inline-block;
    }
  }
  .name {
    @include font_color("font_color2");
  }
  .img-tip,
  .img-tip-active {
    display: none;
    margin-left: 6px;
  }
  .shop-name {
    // .img-tip {
    //   display: inline-block;
    // }
    .img-tip-active {
      display: none;
    }
    &:hover {
      .img-tip {
        display: none;
      }
      .img-tip-active {
        display: inline-block;
      }
    }
  }
}
.detail-content {
  width: 694px;
  @include font_color("font_color2");
  @include background_color("background_color5");
  border-radius: 4px;
  position: relative;
  z-index: 2;
  padding: 24px;
  margin: -12px -16px;
  .detail-wrap {
    margin-bottom: 24px;
    .detail-title {
      font-size: 16px;
      @include font_color("font_color2");
      padding-bottom: 16px;
    }
  }
}
.describe_code {
  display: flex;
  width: 59px;
  span {
    flex: 1;
  }
}
.describe {
  display: flex;
  overflow: hidden;
  @include font_color("font_color3");
  font-size: 14px;
  &-value {
    @include font_color("font_color2");
  }
  .order_color {
    @include font_color("font_color4");
  }
  .describe-value {
    flex: 1;
  }
}
.no_accredit {
  @include font_color("font_color6");
}
.accredit {
  @include font_color("font_color4");
}
.lose_accredit {
  @include font_color("font_color13");
}
.fc-20 {
  @include font_color("font_color20");
}
</style>