<template>
  <a-modal
    :title="`${title}-图片裁剪`"
    :visible="visible"
    :closable="false"
    width="600px"
  >
    <div class="img-container">
      <vueCropper
        ref="cropper"
        :img="img"
        :outputSize="option.outputSize"
        :outputType="option.outputType"
        :canScale="option.canScale"
        :autoCrop="option.autoCrop"
        :autoCropWidth="option.autoCropWidth"
        :autoCropHeight="option.autoCropHeight"
        :canMoveBox="option.canMoveBox"
        :canMove="option.canMove"
        :centerBox="option.centerBox"
        :info="option.info"
        :fixedBox="option.fixedBox"
        :fixed="option.fixed"
        :fixedNumber="option.fixedNumber"
        v-if="cropperShow"
      ></vueCropper>
    </div>
    <div slot="footer" class="modal-footer">
      <div class="btn btn1" @click="reset">恢复默认</div>
      <div class="btn btn1" @click="reUpload">重新上传</div>
      <div class="btn btn2" @click="crop">确认</div>
    </div>
  </a-modal>
</template>

<script>
import { VueCropper } from "vue-cropper";

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      cropperShow: true,

      title: "",
      img: "",
      option: {
        outputSize: 1, // 裁剪生成图片质量
        outputType: "png", // 裁剪生成图片格式
        canScale: true, // 图片是否允许滚轮播放
        autoCrop: true, // 是否默认生成截图框 false
        info: false, // 是否展示截图框信息
        // autoCropWidth: 200, // 生成截图框的宽度
        // autoCropHeight: 200, // 生成截图框的高度
        canMoveBox: true, // 截图框是否可以拖动
        fixed: true,
        fixedNumber: [16, 9],
        canMove: true, // 上传图片是否可拖动
        centerBox: false, // 截图框限制在图片里面
      },
      resolve: null,
      reject: null,
      reUploadCallback: null,
    };
  },
  methods: {
    openModal(title, data, reUploadCallback) {
      this.title = title;
      this.img = data;
      this.reUploadCallback = reUploadCallback;
      this.visible = true;
    },
    async openModalPromise(title, data, reUploadCallback) {
      this.title = title;
      this.img = data;
      this.reUploadCallback = reUploadCallback;
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    reset() {
      this.cropperShow = false;
      this.$nextTick(() => {
        this.cropperShow = true;
      });
    },
    reUpload() {
      if (this.reject) {
        this.reject();
        this.resolve = null;
        this.reject = null;
      }
      this.reUploadCallback && this.reUploadCallback();
      this.visible = false;
    },
    crop() {
      this.$refs.cropper.getCropData((data) => {
        if (this.resolve) {
          this.resolve(data);
          this.resolve = null;
          this.reject = null;
        }
        this.$emit("success", data);
        this.visible = false;
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.img-container {
  width: 100%;
  height: 400px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn {
    height: 32px;
    margin-left: 8px;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    border: none;
    display: inline-block;
    cursor: pointer;
  }
  .btn1 {
    padding: 0 16px;
    background: #2b2f3b;
    color: rgba(255, 255, 255, 0.65);
  }
  .btn2 {
    width: 65px;
    background: #ffcc55;
    color: rgba(0, 0, 0, 0.88);
  }
}
</style>