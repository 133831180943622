export default [
    {
        "aweme_level": "5",
        "live_level": "1",
        "sort": "51",
        "ceil": []
    }, {
        "aweme_level": "5",
        "live_level": "2",
        "sort": "52",
        "ceil": []
    }, {
        "aweme_level": "5",
        "live_level": "3",
        "sort": "53",
        "ceil": []
    }, {
        "aweme_level": "5",
        "live_level": "4",
        "sort": "54",
        "ceil": []
    }, {
        "aweme_level": "5",
        "live_level": "5",
        "sort": "55",
        "ceil": []
    }, {
        "aweme_level": "4",
        "live_level": "1",
        "sort": "41",
        "ceil": []
    }, {
        "aweme_level": "4",
        "live_level": "2",
        "sort": "42",
        "ceil": []
    }, {
        "aweme_level": "4",
        "live_level": "3",
        "sort": "43",
        "ceil": []
    }, {
        "aweme_level": "4",
        "live_level": "4",
        "sort": "44",
        "ceil": []
    }, {
        "aweme_level": "4",
        "live_level": "5",
        "sort": "45",
        "ceil": []
    }, {
        "aweme_level": "3",
        "live_level": "1",
        "sort": "31",
        "ceil": []
    }, {
        "aweme_level": "3",
        "live_level": "2",
        "sort": "32",
        "ceil": []
    }, {
        "aweme_level": "3",
        "live_level": "3",
        "sort": "33",
        "ceil": []
    }, {
        "aweme_level": "3",
        "live_level": "4",
        "sort": "34",
        "ceil": []
    }, {
        "aweme_level": "3",
        "live_level": "5",
        "sort": "35",
        "ceil": []
    }, {
        "aweme_level": "2",
        "live_level": "1",
        "sort": "21",
        "ceil": []
    }, {
        "aweme_level": "2",
        "live_level": "2",
        "sort": "22",
        "ceil": []
    }, {
        "aweme_level": "2",
        "live_level": "3",
        "sort": "23",
        "ceil": []
    }, {
        "aweme_level": "2",
        "live_level": "4",
        "sort": "24",
        "ceil": []
    }, {
        "aweme_level": "2",
        "live_level": "5",
        "sort": "25",
        "ceil": []
    }, {
        "aweme_level": "1",
        "live_level": "1",
        "sort": "11",
        "ceil": []
    }, {
        "aweme_level": "1",
        "live_level": "2",
        "sort": "12",
        "ceil": []
    }, {
        "aweme_level": "1",
        "live_level": "3",
        "sort": "13",
        "ceil": []
    }, {
        "aweme_level": "1",
        "live_level": "4",
        "sort": "14",
        "ceil": []
    }, {
        "aweme_level": "1",
        "live_level": "5",
        "sort": "15",
        "ceil": []
    },
]