<template>
  <div>
    <!-- 头部标题内容 -->
    <header>
      <div class="h-title">话题观察详情</div>
      <p class="main_title"><svg-icon icon-class="blue" class="icon_blue" />{{ name }}：{{ topicMessage.topic_name }}</p>
    </header>
    <!-- tab图表 -->
    <div style="padding: 0 48px 0 46px">
      <a-tabs @change="handleTabsChart">
        <a-tab-pane
          v-for="item in tabList"
          :key="item.id"
          :tab="item.label"
        ></a-tab-pane>
      </a-tabs>
      <div class="head_total">
        <div>
          参与视频：<span class="number">{{ topicMessage.aweme_count | changeNum }}</span>
        </div>
        <div>
          持续天数：<span class="number">{{ topicMessage.days | changeNum }}</span>
        </div>
        <div>
          互动总数：<span class="number">{{ topicMessage.interact_count | changeNum }}</span>
        </div>
      </div>
      <a-spin tip="" :spinning="loadingChart">
        <div class="chart">
          <my-chart
            ref="live_Message"
            :id="'live_Message'"
            :options="getChartOption('live')"
          />
        </div>
      </a-spin>
    </div>
  </div>
</template>
<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "topicMarketing"
);
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartLine from "../chartLine.js";
import echarts from "echarts";
export default {
  name: "TopicDetail",
  data() {
    return {
      topicId: this.$route.query.id,
      name: this.$route.query.name,
      tabList: [
        {
          label: "视频数",
          id: 1,
        },
        {
          label: "参与主播",
          id: 2,
        },
        {
          label: "影响力曲线",
          id: 3,
        },
      ],
      chartData: {
        yData: [],
        xData: [],
      },
      tabKey: 1,
    };
  },
  components: {
    MyChart,
  },
  computed: {
    ...mapState({
      topicMessage: (state) => state.topicMessage,
      loadingChart: (state) => state.loadingChart,
      topicDetailChart: (state) => state.topicDetailChart,
    }),
  },
  methods: {
    ...mapActions(["getTopicMessage", "getTopicDetailChart"]),
    handleTabsChart(value) {
      let xData = this.topicDetailChart.map(({ time }) => time);
      let yData = "";
      switch (value) {
        case 1:
          yData = this.topicDetailChart.map(({ aweme_count }) => aweme_count);
          break;
        case 2:
          yData = this.topicDetailChart.map(({ dealer_count }) => dealer_count);
          break;
        case 3:
          yData = this.topicDetailChart.map(
            ({ interact_count }) => interact_count
          );
          break;
        default:
          break;
      }
      this.chartData = {
        yData,
        xData,
      };
    },
    getChartOption() {
      let option = deepClone(chartLine);
      let xAxisData = null;
      let yAxisData = null;
      let yAxisColor = [
        "#0185e7",
        "#c85502",
        "#01b101",
        "#9d2333",
        "#a906f8",
        "#FFBF00",
      ]; //蓝色，橙色，绿色，红色，紫色
      let legend = [];
      //直播互动
      xAxisData = this.chartData.xData;
      yAxisData = this.chartData.yData;

      option.series = {
        data: yAxisData,
        smooth: true,
        type: "line",
        symbolSize: 5,
        itemStyle: {
          color: yAxisColor[this.tabLiveKey],
        },
      };
      option.xAxis.data = xAxisData;
      option.legend.data = legend;
      return option;
    },
    async initData() {
      await this.getTopicDetailChart({ id: this.topicId });
      this.handleTabsChart(1);
    },
  },
  mounted() {
    this.getTopicMessage({ id: this.topicId });
    this.initData();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.main_title {
  @include font_color("font_color2");
  font-size: 16px;
  padding: 0 0 16px 48px;
}
.head_total {
  display: flex;
  @include font_color("font_color3");
  font-size: 14px;
  padding-left: 16px;
  margin-top:24px;
  @include font_color('font_color3');
  div {
    margin-right: 32px;
  }
  .number{
    @include font_color("font_color6")
  }
}
.chart {
  height: 500px;
}
</style>