/*
<platform-header title="商务政策考核" :tabData="tabData" @tabChange="tabChange" />
*/
<template>
  <div class="platform-container">
    <div class="title">
      {{title}}
      <span class="tooltip-name" v-if="tooltipName">{{tooltipName}}</span>
      <AftTooltip v-if="showTooltip && code" :code="code" style="top: 0px; left: 4px" />
    </div>

    <div class="tab-btn-wrap" v-if="showPlatform">
      <a-button
        v-for="(item, index) in tabData"
        :key="'btn' + index"
        round
        :class="[
          'btn-class',
          currentClick == item.value ? 'active-btn-class' : '',
          isFirst ? 'first' : ''
        ]
          
        "
        :style="defaultBtnStyle"
        @click="tabClick(item.value)"
      >
        <svg-icon :icon-class="item.value" v-if="!item.isHideIcon" class="icon"></svg-icon>
        <span>{{ item.label }}</span>
        <!-- todo -->
        <!-- <div v-if="item.value == 'dongchedi'" class="beta">beta</div> -->
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformHeader",
  props: {
    tabData: {
      type: Array,
    },
    title: {
      type: String,
      default: ' '
    },
    tooltip: {
      type: String,
      default: ''
    },
    tooltipName: {
      type: String,
      default: ''
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: ' '
    },
    defaultBtnStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showPlatform: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentClick: (this.showPlatform && this.tabData[0]) ? this.tabData[0].value : '',
      isFirst: true
    };
  },
  created() {
  },
  computed: {},
  watch: {
    tabData(val) {
      this.currentClick = (this.showPlatform && this.tabData[0]) ? this.tabData[0].value : ''
    }
  },
  methods: {
    tabClick(value) {
      this.currentClick = value;
      this.isFirst = false
      this.$emit("tabChange", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.platform-container {
  // padding: 30px 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 18px;
    // @include font_color("font_color20");
    color: #ffffff;
  } 
  .tooltip-name {
    font-size: 14px;
    color: rgba(255,255,255,0.45);
    margin-left: 16px;
  }
}
.btn-class.active-btn-class {
  // @include background_color("background_color13");
  background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
  // @include font_color("font_color25");
  color: rgba(255, 255, 255, 0.88);
  font-weight: bold;
}

.btn-class.active-btn-class.first {
  animation: btnPlay 2s;
  -webkit-animation: btnPlay 2s;
}
.btn-class {
  cursor: pointer;
  // @include font_color("font_color22");
  color: rgba(255,255,255,0.88);
  min-width: 114px;
  width: 114px;
  height: 40px;
  border: none;
  line-height: 30px;
  padding: 5px 10px;
  border-radius: 0px !important;
  // @include background_color("background_color2");
  background: rgba(255, 255, 255, 0.20);
}
.btn-class:first-child {
  border-radius: 8px 0 0 8px !important;
}
.btn-class:last-child {
  border-radius: 0 8px 8px 0 !important;
  border-right: 1px solid rgba(0, 0, 0, 0);
}
@keyframes btnPlay
{
    0%   {
      background: rgba(255, 255, 255, 0.20);
      color: rgba(255,255,255,0.88);
    }
    25%  {
      background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
      color: rgba(255, 255, 255, 0.88);
    }
    50%  {
      background: rgba(255, 255, 255, 0.20);
      color: rgba(255,255,255,0.88);
    }
    100% {
      background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
      color: rgba(255, 255, 255, 0.88);
    }
}
@-webkit-keyframes btnPlay
{
    0%   {
      background: rgba(255, 255, 255, 0.20);
    }
    25%  {
      background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
    }
    50%  {
      background: rgba(255, 255, 255, 0.20);
    }
    100% {
      background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
    }
}

@-o-keyframes btnPlay
{
    0%   {
      background: rgba(255, 255, 255, 0.20);
    }
    25%  {
      background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
    }
    50%  {
      background: rgba(255, 255, 255, 0.20);
    }
    100% {
      background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
    }
}
@-moz-keyframes btnPlay
{
    0%   {
      background: rgba(255, 255, 255, 0.20);
    }
    25%  {
      background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
    }
    50%  {
      background: rgba(255, 255, 255, 0.20);
    }
    100% {
      background: linear-gradient(180deg, rgba(255,202,92,0.9) 0%, rgba(255,202,92,0.3) 99%);
    }
}


.tab-btn-wrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-class {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px !important;
    & + .btn-class {
      margin-left: 10px;
    }
    .icon {
      margin-right: 4px;
      display: inline-block;
      font-size: 24px;
      // @include font_color("font_color23");
      color: #8890A6;
    }
    &.active-btn-class {
      .icon {
        // @include font_color("font_color25");
        color: rgba(255, 255, 255, 0.88);
      }
    }
  }
}
.beta {
  width: 36px;
  height: 14px;
  border-radius: 7px;
  background: #ff4160;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 14px;
  position: absolute;
  top: -6px;
  right: -12px;
}
</style>
