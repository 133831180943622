<template>
  <div class="task-center-container">
    <!-- 标题区域 -->
    <div class="container-title">
      <div class="container-title-left">
        <span class="fb f18 fc1"
          >任务中心 · {{ currentActivityItem.activityName || "-" }}</span
        >
        <span class="switch-button" @click="switchActivity">
          <span>切换活动</span>
          <svg-icon icon-class="arrow-down" class="arrow-icon" />
        </span>
      </div>
      <div class="container-title-right">
        <span class="f14 fc2">活动日期: </span>
        <span class="f14 fc4"
          >{{ currentActivityItem.startTime || "-" }} 至
          {{ currentActivityItem.endTime || "-" }}</span
        >
      </div>
    </div>
    <!-- 功能区域 -->
    <div class="container-tools">
      <div class="container-tools-left">
        <a-select
          v-model="regionValue"
          :suffixIcon="suffixIcon"
          style="width: 132px; height: 40px; margin-right: 10px"
          placeholder="选择大区"
          @change="getList"
        >
          <a-select-option value="" v-if="isAllRegion"
            >不限大区</a-select-option
          >
          <a-select-option
            :value="item.id"
            v-for="item in areaData"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="container-tools-right">
        <div class="create-button" @click="createActivity">
          <svg-icon
            icon-class="icon-add"
            style="margin-right: 12px; font-size: 12px"
          />
          <span>创建任务</span>
        </div>
      </div>
    </div>
    <!-- 卡片区域 -->
    <div class="container-card">
      <a-spin :spinning="spinning" style="width: 100%">
        <div class="container-card-main" v-if="taskList && taskList.length > 0">
          <div class="card-item" v-for="item in taskList" :key="item.id">
            <div class="card-title">
              {{ item.taskName || "-" }}
              <!-- <div class="card-title-right" @click="deleteClick(item)">
                <svg-icon icon-class="delete" class="delete-icon" />
              </div> -->
            </div>
            <div class="task-type">
              {{ taskTypeData[item.taskType] }}达到
              {{ item.targetValue | changeNum }}
            </div>
            <div class="task-rate-count">
              <div class="task-rate">
                <span
                  >任务完成率
                  <span
                    style="
                      width: 43px;
                      height: 22px;
                      font-weight: 400;
                      color: rgba(255, 255, 255, 0.88);
                    "
                    >{{ item.completionRate }}%</span
                  ></span
                >
              </div>
              <div class="task-count">
                <span
                  >完成门店/总数
                  <span
                    style="
                      width: 43px;
                      height: 22px;
                      font-weight: 400;
                      color: rgba(255, 255, 255, 0.88);
                    "
                    >{{ item.completionCount }}</span
                  >/{{ item.allCount }}</span
                >
              </div>
            </div>

            <!-- <div style="margin: 16px 0 8px">
              <span class="fc3 f20">{{ item.targetCount }}</span>
              <span class="fc6 f14">/{{ item.attendeeCount }}</span>
            </div>
            <div class="fc6 f12" style="margin-bottom: 4px">完成门店/总数</div>
            <a-progress
              :percent="(item.targetCount / item.attendeeCount) * 100"
              :show-info="false"
              strokeColor="#FFCA5C"
            /> -->
            <div class="shadow-container">
              <div @click="onClickDetail(item)">
                <svg-icon icon-class="task-detail" class="icon" />
                <span>任务详情</span>
              </div>
              <div @click="deleteClick(item)">
                <svg-icon icon-class="delete" class="icon" />
                <span>删除任务</span>
              </div>
              <div @click="editClick(item)">
                <svg-icon icon-class="edit2" class="icon" />
                <span>编辑说明</span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-card-empty" v-else>
          <Empty title="暂无任务" />
        </div>
      </a-spin>
    </div>

    <CreateTaskDrawer
      ref="CreateTaskDrawer"
      :activity="currentActivityItem"
      @getList="getList"
    />

    <!-- <SwitchActivity
      :currentItem="currentActivityItem"
      @changeActivity="changeActivity"
      ref="SwitchActivity"
    /> -->

    <!-- 切换活动 -->
    <ActSelectDrawer
      :visible.sync="actSelectDrawerVisible"
      mode="switch"
      :default-selected-act-list="[currentActivityItem]"
      @switch="onSwitchAct"
    />

    <a-modal
      v-model="deleteModal"
      :closable="false"
      :footer="null"
      dialogClass="modal-class"
    >
      <div class="modal-container">
        <a-icon type="exclamation-circle" theme="filled" class="icon" />
        <span>删除后将无法恢复，确认删除该任务？</span>
      </div>
      <div class="modal-footer">
        <div class="btn btn1" @click="deleteModal = false">取消</div>
        <div class="btn btn2" @click="sureDelete">确认</div>
      </div>
    </a-modal>
    <a-modal
      v-model="editModal"
      :closable="false"
      :footer="null"
      dialogClass="modal-class2"
    >
      <div class="edit-title">
        <span>编辑说明</span>
        <a-icon type="close" class="icon" @click="editModal = false" />
      </div>
      <div class="modal-container2">
        <span class="label">任务说明: </span>
        <a-input
          v-model="taskExplain"
          type="textarea"
          class="aft-input modal2"
          placeholder="请输入任务说明"
          style="width: 390px"
          :maxLength="50"
          :autosize="{ minRows: 5, maxRows: 5 }"
        />
      </div>
      <div class="modal-footer2" style="margin-top: 10px">
        <div class="btn btn1" @click="editModal = false">取消</div>
        <div class="btn btn2" @click="sureEdit">确认</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import api from "@/api/taskCenter.js";
import ac_api from "@/api/marketingActivityList.js";
import CreateTaskDrawer from "../components/CreateTaskDrawer";
// import SwitchActivity from "../components/SwitchActivity";
import Empty from "@/components/Empty/Empty";
import ActSelectDrawer from "@/components/ActSelectDrawer";

export default {
  name: "taskCenter",
  components: {
    CreateTaskDrawer,
    // SwitchActivity,
    Empty,
    ActSelectDrawer,
  },
  data() {
    return {
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      isAllRegion: false,
      regionValue: undefined,
      areaData: [],
      taskList: [],
      deleteModal: false,
      currentDeleteItem: {},
      editModal: false,
      currentEditItem: {},
      taskExplain: "",
      currentActivityItem: {},
      taskTypeData: {
        1: "视频发布数",
        2: "直播场次",
        3: "累计视频播放量",
        4: "累计直播观看量",
        5: "活动模版视频数",
        6: "播放≥500视频数",
        7: "时长≥30min直播场次",
        8: "播放≥500活动模板视频数",
        9: "时长≥60min直播场次",
        10: "时长≥90min直播场次",
        11: "时长≥120min直播场次",
        12: "任意模版视频数",
        13: "播放≥500任意模板视频数",
        14: "笔记发布数",
      },
      spinning: false,
      actSelectDrawerVisible: false,
    };
  },
  async created() {
    // 判断页面来源
    if (this.$route.query.aId) {
      this.currentActivityItem = {
        id: this.$route.query.aId,
        startTime: this.$route.query.startTime,
        endTime: this.$route.query.endTime,
        activityName: this.$route.query.aName,
        videoCreateSource: this.$route.query.videoCreateSource,
        activityStatus: this.$route.query.activityStatus,
        isLive: Number(this.$route.query.isLive),
        isVideo: Number(this.$route.query.isVideo),
        isNote: Number(this.$route.query.isNote),
        platforms: this.$route.query.platforms,
      };
    }
    const result1 = await this.getActivityList();
    this.setActivityList(result1);
    this.track("切换活动");
    const result2 = await this.getAreaInfoByActivity();
    this.setAreaData(result2);
    this.getList();
  },
  methods: {
    getList() {
      this.spinning = true;
      let params = {
        activityId: this.currentActivityItem.id,
        regionId: this.regionValue,
      };
      api
        .getProfileTask(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.taskList = res.data.data.taskInfoVOList || [];
          } else {
            this.taskList = [];
            this.$message.error(res.data.message || "获取数据失败");
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },

    // 默认选择活动埋点
    track(action) {
      const trackData = {
        action,
        default: 1,
        activityId: this.currentActivityItem.id,
        activityName: this.currentActivityItem.activityName,
      };
      this.$trackRequest(this.$route, this.$trackEvent.CLICK, trackData);
    },
    // 获取大区
    getAreaInfoByActivity() {
      let params = {
        activityId: this.currentActivityItem.id,
      };
      return api.getAreaInfoByActivity(params);
    },
    setAreaData(res) {
      if (res.data.code === 200) {
        this.areaData = res.data.data.region_list || [];
        if (res.data.data.type === 10) {
          this.isAllRegion = true;
          this.regionValue = "";
        } else {
          this.regionValue = this.areaData[0]?.id;
        }
      } else {
        this.areaData = [];
        this.regionValue = undefined;
        console.error("获取大区失败");
      }
    },

    // 获取活动列表
    getActivityList() {
      let params = {
        page: 1,
        size: 1,
      };
      return ac_api.getActivityList(params);
    },
    setActivityList(res) {
      if (res.data.code == 200 && res.data.data.list.length > 0) {
        if (this.$route.query.aId) {
          return false;
        }
        this.currentActivityItem = res.data.data.list[0];
      } else if (res.data.data.list.length == 0) {
        this.currentActivityItem = {};
        this.$message.error("暂无活动");
      } else {
        this.currentActivityItem = {};
        this.$message.error(res.data.message || "获取活动列表失败");
      }
    },

    // 创建活动 - 打开创建抽屉
    createActivity() {
      if (!this.isAllRegion) {
        this.$message.warning("当前账号不可创建任务,请联系管理员");
        return false;
      }
      this.$refs.CreateTaskDrawer.showDrawer(this.currentActivityItem.id);
    },
    onClickDetail(item) {
      this.$router.push({
        path: `/marketing/taskProgress?taskId=${item.id}`,
        query: {
          text:
            this.taskTypeData[item.taskType] +
            "达到" +
            this.$options.filters.changeNum(item.targetValue),
        },
      });
    },
    // 删除任务
    deleteClick(item) {
      this.currentDeleteItem = item;
      this.deleteModal = true;
    },
    sureDelete() {
      let params = {
        taskId: this.currentDeleteItem.id,
      };
      api.deleteTask(params).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getList();
        } else {
          this.$message.error(
            res.data.message || "删除失败，请重试或联系管理员"
          );
        }
      });
      this.deleteModal = false;
    },
    // 编辑任务说明
    editClick(item) {
      this.currentEditItem = item;
      this.taskExplain = item.taskExplain;
      this.editModal = true;
    },
    sureEdit() {
      let body = {
        taskId: this.currentEditItem.id,
        taskExplain: this.taskExplain,
      };
      api.editTask(body).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.getList();
        } else {
          this.$message.error(
            res.data.message || "修改失败，请重试或联系管理员"
          );
        }
      });
      this.editModal = false;
    },
    // 切换活动按钮点击
    switchActivity() {
      // this.$refs.SwitchActivity.showDrawer();
      this.actSelectDrawerVisible = true;
    },

    // 切换活动触发
    async onSwitchAct(id, item) {
      this.currentActivityItem = item;

      const result = await this.getAreaInfoByActivity();
      this.setAreaData(result);
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.task-center-container {
  .container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    padding: 0 16px;
    &-left {
      .switch-button {
        font-size: 14px;
        color: #ffcc55;
        margin-left: 8px;
        cursor: pointer;
        .arrow-icon {
          margin-left: 4px;
          font-size: 12px;
        }
      }
    }
  }
  .container-tools {
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 20px;

    .create-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      background: #2b2f3b;
      color: #d0d6e6;
      cursor: pointer;
    }
  }
  .container-card {
    width: 100%;
    height: calc(100vh - 224px);
    padding: 0 16px;
    overflow-y: auto;
    &-main {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 15px;
      .card-item {
        width: 100%;
        height: 188px;
        border-radius: 8px;
        overflow: hidden;
        background: #2b2f3b;
        cursor: pointer;
        padding: 20px 20px 24px;
        position: relative;

        .card-title {
          width: 100%;
          height: 22px;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.88);
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .task-type {
          font-size: 14px;
          color: #ffcc55;
          margin: 8px 0;
          // padding-left: 8px;
        }
        .task-rate-count {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.55);
          line-height: 22px;
          // padding-left: 8px;
          margin-top: 40px;
          // .task-rate {
          // width: 43px;
          // height: 22px;
          // font-size: 14px;
          // font-weight: 400;
          // color: rgba(255, 255, 255, 0.88);
          // line-height: 22px;
          // }
          .task-count {
            margin-top: 8px;
          }
        }
        &:hover {
          .shadow-container {
            opacity: 1;
          }
        }
        .shadow-container {
          width: 100%;
          height: 100%;
          padding: 20px;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: space-around;
          background: rgba(0, 0, 0, 0.7);
          opacity: 0;
          > div {
            height: 42px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: rgba(255, 255, 255, 0.88);
            > span {
              margin-top: 4px;
            }
            > .icon {
              font-size: 16px;
            }
            &:hover {
              color: #ffcc55;
            }
          }
        }
      }
    }
    &-empty {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding-top: 150px;
    }
  }

  @media (max-width: 2160px) {
    .container-card {
      .container-card-main {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  @media (max-width: 1800px) {
    .container-card {
      .container-card-main {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  // 公用字体样式
  .fb {
    font-weight: bold;
  }
  .fc1 {
    color: #fff;
  }
  .fc2 {
    color: #8890a6;
  }
  .fc3 {
    color: rgba(255, 255, 255, 0.88);
  }
  .fc4 {
    color: rgba(255, 255, 255, 0.85);
  }
  .fc5 {
    color: rgba(255, 255, 255, 0.7);
  }
  .fc6 {
    color: rgba(255, 255, 255, 0.55);
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f18 {
    font-size: 18px;
  }
  .f20 {
    font-size: 20px;
  }
}

// 弹窗

.modal-container {
  display: flex;
  align-items: center;
  height: 24px;
  .icon {
    font-size: 21px;
    color: #ff9026;

    margin-right: 17px;
  }
  > span {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 24px;
  }
}
.modal-container2 {
  display: flex;
  align-items: flex-start;
  height: 124px;
  .label {
    font-size: 14px;
    color: #8890a6;
    line-height: 22px;
    margin-right: 12px;
  }
}
.edit-title {
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .icon {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.45);
  }
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  .btn {
    height: 32px;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    border: none;
    display: inline-block;
    cursor: pointer;
  }
  .btn1 {
    width: 74px;
    background: #2b2f3b;
    color: rgba(255, 255, 255, 0.65);
  }
  .btn2 {
    width: 65px;
    background: #ffcc55;
    color: rgba(0, 0, 0, 0.88);
    margin-left: 8px;
  }
}
.modal-footer2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  .btn {
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    border: none;
    display: inline-block;
    cursor: pointer;
  }
  .btn1 {
    width: 88px;
    background: #2b2f3b;
    color: rgba(255, 255, 255, 0.65);
  }
  .btn2 {
    width: 88px;
    background: #ffcc55;
    color: rgba(0, 0, 0, 0.88);
    margin-left: 8px;
  }
}
</style>
<style lang="scss" scoped>
/deep/ .modal-class {
  width: 360px;
  height: 136px;
  background: #1a1d26;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.32),
    0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 12px 48px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .ant-modal-body {
    width: 100%;
    height: 100%;
    padding: 32px 32px 24px 34px !important;
  }
  .ant-modal-content {
    width: 100%;
    height: 100%;
    background: #1a1d26 !important;
    box-shadow: none !important;
  }
}
/deep/ .modal-class2 {
  width: 360px;
  height: 262px;
  background: #1a1d26;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.32),
    0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 12px 48px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  .ant-modal-body {
    width: 100%;
    height: 262px;
    padding: 20px 24px 18px 24px !important;
  }
  .ant-modal-content {
    width: 100%;
    height: 100%;
    background: #1a1d26 !important;
    box-shadow: none !important;
  }
}
</style>
