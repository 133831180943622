var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"principal-container"},[_c('div',{staticClass:"principal-container-info"},[_c('div',{staticClass:"info-header"},[_c('div',{staticClass:"info-header-left"},[_c('WebpImage',{attrs:{"src":_vm.ossHost + _vm.infoData.avatar,"width":'60px',"height":'60px',"radius":'11px'}}),_c('span',{staticClass:"p-name"},[_vm._v(_vm._s(_vm.infoData.principal_name))])],1)]),_c('div',{staticClass:"info-data"},[_c('div',{staticClass:"info-data-item"},[_c('span',[_vm._v("门店账号数量")]),_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(_vm.infoData.account_count)))])]),_c('div',{staticClass:"info-data-item"},[_c('span',[_vm._v("营销价值")]),_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(_vm.infoData.marketing_value)))])]),_c('div',{staticClass:"info-data-item"},[_c('span',[_vm._v("粉丝总量")]),_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(_vm.infoData.fans_count)))])]),_c('div',{staticClass:"info-data-item"},[_c('span',[_vm._v(_vm._s(_vm._f("returnWatchText")(_vm.currentPlatform))+" "),_c('AftTooltip',{staticStyle:{"top":"1px","left":"4px"},attrs:{"code":'qjzb11'}})],1),_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(_vm.infoData.watch_count)))])]),_c('div',{staticClass:"info-data-item"},[_c('span',[_vm._v("视频播放")]),_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(_vm.infoData.play_volume)))])])]),(_vm.storeList && _vm.storeList.length > 0)?_c('div',{staticClass:"store-list"},_vm._l((_vm.storeList),function(item,index){return _c('span',{key:index,class:[
          'store-list-item',
          _vm.currentDealerId == item.dealer_id ? 'active' : '',
        ]},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_vm._v(_vm._s(item.dealer_name || "-"))]),_c('div',{staticClass:"img-item",on:{"click":function($event){return _vm.changeDealerId(item.dealer_id)}}},[_c('WebpImage',{attrs:{"src":item.avatar,"width":'40px',"height":'40px',"radius":'50%'}})],1)],2),_c('span',{staticClass:"ranking-tag",on:{"click":function($event){return _vm.changeDealerId(item.dealer_id)}}},[_vm._v(_vm._s(index + 1))])],1)}),0):_vm._e(),(_vm.storeList && _vm.storeList.length > 0)?_c('div',{staticClass:"store-info"},[_c('div',{staticClass:"store-info-tools"},[_c('span',{staticClass:"text-left"},[_vm._v("门店最佳账号")]),_c('a-dropdown',[_c('a',{staticClass:"text-right",on:{"click":(e) => e.preventDefault()}},[_vm._v(" 门店其他账号 "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.accountClick},slot:"overlay"},_vm._l((_vm.accountList),function(item){return _c('a-menu-item',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('div',{staticClass:"store-info-main"},[_c('div',[_c('WebpImage',{attrs:{"src":_vm.authorInfoData.avatar,"width":'40px',"height":'40px',"radius":'50%'}})],1),_c('div',[_c('span',{staticClass:"f14 fc1 fw"},[_vm._v(_vm._s(_vm.authorInfoData.nickname || "-"))]),_c('span',{staticClass:"f12 fc2"},[_vm._v(_vm._s(_vm.currentPlatform == "douyin" ? "抖音" : _vm.currentPlatform == "kuaishou" ? "快手" : "懂车帝")+"号："+_vm._s(_vm.authorInfoData.code || "-"))])]),_c('div',[_c('span',{staticClass:"f14 fc1"},[_vm._v("账号等级")]),_c('span',{class:['f48', 'fw', `fc-${_vm.authorInfoData.level}`]},[_vm._v(_vm._s(_vm.authorInfoData.level))])]),_c('div',[_c('span',{staticClass:"f14 fc3"},[_vm._v("粉丝总数")]),_c('span',{staticClass:"f20 fc1"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.authorInfoData.fans_count)))]),_c('span',{class:[
              'f14',
              _vm.returnRateClass(_vm.authorInfoData.fans_count_increase, 1),
            ]},[_vm._v(_vm._s(Math.abs(_vm.authorInfoData.fans_count_increase) || "-")+" "),(_vm.authorInfoData.fans_count_increase)?_c('svg-icon',{attrs:{"icon-class":_vm.returnRateClass(_vm.authorInfoData.fans_count_increase, 2)}}):_vm._e()],1)]),_c('div',[_c('span',{staticClass:"f14 fc3"},[_vm._v("获赞总数")]),_c('span',{staticClass:"f20 fc1"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.authorInfoData.digg_count)))]),_c('span',{class:[
              'f14',
              _vm.returnRateClass(_vm.authorInfoData.digg_count_increase, 1),
            ]},[_vm._v(_vm._s(Math.abs(_vm.authorInfoData.digg_count_increase) || "-")+" "),(_vm.authorInfoData.digg_count_increase)?_c('svg-icon',{attrs:{"icon-class":_vm.returnRateClass(_vm.authorInfoData.digg_count_increase, 2)}}):_vm._e()],1)])])]):_vm._e(),(!_vm.storeList || _vm.storeList.length == 0)?_c('Empty',{staticStyle:{"margin-top":"60px"},attrs:{"title":"暂无门店"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }