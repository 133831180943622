<template>
  <div class="my_calendar">
    <div class="header">
      <div class="tips">{{ monthTips }}</div>
      <div class="month_box">
        <a-month-picker
          v-model="month"
          class="aft-month-picker default"
          placeholder="选择月份"
          @change="selectMonth"
          :disabled-date="pickerOptions"
        >
          <div slot="suffixIcon">
            <svg-icon :icon-class="'range-picker'" />
          </div>
        </a-month-picker>
      </div>
    </div>
    <a-spin tip="" :spinning="calenderLoading">
      <div class="calendar">
        <ul class="calendar-box calendar-header">
          <li v-for="(item, index) in week" :key="index">
            {{ item }}
          </li>
        </ul>
        <ul class="calendar-box calendar_content">
          <li v-for="index in dateWeek" :key="index" class="big_border"></li>
          <li v-for="item in dayList" :key="item.id" class="big_border day_bg">
            <div class="day_cell">
              <div
                :class="days === item.id ? 'day_style day_active' : 'day_style'"
              >
                {{ item.day
                }}<span class="topic_length" v-if="item.principal_topics">{{
                  "（" + item.principal_topics.length + "）"
                }}</span>
              </div>
              <ul v-if="item.principal_topics" class="car-list">
                <li
                  v-for="(ele, i) in item.principal_topics"
                  :key="ele.topic_id + ele.principal_id"
                >
                  <a-popover trigger="click">
                    <template slot="content">
                      <a-spin tip="" :spinning="popoverLoading">
                          <p
                            v-for="(k, y) in brand_live.aweme_infos"
                            :key="k.aweme_id"
                            @click="gotoDetailPage(k.aweme_author_id)"
                            class="popover_content"
                          >
                            <span>{{ y + 1 }}、</span>

                            <a-tooltip>
                              <template slot="title">{{
                                k.aweme_title
                              }}</template>
                              <a class="topic_detail">{{ k.aweme_title }}</a>
                            </a-tooltip>
                            <span class="wacth_count">
                              <svg-icon
                                icon-class="md-favorite"
                                class="icon-count"
                              />
                              {{ k.aweme_digg_count | changeNum }}</span
                            >
                          </p>
                      </a-spin>
                    </template>

                    <template slot="title">
                      <div class="popover_title">
                        {{ liveDay }}
                        <span class="popver_week">{{ liveWeek }}</span>
                      </div>
                      <div class="p_title">
                        <i></i>
                        <span class="principal_name">{{ liveCard }}</span>
                        <svg-icon
                          icon-class="md-camera_enhance"
                          class="camera_icon"
                        />
                        <span>{{
                          brand_live.topic_aweme_count | changeNum
                        }}</span>
                      </div>
                    </template>
                    <a @click="openPlants(ele, item.id)" class="car_name"
                      ><svg-icon
                        :icon-class="
                          (i + 1) % 2 == 0
                            ? 'orange'
                            : (i + 1) % 3 == 0
                            ? 'blue'
                            : 'green'
                        "
                        class="icon_color"
                      />{{ ele.principal_name }}</a
                    >
                  </a-popover>
                </li>
              </ul>
            </div>
          </li>
          <li
            v-for="index in lassWeek"
            :key="index * 31"
            class="big_border"
          ></li>
        </ul>
      </div>
    </a-spin>
  </div>
</template>
<script>
const colors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
];
import * as vuex from "vuex";
import SvgIcon from "../SvgIcon/SvgIcon.vue";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("topicMarketing");
export default {
  components: { SvgIcon },
  name: "MyCalender",
  props: {
    calenderData: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      month: this.$moment(new Date()),
      days: this.$moment(new Date()).format("YYYY-MM-DD"),
      week: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      dateWeek: [],
      dayList: [],
      monthTips: "",
      colors,
      liveDay: "",
      liveWeek: "",
      liveCard: "",
      topicId: "",
      lassWeek: [],
    };
  },
  computed: {
    ...mapState({
      brand_live: (state) => state.brand_live,
      calenderLoading: (state) => state.calenderLoading,
      popoverLoading: (state) => state.popoverLoading,
    }),
  },
  watch: {
    calenderData: {
      handler(val) {
        this.getMonth(this.month);
        this.diffCalender(val);
      },
      deep: true,
    },
    // dayList: {
    //   handler() {
    //     console.log(323);
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.getMonth(this.month);
  },
  methods: {
    ...mapActions(["getBrandLiveDetail"]),
    // 禁用
    pickerOptions(current) {
      return current && current > this.$moment().endOf("month");
    },

    // 获取某月1号星期几
    getNowMonthWeek(date) {
      let week = this.$moment(date).startOf("month").day();
      this.dateWeek = week;
    },
    // 获取某月最后一天星期几
    getLassMontWeek(date) {
      let num = this.$moment(date).endOf("month").day();
      this.lassWeek = 6 - num;
    },
    // 生成数组
    getArr(num) {
      let arr = [];
      for (let i = 0; i < num; i++) {
        arr.push(i);
      }
      console.log("arr", arr);
      return arr;
    },
    // 计算月份信息
    getMonth(date) {
      this.getNowMonthWeek(date);
      this.getLassMontWeek(date);
      let monthLength = this.$moment(date).daysInMonth();
      let dayStr = this.$moment(date).format("YYYY-MM");
      this.monthTips = dayStr;
      let dateList = [];
      for (let i = 1; i <= monthLength; i++) {
        let obj = {
          id: i >= 10 ? `${dayStr}-${i}` : `${dayStr}-0${i}`,
          day: i >= 10 ? i : `0${i}`,
        };
        dateList.push(obj);
      }
      this.dayList = dateList;
    },
    diffCalender(value) {
      let calenderArr = this.dayList;
      calenderArr.forEach((ele) => {
        for (let i = 0; i < value.length; i++) {
          if (value[i].time == ele.id) {
            console.log("123124124123412312", ele);
            // this.
            ele.principal_topics = value[i].principal_topics;
          }
        }
      });
      console.log("11111", calenderArr);
      this.dayList = calenderArr;
      this.$forceUpdate();
    },
    selectMonth(value) {
      if (!value) {
        this.month = this.$moment().month(moment().month()).startOf("month");
        this.$message.warning("默认选择本月数据");
      }
      this.getMonth(this.month);
      this.$emit("changeMonth", this.month);
    },
    // 打开弹窗
    openPlants(value, time) {
      console.log("打开弹窗value值", value);
      this.liveDay = time;
      this.liveWeek = this.$moment(time).format("dddd");
      this.liveCard = value.principal_name;
      let params = { topic_id: value.topic_id };
      this.getBrandLiveDetail({ params });
      this.topicId = value.topic_id;
    },
    // 跳转详情
    gotoDetailPage() {
      this.$router.push(
        `/topic/detail?id=${this.topicId}&name=${this.liveCard}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
@import "@/assets/styles/_handle.scss";
.my_calendar {
  @include background_color("background_color5");
  margin: 16px 56px 50px 32px;
  border: 1px solid;
  @include border_color("border_color1");
  border-radius: 4px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tips {
      font-size: 32px;
      @include font_color("font_color1");
      margin: 16px 0 0 16px;
    }
    .month_box {
      margin: 16px 24px 0 0;
    }
  }
  .calendar {
    width: 100%;
    &-header {
      padding: 24px 0 16px;
    }
    &-box {
      display: flex;
      flex-flow: row wrap;
      font-size: 14px;

      li {
        flex: 0 0 14.28%;
        padding-left: 16px;
        @include font_color("font_color2");
      }
    }
  }
}
.calendar_content {
  .big_border {
    border: 1px solid;
    @include border_color("border_color1");
    border-bottom: none;
    border-left: none;
  }
  li:nth-child(7n) {
    border-right: none;
  }
}
.day_bg {
  @include background_color("background_color1");
}
.day_cell {
  @include font_color("font_color2");
  margin-top: 12px;
  height: 108px;
  .day_style {
    font-size: 20px;
    padding-bottom: 6px;
    .topic_length {
      font-size: 14px;
      @include font_color("font_color3");
    }
  }
  .day_active {
    @include font_color("font_color5");
  }
}

.car-list {
  height: 74px;
  overflow: auto;
  li {
    padding-left: 0 !important;
  }
}
.car_name {
  @include font_color("font_color2");
  font-size: 14px;
  padding-bottom: 4px;
  display: block;
  .icon_color {
    margin-right: 4px;
  }
}
.popover_content {
  font-size: 12px;
  @include font_color("font_color2");
  display: flex;
  align-items: center;
}
.topic_detail {
  display: inline-block;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include font_color("font_color2");
  &:hover {
    @include font_color("font_color6");
  }
}
.wacth_count {
  margin-left: 13px;
  @include font-color("font_color3");
  .icon-count {
    font-size: 14px;
    @include font_color("font_color8");
  }
}
.popover_title {
  font-size: 12px;
  padding-bottom: 8px;
  @include font_color("font_color3");
  .popver_week {
    display: inline-block;
    margin-left: 12px;
  }
}
.p_title {
  display: flex;
  align-items: center;
  font-size: 12px;
  @include font_color("font_color2");
  .principal_name {
    font-size: 14px;
  }
  i {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    @include background_color("border_color4");
    margin-right: 8px;
  }
  .camera_icon {
    margin: 0 4px 0 25px;
    font-size: 18px;
  }
}
// cursor: not-allowed;屏蔽样式
</style>
<style lang='scss'>
@import "@/assets/styles/_handle.scss";
.ant-popover-inner {
  @include background_color("background_color50");
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  @include border_right_color("border_color13");
  @include border_bottom_color("border_color13");
  @include border_top_color("border_color13");
  @include border_left_color("border_color13");
}
.ant-popover-title {
  padding: 12px 20px 4px 20px;
  border: none;
}
.ant-popover-inner-content {
  @include background_color("background_color50");
  color: rgba(0, 0, 0, 0.65);
}
</style>