<template>
  <div class="base-filter">
    <ul class="base-filter__list">
      <li
        class="base-filter__item"
        v-for="item in list"
        :key="item.value"
        :class="[
          {'is-active': item.value === value}
        ]"
        @click="handleClick(item.value)"
      >
        <span>{{ item.label }}</span>
      </li>  
    </ul>
  </div>  
</template>

<script>
export default {
  name: "BaseFilter",
  props: {
    value: [String, Number],
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    handleClick(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    }
  }
}
</script>

<style lang="less" scoped>
.base-filter {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin: 0 8px 16px 0;
    padding: 0 19px;
    min-width: 92px;
    height: 30px;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
    line-height: 30px;
    border-radius: 8px;
    cursor: pointer;

    &.is-active {
      color: #1C1E27;
      font-weight: bold;
      background-color: #FFCA5C;
    }
  }
}
</style>
