import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 区域列表
  getAreaList() {
    return request({
      url: `/jjt/dealer/dealers/region-list`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then(res => {
      let list = res.data.data.region_list || []
      if (res.data.data.type == 10) {
        // list.unshift({
        //   id: '',
        //   name: '不限大区'
        // })
      }
      list.map(item => {
        item.value = item.id
        item.label = item.name
      })
      return list
    })
  },

  // 竞品区域对比-本品
  getInfo({
    platform,
    params
  }) {
    return request({
      url: `/jjt/brand/info`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    }).then(res => {
      res = res.data
      return res
    })
  },

  // 竞品区域对比-本品
  getDetails({
    platform,
    params
  }) {
    return request({
      url: `/jjt/brand/info/details`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    }).then(res => {
      res = res.data
      return res
    })
  },
}