<template>
  <div class="live-detail-container">
    <div class="video-card">
      <video id="videoElement" :src="liveDetail.play_url" autoplay controls muted></video>
    </div>
    <div class="detail-info">
      <a-tooltip>
        <template slot="title">{{ liveDetail.title }}</template>
        <div class="title">
          {{ liveDetail.title }}
        </div>
      </a-tooltip>
      <div class="info-main">
        <div class="row">
          <div class="info-item">
            <span class="info-item-label">平台: </span
            ><span class="info-item-value">{{ liveDetail.plat_form }}</span>
          </div>
          <div class="info-item">
            <span class="info-item-label">日期: </span
            ><span class="info-item-value">{{ liveDetail.full_date }}</span>
          </div>
        </div>
        <div class="row">
          <div class="info-item">
            <span class="info-item-label">开播时间: </span
            ><span class="info-item-value">{{
              liveDetail.new_publish_time
            }}</span>
          </div>
          <div class="info-item">
            <span class="info-item-label">下播时间: </span
            ><span class="info-item-value">{{
              liveDetail.new_finish_time
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="lock-div">
      <div class="btn3" v-if="!liveDetail.play_url">暂无视频</div>
      <a-button
        type="primary"
        class="btn1"
        @click="unlockClick"
        v-if="
          liveDetail.play_url &&
          liveDetail.url_state == ('Archive' || 'Unknown') &&
          liveDetail.is_restore_completed == ('0' || '3')
        "
        :loading="lockLoading"
      >
        解冻
      </a-button>
      <div
        class="btn3"
        v-if="
          liveDetail.play_url &&
          liveDetail.url_state == ('Archive' || 'Unknown') &&
          liveDetail.is_restore_completed == '1'
        "
      >
        解冻中
      </div>
      <div class="btn2" @click="toRecord">解冻记录</div>
    </div>
    <NewTabs :tabData="tabsData" @tabChange="changeTabs" />
    <div class="tab-container">
      <div class="tab-item card-main" v-show="tabsKey == '1'">
        <div
          class="card-item"
          v-for="(item, index) in demoData.tab1.length > 9 ? demoData.tab1.slice(0,8) : demoData.tab1"
          :key="index"
        >
          <UserCard :infoData="item" />
        </div>
        <div class="card-item more" v-if="demoData.tab1.length > 9">+{{demoData.tab1.length - 8}}</div>
      </div>
      <div class="tab-item" v-show="tabsKey == '2'">
        <PieChart2
          :chartData="demoData.tab2"
          :id="'chart2'"
          :defaultStyle="{ width: '260px', height: '260px' }"
        />
      </div>
      <div class="tab-item" v-show="tabsKey == '3'">
        <MyChart
          :options="getWordCloudOption(demoData.tab3)"
          :id="'chart3'"
          :defaultStyle="{ width: '260px', height: '260px' }"
        />
      </div>
      <div class="tab-item" v-show="tabsKey == '4'">
        <div
          class="series-item"
          v-for="(item, index) in demoData.tab4"
          :key="index"
        >
          <SeriesCard :infoData="item" />
        </div>
      </div>
      <div class="tab-item" v-show="tabsKey == '5'">
        <PieChart2
          :chartData="demoData.tab5"
          :id="'chart5'"
          :defaultStyle="{ width: '260px', height: '260px' }"
        />
      </div>
      <div class="tab-item" v-show="tabsKey == '6'">
        <MyChart
          :options="getWordCloudOption(demoData.tab6)"
          :id="'chart6'"
          :defaultStyle="{ width: '260px', height: '260px' }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import WebpImage from "@/components/WebpImage/WebpImage";
import api from "@/api/globalDetail";
import NewTabs from "./NewTabs";
import PieChart2 from "./PieChart2";
import demoData from "../demoData.js";
import MyChart from "@/components/MyChart/MyChart";
import chartWord_assistant from "../chartWord_assistant";
import deepClone from "@/utils/clone-utils";
import UserCard from "./UserCard";
import SeriesCard from "./SeriesCard";

export default {
  components: { WebpImage, NewTabs, PieChart2, MyChart, UserCard, SeriesCard },
  props: {
    liveDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tabsData: [
        {
          label: "意向客户",
          value: "1",
        },
        {
          label: "话术分析",
          value: "2",
        },
        {
          label: "画面分析",
          value: "3",
        },
        {
          label: "车系提及",
          value: "4",
        },
        {
          label: "评论意见",
          value: "5",
        },
        {
          label: "设备配置",
          value: "6",
        },
      ],
      tabsKey: "1",
      flvPlayer: null,
      dialogVisible: false,
      lockLoading: false, // 解冻按钮loading状态
      demoData,
    };
  },
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatDuration2(number) {
      if (!number || number === 0) {
        return "0分0秒";
      } else {
        let m = (number / 60) | 0;
        let s = number % 60;
        return `${m}分${s}秒`;
      }
    },
  },
  computed: {},
  watch: {},
  created() {
    console.log(demoData);
  },
  methods: {
    // 点击直播回放按钮
    openLive(detail, time = 0) {
      console.log(detail);
      if (detail.play_url) {
        if (detail.url_state == "Archive" || detail.url_state == "Unknown") {
          if (
            detail.is_restore_completed == "0" ||
            detail.is_restore_completed == "3"
          ) {
            this.$message.warning(
              "直播视频已被冻结,可点击解冻按钮解冻 ( 预计1分钟解冻 )"
            );
            return false;
          } else if (detail.is_restore_completed == "1") {
            this.$message.warning(
              "解冻中,可点击解冻记录查看进度 ( 24h 后冻结 )"
            );
            return false;
          }
        }
        this.dialogVisible = true;
        console.log(detail.play_url);
        this.getStream(detail.play_url, time);
      } else {
        this.$message.warning("暂无该直播视频，请联系管理员...");
      }
    },
    // 初始化flv
    getStream(source, time = 0) {
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          console.log(1, source);
          this.flvPlayer = flvjs.createPlayer({
            type: source.indexOf(".mp4") > 0 ? "mp4" : "flv",
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.currentTime = Number(time) || 0;
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            this.handleClose();
            this.$message.warning("暂无该直播视频，请联系管理员...");
          });
        }
      });
    },
    // 关闭 flv 视频弹窗事件
    handleClose() {
      this.dialogVisible = false;
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
      }
    },
    unlockClick() {
      if (this.lockLoading) {
        return false;
      }
      console.log(this.liveDetail);
      // 调用解冻接口
      this.lockLoading = true;
      let body = {
        room_id: this.$route.query.id,
        platform: this.$route.query.platform,
      };
      api.updeteRetore(body).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$message.success("解冻成功");
          this.lockLoading = false;
          this.getLiveDetail(body);
        } else {
          this.$message.error("解冻失败");
          this.lockLoading = false;
        }
      });
    },
    toRecord() {
      let routeUrl = this.$router.resolve({
        path: `/liveAndVideo/thawingRecord`,
        query: {
          platform: this.$route.query.platform,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    openLiveByTime(time) {
      this.openLive(this.liveDetail, time);
    },

    // tabs切换
    changeTabs(val) {
      this.tabsKey = val;
    },

    getWordCloudOption(wData) {
      console.log(11, wData);
      let option = deepClone(chartWord_assistant);

      option.series[0].data = wData || [];
      console.log("guanjianci:", wData, option);
      return option;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.live-detail-container {
  width: 100%;
  height: 100%;
  background: #1c1e27;
  border-radius: 8px;
  padding: 20px;
  .video-card {
    width: 100%;
    height: 436px;
    border-radius: 8px;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .detail-info {
    width: 100%;
    height: 140px;
    padding: 16px 0 20px;
    .title {
      width: 100%;
      height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .info-main {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .row {
        height: 14px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .info-item {
          font-size: 12px;
          line-height: 14px;
          &-label {
            color: #8890a6;
          }
          &-value {
            color: #ffffff;
          }
        }
      }
    }
  }

  .lock-div {
    display: flex;
    height: 40px;
    margin-bottom: 36px;
    > div,
    > .btn1 {
      width: 128px;
      height: 40px;
      border-radius: 8px;
      cursor: pointer;
      text-align: center;
      line-height: 40px;
      border: none;
    }
    .btn1 {
      @include font_color("font_color17");
      @include background_color("background_color34");
      margin-right: 12px;
    }
    .btn2 {
      @include font_color("font_color26");
      @include background_color("background_color79");
    }
    .btn3 {
      @include font_color("font_color23");
      @include background_color("background_color62");
      margin-right: 12px;
      cursor: not-allowed;
    }
  }
  .tab-container {
    height: 260px;
    width: 100%;
    .card-main {
      width: 100%;
      height: 100%;
      padding: 0 14px;
      display: grid;
      grid-template-columns: 64px 64px 64px;
      grid-template-rows: 64px 64px 64px;
      column-gap: 20px;
      row-gap: 20px;

      .card-item {
        width: 64px;
        height: 64px;
        &.more {
          background: rgba(255, 204, 85, 0.3);
          font-size: 14px;
          font-weight: 500;
          color: #ffcc55;
          line-height: 64px;
          border-radius: 50%;
          text-align: center;
        }
      }
    }
    .tab-item {
      .series-item {
        margin-bottom: 4px;
        height: 64px;
        width: 100%;
      }
    }
  }
}
</style>