import echarts from 'echarts'
var option = {
  backgroundColor: "rgba(0,0,0,0)",
  barWidth: 15,
  grid: {
      "left": "6%",
      "top": "30",
      "right": "100",
      "bottom": "40"
  },
  xAxis: {
      type: 'value',
      splitLine: {
          lineStyle: {
              color: "rgba(255,255,255,0.2)"
          }
      },
      axisTick: {
          show: false
      },
      axisLine: { //  改变x轴颜色
          show: false,
          lineStyle: {
              color: '#26D9FF'
          }
      },
      axisLabel: { //  改变x轴字体颜色和大小
          textStyle: {
              color: "rgba(250,250,250,0.6)",
              fontSize: 14
          }
      }
  },
  yAxis: {
      type: 'category',
      data: [],
      splitLine: {
          show: false
      },
      axisTick: {
          show: false
      },
      axisLine: { //  改变y轴颜色
          lineStyle: {
              color: 'rgba(250,250,250,0.6)'
          }
      },
      axisLabel: { //  改变y轴字体颜色和大小
          //formatter: '{value} m³ ', //  给y轴添加单位
          textStyle: {
              color: "rgba(250,250,250,0.6)",
              fontSize: 14
          }
      }
  },
  series: [{
          type: 'bar',
          name: "短视频",
          itemStyle: {
              normal: {
                  label: {
                      show: true, //开启显示
                      position: 'right', //在上方显示
                      textStyle: { //数值样式
                          color: "rgba(250,250,250,0.6)",
                          fontSize: 12,
                          fontWeight: 600
                      }
                  },
                  color: '#7A91FE',
                  borderWidth: 2
              }
          },
          data: []
      }
  ]
}

export default option