<template>
  <a-drawer
    placement="right"
    width="560"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>查看配置</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <div class="drawer-main-flex">
          <div class="flex-label">活动名称：</div>
          <div class="flex-value">
            {{ currentItem.activity_name }}
          </div>
        </div>
        <div class="drawer-main-flex">
          <div class="flex-label">活动日期：</div>
          <div class="flex-value">
            {{ returnDate(currentItem.start_time) }} 至
            {{ returnDate(currentItem.end_time) }}
          </div>
        </div>
        <div class="drawer-main-flex" v-if="currentItem.other_video_key_word">
          <div class="flex-label">视频关键字：</div>
          <div class="flex-tag">
            <div
              class="flex-tag-item"
              v-for="(item, index) in currentItem.other_video_key_word.split(
                ','
              )"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="drawer-main-flex" v-if="currentItem.other_live_key_word">
          <div class="flex-label">直播关键字：</div>
          <div class="flex-tag">
            <div
              class="flex-tag-item"
              v-for="(item, index) in currentItem.other_live_key_word.split(
                ','
              )"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="drawer-main-split" v-if="currentItem.type == 1"></div>
        <div class="drawer-main-flex" v-if="currentItem.type == 1">
          <div class="flex-label">活动头图：</div>
          <div class="preview-img-box" v-if="currentItem.cover">
            <img :src="currentItem.cover" height="120" alt="" />
            <div class="shadow-box" @click="showImage(currentItem.cover)">
              <a-icon type="eye" class="icon" />
            </div>
          </div>
          <div class="flex-value" v-else>-</div>
        </div>
        <div class="drawer-main-flex" v-if="currentItem.type == 1">
          <div class="flex-label">活动简介图：</div>
          <div class="preview-img-box" v-if="currentItem.photo_intro">
            <img :src="currentItem.photo_intro" height="120" alt="" />
            <div class="shadow-box" @click="showImage(currentItem.photo_intro)">
              <a-icon type="eye" class="icon" />
            </div>
          </div>
          <div class="flex-value" v-else>-</div>
        </div>
        <div class="drawer-main-flex" v-if="currentItem.type == 1">
          <div class="flex-label">获奖名单：</div>
          <div class="preview-img-box" v-if="currentItem.awards_picture">
            <img :src="currentItem.awards_picture" height="120" alt="" />
            <div
              class="shadow-box"
              @click="showImage(currentItem.awards_picture)"
            >
              <a-icon type="eye" class="icon" />
            </div>
          </div>
          <div class="flex-value" v-else>-</div>
        </div>
        <div class="drawer-main-flex" v-if="currentItem.type == 1">
          <div class="flex-label">活动模版：</div>
          <div
            class="template-box"
            v-if="templateData && templateData.length > 0"
          >
            <div
              class="template-item"
              v-for="(item, index) in templateData"
              :key="index"
              @click="getAuthUrl(item.template_url)"
            >
              {{ item.template_name }}
            </div>
          </div>
          <div class="flex-value" v-else>-</div>
        </div>
      </div>
      <div class="drawer-tools">
        <span class="btn btn1" @click="onClose">取消</span>
      </div>
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal
      :visible="videoVisible"
      :footer="null"
      @cancel="videoModalClose"
      ref="modal"
    >
      <video
        id="videoElement"
        autoplay
        controls
        muted
        style="width: 100%; height: 100%; border-radius: 10px"
      ></video>
    </a-modal>
  </a-drawer>
</template>

<script>
import api from "@/api/activityManagement";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
export default {
  components: {},
  props: {},
  data() {
    return {
      visible: false,
      previewVisible: false,
      videoVisible: false,
      previewImage: "",
      currentItem: {},
      tableLoading: false,
      templateData: [],
      flvPlayer: null,
    };
  },
  created() {},
  methods: {
    // 打开抽屉
    showDrawer(item) {
      console.log(item);
      this.visible = true;
      this.currentItem = item;
      this.getTemplateManage();
    },

    // 关闭抽屉
    onClose() {
      this.visible = false;
    },

    getTemplateManage() {
      let params = {
        activity_id: this.currentItem.id,
        status: 2,
      };
      this.tableLoading = true;
      this.templateData = [];
      api
        .getTemplateManage(params)
        .then((res) => {
          console.log("getTemplateManage", res);
          if (res.data.code === 0) {
            this.templateData = res.data.data;
          } else {
            this.$message.error(res.message || "获取模版失败");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 关闭图片预览
    handleCancel() {
      this.previewVisible = false;
    },
    // 打开图片预览
    showImage(url) {
      this.previewImage = url;
      this.previewVisible = true;
    },
    // 视频地址通过内容中台获取权限
    getAuthUrl(source) {
      let body = {
        resource_url: source,
      };
      api
        .getAuthUrl(body)
        .then((res) => {
          console.log("getAuthUrl", res);
          if (res.data.code === 200) {
            this.getStream(res.data.data);
          } else {
            this.$message.error(res.data.message || "获取视频权限失败");
          }
        })
        .catch((err) => {
          this.$message.error("获取视频权限失败");
          console.error(err);
        });
    },
    // 初始化flv
    getStream(source) {
      this.videoVisible = true;
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          console.log(1, source);
          this.flvPlayer = flvjs.createPlayer({
            type: source.indexOf(".mp4") > 0 ? "mp4" : "flv",
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.on("error", (err) => {
            console.log("err", err);
            this.videoModalClose();
            this.$message.warning("视频播放失败，请联系管理员...");
          });
        }
      });
    },
    // 关闭 flv 视频弹窗事件
    videoModalClose() {
      this.videoVisible = false;
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
      }
      this.$refs.modal.$destroyAll();
    },
    returnDate(str) {
      if (str) {
        return str.slice(0, 10);
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 116px);
    padding: 0 24px;
    overflow-y: auto;
    &-flex {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 24px;
      .flex-label {
        width: 84px;
        min-width: 84px;
        text-align: right;
        line-height: 28px;
        font-size: 14px;
        color: #8890a6;
      }
      .flex-value {
        line-height: 28px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
      }
      .flex-tag {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        &-item {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 28px;
          padding: 0 8px;
          background: #1d1d1d;
          border-radius: 4px;
          border: 1px solid #434343;
          height: 28px;
          text-align: center;
          margin-right: 4px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .preview-img-box {
        display: inline-block;
        height: 120px;
        position: relative;
        &:hover {
          .shadow-box {
            opacity: 1;
          }
        }
        .shadow-box {
          width: 100%;
          height: 100%;
          color: rgba(255, 255, 255, 0.85);
          background: rgba(0, 0, 0, 0.4);
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          font-size: 16px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .template-box {
        display: flex;
        flex-direction: column;
        .template-item {
          line-height: 28px;
          color: #ffca5b;
          cursor: pointer;
        }
      }
    }
    &-split {
      width: 100%;
      height: 1px;
      background: #3e424a;
      margin-top: 24px;
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 88px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.btn1 {
        background: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}
</style>