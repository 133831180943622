<template>
  <div class="wrap">
    <div class="brand-box">
      <WebpImage
        :scale="'1-1'"
        :src="principalInfo.logo ? ossHost + principalInfo.logo : ''"
        :width="'68px'"
        :height="'68px'"
        :radius="'20px'"
      />
      <span>{{principalInfo.principal_name || '-'}}</span>
    </div>
    <div class="button-box">
      <span class="button big" @click="routerTo('/dealer/storeSearch')">门店查询</span>
      <span class="button small" @click="routerTo('/liveAndVideo/liveRanking')">直播排行</span>
      <span class="button small" @click="routerTo('/liveAndVideo/videoRanking')">视频排行</span>
    </div>
    <div class="matrix-box" @click="routerTo('/matrix/matrixChangeMonitoringN')">
      <div class="matrix-box-top">
        <div
          class="matrix-box-top-container"
          v-if="matrixData && matrixData.id"
        >
          <div>
            <div>
              <svg-icon class="icon" icon-class="monitoring" />
              <span>{{ matrixData.exception_name }}异动</span>
            </div>
            <span>{{ matrixData.statistic_day.slice(0, 10) }}</span>
          </div>
          <div>
            <span class="title">{{ matrixData.indicator_name }}异动</span>
            <span
              :class="matrixData.indicator_value >= 0 ? 'fc-up' : 'fc-down'"
              >{{ matrixData.indicator_value | numRateAbs }}</span
            >
            <svg-icon
              v-if="matrixData.indicator_value"
              class="ratio-icon"
              :icon-class="
                matrixData.indicator_value >= 0 ? 'ratio-up' : 'ratio-down'
              "
            />
          </div>
        </div>
        <div class="matrix-box-top-container" v-else>
          <div>
            <div>
              <svg-icon class="icon" icon-class="monitoring" />
              <span>矩阵健康度监测中…</span>
            </div>
          </div>
          <div class="gif-box">
            <img src="@/assets/img/matrix.gif" alt="" />
          </div>
        </div>
      </div>
      <div class="matrix-box-bottom">点击查看</div>
    </div>
  </div>
</template>

<script>
import WebpImage from "@/components/WebpImage/WebpImage";
import api from "@/api/bulletinBoard";
export default {
  name: "RightWrap1",
  components: { WebpImage },
  data() {
    return {
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      matrixData: {},
      principalInfo: {},
    };
  },
  computed: {},
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getMatrixNotice();
      this.getPrincipalInfo();
    },
    // 接口请求 - 主体信息
    getPrincipalInfo() {
      let params = {
        platform: 'agg',
      };
      api.getPrincipalInfo(params).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getPrincipalInfo", data);
          this.principalInfo = data;
        } else {
          console.error("获取主体信息失败");
        }
      });
    },
    // 接口请求 - 矩阵异动
    getMatrixNotice() {
      let params = {
        platform: this.$parent.currentTab,
      };
      api.getMatrixNotice(params).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getMatrixNotice", data);
          this.matrixData = data;
        } else {
          console.error("获取矩阵异动失败");
          this.matrixData = {};
        }
      });
    },
    routerTo(path) {
      this.$router.push({
        path: path,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: rgb(0, 0, 0) url("../../../assets/img/board_bg.png") no-repeat;
  .brand-box {
    height: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0 24px;
    > span {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
    }
  }
  .button-box {
    width: 100%;
    height: 90px;
    padding: 0 20px;
    margin-bottom: 20px;
    > .button {
      height: 40px;
      line-height: 40px;
      background: #10121a;
      border-radius: 8px;
      text-align: center;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      &.big {
        width: 250px;
      }
      &.small {
        width: 118px;
        margin-top: 10px;
      }
      &.small:last-child {
        margin-left: 14px;
      }
    }
  }
  .matrix-box {
    height: 130px;
    width: 100%;
    cursor: pointer;
    &-top {
      width: 100%;
      height: 80px;
      background: linear-gradient(
        90deg,
        rgba(190, 38, 14, 0.15) 0%,
        rgba(196, 100, 24, 0.15) 100%
      );
      &-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 14px 16px 14px 20px;
        > div:first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          > div:first-child {
            .icon {
              font-size: 22px;
            }
            > span {
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
              line-height: 22px;
              margin-left: 10px;
            }
          }
          > span {
            font-size: 12px;
            font-weight: bold;
            color: #ffca5c;
          }
        }
        > div:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 32px;
          > .title {
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            margin-right: 15px;
          }
          .fc-up {
            color: #e85a34;
            font-size: 12px;
            margin-top: 4px;
          }
          .fc-down {
            color: #45b36b;
            font-size: 12px;
            margin-top: 4px;
          }
          .ratio-icon {
            font-size: 10px;
            margin-left: 4px;
            margin-top: 4px;
          }
        }
        > div:last-child.gif-box {
          display: flex;
          justify-content: center;
          padding: 0;
        }
      }
    }
    &-bottom {
      height: 50px;
      width: 100%;
      font-size: 14px;
      line-height: 50px;
      color: rgba(255, 255, 255, 0.4);
      text-align: center;
    }
  }
}
</style>
