import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
const platformRequest = (platform) => {
  let baseURL = getBaseUrl(platform)

  return new Nr({
    baseURL: baseURL
  }).getAxios()
}
export default {
  // 获取车系详情、热门话题
  getSeriesDetail({
    series_id,
    statistics_begin_date,
    statistics_end_date,
    platform
  }) {

    return request({
      url: `/jjt/aweme-series/series-interaction-detail/${series_id}`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        statistics_begin_date,
        statistics_end_date
      }
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: {
          series: {
            image: (res.data.series && res.data.series.image) ? process.env.VUE_APP_LIVE_OSS_HOST + res.data.series.image : process.env.VUE_APP_LIVE_OSS_HOST + 'car_img/500_ChsEnF5q_DqABhevAACPN-sJ7O0493.jpg',
            name: (res.data.series && res.data.series.name) || '--',
            gap_rate: res.data.gap_rate || 0,
            gap_total: res.data.gap_total || 0
          },
          hotTopic: res.data.hot_search_suggest
        },
        msg: res.msg
      })
    })
  },
  // 获取竞品趋势
  getCompetitorChart({
    series_id,
    statistics_begin_date,
    statistics_end_date,
    platform
  }) {
    return request({
      url: `/jjt/aweme-series/series-enemy-interaction-detail/${series_id}`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        statistics_begin_date,
        statistics_end_date
      }
    }).then(res => {
      res = res.data
      return({
        code: 200,
        data: {
          nameArr: res.data.map(item => {return item.series.name}),
          timeArr: res.data.length > 0 ? res.data[0].interactive_score.map(item => {return item.crawl_time.slice(5, 10)}) : [],
          dataArr: res.data.length > 0 ? res.data.map(item => {return item.interactive_score.map(i => {return i.gap_total})}) : [],
        }
      })
    })
  },
  getVideoList({
    series_id,
    statistics_begin_date,
    statistics_end_date,
    platform
  }) {
    return request({
      url: `/jjt/aweme-series/series-hot-aweme/${series_id}`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        statistics_begin_date,
        statistics_end_date
      }
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: {
          list: res.data
        },
        msg: res.msg
      })
    })
  },
  getEmotion({
    series_id,
    statistics_begin_date,
    statistics_end_date,
    platform
  }) {
    return request({
      url: `/jjt/aweme-series/sentiment-analysis/series-comment/${series_id}`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        statistics_begin_date,
        statistics_end_date
      }
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: {
          emotion: (Math.round(res.data.good_rate * 10000)) / 100 + '%'
        },
        msg: res.msg
      })
    })
  },
  getCommentList({
    current,
    page_size,
    series_id,
    statistics_begin_date,
    statistics_end_date,
    platform
  }) {
    return request({
      url: `/jjt/aweme-series/series-hot-comment/${series_id}`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        current,
        page_size,
        statistics_begin_date,
        statistics_end_date
      }
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: res.data,
        msg: res.msg
      })
    })
  },

  // 新版 车系详情接口

  // 数据趋势
  getDataTrend(body) {
    return platformRequest(body.platform).get(`/jjt/car/series/data-trend`, { params: body.params })
  },
  // 最热话题
  getBestTopic(body) {
    return platformRequest(body.platform).get(`/jjt/car/series/hot-topic`, { params: body.params })
  },
  // 经销商参与 & KOL
  getDealerKol(body) {
    return platformRequest(body.platform).get(`/jjt/car/series/dealer-kol-participate`, { params: body.params })
  },
  // 传播分析
  getAnalysis(body) {
    return platformRequest(body.platform).get(`/jjt/car/series/spread-analyze`, { params: body.params })
  },

  // 车系话题详情接口
  // 主播列表
  getAuthorByTopic(body) {
    return platformRequest(body.platform).get(`/jjt/car/series/topic/author/detail`, { params: body.params })
  },
  // 视频列表
  getVideoByTopic(body) {
    return platformRequest(body.platform).get(`/jjt/car/series/topic/aweme/detail`, { params: body.params })
  },
  // 趋势
  getTrendByTopic(body) {
    return platformRequest(body.platform).get(`/jjt/car/series/topic/detail`, { params: body.params })
  },
}
