<template>
  <div class="component-wrap">
    <a-spin :spinning="weekLoading" class="spin-class" />
    <div class="title-div">
      <NewTabs :tabData="tabData" @tabChange="changeType" />
      <!-- <div @click="routerTo" class="route-div">
        <span class="route">查看更多</span>
        <span class="arrow">
          <svg-icon icon-class="arrow-right" style="font-size: 24px" />
        </span>
      </div> -->
    </div>

    <a-table
      :columns="columns"
      :data-source="type == 'live' ? liveRankList : awemeRankList"
      :rowKey="(data) => data.author_id"
      :customRow="rowClick"
      :pagination="false"
      class="table-style border"
      :locale="locale"
    >
      <div slot="customTitle" style="display: flex; padding-left: 20px">
        <div>排名</div>
        <AftTooltip
          :code="type == 'live' ? 'pgpm04' : 'pgpm05'"
          style="left: 4px"
        />
      </div>
      <div
        slot="ranking"
        slot-scope="text, records, index"
        class="td-style td-left ranking"
      >
        <span style="margin: 0 0 0 20px" class="bc sp">{{ index + 1 }}</span>

        <span class="font-color23" v-if="records.rank_diff">{{
          Math.abs(records.rank_diff) || ""
        }}</span>
        <svg-icon
          v-if="records.rank_diff > 0"
          icon-class="caret-up"
          style="font-size: 10px"
        />
        <svg-icon
          v-if="records.rank_diff < 0"
          icon-class="caret-down"
          style="font-size: 10px"
        />
        <svg-icon
          icon-class="NEW"
          v-if="records.is_new == 0"
          style="font-size: 30px; margin: 4px 0 0 4px"
        />
      </div>
      <div
        slot="name"
        slot-scope="text, records"
        class="td-style table-name"
      >
        <WebpImage
          :src="records.avatar"
          :width="'30px'"
          :height="'30px'"
          :radius="'50%'"
        />
        <span>{{ records.nickname || "-" }}</span>
      </div>
    </a-table>
  </div>
</template>

<script>
import * as vuex from "vuex";
import Tooltip from "@/components/Tooltip/Tooltip";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";
import NewTabs from "@/components/PlatformTab/NewTabs";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brandSummary");
export default {
  name: "CardRanking",
  data() {
    return {
      type: "live",
      columns: [
        {
          width: "130px",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "ranking" },
        },
        {
          title: "平台昵称",
          align: "left",
          scopedSlots: { customRender: "name" },
        },
      ],

      tabData: [
        {
          label: "直播周榜",
          value: "live",
        },
        {
          label: "视频周榜",
          value: "video",
        },
      ],
      locale: {
        emptyText: <Empty style="margin-top: 20px;" title="暂无数据" />,
      },
    };
  },
  components: {
    Tooltip,
    WebpImage,
    NewTabs,
  },
  computed: {
    ...mapState({
      liveRankList: (state) => state.liveRankList, // 直播周榜
      awemeRankList: (state) => state.awemeRankList, // 视频周榜
      weekLoading: (state) => state.weekLoading,
    }),
  },
  created() {
    if (this.$parent.principal_id == "") {
      this.initData();
    }
  },
  methods: {
    ...mapActions(["getLiveRank", "getAwemeRank"]),
    ...mapMutations(["changeState"]),
    initData() {
      let params = {
        platform: this.$parent.currentTab,
      };
      this.getLiveRank(params);
      this.getAwemeRank(params);
    },
    changeType(val) {
      this.type = val;
    },
    //   查看更多跳转
    routerTo() {
      if (this.type == "video") {
        this.$router.push({
          path: "/dealer/hotVideoList",
        });
      } else {
        this.$router.push({
          path: "/dealer/hotLiveList",
        });
      }
    },
    // 跳转作品发布
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            console.log(record);
            this.$router.push({
              path: "/dealer/production",
              query: {
                type: this.$parent.currentTab,
                author_id: record.author_id,
              },
            });
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.component-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 137px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 82px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    > div {
      cursor: pointer;
    }
    .title {
      font-size: 16px;
      margin-left: 10px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      @include font_color("font_color28");
      .route {
        font-size: 12px;
        line-height: 24px;
      }
      .arrow {
        font-size: 24px;
      }
    }
  }
  .table-style {
    .ranking > span {
      min-width: 24px;
      padding: 0 8px;
      height: 24px;
      border-radius: 12px;
      display: inline-block;
      text-align: center;
      line-height: 24px;
    }
    .table-name {
      font-size: 14px;
      @include font_color("font_color21");
      > span {
        margin-left: 10px;
        width: calc(100% - 80px);
        display: inline-block;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        color: rgba(255, 255, 255, 0.85);
      }
    }
    .bc {
      @include background_color("background_color51");
      @include font_color("font_color22");
    }
    .bc.sp {
      @include background_color("background_color88");
      @include font_color("font_color55");
    }
  }
  .td-style {
    height: 42px;
    display: flex;
    align-items: center;
  }
  .td-left {
    text-align: left;
  }
  .td-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .td-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .font-color20 {
    @include font_color("font_color20");
  }
  .font-color21 {
    @include font_color("font_color21");
  }
  .font-color23 {
    @include font_color("font_color23");
  }
  .ranking-color1 {
    @include font_color("font_color26");
  }
  .ranking-color2 {
    @include font_color("font_color29");
  }
  .ranking-color3 {
    @include font_color("font_color32");
  }
}
</style>
