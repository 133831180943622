var option = {
    backgroundColor: "rgba(0,0,0,0)",
    grid: {
        "left": "10",
        "top": "40",
        "right": "10",
        "bottom": "30"
    },
    tooltip: {
        show: false,    // 是否显示提示框组件
    },
    xAxis: {
        type: 'category',
        boundaryGap: true,
        axisLine: {
            show: false, //隐藏x轴
        },
        axisTick: {
            show: false,  //刻度线
        },
        axisLabel: {
            show: false, //隐藏刻度值
        },
        data: []
    },
    yAxis: [
        {
            type: 'value',
            position: 'right',
            axisTick: {
                show: false,  //刻度线
            },
            axisLine: {
                show: false, //隐藏y轴
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        }
    ],
    series: [
        {
            name: '环比',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            showSymbol: false,
            itemStyle: {
                normal: {
                    color: '#FFCA5C',
                },
            },
            lineStyle: {
                width: 4
            },
            data: []
        },
    ]
};
export default option