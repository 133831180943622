<template>
  <a-drawer
    placement="right"
    width="560"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>指标设置</span>
        <a-icon type="close" @click="onClose" class="icon" />
      </div>
      <div :class="['drawer-item', currentItem == '1' ? 'active' : '']">
        <div class="drawer-item-title">
          <span>短视频能力</span>
          <div>
            <span class="submit" @click="saveClick('aweme')">保存</span>
            <span class="arrow-text" @click="changeCurrent('1')">{{
              currentItem == "1" ? "收起" : "展开"
            }}</span>
            <svg-icon
              icon-class="arrow-down"
              class="arrow-icon"
              @click="changeCurrent('1')"
            />
          </div>
        </div>
        <a-select
          v-model="selectValue1"
          :suffixIcon="suffixIcon"
          class="aft-select drawer2"
          style="width: 200px; height: 36px; margin-bottom: 17px"
          placeholder=""
          @change="changeSelect1"
        >
          <a-select-option
            :value="item.id"
            v-for="item in selectData['1']"
            :key="item.id"
          >
            {{ item.indicator_desc_name }}
          </a-select-option>
        </a-select>
        <div class="drawer-item-item">
          <span class="title">S</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="100"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("aweme", selectValue1).aright_value
            }}</span>
            <span>-</span>
            <span>∞</span>
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="title">A</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="83"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("aweme", selectValue1).bright_value
            }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('aweme', selectValue1).aright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="title">B</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="66"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("aweme", selectValue1).cright_value
            }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('aweme', selectValue1).bright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="title">C</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="49"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{ getLevelInfoData("aweme", selectValue1).dvalue }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('aweme', selectValue1).cright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="title">D</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="32"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>-∞</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="getLevelInfoData('aweme', selectValue1).dvalue"
            />
          </div>
        </div>
      </div>
      <div :class="['drawer-item', currentItem == '2' ? 'active' : '']">
        <div class="drawer-item-title">
          <span>直播能力</span>
          <div>
            <span class="submit" @click="saveClick('live')">保存</span>
            <span class="arrow-text" @click="changeCurrent('2')">{{
              currentItem == "2" ? "收起" : "展开"
            }}</span>
            <svg-icon
              icon-class="arrow-down"
              class="arrow-icon"
              @click="changeCurrent('2')"
            />
          </div>
        </div>
        <a-select
          v-model="selectValue2"
          :suffixIcon="suffixIcon"
          class="aft-select drawer2"
          style="width: 200px; height: 36px; margin-bottom: 17px"
          placeholder=""
          @change="changeSelect2"
        >
          <a-select-option
            :value="item.id"
            v-for="item in selectData['2']"
            :key="item.id"
          >
            {{ item.indicator_desc_name }}
          </a-select-option>
        </a-select>
        <div class="drawer-item-item">
          <span class="title">Ⅴ</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="100"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("live", selectValue2).aright_value
            }}</span>
            <span>-</span>
            <span>∞</span>
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="title">Ⅳ</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="83"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("live", selectValue2).bright_value
            }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('live', selectValue2).aright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="title">Ⅲ</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="66"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("live", selectValue2).cright_value
            }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('live', selectValue2).bright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="title">Ⅱ</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="49"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{ getLevelInfoData("live", selectValue2).dvalue }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('live', selectValue2).cright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="title">Ⅰ</span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="32"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>-∞</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="getLevelInfoData('live', selectValue2).dvalue"
            />
          </div>
        </div>
      </div>
      <div :class="['drawer-item', currentItem == '3' ? 'active' : '']">
        <div class="drawer-item-title">
          <span>留资能力</span>
          <div>
            <span class="submit" @click="saveClick('clue')">保存</span>
            <span class="arrow-text" @click="changeCurrent('3')">{{
              currentItem == "3" ? "收起" : "展开"
            }}</span>
            <svg-icon
              icon-class="arrow-down"
              class="arrow-icon"
              @click="changeCurrent('3')"
            />
          </div>
        </div>
        <a-select
          v-model="selectValue3"
          :suffixIcon="suffixIcon"
          class="aft-select drawer2"
          style="width: 200px; height: 36px; margin-bottom: 17px"
          placeholder=""
          @change="changeSelect3"
        >
          <a-select-option
            :value="item.id"
            v-for="item in selectData['3']"
            :key="item.id"
          >
            {{ item.indicator_desc_name }}
          </a-select-option>
        </a-select>
        <div class="drawer-item-item">
          <span class="tag tag5"></span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="100"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("clue", selectValue3).aright_value
            }}</span>
            <span>-</span>
            <span>∞</span>
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="tag tag4"></span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="83"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("clue", selectValue3).bright_value
            }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('clue', selectValue3).aright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="tag tag3"></span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="66"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{
              getLevelInfoData("clue", selectValue3).cright_value
            }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('clue', selectValue3).bright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="tag tag2"></span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="49"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>{{ getLevelInfoData("clue", selectValue3).dvalue }}</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="
                getLevelInfoData('clue', selectValue3).cright_value
              "
            />
          </div>
        </div>
        <div class="drawer-item-item">
          <span class="tag tag1"></span>
          <a-slider
            class="drawer2 aft-slider"
            :default-value="32"
            :tip-formatter="null"
            :disabled="true"
          />
          <div class="number-box">
            <span>-∞</span>
            <span>-</span>
            <a-input-number
              style="width: 100px; height: 36px"
              class="drawer2 aft-input"
              v-model.number="getLevelInfoData('clue', selectValue3).dvalue"
            />
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import api from "@/api/matrixPerspective.js";
import deepClone from "@/utils/clone-utils";
export default {
  components: {},
  props: {
    platform: {
      type: String,
      default: "",
    },
    detailData: {
      type: Object,
      default: () => {
        return {
          aweme: [{ level_info_data: {} }],
          live: [{ level_info_data: {} }],
          clue: [{ level_info_data: {} }],
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      currentItem: "1",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      selectData: {
        1: [],
        2: [],
        3: [],
      },
      selectValue1: "",
      selectValue2: "",
      selectValue3: "",
      newDetailData: {
        aweme: [{ level_info_data: {} }],
        live: [{ level_info_data: {} }],
        clue: [{ level_info_data: {} }],
      },
      saveLoading: false,
    };
  },
  created() {
    this.getDropdownList("1");
    this.getDropdownList("2");
    this.getDropdownList("3");
  },
  watch: {
    platform(val) {
      this.getDropdownList("1");
      this.getDropdownList("2");
      this.getDropdownList("3");
    },
    detailData(val) {
      setTimeout(() => {
        this.selectValue1 = val.aweme[0].id;
        this.selectValue2 = val.live[0].id;
        this.selectValue3 = val.clue[0].id;
      }, 500);
    },
  },
  mounted() {},
  methods: {
    // 打开抽屉
    showDrawer() {
      this.visible = true;
      this.currentItem = "1";
      this.newDetailData = deepClone(this.detailData);

      this.selectValue1 = this.newDetailData.aweme[0].id;
      this.selectValue2 = this.newDetailData.live[0].id;
      this.selectValue3 = this.newDetailData.clue[0].id;
    },

    // 关闭抽屉
    onClose() {
      this.visible = false;
    },
    // 展开、收起
    changeCurrent(type) {
      if (this.currentItem == type) {
        this.currentItem = "";
      } else {
        this.currentItem = type;
      }
    },
    // 短视频能力 下拉切换
    changeSelect1(val) {
      console.log("changeSelect1", val);
    },
    // 直播能力 下拉切换
    changeSelect2(val) {
      console.log("changeSelect2", val);
    },
    // 留资能力 下拉切换
    changeSelect3(val) {
      console.log("changeSelect3", val);
    },
    // 获取下拉列表
    getDropdownList(type) {
      let params = {
        platform:
          this.platform == "douyin"
            ? "1"
            : this.platform == "kuaishou"
            ? "2"
            : this.platform == "dongchedi"
            ? "3"
            : "0",
        account_type: "3",
        indicator_group_type: type,
      };
      api.getDropdownList(params).then((res) => {
        let { code, data } = res.data;
        if (code == 0) {
          this.selectData[type] = data;
        } else {
          this.$message.warning("未获取到指标名称");
        }
      });
    },
    getLevelInfoData(type, id) {
      let data = {};
      let arr = [];
      arr = this.newDetailData[type].filter((item) => {
        if (item.id == id) {
          return item;
        }
      });
      data = arr.length > 0 ? arr[0].level_info_data : {};
      return data;
    },
    // 保存
    saveClick(type) {
      if (this.saveLoading) {
        return false;
      }
      let data = {};
      let arr = [];
      arr = this.newDetailData[type].filter((item) => {
        let id =
          type == "aweme"
            ? this.selectValue1
            : type == "live"
            ? this.selectValue2
            : this.selectValue3;
        if (item.id == id) {
          return item;
        }
      });
      data = {
        id: arr[0].id,
        platform:
          this.platform == "douyin"
            ? "1"
            : this.platform == "kuaishou"
            ? "2"
            : this.platform == "dongchedi"
            ? "3"
            : "0",
        level_info_data: {
          aleft_value: String(arr[0].level_info_data.bright_value),
          aright_value: String(arr[0].level_info_data.aright_value),
          bleft_value: String(arr[0].level_info_data.cright_value),
          bright_value: String(arr[0].level_info_data.bright_value),
          cleft_value: String(arr[0].level_info_data.dvalue),
          cright_value: String(arr[0].level_info_data.cright_value),
          dvalue: String(arr[0].level_info_data.dvalue),
          svalue: String(arr[0].level_info_data.aright_value),
        },
      };
      this.saveLoading = true;
      api.editPerspectiveById(data).then((res) => {
        this.saveLoading = false;
        let { code, msg } = res.data;
        if (code == 0) {
          this.onClose();
          this.$emit("getDetail");
        } else {
          this.$message.warning(msg || "修改指标失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 64px;
    background: #1a1d26;
    box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px 0 24px;
    > span {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.85);
    }
    > .icon {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.45);
      cursor: pointer;
      padding: 10px;
    }
  }
  .drawer-item {
    height: 70px;
    overflow: hidden;
    background: #1a1d26;
    box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.05);
    transition: all 0.3s;
    padding: 0 24px;
    &.active {
      height: 722px;
      background: #12141b;
      .drawer-item-title {
        > div {
          .arrow-icon {
            transform: rotate(180deg);
          }
          .submit {
            cursor: pointer;
            pointer-events: auto;
            color: #ffcc55;
          }
        }
      }
    }
    &-title {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: #ffcc55;
        > span {
          cursor: pointer;
        }

        .submit {
          cursor: not-allowed;
          pointer-events: none;
          color: rgba(255, 255, 255, 0.3);
        }
        .arrow-text {
          margin: 0 12px 0 16px;
        }
        .arrow-icon {
          transition: all 0.3s;
          cursor: pointer;
        }
      }
    }
    &-item {
      width: 100%;
      height: 120px;
      > .title {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        line-height: 22px;
      }
      .tag {
        width: 22px;
        height: 22px;
        display: inline-block;
        border-radius: 50%;
      }
      .tag1 {
        background: #c7c7c7;
      }
      .tag2 {
        background: #fce7b8;
      }
      .tag3 {
        background: #fdd37b;
      }
      .tag4 {
        background: #f1a74c;
      }
      .tag5 {
        background: #f48a6d;
      }
      .number-box {
        height: 36px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.88);
        > span:nth-child(2) {
          margin: 0 8px;
        }
      }
    }
  }
}
</style>
