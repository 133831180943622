<template>
  <div>
    <!-- 矩阵活跃 -->
    <div class="wrap">
      <div class="flex-between mb20">
        <IconTitle
          :icon="'ti-conversion'"
          :title="'转化分析'"
          :type="1"
          :tooltipTitle="true"
        >
          <div>
            直播观看：抖音平台取观看人次，快手平台取观看人数;<br />
            流量-声量转化率 = 声量线索 / (直播观看 + 视频播放);<br />
            声量-留资转化率 = 销售线索 / 声量线索;<br />
            整体留资转化率 = 销售线索 / (直播观看 + 视频播放);
          </div>
        </IconTitle>
      </div>
      <div class="box-main conversion-main">
        <IconCard
          v-for="(item, index) in cardData"
          :key="index"
          :icon="item.icon"
          :background="item.background"
          :color="item.color"
          :name="item.name"
          :value="item.value"
        />
        <img src="@/assets/line/line1.png" alt="" class="line-img1" />
        <img src="@/assets/line/line2.png" alt="" class="line-img2" />
        <img src="@/assets/line/line3.png" alt="" class="line-img3" />
        <span class="rate-box" v-for="item in rateData" :key="item.text1">
          <span class="number">{{ item.number }}</span>
          <span class="text">{{ item.text1 }}</span>
          <span class="text">{{ item.text2 }}</span>
        </span>
        <a-spin :spinning="spinning" class="spin"> </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("dealerOfOneself");
import IconCard from "@/components/IconCard/IconCard";
import IconTitle from "@/components/IconTitle/IconTitle";

const cardData = [
  {
    background: "linear-gradient(90deg, #F59264 0%, #E75934 100%)",
    color: "#461A0E",
    icon: "ci-live-r",
    name: "直播观看",
    value: "-",
  },
  {
    background: "linear-gradient(90deg, #B795FF 0%, #815CFF 100%)",
    color: "#2C1A6A",
    icon: "ci-video-p",
    name: "视频播放",
    value: "-",
  },
  {
    background: "linear-gradient(270deg, #FFC840 0%, #FFE674 100%)",
    color: "#503E10",
    icon: "ci-clue-y",
    name: "声量线索",
    value: "-",
  },
  {
    background: "linear-gradient(90deg, #97F9FB 0%, #5EF2F4 100%)",
    color: "#0C5C5E",
    icon: "ci-clue-b",
    name: "销售线索",
    value: "-",
  },
];
const rateData = [
  {
    number: "-",
    text1: "整体留资",
    text2: "转化率",
  },
  {
    number: "-",
    text1: "流量-声量",
    text2: "转化率",
  },
  {
    number: "-",
    text1: "声量-留资",
    text2: "转化率",
  },
];
export default {
  name: "ConversionBox",
  components: {
    IconCard,
    IconTitle,
  },
  data() {
    return {
      spinning: false,
      cardData,
      rateData,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      analysisData: (state) => state.analysisData, // 转化分析数据
    }),
  },
  created() {},
  methods: {
    ...mapActions(["getAnalysis"]),
    ...mapMutations(["changeState"]),
    async getAnalysisData(data) {
      this.spinning = true;
      await this.getAnalysis(data);
      this.spinning = false;
      const {
        total_live_watch,
        total_play_count,
        voice_volume_cue_count,
        sales_cue_count,
      } = this.analysisData;

      // cardData赋值
      this.cardData[0].value =
        this.$options.filters.changeNum(total_live_watch);
      this.cardData[1].value =
        this.$options.filters.changeNum(total_play_count);
      this.cardData[2].value = this.$options.filters.changeNum(
        voice_volume_cue_count
      );
      this.cardData[3].value = this.$options.filters.changeNum(sales_cue_count);

      // rateData赋值
      this.rateData[0].number = this.$options.filters.numRate(
        (total_live_watch || 0) + (total_play_count || 0)
          ? (sales_cue_count || 0) /
              ((total_live_watch || 0) + (total_play_count || 0))
          : "-",
        3
      );
      this.rateData[1].number = this.$options.filters.numRate(
        (total_live_watch || 0) + (total_play_count || 0)
          ? (voice_volume_cue_count || 0) /
              ((total_live_watch || 0) + (total_play_count || 0))
          : "-",
        3
      );
      this.rateData[2].number = this.$options.filters.numRate(
        voice_volume_cue_count
          ? (sales_cue_count || 0) / (voice_volume_cue_count || 0)
          : "-",
        3
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
// 公用
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.item-center {
  display: flex;
  align-items: center;
}
.mb20 {
  margin-bottom: 20px;
}
// 主体区域
.wrap {
  padding: 0 20px;
  .box-main {
    @include background_color("background_color80");
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 28px;
  }
  // 矩阵活跃
  .conversion-main {
    height: 300px;
    position: relative;

    > div,
    > span {
      position: absolute;
      z-index: 200;
    }
    > img {
      position: absolute;
      z-index: 100;
    }
    > .card-container:nth-of-type(1) {
      top: 30px;
      left: 168px;
    }
    > .card-container:nth-of-type(2) {
      top: 30px;
      left: 328px;
    }
    > .card-container:nth-of-type(3) {
      top: 114px;
      left: 168px;
    }
    > .card-container:nth-of-type(4) {
      top: 198px;
      left: 168px;
    }
    > .line-img1 {
      width: 104px;
      height: 176px;
      top: 64px;
      left: 128px;
    }
    > .line-img2 {
      width: 227px;
      height: 87px;
      top: 64px;
      left: 294px;
    }
    > .line-img3 {
      width: 179px;
      height: 87px;
      top: 161px;
      left: 184px;
    }

    > .rate-box {
      width: 82px;
      height: 82px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 13px;
      .number {
        color: #ffca5c;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 4px;
      }
      .text {
        color: #c8cede;
        font-size: 12px;
      }
    }
    > span:nth-of-type(1) {
      top: 110px;
      left: 40px;
      align-items: flex-end;
      padding: 0 17px 0 0;
    }
    > span:nth-of-type(2) {
      top: 65px;
      left: 534px;
    }
    > span:nth-of-type(3) {
      top: 164px;
      left: 376px;
    }
    .spin {
      width: 100%;
      padding: 140px 0;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
    }
  }
}
</style>