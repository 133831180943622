var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"title-box"},[_c('svg-icon',{staticStyle:{"font-size":"22px"},attrs:{"icon-class":"champion2"}}),_c('span',[_vm._v("卫冕主播")])],1),_c('div',{staticClass:"champion-card"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.championsData.live && _vm.championsData.live.author_id),expression:"championsData.live && championsData.live.author_id"}],class:_vm.currentHover == 1 ? 'champion-card-item open' : 'champion-card-item',on:{"mouseenter":function($event){_vm.currentHover = 1},"click":function($event){return _vm.openProduction(_vm.championsData.live)}}},[_c('div',{staticClass:"champion-card-item-main"},[_c('div',{staticClass:"champion-card-item-main-top"},[_c('div',{staticClass:"img-box",on:{"click":function($event){$event.stopPropagation();return _vm.toDetail(_vm.championsData.live, 'live')}}},[_c('BaseImage',{staticStyle:{"background-color":"#21232c"},attrs:{"src":_vm.championsData.live.cover || '',"default-image":_vm.defaultCoverImg,"fit":"contain","width":"47px","height":"62px","radius":"4px"}}),_c('div',{staticClass:"img-shadow"},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":"play3"}})],1)],1),_c('div',[_c('div',[_c('span',{staticClass:"f14 fc20"},[_vm._v("单场直播冠军")]),(
                  _vm.championsData &&
                  _vm.championsData.live &&
                  _vm.championsData.live.defending_champion_count
                )?_c('span',{staticClass:"f12 fc26"},[_vm._v("已卫冕"+_vm._s(_vm.championsData.live.defending_champion_count || "-")+"周")]):_c('span',{staticClass:"f12 fc26"},[_vm._v("本周加冕")])]),_c('div',[_c('svg-icon',{staticStyle:{"font-size":"22px","margin-right":"6px"},attrs:{"icon-class":"eye2"}}),_c('span',{staticClass:"f16 fw fc26"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.championsData.live.watch_count)))])],1)])]),_c('div',{staticClass:"champion-card-item-main-bottom"},[_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("开播日期")]),_c('span',[_vm._v(_vm._s(_vm.championsData.live.publish_time || "-"))])]),_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("主播账号")]),_c('span',[_vm._v(_vm._s(_vm.championsData.live.nickname || "-"))])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.championsData.aweme && _vm.championsData.aweme.author_id),expression:"championsData.aweme && championsData.aweme.author_id"}],class:_vm.currentHover == 2 ? 'champion-card-item open' : 'champion-card-item',on:{"mouseenter":function($event){_vm.currentHover = 2},"click":function($event){return _vm.openProduction(_vm.championsData.aweme)}}},[_c('div',{staticClass:"champion-card-item-main"},[_c('div',{staticClass:"champion-card-item-main-top"},[_c('div',{staticClass:"img-box",on:{"click":function($event){$event.stopPropagation();return _vm.toDetail(_vm.championsData.aweme, 'aweme')}}},[_c('BaseImage',{staticStyle:{"background-color":"#21232c"},attrs:{"src":_vm.championsData.aweme.aweme_cover || '',"default-image":_vm.defaultCoverImg,"fit":"contain","width":"47px","height":"62px","radius":"4px"}}),_c('div',{staticClass:"img-shadow"},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":"play3"}})],1)],1),_c('div',[_c('div',[_c('span',{staticClass:"f16 fc20"},[_vm._v("视频播放冠军")]),(
                  _vm.championsData &&
                  _vm.championsData.aweme &&
                  _vm.championsData.aweme.defending_champion_count
                )?_c('span',{staticClass:"f12 fc26"},[_vm._v("已卫冕"+_vm._s(_vm.championsData.aweme.defending_champion_count || "-")+"周")]):_c('span',{staticClass:"f12 fc26"},[_vm._v("本周加冕")])]),_c('div',[_c('svg-icon',{staticStyle:{"font-size":"22px","margin-right":"6px"},attrs:{"icon-class":"eye2"}}),_c('span',{staticClass:"f16 fw fc26"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.championsData.aweme.play_volume_count)))])],1)])]),_c('div',{staticClass:"champion-card-item-main-bottom"},[_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("发布日期")]),_c('span',[_vm._v(_vm._s(_vm.championsData.aweme.publish_time || "-"))])]),_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("主播账号")]),_c('span',[_vm._v(_vm._s(_vm.championsData.aweme.nickname || "-"))])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.championsData.market_value && _vm.championsData.market_value.author_id
      ),expression:"\n        championsData.market_value && championsData.market_value.author_id\n      "}],class:_vm.currentHover == 3 ? 'champion-card-item open' : 'champion-card-item',on:{"mouseenter":function($event){_vm.currentHover = 3},"click":function($event){return _vm.openProduction(_vm.championsData.market_value)}}},[_c('div',{staticClass:"champion-card-item-main"},[_c('div',{staticClass:"champion-card-item-main-top"},[_c('div',{staticClass:"img-box hidden"},[_c('BaseImage',{attrs:{"src":_vm.championsData.market_value.avatar || '',"default-image":_vm.defaultAvatarImg,"fit":"cover","width":"47px","height":"47px","radius":"4px"}}),_c('div',{staticClass:"img-shadow"},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":"play3"}})],1)],1),_c('div',[_c('div',[_c('span',{staticClass:"f16 fc20"},[_vm._v("营销价值冠军")]),(
                  _vm.championsData &&
                  _vm.championsData.market_value &&
                  _vm.championsData.market_value.defending_champion_count
                )?_c('span',{staticClass:"f12 fc26"},[_vm._v("已卫冕"+_vm._s(_vm.championsData.market_value.defending_champion_count || "-")+"天")]):_c('span',{staticClass:"f12 fc26"},[_vm._v("今日加冕")])]),_c('div',[_c('span',{staticClass:"f16 fc27"},[_vm._v("¥"+_vm._s(_vm._f("changeNum")(_vm.championsData.market_value.market_value)))]),_c('AftTooltip',{staticStyle:{"top":"0px","left":"4px"},attrs:{"code":'pgqt03'}})],1)])]),_c('div',{staticClass:"champion-card-item-main-bottom"},[_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("直播&视频")]),_c('span',[_vm._v(_vm._s(_vm.championsData.market_value.aweme_live_count))])]),_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("账号等级")]),_c('span',[_vm._v(_vm._s(_vm.championsData.market_value.level))])]),_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("主播账号")]),_c('span',[_vm._v(_vm._s(_vm.championsData.market_value.nickname || "-"))])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }