<template>
  <div class="container">
    <platform-header
      title="矩阵异动监测"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <button-tabs
        :tabData="btnTabsList"
        @tabChange="handleBtnTabsChange"
        :defaultBtnStyle="defaultBtnStyle"
      />
      <div>
        <a-select
          v-model="typeValue"
          :suffixIcon="suffixIcon"
          style="width: 152px; height: 36px;margin-right: 10px;"
          placeholder="选择异动类型"
          @change="selectChange"
        >
          <a-select-option
            :value="item.value"
            v-for="(item, index) in typeList"
            :key="index"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="tagCode"
          show-search
          :filter-option="filterOption"
          :suffixIcon="suffixIcon"
          style="width: 152px; height: 36px"
          placeholder="选择异动指标"
          @change="selectChange"
        >
          <a-select-option value="">全部指标</a-select-option>
          <a-select-option
            :value="item.code"
            v-for="(item, index) in tagList"
            :key="index"
          >
            {{ item.tag }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="main">
      <div class="main-left">
        <a-spin
          tip=""
          v-if="listLoading"
          :spinning="listLoading"
          class="spin-container"
        ></a-spin>
        <div
          class="list-wrap"
          v-infinite-scroll="handleInfiniteOnLoad"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="100"
        >
          <div
            :class="['wrap', currentItem.id == item.id ? 'active' : '']"
            v-for="(item, index) in monitoringList"
            :key="index"
            @click="listClick(item)"
          >
            <div class="tip"></div>
            <div class="content">
              <div>
                <span>{{ item.exception_name }} · {{ item.event_name }}</span>
                <svg-icon
                  v-if="item.exception_type == 10 && item.indicator_value"
                  :icon-class="
                    item.indicator_value >= 0 ? 'ratio-up' : 'ratio-down'
                  "
                  style="font-size: 10px; margin-left: 12px"
                />
                <svg-icon
                  v-if="
                    item.exception_type == 20 &&
                      item.detail_list[0] &&
                      item.detail_list[0].indicator_value
                  "
                  :icon-class="
                    item.detail_list[0].indicator_value >= 0
                      ? 'ratio-up'
                      : 'ratio-down'
                  "
                  style="font-size: 10px; margin-left: 12px"
                />
              </div>
              <div>
                {{ item.statistic_day ? item.statistic_day.slice(0, 10) : "-" }}
              </div>
            </div>
          </div>
          <div class="no-more" v-if="noMore">没有更多数据了</div>
        </div>
      </div>
      <a-spin :spinning="detailLoading" class="detail-loading">
        <div class="main-right">
          <div class="date">
            {{
              currentItem.statistic_day
                ? currentItem.statistic_day.slice(0, 10)
                : "-"
            }}
          </div>
          <div class="title">
            <svg-icon
              :icon-class="
                getIconByType(currentItem.indicator_classification_type).icon
              "
              class="icon1"
            />
            <span
              >{{ currentItem.indicator_name }} ·
              {{ currentItem.exception_name }}异动</span
            >
            <svg-icon :icon-class="'monitoring'" class="icon2" />
          </div>
          <div class="main-right-wrap">
            <div class="monitoring-chart">
              <MyChart
                :options="getChartOption(chartData)"
                :id="'monitoring-chart'"
              />
            </div>
            <div class="monitoring-table">
              <div class="monitoring-table-header flex-row">
                <div class="fc3">
                  异动门店
                  <span class="fc-up"
                    >（{{ currentItem.exception_dealer_count }}）</span
                  >
                </div>
                <div class="fc3">当日增量</div>
                <div class="fc3">环比</div>
              </div>
              <div class="monitoring-table-body">
                <div
                  class="table-item flex-row"
                  v-for="(item, index) in tableData"
                  :key="index"
                  @click="routerDetail(item)"
                >
                  <div class="store-box">
                    <WebpImage
                      :scale="'1-1'"
                      :src="item.avatar"
                      :width="'28px'"
                      :height="'28px'"
                      :radius="'50%'"
                    />
                    <div class="store-box-text">
                      <span>{{ item.dealer_name || "-" }}</span>
                      <span>{{ item.nickname || "-" }}</span>
                    </div>
                  </div>
                  <div class="fc2">{{ item.latest_value | changeNum }}</div>
                  <div class="ratio-box">
                    <span
                      :class="[item.latest_ratio >= 0 ? 'fc-up' : 'fc-down']"
                      >{{ item.latest_ratio | numRateAbs }}</span
                    >
                    <svg-icon
                      v-if="item.latest_ratio"
                      :icon-class="
                        item.latest_ratio >= 0 ? 'ratio-up' : 'ratio-down'
                      "
                      class="ratio-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="empty-right-box" v-if="rightEmpty">
          <Empty />
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import WebpImage from "@/components/WebpImage/WebpImage";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Empty from "@/components/Empty/Empty";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartBarLine from "./options/chartBarLine.js";
import ButtonTabs from "@/components/PlatformTab/NewButtonTab";
import api from "@/api/matrixChangeMonitoring";
import filters from "@/filters";
const btnTabsList = [
  {
    label: "最新异动",
    value: "new",
  },
  {
    label: "历史异动",
    value: "history",
  },
];
export default {
  data() {
    return {
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      chartData: {
        xData: [],
        yData1: [],
        yData2: [],
      },
      tableData: [],
      btnTabsList,
      listTab: "new",
      defaultBtnStyle: {
        height: "36px",
      },
      tagCode: undefined,
      tagList: [],
      typeValue: "",
      typeList: [
        {
          label: "全部类型",
          value: "",
        },
        {
          label: "矩阵异动",
          value: "10",
        },
        {
          label: "门店异动",
          value: "20",
        },
      ],
      currentTab: "",
      current: 1,
      pageSize: 20,
      total: 0,
      listLoading: false,
      detailLoading: false,
      rightEmpty: true,
      monitoringList: [],
      currentItem: {},
      noMore: false,
    };
  },
  components: {
    WebpImage,
    PlatformHeader,
    Empty,
    MyChart,
    ButtonTabs,
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {
    await this.getMonitorTag();
    // 初始化调用 最新矩阵异动列表
    // this.getMonitNewList();
  },
  methods: {
    // 最新、历史切换
    handleBtnTabsChange(val) {
      console.log("最新、历史切换", val);
      if (this.listTab == val) {
        return false;
      }
      this.listTab = val;
      this.resetList();
      if (this.listTab == "new") {
        this.getMonitNewList();
      } else {
        this.getMonitHistoryList();
      }
    },
    // 平台切换
    tabChange(val) {
      this.currentTab = val;
      this.resetList();
      if (this.listTab == "new") {
        this.getMonitNewList();
      } else {
        this.getMonitHistoryList();
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.trim().toLowerCase()) >= 0
      );
    },
    // 异动类型下拉切换
    selectChange() {
      this.resetList();
      if (this.listTab == "new") {
        this.getMonitNewList();
      } else {
        this.getMonitHistoryList();
      }
    },
    // 滚动加载触发
    handleInfiniteOnLoad() {
      if (this.listLoading || this.noMore) {
        return false;
      }
      console.log("handleInfiniteOnLoad");
      if (this.listTab == "new") {
        // this.getMonitNewList();
      } else {
        this.getMonitHistoryList();
      }
    },
    // 重置分页组件参数
    resetList() {
      this.current = 1;
      this.monitoringList = [];
      this.noMore = false;
    },
    getChartOption(data) {
      let option = deepClone(chartBarLine);
      option.series[0].data = data.yData1;
      option.series[1].data = data.yData2;
      option.series[0].name = "title";
      option.xAxis.data = data.xData;
      let that = this;
      option.xAxis.axisLabel.textStyle.color = function(value, index) {
        if (value == that.currentItem.statistic_day.slice(0, 10)) {
          return that.getIconByType(
            that.currentItem.indicator_classification_type
          ).color;
        } else {
          return "#8890A6";
        }
      };
      option.tooltip.formatter = function(params, ticket, callback) {
        console.log(params);
        console.log(that.currentItem);
        let _params = params;
        let ratioStr = "";
        if (_params && _params.length > 1) {
          ratioStr = `<div style="padding: 10px 25px;font-size: 13px;font-weight: bold;">环比: ${filters.numRate2(
            _params[1].value
          )}</div>`;
        }
        return `
                <div style="background: #3C4252;border-radius: 8px;box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);color: #fff; line-height: 1.2em;min-width: 96px; box-sizing: border-box;">
                    <div style="padding: 8px 25px;font-size: 10px;position: relative;">
                        ${_params[0].name}
                    </div>
                    <div style="padding: 10px 25px;font-size: 13px;font-weight: bold;border-top: 1px solid rgba(255, 255, 255, .1);">
                        ${that.currentItem.indicator_name}: ${filters.changeNum(
          Number(_params[0].value)
        )}
                    </div>
                    ${ratioStr}
                </div>
            `;
      };
      return option;
    },

    // 获取标签下拉列表
    getMonitorTag() {
      api.getMonitorTag().then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.tagList = res.data || [];
          console.log("getMonitorTag", res.data);
        } else {
          console.error("获取矩阵异动列表失败");
        }
      });
    },
    // 获取最新矩阵异动列表
    getMonitNewList() {
      let body = {
        platform: this.currentTab,
        params: {
          tag_code: this.tagCode || "",
          exception_type: this.typeValue || "",
        },
      };
      this.listLoading = true;
      api.getMonitNewList(body).then((res) => {
        console.log(res);
        this.listLoading = false;
        if (res.code === 0) {
          if (res.data && res.data.list && res.data.list.length > 0) {
            this.rightEmpty = false;
            this.monitoringList = res.data.list;
            // 默认点击第一个
            this.listClick(res.data.list[0]);
          } else {
            this.rightEmpty = true;
            this.monitoringList = [];
          }
          this.noMore = true;
          console.log("getMonitNewList", res.data);
        } else {
          console.error("获取矩阵异动列表失败");
        }
      });
    },
    // 获取历史矩阵异动列表
    getMonitHistoryList() {
      let body = {
        platform: this.currentTab,
        current: this.current,
        size: this.pageSize,
        tag_code: this.tagCode || "",
        exception_type: this.typeValue || "",
      };
      this.listLoading = true;
      api.getMonitHistoryList(body).then((res) => {
        console.log(res);
        this.listLoading = false;
        if (res.code === 0) {
          if (res.data && res.data.list && res.data.list.length > 0) {
            this.rightEmpty = false;
            this.monitoringList = this.monitoringList.concat(res.data.list);
            if (res.data.pages <= this.current) {
              this.noMore = true;
            }
            if (this.current == 1) {
              // 默认点击第一个
              this.listClick(res.data.list[0]);
            }
            this.current++;
          } else {
            this.rightEmpty = true;
            this.noMore = true;
          }
          console.log("getMonitHistoryList", res.data);
        } else {
          console.error("获取矩阵异动列表失败");
        }
      });
    },
    // 点击列表item获取详情
    listClick(item) {
      this.currentItem = item;
      let body = {
        platform: this.currentTab,
        indicator_type: this.currentItem.indicator_type,
        abnormal_type: this.currentItem.exception_type,
        end_time_str: this.currentItem.statistic_day,
        author_id_array: this.currentItem.author_ids.join(","),
        // author_ids: "85253085858",
      };
      this.detailLoading = true;

      api.getMonitDetail(body).then((res) => {
        console.log(res);
        this.detailLoading = false;
        if (res.code === 0) {
          console.log("getMonitDetail", res.data);
          let newNodeList = res.data.node_list.reverse();
          // 处理图表数据
          let xData = newNodeList.map((i) => {
            return i.date_desc;
          });
          let yData1 = newNodeList.map((i) => {
            return i.value;
          });
          let obj = {
            value: yData1.pop(),
            itemStyle: {
              color: this.getIconByType(
                this.currentItem.indicator_classification_type
              ).color,
            },
          };
          yData1.push(obj);
          let yData2 = newNodeList.map((i) => {
            return i.ratio;
          });
          this.chartData = {
            xData,
            yData1,
            yData2,
          };
          // 处理表格数据
          this.tableData = res.data.top_list;
        } else {
          console.error("获取详情失败");
        }
      });
    },
    getIconByType(type) {
      if (type == 1) {
        return {
          icon: "ti-fans",
          color: "#80DC1E",
        };
      } else if (type == 2 || type == 3) {
        return {
          icon: "ti-video",
          color: "#815CFF",
        };
      } else if (type == 4 || type == 5) {
        return {
          icon: "ti-live",
          color: "#E85A34",
        };
      } else {
        return {
          icon: "ti-fans",
          color: "#80DC1E",
        };
      }
    },
    routerDetail(obj) {
      this.$router.push({
        path: "/dealer/production",
        query: {
          type: this.currentTab,
          author_id: obj.author_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  width: 100%;
  // min-width: 1226px;
  height: calc(100vh - 64px);
  .tools {
    height: 36px;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    width: 100%;
    height: calc(100% - 150px);
    max-height: 530px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 75px 20px;
    &-left {
      height: 100%;
      width: 214px;
      padding-right: 14px;
      position: relative;
      > div {
        height: 100%;
      }
      .spin-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-spin-container {
        height: 100%;
      }
      .list-wrap {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        .wrap {
          width: 100%;
          height: 76px;
          background: #2b2f3b;
          border-radius: 16px;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: nowrap;
          cursor: pointer;
          .tip {
            width: 3px;
            height: 36px;
            border-radius: 0 36px 36px 0;
            background: #ffca5c;
            margin-right: 17px;
          }
          .content {
            width: calc(100% - 20px);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > div:first-child {
              font-weight: bold;
              color: #fff;
              font-size: 16px;
              margin-bottom: 6px;
            }
            > div:last-child {
              font-weight: bold;
              color: #5d6572;
              font-size: 12px;
            }
          }
        }
        .wrap.active {
          background: #13141a;
          .content {
            > div:first-child {
              font-weight: bold;
              color: #ffca5c;
            }
            > div:last-child {
              font-weight: bold;
              color: #ffca5c;
            }
          }
        }
      }
    }

    .detail-loading {
      height: 100%;
      width: calc(100% - 200px);
      background: #13141a;
      border-radius: 14px;
      position: relative;
      > .ant-spin-container {
        width: 100%;
        height: 100%;
      }
      .empty-right-box {
        height: 100%;
        // width: calc(100% - 200px);
        width: 100%;
        border-radius: 14px;
        background: #13141a;
        border-top: 14px solid #ffca5c;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-right {
      height: 100%;
      // width: calc(100% - 200px);
      width: 100%;
      border-radius: 14px;
      background: #13141a;
      border-top: 14px solid #ffca5c;
      padding: 0 40px 0 30px;
      overflow-y: auto;
      .date {
        width: 100%;
        height: 42px;
        font-size: 30px;
        font-weight: bold;
        color: #ffca5c;
        line-height: 42px;
        margin: 40px 0 20px;
      }
      .title {
        display: flex;
        align-items: center;
        > span {
          margin: 0 4px 0 14px;
          font-size: 16px;
          line-height: 22px;
          font-weight: bold;
          color: #fff;
        }
        .icon1 {
          font-size: 22px;
        }
        .icon2 {
          font-size: 20px;
        }
      }
      &-wrap {
        width: 100%;
        height: 290px;
        margin-top: 64px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        .monitoring-chart {
          width: 41%;
          height: 100%;
          margin-right: 15px;
        }
        .monitoring-table {
          width: 59%;
          height: 100%;
          background: #2b2f3b;
          border-radius: 16px;
          position: relative;
          &:before {
            content: "";
            display: block;
            border-width: 13px 9px;
            border-color: transparent #2b2f3b #2b2f3b transparent;
            position: absolute;
            bottom: 37px;
            left: -18px;
            border-style: solid dashed dashed;
            font-size: 0;
            line-height: 0;
          }
          &-header {
            width: 100%;
            height: 53px;
            font-size: 12px;
          }
          .flex-row {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            > div {
              width: 25%;
              text-align: center;
            }
            > div:first-child {
              width: 50%;
              text-align: left;
              padding-left: 20px;
            }
          }
          &-body {
            width: 100%;
            height: 237px;
            display: flex;
            flex-direction: column;
            .table-item {
              width: 100%;
              height: 70px;
              background: #2b2f3b;
              cursor: pointer;
              border-top: 1px solid rgba(255, 255, 255, 0.06);
              &:last-child {
                border-bottom: 1px solid rgba(255, 255, 255, 0.06);
              }
              &:hover {
                background: #242832;
              }
              .store-box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                &-text {
                  margin-left: 14px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  > span:first-child {
                    font-size: 14px;
                    font-weight: bold;
                    color: #ffffff;
                    margin-bottom: 5px;
                  }
                  > span:last-child {
                    font-size: 12px;
                    color: #9ca8ba;
                  }
                }
              }
              .ratio-box {
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .ratio-icon {
                font-size: 8px;
                margin-left: 6px;
              }
            }
          }
        }
      }
    }
  }
}
.fb {
  font-weight: bold;
}
.fc1 {
  color: #fff;
}
.fc2 {
  color: #c8cede;
}
.fc3 {
  color: #9ca8ba;
}
.fc-up {
  color: #ffca5c;
}
.fc-down {
  color: #45b36b;
}
.spin-div,
.empty-div {
  height: calc(100% - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tools {
  height: 86px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.no-more {
  text-align: center;
  color: #c8cede;
}
</style>
