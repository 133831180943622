<template>
  <div class="wrap">
    <div class="card-box">
      <BoardCard
        :title="'粉丝总量'"
        :color="'128, 220, 30'"
        :number="overviewData.fansNumber || '-'"
        :ratio="overviewData.fans_total_chain"
        :routerName="'粉丝中心'"
        :chartData="{
          xData: overviewData.xData,
          yData: overviewData.yDataFans,
        }"
        @routerTo="routerTo('fans')"
      />
    </div>
    <div class="card-box">
      <BoardCard
        :title="'直播观看'"
        :color="'232, 90, 52'"
        :number="overviewData.liveNumber || '-'"
        :ratio="overviewData.live_watch_count_chain"
        :routerName="'直播中心'"
        :chartData="{
          xData: overviewData.xData,
          yData: overviewData.yDataLive,
        }"
        @routerTo="routerTo('live')"
      />
    </div>
    <div class="card-box">
      <BoardCard
        :title="'视频播放'"
        :color="'129, 92, 255'"
        :number="overviewData.videoNumber || '-'"
        :ratio="overviewData.play_volumet_chain"
        :routerName="'视频中心'"
        :chartData="{
          xData: overviewData.xData,
          yData: overviewData.yDataVideo,
        }"
        @routerTo="routerTo('video')"
      />
    </div>
  </div>
</template>

<script>
import BoardCard from "@/components/card/BoardCard";
import api from "@/api/bulletinBoard";
export default {
  name: "LeftWrap1",
  components: {
    BoardCard,
  },
  data() {
    return {
      overviewData: {},
    };
  },
  computed: {},
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getActivityOverview();
    },
    // 接口请求 - 卡片详情
    getActivityOverview() {
      let params = {
        platform: this.$parent.currentTab,
      };
      api.getActivityOverview(params).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getActivityOverview", data);
          this.overviewData = {
            ...data,
            fansNumber: this.$options.filters.changeNum(data.fans_total),
            liveNumber: this.$options.filters.changeNum(data.live_watch_count),
            videoNumber: this.$options.filters.changeNum(data.play_volume),
            xData: data.fans_add_count_action_day.map((i) => {
              return i.date;
            }),
            yDataFans: data.fans_add_count_action_day.map((i) => {
              return i.number;
            }),
            yDataLive: data.watch_volume_action_day.map((i) => {
              return i.value;
            }),
            yDataVideo: data.play_volume_action_day.map((i) => {
              return i.value;
            }),
          };
        } else {
          console.error("获取活动详情失败");
          this.$message.error(res.data.msg || '获取活动详情失败')
          this.overviewData = {};
        }
      });
    },
    routerTo(type) {
      console.log(type);
      let url = "";
      switch (type) {
        case "video":
          url = "/effectDetailVideo";
          break;
        case "live":
          url = "/effectDetailLive";
          break;
        case "fans":
          url = "/effectDetailFans";
          break;
        default:
          break;
      }
      url = `${url}?aId=${this.overviewData.id}&aName=${encodeURIComponent(
        this.overviewData.activity_name
      )}&platform=${this.$parent.currentTab}&dateStr=${
        this.overviewData.date_str
      }`;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  .card-box {
    flex: 1;
    margin-left: 10px;
    background: #10121a;
    border-radius: 16px;
    &:first-child {
      margin: 0;
    }
  }
}
</style>
