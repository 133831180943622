import Nr from '@/utils/NetworldRequest'
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()

function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const platformRequest = (platform) => {
  let baseURL = getBaseUrl(platform)

  return new Nr({
    baseURL: baseURL
  }).getAxios()
}

export default {
    //获取门店百强列表
    getList(body) {
        return platformRequest(body.platform).get('/jjt/industry/hundred/list', { params: body.params })
    },
    //获取主播最新
    getAuthorList(body) {
        return platformRequest(body.platform).get(`/jjt/dealers/${body.author_id}/live-and-aweme`)
    },
    //获取直播百强列表
    getLiveList(body) {
        return platformRequest(body.platform).get('/jjt/industry/live-hundred/list', { params: body.params })
    },
    //获取视频百强列表
    getVideoList(body) {
        return platformRequest(body.platform).get('/jjt/industry/aweme-hundred/list', { params: body.params })
    },
    
}