<template>
  <div class="wrap">
    <div class="wrap-title">
      <div class="title">区域进度</div>
      <NewTabs2 ref="NewTabs2" :tabData="tabsData" @tabChange="changeTabs" />
    </div>
    <div class="chart-box">
      <MyChart
        :options="getBarOption(progressData.xData, progressData.yData)"
        :id="'bar-chart1'"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api/activityOverview";
import MyChart from "@/components/MyChart/MyChart";
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import deepClone from "@/utils/clone-utils";
import chartBar from "../../options/chartBar.js";

const defaultTabsData = [
  {
    label: "任务总完成率",
    value: "1",
  },
  {
    label: "今日参与率",
    value: "2",
  },
  {
    label: "累计视频播放",
    value: "3",
  },
  {
    label: "累计直播观看",
    value: "4",
  },
];
const defaultTabsData2 = [
  {
    label: "任务总完成率",
    value: "1",
  },
  {
    label: "累计视频播放",
    value: "3",
  },
  {
    label: "累计直播观看",
    value: "4",
  },
];

export default {
  name: "LeftWrap3",
  components: {
    MyChart,
    NewTabs2,
  },
  props: {
    isAllRegion: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tabsData: [],
      tabsKey: "1",
      progressData: {
        xData: [],
        yData: [],
      },
    };
  },
  watch: {
    currentItem: {
      immediate: true,
      handler() {
        const { activityStatus, isLive, isVideo, id } = this.currentItem;
        let tabsData = [];

        activityStatus !== 3 && (tabsData = deepClone(defaultTabsData));
        activityStatus === 3 && (tabsData = deepClone(defaultTabsData2));

        // 过滤掉直播相关的
        if (isLive === 0) {
          tabsData = tabsData.filter((item) => !["4"].includes(item.value));
        }

        // 过滤掉视频相关的
        if (isVideo === 0) {
          tabsData = tabsData.filter((item) => !["3"].includes(item.value));
        }

        this.tabsData = tabsData;
        this.tabsKey = this.tabsData.length ? this.tabsData[0].value : "";
        if (id) {
          this.getRegionProgress();
        }
      },
    },
  },
  methods: {
    // 加载当前页面所有接口
    // initData() {
    //   this.$refs.NewTabs2.tabClick("1");
    //   this.getRegionProgress();
    // },
    // 切换tabs
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
      this.getRegionProgress();
    },
    // 接口请求 - 获取区域进度
    getRegionProgress() {
      let params = {
        activityId: this.currentItem.id,
        businessType: this.tabsKey,
      };
      api.getRegionProgress(params).then((res) => {
        if (res.data.code === 200) {
          console.log("getRegionProgress", res.data.data);
          if (this.tabsKey == "1") {
            let currentList = res.data.data.completionList;
            this.progressData = {
              xData: currentList.map((item) => {
                return item.areaName;
              }),
              yData: currentList.map((item) => {
                return {
                  value: item.percentageCompletion,
                  other: [
                    {
                      label: "任务完成率",
                      value: `${item.percentageCompletion}%`,
                    },
                    {
                      label: "已完成门店",
                      value: `${item.completionCount}`,
                    },
                    {
                      label: "门店总数",
                      value: `${item.allCount}`,
                    },
                  ],
                };
              }),
            };
          } else if (this.tabsKey == "2") {
            let currentList = res.data.data.activeList;
            this.progressData = {
              xData: currentList.map((item) => {
                return item.areaName;
              }),
              yData: currentList.map((item) => {
                return {
                  value: item.activeRate,
                  other: [
                    {
                      label: "今日参与率",
                      value: `${item.activeRate}%`,
                    },
                    {
                      label: "今日参与门店",
                      value: `${item.activeStoreNumber}`,
                    },
                    {
                      label: "应参与门店",
                      value: `${item.totalStoresNumber}`,
                    },
                  ],
                };
              }),
            };
          } else if (this.tabsKey == "3") {
            let currentList = res.data.data.awemeList;
            this.progressData = {
              xData: currentList.map((item) => {
                return item.areaName;
              }),
              yData: currentList.map((item) => {
                return {
                  value: item.awemeWatchNumber,
                  other: [
                    {
                      label: "累计视频发布",
                      value: `${this.$options.filters.changeNumNoRound(
                        item.awemeNumber
                      )}`,
                    },
                    {
                      label: "累计视频播放",
                      value: `${this.$options.filters.changeNumNoRound(
                        item.awemeWatchNumber
                      )}`,
                    },
                  ],
                };
              }),
            };
          } else if (this.tabsKey == "4") {
            let currentList = res.data.data.liveList;
            this.progressData = {
              xData: currentList.map((item) => {
                return item.areaName;
              }),
              yData: currentList.map((item) => {
                return {
                  value: item.liveWatchNumber,
                  other: [
                    {
                      label: "累计直播场次",
                      value: `${this.$options.filters.changeNumNoRound(
                        item.liveNumber
                      )}`,
                    },
                    {
                      label: "累计直播观看",
                      value: `${this.$options.filters.changeNumNoRound(
                        item.liveWatchNumber
                      )}`,
                    },
                  ],
                };
              }),
            };
          } else {
            this.progressData = {
              xData: [],
              yData: [],
            };
          }
          console.log(this.progressData);
        } else {
          this.$message.error(res.data.message || "获取区域进度失败");
          this.progressData = {
            xData: [],
            yData: [],
          };
        }
      });
    },

    getBarOption(xData, yData) {
      let option = deepClone(chartBar);
      option.xAxis.data = xData;
      if (this.tabsKey == "1" || this.tabsKey == "2") {
        option.yAxis[0].axisLabel.formatter = "{value}%";
      } else {
        option.yAxis[0].axisLabel.formatter = "{value}";
      }
      option.series[0].data = yData;
      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
    }
  }
  .chart-box {
    height: 136px;
    width: 100%;
  }
}
</style>
