<template>
  <a-drawer
    :visible="visible"
    :width="736"
    placement="right"
    :closable="false"
    :maskClosable="false"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>{{ type == "add" ? "新建" : "编辑" }}活动</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <a-steps :current="currentStep" size="small" class="steps">
        <a-step title="基本信息" />
        <a-step title="活动成员" />
        <a-step title="活动规则" />
      </a-steps>
      <div class="drawer-main">
        <a-spin :spinning="detailLoading">
          <a-form-model
            :model="form"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
            ref="ruleForm"
            :rules="rules"
          >
            <!-- 步骤:基本信息开始 -->
            <template v-if="currentStep === 0">
              <a-form-model-item label="活动名称" prop="name">
                <a-input
                  v-model="form.name"
                  class="aft-input drawer"
                  placeholder="请输入活动名称，不超过20个字"
                  :maxLength="20"
                  style="width: 100%"
                />
                <span class="count">{{ form.name.length || 0 }}/20</span>
              </a-form-model-item>
              <a-form-model-item prop="timeArr">
                <span slot="label"
                  >活动日期
                  <question-tooltip title="活动时长最多支持90天" />
                </span>
                <a-range-picker
                  v-model="form.timeArr"
                  @change="timeChange"
                  class="aft-range-picker drawer"
                  style="width: 346px"
                  :allow-clear="false"
                  :disabled-date="disabledDate"
                  @openChange="selectedStartDate = null"
                >
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" @click="selectDate(current)">
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
                <!-- 未来版本 -->
                <!-- <a-range-picker
                  :show-time="{ format: 'HH' }"
                  format="YYYY-MM-DD HH"
                  v-model="form.timeArr"
                  @change="timeChange"
                  class="aft-range-picker drawer"
                  style="width: 346px"
                  :allow-clear="false"
                  :disabled-date="disabledDate"
                  @openChange="selectedStartDate = null"
                >
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" @click="selectDate(current)">
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker> -->
              </a-form-model-item>
              <a-form-model-item
                label="活动封面图"
                prop="fileList1"
                style="position: relative"
              >
                <div>
                  <div class="upload-box">
                    <div :class="['default-cover-box', form.fileList1.length > 0 ? 'hide-box' : '']">
                      <div class="cover"><div class="txt">默认封面图</div></div>
                      <div class="edit">修改</div>
                    </div>
                    <a-upload
                      :before-upload="(file) => beforeUploadImg(file, 'fileList1')"
                      :custom-request="
                        (e) => customRequest(e, '活动封面图', 'fileList1')
                      "
                      accept="image/png, image/jpg, image/jpeg"
                      list-type="picture-card"
                      :file-list="form.fileList1"
                      @preview="handlePreview"
                      @change="(e) => handleFileChange(e, 'fileList1')"
                      :class="['aft-upload', 'drawer', form.fileList1.length > 0 ? 'has-cover' : 'has-default']"
                    >
                      <div v-if="form.fileList1.length < 1" ref="fileList1">
                        <a-icon type="plus" />
                      </div>
                    </a-upload>
                  </div>
                  <span class="upload-label">
                    支持jpg/png/jpeg 格式，建议比例16:9, 大小不超过4MB
                  </span>
                </div>
              </a-form-model-item>
              <a-form-model-item
                label="活动简介图"
                prop="fileList2"
                style="position: relative"
              >
                <a-upload
                  :before-upload="(file) => beforeUploadImg(file, 'fileList2')"
                  :custom-request="
                    (e) => customRequest(e, '活动简介图', 'fileList2')
                  "
                  accept="image/png, image/jpg, image/jpeg"
                  list-type="picture-card"
                  :file-list="form.fileList2"
                  @preview="handlePreview"
                  @change="(e) => handleFileChange(e, 'fileList2')"
                  class="aft-upload drawer"
                >
                  <div v-if="form.fileList2.length < 1" ref="fileList2">
                    <a-icon type="plus" />
                  </div>
                </a-upload>
                <span class="upload-label">
                  支持jpg/png/jpeg 格式, 大小不超过4MB
                </span>
              </a-form-model-item>
            </template>
            <!-- 步骤:基本信息结束 -->

            <!-- 步骤:活动成员开始 -->
            <div class="scope-wrap" v-if="currentStep === 1">
              <div class="radio-wrap">
                <a-radio-group
                  v-model="form.scope"
                  :disabled="returnDisabled"
                  style="position: relative"
                  @change="formRadioChange"
                >
                  <a-radio :value="1">全部经销商</a-radio>
                  <a-radio :value="4">大区范围</a-radio>
                  <a-radio :value="5">战区范围</a-radio>
                  <a-radio
                    :value="3"
                    v-if="this.groupList[3] && this.groupList[3].length > 0"
                  >
                    门店分组
                  </a-radio>
                  <a-radio
                    :value="2"
                    v-if="this.groupList[2] && this.groupList[2].length > 0"
                  >
                    账号分组
                  </a-radio>
                </a-radio-group>
              </div>
              <a-form-model-item
                v-if="form.scope <= 3"
                label="参与范围"
                prop="scopeId"
              >
                <a-select
                  class="aft-select select-single drawer"
                  v-model="form.scopeId"
                  :default-active-first-option="false"
                  :disabled="returnDisabled"
                  :suffixIcon="suffixIcon"
                  option-filter-prop="children"
                  :placeholder="scopePlaceholder"
                  show-search
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in scopeList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <div class="tips">
                  共 {{ matrixMemberNum.dealerNum }} 家门店，
                  {{ matrixMemberNum.authorNum }} 个媒体号
                </div>
              </a-form-model-item>
              <a-form-model-item v-else label="参与范围" prop="scopeIds">
                <a-select
                  class="aft-select drawer"
                  v-model="form.scopeIds"
                  :default-active-first-option="false"
                  :disabled="returnDisabled"
                  mode="multiple"
                  :suffixIcon="suffixIcon"
                  option-filter-prop="children"
                  :placeholder="scopePlaceholder"
                  show-search
                  @blur="getMatrixMemberNum"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in scopeList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <div class="tips">
                  共 {{ matrixMemberNum.dealerNum }} 家门店，
                  {{ matrixMemberNum.authorNum }} 个媒体号
                </div>
              </a-form-model-item>
            </div>
            <!-- 步骤:活动成员结束 -->

            <!-- 步骤:活动规则开始 -->
            <template v-if="currentStep === 2">
              <a-form-model-item
                label="参与平台（至少选择一项）"
                prop="platform"
                ref="platform"
              >
                <a-checkbox-group
                  v-model="form.platform"
                  class="aft-checkbox drawer"
                  :disabled="returnDisabled"
                  @change="
                    () => {
                      form.checkData.length > 0 &&
                        $refs['checkData'].onFieldChange();
                      form.videoCreateSource = '0';
                    }
                  "
                >
                  <a-checkbox value="1" v-if="platformEnable.douyin">
                    抖音
                  </a-checkbox>
                  <a-checkbox value="2" v-if="platformEnable.kuaishou">
                    快手
                  </a-checkbox>
                  <!-- 选择账号分组无法选择小红书 -->
                  <template v-if="form.scope !== 2">
                    <a-checkbox
                      value="3"
                      @change="handlePlatformChange"
                      v-if="platformEnable.xiaohongshu"
                    >
                      小红书
                    </a-checkbox>
                    <a-checkbox value="4" v-if="platformEnable.shipinhao">
                      视频号
                    </a-checkbox>
                  </template>
                </a-checkbox-group>
              </a-form-model-item>
              <a-form-model-item
                label="参与条件（至少选择一项）"
                prop="checkData"
                ref="checkData"
              >
                <a-checkbox-group
                  v-model="form.checkData"
                  :disabled="returnDisabled"
                  class="aft-checkbox drawer"
                  @change="
                    () =>
                      form.platform.length > 0 &&
                      $refs['platform'].onFieldChange()
                  "
                >
                  <template
                    v-if="
                      platformEnable.douyin ||
                        platformEnable.kuaishou ||
                        platformEnable.shipinhao
                    "
                  >
                    <a-checkbox value="1"> 视频</a-checkbox>
                    <a-checkbox value="2"> 直播</a-checkbox>
                  </template>
                  <template v-if="form.scope !== 2">
                    <a-checkbox
                      value="3"
                      @change="handleCheckDataChange"
                      v-if="platformEnable.xiaohongshu"
                    >
                      笔记
                      <a-tooltip placement="top">
                        <template slot="title">
                          <div>1.笔记仅小红书平台特有内容</div>
                          <div>2.笔记更新频率为T+1天</div>
                        </template>
                        <svg-icon
                          :icon-class="'new-tooltip'"
                          :style="{
                            'margin-left': '5px',
                            color: 'rgba(255,255,255,0.3)',
                          }"
                        />
                      </a-tooltip>
                    </a-checkbox>
                  </template>
                </a-checkbox-group>
              </a-form-model-item>

              <!-- 视频要求开始 -->
              <a-card v-show="form.checkData.indexOf('1') >= 0">
                <div class="rule-title">
                  视频要求（若设置，视频需要同时达到以下要求才算参与活动）
                </div>
                <template v-if="videoSeriesArrShow">
                  <a-form-model-item
                    :label="
                      `视频标题含车系名(${form.video_series_arr.length}/10)`
                    "
                    prop="video_series_arr"
                    v-show="form.checkData.indexOf('1') >= 0"
                  >
                    <a-select
                      ref="select"
                      v-model="form.video_series_arr"
                      mode="multiple"
                      style="width: 100%"
                      class="aft-select drawer"
                      placeholder="请选择视频标题含车系名"
                      notFoundContent="暂无数据"
                      show-search
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      :disabled="returnDisabled"
                      @change="
                        (val) =>
                          selectLimit(
                            val,
                            10,
                            'video_series_arr',
                            '最多关联10个车系'
                          )
                      "
                    >
                      <a-select-option
                        v-for="item in seriesList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </template>
                <a-form-model-item
                  prop="video_value_arr"
                  v-show="form.checkData.indexOf('1') >= 0"
                  ref="videoKeyTypes"
                >
                  <div slot="label">
                    {{ `视频含关键词(${form.video_value_arr.length}/20)` }}
                    <span class="demo-btn" @click="openDemoModal('1')">
                      查看示例
                    </span>
                  </div>
                  <div class="compact">
                    <div class="compact-left">
                      <a-select
                        class="aft-select drawer compact-select"
                        v-model="form.videoKeyTypes"
                        :suffixIcon="suffixIcon"
                        mode="multiple"
                        placeholder="请选择视频类型"
                        @change="$refs['videoKeyTypes'].onFieldChange()"
                      >
                        <a-select-option value="1">
                          视频标题
                        </a-select-option>
                        <a-select-option value="2">
                          视频话题
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="compact-left">
                      <a-select
                        class="aft-select drawer"
                        v-model="form.videoKeywordRelation"
                        :suffixIcon="suffixIcon"
                      >
                        <a-select-option value="or">
                          包含任意关键词
                        </a-select-option>
                        <a-select-option value="and">
                          包含全部关键词
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="compact-right">
                      <a-select
                        class="aft-select drawer"
                        v-model="form.video_value_arr"
                        mode="tags"
                        placeholder="不区分英文大小写和空格"
                        notFoundContent="输入后按回车键添加"
                        :maxTagCount="20"
                        @change="
                          (val) =>
                            selectLimit(
                              val,
                              20,
                              'video_value_arr',
                              '最多输入20个关键词'
                            )
                        "
                      >
                      </a-select>
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item v-show="form.checkData.indexOf('1') >= 0">
                  <div slot="label">
                    视频目标
                  </div>
                  <div class="compact">
                    <div class="compact-left">
                      <a-input
                        class="aft-input drawer"
                        :value="'视频播放量'"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="symbol">
                      ≥
                    </div>
                    <div class="compact-right">
                      <a-input
                        class="aft-input drawer"
                        v-model="form.awemeTarget"
                        placeholder="请输入数值"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        :disabled="returnDisabled"
                      >
                      </a-input>
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item
                  prop="videoCreateSource"
                  v-show="form.checkData.indexOf('1') >= 0"
                >
                  <span slot="label">
                    视频创作来源&nbsp;
                    <question-tooltip :overlayStyle="{ maxWidth: '550px' }">
                      <div>
                        <div>全部视频：统计符合活动规则的视频</div>
                        <div>
                          使用任意模版：统计风火轮导拍模版，包含确认的活动模版
                        </div>
                        <div>
                          仅使用活动模版：仅统计在聚金塔确认的导拍模版
                        </div>
                      </div>
                    </question-tooltip>
                  </span>
                  <a-select
                    v-model="form.videoCreateSource"
                    :suffixIcon="suffixIcon"
                    style="width: 196px; height: 40px;display: inline-block !important"
                    class="aft-select drawer"
                    :disabled="returnDisabled"
                    placeholder="请选择视频创作来源"
                  >
                    <a-select-option value="0"> 全部视频</a-select-option>
                    <template v-if="hasDouyinKuaishou">
                      <a-select-option value="2"> 使用任意模版</a-select-option>
                      <a-select-option value="1">
                        仅使用活动模版
                      </a-select-option>
                    </template>
                  </a-select>
                  <span class="warning-tip" v-if="form.platform.includes('4')"
                    >视频号只支持全部视频</span
                  >
                </a-form-model-item>
              </a-card>

              <!-- 直播要求开始 -->
              <a-card v-show="form.checkData.indexOf('2') >= 0">
                <div class="rule-title">
                  直播要求（若设置，直播需要同时达到以下要求才算参与活动）
                </div>
                <template v-if="liveSeriesArrShow">
                  <a-form-model-item
                    :label="
                      `直播标题含车系名(${form.live_series_arr.length}/10)`
                    "
                    prop="live_series_arr"
                    v-show="form.checkData.indexOf('2') >= 0"
                  >
                    <a-select
                      v-model="form.live_series_arr"
                      mode="multiple"
                      style="width: 100%"
                      class="aft-select drawer"
                      placeholder="请选择直播标题含车系名"
                      notFoundContent="暂无数据"
                      show-search
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      :disabled="returnDisabled"
                      @change="
                        (val) =>
                          selectLimit(
                            val,
                            10,
                            'live_series_arr',
                            '最多关联10个车系'
                          )
                      "
                    >
                      <a-select-option
                        v-for="item in seriesList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </template>
                <a-form-model-item
                  prop="live_value_arr"
                  v-show="form.checkData.indexOf('2') >= 0"
                >
                  <div slot="label">
                    {{ `直播标题含关键词(${form.live_value_arr.length}/20)` }}
                    <span class="demo-btn" @click="openDemoModal('2')">
                      查看示例
                    </span>
                  </div>
                  <div class="compact">
                    <div class="compact-left">
                      <a-select
                        class="aft-select drawer"
                        v-model="form.liveKeywordRelation"
                        :suffixIcon="suffixIcon"
                      >
                        <a-select-option value="or">
                          包含任意关键词
                        </a-select-option>
                        <a-select-option value="and">
                          包含全部关键词
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="compact-right">
                      <a-select
                        v-model="form.live_value_arr"
                        mode="tags"
                        style="width: 100%"
                        class="aft-select drawer"
                        placeholder="请输入直播标题含关键词，不区分英文大小写和空格"
                        notFoundContent="输入后按回车键添加"
                        :maxTagCount="20"
                        @change="
                          (val) =>
                            selectLimit(
                              val,
                              20,
                              'live_value_arr',
                              '最多输入20个关键词'
                            )
                        "
                      >
                      </a-select>
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item v-show="form.checkData.indexOf('2') >= 0">
                  <div slot="label">
                    直播目标
                  </div>
                  <div class="compact">
                    <div class="compact-left">
                      <a-select
                        class="aft-select drawer"
                        v-model="form.liveTargetType"
                        :disabled="returnDisabled"
                        :suffixIcon="suffixIcon"
                      >
                        <a-select-option
                          :value="item.value"
                          v-for="(item, index) in liveTargetList"
                          :key="index"
                        >
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="symbol">
                      ≥
                    </div>
                    <div class="compact-right">
                      <a-input
                        class="aft-input drawer"
                        v-model="form.liveTarget"
                        placeholder="请输入数值，如直播时长≥60min，则输入60"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        :disabled="returnDisabled"
                      >
                      </a-input>
                    </div>
                  </div>
                </a-form-model-item>
              </a-card>

              <!-- 笔记要求开始 -->
              <a-card v-show="form.checkData.indexOf('3') >= 0">
                <div class="rule-title">
                  笔记要求
                </div>
                <a-form-model-item
                  prop="noteContentTypes"
                  v-show="form.checkData.indexOf('3') >= 0"
                  ref="noteContentTypes"
                >
                  <div slot="label">
                    笔记类型
                  </div>
                  <a-checkbox-group
                    v-model="form.noteContentTypes"
                    :disabled="returnDisabled"
                    class="aft-checkbox drawer"
                    @change="$refs['noteContentTypes'].onFieldChange()"
                  >
                    <a-checkbox value="1"> 图文笔记</a-checkbox>
                    <a-checkbox value="2"> 视频笔记</a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
                <a-form-model-item
                  prop="note_value_arr"
                  v-show="form.checkData.indexOf('3') >= 0"
                  ref="noteKeyTypes"
                >
                  <div slot="label">
                    {{ `笔记关键词(${form.note_value_arr.length}/20)` }}
                    <span class="demo-btn" @click="openDemoModal(`3`)">
                      查看示例
                    </span>
                  </div>
                  <div class="compact">
                    <div class="compact-left">
                      <a-select
                        class="aft-select drawer"
                        v-model="form.noteKeyTypes"
                        :suffixIcon="suffixIcon"
                        mode="multiple"
                        placeholder="请选择笔记内容类型"
                        @change="$refs['noteKeyTypes'].onFieldChange()"
                      >
                        <a-select-option value="1">
                          笔记标题
                        </a-select-option>
                        <a-select-option value="2">
                          笔记话题
                        </a-select-option>
                        <a-select-option value="3">
                          笔记正文
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="compact-left">
                      <a-select
                        class="aft-select drawer"
                        v-model="form.noteKeywordRelation"
                        :suffixIcon="suffixIcon"
                      >
                        <a-select-option value="or">
                          包含任意关键词
                        </a-select-option>
                        <a-select-option value="and">
                          包含全部关键词
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="compact-right">
                      <a-select
                        v-model="form.note_value_arr"
                        mode="tags"
                        style="width: 100%"
                        class="aft-select drawer"
                        placeholder="不区分英文大小写和空格"
                        notFoundContent="输入后按回车键添加"
                        :maxTagCount="20"
                        @change="
                          (val) =>
                            selectLimit(
                              val,
                              20,
                              'note_value_arr',
                              '最多输入20个关键词'
                            )
                        "
                      >
                      </a-select>
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item
                  class="note-target"
                  v-show="form.checkData.indexOf('3') >= 0"
                >
                  <div slot="label">
                    笔记目标
                  </div>
                  <div class="compact mb-16">
                    <div class="compact-left">
                      <a-input
                        class="aft-input drawer"
                        :value="'正文字数'"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="symbol">
                      ≥
                    </div>
                    <div class="compact-right">
                      <a-input
                        class="aft-input drawer"
                        v-model="form.noteTextTarget"
                        placeholder="请输入数值"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        :disabled="returnDisabled"
                      >
                      </a-input>
                    </div>
                  </div>
                  <div
                    class="compact"
                    v-show="form.noteContentTypes.indexOf('1') >= 0"
                  >
                    <div class="compact-left">
                      <div class="aft-input-txt">
                        图片数<span>（仅图文笔记）</span>
                      </div>
                    </div>
                    <div class="symbol">
                      ≥
                    </div>
                    <div class="compact-right">
                      <a-input
                        class="aft-input drawer"
                        v-model="form.noteImageTarget"
                        placeholder="仅图文笔记统计图片数，请输入数值"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        :disabled="returnDisabled"
                      >
                      </a-input>
                    </div>
                  </div>
                </a-form-model-item>
              </a-card>

              <a-form-model-item label="风火轮可见" prop="appIsShow">
                <a-switch
                  class="new-aft-switch"
                  v-model="form.appIsShow"
                ></a-switch>
              </a-form-model-item>
            </template>
            <!-- 步骤:活动规则结束 -->
          </a-form-model>
        </a-spin>
      </div>
      <div class="drawer-tools">
        <a-button class="btn btn1" @click="onClose"> 取消</a-button>
        <a-button
          class="btn btn1"
          @click="currentStep--"
          v-if="currentStep !== 0"
        >
          上一步
        </a-button>
        <a-popover
          :visible="qrcodeShow"
          @visibleChange="qrcodeShow = false"
          trigger="click"
          v-if="this.type === 'add'"
        >
          <div slot="content" class="qrcode-box">
            <div class="title">
              使用风火轮APP <br />
              扫码预览活动
            </div>
            <div ref="qrcode" class="qrcode"></div>
          </div>
          <a-button
            class="btn btn1"
            @click="nextStep(3)"
            v-if="currentStep === 2"
            :loading="form.status === 3 && isSaving"
          >
            活动预览
          </a-button>
        </a-popover>
        <a-button
          class="btn btn2"
          @click="nextStep(1)"
          :loading="form.status !== 3 && isSaving"
        >
          {{ currentStep === 2 ? "提交" : "下一步" }}
        </a-button>
      </div>
    </div>

    <ImageCropperModal ref="ImageCropperModal" />
    <DemoModal ref="DemoModal" />
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-drawer>
</template>

<script>
import deepClone from "@/utils/clone-utils";
import { getPrincipalId } from "@/utils/auth";
import ImageCropperModal from "@/components/ImageCropperModal/ImageCropperModal.vue";
import api from "@/api/marketingActivityList";
import ossUploadMixin from "@/mixins/ossUploadMixin";
import { getBase64, base64ToFile, aliImgTransform } from "@/utils/util";
import QuestionTooltip from "@/components/Tooltip/QuestionTooltip";
import Tooltip from "@/components/Tooltip/Tooltip";
import DemoModal from "./DemoModal.vue";
import QRCode from "qrcodejs2";
import * as vuex from "vuex";

const { mapState, mapActions } = vuex.createNamespacedHelpers("auth");

const defaultForm = {
  id: "",
  name: "",
  timeArr: [],
  scope: 1,
  scopeId: getPrincipalId(),
  scopeIds: [],
  video_series_arr: [],
  videoKeyTypes: ["2"],
  videoKeywordRelation: "or",
  video_value_arr: [],
  awemeTarget: "",
  dy_topic_arr: [],
  videoCreateSource: "0",
  live_series_arr: [],
  liveKeywordRelation: "or",
  live_value_arr: [],
  liveTarget: "",
  liveTargetType: 1,
  noteContentTypes: ["1", "2"],
  noteKeyTypes: ["3"],
  noteKeyword: "",
  noteKeywordRelation: "or",
  note_value_arr: [],
  noteImageTarget: "",
  noteTextTarget: "",
  checkData: [],
  fileList1: [],
  fileList2: [],
  platform: [],
  status: "1",
  appIsShow: true,
};
const rules = {
  name: {
    required: true,
    message: "请输入活动名称，不超过20个字",
    trigger: "blur",
  },
  timeArr: {
    required: true,
    message: "请选择活动日期",
    trigger: "blur",
  },
  scopeId: [{ required: true, message: "请选择参与范围", trigger: "change" }],
  scopeIds: [{ required: true, message: "请选择参与范围", trigger: "change" }],
  platform: [
    {
      required: true,
      message: "请至少选择一个平台",
      trigger: "change",
    },
  ],
  checkData: [
    {
      required: true,
      message: "请至少选择一种参与条件",
      trigger: "change",
    },
  ],
  note_value_arr: [],
  appIsShow: [
    {
      required: true,
      message: "",
      trigger: "change",
    },
  ],
  noteContentTypes: [],
  video_value_arr: [],
};
const liveTargetList = [
  { label: "直播时长", value: 1 },
  { label: "直播观看量", value: 2 },
];
export default {
  components: {
    ImageCropperModal,
    QuestionTooltip,
    DemoModal,
    Tooltip,
  },
  mixins: [ossUploadMixin],
  props: {
    groupList: {
      type: Object,
      default: () => {
        return {
          2: [],
          3: [],
        };
      },
    },
    seriesList: {
      type: Array,
    },
    topicList: {
      type: Array,
    },
  },
  // #region data
  data() {
    return {
      visible: false,
      detailLoading: false,
      isSaving: false,
      type: "add",
      currentStep: 0,
      // 平台选择判断
      platformEnable: {
        douyin: false,
        kuaishou: false,
        xiaohongshu: false,
        shipinhao: false,
      },
      form: deepClone(defaultForm),
      selectedStartDate: null,
      currentItem: {},
      rules,
      upload_file_params: {
        business_code: "jjt", // 所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
        need_compress: 2, // 是否需要压缩处理-针对图片(1:是  2:否  默认是)
        type: 1, // 文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
      },
      previewVisible: false,
      previewImage: "",

      dealerList: [{ id: getPrincipalId(), name: "全部经销商" }],
      regionList: [],
      combatZoneList: [],
      matrixMemberNum: {
        authorNum: 0, // 媒体号数
        dealerNum: 0, // 门店数
      },
      qrcodeInstance: null,
      qrcodeShow: false,

      liveTargetList,
      // 判断是否显示车系输入框
      videoSeriesArrShow: false,
      liveSeriesArrShow: false,
    };
  },
  computed: {
    ...mapState({
      platformSettingList: (state) => state.platformSettingList,
    }),
    // 下来选择icon
    suffixIcon() {
      return <svg-icon icon-class="select-arrow" style="font-size: 12px;" />;
    },
    // 表单禁用规则 - 编辑 && 进行中 不可编辑
    returnDisabled() {
      if (this.type == "edit" && this.currentItem.activityStatus == 2) {
        return true;
      } else {
        return false;
      }
    },
    // 成员选择框placeholder
    scopePlaceholder() {
      let placeholder;
      switch (this.form.scope) {
        case 1:
          placeholder = "请选择经销商";
          break;
        case 2:
          placeholder = "请选择账号分组";
          break;
        case 3:
          placeholder = "请选择门店分组";
          break;
        case 4:
          placeholder = "请选择大区，可多选";
          break;
        case 5:
          placeholder = "请选择战区，可多选";
          break;
        default:
          break;
      }
      return placeholder;
    },
    // 成员选择列表
    scopeList() {
      const scope = this.form.scope;
      let scopeList = [];
      scope === 1 && (scopeList = this.dealerList);
      (scope === 2 || scope === 3) && (scopeList = this.groupList[`${scope}`]);
      scope === 4 && (scopeList = this.regionList);
      scope === 5 && (scopeList = this.combatZoneList);
      return scopeList;
    },
    hasDouyinKuaishou() {
      return (
        this.form.platform.includes("1") || this.form.platform.includes("2")
      );
    },
  },
  // #endregion data
  watch: {
    "form.scopeId"(val) {
      console.log(val);
      this.getMatrixMemberNum();
    },
  },
  methods: {
    ...mapActions(["getPlatformSetting"]),
    /**
     * 打开drawer
     * @param {string} type 类型 add:新增 edit:编辑
     * @param {object} currentItem 选择活动详情 edit传入
     */
    showDrawer(type, currentItem) {
      // 保留qrcodeInstance
      const qrcodeInstance = this.qrcodeInstance;
      Object.assign(this.$data, this.$options.data());
      this.qrcodeInstance = qrcodeInstance;
      this.type = type;
      this.form = deepClone(defaultForm);
      // 未来版本
      // this.form.timeArr = [moment().startOf("days"), moment().endOf("days")];
      // this.$nextTick(() => {
      //   this.form.timeArr = [undefined, undefined];
      // });
      this.platformCheck();
      this.getRegionList();
      this.getCombatZoneListByPrincipalOnly();

      if (this.type == "add") {
        this.currentItem = {};
        this.getMatrixMemberNum();
      } else {
        // 获取活动详情
        this.getActivityDetail(currentItem.id);
      }
      this.visible = true;
    },
    /**
     * 活动弹窗-取消
     * @param {boolean} 是否确认
     */
    onClose(isConfirm = true) {
      if (!isConfirm) {
        this.visible = false;
        return;
      }
      this.$confirm({
        icon: () => <a-icon type="info-circle" theme="filled" />,
        title: "离开后数据将会丢失，确认要离开吗？",
        onOk: () => {
          this.$nextTick(() => {
            this.$refs.ruleForm.resetFields();
          });
          this.visible = false;
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    // 获取平台配置列表
    async platformCheck() {
      try {
        await this.getPlatformSetting(getPrincipalId());
        this.platformSettingList.forEach((item) => {
          if (item.name === "抖音") {
            this.platformEnable.douyin = true;
          } else if (item.name === "快手") {
            this.platformEnable.kuaishou = true;
          } else if (item.name === "小红书") {
            this.platformEnable.xiaohongshu = true;
          } else if (item.name === "视频号") {
            this.platformEnable.shipinhao = true;
          }
        });
        console.log(this.platformSettingList);
      } catch (error) {}
    },
    /**
     * 获取大区列表
     */
    async getRegionList() {
      try {
        const params = {
          principal_id: getPrincipalId(),
        };
        const res = await api.getRegionList(params);
        const { code, data, message } = res.data;
        if (code === 200) {
          this.regionList = (data || []).map((item) => {
            return { id: item.region_id, name: item.region_names };
          });
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 只通过主体，获取战区列表
     */
    async getCombatZoneListByPrincipalOnly() {
      try {
        const params = {
          principalId: getPrincipalId(),
        };
        const res = await api.getCombatZoneListByPrincipalOnly(params);
        const { code, data, message } = res.data;

        if (code === 200) {
          this.combatZoneList = (data || []).map((item) => {
            return { id: item.combat_zone_id, name: item.combat_zone_name };
          });
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 查询活动成员数量及媒体号数
     */
    getMatrixMemberNum() {
      this.matrixMemberNum.dealerNum = 0;
      this.matrixMemberNum.authorNum = 0;
      if (
        (this.form.scope <= 3 && !this.form.scopeId) ||
        (this.form.scope > 3 && this.form.scopeIds.length === 0)
      ) {
        return;
      }
      const params = {
        scope: this.form.scope,
        scopeId:
          this.form.scope <= 3 ? [this.form.scopeId] : this.form.scopeIds,
      };
      api.getMatrixMemberNum(params).then((res) => {
        console.log("res:", res);
        if (res.data.code === 200) {
          this.matrixMemberNum = res.data.data;
          console.log("this.matrixMemberNum:", this.matrixMemberNum);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    /**
     * 获取活动详情
     * @param {string} id 活动id
     */
    getActivityDetail(id) {
      let params = {
        activityId: id,
      };
      this.detailLoading = true;
      api
        .getActivityDetail(params)
        .then((res) => {
          if (res.data.code == 200) {
            this.currentItem = res.data.data || {};
            this.setForm();
            this.getMatrixMemberNum();
          } else {
            this.onClose();
            this.$message.error("获取详情失败");
          }
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
    /**
     * 将详情会写到form里
     */
    setForm() {
      const item = deepClone(this.currentItem);
      console.log(item.endTime);
      this.form = {
        name: item.activityName,
        timeArr: [moment(item.startDateTime), moment(item.endDateTime)],
        scope: item.scope,
        scopeId: item.scope <= 3 ? item.scopeId[0] : getPrincipalId(),
        scopeIds: item.scope > 3 ? item.scopeId : [],
        video_series_arr: item.videoSeriesKeywordId
          ? item.videoSeriesKeywordId.split(",")
          : [],
        videoKeyTypes: item.videoKeyTypes ? item.videoKeyTypes.split(",") : [],
        videoKeywordRelation: item.videoKeywordRelation || "or",
        video_value_arr: item.videoKeyword ? item.videoKeyword.split(",") : [],
        awemeTarget: item.awemeTarget || "",
        videoCreateSource: String(item.videoCreateSource),
        live_series_arr: item.liveSeriesKeywordId
          ? item.liveSeriesKeywordId.split(",")
          : [],
        liveKeywordRelation: item.liveKeywordRelation || "or",
        live_value_arr: item.liveKeyword ? item.liveKeyword.split(",") : [],
        liveTargetType: item.liveTargetType || 1,
        liveTarget:
          item.liveTargetType === 1
            ? item.liveTarget / 60 || ""
            : item.liveTarget || "",
        noteContentTypes: item.noteContentTypes
          ? item.noteContentTypes.split(",")
          : [],
        noteKeyTypes: item.noteKeyTypes ? item.noteKeyTypes.split(",") : [],
        note_value_arr: item.noteKeyword ? item.noteKeyword.split(",") : [],
        noteKeywordRelation: item.noteKeywordRelation || "or",
        noteTextTarget: item.noteTextTarget || "",
        noteImageTarget: item.noteImageTarget || "",
        checkData: item.statisticsRange.split(","),
        fileList1: item.cover
          ? [
              {
                uid: "-1",
                name: "1.png",
                status: "done",
                url: aliImgTransform(item.cover, 100),
                originUrl: item.cover,
                response: { url: item.cover },
              },
            ]
          : [],
        fileList2: item.introductionImage
          ? [
              {
                uid: "-1",
                name: "2.png",
                status: "done",
                url: aliImgTransform(item.introductionImage, 100),
                originUrl: item.introductionImage,
                response: { url: item.introductionImage },
              },
            ]
          : [],
        platform: item.platform.split(","),
        appIsShow: item.appIsShow ? true : false,
      };
      this.videoSeriesArrShow = item.videoSeriesKeywordId ? true : false;
      this.liveSeriesArrShow = item.liveSeriesKeywordId ? true : false;
      console.log("form", this.form);
    },
    /**
     * 禁用日期判断
     * @param {moment} current 当前日期
     * @return {boolean} 是否禁用
     */
    disabledDate(current) {
      // console.log("current:", current);
      let result = false;
      // 日期小于当前日期, 禁用
      if (
        current &&
        current <
          moment()
            .startOf("day")
            .subtract(10, "days")
      ) {
        result = true;
      } else if (
        this.type === "add" ||
        (this.type === "edit" && this.currentItem.activityStatus === 1)
      ) {
        // 新增时, 编辑活动未开始时 选择了开始时间,限制可选时间为90天
        result =
          this.selectedStartDate &&
          (current < moment(this.selectedStartDate).startOf("day") ||
            current >
              moment(this.selectedStartDate)
                .add("89", "days")
                .endOf("day"));
      } else if (this.returnDisabled) {
        result =
          current < moment(this.form.timeArr[0]).startOf("day") ||
          current >
            moment(this.form.timeArr[0])
              .add("89", "days")
              .endOf("day");
      } else {
        result = false;
      }
      return result;
    },
    /**
     * 选择日期
     * @param {date} current 当前日期
     */
    selectDate(current) {
      if (!this.disabledDate(current))
        this.selectedStartDate = this.selectedStartDate ? null : current;
    },
    /**
     * 选择日期完成
     * @param {array[date,date]} value 选择的时间段
     */
    timeChange(value) {
      // console.log("timeChange", value[1].diff(value[0], "day"));
      // console.log(this.type);
      // console.log(this.currentItem);
      // 编辑 && 进行中 时判断逻辑 -- todo
      if (this.type == "edit" && this.currentItem.activityStatus == 2) {
        // 1. 开始时间 需要相等
        if (value[0].format("YYYY-MM-DD") != this.currentItem.startTime) {
          this.form.timeArr[0] = moment(this.currentItem.startTime);
          // this.$message.warning("进行中的活动不可修改活动开始时间");
        }
        // 2. 结束时间 大于 当前时间
        if (
          value[1].format("X") <
          moment(moment().format("YYYY-MM-DD")).format("X")
        ) {
          this.form.timeArr[1] = moment();
          this.$message.warning("结束时间不能早于当天");
          return;
        }
      }
    },
    /**
     * 图片上传前,校验
     * @param {File} file 图片文件
     * @param {string} fileListName 图片文件列表名称
     * @return {boolen} 是否通过校验
     */
    beforeUploadImg(file, fileListName) {
      const isJpgOrPng =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isJpgOrPng) {
        this.$message.error("上传的图片格式只能为jpg/png/jpeg!");
      }
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error("图片格式不超过4MB!");
      }
      if (!(isJpgOrPng && isLt4M)) {
        setTimeout(() => {
          this.form[fileListName] = [];
        }, 200);
      }
      return isJpgOrPng && isLt4M;
    },
    /**
     * 自定义上传
     * @param {File} file 要上传的文件
     * @param {Function} onSuccess 成功回调函数
     * @param {Function} onProgress 进度回调函数
     * @param {string} title 标题
     * @param {string} fileListName 图片列表名称
     */
    async customRequest({ file, onSuccess, onProgress }, title, fileListName) {
      if (title === "活动封面图") {
        this.$refs["ImageCropperModal"]
          .openModalPromise(title, URL.createObjectURL(file), () => {
            this.form[fileListName] = [];
            this.$nextTick(() => {
              this.$refs[fileListName].click();
            });
          })
          .then(async (base64) => {
            this.form[fileListName][0].thumbUrl = base64;
            this.form[fileListName][0].url = base64;
            const uploadFile = base64ToFile(
              base64,
              `${new Date().getTime()}.png`
            );
            const formData = new FormData();
            formData.append(
              "business_code",
              this.upload_file_params.business_code
            );
            formData.append(
              "need_compress",
              this.upload_file_params.need_compress
            );
            formData.append("type", this.upload_file_params.type);
            formData.append("file", uploadFile);
            await this.normalUploadFile(uploadFile, onSuccess, formData, () => {
              this.form[fileListName][0].status = "error";
              this.form[fileListName][0].thumbUrl = "";
              this.form[fileListName][0].url = "";
            });
            onProgress({ percent: 100 });
          })
          .catch(() => {});
      } else {
        const formData = new FormData();
        formData.append("business_code", this.upload_file_params.business_code);
        formData.append("need_compress", this.upload_file_params.need_compress);
        formData.append("type", this.upload_file_params.type);
        formData.append("file", file);
        await this.normalUploadFile(file, onSuccess, formData, () => {
          this.form[fileListName][0].status = "error";
          this.form[fileListName][0].thumbUrl = "";
          this.form[fileListName][0].url = "";
        });
        onProgress({ percent: 100 });
      }
    },
    /**
     * 文件改变
     * @param {File} fileList 文件列表
     * @param {string} fileListName 对应的文件列表名称
     */
    handleFileChange({ fileList }, fileListName) {
      this.form[fileListName] = fileList;
    },
    /**
     * 预览
     * @param {File} file 预览的文件
     */
    async handlePreview(file) {
      if (!file.originUrl && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.originUrl || file.preview;
      this.previewVisible = true;
    },
    /**
     * 成员选择改变
     * @param {string} val 改变后的值
     */
    formRadioChange(val) {
      // console.log("val:", val);
      this.form.scopeId = undefined;
      this.form.scopeIds = [];
      this.$refs.ruleForm.clearValidate(["scopeId", "scopeIds"]);
      this.form.scope === 1 && (this.form.scopeId = getPrincipalId());
    },

    /**
     * 筛选
     * @param {string} input 输入的值
     * @param {object} option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    /**
     * 选择限制
     * @param {Array} value 已选择数组
     * @param {number} limitNumber 限制长度
     * @param {stirng} arrName 数组名称
     * @param {string} tips 提示语
     */
    selectLimit(value, limitNumber, arrName, tips) {
      if (value && value.length > limitNumber) {
        this.form[arrName].pop();
        this.$message.warning(tips);
      }
    },

    /**
     * 选择平台
     * @return 事件
     */
    handlePlatformChange(e) {
      if (e.target.checked) {
        this.form.checkData.push(e.target.value);
      } else {
        this.form.checkData = this.form.checkData.filter(
          (item) => item !== e.target.value
        );
      }
    },

    /**
     * 选择参与条件
     */
    handleCheckDataChange(e) {
      if (e.target.checked) {
        this.form.platform.push(e.target.value);
      } else {
        this.form.platform = this.form.platform.filter(
          (item) => item !== e.target.value
        );
      }
    },

    /**
     * 打开示例对话框
     * @param {string} checkData 类型 1视频 2直播 3小红书
     */
    openDemoModal(checkData) {
      let keywordRelation = "";
      const type = checkData;
      switch (type) {
        case "1":
          keywordRelation = this.form.videoKeywordRelation;
          break;
        case "2":
          keywordRelation = this.form.liveKeywordRelation;
          break;
        case "3":
          keywordRelation = this.form.noteKeywordRelation;
          break;
        default:
          break;
      }
      this.$refs.DemoModal.openModal(checkData, keywordRelation);
    },
    /**
     * 显示二维码
     */
    showQrCode() {
      setTimeout(() => {
        const text = `activityPreview?id=${this.form.id}&name=${this.form.name}`;
        if (this.qrcodeInstance) {
          this.qrcodeInstance.clear();
          this.qrcodeInstance.makeCode(text);
        } else {
          this.qrcodeInstance = new QRCode(this.$refs.qrcode, {
            text: text,
            width: 120,
            height: 120,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }
      }, 600);
    },
    /**
     * 创建/修改活动请求
     * @param {object} body 请求参数
     */
    saveActivity(body) {
      this.isSaving = true;
      const saveApi =
        this.form.status === 3 ? api.createActivity : api.editActivity;
      const tips = this.type === "add" ? "提交" : "编辑";
      saveApi(body)
        .then((res) => {
          if (res.data.code === 200) {
            if (this.form.status === 3) {
              this.form.id = res.data.data;
              setTimeout(() => {
                this.qrcodeShow = true;
                this.showQrCode();
              }, 0);
            } else {
              this.$message.success(`${tips}成功`);
              this.onClose(false);
              this.$emit(
                "callback",
                this.type === "add" ? res.data.data : false
              );
            }
          } else {
            this.$message.error(res.data.message || `${tips}失败`);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    /**
     * 下一步
     */
    nextStep(status) {
      this.form.status = status;
      this.$refs.ruleForm.validate((valid) => {
        // 图片上传检查
        if (
          this.currentStep === 0 &&
          ((this.form.fileList1.length > 0 &&
            (!this.form.fileList1[0].response ||
              !this.form.fileList1[0].response.url)) ||
            (this.form.fileList2.length > 0 &&
              (!this.form.fileList2[0].response ||
                !this.form.fileList2[0].response.url)))
        ) {
          this.$message.error("图片未上传完成");
          return false;
        }
        if (!valid) {
          // this.$message.error("校验不通过,无法提交");
          return;
        }
        // 非最后一页，跳转下一页
        if (this.currentStep !== 2) {
          this.currentStep++;
        } else {
          // 最后一页提交表单
          // 处理参数
          let body = {
            id: this.type === "add" ? this.form.id : this.currentItem.id,
            activityName: this.form.name,
            // 未来版本
            // startTime:
            //   moment(this.form.timeArr[0]).format("YYYY-MM-DD HH") + ":00:00",
            // endTime:
            //   moment(this.form.timeArr[1]).format("YYYY-MM-DD HH") + ":59:59",
            startTime:
              moment(this.form.timeArr[0]).format("YYYY-MM-DD") + " 00:00:00",
            endTime:
              moment(this.form.timeArr[1]).format("YYYY-MM-DD") + " 23:59:59",
            scope: this.form.scope,
            scopeId:
              this.form.scope <= 3 ? [this.form.scopeId] : this.form.scopeIds,
            platform: this.form.platform.join(","),
            statisticsRange: this.form.checkData.join(","),
            cover:
              this.form.fileList1.length > 0
                ? this.form.fileList1[0].response.url
                : "https://fhl-ai-video.afanticar.com/image/activity-cover/acrivity-info-covor2.png",
            introductionImage:
              this.form.fileList2.length > 0
                ? this.form.fileList2[0].response.url
                : "",
            principalId: getPrincipalId(),
            status: this.form.status,
            appIsShow: this.form.appIsShow ? 1 : 0,
          };
          // 勾选视频
          if (this.form.checkData.indexOf("1") >= 0) {
            body.videoKeyTypes = this.form.videoKeyTypes.toString();
            body.videoKeywordRelation = this.form.videoKeywordRelation;
            body.videoKeyword = this.form.video_value_arr.join(",");
            body.videoSeriesKeywordId = this.form.video_series_arr.join(",");
            body.videoCreateSource = this.form.videoCreateSource;
            body.awemeTarget = this.form.awemeTarget || 0;
          } else {
            body.videoKeyTypes = "";
            body.videoKeywordRelation = "";
            body.videoKeyword = "";
            body.videoSeriesKeywordId = "";
            body.videoCreateSource = 0;
            body.awemeTarget = 0;
          }
          // 勾选直播
          if (this.form.checkData.indexOf("2") >= 0) {
            body.liveKeywordRelation = this.form.liveKeywordRelation;
            body.liveKeyword = this.form.live_value_arr.join(",");
            body.liveSeriesKeywordId = this.form.live_series_arr.join(",");
            body.liveTargetType = this.form.liveTargetType;
            body.liveTarget = this.form.liveTarget || 0;
          } else {
            body.liveKeywordRelation = "";
            body.liveKeyword = "";
            body.liveSeriesKeywordId = "";
            body.liveTargetType = "";
            body.liveTarget = 0;
          }
          // 勾选小红书
          if (this.form.checkData.indexOf("3") >= 0) {
            body.noteContentTypes = this.form.noteContentTypes.join(",");
            body.noteKeyTypes = this.form.noteKeyTypes.join(",");
            body.noteKeywordRelation = this.form.noteKeywordRelation;
            body.noteKeyword = this.form.note_value_arr.join(",");
            body.noteTextTarget = this.form.noteTextTarget || 0;
            body.noteImageTarget =
              this.form.noteContentTypes.indexOf("1") >= 0
                ? this.form.noteImageTarget
                : 0;
          } else {
            body.noteContentTypes = "";
            body.noteKeyTypes = "";
            body.noteKeywordRelation = "";
            body.noteKeyword = "";
            body.noteTextTarget = "";
            body.noteImageTarget = "";
          }

          body.videoTopic = [];

          // console.log("this.currentItem", this.currentItem);
          // console.log("body", body);
          this.saveActivity(body);
        }
      });
    },
  },
  created() {
    this.rules.platform.push({
      validator: (_, __, callback) => {
        if (
          (this.form.checkData.indexOf("1") >= 0 ||
            this.form.checkData.indexOf("2") >= 0 ||
            this.form.checkData.indexOf("4") >= 0) &&
          this.form.platform.indexOf("1") < 0 &&
          this.form.platform.indexOf("2") < 0 &&
          this.form.platform.indexOf("4") < 0
        ) {
          callback(new Error("请至少选择抖音或者快手或者视频号平台"));
        } else {
          callback();
        }
      },
    });
    this.rules.checkData.push({
      validator: (_, __, callback) => {
        if (
          (this.form.platform.indexOf("1") >= 0 ||
            this.form.platform.indexOf("2") >= 0) &&
          this.form.checkData.indexOf("1") < 0 &&
          this.form.checkData.indexOf("2") < 0
        ) {
          callback(new Error("请至少选择视频或者直播参与条件"));
        } else {
          callback();
        }
      },
    });
    this.rules.note_value_arr.push({
      validator: (_, __, callback) => {
        if (
          this.form.checkData.indexOf("3") >= 0 &&
          this.form.noteKeyTypes.length < 1
        ) {
          callback(new Error("请选择笔记内容类型"));
        } else {
          callback();
        }
      },
      trigger: "change",
    });
    this.rules.noteContentTypes.push({
      validator: (_, __, callback) => {
        if (
          this.form.checkData.indexOf("3") >= 0 &&
          this.form.noteContentTypes.length < 1
        ) {
          callback(new Error("请至少选择一个笔记类型"));
        } else {
          callback();
        }
      },
      trigger: "change",
    });
    this.rules.video_value_arr.push({
      validator: (_, __, callback) => {
        if (
          this.form.checkData.indexOf("1") >= 0 &&
          this.form.videoKeyTypes.length < 1
        ) {
          callback(new Error("请选择视频类型"));
        } else {
          callback();
        }
      },
      trigger: "change",
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

::v-deep .ant-drawer-body {
  padding: 0;
}

// 修改默认的steps样式
::v-deep .ant-steps-item-finish .ant-steps-item-icon {
  background: rgba(255, 204, 85, 0.2);
  border-color: transparent;
  //   border: 1px solid rgba(255, 204, 85, 0.2);
}

::v-deep .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffcc55;
}

::v-deep
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
::v-deep
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.88);
}

::v-deep .ant-steps-item-process .ant-steps-item-icon {
  background: #ffcc55;
  border-color: transparent;
}

::v-deep .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #000000;
}

::v-deep .ant-steps-item-wait .ant-steps-item-icon {
  background: #333333;
  border-color: transparent;
}

::v-deep .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(255, 255, 255, 0.55);
}

::v-deep
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.55);
}

::v-deep
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
::v-deep
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
::v-deep
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgba(255, 255, 255, 0.2);
}

::v-deep .ant-upload-list-item-uploading-text {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

::v-deep .ant-upload-list-item-progress {
  margin-left: 4px;
}

::v-deep
  .ant-upload-list-picture-card
  .ant-upload-list-item-uploading.ant-upload-list-item {
  background: none;
}

::v-deep .ant-select {
  display: block !important;
}

::v-deep .ant-card {
  background: transparent;
  margin-bottom: 16px;
}

::v-deep .ant-card-bordered {
  border-color: #2b2f3b;
  border-radius: 8px;
}

::v-deep .ant-card-body {
  background: #1a1d26;
  padding-bottom: 0;
  border-radius: 8px;
}

.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;

  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);

    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }

  .steps {
    height: 72px;
    padding: 24px;
  }

  .drawer-main {
    width: 100%;
    height: calc(100% - 188px);
    padding: 0 24px;
    overflow-y: auto;

    .count {
      color: #8890a6;
      position: absolute;
      right: 16px;
    }

    .aft-upload {
      margin-bottom: -24px;
    }

    .upload-label {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.45);
    }

    .drawer-tips {
      width: 100%;
      height: 40px;
      background: #1e2b48;
      border-radius: 2px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      margin-top: 24px;

      .icon {
        color: #3c7eff;
        margin: 0 8px 0 17px;
      }
    }

    .scope-wrap {
      position: relative;

      .radio-wrap {
        position: absolute;
        top: 29px;
        left: 0;
        z-index: 1;
      }

      .select-single {
        height: 40px;
      }

      .tips {
        color: rgba(255, 255, 255, 0.55);
      }

      /deep/ .ant-form {
        &-item {
          &-control-wrapper {
            margin-top: 40px;
          }
        }
      }
    }

    .rule-title {
      font-size: 14px;
      color: #ffffff;
      padding-bottom: 24px;
    }
  }

  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      margin-left: 8px;

      &.btn1 {
        background: #2b2f3b;
        border-color: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }

      &.btn2 {
        background: #ffca5b;
        border-color: #ffca5b;
        color: #000000;
      }
    }
  }
}

.qrcode-box {
  width: 128px;

  .title {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.88);
    line-height: 20px;
    text-align: center;
    margin: 8px;
  }

  .qrcode {
    width: 120px;
    height: 120px;
    margin: 0px 4px 8px 4px;
  }
}

.demo-btn {
  margin-left: 8px;
  color: #ffca5b;
  cursor: pointer;
  font-size: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.compact {
  display: flex;

  .symbol {
    color: rgba(255, 255, 255, 0.88);
    margin-right: 8px;
  }

  &-left {
    margin-right: 8px;
    width: 160px;
    display: flex;
    align-items: center;
  }

  &-right {
    flex: 1;
    display: flex;
    align-items: center;
  }

  /deep/ .compact-select ul {
    font-size: 0;
    .ant-select-selection__choice__content {
      font-size: 14px;
    }
  }
  .aft-input-txt {
    padding-left: 11px;
    width: 100%;
    height: 40px;
    background-color: #2b2f3b !important;
    color: rgba(255, 255, 255, 0.85) !important;
    border-radius: 8px;
    pointer-events: none;
    cursor: not-allowed;
    span {
      color: #ffca5b;
    }
  }
}

.warning-tip {
  margin-left: 16px;
  color: #ff9026;
}

.upload-box {
  position: relative;
  display: table;
  .aft-upload {
    position: relative;
    z-index: 10;
    &.has-default {
      opacity: 0;
    }
    &.has-file {
      opacity: 1;
    }
  }
  .default-cover-box {
    position: absolute;
    width: 104px;
    height: 104px;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    border: 1px dashed rgba(255, 255, 255, 0.2);
    &.hide-box {
      display: none;
    }
    .cover {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-image: url('https://fhl-ai-video.afanticar.com/image/activity-cover/acrivity-info-covor2.png');
    }
    .txt {
      padding: 6px 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      text-align: center;
      color: rgba(255, 255, 255, 0.88);
      line-height: 1;
    }
    .edit {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .9);
      font-size: 14px;
      color: rgba(255, 255, 255, 0.88);
      opacity: 0;
      transition: opacity .2s;
    }
  }
  &:hover .edit {
    opacity: 1;
  }
}
</style>
