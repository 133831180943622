<template>
  <a-tooltip placement="top" :overlayStyle="tooltipStyle">
    <template slot="title">
      <span
        ><slot>{{ title }}</slot></span
      >
    </template>
    <a-icon
      type="exclamation-circle"
      class="tooltip-icon"
      :style="defaultStyle"
    />
  </a-tooltip>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    title: {
      type: String,
    },
    tooltipStyle: {
      type: Object,
      default: () => {},
    },
    defaultStyle: {
      type: Object,
      default: () => {
        return {
          margin: "0 10px",
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.tooltip-icon {
  @include font_color("font_color22");
  cursor: pointer;
}
.tooltip-icon:hover {
  @include font_color("font_color26");
}
</style>
