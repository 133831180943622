import echarts from 'echarts'
var option = {
color:['#22C5E6','#7A91FE','#FAA056'],
legend:{
    bottom: 20,
    textStyle: {
        color: '#828282'
    },
},
tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b}: ({d}%)",
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
        color: '#fff',
    },
},
grid: {
    left: "10%",
    right: "10%",
    bottom: "20%",
    containLabel: false
},
series: [
    {
        name: "性别分布",
        type: "pie",
        radius: ["0%", "50%"],
        avoidLabelOverlap: false,
        label: {
            normal:{
            position: "outside",
            alignTo: "none",
            bleedMargin: 1,
            // formatter: '{b} {d}% '
            }

        },
        emphasis: {
            label: {
            show: true,
            fontSize: "12",
            fontWeight: "bold"
            }
        },
        // itemStyle: {
        //     borderWidth: 10,
        //     borderColor: '#fff'
        // },
        // data: [
        //     {
        //     value: this.pieData.gender.percentage[0],
        //     name: this.pieData.gender.desc[0]
        //     },
        //     {
        //     value: this.pieData.gender.percentage[1],
        //     name: this.pieData.gender.desc[1]
        //     }
        // ]
    }
]
}

export default option