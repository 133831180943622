<template>
  <div class="fixed-container">
    <LiveAssistantHeader v-if="liveDetail" :liveDetail="liveDetail" />
    <div class="main-container">
      <div class="main-container-left">
        <LiveDetailCardNew v-if="liveDetail" :liveDetail="liveDetail" ref="LiveDetailCardNew" />
      </div>
      <div class="main-container-right">
        <AssistantChart @openLiveByTime="openLiveByTime" />
      </div>
    </div>
  </div>
</template>

<script>
import LiveAssistantHeader from "./components/LiveAssistantHeader";
import LiveDetailCardNew from "./components/LiveDetailCardNew";
import AssistantChart from "./components/AssistantChart";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers("globalDetail");
export default {
  name: "liveAssistant",
  components: {
    LiveAssistantHeader,
    LiveDetailCardNew,
    AssistantChart,
  },
  data() {
    return {
      spinning: false,
    };
  },

  created() {
    this.changeState({ data: {}, name: "liveDetail" });
  },
  async mounted() {
    console.log("liveDetail", this.liveDetail);

    if (this.$route.query.id) {
      this.platform = this.$route.query.platform;
      let params = {
        room_id: this.$route.query.id,
        platform: this.$route.query.platform,
      };
      this.spinning = true;
      if (this.$route.query.platform == "douyin") {
        await Promise.all([
          this.getLiveDetail(params),
          // this.getReviewAssistant(params),
          // this.getReviewAssistantOcean(params),
        ]);
      } else {
        await Promise.all([
          this.getLiveDetail(params),
          // this.getReviewAssistant(params),
        ]);
      }
      this.spinning = false;
      this.$nextTick(() => {
        // this.initChart();
      });
    } else {
      console.log("没有获取到直播间信息");
    }
  },
  watch: {},
  computed: {
    ...mapState({
      liveDetail: (state) => state.liveDetail,
    }),
  },
  methods: {
    ...mapActions(["getLiveDetail"]),
    ...mapMutations(["changeState"]),
    openLiveByTime(time) {
      this.$refs.LiveDetailCardNew.openLive(this.liveDetail, time)
    }
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.fixed-container {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: #151615;
}
.main-container {
  width: 100%;
  height: 1067px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 20px 0 0;
  padding-bottom: 20px;
  &-left {
    height: 1047px;
    width: 300px;
  }
  &-right {
    height: 1047px;
    width: calc(100% - 320px);
  }
}
</style>