<template>
  <div class="container" style="position: relative;">
    <div class="title">门店收藏详情</div>
    <div class="tools">
      <div class="f16 font-color2">
        <a-icon type="left" style="margin-right: 52px" @click="back"/>
        <span>{{ name }}</span>
      </div>
      <div>
        <span class="f14 font-color3">分组编号：</span>
        <span class="f14 font-color5">{{ group_code }}</span>
      </div>
    </div>
    <div class="content">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :expanded-row-keys.sync="expandedRowKeys"
        :pagination="false"
        :scroll="{y: 'calc(100vh - 300px)'}"
        :rowKey="(data) => data.dealer_id || data.authorId"
        :customRow="rowClick"
        :rowClassName="rowClassName"
        :expandIconColumnIndex="-1"
        :locale="locale"
      >
        <div slot="actionTitle" style="display: flex; align-items: center">
          <div>操作</div>
        </div>
        <div slot="customTitle" style="display: flex">
          <div style="line-height: 32px; margin-right: 12px">门店名称</div>
          <div>
            <a-input-search
              placeholder="请输入门店全名或关键字"
              v-model.trim="search_val"
              @pressEnter="getList(true)"
              @search="getList(true)"
            />
          </div>
        </div>
        <div
          slot="num"
          slot-scope="text, records, index"
          class="font-color3 f14"
          style="padding: 17px 0"
        >
          <span v-if="!records.authorId">{{
            (current - 1) * pageSize + Number(index) + 1
          }}</span>
        </div>
        <div slot="storeName" slot-scope="scope" class="font-color2 f14">
          <span v-if="!scope.authorId">{{ scope.dealer_name || '-' }}</span>
          <div v-else class="table-name">
            <img :src="scope.avatar || defultAvatar" width="20" alt="" />
            <span>{{ scope.authorName || '-' }}</span>
            <!-- <svg-icon icon-class="attestation" class="f14" v-if="scope.accountType == '11'" />
            <svg-icon icon-class="md-account_circle" class="font-color9 f14" v-if="scope.accountType == '12'" /> -->

            <img
              src="@/assets/img/type-lanv.png"
              v-if="scope.accountType == 11"
              width="20px"
              style="width: 20px; height: 20px; margin-right: 4px"
              alt=""
            />
            <img
              src="@/assets/img/type-ordinary.png"
              v-else
              width="20"
              style="margin-right: 4px"
              alt=""
            />
            <!-- <svg-icon icon-class="fans" /> -->
          </div>
        </div>
        <div slot="storeCode" slot-scope="scope" class="font-color3 f14">
          <span v-if="!scope.authorId">{{ scope.store_code || '-' }}</span>
        </div>
        <div slot="provice" slot-scope="scope" class="font-color3 f14">
          <span v-if="!scope.authorId">{{ scope.province_name || '-' }}</span>
        </div>
        <div slot="city" slot-scope="scope" class="font-color3 f14">
          <span v-if="!scope.authorId">{{ scope.city_name || '-' }}</span>
        </div>
        <div slot="accountCount" slot-scope="scope" class="font-color3 f14">
          <span v-if="!scope.authorId">{{
            scope.account_count | changeNum
          }}</span>
        </div>
        <div slot="level" slot-scope="scope" class="font-color3 f14">
          <span :class="scope.authorLevel == 'S' ? 'font-color9' : scope.authorLevel == 'A' ? 'font-color5' : ''">{{ scope.authorLevel || "-" }}</span>
        </div>
        <div
          slot="platform"
          slot-scope="scope"
          :class="scope.authorId ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.platform == 1 ? '抖音' : scope.platform == 2 ? '快手' : scope.platform == 3 ? '懂车帝' : "-" }}</span>
        </div>
        <div
          slot="video"
          slot-scope="scope"
          :class="scope.authorId ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.awemeCount | changeNum }}</span>
        </div>
        <div
          slot="live"
          slot-scope="scope"
          :class="scope.authorId ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.liveCount | changeNum }}</span>
        </div>
        <div
          slot="diggCount"
          slot-scope="scope"
          :class="scope.authorId ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.likeCount | changeNum }}</span>
        </div>
        <div
          slot="fansCount"
          slot-scope="scope"
          :class="scope.authorId ? 'font-color2 f14' : 'font-color3 f14'"
        >
          <span>{{ scope.fansCount | changeNum }}</span>
        </div>
        <div slot="action" slot-scope="scope" class="font-color3 f14">
          <div class="table-action" v-if="!scope.authorId">
            <a-icon
              type="star"
              class="font-color6"
              theme="filled"
              @click.stop="starClick(scope.dealer_id)"
            />
            <a-icon
              type="down"
              :rotate="currentRow == scope ? 180 : 0"
              style="margin-left: 10px"
            />
          </div>
          <div
            v-else
            class="font-color4 f14"
            style="padding: 15px 0"
            @click="routerTo(scope)"
          >
            查看
          </div>
        </div>
      </a-table>
    </div>

    <div class="page-div">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
    <!-- 收藏门店弹窗 -->
    <div class="store-modal" v-show="storeModalShow" @click="hideAllModal">
      <div class="store-modal-container" @click.stop>
        <div class="store-modal-container-title">收藏门店</div>
        <div class="list">
          <multiple-group
            :multipleData="multipleData"
            @multipleChange="multipleChange"
          ></multiple-group>
        </div>
        <div class="action-div">
          <div @click="showAddGroup"><span class="icon">+</span> 新建分组</div>
          <span class="btn btn1" @click="closeModal">取消</span>
          <span class="btn btn2" @click="groupBindDealer">确定</span>
        </div>
      </div>
    </div>
    <!-- 新建分组弹窗 -->
    <div class="add-modal" v-show="addModalShow" @click="addModalShow = false">
      <div class="add-modal-container" @click.stop>
        <a-input v-model.trim="addGroupValue" placeholder="输入新建分组名称" />
        <div class="action-div">
          <span :class="!!addGroupValue ? '' : 'active'" @click="addModalShow = false;"
            >取消</span
          >
          <span :class="!!addGroupValue ? 'active' : ''" @click="addGroupDealer"
            >确定</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import MultipleGroup from "@/components/MultipleGroup/MultipleGroup";
import _http from '@/api/storeCollectionDetail';
import Empty from "@/components/Empty/Empty";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "storeCollectionDetail"
);

export default {
  components: {
    MultipleGroup,
  },
  data() {
    return {
      defultAvatar: require('@/assets/img/defult-avatar.png'),
      name: "",
      group_code: "",
      search_val: "",
      locale: {
        emptyText: <Empty style="margin-top: 240px;" title="暂无数据" />,
      },
      columns: [
        {
          title: "序号",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "num" },
        },
        {
          // title: "门店名称",
          width: "22.9%",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "storeName" },
        },
        {
          title: "门店编码",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "storeCode" },
        },
        {
          title: "省份",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "provice" },
        },
        {
          title: "城市",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "city" },
        },
        {
          title: "账号数量",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "accountCount" },
        },
        {
          title: "门店分级",
          width: "7.6%",
          align: "left",
          scopedSlots: { customRender: "level" },
        },
        {
          title: "平台",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "platform" },
        },
        {
          title: "视频",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "video" },
        },
        // {
        //   title: "直播",
        //   width: "5%",
        //   align: "left",
        //   scopedSlots: { customRender: "live" },
        // },
        {
          title: "获赞",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "diggCount" },
        },
        {
          title: "粉丝",
          width: "5%",
          align: "left",
          scopedSlots: { customRender: "fansCount" },
        },
        {
          width: "6.3%",
          align: "right",
          slots: { title: 'actionTitle' },
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      // childrenData: [],
      expandedRowKeys: [],
      currentRow: {},
      current: 1,
      pageSize: 20,
      total: 0,
      currentIndex: 0,
      storeModalShow: false,
      addModalShow: false,
      addGroupValue: '',
    };
  },
  computed: {
    ...mapState({
      dealerData: (state) => state.dealerData,
      accountData: (state) => state.accountData,
      editDealerRes: (state) => state.editDealerRes,
      groupList: (state) => state.groupList,
      multipleData: (state) => state.multipleData,
    }),
  },
  created() {
    this.name = this.$route.query.name;
    this.group_code = this.$route.query.group_code;
    if (this.$route.query.name && this.$route.query.group_code) {
      this.getList(true);
    } else {
      this.$message.error("加载失败，请从新选择分组！");
    }
    this.getSortGroup();
  },
  mounted() {},
  watch: {
    dealerData(val) {
      this.tableData = val.list;
      this.total = val.pagination.total;
    },
    accountData(val) {
      this.tableData[this.currentIndex].children = val;
      this.expandedRowKeys = [this.currentRow.dealer_id];
    },
  },
  methods: {
    ...mapActions(["getDealerList", "getDealerAccount", "editDealerFavorite", "getSortGroup", "getDealerGroup"]),
    getList(isFirst) {
      if (isFirst) {
        this.current = 1
      }
      this.expandedRowKeys = []
      this.currentRow = {}
      let params = {
        group_code: this.group_code,
        current: this.current,
        page_size: this.pageSize,
      }
      if (this.search_val) {
        params.keyword = this.search_val
      }
      
      this.getDealerList(params);
    },
    pageChange(num) {
      console.log(num);
      this.getList();
    },
    multipleChange(index) {
      this.multipleData[index].favorite = this.multipleData[index].favorite?0:1;
    },
    // 关闭分组
    closeModal(){
      this.storeModalShow = false;
    },
    // 获取经销商相关分组
    starClick(id) {
      console.log("dealer_id", id);
      this.dealer_id = id
      let params = {
        dealer_id: id,
      };
      this.getDealerGroup({ params });
      this.storeModalShow = true;
    },
    hideAllModal() {
      this.storeModalShow = false;
      this.addModalShow = false;
    },
    // 展开添加
    showAddGroup() {
      this.addModalShow = true;
      this.addGroupValue = ''
    },
    // 添加分组
    addGroupDealer(){
      if (this.addGroupValue.length <= 0) {
        this.$message.warning('请输入分组名')
        return false
      }
      if(!!this.addGroupValue){
        let params = {
          new_or_copy:0,
          name:this.addGroupValue
        }
        this.addModalShow = false;

        _http.addShopGroup(params).then(res=>{
          if(res.data.code==0){
            this.$message.success('新增分组成功！')
            this.starClick(this.dealer_id)
          }else{
             this.$message.error('新增分组失败！')
          }
        })
      }else{
        this.addModalShow = false;
      }
    },
    // 确认绑定分组
    groupBindDealer(){
      let data = {
        dealer_id: this.dealer_id,
        group_list:this.multipleData
      }
      _http.addCollectGrounp(data).then(res=>{
          if(res.data.code==0){
            this.storeModalShow = false;
            this.$message.success('绑定分组成功！')
            this.getList(true)
          }else{
            this.$message.error('绑定分组失败！')
          }
      })
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            console.log(record, index);
            if (record.authorId) {
              return false;
            }
            if (record.account_count <= 0) {
              this.$message.warning("当前门店没有关联的账号");
              return false;
            }
            if (this.currentRow == record) {
              // 关闭当前行
              this.currentRow = {};
              // 清空子表格数据
              // this.childrenData = [];
              this.expandedRowKeys = [];
            } else {
              // 展开当前行
              this.currentRow = record;
              this.expandedRowKeys = [record.dealer_id];
              if (this.tableData[index].children) {
                console.log("已经查询过了");
                return false;
              }
              this.currentIndex = index;
              this.getDealerAccount({
                dealer_id: record.dealer_id,
              });
            }
          },
        },
      };
    },
    //table行class
    rowClassName(record, index) {
      let className = "";
      if (record == this.currentRow) {
        className = "rowActive";
      }
      return className;
    },
    // 返回门店收藏
    back() {
      this.$router.push({
        path: 'storeCollection'
      })
    },
    // 点击子表格跳转-todoD
    routerTo(obj) {
      this.$router.push({
        path: 'production',
        query: {
          type: obj.platform == 1 ? 'douyin' : obj.platform == 2 ? 'kuaishou' : obj.platform == 3 ? 'dongchedi' : '',
          author_id: obj.authorId
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include font_color("font_color2");
}
.tools {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.content {
  padding: 0 24px;
  height: calc(100vh - 250px);
  overflow-y: auto;
  .table-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      margin: 0 4px 0 6px;
      max-width: calc(100% - 40px);
      display: inline-block;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  .table-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.store-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 100;
  &-container {
    position: absolute;
    right: 24px;
    bottom: 115px;
    width: 272px;
    height: 412px;
    border-radius: 4px;
    @include background_color("background_color27");
    @include font_color("font_color2");
    font-size: 14px;
    &-title {
      font-size: 16px;
      padding: 24px 0 20px;
      text-align: center;
    }
    .list {
      padding: 0 32px;
      height: 280px;
      overflow-y: auto;
    }
    .action-div {
      padding: 16px 12px 16px 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      > div {
        cursor: pointer;
        height: 32px;
        line-height: 18px;
        @include font_color("font_color2");
        .icon {
          font-size: 30px;
          @include font_color("font_color4");
          position: relative;
          top: 3px;
        }
      }
      .btn {
        width: 60px;
        height: 32px;
        border-radius: 4px;
        @include background_color("background_color5");
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        cursor: pointer;
      }
      .btn1 {
        @include font_color("font_color3");
        margin: 0 8px 0 20px;
      }
      .btn2 {
        @include font_color("font_color1");
      }
    }
  }
}
.add-modal {
  position: absolute;
  right: 24px;
  bottom: 115px;
  width: 272px;
  height: 412px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  z-index: 110;
  &-container {
    position: absolute;
    bottom: 0;
    left: 0;
    @include background_color("background_color29");
    width: 100%;
    height: 116px;
    padding: 24px;
    .action-div {
      display: flex;
      justify-content: space-around;
      > span {
        padding: 16px;
        margin-top: 8px;
        cursor: pointer;
        @include font_color("font_color3");
      }
      > span.active {
        @include font_color("font_color2");
      }
    }
  }
}
.rowActive {
  @include background_color("background_color42");
}
.page-div {
  text-align: center;
  padding: 26px 0;
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color3 {
  @include font_color("font_color3");
}
.font-color4 {
  @include font_color("font_color4");
}
.font-color5 {
  @include font_color("font_color5");
}
.font-color6 {
  @include font_color("font_color6");
  font-size: 24px;
}
.font-color9 {
  @include font_color("font_color9");
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
</style>