<template>
  <div
    class="activity-card"
    :style="{
      width: size == 'small' ? '90px' : '140px',
      height: size == 'small' ? '64px' : '102px',
      padding: size == 'small' ? '10px 0 10px 14px' : '18px 0 17px 14px',
      background: background,
    }"
  >
    <div
      class="activity-card-title"
      :style="{ 'font-size': size == 'small' ? '12px' : '14px' }"
    >
      {{ title }}<AftTooltip v-if="code" :code="code" style="top: 0px; left: 4px" />
    </div>
    <div
      class="activity-card-number"
      :style="{ 'font-size': size == 'small' ? '18px' : '24px' }"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "activityCard",
  components: {},
  props: {
    title: {
      type: String,
      require: true,
    },
    size: {
      type: String,
      default: "default",
    },
    code: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "linear-gradient(180deg, #64FAFE 0%, #A5FDFF 100%)",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
@import "@/assets/styles/_handle.scss";
.activity-card {
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 16px;
  &-title {
  }
  &-number {
    font-weight: bold;
  }
}
.colorA {
  @include font_color("font_color8");
}
.colorB {
  @include font_color("font_color7");
}
.colorC {
  @include font_color("font_color9");
}
</style>