/** 
* tabData: 按钮组数据
* tabChange: 点击按钮触发的方法
* example: <platform-tab :tabData="tabData" @tabChange="tabChange"></platform-tab>
*/

<template>
  <div class="card-button-group-container">
    <a-button
      v-for="(item, index) in tabData"
      :key="'btn' + index"
      round
      :class="
        currentClick == item.value ? 'active-btn-class btn-class' : 'btn-class'
      "
      :style="defaultBtnStyle"
      @click="tabClick(item.value)"
    >
      <span class="label">{{ item.label }}</span>
    </a-button>
  </div>
</template>

<script>
export default {
  name: "CardButtonGroup",
  props: {
    tabData: {
      type: Array,
    },
    defaultBtnStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currentClick: this.tabData[0].value,
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    tabClick(value) {
      this.currentClick = value;
      this.$emit("tabChange", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.card-button-group-container {
  display: flex;
  align-items: center;
}
.btn-class.active-btn-class {
  background: #13141a;
  border: 1px solid #253255;
  color: #ffffff;
  font-weight: bold;
}
.btn-class {
  cursor: pointer;
  min-width: 86px;
  height: 32px;
  border: none;
  line-height: 32px;
  border-radius: 0px !important;
  background: #2b2f3b;
  color: #8890a6;
  .label {
    width: 100%;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    display: inline-block;
  }
}
.btn-class:first-child {
  border-radius: 4px 0 0 4px !important;
}
.btn-class:last-child {
  border-radius: 0 4px 4px 0 !important;
}
.btn-class:first-child.btn-class:last-child {
  border-radius: 4px 4px 4px 4px !important;
}

.tab-btn-wrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-class {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px !important;
    & + .btn-class {
      margin-left: 10px;
    }
    .icon {
      margin-right: 4px;
      display: inline-block;
      font-size: 24px;
      @include font_color("font_color23");
    }
    &.active-btn-class {
      .icon {
        @include font_color("font_color25");
      }
    }
  }
}
</style>
