<template>
  <div style="width: 100%; height: calc(100vh - 65px); overflow: auto">
    <div class="window-min-width">
      <div class="title-container">
        <span class="fc1 f18 fb">活动概况</span>
        <span class="fc6 f14" v-if="updateTime">{{ updateTime }} 更新</span>
      </div>
      <div class="tools-container">
        <div>
          <span
            class="status icon1"
            v-if="currentActivityItem.activityStatus == 2"
            >{{ returnTagText(currentActivityItem) }}</span
          >
          <span
            class="status icon2"
            v-else-if="currentActivityItem.activityStatus == 3"
            >已结束</span
          >
          <span class="status icon3" v-else>{{
            returnTagText(currentActivityItem)
          }}</span>
          <span class="fc3 f14 fb">{{
            currentActivityItem.activityName || "-"
          }}</span>
          <span class="fc7 f14" @click="switchActivity">
            切换活动 <svg-icon icon-class="arrow-down" />
          </span>
        </div>
        <div>
          <span
            class="fc7 f14"
            v-if="currentActivityItem.activityStatus == 3"
            @click="uploadWinnerPic"
          >
            上传获奖名单
          </span>
          <span class="fc7 f14" v-else @click="openTemplateDrawer">
            管理导拍模版
          </span>
          <!-- <span class="fc7 f14" @click="routerToByName('活动列表')">
            活动列表
          </span> -->
          <span
            class="fc7 f14"
            style="margin-left: 8px"
            @click="openReadDrawer"
          >
            详情
          </span>
        </div>
      </div>
      <div class="main-container">
        <div class="left-container">
          <div>
            <a-select
              class="aft-select default"
              v-model="regionValue"
              placeholder="选择大区"
              style="width: 122px; margin-right: 8px"
              @change="regionChange"
            >
              <a-select-option v-if="isAllRegion" value="">
                不限大区
              </a-select-option>
              <a-select-option
                v-for="item in regionList || []"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>

          <!-- 数据趋势 -->
          <div class="left-wrap left-wrap1">
            <LeftWrap1
              ref="LeftWrap1"
              :currentItem="currentActivityItem"
              :loading="trendLoading"
              :trend-data="trendData"
              @tab-change="handleTrendTabChange"
            />
          </div>
          <!-- 任务 -->
          <div class="left-wrap left-wrap2">
            <LeftWrap2
              ref="LeftWrap2"
              :task-data="taskData"
              :is-all-region="isAllRegion"
            />
          </div>
          <!-- 区域进度 -->
          <div class="left-wrap left-wrap3">
            <LeftWrap3
              ref="LeftWrap3"
              :is-all-region="isAllRegion"
              :currentItem="currentActivityItem"
            />
          </div>
        </div>
        <div class="right-container">
          <!-- 实时大屏 -->
          <div class="right-wrap1" v-if="currentActivityItem.isLive === 1">
            <IconCard
              :icon="'ci-screen-y'"
              :background="'linear-gradient(90deg, #FFE674 0%, #FFC840 100%)'"
              :color="'rgba(0,0,0,0.88)'"
              :name="returnTagText2(currentActivityItem)"
              :value="'实时大屏'"
              :width="'100%'"
              @cardClick="routerToByName('直播大屏')"
              :showShadow="false"
            />
          </div>
          <!-- 活动榜单 -->
          <div class="right-wrap2">
            <RightWrap2
              ref="RightWrap2"
              :current-item="currentActivityItem"
              :region-id="regionValue"
            />
          </div>
        </div>
      </div>
      <div class="bottom-container">
        <BottomWrap
          ref="BottomWrap"
          :loading="dataLoading"
          :currentItem="currentActivityItem"
          :card-data="cardData"
        />
      </div>
    </div>

    <!-- <SwitchActivity
      :currentItem="currentActivityItem"
      @changeActivity="changeActivity"
      ref="SwitchActivity"
    /> -->

    <!-- 切换活动 -->
    <ActSelectDrawer
      :visible.sync="actSelectDrawerVisible"
      mode="switch"
      :default-selected-act-list="[currentActivityItem]"
      @switch="changeActivity"
    />

    <TemplateDrawer ref="TemplateDrawer" />

    <WinnerDrawer ref="WinnerDrawer" />

    <ReadDrawer ref="ReadDrawer" />
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import api from "@/api/activityOverview.js";
import ac_api from "@/api/marketingActivityList.js";
import IconCard from "@/components/IconCard/IconCard";
// import SwitchActivity from "../components/SwitchActivity";
import ActSelectDrawer from "@/components/ActSelectDrawer";
import TemplateDrawer from "../components/TemplateDrawer";
import WinnerDrawer from "../components/WinnerDrawer";
import ReadDrawer from "../components/ReadDrawer";
import LeftWrap1 from "./components/LeftWrap1";
import LeftWrap2 from "./components/LeftWrap2";
import LeftWrap3 from "./components/LeftWrap3";
import RightWrap2 from "./components/RightWrap2";
import BottomWrap from "./components/BottomWrap";
import deepClone from "@/utils/clone-utils";

const defaultCardData = [
  {
    title: "门店",
    icon: "ti-store",
    background: "linear-gradient(270deg, #7FDB1E 0%, #C5FF88 100%)",
    color: "#284608",
    cIcon: "ci-store-g",
    children: [
      // {
      //   name: "活动期间增粉",
      //   key: "addFansCount",
      //   value: "-"
      // },
      {
        name: "参与门店",
        key: "storeCount",
        value: "-",
        tooltipCode: "hdzb03",
      },
    ],
  },
  {
    title: "视频",
    icon: "ti-video",
    background: "linear-gradient(90deg, #B795FF 0%, #815CFF 100%)",
    color: "#2C1A6A",
    cIcon: "ci-video-p",
    children: [
      {
        name: "视频发布",
        key: "awemePublishCount",
        value: "-",
        tooltipCode: "",
      },
      {
        name: "视频播放",
        key: "awemeWatchCount",
        value: "-",
        tooltipCode: "",
      },
      {
        name: "视频互动",
        key: "awemeInteractCount",
        value: "-",
        tooltipCode: "",
      },
    ],
  },
  {
    title: "直播",
    icon: "ti-live",
    background: "linear-gradient(90deg, #F59264 0%, #E75934 100%)",
    color: "#461A0E",
    cIcon: "ci-live-r",
    children: [
      {
        name: "直播场次",
        key: "liveCount",
        value: "-",
        tooltipCode: "",
      },
      {
        name: "直播观看",
        key: "liveWatchCount",
        value: "-",
        tooltipCode: "",
      },
      {
        name: "直播互动",
        key: "liveInteractCount",
        value: "-",
        tooltipCode: "",
      },
    ],
  },
];

export default {
  name: "activityOverview",
  components: {
    PlatformHeader,
    MyChart,
    IconCard,
    // SwitchActivity,
    ActSelectDrawer,
    TemplateDrawer,
    WinnerDrawer,
    ReadDrawer,
    LeftWrap1,
    LeftWrap2,
    LeftWrap3,
    RightWrap2,
    BottomWrap,
  },
  data() {
    return {
      currentActivityItem: {},
      updateTime: "",
      regionList: [],
      isAllRegion: false,
      regionValue: "",
      trendLoading: false,
      trendActive: 3,
      trendData: [],
      taskData: {
        taskCompletionRate: "",
        dealerParticipationRate: "",
        taskInfoVOList: [],
      },
      dataLoading: false,
      cardData: [],
      actSelectDrawerVisible: false,
    };
  },
  created() {
    // 判断页面来源
    if (this.$route.query.aId) {
      this.currentActivityItem = {
        id: this.$route.query.aId,
        startTime: this.$route.query.startTime,
        endTime: this.$route.query.endTime,
        activityName: this.$route.query.aName,
        videoCreateSource: this.$route.query.videoCreateSource,
        activityStatus: Number(this.$route.query.activityStatus),
        isLive: Number(this.$route.query.isLive),
        isVideo: Number(this.$route.query.isVideo),
      };

      this.setTrendActive();
    }
  },
  async mounted() {
    const [result1] = await Promise.all([this.getActivityList()]);
    await this.setActivityList(result1);
    this.track("切换活动");
  },
  methods: {
    // 默认选择活动埋点
    track(action) {
      const trackData = {
        action,
        default: 1,
        activityId: this.currentActivityItem.id,
        activityName: this.currentActivityItem.activityName,
      };
      this.trackAction = "";
      this.$trackRequest(this.$route, this.$trackEvent.CLICK, trackData);
    },
    // 获取活动列表
    getActivityList() {
      let params = {
        page: 1,
        size: 1,
      };
      return ac_api.getActivityList(params);
    },
    async setActivityList(res) {
      if (res.data.code == 200 && res.data.data.list.length > 0) {
        if (!this.$route.query.aId) {
          this.currentActivityItem = res.data.data.list[0];
        }
        await this.getAreaInfoByActivity();
        this.initAllData();
      } else {
        this.currentActivityItem = {};
        this.$message.error("暂无活动");
      }
    },
    setTrendActive() {
      if (this.currentActivityItem.activityStatus === 3) {
        this.trendActive = 1;
      } else {
        this.trendActive = 3;
      }
    },
    //
    regionChange(val) {
      console.log(this.trendActiveTab);
      this.initAllData();
    },
    // 获取大区下拉列表
    async getAreaInfoByActivity() {
      try {
        const params = {
          activityId: this.currentActivityItem.id,
        };
        const res = await api.getAreaInfoByActivity(params);

        if (res.data.code == 200 && res.data.data.region_list.length > 0) {
          this.regionList = res.data.data.region_list || [];
          if (res.data.data.type === 10) {
            this.isAllRegion = true;
            this.regionValue = "";
          } else {
            this.regionValue = this.regionList[0].id;
          }
          // this.getActivityData();
        } else {
          this.regionList = [];
          this.$message.warning(res.data.message || "获取大区列表失败");
        }
      } catch (e) {
        console.error(e);
      }
    },
    initAllData() {
      this.getUpdateTime();
      // this.$refs.LeftWrap1.initData();
      this.getDataTrend();
      // this.$refs.LeftWrap2.initData();
      this.getTaskList();
      this.$refs.RightWrap2.initData();
      // this.$refs.BottomWrap.initData();
      this.getActivityData();
    },
    // 活动数据更新时间
    getUpdateTime() {
      let params = {
        activityId: this.currentActivityItem.id,
      };
      api.getUpdateTime(params).then((res) => {
        this.updateTime = res.data.data;
      });
    },
    handleTrendTabChange(val) {
      this.trendActive = val;
      this.getDataTrend();
    },
    // 获取数据趋势
    async getDataTrend() {
      this.trendLoading = true;
      try {
        const params = {
          activityId: this.currentActivityItem.id,
          regionId: this.regionValue,
        };
        const res = await api[`fetchDataTrend${this.trendActive}`](params);
        const { code, data, message } = res.data;

        this.trendLoading = false;

        if (code === 200) {
          this.trendActive === 1 && (this.trendData = data || []);
          this.trendActive === 2 && (this.trendData = data || []);
          this.trendActive === 3 &&
            (this.trendData = data?.dataTrendList || []);
        } else {
          this.$message.error(message);
          this.trendData = [];
        }
      } catch (e) {
        console.error(e);
        this.trendLoading = false;
        this.trendData = [];
      }
    },
    // 获取任务列表
    getTaskList() {
      let params = {
        activityId: this.currentActivityItem.id,
        regionId: this.regionValue,
      };
      // this.taskScroll("first");
      api.getTaskList(params).then((res) => {
        if (res.data.code === 200) {
          console.log("getTaskList", res.data.data);
          this.taskData = res.data.data;
        } else {
          this.$message.error(res.data.message || "获取任务列表失败");
          this.taskData = {
            taskCompletionRate: "",
            taskInfoVOList: [],
          };
        }
      });
    },
    // 获取活动数据
    getActivityData() {
      this.setCardData();

      this.dataLoading = true;
      const params = {
        activityId: this.currentActivityItem.id,
        // startTime: moment(this.dateValue[0]).format("YYYY-MM-DD"),
        // endTime: moment(this.dateValue[1]).format("YYYY-MM-DD"),
        regionId: this.regionValue,
      };
      api
        .getActivityData(params)
        .then((res) => {
          this.dataLoading = false;
          if (res.data.code === 200) {
            console.log("getActivityData", res.data.data);
            let data = res.data.data;
            // this.cardData[0].children[0].value = this.$options.filters.changeNum(data.addFansCount)
            this.cardData[0].children[0].value = this.$options.filters.changeNumNoRound(
              data.storeCount
            );

            if (this.currentActivityItem.isVideo === 1) {
              this.cardData[1].children[0].value = this.$options.filters.changeNumNoRound(
                data.awemePublishCount
              );
              this.cardData[1].children[1].value = this.$options.filters.changeNumNoRound(
                data.awemeWatchCount
              );
              this.cardData[1].children[2].value = this.$options.filters.changeNumNoRound(
                data.awemeInteractCount
              );
            }

            if (this.currentActivityItem.isLive === 1) {
              this.cardData[2].children[0].value = this.$options.filters.changeNumNoRound(
                data.liveCount
              );
              this.cardData[2].children[1].value = this.$options.filters.changeNumNoRound(
                data.liveWatchCount
              );
              this.cardData[2].children[2].value = this.$options.filters.changeNumNoRound(
                data.liveInteractCount
              );
            }
          } else {
            this.$message.error(res.data.message || "获取活动数据失败");
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    setCardData() {
      let cardData = deepClone(defaultCardData);

      // 过滤掉直播相关的
      if (this.currentActivityItem.isLive === 0) {
        cardData = cardData.filter((item) => !["直播"].includes(item.title));
      }

      // 过滤掉视频相关的
      if (this.currentActivityItem.isVideo === 0) {
        cardData = cardData.filter((item) => !["视频"].includes(item.title));
      }

      this.cardData = cardData;
    },
    // 切换活动按钮点击
    switchActivity() {
      // this.$refs.SwitchActivity.showDrawer();
      this.actSelectDrawerVisible = true;
    },
    // 切换活动触发
    async changeActivity(id, item) {
      this.currentActivityItem = item;
      this.setTrendActive();
      await this.getAreaInfoByActivity();
      this.initAllData();
    },
    // 管理导拍模版
    openTemplateDrawer() {
      this.$refs.TemplateDrawer.showDrawer(this.currentActivityItem);
    },
    // 上传获奖名单
    uploadWinnerPic() {
      this.$refs.WinnerDrawer.showDrawer(this.currentActivityItem);
    },
    // 查看详情
    openReadDrawer() {
      this.$refs.ReadDrawer.showDrawer(this.currentActivityItem);
    },
    returnTagText(item) {
      if (item.activityStatus == 2) {
        return `进行中`;
      } else if (item.activityStatus == 3) {
        return "已结束";
      } else {
        return `未开始`;
      }
    },
    returnTagText2(item) {
      if (item.activityStatus == 2) {
        return `正在直播`;
      } else if (item.activityStatus == 3) {
        return "活动已结束";
      } else {
        return `活动未开始`;
      }
    },
    routerToByName(name) {
      let url = "";
      switch (name) {
        case "直播大屏":
          url = "/marketing/activityScreenN";
          break;
        case "活动列表":
          url = "/marketing/nActivityList";
          break;
        case "任务中心":
          url = "/marketing/taskCenter";
          break;
        case "活动统计":
          url = "/marketing/activityStatistics";
          break;
        case "活动榜单":
          url = "/marketing/activityRanking";
          break;
        default:
          break;
      }
      if (name == "活动列表") {
        window.open(url);
        return false;
      }
      if (name == "直播大屏" && this.currentActivityItem.activityStatus != 2) {
        this.$message.warning("当前活动无法查看实时大屏");
        return false;
      }
      let startTime = moment(this.currentActivityItem.startTime).format(
        "YYYY-MM-DD"
      );
      let endTime = moment(this.currentActivityItem.endTime).format(
        "YYYY-MM-DD"
      );
      url = `${url}?aId=${
        this.currentActivityItem.id
      }&aName=${encodeURIComponent(
        this.currentActivityItem.activityName
      )}&startTime=${startTime}&endTime=${endTime}&activityStatus=${
        this.currentActivityItem.activityStatus
      }&videoCreateSource=${
        this.currentActivityItem.videoCreateSource
      }&isLive=${this.currentActivityItem.isLive}&isVideo=${
        this.currentActivityItem.isVideo
      }`;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.window-min-width {
  min-width: 1218px;
}
.title-container {
  height: 22px;
  padding: 0 20px;
  margin: 20px 0 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tools-container {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .status {
    padding: 0 8px;
    height: 22px;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    display: inline-block;
    margin-right: 8px;
  }
  .icon1 {
    color: #27c346;
    background: rgba(39, 195, 70, 0.2);
  }
  .icon2 {
    color: #8890a6;
    background: #3a3e4a;
  }
  .icon3 {
    color: #ff9e3d;
    background: rgba(255, 158, 61, 0.2);
  }
  > div:last-child {
    display: flex;
    align-items: center;
    > span {
      margin-left: 32px;
    }
  }
}
.main-container {
  width: 100%;
  // height: 812px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .left-container {
    width: calc(100% - 296px);
    height: 100%;
    display: flex;
    flex-direction: column;

    .left-wrap {
      margin-top: 16px;
      width: 100%;
      margin-bottom: 15px;
      border-radius: 16px;
      background: #2b2f3b;
    }

    .left-wrap1 {
      margin-bottom: 0;
      width: 100%;
      height: 320px;
    }
    .left-wrap2 {
      margin-bottom: 0;
      height: 244px;
    }
    .left-wrap3 {
      margin-bottom: 0;
      height: 200px;
    }
  }
  .right-container {
    width: 280px;
    height: 100%;
    .right-wrap1 {
      margin: 0 0 16px;
      width: 100%;
      height: 72px;
      background: #2b2f3b;
      border-radius: 16px;
      cursor: pointer;
    }
    .right-wrap2 {
      width: 100%;
      height: 706px;

      background: #2b2f3b;
      border-radius: 16px;
      overflow: hidden;
    }
  }
}
.bottom-container {
  margin-top: 16px;
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
  height: 398px;
}

// 公用字体样式
.fb {
  font-weight: bold;
}
.fc1 {
  color: #fff;
}
.fc2 {
  color: #8890a6;
}
.fc3 {
  color: rgba(255, 255, 255, 0.88);
}
.fc4 {
  color: rgba(255, 255, 255, 0.85);
}
.fc5 {
  color: rgba(255, 255, 255, 0.7);
}
.fc6 {
  color: rgba(255, 255, 255, 0.55);
}
.fc7 {
  color: #ffcc55;
  cursor: pointer;
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f18 {
  font-size: 18px;
}
.f20 {
  font-size: 20px;
}
</style>
