<template>
  <div>
    <!-- 数据看板 -->
    <div class="wrap">
      <div class="flex-between mb20">
        <div class="item-center">
          <IconTitle :icon="'ti-board'" :title="'数据看板'" :type="1" />

          <a-select
            v-model="region_id"
            :suffixIcon="suffixIcon"
            style="width: 132px; height: 40px; margin: 0 10px 0 20px"
            placeholder="选择大区"
            @change="selectChange('area')"
          >
            <a-select-option
              :value="item.id"
              v-for="item in areaList"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-select
            style="width: 192px; height: 40px; margin-right: 10px"
            v-model="group_code"
            :suffixIcon="suffixIcon"
            @change="selectChange('group')"
            placeholder="选择分组"
          >
            <a-select-option value="">不限分组</a-select-option>
            <a-select-option v-for="d in groupList" :key="d.group_code">
              {{ d.group_name }}
            </a-select-option>
          </a-select>
        </div>

        <date-tab-all
          :type="dateType"
          :weekDisabledDate="weekDisabledDate"
          @changeType="changeType"
          @changeDate="changeDate"
          ref="dateTab"
        ></date-tab-all>
      </div>
      <div class="box-main board-main">
        <div v-for="item in cardData" :key="item.title">
          <IconTitle :icon="item.icon" :title="item.title" :type="2" />
          <div class="right">
            <AsyncCard
              v-for="ci in item.children"
              :ref="`ac-${ci.name}`"
              :key="ci.name"
              :background="item.background"
              :color="item.color"
              :icon="item.cIcon"
              :name="ci.name"
              :value="ci.value"
              :rightValue="ci.rightValue"
              :isOpen="dateType == 'day' ? false : ci.isOpen"
              :tooltipTitle="ci.tooltipTitle"
              :tooltipCode="ci.tooltipCode"
              :dateType="dateType"
              :dateString="getDateString()"
              @closeAllCard="closeAllCard"
            >
              <div v-if="ci.tooltipTitle">
                <div v-if="ci.name == '声量线索'">
                  用户私信 + 短视频、直播线索评论购车意向人数；
                  每日月增量累计去重
                </div>
                <div v-if="ci.name == '销售线索'">
                  私信、直播、短视频、主页、行业工具等渠道的(电话)留资数量；
                  每日月增量累计去重
                </div>
                <div v-if="ci.name == '有效直播场次'">
                  直播时长 ≥ 30min的直播场次；
                </div>
                <div v-if="ci.name == '直播观看人数'">
                  主播账号授权服务平台后，可查看对应直播观看人数；
                </div>
              </div>
            </AsyncCard>
          </div>
          <!-- 金琳让先隐藏 -->
          <!-- <div
            v-if="
              item.title == '直播' &&
              principalName == '北京现代' &&
              currentTab == 'douyin' &&
              region &&
              dateType == 'month' &&
              handleMonth &&
              groupTrue
            "
          >
            <div class="left-card">
              <span>{{ live_exposure_ucnt }}</span>
              <span style="position: relative; z-index: 100">
                <span
                  >直播曝光量
                  <a-tooltip overlayClassName="liveToolTip" placement="top">
                    <template #title>
                      <div style="width: 530px !important; overflow: hidden;"
                        >
                        <div >
                          <p>直播曝光量说明：</p>
                          <p>
                            1、数据仅包含在风火轮App内完成「线索数据」授权的抖音企业号的直播曝光量。
                          </p>
                          <p>2、未授权「线索数据」的抖音企业号数据不计入。</p>
                          <p>3、非抖音企业号的直播曝光量不计入。</p>
                          <p>
                            4、"直播曝光量"指标每月更新1次，当月数据预计在下月2号更新。
                          </p>
                        </div>
                      </div>
                    </template>
                    <svg-icon
                      :icon-class="'tooltip-icon2'"
                      class="tooltip-icon"
                    />
                  </a-tooltip>
                </span>
              </span>
            </div>
          </div> -->
        </div>
        <a-spin :spinning="spinning" class="spin" v-show="spinning"> </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("dealerOfOneself");
import AsyncCard from "@/components/AsyncCard/AsyncCard";
import IconTitle from "@/components/IconTitle/IconTitle";
import DateTabAll from "@/components/DateTab/DateTabAll";
import { getPrincipalName } from "@/utils/auth";
const cardData = [
  {
    title: "粉丝",
    icon: "ti-fans",
    background: "linear-gradient(270deg, #7FDB1E 0%, #C5FF88 100%)",
    color: "#284608",
    cIcon: "ci-fans-g",
    children: [
      {
        name: "粉丝总量",
        key: "fans_total",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        isOpen: false,
      },
      {
        name: "粉丝增量",
        key: "fans_increase",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        isOpen: false,
      },
    ],
  },
  {
    title: "视频",
    icon: "ti-video",
    background: "linear-gradient(90deg, #B795FF 0%, #815CFF 100%)",
    color: "#2C1A6A",
    cIcon: "ci-video-p",
    children: [
      {
        name: "视频发布",
        key: "aweme_count",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        isOpen: false,
      },
      {
        name: "视频播放量",
        key: "aweme_play_count",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        isOpen: false,
      },
      {
        name: "视频条均播放",
        key: "aweme_play_avg",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        isOpen: false,
      },
    ],
  },
  {
    title: "直播",
    icon: "ti-live",
    background: "linear-gradient(90deg, #F59264 0%, #E75934 100%)",
    color: "#461A0E",
    cIcon: "ci-live-r",
    children: [
      {
        name: "直播场次",
        key: "live_count",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        isOpen: false,
      },
      {
        name: "有效直播场次",
        key: "effective_live_count",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        tooltipTitle: true,
        isOpen: false,
      },
      {
        name: "直播观看人次",
        key: "live_watch_time",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        tooltipCode: "qjzb11",
        isOpen: false,
      },
      {
        name: "直播观看人数",
        key: "live_watch_count",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        tooltipTitle: true,
        tooltipCode: "qjzb11",
        isOpen: false,
      },
      {
        name: "直播场均观看",
        key: "live_watch_avg",
        value: "-",
        rightValue: ["-", "-", "-", "-"],
        isOpen: false,
      },
    ],
  },
  // 陈鹏让隐藏的,8-18
  // {
  //   title: "线索",
  //   icon: "ti-clue",
  //   background: "linear-gradient(270deg, #FFC840 0%, #FFE674 100%)",
  //   color: "#503E10",
  //   cIcon: "ci-clue-y",
  //   children: [
  //     {
  //       name: "声量线索",
  //       key: "voice_volume_cue_count",
  //       value: "-",
  //       rightValue: ["-", "-", "-", "-"],
  //       isOpen: false,
  //       tooltipTitle: true,
  //     },
  //     {
  //       name: "销售线索",
  //       key: "sales_cue_count",
  //       value: "-",
  //       rightValue: ["-", "-", "-", "-"],
  //       isOpen: false,
  //       tooltipTitle: true,
  //     },
  //   ],
  // },
];
export default {
  name: "BoardBox",
  components: {
    AsyncCard,
    IconTitle,
    DateTabAll,
  },
  data() {
    return {
      live_exposure_ucnt: "-",
      handleMonth: true,
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      principalName: "",
      currentTab: "",
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      spinning: false,
      group_code: undefined,
      region_id: "",
      currentSelectType: "area", //下拉框互斥标示
      dateType: "month",
      currentParams: {},
      cardData,
      isFirst: true,
      weekDisabledDate(current) {
        return current && current >= moment().startOf("isoWeek");
      },
    };
  },
  // created() {
  //   this.principalName = getPrincipalName();
  //   console.log(this.principalName,'99999')
  // },
  mounted() {
    this.$refs.dateTab.emitMonthDate();
    this.principalName = getPrincipalName();
  },
  watch: {},
  computed: {
    groupTrue() {
      return this.group_code == undefined || this.group_code == "";
    },
    region() {
      return this.region_id == undefined || this.region_id == "";
    },
    ...mapState({
      areaList: (state) => state.areaList, // 大区
      groupList: (state) => state.groupList, // 分组
      boardData: (state) => state.boardData, // 数据看板数据
    }),
  },
  created() {
    Promise.all([this.getAreaList(), this.getSortGroup()]).then(() => {
      if (!this.areaList && this.areaList.length == 0) {
        this.$message.warning("当前账号未绑定大区，请联系管理员");
        return false;
      }
      this.region_id = this.areaList[0].id;
      this.changeParams();
    });
  },
  methods: {
    ...mapActions(["getAreaList", "getSortGroup", "getBoard"]),
    ...mapMutations(["changeState"]),
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
    },
    changeParams() {
      let year, week, month, start_time, end_time;
      if (this.currentParams.type == "month") {
        year = this.currentParams.monthYear;
        month = this.currentParams.monthNumber;
      } else if (this.currentParams.type == "week") {
        year = this.currentParams.weekYear;
        week = this.currentParams.weekNumber;
      } else {
        start_time = this.currentParams.startTime;
        end_time = this.currentParams.endTime;
      }
      this.$emit("changeParams", {
        region_id: this.region_id,
        group_code: this.group_code,
        year,
        week,
        month,
        start_time,
        end_time,
      });
    },
    // 下拉框分组、大区切换
    selectChange(type) {
      console.log(type);
      if (type == "area") {
        this.currentSelectType = "area";
        this.group_code = undefined;
      } else {
        this.currentSelectType = "group";
        this.region_id = undefined;
      }
      this.changeParams();
    },

    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
    },
    // 日期切换
    changeDate(params) {
      if (this.isFirst) {
        this.isFirst = false;
        this.currentParams = params;
        return false;
      }
      console.log("changeDate", params);
      this.currentParams = params;
      console.log("this.currentParams", this.currentParams);
      this.changeParams();
    },
    // 获取数据看板数据
    async getBoardData(data) {
      console.log(data, "请求参数");
      this.currentTab = data.platform;
      let month = data.params.month;
      let temp = data.params.year;
      if (temp < this.currentYear) {
        this.handleMonth = true;
      } else if (month < this.currentMonth) {
        this.handleMonth = true;
      } else {
        this.handleMonth = false;
      }
      this.spinning = true;
      await this.getBoard(data);
      let chain = "_chain";
      let last = "_last_period";
      this.cardData.forEach((item) => {
        item.children.forEach((i) => {
          i.value = this.$options.filters.changeNum(this.boardData[i.key]);
          i.rightValue = [
            this.$options.filters.changeNum(this.boardData[i.key]),
            this.$options.filters.changeNum(this.boardData[i.key + last]),
            this.$options.filters.numRate(this.boardData[i.key + chain]),
            this.boardData[i.key + chain] && this.boardData[i.key + chain] < 0
              ? "-"
              : "+",
          ];
        });
      });
      this.spinning = false;
      this.live_exposure_ucnt = this.$options.filters.changeNum(
        this.boardData.live_exposure_ucnt
      );
      console.log(this.boardData, "数据看板数据");
    },
    // 关闭所有卡片
    closeAllCard(name) {
      console.log("name", name);
      this.cardData.forEach((item) => {
        item.children.forEach((ci) => {
          if (ci.name != name) {
            this.$refs["ac-" + ci.name].isOpen = false;
            this.$refs["ac-" + ci.name].cardOpen = false;
            ci.isOpen = false;
          } else {
            ci.isOpen = true;
          }
        });
      });
    },
    // 获取卡片日期展示
    getDateString() {
      if (this.dateType == "month") {
        return `${this.currentParams.monthYear}-${this.currentParams.monthNumber}月`;
      } else if (this.dateType == "week") {
        return `${this.currentParams.weekYear}-${this.currentParams.weekNumber}周`;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
// 公用
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.item-center {
  display: flex;
  align-items: center;
}
.mb20 {
  margin-bottom: 20px;
}
// 主体区域
.wrap {
  padding: 0 20px;
  .box-main {
    @include background_color("background_color80");
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 28px;
  }
  // 数据看板
  .board-main {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0 30px 36px;
    position: relative;
    > .spin {
      width: 100%;
      height: 100%;
      padding: 192px 0;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
    }
    > div {
      height: 72px;
      width: 100%;
      display: flex;
      align-items: center;
      .right {
        padding-left: 30px;
        display: flex;
        justify-content: flex-start;
        > div {
          margin-left: 12px;
        }
      }
      .left-card {
        border-radius: 8px;
        margin-left: 13px;
        background: linear-gradient(
          270deg,
          #1e222c 0%,
          #2a2d38 100%
        ) !important;
        width: 148px;
        min-width: 148px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 13px 0 14px 16px;
        font-weight: bold;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        > span:nth-child(1) {
          font-size: 18px;
          color: #fff;
        }
        > span:nth-child(2) {
          color: #9ca8ba;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          > span {
            font-size: 12px;
            opacity: 0.6;
          }
          .tooltip-icon {
            @include font_color("font_color50");
            @include background_color("background_color51");
            border-radius: 50%;
            cursor: pointer;
            font-size: 16px;
            position: relative;
            top: 1px;
          }
          .tooltip-icon:hover {
            @include font_color("font_color20");
            @include background_color("background_color83");
          }
          .tooltip-icon:active {
            @include font_color("font_color26");
            @include background_color("background_color84");
          }
        }
      }
    }
    
  }
}
</style>

<style lang="scss">
.liveToolTip{
  max-width :1000px;
  .ant-tooltip-inner{
    
    background: rgba(0, 0, 0, 0.2);
  }
}
</style>