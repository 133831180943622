<template>
  <div class="icon-title-container">
    <svg-icon :icon-class="icon" :class="`icon type${type}`" />
    <span :class="`type${type}`">{{ title }}</span>
    <!-- <CardTooltip class="tooltip-icon" v-if="tooltipTitle">
      <slot>{{ tooltipTitle }}</slot>
    </CardTooltip> -->
  </div>
</template>

<script>
// import CardTooltip from "@/components/Tooltip/CardTooltip";
export default {
  name: "IconTitle",
  components: {
    // CardTooltip,
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "-",
    },
    // 1: icon(16) title(16) margin(8)
    // 2: icon(22) title(14) margin(16)
    // 3: icon(22) title(14) margin(6)
    // 4: icon(22) title(16) margin(10)
    type: {
      type: Number,
      default: 1,
    },
    tooltipTitle: {
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

.icon-title-container {
  display: flex;
  align-items: center;
  span {
    @include font_color("font_color20");
  }

  .icon.type1 {
    font-size: 16px;
  }
  span.type1 {
    font-size: 16px;
    margin-left: 8px;
  }

  .icon.type2 {
    font-size: 22px;
  }
  span.type2 {
    font-size: 14px;
    margin-left: 16px;
  }
  
  .icon.type3 {
    font-size: 22px;
  }
  span.type3 {
    font-size: 14px;
    margin-left: 6px;
  }

  .icon.type4 {
    font-size: 22px;
  }
  span.type4 {
    font-size: 16px;
    margin-left: 10px;
  }
  
  .tooltip-icon {
    font-size: 16px;
    color: #9CA8BA;
  }
}
</style>
