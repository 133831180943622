<template>
  <div class="tabs-content1">
    <div class="title-div">
      <span class="title">核心指标</span>
      <NewTabs2 :tabData="tabsData" @tabChange="changeTabs" />
    </div>
    <div class="data-box">
      <div class="data-box-item">
        <span class="label">销售线索</span>
        <span class="value">{{ overviewData.sales_summary | changeNum }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">昨日新增线索</span>
        <span class="value">{{ overviewData.increased | changeNum }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">总流量</span>
        <span class="value">{{ overviewData.flow_value | changeNum }}</span>
      </div>
      <div class="data-box-item">
        <span class="label">线索转化率</span>
        <span class="value">{{ overviewData.conversion_rate | numRate3 }}</span>
      </div>
    </div>

    <div class="chart-div">
      <div class="chart-div-left">
        <MyChart
          v-if="chartShow"
          :options="getPieOption(pieData)"
          :id="'pie-chart-1'"
        />
      </div>
      <div class="chart-div-right">
        <MyChart
          v-if="chartShow"
          :options="getBarOption(barData.xData, barData.yData)"
          :id="'bar-chart1'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewTabs2 from "@/components/PlatformTab/NewTabs2";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartPie3 from "../../options/chartPie3.js";
import chartBar4 from "../../options/chartBar4.js";
import api from "@/api/clueMarket.js";
export default {
  name: "TabsContent1",
  components: { NewTabs2, MyChart },
  data() {
    return {
      chartShow: true,
      tabsData: [
        {
          label: "按日",
          value: "1",
        },
        {
          label: "按月",
          value: "2",
        },
      ],
      tabsKey: "1",
      pieData: [],
      barData: {
        xData: [],
        yData: [],
      },
      overviewData: {},
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getClueOverview();
    },
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
      this.getComponentData();
    },
    getPieOption(data) {
      let option = deepClone(chartPie3);
      let total = data.reduce((sum, val) => {
        return sum + (val.value || 0);
      }, 0);
      option.title[0].text = this.$options.filters.changeNum(total);
      option.series[0].data = data;
      return option;
    },
    getBarOption(xData, yData) {
      let option = deepClone(chartBar4);
      option.xAxis.data = xData;
      option.series[0].data = yData;
      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
          is_month: this.tabsKey == "2" ? true : false,
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getClueOverview() {
      let data = this.getParentsParams();
      api.getClueOverview(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          this.pieData = data.regions
            ? data.regions.map((item) => {
                return {
                  value: item.sales_count || 0,
                  name: item.area_name,
                };
              })
            : [];
          this.barData = {
            xData: data.nodes
              ? data.nodes.map((item) => {
                  return item.date;
                })
              : [],
            yData: data.nodes
              ? data.nodes.map((item) => {
                  return item.sales_count;
                })
              : [],
          };
          this.overviewData = data;
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content1 {
  width: 100%;
  height: 400px;
  background: #2b2f3b;
  border-radius: 16px;
  .title-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    padding: 0 20px;
    > .title {
      font-size: 14px;
      color: #ffffff;
    }
  }
  .data-box {
    width: 100%;
    height: 54px;
    padding: 0 20px;
    display: flex;
    &-item {
      width: 160px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .label {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.45);
        line-height: 22px;
      }
      .value {
        font-size: 24px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 32px;
      }
    }
  }
  .chart-div {
    width: 100%;
    height: 250px;
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    &-left {
      width: 40%;
      height: 100%;
    }
    &-right {
      width: 60%;
      height: 100%;
    }
  }
}
</style>
