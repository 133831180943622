<template>
  <div class="order_wrap">
    <span
      v-for="(item, idx) in orderList"
      :key="item.value"
      @click="clickItem(item, idx)"
      :class="[checkIdx === idx ? 'checked' : '']"
      >{{ item.text }}</span
    >
  </div>
</template>
<script>
import _ from "lodash";
export default {
  props: {
    orderList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    orderIndex: {
      type: Number,
      default: undefined
    }
  },
  watch: {
    orderIndex: {
      handler: function(val) {
        if(!isNaN(val)) {
          this.checkIdx = val
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      checkIdx: 0,
    };
  },
  methods: {
    clickItem(item, idx) {
      let data = _.clone(item);
      this.checkIdx = idx
      // 因此，我们推荐你始终使用 kebab-case 的事件名。
      this.$emit("order-click", { data, index: idx });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_handle.scss';
.order_wrap {
  position: relative;
  height: 40px;
  @include font_color("font_color22");
  line-height: 40px;
  > span {
    height: 40px;
    border-radius: 8px;
    padding: 0 18px;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    @include background_color("background_color2");
    position: relative;
    transition: all 0.3s ease-in;
    &:first-child:hover {
      @include font_color("font_color1");
      @include background_color("background_color59");
      font-weight: bold;
    }
    &:hover {
      @include font_color("font_color25");
      @include background_color("background_color13");
      font-weight: bold;
    }
  }

  >span:first-child {
    margin-right: 20px;
  }
  >span:first-child.checked {
    @include font_color("font_color1");
    @include background_color("background_color59");
    font-weight: bold;
  }
  >span.checked {
    @include font_color("font_color25");
    @include background_color("background_color13");
    font-weight: bold;
  }
}
</style>

