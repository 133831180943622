<template>
  <div class="fans-portrait-container">
    <div class="row" v-if="portraitData.interest_distributions">
      <div class="row-item">
        <div class="row-item-title">兴趣分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(portraitData.interest_distributions, '1')"
            :id="'bar-chart-1'"
          />
        </div>
      </div>
      <div class="row-item">
        <div class="row-item-title">年龄层分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(portraitData.age_distributions)"
            :id="'pie-chart-1'"
          />
        </div>
      </div>
    </div>
    <div
      class="row"
      :style="{
        'grid-template-columns': `repeat(${
          $route.query.type == 'douyin' ? 3 : 2
        }, 1fr)`
      }"
      v-if="portraitData.interest_distributions"
    >
      <div class="row-item" v-if="$route.query.type == 'douyin'">
        <div class="row-item-title">活跃度分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(portraitData.active_days_distributions)"
            :id="'pie-chart-2'"
          />
        </div>
      </div>
      <div class="row-item">
        <div class="row-item-title">性别分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getPieOption(portraitData.gender_distributions)"
            :id="'pie-chart-3'"
          />
        </div>
      </div>
      <div class="row-item">
        <div class="row-item-title">Top10 省份分布</div>
        <div class="row-item-chart">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(portraitData.province_distributions.slice(0, 10), '2')"
            :id="'bar-chart-2'"
          />
        </div>
      </div>
    </div>
    <div class="empty-container" v-else>
      <Empty />
    </div>
  </div>
</template>

<script>
import api from "@/api/production";
import Empty from "@/components/Empty/Empty";
import MyChart from "@/components/MyChart/MyChart";
import chartPie from "../options/chartPie.js";
import chartBar1 from "../options/chartBar1.js";
import chartBar2 from "../options/chartBar2.js";
import deepClone from "@/utils/clone-utils";

export default {
  components: { MyChart, Empty },
  data() {
    return {
      chartShow: true,
      portraitData: {}
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    // 加载当前页面接口
    initData() {
      this.getFansPortrait();
    },

    // 获取粉丝画像
    getFansPortrait() {
      let data = {
        type: this.$route.query.type,
        params: {
          author_id: this.$route.query.author_id
        }
      };
      api.getFansPortrait(data).then(res => {
        console.log("getFansPortrait", res);
        const { code, data } = res.data;
        if (code == 0) {
          this.portraitData = data || {};
        } else {
          this.portraitData = {};
        }
      });
    },

    getBarOption(data, type) {
      let option = deepClone(type == "1" ? chartBar1 : chartBar2);
      if (data && data.length > 0) {
        let xData = data.map(item => {
          return item.item;
        });
        let sData = data.map(item => {
          return item.value;
        });
        if (type == "1") {
          option.xAxis[0].data = xData;
          option.series[0].data = sData;
        } else {
          option.yAxis[0].data = xData.reverse();
          option.series[0].data = sData.reverse();
        }
      }
      return option;
    },
    getPieOption(data) {
      let option = deepClone(chartPie);
      if (data && data.length > 0) {
        let newData = data.map(item => {
          return {
            name: item.item,
            value: item.value
          };
        });
        option.series[0].data = newData;
      }
      return option;
    },

    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.fans-portrait-container {
  width: 100%;
  height: 760px;
  margin-bottom: 20px;
  .row {
    width: 100%;
    height: 372px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    &-item {
      background: #2b2f3b;
      border-radius: 16px;
      padding-top: 20px;
      height: 100%;
      &-title {
        padding: 0 20px;
        width: 100%;
        height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
        line-height: 24px;
      }
      &-chart {
        width: 100%;
        height: calc(100% - 24px);
      }
    }
  }
  .empty-container {
    width: 100%;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>