<template>
  <div class="tabs-content2">
    <div class="tabs-content2-main">
      <NewTabs
        :tabData="tabsData"
        @tabChange="changeTabs"
        :labelStyle="{ lineHeight: '44px' }"
      />
      <div class="tabs-main" v-if="statisticsData[tabsKey]">
        <div class="tabs-main-left">
          <div class="font1" style="padding-top: 40px">挂载率</div>
          <div class="font2" style="margin-bottom: 8px">{{statisticsData[tabsKey].mount_rate | numRate}}</div>
          <div class="flex">
            <span class="font1" style="margin-right: 8px">较昨日</span>
            <span class="font3" style="margin-right: 4px">{{statisticsData[tabsKey].day_chain | numRate}}</span>
            <svg-icon icon-class="caret-up" v-if="statisticsData[tabsKey].day_chain > 0" />
            <svg-icon icon-class="caret-down" v-if="statisticsData[tabsKey].day_chain < 0" />
            <span class="tag2" v-if="statisticsData[tabsKey].mount_rate && statisticsData[tabsKey].mount_rate > 0.8">健康</span>
            <span class="tag1" v-else>偏低</span>
          </div>
          <div class="data-list">
            <div class="data-list-item">
              <span class="font1">采集线索</span>
              <span class="font2">{{ statisticsData[tabsKey].gather_clues_count | changeNum }}</span>
            </div>
            <div class="data-list-item">
              <span class="font1">覆盖流量</span>
              <span class="font2">{{ statisticsData[tabsKey].overwrite_traffic | changeNum }}</span>
            </div>
            <div class="data-list-item">
              <span class="font1">未覆盖流量</span>
              <span class="font2">{{ statisticsData[tabsKey].not_overwrite_traffic | changeNum }}</span>
            </div>
          </div>
        </div>
        <div class="tabs-main-right">
          <MyChart
            v-if="chartShow"
            :options="getBarOption(chartData.proportion_x, chartData.proportion_y)"
            :id="'bar-chart1'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewTabs from "@/components/PlatformTab/NewTabs";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import chartBar from "../../options/chartBar.js";
import chartPie from "../../options/chartPie.js";
import api from "@/api/componentsMarket.js";
export default {
  name: "TabsContent2",
  components: { NewTabs, MyChart },
  data() {
    return {
      chartShow: true,
      tabsData: [
        {
          label: "直播组件",
          value: "live_component",
        },
        {
          label: "视频组件",
          value: "aweme_component",
        },
      ],
      tabsKey: "live_component",
      chartData: {},
      statisticsData: {
        live_component: {},
        aweme_component: {}
      }
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    getComponentData() {
      this.getComponentV1();
      this.getComponentV2();
    },
    changeTabs(val) {
      if (this.tabsKey == val) {
        return false;
      }
      this.tabsKey = val;
    },
    getBarOption(xData, yData) {
      let option = deepClone(chartBar);
      option.xAxis.data = xData;
      option.series[0].data = yData;
      return option;
    },
    getPieOption(data) {
      let option = deepClone(chartPie);
      option.series[0].data = data;
      return option;
    },
    getParentsParams() {
      let data = {
        platform: this.$parent.currentTab,
        params: {
          area_id: this.$parent.area_id.key || "",
        },
      };
      // 按活动
      if (this.$parent.toolsTabsKey == "1") {
        data.params.activity_id = this.$parent.activity_value;
      }
      // 按时间
      if (this.$parent.toolsTabsKey == "2") {
        data.params.start_time = this.$parent.begin_time;
        data.params.end_time = this.$parent.end_time;
      }
      return data;
    },
    getComponentV1() {
      let data = this.getParentsParams();
      api.getComponentV1(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          this.statisticsData = data
        }
      });
    },
    getComponentV2() {
      let data = this.getParentsParams();
      api.getComponentV2(data).then((res) => {
        const { data, code } = res.data;
        if (code == 0) {
          this.chartData = data
        }
      });
    },
    resizeChart() {
      this.chartShow = false;
      setTimeout(() => {
        this.chartShow = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tabs-content2 {
  width: 100%;
  height: 340px;
  &-main {
    width: 100%;
    height: 100%;
    background: #2b2f3b;
    border-radius: 16px;
    padding: 8px 20px 0;
    .tabs-main {
      width: 100%;
      height: 282px;
      display: flex;
      flex-wrap: nowrap;
      &-left {
        width: 320px;
        height: 100%;
        .font1 {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.45);
          line-height: 22px;
        }
        .font2 {
          font-size: 24px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 32px;
        }
        .font3 {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
        }
        .flex {
          display: flex;
          align-items: center;
        }
        .tag1 {
          width: 40px;
          height: 20px;
          background: rgba(255, 158, 61, 0.3);
          border-radius: 4px;
          font-size: 12px;
          color: #ff9e3d;
          line-height: 20px;
          text-align: center;
          margin-left: 8px;
        }
        .tag2 {
          width: 40px;
          height: 20px;
          background: rgba(39,195,70,0.3);
          border-radius: 4px;
          font-size: 12px;
          color: #27C346;
          line-height: 20px;
          text-align: center;
          margin-left: 8px;
        }
        .data-list {
          margin-top: 33px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
      &-right {
        width: calc(100% - 320px);
        height: 100%;
      }
    }
  }
}
</style>
