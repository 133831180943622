import echarts from 'echarts'
var option = {
  grid: {
    left: "5%",
    right: "7%",
    bottom: "5%",
    top: "5%",
    containLabel: true,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: '#ddd'
      }
    },
    backgroundColor: 'rgba(37, 50, 85, 0.9)',
    textStyle: {
        color: '#fff',
    },
    padding: [5, 10],
    extraCssText: 'box-shadow: 0 0 5px rgba(0,0,0,0.3)'
  },

  xAxis: [{
    show: true,
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: '#828282',
      }
    },
    splitArea: {
      // show: true,
      color: '#828282',
      lineStyle: {
        color: '#828282'
      },
    },
    axisLabel: {
      color: '#828282'
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: [],

  }],
  yAxis: [{
    type: 'value',
    min: 0,
    // max: 140,
    splitNumber: 4,
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)'
      }
    },
    axisLine: {
      show: true,
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#828282',
      },
    },
    axisTick: {
      show: false,
    },
  }],
  series: [{
    name: '',
    type: 'line',
    smooth: true,

    symbol: 'circle',
    symbolSize: 10,
    data: [],
    areaStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(205,52,42, 0.5)'
        }, {
          offset: 0.8,
          color: 'rgba(235,235,21, 0)'
        }], false),
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowBlur: 10
      },
    },
    itemStyle: {
      normal: {
        color: '#f7b851'
      }
    },

  }]
}

export default option