<template>
  <div class="table-item2">
    <div class="card-title">
      <svg-icon icon-class="list-title-left" class="title-icon" />
      <span>
        {{ list.title || "-" }}
      </span>
      <svg-icon icon-class="list-title-right" class="title-icon" />
    </div>
    <div class="table-body" v-if="list.data && list.data.length">
      <div
        class="table-body-item"
        v-for="(item, index) in list.data || []"
        :key="list.key + index"
        @click.stop="openPage(item)"
      >
        <div class="table-body-item-number">
          <span :class="['ranking', `ranking${index + 1}`]">{{
            index + 1
          }}</span>
        </div>
        <div class="table-body-item-left">
          <BaseImage
            :src="item.awemeCover || item.liveCover || ''"
            :default-image="defaultCoverImg"
            fit="contain"
            width="90px"
            height="120px"
            radius="4px"
            style="background-color: #21232c;"
          />
          <svg-icon
            class="platform-icon"
            :icon-class="
              item.platform == '抖音'
                ? 'platform-color-douyin'
                : 'platform-color-kuaishou'
            "
          />
        </div>
        <div class="table-body-item-right">
          <div class="title">
            {{ item.awemeTitle || item.liveTitle || "-" }}
          </div>
          <div class="author">
            <BaseImage
              :src="item.dealerAuthorAvatar || ''"
              :default-image="defaultAvatarImg"
              width="24px"
              height="24px"
              fit="cover"
              round
            />
            <span>{{ item.dealerName || "-" }}</span>
          </div>
          <div class="statistics">
            <span>{{ list.targetName || "-" }}</span>
            <span>{{ item[list.targetKey] | changeNumNoRound }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-box" v-else>
      <Empty title="暂无数据" />
    </div>
  </div>
</template>

<script>
import defaultAvatarImg from "@/assets/default/default1-1.png";
import defaultCoverImg from "@/assets/default/default3-4.png";
import BaseImage from "@/components/BaseImage";
import Empty from "@/components/Empty/Empty";
import globalApi from '@/api/globalDetail';

export default {
  name: "TableItem1",
  components: {
    BaseImage,
    Empty,
  },
  props: {
    list: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      defaultAvatarImg,
      defaultCoverImg
    };
  },
  methods: {
    async openPage(val) {
      let platform = 'douyin';
      val.platform === '抖音' && (platform = 'douyin');
      val.platform === '快手' && (platform = 'kuaishou');

      try {
        const params = { platform };
        val.roomId && (params.room_id = val.roomId);
        val.awemeId && (params.aweme_id = val.awemeId);
        const { code, data, msg } = await globalApi[val.roomId ? 'getLiveDetail' : 'getVideoDetail'](params);

        // 直播
        if (val.roomId) {
          if (code === 0) {
            const route = this.$router.resolve({
              path: this.list.routePath,
              query: {
                id: val.roomId,
                platform
              }  
            });

            window.open(route.href);
          } else {
            if (code === 5001) {
              this.$message.warning('暂无详情数据');
            } else {
              this.$message.error(msg);
            }
          }
        }

        // 视频
        if (val.awemeId) {
          if (code === 0) {
            if (data.aweme_id) {
              const route = this.$router.resolve({
                path: this.list.routePath,
                query: {
                  id: val.awemeId,
                  platform
                }  
              });

              window.open(route.href);
            } else {
              this.$message.warning('暂无详情数据');  
            }
          } else {
            this.$message.error(msg);  
          }
        }
      } catch(e) {
        console.error(e)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.table-item2 {
  width: 100%;
  .card-title {
    width: 100%;
    height: 72px;
    background: url("../../../../assets/live_images/list-title-bc.png")
        no-repeat right 0,
      linear-gradient(90deg, #ffe674 0%, #ffc840 100%);
    background-size: contain;
    padding-left: 30px;
    border-radius: 16px 16px 0 0;
    display: flex;
    align-items: center;
    > span {
      font-size: 24px;
      font-weight: bold;
      color: #845303;
      line-height: 32px;
    }
    .title-icon {
      font-size: 34px;
    }
  }
  .table-body {
    width: 100%;
    height: calc(100vh - 336px);
    border-radius: 0 0 16px 16px;
    overflow-y: auto;
    &-item {
      height: 144px;
      width: 100%;
      border-bottom: 1px solid #3c4252;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 42px 0 0;
      cursor: pointer;
      &:last-child {
        border: none;
      }
      &-number {
        width: 72px;
        text-align: center;
      }
      &-left {
        width: 90px;
        height: 120px;
        position: relative;
        .platform-icon {
          font-size: 20px;
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
      &-right {
        width: calc(100% - 178px);
        height: 120px;
        margin-left: 16px;
        position: relative;
        > .title {
          width: 100%;
          height: 44px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.88);
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .author {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.55);
          line-height: 22px;
          margin: 8px 0 0 0;
          > span {
            margin-left: 4px;
          }
        }
        .statistics {
          position: absolute;
          bottom: 0;
          right: 0;
          > span:first-child {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.55);
            line-height: 20px;
          }
          > span:last-child {
            font-size: 12px;
            font-weight: bold;
            color: #ffcc55;
            line-height: 20px;
            margin-left: 4px;
          }
        }
      }

      &:hover {
        background: #20232C;
      }
    }
  }
  .empty-box {
    width: 100%;
    height: calc(100vh - 336px);
    background: #2b2f3b;
    border-radius: 0 0 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ranking {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.12);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  &.ranking1,
  &.ranking2,
  &.ranking3 {
    background: rgba(255, 204, 85, 0.12);
    color: #ffcc55;
    font-weight: bold;
  }
}
</style>
