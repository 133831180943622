import { render, staticRenderFns } from "./NewButtonTab.vue?vue&type=template&id=465e1daa&scoped=true"
import script from "./NewButtonTab.vue?vue&type=script&lang=js"
export * from "./NewButtonTab.vue?vue&type=script&lang=js"
import style0 from "./NewButtonTab.vue?vue&type=style&index=0&id=465e1daa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465e1daa",
  null
  
)

/* custom blocks */
import block0 from "./NewButtonTab.vue?vue&type=custom&index=0&blockType=platform-tab&%3AtabData=tabData&%40tabChange=tabChange"
if (typeof block0 === 'function') block0(component)

export default component.exports