import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default{
    // ------ left wrap1 -------
    // 聚金塔极简版活动详情卡片
    getActivityOverview({
        platform
    }){
        return request({
            url: '/jjt/simple/board/activity/overview',
            method: 'get',
            baseURL: getBaseUrl(platform),
        })
    },
    // ------ left wrap2 -------
    // 聚金塔极简版看板趋势图
    getTrendChart({
        platform
    }){
        return request({
            url: '/jjt/simple/board/trend-chart',
            method: 'get',
            baseURL: getBaseUrl(platform),
        })
    },
    // 聚金塔极简版看板矩阵流量
    getMatrixData({
        platform
    }){
        return request({
            url: '/jjt/simple/board/matrix-throughput',
            method: 'get',
            baseURL: getBaseUrl(platform),
        })
    },
    // ------ left wrap3 -------
    // 聚金塔极简版看板活动列表
    getActivityList({
        platform
    }){
        return request({
            url: '/jjt/simple/board/activity/list',
            method: 'get',
            baseURL: getBaseUrl(platform),
        })
    },
    // ------ left wrap4 -------
    // 聚金塔极简版直播指标
    getLiveOnlineData({
        params,
        platform
    }){
        return request({
            url: '/jjt/simple/board/live/online/dealer-list',
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        })
    },
    // ------ right wrap1 -------
    // 聚金塔主体信息
    getPrincipalInfo({
        platform
    }){
        return request({
            url: '/jjt/simple/board/principal/simple-info',
            method: 'get',
            baseURL: getBaseUrl(platform),
        })
    },
    // 聚金塔极简版矩阵异动
    getMatrixNotice({
        platform
    }){
        return request({
            url: '/jjt/simple/board/matrix/monitor/notice',
            method: 'get',
            baseURL: getBaseUrl(platform),
        })
    },
    // ------ right wrap2 -------
    // 聚金塔极简版榜单
    getChampions({
        params,
        platform
    }){
        return request({
            url: '/jjt/simple/board/champions',
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        })
    },
}