<template>
  <div class="chart-modal" v-show="visible" @click="visible = false">
    <div class="chart-modal-container2" @click.stop>
      <div class="flex-between">
        <span class="title">城市统计</span>
        <a-icon class="icon" type="close" @click="visible = false" />
      </div>
      <a-spin :spinning="loading" class="list-container">
        <div style="height:100%" v-if="loading"></div>
        <div v-if="modalData && modalData.length > 0">
          <div
            class="list-item"
            v-for="(item, index) in modalData"
            :key="index"
          >
            <div class="label">{{ item.label }}</div>
            <div class="progress" :style="{ width: item.width }"></div>
            <div class="number">{{ item.number }}</div>
          </div>
        </div>
        <div
          class="empty-box"
          v-if="(!modalData || modalData.length === 0) && !loading"
        >
          <Empty
            style="margin-top: 100px;margin-bottom: 100px;"
            title="暂无数据"
          />
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import api from "@/api/closedLoop";
import Empty from "@/components/Empty/Empty";

export default {
  components: { Empty },
  data() {
    return {
      visible: false,
      loading: false,
      modalData: [],
    };
  },
  methods: {
    async openModal(data) {
      Object.assign(this.$data, this.$options.data());
      this.visible = true;
      this.loading = true;
      await this.getDetailByArea(data);
      this.loading = false;
    },
    getDataSum(arr) {
      return arr.reduce(function(total, item) {
        return total + (item.value || item.count);
      }, 0);
    },
    getProgressWidth(arr) {
      let max = Math.max(
        ...arr.map((item) => {
          return item.value || item.count;
        })
      );
      let sum = this.getDataSum(arr);
      this.modalData = arr.map((item) => {
        return {
          label: item.name || item.series_name,
          number: this.$options.filters.numRate2(
            (item.value || item.count) / sum
          ),
          width: (((item.value || item.count) / max) * 418).toFixed(2) + "px",
        };
      });
    },
    getDetailByArea(data) {
      return api.getDetailByArea(data).then((res) => {
        const { code, data } = res.data;
        if (code == 0) {
          this.getProgressWidth(data);
        } else {
          this.$message.warning("获取城市统计失败");
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";
.chart-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 100;
  &-container2 {
    position: absolute;
    border-radius: 8px;
    top: calc(50vh - 194px);
    left: calc(50% - 300px);
    background: #2b2f3b;
    width: 600px;
    height: 388px;
    padding: 20px 24px;
    @include box_shadow("box_shadow4");
    .flex-between {
      height: 24px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
      }
      .icon {
        font-size: 16px;
        color: #818388;
        cursor: pointer;
      }
    }
    .list-container {
      width: 100%;
      height: calc(100% - 44px);
      padding-right: 10px;
      overflow-y: auto;
      .list-item {
        height: 20px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .label {
          width: 80px;
          text-align: right;
          margin-right: 8px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.65);
        }
        .progress {
          width: 418px;
          height: 10px;
          background: #ffca5c;
          border-radius: 4px;
        }
        .number {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.88);
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
