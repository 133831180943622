<template>
  <div class="container">
    <platform-header
      title="门店统计"
      :currentTab="currentTab"
      :isAll="true"
      @tabChange="tabChange"
    />
    <!-- <div class="title">
      <div class="btn-wrap">
        <a-button class="button2" @click="exportClick">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div> -->
    <div class="tool-tab">
      <ButtonGroup :tab-data="tabList" @tabChange="handleTypeClick" />
      <RouterButton
        :title="'门店趋势统计'"
        :icon="'rb-regional-stores'"
        :path="'/dealer/storeTrend'"
      />
    </div>
    <div class="tools">
      <div>
        <a-select
          v-model="regionValue"
          :suffixIcon="suffixIcon"
          style="width: 126px; height: 36px; margin-right: 8px"
          placeholder="选择大区"
        >
          <a-select-option
            :value="item.id"
            v-for="item in areaData"
            :key="item.id"
            @click="regionClick(item)"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="provValue"
          :suffixIcon="suffixIcon"
          style="width: 112px; height: 36px; margin-right: 8px"
          placeholder="选择省份"
        >
          <a-select-option value="" @click="provClick(false)"
            >全部省份</a-select-option
          >
          <a-select-option
            :value="item.id"
            v-for="item in provData"
            :key="item.id"
            @click="provClick(item)"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="cityValue"
          :suffixIcon="suffixIcon"
          style="width: 112px; height: 36px; margin-right: 8px"
          placeholder="选择城市"
        >
          <a-select-option value="">全部城市</a-select-option>
          <a-select-option
            :value="item.id"
            v-for="item in cityData"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-input
          v-model.trim="word"
          style="width: 278px; margin-right: 8px"
          class="aft-input default"
          :placeholder="
            `搜索门店名、${
              tabType == '1' ? '公司名' : '账号昵称'
            }或门店编码关键字`
          "
        />
        <a-select
          v-model="groupValue"
          :suffixIcon="suffixIcon"
          style="width: 140px; height: 36px; margin-right: 8px"
          placeholder="选择分组"
        >
          <a-select-option value="">不限分组</a-select-option>
          <a-select-option
            :value="item.group_code"
            v-for="item in groupData"
            :key="item.group_code"
          >
            {{ item.group_name }}
          </a-select-option>
        </a-select>
        <a-button class="search-btn" @click="search(true)">确定</a-button>
      </div>
      <div class="btn-wrap">
        <ExcelTypeButton :type="excelType" @changeExcelType="changeExcelType" />
        <a-button v-hide-by-username class="button2" @click="exportClick">
          <svg-icon icon-class="export" class="btn-icon" />
          <span>Excel导出</span>
        </a-button>
      </div>
    </div>
    <div class="tools tools2">
      <div style="display: flex">
        <SwitchButton
          :type="switchButtonType"
          :title="'剔除历史视频'"
          :tooltip="'mtsp01'"
          @changeButtonType="changeButtonType"
        />
        <SwitchPopover
          v-show="switchButtonType"
          style="margin-left: 8px"
          :settingData="settingData"
          @colsePopover="changeSwitchPopover"
        />
      </div>
      <date-tab
        :type="dateType"
        :disableDays="1"
        @changeType="changeType"
        @changeDate="searchTimeTable"
        ref="dateTab"
      ></date-tab>
    </div>
    <div class="tools tools2" v-show="tabType == '0'">
      <a-radio-group
        v-show="tabType == '0'"
        v-model="searchForm.typeRadio"
        :options="radioOptions"
        @change="radioOnChange"
      />
    </div>

    <div class="content">
      <div :class="['table', tabType == '0' ? '' : 'long']">
        <a-table
          v-show="tableData && tableData.length > 0"
          :columns="columns"
          :data-source="tableData"
          :scroll="{
            x: '100%',
            y: `calc(100vh - ${tabType == '0' ? '502' : '454'}px)`,
          }"
          :rowKey="(data, index) => data.dealer_id + index"
          :pagination="false"
          :locale="locale"
          :loading="tableLoading"
          class="aft-table default x-scroll"
        >
          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            {{ (current - 1) * pageSize + Number(index) + 1 }}
          </div>
          <div slot="customTitle">
            <div v-if="tabType == '1'">门店/公司名</div>
            <div v-else>账号/门店名</div>
          </div>
          <div
            slot="watchCountTitle"
            style="display: flex; align-items: center"
          >
            <div>
              观看人数<AftTooltip
                :code="'qjzb11'"
                style="top: 2px; left: 4px"
              />
            </div>
          </div>
          <div slot="watchTimeTitle" style="display: flex; align-items: center">
            <div>
              观看人次<AftTooltip
                :code="'qjzb11'"
                style="top: 2px; left: 4px"
              />
            </div>
          </div>
          <div slot="addFansTitle" style="display: flex; align-items: center">
            <div>
              直播增粉
              <AftTooltip :code="'qjzb10'" style="top: 2px; left: 4px" />
            </div>
          </div>
          <div
            slot="awemeActivationTitle"
            style="display: flex; align-items: center"
          >
            <div>
              视频活跃度
              <AftTooltip :code="'bksp02'" style="top: 2px; left: 4px" />
            </div>
          </div>
          <div
            slot="liveActivationTitle"
            style="display: flex; align-items: center"
          >
            <div>
              直播活跃度
              <AftTooltip :code="'bkzb02'" style="top: 2px; left: 4px" />
            </div>
          </div>

          <div slot="tooltipTitle" style="display: flex; align-items: center">
            <div>视频播放</div>
            <!-- <AftTooltip :code="'mtsp01'" style="top: 0px; left: 4px" /> -->
          </div>
          <span slot="customAction" @click="actionClick">
            <a-tooltip>
              <template slot="title"> 列设置 </template>
              <a-icon type="setting" class="action-ico" theme="filled" />
            </a-tooltip>
          </span>
          <div slot="account" slot-scope="scope" class="table-account">
            <div class="table-account-left">
              <div>
                <div>
                  <a-tooltip v-if="tabType == '0'">
                    <template slot="title">{{
                      scope.nickname || "-"
                    }}</template>

                    <span class="font-color2 f16">
                      <img
                        v-if="scope.verification_type == 2"
                        src="@/assets/img/type-lanv.png"
                        width="20"
                        style="margin-right: 4px; margin-bottom: 2px"
                        alt=""
                      />
                      <img
                        v-else
                        src="@/assets/img/type-ordinary.png"
                        width="20"
                        style="margin-right: 4px; margin-bottom: 2px"
                        alt=""
                      />
                      {{ scope.nickname || "-" }}</span
                    >
                  </a-tooltip>

                  <a-tooltip v-else>
                    <template slot="title">{{
                      scope.dealer_name || "-"
                    }}</template>

                    <span class="font-color2 f16">
                      <img
                        src="@/assets/img/type-store.png"
                        width="20"
                        style="margin-right: 4px; margin-bottom: 2px"
                        alt=""
                      />
                      {{ scope.dealer_name || "-" }}</span
                    >
                  </a-tooltip>
                </div>
                <div>
                  <a-tooltip v-if="tabType == '0'">
                    <template slot="title">{{
                      scope.dealer_name || "-"
                    }}</template>

                    <span class="font-color3 f12">
                      <img
                        src="@/assets/img/type-store.png"
                        width="16"
                        style="margin: 0 6px 2px 2px"
                        alt=""
                      />
                      {{ scope.dealer_name || "-" }}
                    </span>
                  </a-tooltip>
                  <a-tooltip v-else>
                    <template slot="title">{{
                      scope.company_name || "-"
                    }}</template>

                    <span class="font-color3 f12">
                      <img
                        src="@/assets/img/type-company.png"
                        width="16"
                        style="margin: 0 6px 2px 2px"
                        alt=""
                      />
                      {{ scope.company_name || "-" }}
                    </span>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div slot="storeCode" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.store_code || "-" }}</span>
          </div>
          <div slot="fans_count" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.fans_count | changeNum }}</span>
          </div>
          <div
            slot="fans_count_total"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.fans_count_total | changeNum }}</span>
          </div>
          <div slot="fans_tt_count" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.fans_tt_count | changeNum }}</span>
          </div>
          <div slot="fans_hs_count" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.fans_hs_count | changeNum }}</span>
          </div>
          <div slot="aweme_count" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.aweme_count | changeNum }}</span>
          </div>
          <div
            slot="aweme_like_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.aweme_like_count | changeNum }}</span>
          </div>
          <div
            slot="aweme_comment_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.aweme_comment_count | changeNum }}</span>
          </div>
          <div
            slot="aweme_share_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.aweme_share_count | changeNum }}</span>
          </div>
          <div
            slot="aweme_play_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.aweme_play_count | changeNum }}</span>
          </div>
          <div
            slot="aweme_activation"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.aweme_activation | changeNum }}</span>
          </div>
          <div slot="live_count" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.live_count | changeNum }}</span>
          </div>
          <div slot="live_time" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.live_time | disposeLongTime_h }}</span>
          </div>
          <div
            slot="live_watch_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.live_watch_count | changeNum }}</span>
          </div>
          <div
            slot="live_watch_time"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.live_watch_time | changeNum }}</span>
          </div>
          <div
            slot="live_like_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.live_like_count | changeNum }}</span>
          </div>
          <div
            slot="live_comment_count"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.live_comment_count | changeNum }}</span>
          </div>
          <div
            slot="live_activation"
            slot-scope="scope"
            class="font-color3 f14"
          >
            <span>{{ scope.live_activation | changeNum }}</span>
          </div>
          <div slot="live_fans_add" slot-scope="scope" class="font-color3 f14">
            <span>{{ scope.live_fans_add | changeNum }}</span>
          </div>
          <div
            slot="action"
            slot-scope="scope"
            class="font-color3 f14"
            @click="routerDetail(scope)"
          >
            <span v-if="scope.author_id">查看</span>
          </div>
        </a-table>
        <Empty
          style="margin: 20vh"
          v-show="!tableData || tableData.length == 0"
        />
      </div>
      <div class="page-div aft-pagination">
        <a-pagination
          v-model="current"
          size="small"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
        />
      </div>
    </div>

    <!-- 收藏门店弹窗 -->
    <div class="setting-modal" v-show="settingModalShow" @click="settingClose">
      <div class="setting-modal-container" @click.stop>
        <div class="setting-modal-container-title">
          选择展示指标
          <!-- <span>(最多6个)</span> -->
        </div>
        <div class="list">
          <a-checkbox-group
            v-model="currentCheck"
            @change="tableChange"
            style="width: 100%"
          >
            <a-row>
              <a-col
                :span="8"
                v-for="(item, index) in tableSetting"
                :key="index"
                :style="{
                  'margin-bottom': '16px',
                  display:
                    (item.label == '头条粉丝' || item.label == '火山粉丝') &&
                    currentTab != 'douyin'
                      ? 'none'
                      : 'inline-block',
                }"
              >
                <!-- <a-checkbox :value="item.value" :checked="false" :disabled="true" v-if="(item.label == '头条粉丝' || item.label == '火山粉丝') && currentTab != 'douyin'">{{ item.label }}</a-checkbox> -->
                <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
        <div class="action-div">
          <span class="btn btn1" @click="settingClose">取消</span>
          <span class="btn btn2" @click="settingSure">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import _ from "lodash";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "storeCompetition"
);
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import DateTab from "@/components/DateTab/DateTab2";
import exportTable from "@/utils/exportTable";
import Empty from "@/components/Empty/Empty";
import ExcelTypeButton from "@/components/ExcelTypeButton/ExcelTypeButton";
import Tooltip from "@/components/Tooltip/Tooltip";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import RouterButton from "@/components/RouterButton/RouterButton";
import deepClone from "@/utils/clone-utils";
import SwitchPopover from "@/components/SwitchPopover/SwitchPopover";
import SwitchButton from "@/components/SwitchPopover/SwitchButton";
import tagApi from "@/api/illegalTag";

const tableSetting = [
  {
    value: "fans_count/粉丝增量/0",
    label: "粉丝增量",
  },
  {
    value: "fans_count_total/粉丝总量/1",
    label: "粉丝总量",
  },
  {
    value: "fans_tt_count/头条粉丝/2",
    label: "头条粉丝",
  },
  {
    value: "fans_hs_count/火山粉丝/3",
    label: "火山粉丝",
  },
  {
    value: "aweme_count/视频发布/4",
    label: "视频发布",
  },
  {
    value: "aweme_like_count/视频点赞/5",
    label: "视频点赞",
  },
  {
    value: "aweme_comment_count/视频评论/6",
    label: "视频评论",
  },
  {
    value: "aweme_share_count/视频转发/7",
    label: "视频转发",
  },
  {
    value: "aweme_play_count/视频播放/8",
    label: "视频播放",
  },
  {
    value: "aweme_activation/视频活跃度/9",
    label: "视频活跃度",
  },
  {
    value: "live_count/直播场次/10",
    label: "直播场次",
  },
  {
    value: "live_time/直播时长/11",
    label: "直播时长",
  },
  {
    value: "live_watch_count/观看人数/12",
    label: "观看人数",
  },
  {
    value: "live_watch_time/观看人次/13",
    label: "观看人次",
  },
  {
    value: "live_like_count/直播点赞/14",
    label: "直播点赞",
  },
  {
    value: "live_comment_count/直播评论/15",
    label: "直播评论",
  },
  {
    value: "live_activation/直播活跃度/16",
    label: "直播活跃度",
  },
  {
    value: "live_fans_add/直播增粉/17",
    label: "直播增粉",
  },
];
const allCheck = [
  "fans_count/粉丝增量/0",
  "fans_count_total/粉丝总量/1",
  "fans_tt_count/头条粉丝/2",
  "fans_hs_count/火山粉丝/3",
  "aweme_count/视频发布/4",
  "aweme_like_count/视频点赞/5",
  "aweme_comment_count/视频评论/6",
  "aweme_share_count/视频转发/7",
  "aweme_play_count/视频播放/8",
  "aweme_activation/视频活跃度/9",
  "live_count/直播场次/10",
  "live_time/直播时长/11",
  "live_watch_count/观看人数/12",
  "live_watch_time/观看人次/13",
  "live_like_count/直播点赞/14",
  "live_comment_count/直播评论/15",
  "live_activation/直播活跃度/16",
  "live_fans_add/直播增粉/17",
];
const settingData = [
  {
    type: "text",
    text: "剔除刷量视频",
    value: "1",
    bool: false,
  },
  {
    type: "text",
    text: "剔除挂播直播",
    value: "4",
    bool: false,
  },
  {
    type: "input",
    text: "剔除播放低于",
    text2: "的视频",
    value: "3",
    bool: false,
    inputValue: "500",
    inputRules: [
      {
        validator: function(rule, value, callback) {
          const pattern = /^([1-9]|[1-9]\d|[1-9]\d\d|1000)$/;

          if (!value || !pattern.test(value)) {
            callback(new Error("请输入1-1000的整数值"));
          } else {
            callback();
          }
        },
      },
    ],
  },
  {
    type: "input",
    text: "剔除时长低于",
    text2: "min的直播",
    value: "5",
    bool: false,
    inputValue: "30",
    inputRules: [
      {
        validator: function(rule, value, callback) {
          const pattern = /^([1-9]|[1-9]\d|100)$/;

          if (!value || !pattern.test(value)) {
            callback(new Error("请输入1-100的整数值"));
          } else {
            callback();
          }
        },
      },
    ],
  },
];
export default {
  components: {
    PlatformHeader,
    DateTab,
    ExcelTypeButton,
    Empty,
    Tooltip,
    ButtonGroup,
    RouterButton,
    SwitchPopover,
    SwitchButton,
  },
  data() {
    return {
      currentTab: "",
      tabList: [
        {
          label: "门 店",
          value: "1",
        },
        {
          label: "账 号",
          value: "0",
        },
      ],
      tabType: "1",
      excelType: false,
      switchButtonType: true,
      locale: {
        emptyText: <Empty style="margin-top: 20px;" title="暂无数据" />,
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      dateType: "month",
      word: "",
      searchForm: {
        typeRadio: "",
      },
      currentRadio: "",
      radioOptions: [
        { label: "全部账号", value: "" },
        { label: "蓝V认证账号", value: "1" },
        { label: "非蓝V认证账号", value: "2" },
      ],
      firstColumns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "num" },
          fixed: "left",
        },
        {
          key: "account",
          align: "left",
          slots: { title: "customTitle" },
          width: 200,
          scopedSlots: { customRender: "account" },
          fixed: "left",
        },
        {
          key: "storeCode",
          title: "门店编码",
          align: "left",
          width: 150,
          scopedSlots: { customRender: "storeCode" },
          fixed: "left",
        },
        {
          key: "action",
          width: 70,
          align: "left",
          slots: { title: "customAction" },
          scopedSlots: { customRender: "action" },
          fixed: "right",
        },
      ],
      columns: [],
      tableSetting: deepClone(tableSetting),
      current: 1,
      pageSize: 20,
      total: 0,
      tableData: [],
      tableLoading: false,
      settingModalShow: false,
      regionValue: undefined,
      provValue: undefined,
      cityValue: undefined,
      groupValue: undefined,
      provData: [],
      cityData: [],
      currentCheck: deepClone(allCheck),
      oldCheck: deepClone(allCheck),
      settingData: deepClone(settingData),
      settingType: "2",
      aweme_play_volume: "",
      live_time: "",

      illegalTagDisplay: {
        fake_aweme_data_display: 0,
        nobody_live_display: 0,
      },
    };
  },
  computed: {
    ...mapState({
      areaData: (state) => state.areaData,
      groupData: (state) => state.groupData,
      competitionData: (state) => state.competitionData,
      competitionAllData: (state) => state.competitionAllData,
    }),
  },
  watch: {
    competitionData(val) {
      this.tableData = val.list;
      this.total = val.pagination.total;
    },
  },
  async created() {
    await this.getIllegalTagDisplay();
    await this.getAreaInfo();
    if (this.areaData && this.areaData.length > 0) {
      this.regionValue = this.areaData[0].id;
      this.regionClick(this.areaData[0]);
    }
    this.getAllGroup();
    // this.$nextTick(() => {
    //   this.search(true);
    //   this.setTableCol(true);
    // });
  },
  mounted() {},
  methods: {
    ...mapActions(["getAreaInfo", "getAllGroup", "getCompetitionList"]),
    initSettingData() {
      this.settingData = deepClone(settingData).filter((item) => {
        if (item.text == "剔除刷量视频") {
          return this.illegalTagDisplay.fake_aweme_data_display;
        } else if (item.text == "剔除挂播直播") {
          return this.illegalTagDisplay.nobody_live_display;
        } else {
          return true;
        }
      });
    },
    /**
     * 刷量视频/挂播直播状态
     */
    async getIllegalTagDisplay() {
      try {
        const res = await tagApi.getIllegalTagDisplay();
        const { code, data, message } = res.data;
        if (code === 0) {
          this.illegalTagDisplay = data;
          this.initSettingData();
        } else {
          this.$message.error(message);
        }
      } catch (error) {}
    },
    // 大区点击
    regionClick(item) {
      this.provData = item.children;
      this.provValue = undefined;
      this.cityValue = undefined;
    },
    // 切换顶部tab（经销商/账号）
    handleTypeClick(val) {
      this.current = 1;
      this.word = "";
      this.tabType = val;
      if (val == "0") {
        this.searchForm.typeRadio = "";
      }
      this.search(true);
    },
    provClick(item) {
      if (item) {
        this.cityData = item.children;
        this.cityValue = undefined;
      } else {
        this.cityValue = undefined;
        this.cityData = [];
      }
    },
    async search(isFirst) {
      console.log("isFirst", isFirst);
      this.tableLoading = true;
      let begin_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      if (isFirst) {
        this.current = 1;
      }
      let params = {
        is_dealer: this.tabType,
        word: this.word,
        area_id: this.regionValue || "",
        province_city_id: this.cityValue || this.provValue || "",
        group_code: this.groupValue || "",
        verification_type: this.searchForm.typeRadio,
        begin_time: begin_time,
        end_time: end_time,
        current: this.current,
        page_size: this.pageSize,
        platform: this.currentTab,
        type: this.settingType,
        aweme_play_volume: this.aweme_play_volume,
        live_time: this.live_time,
      };
      if (isFirst) {
        this.currentParams = params;
      }
      this.currentParams.current = this.current;
      this.currentRadio = _.cloneDeep(this.searchForm.typeRadio);
      await this.getCompetitionList(this.currentParams);
      this.tableLoading = false;
      if (this.competitionAllData.code == 6000) {
        if (this.dateType == "month") {
          // 选择月份 小于等于 上两个月，则false
          if (
            Date.parse(this.$refs.dateTab.searchMonthTime.format("YYYY-MM")) <=
            Date.parse(
              moment()
                .subtract(2, "months")
                .format("YYYY-MM")
            )
          ) {
            return false;
          }
          this.$refs.dateTab.searchMonthTime = moment().subtract(2, "months");
          this.$refs.dateTab.monthStartTime =
            moment()
              .subtract(2, "months")
              .startOf("month")
              .format("YYYY-MM-DD") + " 00:00:00";
          this.$refs.dateTab.monthEndTime =
            moment()
              .subtract(2, "months")
              .endOf("month")
              .format("YYYY-MM-DD") + " 23:59:59";
          this.$message.warning(
            "上月数据预计12：00跑完，页面展示的数据统计时间往前推一个月"
          );
        }
        if (this.dateType == "day") {
          // 结束时间 小于等于 前天，则false
          if (
            Date.parse(
              this.$refs.dateTab.searchDayTime[1].format("YYYY-MM-DD")
            ) <=
            Date.parse(
              moment()
                .subtract(2, "days")
                .format("YYYY-MM-DD")
            )
          ) {
            return false;
          }
          // 如果 开始时间 为昨日，则开始结束时间都变为前天
          // 如果 结束时间 为昨日，则结束时间为前天
          this.$refs.dateTab.searchDayTime = [
            this.$refs.dateTab.searchDayTime[0].format("YYYY-MM-DD") ==
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
              ? moment().subtract(2, "days")
              : this.$refs.dateTab.searchDayTime[0],
            moment().subtract(2, "days"),
          ];
          this.$refs.dateTab.dayStartTime =
            this.$refs.dateTab.searchDayTime[0].format("YYYY-MM-DD") +
            " 00:00:00";
          this.$refs.dateTab.dayEndTime =
            this.$refs.dateTab.searchDayTime[1].format("YYYY-MM-DD") +
            " 23:59:59";
          this.$message.warning(
            "昨日数据预计12：00跑完，页面展示的数据统计时间往前推一天"
          );
        }
        this.search(true);
      }
    },
    // 指标弹窗 确定点击
    settingSure() {
      this.settingModalShow = false;
      this.oldCheck = deepClone(this.currentCheck);
      this.setTableCol();
    },
    // 指标弹窗 取消点击
    settingClose() {
      this.settingModalShow = false;
      this.currentCheck = deepClone(this.oldCheck);
    },

    // 内容显示设置切换
    changeSwitchPopover(value) {
      console.log("changeSwitchPopover", value);
      // 给settingType赋值
      this.setSettingType(value);
      this.search(true);
    },
    setSettingType(arr) {
      const newArr = arr.reduce((acc, cur) => {
        if (cur.bool) {
          acc.push(cur.value);
          cur.type === "input" &&
            cur.value === "3" &&
            (this.aweme_play_volume = cur.inputValue);
          cur.type === "input" &&
            cur.value === "5" &&
            (this.live_time = cur.inputValue);
        } else {
          cur.type === "input" &&
            cur.value === "3" &&
            (this.aweme_play_volume = "");
          cur.type === "input" && cur.value === "5" && (this.live_time = "");
        }

        return acc;
      }, []);
      newArr.push("2");
      this.settingType = newArr.join(",");
    },
    // 抖音/快手 tab切换
    tabChange(val) {
      this.currentTab = val;
      this.$nextTick(() => {
        this.setTableCol(true);
        this.search(true);
      });
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
      // this.searchTimeTable();
    },
    // 切换时间查询
    searchTimeTable() {
      this.search(true);
    },
    // 页码切换
    pageChange(val) {
      console.log("pageChange", val);
      this.current = val;
      this.search();
    },
    // 单选菜单切换
    radioOnChange(e) {},
    // 指标点击
    actionClick() {
      this.settingModalShow = true;

      console.log("this.currentCheck111", this.currentCheck);
      console.log("this.oldCheck111", this.oldCheck);
      console.log("this.tableSetting111", this.tableSetting);
    },
    tableChange(val) {
      // this.currentCheck = val
      // if (val.length > 6) {
      //   this.currentCheck = val.slice(0, 6);
      //   this.$message.warning("指标选择不能超过6个");
      // }
      console.log(val);

      console.log("this.currentCheck", this.currentCheck);
      console.log("this.oldCheck", this.oldCheck);
      console.log("this.tableSetting", this.tableSetting);
    },
    setTableCol(isCheckAll) {
      this.columns = _.cloneDeep(this.firstColumns);
      if (isCheckAll) {
        this.currentCheck = deepClone(allCheck);
        this.oldCheck = deepClone(allCheck);
      }
      this.tableSetting = deepClone(tableSetting);
      this.currentCheck = this.currentCheck.sort(this.sortSetting);
      this.currentCheck = this.currentCheck.filter((item) => {
        if (
          (item.split("/")[0] == "fans_tt_count" ||
            item.split("/")[0] == "fans_hs_count") &&
          this.currentTab != "douyin"
        ) {
          return false;
        } else {
          return true;
        }
      });
      // todo agg
      // this.currentCheck = this.currentCheck.filter((item) => {
      //   if (
      //     (item.split("/")[0] == "aweme_activation" ||
      //       item.split("/")[0] == "live_activation") &&
      //     this.currentTab == "agg"
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });

      this.oldCheck = this.currentCheck.filter((item) => {
        if (
          (item.split("/")[0] == "fans_tt_count" ||
            item.split("/")[0] == "fans_hs_count") &&
          this.currentTab != "douyin"
        ) {
          return false;
        } else {
          return true;
        }
      });

      // todo agg
      // this.oldCheck = this.currentCheck.filter((item) => {
      //   if (
      //     (item.split("/")[0] == "aweme_activation" ||
      //       item.split("/")[0] == "live_activation") &&
      //     this.currentTab == "agg"
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });

      // console.log('this.tableSetting111',this.tableSetting)
      // this.tableSetting = this.tableSetting.filter((item) => {
      //   console.log(345, item)
      //   if (
      //     (item.value.split("/")[0] == "fans_tt_count" ||
      //       item.value.split("/")[0] == "fans_hs_count") &&
      //     this.currentTab != "douyin"
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
      this.currentCheck.forEach((item) => {
        let newItem = {
          key: item.split("/")[0],
          title: item.split("/")[1],
          align: "center",
          width: 100,
          scopedSlots: { customRender: item.split("/")[0] },
        };
        if (item.split("/")[0] == "aweme_play_count") {
          delete newItem.title;
          newItem.slots = { title: "tooltipTitle" };
        }
        if (item.split("/")[0] == "live_watch_count") {
          delete newItem.title;
          newItem.slots = { title: "watchCountTitle" };
        }
        if (item.split("/")[0] == "live_watch_time") {
          delete newItem.title;
          newItem.slots = { title: "watchTimeTitle" };
        }
        if (item.split("/")[0] == "live_fans_add") {
          delete newItem.title;
          newItem.slots = { title: "addFansTitle" };
        }
        if (item.split("/")[0] == "aweme_activation") {
          delete newItem.title;
          newItem.slots = { title: "awemeActivationTitle" };
        }
        if (item.split("/")[0] == "live_activation") {
          delete newItem.title;
          newItem.slots = { title: "liveActivationTitle" };
        }
        this.columns.splice(-1, 0, newItem);
      });
      // console.log('this.tableSetting',this.tableSetting)
    },
    sortSetting(a, b) {
      return a.split("/")[2] - b.split("/")[2];
    },
    changeExcelType() {
      this.excelType = !this.excelType;
    },
    changeButtonType() {
      this.switchButtonType = !this.switchButtonType;
      if (!this.switchButtonType) {
        this.initSettingData();
        this.settingType = "";
      } else {
        this.settingType = "2";
      }
    },
    routerTo() {
      this.$router.push({
        path: "storeSearch",
      });
    },
    routerDetail(obj) {
      this.$router.push({
        path: "/dealer/production",
        query: {
          type:
            this.currentTab == "agg"
              ? this.getPlatform(obj.platform)
              : this.currentTab,
          author_id: obj.author_id,
        },
      });
    },
    getPlatform(type) {
      if (type == 1) {
        return "douyin";
      } else if (type == 2) {
        return "kuaishou";
      } else if (type == 3) {
        return "dongchedi";
      } else {
        return "douyin";
      }
    },

    // 导出
    exportClick() {
      let begin_time = "";
      let end_time = "";
      if (this.dateType == "month") {
        if (
          !this.$refs.dateTab.monthStartTime ||
          !this.$refs.dateTab.monthEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.monthStartTime;
        end_time = this.$refs.dateTab.monthEndTime;
      }
      if (this.dateType == "day") {
        if (
          !this.$refs.dateTab.dayStartTime ||
          !this.$refs.dateTab.dayEndTime
        ) {
          this.$message.warning("请选择日期");
          return false;
        }
        begin_time = this.$refs.dateTab.dayStartTime;
        end_time = this.$refs.dateTab.dayEndTime;
      }
      let kpis = "";
      if (this.currentCheck.length > 0) {
        kpis = this.currentCheck
          .map((item) => {
            return item.split("/")[2];
          })
          .join(",");
      }
      let params = {
        ...this.currentParams,
        kpis: kpis,
        flag: this.excelType ? 1 : 0,
        aweme_play_volume: this.aweme_play_volume,
        live_time: this.live_time,
      };
      exportTable(
        this.$http,
        params,
        this.currentTab,
        "/jjt/dealers/dealer-competition/export/new",
        "filename"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  position: relative;
  .platform-div {
    width: 100%;
    height: 40px;
    @include background_color("background_color2");
    text-align: right;
    position: relative;

    .beta-icon {
      width: 36px;
      height: 14px;
      border-radius: 7px;
      background: #ff4160;
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      position: absolute;
      top: 4px;
      right: 0px;
    }
  }
  .tool-tab {
    padding: 0 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }
  .tools {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .search-btn {
      @include border_color("border_color11");
      @include font_color("font_color17");
      @include background_color("background_color34");
      height: 40px;
    }
    .btn-wrap {
      display: flex;
      align-items: center;
    }
    .button {
      width: 112px;
      height: 40px;
      line-height: 30px;
      font-size: 14px;
      background: none;
      border-radius: 8px;
      .btn-icon {
        font-size: 18px;
        margin-right: 4px;
      }
    }
    .btn-color1 {
      @include font_color("font_color4");
      @include background_color("background_color1");
      @include border_color("font_color4");
    }
    .btn-color2 {
      @include font_color("font_color7");
      @include background_color("background_color1");
      @include border_color("font_color7");
      margin-left: 24px;
    }
    .button2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      margin-left: 24px;
      @include font_color("font_color1");
      @include background_color("background_color63");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color34");
      }
      &:hover .btn-icon {
        @include font_color("font_color35");
      }
    }
  }
  .tools2 {
    flex-direction: row;
    justify-content: space-between;
  }
  .content {
    padding: 0 20px;
    .table.long {
      height: calc(100vh - 404px);
    }
    .table {
      height: calc(100vh - 452px);
      overflow-y: auto;
      &-account {
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        &-left {
          display: flex;
          flex-direction: row;
          @include font_color("font_color3");
          > div {
            margin-left: 8px;
            > div {
              width: 160px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }
      }
      .action-ico {
        margin-left: 20px;
        // @include font_color("font_color5");
        color: #9ca8ba;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: #ffca5c;
        }
      }
    }
  }
  .page-div {
    text-align: right;
    padding: 20px 0;
  }
  .font-color2 {
    @include font_color("font_color2");
  }
  .font-color3 {
    @include font_color("font_color3");
  }
  .font-color4 {
    @include font_color("font_color4");
  }
  .font-color6 {
    @include font_color("font_color6");
    font-size: 24px;
  }
  .font-color7 {
    @include font_color("font_color7");
  }
  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f16 {
    font-size: 16px;
  }
}
.setting-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 100;
  &-container {
    position: absolute;
    right: 24px;
    top: 260px;
    width: 424px;
    height: 344px;
    border-radius: 4px;
    @include background_color("background_color27");
    @include font_color("font_color2");
    font-size: 14px;
    &-title {
      font-size: 16px;
      padding: 24px 0 20px;
      text-align: center;
      > span {
        font-size: 14px;
        @include font_color("font_color3");
      }
    }
    .list {
      padding: 0 32px;
      height: 210px;
    }
    .action-div {
      padding: 16px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      > div {
        cursor: pointer;
        height: 32px;
        line-height: 18px;
        @include font_color("font_color2");
        .icon {
          font-size: 30px;
          @include font_color("font_color4");
          position: relative;
          top: 3px;
        }
      }
      .btn {
        width: 60px;
        height: 32px;
        border-radius: 4px;
        @include background_color("background_color5");
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        cursor: pointer;
      }
      .btn1 {
        @include font_color("font_color3");
        margin-right: 24px;
      }
      .btn2 {
        @include font_color("font_color1");
      }
    }
  }
}
</style>
