<template>
  <div class="best-store-container">
    <div class="title">
      <span>最佳门店</span>
    </div>

    <div class="hot-list-box" v-if="list && list.length > 0">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="onClickDealerBest(index)"
      >
        <div class="avatar"></div>
        <WebpImage
          :src="item.avatar"
          :width="'54px'"
          :height="'54px'"
          :radius="'27px'"
        />
        <div class="content">
          <p class="title">{{ item.dealer_name || "-" }}</p>
          <p class="txt">
            共发布<span
              ><em>{{ item.aweme_count | changeNum }}</em
              >条</span
            >视频,<span
              ><em>{{ item.live_count | changeNum }}</em
              >场</span
            >直播
          </p>
          <p class="txt">
            收获<span
              ><em>{{ item.volume_clue_count | changeNum }}</em
              >条</span
            >声量线索,<span
              ><em>{{ item.sale_clue_count | changeNum }}</em
              >条</span
            >销售线索
          </p>
        </div>
      </div>
    </div>
    <Empty v-else style="padding-top: 140px" title="暂无数据" />
  </div>
</template>

<script>
import Empty from "@/components/Empty/Empty";
import WebpImage from "@/components/WebpImage/WebpImage";
export default {
  name: "BestStore",
  props: {
    data: {
      type: String,
      default: "-",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    Empty,
    WebpImage,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.best-store-container {
  width: 100%;
  height: 100%;
  > .title {
    display: flex;
    align-items: center;
    padding: 27px 20px 19px;
    > span {
      font-size: 18px;
      font-weight: bold;
      color: #ffca5c;
    }
  }
}

.hot-list-box {
  height: calc(100% - 73px);
  overflow-y: auto;
  .item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    height: 86px;
    &.active {
      @include background_color("background_color78");
    }
    &:last-child {
      padding-bottom: 5px;
      margin-bottom: 0;
    }
  }
  .content {
    flex: 1;
    margin-left: 20px;
  }
  p {
    margin: 0;
    line-height: 1.5;
  }
  .title {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    @include font_color("font_color20");
  }
  .txt {
    font-size: 12px;
    @include font_color("font_color23");
    span {
      @include font_color("font_color26");
    }
    em {
      font-style: normal;
      font-weight: bold;
    }
  }
}
</style>