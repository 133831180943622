<template>
  <div class="platform_wrap">
    <span
      v-for="(item, idx) in platformList"
      :key="item.value"
      :class="[checkIdx === idx ? 'checked' : '']"
      @click="clickItem(item, idx)"
      >{{ item.text }}</span
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      platformList: [
        {
          text: "抖音",
          value: "douyin",
        },
        {
          text: "快手",
          value: "kuaishou",
        },
        // {
        //   text: "懂车帝",
        //   value: "dcd",
        // },
      ],
      checkIdx: 0,
    };
  },
  methods: {
    clickItem(item, idx) {
      let data = _.clone(item);
      this.checkIdx = idx;
      // 因此，我们推荐你始终使用 kebab-case 的事件名。
      this.$emit("platform-click", { data, index: idx });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../less/color.less");
.platform_wrap {
  height: 30px;
  font-size: 12px;
  > span {
    height: 30px;
    line-height: 26px;
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 0 20px;
    border: 2px solid @border-color;
    // transition: color 0.3s linear, background-color 0.3s linear, border-color 0.3s linear;
    // transition: all 0.3s linear;

    margin-left: -2px;
    cursor: pointer;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:not(:first-child) {
      border-left: 2px solid transparent;
    }
    &:hover {
      position: relative;
      z-index: 1;
      color: #cbf57b;
      border-color: #cbf57b;
    }
  }
  > span.checked {
    position: relative;
    z-index: 2;
    border-color: #cbf57b;
    background-color: #cbf57b;
    color: #333;
    font-weight: 500;
  }
}
</style>